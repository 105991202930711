import { Container, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import ChimeNotifications from '../../common/CampaignEventComponents/ChimeNotifications';
import EventCapacityRedirectSettings from './AdvancedSettings/EventCapacityRedirectSettings';
import Gamified from '../../common/CampaignEventComponents/Gamified';
import ParticipantRegistration from './AdvancedSettings/ParticipantRegistration';
import Scoring from '../../common/CampaignEventComponents/Scoring';
import Sponsorship from './AdvancedSettings/Sponsorship';
import Prizes from './AdvancedSettings/Prizes';
import EventDetailsSubType from './AdvancedSettings/EventDetailsSubType';
import SkillBuilder from '../../common/CampaignEventComponents/SkillBuilder';
import SecretKeyDetails from './AdvancedSettings/SecretKeyDetails';
import PublicEventSettings from './AdvancedSettings/PublicEventSettings';
import PreEventLobbyVideo from './AdvancedSettings/PreEventLobbyVideo';
import AccessControl from '../../common/CampaignEventComponents/AccessControl';
import LabAvailabilty from '../../common/CampaignEventComponents/LabAvailability';
import Link from '../../ui/atoms/Link/Link';
import { RoutePath } from '@/src/RoutePath';
import { useUser } from '@/src/store/user.context';
import TeamSettings from './Summary/TeamSettings';
import { noop } from 'lodash';

interface EventDetailsAdvancedSettingsProps {
  event: Event;
}

const EventDetailsAdvancedSettings: React.FC<EventDetailsAdvancedSettingsProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <SpaceBetween direction="vertical" size="s">
      <Container>
        <Grid gridDefinition={[{ colspan: 8 }]}>
          <Header
            variant="h2"
            description={
              <div>
                {t(i18nKeys.events.eventDetails.headers.advancedSettings.description)}
                <Link href={RoutePath.FAQ}>FAQ</Link>
              </div>
            }>
            {t(i18nKeys.events.eventDetails.headers.advancedSettings.header)}
          </Header>
        </Grid>
      </Container>
      {(user?.isSuperAdmin || user?.isEventAdmin || user?.isCampaignAdmin) && <Scoring target={event} />}
      {user?.isAmazonian && <ChimeNotifications target={event} />}
      <EventDetailsSubType event={event} />
      {user?.isAmazonian && <Gamified target={event} />}
      {user?.isAmazonian && <PublicEventSettings event={event} />}
      <SecretKeyDetails event={event} />
      {user?.isAmazonian && <SkillBuilder target={event} />}
      <TeamSettings target={event} validationHandler={noop} isAdvancedSettings />
      {user?.isAmazonian && <PreEventLobbyVideo event={event} />}
      {user?.isAmazonian && <Prizes event={event} />}
      {user?.isAmazonian && <ParticipantRegistration event={event} />}
      {user?.isAmazonian && <AccessControl target={event} />}
      {user?.isAmazonian && <LabAvailabilty target={event} />}
      {user?.isAmazonian && <Sponsorship event={event} />}
      <EventCapacityRedirectSettings event={event} />
    </SpaceBetween>
  );
};

export default EventDetailsAdvancedSettings;
