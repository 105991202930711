import React from 'react';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import JamChallengeSolve from '../../ui/molecules/MyJams/JamChallengeDetails/JamOverview/SolveHeader';
import { JamChallengeProperties } from '../../ui/molecules/MyJams/JamChallengeDetails/JamOverview/JamChallengeProperties';
import JamChallengeStatusModal from '../../ui/molecules/MyJams/JamChallengeDetails/JamChallengeStatusModal/JamChallengeStatusModal';
import { SpaceBetween } from '@amzn/awsui-components-react';

interface JamChallengeProps {
  defaultExpanded?: boolean;
  hideProperties?: boolean;
  hideHeader?: boolean;
}

const JamChallengeHeader: React.FC<JamChallengeProps> = ({
  defaultExpanded = false,
  hideProperties = false,
  hideHeader = false,
}) => {
  const { challengeProgress, enableChallengeStatusModal } = useJamChallengeDetails();

  return (
    <SpaceBetween direction="vertical" size="xxl">
      {!hideHeader && <JamChallengeSolve />}
      {!hideProperties && <JamChallengeProperties defaultExpanded={defaultExpanded} />}
      {(challengeProgress?.hasAwsResources || challengeProgress?.hasExternalResources) &&
        enableChallengeStatusModal &&
        !challengeProgress?.completed && <JamChallengeStatusModal />}
    </SpaceBetween>
  );
};

export default JamChallengeHeader;
