import { LabSignInDetails } from '../types/JamSupportChat';

export const isDefined = (obj: any): boolean => {
  return obj !== null;
};

/**
 * Determine whether any of the given expirations epoch timestamps (in milliseconds)
 * expire soon according to the current date/time.
 *
 * "Soon" in this case means "in 30 seconds or less".
 */
export const anyExpireSoon = (...expirations: (number | undefined)[]): boolean => {
  return (expirations.filter(isDefined) as number[]).some((timestamp: number) => timestamp < Date.now() + 30_000);
};

/**
 * Determine whether the URL or credentials of any of the provided sign in details
 * expire soon.
 */
export const anySignInDetailsExpireSoon = (...details: (LabSignInDetails | undefined)[]): boolean => {
  return anyExpireSoon(
    ...details
      .filter(isDefined)
      .map((detail) => [detail?.expiration, detail?.urlExpiration])
      .reduce((prev, curr) => [...prev, ...curr], [])
  );
};
