/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useLayoutEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Form,
  FormField,
  Grid,
  Input,
  Modal,
  RadioGroup,
  Select,
  TextContent,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { COUNTRIES } from '@/src/constants/countries';
import { STATES } from '@/src/constants/states';
import { JamConsent } from '@/src/types/JamConsent';
import { JamParticipantProfile } from '@/src/types/JamParticipantProfile';
import { useApi } from '@/src/store/api.context';
import './ConsentForm.scss';
import { EMAIL_REGEX, PHONE_NUMBER_REGEX, POSTAL_CODE_REGEX } from '@/src/constants/validation-constants';
import { customEventTrigger } from '@/src/components/analytics/createEventTrigger';
import { ConsentMandatoryFields } from '../../UIModel';

interface ConsentFormProps {
  visible: boolean;
  sponsorshipSettings: any;
  consentProfile: JamParticipantProfile;
  consent: JamConsent | undefined;
  eventName: string;
  onConsentAccepted: () => void;
}

const ConsentForm: React.FC<ConsentFormProps> = (props) => {
  const INTERESTS = {
    PERSONAL: 'PERSONAL',
    BUSINESS: 'BUSINESS',
  };

  const { jamConsentApi } = useApi();
  const { t } = useTranslation();
  const countries = COUNTRIES.map((countryEl) => ({ value: countryEl.code, label: t(countryEl.name) }));
  const [visible, setVisible] = React.useState(props.visible);
  const [consentChecked, setConsent] = React.useState(false);
  const userEmail = props.consentProfile.email || '';
  const [email, setEmail] = React.useState(userEmail);
  const [interest, setInterest] = React.useState(INTERESTS.PERSONAL);
  const [firstName, setFirstName] = React.useState(props.consentProfile.firstName || '');
  const [lastName, setLastName] = React.useState(props.consentProfile.lastName || '');
  const [company, setCompany] = React.useState(props.consentProfile.company || '');
  const [title, setTitle] = React.useState(props.consentProfile.title || '');
  const [address, setAddress] = React.useState(props.consentProfile.address || '');
  const [postalCode, setPostalCode] = React.useState(props.consentProfile.postalCode || '');
  const [phoneNumber, setPhoneNumber] = React.useState(props.consentProfile.phoneNumber || '');
  const defaultCountry = countries.filter((item) => item.value === props.consentProfile.country);
  const [country, setCountry] = React.useState<OptionDefinition | null>(defaultCountry[0]);
  const defaultStates = STATES.filter((state) => state.countryCode === props.consentProfile.country);
  const [mandatoryFields, setMandatoryFields] = React.useState<ConsentMandatoryFields[]>([]);
  const states = defaultStates.map((stateEl) => ({ value: stateEl.code, label: t(stateEl.name) }));
  const [selectedStates, setStates] = React.useState(states);
  const defaultState = states.filter((stateEl) => stateEl.value === props.consentProfile.stateOrProvince);
  const [selectedState, setState] = React.useState<OptionDefinition | null>(defaultState[0]);
  const [emailErrorText, setEmailError] = React.useState('');
  const [firstNameErrorText, setFirstNameError] = React.useState('');
  const [lastNameErrorText, setLastNameError] = React.useState('');
  const [titleErrorText, setTitleError] = React.useState('');
  const [companyErrorText, setCompanyError] = React.useState('');
  const [countryErrorText, setCountryError] = React.useState('');
  const [stateErrorText, setStateError] = React.useState('');
  const [addressErrorText, setAddressError] = React.useState('');
  const [postalErrorText, setPostalError] = React.useState('');
  const [phoneNumberErrorText, setPhoneNumberError] = React.useState('');
  const hasStates = selectedStates.length > 0;

  const interestItem = [
    { value: INTERESTS.PERSONAL, label: `${t(i18nKeys.joinTeam.consent.personal)}` },
    { value: INTERESTS.BUSINESS, label: `${t(i18nKeys.joinTeam.consent.business)}` },
  ];

  useLayoutEffect(() => {
    let requiredMandatoryFields = [];
    // these fields will always be mandatory
    requiredMandatoryFields = [ConsentMandatoryFields.email, ConsentMandatoryFields.interest];

    if (props.sponsorshipSettings.firstNameRequired) {
      requiredMandatoryFields.push(ConsentMandatoryFields.firstName);
    }
    if (props.sponsorshipSettings.lastNameRequired) {
      requiredMandatoryFields.push(ConsentMandatoryFields.lastName);
    }
    if (props.sponsorshipSettings.phoneNumberRequired) {
      requiredMandatoryFields.push(ConsentMandatoryFields.phoneNumber);
    }
    if (props.sponsorshipSettings.companyRequired) {
      requiredMandatoryFields.push(ConsentMandatoryFields.company);
    }
    if (props.sponsorshipSettings.titleRequired) {
      requiredMandatoryFields.push(ConsentMandatoryFields.title);
    }
    if (props.sponsorshipSettings.addressRequired) {
      requiredMandatoryFields.push(ConsentMandatoryFields.address);
    }
    if (props.sponsorshipSettings.countryRequired) {
      requiredMandatoryFields.push(ConsentMandatoryFields.country);
    }
    if (props.sponsorshipSettings.countryRequired && hasStates) {
      requiredMandatoryFields.push(ConsentMandatoryFields.stateOrProvince);
    }
    if (props.sponsorshipSettings.postalCodeRequired) {
      requiredMandatoryFields.push(ConsentMandatoryFields.postalCode);
    }
    setMandatoryFields(requiredMandatoryFields);
  }, [hasStates]);

  const validateMandatoryFields = (field: ConsentMandatoryFields) => {
    return mandatoryFields.includes(field);
  };

  const validate = (field: ConsentMandatoryFields) => {
    let hasError = false;
    switch (field) {
      case ConsentMandatoryFields.email:
        const onEmailErrorText = email
          ? !isValidEmail(email)
            ? `${t(i18nKeys.joinTeam.consent.emailInvalid)}`
            : ''
          : mandatoryFields.includes(field)
          ? `${t(i18nKeys.joinTeam.consent.emailRequired)}`
          : '';
        setEmailError(onEmailErrorText);
        hasError = !!onEmailErrorText;
        break;
      case ConsentMandatoryFields.firstName:
        const fristNameErrText =
          !firstName && mandatoryFields.includes(field) ? `${t(i18nKeys.joinTeam.consent.firstNameRequired)}` : '';
        setFirstNameError(fristNameErrText);
        hasError = !!firstNameErrorText;
        break;
      case ConsentMandatoryFields.lastName:
        const lastNameErrText =
          !lastName && mandatoryFields.includes(field) ? `${t(i18nKeys.joinTeam.consent.lastNameRequired)}` : '';
        setLastNameError(lastNameErrText);
        hasError = !!lastNameErrText;
        break;
      case ConsentMandatoryFields.company:
        const companyErrText =
          !company && mandatoryFields.includes(field) ? `${t(i18nKeys.joinTeam.consent.companyRequired)}` : '';
        setCompanyError(companyErrText);
        hasError = !!companyErrText;
        break;
      case ConsentMandatoryFields.title:
        const titleErrText =
          !title && mandatoryFields.includes(field) ? `${t(i18nKeys.joinTeam.consent.titleRequired)}` : '';
        setTitleError(titleErrText);
        hasError = !!titleErrText;
        break;
      case ConsentMandatoryFields.country:
        const countryErrText =
          !country && mandatoryFields.includes(field) ? `${t(i18nKeys.joinTeam.consent.countryRequired)}` : '';
        setCountryError(countryErrText);
        hasError = !!countryErrText;
        break;
      case ConsentMandatoryFields.stateOrProvince:
        const stateErrText =
          !selectedState && mandatoryFields.includes(field)
            ? `${t(i18nKeys.joinTeam.consent.stateOrProvinceRequired)}`
            : '';
        setStateError(stateErrText);
        hasError = !!stateErrText;
        break;
      case ConsentMandatoryFields.address:
        const addressErrText =
          !address && mandatoryFields.includes(field) ? `${t(i18nKeys.joinTeam.consent.addressRequired)}` : '';
        setAddressError(addressErrText);
        hasError = !!addressErrText;
        break;
      case ConsentMandatoryFields.postalCode:
        const postalCodeErrText = postalCode
          ? !isValidPostalCode(postalCode)
            ? `${t(i18nKeys.joinTeam.consent.postalCodeInvalid)}`
            : ''
          : mandatoryFields.includes(field)
          ? `${t(i18nKeys.joinTeam.consent.postalCodeRequired)}`
          : '';
        setPostalError(postalCodeErrText);
        hasError = !!postalCodeErrText;
        break;
      case ConsentMandatoryFields.phoneNumber:
        const phoneNumberErrText = phoneNumber
          ? !isValidPhoneNumber(phoneNumber)
            ? `${t(i18nKeys.joinTeam.consent.phoneNumberInvalid)}`
            : ''
          : mandatoryFields.includes(field)
          ? `${t(i18nKeys.joinTeam.consent.phoneNumberRequired)}`
          : '';
        setPhoneNumberError(phoneNumberErrText);
        hasError = !!phoneNumberErrText;
        break;
    }
    return hasError;
  };

  const onCountryChange = (item: OptionDefinition) => {
    const currentStates = STATES.filter((state) => state.countryCode === item.value);
    setCountry(item);
    if (currentStates) {
      const stateValue = currentStates.map((stateEl) => ({ value: stateEl.code, label: t(stateEl.name) }));
      setStates(stateValue);
      setState(null);
    }
  };

  const isValidEmail = (emailId: string): boolean => {
    return EMAIL_REGEX.test(emailId);
  };

  const isValidPhoneNumber = (validPhoneNumber: string): boolean => {
    return PHONE_NUMBER_REGEX.test(validPhoneNumber.toString());
  };

  const isValidPostalCode = (postal: string) => {
    return POSTAL_CODE_REGEX.test(postal.toString());
  };

  const validForm = () => {
    let hasError = false;
    mandatoryFields.forEach((field) => {
      const error = validate(field);
      if (!hasError && error) {
        hasError = true;
      }
    });
    return hasError;
  };

  const submitConsent = async () => {
    if (validForm()) {
      return false;
    }

    customEventTrigger('submit', 'Consent Submit', window.location.href, 'Consent Submit', {});

    const consentProfileData: JamParticipantProfile = {
      email,
      emailVerified: props.consentProfile.emailVerified,
      nickname: props.consentProfile.nickname,
      title,
      company,
      country: country?.value || null,
      firstName,
      lastName,
      stateOrProvince: selectedState?.value || null,
      postalCode,
      address,
      phoneNumber,
      interest,
    };

    const sponsor = {
      name: null,
      url: null,
      description: null,
      logo: null,
    };

    const consent = props.consent
      ? props.consent
      : {
          sponsor,
          optedIn: false,
          eventName: null,
          challengeId: null,
          challengeTitle: null,
          eventTitle: null,
          profile: null,
          eventUrlPath: null,
          isEventConsent: null,
        };
    consent.profile = consentProfileData;
    try {
      await jamConsentApi.saveEventConsent(props.eventName, consent as JamConsent).then(() => {
        setVisible(false);
      });
    } catch (e) {
      // error
    }
    props.onConsentAccepted();
  };

  return (
    <Modal
      header={
        <TextContent>
          <h3>{t(i18nKeys.joinTeam.consent.title)}</h3>
        </TextContent>
      }
      visible={visible}
      size="large"
      closeAriaLabel={t(i18nKeys.general.close)}>
      <Form
        actions={
          <Button
            ariaLabel={t(i18nKeys.joinTeam.consent.submitLabel)}
            variant="primary"
            onClick={() => void submitConsent()}>
            {t(i18nKeys.joinTeam.consent.submitLabel)}
          </Button>
        }>
        <Checkbox checked={consentChecked} onChange={() => setConsent(!consentChecked)}>
          {t(i18nKeys.joinTeam.consent.consentBrief)}
        </Checkbox>
        <hr className="HDivider" />
        <Box margin={{ top: 'l' }}>
          <TextContent>
            <small>{t(i18nKeys.joinTeam.consent.consentDescription)}</small>
          </TextContent>
        </Box>
        {consentChecked && (
          <Box margin={{ top: 'xxl' }}>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <FormField
                label={`${t(i18nKeys.joinTeam.consent.email)}${
                  validateMandatoryFields(ConsentMandatoryFields.email) ? '*' : ''
                }`}
                errorText={emailErrorText}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}>
                <Input
                  value={email}
                  type="email"
                  onChange={({ detail }) => setEmail(detail.value)}
                  onBlur={() => validate(ConsentMandatoryFields.email)}
                />
              </FormField>
              <FormField
                label={`${t(i18nKeys.joinTeam.consent.interestQuestion)}${
                  validateMandatoryFields(ConsentMandatoryFields.interest) ? '*' : ''
                }`}>
                <RadioGroup
                  value={interest}
                  onChange={({ detail }) => setInterest(detail.value)}
                  items={interestItem}
                />
              </FormField>
            </Grid>
            <Box margin={{ top: 'xs' }}>
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <FormField
                  label={`${t(i18nKeys.joinTeam.consent.firstName)}${
                    validateMandatoryFields(ConsentMandatoryFields.firstName) ? '*' : ''
                  }`}
                  i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                  errorText={firstNameErrorText}>
                  <Input
                    value={firstName}
                    onChange={({ detail }) => setFirstName(detail.value)}
                    onBlur={() => validate(ConsentMandatoryFields.firstName)}
                    type="text"
                  />
                </FormField>
                <FormField
                  label={`${t(i18nKeys.joinTeam.consent.lastName)}${
                    validateMandatoryFields(ConsentMandatoryFields.lastName) ? '*' : ''
                  }`}
                  i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                  errorText={lastNameErrorText}>
                  <Input
                    value={lastName}
                    onChange={({ detail }) => setLastName(detail.value)}
                    onBlur={() => validate(ConsentMandatoryFields.lastName)}
                    type="text"
                  />
                </FormField>
              </Grid>
            </Box>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <FormField
                label={`${t(i18nKeys.joinTeam.consent.company)}${
                  validateMandatoryFields(ConsentMandatoryFields.company) ? '*' : ''
                }`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={companyErrorText}>
                <Input
                  value={company}
                  onChange={({ detail }) => setCompany(detail.value)}
                  onBlur={() => validate(ConsentMandatoryFields.company)}
                  type="text"
                />
              </FormField>
              <FormField
                label={`${t(i18nKeys.joinTeam.consent.titleLabel)}${
                  validateMandatoryFields(ConsentMandatoryFields.title) ? '*' : ''
                }`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={titleErrorText}>
                <Input
                  value={title}
                  onChange={({ detail }) => setTitle(detail.value)}
                  onBlur={() => validate(ConsentMandatoryFields.title)}
                  type="text"
                />
              </FormField>
            </Grid>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <FormField
                label={`${t(i18nKeys.joinTeam.consent.country)}${
                  validateMandatoryFields(ConsentMandatoryFields.country) ? '*' : ''
                }`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={countryErrorText}>
                <Select
                  selectedOption={country}
                  onChange={({ detail }) => onCountryChange(detail.selectedOption)}
                  onBlur={() => validate(ConsentMandatoryFields.country)}
                  options={countries}
                  placeholder="Select Country"
                />
              </FormField>
              {hasStates && (
                <FormField
                  label={`${t(i18nKeys.joinTeam.consent.stateProvince)}${
                    validateMandatoryFields(ConsentMandatoryFields.stateOrProvince) ? '*' : ''
                  }`}
                  i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                  errorText={stateErrorText}>
                  <Select
                    selectedOption={selectedState}
                    onChange={({ detail }) => setState(detail.selectedOption)}
                    onBlur={() => validate(ConsentMandatoryFields.stateOrProvince)}
                    options={states}
                    placeholder="Select State"
                  />
                </FormField>
              )}
            </Grid>
            <Grid gridDefinition={[{ colspan: 12 }]}>
              <FormField
                stretch
                label={`${t(i18nKeys.joinTeam.consent.address)}${
                  validateMandatoryFields(ConsentMandatoryFields.address) ? '*' : ''
                }`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={addressErrorText}>
                <Input
                  value={address}
                  onChange={({ detail }) => setAddress(detail.value)}
                  onBlur={() => validate(ConsentMandatoryFields.address)}
                  type="text"
                />
              </FormField>
            </Grid>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <FormField
                label={`${t(i18nKeys.joinTeam.consent.postalCode)}${
                  validateMandatoryFields(ConsentMandatoryFields.postalCode) ? '*' : ''
                }`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={postalErrorText}>
                <Input
                  value={postalCode}
                  onChange={({ detail }) => setPostalCode(detail.value)}
                  onBlur={() => validate(ConsentMandatoryFields.postalCode)}
                  type="text"
                />
              </FormField>
              <FormField
                label={`${t(i18nKeys.joinTeam.consent.phoneNumber)}${
                  validateMandatoryFields(ConsentMandatoryFields.phoneNumber) ? '*' : ''
                }`}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={phoneNumberErrorText}>
                <Input
                  value={phoneNumber}
                  onChange={({ detail }) => setPhoneNumber(detail.value)}
                  onBlur={() => validate(ConsentMandatoryFields.phoneNumber)}
                  type="text"
                />
              </FormField>
            </Grid>
          </Box>
        )}
        <Box margin={{ top: 'l' }}>
          <hr className="HDivider" />
        </Box>
      </Form>
    </Modal>
  );
};

export default ConsentForm;
