import { uniqBy } from 'lodash';
import { JamSupportChat } from '../types/JamSupportChat';
import { User } from '../types/User';

export const sanitizeSupportChats = (unassignedSupportChats: JamSupportChat[], currentUser: User): JamSupportChat[] => {
  const chats = unassignedSupportChats.filter((chat) => {
    // dont show chats created by the user who is logged in
    if (chat.createdBy === currentUser.username) {
      return false;
    }
    // handle case where chat no longer active
    if (!chat.active) {
      return false;
    }

    return true;
  });

  // sort by oldest first.
  // oldest can be determined by checking the timeSent of the first message
  chats.sort((a, b) => {
    const timeA = a.messages[0]?.timeSent;
    const timeB = b.messages[0]?.timeSent;

    if (timeA > timeB) return 1;
    if (timeA < timeB) return -1;
    return 0;
  });
  return uniqBy(chats, ({ id }) => id);
};
