import { isValidLength, validateField, validateSection } from "./validation.utils";
import { InputValidationHelper } from '../types/InputValidator';
import { CHALLENGE_SET_TITLE_MIN_LENGTH, CHALLENGE_SET_TITLE_MAX_LENGTH, CHALLENGE_SET_DESCRIPTION_MIN_LENGTH, CHALLENGE_SET_DESCRIPTION_MAX_LENGTH } from '@/src/store/challenge-set.context'
import { safeString } from "./string.utils";

// eslint-disable-next-line no-shadow
export enum ChallengeSetTitleDescriptionFields {
  CHALLENGE_SET_TITLE = 'challenge-set-title',
  CHALLENGE_SET_DESCRIPTION = 'challenge-set-description',
}


export type ChallengeSetValidationFields =
  | ChallengeSetTitleDescriptionFields


const validateChallengeSetTitle = (title: string, errorText: string) => {
  const input = safeString(title);
  const isValid = isValidLength(input, CHALLENGE_SET_TITLE_MIN_LENGTH, CHALLENGE_SET_TITLE_MAX_LENGTH);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};
  
const validateChallengeDescription = (description: string, errorText: string) => {
  const input = safeString(description);
  const isValid = isValidLength(input, CHALLENGE_SET_DESCRIPTION_MIN_LENGTH, CHALLENGE_SET_DESCRIPTION_MAX_LENGTH);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const challengeSetTitleDescriptionValidator: (
  title: string,
  description: string,
  titleErrorText: string,
  descriptionErrorText: string,
  errorSetterByField: Map<ChallengeSetTitleDescriptionFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: ChallengeSetTitleDescriptionFields, setError?: boolean) => boolean;
} = (
  title: string,
  description: string,
  titleErrorText: string,
  descriptionErrorText: string,
  errorSetterByField: Map<ChallengeSetTitleDescriptionFields, (error: string) => void>
) => {
  const validatorHelperByField = new Map<ChallengeSetTitleDescriptionFields, InputValidationHelper>([
    [ChallengeSetTitleDescriptionFields.CHALLENGE_SET_TITLE, validateChallengeSetTitle(title, titleErrorText)],
    [ChallengeSetTitleDescriptionFields.CHALLENGE_SET_DESCRIPTION, validateChallengeDescription(description, descriptionErrorText)],
  ]);

  const isValidField = (field: ChallengeSetTitleDescriptionFields, setError = true) => {
    return validateField<ChallengeSetTitleDescriptionFields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection<ChallengeSetTitleDescriptionFields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};