import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState } from '../common/TableEmptyState';
import { i18nKeys } from '../../utils/i18n.utils';
import { COLUMN_DEFINITIONS, filteringFunction } from './usage-plan-list-config';
import { LoadingBar } from '../common/LoadingBar';
import { Button, Pagination, Table, TextFilter } from '@amzn/awsui-components-react';
import { TableHeader } from '../common/TableHeader';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../RoutePath';
import { paginationLabels } from '../../utils/table.utils';
import { usePlans } from '../../store/usage-plan/usage-plan.context';
import { useUser } from '../../store/user.context';

const UsagePlanList: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { usagePlans, loadPlans } = usePlans();
  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['name', 'description'],
  });

  useEffect(() => {
    if (!usagePlans || !usagePlans.length) {
      loadPlans();
    }
  }, []);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    usagePlans,
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.usagePlan.list.empty.title)}
            subtitle={t(i18nKeys.usagePlan.list.empty.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  if (!usagePlans) {
    return <LoadingBar />;
  }

  return (
    <Table
      {...collectionProps}
      header={
        <TableHeader
          totalItems={usagePlans.length}
          title={t(i18nKeys.usagePlan.title)}
          actionButtons={
            user?.isSuperAdmin && (
              <Link to={RoutePath.NEW_USAGE_PLAN}>
                <Button variant="primary">{t(i18nKeys.usagePlan.createUsagePlan)}</Button>
              </Link>
            )
          }
        />
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleColumns}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
          filteringAriaLabel={t(i18nKeys.usagePlan.filteringLabel)}
        />
      }
    />
  );
};

export default UsagePlanList;
