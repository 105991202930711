import React, { useCallback, useEffect, useState, useMemo } from 'react';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

// components
import { ChallengeOrdering, IChallengeOrderingSection } from '@/src/components/ui/organisms/EventTemplate';

// types
import { ChallengeListItem } from '@/src/types/Challenge';
import { EventDurationInfo, EventTemplateChallengeOrderFields } from '@/src/types/EventTemplate';
import { BACKUP_CHALLENGE_LIMIT } from '@/src/constants/event-template.constants';
import { useTranslation } from 'react-i18next';
import { Box, Spinner } from '@amzn/awsui-components-react';

interface IChallengeOrderProps {
  activeStepIndex?: number;
  editMode?: boolean;
  setActiveStepIndex?: (x: number) => void;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
  challengeOrderError: string;
  getEventDurationInfo: () => EventDurationInfo | undefined;
  setChallengeOrderError: (error: string) => void;
  primaryChallengeListItems: ChallengeListItem[];
  backupChallengeListItems: ChallengeListItem[];
  onPrimaryBackupChallengesChange: (
    primaryChallenges: ChallengeListItem[],
    backupChallenges: ChallengeListItem[]
  ) => void;
  challengeOrderValidator: (
    minChallenges: number,
    maxChallenges: number,
    primaryChallenges: ChallengeListItem[],
    backupChallenges: ChallengeListItem[],
    errorText: string,
    errorSetterByField: Map<EventTemplateChallengeOrderFields, (error: string) => void>
  ) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: EventTemplateChallengeOrderFields, setError?: boolean) => boolean;
  };
}

export const ChallengeOrder: React.FC<IChallengeOrderProps> = ({
  validationHandler,
  editMode,
  challengeOrderError,
  primaryChallengeListItems,
  backupChallengeListItems,
  setChallengeOrderError,
  getEventDurationInfo,
  onPrimaryBackupChallengesChange,
  challengeOrderValidator,
}) => {
  const { t } = useTranslation();

  const [primaryChallenges, setPrimaryChallenges] = useState<ChallengeListItem[]>();
  const [backupChallenges, setBackupChallenges] = useState<ChallengeListItem[]>();

  useEffect(() => {
    if (!primaryChallenges && primaryChallengeListItems.length > 0) {
      setPrimaryChallenges(primaryChallengeListItems);
    }
  }, [primaryChallenges, primaryChallengeListItems]);

  useEffect(() => {
    if (!backupChallenges && backupChallengeListItems.length > 0) {
      setBackupChallenges(backupChallengeListItems);
    }
  }, [backupChallenges, backupChallengeListItems]);

  const handlePrimaryChallengeOrderChange = useCallback(
    (updatedChallenges: ChallengeListItem[]) => {
      setPrimaryChallenges([...updatedChallenges]);
    },
    [backupChallenges]
  );

  const handleBackupChallengeOrderChange = useCallback(
    (updatedChallenges: ChallengeListItem[]) => {
      setBackupChallenges([...updatedChallenges]);
    },
    [primaryChallenges]
  );

  const handleRemovePrimaryChallange = useCallback(
    (challengeId: string) => {
      setPrimaryChallenges([...(primaryChallenges ?? []).filter((item) => item.id !== challengeId)]);
    },
    [primaryChallenges]
  );

  const handleRemoveBackUpChallange = useCallback(
    (challengeId: string) => {
      setBackupChallenges([...(backupChallenges ?? []).filter((item) => item.id !== challengeId)]);
    },
    [backupChallenges]
  );

  const handleRemoveChallange = (challengeId: string, isPrimary: boolean) => {
    if (isPrimary) handleRemovePrimaryChallange(challengeId);
    else handleRemoveBackUpChallange(challengeId);
  };

  useEffect(() => {
    if (!primaryChallenges || !backupChallenges) return;

    onPrimaryBackupChallengesChange(primaryChallenges, backupChallenges);
  }, [primaryChallenges, backupChallenges]);

  const primary = useMemo<IChallengeOrderingSection>(() => {
    return {
      title: i18nKeys.eventTemplates.step3.primaryChallenges,
      items: primaryChallenges ?? [],
      onChange: handlePrimaryChallengeOrderChange,
    };
  }, [handlePrimaryChallengeOrderChange, primaryChallenges]);

  const backup = useMemo<IChallengeOrderingSection>(() => {
    return {
      title: i18nKeys.eventTemplates.step3.backupChallenges,
      items: backupChallenges ?? [],
      onChange: handleBackupChallengeOrderChange,
    };
  }, [handlePrimaryChallengeOrderChange, backupChallenges]);

  const validator = useMemo(() => {
    const minChallenges = getEventDurationInfo()?.minChallenges ?? 0;
    const maxChallenges = getEventDurationInfo()?.maxChallenges ?? 0;
    return challengeOrderValidator(
      minChallenges,
      maxChallenges,
      primaryChallenges ?? [],
      backupChallenges ?? [],
      t(i18nKeys.eventTemplates.step3.validationMessage, {
        minChallenges,
        maxChallenges,
        backupChallengesLimit: BACKUP_CHALLENGE_LIMIT,
      }),
      new Map<EventTemplateChallengeOrderFields, (error: string) => void>([
        [EventTemplateChallengeOrderFields.CHALLENGE_ORDER, (error: string) => setChallengeOrderError(error)],
      ])
    );
  }, [primaryChallenges, backupChallenges, getEventDurationInfo, challengeOrderValidator]);

  validationHandler(() => {
    const result = validator.isValidSection(true);
    if (result) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  });

  useEffect(() => {
    if (challengeOrderError) {
      window.scrollTo(0, 0);
    }
  }, [challengeOrderError]);

  return (
    <Box margin={{ top: 'xl' }}>
      {!primaryChallenges && !backupChallenges ? (
        <Spinner />
      ) : (
        <ChallengeOrdering
          error={challengeOrderError}
          alertText={i18nKeys.eventTemplates.step3.info}
          primary={primary}
          backup={backup}
          editMode={editMode}
          handleRemove={handleRemoveChallange}
        />
      )}
    </Box>
  );
};
