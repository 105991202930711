import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, Button, Form, FormField, Modal, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RemoveTeamModalProps {
  visible: boolean;
  handleClose: () => void;
  handleRemoveTeam: (inactive: boolean) => Promise<void>;
}

const RemoveTeamModal: React.FC<RemoveTeamModalProps> = ({ handleClose, visible, handleRemoveTeam }) => {
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState<OptionDefinition | null>(null);
  const [error, setError] = useState('');

  const reasons = [
    { label: t(i18nKeys.facilitator.participants.modals.removeTeam.removeOtherReason), value: '0' },
    { label: t(i18nKeys.facilitator.participants.modals.removeTeam.removeInactivity), value: '1' },
  ];

  const onSubmitPress = () => {
    if (!selectedReason) {
      setError(t(i18nKeys.facilitator.participants.modals.removeTeam.removeInactivity));
      return;
    }
    void handleRemoveTeam(selectedReason.value === '1');
    handleClose();
  };
  return (
    <Modal
      onDismiss={handleClose}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onSubmitPress}>
              {t(i18nKeys.general.submit)}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t(i18nKeys.facilitator.participants.modals.removeTeam.title)}>
      <Form>
        <FormField label={t(i18nKeys.facilitator.participants.modals.removeTeam.fieldLabel)} errorText={error}>
          <Select
            selectedOption={selectedReason}
            onChange={({ detail }) => setSelectedReason(detail.selectedOption)}
            options={reasons}
            placeholder={t(i18nKeys.facilitator.participants.modals.removeTeam.selectReason)}
          />
        </FormField>
      </Form>
    </Modal>
  );
};

export default RemoveTeamModal;
