import {
  EventDurationType,
  EventLearningType,
  EventLearningTypeItem,
  EventTopicItem,
  IEventTemplatePage,
  ISortingState,
} from '@/src/types/EventTemplate';
import { PropertyFilterProps } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { i18nKeys } from '../utils/i18n.utils';
import EventTemplateTopics from './event-template-topics.json';

export const MIN_TEAM_SIZE = 1;
export const BACKUP_CHALLENGE_LIMIT = 2;
export const EVENT_TEMPLATE_TOPICS: EventTopicItem[] = EventTemplateTopics;

export const EVENT_TEMPLATE_TAGS: EventTopicItem[] = [
  {
    label: 'Good For Beginners',
    value: 'Good For Beginners',
    description: 'good for beginners',
  },
];

export const EVENT_DURATION_DICTIONARY = {
  [EventDurationType.SHORT]: i18nKeys.eventTemplates.step1.eventDuration.short,
  [EventDurationType.MEDIUM]: i18nKeys.eventTemplates.step1.eventDuration.medium,
  [EventDurationType.LONG]: i18nKeys.eventTemplates.step1.eventDuration.long,
  [EventDurationType.SPL]: i18nKeys.eventTemplates.step1.eventDuration.spl,
  [EventDurationType.CUSTOM]: i18nKeys.eventTemplates.step1.eventDuration.custom,
};

export const EVENT_LEARNING_TYPES: EventLearningTypeItem[] = [
  {
    label: i18nKeys.eventTemplates.step1.eventLearningType.team.label,
    value: EventLearningType.TEAM,
    description: i18nKeys.eventTemplates.step1.eventLearningType.team.description,
  },
  {
    label: i18nKeys.eventTemplates.step1.eventLearningType.individual.label,
    value: EventLearningType.INDIVIDUAL,
    description: i18nKeys.eventTemplates.step1.eventLearningType.individual.description,
  },
];

export const STABILITY_HEALTH_CRITERIA = 0.92;

export const EVENT_TEMPLATE_FEEDBACK_COMMENT_SORT_OPTIONS: OptionDefinition[] = [
  { label: i18nKeys.eventTemplates.constants.allRatings, value: 'rating' },
  { label: i18nKeys.eventTemplates.constants.createAt, value: 'createdAt' },
];

export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_COUNT = 0;
export const EVENT_TEMPLATE_PAGE_SIZE = 500;
export const DEFAULT_FILTER: PropertyFilterProps.Query = {
  tokens: [],
  operation: 'and',
};
export const DEFAULT_SORT: ISortingState = {
  sortingColumn: {
    id: 'createdDate',
    header: i18nKeys.tables.tableConfig.createdDate,
    isRowHeader: true,
    sortingField: 'createdDate',
  },
  isDescending: true,
};

export const DEFAULT_PAGINATION: IEventTemplatePage = {
  currentPageNumber: DEFAULT_PAGE_NO,
  limit: DEFAULT_PAGE_SIZE,
  pageCount: DEFAULT_PAGE_COUNT,
  query: DEFAULT_FILTER,
  sortingState: DEFAULT_SORT,
};
