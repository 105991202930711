import { TFunction } from 'i18next';
import { ChallengeBoard } from '../types/ChallengeBoard';
import { i18nKeys } from '../utils/i18n.utils';
import { asList } from '../utils/list.utils';
import { fromPlainObject } from '../utils/mapper.utils';
import { ApiClient } from './ApiClient';

export class ChallengeBoardAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  public async findAll(silent = false): Promise<ChallengeBoard[]> {
    return this.apiClient.get({
      path: '/admin/challenges/boards',
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeBoards.findAll),
      silent,
      responseMapper: asList((obj) => fromPlainObject(obj, ChallengeBoard)),
    }) as Promise<ChallengeBoard[]>;
  }

  public async findOneById(boardId: string, silent = false): Promise<ChallengeBoard> {
    return this.apiClient.get({
      path: `/admin/challenges/boards/${boardId}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeBoards.findOneById),
      silent,
      responseMapper: (obj) => fromPlainObject(obj, ChallengeBoard),
    }) as Promise<ChallengeBoard>;
  }
}
