import React, { useContext, useState } from 'react';
import { useApi } from './api.context';
import { PostEventReport } from '../types/JamReport';

export interface JamReportContextValue {
  reportApiData?: PostEventReport;
  loadJamReport: (eventName: string) => Promise<void>;
}

const JamReportContext = React.createContext<JamReportContextValue>({
  reportApiData: undefined,
  loadJamReport: () => new Promise(() => { 
    // do nothing
  }),
});

const JamReportProvider: React.FC = ({ children }) => {
  const { jamReportAPI } = useApi();

  const [reportApiData, setReportApiData] = useState<PostEventReport | undefined>();

  const loadJamReport = async (eventName: string) => {
     if (!eventName) {
       throw new Error('Event not found');
     }
    const response = await jamReportAPI.getJamReport(eventName);
    setReportApiData(response);
  };

  const data: JamReportContextValue = {
    reportApiData,
    loadJamReport,
  };
  return <JamReportContext.Provider value={data}>{children}</JamReportContext.Provider>;
};

const useJamReport = () => {
  const context = useContext(JamReportContext);
  if (context === undefined) {
    throw new Error('UseJamReport can only be used inside JamReportProvider');
  }
  return context;
};

export { JamReportProvider, useJamReport };
