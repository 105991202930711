export class JamLeaderboardEntry {
  constructor() {
    this.timestamp = new Date().getTime();
  }

  rank: number | null = null;
  mostRecentScoreTime: number | null = null;
  timestamp: number | null = null;
  teamDisplayName: string | null = null;
  teamName: string | null = null;
  teamNumUsedClues = 0;
  teamTotalAppliedPenalty = 0;
  teamTotalEffectiveScore = 0;
  teamNumSolvedChallenges = 0;
  teamNumScoredChallenges = 0;
  maxPossibleEventScore = 0;

  static fromPlainObject(obj: any = {}): JamLeaderboardEntry {
    return Object.assign(new JamLeaderboardEntry(), obj) as JamLeaderboardEntry;
  }

  get maxPossibleTeamScore(): number | null {
    if (this.maxPossibleEventScore && this.maxPossibleEventScore > 0) {
      return this.maxPossibleEventScore - (this.teamTotalAppliedPenalty || 0);
    }
    return null;
  }

  /**
   * Returns whether or not given leaderboard entry is valid (based on values of key attributes)
   */
  isValidEntry(): boolean {
    return this.rank != null && this.timestamp != null && this.teamName != null && this.teamDisplayName != null;
  }
}
