export enum ErrorCodes {
  // noinspection JSUnusedGlobalSymbols
  SUCCESS=0,
  ITEM_NOT_FOUND=10,
  CHALLENGE_NOT_FOUND=101,
  CHALLENGE_NOT_PART_OF_THE_EVENT=102,
  CHALLENGE_ALREADY_CORRECTLY_ANSWERED=103,
  CHALLENGE_CLUE_ALREADY_REQUESTED_BY_TEAM=104,
  CHALLENGE_CLUE_REQUEST_OUT_OF_ORDER=105,
  NO_MORE_CLUES_TO_REQUEST=106,
  EVENT_IS_REQUIRED=200,
  EVENT_NOT_FOUND=201,
  EVENT_NOT_STARTED=202,
  EVENT_HAS_ENDED=203,
  EVENT_CODE_IS_REQUIRED=204,
  INCORRECT_EVENT_CODE=205,
  RESERVED_EVENT_NAME=210,
  RESERVED_CHALLENGE_ID=211,
  EVENT_IS_CLOSED_FOR_NEW_REGISTRATIONS=208,
  TEAM_NOT_FOUND=401,
  NOT_MEMBER_OF_THE_TEAM=405,
  TEAM_NOT_REGISTERED_TO_EVENT=406,
  ALREADY_MEMBER_OF_ANOTHER_TEAM=407,
  TEAM_NAME_ALREADY_EXISTS=408,
  INVALID_TEAM_NAME=409,
  TEAM_PASSWORD_IS_REQUIRED=411,
  INCORRECT_TEAM_PASSWORD=412,
  TEAM_IS_ALREADY_FULL=413,
  PARTICIPANT_NOT_FOUND=500,
  PARTICIPANT_IS_REQUIRED=501,
  PARTICIPANT_NOT_REGISTERED_TO_EVENT=502,
  PARTICIPANT_ALREADY_REGISTERED_TO_EVENT=503,
  FATAL_ERROR=-1,
  UNKNOWN_ERROR=-2,
  CHALLENGE_NOT_APPROVED=601,
  NO_TEAMS_FOR_EVENT=215,
  QWIKLABS_EMAIL_NOT_PROVIDED =701
}