import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengeListItem } from '@/src/types/Challenge';
import { SpaceBetween } from '@amzn/awsui-components-react';

import {
  DetailHeader,
  EventDetailReview,
  IEventDetailReviewProps,
  EventImageReview,
  ChallengeRationaleReview,
} from '@/src/components/ui/molecules/EventTemplate';

import { i18nKeys } from '@/src/utils/i18n.utils';
import { EventTemplateRationale } from '@/src/types/EventTemplate';

export interface IConfigureEventReviewProps {
  challenges: ChallengeListItem[];
  rationale?: EventTemplateRationale;
  eventDetails: IEventDetailReviewProps;
  imageUrl?: string;
  onEditClick: () => void;
}

const { stepTitle } = i18nKeys.eventTemplates.step5.steps.step4;
export const ConfigureEventReview: React.FC<IConfigureEventReviewProps> = ({
  eventDetails,
  challenges,
  rationale,
  imageUrl,
  onEditClick,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <DetailHeader heading={t(stepTitle)} onEditClick={onEditClick} />
      <SpaceBetween size="l">
        <EventDetailReview
          summary={eventDetails.summary}
          topic={eventDetails.topic}
          teamSize={eventDetails.teamSize}
          minParticipants={eventDetails.minParticipants}
          maxParticipants={eventDetails.maxParticipants}
          tags={eventDetails.tags}
        />
        <EventImageReview imageUrl={imageUrl} />
        <ChallengeRationaleReview challenges={challenges} rationale={rationale} />
      </SpaceBetween>
    </div>
  );
};
