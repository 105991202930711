/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Header,
  Icon,
  Link,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../store/challenge.context';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import {
  hasAwsJamResourceWithoutRegion,
  hasValidCfnTemplateResource,
  parseCfnToJson,
} from '../../../../utils/cfn.utils';
import { getEnvVar } from '../../../../utils/env-var.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import { RoutePath } from '@/src/RoutePath';
import { KeyValue } from '@/src/components/common/KeyValue';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';

interface ChallengeCFNTemplateDetailProps {
  challenge: Challenge;
}

const ChallengeCFNTemplateDetail: React.FC<ChallengeCFNTemplateDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { getChallengeResources, getChallengeConfiguration, validateCfnTemplate } =
    useChallenges();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [templateScanResults, setTemplateScanResults] = useState('');
  const [disableValidation,setDisableValidation] = useState(true);
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const challengeResourceAccountName = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME');

  useEffect(() => {
    getChallengeConfiguration();
    void getChallengeResources(challenge.challengeId || '');
  }, []);

  useEffect(() => {
    setTemplateScanResults('');
  }, [editMode]);

  useEffect(() => {
    if(editedChallenge?.props.cfnTemplate?.length !==0){
    setDisableValidation(false);
    }
    else{
      setDisableValidation(true);
    }
  }, [editedChallenge?.props.cfnTemplate]);

  const [, setItems] = useState([
    {
      dismissible: true,
      dismissLabel: t(i18nKeys.challenges.subSections.cfnTemplate.dismissLabel),
      onDismiss: () => setItems([]),
      content: <>{t(i18nKeys.challenges.subSections.cfnTemplate.infoFlashbar)}</>,
    },
  ]);

  const handleFileUpload = (uploadedFile: File) => {
    // Handle file upload
    uploadedFile
      .text()
      .then((file) => {
        handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, file);
      })
      .catch((err) => preProdLogger(err));
  };

  const handleUpdateCfnTemplate = (template: string) => {
    if (editMode) {
      handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, template);
    }
  };

  const challengeResourceBucket = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME') || '';
  const cfnTemplateFileName = `aws-jam-${challenge.challengeId}-cfn-template.yaml`;

  const onValidateCFNTemplate = () => {
    if (editedChallenge) {
      validateCfnTemplate(editedChallenge.props.cfnTemplate || '')
        .then((results) => setTemplateScanResults(results))
        .catch((err) => preProdLogger(err));
    }
  };

  const hasInvalidCfnTemplateResource = () => {
    if (editedChallenge) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const cfnJson = parseCfnToJson(editedChallenge.props.cfnTemplate || '');
      return !hasValidCfnTemplateResource(cfnJson);
    }
  };

  const hasMissingResourceRegion = () => {
    if (editedChallenge) {
      return hasAwsJamResourceWithoutRegion(editedChallenge.props.cfnTemplate || '');
    }
  };
  const clickAnchorWithAttributes = (attributes: { [key: string]: string }): void => {
    const downloadAnchorNode = document.createElement('a');

    for (const [attrName, attrValue] of Object.entries(attributes)) {
      downloadAnchorNode.setAttribute(attrName, attrValue);
    }

    document.body.appendChild(downloadAnchorNode);

    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const downloadAsYAML = (content: string, filename: string, mimetype: string) => {
    clickAnchorWithAttributes({
      href: `data:${mimetype};charset=utf-8,${encodeURIComponent(content)}`,
      download: filename,
    });
  };

  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.CFN_TEMPLATE} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.cloudformationTemplate)}
        </Header>
      }>
      <Box margin={{bottom:"m"}}>
        <TextContent>
          {t(i18nKeys.challenges.subSections.cfnTemplate.infoFlashbar)}
        </TextContent>
      </Box>
      <SpaceBetween size="s">
      {editMode && <div
          style={{
            backgroundColor: awsui.colorBackgroundCellShaded,
            padding: '10px',
            borderRadius: '8px'
          }}>
          <KeyValue
            className="primary-text"
            label={<TextContent><p><strong>{t(i18nKeys.challenges.subSections.cfnTemplate.toolsAndResources)}</strong></p></TextContent>}>
            <SpaceBetween size="xs" direction="vertical" >
              <Button variant="link" id='challenge-cnftemplate-show-snippet' data-testid='challenge-cnftemplate-show-snippet' href={RoutePath.CHALLENGES_SNIPPETS} target='_blank'><Link external /> {t(i18nKeys.challenges.challengeDetails.buttons.showCfnSnippets)}</Button>
              <Button variant="link" id='challenge-cnftemplate-provide-ssh-access' data-testid='challenge-cnftemplate-provide-ssh-access' href={RoutePath.SSH_ACCESS} target='_blank'><Link external /> {t(i18nKeys.challenges.challengeDetails.buttons.provideSshAccess)}</Button>
              <Button variant="link" id='challenge-cnftemplate-using-challenge-resource' data-testid='challenge-cnftemplate-using-challenge-resource' href={`${RoutePath.CHALLENGE_RESOURCES}${challenge.id}`} target='_blank'><Link external /> {t(i18nKeys.challenges.challengeDetails.buttons.usingChallengeResources)}</Button>
            </SpaceBetween>
          </KeyValue>
        </div>}
        {editMode && (<TextContent>
                <p><strong>{t(i18nKeys.challenges.subSections.cfnTemplate.format.title)}{' '}</strong>{t(i18nKeys.challenges.subSections.cfnTemplate.format.part1)}{' '}<a href="https://github.com/awslabs/aws-cfn-template-flip">aws-cfn-template-flip</a>{t(i18nKeys.challenges.subSections.cfnTemplate.format.part2)}{' '}<a href="https://us-west-2.console.aws.amazon.com/cloudformation/designer/home?region=us-west-2">CloudFormation Template Designer</a>{t(i18nKeys.challenges.subSections.cfnTemplate.format.part3)}</p> 
              </TextContent>)}
        {editMode && (
          <Box padding={'l'} textAlign="center">
            <div
              style={{
                padding: '20px',
                border: '2px solid #AAB7B8',
                borderStyle: 'dashed',
              }}>
              <SpaceBetween size="s" direction="vertical" alignItems="center">
                <input
                  ref={hiddenFileInput}
                  id="chooseFileInput"
                  type="file"
                  hidden
                  multiple={false}
                  accept="application/yaml"
                  // @ts-ignore
                  onChange={(event) => handleFileUpload(event.target.files[0])}
                />
                <Button
                  id='challenge-cnftemplate-upload'
                  data-testid='challenge-cnftemplate-upload'
                  variant="link"
                  iconName="upload"
                  formAction="none"
                  onClick={() => {
                    // @ts-ignore
                    hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                    // @ts-ignore
                    hiddenFileInput.current.click();
                  }} />
                  <SpaceBetween size={'xxs'} direction="horizontal" alignItems="center">
                  <p>{t(i18nKeys.challenges.subSections.cfnTemplate.dropYamlFile)}</p>
                  <Link onFollow={() =>  {
                     // @ts-ignore
                     hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                     // @ts-ignore
                     hiddenFileInput.current.click();
                  }}>
                    {t(i18nKeys.challenges.subSections.cfnTemplate.browseFiles)}
                    </Link>
                  </SpaceBetween>
                {/* {editedChallenge?.hasCfnTemplate && (
                  <Button
                    iconName="status-negative"
                    formAction="none"
                    onClick={() => {
                      handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, null);
                    }}>
                    {t(i18nKeys.general.remove)}
                  </Button>
                )} */}
              </SpaceBetween>
            </div>
          </Box>
        )}
        {challenge.hasCfnTemplate && !editMode && (
          <div
          id="challenge-cfn-template-download"
              data-testid="challenge-cfn-template-download"
              onClick={() =>
                downloadAsYAML(challenge.props.cfnTemplate || '', cfnTemplateFileName + '.yaml', 'text/yaml')}
                >
            <Icon name="file" />
            <Link href="#" variant="primary">
              {cfnTemplateFileName}
            </Link>
          </div>
        )}
        {editMode && (
          <JamCodeEditor
            language="yaml"
            value={editedChallenge?.props.cfnTemplate || ''}
            setValue={(e) => {
              handleUpdateCfnTemplate(e);
            }}
          />
        )}
        {editMode && (
          <SpaceBetween size="l" direction="vertical" alignItems="end">
            <Button disabled={disableValidation} id='challenge-cnftemplate-validate-cfn-template' data-testid='challenge-cnftemplate-validate-cfn-template' iconName="status-positive" onClick={onValidateCFNTemplate}>
              {t(i18nKeys.challenges.challengeDetails.buttons.validateTemplate)}
            </Button>
          </SpaceBetween>
        )}
        {!editMode && (
          <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
            <JamCodeEditor
              language="yaml"
              value={challenge.props.cfnTemplate || ''}
              setValue={(e) => {
                handleUpdateCfnTemplate(e);
              }}
            />
          </div>
        )}
        {templateScanResults.length > 0 && editMode && (
          <ExpandableSection
            header={<Header>{t(i18nKeys.challenges.challengeDetails.headings.templateSecurityFindings)}</Header>}>
            <JamCodeEditor
              language="yaml"
              value={templateScanResults}
              setValue={() => {
                preProdLogger('none');
              }}
            />
          </ExpandableSection>
        )}

        {!editMode && challenge.hasCfnTemplate && (
          <div>
            {(hasMissingResourceRegion() || hasInvalidCfnTemplateResource()) && (
              <div style={{ fontSize: '1rem' }}>
                <div className="aws-panel aws-panel-info mb-3">
                  <span className="fa fa-info-circle" />
                  {hasMissingResourceRegion() && (
                    <Box>
                      {t(i18nKeys.challenges.challengeDetails.text.missingResourcePartOne)}{' '}
                      <strong>{challengeResourceAccountName}</strong>{' '}
                      {t(i18nKeys.challenges.challengeDetails.text.missingResourcePartTwo)}
                    </Box>
                  )}
                  {hasInvalidCfnTemplateResource() && (
                    <span style={{ display: hasMissingResourceRegion() ? 'block' : 'inline' }}>
                      {hasMissingResourceRegion() && <br />}
                      {t(i18nKeys.challenges.challengeDetails.text.nestedCfnTemplate)}
                      <strong>{`${challengeResourceBucket} ${t(
                        i18nKeys.challenges.challengeDetails.text.bucket
                      )}`}</strong>
                      .
                    </span>
                  )}

                  <div>
                    <br />
                    {t(i18nKeys.challenges.challengeDetails.text.mayDelayApproval)}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </SpaceBetween>
    </Container>
  );
};

export default ChallengeCFNTemplateDetail;
