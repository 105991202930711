import React, { useEffect, useState } from 'react';
import { Cards, Checkbox, Container, ExpandableSection, Grid, Header } from '@amzn/awsui-components-react';
import { Event } from '../../../../types/Event';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../../store/user.context';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { EventTypes } from '../EventDetailsModel';

interface DetailsEventTypeProps {
  target: Event | undefined;
}

const DetailsEventType: React.FC<DetailsEventTypeProps> = ({ target }) => {
  const { editMode, newEventMode, isGuestUserAndEditModeEnabled, handleUpdateEditEvent } = useEditEvent();
  const { t } = useTranslation();
  const { user } = useUser();
  const [testEventPublicChecked, setTestEventPublicChecked] = useState(false);
  const [lockTestRadio, setLockTestRadio] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState<
    { name: string; description: string; value: EventTypes; footer?: JSX.Element }[]
  >([]);

  const canEdit = editMode || newEventMode;

  const eventTypes = {
    liveEvent: {
      name: t(i18nKeys.events.eventDetails.headers.eventType.liveEvent),
      description: t(i18nKeys.events.eventDetails.headers.eventType.liveEventDescription),
      value: EventTypes.LIVE,
      footer: undefined,
    },
    testEvent: {
      name: t(i18nKeys.events.eventDetails.headers.eventType.testEvent),
      description: t(i18nKeys.events.eventDetails.headers.eventType.testEventDescription),
      value: EventTypes.TEST,
      footer:
        (user && (
          <Checkbox
            onChange={({ detail }) => {
              handleUpdateEditEvent(EditEventActions.TEST_EVENT_PUBLIC, detail.checked);
              setTestEventPublicChecked(detail.checked);
            }}
            checked={testEventPublicChecked}
            disabled={lockTestRadio || !target?.canEditAttribute('test', user)}
            description={t(i18nKeys.events.eventDetails.labels.eventType.description)}>
            {t(i18nKeys.events.eventDetails.labels.eventType.allowAccess)}
          </Checkbox>
        )) ||
        undefined,
    },
  };

  useEffect(() => {
    if (target?.test) {
      setSelectedEventType([eventTypes.testEvent]);
    } else {
      setSelectedEventType([eventTypes.liveEvent]);
    }
    if (!target?.testEventPublic) {
      setTestEventPublicChecked(true);
    }
    if (target?.approved) {
      setLockTestRadio(true);
    }
  }, [editMode, newEventMode]);

  const handleSelectedItems = (
    itemSelected: {
      name: string;
      description: string;
      value: EventTypes;
      footer?: JSX.Element;
    }[]
  ) => {
    if (itemSelected[0].value === eventTypes.testEvent.value) {
      handleUpdateEditEvent(EditEventActions.TEST_EVENT_PUBLIC, false);
      setTestEventPublicChecked(true);
    }
    setSelectedEventType([itemSelected[0]]);
    handleUpdateEditEvent(EditEventActions.TEST, itemSelected[0].value === eventTypes.testEvent.value ? true : false);
  };

  const renderContent = () => {
    return canEdit && user ? (
      <Cards
        selectionType="single"
        trackBy="value"
        cardsPerRow={[{ cards: 2 }]}
        ariaLabels={{
          itemSelectionLabel: (e, n) => `select ${n.name}`,
          selectionGroupLabel: t(i18nKeys.events.eventDetails.labels.eventTypesSelection)
        }}
        selectedItems={selectedEventType}
        isItemDisabled={() =>
          lockTestRadio || !target?.canEditAttribute('test', user) || false || !!isGuestUserAndEditModeEnabled
        }
        onSelectionChange={({ detail }) => handleSelectedItems(detail.selectedItems)}
        cardDefinition={{
          header: (e) => <b>{e?.name}</b>,
          sections: [
            {
              id: 'description',
              content: (e) => e?.description,
            },
            {
              id: 'footer',
              content: (e) => e?.footer,
            },
          ],
        }}
        items={[eventTypes.liveEvent, eventTypes.testEvent]}
        data-testid="eventTypeSelection"
      />
    ) : (
      !canEdit && (
        <React.Fragment>
          <div className="section-first-row">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
              <div>{t(i18nKeys.events.eventDetails.headers.eventType.header)}</div>
              <div>
                {target?.test
                  ? t(i18nKeys.events.eventDetails.headers.eventType.testEvent)
                  : t(i18nKeys.events.eventDetails.headers.eventType.liveEvent)}
              </div>
            </Grid>
          </div>
          {target?.test && (
            <div className="grey-section-divider-top">
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                <div>{t(i18nKeys.events.eventDetails.labels.eventType.allowAccess)}</div>
                <div>{!target?.testEventPublic ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
              </Grid>
            </div>
          )}
        </React.Fragment>
      )
    );
  };

  // TODO: Add number of days before testing logic/input
  return !newEventMode ? (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventType.header)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventType.header)}</Header>}>
      {renderContent()}
    </Container>
  );
};

export default DetailsEventType;
