import React from 'react'
import { CollectionPreferencesProps, TableProps } from "@amzn/awsui-components-react";
import { Link } from "react-router-dom";
import { TinyEvent } from '@/src/types/Event';
import { EVENT_DETAILS_ROUTES } from '@/src/routes';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';



export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_COLLECTION_PREFERENCES = {
  pageSize: DEFAULT_PAGE_SIZE, 
  visibleContent: ['title', 'name'] 
}

export type LabEventsCollectionPreferences = typeof DEFAULT_COLLECTION_PREFERENCES

export const PROPERTY_FILTER_PROPERTIES: readonly PropertyFilterProperty[] =  [
  {
    key: "title",
    operators: ["="],
    propertyLabel: "Event Title",
    groupValuesLabel: ""
  },
  {
    key: "name",
    operators: ["="],
    propertyLabel: "Event ID",
    groupValuesLabel: "Event ID",
  }
]

export const COLUMN_DEFINITIONS:TableProps<TinyEvent>['columnDefinitions'] =  [
  {
    id: "title",
    header: "Event Title",
    cell: item => <Link to={EVENT_DETAILS_ROUTES.Summary.resolve(item.name)}>{item.title}</Link>,
    sortingField: "title",
  },
  {
    id: "name",
    header: "Event ID",
    cell: item => item.name,
    sortingField: "name"
  },
]

export const PAGE_SIZE_PREFERENCES: CollectionPreferencesProps.PageSizePreference  = {
  title: "Page size",
  options: [
    { value: 10, label: "10 events" },
    { value: 20, label: "20 events" }
  ]
}

const VISIBLE_CONTENT_PREFERENCE:CollectionPreferencesProps['visibleContentPreference']  = {
  title: 'Select visible content',
  options: [
    {
      label: 'Main properties',
      options: COLUMN_DEFINITIONS.map(({ id, header }) => ({ id: id ?? '', label: header as string, editable: id !== 'id' })),
    },
  ],
};

export const COLECTION_PREFERENCES_PROPS: CollectionPreferencesProps = {
  pageSizePreference: PAGE_SIZE_PREFERENCES,
  visibleContentPreference: VISIBLE_CONTENT_PREFERENCE,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
}