import * as React from 'react';
import { DeploymentStatisticListItem } from '../../../../../types/Challenge';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../../utils/table.utils';
import { TFunctionProvider } from '../../../../common/TFunctionProvider';

export const filteringFunction = (item: DeploymentStatisticListItem, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return matcher.filterEmpty || matcher.isMatch(item?.region);
};

export const COLUMN_DEFINITIONS = [
  {
    id: 'region',
    header: <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.region)}</TFunctionProvider>,
    cell: (item: DeploymentStatisticListItem) => (item ? item.region : '-'),
    sortingField: 'region',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'successfulLabs',
    header: (
      <TFunctionProvider>
        {(t) => t(i18nKeys.challenges.challengeDetails.table.titles.successfulLabs)}
      </TFunctionProvider>
    ),
    cell: (item: DeploymentStatisticListItem) => (item ? item.successfulLabs : '-'),
    sortingField: 'successfulLabs',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'totalLabs',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.totalLabs)}</TFunctionProvider>
    ),
    cell: (item: DeploymentStatisticListItem) => (item ? item.totalLabs : '-'),
    sortingField: 'totalLabs',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'percentLabsSuccessful',
    header: (
      <TFunctionProvider>
        {(t) => t(i18nKeys.challenges.challengeDetails.table.titles.percentLabsSuccessful)}
      </TFunctionProvider>
    ),
    cell: (item: DeploymentStatisticListItem) => (item ? item.precentLabsSuccessful : '-'),
    sortingField: 'percentLabsSuccessful',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'stackDeployTime',
    header: (
      <TFunctionProvider>
        {(t) => t(i18nKeys.challenges.challengeDetails.table.titles.stackDeployTime)}
      </TFunctionProvider>
    ),
    cell: (item: DeploymentStatisticListItem) => (item ? item.stackDeployTime : '-'),
    sortingField: 'stackDeployTime',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'labVendTime',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.labVendTime)}</TFunctionProvider>
    ),
    cell: (item: DeploymentStatisticListItem) => (item ? item.labVendTime : '-'),
    sortingField: 'labVendTime',
    minWidth: 100,
    allowLineWrap: true,
  },
];
