import { useCollection } from '@amzn/awsui-collection-hooks';
import { Pagination, Table, TextFilter } from '@amzn/awsui-components-react';
import { pick } from 'lodash';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { useChallenges } from '../../../store/challenge.context';
import { UserPreferenceKeys } from '../../../store/user.context';
import { Challenge, ChallengeWrapper } from '../../../types/Challenge';
import { StackEvent } from '../../../types/cloud-formation';
import { Lab, LabCloudFormationDetails, LabSignInDetails } from '../../../types/LabModels';
import { LabProvider } from '../../../types/LabProvider';
import { getFailedStackEvents } from '../../../utils/cfn.utils';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { paginationLabels } from '../../../utils/table.utils';
import AwsAccountCLIModal from '../Challenges/AwsAccountCLIModal';
import { ConfirmModal } from '../ConfirmModal';
import { DisplayHTMLModal } from '../DisplayHTMLModal';
import { TableEmptyState } from '../TableEmptyState';
import { COLUMN_DEFINITIONS, filteringFunction } from './lab-account-table-list-config';

interface LabAccountTableProps {
  labs: Lab[];
  filterable: boolean;
  showLabProviderStatus: boolean;
  showChallenge: boolean;
  paginate: boolean;
}

const LabAccountTable: React.FC<LabAccountTableProps> = ({
  labs,
  filterable = true,
  showLabProviderStatus = true,
  showChallenge = true,
  paginate = true,
}) => {
  const { t } = useTranslation();
  const { eventsApi } = useApi();
  const { challengeWrapperMap } = useChallenges();

  const [preferences, setPreferences] = useState({
    pageSize: 10,
    visibleColumns: ['challenge', 'master', 'status', 'extStatus', 'assignedTeam', 'moreOptions'],
  });

  const [currentLab, setCurrentLab] = useState<Lab>();
  const [teamSignInDetails, setTeamSignInDetails] = useState<LabSignInDetails | undefined>();
  const [adminSignInDetails, setAdminSignInDetails] = useState<LabSignInDetails | undefined>();
  const [masterSignInDetails, setMasterSignInDetails] = useState<LabSignInDetails | undefined>();

  // modal state
  const [visibleStatusHistory, setVisibleStatusHistory] = useState(false);
  const [visibleError, setVisibleError] = useState(false);
  const [visibleMetadata, setVisibleMetadata] = useState(false);
  const [visibleAccountStackDetails, setVisibleAccountStackDetails] = useState(false);
  const [visibleConfirmUnassignAccount, setVisibleConfirmUnassignAccount] = useState(false);
  const [visibleReasonUnassignAccount, setVisibleReasonUnassignAccount] = useState(false);
  const [visibleConfirmTerminateAccount, setVisibleTerminateAccount] = useState(false);
  const [visibleReasonTerminateAccount, setVisibleReasonTerminateAccount] = useState(false);

  const [visibleTeamSignInDetails, setVisibleTeamSignInDetails] = useState(false);
  const [visibleAdminSignInDetails, setVisibleAdminSignInDetails] = useState(false);
  const [visibleMasterSignInDetails, setVisibleMasterSignInDetails] = useState(false);

  // modal messagaes
  const [statusHistoryMessage, setStatusHistoryMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [unassignAccountReason, setUnassignAccountReason] = useState('admin unassigned team from account');
  const [terminateAccountMessage, setTerminateAccountMessage] = useState('');
  const [terminateAccountReason, setTerminateAccountReason] = useState('');
  const [metadataMessage, setMetadataMessage] = useState('');
  const [accountStackTitle, setAccountStackTitle] = useState('');
  const [accountStackMessage, setAccountStackMessage] = useState('');

  useEffect(() => {
    loadPreferences();
  }, []);

  const loadPreferences = () => {
    const pageSize = Number(sessionStorage.getItem(UserPreferenceKeys.PAGE_SIZE));

    const visibleColumns = preferences.visibleColumns.filter((col) => {
      if (!showLabProviderStatus && col === 'extStatus') {
        return false;
      }
      return !(!showChallenge && col === 'challenge');
    });

    setPreferences({ pageSize, visibleColumns });
  };

  const { actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(labs, {
    filtering: {
      filteringFunction,
      empty: (
        <TableEmptyState
          title={t(i18nKeys.lab.table.titles.emptyLabState)}
          subtitle={t(i18nKeys.lab.table.titles.emptyLabSubtitle)}
        />
      ),
      noMatch: (
        <TableEmptyState
          title={t(i18nKeys.tables.noMatch.title)}
          subtitle={t(i18nKeys.tables.noMatch.subtitle)}
          onClearFilter={() => actions.setFiltering('')}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  const getTimeFormat = (time: Moment, now: Moment) => {
    if (time.year() === now.year()) {
      return 'MMM Do h:mm A';
    }
    return 'MMM Do YYYY';
  };

  const formatDate = (dateString: string) => {
    const time = moment(dateString);
    return time.format(getTimeFormat(time, moment()));
  };

  const toggleStatusHistory = (lab: Lab) => {
    const message = lab.statusHistory
      .map((item) => {
        const time = formatDate(moment(item.time).format());
        return `[${time}] - ${item.status} - ${item.details}`;
      })
      .join('\n');

    setStatusHistoryMessage(message);
    setVisibleStatusHistory(true);
  };

  const toggleResourceDeploymentHistory = (lab: Lab) => {
    eventsApi
      .getLabResourceDeploymentHistory(lab)
      .then((resourceDeploymentItems) => lab.applyResourceTaskHistory(resourceDeploymentItems))
      .catch((err) => preProdLogger(err));

    // TODO: Create Lab Resource Deployment History Component Modal
  };

  const toggleError = (lab: Lab) => {
    let error = lab.error && lab.error.replace(/\\"/g, '"').replace(/"{/g, '{').replace(/}"/g, '}');

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = JSON.parse(error || '');
      error = JSON.stringify(error, null, 4);
    } catch (e) {
      // ignore
      preProdLogger(e);
    }

    const message = `${t(i18nKeys.lab.table.labels.awsAccountNumber)}: ${lab.awsAccountNumber}
      ${t(i18nKeys.lab.table.labels.sessionId)}: ${lab.sessionId}
      ${t(i18nKeys.lab.table.labels.labId)}: ${lab.id}
      ${t(i18nKeys.lab.table.labels.error)}: ${error}`;

    setErrorMessage(message);
    setVisibleError(true);
  };

  const toggleUnassignAccount = (lab: Lab) => {
    setCurrentLab(lab);
    setVisibleConfirmUnassignAccount(true);
  };

  const handleUnassignAccount = () => {
    setVisibleConfirmUnassignAccount(false);
    if (currentLab) {
      void eventsApi.teamRestart(currentLab, unassignAccountReason);
    }
    setVisibleReasonUnassignAccount(false);
  };

  const toggleTerminateLab = (lab: Lab) => {
    let message = t(i18nKeys.modal.message.terminateAccount);

    if (lab.team) {
      message += t(i18nKeys.modal.message.terminateAccountTeam);
    }

    setTerminateAccountMessage(message);
    setVisibleTerminateAccount(true);
  };

  const handleTerminateAccount = () => {
    setVisibleTerminateAccount(false);
    if (currentLab) {
      void eventsApi.terminateLab(currentLab, terminateAccountReason);
    }
    setVisibleConfirmUnassignAccount(false);
  };

  const getMetadata = async (lab: Lab): Promise<{ [key: string]: string }> => {
    const metadata = await eventsApi.getLabMetadata(lab);
    return Object.assign({}, metadata, pick(lab, ['holdUntil', 'master']));
  };

  const toggleMetadata = (lab: Lab) => {
    const note =
      lab.labProvider === LabProvider.EVENT_ENGINE
        ? 'NOTE: The `labId` attribute is the EventEngine Team ID, and the `deploymentSessionId` attribute is the EventEngine Event ID\n\n'
        : '';

    getMetadata(lab)
      .then((metadata) => setMetadataMessage(note + JSON.stringify(metadata, null, 4)))
      .then(() => setVisibleMetadata(true))
      .catch((err) => preProdLogger(err));
  };

  const viewCFNStackInfo = (lab: Lab, cfnStackDetails: LabCloudFormationDetails) => {
    const challengeWrapper: ChallengeWrapper | null = challengeWrapperMap[lab.challengeId || ''];
    const challenge: Challenge | null = challengeWrapper?.latest;
    try {
      if (!cfnStackDetails) {
        cfnStackDetails = {
          awsAccountNumber: lab.awsAccountNumber,
          labId: lab.id,
          stacks: [],
          stackEvents: [],
        };
      }

      let title = `${t(i18nKeys.lab.table.titles.cfnDetails)}: [${t(i18nKeys.lab.table.labels.awsAccountNumber)}: ${
        lab.awsAccountNumber
      }]`;
      if (challenge) {
        title += ` [${t(i18nKeys.lab.table.titles.challenge)}: ${challenge.challengeId}]`;
      }

      const cfnDetails = JSON.stringify(cfnStackDetails, null, 2);
      let content = `<h3>${t(i18nKeys.lab.table.titles.fullDetails)}</h3><pre>${cfnDetails}</pre>`;

      const failedEvents: StackEvent[] = getFailedStackEvents(cfnStackDetails.stackEvents);
      if (failedEvents.length > 0) {
        const failedEventsContent = JSON.stringify(failedEvents, null, 2);
        content = `<h3>${t(
          i18nKeys.lab.table.titles.mainFailedEvents
        )}</h3><pre>${failedEventsContent}</pre>${content}`;
      }

      setAccountStackTitle(title);
      setAccountStackMessage(content);
      setVisibleAccountStackDetails(true);
    } catch (e) {
      preProdLogger(e);
    }
  };

  const toggleAccountStackDetails = (lab: Lab) => {
    eventsApi
      .getChallengeLabCfnDetails(lab)
      .then((stackDetails) => {
        if (stackDetails) {
          viewCFNStackInfo(lab, stackDetails);
        } else {
          preProdLogger('Failed to get stack details');
        }
      })
      .catch((err) => preProdLogger(err));
  };

  const handleTeamSignInUrl = (lab: Lab, fetch = true) => {
    eventsApi
      .getChallengeLabAdminSignIn(lab, fetch)
      .then((signInDetails) => {
        if (signInDetails) {
          setTeamSignInDetails(signInDetails);
          setVisibleTeamSignInDetails(true);
        }
      })
      .catch((err) => preProdLogger(err));
  };

  const handleAdminSignInUrl = (lab: Lab, fetch = true) => {
    eventsApi
      .getChallengeLabAdminSignIn(lab, fetch)
      .then((signInDetails) => {
        if (signInDetails) {
          setAdminSignInDetails(signInDetails);
          setVisibleAdminSignInDetails(true);
        }
      })
      .catch((err) => preProdLogger(err));
  };

  const handleMasterSignInUrl = (lab: Lab, fetch = true) => {
    eventsApi
      .getChallengeLabAdminSignIn(lab, fetch)
      .then((signInDetails) => {
        if (signInDetails) {
          setMasterSignInDetails(signInDetails);
          setVisibleMasterSignInDetails(true);
        }
      })
      .catch((err) => preProdLogger(err));
  };

  return (
    <React.Fragment>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS(
          toggleStatusHistory,
          toggleResourceDeploymentHistory,
          toggleError,
          toggleUnassignAccount,
          toggleTerminateLab,
          toggleMetadata,
          toggleAccountStackDetails,
          handleTeamSignInUrl,
          handleAdminSignInUrl,
          handleMasterSignInUrl
        )}
        items={labs}
        loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingIssues)}
        pagination={paginate ? <Pagination {...paginationProps} ariaLabels={paginationLabels(t)} /> : undefined}
        filter={
          filterable ? (
            <TextFilter
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.challenges.filteringLabel)}
            />
          ) : undefined
        }
        resizableColumns
        visibleColumns={preferences.visibleColumns}
      />
      <ConfirmModal
        title={t(i18nKeys.modal.title.labStatusHistory)}
        message={statusHistoryMessage}
        visible={visibleStatusHistory}
        confirmBtnLabel={t(i18nKeys.general.close)}
        onConfirm={() => setVisibleStatusHistory(false)}
        onCancel={() => setVisibleStatusHistory(false)}
      />
      <ConfirmModal
        title={t(i18nKeys.modal.title.labError)}
        message={errorMessage}
        visible={visibleError}
        confirmBtnLabel={t(i18nKeys.general.close)}
        onConfirm={() => setVisibleError(false)}
        onCancel={() => setVisibleError(false)}
      />
      <ConfirmModal
        message={t(i18nKeys.modal.message.unassignAccount)}
        visible={visibleConfirmUnassignAccount}
        onConfirm={() => setVisibleReasonUnassignAccount(true)}
        onCancel={() => setVisibleConfirmUnassignAccount(false)}
      />
      <ConfirmModal
        message={t(i18nKeys.modal.message.unassignAccountReason)}
        visible={visibleReasonUnassignAccount}
        textInput={unassignAccountReason}
        onTextInput={setUnassignAccountReason}
        onConfirm={handleUnassignAccount}
        onCancel={() => setVisibleReasonUnassignAccount(false)}
      />
      <ConfirmModal
        message={terminateAccountMessage}
        visible={visibleConfirmTerminateAccount}
        onConfirm={handleTerminateAccount}
        onCancel={() => setVisibleTerminateAccount(false)}
      />
      <ConfirmModal
        message={t(i18nKeys.modal.message.terminateAccountReason)}
        visible={visibleReasonTerminateAccount}
        textInput={terminateAccountReason}
        onTextInput={setTerminateAccountReason}
        onConfirm={handleTerminateAccount}
        onCancel={() => setVisibleReasonTerminateAccount(false)}
      />
      <ConfirmModal
        title={t(i18nKeys.modal.title.labAccountMetadata)}
        message={metadataMessage}
        visible={visibleMetadata}
        confirmBtnLabel={t(i18nKeys.general.close)}
        onConfirm={() => setVisibleMetadata(false)}
        onCancel={() => setVisibleMetadata(false)}
      />
      <DisplayHTMLModal
        title={accountStackTitle}
        message={accountStackMessage}
        visible={visibleAccountStackDetails}
        onClose={() => setVisibleAccountStackDetails(false)}
        onCancel={() => setVisibleAccountStackDetails(false)}
      />
      {teamSignInDetails && (
        <AwsAccountCLIModal
          visible={visibleTeamSignInDetails}
          onCancel={() => setVisibleTeamSignInDetails(false)}
          onConfirm={() => setVisibleTeamSignInDetails(false)}
          credentials={teamSignInDetails.credentials}
        />
      )}
      {adminSignInDetails && (
        <AwsAccountCLIModal
          visible={visibleAdminSignInDetails}
          onCancel={() => setVisibleAdminSignInDetails(false)}
          onConfirm={() => setVisibleAdminSignInDetails(false)}
          credentials={adminSignInDetails.credentials}
        />
      )}
      {masterSignInDetails && (
        <AwsAccountCLIModal
          visible={visibleMasterSignInDetails}
          onCancel={() => setVisibleMasterSignInDetails(false)}
          onConfirm={() => setVisibleMasterSignInDetails(false)}
          credentials={masterSignInDetails.credentials}
        />
      )}
    </React.Fragment>
  );
};

export default LabAccountTable;
