import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useChallenges } from '../../../store/challenge.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { Event } from '../../../types/Event';
import ChallengeCard from './ChallengeCard';
import GeneralBackupChallenges from './GeneralBackupChallenges';

interface BackupChallengesProps {
  target: Event;
}

const BackupChallenges: React.FC<BackupChallengesProps> = ({ target }) => {
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const { toggleChallengeInfo } = useToolPanel();

  return (
    <SpaceBetween direction="vertical" size="s">
      <GeneralBackupChallenges target={target} />
      {target.challengeDescriptors.map((cd: ChallengeDescriptor, i) => {
        if (cd.challengeId) {
          const challengeListItem = getChallengeListItemFromChallengeId(cd.challengeId);
          if (challengeListItem) {
            return (
              <ChallengeCard
                key={`challenge-card-${i}`}
                toggleChallengeInfo={toggleChallengeInfo}
                index={i}
                target={target}
                challenge={challengeListItem}
                backupChallenges
              />
            );
          }
        }
      })}
    </SpaceBetween>
  );
};
export default BackupChallenges;
