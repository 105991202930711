import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UsagePlan } from '../../../types/usage-plan/UsagePlan';
import { Button, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { usePlans } from '../../../store/usage-plan/usage-plan.context';
import CreateUsagePlanDetails from '../create-usage-plan/CreateUsagePlanDetails';
import CreateUsagePlanRequestDetails from '../create-usage-plan/CreateUsagePlanRequestDetails';
import CreateUsagePlanParticipant from '../create-usage-plan/CreateUsagePlanParticipant';
import CreateUsagePlanChallenge from '../create-usage-plan/CreateUsagePlanChallenge';
import CreateUsagePlanOptions from '../create-usage-plan/CreateUsagePlanOptions';
import UsagePlanDetailsOverview from './UsagePlanDetailsOverview';
import { useUser } from '../../../store/user.context';
import { useEffect, useState } from 'react';

interface UsagePlanDetailsSummaryProps {
  usagePlan: UsagePlan | undefined;
  canEdit?: boolean;
}

const UsagePlanDetailsSummary: React.FC<UsagePlanDetailsSummaryProps> = ({ usagePlan, canEdit = false }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const {
    editMode,
    setEditMode,
    updateUsagePlan,
    getPlanDetails,
    updateAllowlist,
    editedUsagePlan,
    toggleEditMode,
    initializeCreateEditPlan,
    isValidUsagePlan,
  } = usePlans();
  const [errorText, setErrorText] = useState('');

  const handleToggleEdit = () => {
    if (usagePlan) {
      initializeCreateEditPlan(usagePlan);
    }
    toggleEditMode();
  };

  const onSave = () => {
    if (usagePlan?.isMaintainer(user)) {
      updateAllowlist(editedUsagePlan?.getAllowlist() ?? [], editedUsagePlan?.id ?? '');
    } else {
      if (isValidUsagePlan(editedUsagePlan)) {
        updateUsagePlan(editedUsagePlan);
        setErrorText('');
        toggleEditMode();
        getPlanDetails(usagePlan?.id ?? '');
      } else {
        setErrorText(t(i18nKeys.usagePlan.invalidField));
      }
    }
  };

  // clean up on unmount
  useEffect(() => {
    return () => {
      setEditMode(false);
      initializeCreateEditPlan();
    };
  }, []);

  return (
    <div>
      {editMode && canEdit && (
        <form onSubmit={(e) => e.preventDefault()}>
          <Form
            errorText={errorText}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button formAction="none" onClick={handleToggleEdit} variant="link">
                  {t(i18nKeys.general.cancel)}{' '}
                </Button>
                <Button formAction="submit" variant="primary" onClick={onSave}>
                  {t(i18nKeys.general.save)}
                </Button>
              </SpaceBetween>
            }
            header={<Header variant="h1">{t(i18nKeys.general.edit)}</Header>}>
            <SpaceBetween direction="vertical" size="m">
              {user && user.isSuperAdmin && (
                <>
                  <CreateUsagePlanDetails target={editedUsagePlan} errorText={errorText} />
                  <CreateUsagePlanRequestDetails target={editedUsagePlan} errorText={errorText} />
                  <CreateUsagePlanParticipant target={editedUsagePlan} errorText={errorText} />
                  <CreateUsagePlanChallenge target={editedUsagePlan} errorText={errorText} />
                </>
              )}
              <CreateUsagePlanOptions target={editedUsagePlan} errorText={errorText} />
            </SpaceBetween>
          </Form>
        </form>
      )}

      {!editMode && (
        <UsagePlanDetailsOverview usagePlan={usagePlan} handleToggleEdit={canEdit ? handleToggleEdit : undefined} />
      )}
    </div>
  );
};

export default UsagePlanDetailsSummary;
