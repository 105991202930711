import { Alert, Button, Container, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { useEditCampaign } from '../../../store/edit-campaign.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { Campaign } from '../../../types/Campaign';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { i18nKeys } from '../../../utils/i18n.utils';
import { toTitleCase } from '../../../utils/string.utils';
import { HorizontalRule } from '../../common/HorizontalRule';
import { KeyValue } from '../../common/KeyValue';
import ReviewRow from '../../events/newEventSteps/ReviewRow';

interface NewCampaignReviewProps {
  target: Campaign | null;
  navigate: (step: number) => void;
}

const NewCampaignReview: React.FC<NewCampaignReviewProps> = ({ target, navigate }) => {
  const { t } = useTranslation();
  const { newCampaignImportSources } = useEditCampaign();
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const { toggleChallengeInfo } = useToolPanel();

  return (
    <React.Fragment>
      {target && (
        <SpaceBetween direction="vertical" size="s">
          <Container
            header={
              <Header variant="h2" actions={<Button onClick={() => navigate(0)}>{t(i18nKeys.general.edit)}</Button>}>
                {t(i18nKeys.campaigns.newCampaign.steps.campaignDetails.title)}
              </Header>
            }>
            <ReviewRow
              label={t(i18nKeys.campaigns.newCampaign.steps.campaignDetails.campaignTitle)}
              value={target.title}
            />
            <HorizontalRule evenMargins />
            <ReviewRow
              label={t(i18nKeys.campaigns.newCampaign.steps.campaignDetails.usagePlan)}
              value={target.usagePlanId || t(i18nKeys.general.nA)}
            />
            <HorizontalRule evenMargins />
            <ReviewRow
              label={t(i18nKeys.campaigns.newCampaign.steps.campaignDetails.participantLimit)}
              value={t(i18nKeys.campaigns.labels.campaignDetails.participantsLimitMessage, {
                participantLimit: target.campaignSettings.maxExpectedParticipants,
              })}
            />
          </Container>
          <Container
            header={
              <Header variant="h2" actions={<Button onClick={() => navigate(1)}>{t(i18nKeys.general.edit)}</Button>}>
                {t(i18nKeys.campaigns.newCampaign.steps.permissionsAndReporting.title)}
              </Header>
            }>
            {target?.campaignSettings.eventPermissions.map((eventPermission, key) => {
              return (
                <React.Fragment key={`event-permission-${key}`}>
                  <ReviewRow label={eventPermission.email} value={toTitleCase(eventPermission.eventPermissionType)} />
                  <HorizontalRule evenMargins />
                </React.Fragment>
              );
            })}
            <ReviewRow
              label={t(i18nKeys.campaigns.newCampaign.steps.permissionsAndReporting.reportRecipients)}
              value={target.campaignSettings.reportRecipients.join(', ') || t(i18nKeys.general.nA)}
            />
          </Container>
          <Container
            header={
              <Header variant="h2" actions={<Button onClick={() => navigate(2)}>{t(i18nKeys.general.edit)}</Button>}>
                {t(i18nKeys.campaigns.newCampaign.steps.challenges.title)}
              </Header>
            }>
            {(newCampaignImportSources.challengeSet || newCampaignImportSources.event) && (
              <Alert className="mb-8" type="info">
                {t(i18nKeys.challenges.importChallenges.fields.challengeSets.campaignWarning)}
              </Alert>
            )}
            {newCampaignImportSources.challengeSet && (
              <KeyValue label={<strong>{t(i18nKeys.newEvent.labels.importedChallengeSet)}</strong>}>
                <div>{newCampaignImportSources.challengeSet.title}</div>
                <div className="secondary-text">
                  {t(i18nKeys.newEvent.labels.challengeCount, {
                    challengeCount: newCampaignImportSources.challengeSet.challengeIds.length,
                  })}
                </div>
              </KeyValue>
            )}
            {newCampaignImportSources.event && (
              <KeyValue label={<strong>{t(i18nKeys.newEvent.labels.importedEventChallenges)}</strong>}>
                <div>{newCampaignImportSources.event.title}</div>
                <div className="secondary-text">
                  {t(i18nKeys.newEvent.labels.challengeCount, {
                    challengeCount: newCampaignImportSources.event.challengeDescriptors.length,
                  })}
                </div>
              </KeyValue>
            )}
            {target.challengeDescriptors.length > 0 && (
              <KeyValue
                label={
                  <strong>
                    {t(i18nKeys.newEvent.labels.selectedChallenges, {
                      challengeCount: target.challengeDescriptors.length,
                    })}
                  </strong>
                }>
                <SpaceBetween direction="vertical" size="s">
                  {target.challengeDescriptors.map((cd: ChallengeDescriptor, i) => {
                    const challengeListItem = getChallengeListItemFromChallengeId(cd.challengeId || '');
                    if (challengeListItem) {
                      return (
                        <div key={challengeListItem.challengeId}>
                          <strong>{i + 1}. </strong>
                          <Link
                            onFollow={() => toggleChallengeInfo(challengeListItem)}
                            key={`challenge-list-item-${i}`}>
                            {challengeListItem?.props.title}
                          </Link>
                          <span> ({challengeListItem.challengeId})</span>
                        </div>
                      );
                    }
                  })}
                </SpaceBetween>
              </KeyValue>
            )}
          </Container>
          <Container
            header={
              <Header variant="h2" actions={<Button onClick={() => navigate(3)}>{t(i18nKeys.general.edit)}</Button>}>
                {t(i18nKeys.campaigns.newCampaign.steps.settings.title)}
              </Header>
            }>
            <ReviewRow label={t(i18nKeys.campaigns.newCampaign.steps.settings.campaignType)} value={target.type} />
            <HorizontalRule evenMargins />
            <ReviewRow
              label={t(i18nKeys.campaigns.newCampaign.steps.settings.clues)}
              value={target.campaignSettings.cluesAllowed ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}
            />
            <HorizontalRule evenMargins />
            <ReviewRow
              label={t(i18nKeys.campaigns.newCampaign.steps.settings.timeLimit)}
              value={target.campaignSettings.sessionDurationLimitHours}
            />
            <HorizontalRule evenMargins />
            <ReviewRow
              label={t(i18nKeys.campaigns.newCampaign.steps.settings.passingScore)}
              value={target.campaignSettings.passScorePercent}
            />
            <HorizontalRule evenMargins />
            <ReviewRow
              label={t(i18nKeys.campaigns.newCampaign.steps.settings.numberOfAttempts)}
              value={target.campaignSettings.allowedAttempts}
            />
          </Container>
        </SpaceBetween>
      )}
    </React.Fragment>
  );
};

export default NewCampaignReview;
