/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from "i18next";
import { GAME_API_PREFIX } from "../constants/api";
import { ApiClient } from "./ApiClient";
import { ParticipantProfile } from "../types/ParticipantProfile";


export class ParticipantAPI {
   
    constructor(private apiClient: ApiClient, private t: TFunction) {
      // do nothing
    }

    public async getParticipantProfile(): Promise<ParticipantProfile> {
        return (await this.apiClient.get({
          path: `${GAME_API_PREFIX}/participant/profile`,
          errorHandled: true,
          responseMapper: (res) => res.profile as ParticipantProfile,
        })) as Promise<ParticipantProfile>;
      }
}