import { Box, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../store/api.context';
import {
  Challenge,
  ChallengeFeedback,
  ChallengeFeedbackSortOptions,
  ChallengeFeedbackSortType,
  ChallengeFeedbackSummary,
} from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { safeString } from '../../../../utils/string.utils';
import { Columns } from '../../../common/Columns';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { PageHeader } from '../../../common/PageHeader';
import { FeedbackItem } from './feedbackItems/FeedbackItem';
import FeedbackProgress from '@/src/components/events/eventDetailsSections/Feedback/FeedbackBlocks/FeedbackProgress';
import FeedbackLearningOutcome from '@/src/components/events/eventDetailsSections/Feedback/FeedbackBlocks/FeedbackLearningOutcome';

const ROWS_PER_PAGE = 40;

interface ChallengeReviewsDetailProps {
  challenge: Challenge;
}

const ChallengeReviewsDetail: React.FC<ChallengeReviewsDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();

  const [loading, setLoading] = useState(false);

  const [feedbackList, setFeedbackList] = useState<ChallengeFeedback[]>([]);
  const [feedbackWithComments, setFeedbackWithComments] = useState<ChallengeFeedback[]>();
  const [feedbackSortOptions, setFeedbackSortOption] = useState<ChallengeFeedbackSortOptions>({
    sortType: ChallengeFeedbackSortType.DATE,
    ascending: false,
    label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByNewest),
  });

  const [summary, setSummary] = useState<ChallengeFeedbackSummary>();
  const [sortOption, setSortOption] = useState<OptionDefinition>({ label: 'Sort by newest', value: '0' });

  useComponentDidMountEffect(async () => {
    setSummary(await challengesApi.getChallengeFeedbackSummary(safeString(challenge.id), false));
    void loadNextPage(false);
  });

  const loadNextPage = async (reload = false) => {
    // don't allow concurrent page requests
    if (!reload && loading) {
      return;
    }

    setLoading(true);

    const lastFeedback: ChallengeFeedback | null =
      feedbackList && feedbackList.length > 0 ? feedbackList[feedbackList.length - 1] : null;

    const rows = await challengesApi.getChallengeFeedback(
      safeString(challenge.id),
      ROWS_PER_PAGE,
      feedbackSortOptions,
      lastFeedback
    );

    const hasRows = rows.length > 0;

    if (hasRows) {
      // update the feedback list
      addRows(rows);

      const partialPage = rows.length > 0 && rows.length < ROWS_PER_PAGE;
      const hasComments = withComments(rows).length > 0;

      // if this is a partial page, or this page has no comments
      // then load one more page
      if (partialPage || !hasComments) {
        // if this is already a reload, then dont load another page
        if (!reload) {
          return;
        }
      }
    }
  };

  const addRows = (feedbacks: ChallengeFeedback[]) => {
    setFeedbackList(feedbacks);
    updateFeedbackWithComments(feedbacks);
  };

  const withComments = (feedbacks: ChallengeFeedback[]): ChallengeFeedback[] => {
    return (feedbacks || []).filter((f) => f.notes != null && f.notes.length > 0);
  };

  const updateFeedbackWithComments = (feedbacks: ChallengeFeedback[]) => {
    setFeedbackWithComments(withComments(feedbacks));
  };

  const updateSortOption = (selectedOption: OptionDefinition) => {
    const sortOptionIndex = Number(selectedOption.value);
    setFeedbackSortOption(getFeedbackSortOptions()[sortOptionIndex]);
    setSortOption(selectedOption);
    setFeedbackList([]);
    void loadNextPage(true);
  };

  const getFeedbackSortOptions = (): ChallengeFeedbackSortOptions[] => {
    return [
      {
        sortType: ChallengeFeedbackSortType.DATE,
        ascending: false,
        label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByNewest),
      },
      {
        sortType: ChallengeFeedbackSortType.DATE,
        ascending: true,
        label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByOldest),
      },
      {
        sortType: ChallengeFeedbackSortType.RATING,
        ascending: true,
        label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByLowestRating),
      },
      {
        sortType: ChallengeFeedbackSortType.RATING,
        ascending: false,
        label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByHighestRating),
      },
    ];
  };

  return (
    <React.Fragment>
      <Columns columns={2}>
        <FeedbackProgress label={t(i18nKeys.challenges.challengeDetails.text.feedbackItem.rating)} summary={summary} />
        <FeedbackProgress
          label={t(i18nKeys.challenges.challengeDetails.text.feedbackItem.difficulty)}
          summary={summary}
          hasDifficulty={summary && true}
        />
      </Columns>
      <HorizontalRule />
      <Box margin={{ bottom: 'xl' }}>
        <FeedbackLearningOutcome summary={summary} />
      </Box>
      <HorizontalRule />
      <PageHeader
        title={t(i18nKeys.challenges.challengeDetails.headings.reviews)}
        buttons={[
          <Select
            key="1"
            selectedOption={sortOption}
            onChange={({ detail }) => updateSortOption(detail.selectedOption)}
            options={[
              { label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByNewest), value: '0' },
              { label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByOldest), value: '1' },
              { label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByLowestRating), value: '2' },
              { label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByHighestRating), value: '3' },
            ]}
            selectedAriaLabel={t(i18nKeys.general.selected)}
          />,
        ]}
      />
      {feedbackWithComments && feedbackWithComments.length > 0 ? (
        feedbackWithComments.map((feedback, i) => (
          <Box key={feedback.eventName}>
            <FeedbackItem challengeFeedback={feedback} />
            {i + 1 !== feedbackWithComments.length && <HorizontalRule />}
          </Box>
        ))
      ) : (
        <div>{t(i18nKeys.challenges.challengeDetails.text.challengeFeedbackEmptyState)}</div>
      )}
    </React.Fragment>
  );
};

export default ChallengeReviewsDetail;
