import { Campaign } from '../types/Campaign';
import { Event } from '../types/Event';
import moment from 'moment-timezone';

export const getEmptyEventPermissions = (target: Event | Campaign): string[] => {
  const isEvent = target instanceof Event;
  const eventPermissions = isEvent ? target?.eventPermissions : target?.campaignSettings?.eventPermissions;
  return eventPermissions?.filter((item) => !item.eventPermissionType).map((item) => item.email) || [];
};

/**
 * Determines if the string is a past date. 
 * 
 * @param startDate 
 * @returns 
 */
export const isDateInThePast = (startTimestamp: number | string): boolean => {
  if(startTimestamp && moment.utc(startTimestamp).isBefore(moment.now())){
    return true;
  } else {
    return false;
  }
}
