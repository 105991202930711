import React, { useEffect, useState } from 'react';
import { Box, Table } from '@amzn/awsui-components-react';
import './JamReport.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useJamReport } from '@/src/store/jam-report.context';
import JamContainer from '@/src/components/ui/molecules/JamContainer/JamContainer';

import { LeaderboardEntry } from '@/src/types/JamReport';

const LeaderBoard = () => {
  const { reportApiData } = useJamReport();
  const [tableData, setTableData] = useState<LeaderboardEntry[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    if (reportApiData) {
      const { topTenTeamsLeaderBoardEntry } = reportApiData;
      if (topTenTeamsLeaderBoardEntry && topTenTeamsLeaderBoardEntry?.length > 0) {
        setTableData(topTenTeamsLeaderBoardEntry);
      }
    }
  }, []);
  return (
    <JamContainer>
      <Box>
        <h2>{t(i18nKeys.eventReport.leaderboardTable.heading)}</h2>
      </Box>
      <Table
        contentDensity="compact"
        columnDefinitions={[
          {
            header: t(i18nKeys.eventReport.leaderboardTable.rank),
            cell: (item) => item?.rank || '',
            maxWidth: 50,
          },
          {
            header: t(i18nKeys.eventReport.leaderboardTable.team),
            cell: (item) => item?.teamDisplayName || '',
            minWidth: 100,
          },
          {
            header: t(i18nKeys.eventReport.leaderboardTable.completed),
            cell: (item) => item?.teamNumSolvedChallenges || '',
            minWidth: 100,
          },
          {
            header: t(i18nKeys.eventReport.leaderboardTable.score),
            cell: (item) => item?.teamTotalEffectiveScore || '',
            minWidth: 100,
          },
          {
            header: t(i18nKeys.eventReport.leaderboardTable.maxScore),
            cell: (item) => item?.maxPossibleEventScore || '',
            minWidth: 100,
          },
        ]}
        items={tableData || []}
        loadingText={t(i18nKeys.eventReport.loadigResource)}
        sortingDisabled
        variant="embedded"
      />
    </JamContainer>
  );
};

export default LeaderBoard;
