import { useCollection } from '@amzn/awsui-collection-hooks';
import { CollectionPreferencesProps, Pagination, SpaceBetween, Table, TextFilter } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../../../../types/common';
import { TeamMetricsRow } from '../../../../../types/DetailedEventStatisticsReport';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { paginationLabels } from '../../../../../utils/table.utils';
import { TableEmptyState } from '../../../../common/TableEmptyState';
import { TableHeader } from '../../../../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './team-metrics-config';
import { TimezoneFormat } from '@/src/components/common/CommonModel';

interface TeamMetricsTableProps {
  teamMetrics: TeamMetricsRow[];
  selectTeam: Dispatch<SetStateAction<Nullable<TeamMetricsRow>>>;
}

const TeamMetricsTable: React.FC<TeamMetricsTableProps> = ({ teamMetrics, selectTeam }) => {
  const { t } = useTranslation();
  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
    custom: TimezoneFormat.LOCAL,
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    teamMetrics || [],
    {
      filtering: {
        filteringFunction,
        empty: <TableEmptyState title={t(i18nKeys.report.headers.teamMetricsTable.emptyTable.empty)} />,
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );
  return (
    <Table
      {...collectionProps}
      resizableColumns
      header={
        <TableHeader
          totalItems={teamMetrics.length}
          title={t(i18nKeys.report.headers.teamMetricsTable.headers.teamMetrics)}
        />
      }
      columnDefinitions={COLUMN_DEFINITIONS(preferences, selectTeam)}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      filter={
        <SpaceBetween direction="horizontal" size="m">
          <TextFilter
            className="text-filter"
            filteringPlaceholder={t(i18nKeys.report.headers.teamMetricsTable.labels.searchTeams)}
            {...filterProps}
            countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
            filteringAriaLabel={t(i18nKeys.events.filteringLabel)}
          />
        </SpaceBetween>
      }
    />
  );
};
export default TeamMetricsTable;
