import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { TinyEvent } from '../../../../../types/Event';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../../utils/table.utils';
import { TFunctionProvider } from '../../../../common/TFunctionProvider';

export const filteringFunction = (item: TinyEvent, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return matcher.filterEmpty || matcher.isMatch(item?.title);
};

export const COLUMN_DEFINITIONS = [
  {
    id: 'title',
    header: <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.title)}</TFunctionProvider>,
    cell: (item: TinyEvent) => (item ? <Link to={''}>{item.title}</Link> : '-'),
    sortingField: 'title',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'startTime',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.startTime)}</TFunctionProvider>
    ),
    cell: (item: TinyEvent) => (item ? moment(item.startDate).format('lll') : '-'),
    sortingField: 'startTime',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'endTime',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.endTime)}</TFunctionProvider>
    ),
    cell: (item: TinyEvent) => (item ? moment(item.endDate).format('lll') : '-'),
    sortingField: 'endTime',
    minWidth: 100,
    allowLineWrap: true,
  },
];
