import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import ChallengeAdvancedSettingDetail from './AdvancedSettings';
import ChallengeAWSSettingDetail from './AWSSettings';
import ChallengeBasicSettingDetail from './BasicSettings';
import ChallengeSponsorshipSettingDetail from './SponsorshipSettings';
import ChallengeSSHSettingDetail from './SSHSettings';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';

interface ChallengeSettingsDetailProps {
  challenge: Challenge;
}

const ChallengeSettingsDetails: React.FC<ChallengeSettingsDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.SETTINGS} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.settings)}
        </Header>
      }>
      <SpaceBetween direction="vertical" size="s">
        <ChallengeBasicSettingDetail challenge={challenge} />
        <ChallengeSSHSettingDetail challenge={challenge} />
        <ChallengeAWSSettingDetail challenge={challenge} />
        <ChallengeSponsorshipSettingDetail challenge={challenge} />
        <ChallengeAdvancedSettingDetail challenge={challenge} />
      </SpaceBetween>
    </Container>
  );
};

export default ChallengeSettingsDetails;
