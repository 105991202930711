import { LocalizationOverrides } from '../types/common';
import { i18nKeys } from '../utils/i18n.utils';
import { ApiClient } from './ApiClient';
import { TFunction } from "i18next";

export class SettingsAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  async getLocalizationMessages(appName: string, languageCode: string, silent = false): Promise<LocalizationOverrides> {
    return (await this.apiClient.get({
      path: `/admin/settings/localization/${appName}/${languageCode}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.settings.getLocalizationMessages),
      silent,
    })) as Promise<LocalizationOverrides>;
  }

  async overrideLocalizationMessage(
    appName: string,
    languageCode: string,
    key: string,
    value: string,
    silent = false
  ): Promise<void> {
    await this.apiClient.post({
      path: `/admin/settings/localization/${appName}/${languageCode}`,
      body: {
        key,
        value,
      },
      successMessage: this.t(i18nKeys.success.requestSucceeded.settings.overrideLocalizationMessage),
      failMessage: this.t(i18nKeys.errors.requestFailed.settings.overrideLocalizationMessage),
      silent,
    });
  }

  async removeLocalizationOverride(appName: string, languageCode: string, key: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/admin/settings/localization/${appName}/${languageCode}/remove`,
      body: { key },
      successMessage: this.t(i18nKeys.success.requestSucceeded.settings.removeLocalizationOverride),
      failMessage: this.t(i18nKeys.errors.requestFailed.settings.removeLocalizationOverride),
      silent,
    });
  }
}
