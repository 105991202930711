import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthClient } from '../api/AuthClient';
import { AddFlashbar, useFlashbars } from './flashbar.context';
import { History } from 'history';

export interface AuthContextValue {
  authClient: AuthClient;
}

const AuthClientContext = React.createContext<AuthContextValue>({
  authClient: new AuthClient((null as unknown) as AddFlashbar, (null as unknown) as History),
});

const AuthClientProvider: React.FC = ({ children }) => {
  const { addFlashbar } = useFlashbars();
  const history = useHistory();

  // make a single auth client
  const [authClient] = useState<AuthClient>(new AuthClient(addFlashbar, history));

  const data: AuthContextValue = { authClient };

  return <AuthClientContext.Provider value={data}>{children}</AuthClientContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthClientContext);
  if (context === undefined) {
    throw new Error('useAuthClient can only be used inside AuthClientProvider');
  }
  return context;
};

export { AuthClientProvider, useAuth };
