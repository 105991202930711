import React, { useEffect } from 'react';
import './Confetti.scss';
import { startConfettiAnimation } from './confettiAnim';

const Confetti = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    startConfettiAnimation('leaderboad-confetti');
  }, []);

  return <div className="leaderboad-confetti" id="leaderboad-confetti" />;
};

export default Confetti;
