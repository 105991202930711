import React, { useCallback, memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@amzn/awsui-components-react';

import './LanguageSelect.scss';

import { i18nKeys } from '@/src/utils/i18n.utils';

type LanguageOption = {
  value: string;
  label: string;
};
export interface ILanguageSelectProps {
  /** selected language code **/
  lang: LanguageOption;
  /** Available langauges */
  options: LanguageOption[];
  /** on language changed callback */
  onLanguageSelect: (code: string) => void;
}

export const LanguageSelect = memo<ILanguageSelectProps>(
  ({ lang, options, onLanguageSelect }: ILanguageSelectProps) => {
    const { t } = useTranslation();
    const [selectedLang, setSelectedLang] = useState<string>(lang.value);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      if (lang.value) {
        setSelectedLang(lang.value);
      }
    }, [lang.value]);

    const onShowModalClick = useCallback(() => {
      setShowModal(true);
    }, []);

    const onHideModalClick = useCallback(() => {
      setShowModal(false);
    }, []);

    const handleLanguageChange = useCallback((code: string) => {
      setSelectedLang(code);
    }, []);

    const handleLanguageSelect = useCallback(() => {
      onHideModalClick();
      onLanguageSelect(selectedLang);
    }, [selectedLang, onLanguageSelect]);

    return (
      <div className="language-toggle">
        <Button variant="link" onClick={onShowModalClick}>
          {' '}
          {lang.label}
        </Button>

        <Modal
          visible={showModal}
          header={t(i18nKeys.general.selectLanguage)}
          onDismiss={onHideModalClick}
          footer={
            <span>
              <Button variant="link" onClick={onHideModalClick}>
                {t(i18nKeys.general.cancel)}
              </Button>
              <Button variant="primary" onClick={handleLanguageSelect}>
                {t(i18nKeys.general.confirm)}
              </Button>
            </span>
          }>
          <div className="language-toggle_modal_content">
            {options.map((option, key) => (
              <Button
                key={key}
                variant={option.value === selectedLang ? 'primary' : 'normal'}
                onClick={handleLanguageChange.bind(this, option.value)}
                ariaLabel={option.label}>
                {option.label}
              </Button>
            ))}
          </div>
        </Modal>
      </div>
    );
  }
);

LanguageSelect.displayName = 'LanguageSelect';
