import React from 'react';
import { TeamChallengeTaskProgress } from '@/src/types/JamTeamChallengeAnswer';
import { Box, ColumnLayout, Container, ExpandableSection } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface ShowTasksProps {
  taskProgress: TeamChallengeTaskProgress[];
}

const ShowTasks: React.FC<ShowTasksProps> = ({ taskProgress }) => {
  const { t } = useTranslation();

  return (
    <ExpandableSection headerText={t(i18nKeys.facilitator.participants.label.showTask)}>
      {taskProgress.map((task) => (
        <Box key={task.taskId} variant="h4" margin={{ vertical: 'xxxs' }}>
          <Container>
            <Box variant="h4" margin={{ bottom: 'xs' }}>
              {t(i18nKeys.facilitator.participants.label.task)} : {task.taskNumber}
            </Box>
            <ColumnLayout borders="horizontal" columns={2}>
              <Box className="space-between">
                <Box>{t(i18nKeys.facilitator.participants.label.completed)}</Box>
                <Box>{task.completedTime ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</Box>
              </Box>
              <Box className="space-between">
                <Box>{t(i18nKeys.facilitator.participants.label.usedClues)}</Box>
                <Box>{task.cluePenalties?.length ?? 0}</Box>
              </Box>
              <Box className="space-between">
                <Box>{t(i18nKeys.facilitator.participants.label.submittedAnswers)}</Box>
                <Box>{(task.submittedAnswers || []).join(', ')}</Box>
              </Box>
            </ColumnLayout>
          </Container>
        </Box>
      ))}
    </ExpandableSection>
  );
};

export default ShowTasks;
