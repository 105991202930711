import routes, { RouteItem } from '../routes';
import { matchPath } from 'react-router';
import { splitDelimitedEmails } from './string.utils';
import { uniq } from 'lodash';
interface Match {
  params: {
    [key: string]: string;
  };
  isExact: boolean;
  path: string;
  url: string;
}

/**
 * Replace params in a route with the value of those params.
 * example: replace /rules/:ruleId with /rules/rule-1
 *
 * @param match
 * @param path
 */
export const replaceParamsInPath = (match: Match | null, path: string) => {
  if (match != null && Object.keys(match.params).length) {
    return Object.keys(match.params).reduce((fragment, param) => {
      return fragment.replace(`:${param}`, match.params[param]);
    }, path);
  }

  return path;
};

/**
 * Based on the current path, return a list of breadcrumb items, resolving any entity names and replacing
 * any path parameters.
 *
 * @param path
 */
export const getCurrentBreadCrumbs = (
  path: string
): { text: string; match: Match; href: string; routeItem: RouteItem }[] => {
  return routes
    .map((routeItem) => {
      const match = matchPath(path, {
        path: routeItem.path,
        exact: false,
        strict: false,
      });
      return { routeItem, match };
    })
    .filter(({ match }) => match != null)
    .map(({ routeItem, match }: { routeItem: RouteItem; match: any }) => {
      return {
        text: routeItem.name,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        match,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        href: replaceParamsInPath(match, routeItem.path),
        routeItem,
      };
    });
};

/**
 * Returns a path with exactly 1 leading /
 *
 * path/to/some/api -> /path/to/some/api
 * /path/to/some/api -> /path/to/some/api
 *
 * @param path
 */
export const safePath = (path: string) => (path.startsWith('/') ? path : `/${path}`);

/**
 * Takes an array of values, splits each value if it is delimited, then merges all results into a result array with only unique members.
 * NOTE: the custom mapper is applied before the custom validator.
 *
 * @param values
 * @param mapper a function for mapping values (defaults to NOOP if not supplied)
 * @param validator a function for validating/excluding invalid values (defaults to include all values if not supplied)
 */
export const parseQueryParamList = (
  params: string[],
  mapper: (v: string) => string = (v: string) => v,
  validator: (v: string) => boolean = (_v: string) => true
) => {
  return (
    uniq(params.reduce((acc: string[], value) => [...acc, ...splitDelimitedEmails(value)], []))
      .map((v) => (v ? v.trim() : v))
      // remove empty values
      .filter((v) => v?.length > 0)
      // apply custom mapper
      .map(mapper)
      // apply custom validation
      .filter(validator)
  );
};

/**
 * Returns the value associated with the input query parameter name or null if it does not exist
 */
export const parseQueryParam = (queryParams: URLSearchParams, paramName: string) => {
  const value = (queryParams.get(paramName) || '').trim();
  return value?.length > 0 ? value : null;
};
