import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Container,
  ContentLayout,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Tabs,
} from '@amzn/awsui-components-react';
import { Background, PreRequisiteSetup } from '@/src/__mocks__/mock-jam-challenge-tasks';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import TaskPointContainer from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamTask/PointsContainer';
import CollapsibleTabContent from '@/src/components/ui/organisms/MyJams/JamChallengeDetails/CollapsibleTabContent';
import { useHistory, useParams } from 'react-router-dom';
import './JamTaskInfo.scss';
import { Markdown } from '@/src/components/common/Markdown';
import { ChallengeLearningType } from '@/src/types/Challenge';
import { BORKMarkdownRenderer } from '@/src/components/ui/molecules/Challenge';
import { JamChallengeTaskAnswerFields } from '@/src/types/JamChallenges';
import { jamChallengeTaskAnswerValidator } from '@/src/utils/jam-challenge-task.validation.utils';
import { LoadingBar } from '@/src/components/common/LoadingBar';
import JamTaskStatusModal from './JamTaskStatusModal';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { JAM_CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { JamTaskProgress, JamTaskStatus } from '@/src/types/JamChallengeDetails';
import { useFlashbars } from '@/src/store/flashbar.context';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import JamChallengeHeader from '../../JamChallengeHeader/JamChallengeHeader';
import { LoggingService } from '@/src/utils/logging-service.utils';
import { preProdLogger } from '@/src/utils/log.utils';
import ChallengeSolvedModal from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamTask/Modals/ChallengeSolvedModal/ChallengeSolvedModal';
import ChallengeNotCompleteModal from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamTask/Modals/ChallengeNotCompleteModal';

interface IHandleOnChange {
  detail: { value: string };
}

const JamTaskInfo: React.FC = () => {
  const { t } = useTranslation();
  const {
    selectedJamChallenge,
    validateTaskAnswer,
    getJamChallengeTask,
    task,
    fetchingtaskValidationResponse,
    challengeProgress,
    challengeSupportDetails,
    loadJamChallengeProgressData,
    jamChallengeId,
    loadJamChallengeDetails,
    loadChallengeSupportDetails,
    showChallengeStatusProgress,
  } = useJamChallengeDetails();
  const { eventName, eventSlug, event } = useJamEventDetails();
  const { loadJamChallengeData } = useJamChallenge();
  const params: { 0: string; 1: string; 2: string } = useParams();
  const history = useHistory();
  const { clearFlashbars } = useFlashbars();
  const [answer, setAnswer] = useState<string>('');
  const [answerError, setAnswerError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [showProgressModal, showProgressModalSet] = useState(false);

  const handleSolvedModalClose = useCallback(() => {
    showProgressModalSet(false);
    void loadJamChallengeDetails({ id: eventName, challengeId: params[1] });
    void loadJamChallengeData({ id: eventName, showLoader: false });
    history.push(JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventSlug, params[1]]));
  }, [history, eventSlug, params]);

  const handleModalDismiss = () => {
    showProgressModalSet(false);
  };

  const isFacilitator = !!event?.facilitator;
  const briefClues = task?.clues || [];
  const previousTaskProgress = useRef<JamTaskProgress|undefined>();

  const currentTaskProgress = useMemo(() => {
    if (challengeProgress?.taskProgress?.length) {
      const taskProgress = challengeProgress?.taskProgress.find(({taskId}) => taskId === task?.id);
      return taskProgress;
    }
  }, [challengeProgress, task?.id]);

  const clues = useMemo(() => {
    if (showChallengeStatusProgress) {
      return [];
    }
    if (!isFacilitator) {
      return briefClues || [];
    }
    const challenge = challengeSupportDetails?.challenges.find(({ id }) => id === selectedJamChallenge?.id);
    if (!challenge) {
      return [];
    }
    const taskDetails = challenge.getTaskDetails(task?.id as string);
    return briefClues.map((clue) => ({
      ...clue,
      description: taskDetails?.getClue(clue.order)?.description,
    }));
  }, [briefClues, isFacilitator, challengeSupportDetails, selectedJamChallenge, task, currentTaskProgress?.completed, showChallengeStatusProgress]);

  const handleInput = ({ detail }: IHandleOnChange) => {
    setAnswer(detail.value);
  };

  const handleProgressCheck = useCallback(async () => {
    try {
      await loadJamChallengeProgressData({ id: eventName, challengeId: jamChallengeId });
      showProgressModalSet(true);
    } catch (err) {
      preProdLogger('Progress Check failed', err);
    }
  }, [loadJamChallengeProgressData, eventName, jamChallengeId, task]);

  useEffect(() => {
    // only show modal when task complete goes from false to true
    if (currentTaskProgress?.completed && previousTaskProgress?.current?.completed === false && previousTaskProgress?.current?.taskId === currentTaskProgress.taskId && !!currentTaskProgress.taskId) {
      showProgressModalSet(true);
    }
    previousTaskProgress.current = currentTaskProgress;
  }, [currentTaskProgress]);

  const submitAnswer = async (input: string) => {
    clearFlashbars();
    setLoading(true);
    const success = await validateTaskAnswer({ id: eventName, challengeId: jamChallengeId }, input, task?.id);
    setAnswer('');
    showProgressModalSet(true);
    if (success) {
      await loadJamChallengeDetails({ id: eventName, challengeId: params[1] });
      await loadJamChallengeData({ id: eventName, showLoader: false });
    }
    setLoading(false);
  };

  const validator = useMemo(
    () =>
      jamChallengeTaskAnswerValidator(
        answer,
        t(i18nKeys.myJams.challenges.details.task.answer.error),
        new Map<JamChallengeTaskAnswerFields, (error: string) => void>([
          [JamChallengeTaskAnswerFields.ANSWER, (error: string) => setAnswerError(error)],
        ])
      ),
    [answer, jamChallengeTaskAnswerValidator]
  );

  const initialize = async () => {
    setLoading(true);
    clearFlashbars();
    try {
      // load challenge only for empty selectedChallenge or new request
      if (!selectedJamChallenge || selectedJamChallenge?.id !== params[1]) {
        await Promise.all([
          loadJamChallengeDetails({ id: eventName, challengeId: params[1] }),
          isFacilitator ? loadChallengeSupportDetails(eventName, params[1]) : Promise.resolve(),
        ])
      }
    } catch (e) {
      LoggingService.debug('Error :', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void initialize();
  }, [params[1]]);

  useEffect(() => {
    clearFlashbars();
    void getJamChallengeTask({ taskId: params[2] });
  }, [params[2], selectedJamChallenge?.id ]);

  useEffect(() => {
    if (!selectedJamChallenge) {
      history.push(JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventSlug, params[1]]));
    }
  }, []);

  if (loading || fetchingtaskValidationResponse) {
    return <LoadingBar />;
  }

  return (
    <ContentLayout
      header={
        <Header variant="h1" description={t(i18nKeys.myJams.challenges.subtitle)}>
          {selectedJamChallenge?.title}
        </Header>
      }>
      <SpaceBetween direction="vertical" size="xxl">
        <JamChallengeHeader />
        <Box className="div" margin={{ bottom: 'xxl' }}>
          <Box variant="h1" margin={{ bottom: 's' }}>
            {t(i18nKeys.myJams.challenges.details.task.title)} {task?.taskNumber} : {task?.title ?? 'Untitle Task'}
          </Box>
          <SpaceBetween direction="vertical" size="xl">
            <TaskPointContainer task={task} handleProgressCheck={handleProgressCheck} disableProgressButton={showChallengeStatusProgress} />
            {selectedJamChallenge?.learningType === ChallengeLearningType.INDIVIDUAL ? (
              <Container className="dark-bg">
                {task?.status !== JamTaskStatus.COMPLETE && challengeProgress?.started && (
                  <Button variant="primary" onClick={() => void submitAnswer('done')}>
                    {t(i18nKeys.myJams.challenges.tabs.buttons.markAsComplete)}
                  </Button>
                )}
                <BORKMarkdownRenderer markdown={task?.content ?? ''} />
              </Container>
            ) : (
              <Tabs
                tabs={[
                  ...(task?.background
                    ? [
                        {
                          label: t(i18nKeys.myJams.challenges.tabs.background),
                          id: Background.title ?? 'background',
                          content: (
                            <CollapsibleTabContent content={task?.background ?? ''} isFacilitator={isFacilitator} />
                          ),
                        },
                      ]
                    : []),
                  ...(task?.inventory
                    ? [
                        {
                          label: t(i18nKeys.myJams.challenges.tabs.preRequisiteSetup),
                          id: PreRequisiteSetup.title ?? 'Pre-requisite setup',
                          content: (
                            <CollapsibleTabContent content={task?.content ?? ''} isFacilitator={isFacilitator} />
                          ),
                        },
                      ]
                    : []),
                  {
                    label: t(i18nKeys.myJams.challenges.tabs.taskAndClue),
                    id: `${t(i18nKeys.myJams.challenges.details.task.title)}-and-${t(
                      i18nKeys.myJams.challenges.details.task.clue.clues
                    )}`,
                    content: (
                      <SpaceBetween direction="vertical" size="xl">
                        <Form>
                          <Container className="dark-bg">
                            <SpaceBetween direction="vertical" size="l">
                              {task?.status !== JamTaskStatus.COMPLETE &&
                                task?.allowInputAnswer &&
                                challengeProgress?.started &&
                                !showChallengeStatusProgress && ( // if challenge deploy is in progress don't show submit answer
                                  <SpaceBetween direction="horizontal" size="l" alignItems="center">
                                    <Box className="awsui_root_2rhyz_7lwtk_93 awsui_input-container_2rhyz_7lwtk_220">
                                      <FormField errorText={answerError}>
                                        <Input
                                          id="answerTextBox"
                                          value={answer}
                                          placeholder="Enter answer here"
                                          onChange={handleInput}
                                          onBlur={() => validator.isValidField(JamChallengeTaskAnswerFields.ANSWER)}
                                        />
                                      </FormField>
                                    </Box>
                                    <div
                                      id="submitAnswerButton"
                                      data-testid="submitAnswerButton"
                                      onClick={() => void submitAnswer(answer)}>
                                      <Button variant="primary">
                                        {t(i18nKeys.myJams.challenges.tabs.buttons.submitAnswer)}
                                      </Button>
                                    </div>
                                  </SpaceBetween>
                                )}
                              <div className="task-markdown-container">
                                <Markdown content={task?.content} />
                              </div>
                            </SpaceBetween>
                          </Container>
                        </Form>

                        {!!clues?.length && (
                          <CollapsibleTabContent
                            isFacilitator={isFacilitator}
                            content={task?.content ?? ''}
                            clues={clues}
                            showClues
                            disableClueBtn={task?.status === JamTaskStatus.COMPLETE}
                          />
                        )}
                      </SpaceBetween>
                    ),
                  },
                ]}
                ariaLabel={t(i18nKeys.myJams.challenges.tabs.taskAndClue)}
              />
            )}
          </SpaceBetween>
        </Box>
      </SpaceBetween>
      <ProgressModal
        showProgressModal={showProgressModal}
        challengeCompleted={!!challengeProgress?.completed}
        currentTaskCompleted={!!currentTaskProgress?.completed}
        onClose={handleSolvedModalClose}
        onDismiss={handleModalDismiss}
        />
    </ContentLayout>
  );
};

interface ProgressModalProps {
  showProgressModal: boolean;
  currentTaskCompleted: boolean;
  challengeCompleted: boolean;
  onClose: () => void;
  onDismiss: () => void;
}

const ProgressModal = ({showProgressModal, currentTaskCompleted, challengeCompleted, onClose, onDismiss }: ProgressModalProps) => {
  if (!showProgressModal) {
    return null;
  }
  if (challengeCompleted) {
    return <ChallengeSolvedModal
      value={showProgressModal}
      onClose={onClose}
      onDismiss={onDismiss}
      />;
  }
  if (currentTaskCompleted) {
    return <JamTaskStatusModal
      isCompleted={currentTaskCompleted}
      onDismiss={onDismiss}
      onClose={onClose}
      />;
  }
  return <ChallengeNotCompleteModal value={showProgressModal} setValue={() => onDismiss()} />;
};

export default JamTaskInfo;
