import {
  BarChart,
  Box,
  ColumnLayout,
  ExpandableSection,
  Header,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useToolPanel } from '../../../../store/tool-panel.context';
import { ChallengeListItem } from '../../../../types/Challenge';
import { ChallengeMetricsRow } from '../../../../types/DetailedEventStatisticsReport';
import { getDisplayDuration } from '../../../../utils/time.utils';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { KeyValue } from '../../../common/KeyValue';
import { Nullable } from '../../../../types/common';
import { useChallenges } from '../../../../store/challenge.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { LearningOutcome } from '../../../common/LearningOutcome';
import { roundFloat } from '../../../../utils/number.utils';
import { max } from 'lodash';

interface ChallengeMetricsProps {
  challengeMetricsRow: ChallengeMetricsRow | undefined;
  title: string;
  teamCount: number;
}

const ChallengeMetrics: React.FC<ChallengeMetricsProps> = ({ challengeMetricsRow, title, teamCount }) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState<string[]>([]);
  const { toggleChallengeInfo } = useToolPanel();
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const [challengeListItem, setChallengeListItem] = useState<Nullable<ChallengeListItem>>();
  const getFeedbackComments = (): string[] => {
    if (challengeMetricsRow && challengeMetricsRow.feedbackComments) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return (
        challengeMetricsRow.feedbackComments.filter((feedback: string) => {
          return feedback != null && feedback.length > 1;
        }) || []
      );
    } else {
      return [];
    }
  };

  const maxTeam = useMemo(() => {
    const started = challengeMetricsRow?.numTeamsStarted || 0;
    const attempted = challengeMetricsRow?.numTeamsAttempted || 0;
    return max([started, attempted, 40]) as number; // 40 is default
  }, [challengeMetricsRow]);

  useEffect(() => {
    setComments(getFeedbackComments());
    if (challengeMetricsRow?.challengeId) {
      setChallengeListItem(getChallengeListItemFromChallengeId(challengeMetricsRow?.challengeId));
    }
  }, [challengeMetricsRow]);

  return (
    <ExpandableSection
      header={
        <Header>
          {title}
          {challengeListItem && (
            <Link className="ml-5" variant="info" onFollow={() => toggleChallengeInfo(challengeListItem)}>
              {t(i18nKeys.general.info)}
            </Link>
          )}
        </Header>
      }
      variant="container"
      className="mb-10 nested-interactive">
      <div style={{ borderBottom: '1px solid #E9EBED', paddingBottom: '20px', marginBottom: '20px' }}>
        <Header variant="h3">{t(i18nKeys.report.headers.teamData)}</Header>
        <BarChart
          hideFilter
          hideLegend
          stackedBars
          height={220}
          yTitle={t(i18nKeys.report.graph.labels.numOfTeams)}
          xTitle={t(i18nKeys.report.graph.labels.teamState)}
          xScaleType="categorical"
          yDomain={[0, maxTeam]}
          series={[
            {
              title: t(i18nKeys.report.graph.labels.started),
              type: 'bar',
              color: '#688AE8',
              data: [
                {
                  x: t(i18nKeys.report.graph.labels.started).toString(),
                  y: challengeMetricsRow?.numTeamsStarted || 0,
                },
              ],
            },
            {
              title: t(i18nKeys.report.graph.labels.attempted),
              type: 'bar',
              color: '#B1325C',
              data: [
                {
                  x: t(i18nKeys.report.graph.labels.attempted).toString(),
                  y: challengeMetricsRow?.numTeamsAttempted || 0,
                },
              ],
            },
            {
              title: t(i18nKeys.report.graph.labels.solved),
              type: 'bar',
              color: '#2EA597',
              data: [
                {
                  x: t(i18nKeys.report.graph.labels.solved).toString(),
                  y: challengeMetricsRow?.numTeamsSolved || 0,
                },
              ],
            },
          ]}
        />
      </div>
      <ColumnLayout columns={3} variant="text-grid">
        <Box>
          <KeyValue label={<strong>{t(i18nKeys.report.labels.timeToSolve)}</strong>}>
            {challengeMetricsRow?.timeToCompletedChallenge &&
            Object.keys(challengeMetricsRow.timeToCompletedChallenge).length > 0 ? (
              <React.Fragment>
                {challengeMetricsRow?.timeToCompletedChallenge.mean && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.mean)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToCompletedChallenge.mean)}
                  </div>
                )}
                {challengeMetricsRow?.timeToCompletedChallenge.min && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.min)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToCompletedChallenge.min)}
                  </div>
                )}
                {challengeMetricsRow?.timeToCompletedChallenge.max && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.max)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToCompletedChallenge.max)}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <span>{t(i18nKeys.general.nA)}</span>
            )}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.report.labels.timeSpentOnFirstAttempt)}</strong>}>
            {challengeMetricsRow?.timeToFirstAttempt &&
            Object.keys(challengeMetricsRow.timeToFirstAttempt).length > 0 ? (
              <React.Fragment>
                {challengeMetricsRow?.timeToFirstAttempt.mean && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.mean)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToFirstAttempt.mean)}
                  </div>
                )}
                {challengeMetricsRow?.timeToFirstAttempt.min && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.min)}</strong>{' '}
                    {getDisplayDuration(challengeMetricsRow?.timeToFirstAttempt.min)}
                  </div>
                )}
                {challengeMetricsRow?.timeToFirstAttempt.max && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.max)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToFirstAttempt.max)}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <span>{t(i18nKeys.general.nA)}</span>
            )}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.report.labels.attemptsMade)}</strong>}>
            <div>
              {t(i18nKeys.report.labels.numOfCount, {
                num: challengeMetricsRow?.numTeamsAttempted || '0',
                totalCount: teamCount,
              })}
            </div>
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.report.labels.restarts)}</strong>}>
            <div>{challengeMetricsRow?.numberOfRestartsUsed}</div>
          </KeyValue>
          {/** TODO: Add the Avg Reasearch time once values are made available on report */}
        </Box>
        <Box>
          {/** TODO: Implement Time spent on tasks once values are available on report */}
          <KeyValue label={<strong>{t(i18nKeys.report.labels.cluesUsed)}</strong>}>
            <div>{challengeMetricsRow?.numCluesRequested || 0}</div>
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.report.labels.supportChats)}</strong>}>
            <div>{challengeMetricsRow?.supportChatRequests}</div>
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.report.labels.solvedWithAmazonCodeWhisperer)}</strong>}>
            <div>{challengeMetricsRow?.numParticipantsUsedCodeWhisperer ?? 0}</div>
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.report.labels.solvedWithAwsConsole)}</strong>}>
            <div>{challengeMetricsRow?.numParticipantsUsedAwsConsole ?? 0}</div>
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.report.labels.solvedWithAwsCli)}</strong>}>
            <div>{challengeMetricsRow?.numParticipantsUsedAwsCli ?? 0}</div>
          </KeyValue>
        </Box>
        <Box>
          <SpaceBetween direction="vertical" size="s">
            <LearningOutcome
              totalLearned={challengeMetricsRow?.learnedSomethingNew || 0}
              totalCount={
                (challengeMetricsRow?.learnedSomethingNew || 0) + (challengeMetricsRow?.didNotLearnSomethingNew || 0)
              }
            />
            <KeyValue label={<strong>{t(i18nKeys.report.labels.avgRank)}</strong>}>
              <div>
                {!Number.isNaN(challengeMetricsRow?.avgChallengeRank)
                  ? roundFloat(challengeMetricsRow?.avgChallengeRank as number, 2)
                  : t(i18nKeys.general.nA)}
              </div>
            </KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.report.labels.avgDifficulty)}</strong>}>
              <div>
                {!Number.isNaN(challengeMetricsRow?.avgChallengeDifficulty)
                  ? roundFloat(challengeMetricsRow?.avgChallengeDifficulty as number, 2)
                  : t(i18nKeys.general.nA)}
              </div>
            </KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.report.labels.awsAccounts)}</strong>}>
              <div>{challengeMetricsRow?.totalNumberOfAWSAccountsUsed || 0}</div>
            </KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.report.labels.awsServices)}</strong>}>
              <div>{challengeMetricsRow?.awsServicesUsed?.join(', ')}</div>
            </KeyValue>
          </SpaceBetween>
        </Box>
      </ColumnLayout>
      {comments.length > 0 && (
        <React.Fragment>
          <HorizontalRule evenMargins />
          <ExpandableSection header={t(i18nKeys.report.headers.comments, { commentCount: comments.length })}>
            {comments.map((comment: string, i) => {
              return (
                <React.Fragment key={`comment-${i}`}>
                  <div>&quot;{comment}&quot;</div>
                  {i + 1 !== comments.length && (
                    <div>
                      <strong>—</strong>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </ExpandableSection>
        </React.Fragment>
      )}
    </ExpandableSection>
  );
};
export default ChallengeMetrics;
