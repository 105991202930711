import React from 'react';
import { Box, Container, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from '@/src/components/common/CopyToClipboard';
import { IModalContent } from '@/src/__mocks__/mock-jam-challenge-tasks';

interface AwsCLIModalContentProps {
  content: IModalContent;
}

const AwsCLIModalContent: React.FC<AwsCLIModalContentProps> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <SpaceBetween size="m" direction="vertical">
      <Box variant="p" margin={{ horizontal: 'xxs' }}>
        {content.text}, <Link href={content.link}>{t(i18nKeys.myJams.challenges.common.link)}:</Link>
      </Box>
      <Container className="dark-bg">
        <SpaceBetween size="xs" direction="vertical">
          {content.import && (
            <Box variant="div" color="text-status-inactive">
              {content.import}
            </Box>
          )}
          {content.commands.map((command, i) => (
            <Box key={i} variant="div" color="text-status-inactive">
              {command}
            </Box>
          ))}
          {content.session && (
            <Box variant="div" color="text-status-inactive">
              {content.session}
            </Box>
          )}
          {content.list &&
            content.list.map((item, i) => (
              <Box key={i} variant="div" color="text-status-inactive">
                {item}
              </Box>
            ))}
        </SpaceBetween>
      </Container>
      <CopyToClipboard value={
        (content.import === undefined ? "" : content.import + "\n") +
        content.commands.join('\n') +
        (content.session === undefined ? "" : "\n" + content.session + "\n") +
        (content.list === undefined ? "" : content.list.join('\n'))
      } />
    </SpaceBetween>
  );
};

export default AwsCLIModalContent;
