import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Challenge } from '../../../../types/Challenge';
import ChallengeDetailsDetail from './Details';
import ChallengeSummaryDetail from './Summary';

interface ChallengeSummaryDetailProps {
  challenge: Challenge;
}

const ChallengeSummaryDetails: React.FC<ChallengeSummaryDetailProps> = ({ challenge }) => {
  return (
    <Box>
      <SpaceBetween direction="vertical" size="m">
        <ChallengeSummaryDetail challenge={challenge} />
        <ChallengeDetailsDetail challenge={challenge} />
      </SpaceBetween>
    </Box>
  );
};

export default ChallengeSummaryDetails;
