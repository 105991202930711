import React, { useState } from 'react';
import EventFeedback from '../../organisms/MyJams/JamFeedback/ExpandableFeedback/EventFeedback';
import { Header, Modal } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface JamEventFeedbackModalProps {
  onDismiss: () => void;
}

const JamEventFeedbackModal: React.FC<JamEventFeedbackModalProps> = ({ onDismiss }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);

  const onHide = () => {
    setVisible(false);
    onDismiss();
  };
  return (
    <Modal header={<Header>{t(i18nKeys.feedback.headers.eventFeedback)}</Header>} onDismiss={onHide} visible={visible}>
      <EventFeedback onFeedbackDone={onDismiss} />
    </Modal>
  );
};

export default JamEventFeedbackModal;
