import * as React from 'react';
import { embedDashboard, EmbeddingOptions } from 'amazon-quicksight-embedding-sdk';
import { usePlans } from '../../../store/usage-plan/usage-plan.context';
import { useEffect } from 'react';
import { UsagePlan } from '../../../types/usage-plan/UsagePlan';

interface UsagePlanDetailsUtilizationProps {
  usagePlan: UsagePlan | undefined;
}

const UsagePlanDetailsUtilization: React.FC<UsagePlanDetailsUtilizationProps> = ({ usagePlan }) => {
  const { getUsagePlanDashboardUrl } = usePlans();

  useEffect(() => {
    if (usagePlan?.id) {
      void getUsagePlanDashboardUrl(usagePlan.id).then((dashboardUrl: string) => {
        setupEmbeddedDashboard(dashboardUrl);
      });
    }
  }, []);

  const onDashboardError = (payload: { errorCode: string }) => {
    if (usagePlan?.id && (payload.errorCode === 'Forbidden' || payload.errorCode === 'Unauthorized')) {
      // if the errors are either of the two codes, it means the session expired so request another one
      void getUsagePlanDashboardUrl(usagePlan.id).then((dashboardUrl: string) => {
        setupEmbeddedDashboard(dashboardUrl);
      });
    }
  };

  const setupEmbeddedDashboard = (url: string) => {
    const container = document.getElementById('embeddingContainer');
    // using https://github.com/awslabs/amazon-quicksight-embedding-sdk
    const options: EmbeddingOptions = {
      url,
      container: container ?? '',
      height: 'AutoFit',
      parameters: {
        usagePlanId: usagePlan?.id,
      },
    };
    const dashboard = embedDashboard(options);
    dashboard.on('error', onDashboardError);
  };

  return <div id={'embeddingContainer'}> </div>;
};

export default UsagePlanDetailsUtilization;
