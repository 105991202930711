import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { i18nKeys } from '../utils/i18n.utils';
import { JamMinimalParticipant } from '../types/JamEvent';
import { FACILITATOR_API_PREFIX, GAME_API_PREFIX } from '../constants/api';
import { ChallengeIssueSeverity } from '../types/Challenge';
import { FacilitatorChallengeIssue } from '../types/JamIssue';
import {
  FacilitatorsAndNoTeamParticpants,
  JamFacilitatorParticipantsRes,
  JamFacilitatorTeamsAvailabilityRes,
  JamParticpantsStats,
} from '../types/JamFacilitator';
import { JamEventIncident } from './JamEventIncident';
import { TeamProgressResponse } from '../types/JamTeamChallengeAnswer';
import { JamEventSupportDetails } from '../types/JamChallengeSupportDetails';

export class JamFacilitatorAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  async unlockChallenges(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/challenges/unlock`,
      successMessage: this.t(i18nKeys.facilitator.jamSettings.success.enableChallenges),
      failMessage: this.t(i18nKeys.facilitator.jamSettings.errors.failedEnableChallenges),
      silent,
    });
  }

  async lockChallenges(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/challenges/lock`,
      successMessage: this.t(i18nKeys.facilitator.jamSettings.success.warmupMode),
      failMessage: this.t(i18nKeys.facilitator.jamSettings.errors.failedWarmupMode),
      silent,
    });
  }

  async addOneHourToEvent(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/addTime`,
      successMessage: this.t(i18nKeys.facilitator.jamSettings.success.updateEndTime),
      failMessage: this.t(i18nKeys.facilitator.jamSettings.errors.failedUpdateEndTime),
      silent,
    });
  }

  async subtractOneHourFromEvent(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/subtractTime`,
      successMessage: this.t(i18nKeys.facilitator.jamSettings.success.updateEndTime),
      failMessage: this.t(i18nKeys.facilitator.jamSettings.errors.failedUpdateEndTime),
      silent,
    });
  }

  async getEventParticipants(eventName: string, silent = false): Promise<{ participants: JamMinimalParticipant[] }> {
    return this.apiClient.get({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/participants`,
      failMessage: this.t(i18nKeys.facilitator.messaging.form.errors.failedParticipantLoad),
      silent,
    }) as Promise<{ participants: JamMinimalParticipant[] }>;
  }

  public async createChallengeIssue(
    eventName: string,
    challengeId: string,
    title: string,
    description: string,
    severity: ChallengeIssueSeverity
  ): Promise<void> {
    return (await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/issues/${challengeId}`,
      body: { title, description, severity },
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.createChallengeIssue),
    })) as Promise<void>;
  }

  public async getChallengeIssuesByChallengeId(
    eventName: string,
    challengeId: string
  ): Promise<FacilitatorChallengeIssue[]> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/issues/${challengeId}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getChallengeIssuesByChallengeId),
    })) as Promise<FacilitatorChallengeIssue[]>;
  }

  public async addFacilitatorNotes(eventName: string, challengeId: string, facilitatorNotes: string): Promise<void> {
    return (await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/challenges/${challengeId}/notes`,
      body: facilitatorNotes,
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.addFacilitatorNotes),
    })) as Promise<void>;
  }

  public async getEventSupportDetails(eventName: string): Promise<JamEventSupportDetails> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/support-details`,
      responseMapper: (object) => JamEventSupportDetails.fromPlainObject(object),
      failMessage: this.t(i18nKeys.errors.requestFailed.challenges.getEventSupportDetails),
    })) as Promise<JamEventSupportDetails>;
  }

  public async enableSupportChat(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/supportChat/enable`,
      successMessage: this.t(i18nKeys.facilitator.jamSettings.success.enableSupportChat),
      failMessage: this.t(i18nKeys.facilitator.jamSettings.errors.failedEnableSupportChat),
      silent,
    });
  }

  public async disableSupportChat(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/supportChat/disable`,
      successMessage: this.t(i18nKeys.facilitator.jamSettings.success.disableSupportChat),
      failMessage: this.t(i18nKeys.facilitator.jamSettings.errors.failedDisableSupportChat),
      silent,
    });
  }

  async getJamFacilitatorParticipants(eventName: string, silent = false): Promise<JamFacilitatorParticipantsRes> {
    return (await this.apiClient.get({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/participants`,
      failMessage: this.t(i18nKeys.facilitator.participants.errors.facilitatorParticipants),
      silent,
    })) as Promise<JamFacilitatorParticipantsRes>;
  }

  getJamFacilitatorParticipantStats = async (eventName: string): Promise<JamParticpantsStats> => {
    return (await this.apiClient.get({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/count`,
      failMessage: this.t(i18nKeys.facilitator.participants.errors.facilitatorParticipantsStats),
    })) as Promise<JamParticpantsStats>;
  };

  getJamFacilitatorsAndNoTeamParticipants = async (eventName: string): Promise<FacilitatorsAndNoTeamParticpants> => {
    return (await this.apiClient.get({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/eventParticipants`,
      failMessage: this.t(i18nKeys.facilitator.participants.errors.facilitatorsNoTeamParticipants),
    })) as Promise<FacilitatorsAndNoTeamParticpants>;
  };

  async getJamFacilitatorTeamsAvailability(
    eventName: string,
    pageSize: number,
    lastToken = '',
    silent = false
  ): Promise<JamFacilitatorTeamsAvailabilityRes> {
    return (await this.apiClient.get({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/teams/availability`,
      failMessage: this.t(i18nKeys.facilitator.participants.errors.facilitatorTeamsAvailability),
      silent,
      params: { pageSize, lastToken },
    })) as Promise<JamFacilitatorTeamsAvailabilityRes>;
  }

  public async getEventIncidents(eventName: string, silent = false): Promise<JamEventIncident[]> {
    const incidents = (await this.apiClient.get({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/incidents`,
      failMessage: this.t(i18nKeys.facilitator.notifications.list.failedLoad),
      silent,
    })) as JamEventIncident[];

    // sort by most recent
    return incidents.sort((a, b) => b.time - a.time);
  }

  async getJamFacilitatorParticipantTeamProgress(
    eventName: string,
    teamName: string,
    silent = false
  ): Promise<TeamProgressResponse> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/team/progress`,
      body: { teamName },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.facilitatorTeamsAvailability),
      silent,
    })) as Promise<TeamProgressResponse>;
  }

  async restartChallenge(eventName: string, challengeId: string, teamName: string, silent = false): Promise<void> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/restartChallenge`,
      body: { teamName, challengeId },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.restartChallenge),
      silent,
    })) as Promise<void>;
  }

  async startChat(eventName: string, userId: string, silent = false): Promise<void> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/users/startChat`,
      body: { userId },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.startChat),
      silent,
    })) as Promise<void>;
  }

  async changeTeamName(eventName: string, teamName: string, alias: string, silent = false): Promise<void> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/team/name`,
      body: { teamName, alias },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.changeTeamName),
      silent,
    })) as Promise<void>;
  }

  async lockTeamName(eventName: string, teamName: string, silent = false): Promise<void> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/team/alias/lock`,
      body: { teamName },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.lockTeamName),
      silent,
    })) as Promise<void>;
  }

  async unlockTeamName(eventName: string, teamName: string, silent = false): Promise<void> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/team/alias/unlock`,
      body: { teamName },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.unlockTeamName),
      silent,
    })) as Promise<void>;
  }

  async changeTeamPassword(eventName: string, teamName: string, password: string, silent = false): Promise<void> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/team/password`,
      body: { teamName, password },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.changeTeamPassword),
      silent,
    })) as Promise<void>;
  }

  async changeTeam(eventName: string, userId: string, teamName: string, silent = false): Promise<void> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/users/assignTeam`,
      body: { eventName, userId, teamName },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.changeTeam),
      silent,
    })) as Promise<void>;
  }

  async removeFromTeam(eventName: string, userId: string, isInactive: boolean, silent = false): Promise<void> {
    return (await this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/users/unassignTeam`,
      body: { userId, isInactive },
      failMessage: this.t(i18nKeys.facilitator.participants.errors.removeFromTeam),
      silent,
    })) as Promise<void>;
  }

  async changeTeamOwner(eventName: string, teamName: string, userId: string, silent = false): Promise<void> {
    return this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/team/owner`,
      body: { teamName, userId },
      successMessage: this.t(i18nKeys.facilitator.participants.succes.teamOwnerUpdated),
      failMessage: this.t(i18nKeys.facilitator.participants.errors.failedChangeOwner),
      silent,
    }) as Promise<void>;
  }

  async downloadSSHKeyPair(eventName: string,  challengeId: string, teamName: string):  Promise<Blob> {
    return this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/labKeyPair`,
      body: { challengeId, teamName },
      responseType: 'attachment',
      successMessage: this.t(i18nKeys.facilitator.participants.succes.sshKeyPairDownload),
      failMessage: this.t(i18nKeys.facilitator.participants.errors.sshKeyPairDownload),
    }) as Promise<Blob>;
  }

  async markChallengeSolved(eventName: string,  challengeId: string, teamName: string, taskId: string):  Promise<void> {
    return this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/markChallengeSolved`,
      body: { challengeId, teamName, taskId },
      successMessage: this.t(i18nKeys.facilitator.participants.succes.markChallengeSolved),
      failMessage: this.t(i18nKeys.facilitator.participants.errors.markChallengeSolved),
    }) as Promise<void>;
  }

  async refundTaskCluePoints(eventName: string,  challengeId: string, teamName: string, taskId: string, clueOrder: number):  Promise<void> {
    return this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/refundClue`,
      body: { challengeId, teamName, taskId, clueOrder },
      successMessage: this.t(i18nKeys.facilitator.participants.succes.refundTaskCluePoints),
      failMessage: this.t(i18nKeys.facilitator.participants.errors.refundTaskCluePoints),
    }) as Promise<void>;
  }

  async autoAssignTeams(eventName: string, strategy: number):  Promise<void> {
    return this.apiClient.post({
      path: `${FACILITATOR_API_PREFIX}/${eventName}/users/autoAssign`,
      body: { strategy },
      successMessage: this.t(i18nKeys.facilitator.participants.succes.autoAssignTeams),
      failMessage: this.t(i18nKeys.facilitator.participants.errors.autoAssignTeams),
    }) as Promise<void>;
  }
}
