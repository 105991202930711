import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ContentLayout,
  Form,
  FormField,
  Header,
  Icon,
  Input,
  Modal,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextContent,
  TextFilter,
  Textarea,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useTranslation } from 'react-i18next';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { ChallengeIssue, ChallengeIssueSeverity } from '@/src/types/Challenge';
import { useApi } from '@/src/store/api.context';
import { safeString } from '@/src/utils/string.utils';
import {
  getColumnDefinitions,
  filteringFunction,
} from '../../challenges/challengeDetailSections/challengeIssues/issue-list-config';
import { TableEmptyState } from '../../common/TableEmptyState';
import { TableHeader } from '../../common/TableHeader';
import { paginationLabels } from '../../event-templates/CustomTable/table-config';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import JamChallengeHeader from '../JamChallengeHeader/JamChallengeHeader';
import { useParams } from 'react-router-dom';

const JamIssue = () => {
  const { t } = useTranslation();
  const { challengesApi, jamFacilitatorApi } = useApi();
  const { clearFlashbars } = useFlashbars();
  const { 0: eventId, 1: jamChallengeId}: { 0: string; 1: string } = useParams();
  const { selectedJamChallenge, loadJamChallengeDetails } = useJamChallengeDetails();
  const [challengeIssues, setChallengeIssues] = useState<ChallengeIssue[] | undefined>();
  const { eventName } = useJamEventDetails();
  const { issue } = i18nKeys.challenges.challengeDetails.table;

  const severityOptions = [
    {
      value: ChallengeIssueSeverity.CRITICAL,
      label: `1-${t(issue.severity.criticalLabel)}:${t(issue.severity.criticalDescription)}`,
    },
    {
      value: ChallengeIssueSeverity.HIGH,
      label: `2-${t(issue.severity.higLlabel)}:${t(issue.severity.highDescription)}`,
    },
    {
      value: ChallengeIssueSeverity.MEDIUM,
      label: `3-${t(issue.severity.mediumLabel)}:${t(issue.severity.mediumDescription)}`,
    },
    {
      value: ChallengeIssueSeverity.LOW,
      label: `4-${t(issue.severity.lowLabel)}:${t(issue.severity.lowDescription)}`,
    },
  ];

  const [newIssueVisible, setNewIssueVisible] = useState(false);
  const [issueTitle, setIssueTitle] = useState('');
  const [issueDescription, setIssueDescription] = useState('');
  const [severity, setSeverity] = useState<OptionDefinition | null>(severityOptions[3]);

  const [showIssueDetailModal, setShowIssueDetailModal ] = useState(false)
  const [selectedIssue, setSelectedIssue] = useState<ChallengeIssue>()

  useEffect(() => {
    clearFlashbars();
    void loadChallengeIssues();
  }, [jamChallengeId]);

  useLayoutEffect(() => {
    clearFlashbars();
  }, []);

  useEffect(() => {
    if (!selectedJamChallenge) {
      void loadJamChallengeDetails({id: eventId, challengeId: jamChallengeId})
    }
  }, [selectedJamChallenge, jamChallengeId, eventId])

  const loadChallengeIssues = async () => {
    if (jamChallengeId) {
      setChallengeIssues(await challengesApi.getChallengeIssuesByChallengeId(safeString(jamChallengeId)));
    }
  };
  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['title', 'createdDate', 'openedBy', 'status', 'severity', 'comments'],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    challengeIssues || [],
    {
      filtering: {
        filteringFunction,
        empty: <TableEmptyState title={t(i18nKeys.challenges.challengeDetails.table.emptyIssuesState)} />,
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const getIssues = async () => {
    clearFlashbars();
    setChallengeIssues(await challengesApi.getChallengeIssuesByChallengeId(safeString(jamChallengeId)));
  };

  const onRefresh = () => {
    void getIssues();
  };

  const isValid = (): boolean => {
    return jamChallengeId !== null && issueTitle !== '' && issueDescription !== '';
  };

  const onIssueSubmit = () => {
    if (!isValid()) {
      return;
    }
    void jamFacilitatorApi.createChallengeIssue(
      safeString(eventName),
      safeString(jamChallengeId),
      issueTitle,
      issueDescription,
      safeString(severity?.value) as ChallengeIssueSeverity
    );
    void getIssues();
    resetModal();
    setNewIssueVisible(false);
  };

  const resetModal = () => {
    setIssueTitle('');
    setSeverity(severityOptions[3]);
    setIssueDescription('');
  };

  const onIssueClicked = useCallback((issueItem: ChallengeIssue) => {
    setShowIssueDetailModal(true)
    setSelectedIssue(issueItem)
  }, [])

  const onIssueModalClosed = useCallback(() => {
    setShowIssueDetailModal(false)
    setSelectedIssue(undefined)
  }, [])

  const columnDefinitions = useMemo(() => {
    return getColumnDefinitions(onIssueClicked)
  }, [onIssueClicked])

  return (
    <ContentLayout header={<Header variant="h1">{selectedJamChallenge?.title ?? 'Untitled Jam Challenge'}</Header>}>
      <SpaceBetween direction="vertical" size="xxl">
        <JamChallengeHeader hideProperties />
        <Box margin={{ vertical: 'm' }}>
          <Table
            {...collectionProps}
            variant="container"
            resizableColumns
            header={
              <TableHeader
                totalItems={challengeIssues ? challengeIssues.length : 0}
                title={t(i18nKeys.challenges.challengeDetails.headings.issues)}
                toggleTools={() => alert('challenge list tools not implemented yet')}
                actionButtons={
                  <SpaceBetween direction="horizontal" size="s">
                    <Button onClick={() => onRefresh()}>
                      <Icon name="refresh" />
                      <Box margin={{ left: 'xs' }} variant="span" color="text-status-info">
                        {t(i18nKeys.challenges.challengeDetails.table.button.refresh)}
                      </Box>
                    </Button>
                    <Button variant="primary" onClick={() => setNewIssueVisible(true)}>
                      {t(i18nKeys.challenges.challengeDetails.table.button.openNewIssue)}
                    </Button>
                  </SpaceBetween>
                }
              />
            }
            columnDefinitions={columnDefinitions}
            items={items}
            loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingIssues)}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
            filter={
              <TextFilter
                {...filterProps}
                countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
                filteringAriaLabel={t(i18nKeys.challenges.filteringLabel)}
              />
            }
          />
          <Modal
            visible={newIssueVisible}
            onDismiss={() => setNewIssueVisible(false)}
            size="large"
            closeAriaLabel={t(i18nKeys.general.close)}
            header={
              <TextContent>
                <h3>{t(i18nKeys.challenges.challengeDetails.table.issue.newIssue)}</h3>
              </TextContent>
            }>
            <Form
              actions={
                <SpaceBetween size={'s'} direction="horizontal">
                  <Button
                    ariaLabel={t(i18nKeys.challenges.challengeDetails.table.button.cancle)}
                    variant="link"
                    onClick={() => setNewIssueVisible(false)}>
                    {t(i18nKeys.challenges.challengeDetails.table.button.cancle)}
                  </Button>
                  <Button
                    ariaLabel={t(i18nKeys.challenges.challengeDetails.table.button.submit)}
                    variant="primary"
                    onClick={() => onIssueSubmit()}>
                    {t(i18nKeys.challenges.challengeDetails.table.button.submit)}
                  </Button>
                </SpaceBetween>
              }>
              <Box margin={{ top: 'l' }}>
                <FormField label={`${t(i18nKeys.challenges.challengeDetails.table.issue.titleLabel)}`}>
                  <Input value={issueTitle} type="text" onChange={({ detail }) => setIssueTitle(detail.value)} />
                </FormField>
              </Box>
              <Box margin={{ top: 'l' }}>
                <FormField label={`${t(i18nKeys.challenges.challengeDetails.table.issue.severityLabel)}`}>
                  <Select
                    selectedOption={severity}
                    options={severityOptions}
                    onChange={({ detail }) => setSeverity(detail.selectedOption)}
                  />
                </FormField>
              </Box>
              <Box margin={{ top: 'l' }}>
                <FormField label={`${t(i18nKeys.challenges.challengeDetails.table.issue.descriptionLabel)}`} stretch>
                  <Textarea
                    value={issueDescription}
                    placeholder={t(i18nKeys.challenges.challengeDetails.table.issue.descriptionPlaceholder)}
                    onChange={({ detail }) => setIssueDescription(detail.value)}
                  />
                </FormField>
              </Box>
              <Box margin={{ top: 'l' }} padding={{ right: 'xxs' }}>
                <div className="grey-section-divider-top" />
              </Box>
            </Form>
          </Modal>

          <Modal
            visible={showIssueDetailModal}
            onDismiss={onIssueModalClosed}
            size="large"
            closeAriaLabel={t(i18nKeys.general.close)}
            header={
              <TextContent>
                <h3>{selectedIssue?.title}</h3>
              </TextContent>
            }
            footer={
              <Box float="right">
                <Button variant="primary" onClick={onIssueModalClosed}>{t(i18nKeys.general.ok)}</Button>
              </Box>
            }>
            <p>{selectedIssue?.description}</p>
          </Modal>
        </Box>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default JamIssue;
