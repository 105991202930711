import { ExpandableSection, FormField, Header, Input, Textarea } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengePropAction,
  ChallengeSettingsFields,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { Challenge, Sponsor } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { KeyValue } from '../../../common/KeyValue';

interface ChallengeSponsorshipSettingDetailProps {
  challenge: Challenge;
}

const ChallengeSponsorshipSettingDetail: React.FC<ChallengeSponsorshipSettingDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp, challengeSettingsValidator } = useCreateChallenge();

  const field = i18nKeys.challenges.subSections.settings;
  const [sponsorSettings, setSponsorSettings] = useState<Sponsor>({
    name: editedChallenge?.props.sponsor?.name || '',
    url: editedChallenge?.props.sponsor?.url || '',
    logo: editedChallenge?.props.sponsor?.logo || '',
    description: editedChallenge?.props.sponsor?.description || '',
  });

  useEffect(() => {
    // reset error fields when editMode is changed
    if (!editMode) {
      setSponsorNameErrorText('');
      setSponsorUrlErrorText('');
      setSponsorLogoUrlErrorText('');
      setChallengeIconErrorText('');
    }
  }, [editMode]);

  const validator = challengeSettingsValidator(
    new Map<ChallengeSettingsFields, (error: string) => void>([
      [ChallengeSettingsFields.SPONSOR_NAME, (error: string) => setSponsorNameErrorText(error)],
      [ChallengeSettingsFields.SPONSOR_URL, (error: string) => setSponsorUrlErrorText(error)],
      [ChallengeSettingsFields.SPONSOR_LOGO, (error: string) => setSponsorLogoUrlErrorText(error)],
      [ChallengeSettingsFields.CHALLENGE_ICON, (error: string) => setChallengeIconErrorText(error)],
    ])
  );

  const [sponsorNameErrorText, setSponsorNameErrorText] = useState<string>('');
  const [sponsorUrlErrorText, setSponsorUrlErrorText] = useState<string>('');
  const [sponsorLogoUrlErrorText, setSponsorLogoUrlErrorText] = useState<string>('');
  const [challengeIconErrorText, setChallengeIconErrorText] = useState<string>('');

  const [advancedSettings, setAdvancedSettings] = useState({
    challengeIcon: editedChallenge?.props.challengeIcon || '',
    defaultLabProvider: editedChallenge?.props.defaultLabProvider,
    challengeAlwaysOn: editedChallenge?.props.challengeAlwaysOn,
    mode: editedChallenge?.props.mode,
    allowlistServicesRequired: editedChallenge?.props.allowlistServicesRequired,
    idleMinsBeforeReady: editedChallenge?.props.idleMinsBeforeReady,
  });

  React.useEffect(() => {
    if (editedChallenge) {
      setSponsorSettings({
        name: editedChallenge.props.sponsor?.name || '',
        url: editedChallenge.props.sponsor?.url || '',
        logo: editedChallenge.props.sponsor?.logo || '',
        description: editedChallenge.props.sponsor?.description || '',
      });

      setAdvancedSettings({
        challengeIcon: editedChallenge.props.challengeIcon || '',
        defaultLabProvider: editedChallenge.props.defaultLabProvider,
        challengeAlwaysOn: editedChallenge.props.challengeAlwaysOn,
        mode: editedChallenge.props.mode,
        allowlistServicesRequired: editedChallenge.props.allowlistServicesRequired,
        idleMinsBeforeReady: editedChallenge.props.idleMinsBeforeReady,
      });
    }
  }, [editMode]);

  const handleSponsorSettingsUpdate = (event: { name: string; value: string }) => {
    const { name, value } = event;
    const newSponsorSettings = {
      ...sponsorSettings,
      [name]: value,
    };

    handleUpdateChallengeProp(ChallengePropAction.SPONSOR_SETTINGS, newSponsorSettings);
    setSponsorSettings(newSponsorSettings);
  };

  const handleAdvancedSettingsUpdate = (event: {
    target: { name: ChallengeSettingsFields; value: boolean | string | readonly OptionDefinition[] | undefined };
  }) => {
    const { name, value } = event.target;
    const uiSettings = {
      ...advancedSettings,
      [name]: value,
    };
    setAdvancedSettings(uiSettings);
    handleUpdateChallengeProp(ChallengePropAction.ADVANCED_SETTINGS, uiSettings);
  };

  const [expanded, setExpanded] = useState(true);

  return (
    <ExpandableSection
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.sponsorshipSettings)}</Header>}>
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.sponsor)}>
        {!editMode && challenge.props.sponsor && challenge.props.sponsor.name}
        {!editMode && !challenge.props.sponsor && t(i18nKeys.general.none)}
        {editMode && (
          <FormField errorText={sponsorNameErrorText}>
            <Input
              onBlur={() => validator.isValidField(ChallengeSettingsFields.SPONSOR_NAME)}
              onChange={({ detail }) =>
                handleSponsorSettingsUpdate({ name: ChallengeSettingsFields.SPONSOR_NAME, value: detail.value })
              }
              name="name"
              value={editedChallenge?.props.sponsor?.name || ''}
              autoComplete
              placeholder={t(i18nKeys.challenges.subSections.settings.container_4.fields.sponsor.placeholder)}
            />
          </FormField>
        )}
      </KeyValue>
      <HorizontalRule />
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.url)}>
        {!editMode && challenge.props.sponsor?.url && challenge.props.sponsor.url}
        {!editMode && !challenge.props.sponsor?.url && t(i18nKeys.general.none)}
        {editMode && (
          <FormField errorText={sponsorUrlErrorText}>
            <Input
              onBlur={() => validator.isValidField(ChallengeSettingsFields.SPONSOR_URL)}
              onChange={({ detail }) =>
                handleSponsorSettingsUpdate({ name: ChallengeSettingsFields.SPONSOR_URL, value: detail.value })
              }
              name="url"
              value={editedChallenge?.props.sponsor?.url || ''}
              inputMode="url"
              autoComplete
              placeholder={t(i18nKeys.challenges.subSections.settings.container_4.fields.url.placeholder)}
            />
          </FormField>
        )}
      </KeyValue>
      <HorizontalRule />
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.icon)}>
        {!editMode && challenge.props.challengeIcon && challenge.props.challengeIcon}
        {!editMode && !challenge.props.challengeIcon && t(i18nKeys.general.none)}
        {editMode && (
          <FormField constraintText={t(field.container_4.fields.icon.constraint)} errorText={challengeIconErrorText}>
            <Input
              onBlur={() => validator.isValidField(ChallengeSettingsFields.CHALLENGE_ICON)}
              onChange={({ detail }) =>
                handleAdvancedSettingsUpdate({
                  target: { name: ChallengeSettingsFields.CHALLENGE_ICON, value: detail.value },
                })
              }
              value={editedChallenge?.props.challengeIcon || ''}
              inputMode="url"
              placeholder={t(i18nKeys.challenges.subSections.settings.container_4.fields.icon.placeholder)}
              autoComplete
            />
          </FormField>
        )}
      </KeyValue>
      <HorizontalRule />
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.logo)}>
        {!editMode && challenge.props.sponsor?.logo && challenge.props.sponsor.logo}
        {!editMode && !challenge.props.sponsor?.logo && t(i18nKeys.general.useDefault)}
        {editMode && (
          <FormField errorText={sponsorLogoUrlErrorText} constraintText={t(field.container_4.fields.logo.constraint)}>
            <Input
              onBlur={() => validator.isValidField(ChallengeSettingsFields.SPONSOR_LOGO)}
              onChange={({ detail }) =>
                handleSponsorSettingsUpdate({ name: ChallengeSettingsFields.SPONSOR_LOGO, value: detail.value })
              }
              name="url"
              value={editedChallenge?.props.sponsor?.logo || ''}
              inputMode="url"
              autoComplete
              placeholder={t(i18nKeys.challenges.subSections.settings.container_4.fields.url.placeholder)}
            />
          </FormField>
        )}
      </KeyValue>
      <HorizontalRule />
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.description)}>
        {!editMode && challenge.props.sponsor?.description && challenge.props.sponsor.description}
        {!editMode && challenge.props.sponsor?.description && t(i18nKeys.general.none)}
        {editMode && (
          <FormField>
            <Textarea
              onChange={({ detail }) =>
                handleSponsorSettingsUpdate({ name: ChallengeSettingsFields.SPONSOR_DESCRIPTION, value: detail.value })
              }
              value={editedChallenge?.props.sponsor?.description || ''}
              placeholder={t(i18nKeys.challenges.subSections.settings.container_4.fields.description.placeholder)}
            />
          </FormField>
        )}
      </KeyValue>
    </ExpandableSection>
  );
};

export default ChallengeSponsorshipSettingDetail;
