import { useTranslation } from 'react-i18next';
import { Container, DatePicker, FormField, Header, Input, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { EditUsagePlanActions, usePlans } from '../../../store/usage-plan/usage-plan.context';
import { DateString } from '../../../types/common';
import { UsagePlan } from '../../../types/usage-plan/UsagePlan';
import { getLanguageCodeSafe } from '../../../utils/locale.utils';

interface CreateUsagePlanDetailsProps {
  target: UsagePlan | undefined;
  errorText: string;
}

const CreateUsagePlanDetails: React.FC<CreateUsagePlanDetailsProps> = ({ target, errorText }) => {
  const { i18n, t } = useTranslation();
  const { handleUpdateUsagePlan } = usePlans();
  const [planName, setPlanName] = useState(target?.name || '');
  const [planNameErr, setPlanNameErr] = useState('');
  const [expirationDate, setExpirationDate] = useState(target?.expiration || '');
  const [expirationDateErr, setExpirationDateErr] = useState('');
  const [description, setDescription] = useState(target?.description || '');
  const [descriptionErr, setDescriptionErr] = useState('');
  const [notes, setNotes] = useState(target?.notes || '');
  const [notesErr, setNotesErr] = useState('');

  const languageCode: string = getLanguageCodeSafe(i18n.language);

  useEffect(() => {
    // if this string is set, it means the usage plan failed validation on save in the parent component
    // in that case, re-validate the inputs on this component and update the UI with individual error strings if applicable
    if (errorText) {
      onNameChange(planName);
      onExpirationChange(expirationDate);
      onDescriptionChange(description);
      onNotesChange(notes);
    }
  }, [errorText]);

  const onNameChange = (newName: string) => {
    setPlanName(newName);
    setPlanNameErr(handleUpdateUsagePlan(EditUsagePlanActions.NAME, newName));
  };

  const onExpirationChange = (newExpiration: DateString) => {
    setExpirationDate(newExpiration);
    setExpirationDateErr(handleUpdateUsagePlan(EditUsagePlanActions.EXPIRATION, newExpiration));
  };

  const onDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    setDescriptionErr(handleUpdateUsagePlan(EditUsagePlanActions.DESCRIPTION, newDescription));
  };

  const onNotesChange = (newNotes: string) => {
    setNotes(newNotes);
    setNotesErr(handleUpdateUsagePlan(EditUsagePlanActions.NOTES, newNotes));
  };

  return (
    <Container header={<Header variant={'h2'}>{t(i18nKeys.usagePlan.sections.details)}</Header>}>
      <SpaceBetween direction={'vertical'} size={'l'}>
        {/* Name */}
        <FormField errorText={planNameErr} label={t(i18nKeys.usagePlan.fields.name.title)}>
          <Input
            value={planName}
            onChange={({ detail }) => onNameChange(detail.value)}
            placeholder={t(i18nKeys.usagePlan.fields.name.placeholder)}
            ariaRequired
          />
        </FormField>

        {/* Expiration date */}
        <FormField
          errorText={expirationDateErr}
          label={t(i18nKeys.usagePlan.fields.expiration.title) + ' (Optional)'}
          description={t(i18nKeys.usagePlan.fields.expiration.description)}>
          <DatePicker
            className="inline"
            onChange={({ detail }) => onExpirationChange(detail.value)}
            value={expirationDate}
            openCalendarAriaLabel={(selectedDate) =>
              t(i18nKeys.general.chooseDate) + (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
            }
            locale={languageCode}
            previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
            nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
            todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
          />
        </FormField>

        {/* Description */}
        <FormField
          errorText={descriptionErr}
          label={t(i18nKeys.usagePlan.fields.description.title)}
          description={t(i18nKeys.usagePlan.fields.description.description)}>
          <Textarea
            value={description}
            onChange={({ detail }) => onDescriptionChange(detail.value)}
            placeholder={t(i18nKeys.usagePlan.fields.description.placeholder)}
          />
        </FormField>

        {/* Notes  */}
        <FormField
          errorText={notesErr}
          label={t(i18nKeys.usagePlan.fields.notes.title)}
          description={t(i18nKeys.usagePlan.fields.notes.description)}>
          <Textarea
            value={notes}
            onChange={({ detail }) => onNotesChange(detail.value)}
            placeholder={t(i18nKeys.usagePlan.fields.notes.placeholder)}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default CreateUsagePlanDetails;
