import React from 'react';
import JamSpinner from './JamSpinner';

const AbsLoader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000,
      }}>
      <JamSpinner />
    </div>
  );
};

export default AbsLoader;
