/* eslint-disable @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-unsafe-call */
import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { GAME_API_PREFIX } from '../constants/api';
import { JamEventTeamGoal } from '../types/JamCommon';
import { i18nKeys } from '../utils/i18n.utils';
import { JamTeamDetails, JamTeamItem } from '../types/JamTeam';

export class JamTeamAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * change the name of the team
   *
   * @param eventName string
   * @param alias string
   * @param silent boolean
   */
  async renameTeam(eventName: string, alias: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/event/${eventName}/team/alias`,
      body: { alias },
      successMessage: this.t(i18nKeys.jamTeam.updatedTeamName),
      failMessage: this.t(i18nKeys.jamTeam.errors.failedRename),
      silent,
    });
  }

  /**
   * change the team password through which
   * event participant can join team owner's team
   *
   * @param eventName string
   * @param password string
   * @param silent boolean
   */
  async changeTeamPassword(eventName: string, password: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/event/${eventName}/team/password`,
      body: { password: password || null },
      successMessage: this.t(i18nKeys.jamTeam.teamPasswordUpdate),
      failMessage: this.t(i18nKeys.jamTeam.errors.failedPasswordUpdate),
      silent,
    });
  }

  /**
   * change the goal of the team
   * possible values are
   * Play to learn
   * play to win
   *
   * @param eventName string
   * @param goal enum
   * @param silent boolean
   */
  async changeTeamGoal(eventName: string, goal: JamEventTeamGoal, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/event/${eventName}/team/goal`,
      body: { goal },
      successMessage: this.t(i18nKeys.jamTeam.updatedTeamGoal),
      failMessage: this.t(i18nKeys.jamTeam.errors.failedTeamGoalUpdate),
      silent,
    });
  }

  /**
   * while onboarding to an event
   * participant can either join existing team
   * or if he has no access or wants his own team
   * can create team with or without password
   *
   * this method creates new team
   *
   * @param teamName string
   * @param teamGoal enum
   * @param password string | null
   * @param eventName string
   * @returns boolean
   */
  async createTeam(
    teamName: string,
    teamGoal: JamEventTeamGoal,
    password: string | null = null,
    eventName: string
  ): Promise<boolean> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/event/${eventName}/team`,
      body: { teamName, password, teamGoal },
    });

    return true;
  }

  /**
   * while onboarding to an event
   * participant can either join existing team
   * or if he has no access or wants his own team
   * can create team with or without password
   *
   * this method joins to an existing team
   *
   * @param teamName string
   * @param password string | null
   * @param eventName string
   * @returns boolean
   */
  async joinTeam(teamName: string, password: string | null = null, eventName: string): Promise<boolean> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/event/${eventName}/team/member`,
      body: { teamName, password },
    });

    return true;
  }

  async getTeamDetails(eventName: string, teamId: string): Promise<JamTeamDetails> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/event/${eventName}/team/${teamId}`,
      responseMapper: JamTeamDetails.fromPlainObject,
      failMessage: this.t(i18nKeys.jamTeam.errors.failedTeamDetails),
    })) as Promise<JamTeamDetails>;
  }

  async getEventTeams(eventName: string) {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/event/${eventName}/teams`,
      responseMapper: (response) => (response.teams || []).map(JamTeamItem.fromPlainObject),
      failMessage: this.t(i18nKeys.jamTeam.errors.failedTeams),
    })) as Promise<JamTeamItem[]>;
  }
}
