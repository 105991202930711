export enum RoutePath {
  ROOT = '/',
  OAUTH2 = '/oauth2',
  SIGNOUT = '/signout',
  EVENTS = '/my-events',
  NEW_EVENT = '/events/new',
  EVENT_DETAILS = '/events/:eventName',
  CHALLENGES = '/challenges',
  LABS = '/labs',
  NEW_CAMPAIGN = '/campaigns/new',
  CAMPAIGNS = '/campaigns',
  CHALLENGE_SETS = '/challenge-set',
  CHALLENGE_SET_DETAILS = '/challenge-set/:challengeSetId',
  NEW_CHALLENGE_SET = '/challenge-set/new',
  CAMPAIGN_DETAILS = '/campaigns/:campaignId',
  CAMPAIGN_GROUP_DETAILS = '/campaigns/:campaignId/groups/:eventName',
  CHALLENGE_DETAILS = '/challenges/:challengeId',
  NEW_CHALLENGE = '/challenges/new',
  CHALLENGE_SNIPPETS = '/challenges/snippets',
  GAME_BOARDS = '/challenges/boards',
  GAME_BOARDS_V1 = '/v1/challenges/boards',
  REVIEW_CHALLENGES = '/review-challenges',
  ACCOUNT = '/account',
  USAGE_PLANS = '/usage-plans',
  NEW_USAGE_PLAN = '/usage-plans/create',
  USAGE_PLAN_DETAILS = '/usage-plans/:planId',
  REPORTS = '/reports',
  PRIZES = '/prizes',
  SETTINGS = '/settings',
  DOCUMENTATION = '/documentation',
  DOCUMENTATION_V1 = '/v1/documentation',
  EVENT_CATALOG_TEMPLATES = '/event-catalog-templates',
  EVENT_CATALOG_TEMPLATE_CREATE = '/event-catalog-templates/create',
  EVENT_CATALOG_TEMPLATE_DETAILS = '/event-catalog-templates/:eventTemplateId',
  CATALOG = '/events',
  CATALOG_DETAIL = '/events/details',
  CATALOG_DETAILS = '/events/details/:catalogId',
  JAM_PROFILE_CREATE = '/create-jam-profile',
  MY_JAMS = '/jam',
  MY_JAMS_ACTIVE = '/active-events',
  MY_JAMS_PAST = '/past-events',
  MY_JAMS_UPCOMING = '/upcoming-events',
  JOIN_AN_EVENT = '/join-an-event',
  JAM_EVENT = '/:eventId',
  JAM_EVENT_ONBOARDING = '/:eventId/onboarding',
  JOIN_TEAM = '/:eventId/onboarding/:joinOption',
  JAM_CHALLENGE = '/:eventId/challenges/:challengeId/overview',
  JAM_CHALLENGE_TASK = '/:eventId/challenges/:challengeId/:taskId',
  JAM_EVENT_TEAM = '/:eventId/team',
  FAQ = '/faq',
  ADMIN = '/admin',
  FAQ_SECTION = '/faq/:section',
  JAM_PROFILE = '/profile',
  JAM_PROFILE_DETAILS = '/profile/detail',
  USER_PROFILE_CREATE = '/profile/create',
  DATA_AND_PRIVACY = '/profile/data-and-privacy',
  JAM_SKILL_PROFILE = '/profile/skill-detail',
  JAM_PRIZES = '/profile/prizes',
  JAM_FACILITATOR = '/:eventId/facilitator',
  JAM_FACILITATOR_MESSAGING = '/:eventId/facilitator/messaging',
  JAM_404 = '/404',
  JAM_403 = '/403',
  JAM_500 = '/500',
  JAM_FACILITATOR_SUPPORT_CHATS = '/:eventId/facilitator/support-chats',
  JAM_FACILITATOR_JAM_SETTINGS = '/:eventId/facilitator/settings',
  JAM_FACILITATOR_NOTIFICATIONS = '/:eventId/facilitator/notifications',
  JAM_FACILITATOR_PARTICIPANTS = '/:eventId/facilitator/participants',
  JAM_MESSAGES = '/:eventId/messages',
  ACTIVE_EVENTS = '/active-events',
  UPCOMING_EVENTS = '/upcoming-events',
  PAST_EVENTS = '/past-events',
  PUBLIC_EVENTS = '/public-events',
  JAM_CAMPAIGN = '/campaigns/:campaignSlug/:campaignGroupId',
  SSH_ACCESS = '/challenges-ssh-access',
  CHALLENGE_RESOURCES = '/challenges-resources/',
  CHALLENGES_RESOURCES = '/challenges-resources/:challengeId',
  CHALLENGES_SNIPPETS = '/challenges-snippets',
  CHALLENGES_SNIPPETS_V1 = '/v1/challenges/snippets',
  SHOW_I_AM = '/show-i-am',
}
