const randomNumber = () => Math.ceil(Math.random() * 1000000000000);

/**
 * Generate a universally random string.
 * Prefixed with epoch time to limit the change of duplicated to within the same millisecond.
 * Add 3 random 12 digit numbers to eliminate the chance of duplicates within the same millisecond.
 */
export const randomId = (): string => {
  return `${Date.now()}-${randomNumber()}-${randomNumber()}-${randomNumber()}`;
};
