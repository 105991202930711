import React from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface ChallengeNotCompleteProps {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChallengeNotCompleteModal: React.FC<ChallengeNotCompleteProps> = ({ value, setValue }) => {
  const { t } = useTranslation();
  const handleModal = () => setValue(!value);
  return (
    <Modal
      onDismiss={handleModal}
      visible={value}
      footer={
        <Box float="right" margin={{ vertical: 'xs' }}>
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={handleModal}>
              {t(i18nKeys.general.close)}
            </Button>
          </SpaceBetween>
        </Box>
      }
      disableContentPaddings>
      <Box className="not-complete-modal">
        <SpaceBetween direction="vertical" size="xxxs">
          <Box variant="h4">{t(i18nKeys.myJams.challenges.details.task.modal.challengeNotComplete.title)}</Box>
          <Box variant="p">{t(i18nKeys.myJams.challenges.details.task.modal.challengeNotComplete.subtitle)}</Box>
        </SpaceBetween>
        <img src="/assets/Mask group.png" alt="mask-group" />
      </Box>
    </Modal>
  );
};

export default ChallengeNotCompleteModal;
