import { JamJoinTeamOptions } from '@/src/types/JamCommon';
import { i18nKeys } from '@/src/utils/i18n.utils';

export const JoinTeamHeaders = {
  [JamJoinTeamOptions.createTeam]: i18nKeys.joinTeam.joinOptions.createTeam,
  [JamJoinTeamOptions.matchTeam]: i18nKeys.joinTeam.joinOptions.matchMeTeam,
  [JamJoinTeamOptions.joinSpecificTeam]: i18nKeys.joinTeam.joinOptions.joinSpecificTeam,
};

export type JoinTeamHeadersKey = keyof typeof JoinTeamHeaders;
