import React from 'react';
import { Box, Button, Modal, Select, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { RestartChallengeReason } from '@/src/types/JamCommon';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';

interface RestartChallengeModalProps {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const RestartChallengeModal: React.FC<RestartChallengeModalProps> = ({ value, setValue }) => {
  const { t } = useTranslation();
  const { eventName } = useJamEventDetails();
  const { isStartingChallange, reStartJamChallenge, jamChallengeId } = useJamChallengeDetails();

  const { restartChallenge } = i18nKeys.myJams.challenges.restart.prompt;
  const [selectedOption, setSelectedOption] = React.useState<OptionDefinition>({
    label: t(restartChallenge.reasons.stuckReason),
    value: RestartChallengeReason.stuckReason,
  });
  const handleModal = () => setValue(!value);

  const handleRestartChallengeReason = () => {
    void reStartJamChallenge({
      id: eventName,
      challengeId: jamChallengeId,
      reason: selectedOption.value ?? '',
    }).finally(() => handleModal());
  };

  return (
    <Modal
      onDismiss={handleModal}
      visible={value}
      header={
        <SpaceBetween size="xs" direction="horizontal">
          <img src="/assets/aws-jam-logo-70x70.png" alt="logo" style={{ width: '40px' }} />
          <Box variant="h2">{t(restartChallenge.title)}</Box>
        </SpaceBetween>
      }
      footer={
        <Box float="right" margin={{ vertical: 'xs' }}>
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleModal}>{t(restartChallenge.button.cancel)}</Button>
            <Button variant="primary" onClick={handleRestartChallengeReason} disabled={isStartingChallange}>
              {isStartingChallange && (
                <Box variant="span" margin={{ right: 's' }}>
                  <Spinner />
                </Box>
              )}
              {t(restartChallenge.button.continue)}
            </Button>
          </SpaceBetween>
        </Box>
      }>
      <SpaceBetween size="m" direction="vertical">
        <Box>{t(restartChallenge.message)}</Box>
        <Select
          selectedOption={selectedOption}
          onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
          options={[
            { label: t(restartChallenge.reasons.stuckReason), value: RestartChallengeReason.stuckReason },
            {
              label: t(restartChallenge.reasons.badDeploymentReason),
              value: RestartChallengeReason.badDeploymentReason,
            },
            { label: t(restartChallenge.reasons.retryReason), value: RestartChallengeReason.retryReason },
            { label: t(restartChallenge.reasons.testingReason), value: RestartChallengeReason.testingReason },
            { label: t(restartChallenge.reasons.otherReason), value: RestartChallengeReason.otherReason },
          ]}
        />
      </SpaceBetween>
    </Modal>
  );
};

export default RestartChallengeModal;
