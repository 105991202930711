import React from 'react';
import {Box, FormField, RadioGroup, SpaceBetween, Textarea} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface LearnSomethingAndFeedbackProps {
  radio: boolean | string;
  radioConstraintText?: string;
  textArea: string;
  textAreaConstraintText?: string;
  radioName: string;
  textAreaName: string;
  setValue: (name: string, value: string) => void;
}

const LearnSomethingAndFeedback: React.FC<LearnSomethingAndFeedbackProps> = ({
  radio,
  radioConstraintText,
  textArea,
  textAreaConstraintText,
  radioName,
  textAreaName,
  setValue,
}) => {
  const { t } = useTranslation();
  return (
    <SpaceBetween direction="vertical" size="m">
      <Box variant="div">
        <FormField label={t(i18nKeys.myJams.feedback.radio.learnSomethingNew)} constraintText={radioConstraintText}>
          <RadioGroup
            onChange={({ detail }) => setValue(radioName, detail.value)}
            value={String(radio)}
            items={[
              { value: 'yes', label: t(i18nKeys.myJams.feedback.radio.yes) },
              { value: 'no', label: t(i18nKeys.myJams.feedback.radio.no) },
            ]}
            data-testid="learn-something-radio-group"
          />
        </FormField>
      </Box>
      <Box variant="div" className="feedback-textarea">
        <FormField label={t(i18nKeys.myJams.feedback.label.additionalFeedback)} constraintText={textAreaConstraintText}>
          <Textarea
            onChange={({ detail }) => setValue(textAreaName, detail.value)}
            value={textArea}
            placeholder={t(i18nKeys.general.optional)}
            data-testid="additional-feedback-textarea"
          />
        </FormField>
      </Box>
    </SpaceBetween>
  );
};
export default LearnSomethingAndFeedback;
