import React from 'react';
import './CustomBadge.scss';

interface customBadge {
  color: string;
}
export const CustomBadge: React.FC<customBadge> = ({ children, ...props }) => {
  const { color } = props;

  return (
    <span className="custom-badge" style={{ backgroundColor: `${color}` }}>
      {children}
    </span>
  );
};
