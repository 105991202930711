import { useEffect } from 'react';

/**
 * This hook allows applying an effect to a component after only the first render.
 * 
 * @param func
 */
export const useComponentDidMountEffect = (func: () => Promise<any>) => {
  useEffect(() => {
    void func().then(() => true);
    // eslint-disable-next-line
  }, []);
  // passing [] as the second argument ensures this will only be triggered on the initial render
  // since the effect is triggered whenever the second argument changes.
};
