import React from 'react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Container, Header, RadioGroup } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

interface JamSkillProfileFormProps {
  isModal?: boolean;
  value: string;
  setValue: (value: string) => void;
}

const Div: React.FC<{ children: React.ReactNode }> = ({ children }) => <div>{children}</div>;

const JamSkillProfileForm: React.FC<JamSkillProfileFormProps> = ({ isModal, value, setValue }) => {
  const { t } = useTranslation();
  const Wrapper = isModal ? Div : Container;
  const config = isModal ? {} : { disableHeaderPaddings: true };

  return (
    <Wrapper {...config}>
      <Header className="form-space" variant="h2" description={t(i18nKeys.JamProfile.jamSkillProfile.page.desc)}>
        {t(i18nKeys.JamProfile.jamSkillProfile.page.title)}
      </Header>
      <div>
        <h3>{t(i18nKeys.JamProfile.jamSkillProfile.page.radio.title)}</h3>
        <RadioGroup
          className="awsui_native-input_1wepg_12w0t_106"
          onChange={({ detail }) => setValue(detail.value)}
          value={value}
          items={[
            {
              value: '1',
              label: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.beginner),
              description: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.beginnerD),
              controlId: 'proficiencyRatingRadio1',
            },
            {
              value: '2',
              label: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.basicUser),
              description: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.basicUserD),
              controlId: 'proficiencyRatingRadio2',
            },
            {
              value: '3',
              label: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.intermediateUser),
              description: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.intermediateUserD),
              controlId: 'proficiencyRatingRadio3',
            },
            {
              value: '4',
              label: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.advancedUser),
              description: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.advancedUserD),
              controlId: 'proficiencyRatingRadio4',
            },
            {
              value: '5',
              label: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.expert),
              description: t(i18nKeys.JamProfile.jamSkillProfile.page.radio.expertD),
              controlId: 'proficiencyRatingRadio5',
            },
          ]}
        />
      </div>
    </Wrapper>
  );
};

export default JamSkillProfileForm;
