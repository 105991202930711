import React from 'react';
import { Button, Header } from '@amzn/awsui-components-react';

import './DetailHeader.scss';

interface IDetailHeaderProps {
  heading: string;
  marginTop?: number;
  onEditClick: () => void;
}

export const DetailHeader: React.FC<IDetailHeaderProps> = ({ heading, onEditClick, marginTop }) => {
  return (
    <div className="detail-header" style={{ marginTop }}>
      <Header variant="h3">{heading}</Header>
      <Button onClick={onEditClick}>Edit</Button>
    </div>
  );
};

export default DetailHeader;
