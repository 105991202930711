/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext, useState } from 'react';
import { useApi } from './api.context';
import { LeaderboardMiniTeam, LeaderboardTeam } from '../types/JamLeaderboard';
import { useJamEventDetails } from './jam-event-details.context';
import { noop } from 'lodash';

export interface JamLeaderboardContextValue {
  leaderboard: LeaderboardTeam[];
  loadLeaderboard: (page: number, pageSize: number) => Promise<void>;
  loadLeaderboardTeamview: (teamId: string) => Promise<void>;
  isPresenterMode: boolean;
  mouseMoved: boolean;
  togglePresenterMode: () => void;
  updateMouseActivity: (moved: boolean) => void;
  leaderboardTeamview: (LeaderboardMiniTeam | LeaderboardTeam)[];
  totalTeams: number;
  topTeams: LeaderboardTeam[];
}

const JamLeaderboardContext = React.createContext<JamLeaderboardContextValue>({
  leaderboard: [],
  leaderboardTeamview: [],
  loadLeaderboard: () => new Promise(noop),
  loadLeaderboardTeamview: () => new Promise(noop),
  isPresenterMode: true,
  mouseMoved: true,
  togglePresenterMode: noop,
  updateMouseActivity: noop,
  totalTeams: 0,
  topTeams: [],
});

const JamLeaderboardProvider: React.FC = ({ children }) => {
  const { participantEventApi } = useApi();
  const { eventName } = useJamEventDetails();

  const [isPresenterMode, setIsPresenterMode] = useState(false);
  const [leaderboard, setLeaderboard] = useState<LeaderboardTeam[]>([]);
  const [topTeams, setTopTeams] = useState<LeaderboardTeam[]>([]);
  const [leaderboardTeamview, setLeaderboardTeamview] = useState<(LeaderboardTeam | LeaderboardMiniTeam)[]>([]);
  const [mouseMoved, setMouseMoved] = useState(true);
  const [totalTeams, setTotalTeam] = useState(0);

  const loadLeaderboard = async (page = 1, pageSize = 10) => {
    if (!eventName) {
      throw new Error('event not found');
    }

    // offset is 0 index based value
    const response = await participantEventApi.getEventLeaderboard(eventName, {
      offset: (page - 1) * pageSize,
      limit: pageSize,
    });

    setLeaderboard(response.leaderBoard);
    setTotalTeam(Math.ceil(response.count / pageSize));
    if (page === 1) {
      setTopTeams(response.leaderBoard);
    }
  };

  const loadLeaderboardTeamview = async (teamId: string) => {
    if (!eventName) {
      throw new Error('event not found');
    }

    const response = await participantEventApi.getEventLeaderboardTeamView(eventName, teamId);
    setLeaderboardTeamview(response.teamView);
  };

  const togglePresenterMode = () => {
    setIsPresenterMode((prev) => !prev);
  };

  const updateMouseActivity = (moved: boolean) => {
    setMouseMoved(moved);
  };

  const data: JamLeaderboardContextValue = {
    leaderboard,
    leaderboardTeamview,
    loadLeaderboard,
    loadLeaderboardTeamview,
    isPresenterMode,
    mouseMoved,
    togglePresenterMode,
    updateMouseActivity,
    totalTeams,
    topTeams,
  };
  return <JamLeaderboardContext.Provider value={data}>{children}</JamLeaderboardContext.Provider>;
};

const useJamLeaderboard = () => {
  const context = useContext(JamLeaderboardContext);
  if (context === undefined) {
    throw new Error('useJamLeaderboard can only be used inside JamMyTeamProvider');
  }
  return context;
};

export { JamLeaderboardProvider, useJamLeaderboard };
