import React from 'react';
import './RatingSummary.scss';

interface RatingSummaryProps {
  one: number;
  two: number;
  three: number;
  four: number;
  five: number;
  total: number;
  color: string;
  ratingUnit: string;
}

const RatingSummary: React.FC<RatingSummaryProps> = ({ one, two, three, four, five, total, color, ratingUnit }) => {
  const getValues = () => {
    return [
      { label: `5 ${ratingUnit}`, value: five },
      { label: `4 ${ratingUnit}`, value: four },
      { label: `3 ${ratingUnit}`, value: three },
      { label: `2 ${ratingUnit}`, value: two },
      { label: `1 ${ratingUnit}`, value: one },
    ];
  };

  const percentOfTotal = (num: number) => {
    if (num < 1) {
      return 0;
    }
    return Math.ceil((num / total) * 100);
  };

  return (
    <div className="rating-summary">
      {getValues().map((item, i) => {
        return (
          <div className="rating-item" key={i}>
            <span className="rating-label">{item.label}</span>
            <span className="rating-bar">
              <span
                className="rating-bar-fill"
                style={{ width: `${percentOfTotal(item.value)}%`, backgroundColor: color }}
              />
            </span>
            <span className="rating-sum" style={{ marginLeft: '0.5rem' }}>
              {percentOfTotal(item.value)}% ({item.value})
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default RatingSummary;
