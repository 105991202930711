/**
 * Enhanced interface for sessionStorage supporting the storage of arbitrary javascript objects.
 */

/**
 * Get an item in session storage by key.
 */
const get = <T>(key: string): Exclude<T, undefined> | null => {
  const stored = sessionStorage.getItem(key);

  if (stored == null) {
    return null;
  }

  return JSON.parse(stored) as Exclude<T, undefined>;
};

/**
 * Set an item in session storage.
 */
const set = <T>(key: string, value: Exclude<T, undefined>): void => {
  return sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * Remove an item from session storage.
 */
const remove = (key: string): void => {
  return sessionStorage.removeItem(key);
};

/**
 * Remove all items from session storage.
 */
const clear = (): void => {
  sessionStorage.clear();
};

const SessionStorage = { get, set, remove, clear };
export default SessionStorage;
