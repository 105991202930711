import React from 'react';
import './IntroVideo.scss';

const IntroVideo = () => {
  // const { t } = useTranslation();
  // const [isVideoReady, setIsVideoReady] = useState(false);
  // const arrowRightHeight = (16 * 27) / 22;
  return (
    <div className="intro-video-container">
      {/* <div className="avatar-interact">
        <div className="avatar-popover">
          <div className="avatar-popover-content">
            <Box color="inherit">{t(i18nKeys.catalog.meetAwsTeam.introVideo.bubbleSpeech)}</Box>
          </div>
          <div style={{ height: arrowRightHeight }}>
            <svg width="16" height={arrowRightHeight} viewBox="0 0 22 27" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.8791 11.6779C22.2441 12.4413 22.2441 14.4055 20.8791 15.1689L-1.17351e-06 26.8468L0 -1.04907e-06L20.8791 11.6779Z" />
            </svg>
          </div>
        </div>
        <img className="monkey-avatar" src="/assets/monkey-avatar.png" />
      </div> */}
      {/* <div className="video-player">
        <ReactPlayer onReady={() => setIsVideoReady(true)} url={INTRO_VIDEO_URL} style={{ flex: 1 }} />
        {!isVideoReady && <Spinner variant="inverted" />}
      </div> */}
    </div>
  );
};

export default IntroVideo;
