import React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface JamFacilitatorTeamModalProps {
  headingText: string;
  name: string;
  withInput?: boolean;
  visibleTeamNameChange?: boolean;
  handleModal: (value: string, isSubmitted: boolean) => void;
}

const JamFacilitatorTeamModal: React.FC<JamFacilitatorTeamModalProps> = ({
  headingText,
  children,
  name,
  withInput,
  visibleTeamNameChange,
  handleModal,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(true);

  const handleClose = (isSubmitted: boolean) => {
    setVisible(false);
    handleModal(name, isSubmitted);
  };

  return (
    <Modal
      onDismiss={() => handleClose(false)}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            {withInput ? (
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => handleClose(false)}>
                  {t(i18nKeys.facilitator.participants.buttons.cancel)}
                </Button>
                <Button variant="primary" onClick={() => handleClose(true)}>
                  {visibleTeamNameChange
                    ? t(i18nKeys.facilitator.participants.buttons.changeTeamName)
                    : t(i18nKeys.facilitator.participants.buttons.changeTeamPassword)}
                </Button>
              </SpaceBetween>
            ) : (
              <Button variant="primary" onClick={() => handleClose(false)}>
                {t(i18nKeys.general.close)}
              </Button>
            )}
          </SpaceBetween>
        </Box>
      }
      header={headingText}>
      {children}
    </Modal>
  );
};

export default JamFacilitatorTeamModal;
