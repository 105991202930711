import React from 'react';
import { Box, Grid, GridProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import IntroVideo from '../IntroVideo/IntroVideo';
import './MeetAwsJam.scss';

const MeetAwsJam = () => {
  const { t } = useTranslation();
  const colspan: GridProps.BreakpointMapping = { xl: 6, l: 6, m: 6, s: 6, xs: 12, xxs: 12 };
  return (
    <div className="meet-aws-jam-container">
      <Box padding={{ top: 'xxxl', bottom: 'xxxl' }}>
        <Box padding={{ top: 'l', bottom: 'l' }}>
          <Grid gridDefinition={[{ colspan }, { colspan }]}>
            <div style={{ flex: 1 }}>
              <Box variant="h1" padding={{ top: 'xxxl', bottom: 'xxxl' }}>
                {t(i18nKeys.catalog.meetAwsTeam.title)}
              </Box>
              <Box variant="p">{t(i18nKeys.catalog.meetAwsTeam.description)}</Box>
            </div>
            <div>
              <IntroVideo />
            </div>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default MeetAwsJam;
