import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnLayout, Container, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { EditUsagePlanActions, usePlans } from '../../../store/usage-plan/usage-plan.context';
import { DEFAULT_MIN_MAX, MinMax, UsagePlan } from '../../../types/usage-plan/UsagePlan';
import { RequestType } from '../../../types/common';

interface CreateUsagePlanParticipantProps {
  target: UsagePlan | undefined;
  errorText: string;
}

const CreateUsagePlanParticipant: React.FC<CreateUsagePlanParticipantProps> = ({ target, errorText }) => {
  const { t } = useTranslation();
  const { handleUpdateUsagePlan } = usePlans();
  const [numOfParticipants, setNumOfParticipants] = useState<MinMax>(
    target?.numOfParticipants || { ...DEFAULT_MIN_MAX }
  );
  const [numOfParticipantsErr, setNumOfParticipantsErr] = useState('');
  const [maxTeamSize, setMaxTeamSize] = useState<MinMax>(target?.maxTeamSize || { ...DEFAULT_MIN_MAX });
  const [maxTeamSizeErr, setMaxTeamSizeErr] = useState('');

  useEffect(() => {
    if (errorText) {
      onParticipantLimitChange(numOfParticipants);
      onMaxTeamSizeChange(maxTeamSize);
    }
  }, [errorText]);

  const onParticipantLimitChange = (limit: MinMax) => {
    setNumOfParticipants(limit);
    setNumOfParticipantsErr(handleUpdateUsagePlan(EditUsagePlanActions.NUM_OF_PARTICIPANTS, limit));
  };

  const onMaxTeamSizeChange = (limit: MinMax) => {
    setMaxTeamSize(limit);
    setMaxTeamSizeErr(handleUpdateUsagePlan(EditUsagePlanActions.TEAM_SIZE, limit));
  };

  return (
    <Container header={<Header variant={'h2'}>{t(i18nKeys.usagePlan.sections.participant)}</Header>}>
      <SpaceBetween direction={'vertical'} size={'l'}>
        {/* Participant Limits */}
        <FormField
          errorText={numOfParticipantsErr}
          label={t(i18nKeys.usagePlan.fields.participant.title)}
          description={t(i18nKeys.usagePlan.fields.participant.description)}>
          <ColumnLayout columns={1}>
            <div>
              <Input
                inputMode="numeric"
                type="number"
                value={numOfParticipants.min.toString()}
                onChange={({ detail }) => {
                  numOfParticipants.min = Number(detail.value);
                  onParticipantLimitChange(numOfParticipants);
                }}
                className="numeric-input"
                placeholder={t(i18nKeys.general.numPlaceholder)}
                ariaRequired
              />{' '}
              {t(i18nKeys.general.min)}
            </div>
            <div>
              <Input
                inputMode="numeric"
                type="number"
                value={numOfParticipants.max.toString()}
                onChange={({ detail }) => {
                  numOfParticipants.max = Number(detail.value);
                  onParticipantLimitChange(numOfParticipants);
                }}
                className="numeric-input"
                placeholder={t(i18nKeys.general.numPlaceholder)}
                ariaRequired
              />{' '}
              {t(i18nKeys.general.max)}
            </div>
          </ColumnLayout>
        </FormField>

        {/* Team size */}
        {/* This field should only be used for event request types */}
        {target && target.requestType === RequestType.EVENT && (
          <FormField
            errorText={maxTeamSizeErr}
            label={t(i18nKeys.usagePlan.fields.teamSize.title)}
            description={t(i18nKeys.usagePlan.fields.teamSize.description)}>
            <ColumnLayout columns={1}>
              <div>
                <Input
                  inputMode="numeric"
                  type="number"
                  value={maxTeamSize.min.toString()}
                  onChange={({ detail }) => {
                    maxTeamSize.min = Number(detail.value);
                    onMaxTeamSizeChange(maxTeamSize);
                  }}
                  className="numeric-input"
                  placeholder={t(i18nKeys.general.numPlaceholder)}
                  ariaRequired
                />{' '}
                {t(i18nKeys.general.min)}
              </div>
              <div>
                <Input
                  inputMode="numeric"
                  type="number"
                  value={maxTeamSize.max.toString()}
                  onChange={({ detail }) => {
                    maxTeamSize.max = Number(detail.value);
                    onMaxTeamSizeChange(maxTeamSize);
                  }}
                  className="numeric-input"
                  placeholder={t(i18nKeys.general.numPlaceholder)}
                  ariaRequired
                />{' '}
                {t(i18nKeys.general.max)}
              </div>
            </ColumnLayout>
          </FormField>
        )}
      </SpaceBetween>
    </Container>
  );
};

export default CreateUsagePlanParticipant;
