import { Box, Grid, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengeFeedback, ChallengeFeedbackSummary } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { toPercentage } from '../../../../utils/percentage.utils';
import RatingSummary from '../../../challenges/challengesCommon/ratingSummary/RatingSummary';
import CircleRating from '../../../common/CircleRating';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { KeyValue } from '../../../common/KeyValue';
import StarRating from '../../../common/StarRating';
import FeedbackRow from './FeedbackRow';

interface ChallengeFeedbackSectionProps {
  summary: ChallengeFeedbackSummary | undefined;
  averageChallengeRating: number;
  averageDifficultyRating: number;
  feedbackWithComments: ChallengeFeedback[];
}

const ChallengeFeedbackSection: React.FC<ChallengeFeedbackSectionProps> = ({
  summary,
  averageChallengeRating,
  averageDifficultyRating,
  feedbackWithComments,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
        <Box>
          <KeyValue label={<Header variant="h3">{t(i18nKeys.feedback.headers.challengeRating)}</Header>}>
            <StarRating value={averageChallengeRating} valueCount={summary?.total || 0} showAverage inlineAverage />
            <RatingSummary
              one={summary ? summary.rating1 : 0}
              two={summary ? summary.rating2 : 0}
              three={summary ? summary.rating3 : 0}
              four={summary ? summary.rating4 : 0}
              five={summary ? summary.rating5 : 0}
              total={summary ? summary.total : 0}
              color={'darkorange'}
              ratingUnit={'stars'}
            />
          </KeyValue>
          <HorizontalRule evenMargins />
          <KeyValue label={<Header variant="h3">{t(i18nKeys.feedback.headers.challengeDifficulty)}</Header>}>
            <CircleRating value={averageDifficultyRating} />
            <RatingSummary
              one={summary ? summary.difficulty1 : 0}
              two={summary ? summary.difficulty2 : 0}
              three={summary ? summary.difficulty3 : 0}
              four={summary ? summary.difficulty4 : 0}
              five={summary ? summary.difficulty5 : 0}
              total={summary ? summary.total : 0}
              color={'darkorange'}
              ratingUnit={'stars'}
            />
          </KeyValue>
          <HorizontalRule evenMargins />
          {summary && (
            <KeyValue label={<Header variant="h3">{t(i18nKeys.feedback.headers.learningPlan)}</Header>}>
              <div className="mb-8">
                <strong>
                  {toPercentage(
                    summary.learnedSomethingNew / (summary.learnedSomethingNew + summary.didNotLearnSomethingNew)
                  )}
                </strong>{' '}
                {t(i18nKeys.feedback.labels.percentageLearned)}
              </div>
              <div>{t(i18nKeys.feedback.labels.yes, { count: summary.learnedSomethingNew || 0 })}</div>
              <div>{t(i18nKeys.feedback.labels.no, { count: summary.didNotLearnSomethingNew || 0 })}</div>
              <div>
                {t(i18nKeys.feedback.labels.nA, {
                  count: summary.total - summary.learnedSomethingNew - summary.didNotLearnSomethingNew || 0,
                })}
              </div>
            </KeyValue>
          )}
        </Box>
        <Box>
          <Header variant="h1" className="mb-8">
            {t(i18nKeys.feedback.headers.challengeFeedback, { challengeId: summary?.challengeId })}
          </Header>
          {feedbackWithComments.map((feedback, i) => {
            return (
              <React.Fragment key={`feedback-row-${i}`}>
                <FeedbackRow challengeFeedback={feedback} />
                {i + 1 !== feedbackWithComments.length && <HorizontalRule evenMargins />}
              </React.Fragment>
            );
          })}
        </Box>
      </Grid>
    </React.Fragment>
  );
};
export default ChallengeFeedbackSection;
