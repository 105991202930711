import React from 'react';
import { Badge } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';

interface NewBadgeProps {
  className?: string;
}
export const NewBadge: React.FC<NewBadgeProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Badge color="blue" className={className}>
      {t(i18nKeys.general.new)}
    </Badge>
  );
};
