import {AwsRegions} from './aws-regions';

// noinspection JSUnusedGlobalSymbols
export const SupportedLabRegions = [
  AwsRegions.US_EAST_1,
  AwsRegions.US_WEST_2,
  AwsRegions.AP_NORTHEAST_1,
  AwsRegions.AP_SOUTHEAST_2,
  AwsRegions.EU_WEST_2,
];
