import { capitalize } from 'lodash';

export enum LabProvider {
  EVENT_ENGINE = 'EVENT_ENGINE',
  AWS_LABS = 'AWS_LABS',
}

export const DEFAULT_LAB_PROVIDER = LabProvider.AWS_LABS;

/**
 * Converts a lab provider string from UPPER_SNAKE_CASE to Display Case.
 * Example: EVENT_ENGINE -> Event Engine, AWS_LABS -> AWS Labs
 *
 * @param labProvider
 */
export const formatLabProviderLabel = (labProvider: string) => {
  return (
    labProvider
      .split('_')
      .map(capitalize)
      .join(' ')
      // Aws should be capitalized AWS
      .replace('Aws', 'AWS')
  );
};

/**
 * Display friendly labels for each lab provider.
 * Usage: LAB_PROVIDER_LABELS[labProvider]
 */
export const LAB_PROVIDER_LABELS: { [key: string]: string } = Object.keys(LabProvider).reduce((labels, labProvider) => {
  return {
    ...labels,
    [labProvider]: formatLabProviderLabel(labProvider),
  };
}, {});

/**
 * A list of { value, label } items that can be used in a <select> for choosing a lab provider.
 * The default lab provider IS NOT explicitly called out.
 */
export const LAB_PROVIDER_SELECT_ITEMS_WITHOUT_DEFAULT_SPECIFIED = Object.keys(LabProvider).map((value) => {
  return {
    value,
    label: LAB_PROVIDER_LABELS[value],
  };
});

/**
 * A list of { value, label } items that can be used in a <select> for choosing a lab provider.
 * The default lab provider IS explicitly called out with (Default) after the label.
 */
export const LAB_PROVIDER_SELECT_ITEMS = LAB_PROVIDER_SELECT_ITEMS_WITHOUT_DEFAULT_SPECIFIED.map(({ label, value }) => {
  if (value === DEFAULT_LAB_PROVIDER) {
    label += ' (Default)';
  }
  return { value, label };
});
