import React, { useCallback, useEffect, useMemo, useState } from 'react';

// components
import {
  EventDurationInfo,
  EventLearningType,
  EventTemplateChallengeSelectFields,
  IEventTemplate,
} from '@/src/types/EventTemplate';

import { Alert, ColumnLayout, Container, PropertyFilterProps, Table, Tabs } from '@amzn/awsui-components-react';
import { ChallengesTable } from '@/src/components/event-templates/EventTemplateCreate/Sections/SelectChallenges/ChallengesTable';
import { ChallengeSetTable } from '@/src/components/event-templates/EventTemplateCreate/Sections/SelectChallenges/ChallengeSetTable';
import { useTranslation } from 'react-i18next';
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';
import { EventTemplateChallengeTabId } from '@/src/store/create-event-template.context';
import {
  columnDefinitions,
  visibleContentForChallenge,
  visibleContentForChallengeSet,
  filteringProperties,
} from '@/src/components/event-templates/EventTemplateCreate/Sections/SelectChallenges/table-config';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { ChallengeListItem, ChallengeUtils } from '@/src/types/Challenge';
import { useToolPanel } from '@/src/store/tool-panel.context';
import { useSplitPanel } from '@/src/store/split-panel.context';
import { ChallengeSet } from '@/src/types/ChallengeSet';
import { ChallengeOrder } from '@/src/components/event-templates/EventTemplateCreate/Sections';
import { BACKUP_CHALLENGE_LIMIT } from '@/src/constants/event-template.constants';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/uxdg';
import { challengeOrderValidator, challengeSelectValidator } from '@/src/utils/event-template.validation.utils';
import { useEditEventTemplate } from '@/src/store/edit-event-template.context';
import { getBackupChallengesListItems, getPrimaryChallengeListItems } from '@/src/utils/event-template.utils';
import JamSpinner from '@/src/components/common/JamSpinner';
import { useChallenges } from '@/src/store/challenge.context';

export interface IEventDetailChallengeEditProps {
  eventTemplate: IEventTemplate;
  eventTemplateEditMode: boolean;
  selectedChallenges: ChallengeListItem[];
  getEventDurationInfo: () => EventDurationInfo | undefined;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
  onSelectedChallengesChange: (items: ChallengeListItem[]) => void;
  onPrimaryBackupChallengesChange: (
    primaryChallenges: ChallengeListItem[],
    backupChallenges: ChallengeListItem[]
  ) => void;
  handleCancel: () => void;
}

const EventDetailChallengeEdit: React.FC<IEventDetailChallengeEditProps> = ({
  eventTemplate,
  getEventDurationInfo,
  validationHandler,
  onSelectedChallengesChange,
  onPrimaryBackupChallengesChange,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const [challengeOrderError, setChallengeOrderError] = useState('');
  const [selectChallengesError, setSelectChallengesError] = useState<string>('');
  const {
    // challengeListItems,
    challengeSets,
    challengeSetToggleEnable,
    selectedEditChallengeListItems,
    currentSelectedChallengeSet,
    onCurrentSelectedChallengeSetChange,
    propertyFilterProps: propertyFilterPropsFromContext,
    allPagesItems: allPagesItemsFromContext,
    setAllPagesItems,
    setPropertyFilterProps,
    challengeListItemMap,
    challengeToggleEnable,
    isChallengeToggleEnable,
    isChallengeSetToggleEnable,
    propertyFilterRef,
    paginationRef,
    collectionPrefRef,
  } = useEventTemplateChallenges();
  const {
    challengeListOpenSearchItems,
    getOpenSearchChallenges,
    challengeRequestOptions,
    setChallengeRequestOptions,
    challengesCount,
    handleFilterValues,
    selectedChallengesById,
  } = useChallenges();

  const { editedEventTemplate } = useEditEventTemplate();

  const [selectedChallengeItems, setSelectedChallengeItems] = useState<ChallengeListItem[]>(
    selectedChallengesById ?? []
  );

  const primaryChallengeListItems = useMemo(() => {
    if (!editedEventTemplate?.challenges) return [];
    return getPrimaryChallengeListItems(editedEventTemplate?.challenges, selectedChallengeItems);
  }, [editedEventTemplate?.challenges, selectedChallengeItems]);

  const backupChallengeListItems = useMemo(() => {
    if (!editedEventTemplate?.challenges) return [];
    return getBackupChallengesListItems(editedEventTemplate?.challenges, selectedChallengeItems);
  }, [editedEventTemplate?.challenges, selectedChallengeItems]);

  const [selectedChallengeSetItem, setSelectedChallengeSetItem] = useState<ChallengeSet | undefined>(
    currentSelectedChallengeSet
  );
  const [challengePreferences, setChallengePreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
    visibleContent: visibleContentForChallenge,
  });
  const [challengeSetPreferences, setChallengeSetPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
    visibleContent: visibleContentForChallengeSet,
  });

  const [activeChallengeTabId, setActiveChallengeTabId] = useState<string>(EventTemplateChallengeTabId.CHALLENGE_ORDER);

  const { toggleChallengeDetailsInfo, toggleChallengeSetDetailsInfo } = useToolPanel();
  const { toggleShowSplitPanel, renderSplitPanelContent } = useSplitPanel();

  const [currPageIndex, setCurrPageIndex] = useState<number>(1);
  const [numOfPages, setNumOfPages] = useState<number>(0);
  const [query, setQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'and',
  });

  useEffect(() => {
    void getOpenSearchChallenges(false, true, true);
  }, [challengeRequestOptions]);

  useEffect(() => {
    handleNumOfPages(challengeRequestOptions.limit);
  }, [challengesCount]);

  const handleNumOfPages = (limit: number) => {
    if (challengesCount > 0) {
      const page = Math.ceil(challengesCount / limit);
      setNumOfPages(page);
    }
  };

  // on pagination click challenge List updates
  const onPageClick = (currentPageIndex: number) => {
    if (currentPageIndex !== currPageIndex) {
      setCurrPageIndex(currentPageIndex);
      const limit = challengeRequestOptions.limit;
      const newOffset = currentPageIndex === 1 ? 0 : (currentPageIndex - 1) * limit;
      setChallengeRequestOptions({ ...challengeRequestOptions, offset: newOffset });
    }
  };

  const setFilterValues = (details: PropertyFilterProps.Query, toggleStabilityChecked: boolean) => {
    handleFilterValues(details, toggleStabilityChecked, true);
    setQuery(details);
    setCurrPageIndex(1);
  };

  useEffect(() => {
    setFilterValues(query, challengeToggleEnable);
  }, [challengeToggleEnable]);

  useEffect(() => {
    isChallengeToggleEnable(false);
    return () => {
      handleCancel();
      toggleShowSplitPanel(false);
    };
  }, []);

  useEffect(() => {
    updateEventTemplateChallengeSplitPanelContent(selectedEditChallengeListItems);
  }, [selectedEditChallengeListItems]);

  const validator = useMemo(() => {
    const minChallenges = getEventDurationInfo()?.minChallenges ?? 0;
    const maxChallenges = getEventDurationInfo()?.maxChallenges ?? 0;
    return challengeSelectValidator(
      minChallenges,
      Infinity,
      selectedEditChallengeListItems,
      t(i18nKeys.eventTemplates.step2.header.alert, {
        minChallenges,
        maxChallenges,
        backupChallengesLimit: BACKUP_CHALLENGE_LIMIT,
      }),
      new Map<EventTemplateChallengeSelectFields, (error: string) => void>([
        [EventTemplateChallengeSelectFields.EVENT_CHALLENGE_SELECT, (error: string) => setSelectChallengesError(error)],
      ])
    );
  }, [selectedEditChallengeListItems, getEventDurationInfo]);

  validationHandler(() => {
    const result = validator.isValidSection(true);
    if (result) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  });

  const handleTabChange = (activeTabId: string) => {
    setActiveChallengeTabId(activeTabId);
  };

  const handleSelectedChallengesChange = (challenges: ChallengeListItem[]) => {
    setSelectedChallengeItems(challenges);
    if (selectedChallengeItems.length !== challenges.length) {
      onCurrentSelectedChallengeSetChange(undefined);
      setSelectedChallengeSetItem(undefined);
    }
  };

  const handlePrimaryBackupChallengesChange = (
    primaryChallenges: ChallengeListItem[],
    backupChallenges: ChallengeListItem[]
  ) => {
    onPrimaryBackupChallengesChange(primaryChallenges, backupChallenges);
  };

  const addChallenges = useCallback(() => {
    onSelectedChallengesChange(selectedChallengeItems);
  }, [onSelectedChallengesChange, selectedChallengeItems]);

  const updateEventTemplateChallengeSplitPanelContent = (challenges: ChallengeListItem[]) => {
    toggleShowSplitPanel(challenges.length > 0);
    renderSplitPanelContent(
      `${challenges.length} ${t(i18nKeys.eventTemplates.tabs.common.challengeSelected)}`,
      <ColumnLayout variant="text-grid">
        <Table
          variant="borderless"
          columnDefinitions={columnDefinitions(toggleChallengeDetailsInfo, t)}
          items={challenges}
        />
      </ColumnLayout>
    );
  };

  const addChallengeSet = () => {
    if (selectedChallengeSetItem) {
      onCurrentSelectedChallengeSetChange(selectedChallengeSetItem);
      const challenges: ChallengeListItem[] = selectedChallengeSetItem.challengeIds
        .map((challengeId: string) => challengeListItemMap[challengeId])
        .filter((challengeListItem: ChallengeListItem) => challengeListItem);
      if (challenges) {
        onSelectedChallengesChange(challenges);
        setSelectedChallengeItems(challenges);
        updateEventTemplateChallengeSplitPanelContent(challenges);
      }
    }
  };

  const handlePropertyFilterChange = (propertyFilterProps: PropertyFilterProps) => {
    if (!propertyFilterPropsFromContext) {
      setPropertyFilterProps(propertyFilterProps);
    }
  };

  const handleAllPageItemsChange = (allPageItems: ChallengeListItem[] | ChallengeSet[]) => {
    return allPageItems.length !== allPagesItemsFromContext?.length && setAllPagesItems(allPageItems);
  };

  const handleSelectedChallengeSetChange = (selectedChallengeSet: ChallengeSet[]) => {
    if (selectedChallengeSet.length > 0) {
      const challengeSet = selectedChallengeSet[0];
      setSelectedChallengeSetItem(challengeSet);
    }
  };
  if (!editedEventTemplate) {
    return <JamSpinner />;
  }

  return (
    <Container>
      {selectChallengesError && (
        <Alert statusIconAriaLabel={t(i18nKeys.general.error)} type="error">
          {selectChallengesError}
        </Alert>
      )}
      <Tabs
        onChange={({ detail }) => handleTabChange(detail.activeTabId)}
        activeTabId={activeChallengeTabId}
        tabs={[
          {
            id: EventTemplateChallengeTabId.CHALLENGE_ORDER,
            label: t(i18nKeys.eventTemplates.step3.title),
            content: (
              <ChallengeOrder
                editMode
                challengeOrderError={challengeOrderError}
                setChallengeOrderError={setChallengeOrderError}
                primaryChallengeListItems={primaryChallengeListItems}
                backupChallengeListItems={backupChallengeListItems}
                getEventDurationInfo={getEventDurationInfo}
                onPrimaryBackupChallengesChange={handlePrimaryBackupChallengesChange}
                validationHandler={validationHandler}
                challengeOrderValidator={challengeOrderValidator}
              />
            ),
          },
          {
            id: EventTemplateChallengeTabId.CHALLENGE,
            label: t(i18nKeys.eventTemplates.tabs.label.addChallenges),
            content: (
              <ChallengesTable
                editMode
                loading={!challengeListOpenSearchItems || challengeListOpenSearchItems.length === 0}
                propertyFilterRef={propertyFilterRef}
                paginationRef={paginationRef}
                collectionPrefRef={collectionPrefRef}
                data={
                  challengeListOpenSearchItems
                    ? challengeListOpenSearchItems?.filter(
                        (c) =>
                          ChallengeUtils.isIndividualLearningType(c) ===
                          (eventTemplate.learningType === EventLearningType.INDIVIDUAL)
                      )
                    : []
                }
                onPropertyFilterPropsChange={handlePropertyFilterChange}
                onAllPageItemsChange={handleAllPageItemsChange}
                selectedItems={selectedChallengeItems}
                filterProps={filteringProperties}
                preferences={challengePreferences}
                setPreferences={setChallengePreferences}
                challengeToggleEnable={challengeToggleEnable}
                isChallengeToggleEnable={isChallengeToggleEnable}
                onSelectedItemsChange={handleSelectedChallengesChange}
                onToggleDetails={toggleChallengeDetailsInfo}
                addChallenges={addChallenges}
                showPreferences
                currPageIndex={currPageIndex}
                numOfPages={numOfPages}
                challengesCount={challengesCount}
                onPageClick={onPageClick}
                setFilterValues={setFilterValues}
                filterQuery={query}
              />
            ),
          },
          {
            id: EventTemplateChallengeTabId.CHALLENGE_SETS,
            label: t(i18nKeys.eventTemplates.tabs.label.addChallengeSets),
            content: (
              <ChallengeSetTable
                data={challengeSets}
                propertyFilterRef={propertyFilterRef}
                paginationRef={paginationRef}
                collectionPrefRef={collectionPrefRef}
                challengeListItemMap={challengeListItemMap}
                loading={!challengeSets}
                selectedItems={selectedChallengeSetItem ? [selectedChallengeSetItem] : []}
                filterProps={filteringProperties}
                preferences={challengeSetPreferences}
                setPreferences={setChallengeSetPreferences}
                challengeSetToggleEnable={challengeSetToggleEnable}
                isChallengeSetToggleEnable={isChallengeSetToggleEnable}
                onSelectedItemsChange={handleSelectedChallengeSetChange}
                onToggleDetails={toggleChallengeSetDetailsInfo}
                addChallenges={addChallengeSet}
                showPreferences
              />
            ),
          },
        ]}
      />
    </Container>
  );
};

export default EventDetailChallengeEdit;
