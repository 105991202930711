import { useState } from 'react';

/**
 * This allows wrapping some data in a ref so that the value is preserved even if accessed
 * from within a JS function that has a closure where the data might have a different value when that
 * closure was created.
 *
 * learn more: https://medium.com/@luis.roman/react-hooks-how-to-avoid-outdated-data-on-handlers-closures-a27020b68dd9
 *
 * @param data
 */
export const useLocalContext = <T extends { [key: string]: any }>(data: T): T => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [ctx] = useState<any>({});
  Object.keys(data).forEach((key: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ctx[key] = data[key];
  });
  return ctx as T;
};
