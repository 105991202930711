import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './components/root/App';
import reportWebVitals from './reportWebVitals';
import '@amzn/awsui-global-styles/polaris.css';
import './i18n';
import { LoadingBar } from './components/common/LoadingBar';
import { UserProvider } from './store/user.context';
import { ThemeProvider } from './store/theme.context';
import { FlashbarProvider } from './store/flashbar.context';
import { ApiClientProvider } from './store/api.context';
import Amplify from 'aws-amplify';
import amplifyConfig from './config/amplifyConfig';
import { AuthClientProvider } from './store/auth.context';
import { EventsProvider } from './store/events.context';
import { CookieConsentProvider } from './store/cookie-consent.context';
import { EditEventProvider } from './store/edit-event.context';
import { UsagePlanProvider } from './store/usage-plan/usage-plan.context';
import { ChallengesProvider } from './store/challenge.context';
import { SplitPanelProvider } from './store/split-panel.context';
import { ToolPanelProvider } from './store/tool-panel.context';
import { BASE_PATH_PREFIX } from './utils/env.utils';
import { CreateChallengeProvider } from './store/create-challenge.context';
import { ChallengeTutorial } from './components/challenges/ChallengeTutorial';
import { ChallengeBoardProvider } from './store/challenge-board.context';
import { ChallengeSetProvider } from './store/challenge-set.context';
import { LSEProvider } from './store/lse.context';
import { CampaignsProvider } from './store/campaigns.context';
import { EditCampaignProvider } from './store/edit-campaign.context';
import { CreateEventTemplateProvider } from '@/src/store/create-event-template.context';
import { EditEventTemplateProvider } from '@/src/store/edit-event-template.context';
import { EventTemplateProvider } from './store/event-template.context';
import { EventTemplateChallengesProvider } from './store/event-template-challenges.context';
import { JamDashboardProvider } from './store/jam-dashboard.context';
import { JamChallengeProvider } from './store/jam-challenge.context';
import { HelmetProvider } from 'react-helmet-async';
import { WebsocketProvider } from './store/websocket.context';
import { JamMyTeamProvider } from './store/jam-myteam.context';
import { JamChallengeRecentFeedbackProvider } from './store/jam-challenge-recent-feedback';
import { JamFeedbackProvider } from './store/jam-feedback';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { JamChallengeDetailsProvider } from './store/jam-challenge-details.context';
import { JamFacilitatorProvider } from './store/jam-facilitator.context';
import { JamReportProvider } from '@/src/store/jam-report.context';
import { JamChatProvider } from './store/jam-chat.context';
import { JamEventDetailsProvider } from './store/jam-event-details.context';
import { JamLeaderboardProvider } from './store/jam-leaderboard.context';
import { EventTemplateOffersProvider } from './store/event-template-offers.context';
import { PublicEventsProvider } from './store/public-events.context';
import { HeaderProvider } from './store/header.context';
import { UtilityProvider } from './store/utility.provider';

library.add(faCaretDown as IconDefinition);
library.add(faUser as IconDefinition);

Amplify.configure(amplifyConfig);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
global.fetch = require('node-fetch');

const Root = () => (
  <React.StrictMode>
    <BrowserRouter basename={BASE_PATH_PREFIX}>
      <div className="awsui">
        <Suspense fallback={<LoadingBar />}>
          <UtilityProvider>
            <FlashbarProvider>
              <AuthClientProvider>
                <ApiClientProvider>
                  <UserProvider>
                    {/*
                    TODO: figure out how to wrap a provider around a specific route in routes.ts or MainContent.tsx
                          because this EventsProvider is only needed by EventList and EventDetails
                  */}
                    <CookieConsentProvider>
                      <LSEProvider>
                        <PublicEventsProvider>
                          <ChallengesProvider>
                            <ChallengeSetProvider>
                              <ChallengeBoardProvider>
                                <SplitPanelProvider>
                                  <ToolPanelProvider>
                                    <EventsProvider>
                                      <EventTemplateOffersProvider>
                                        <EventTemplateProvider>
                                          <CreateEventTemplateProvider>
                                            <EditEventTemplateProvider>
                                              <EventTemplateChallengesProvider>
                                                <CampaignsProvider>
                                                  <EditEventProvider>
                                                    <EditCampaignProvider>
                                                      <UsagePlanProvider>
                                                        <CreateChallengeProvider>
                                                          <JamEventDetailsProvider>
                                                            <JamDashboardProvider>
                                                              <JamLeaderboardProvider>
                                                                <JamChallengeProvider>
                                                                  <JamChallengeDetailsProvider>
                                                                    <JamFeedbackProvider>
                                                                      <JamFacilitatorProvider>
                                                                        <JamReportProvider>
                                                                          <JamChallengeRecentFeedbackProvider>
                                                                            <ChallengeTutorial>
                                                                              <JamMyTeamProvider>
                                                                                <JamChatProvider>
                                                                                  <WebsocketProvider>
                                                                                    <HelmetProvider>
                                                                                      <ThemeProvider>
                                                                                        <HeaderProvider>
                                                                                          <App />
                                                                                        </HeaderProvider>
                                                                                      </ThemeProvider>
                                                                                    </HelmetProvider>
                                                                                  </WebsocketProvider>
                                                                                </JamChatProvider>
                                                                              </JamMyTeamProvider>
                                                                            </ChallengeTutorial>
                                                                          </JamChallengeRecentFeedbackProvider>
                                                                        </JamReportProvider>
                                                                      </JamFacilitatorProvider>
                                                                    </JamFeedbackProvider>
                                                                  </JamChallengeDetailsProvider>
                                                                </JamChallengeProvider>
                                                              </JamLeaderboardProvider>
                                                            </JamDashboardProvider>
                                                          </JamEventDetailsProvider>
                                                        </CreateChallengeProvider>
                                                      </UsagePlanProvider>
                                                    </EditCampaignProvider>
                                                  </EditEventProvider>
                                                </CampaignsProvider>
                                              </EventTemplateChallengesProvider>
                                            </EditEventTemplateProvider>
                                          </CreateEventTemplateProvider>
                                        </EventTemplateProvider>
                                      </EventTemplateOffersProvider>
                                    </EventsProvider>
                                  </ToolPanelProvider>
                                </SplitPanelProvider>
                              </ChallengeBoardProvider>
                            </ChallengeSetProvider>
                          </ChallengesProvider>
                        </PublicEventsProvider>
                      </LSEProvider>
                    </CookieConsentProvider>
                  </UserProvider>
                </ApiClientProvider>
              </AuthClientProvider>
            </FlashbarProvider>
          </UtilityProvider>
        </Suspense>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
