import {
  ColumnLayout,
  ExpandableSection,
  FormField,
  Header,
  Input,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import * as React from 'react';
// import { useTranslation } from 'react-i18next';
import { Challenge } from '../../../../types/Challenge';
// import { i18nKeys } from '../../../../utils/i18n.utils';
import { Column } from '@/src/components/common/Column';
import MarkDownPreviewEditor from '@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor';
import { useState } from 'react';
import { Markdown } from '@/src/components/common/Markdown';
import { TranslatedAttribute } from '@/src/types/common';

interface ChallengeTranslationDetailProps {
  formUpdate: ({ attributeName, originalText, translatedText }: TranslatedAttribute) => void;
  attributeName?: string;
  challenge: Challenge;
  heading?: string;
  title?: { label: string; value: string };
  originalTitle?: { label: string; value: string };
  description?: { label: string; value: string };
  originalDescription?: { label: string; value: string };
  editor?: boolean;
}

const ChallengeTranslationDetail: React.FC<ChallengeTranslationDetailProps> = ({
  formUpdate,
  attributeName,
  title,
  originalTitle,
  description,
  originalDescription,
  heading,
  editor = false,
}) => {
  // const { t } = useTranslation();

  const [newDescription, setNewDescription] = useState(description?.value || '');
  const [newTitle, setTitle] = useState(title?.value || '');

  const onFormChange = ({
    key,
    attrName,
    translatedText,
    originalText,
  }: {
    key: string;
    attrName: string;
    translatedText: string;
    originalText: string;
  }) => {
    if (key === 'title') {
      setTitle(translatedText);
    }
    if (key === 'description') {
      setNewDescription(translatedText);
    }
    void formUpdate({ attributeName: attrName, originalText, translatedText });
  };

  const attr = ['title', 'description', 'category'];

  return (
    <ExpandableSection
      defaultExpanded
      variant="container"
      header={<Header variant="h2">{heading || title?.label || 'Heading'}</Header>}>
      <div className="expandable-section">
        <SpaceBetween size="s">
          <ExpandableSection variant="container" header={<Header variant="h2">Original[English]</Header>}>
            <ColumnLayout columns={1}>
              {originalTitle && (
                <Column size="s">
                  <FormField label={originalTitle?.label || 'Title'}>
                    <Input disabled value="Amazon EKS-Cluster-Erstellung mit Terraform " />
                  </FormField>
                </Column>
              )}
              {originalDescription && (
                <Column size="s">
                  <FormField label={originalDescription.label}>
                    <Markdown content={originalDescription.value || ''} />
                  </FormField>
                </Column>
              )}
            </ColumnLayout>
          </ExpandableSection>
          {title && (
            <ColumnLayout columns={2}>
              <Column size="s">
                <FormField label={title?.label || 'Title'}>
                  <Input
                    onChange={({ detail }) =>
                      onFormChange({
                        key: 'title',
                        attrName: attr.includes(attributeName || '') ? attributeName || '' : `${attributeName}.title`,
                        translatedText: detail.value,
                        originalText: title?.value,
                      })
                    }
                    value={newTitle || ''}
                  />
                </FormField>
              </Column>
              <Column size="s">
                <FormField label="Preview">
                  <TextContent>{newTitle || ''}</TextContent>
                </FormField>
              </Column>
            </ColumnLayout>
          )}
          {editor && (
            <MarkDownPreviewEditor
              activeTabIndex={2}
              text={newDescription || ''}
              onTextChanged={(e) => {
                onFormChange({
                  key: 'description',
                  attrName: attr.includes(attributeName || '') ? attributeName || '' : `${attributeName}.description`,
                  translatedText: e,
                  originalText: description?.value || '',
                });
              }}
            />
          )}
        </SpaceBetween>
      </div>
    </ExpandableSection>
  );
};

export default ChallengeTranslationDetail;
