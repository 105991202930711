import { Button, Header, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvents } from '../../../../store/events.context';
import { Nullable } from '../../../../types/common';
import { TeamMetricsRow } from '../../../../types/DetailedEventStatisticsReport';
import { Event } from '../../../../types/Event';
import { downloadObjectAsCsv, downloadObjectAsJson } from '../../../../utils/download.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { HorizontalRule } from '../../../common/HorizontalRule';
import ChallengeMetrics from './ChallengeMetrics';
import TeamMetricDetails from './TeamMetrics/TeamMetricDetails';
import TeamMetricsTable from './TeamMetrics/TeamMetricsTable';

interface ReportProps {
  event: Event | undefined;
}

const Report: React.FC<ReportProps> = ({ event }) => {
  const { t } = useTranslation();
  const { loadEventReport, eventReport } = useEvents();
  const [selectedTeam, setSelectedTeam] = useState<Nullable<TeamMetricsRow>>(null);
  const [activeTab, setActiveTab] = useState('challenge-metrics');

  useEffect(() => {
    if (event) {
      loadEventReport(event.name);
    }
  }, []);

  const downloadAsJson = () => {
    if (eventReport) {
      const eventJson = JSON.stringify(eventReport, null, 2);
      downloadObjectAsJson(eventJson, (event?.id || 'jam') + '-event-report');
    }
  };

  const downloadChallengeMetrics = () => {
    if(eventReport && eventReport.challengeMetrics)
    {
      const challengeReport = eventReport.challengeMetrics;
      downloadObjectAsCsv(challengeReport,(event?.id || 'jam') + '-challengeMetrics');
    }
  }

  const downloadTeamMetrics = () => {
    if(eventReport && eventReport.teamMetrics)
    {
      const challengeReport = eventReport.teamMetrics;
      downloadObjectAsCsv(challengeReport,(event?.id || 'jam') + '-teamMetrics');
    }
  }

  return (
    <React.Fragment>
      {!selectedTeam && (
        <React.Fragment>
          <Header
            variant="h2"
            actions={
              <SpaceBetween size={'s'} direction="horizontal">
                <Button variant="normal" onClick={() => downloadAsJson()}>
                  {t(i18nKeys.report.buttons.downloadReport)} (JSON)
                </Button>
                <Button variant="normal" onClick={() => downloadChallengeMetrics()}>
                  {t(i18nKeys.report.buttons.exportChallengeMetrics)}
                </Button>
                <Button variant="normal" onClick={() => downloadTeamMetrics()}>
                  {t(i18nKeys.report.buttons.exportTeamMetrics)}
                </Button>
              </SpaceBetween>
            }>
            {t(i18nKeys.report.headers.report)}
          </Header>
          <HorizontalRule evenMargins className="zero-margin-bottom" />
          <Tabs
            tabs={[
              {
                id: 'challenge-metrics',
                label: t(i18nKeys.report.headers.challengeMetrics),
                content: (
                  <div>
                    {eventReport?.challengeMetrics.map((challengeMetrics, i) => {
                      if (challengeMetrics && challengeMetrics.challengeId) {
                        return (
                          <ChallengeMetrics
                            key={`challenge-metrics-row-${i}`}
                            challengeMetricsRow={challengeMetrics}
                            teamCount={eventReport.teamMetrics.length || 0}
                            title={eventReport.challengeTitles[challengeMetrics.challengeId]}
                          />
                        );
                      }
                    })}
                  </div>
                ),
              },
              {
                id: 'team-metrics',
                label: t(i18nKeys.report.headers.teamMetrics),
                content: (
                  <div>
                    {eventReport?.teamMetrics && (
                      <TeamMetricsTable teamMetrics={eventReport?.teamMetrics} selectTeam={setSelectedTeam} />
                    )}
                  </div>
                ),
              },
            ]}
            activeTabId={activeTab}
            onChange={(tabChangeEvent) => setActiveTab(tabChangeEvent.detail.activeTabId)}
          />
        </React.Fragment>
      )}
      {!!selectedTeam && <TeamMetricDetails teamMetrics={selectedTeam} selectTeam={setSelectedTeam} />}
    </React.Fragment>
  );
};

export default Report;
