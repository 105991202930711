/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/unbound-method */
import { IJamChallenge } from './JamChallenges';
import { JamEventStatus, JamEventTeamAssignmentType } from './JamCommon';
import { JamEventSponsorshipSettings } from './JamEventSponsorshipSettings';
import { OfflineNotificationMessage } from './JamNotifications';
import { EventType } from '../constants/shared/event-type';

export type JamEventType =
  | 'CAMPAIGN_GROUP'
  | 'JAM'
  | 'SPL'
  | 'CTF'
  | 'ONE_CLICK_TEST_EVENT'
  | 'RECURRING_AUTOMATED_TEST_EVENT'
  | 'CAMPAIGN_GROUP';

export class JamEventDetails {
  challenges: IJamChallenge[];
  eventPath: string;
  eventName: string;
  skillProfileUpdateRequired: boolean;
  eventEndDate: string;
  eventStartDate: string;
  teamId: string;
  teamName: string;
  eventStatus: JamEventStatus;
  allowViewChallenges: boolean;
  audienceType: string;
  title: string;
  sponsorshipSettings: JamEventSponsorshipSettings = new JamEventSponsorshipSettings();
  teamAssignmentType: JamEventTeamAssignmentType;
  formTeamsMinsBeforeEventStart: number;
  facilitator: boolean;
  supportUser: boolean;
  supportChatEnabled: boolean;
  teamChatEnabled: boolean;
  messages?: OfflineNotificationMessage[];
  enrolledToEvent: boolean;
  labExtensionHours: number;
  type?: JamEventType;
  gamified = true; // gamified by default
  testEvent: boolean;

  isOnboardingComplete(): boolean {
    // step 1: must be enrolled to the event
    if (!this.enrolledToEvent) {
      return false;
    }
    // step 2: must update skill profile if required
    if (this.skillProfileUpdateRequired) {
      return false;
    }
    // step 3: must be assigned to a team
    if (!this.teamId) {
      return false;
    }
    // onboarding is completed. see: https://tinyurl.com/yyz8vfta
    return true;
  }

  get ended(): boolean {
    return this.eventStatus === JamEventStatus.ENDED;
  }

  get notStarted(): boolean {
    return this.eventStatus === JamEventStatus.NOT_STARTED;
  }

  get isSingleParticipantEvent(): boolean {
    return this.audienceType === 'INDIVIDUAL';
  }

  get timeUntilCanFormTeams(): number {
    const teamFormingStartTime = 0;
    const timeTillCanFormTeams = teamFormingStartTime - Date.now();
    return Math.max(0, timeTillCanFormTeams);
  }

  get isSplEvent(): boolean {
    return this.type === EventType.SPL;
  }

  static fromPlainObject(obj: any): JamEventDetails {
    const data = Object.assign(new JamEventDetails(), obj) as JamEventDetails;
    data.messages = (obj.messages || []).map(
      OfflineNotificationMessage.fromPlainObject
    ) as OfflineNotificationMessage[];
    data.sponsorshipSettings = JamEventSponsorshipSettings.fromPlainObject(obj.sponsorshipSettings || {});
    return data;
  }
}
