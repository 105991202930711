import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { Campaign } from '../../../types/Campaign';
// import TargetDetailsUsagePlan from '../../common/CampaignEventComponents/TargetDetailsUsagePlan';
import TargetSummaryDetails from '../../common/CampaignEventComponents/TargetSummaryDetails';
import ParticipantLimit from '../CampaignSections/ParticipantLimit';

interface NewCampaignDetailsProps {
  target: Campaign | null;
}

const NewCampaignDetails: React.FC<NewCampaignDetailsProps> = ({ target }) => {
  return (
    <React.Fragment>
      {target && (
        <SpaceBetween direction="vertical" size="s">
          <TargetSummaryDetails target={target} />
          {/* <TargetDetailsUsagePlan target={target} /> */}
          <ParticipantLimit campaign={target} />
        </SpaceBetween>
      )}
    </React.Fragment>
  );
};

export default NewCampaignDetails;
