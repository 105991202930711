import React, { useEffect, useRef, useState } from 'react';
import { ChatMessage } from '@/src/types/Chat';
import { Box, Button } from '@amzn/awsui-components-react';
import './JamChat.scss';
import { decodeHtmlCharCodes, getMessageSentTime } from '@/src/utils/chat.utils';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import Linkify from '../../../atoms/Linkify/Linkify';
import { useUser } from '@/src/store/user.context';
import { Link } from '@amzn/awsui-components-react/uxdg';

interface JamChatProps {
  messages: ChatMessage[];
  sendMessage: (message: string) => void;
  disabled: boolean;
  closeWindow: () => void;
}

const JamChat: React.FC<JamChatProps> = ({ messages, sendMessage, disabled, closeWindow }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const messagesDivEl = useRef<HTMLDivElement>(null);
  const { user } = useUser();

  const onMessageSend = () => {
    if (!message?.trim()) {
      return;
    }
    sendMessage(message);
    setMessage('');
  };

  const scrollToBottom = () => {
    const scrollToOptions = { behavior: 'instant' };
    messagesDivEl.current?.scrollIntoView(scrollToOptions as unknown as ScrollIntoViewOptions);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const renderMessage = (chatMessage: ChatMessage) => {
    const myMessage = user?.username === chatMessage.senderParticipantId;
    return (
      <div className="message" key={`${chatMessage.timeSent}-${chatMessage.message}`}>
        <div className={`message-item ${myMessage ? 'my-message' : ''}`}>
          <div className="username">
            {myMessage ? '' : chatMessage.sender}{' '}
            <span className="time">{getMessageSentTime(chatMessage.timeSent)}</span>
          </div>
          <div className="message-text">
            <Linkify external>{decodeHtmlCharCodes(chatMessage.message)}</Linkify>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="conversation">
      <div className="messages">
        <div className="messages-inner" tabIndex={0}>
          {(messages || []).map(renderMessage)}
          <div ref={messagesDivEl} />
        </div>
      </div>
      {disabled && (
        <Box textAlign="center" padding={{ top: 'm', bottom: 'm' }} color="text-status-error" fontWeight="bold">
          {t(i18nKeys.jamChat.actions.disabled)} (
          <Link
            onFollow={(e) => {
              e.preventDefault();
              closeWindow();
            }}>
            <span className="close-window">{t(i18nKeys.jamChat.actions.closeWindow)}</span>
          </Link>
          )
        </Box>
      )}
      {!disabled && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onMessageSend();
          }}>
          <div className="chat-footer">
            <div className="chat-input">
              <input
                placeholder={t(i18nKeys.myJams.chat.form.messagePlaceholder)}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <Button variant="primary" onClick={onMessageSend}>
              {t(i18nKeys.general.send)}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default JamChat;
