import React from 'react';
import Reviews from '@/src/components/ui/molecules/Reviews/Reviews';
import styles from './Comments.module.scss';
import { EventTemplateReview } from '@/src/types/EventTemplate';
import TimeAgo from 'timeago-react';
import { safeString } from '@/src/utils/string.utils';

interface CommentProps {
  review: EventTemplateReview;
  toggleEventTemplateFeedbackReviewLikeStatus: (id: string) => void;
}

const Comment: React.FC<CommentProps> = ({ review }) => {
  return (
    <div className={styles.comment}>
      <img width="50" src={review.imgURL} alt="user-icon" />
      <div className={styles.userInfo}>
        <strong>{review.userName}</strong>
        <div className={styles.userDetails}>
          <Reviews rating={review.rating} />
          <span className={styles.timeAgo}>
            <TimeAgo datetime={safeString(review.date)} />
          </span>
        </div>
        <p>{review.review}</p>
      </div>
    </div>
  );
};

export default Comment;
