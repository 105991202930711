import { NullableString } from '../types/common';

export const copyToClipboard = (value: NullableString | undefined) => {
  if (value) {
    void navigator.clipboard.writeText(value);
  }
};

export const copyToClipboardJSON = (value: string) => {
  const input = document.createElement('textarea');
  document.body.appendChild(input);
  input.value = value;
  input.select();
  document.execCommand('copy',false);
  input.remove();
};