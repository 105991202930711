
export enum ChallengeViews {
    MAP = 'map',
    LIST = 'list',
  }
  
  export enum UIThemes {
    DARK = 'DARK',
    LIGHT = 'LIGHT',
  }

export enum FeedbackType {
    EVENT = 'event',
    CHALLENGE = 'challenge',
  }

export enum EventTypes {
    LIVE,
    TEST,
  }