import { EVENT_TEMPLATE_TAGS, EVENT_TEMPLATE_TOPICS } from '@/src/constants/event-template.constants';
import { ChallengeListItem } from '../types/Challenge';
import { IEventTemplateChallenge } from '../types/EventTemplate';

export const getSelectedTopics = (topics?: string[]) => {
  if (!topics) return [];
  return EVENT_TEMPLATE_TOPICS.filter((item) => topics.includes(item.value));
};

export const getSelectedTags = (tags: string[]) => {
  return EVENT_TEMPLATE_TAGS.filter((item) => tags?.includes(item.value));
};

export const getAwsServicesFromChallengeList = (challengeList: ChallengeListItem[]) => {
  const awsServices: string[] = [];
  for (const challenge of challengeList) {
    if (challenge?.props) {
      awsServices.push(...challenge.props.awsServices);
    }
  }

  return Array.from(new Set(awsServices));
};

type GetChallengeLimitOptions = {
  minChallenges: number;
  maxChallenges: number;
  backupChallenges: number;
  challengesLen: number;
};
type GetChallengeLimitFunction = (options: GetChallengeLimitOptions) => number;

export const getChallengeLimit: GetChallengeLimitFunction = ({
  minChallenges,
  maxChallenges,
  backupChallenges,
  challengesLen,
}) => {
  return Math.max(minChallenges, Math.min(maxChallenges, challengesLen - backupChallenges));
};

export const getPrimaryChallengeListItems = (
  challenges: IEventTemplateChallenge[],
  selectedChallengeItems?: ChallengeListItem[]
): ChallengeListItem[] => {
  if (!selectedChallengeItems || !challenges) return [];
  return selectedChallengeItems.filter(
    (item) => item && challenges.find((_) => _.id === item.challengeId && _.backup === false)
  );
};

export const getBackupChallengesListItems = (
  challenges: IEventTemplateChallenge[],
  selectedChallengeItems?: ChallengeListItem[]
): ChallengeListItem[] => {
  if (!selectedChallengeItems || !challenges) return [];
  return selectedChallengeItems.filter(
    (item) => item && challenges.find((_) => _.id === item.challengeId && _.backup === true)
  );
};
