import React from 'react';
import ReviewStars from '@/src/components/ui/molecules/Reviews/Reviews';
import './Progress.scss';

const Progress = ({ progress, rating, reviews = false }: { progress: number; rating: number; reviews?: boolean }) => {
  return (
    <div className="review-container">
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${progress}%` }} />
      </div>
      {reviews && (
        <>
          <div className="reviews-wrapper">
            <ReviewStars rating={rating} />
          </div>
          <span>{progress}%</span>
        </>
      )}
    </div>
  );
};

export default Progress;
