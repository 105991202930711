import {
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  RadioGroup,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../store/api.context';
import { Challenge } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import MarkDownPreviewEditor from '@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor';

interface NewIssueProps {
  challenge: Challenge;
  toggleNewIssues: () => void;
}
export enum ChallengeIssueSeverity {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}
export const ChallengeIssueSeverityDescriptions = {
  CRITICAL: '1 - Critical: Participants impacted by broken challenge',
  HIGH: '2 - High: Challenge not usable, but participants not yet impacted',
  MEDIUM: '3 - Medium: Challenge usable, but requires attention',
  LOW: '4 - Low: Usability not immediately impacted',
};
export const labelData = (text: string) => {
  if (text === ChallengeIssueSeverity.CRITICAL) {
    return ChallengeIssueSeverityDescriptions.CRITICAL;
  }
  if (text === ChallengeIssueSeverity.HIGH) {
    return ChallengeIssueSeverityDescriptions.HIGH;
  }
  if (text === ChallengeIssueSeverity.MEDIUM) {
    return ChallengeIssueSeverityDescriptions.MEDIUM;
  }
  if (text === ChallengeIssueSeverity.LOW) {
    return ChallengeIssueSeverityDescriptions.LOW;
  }
};

export const emptyDescription = `> Please describe the nature of this issue in a few sentences

### Triage Details

> Please provide as many of the following details as are relevant to this issue.

- Time this issue first occurred: 
- How many times has this issue occurred?

### Additional Info

> Provide any additional information here that might help with reproducing the issue or understanding the problem.`;

export interface ICreateNewIssue {
  challengeId?: string;
  eventName: string | null;
  title: string;
  description: string;
  severity: string;
  isPrivate: boolean;
}

export const inputWidth = '520px';

const NewIssue: React.FC<NewIssueProps> = ({ challenge, toggleNewIssues }) => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();

  const initialData = {
    challengeId: challenge?.challengeId || '',
    eventName: null,
    title: '',
    description: emptyDescription,
    severity: ChallengeIssueSeverity.LOW,
    isPrivate: false,
  };
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [formData, setFormData] = useState<ICreateNewIssue>(initialData);

  const updateEditorData = (text: string) => {
    setFormData({ ...formData, description: text });
  };

  const onFormSubmit = async () => {
    if (formData.title === '' || formData.title.length > 250) {
      setTitleError(true);
      return;
    }
    if (formData.description === emptyDescription || formData.description.length > 2000) {
      setDescriptionError(true);
      return;
    }
    setDescriptionError(false);
    await challengesApi.createChallengeIssue(formData);
  };

  return (
    <Container header={<Header variant="h3">{t(i18nKeys.challenges.challengeDetails.titles.newIssue)}</Header>}>
      <SpaceBetween size="s">
        <FormField
          label="Title"
          errorText={titleError ? `${t(i18nKeys.challenges.subSections.issues.formError.title)}` : ''}>
          <div style={{ width: inputWidth }}>
            <Input
              value={formData.title}
              onBlur={() => setTitleError(false)}
              onChange={(event) => setFormData({ ...formData, title: event.detail.value })}
            />
          </div>
        </FormField>
        <FormField
          stretch
          errorText={descriptionError ? `${t(i18nKeys.challenges.subSections.issues.formError.description)}` : ''}>
          <MarkDownPreviewEditor text={formData.description} onTextChanged={(e) => updateEditorData(e)} />
        </FormField>
        <div style={{ width: inputWidth }}>
          <SpaceBetween size="s">
            <FormField label="Challenge">
              <Input value={challenge?.challengeId || ''} readOnly />
            </FormField>
            <FormField label="Severity">
              <Select
                selectedOption={{ label: labelData(formData.severity), value: formData.severity }}
                onChange={({ detail }) => setFormData({ ...formData, severity: detail.selectedOption.value || '' })}
                options={[
                  {
                    value: ChallengeIssueSeverity.CRITICAL,
                    label: ChallengeIssueSeverityDescriptions.CRITICAL,
                  },
                  { value: ChallengeIssueSeverity.HIGH, label: ChallengeIssueSeverityDescriptions.HIGH },
                  { value: ChallengeIssueSeverity.MEDIUM, label: ChallengeIssueSeverityDescriptions.MEDIUM },
                  { value: ChallengeIssueSeverity.LOW, label: ChallengeIssueSeverityDescriptions.LOW },
                ]}
              />
            </FormField>
            <FormField label="Severity">
              <RadioGroup
                onChange={({ detail }) =>
                  setFormData({ ...formData, isPrivate: detail.value === 'true' ? true : false })
                }
                value={formData.isPrivate === true ? 'true' : 'false'}
                items={[
                  { value: 'true', label: `${t(i18nKeys.challenges.challengeDetails.labels.private.yes)}` },
                  { value: 'false', label: `${t(i18nKeys.challenges.challengeDetails.labels.private.no)}` },
                ]}
              />
            </FormField>
          </SpaceBetween>
        </div>
        <div style={{ borderTop: '1px solid var(--grey-200, #e9ebed)', paddingTop: '10px' }}>
          <Box float="right">
            <SpaceBetween size="l" direction="horizontal">
              <Button id='challenge-new-issue-cancel' data-testid='challenge-new-issue-cancel' onClick={() => toggleNewIssues()}> {t(i18nKeys.general.cancel)}</Button>
              <Button id='challenge-new-issue-submit' data-testid='challenge-new-issue-submit' variant="primary" onClick={() => void onFormSubmit()}>
                {t(i18nKeys.challenges.challengeDetails.buttons.submitNewIssue)}
              </Button>
            </SpaceBetween>
          </Box>
        </div>
      </SpaceBetween>
    </Container>
  );
};

export default NewIssue;
