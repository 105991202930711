import React from 'react';
import { CollectionPreferencesProps, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

// components
import EventDetailChallenge from '@/src/components/ui/organisms/EventTemplate/EventDetailChallenge';

// types
import { ChallengeListItem } from '@/src/types/Challenge';
import { EventDurationInfo } from '@/src/types/EventTemplate';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';
import JamSpinner from "@/src/components/common/JamSpinner";

interface IEventDetailChallengesSection {
  primaryChallenges: ChallengeListItem[];
  backupChallenges: ChallengeListItem[];
  getEventDurationInfo: () => EventDurationInfo | undefined;
  eventTemplateChallengePreferences: CollectionPreferencesProps.Preferences;
  toggleChallengeDetailsInfo: (challenge: ChallengeListItem) => void;
}

const EventDetailChallengesSection: React.FC<IEventDetailChallengesSection> = ({
  primaryChallenges,
  backupChallenges,
  eventTemplateChallengePreferences,
  toggleChallengeDetailsInfo,
}) => {
  if (primaryChallenges.length === 0 && backupChallenges.length === 0) {
    return (
      <div style={{ marginTop: '5rem' }}>
        <JamSpinner />
      </div>
    );
  }
  const { t } = useTranslation();

  return (
    <SpaceBetween size="l">
      {primaryChallenges.length > 0 && (
        <EventDetailChallenge
          title={t(i18nKeys.eventTemplates.step3.primaryChallenges)}
          challenges={primaryChallenges}
          eventTemplateChallengePreferences={eventTemplateChallengePreferences}
          toggleChallengeDetailsInfo={toggleChallengeDetailsInfo}
        />
      )}

      {backupChallenges.length > 0 && (
        <EventDetailChallenge
          title={t(i18nKeys.eventTemplates.step3.backupChallenges)}
          challenges={backupChallenges}
          eventTemplateChallengePreferences={eventTemplateChallengePreferences}
          toggleChallengeDetailsInfo={toggleChallengeDetailsInfo}
        />
      )}
    </SpaceBetween>
  );
};

export default EventDetailChallengesSection;
