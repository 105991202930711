import React from 'react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  Container,
  FormField,
  Header,
  Input,
  InputProps,
  Multiselect,
  MultiselectProps,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import { TextareaProps } from '@amzn/awsui-components-react/uxdg';
import { useTranslation } from 'react-i18next';
import { getSelectedTags, getSelectedTopics } from '@/src/utils/event-template.utils';
import { EVENT_TEMPLATE_TAGS, EVENT_TEMPLATE_TOPICS } from '@/src/constants/event-template.constants';
import {
  EventDurationType,
  EventLearningType,
  EventTemplateDetailFields,
  EventTemplateOfferMap,
} from '@/src/types/EventTemplate';
import EventConfiguration from '@/src/components/event-templates/EventTemplateCreate/Sections/NameDuration/EventConfiguration';

interface IProps {
  hideEventConfiguration?: boolean;
  summary: string;
  teamSize: number;
  minParticipants: number;
  maxParticipants: number;
  topics: string[];
  learningType?: EventLearningType;
  duration: EventDurationType | undefined;
  tags: string[];
  offers?: EventTemplateOfferMap;
  onSummaryChange: (summmary: string) => void;
  onTopicsChange: (topics: string[]) => void;
  onTeamSizeChange: (teamSize: number) => void;
  onMinParticipantsChange: (value: number) => void;
  onMaxParticipantsChange: (value: number) => void;
  onTagsChange: (tags: string[]) => void;
  onLearningTypeChange: (learningType: EventLearningType) => void;
  onDurationChange: (eventDuration: EventDurationType) => void;
  validator: {
    isValidSection: (setErrors?: boolean | undefined) => boolean;
    isValidField: (field: EventTemplateDetailFields, setError?: boolean | undefined) => boolean;
  };
  eventDetailsSummaryError: string;
  eventDetailsTopicError: string;
  eventDetailsTeamSizeError: string;
  eventDetailsMinParticipantsError: string;
  eventDetailsMaxParticipantsError: string;
  eventDetailsTagsError: string;
}
const DetailSection: React.FC<IProps> = ({
  hideEventConfiguration,
  summary,
  teamSize,
  minParticipants,
  maxParticipants,
  topics,
  learningType,
  duration,
  tags,
  offers,
  onSummaryChange,
  onTopicsChange,
  onTeamSizeChange,
  onMinParticipantsChange,
  onMaxParticipantsChange,
  onTagsChange,
  onLearningTypeChange,
  onDurationChange,
  validator,
  eventDetailsSummaryError,
  eventDetailsTopicError,
  eventDetailsTeamSizeError,
  eventDetailsMinParticipantsError,
  eventDetailsMaxParticipantsError,
  eventDetailsTagsError,
}) => {
  const { t } = useTranslation();

  const handleSummaryChange: TextareaProps['onChange'] = (e) => {
    onSummaryChange(e.detail.value);
  };

  const handleTopicChange: MultiselectProps['onChange'] = (e) => {
    const selections = e.detail.selectedOptions;
    onTopicsChange(selections.map((item) => item.value as string));
  };

  const handleTagsChange: MultiselectProps['onChange'] = (e) => {
    const selections = e.detail.selectedOptions;
    onTagsChange(selections.map((item) => item.value as string));
  };

  const handleTeamSizeChange: InputProps['onChange'] = (e) => {
    onTeamSizeChange(parseInt(e.detail.value, 10));
  };

  const handleMinParticipantsChange: InputProps['onChange'] = (e) => {
    onMinParticipantsChange(parseInt(e.detail.value, 10));
  };

  const handleMaxParticipantsChange: InputProps['onChange'] = (e) => {
    onMaxParticipantsChange(parseInt(e.detail.value, 10));
  };

  return (
    <Container header={<Header variant="h2">{t(i18nKeys.eventTemplates.step4.eventDetails)}</Header>}>
      <SpaceBetween direction="vertical" size="l">
        {!hideEventConfiguration && (
          <EventConfiguration
            learningType={learningType}
            onLearningTypeChange={onLearningTypeChange}
            offers={offers}
            duration={duration}
            eventDurationError={''}
            onDurationChange={onDurationChange}
          />
        )}
        <FormField label={t(i18nKeys.eventTemplates.step4.summary.label)} i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}} errorText={eventDetailsSummaryError}>
          <Textarea
            placeholder={t(i18nKeys.eventTemplates.step4.summary.placeholder)}
            value={summary}
            onChange={handleSummaryChange}
            onBlur={() => validator.isValidField(EventTemplateDetailFields.EVENT_SUMMARY)}
          />
        </FormField>
        <FormField label={t(i18nKeys.eventTemplates.step4.topic.label)} i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}} errorText={eventDetailsTopicError}>
          <Multiselect
            selectedOptions={getSelectedTopics(topics)}
            onChange={handleTopicChange}
            onBlur={() => validator.isValidField(EventTemplateDetailFields.EVENT_TOPIC)}
            deselectAriaLabel={(e) => `${t(i18nKeys.general.remove)} ${e.label}`}
            options={EVENT_TEMPLATE_TOPICS}
            placeholder={t(i18nKeys.eventTemplates.step4.topic.placeholder)}
            selectedAriaLabel={t(i18nKeys.eventTemplates.step4.topic.selectedAriaLabel)}
          />
        </FormField>
        <FormField
          label={t(i18nKeys.eventTemplates.step4.recommendedTeamSize.label)}
          description={t(i18nKeys.eventTemplates.step4.recommendedTeamSize.description)}
          constraintText={t(i18nKeys.eventTemplates.step4.recommendedTeamSize.constraintText)}
          i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}}
          errorText={eventDetailsTeamSizeError}>
          <div className="teamSize">
            <Input
              type="number"
              inputMode={'numeric'}
              disabled={learningType === EventLearningType.INDIVIDUAL}
              value={`${teamSize}`}
              onChange={handleTeamSizeChange}
              onBlur={() => validator.isValidField(EventTemplateDetailFields.EVENT_TEAM_SIZE)}
            />
          </div>
        </FormField>
        <FormField
          label={t(i18nKeys.eventTemplates.step4.participants.label)}
          description={t(i18nKeys.eventTemplates.step4.participants.description)}
          constraintText={t(i18nKeys.eventTemplates.step4.participants.constraintText)}
          i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}}
          errorText={eventDetailsMinParticipantsError || eventDetailsMaxParticipantsError}>
          <div className="participants">
            <Input
              type="number"
              inputMode={'numeric'}
              value={`${minParticipants}`}
              onChange={handleMinParticipantsChange}
              controlId="minParticipants"
              onBlur={() =>
                validator.isValidField(EventTemplateDetailFields.EVENT_MIN_PARTICIPANTS) &&
                // validate max only after it's set
                (!Number.isSafeInteger(maxParticipants) ||
                  validator.isValidField(EventTemplateDetailFields.EVENT_MAX_PARTICIPANTS))
              }
            />
            -
            <Input
              type="number"
              inputMode={'numeric'}
              value={`${maxParticipants}`}
              onChange={handleMaxParticipantsChange}
              controlId="maxParticipants"
              onBlur={() =>
                validator.isValidField(EventTemplateDetailFields.EVENT_MIN_PARTICIPANTS) &&
                validator.isValidField(EventTemplateDetailFields.EVENT_MAX_PARTICIPANTS)
              }
            />
          </div>
        </FormField>
        <FormField label={t(i18nKeys.eventTemplates.step4.tags.label)} i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}} errorText={eventDetailsTagsError}>
          <Multiselect
            selectedOptions={getSelectedTags(tags)}
            onChange={handleTagsChange}
            onBlur={() => validator.isValidField(EventTemplateDetailFields.EVENT_TAGS)}
            deselectAriaLabel={(e) => `${t(i18nKeys.general.remove)} ${e.label}`}
            options={EVENT_TEMPLATE_TAGS}
            placeholder={t(i18nKeys.eventTemplates.step4.tags.placeholder)}
            selectedAriaLabel={t(i18nKeys.eventTemplates.step4.tags.selectedAriaLabel)}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};
export default DetailSection;
