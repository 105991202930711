import React from 'react';
import { Box } from '@amzn/awsui-components-react';
import './KeyValuePairRow.scss';

export interface KeyValuePairRowProps {
  param: string;
  value: string | number;
  variant?: 'bordered' | 'none';
  color?: 'link' | 'primary' | 'secondary';
  isLast?: boolean;
  action?: React.ReactNode;
}

const KeyValuePairRow: React.FC<KeyValuePairRowProps> = ({
  param,
  value,
  variant = 'none',
  color = 'primary',
  isLast,
  action,
}) => {
  return (
    <div className={`key-value-pair-row ${variant === 'bordered' ? 'bordered' : ''} ${isLast ? 'last' : ''}`}>
      <Box variant="h5">{param}</Box>
      <Box variant="h5" data-classname={`value-${color}`}>
        {value} {action}
      </Box>
    </div>
  );
};

export default KeyValuePairRow;
