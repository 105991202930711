export enum ModalType {
    'RemovePassword' = 1,
    'RenameTeam' = 2,
    'ChangePassword' = 3,
    'EditTeamGoal' = 4,
  }

  export enum ConsentMandatoryFields {
    email = 'email',
    firstName = 'firstName',
    lastName = 'lastName',
    title = 'title',
    company = 'company',
    country = 'country',
    stateOrProvince = 'stateOrProvince',
    postalCode = 'postalCode',
    address = 'address',
    phoneNumber = 'phoneNumber',
    interest = 'interest',
  }
