import { RoutePath } from '@/src/RoutePath';
import { i18nKeys } from '@/src/utils/i18n.utils';

export const FOOTER_LINKS_TEXT = [
  {
    title: i18nKeys.homeFooter.play.text,
    sections: [
      {
        text: i18nKeys.homeFooter.play.joinAJam.text,
        link: RoutePath.JOIN_AN_EVENT,
        description: i18nKeys.homeFooter.play.joinAJam.description,
      },
      {
        text: i18nKeys.homeFooter.play.publicEvents.text,
        link: RoutePath.PUBLIC_EVENTS,
        description: i18nKeys.homeFooter.play.publicEvents.description,
      },
    ],
  },
  {
    title: i18nKeys.homeFooter.host.text,
    sections: [
      {
        text: i18nKeys.homeFooter.host.learningEvents.text,
        link: RoutePath.CATALOG,
        description: i18nKeys.homeFooter.host.learningEvents.description,
      },
      {
        text: i18nKeys.homeFooter.host.eventLibrary.text,
        link: RoutePath.MY_JAMS,
        description: i18nKeys.homeFooter.host.eventLibrary.description,
      },
    ],
  },
  {
    title: i18nKeys.homeFooter.support.text,
    sections: [
      {
        text: i18nKeys.homeFooter.support.faq.text,
        link: RoutePath.FAQ,
        description: i18nKeys.homeFooter.support.faq.description,
      },
    ],
  },
];
