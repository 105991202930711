/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Header,
  Icon,
  Link,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../store/challenge.context';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { useFlashbars } from '../../../../store/flashbar.context';
import { Challenge, ChallengeReviewableSection, IamPolicyValidationResponse } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import './IAMPolicy.scss';

interface ChallengeIAMPolicyDetailProps {
  challenge: Challenge;
}

const ChallengeIAMPolicyDetail: React.FC<ChallengeIAMPolicyDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { validateIamPolicy } = useChallenges();
  const { addErrorFlashbar } = useFlashbars();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [validationResponse, setValidationResponse] = useState<IamPolicyValidationResponse | undefined>();
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const [, setItems] = useState([
    {
      dismissible: true,
      dismissLabel: t(i18nKeys.challenges.subSections.iamPolicy.dismissLabel),
      onDismiss: () => setItems([]),
      content: <>{t(i18nKeys.challenges.subSections.iamPolicy.infoFlashbar)}</>,
    },
  ]);

  const iamPolicyFileName = `aws-jam-${challenge.challengeId}-team-iam-policy`;

  const handleFileUpload = (uploadedFile: File) => {
    // Handle file upload
    uploadedFile
      .text()
      .then((file) => {
        handleUpdateChallengeProp(ChallengePropAction.STUDENT_POLICY, file);
      })
      .catch((err) => preProdLogger(err));
  };

  const handleUpdateIamPolicy = (policy: string) => {
    if (editMode) {
      handleUpdateChallengeProp(ChallengePropAction.STUDENT_POLICY, policy);
    }
  };

  const onValidateIamPolicy = () => {
    if (editedChallenge) {
      if (!editedChallenge.props.studentPolicy || editedChallenge.props.studentPolicy.trim().length < 1) {
        addErrorFlashbar(t(i18nKeys.challenges.challengeDetails.messages.emptyPolicy));
      } else {
        validateIamPolicy(editedChallenge.props.studentPolicy)
          .then((response) => setValidationResponse(response))
          .catch((err) => preProdLogger(err));
      }
    }
  };

  const getValidationList = () => {
    if (validationResponse) {
      if (validationResponse?.findings?.length > 0) {
        return (
          <ul>
            {validationResponse.findings.map((result, i) => (
              <li key={i}>
                <b>{result.issueCode}:</b> {result.finding}{' '}
                <a href={result.learnMoreLink}> {t(i18nKeys.general.learnMore)} </a>{' '}
              </li>
            ))}
          </ul>
        );
      } else {
        return <div>{t(i18nKeys.challenges.challengeDetails.messages.emptyPolicy)}</div>;
      }
    }
  };
  const clickAnchorWithAttributes = (attributes: { [key: string]: string }): void => {
    const downloadAnchorNode = document.createElement('a');

    for (const [attrName, attrValue] of Object.entries(attributes)) {
      downloadAnchorNode.setAttribute(attrName, attrValue);
    }

    document.body.appendChild(downloadAnchorNode);

    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const downloadAsJSON = (content: string, filename: string, mimetype: string) => {
    clickAnchorWithAttributes({
      href: `data:${mimetype};charset=utf-8,${encodeURIComponent(content)}`,
      download: filename,
    });
  };

  return (
    <div className="iam-policy">
      <Container
        header={
          <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.IAM_POLICY} />}>
            {t(i18nKeys.challenges.challengeDetails.headings.teamIAMPolicy)}
          </Header>
        }>
        <SpaceBetween size="s">
          {editMode && (
            <TextContent>
              <div style={{ maxWidth: '1024px' }}>{t(i18nKeys.challenges.subSections.iamPolicy.infoFlashbar)}</div>
            </TextContent>
          )}
          {challenge.hasIamPolicy && !editMode && (
            <TextContent>
              <div style={{ maxWidth: '1024px' }}>{t(i18nKeys.challenges.subSections.iamPolicy.infoFlashbar)}</div>
            </TextContent>
          )}
          {editMode && (
            <Box float="right">
              <Button href="./show-i-am">{t(i18nKeys.challenges.challengeDetails.buttons.showIamSnippets)}</Button>
            </Box>
          )}
          {editMode && (
            <div style={{ backgroundColor: '#F4F4F4', borderRadius: '8px', padding: '20px' }}>
              <SpaceBetween size="s">
                <TextContent>
                  <strong>Tools and Resources</strong>
                </TextContent>
                <Link href="#">
                  <SpaceBetween direction="horizontal" size="xxs">
                    <Icon name="external" />
                    IAM Snippets
                  </SpaceBetween>
                </Link>
              </SpaceBetween>
            </div>
          )}
          {editMode && (
            <TextContent>
              <strong>Format:</strong> JSON only
            </TextContent>
          )}
          {editMode && (
            <div className="iam-dropzone">
              <Box padding={'l'} textAlign="center">
                <Button
                  id='challenge-i-am-policy-upload' data-testid='challenge-i-am-policy-upload'
                  variant="link"
                  iconName="upload"
                  formAction="none"
                  onClick={() => {
                    // @ts-ignore
                    hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                    // @ts-ignore
                    hiddenFileInput.current.click();
                  }}
                />

                <input
                  ref={hiddenFileInput}
                  id="chooseFileInput"
                  type="file"
                  hidden
                  multiple={false}
                  accept="application/json"
                  // @ts-ignore
                  onChange={(event) => handleFileUpload(event.target.files[0])}
                />
                <p>
                  {t(i18nKeys.challenges.subSections.iamPolicy.dropJsonFiles)}
                  <Link
                    onFollow={() => {
                      // @ts-ignore
                      hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                      // @ts-ignore
                      hiddenFileInput.current.click();
                    }}>
                    {' '}
                    {t(i18nKeys.challenges.subSections.iamPolicy.browseFiles)}
                  </Link>
                </p>
              </Box>
            </div>
          )}
          {challenge.hasIamPolicy && !editMode && (
            <div
              id="challenge-i-am-download--policy"
              data-testid="challenge-i-am-download--policy"
              onClick={() =>
                downloadAsJSON(challenge.props.studentPolicy || '', iamPolicyFileName + '.json', 'text/json')
              }>
              <Icon name="file" />
              <Link href="#">{iamPolicyFileName}</Link>
            </div>
          )}

          {editMode && (
            <JamCodeEditor
              language="json"
              value={editedChallenge?.props.studentPolicy || ''}
              setValue={(e) => {
                handleUpdateIamPolicy(e);
              }}
            />
          )}

          {!editMode && (
            <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
              <JamCodeEditor
                language="json"
                value={challenge.props.studentPolicy || ''}
                setValue={() => {
                  preProdLogger('no input allowed');
                }}
              />
            </div>
          )}
          {editMode && (
            <Box float="right">
              <Button id='challenge-i-am-policy-validate-policy' data-testid='challenge-i-am-policy-validate-policy' iconName="status-positive" onClick={onValidateIamPolicy}>
                {t(i18nKeys.challenges.subSections.iamPolicy.validatePolicy)}
              </Button>
            </Box>
          )}
          {editMode && validationResponse && (
            <ExpandableSection
              header={
                <Header variant="h3">{t(i18nKeys.challenges.challengeDetails.titles.iamSecurityFindings)}</Header>
              }>
              {getValidationList()}
            </ExpandableSection>
          )}
        </SpaceBetween>
      </Container>
    </div>
  );
};

export default ChallengeIAMPolicyDetail;
