import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, ButtonDropdown, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { JamFacilitatorTeamsAvailability, PARTICIPANT_ACTION } from '@/src/types/JamFacilitator';
import { useJamFacilitator } from '@/src/store/jam-facilitator.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useJamMyTeam } from '@/src/store/jam-myteam.context';
import ChangeTeamModal from '@/src/components/ui/molecules/JamTeam/ChangeTeamModal/ChangeTeamModal';
import RemoveTeamModal from '@/src/components/ui/molecules/JamTeam/RemoveTeamModal/RemoveTeamModal';
import { useFlashbars } from '@/src/store/flashbar.context';

export interface ParticipantInfo {
  nickname: string;
  login: string;
  teamName: string | null;
  excludeFromAutoAssignment: boolean;
  facilitator: boolean;
  skillRank?: number;
}

export interface ParticipantContainerProps {
  participant: ParticipantInfo;
  team?: JamFacilitatorTeamsAvailability;
  setLoading: (flag: boolean) => void;
}

export const SkillRank = ({ rank }: { rank: number | string }) => (
  <React.Fragment>
    <Box className={`star-rating filled`}>
      <svg
        aria-label="This is the skill profile rating"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27 17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-star">
        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
      </svg>
    </Box>
    <Box variant="span" color="text-status-warning" fontSize="heading-l">
      {rank}
    </Box>
  </React.Fragment>
);

const ParticipantContainer: React.FC<ParticipantContainerProps> = ({ participant, setLoading, team }) => {
  const { t } = useTranslation();
  const { eventName, event } = useJamEventDetails();
  const { changeTeam, removeFromTeam, makeTeamOwner } = useJamFacilitator();
  const { loadAllTeams, eventTeams } = useJamMyTeam();
  const { addErrorFlashbar, clearFlashbars } = useFlashbars();
  const [changeTeamFormVisible, setChangeTeamFormVisible] = useState(false);
  const [removeTeamFormVisible, setRemoveTeamFormVisible] = useState(false);
  const isOwner = team?.owner === participant.login;

  const { participantActions } = i18nKeys.facilitator.participants;

  const actions = useMemo(() => {
    const partialActions = [{ id: PARTICIPANT_ACTION.CHANGE_TEAM, text: `${t(participantActions.changeTeam)}` }];
    if (team) {
      partialActions.push({ id: PARTICIPANT_ACTION.REMOVE_FROM_TEAM, text: `${t(participantActions.removeFromTeam)}` });
    }

    if (team?.owner && participant.login && !event?.ended && !event?.isSingleParticipantEvent && !isOwner) {
      partialActions.push({
        id: PARTICIPANT_ACTION.MAKE_TEAM_OWNER,
        text: `${t(participantActions.makeTeamOwner)}`,
      });
    }
    return partialActions;
  }, [participant, team, event, isOwner]);

  const handleModal = (value: string) => {
    switch (value) {
      case PARTICIPANT_ACTION.CHANGE_TEAM:
        if (!changeTeamFormVisible) {
          void loadAllTeams();
        }
        setChangeTeamFormVisible((prev) => !prev);
        return;
      case PARTICIPANT_ACTION.REMOVE_FROM_TEAM:
        setRemoveTeamFormVisible(true);
        return;
      case PARTICIPANT_ACTION.MAKE_TEAM_OWNER:
        void handleMakeTeamOwner();
        return;
      default:
        return;
    }
  };

  const handleMakeTeamOwner = async () => {
    clearFlashbars();
    if (!team?.name) {
      addErrorFlashbar(t(i18nKeys.facilitator.participants.errors.noTeamFound));
      return;
    }
    try {
      setLoading(true);
      await makeTeamOwner(eventName, team.name, participant.login);
    } catch (e) {
      // erorr handled
    }
    setLoading(false);
  };

  const handleTeamChange = async (newTeamName: string) => {
    clearFlashbars();
    try {
      setLoading(true);
      await changeTeam(eventName, participant.login, newTeamName);
    } catch (e) {
      // erorr handled
    }
    setLoading(false);
  };

  const handleRemoveTeam = async (inactive: boolean) => {
    clearFlashbars();
    try {
      setLoading(true);
      await removeFromTeam(eventName, participant.login, inactive);
    } catch (e) {
      // erorr handled
    }
    setLoading(false);
  };

  return (
    <Container className="dark-bg">
      <Box className="space-between">
        <SpaceBetween direction="horizontal" size="s">
          <Box variant="h3">{participant.nickname}</Box>
          {isOwner && <SkillRank rank={t(i18nKeys.participants.headers.teamOwner)} />}
          {participant.skillRank && <SkillRank rank={participant.skillRank} />}
        </SpaceBetween>
        <ButtonDropdown
          items={actions}
          ariaLabel="action-button"
          onItemClick={({ detail }) => handleModal(detail.id)}
          variant="icon"
        />
      </Box>
      {changeTeamFormVisible && (
        <ChangeTeamModal
          teams={eventTeams.filter((eventTeam) => !eventTeam.team.facilitatorOnly)}
          visible={changeTeamFormVisible}
          handleClose={() => setChangeTeamFormVisible(false)}
          currentTeamName={participant.teamName as string}
          nickName={participant.nickname}
          handleTeamChange={handleTeamChange}
        />
      )}
      {removeTeamFormVisible && (
        <RemoveTeamModal
          visible={removeTeamFormVisible}
          handleClose={() => setRemoveTeamFormVisible(false)}
          handleRemoveTeam={handleRemoveTeam}
        />
      )}
    </Container>
  );
};

export default ParticipantContainer;
