import React from 'react';
import * as FAQ from './molecules/Answers';
import { i18nKeys } from '@/src/utils/i18n.utils';

export const questions = {
  general: [
    {
      question: 'faq.generalFaq.question.one',
      answer: <FAQ.AwsJam />,
    },
    {
      question: 'faq.generalFaq.question.two',
      answer: <FAQ.TranslatedString answer="faq.generalFaq.answer.two" />,
    },
    {
      question: 'faq.generalFaq.question.three',
      answer: <FAQ.AwsJamHelpMe />,
    },
    {
      question: 'faq.generalFaq.question.four',
      answer: <FAQ.CanIPlay />,
    },
    // Hiding following FAQ because purchase option is disabled in Jam
    // {
    //   question: 'faq.generalFaq.question.five',
    //   answer: <FAQ.HowDoPurchase />,
    // },
    {
      question: 'faq.generalFaq.question.six',
      answer: <FAQ.DoIGetBenefit />,
    },
    {
      question: 'faq.generalFaq.question.seven',
      answer: <FAQ.AwsProfessional />,
    },
    {
      question: 'faq.generalFaq.question.eight',
      answer: <FAQ.TranslatedString answer="faq.generalFaq.answer.eight" />,
    },
  ],
  host: [
    {
      question: 'faq.hostFaq.question.one',
      answer: <FAQ.CreateAwsJamEvent />,
    },
    // Hiding following FAQ because purchase option is disabled in Jam
    // {
    //   question: 'faq.hostFaq.question.two',
    //   answer: <FAQ.PurchaseOutsideUs />,
    // },
    // {
    //   question: 'faq.hostFaq.question.three',
    //   answer: <FAQ.CancelPurchaseEvent />,
    // },
    // {
    //   question: 'faq.hostFaq.question.four',
    //   answer: <FAQ.PurchasePaymentError />,
    // },
    {
      question: 'faq.hostFaq.question.five',
      answer: <FAQ.SwapChallenges />,
    },
    {
      question: 'faq.hostFaq.question.six',
      answer: <FAQ.InviteParticipant />,
    },
    {
      question: 'faq.hostFaq.question.seven',
      answer: <FAQ.InviteFacilitator />,
    },
  ],
  facilitating: [
    {
      question: 'faq.facilitatorFaq.question.one',
      answer: <FAQ.BecomeFacilitator />,
    },
    {
      question: 'faq.facilitatorFaq.question.two',
      answer: <FAQ.RoleAsFacilitator />,
    },
    {
      question: 'faq.facilitatorFaq.question.three',
      answer: <FAQ.ExpertFacilitator />,
    },
    {
      question: 'faq.facilitatorFaq.question.four',
      answer: <FAQ.TranslatedString answer="faq.facilitatorFaq.answer.four" />,
    },
    {
      question: 'faq.facilitatorFaq.question.five',
      answer: <FAQ.RestartALab />,
    },
  ],
  play: [
    {
      question: 'faq.playFaq.question.one',
      answer: <FAQ.TranslatedString answer="faq.playFaq.answer.one" />,
    },
    {
      question: 'faq.playFaq.question.two',
      answer: <FAQ.TranslatedString answer="faq.playFaq.answer.two" />,
    },
    {
      question: 'faq.playFaq.question.three',
      answer: <FAQ.JoinAJamEvent />,
    },
    {
      question: 'faq.playFaq.question.four',
      answer: <FAQ.CreateJoinTeam />,
    },
    {
      question: 'faq.playFaq.question.five',
      answer: <FAQ.CommunicateWithTeam />,
    },
    {
      question: 'faq.playFaq.question.six',
      answer: <FAQ.WhoWins />,
    },
    {
      question: 'faq.playFaq.question.seven',
      answer: <FAQ.EarnPointSolve />,
    },
    {
      question: 'faq.playFaq.question.eight',
      answer: <FAQ.HowToResolve />,
    },
  ],
};
export type QuestionSectionKeys = keyof typeof questions;
export type QuestionSection = {
  [key in QuestionSectionKeys]: { title: string; seoPageName: string };
};

// Seo page name should be match with the given details page in seoMetaTags.json
export const QuestionSections: QuestionSection = {
  general: { title: i18nKeys.faq.sections.generalFaq, seoPageName: 'general' },
  host: { title: i18nKeys.faq.sections.hostFaq, seoPageName: 'host' },
  facilitating: { title: i18nKeys.faq.sections.facilitatorFaq, seoPageName: 'facilitator' },
  play: { title: i18nKeys.faq.sections.playFaq, seoPageName: 'play' },
};
