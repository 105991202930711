import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { config } from '../config/app-config';

import { AddErrorFlashbar, AddSuccessFlashbar, useFlashbars } from './flashbar.context';
import { useUser } from './user.context';
import { useAuth } from './auth.context';

import {
  AccountAPI,
  ApiClient,
  CampaignAPI,
  ChallengeBoardAPI,
  ChallengeSetAPI,
  ChallengesAPI,
  EventTemplateAPI,
  EventsAPI,
  JamTeamAPI,
  LseAPI,
  ParticipantAPI,
  ReportsAPI,
  SettingsAPI,
  TeamAPI,
  UsagePlanAPI,
  UserAPI,
  JamDashboardAPI,
  JamChallengeAPI,
  JamConsentAPI,
  EmailParticipantsAPI,
  JamMessageAPI,
  JamSupportChatAPI,
  JamProfileAPI,
  JamFacilitatorAPI,
  JamReportAPI,
  JamEventDetailsAPI,
  PublicEventsAPI,
} from '@/src/api';
import { ParticipantEventAPI } from '../api/ParticipantEventAPI';
import { JamChallengeDetailsAPI } from '../api/JamChallengeDetailsAPI';
import { JamChallengeFeedbackAPI } from '../api/JamFeedbackAPI';
import { LabShutOffAPI } from '../api/LabShutOffAPI';

export interface ApiContextValue {
  apiClient: ApiClient | null;
  eventsApi: EventsAPI;
  challengesApi: ChallengesAPI;
  challengeSetApi: ChallengeSetAPI;
  challengeBoardApi: ChallengeBoardAPI;
  accountApi: AccountAPI;
  usagePlanApi: UsagePlanAPI;
  userApi: UserAPI;
  teamApi: TeamAPI;
  reportsApi: ReportsAPI;
  lseApi: LseAPI;
  campaignApi: CampaignAPI;
  settingsApi: SettingsAPI;
  eventTemplateApi: EventTemplateAPI;
  participantEventApi: ParticipantEventAPI;
  jamDashboardApi: JamDashboardAPI;
  jamChallengeApi: JamChallengeAPI;
  jamChallengeFeedbackApi: JamChallengeFeedbackAPI;
  jamChallengeDetailsApi: JamChallengeDetailsAPI;
  jamConsentApi: JamConsentAPI;
  jamProfileAPI: JamProfileAPI;
  emailParticipantsAPI: EmailParticipantsAPI;
  jamTeamApi: JamTeamAPI;
  jamMessageApi: JamMessageAPI;
  jamSupportChatApi: JamSupportChatAPI;
  participantAPI: ParticipantAPI;
  jamFacilitatorApi: JamFacilitatorAPI;
  jamReportAPI: JamReportAPI;
  jamEventDetailsApi: JamEventDetailsAPI;
  publicEventsAPI: PublicEventsAPI;
  labshutoffAPI: LabShutOffAPI;
}

const ApiClientContext = React.createContext<ApiContextValue>({
  apiClient: null,
  // initialize with null cast as an ApiClient so that we don't have to declare eventsAPI as EventsAPI|null
  eventsApi: new EventsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  challengesApi: new ChallengesAPI(null as unknown as ApiClient, null as unknown as TFunction),
  challengeSetApi: new ChallengeSetAPI(null as unknown as ApiClient, null as unknown as TFunction),
  challengeBoardApi: new ChallengeBoardAPI(null as unknown as ApiClient, null as unknown as TFunction),
  accountApi: new AccountAPI(null as unknown as ApiClient, null as unknown as TFunction),
  usagePlanApi: new UsagePlanAPI(null as unknown as ApiClient, null as unknown as TFunction),
  userApi: new UserAPI(null as unknown as ApiClient, null as unknown as TFunction),
  teamApi: new TeamAPI(
    null as unknown as ApiClient,
    null as unknown as AddSuccessFlashbar,
    null as unknown as AddErrorFlashbar,
    null as unknown as TFunction
  ),
  reportsApi: new ReportsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  lseApi: new LseAPI(null as unknown as ApiClient),
  campaignApi: new CampaignAPI(null as unknown as ApiClient, null as unknown as TFunction),
  settingsApi: new SettingsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  eventTemplateApi: new EventTemplateAPI(null as unknown as ApiClient, null as unknown as TFunction),
  participantEventApi: new ParticipantEventAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamDashboardApi: new JamDashboardAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamChallengeApi: new JamChallengeAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamChallengeFeedbackApi: new JamChallengeFeedbackAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamChallengeDetailsApi: new JamChallengeDetailsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamConsentApi: new JamConsentAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamProfileAPI: new JamProfileAPI(null as unknown as ApiClient, null as unknown as TFunction),
  emailParticipantsAPI: new EmailParticipantsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamTeamApi: new JamTeamAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamMessageApi: new JamMessageAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamSupportChatApi: new JamSupportChatAPI(null as unknown as ApiClient, null as unknown as TFunction),
  participantAPI: new ParticipantAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamFacilitatorApi: new JamFacilitatorAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamReportAPI: new JamReportAPI(null as unknown as ApiClient, null as unknown as TFunction),
  jamEventDetailsApi: new JamEventDetailsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  publicEventsAPI: new PublicEventsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  labshutoffAPI: new LabShutOffAPI(null as unknown as ApiClient, null as unknown as TFunction),
});

const ApiClientProvider: React.FC = ({ children }) => {
  const { addFlashbar, addSuccessFlashbar, addErrorFlashbar } = useFlashbars();
  const { authClient } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  // make a single api client and never expose any method for updating this state/client
  const [apiClient] = useState<ApiClient>(
    new ApiClient(
      config.api.origin,
      config.api.originCheckout,
      authClient,
      user,
      addFlashbar,
      addSuccessFlashbar,
      addErrorFlashbar,
      useHistory()
    )
  );

  const data: ApiContextValue = {
    apiClient,
    eventsApi: new EventsAPI(apiClient, t),
    challengesApi: new ChallengesAPI(apiClient, t),
    challengeSetApi: new ChallengeSetAPI(apiClient, t),
    challengeBoardApi: new ChallengeBoardAPI(apiClient, t),
    accountApi: new AccountAPI(apiClient, t),
    usagePlanApi: new UsagePlanAPI(apiClient, t),
    userApi: new UserAPI(apiClient, t),
    teamApi: new TeamAPI(apiClient, addSuccessFlashbar, addErrorFlashbar, t),
    reportsApi: new ReportsAPI(apiClient, t),
    lseApi: new LseAPI(apiClient),
    campaignApi: new CampaignAPI(apiClient, t),
    settingsApi: new SettingsAPI(apiClient, t),
    eventTemplateApi: new EventTemplateAPI(apiClient, t),
    participantEventApi: new ParticipantEventAPI(apiClient, t),
    jamDashboardApi: new JamDashboardAPI(apiClient, t),
    jamChallengeApi: new JamChallengeAPI(apiClient, t),
    jamChallengeFeedbackApi: new JamChallengeFeedbackAPI(apiClient, t),
    jamChallengeDetailsApi: new JamChallengeDetailsAPI(apiClient, t),
    jamConsentApi: new JamConsentAPI(apiClient, t),
    jamProfileAPI: new JamProfileAPI(apiClient, t),
    emailParticipantsAPI: new EmailParticipantsAPI(apiClient, t),
    jamTeamApi: new JamTeamAPI(apiClient, t),
    jamMessageApi: new JamMessageAPI(apiClient, t),
    jamSupportChatApi: new JamSupportChatAPI(apiClient, t),
    participantAPI: new ParticipantAPI(apiClient, t),
    jamFacilitatorApi: new JamFacilitatorAPI(apiClient, t),
    jamReportAPI: new JamReportAPI(apiClient, t),
    jamEventDetailsApi: new JamEventDetailsAPI(apiClient, t),
    publicEventsAPI: new PublicEventsAPI(apiClient, t),
    labshutoffAPI: new LabShutOffAPI(apiClient, t),
  };

  return <ApiClientContext.Provider value={data}>{children}</ApiClientContext.Provider>;
};

const useApi = () => {
  const context = useContext(ApiClientContext);
  if (context === undefined) {
    throw new Error('useApiClient can only be used inside ApiClientProvider');
  }
  return context;
};

export { ApiClientProvider, useApi };
