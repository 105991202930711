import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  ContentLayout,
  ExpandableSection,
  Grid,
  Header,
  Icon,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './JamMessages.scss';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { OfflineNotificationMessage } from '@/src/types/JamNotifications';

const JamMessages = () => {
  const { t } = useTranslation();
  const { event, loadEventMessages } = useJamEventDetails();
  const [ messages, setMessages ] = useState<OfflineNotificationMessage[]>([]);
  // TODO - re-enable once back-end implementation is fixed https://sim.amazon.com/issues/JAM-13173
  // const [ disableMarkAsRead, setDisableMarkAsRead ] = useState(true);
  // const { jamMessageApi } = useApi();

  useEffect(() => {
    void loadEventMessages();
  }, []);

  useEffect(() => {
    // Make a copy of the original messages array to reverse, since `reverse` is an in-place operation.
    const newMessages = [...(event?.messages ?? [])].reverse();
    setMessages(newMessages);
    // TODO - re-enable once back-end implementation is fixed https://sim.amazon.com/issues/JAM-13173
    // setDisableMarkAsRead(newMessages.filter(x => !x.read).length === 0);
  }, [event]);

  // TODO - re-enable once back-end implementation is fixed https://sim.amazon.com/issues/JAM-13173
  // const markAllAsRead = () => {
  //   setDisableMarkAsRead(true);
  //   void jamMessageApi.markAllMessagesAsReadForParticipant({ eventName, silent: true });
  //
  //   // Since it takes a second or two before the backend will be updated, just update locally for now
  //   const newMessages = messages.map((msg) => ({ ...msg, read: true }));
  //   setMessages(newMessages);
  // }
  //
  // const header = <Header actions={
  //   <Button disabled={disableMarkAsRead}
  //           variant="primary"
  //           onClick={markAllAsRead}
  //           data-testid="mark-as-read-btn"
  //   >
  //     {t(i18nKeys.jamMessages.actions.markAllRead)}
  //   </Button>
  // }>{t(i18nKeys.jamMessages.title)}</Header>;
  const header = <Header>{t(i18nKeys.jamMessages.title)}</Header>;

  return (
    <div className="jam-messages">
      <ContentLayout header={header}>
        {messages.length === 0 && <Container>{t(i18nKeys.jamMessages.errors.empty)}</Container>}
        <Grid gridDefinition={Array(messages.length).fill({ colspan: 12 })}>
          {messages.map(({ notificationMessage, id, received, read }, index) => (
            <ExpandableSection
              key={`${id}-${index}`}
              defaultExpanded={index === 0}
              data-testid="notification-message-expandable-section"
              variant="container"
              headerText={notificationMessage.title}
              headerActions={
                <SpaceBetween direction="horizontal" size="xxl">
                  {['warning', 'warning_with_email'].includes(notificationMessage.type) && (
                    <div className="warning-label">
                      <Icon name="status-warning" /> {t(i18nKeys.jamMessages.labels.warning)}
                    </div>
                  )}
                  {!read &&
                    <div className="unread-label">
                      <Icon name="envelope" data-testid="unread-icon" /> unread
                    </div>}
                  <Box>{moment(received).format('M/DD/YYYY, h:mm A')}</Box>
                </SpaceBetween>
              }>
              <Grid gridDefinition={[{ colspan: 10 }]}>{notificationMessage.message}</Grid>
            </ExpandableSection>
          ))}
        </Grid>
      </ContentLayout>
    </div>
  );
};

export default JamMessages;
