import {
  ContentLayout,
  Badge,
  Button,
  ButtonDropdown,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Box,
} from '@amzn/awsui-components-react';
import React, { ReactNode, useEffect, useState } from 'react';
import * as common from '../../types/common';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { campaignsRoute, CAMPAIGN_DETAILS_ROUTES } from '../../routes';
import { useCampaigns } from '../../store/campaigns.context';
import { EditCampaignActions, useEditCampaign } from '../../store/edit-campaign.context';
import { useToolPanel } from '../../store/tool-panel.context';
import { useUser } from '../../store/user.context';
import { ChallengeDescriptor } from '../../types/Challenge';
import {
  ApprovalStatus,
  ChangeRequestPendingStatus,
  ChangeRequestStatus,
  Comment,
  RequestUnapprovedStatus,
} from '../../types/common';
import { i18nKeys } from '../../utils/i18n.utils';
import { preProdLogger } from '../../utils/log.utils';
import ChallengeSelection from '../common/Challenges/ChallengeSelection';
import ChallengeTabs from '../common/Challenges/ChallengeTabs';
import Comments from '../common/Comments/Comments';
import { LoadingBar } from '../common/LoadingBar';
import TPopover from '../common/TPopover';
import CampaignAdvancedSettings from './CampaignSections/CampaignAdvancedSettings';
import CampaignSettings from './CampaignSections/CampaignSettings';
import CampaignSummary from './CampaignSections/CampaignSummary';
import GroupsList from './CampaignSections/Groups/GroupsList';
import { ConfirmModal } from '../common/ConfirmModal';
import { getEmptyEventPermissions } from '../../utils/event-campaign-common.utils';
import { StatusBadge } from '../common/StatusBadge';
import NewGroupDetails from './CampaignSections/Groups/NewGroupDetails';
import GroupDetails from './CampaignSections/Groups/GroupDetails';
import ChangeRequestSummary from '../common/ChangeRequestSummary';
import { useApi } from '../../store/api.context';
import { fromPlainObject } from '../../utils/mapper.utils';
import { Campaign, JamCampaignRequest } from '../../types/Campaign';
import { SAVE_ACTIONS } from './CampaignModel';

const CampaignDetails: React.FC = () => {
  const { t } = useTranslation();
  const {
    campaign,
    getCampaignById,
    addCampaignComment,
    updateCampaignComment,
    deleteCampaignComment,
    approveChangeRequest,
    denyChangeRequest,
    cancelChangeRequest,
    cancelCampaignRequest,
    markChangeRequestPending,
    markRequestPending,
    approveCampaignRequest,
    denyCampaignRequest,
    deleteCampaign,
  } = useCampaigns();
  const [loading, setLoading] = useState(false);
  const { campaignId }: { campaignId: string } = useParams();
  const { toggleChallengeInfo } = useToolPanel();
  const {
    campaignEditMode,
    toggleEditMode,
    hasChanges,
    saveUpdatedCampaign,
    editedCampaign,
    initializeEditCampaign,
    handleUpdateEditCampaign,
  } = useEditCampaign();
  const [typeOfSave, setTypeOfSave] = useState<common.Nullable<SAVE_ACTIONS>>();
  const { user } = useUser();
  const [userEmptyPermissionsVisible, setUserEmptyPermissionsVisible] = useState(false);
  const [confirmMarkAsPendingModalVisible, setConfirmMarkAsPendingModalVisible] = useState(false);
  const [confirmMarkChangeRequestAsPendingModalVisible, setConfirmMarkChangeRequestAsPendingModalVisible] =
    useState(false);
  const [confirmApproveChangeRequestModalVisible, setConfirmApproveChangeRequestModalVisible] = useState(false);
  const [confirmDenyChangeRequestModalVisible, setConfirmDenyChangeRequestModalVisible] = useState(false);
  const [confirmApproveCampaignRequestModalVisible, setConfirmApproveCampaignRequestModalVisible] = useState(false);
  const [confirmDenyCampaignRequestModalVisible, setConfirmDenyCampaignRequestModalVisible] = useState(false);
  const [confirmDeleteCampaignModalVisible, setConfirmDeleteCampaignModalVisible] = useState(false);
  const [confirmCloneCampaignModalVisible, setConfirmCloneCampaignModalVisible] = useState(false);
  const [confirmCancelChangeRequestModalVisible, setConfirmCancelChangeRequestModalVisible] = useState(false);
  const [confirmCancelCampaignRequestModalVisible, setConfirmCancelCampaignRequestModalVisible] = useState(false);
  const [actionDropdownItems, setActionDropdownItems] = useState<{ text: string; id: string; disabled: boolean }[]>([]);
  const [comment, setComment] = useState('');
  const [slug, setSlug] = useState('');
  const history = useHistory();
  const { campaignApi } = useApi();

  let campaignComments: Comment[] = [];
  // Comments are in FIFO format, so reversing them puts the newest on top
  if (campaign?.comments) {
    campaignComments = [...campaign?.comments].reverse();
  }

  const CAMPAIGN_ACTIONS_BUTTONS = {
    EDIT: 'edit',
    SUBMIT_CHANGE_REQUEST: 'submit-change-request',
    CANCEL_CHANGE_REQUEST: 'cancel-change-request',
    APPROVE_CHANGE_REQUEST: 'approve-change-request',
    DENY_CHANGE_REQUEST: 'deny-change-request',
    UPDATE_CAMPAIGN_REQUEST: 'update-campaign-request',
    CANCEL_CAMPAIGN_REQUEST: 'cancel-campaign-request',
    APPROVE_CAMPAIGN_REQUEST: 'approve-campaign-request',
    DENY_CAMPAIGN_REQUEST: 'deny-campaign-request',
    CHANGE_REQUEST_PENDING: 'change-request-pending',
    CAMPAIGN_REQUEST_PENDING: 'campaign-request-pending',
    DELETE_CAMPAIGN: 'delete-campaign',
    CLONE_CAMPAIGN: 'clone-campaign',
  };

  useEffect(() => {
    getCampaignById(campaignId)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        preProdLogger('Error loading campaign', err.messsage);
      });
  }, []);

  const shouldShowButton = (buttonName: string): boolean => {
    if (!campaign || !user) {
      return false;
    }
    switch (buttonName) {
      case CAMPAIGN_ACTIONS_BUTTONS.CLONE_CAMPAIGN:
        return campaign.isAdmin(user) && campaign.approved;
      case CAMPAIGN_ACTIONS_BUTTONS.DELETE_CAMPAIGN:
        return campaign.isAdmin(user);
      case CAMPAIGN_ACTIONS_BUTTONS.EDIT:
        return campaign.isAdmin(user);

      case CAMPAIGN_ACTIONS_BUTTONS.SUBMIT_CHANGE_REQUEST:
        if (!hasChanges(campaign)) {
          return false;
        }
        // Can submit a change request as long as there is no other change request in progress
        return !campaign.isAdmin(user) && campaign.approved && !campaign.pendingChangeRequest;

      case CAMPAIGN_ACTIONS_BUTTONS.CANCEL_CHANGE_REQUEST:
        // Can cancel a change request as long as the request is still in progress.
        return !campaign.isAdmin(user) && campaign.approved && campaign.pendingChangeRequest;

      case CAMPAIGN_ACTIONS_BUTTONS.APPROVE_CHANGE_REQUEST:
        // Can approve a change request as long as it is not yet resolved.
        return canEditCampaignChangeRequestStatus();

      case CAMPAIGN_ACTIONS_BUTTONS.CHANGE_REQUEST_PENDING:
        // Can set a change request to pending status as long as it is in CHANGE_REQUESTED status.
        return (
          campaign.isAdmin(user) &&
          campaign.approved &&
          campaign.changeRequest != null &&
          campaign.changeRequest.status === ChangeRequestStatus.CHANGE_REQUESTED
        );

      case CAMPAIGN_ACTIONS_BUTTONS.CAMPAIGN_REQUEST_PENDING:
        // Can set an campaign request to pending status as long as it is in REQUEST_SUBMITTED status.
        return campaign.isAdmin(user) && campaign.status === ApprovalStatus.REQUEST_SUBMITTED;

      case CAMPAIGN_ACTIONS_BUTTONS.DENY_CHANGE_REQUEST:
        // Can deny a change request as long as it is not yet resolved.
        return canEditCampaignChangeRequestStatus();

      case CAMPAIGN_ACTIONS_BUTTONS.UPDATE_CAMPAIGN_REQUEST:
        if (!hasChanges(campaign)) {
          return false;
        }
        // Can update an campaign request as long as the current campaign request is not resolved yet.
        return !canEditCampaignRequestStatus();

      case CAMPAIGN_ACTIONS_BUTTONS.CANCEL_CAMPAIGN_REQUEST:
        // Can cancel an campaign request as long as it is not yet resolved.
        return !campaign.isAdmin(user) && !campaign.requestResolved;

      case CAMPAIGN_ACTIONS_BUTTONS.APPROVE_CAMPAIGN_REQUEST:
        // Can approve an campaign request as long as it is not yet resolved.
        return canEditCampaignRequestStatus();

      case CAMPAIGN_ACTIONS_BUTTONS.DENY_CAMPAIGN_REQUEST:
        // Can deny an campaign request as long as it is not yet resolved.
        return canEditCampaignRequestStatus();

      default:
        return false;
    }
  };

  const canEditCampaignRequestStatus = () => {
    if (campaign && user) {
      return campaign.isAdmin(user) && !campaign.requestResolved;
    }
    return false;
  };

  const canEditCampaignChangeRequestStatus = () => {
    if (campaign && user) {
      return (
        campaign.isAdmin(user) &&
        campaign.approved &&
        campaign.changeRequest != null &&
        !campaign.changeRequest.resolved
      );
    } else {
      return false;
    }
  };

  const getSaveButton = () => {
    if (user && campaign) {
      switch (campaign?.status) {
        case ChangeRequestPendingStatus.CHANGE_PENDING:
        case ChangeRequestPendingStatus.CHANGE_REQUESTED:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={loading}
              disabled={!hasChanges(campaign)}
              onClick={() => saveCampaign(SAVE_ACTIONS.UPDATE_CHANGE_REQUEST)}>
              {t(i18nKeys.campaigns.buttons.updateChangeRequest)}
            </Button>
          );
        case RequestUnapprovedStatus.REQUEST_SUBMITTED:
        case RequestUnapprovedStatus.REQUEST_PENDING:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={loading}
              disabled={!hasChanges(campaign)}
              onClick={() => saveCampaign(SAVE_ACTIONS.UPDATE_CAMPAIGN_REQUEST)}>
              {t(i18nKeys.campaigns.buttons.updateCampaignRequest)}
            </Button>
          );
        default:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={loading}
              disabled={!hasChanges(campaign)}
              onClick={() => saveCampaign(SAVE_ACTIONS.SUBMIT_CHANGE_REQUEST)}>
              {t(i18nKeys.campaigns.buttons.createChangeRequest)}
            </Button>
          );
      }
    }
  };

  const saveCampaign = (saveType: SAVE_ACTIONS, byPassAlert = false) => {
    if (campaignEditMode && user && campaign && hasChanges(campaign)) {
      setTypeOfSave(saveType);
      if (!byPassAlert) {
        const proceed = alertEmptyEventPermissions();
        if (!proceed) {
          return;
        }
      }
      setTypeOfSave(null);
      setUserEmptyPermissionsVisible(false);
      setLoading(true);
      saveUpdatedCampaign(saveType)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger('Error saving campaign', err.message);
        });
    }
  };

  const alertEmptyEventPermissions = () => {
    if (editedCampaign) {
      const userNoPermissions = getEmptyEventPermissions(editedCampaign);
      if (userNoPermissions.length > 0) {
        setUserEmptyPermissionsVisible(true);
        return;
      }
    }
    return true;
  };

  const handleActionClick = (clickEvent: any) => {
    switch (clickEvent.detail.id) {
      case CAMPAIGN_ACTIONS_BUTTONS.DELETE_CAMPAIGN:
        setConfirmDeleteCampaignModalVisible(true);
        break;
      case CAMPAIGN_ACTIONS_BUTTONS.CLONE_CAMPAIGN:
        setConfirmCloneCampaignModalVisible(true);
        break;
      default:
        break;
    }
    return;
  };

  const generateReviewButtons = () => {
    const reviewButtons = [];
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.CAMPAIGN_REQUEST_PENDING)) {
      reviewButtons.push(
        <Button variant="primary" onClick={() => setConfirmMarkAsPendingModalVisible(true)}>
          {t(i18nKeys.campaigns.buttons.markCampaignRequestPending)}
        </Button>
      );
    }
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.CHANGE_REQUEST_PENDING)) {
      reviewButtons.push(
        <Button variant="primary" onClick={() => setConfirmMarkChangeRequestAsPendingModalVisible(true)}>
          {t(i18nKeys.campaigns.buttons.markChangeRequestPending)}
        </Button>
      );
    }
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.CANCEL_CHANGE_REQUEST)) {
      reviewButtons.push(
        <Button variant="normal" onClick={() => setConfirmCancelChangeRequestModalVisible(true)}>
          {t(i18nKeys.campaigns.buttons.cancelChangeRequest)}
        </Button>
      );
    }
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.DENY_CHANGE_REQUEST)) {
      reviewButtons.push(
        <Button variant="normal" onClick={() => setConfirmDenyChangeRequestModalVisible(true)}>
          {t(i18nKeys.campaigns.buttons.denyChangeRequest)}
        </Button>
      );
    }
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.APPROVE_CHANGE_REQUEST)) {
      reviewButtons.push(
        <Button variant="primary" onClick={() => setConfirmApproveChangeRequestModalVisible(true)}>
          {t(i18nKeys.campaigns.buttons.approveChangeRequest)}
        </Button>
      );
    }
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.CANCEL_CAMPAIGN_REQUEST)) {
      reviewButtons.push(
        <Button variant="normal" onClick={() => setConfirmCancelCampaignRequestModalVisible(true)}>
          {t(i18nKeys.campaigns.buttons.cancelCampaignRequest)}
        </Button>
      );
    }
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.DENY_CAMPAIGN_REQUEST)) {
      reviewButtons.push(
        <Button variant="normal" onClick={() => setConfirmDenyCampaignRequestModalVisible(true)}>
          {t(i18nKeys.campaigns.buttons.denyCampaignRequest)}
        </Button>
      );
    }
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.APPROVE_CAMPAIGN_REQUEST)) {
      reviewButtons.push(
        <Button
          variant="primary"
          onClick={() => {
            setSlug(campaign?.title?.toLowerCase().replaceAll(' ', '-') || '');
            setConfirmApproveCampaignRequestModalVisible(true);
          }}>
          {t(i18nKeys.campaigns.buttons.approveCampaignRequest)}
        </Button>
      );
    }
    return reviewButtons;
  };

  const generateActionButtons = () => {
    if (campaign) {
      return (
        <SpaceBetween direction="horizontal" size="s">
          {!campaignEditMode && (
            <SpaceBetween direction="horizontal" size="s">
              {shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.EDIT) && (
                <Button
                  variant="normal"
                  onClick={() => {
                    initializeEditCampaign(campaign);
                    toggleEditMode();
                  }}>
                  {t(i18nKeys.general.edit)}
                </Button>
              )}
              {generateReviewButtons().map((button: ReactNode) => button)}
              {actionDropdownItems?.length > 0 && (
                <ButtonDropdown onItemClick={handleActionClick} items={actionDropdownItems}>
                  {t(i18nKeys.events.eventDetails.buttons.actionsPlaceholder)}
                </ButtonDropdown>
              )}
            </SpaceBetween>
          )}
          {campaignEditMode && (
            <React.Fragment>
              <Button variant="link" onClick={() => toggleEditMode()}>
                {t(i18nKeys.general.cancel)}
              </Button>
              {getSaveButton()}
            </React.Fragment>
          )}
        </SpaceBetween>
      );
    }
  };

  const handleChallengeAction = (payload: ChallengeDescriptor[]) => {
    if (editedCampaign && campaignEditMode) {
      handleUpdateEditCampaign(EditCampaignActions.CHALLENGE_DESCRIPTORS, payload);
    }
  };

  const cleanupModals = () => {
    setConfirmApproveChangeRequestModalVisible(false);
    setConfirmDenyChangeRequestModalVisible(false);
    setConfirmMarkChangeRequestAsPendingModalVisible(false);
    setConfirmMarkAsPendingModalVisible(false);
    setConfirmApproveCampaignRequestModalVisible(false);
    setConfirmDenyCampaignRequestModalVisible(false);
    setConfirmDeleteCampaignModalVisible(false);
    setSlug('');
    setComment('');
  };

  const renderChildView = () => {
    if (campaign) {
      return (
        <Switch>
          <Route path={CAMPAIGN_DETAILS_ROUTES.Comments.wildcard()}>
            <Comments
              comments={campaignComments}
              addNewComment={addCampaignComment}
              deleteComment={deleteCampaignComment}
              updateComment={updateCampaignComment}
            />
          </Route>
          <Route path={CAMPAIGN_DETAILS_ROUTES.Challenges.wildcard()}>
            {!campaignEditMode && (
              <ChallengeSelection
                target={campaign}
                targetChallengeDescriptors={campaign.challengeDescriptors}
                toggleChallengeInfo={toggleChallengeInfo}
              />
            )}
            {campaignEditMode && (
              <ChallengeTabs
                target={editedCampaign || campaign}
                challengeDescriptors={editedCampaign?.challengeDescriptors || campaign.challengeDescriptors}
                action={EditCampaignActions.CHALLENGE_DESCRIPTORS}
                handleChallengeAction={handleChallengeAction}
                toggleChallengeInfo={toggleChallengeInfo}
              />
            )}
          </Route>
          <Route path={CAMPAIGN_DETAILS_ROUTES.AdvancedSettings.wildcard()}>
            <CampaignAdvancedSettings campaign={editedCampaign || campaign} />
          </Route>
          <Route path={CAMPAIGN_DETAILS_ROUTES.Settings.wildcard()}>
            <CampaignSettings campaign={editedCampaign || campaign} />
          </Route>
          <Route path={CAMPAIGN_DETAILS_ROUTES.NewGroup.wildcard()}>
            <NewGroupDetails campaign={editedCampaign || campaign} />
          </Route>
          <Route path={CAMPAIGN_DETAILS_ROUTES.GroupDetails.wildcard()}>
            <GroupDetails campaign={editedCampaign || campaign} />
          </Route>
          {campaign.wasEverApproved && (
            <Route path={CAMPAIGN_DETAILS_ROUTES.Groups.wildcard()}>
              <GroupsList campaign={editedCampaign || campaign} />
            </Route>
          )}
          {/* Summary must be last */}
          <Route path={CAMPAIGN_DETAILS_ROUTES.Summary.wildcard()}>
            <CampaignSummary campaign={editedCampaign || campaign} />
          </Route>
        </Switch>
      );
    }
  };

  const approveJamCampaignChangeRequest = () => {
    setLoading(true);
    approveChangeRequest(comment || '')
      .then(() => {
        setLoading(false);
        cleanupModals();
      })
      .catch(() => {
        setLoading(false);
        cleanupModals();
      });
  };

  const denyJamCampaignChangeRequest = () => {
    if (comment.length > 0) {
      setLoading(true);
      denyChangeRequest(comment)
        .then(() => {
          setLoading(false);
          cleanupModals();
        })
        .catch(() => {
          setLoading(false);
          cleanupModals();
        });
    }
  };

  const cancelJamCampaignChangeRequest = () => {
    setLoading(true);
    cancelChangeRequest(comment || '')
      .then(() => {
        setLoading(false);
        setComment('');
        setConfirmCancelChangeRequestModalVisible(false);
      })
      .catch((err) => {
        preProdLogger('Error cancelling change request', err.message);
        setLoading(false);
        setConfirmCancelChangeRequestModalVisible(false);
      });
  };

  const cancelJamCampaignRequest = () => {
    setLoading(true);
    cancelCampaignRequest(comment || '')
      .then(() => {
        setLoading(false);
        setComment('');
        setConfirmCancelCampaignRequestModalVisible(false);
      })
      .catch((err) => {
        preProdLogger('Error cancelling campaign request', err.message);
        setLoading(false);
        setConfirmCancelCampaignRequestModalVisible(false);
      });
  };

  const setJamCampaignChangeRequestPending = () => {
    setLoading(true);
    markChangeRequestPending()
      .then(() => {
        setLoading(false);
        cleanupModals();
      })
      .catch(() => {
        setLoading(false);
        cleanupModals();
      });
  };

  const setJamCampaignRequestPending = () => {
    setLoading(true);
    markRequestPending()
      .then(() => {
        setLoading(false);
        cleanupModals();
      })
      .catch(() => {
        setLoading(false);
        cleanupModals();
      });
  };

  const approveJamCampaignRequest = () => {
    if (slug.length > 0) {
      setLoading(true);
      approveCampaignRequest(slug, comment || '')
        .then(() => {
          setLoading(false);
          cleanupModals();
        })
        .catch(() => {
          setLoading(false);
          cleanupModals();
        });
    }
  };

  const denyJamCampaignRequest = () => {
    if (comment.length > 0) {
      setLoading(true);
      denyCampaignRequest(comment)
        .then(() => {
          setLoading(false);
          cleanupModals();
        })
        .catch(() => {
          setLoading(false);
          cleanupModals();
        });
    }
  };

  const deleteJamCampaign = () => {
    setLoading(true);
    deleteCampaign()
      .then(() => {
        setLoading(false);
        cleanupModals();
        history.push(campaignsRoute.path);
      })
      .catch((err) => {
        preProdLogger('Error deleting campaign', err.message);
        setLoading(false);
        cleanupModals();
      });
  };

  const handleCloneCampaign = () => {
    setLoading(true);
    const clonedCampaignRequest = fromPlainObject(campaign, JamCampaignRequest) as JamCampaignRequest;
    campaignApi
      .createJamCampaignRequest(clonedCampaignRequest)
      .then(async (res: Campaign) => {
        if (res.id) {
          setConfirmCloneCampaignModalVisible(false);
          await getCampaignById(res.id);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setConfirmCloneCampaignModalVisible(false);
        preProdLogger('Error creating campaign clone request', err.message);
      });
  };

  const generateActionDropdownButton = () => {
    const newDropdownItems: { text: string; id: string; disabled: boolean }[] = [];
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.DELETE_CAMPAIGN)) {
      newDropdownItems.push({
        text: t(i18nKeys.campaigns.buttons.deleteCampaign),
        id: CAMPAIGN_ACTIONS_BUTTONS.DELETE_CAMPAIGN,
        disabled: false,
      });
    }
    if (shouldShowButton(CAMPAIGN_ACTIONS_BUTTONS.CLONE_CAMPAIGN)) {
      newDropdownItems.push({
        text: t(i18nKeys.campaigns.buttons.cloneCampaign),
        id: CAMPAIGN_ACTIONS_BUTTONS.CLONE_CAMPAIGN,
        disabled: false,
      });
    }
    setActionDropdownItems(newDropdownItems);
  };

  /**
   * Generate event permission based actions once event has loaded
   */
  useEffect(() => {
    if (campaign) {
      generateActionDropdownButton();
    }
  }, [campaign]);

  return (
    <React.Fragment>
      {loading && <LoadingBar />}
      {!loading && (
        <React.Fragment>
          <ConfirmModal
            visible={confirmCloneCampaignModalVisible}
            title={t(i18nKeys.campaigns.modals.cloneCampaignRequest.title)}
            confirmBtnLabel={t(i18nKeys.campaigns.modals.cloneCampaignRequest.confirmButton)}
            onConfirm={() => handleCloneCampaign()}
            onCancel={() => setConfirmCloneCampaignModalVisible(false)}
          />
          <ConfirmModal
            visible={confirmApproveChangeRequestModalVisible}
            title={t(i18nKeys.campaigns.modals.approveChangeRequest.title)}
            message={
              <React.Fragment>
                {t(i18nKeys.campaigns.modals.denyChangeRequest.message)}
                <Input value={comment} onChange={({ detail }) => setComment(detail.value)} />
              </React.Fragment>
            }
            confirmBtnLabel={t(i18nKeys.campaigns.modals.approveChangeRequest.confirmButton)}
            onConfirm={() => approveJamCampaignChangeRequest()}
            onCancel={() => cleanupModals()}
          />
          <ConfirmModal
            title={t(i18nKeys.campaigns.modals.cancelChangeRequest.title)}
            message={
              <React.Fragment>
                <div>{t(i18nKeys.campaigns.modals.cancelChangeRequest.message)}</div>
                <Input value={comment} onChange={({ detail }) => setComment(detail.value)} />
              </React.Fragment>
            }
            visible={confirmCancelChangeRequestModalVisible}
            onCancel={() => {
              setComment('');
              setConfirmCancelChangeRequestModalVisible(false);
            }}
            confirmBtnLabel={t(i18nKeys.campaigns.modals.cancelChangeRequest.confirmButton)}
            onConfirm={() => cancelJamCampaignChangeRequest()}
          />
          <ConfirmModal
            title={t(i18nKeys.campaigns.modals.cancelCampaignRequest.title)}
            message={
              <React.Fragment>
                <div>{t(i18nKeys.campaigns.modals.cancelCampaignRequest.message)}</div>
                <Input value={comment} onChange={({ detail }) => setComment(detail.value)} />
              </React.Fragment>
            }
            visible={confirmCancelCampaignRequestModalVisible}
            confirmBtnLabel={t(i18nKeys.campaigns.modals.cancelCampaignRequest.confirmButton)}
            onCancel={() => {
              setComment('');
              setConfirmCancelCampaignRequestModalVisible(false);
            }}
            onConfirm={() => cancelJamCampaignRequest()}
          />
          <ConfirmModal
            visible={confirmDenyChangeRequestModalVisible}
            title={t(i18nKeys.campaigns.modals.denyChangeRequest.title)}
            message={
              <React.Fragment>
                {t(i18nKeys.campaigns.modals.denyChangeRequest.message)}
                <Input value={comment} onChange={({ detail }) => setComment(detail.value)} />
              </React.Fragment>
            }
            confirmBtnLabel={t(i18nKeys.campaigns.modals.denyChangeRequest.confirmButton)}
            onConfirm={() => denyJamCampaignChangeRequest()}
            disabled={comment.length < 1}
            onCancel={() => cleanupModals()}
          />
          <ConfirmModal
            visible={confirmMarkChangeRequestAsPendingModalVisible}
            message={t(i18nKeys.campaigns.modals.markChangeRequestPending.message)}
            confirmBtnLabel={t(i18nKeys.campaigns.modals.markChangeRequestPending.confirmButton)}
            onConfirm={() => setJamCampaignChangeRequestPending()}
            onCancel={() => cleanupModals()}
          />
          <ConfirmModal
            visible={confirmMarkAsPendingModalVisible}
            message={t(i18nKeys.campaigns.modals.markChangeRequestPending.message)}
            confirmBtnLabel={t(i18nKeys.campaigns.modals.markChangeRequestPending.confirmButton)}
            onConfirm={() => setJamCampaignRequestPending()}
            onCancel={() => cleanupModals()}
          />
          <ConfirmModal
            visible={confirmApproveCampaignRequestModalVisible}
            title={t(i18nKeys.campaigns.modals.approveCampaignRequest.title)}
            message={
              <React.Fragment>
                <FormField
                  label="Slug"
                  description={t(i18nKeys.campaigns.modals.approveCampaignRequest.slugMessage, {
                    example: 'https://game.dev.jam.proserve.aws.dev/campaigns/my-campaign-1',
                  })}>
                  <Input value={slug} onChange={({ detail }) => setSlug(detail.value)} />
                </FormField>
                {t(i18nKeys.campaigns.modals.approveCampaignRequest.message)}
                <Input value={comment} onChange={({ detail }) => setComment(detail.value)} />
              </React.Fragment>
            }
            confirmBtnLabel={t(i18nKeys.campaigns.modals.approveCampaignRequest.confirmButton)}
            disabled={slug.length < 1}
            onConfirm={() => approveJamCampaignRequest()}
            onCancel={() => cleanupModals()}
          />
          <ConfirmModal
            visible={confirmDenyCampaignRequestModalVisible}
            title={t(i18nKeys.campaigns.modals.denyCampaignRequest.title)}
            message={
              <React.Fragment>
                {t(i18nKeys.campaigns.modals.denyCampaignRequest.message)}
                <Input value={comment} onChange={({ detail }) => setComment(detail.value)} />
              </React.Fragment>
            }
            confirmBtnLabel={t(i18nKeys.campaigns.modals.denyCampaignRequest.confirmButton)}
            onCancel={() => cleanupModals()}
            disabled={comment.length < 1}
            onConfirm={() => denyJamCampaignRequest()}
          />
          <ConfirmModal
            visible={confirmDeleteCampaignModalVisible}
            message={t(i18nKeys.campaigns.modals.deleteCampaign.message)}
            confirmBtnLabel={t(i18nKeys.campaigns.modals.deleteCampaign.confirmButton)}
            onCancel={() => cleanupModals()}
            onConfirm={() => deleteJamCampaign()}
          />
          {editedCampaign && typeOfSave && (
            <ConfirmModal
              visible={userEmptyPermissionsVisible}
              title={t(i18nKeys.events.eventDetails.labels.eventActions.emptyEventPermissionsDetected)}
              message={t(i18nKeys.events.eventDetails.messages.eventActions.emptyEventPermissions, {
                emails: getEmptyEventPermissions(editedCampaign).join(', '),
              })}
              onCancel={() => setUserEmptyPermissionsVisible(false)}
              onConfirm={() => saveCampaign(typeOfSave, true)}
            />
          )}
          <ContentLayout
            disableOverlap
            header={
              <Header variant="h1" actions={generateActionButtons()}>
                <div>
                  {campaign?.title}
                  {campaign?.status && <StatusBadge status={campaign?.status} />}
                </div>
                <div style={{ marginTop: '-4px' }}>
                  {campaign?.tags &&
                    (campaign.tags.length > 1 ? (
                      <TPopover
                        headerKey={i18nKeys.campaigns.headers.tags}
                        content={
                          <SpaceBetween direction="vertical" size="s">
                            {campaign.tags.map((tag, i) => (
                              <Badge key={`campaign-tag-${i}`}>{tag}</Badge>
                            ))}
                          </SpaceBetween>
                        }>
                        {t(i18nKeys.campaigns.labels.tags, { tagCount: campaign.tags.length })}
                      </TPopover>
                    ) : (
                      <Badge>{campaign.tags[0]}</Badge>
                    ))}
                </div>
              </Header>
            }>
            <Box margin={{ top: 'l' }}>
              <SpaceBetween size="l">
                {campaign && (
                  <ChangeRequestSummary source={campaign} getPendingChanges={() => campaign.getPendingChanges()} />
                )}
                {renderChildView()}
              </SpaceBetween>
            </Box>
          </ContentLayout>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default CampaignDetails;
