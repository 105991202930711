import { Container, ContainerProps } from '@amzn/awsui-components-react';
import React from 'react';
import './JamContainer.scss';

const JamContainer: React.FC<ContainerProps> = ({ children, ...props }) => {
  return (
    <Container data-classname="container" {...props}>
      {children}
    </Container>
  );
};

// This component help you out when you have to create two jam conainers side by side with equal Height
export const JamContainerEqualHeight: React.FC<ContainerProps> = ({ children, ...props }) => {
  return (
    <Container data-classname="container-equal-height" {...props}>
      {children}
    </Container>
  );
};

export default JamContainer;
