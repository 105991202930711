import { Badge, Icon } from '@amzn/awsui-components-react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NullableString } from '../../types/common';
import { i18nKeys } from '../../utils/i18n.utils';

interface RegionBadgeProps {
  regions: NullableString[] | undefined;
  selfShutoff?: boolean;
  pauseSvg: ReactNode;
}

const RegionBadge: React.FC<RegionBadgeProps> = ({ regions, selfShutoff = false, pauseSvg }) => {
  const { t } = useTranslation();

  /**
   * The maximum number of individual region badges that can be shown side by side
   * before a single badge is shown instead of the form `<numRegions> Regions`.
   * Should be >= 1.
   *
   */
  const MAX_INDIVIDUAL_REGION_BADGES = 1;
  const regionShutoffDictionary = {
    regionSelfShutoff: t(i18nKeys.events.eventDetails.labels.labShutoff.region.selfShutoff),
    regionInheritedShutoff: t(i18nKeys.events.eventDetails.labels.labShutoff.region.inheritedShutoff),
  };

  /**
   * List regions individually or show the number of regions in the badge
   */
  return (
    <React.Fragment>
      {regions &&
        regions?.length <= MAX_INDIVIDUAL_REGION_BADGES &&
        regions.map((region, i) => {
          return (
            <span
              title={
                selfShutoff ? regionShutoffDictionary.regionSelfShutoff : regionShutoffDictionary.regionInheritedShutoff
              }
              key={i}>
              <Badge color="red">
                <Icon svg={pauseSvg} />
                {region}
              </Badge>
            </span>
          );
        })}

      {regions && regions.length > MAX_INDIVIDUAL_REGION_BADGES && (
        <span title={regions.join(', ')}>
          <Badge color="red">
            <Icon svg={pauseSvg} />
            {t(i18nKeys.events.eventDetails.labels.labShutoff.region.regionCount, { count: regions.length })}
          </Badge>
        </span>
      )}
    </React.Fragment>
  );
};

export default RegionBadge;
