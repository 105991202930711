/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { Container, Header, ColumnLayout, SpaceBetween, Box } from '@amzn/awsui-components-react';
import { DetailHeader } from '@/src/components/ui/molecules/EventTemplate';

// types
import { ChallengeListItem } from '@/src/types/Challenge';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

export interface IChallengeOrderReviewProps {
  challenges: ChallengeListItem[];
  onEditClick: () => void;
}

const { stepTitle, cardTitle, sectionTitle } = i18nKeys.eventTemplates.step5.steps.step3;

export const ChallengeOrderReview: React.FC<IChallengeOrderReviewProps> = ({ challenges, onEditClick }) => {
  const { t } = useTranslation();
  return (
    <div>
      <DetailHeader marginTop={0} heading={t(stepTitle)} onEditClick={onEditClick} />
      <Container header={<Header variant="h2">{t(cardTitle)}</Header>}>
        <ColumnLayout columns={2} variant="text-grid">
          {challenges.map((challenge: ChallengeListItem, index) => (
            <SpaceBetween key={index} size="l">
              <div>
                <Box variant="awsui-key-label">
                  {t(sectionTitle)} {index + 1}
                </Box>
                <div>{challenge.props.title}</div>
              </div>
            </SpaceBetween>
          ))}
        </ColumnLayout>
      </Container>
    </div>
  );
};
