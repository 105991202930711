import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  FormField,
  Header,
  Input,
  SpaceBetween,
  TokenGroup,
  TokenGroupProps,
} from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { EditUsagePlanActions, usePlans } from '../../../store/usage-plan/usage-plan.context';
import { isEmailValid } from '../../../utils/string.utils';
import { useUser } from '../../../store/user.context';
import { UsagePlan } from '../../../types/usage-plan/UsagePlan';

interface CreateUsagePlanOptionsProps {
  target: UsagePlan | undefined;
  errorText: string;
}

const CreateUsagePlanOptions: React.FC<CreateUsagePlanOptionsProps> = ({ target, errorText }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { handleUpdateUsagePlan } = usePlans();
  const [allowedUser, setAllowedUser] = useState('');
  const [allowedUsers, setAllowedUsers] = useState<string[]>(target?.getAllowlist() || []);
  const [allowedUserErr, setAllowedUserErr] = useState('');
  const [maintainer, setMaintainer] = useState('');
  const [maintainers, setMaintainers] = useState<string[]>(target?.getMaintainers() || []);
  const [maintainerErr, setMaintainerErr] = useState('');

  useEffect(() => {
    // if this string is set, it means the usage plan failed validation on save in the parent component
    // in that case, re-validate the inputs on this component and update the UI with individual error strings if applicable
    if (errorText) {
      onAllowlistChange(allowedUsers);
      onMaintainersChange(maintainers);
    }
  }, [errorText]);

  const onAddAllowedUser = () => {
    if (isEmailValid(allowedUser) && allowedUsers.includes(allowedUser) === false) {
      allowedUsers.push(allowedUser);
      onAllowlistChange(allowedUsers);
    }
    setAllowedUser('');
  };

  const onAllowlistChange = (allowlist: string[]) => {
    setAllowedUsers(allowlist);
    setAllowedUserErr(handleUpdateUsagePlan(EditUsagePlanActions.ALLOWLIST, allowlist));
  };

  const onAddMaintainer = () => {
    if (isEmailValid(maintainer) && maintainers.includes(maintainer) === false) {
      maintainers.push(maintainer);
      onMaintainersChange(maintainers);
    }
    setMaintainer('');
  };

  const onMaintainersChange = (maintainerList: string[]) => {
    setMaintainers(maintainerList);
    setMaintainerErr(handleUpdateUsagePlan(EditUsagePlanActions.MAINTAINERS, maintainerList));
  };

  return (
    <Container header={<Header variant={'h2'}>{t(i18nKeys.usagePlan.sections.options)}</Header>}>
      <SpaceBetween direction={'vertical'} size={'l'}>
        {/* Allowed users */}
        <FormField
          errorText={allowedUserErr}
          label={t(i18nKeys.usagePlan.fields.allowedUsers.title)}
          description={t(i18nKeys.usagePlan.fields.allowedUsers.description)}
          secondaryControl={
            <Button onClick={() => onAddAllowedUser()}>{t(i18nKeys.usagePlan.fields.allowedUsers.add)}</Button>
          }>
          <Input
            // only super admins and users who are on the maintainer list can modify the allowlist
            disabled={!user?.isSuperAdmin && !target?.isMaintainer(user)}
            value={allowedUser}
            onChange={({ detail }) => setAllowedUser(detail.value)}
            placeholder={t(i18nKeys.usagePlan.fields.allowedUsers.placeholder)}
          />
          <TokenGroup
            onDismiss={({ detail: { itemIndex } }) => {
              onAllowlistChange([...allowedUsers.slice(0, itemIndex), ...allowedUsers.slice(itemIndex + 1)]);
            }}
            items={allowedUsers.map((email) => ({ label: email } as TokenGroupProps.Item))}
          />
        </FormField>
      </SpaceBetween>
      <br />
      <SpaceBetween direction={'vertical'} size={'l'}>
        {/* maintainers */}
        <FormField
          errorText={maintainerErr}
          label={t(i18nKeys.usagePlan.fields.maintainers.title)}
          description={t(i18nKeys.usagePlan.fields.maintainers.description)}
          secondaryControl={
            <Button onClick={() => onAddMaintainer()}>{t(i18nKeys.usagePlan.fields.maintainers.add)}</Button>
          }>
          <Input
            // only super admins can modify the maintainer list
            disabled={!(user && user.isSuperAdmin)}
            value={maintainer}
            onChange={({ detail }) => setMaintainer(detail.value)}
            placeholder={t(i18nKeys.usagePlan.fields.maintainers.placeholder)}
          />
          <TokenGroup
            onDismiss={({ detail: { itemIndex } }) => {
              onMaintainersChange([...maintainers.slice(0, itemIndex), ...maintainers.slice(itemIndex + 1)]);
            }}
            items={maintainers.map(
              (email) => ({ label: email, disabled: !user?.isSuperAdmin } as TokenGroupProps.Item)
            )}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default CreateUsagePlanOptions;
