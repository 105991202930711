import { RoutePath } from '@/src/RoutePath';
import { PublicEventData } from '@/src/types/PublicEvents';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, Button, Cards, ContentLayout, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FooterLinks from '../../event-templates-catalog/molecules/FooterLinks/FooterLinks';
import './PublicEvents.scss';
import { CARD_DEFINITIONS } from './public-event.config';
import { usePublicEvents } from '@/src/store/public-events.context';
import JamSpinner from '../../common/JamSpinner';
import { useHistory } from 'react-router-dom';

const PublicEvents: React.FC = () => {
  const { t } = useTranslation();
  const [publicEventData, setPublicEventData] = useState<PublicEventData[]>([]);
  const { loadEvents, events } = usePublicEvents();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const redirectToAllEvents = () => {
    history.push(RoutePath.CATALOG);
  };
  const initialize = async () => {
    try {
      setIsLoading(true);
      await loadEvents();
    } catch (e) {
      // Log error here
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (events) {
      const newData = events?.filter((event) => event);
      setPublicEventData(newData);
    }
  }, [events]);

  useEffect(() => {
    void initialize();
  }, []);

  const getNoEventsPresent = () => {
    return (
      <div className="no-events-found">
        <SpaceBetween direction="vertical" size="xxl">
          <div className="no-events-found__info">
            <Box textAlign="center">
              <h2>{t(i18nKeys.publicEvents.publicEventsNotFound)}</h2>
              <Box padding={{ bottom: 'xs' }} variant="p">
                {t(i18nKeys.publicEvents.publicEventsNotFoundSubtitle)}
              </Box>
              <Box padding={{ bottom: 's' }} variant="p">
                {t(i18nKeys.publicEvents.publicEventsNotFoundDescription)}
              </Box>
              <Button href={RoutePath.CATALOG} variant="primary">
                {t(i18nKeys.publicEvents.publicEventsNotFoundButtonText)}
              </Button>
            </Box>
          </div>
          <div className="no-events-found__background">
            <img
              alt="no public event failed background"
              src="/assets/public-events-failed-background.png"
              width="100%"
            />
          </div>
        </SpaceBetween>
      </div>
    );
  };

  if (isLoading) {
    return <JamSpinner />;
  }

  if (!publicEventData.length) {
    return (
      <ContentLayout
        disableOverlap
        header={
          <div className="center-content">
            <Header variant="awsui-h1-sticky" description={t(i18nKeys.publicEvents.description)}>
              {t(i18nKeys.publicEvents.title)}
            </Header>
          </div>
        }>
        <div className="public-events-container no-events">
          <div className="main-body">
            <div className="main-content">
              <SpaceBetween size="m">
                <Box>
                  <h2>{t(i18nKeys.publicEvents.publicEventsNotFound)}</h2>
                </Box>
                <Box>{t(i18nKeys.publicEvents.publicEventsNotFoundSubtitle)}</Box>
                <Box>{t(i18nKeys.publicEvents.publicEventsNotFoundDescription)}</Box>
                <Button variant="primary" data-testid="primary-button" onClick={redirectToAllEvents}>
                  {t(i18nKeys.publicEvents.publicEventsNotFoundButtonText)}
                </Button>
              </SpaceBetween>
            </div>
          </div>
        </div>

        <div className="before-footer-img">
          <img src="/assets/public-events-failed-background.png" alt="" />
        </div>
        <div className="public-events-footer">
          <FooterLinks />
        </div>
      </ContentLayout>
    );
  }

  return (
    <div className="public-events-container">
      <ContentLayout
        disableOverlap
        header={
          <div className="center-content">
            <Header
              variant="awsui-h1-sticky"
              counter={`(${events?.length})`}
              description={t(i18nKeys.publicEvents.description)}>
              {t(i18nKeys.publicEvents.title)}
            </Header>
          </div>
        }>
        <div style={{ margin: '20px 100px', minHeight: '60vh' }}>
          <Cards
            stickyHeader
            cardDefinition={CARD_DEFINITIONS}
            items={publicEventData}
            variant="full-page"
            totalItemsCount={publicEventData?.length}
            empty={getNoEventsPresent()}
            cardsPerRow={[
              {
                cards: 1,
              },
              {
                minWidth: 500,
                cards: 2,
              },
              {
                minWidth: 800,
                cards: 3,
              },
            ]}
          />
        </div>
      </ContentLayout>
      <div className="public-events-footer">
        <FooterLinks />
      </div>
    </div>
  );
};

export default PublicEvents;
