import { config } from '@/src/config/app-config';
import { RoutePath } from '@/src/RoutePath';
import { i18nKeys } from '@/src/utils/i18n.utils';

export interface BookEventStep {
  image: string;
  title: string;
  background: string;
  imageClass: string;
  subtitle: string;
  link?: string;
  linkUrl?: string;
}

const step1 = {
  image: '/assets/monkey-search.png',
  title: i18nKeys.catalog.bookEventSteps.steps.step1.title,
  background: 'step__item__image__background1',
  imageClass: 'step__item__image__avatar1',
  link: i18nKeys.catalog.bookEventSteps.steps.step1.link,
  linkUrl: RoutePath.CATALOG,
  subtitle: i18nKeys.catalog.bookEventSteps.steps.step1.subtitle,
};

const step1Alt = {
  image: '/assets/monkey-search.png',
  title: i18nKeys.catalog.bookEventSteps.steps.step1Alt.title,
  background: 'step__item__image__background1',
  imageClass: 'step__item__image__avatar1',
  link: i18nKeys.catalog.bookEventSteps.steps.step1Alt.link,
  linkUrl: RoutePath.NEW_EVENT,
  subtitle: i18nKeys.catalog.bookEventSteps.steps.step1Alt.subtitle,
};

export const BOOK_EVENT_STEPS: BookEventStep[] = [
  config.hideEventCatalog ? step1Alt: step1,
  {
    image: '/assets/book-event-step2.png',
    title: i18nKeys.catalog.bookEventSteps.steps.step2.title,
    background: 'step__item__image__background2',
    imageClass: 'step__item__image__avatar2',
    subtitle: i18nKeys.catalog.bookEventSteps.steps.step2.subtitle,
  },
  {
    image: '/assets/book-event-step3.png',
    title: i18nKeys.catalog.bookEventSteps.steps.step3.title,
    background: 'step__item__image__background3',
    imageClass: 'step__item__image__avatar3',
    subtitle: i18nKeys.catalog.bookEventSteps.steps.step3.subtitle,
  },
];
