import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';

interface TransWrapperProps {
  children: (t: TFunction) => ReactNode;
}

/**
 * If you are declaring some JSX outside of a render function of a component, you might be tempted to
 * use the <Trans> component from react-i18next, but using the Trans component outside of a render function of a
 * component results in text not being updated when the user changes their preferred language. This <TFunctionProvider>
 * component simply allows providing the t function from the useTranslation hook, which solves the problem of only
 * rendering a single time on initial render.
 *
 * This component is an example of using the renderProps design pattern, where the children of this component is defined
 * as a function which expects to be called with some props that are provided by the wrapping component. In this case
 * we are providing the t function from the useTranslation hook.
 * 
 * @param children
 * @constructor
 */
export const TFunctionProvider: React.FC<TransWrapperProps> = ({ children }) => {
  const { t } = useTranslation();
  return <>{children(t)}</>;
};
