import React, { useMemo } from 'react';
import { FormField, SpaceBetween, Tiles } from '@amzn/awsui-components-react';
import { CustomDuration } from '../CustomDuration';
import { useTranslation } from 'react-i18next';
import { EventDurationTile } from '../EventDurationTile';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { EventDurationType } from '@/src/types/EventTemplate';
import { IEventDurationProps } from './EventDuration.types';

// Localization keys
const { eventDuration } = i18nKeys.eventTemplates.step1;

export const EventDuration: React.FC<IEventDurationProps> = ({
  stretch,
  items,
  selectedValue,
  onOptionChange,
  customOptions,
}) => {
  const { t } = useTranslation();

  const durationItems = useMemo(() => {
    return items.map(({ label, value, data }) => {
      let description;
      if (data) {
        description = <EventDurationTile {...data} />;
      }
      return { label: t(label), value, description };
    });
  }, [items, t]);

  return (
    <SpaceBetween size="s">
      <FormField label={t(eventDuration.duration)} stretch={stretch}>
        <Tiles
          ariaLabel={t(eventDuration.durationOptions)}
          items={durationItems}
          value={selectedValue ?? null}
          onChange={onOptionChange}
        />
      </FormField>
      {durationItems.find((data) => data.value === EventDurationType.CUSTOM) &&
        selectedValue === EventDurationType.CUSTOM &&
        customOptions && <CustomDuration {...customOptions} />}
    </SpaceBetween>
  );
};
