import {
  Checkbox,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
interface PublicEventSettingsProps {
  event: Event;
}

const PublicEventSettings: React.FC<PublicEventSettingsProps> = ({ event }) => {
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { user } = useUser();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (user && event) {
      setDisabled(!event?.canEditAttribute('publicEventDetails', user));
    }
  }, [editMode]);

  return (
    <React.Fragment>
      {event?.approved && user?.isEventAdmin && !event?.test && (
        <ExpandableSection
          variant="container"
          header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.publicEventSettings)}</Header>}>
          {editMode && (
            <Grid gridDefinition={[{ colspan: 8 }]}>
              <SpaceBetween direction="vertical" size="s">
                <Checkbox
                  checked={editedEvent?.publicEventDetails.visible || false}
                  disabled={disabled}
                  onChange={({ detail }) =>
                    handleUpdateEditEvent(EditEventActions.PUBLIC_DETAILS_VISIBLE, detail.checked)
                  }>
                  {t(i18nKeys.events.eventDetails.labels.publicEventSettings.showOnHomePage)}
                </Checkbox>
                <FormField label={t(i18nKeys.events.eventDetails.labels.publicEventSettings.title)}>
                  <Input
                    placeholder={t(i18nKeys.events.eventDetails.labels.publicEventSettings.titlePlaceholder)}
                    value={editedEvent?.publicEventDetails.title || ''}
                    disabled={disabled}
                    onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.PUBLIC_TITLE, detail.value)}
                  />
                </FormField>
                <FormField label={t(i18nKeys.events.eventDetails.labels.publicEventSettings.description)}>
                  <Textarea
                    placeholder={t(i18nKeys.events.eventDetails.labels.publicEventSettings.descriptionPlaceholder)}
                    value={editedEvent?.publicEventDetails.description || ''}
                    rows={5}
                    disabled={disabled}
                    onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.PUBLIC_DESCRIPTION, detail.value)}
                  />
                </FormField>
                <FormField label={t(i18nKeys.events.eventDetails.labels.publicEventSettings.location)}>
                  <Input
                    placeholder={t(i18nKeys.events.eventDetails.labels.publicEventSettings.locationPlaceholder)}
                    value={editedEvent?.publicEventDetails.location || ''}
                    disabled={disabled}
                    onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.PUBLIC_LOCATION, detail.value)}
                  />
                </FormField>
                <FormField label={t(i18nKeys.events.eventDetails.labels.publicEventSettings.moreInfoURL)}>
                  <Input
                    placeholder={t(i18nKeys.events.eventDetails.labels.publicEventSettings.moreInfoURLPlaceholder)}
                    value={editedEvent?.publicEventDetails.moreInfoURL || ''}
                    disabled={disabled}
                    onChange={({ detail }) =>
                      handleUpdateEditEvent(EditEventActions.PUBLIC_MORE_INFO_URL, detail.value)
                    }
                  />
                </FormField>
                <FormField
                  label={t(i18nKeys.events.eventDetails.labels.publicEventSettings.imageFilename)}
                  description={t(i18nKeys.events.eventDetails.labels.publicEventSettings.imageFilenameDescription)}>
                  <Input
                    placeholder={t(i18nKeys.events.eventDetails.labels.publicEventSettings.imageFilenamePlaceholder)}
                    value={editedEvent?.publicEventDetails.imageFilename || ''}
                    disabled={disabled}
                    onChange={({ detail }) =>
                      handleUpdateEditEvent(EditEventActions.PUBLIC_IMAGE_FILE_NAME, detail.value)
                    }
                  />
                </FormField>
              </SpaceBetween>
            </Grid>
          )}
          {!editMode && (
            <React.Fragment>
              <div className="section-first-row">
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.labels.publicEventSettings.showOnHomePage)}
                  </div>
                  <div>{event?.publicEventDetails.visible ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
                </Grid>
              </div>
              <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.labels.publicEventSettings.title)}
                  </div>
                  <div>
                    {!_.isEmpty(event?.publicEventDetails.title)
                      ? event?.publicEventDetails.title
                      : t(i18nKeys.general.default)}
                  </div>
                </Grid>
              </div>
              <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.labels.publicEventSettings.description)}
                  </div>
                  <div>
                    {!_.isEmpty(event?.publicEventDetails.description)
                      ? event?.publicEventDetails.description
                      : t(i18nKeys.general.default)}
                  </div>
                </Grid>
              </div>
              <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.labels.publicEventSettings.location)}
                  </div>
                  <div>
                    {!_.isEmpty(event?.publicEventDetails.location)
                      ? event?.publicEventDetails.location
                      : t(i18nKeys.general.default)}
                  </div>
                </Grid>
              </div>
              <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.labels.publicEventSettings.moreInfoURL)}
                  </div>
                  <div>
                    {!_.isEmpty(event?.publicEventDetails.moreInfoURL)
                      ? event?.publicEventDetails.moreInfoURL
                      : t(i18nKeys.general.default)}
                  </div>
                </Grid>
              </div>
              <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.labels.publicEventSettings.imageFilename)}
                  </div>
                  <div>
                    {!_.isEmpty(event?.publicEventDetails.imageFilename)
                      ? event?.publicEventDetails.imageFilename
                      : t(i18nKeys.general.default)}
                  </div>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </ExpandableSection>
      )}
    </React.Fragment>
  );
};
export default PublicEventSettings;
