/* eslint-disable @typescript-eslint/unbound-method */
import React, { useContext, useMemo, useState } from 'react';
import { useApi } from './api.context';
import { JamEventDetails } from '../types/JamEventDetails';
import { OfflineNotificationMessage } from '../types/JamNotifications';
import { JamEvent } from '../types/JamEvent';
import { JamEventIncident } from '../api/JamEventIncident';
import { getErrorMessage } from '../utils/errors.utils';
import { i18nKeys } from '../utils/i18n.utils';
import { errorFlashbar } from '../utils/notification.utils';
import { useFlashbars } from './flashbar.context';
import { useTranslation } from 'react-i18next';

export interface JamEventDetailsContextValue {
  event?: JamEventDetails;
  isFetchingEvent: boolean;
  eventName: string;
  eventSlug: string;
  unreadMessageCounts: number;
  eventIncidents: JamEventIncident[];
  loadEventDetails: (eventName: string, isFetching?: boolean, throwError?: boolean) => Promise<void>;
  loadEventMessages: () => Promise<void>;
  loadEventIncidents: () => Promise<void>;
  updateParticipantEventMessages: (event: OfflineNotificationMessage[]) => void;
  updateEventByJamEvent: (event: JamEvent) => void;
  updateEventIncidents: (incidents: JamEventIncident) => void;
  resetEvent: () => void;
}

const JamEventDetailsContext = React.createContext<JamEventDetailsContextValue>({
  event: undefined,
  isFetchingEvent: true,
  eventName: '',
  eventSlug: '',
  unreadMessageCounts: 0,
  eventIncidents: [],
  loadEventDetails: (_eventName: string) =>
    new Promise(() => {
      // do nothing
    }),
  loadEventMessages: () =>
    new Promise(() => {
      // do nothing
    }),
  loadEventIncidents: () =>
    new Promise(() => {
      // do nothing
    }),
  updateParticipantEventMessages: (_messages: OfflineNotificationMessage[]) => {
    // do nothing
  },
  updateEventByJamEvent: (_event: JamEvent) => {
    // do nothing
  },
  updateEventIncidents: () => {
    // do nothing
  },
  resetEvent: () => {
    // do nothing
  },
});

const JamEventDetailsProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { jamEventDetailsApi, participantEventApi, jamFacilitatorApi } = useApi();
  const { addFlashbar } = useFlashbars();

  const [event, setEvent] = useState<JamEventDetails>();
  const [isFetchingEvent, setIsFetchingEvent] = useState(true);
  const [eventIncidents, setEventIncidents] = useState<JamEventIncident[]>([]);

  const eventName = event?.eventName || '';
  const eventSlug = useMemo(() => {
    /**
     * to get the event by slug we pass the slug
     * in event details API first time when we load
     * the event.
     *
     * but for campaign we need two params, campaign slug and campaign parameter
     * which will not be there as per current routing system (i.e. /jam/campaigns/:campaignSlug/:campaignGroupId vs /jam/:eventSlug)
     * so it will break if we use slug for campaign.
     * hence slug should be uuid of event for campaign
     * */
    if (event?.type === 'CAMPAIGN_GROUP') {
      return eventName;
    }
    return event?.eventPath || eventName;
  }, [event, eventName]);

  const unreadMessageCounts = useMemo(() => {
    return (event?.messages || []).filter(({ read }) => !read).length;
  }, [event?.messages]);

  const loadEventDetails = async (name: string, isFetching = true, throwError = false) => {
    try {
      setIsFetchingEvent(isFetching);
      const response: JamEventDetails = await jamEventDetailsApi.getEventDetails(name, true);
      setEvent(response);
    } catch (err: any) {
      // Error
      const errMessage = <div dangerouslySetInnerHTML={{ __html: getErrorMessage(err) }} />;
      addFlashbar(errorFlashbar(t(i18nKeys.general.error), errMessage));
      setIsFetchingEvent(false);
      if (throwError) {
        throw err;
      }
    }
    setIsFetchingEvent(false);
  };

  const loadEventMessages = async (): Promise<void> => {
    if (!event?.eventName) {
      throw new Error('no event found');
    }
    const participantEvent = await participantEventApi.getParticipantEvent(eventName);
    const messages = (participantEvent.messages || []).map(OfflineNotificationMessage.fromPlainObject);
    event.messages = messages;
    setEvent(JamEventDetails.fromPlainObject(event));
  };

  const updateParticipantEventMessages = (messages: OfflineNotificationMessage[]) => {
    if (!event?.eventName) {
      return;
    }

    let oldMessages: OfflineNotificationMessage[] = [ ...(event?.messages || []) ];

    // A single message with a null ID means we're getting in a fresh message
    if (messages.length === 1 && messages[0].id == null) {
      event.messages = oldMessages.concat(messages);
    } else {
      // Otherwise we're re-receiving previous messages, which may contain updates to existing messages.
      // Filter out any previously received messages with null IDs as the updated message will now contain an ID.
      oldMessages = oldMessages.filter(msg => msg.id != null);
      const newMessages: OfflineNotificationMessage[] = [];

      messages.map(OfflineNotificationMessage.fromPlainObject).forEach((newMessage) => {
        const msgIndex = oldMessages.findIndex((msg) => msg.id === newMessage.id);
        if (msgIndex >= 0) {
          oldMessages[msgIndex] = newMessage;
        } else {
          newMessages.push(newMessage);
        }
      });

      event.messages = oldMessages.concat(newMessages);
    }

    setEvent(JamEventDetails.fromPlainObject(event));
  };

  const updateEventByJamEvent = (wsEvent: JamEvent) => {
    if (!event) {
      return;
    }
    event.eventStatus = wsEvent.status;
    event.allowViewChallenges = wsEvent.allowViewChallenges;
    event.labExtensionHours = wsEvent.labExtensionHours;
    event.supportChatEnabled = wsEvent.collaborationOptions.supportChatEnabled;
    event.audienceType = wsEvent.audienceType;
    event.eventEndDate = wsEvent.endDate;
    setEvent(JamEventDetails.fromPlainObject(event));
  };

  const loadEventIncidents = async () => {
    if (!event) {
      throw new Error('event not found');
    }

    const response = await jamFacilitatorApi.getEventIncidents(eventName);
    setEventIncidents(response);
  };

  const updateEventIncidents = (incident: JamEventIncident) => {
    setEventIncidents((prev) => [incident, ...prev]);
  };

  const resetEvent = () => {
    setEvent(undefined);
  };

  const data: JamEventDetailsContextValue = {
    event,
    isFetchingEvent,
    eventName,
    eventSlug,
    unreadMessageCounts,
    eventIncidents,
    loadEventDetails,
    loadEventMessages,
    loadEventIncidents,
    updateParticipantEventMessages,
    updateEventByJamEvent,
    updateEventIncidents,
    resetEvent,
  };
  return <JamEventDetailsContext.Provider value={data}>{children}</JamEventDetailsContext.Provider>;
};

const useJamEventDetails = () => {
  const context = useContext(JamEventDetailsContext);
  if (context === undefined) {
    throw new Error('useJamMyTeamClient can only be used inside JamMyTeamProvider');
  }
  return context;
};

export { JamEventDetailsProvider, useJamEventDetails };
