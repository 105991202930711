import { useHistory, useLocation } from 'react-router-dom';
import { getCurrentBreadCrumbs } from '../utils/route.utils';
import { RoutePath } from '../RoutePath';
import {useApi} from "@/src/store/api.context";
import { useEffect } from 'react';

export const useActiveRouteDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { eventsApi } = useApi();
  const breadcrumbs = getCurrentBreadCrumbs(history.location.pathname);
  const isCatalogDetails = location.pathname.includes(RoutePath.CATALOG_DETAIL) || location.pathname.includes(RoutePath.PUBLIC_EVENTS);
  const enableBreadCrumb = isCatalogDetails || breadcrumbs.length < 2;

  const fetchEventAndRedirect = async () => {
    const urlSearchParams = new URLSearchParams(location.search);
    const tcOrderId = urlSearchParams.get('tcOrderId');
    if (tcOrderId) {
      const eventResponse = await eventsApi.getEventByOrderId(tcOrderId);
      const eventId = eventResponse.event.name;
      history.push(`/events/${eventId}`);
    }
  };

  useEffect(() => {
    void fetchEventAndRedirect();
  }, []);

  return {
    activeRoute: location.pathname,
    breadcrumbs,
    isHome: enableBreadCrumb,
    isCatalog: location.pathname === RoutePath.CATALOG,
    isCatalogDetails,
    isCreateJamProfile: location.pathname === RoutePath.JAM_PROFILE_CREATE,
  };
};
