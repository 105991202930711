export const GAME_API_PREFIX = '/game';
export const TEAM_CHALLENGE_API_PREFIX = '/teamchallenge';
export const FACILITATOR_API_PREFIX = '/facilitator';

/**
 * this is for game experience urls
 *
 * if you change this, you also need to
 * explicitly change it under
 * RoutePath.ts as it is enum and don't
 * accept dynamic value
 *
 * and myjam.utils.ts file as well
 */
export const MY_JAM_PREFIX = ''; // replace /jam with empty
