import React from 'react';
import { Box, Button, ColumnLayout, Container, Grid } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { JamTask, JamTaskStatus } from '@/src/types/JamChallengeDetails';
import { sum } from 'lodash';

interface TaskPointContainerProps {
  task: JamTask | undefined;
  handleProgressCheck: () => Promise<any>;
  disableProgressButton: boolean;
}

const TaskPointContainer: React.FC<TaskPointContainerProps> = ({ task, handleProgressCheck, disableProgressButton }) => {
  const { t } = useTranslation();

  const TotalPenalty = React.useMemo(
    () =>
      task?.clues && task.clues.length > 0
        ? sum(task?.clues.filter((clue) => clue.used).map((clue) => clue.cluePenalty))
        : 0,
    [task?.clues]
  );

  const AvailablePoints = React.useMemo(() => {
    const Points = (task?.pointsPossible ?? 0) - (task?.pointsEarned ?? 0);
    return !!Points ? Points - TotalPenalty : 0;
  }, [task?.clues, TotalPenalty]);

  const showProgressCheckButton = task?.status !== JamTaskStatus.COMPLETE && task?.validatedByLambda;
  const gridDefinition=[{ colspan: 10 }];
  if (showProgressCheckButton) {
    gridDefinition.push({ colspan: 2 });
  }

  return (
    <Container variant="default" className="dark-bg">
      <Grid gridDefinition={gridDefinition}>
        <ColumnLayout columns={3} variant="text-grid">
          <Box variant="div">
            <Box>{t(i18nKeys.myJams.challenges.details.task.possiblePoints)}</Box>
            <Box variant="h4" className="global-value">
              {task?.pointsPossible}
            </Box>
          </Box>
          <Box variant="div">
            <Box>{t(i18nKeys.myJams.challenges.details.task.cluePenalty)}</Box>
            <Box variant="h4" className="global-value">
              {TotalPenalty}
            </Box>
          </Box>
          <Box variant="div">
            <Box>{t(i18nKeys.myJams.challenges.details.task.PointsAvailable)}</Box>
            <Box variant="h4" className="global-value">
              {AvailablePoints}
            </Box>
          </Box>
        </ColumnLayout>
        {showProgressCheckButton && (
          <Box variant="div" margin={{ top: 'xs' }}>
            <Button variant="primary" disabled={disableProgressButton} onClick={() => void handleProgressCheck()}>
              {t(i18nKeys.myJams.challenges.details.task.buttons.checkMyProgress)}
            </Button>
          </Box>
        )}
      </Grid>
    </Container>
  );
};

export default TaskPointContainer;
