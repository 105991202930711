import React from 'react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Link } from '@amzn/awsui-components-react';
import { EventTemplateReport } from '@/src/types/EventTemplate';
import { TFunction } from 'react-i18next';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';

export const columnDefinitions = (t: TFunction<'translation', undefined>) => [
  {
    id: 'title',
    header: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.title),
    cell: (item: EventTemplateReport) => <Link href={`#${item.title}`}>{item.title}</Link>,
    isRowHeader: true,
    sortingField: 'title',
  },
  {
    id: 'owner',
    header: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.owner),
    cell: (e: EventTemplateReport) => e.owner,
    sortingField: 'owner',
  },
  {
    id: 'date',
    header: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.date),
    cell: (e: EventTemplateReport) => e.date.toLocaleString(),
    sortingField: 'date',
    sortingComparator: (a: EventTemplateReport, b: EventTemplateReport) => a.date.valueOf() - b.date.valueOf(),
  },
  {
    id: 'learners',
    header: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.learners),
    cell: (e: EventTemplateReport) => e.learners,
    sortingField: 'learners',
  },
  {
    id: 'totalRevenue',
    header: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.totalRevenue),
    cell: (e: EventTemplateReport) => `$${e.totalRevenue.toLocaleString()} (USD)`,
    sortingField: 'totalRevenue',
  },
];
export const visibleContent = ['title', 'owner', 'date', 'learners', 'totalRevenue'];

export const paginationLabels = {
  nextPageLabel: i18nKeys.tables.pagination.nextPage,
  pageLabel: (pageNumber: number) => `${i18nKeys.tables.pagination.goToPage} ${pageNumber}`,
  previousPageLabel: i18nKeys.tables.pagination.prevPage,
};

export const collectionPreferencesProps = (t: TFunction<'translation', undefined>) => {
  return {
    pageSizePreference: {
      title: t(i18nKeys.campaigns.labels.preferences.title),
      options: [
        { value: 10, label: t(i18nKeys.general.preferences.resources10) },
        { value: 20, label: t(i18nKeys.general.preferences.resources20) },
      ],
    },
    visibleContentPreference: {
      title: t(i18nKeys.general.preferences.visibleContent.title),
      options: [
        {
          label: t(i18nKeys.general.preferences.visibleContent.optionLabel),
          options: columnDefinitions(t).map(({ id, header }) => ({ id, label: header, editable: id !== 'id' })),
        },
      ],
    },
    cancelLabel: t(i18nKeys.codeEditor.cancelLabel),
    confirmLabel: t(i18nKeys.codeEditor.confirmLabel),
    title: t(i18nKeys.general.preferences.title),
  };
};

export const filteringProperties = (t: TFunction<'translation', undefined>): PropertyFilterProperty[] => {
  return [
    {
      key: 'title',
      operators: [
        {
          operator: '=',
          match: (itemValue: unknown, tokenValue: string) => tokenValue.split(',').includes(itemValue as string),
        },
        {
          operator: '!=',
          match: (itemValue: unknown, tokenValue: string) => !tokenValue.split(',').includes(itemValue as string),
        },
      ],
      groupValuesLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.title),
      propertyLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.title),
    },
    {
      key: 'owner',
      groupValuesLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.owner),
      propertyLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.owner),
    },
    {
      key: 'date',
      groupValuesLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.date),
      propertyLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.date),
    },
    {
      key: 'learners',
      groupValuesLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.learners),
      propertyLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.event.learners),
    },
    {
      key: 'totalRevenue',
      groupValuesLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.totalRevenue),
      propertyLabel: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.totalRevenue),
    },
  ];
};
