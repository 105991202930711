import React, { useState } from 'react';
import { Box, Button, Form, FormField, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { JoinOrCreateTeamProps } from '@/src/types/JamJoinTeam';
import TeamListSelection from '../TeamListSelection/TeamListSelection';
import { JamTeamItem } from '@/src/types/JamTeam';
import { customEventTrigger } from '@/src/components/analytics/createEventTrigger';
import { JamEventTeamAssignmentType } from '@/src/types/JamCommon';

interface JoinExistingTeamFormProps extends Omit<JoinOrCreateTeamProps, 'createTeam'> {
  teams: JamTeamItem[];
  teamAssignmentType: JamEventTeamAssignmentType;
}

const JoinExistingTeamForm: React.FC<JoinExistingTeamFormProps> = ({ teams, joinTeam, saving, teamAssignmentType }) => {
  const { t } = useTranslation();
  const [selectedTeam, setSelectedTeam] = useState<JamTeamItem[]>();
  const [selectedTeamError, setSelectedTeamError] = useState('');

  const onSubmit = async () => {
    if (!selectedTeam?.length) {
      setSelectedTeamError(t(i18nKeys.joinTeam.joinExistingTeam.field.errors.teamError));
      return;
    }
    customEventTrigger('submit', 'Join Existing Team', window.location.href, 'Join Existing Team', {});
    await joinTeam(selectedTeam[0].team.teamName, null);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Grid gridDefinition={[{ colspan: 9 }]}>
        <Form>
          <SpaceBetween direction="vertical" size="l">
            <Box margin={{ top: 'l' }}>{t(i18nKeys.joinTeam.joinExistingTeam.description)}</Box>
            <FormField stretch errorText={selectedTeamError}>
              <TeamListSelection
                items={teams}
                cardsPerRow={[{ cards: 3 }]}
                onSelectionChange={({ detail }) => {
                  setSelectedTeam(detail.selectedItems);
                  setSelectedTeamError('');
                }}
                selectedItems={selectedTeam}
                teamAssignmentType={teamAssignmentType}
              />
            </FormField>

            <div className="divider" />
            <Box float="right">
              <Button loading={saving} variant="primary" onClick={() => void onSubmit()}>
                {t(i18nKeys.joinTeam.joinSpecificTeam.submitBtn)}
              </Button>
            </Box>
          </SpaceBetween>
        </Form>
      </Grid>
    </form>
  );
};

export default JoinExistingTeamForm;
