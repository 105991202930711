import { Box, Button, Container, Header } from "@amzn/awsui-components-react";
import React, { useEffect } from "react";
import "./JamCampaignRules.scss";
import { useTranslation } from "react-i18next";
import { i18nKeys } from "@/src/utils/i18n.utils";
import { CampaignAttemptResponse } from "@/src/types/Campaign";
import { useCampaigns } from "@/src/store/campaigns.context";
import { JamEventDetails } from "@/src/types/JamEventDetails";
import moment from "moment";
import converter from "number-to-words";
import { getErrorMessage } from "@/src/utils/errors.utils";
import { useJamChallenge } from "@/src/store/jam-challenge.context";
import { useFlashbars } from "@/src/store/flashbar.context";
import { isDateInThePast } from "@/src/utils/event-campaign-common.utils";

interface JamCampaignRulesProps {
  event?: JamEventDetails;
  campaignAttempt?: CampaignAttemptResponse;
  handleCloseRules: () => void;
}

const JamCampaignRules: React.FC<JamCampaignRulesProps> = ({ event, campaignAttempt, handleCloseRules}) => {
  const { t } = useTranslation();
  const { startCampaignAttempt, handleIsFetchingCampaign, getCampaignEventDetails, handleShowCampaignRules } = useCampaigns();
  const { loadJamChallengeData } = useJamChallenge();
  const { addErrorFlashbar } = useFlashbars();

  // Handle adding to the page and removing from the page
  const handleClick = async () => {
    // Start campaign attempt 1
    try {
      if(event?.eventName && !event.ended && (!campaignAttempt || campaignAttempt?.attemptNumber === 0)){
        handleIsFetchingCampaign(true);
        // Start a campaign attempt
        await startCampaignAttempt(event?.eventName, true);
        // Fetch the campaign attempt
        await getCampaignEventDetails(event?.eventName, true, true);
        // Fetch challenges
        await loadJamChallengeData({ id: event?.eventName, showLoader: true });
      } else {
        // Close rules page
        handleCloseRules();
      }
    } catch (err){
      addErrorFlashbar(getErrorMessage(err));
    } finally {
      handleIsFetchingCampaign(false);
    }
  }

  // Event end date and time
  const endDateTime = event?.eventEndDate;

  // Event challenge count
  const challengeCount = event?.challenges.length || 0;

  // Campaign attempt duration
  const sessionDurationLimitHours = campaignAttempt?.sessionDurationLimitHours || 0;

  // Challenge lab duration
  const labTimeoutHours = campaignAttempt?.labTimeoutHours || 0;

  // Number of allowed campaign attempts
  const allowedAttempts = campaignAttempt?.allowedAttempts || 0;

  // Event start date and time
  const eventStartDate = event?.eventStartDate || '';

  // Split dates into dates and times
  const endTime = moment(endDateTime).format("LT");
  const endDate = moment(endDateTime).format("LL");
  const startTime = moment(eventStartDate).format("LT");
  const startDate = moment(eventStartDate).format("LL");

  // Convert values to a word representation. I.E 1 becomes one.
  const parsedChallengeCount = converter.toWords(challengeCount);
  const parsedSessionDurationLimitHours = converter.toWords(sessionDurationLimitHours);
  const parsedLabTimeoutHours = converter.toWords(labTimeoutHours);
  const parsedAllowedAttempts = converter.toWords(allowedAttempts);

  // Determine if the event has started
  const eventStarted = isDateInThePast(eventStartDate);

  // Whenever this component unmounts we need 
  // to make sure that this value gets reset to defaul
  useEffect(() => {
    return () => {
      handleShowCampaignRules(false);
    }
  }, [])
 
  return (
    <>
      {
      campaignAttempt ? (
      <div className="rules-component">
          <div className="rules-image-container">
              <img src="/assets/WavingHandMonkey.png" className="rules-image"/>
          </div> 
          <Container
            className="rules-container"
          >
            <Header
              variant="h1"
              actions = {
                eventStarted ? (
                  <Button 
                    variant="icon"
                    iconName="close"
                    onClick={() => void handleClick()}
                    data-testid="campaignRulesCloseButton"
                  />
                ) : null
              }
            >
              <p className="rules-header">{t(i18nKeys.campaigns.rules.title)}</p>
            </Header>
              <div className="rule">
                <Box margin={{ top: "s", bottom: "l"}}>
                  <p className = "rules-description">{t(i18nKeys.campaigns.rules.description)}</p>
                </Box>
              </div>
              <div className="rule">
                <Box>
                  <p className="rules-sub-header">{t(i18nKeys.campaigns.rules.deadline.title)}</p>
                </Box>
                <Box margin={{ top: "xs", bottom: "l" }}>
                  <p className = "rules-description">{t(i18nKeys.campaigns.rules.deadline.description, {date: endDate, time: endTime})}</p>
                </Box>
                </div>
              <div className="rule">
                <Box className="rules-sub-header">
                  <p className="rules-sub-header">{t(i18nKeys.campaigns.rules.challenges.title)}</p>
                </Box>
                <Box margin={{ top: "xs", bottom: "l" }}>
                  <p className = "rules-description">
                    {
                      t(
                        challengeCount > 1 ? i18nKeys.campaigns.rules.challenges.description_plural : i18nKeys.campaigns.rules.challenges.description_singular, 
                        {challengeCount: parsedChallengeCount}
                      )
                    }
                  </p>
                </Box>
                </div>
              <div className="rule">
                <Box className="rules-sub-header">
                  <p className="rules-sub-header">{t(i18nKeys.campaigns.rules.timeConstraints.title)}</p>
                  </Box>
                <Box margin={{ top: "xs", bottom: "l" }}>
                  <p className = "rules-description">
  
                    {
                      t(
                        sessionDurationLimitHours > 1 ? i18nKeys.campaigns.rules.timeConstraints.descriptionHours_plural : i18nKeys.campaigns.rules.timeConstraints.descriptionHours_singular, 
                        {
                          hours: parsedSessionDurationLimitHours,
                        }
                      ) 
                      + " " +
                      t(
                        challengeCount > 1 ? i18nKeys.campaigns.rules.timeConstraints.descriptionChallengeCount_plural : i18nKeys.campaigns.rules.timeConstraints.descriptionChallengeCount_singular, 
                        {
                          challengeCount: parsedChallengeCount
                        }
                      ) 
                      + " " +
                      t(
                        labTimeoutHours > 1 ? i18nKeys.campaigns.rules.timeConstraints.descriptionLabDuration_plural : i18nKeys.campaigns.rules.timeConstraints.descriptionLabDuration_singular, 
                        {
                          labDuration: parsedLabTimeoutHours
                        }
                      )
                    }
                  </p>
                </Box>
              </div>
              <div className="rule">
                <Box className="rules-sub-header">
                  <p className="rules-sub-header">{t(i18nKeys.campaigns.rules.numberOfAttempts.title)}</p>
                  </Box>
                <Box margin={{ top: "xs", bottom: "l" }}>
                <p className = "rules-description">
                  {
                      t(
                        allowedAttempts > 1 ? i18nKeys.campaigns.rules.numberOfAttempts.descriptionAllowedAttempts_plural : i18nKeys.campaigns.rules.numberOfAttempts.descriptionAllowedAttempts_singular, 
                        {
                          allowedAttempts: parsedAllowedAttempts
                        }
                      )
                      + " " +
                      t(
                        sessionDurationLimitHours > 1 ? i18nKeys.campaigns.rules.numberOfAttempts.descriptionAttemptDuration_plural : i18nKeys.campaigns.rules.numberOfAttempts.descriptionAttemptDuration_singular, 
                        {
                          attemptDuration: parsedSessionDurationLimitHours
                        }
                      )
                    }
                </p>
                </Box>
              </div>
              <div className="rule">
                <Box className="rules-sub-header">
                  <p className="rules-sub-header">{t(i18nKeys.campaigns.rules.readyToStart.title)}</p>
                  </Box>
                <Box margin={{ top: "xs", bottom: "xxl" }}>
                <p className = "rules-description">{t(i18nKeys.campaigns.rules.readyToStart.description)}</p>
                </Box>
              </div>
              <div className="rules-footer">
                {
                  !eventStarted ? (
                    <p className = "rules-campaign-not-started">
                      {t(i18nKeys.campaigns.rules.notStarted.description, {date: startDate, time: startTime})}
                    </p>
                  ) : (
                      <Button 
                        onClick={() => void handleClick()} 
                        variant="primary" 
                        data-testid="campaignRulesBeginButton"
                      >
                          {t(i18nKeys.campaigns.rules.begin)}
                      </Button>
                  )
                }
              </div>
        </Container>
      </div>
      ) : null
    }
    </>
  );
};

export default JamCampaignRules;

