import { useTranslation } from 'react-i18next';
import { Modal, SpaceBetween, FormField, Button, Multiselect, Link } from '@amzn/awsui-components-react';
import { useUser } from '../../store/user.context';
import './RoleSpoofingModal.scss';
import { useState } from 'react';
import { getSpoofedGroups, SPOOFED_GROUP_KEY } from '../../utils/spoofed-groups';
import { AuthRole } from '../../constants/shared/auth-roles';
import { useComponentDidMountEffect } from '../../hooks/useComponentDidMountEffect';
import { IdentityProvider, IdpToProviderName } from '../../types/IdentityProvider';
import { i18nKeys } from '../../utils/i18n.utils';
import * as React from 'react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

export const USERNAME_PASSWORD_IDP_NAME = 'username-password';

export interface RoleSpoofingModalProps {
  handleCloseModal: () => void;
}

/**
 * Get the corresponding name as string for identity providers, if not idp, returns username and password
 *
 * @param idp
 */
export const getSpoofedIdentityProviderGroupName = (idp: IdentityProvider | null | undefined): string => {
  if (idp) {
    return IdpToProviderName[idp];
  }
  return USERNAME_PASSWORD_IDP_NAME;
};

export const RoleSpoofingModal: React.FC<RoleSpoofingModalProps> = (props) => {
  const { t } = useTranslation();
  const { user, setUser, isLoggedIn } = useUser();

  const [selectedGroups, setSelectedGroups] = useState<string[] | null>();
  const [idp, setIdp] = useState<string | null>(getSpoofedIdentityProviderGroupName(user?.provider));
  const [authGroups, setAuthGroups] = useState<Map<AuthRole, boolean> | null>();

  const currentSpoofedGroups: string[] = getSpoofedGroups();
  const allAuthGroupValues: AuthRole[] = Object.values(AuthRole);

  // variable used to set the authGroup map state
  let authGroupMap: Map<AuthRole, boolean> = new Map();

  // eslint-disable-next-line @typescript-eslint/require-await
  useComponentDidMountEffect(async () => {
    if (user && isLoggedIn) {
      authGroupMap = new Map<AuthRole, boolean>();
      allAuthGroupValues.forEach((group: AuthRole) => {
        authGroupMap.set(group, user.memberOfGroup(group));
      });
      setAuthGroups(authGroupMap);
      setIdp(getSpoofedIdentityProviderGroupName(user.idp));
    }
  });

  /**
   * change the selected groups to contain the current idp and all auth groups
   */
  const applyAllAuthGroups = () => {
    authGroupMap = new Map<AuthRole, boolean>();
    allAuthGroupValues.forEach((group: AuthRole) => {
      authGroupMap.set(group, true);
    });
    setAuthGroups(authGroupMap);
    setSelectedGroups([idp || '', ...allAuthGroupValues]);
  };

  /**
   * change the selected groups to contain the current idp and no auth groups
   */
  const applyNoneAuthGroups = () => {
    authGroupMap = new Map<AuthRole, boolean>();
    allAuthGroupValues.forEach((group: AuthRole) => {
      authGroupMap.set(group, false);
    });
    setAuthGroups(authGroupMap);
    setSelectedGroups([idp || '']);
  };

  const handleDismissModal = () => {
    props.handleCloseModal();
  };

  /**
   * set the selected groups to local storage
   */
  const applySpoofedRoles = () => {
    localStorage.setItem(SPOOFED_GROUP_KEY, JSON.stringify(selectedGroups));
    refreshUser();
    handleDismissModal();
  };

  /**
   * delete local storage
   */
  const removeSpoofedRoles = () => {
    localStorage.removeItem(SPOOFED_GROUP_KEY);
    refreshUser();
    handleDismissModal();
  };

  /**
   * Force refresh of user to cause any consumers of user context to re-render
   */
  const refreshUser = () => {
    if (user != null && setUser != null) {
      // Need to create a clone object. Doing setUser(user) does not cause consumers to re-render.
      const userClone = user.clone();
      setUser(userClone);
    }
  };

  const getAuthGroupSelectionOptions = (selectedOnly: boolean): OptionDefinition[] => {
    const options: OptionDefinition[] = [];
    allAuthGroupValues.forEach((group) => {
      if (selectedOnly && authGroups?.get(group) === true) {
        options.push({ label: group, value: group });
      } else if (!selectedOnly) {
        options.push({ label: group, value: group });
      }
    });
    return options;
  };

  const allAuthGroupOptions = getAuthGroupSelectionOptions(false);
  const selectedAuthGroupOptions = getAuthGroupSelectionOptions(true);

  /**
   * set the selected groups to contain selected auth groups and the existing idp value
   *
   * @param idpValue
   */
  const handleAuthGroupChange = (selectedOptions: OptionDefinition[]) => {
    if (selectedOptions) {
      const selectedAuthGroups = [...selectedOptions.map((option) => option.label || '')];
      setSelectedGroups([...selectedAuthGroups, idp || '']);
      authGroupMap = new Map<AuthRole, boolean>();
      allAuthGroupValues.forEach((group: AuthRole) => {
        authGroupMap.set(group, selectedAuthGroups.includes(group));
      });
      setAuthGroups(authGroupMap);
    }
  };

  return (
    <Modal
      header={t(i18nKeys.roleSpoofing.header)}
      visible
      onDismiss={() => handleDismissModal()}
      closeAriaLabel={t(i18nKeys.general.close)}
      footer={
        <SpaceBetween size="xs" direction="horizontal" className="role-spoofing-buttons">
          {currentSpoofedGroups.length > 0 && (
            <Button variant="normal" onClick={removeSpoofedRoles}>
              {t(i18nKeys.roleSpoofing.delete)}
            </Button>
          )}
          <Button variant="link" onClick={() => handleDismissModal()}>
            {t(i18nKeys.general.cancel)}
          </Button>
          <Button variant="primary" onClick={applySpoofedRoles}>
            {t(i18nKeys.roleSpoofing.apply)}
          </Button>
        </SpaceBetween>
      }>
      <SpaceBetween direction="vertical" size="m">
        <FormField label={t(i18nKeys.roleSpoofing.authGroups.label)}>
          <SpaceBetween size="s" direction="vertical">
            <SpaceBetween direction="horizontal" size="s">
              <Link onFollow={() => applyAllAuthGroups()}>{t(i18nKeys.roleSpoofing.authGroups.selectAll)}</Link>
              <Link onFollow={() => applyNoneAuthGroups()}>{t(i18nKeys.roleSpoofing.authGroups.selectNone)}</Link>
            </SpaceBetween>
            <Multiselect
              selectedOptions={selectedAuthGroupOptions}
              selectedAriaLabel={t(i18nKeys.roleSpoofing.authGroups.selected)}
              options={allAuthGroupOptions}
              placeholder={t(i18nKeys.roleSpoofing.authGroups.placeholder)}
              onChange={({ detail }) => {
                handleAuthGroupChange([...detail.selectedOptions]);
              }}
              keepOpen={false}
            />
          </SpaceBetween>
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};
