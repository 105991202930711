import { Box, Container, Icon, SpaceBetween, Link as ALink } from '@amzn/awsui-components-react';
import React from 'react';
import { Link } from 'react-router-dom';
import './PublicEventCard.scss';

interface IProps {
  date: string;
  startDate: string;
  endDate: string;
  title: string;
  description: string;
  location: string;
  moreInfoURL: string;
  imageFilename: string;
  eventId: string;
}

const PublicEventCard: React.FC<IProps> = ({ date, title, description, eventId }) => {

  return (
    // as per the JAM-10304 (Remove /jam from public demo page - https://jam.aws.com/v2/jam/public-demo)
    <Link className="public-event-card__container" to={`/${eventId}`}>
      <div className="public-events-card__container">
        <Container
          disableContentPaddings
          media={{
            content: (
              <div className="public-event-card__image">
                <img src={'/assets/public-event-background.png'} alt="placeholder" />
              </div>
            ),
            height: 20,
          }}>
          <div className="public-event-card__content">
            <SpaceBetween direction="vertical" size="xs">
              <Link className="public-event-card__container" to={`/${eventId}`}>
                <Box variant="h4" margin={{ left: 'm', top: 'xs' }}>
                  {title}
                </Box>
              </Link>
              <div style={{ minHeight: '150px' }}>
                <Box variant="p" margin={{ left: 'm', bottom: 'xl' }}>
                  {description?.length < 100 ? (
                    description
                  ) : (
                    <p>
                      {description?.slice(0, 200)}
                      {description && <ALink href={`/${eventId}`}>...read more</ALink>}
                    </p>
                  )}
                </Box>
              </div>
              <Box margin={{ left: 'm', bottom: 's' }} data-classname="more-info" fontSize="body-s">
                <SpaceBetween data-classname="more-info" direction="horizontal" size="xs">
                  <Icon name="status-pending" />
                  <div className="dates">Available from {date}</div>
                </SpaceBetween>
              </Box>
            </SpaceBetween>
          </div>
        </Container>
      </div>
    </Link>
  );
};

export default PublicEventCard;
