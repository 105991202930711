import React, { useEffect, useState } from 'react';
import { Box, Table } from '@amzn/awsui-components-react';
import './JamReport.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useJamReport } from '@/src/store/jam-report.context';
import { NotableInsight } from '@/src/types/JamReport';
import JamContainer from '@/src/components/ui/molecules/JamContainer/JamContainer';

const NotableMoment = () => {
  const { reportApiData } = useJamReport();
  const [tableData, setTableData] = useState<NotableInsight[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    if (reportApiData) {
      const { notableInsights } = reportApiData;
      if (notableInsights && notableInsights?.length > 0) {
        setTableData(notableInsights);
      }
    }
  }, []);

  return (
    <JamContainer>
      <Box>
        <h2>{t(i18nKeys.eventReport.notableMoment.heading)}</h2>
      </Box>
      <Table
        contentDensity="compact"
        columnDefinitions={[
          {
            header: t(i18nKeys.eventReport.notableMoment.heading),
            cell: (item) => item?.content || '',
            minWidth: 200,
          },
        ]}
        items={tableData || []}
        loadingText="Loading resources"
        sortingDisabled
        variant="embedded"
      />
    </JamContainer>
  );
};

export default NotableMoment;
