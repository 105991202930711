import React, { useEffect } from 'react';
import { REDIRECT_AFTER_PROFILE_CREATE } from '@/src/constants/common';
import { JamProfile } from '@/src/types/JamProfile';
import { useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '@/src/RoutePath';
import JamSpinner from '@/src/components/common/JamSpinner';
import { useUser } from '@/src/store/user.context';
import SessionStorage from '@/src/utils/session-storage';

export const isProfileEmpty = (profile?: JamProfile) => {
  if (!profile) return true;
  const { country, nickname, email } = profile;
  if (!country || !nickname || !email) {
    // country not added or nickname not added take user to jam profile detail page
    return true;
  }
  return false;
};

/**
 * Component to redirect the user to the JAM profile if his/her profile is incomplete
 * or hide it and show a terms and conditions modal otherwise.
 */
export const JamProfileCheckGuard: React.FC = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { profile, loading, user } = useUser();
  const noProfileRedirectPaths: string[] = [RoutePath.OAUTH2, RoutePath.USER_PROFILE_CREATE]

  useEffect(() => {
    if (!user || loading) {
      return;
    }

    // Do not immediately redirect when the Oauth page loads - let Oauth do its own redirect first.
    // Also do not redirect to the profile create page when already on the profile create page.
    if (isProfileEmpty(profile) && !noProfileRedirectPaths.includes(location.pathname)) {
      SessionStorage.set(REDIRECT_AFTER_PROFILE_CREATE, location);
      history.replace(RoutePath.USER_PROFILE_CREATE);
    }
  }, [history, user, profile, loading, location.pathname]);

  if (loading) return <JamSpinner />;

  return <React.Fragment>{props.children}</React.Fragment>;
};
