import { Grid, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';
import { CHALLENGE_DETAILS_ROUTES } from '../../routes';
import { ChallengeWrapper } from '../../types/Challenge';
import { i18nKeys } from '../../utils/i18n.utils';
import { getDuration } from '../../utils/time.utils';
import ChallengeStatusIndicator from '../challenges/challengesCommon/ChallengeStatusIndictator';
import DifficultyIndicator from '../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { KeyValue } from '../common/KeyValue';
import { LoadingBar } from '../common/LoadingBar';
import { Markdown } from '../common/Markdown';
import StarRating from '../common/StarRating';
import { useUser } from '@/src/store/user.context';

interface ChallengeDetailsProps {
  fullChallenge: ChallengeWrapper;
}

export const ChallengeDetails: React.FC<ChallengeDetailsProps> = ({ fullChallenge }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  if (!fullChallenge) {
    return (
      <div style={{ marginTop: '32px' }}>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <SpaceBetween direction="vertical" size="s">
          <StarRating
            value={fullChallenge.globalStatistics.rating}
            valueCount={fullChallenge.globalStatistics.ratingCount}
            showAverage
            inlineAverage
          />
          <div style={{ marginBottom: '12px' }}>
            {fullChallenge.latestApproved && <DifficultyIndicator challenge={fullChallenge?.latestApproved} />}
          </div>
          <KeyValue
            label={
              <strong className="default-text">{t(i18nKeys.challenges.challengeDetails.titles.description)}</strong>
            }>
            {fullChallenge?.latestApproved?.props.description && (
              <Markdown className="default-text" content={fullChallenge?.latestApproved?.props.description} />
            )}
          </KeyValue>
          <Grid
            gridDefinition={[
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 4 },
              { colspan: 8 },
              { colspan: 4 },
              { colspan: 8 },
            ]}>
            <div>
              <strong>{t(i18nKeys.challenges.browseChallenges.headers.type)}</strong>
            </div>
            <div>{fullChallenge.latestApproved?.props.jamType}</div>
            <div>
              <strong>{t(i18nKeys.challenges.browseChallenges.headers.category)}</strong>
            </div>
            <div>{fullChallenge.latestApproved?.props.category || t(i18nKeys.general.nA)}</div>
            <div>
              <strong>{t(i18nKeys.challenges.browseChallenges.headers.openIssues)}</strong>
            </div>
            <div>{fullChallenge?.globalStatistics?.unresolvedChallengeIssues}</div>
            <div>
              <strong>{t(i18nKeys.challenges.browseChallenges.headers.avgSolveTime)}</strong>
            </div>
            <div>
              {getDuration(fullChallenge?.globalStatistics?.solveTimes?.trimmedAvgSeconds) || t(i18nKeys.general.nA)}
            </div>
            <div>
              <strong>{t(i18nKeys.challenges.browseChallenges.headers.jamsUsed)}</strong>
            </div>
            <div>{fullChallenge?.globalStatistics.jamsUsed}</div>
            <div>
              <strong>{t(i18nKeys.challenges.browseChallenges.headers.lastUsed)}</strong>
            </div>
            <div>
              {(fullChallenge?.globalStatistics?.lastUsed && (
                <TimeAgo datetime={fullChallenge?.globalStatistics?.lastUsed} />
              )) ||
                t(i18nKeys.general.nA)}
            </div>
            <div>
              <strong>{t(i18nKeys.challenges.browseChallenges.headers.awsServices)}</strong>
            </div>
            <div>
              {fullChallenge?.latestApproved?.props.awsServices.map((awsService, index) => {
                if (index + 1 !== fullChallenge.latestApproved?.props.awsServices.length) {
                  return <span key={`awsService-${index}`}>{`${awsService}, `}</span>;
                } else {
                  return <span key={`awsService-${index}`}>{awsService}</span>;
                }
              })}
            </div>
            <div>
              <strong>{t(i18nKeys.challenges.challengeDetails.titles.status)}</strong>
            </div>
            <div>
              {fullChallenge.latestApproved && (
                <ChallengeStatusIndicator challenge={fullChallenge.latestApproved} showVersion={false} subtitle="" />
              )}
            </div>
            <div>
              <strong>{t(i18nKeys.challenges.browseChallenges.headers.tags)}</strong>
            </div>
            {fullChallenge?.latestApproved && (
              <div>
                {fullChallenge?.latestApproved?.props.tags.length > 0 &&
                  fullChallenge.latestApproved?.props.tags.map((tag, index) => {
                    if (index + 1 !== fullChallenge.latestApproved?.props.tags.length) {
                      return <span key={`tag-${index}`}>{`${tag}, `}</span>;
                    } else {
                      return <span key={`tag-${index}`}>{tag}</span>;
                    }
                  })}
                {fullChallenge?.latestApproved?.props.tags.length < 1 && t(i18nKeys.general.nA)}
              </div>
            )}
          </Grid>
          {!user?.isOnlyBasicUser && (
            <Link external href={CHALLENGE_DETAILS_ROUTES.Summary.resolve(fullChallenge.challengeId ?? '')}>
              {t(i18nKeys.challenges.challengeDetails.buttons.goToChallengeDetails)}
            </Link>
          )}
        </SpaceBetween>
      </React.Fragment>
    );
  }
};

export default ChallengeDetails;
