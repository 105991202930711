import React from 'react';
import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { i18nKeys } from '@/src/utils/i18n.utils';

// components
import { TruncatedText } from '../TruncatedText';

// types
import type { ChallengeListItem } from '@/src/types/Challenge';
import type { EventTemplateRationale } from '@/src/types/EventTemplate';

export interface IChallengeRationaleReviewProps {
  challenges: ChallengeListItem[];
  rationale?: EventTemplateRationale;
}

const { rationale: rationaleText } = i18nKeys.eventTemplates.step5.steps.step4;

export const ChallengeRationaleReview: React.FC<IChallengeRationaleReviewProps> = ({ challenges, rationale }) => {
  const { t } = useTranslation();

  return (
    <Container header={<Header variant="h2">{t(rationaleText.title)}</Header>}>
      <ColumnLayout columns={1} variant="text-grid">
        {challenges.map((challenge: ChallengeListItem, index) => (
          <SpaceBetween key={index} size="l">
            <div>
              <Box variant="h4">{challenge.props.title}</Box>
              <Box margin={{ top: 'l' }}>
                <Box variant="awsui-key-label">{t(rationaleText.text)}</Box>
                <TruncatedText text={rationale?.[challenge?.id ?? ''] || '-'} maxLength={300} />
              </Box>
            </div>
          </SpaceBetween>
        ))}
      </ColumnLayout>
    </Container>
  );
};
