import { ExpandableSection, Grid, Header, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Campaign } from '../../../../types/Campaign';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';

interface AuditProps {
  target: Event | Campaign | undefined;
}

const Audit: React.FC<AuditProps> = ({ target }) => {
  const isEvent = target instanceof Event;
  const { t } = useTranslation();
  const lastUpdatedDate = target?.lastUpdatedDate
    ? new Date(target?.lastUpdatedDate).toLocaleDateString('en', {
        day: 'numeric',
        month: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        year: '2-digit',
      })
    : null;

  let requestStatus: 'Denied' | 'Approved' | 'Cancelled' | null = null;
  if (target?.denied) {
    requestStatus = t(i18nKeys.general.denied);
  } else if (target?.approved) {
    requestStatus = t(i18nKeys.general.approved);
  } else if ((isEvent && !target?.cancelledBy && target?.cancelled) || (!isEvent && target?.cancelled)) {
    requestStatus = t(i18nKeys.general.cancelled);
  } else if (isEvent && target?.cancelledBy && target?.cancelled) {
    requestStatus = t(i18nKeys.general.approved);
  }

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.audit)}</Header>}>
      {target?.lastUpdatedDate && (
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.lastModified)}</div>
            <div>{lastUpdatedDate}</div>
          </Grid>
        </div>
      )}
      {((isEvent && !target?.isCampaignEvent) || (!isEvent && target?.lastUpdatedBy)) && (
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.lastModifiedBy)}</div>
            <div>{target?.lastUpdatedBy || '--'}</div>
          </Grid>
        </div>
      )}
      {((isEvent && !target?.isCampaignEvent) || (!isEvent && target?.requestedBy)) && (
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.createdBy)}</div>
            <div>{target?.requestedBy || '--'}</div>
          </Grid>
        </div>
      )}
      {target?.requestResolved && ((isEvent && !target?.isAutoGenerated) || !isEvent) && requestStatus && (
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">
              {t(
                isEvent
                  ? i18nKeys.events.eventDetails.labels.eventRequestStatus
                  : i18nKeys.campaigns.labels.campaignDetails.campaignRequestStatus,
                { status: requestStatus }
              )}
            </div>
            <div>{target?.requestResolvedBy || '--'}</div>
          </Grid>
        </div>
      )}
      {isEvent && target?.isCampaignEvent && (
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.campaign)}</div>
            <Link external href={`/campaigns/${target?.campaignId}/groups/${target?.campaignGroupId}`}>
              {t(i18nKeys.events.eventDetails.labels.openInNewTab)}
            </Link>
          </Grid>
        </div>
      )}
      {isEvent && target?.cancellationTime && (
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.eventCancelledBy)}</div>
            <div>{target?.cancelledBy || '--'}</div>
          </Grid>
        </div>
      )}
      {target?.changeRequest && (
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.changeRequestedBy)}</div>
            <div>{target?.changeRequest.requestedBy || '--'}</div>
          </Grid>
        </div>
      )}
      {target?.changeRequest && (
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.changeRequestResolvedBy)}</div>
            <div>{target?.changeRequest?.requestResolvedBy || '--'}</div>
          </Grid>
        </div>
      )}
    </ExpandableSection>
  );
};
export default Audit;
