import * as React from 'react';

import { HelpPanel, Tabs, TutorialPanel } from '@amzn/awsui-components-react';
import { preProdLogger } from '../../utils/log.utils';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import { ChallengeTutorialTabs, useCreateChallenge } from '../../store/create-challenge.context';
import { TFunctionProvider } from '../common/TFunctionProvider';
import { challengeAuthoringGuideUrl } from '../../utils/jam-urls';
import { footer, helpPanelContent } from '../tutorials/HelpPanelContent';

export const ChallengeToolSet: React.FC = () => {
  const { t } = useTranslation();
  const { tutorials, helpPanelTopic } = useCreateChallenge();
  const { toolsTab, setToolsTab } = useCreateChallenge();
  const fieldPrefix = i18nKeys.tutorial.createChallenge;
  const helpPanels = helpPanelContent();

  return (
    <Tabs
      activeTabId={toolsTab}
      onChange={(event) => {
        preProdLogger('Changing tabs', event.detail.activeTabId);
        setToolsTab(event.detail.activeTabId as ChallengeTutorialTabs);
      }}
      tabs={[
        {
          id: ChallengeTutorialTabs.INFO,
          label: <TFunctionProvider>{(tr) => tr(i18nKeys.general.info)}</TFunctionProvider>,
          content: helpPanels.has(helpPanelTopic) ? (
            helpPanels.get(helpPanelTopic)
          ) : (
            <div>
              <HelpPanel footer={footer} header={<h2>AWS Jam</h2>}>
                <p>
                  <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.default.content)}</TFunctionProvider>
                </p>
              </HelpPanel>
            </div>
          ),
        },
        {
          id: ChallengeTutorialTabs.TUTORIAL,
          label: <TFunctionProvider>{(tr) => tr(fieldPrefix.tabtitle)}</TFunctionProvider>,
          content: (
            <TutorialPanel
              tutorials={tutorials}
              downloadUrl={challengeAuthoringGuideUrl}
              i18nStrings={{
                labelsTaskStatus: {
                  pending: '',
                  'in-progress': '',
                  success: '',
                },
                loadingText: t(i18nKeys.general.loading),
                tutorialListTitle: t(i18nKeys.tutorial.title),
                tutorialListDescription: t(i18nKeys.tutorial.description),
                tutorialListDownloadLinkText: t(i18nKeys.tutorial.download),
                tutorialCompletedText: t(i18nKeys.tutorial.completed),
                labelExitTutorial: t(i18nKeys.tutorial.dismiss),
                learnMoreLinkText: t(i18nKeys.tutorial.learnMore),
                startTutorialButtonText: t(i18nKeys.tutorial.start),
                restartTutorialButtonText: t(i18nKeys.tutorial.restart),
                completionScreenTitle: t(i18nKeys.tutorial.completionText),
                feedbackLinkText: t(i18nKeys.tutorial.feedback),
                dismissTutorialButtonText: t(i18nKeys.tutorial.dismiss),
                taskTitle: (taskIndex, taskTitle) =>
                  `${t(i18nKeys.general.taskCounter, { count: taskIndex + 1 })}: ${taskTitle}`,
                stepTitle: (stepIndex, stepTitle) =>
                  `${t(i18nKeys.general.stepCounter, { count: stepIndex + 1 })}: ${stepTitle}`,
                labelTotalSteps: (totalStepCount) => t(i18nKeys.general.totalSteps, { count: totalStepCount }),
                labelLearnMoreExternalIcon: t(i18nKeys.tutorial.learnMoreIconLabel),
              }}
              onFeedbackClick={() => preProdLogger('feedback') /* TODO: ADD FUNCTIONALITY FOR FEEDBACK CLICK */}
            />
          ),
        },
      ]}
      variant={'container'}
    />
  );
};
