import React from 'react';
import {Box, FormField} from '@amzn/awsui-components-react';
import FeedbackStars from '../FeedbackStars/FeedbackStars';

interface RatingContainerProps {
  label: string;
  name: string;
  rating: number;
  setRating: (name: string, value: number) => void;
  constraintText?: string;
}

const RatingContainer: React.FC<RatingContainerProps> = ({ label, name, rating, setRating, constraintText }) => (
  <Box variant="div">
    <FormField label={label} constraintText={constraintText}>
      <FeedbackStars name={name} rating={rating} setRating={setRating} />
    </FormField>
  </Box>
);

export default RatingContainer;
