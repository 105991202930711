import { CreateTeamFields } from '../types/CreateTeam';
import { ErrorSetterByField, InputValidationHelper } from '../types/InputValidator';
import { JamEventTeamGoal } from '../types/JamCommon';
import { validateField, validateIsEmpty, validateMatchField, validateSection } from './validation.utils';

interface Params {
  teamName: string;
  password: string;
  confirmPassword: string;
  teamGoal?: JamEventTeamGoal | string;
  privacySettings: string;
}

export const createTeamValidator: (
  fields: Params,
  errors: Params,
  errorSetterByField: ErrorSetterByField<CreateTeamFields>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: CreateTeamFields, setError?: boolean) => boolean;
} = (fields, errors, errorSetterByField: Map<CreateTeamFields, (error: string) => void>) => {
  const validatorHelperByField = new Map<CreateTeamFields, InputValidationHelper>([
    [CreateTeamFields.TEAM_NAME, validateIsEmpty(fields.teamName, errors.teamName)],
    [CreateTeamFields.TEAM_GOAL, validateIsEmpty(fields.teamGoal, errors.teamGoal as string)],
    [CreateTeamFields.PRIVACY_SETTINGS, validateIsEmpty(fields.privacySettings, errors.privacySettings)],
    [CreateTeamFields.PASSWORD, validateIsEmpty(fields.password, errors.password)],
    [
      CreateTeamFields.CONFIRM_PASSWORD,
      validateMatchField(fields.confirmPassword, fields.password, errors.confirmPassword),
    ],
  ]);
  const errorSetterByFieldFiltered = new Map(errorSetterByField);
  if (fields.privacySettings === 'private') {
    errorSetterByFieldFiltered.set(
      CreateTeamFields.PASSWORD,
      errorSetterByField.get(CreateTeamFields.PASSWORD) as (error: string) => void
    );
    errorSetterByFieldFiltered.set(
      CreateTeamFields.CONFIRM_PASSWORD,
      errorSetterByField.get(CreateTeamFields.CONFIRM_PASSWORD) as (error: string) => void
    );
  } else {
    errorSetterByFieldFiltered.delete(CreateTeamFields.PASSWORD);
    errorSetterByFieldFiltered.delete(CreateTeamFields.CONFIRM_PASSWORD);
  }

  const isValidField = (field: CreateTeamFields, setError = true) => {
    return validateField<CreateTeamFields>(validatorHelperByField, errorSetterByFieldFiltered, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection<CreateTeamFields>(validatorHelperByField, errorSetterByFieldFiltered, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};
