import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, Modal } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { SKILL_BUILDER_LEARN_URL } from '@/src/constants/common';

interface JoinEventErrorModalProps {
  visible: boolean;
  handleClose: () => void;
}

const JoinEventErrorModal: React.FC<JoinEventErrorModalProps> = ({ visible, handleClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onDismiss={handleClose}
      closeAriaLabel={t(i18nKeys.general.close)}
      visible={visible}
      footer={
        <Box float="right">
          <Button variant="primary" onClick={handleClose}>
            {t(i18nKeys.general.ok)}
          </Button>
        </Box>
      }
      header={t(i18nKeys.myJams.joinAnEvent.errorModal.noAccessToJoin)}>
      {t(i18nKeys.myJams.joinAnEvent.errorModal.eventRequire)}
      <Link external href={SKILL_BUILDER_LEARN_URL}>
        {t(i18nKeys.myJams.joinAnEvent.errorModal.action)}
      </Link>
    </Modal>
  );
};

export default JoinEventErrorModal;
