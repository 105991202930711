import React from 'react';
import { ContentLayout, SpaceBetween, Header, Grid, ColumnLayout } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import './index.scss';
import ClickableProfileOption from './ClickableProfileOption';
import { JAM_PROFILE_LINKS } from './jam-profile-links.config';

const JamProfile: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">{t(i18nKeys.JamProfile.title)}</Header>
        </SpaceBetween>
      }>
      <Grid>
        <ColumnLayout columns={2}>
          {JAM_PROFILE_LINKS.map((option) => (
            <div key={option.title} className="option">
              <ClickableProfileOption title={option.title} description={option.description} link={option.link} />
            </div>
          ))}
        </ColumnLayout>
      </Grid>
    </ContentLayout>
  );
};

export default JamProfile;
