import { TFunction } from 'i18next';
import { EventBase } from '../types/Event';
import {
  CreateLabShutoffActionRequest,
  CreateLabShutoffActionResponse,
  GetLabShutoffAuditTrailResponse,
  GetLabShutoffStatusRequest,
  GetLabShutoffStatusResponse,
  LabShutoffCandidate,
} from '../types/LabShutoff';
import { fromPlainObject } from '../utils/mapper.utils';
import { safeString } from '../utils/string.utils';
import { ApiClient } from './ApiClient';
import { i18nKeys } from '../utils/i18n.utils';
import { DateRangeFilter } from '../types/common';
import { QueryParams } from './types';

export class LabShutOffAPI {
  private static ROOT_PATH = '/admin/labshutoff';

  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * Shut off lab deployment for a candidate.
   */
  public async shutoffCandidate(
    reason: string,
    candidate: LabShutoffCandidate,
    silent = false
  ): Promise<CreateLabShutoffActionResponse> {
    const request: CreateLabShutoffActionRequest = {
      candidateString: safeString(candidate.toCandidateString()),
      reason,
    };

    return this.apiClient.post({
      path: `${LabShutOffAPI.ROOT_PATH}/shutoff`,
      body: request,
      successMessage: this.t(i18nKeys.eventLabs.messages.successCreateShutoff),
      failMessage: this.t(i18nKeys.eventLabs.messages.failedCreateShutoff),
      silent,
      responseMapper: (object) => fromPlainObject(object, CreateLabShutoffActionResponse),
    }) as Promise<CreateLabShutoffActionResponse>;
  }

  /**
   * Turn lab deployment back on for a candidate.
   */
  async turnOnCandidate(candidate: LabShutoffCandidate, silent = false): Promise<CreateLabShutoffActionResponse> {
    const request: CreateLabShutoffActionRequest = {
      candidateString: candidate.toCandidateString() || '',
    };

    return this.apiClient.post({
      path: `${LabShutOffAPI.ROOT_PATH}/turnon`,
      body: request,
      successMessage: this.t(i18nKeys.eventLabs.messages.successCreateTurnon),
      failMessage: this.t(i18nKeys.eventLabs.messages.failedCreateTurnon),
      silent,
      responseMapper: (object) => fromPlainObject(object, CreateLabShutoffActionResponse),
    }) as Promise<CreateLabShutoffActionResponse>;
  }

  /**
   * Get the shutoff statuses of one or more provided candidates.
   */
  public async getShutoffStatuses(
    candidates: LabShutoffCandidate[],
    silent = false
  ): Promise<GetLabShutoffStatusResponse> {
    const request: GetLabShutoffStatusRequest = {
      candidateStrings: candidates.map((candidate) => safeString(candidate.toCandidateString())),
    };

    return this.apiClient.post({
      path: `${LabShutOffAPI.ROOT_PATH}/status`,
      body: request,
      failMessage: this.t(i18nKeys.eventLabs.messages.failedShutoffStatuses),
      silent,
      responseMapper: (object) => fromPlainObject(object, GetLabShutoffStatusResponse),
    }) as Promise<GetLabShutoffStatusResponse>;
  }

  /** Utility methods that use the shutoff APIs */

  /**
   * Populate the shutoff statuses for the given list of events, including populating
   * their challenges and optionally including test events. Makes one API call.
   * Populating the challenge descriptors of an event with accurate shutoff statuses is important so that
   * [X challenges] badges can be shown next to the event status.
   *
   * @param events
   * @param includeTestEvents - whether to populate the shutoff statuses of the corresponding test events,
   * if test event names are present. Default true.
   * @return provided list of EventBase with their shutoff statuses/challenges modified in place
   */
  async populateEventChallengeShutoffs(events: EventBase[], includeTestEvents = true): Promise<EventBase[]> {
    const candidates: LabShutoffCandidate[] = [];

    events.forEach((event: EventBase) => {
      candidates.push(LabShutoffCandidate.ofEvent(event.name));

      if (includeTestEvents && event.testCloneEventName) {
        candidates.push(LabShutoffCandidate.ofEvent(event.testCloneEventName));
      }

      event.challengeDescriptors.forEach((cd) => {
        // add a candidate for the regular event and a candidate for the test event
        candidates.push(LabShutoffCandidate.ofEventChallengePair(event.name, cd.challengeId || ''));

        if (includeTestEvents && event.testCloneEventName) {
          candidates.push(LabShutoffCandidate.ofEventChallengePair(event.testCloneEventName, cd.challengeId || ''));
        }
      });
    });
    // make the API call to get shutoff statuses
    const shutoffStatuses = (await this.getShutoffStatuses(candidates, true)).statuses;

    events.forEach((event: EventBase) => {
      event.shutoffStatus = shutoffStatuses[LabShutoffCandidate.ofEvent(event.name).toCandidateString() || ''];

      if (includeTestEvents && event.testCloneEventName) {
        event.testEventShutoffStatus =
          shutoffStatuses[LabShutoffCandidate.ofEvent(event.testCloneEventName).toCandidateString() || ''];
      }

      event.challengeDescriptors.forEach((cd) => {
        cd.shutoffStatus =
          shutoffStatuses[
            LabShutoffCandidate.ofEventChallengePair(event.name, cd.challengeId || '').toCandidateString() || ''
          ];

        if (includeTestEvents && event.testCloneEventName) {
          cd.testEventShutoffStatus =
            shutoffStatuses[
              LabShutoffCandidate.ofEventChallengePair(
                event.testCloneEventName,
                cd.challengeId || ''
              ).toCandidateString() || ''
            ];
        }
      });
    });

    return events;
  }

  /**
   * Get a list of LabShutoffActions in a given date range.
   *
   * @param range
   * @param silent
   */
  async getAuditTrail(range: DateRangeFilter, silent = false): Promise<GetLabShutoffAuditTrailResponse> {
    const params: QueryParams = {};

    if (range.start) {
      params.dateRangeStart = range.start;
    }

    if (range.end) {
      params.dateRangeEnd = range.end;
    }

    return this.apiClient.get({
      path: '/admin/labshutoff/audit-trail',
      failMessage: 'Failed to get lab shutoff audit trail',
      params,
      silent,
      responseMapper: (object) => fromPlainObject(object, GetLabShutoffAuditTrailResponse),
    }) as Promise<GetLabShutoffAuditTrailResponse>;
  }
}
