import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rating } from 'react-simple-star-rating';
import { i18nKeys } from '../../utils/i18n.utils';
import { roundFloat } from '../../utils/number.utils';

interface StarRatingProps {
  value: number;
  valueCount: number;
  showAverage?: boolean;
  inlineAverage?: boolean;
  customView?: boolean;
}

const StarRating: React.FC<StarRatingProps> = ({
  value,
  valueCount,
  showAverage = false,
  inlineAverage = false,
  customView = false,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'inline-block' }}>
      <Rating readonly initialValue={value} size={customView ? 25 : 15} iconsCount={5} allowFraction />
      {showAverage && (
        <div className="default-text" style={{ display: inlineAverage ? 'inline-block' : 'block', marginLeft: '5px' }}>
          {t(i18nKeys.general.average)} {roundFloat(value).toFixed(2)} ({value || 0}) ({valueCount || 0})
        </div>
      )}
    </div>
  );
};
export default StarRating;
