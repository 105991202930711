import { preProdLogger } from '../../utils/log.utils';
import { AnnotationContext, TutorialPanelProps } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useState } from 'react';
import { i18nKeys } from '../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useToolPanel } from '../../store/tool-panel.context';
import { useApi } from '../../store/api.context';
import { useCreateChallenge } from '../../store/create-challenge.context';
import { useComponentDidMountEffect } from '../../hooks/useComponentDidMountEffect';
import { useUser } from '../../store/user.context';
import { useAuth } from '../../store/auth.context';
import { ChallengeHotspot } from "./challengesCommon/ChallengeHotspots";
import { ChallengeTutorialType } from '../tutorials/TutorialsModel';

export const ChallengeTutorial: React.FC = ({ children }) => {
  const { i18n, t } = useTranslation();
  const { user } = useUser();
  const { toggleToolPanel } = useToolPanel();
  const { tutorials, setHelpPanelTopic } = useCreateChallenge();
  const { authClient } = useAuth();
  const [startTutorial, setStartTutorial] = useState<boolean>(true);
  const [currentTutorial, setCurrentTutorial] = useState<TutorialPanelProps.Tutorial | null>(null);
  const { accountApi } = useApi();

  // Since the tutorial titles are plain strings, they could not be translated in ChallengeTutorialData.
  // Therefore, we translate each of them here before rendering.
  const localizeTutorial = (tutorial: TutorialPanelProps.Tutorial | null) => {
    return !tutorial ? tutorial :  {
      ...tutorial,
      title: t(tutorial.title),
      tasks: tutorial.tasks.map((task) => ({
        title: t(task.title),
        steps: task.steps.map((step)  => ({
          ...step,
          title: t(step.title),
        })) as readonly TutorialPanelProps.Step[],
      })) as readonly TutorialPanelProps.Task[],
    } as TutorialPanelProps.Tutorial
  }

  useComponentDidMountEffect(async () => {
    if ((await authClient.isSignedIn()) && user?.isChallengeBuilder) {
      const response = await accountApi.getLastFinishedTutorialDate();
      setStartTutorial(response.lastFinishedTutorialOn == null);
    }
  });

  useEffect(() => {
    setCurrentTutorial(localizeTutorial(tutorials[ChallengeTutorialType.CREATE_CHALLENGE]));
  }, [i18n.language]);

  useEffect(() => {
    if (startTutorial) {
      setCurrentTutorial(localizeTutorial(tutorials[ChallengeTutorialType.CREATE_CHALLENGE]));
      toggleToolPanel(true);
    } else {
      setCurrentTutorial(null);
      toggleToolPanel(false);
    }
  }, [startTutorial]);

  const toggleTutorialCompleted = useCallback(
    (completed: boolean) => {
      if (!currentTutorial) {
        preProdLogger('Tutorial not found...');
        return completed;
      }
      const newTutorial = {
        ...currentTutorial,
        completed,
      };
      preProdLogger('Completed', newTutorial.completed);
      setCurrentTutorial(newTutorial);
    },
    [currentTutorial]
  );

  return (
    <AnnotationContext
      currentTutorial={currentTutorial}
      onExitTutorial={() => {
        preProdLogger('Exiting tutorial');
        setStartTutorial(false);
      }}
      onStartTutorial={(event) => {
        preProdLogger('Starting tutorial', event);
        toggleTutorialCompleted(false);
        setStartTutorial(true);
      }}
      onFinish={() => {
        if (!currentTutorial) {
          return;
        }
        toggleTutorialCompleted(true);
        void accountApi.updateLastFinishedTutorialDate();
      }}
      onStepChange={({ detail }) => {
        preProdLogger('Step changed', detail.step);
        setHelpPanelTopic(Object.values(ChallengeHotspot)[detail.step]);
      }}
      i18nStrings={{
        stepCounterText: (stepIndex: number, totalStepCount: number) =>
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unsafe-assignment
          t(i18nKeys.general.stepCounter, { count: stepIndex + 1 }) + '/' + totalStepCount,
        taskTitle: (taskIndex: number, taskTitle: string) =>
          t(i18nKeys.general.taskCounter, { count: taskIndex + 1 }) + ': ' + taskTitle,
        labelHotspot: (openState: boolean) => {
          return openState ? t(i18nKeys.tutorial.annotation.close) : t(i18nKeys.tutorial.annotation.open);
        },
        nextButtonText: t(i18nKeys.general.next),
        previousButtonText: t(i18nKeys.general.previous),
        finishButtonText: t(i18nKeys.general.finish),
        labelDismissAnnotation: t(i18nKeys.tutorial.annotation.dismiss),
      }}>
      {children}
    </AnnotationContext>
  );
};
