/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { isEmpty } from 'lodash';
import { JamEventTeamGoal } from './JamCommon';

export class JamEventTeam {
  teamId: string;
  teamName: string;
  owner: string;
  passwordRequired: boolean;
  facilitatorOnly: boolean;
  suspectedCheater = false;
  cheatingEvidence: string[] = [];
  size: number;
  capacity: number;
  autoCreated: boolean;
  goal: JamEventTeamGoal = JamEventTeamGoal.PLAY_TO_LEARN;
  passwordProtected = false;
  canRename = false;
  isAutoAssigned = false;

  static fromPlainObject(obj: any): JamEventTeam {
    return Object.assign(new JamEventTeam(), obj || {}) as JamEventTeam;
  }

  get displayName(): string {
    if (isEmpty(this.teamName)) {
      return this.teamId;
    } else {
      return this.teamName;
    }
  }

  set displayName(name: string) {
    this.teamName = name;
  }

  get full(): boolean {
    return this.availableSeats <= 0;
  }

  get availableSeats(): number {
    return this.capacity - this.size;
  }
}

export class JamEventTeamSuggestions {
  playToWin: JamEventTeam[] = [];
  playToLearn: JamEventTeam[] = [];

  static fromPlainObject(obj: any): JamEventTeamSuggestions {
    return Object.assign(new JamEventTeamSuggestions(), obj, {
      playToWin: (obj.playToWin || []).map(JamEventTeam.fromPlainObject) as JamEventTeam[],
      playToLearn: (obj.playToLearn || []).map(JamEventTeam.fromPlainObject) as JamEventTeam[],
    }) as JamEventTeamSuggestions;
  }
}
