import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { i18nKeys } from '@/src/utils/i18n.utils';

export interface IEventImageReviewProps {
  imageUrl?: string;
}

const { imageTitle } = i18nKeys.eventTemplates.step5.steps.step4;

export const EventImageReview: React.FC<IEventImageReviewProps> = ({ imageUrl }) => {
  const { t } = useTranslation();

  return (
    <Container header={<Header variant="h2">{t(imageTitle)}</Header>}>
      <ColumnLayout columns={1} variant="text-grid">
        <SpaceBetween size="l" direction="horizontal">
          <Box>
            {/* <Box>{image.name || '-'}</Box> */}
            <img width={120} src={imageUrl}  alt={t(i18nKeys.eventTemplates.step4.image.altEvent)}/>
          </Box>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};
