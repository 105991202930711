import React, { useState } from 'react';
import { Box, Button, Modal } from '@amzn/awsui-components-react';
import ReviewStars from '../Reviews/Reviews';
import './ReviewCard.scss';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface IProps {
  imgSrc: string;
  rating: number;
  description: string;
  title: string;
  name: string;
  position: string;
  company: string;
  isExpanded?: boolean;
}

const MAX_CHARACTERS = 250;

const ReviewCard: React.FC<IProps> = (props) => {
  const { imgSrc, rating, description, name, title, position, company, isExpanded } = props;
  const [showModal, setShowModal] = useState(false);
  const hasReadMore = isExpanded ? false : description.length > MAX_CHARACTERS;
  const trimmedDescription = hasReadMore ? description.substring(0, MAX_CHARACTERS) + '...' : description;
  const { t } = useTranslation();

  return (
    <>
      <div className="review-card">
        <div className="review-card__image-container">
          <img src={imgSrc} alt="" className="review-card__image" />
          <div className="review-card__text-container">
            <Box variant="p">
              <div className="review-card__text">{name}</div>
            </Box>
            <Box variant="small" color="text-label">
              <i>{position}</i>
            </Box>
            <Box variant="small" color="text-label">
              {company}
            </Box>
          </div>
        </div>
        <ReviewStars rating={rating} maxRating={5} />
        <Box variant="h5">{title}</Box>
        <Box variant="p">
          {`"${trimmedDescription}"`}
          {hasReadMore && (
            <Button variant="link" onClick={() => setShowModal(true)}>
              {t(i18nKeys.catalog.readMore)}
            </Button>
          )}
        </Box>
      </div>
      {showModal && (
        <Modal visible onDismiss={() => setShowModal(false)}>
          <ReviewCard {...props} isExpanded />
        </Modal>
      )}
    </>
  );
};

export default ReviewCard;
