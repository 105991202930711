import { Link as CloudScapeLink, LinkProps } from '@amzn/awsui-components-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

/**
 * this component inherently uses
 * Link component from CloudScape only as you see in the import
 *
 * the CloudScape Link component acts as anchor tag <a /> and
 * reloads the page when clicked rather just pushing the history
 * and navigating without reloading the page
 *
 * this component resolved mentioned problem without losing the
 * design pattern from CloudScape
 */
const Link: React.FC<LinkProps> = ({ onFollow, ...props }) => {
  const history = useHistory();

  return (
    <CloudScapeLink
      {...props}
      onFollow={(e) => {
        e.preventDefault();
        if (props.href) {
          history.push(props.href);
        }
        onFollow?.(e);
      }}
    />
  );
};

export default Link;
