export const DISPLAY_NAME_REGEX = /^[a-z0-9!"#$%&'()*+,.\/:;<=>?@\[\] ^_`{|}~-]*$/i;
export const EMAIL_REGEX = /^[^@]+@[^.@_]{2,255}(\.[a-z]{2,})+$/;
export const POSTAL_CODE_REGEX = /^[a-zA-Z0-9-\s]{2,15}$/;
export const MIN_PHONE_NUMBER_LENGTH = 7;
export const MAX_PHONE_NUMBER_LENGTH = 15;
// Allow beginning white space, spaces or dashes between numbers, and up to an 11-digit phone number ex. 1-800-555-1234
export const PHONE_NUMBER_REGEX = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})/;
export const TIME_DURATION_REGEX = /^(1[0-8][0-9]|19[0-1]):[0-5][0-9]$/;
export const NUMBER_REGEX = /^[0-9:]+$/;
export const MIN_ADDRESS_LENGTH = 3;
export const MAX_ADDRESS_LENGTH = 200;
export const MIN_NAME_LENGTH = 1;
export const MAX_NAME_LENGTH = 100;
export const MIN_PROFILE_TITLE_LENGTH = 3;
export const MAX_PROFILE_TITLE_LENGTH = 100;
export const MIN_COMPANY_LENGTH = 1;
export const MAX_COMPANY_LENGTH = 100;
export const MAX_EVENT_DURATION = '192:00'; // Exceed the event duration to 192 (8days)
export const EVENT_DURATION_MAX_HOURS = '191'; 
export const EVENT_DURATION_MAX_LIMIT = '192';
export const EVENT_DURATION_MAX_MINUTES = '59';
