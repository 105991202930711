/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import _ from 'lodash';
import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import {
  Challenge,
  ChallengeTask,
  ChallengeTaskValidationType,
  ChallengeLearningType,
  ChallengeWrapper,
  Clue,
  Sponsor,
} from '../types/Challenge';
import { useApi } from './api.context';
import { DEFAULT_LAB_PROVIDER } from '../types/LabProvider';
import { ChallengeModes } from '../constants/shared/challenge-modes';
import {
  containsLargeEventName,
  containsOnlyNumbers,
  containsYear,
  isValidLength,
  isValidUrl,
  isValidChallengeId,
} from '../utils/validation.utils';
import { i18nKeys, withI18NPrefix } from '../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { isEmpty, parseBraceDelimitedString, safeString } from '../utils/string.utils';
import { safeArray } from '../utils/list.utils';
import {
  LabProviderDefinitions,
  ModeDefinitions,
  SettingType,
} from '../components/challenges/challengesCommon/ChallengeOptionDefinitions';
import { useToolPanel } from './tool-panel.context';
import { useAvailableTutorials } from '../components/tutorials/ChallengeTutorialData';
import { TutorialPanelProps } from '@amzn/awsui-components-react';
import { ChallengeHotspot } from '../components/challenges/challengesCommon/ChallengeHotspots';
import { Nullable, NullableString } from '../types/common';
import { useChallenges } from './challenge.context';
import { AccountCredentials } from '../types/LabModels';
import { Credentials } from 'aws-sdk';
import { uploadFile } from '../utils/s3-utils';
import { getEnvVar } from '../utils/env-var.utils';
import { preProdLogger } from '../utils/log.utils';
import { useFlashbars } from './flashbar.context';

export type ToggleChallengeEditMode = (challenge: Challenge | null) => void;
export type SaveNewChallenge = () => void;
export type SaveEditedChallenge = () => Promise<Challenge | null>;
export type InitializeNewChallenge = () => void;
export type HandleUpdateChallengeId = (challengeId: string) => void;
export type HandleUpdateChallengeProp = (_action: string, _payload: any) => void;
export type HandleUpdateChallengeTask = (_action: string, _taskNumber: number, _payload: any) => void;
export type HandleUpdateChallengeClue = (
  _action: string,
  _taskNumber: number,
  clueNumber: number,
  _payload: any
) => void;
export type HandleCreateChallenge = () => Promise<Nullable<Challenge> | undefined>;
export type AddChallengeTask = (challengeId: string, challengeVersion: number) => Promise<void>;
export type RemoveChallengeTask = (challengeId: string, challengeVersion: number, taskId: string) => Promise<void>;
export type ChallengeValidationFields =
  | ChallengeSettingsFields
  | ChallengeLearningOutcomeFields
  | ChallengeOverviewFields
  | ChallengeTaskFields
  | ClueFields;

// TODO: Refactor all Challenge*Fields to be 1:1 with ChallengePropAction
// eslint-disable-next-line no-shadow
export enum ChallengePropAction {
  TITLE = 'title',
  DESCRIPTION = 'description',
  TAGS = 'tags',
  LEARNING_OUTCOME = 'learning-outcome',
  BASIC_SETTINGS = 'basic-settings',
  SSH_SETTINGS = 'ssh-settings',
  AWS_SETTINGS = 'aws-settings',
  SPONSOR_SETTINGS = 'sponsor-settings',
  ADVANCED_SETTINGS = 'advanced-settings',
  TASKS = 'tasks',
  ISSUE = 'issue',
  TRANSLATION = 'translation',
  TASK_TITLE = 'task-title',
  TASK_CONTENT = 'task-content',
  TASK_WEIGHTED_SCORE = 'task-weighted-score',
  TASK_VALIDATION_TYPE = 'task-validation-type',
  TASK_PREREQUISITES = 'task-prerequisites',
  TASK_DESCRIPTION = 'task-description',
  TASK_BACKGROUND = 'task-background',
  TASK_GETTING_STARTED = 'task-getting-started',
  TASK_INVENTORY = 'task-inventory',
  TASK_SERVICES = 'task-services-you-should-know',
  TASK_VALIDATION = 'task-validation',
  TASK_CLUES = 'task-clues',
  TASK_VALIDATION_RUNTIME = 'task-validation-runtime',
  TASK_VALIDATION_LAMDA = 'task-validation-lambda',
  TASK_GLOBAL_STATIC_ANSWER = 'task-global-static-answer',
  TASK_VALIDATION_DESCRIPTION = 'task-validation-description',
  CLUE_TITLE = 'clue-title',
  CLUE_DESCRIPTION = 'clue-description',
  RESOURCES = 'resources',
  STUDENT_POLICY = 'student-policy',
  CFN_TEMPLATE = 'cfn-template',
  NEXT_STEPS = 'next-steps',
  WIKI = 'wiki',
  MAINTAINERS = 'maintainers',
  OWNER = 'owner',
  FACILITATOR_NOTES = 'facilitator-notes',
  PARTICIPANTS_CSV = 'participants-csv-file',
}

// eslint-disable-next-line no-shadow
export enum ChallengeSettingsFields {
  JAM_TYPE = 'jamType',
  CATEGORY = 'category',
  DIFFICULTY = 'difficulty',
  LEARNING_TYPE = 'learningType',
  SSH_KEYPAIR_REQUIRED = 'sshKeyPairRequired',
  AWS_SERVICES = 'awsServices',
  REGION_ALLOWLIST = 'regionAllowList',
  SPONSOR_NAME = 'name',
  SPONSOR_URL = 'url',
  SPONSOR_LOGO = 'logo',
  SPONSOR_DESCRIPTION = 'description',
  CHALLENGE_ICON = 'challengeIcon',
  SETTINGS_DEFAULT_LAB_PROVIDER = 'defaultLabProvider',
  CHALLENGE_ALWAYS_ON = 'challengeAlwaysOn',
  MODE = 'mode',
  ALLOWLISTED_SERVICES = 'allowlistServicesRequired',
  IDLE_MINS_BEFORE_READY = 'idleMinsBeforeReady',
}

// eslint-disable-next-line no-shadow
export enum ChallengeLearningOutcomeFields {
  SUMMARY = 'SUMMARY',
  INTRODUCTION = 'INTRODUCTION',
  TOPICS_COVERED = 'TOPICS_COVERED',
  PRE_REQUISITES = 'PRE_REQUISITES',
}

// eslint-disable-next-line no-shadow
export enum ChallengeTaskFields {
  WEIGHTED_SCORE = 'scorePercent',
  TASK_TITLE = 'title',
  TASK_DESCRIPTION = 'description',
  BACKGROUND = 'background',
  GETTING_STARTED = 'gettingStarted',
  INVENTORY = 'inventory',
  AWS_SERVICES_USED = 'awsServicesUsed',
  TASK_VALIDATION_DESCRIPTION = 'taskValidationDescription',
  CONTENT = 'content',
  VALIDATION_TYPE = 'validationType',
  GLOBAL_STATIC_ANSWER = 'globalStaticAnswer',
  VALIDATION_FUNCTION = 'validationFunction',
}

// eslint-disable-next-line no-shadow
export enum ClueFields {
  TITLE = 'title',
  DESCRIPTION = 'description',
}

// eslint-disable-next-line no-shadow
export enum ChallengeClueFields {
  CLUE_TITLE,
  CLUE_DESCRIPTION,
}

// eslint-disable-next-line no-shadow
export enum ChallengeOverviewFields {
  CHALLENGE_ID = 'challenge-id',
  TITLE = 'title',
  DESCRIPTION = 'description',
}

// eslint-disable-next-line no-shadow
export enum ChallengeSections {
  OVERVIEW,
  LEARNING_OUTCOME,
  SETTINGS,
}

// eslint-disable-next-line no-shadow
export enum PatchRoute {
  SUMMARY = `/summary`,
  ISSUE = `/issues`,
  TRANSLATION = `/translations`,
  SETTINGS = `/settings`,
  CFN = `/cfn`,
  IAM = `/iam`,
  WIKI = `/wiki`,
  LEARNING_OUTCOME = `/learning-outcome`,
  NEXT_STEPS = `/next-steps`,
  TASK_CONFIG = `/tasks/config`,
}

// eslint-disable-next-line no-shadow
export enum PatchProp {
  TITLE = `title`,
  DESCRIPTION = `description`,
  TAGS = 'tags',
  CFN = `cfnTemplate`,
  IAM = `studentPolicy`,
  SETTING = `setting`,
  WIKI = `wiki`,
  LEARNING_OUTCOME = `learningOutcome`,
  NEXT_STEPS = `nextSteps`,
  TASK = `task`,
  ISSUE = `issue`,
  TRANSLATION = `translation`,
  TASK_CONFIG = `tasks`,
  RESOURCES = 'resources',
  MAINTAINERS = 'maintainers',
  OWNER = 'owner',
}

// eslint-disable-next-line no-shadow
export enum ChallengeTutorialTabs {
  INFO = 'Info',
  TUTORIAL = 'Tutorial',
}

export const CHALLENGE_ID_MIN_LENGTH = 4;
export const CHALLENGE_ID_MAX_LENGTH = 30;
export const TITLE_MIN_LENGTH = 3;
export const TITLE_MAX_LENGTH = 120;
export const DESCRIPTION_MIN_LENGTH = 10;
export const DESCRIPTION_MAX_LENGTH = 1200;
export const SUMMARY_MIN_LENGTH = 10;
export const SUMMARY_MAX_LENGTH = 1000;
export const INTRODUCTION_MIN_LENGTH = 10;
export const INTRODUCTION_MAX_LENGTH = 500;
export const TOPICS_COVERED_MIN_LENGTH = 2;
export const TOPICS_COVERED_MAX_LENGTH = 500;
export const CATEGORY_MIN_LENGTH = 2;
export const CATEGORY_MAX_LENGTH = 30;
export const PREREQUISITES_MIN_LENGTH = 10;
export const PREREQUISITES_MAX_LENGTH = 500;
export const CONTENT_MIN_LENGTH = 10;
export const CONTENT_MAX_LENGTH = 5000;
export const SPONSOR_NAME_MIN_LENGTH = 2;
export const SPONSOR_NAME_MAX_LENGTH = 100;
export const TASK_TITLE_MIN_LENGTH = 3;
export const TASK_TITLE_LENGTH = 500;
export const BACKGROUND_MIN_LENGTH = 10;
export const BACKGROUND_MAX_LENGTH = 1200;
export const GETTING_STARTED_MIN_LENGTH = 10;
export const GETTING_STARTED_MAX_LENGTH = 1200;
export const INVENTORY_MIN_LENGTH = 10;
export const INVENTORY_MAX_LENGTH = 500;
export const TASK_VALIDATION_DESCRIPTION_MIN_LENGTH = 10;
export const TASK_VALIDATION_DESCRIPTION_MAX_LENGTH = 500;
export const CLUE_TITLE_MIN_LENGTH = 3;
export const CLUE_TITLE_MAX_LENGTH = 120;
export const CLUE_DESCRIPTION_MIN_LENGTH = 10;
export const CLUE_DESCRIPTION_MAX_LENGTH = 1200;
export const COMMENT_MIN_LENGTH = 2;
export const COMMENT_MAX_LENGTH = 1200;

export interface InputValidationHelper {
  isValid: () => boolean;
  checkErrors: () => string;
}

export type ChallengeTutorialInfoTopic = ChallengeHotspot | ChallengeSections;

export interface TaskValidationHelper {
  isValid: () => boolean;
  checkErrors: () => () => string;
}

export interface CreateChallengeContextValue {
  editMode: boolean;
  toggleChallengeEditMode: ToggleChallengeEditMode;
  initializeNewChallenge: InitializeNewChallenge;
  saveEditedChallenge: SaveEditedChallenge;
  editedChallenge: Challenge | null;
  newChallenge: Challenge | null;
  challengeResourcesToUpload: File[];
  saveNewChallenge: SaveNewChallenge;
  handleUpdateChallengeId: HandleUpdateChallengeId;
  handleUpdateChallengeProp: HandleUpdateChallengeProp;
  handleUpdateChallengeTask: HandleUpdateChallengeTask;
  handleUpdateChallengeClue: HandleUpdateChallengeClue;
  handleCreateChallenge: HandleCreateChallenge;
  addChallengeTask: AddChallengeTask;
  removeChallengeTask: RemoveChallengeTask;
  challengeOverviewValidator: (errorSetterByField: Map<ChallengeOverviewFields, (error: string) => void>) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ChallengeOverviewFields, setError?: boolean) => boolean;
  };
  challengeLearningOutcomeValidator: (
    errorSetterByField: Map<ChallengeLearningOutcomeFields, (error: string) => void>
  ) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ChallengeLearningOutcomeFields, setError?: boolean) => boolean;
  };
  challengeSettingsValidator: (errorSetterByField: Map<ChallengeSettingsFields, (error: string) => void>) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ChallengeSettingsFields, setError?: boolean) => boolean;
  };
  challengeTaskValidator: (
    challengeTask: ChallengeTask,
    errorSetterByField: Map<ChallengeTaskFields, (error: string) => void>
  ) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ChallengeTaskFields, setError?: boolean) => boolean;
  };
  toolsTab: ChallengeTutorialTabs;
  setToolsTab: Dispatch<SetStateAction<ChallengeTutorialTabs>>;
  helpPanelTopic: ChallengeTutorialInfoTopic;
  setHelpPanelTopic: Dispatch<SetStateAction<ChallengeTutorialInfoTopic>>;
  makeHelpPanelHandler: (topic: ChallengeTutorialInfoTopic) => () => void;
  tutorials: TutorialPanelProps.Tutorial[];
  setTutorialCompleted: (tutorial: TutorialPanelProps.Tutorial, completed: boolean) => void;
  challengeClueValidator: (
    clue: Clue,
    errorSetterByField: Map<ClueFields, (error: string) => void>
  ) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ClueFields, setError?: boolean) => boolean;
  };
  validateTasksScoring: (tasks: ChallengeTask[]) => InputValidationHelper;
}

const defaultChallengeSectionValidator = () => {
  return {
    isValidSection: () => false,
    isValidField: () => false,
  };
};

const CreateChallengeContext = createContext<CreateChallengeContextValue>({
  editMode: false,
  toggleChallengeEditMode: (_challenge: Challenge | null): void => {
    // do nothing
  },
  initializeNewChallenge: (): void => {
    // do nothing
  },
  saveEditedChallenge: () =>
    new Promise(() => {
      // do nothing
    }),
  editedChallenge: null,
  newChallenge: null,
  challengeResourcesToUpload: [],
  saveNewChallenge: (): void => {
    // do nothing
  },
  handleUpdateChallengeId: (_challengeId: string) => {
    // do nothing
  },
  handleUpdateChallengeProp: (_action: string, _payload: any): void => {
    // do nothing
  },
  handleUpdateChallengeTask: (_action: string, _taskNumber: number, _payload: any): void => {
    // do nothing
  },
  handleUpdateChallengeClue: (_action: string, _taskNumber: number, _clueNumber: number, _payload: any): void => {
    // do nothing
  },
  handleCreateChallenge: (): Promise<Nullable<Challenge>> =>
    new Promise((resolve) => {
      resolve(null);
    }),
  addChallengeTask: (_challengeId: string, _challengeVersion: number) =>
    new Promise(() => {
      // do nothing
    }),
  removeChallengeTask: (_challengeId: string, _challengeVersion: number, _taskId: string) =>
    new Promise(() => {
      // do nothing
    }),
  challengeOverviewValidator: defaultChallengeSectionValidator,
  challengeLearningOutcomeValidator: defaultChallengeSectionValidator,
  challengeSettingsValidator: defaultChallengeSectionValidator,
  toolsTab: ChallengeTutorialTabs.TUTORIAL,
  setToolsTab: () => {
    // do nothing
  },
  helpPanelTopic: ChallengeSections.OVERVIEW,
  setHelpPanelTopic: () => {
    // do nothing
  },
  makeHelpPanelHandler: () => () => {
    // do nothing
  },
  tutorials: [],
  setTutorialCompleted: () => {
    // do nothing
  },
  challengeTaskValidator: defaultChallengeSectionValidator,
  challengeClueValidator: defaultChallengeSectionValidator,
  validateTasksScoring: () => {
    return {
      isValid: () => false,
      checkErrors: () => '',
    };
  },
});

const CreateChallengeProvider: React.FC = ({ children }) => {
  const [editMode, setEditMode] = useState(false);
  const [newChallenge, setNewChallenge] = useState<Challenge | null>(null);
  const [editedChallenge, setEditedChallenge] = useState<Challenge | null>(null);
  const [editedAttributes, setEditedAttributes] = useState<Set<string>>(new Set());
  const [challengeResourcesToUpload, setChallengeResourcesToUpload] = useState<File[]>([]);
  const { challengesApi } = useApi();
  const { t } = useTranslation();
  const [toolsTab, setToolsTab] = useState<ChallengeTutorialTabs>(ChallengeTutorialTabs.TUTORIAL);
  const [helpPanelTopic, setHelpPanelTopic] = useState<ChallengeTutorialInfoTopic>(ChallengeSections.OVERVIEW);
  const { toggleToolPanel, setToolPanelWidth } = useToolPanel();
  const makeHelpPanelHandler = useCallback(
    (topic: ChallengeTutorialInfoTopic) => () => {
      setHelpPanelTopic(topic); // this tracks which section should be showed
      setToolsTab(ChallengeTutorialTabs.INFO); // this tracks tabs
      setToolPanelWidth(300);
      toggleToolPanel(true); // this tracks whether the tool-panel is open or not
    },
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { tutorials, setTutorialCompleted } = useAvailableTutorials(makeHelpPanelHandler);
  const { setChallenge } = useChallenges();
  const { addSuccessFlashbar } = useFlashbars();

  const toggleChallengeEditMode = (challenge: Challenge | null) => {
    setEditedChallenge(challenge);
    setNewChallenge(challenge);
    setEditMode(!editMode);
  };

  const initializeNewChallenge = () => {
    setEditedChallenge(new Challenge());
  };

  const saveNewChallenge = () => {
    setEditedChallenge(editedChallenge);
  };

  const saveEditedChallenge = async (silent = false) => {
    let currentChallenge = _.cloneDeep(editedChallenge) || new Challenge();
    const currentChallengeId: NullableString = currentChallenge.challengeId;
    const currentEditedAttributes = Array.from(editedAttributes);
    let containsError = false;

    if (editedChallenge) {
      for (const attr of currentEditedAttributes) {
        switch (attr) {
          case PatchProp.TITLE:
            await challengesApi
              .patchChallenge(
                PatchRoute.SUMMARY,
                currentChallengeId,
                currentChallenge.version,
                editedChallenge.props.pick(PatchProp.TITLE, PatchProp.DESCRIPTION),
                true
              )
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.TAGS:
            await challengesApi
              .updateTags(currentChallengeId, currentChallenge.version, currentChallenge.props.tags, true)
              .then((cw: ChallengeWrapper) => {
                if (cw.latest && cw.latest.version) {
                  currentChallenge = cw.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.ISSUE:
            await challengesApi.updateChallengeIssueDetails(editedChallenge.props.issue);
            break;
          case PatchProp.TRANSLATION:
            await challengesApi.updateTranslatedChallenge(editedChallenge.props.translation);
            break;
          case PatchProp.SETTING:
            await challengesApi
              .patchChallenge(
                PatchRoute.SETTINGS,
                currentChallengeId,
                currentChallenge.version,
                editedChallenge.props.settings,
                true
              )
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.CFN:
            challengesApi
              .patchChallenge(
                PatchRoute.CFN,
                currentChallengeId,
                currentChallenge.version,
                editedChallenge.props.pick(PatchProp.CFN),
                true
              )
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.IAM:
            await challengesApi
              .patchChallenge(
                PatchRoute.IAM,
                currentChallengeId,
                currentChallenge.version,
                editedChallenge.props.pick(PatchProp.IAM),
                true
              )
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.WIKI:
            await challengesApi
              .patchChallenge(
                PatchRoute.WIKI,
                currentChallengeId,
                currentChallenge.version,
                editedChallenge.props.pick(PatchProp.WIKI),
                true
              )
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.LEARNING_OUTCOME:
            await challengesApi
              .patchChallenge(
                PatchRoute.LEARNING_OUTCOME,
                currentChallengeId,
                currentChallenge.version,
                editedChallenge.props.pick(PatchProp.LEARNING_OUTCOME),
                true
              )
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.NEXT_STEPS:
            await challengesApi
              .patchChallenge(
                PatchRoute.NEXT_STEPS,
                currentChallengeId,
                currentChallenge.version,
                editedChallenge.props.pick(PatchProp.NEXT_STEPS),
                true
              )
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.TASK:
            // DO NOT USE FOR CREATE CHALLENGE FLOW
            for (const task of editedChallenge.props.tasks) {
              await challengesApi
                .patchChallenge(
                  `/tasks/${task.id}`,
                  currentChallengeId,
                  currentChallenge.version,
                  editedChallenge.props.pickTask(task.id || ''),
                  true
                )
                .then((c: ChallengeWrapper) => {
                  if (c.latest && c.latest.version) {
                    currentChallenge = c.latest;
                  }
                })
                .catch(() => (containsError = true));
            }
            break;
          case PatchProp.TASK_CONFIG:
            // DO NOT USE FOR CREATE CHALLENGE FLOW
            await challengesApi
              .patchChallenge(
                PatchRoute.TASK_CONFIG,
                currentChallengeId,
                currentChallenge.version,
                editedChallenge.props.pick(PatchProp.TASK_CONFIG),
                true
              )
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.RESOURCES:
            await uploadFiles(challengeResourcesToUpload, true).catch((err) => preProdLogger(err));
            break;
          case PatchProp.MAINTAINERS:
            await challengesApi
              .updateMaintainers(currentChallengeId, currentChallenge.version, currentChallenge.props.maintainers, true)
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
          case PatchProp.OWNER:
            await challengesApi
              .updateOwner(currentChallengeId, currentChallenge.version, currentChallenge.props.owner, true)
              .then((c: ChallengeWrapper) => {
                if (c.latest && c.latest.version) {
                  currentChallenge = c.latest;
                }
              })
              .catch(() => (containsError = true));
            break;
        }
      }
    }
    if (containsError) {
      return null;
    } else {
      if (!silent) {
        addSuccessFlashbar(withI18NPrefix(i18nKeys.success.requestSucceeded.challenges.patchChallenge));
      }
      return currentChallenge;
    }
  };

  const handleUpdateChallengeId = (challengeId: string) => {
    const challenge = editedChallenge ? _.cloneDeep(editedChallenge) : new Challenge();
    challenge.challengeId = challengeId;
    setEditedChallenge(challenge);
  };

  const handleUpdateChallengeProp = (action: string, payload: any) => {
    const challenge = editedChallenge ? _.cloneDeep(editedChallenge) : new Challenge();
    const challengeProps = challenge?.props;

    switch (action) {
      case ChallengePropAction.TITLE:
        challengeProps.title = payload;
        updateEditedAttributes(PatchProp.TITLE);
        break;
      case ChallengePropAction.DESCRIPTION:
        challengeProps.description = payload;
        // Note: Existing Patch API updates the challenge title & summary at the same time.
        updateEditedAttributes(PatchProp.TITLE);
        break;
      case ChallengePropAction.TAGS:
        challengeProps.tags = payload ?? [];
        updateEditedAttributes(PatchProp.TAGS);
        break;
      case ChallengePropAction.LEARNING_OUTCOME:
        challengeProps.learningOutcome = payload ?? {};
        updateEditedAttributes(PatchProp.LEARNING_OUTCOME);
        break;
      case ChallengePropAction.ISSUE:
        challengeProps.issue = payload ?? {};
        updateEditedAttributes(PatchProp.ISSUE);
        break;
      case ChallengePropAction.TRANSLATION:
        challengeProps.translation = payload ?? {};
        updateEditedAttributes(PatchProp.TRANSLATION);
        break;
      case ChallengePropAction.BASIC_SETTINGS:
        challengeProps.jamType = payload.jamType;
        challengeProps.category = payload.category;
        challengeProps.difficulty = Number(payload.difficulty);
        challengeProps.learningType = payload.learningType;
        updateEditedAttributes(PatchProp.SETTING);
        break;
      case ChallengePropAction.SSH_SETTINGS:
        challengeProps.sshKeyPairRequired = payload.sshKeyPairRequired ?? false;
        updateEditedAttributes(PatchProp.SETTING);
        break;
      case ChallengePropAction.AWS_SETTINGS:
        challengeProps.awsServices = payload.awsServices ?? [];
        challengeProps.regionAllowlist = payload.regionAllowList ?? [];
        updateEditedAttributes(PatchProp.SETTING);
        break;
      case ChallengePropAction.SPONSOR_SETTINGS:
        challengeProps.sponsor = payload ?? {};
        updateEditedAttributes(PatchProp.SETTING);
        break;
      case ChallengePropAction.ADVANCED_SETTINGS:
        challengeProps.challengeIcon = payload.challengeIcon;
        challengeProps.defaultLabProvider = payload.defaultLabProvider ?? DEFAULT_LAB_PROVIDER;
        challengeProps.challengeAlwaysOn = payload.challengeAlwaysOn ?? false;
        challengeProps.mode = payload.mode ?? ChallengeModes.TRADITIONAL;
        challengeProps.allowlistServicesRequired = payload.allowlistServicesRequired ?? [];
        challengeProps.idleMinsBeforeReady = payload.idleMinsBeforeReady as number;
        updateEditedAttributes(PatchProp.SETTING);
        break;
      case ChallengePropAction.RESOURCES:
        setChallengeResourcesToUpload(payload);
        updateEditedAttributes(PatchProp.RESOURCES);
        break;
      case ChallengePropAction.STUDENT_POLICY:
        challengeProps.studentPolicy = payload;
        updateEditedAttributes(PatchProp.IAM);
        break;
      case ChallengePropAction.CFN_TEMPLATE:
        challengeProps.cfnTemplate = payload;
        updateEditedAttributes(PatchProp.CFN);
        break;
      case ChallengePropAction.NEXT_STEPS:
        challengeProps.nextSteps = payload;
        updateEditedAttributes(PatchProp.NEXT_STEPS);
        break;
      case ChallengePropAction.MAINTAINERS:
        challengeProps.maintainers = payload;
        updateEditedAttributes(PatchProp.MAINTAINERS);
        break;
      case ChallengePropAction.OWNER:
        challengeProps.owner = payload;
        updateEditedAttributes(PatchProp.OWNER);
        break;
      case ChallengePropAction.WIKI:
        challengeProps.wiki = payload;
        updateEditedAttributes(PatchProp.WIKI);
        break;
      case ChallengePropAction.FACILITATOR_NOTES:
        challengeProps.facilitatorNotes = payload;
        break;
    }
    challenge.props = challengeProps;
    if (editedChallenge) {
      // This is needed to use the updated challenge props this frame for validation purposes
      editedChallenge.props = challengeProps;
    }
    setEditedChallenge(challenge);
  };

  const handleUpdateChallengeTask = (action: string, taskNumber: number, payload: any) => {
    const challenge = editedChallenge ? _.cloneDeep(editedChallenge) : new Challenge();
    const tasks = challenge.props.tasks;

    const task: ChallengeTask = tasks.length === 0 ? ChallengeTask.defaultChallengeTask() : tasks[taskNumber - 1];

    switch (action) {
      case ChallengePropAction.TASK_TITLE:
        task.title = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_CONTENT:
        task.content = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_WEIGHTED_SCORE:
        task.scorePercent = payload;
        updateEditedAttributes(PatchProp.TASK_CONFIG);
        break;
      case ChallengePropAction.TASK_VALIDATION_TYPE:
        task.validationType = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_PREREQUISITES:
        task.dependsOnTaskIds.push(payload);
        updateEditedAttributes(PatchProp.TASK_CONFIG);
        break;
      case ChallengePropAction.TASK_DESCRIPTION:
        task.description = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_BACKGROUND:
        task.background = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_GETTING_STARTED:
        task.gettingStarted = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_INVENTORY:
        task.inventory = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_SERVICES:
        task.awsServicesUsed = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_DESCRIPTION:
        task.description = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_VALIDATION_DESCRIPTION:
        task.validationDescription = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_CONTENT:
        task.content = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_CLUES:
        task.clues = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_VALIDATION_RUNTIME:
        task.validationFunctionRuntime = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_VALIDATION_LAMDA:
        task.validationFunction = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_GLOBAL_STATIC_ANSWER:
        task.globalStaticAnswer = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.TASK_GLOBAL_STATIC_ANSWER:
        task.globalStaticAnswer = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      default:
        throw new Error('Not supported!');
    }

    challenge.props.tasks[taskNumber - 1] = task;
    setEditedChallenge(challenge);
  };

  const handleUpdateChallengeClue = (action: string, taskNumber: number, clueNumber: number, payload: any) => {
    const challenge = editedChallenge ? _.cloneDeep(editedChallenge) : new Challenge();
    const task = challenge.props.tasks[taskNumber - 1];
    const clue: Clue = task.clues.length > 0 ? task.clues[clueNumber - 1] : Clue.defaultClue();
    switch (action) {
      case ChallengePropAction.CLUE_TITLE:
        clue.title = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      case ChallengePropAction.CLUE_DESCRIPTION:
        clue.description = payload;
        updateEditedAttributes(PatchProp.TASK);
        break;
      default:
        throw new Error('Not supported!');
    }

    task.clues[clueNumber - 1] = clue;
    challenge.props.tasks[taskNumber - 1] = task;
    setEditedChallenge(challenge);
  };

  const updateEditedAttributes = (attr: PatchProp) => {
    if (attr.trim()) {
      setEditedAttributes((attrs) => new Set(attrs).add(attr.trim()));
    }
  };

  const handleCreateChallenge = async (): Promise<Nullable<Challenge> | undefined> => {
    if (editedChallenge?.challengeId) {
      const createBody = { title: editedChallenge.props.title, description: editedChallenge.props.description}
      return await challengesApi
        .createChallenge(editedChallenge.challengeId, createBody)
        .then((challengeWrapper) => {
          if (challengeWrapper?.latest) {
            const currentChallenge = editedChallenge;
            currentChallenge.version = challengeWrapper.latest?.version;
            return currentChallenge;
          }
        })
        .then(async (challenge) => {
          if (challenge) {
            const updated = await saveEditedChallenge(true);
            if (updated) {
              setEditedChallenge(null);
              return updated;
            } else {
              // If the challenge fails after attempting to patch changes, we will need to delete the challenge
              // since we pre-create the challenge ID to allow the local values to be updated on the backend.
              // If not done, then the challenge builder will be required to fix the issue that blocked the patch
              // AND change their challenge ID since the original ID would've existed.
              void challengesApi.deleteChallenge(editedChallenge.challengeId || '');
            }
          }
          return null;
        });
    } else {
      return new Promise((resolve) => {
        resolve(null);
      });
    }
  };

  const addChallengeTask = async (challengeId: string, challengeVersion: number) => {
    await challengesApi.createChallengeTask(challengeId, challengeVersion).then((c: ChallengeWrapper) => {
      if (c.latest) {
        setEditedChallenge(c.latest);
        setChallenge(c.latest);
      }
    });
  };

  const removeChallengeTask = async (challengeId: string, challengeVersion: number, taskId: string) => {
    await challengesApi.removeChallengeTask(challengeId, challengeVersion, taskId).then((c: ChallengeWrapper) => {
      if (c.latest) {
        setEditedChallenge(c.latest);
        setChallenge(c.latest);
      }
    });
  };

  const uploadFiles = async (files: File[], silent = true) => {
    const accountCredentials: AccountCredentials = await challengesApi.getCredentialsToUploadChallengeResources(
      editedChallenge?.challengeId || '',
      silent
    );

    const creds: Nullable<Credentials> = accountCredentials.toAWSCredentials();

    if (creds) {
      await Promise.all(
        files.map((file) => {
          return uploadFile(
            creds,
            getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME'),
            file,
            editedChallenge?.challengeId
          );
        })
      );
    }
  };

  // Validation Methods
  const validationReducer = (input: string) => (acc: string[], rule: { fn: (s: string) => any, err: string } ) => {
    if (rule.fn(input)) {
      acc.push(rule.err);
    }
    return acc;
  };

  const challengeIdValidationRules = [
    { fn: (v: string) => !v, err: t(i18nKeys.challenges.subSections.overview.fields.challengeId.required) },
    { fn: (v: string) => !isValidLength(v, CHALLENGE_ID_MIN_LENGTH, CHALLENGE_ID_MAX_LENGTH), err: t(i18nKeys.challenges.subSections.overview.fields.challengeId.lengthError, {
      CHALLENGE_ID_MIN_LENGTH,
      CHALLENGE_ID_MAX_LENGTH,
    }) },
    { fn: (v: string) => containsYear(v), err: t(i18nKeys.challenges.subSections.overview.fields.challengeId.yearError) },
    { fn: (v: string) => containsOnlyNumbers(v), err: t(i18nKeys.challenges.subSections.overview.fields.challengeId.noAlphaError) },
    { fn: (v: string) => containsLargeEventName(v), err: t(i18nKeys.challenges.subSections.overview.fields.challengeId.eventNameError) },
    { fn: (v: string) => !isValidChallengeId(v), err: t(i18nKeys.challenges.subSections.overview.fields.challengeId.invalid) }
  ];

  const validateChallengeId = () => {
    const input = safeString(editedChallenge?.challengeId);
    const errors = challengeIdValidationRules.reduce(validationReducer(input), [])
    const isValid = !errors.length;

    return {
      isValid: () => isValid,
      checkErrors: () => isValid ? '' : errors[0],
    };
  };

  const challengeTitleValidationRules = [
    { fn: (v: string) => isEmpty(v), err: t(i18nKeys.challenges.subSections.overview.fields.title.required) },
    { fn: (v: string) => !isValidLength(v, TITLE_MIN_LENGTH, TITLE_MAX_LENGTH), err: t(i18nKeys.challenges.subSections.overview.fields.title.invalid) },
  ];

  const validateTitle = () => {
    const input = safeString(editedChallenge?.props.title);
    const errors = challengeTitleValidationRules.reduce(validationReducer(input), []);
    const isValid = !errors.length;
    return {
      isValid: () => isValid,
      checkErrors: () => isValid ? '' : errors[0],
    };
  };

  const challengeDescriptionValidationRules = [
    { fn: (v: string) => isEmpty(v), err: t(i18nKeys.challenges.subSections.overview.fields.description.required) },
    { fn: (v: string) => !isValidLength(v, DESCRIPTION_MIN_LENGTH, DESCRIPTION_MAX_LENGTH), err: t(i18nKeys.challenges.subSections.overview.fields.description.required) },
  ];

  const validateDescription = () => {
    const input = safeString(editedChallenge?.props.description);
    const errors = challengeDescriptionValidationRules.reduce(validationReducer(input), []);
    const isValid = !errors.length;
    return {
      isValid: () => isValid,
      checkErrors: () => isValid ? '' : errors[0],
    };
  };

  const validateSummary = () => {
    const input = safeString(editedChallenge?.props.learningOutcome.summary);
    const isValid = isValidLength(input, SUMMARY_MIN_LENGTH, SUMMARY_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.learningPlan.fields.summary.required)
          : t(i18nKeys.challenges.subSections.learningPlan.fields.summary.invalid),
    };
  };

  const validateIntroduction = () => {
    const input = safeString(editedChallenge?.props.learningOutcome.introduction);
    const isValid = isValidLength(input, INTRODUCTION_MIN_LENGTH, INTRODUCTION_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.learningPlan.fields.introduction.required)
          : t(i18nKeys.challenges.subSections.learningPlan.fields.introduction.invalid),
    };
  };

  const validateLearningObjectives = () => {
    // We must test:
    // 1. The total length of the brace-delimited string does not exceed the max length, since it is stored in a single
    // field on the backend,
    // 2. Each individual objective is at least the min length.
    const isValid = () => {
      const input = safeString(editedChallenge?.props.learningOutcome.topicsCovered);
      const parsedLearningObjectives = parseBraceDelimitedString(input) || [input];

      return (
        isValidLength(input, TOPICS_COVERED_MIN_LENGTH, TOPICS_COVERED_MAX_LENGTH) &&
        parsedLearningObjectives.every((element) => {
          return isValidLength(element, TOPICS_COVERED_MIN_LENGTH, TOPICS_COVERED_MAX_LENGTH);
        })
      );
    };

    return {
      isValid,
      checkErrors: () =>
        isValid()
          ? ''
          : isEmpty(safeString(editedChallenge?.props.learningOutcome.topicsCovered))
          ? t(i18nKeys.challenges.subSections.learningPlan.fields.learningObjectives.required)
          : t(i18nKeys.challenges.subSections.learningPlan.fields.learningObjectives.constraint, {
              TOPICS_COVERED_MIN_LENGTH,
              TOPICS_COVERED_MAX_LENGTH,
            }),
    };
  };

  const validatePrerequisites = () => {
    const input = safeString(editedChallenge?.props.learningOutcome.technicalKnowledgePrerequisites);
    const isValid = isValidLength(input, PREREQUISITES_MIN_LENGTH, PREREQUISITES_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.learningPlan.fields.technicalKnowledgePrerequisites.required)
          : t(i18nKeys.challenges.subSections.learningPlan.fields.technicalKnowledgePrerequisites.invalid),
    };
  };

  const validateType = () => {
    const type = safeString(editedChallenge?.props.jamType);
    const isValid = !isEmpty(type) && Object.values(SettingType).find((item) => item === type) !== null;
    return {
      isValid: () => isValid,
      checkErrors: () => (isValid ? '' : t(i18nKeys.challenges.subSections.settings.container_1.fields.type.error)),
    };
  };

  const validateCategory = () => {
    const input = safeString(editedChallenge?.props.category);
    const isTwoWordsOrLess = (input?.split(' ') || []).length <= 2;
    const isValid = isValidLength(input, CATEGORY_MIN_LENGTH, CATEGORY_MAX_LENGTH) && isTwoWordsOrLess;
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.settings.container_1.fields.category.required)
          : t(i18nKeys.challenges.subSections.settings.container_1.fields.category.invalid),
    };
  };

  const validateDifficulty = () => {
    const isValid = !isEmpty((editedChallenge?.props.difficulty ?? '').toString());
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid ? '' : t(i18nKeys.challenges.subSections.settings.container_1.fields.difficulty.required),
    };
  };

  const validateLearningType = () => {
    const learningType = safeString(editedChallenge?.props.learningType);
    const isValid = Object.values(ChallengeLearningType).some((item) => item === learningType);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(learningType)
          ? t(i18nKeys.challenges.subSections.settings.container_1.fields.learningType.required)
          : t(i18nKeys.challenges.subSections.settings.container_1.fields.learningType.invalid),
    };
  };

  const validateServices = () => {
    const isValid = () => safeArray(editedChallenge?.props.awsServices).length >= 1;
    return {
      isValid,
      checkErrors: () =>
        isValid() ? '' : t(i18nKeys.challenges.subSections.settings.container_3.fields.awsServices.error),
    };
  };

  const validateRegions = () => {
    const isValid = () => safeArray(editedChallenge?.props.regionAllowlist).length >= 1;
    return {
      isValid,
      checkErrors: () =>
        isValid() ? '' : t(i18nKeys.challenges.subSections.settings.container_3.fields.supportedRegions.error),
    };
  };

  const validateSponsorName = () => {
    const attribute = editedChallenge?.props.sponsor?.name;
    const isValid =
      Sponsor.isEmpty(editedChallenge?.props.sponsor || new Sponsor()) ||
      isValidLength(safeString(attribute), SPONSOR_NAME_MIN_LENGTH, SPONSOR_NAME_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : attribute == null
          ? t(i18nKeys.challenges.subSections.settings.container_4.fields.sponsor.required)
          : t(i18nKeys.challenges.subSections.settings.container_4.fields.sponsor.invalid),
    };
  };

  const validateSponsorUrl = () => {
    const isValid: boolean =
      Sponsor.isEmpty(editedChallenge?.props.sponsor || new Sponsor()) ||
      isValidUrl(safeString(editedChallenge?.props.sponsor?.url));
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(safeString(editedChallenge?.props.sponsor?.url))
          ? t(i18nKeys.challenges.subSections.settings.container_4.fields.url.error)
          : t(i18nKeys.challenges.subSections.settings.container_4.fields.url.invalid),
    };
  };

  const validateSponsorLogoUrl = () => {
    const isValid: boolean =
      Sponsor.isEmpty(editedChallenge?.props.sponsor || new Sponsor()) ||
      isValidUrl(safeString(editedChallenge?.props.sponsor?.logo));
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(safeString(editedChallenge?.props.sponsor?.logo))
          ? t(i18nKeys.challenges.subSections.settings.container_4.fields.logo.error)
          : t(i18nKeys.challenges.subSections.settings.container_4.fields.logo.invalid),
    };
  };

  const validateChallengeIcon = () => {
    const isValid =
      isEmpty(safeString(editedChallenge?.props.challengeIcon)) ||
      isValidUrl(safeString(editedChallenge?.props.challengeIcon));
    return {
      isValid: () => isValid,
      checkErrors: () => (isValid ? '' : t(i18nKeys.challenges.subSections.settings.container_4.fields.icon.error)),
    };
  };

  const validateLabProvider = () => {
    const labProvider = safeString(editedChallenge?.props.defaultLabProvider);
    const isValid =
      !isEmpty(safeString(labProvider)) &&
      new Set<string>(LabProviderDefinitions.map((item) => safeString(item.value))).has(labProvider);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid ? '' : t(i18nKeys.challenges.subSections.settings.container_5.fields.labProvider.error),
    };
  };

  const validateMode = () => {
    const mode = safeString(editedChallenge?.props.mode);
    const isValid =
      !isEmpty(safeString(mode)) && new Set<string>(ModeDefinitions.map((item) => safeString(item.value))).has(mode);
    return {
      isValid: () => isValid,
      checkErrors: () => (isValid ? '' : t(i18nKeys.challenges.subSections.settings.container_5.fields.mode.error)),
    };
  };

  const validateTaskScore = (task: ChallengeTask) => {
    const isValid = Number.isInteger(task.scorePercent) && task.scorePercent >= 1 && task.scorePercent <= 100;
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : task.scorePercent == null
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.weightedScore.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.weightedScore.invalid),
    };
  };

  const validateTaskTitle = (task: ChallengeTask) => {
    const input = safeString(task.title);
    const isValid = isValidLength(input, TASK_TITLE_MIN_LENGTH, TASK_TITLE_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.title.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.title.invalid),
    };
  };

  const validateTaskDescription = (task: ChallengeTask) => {
    const input = safeString(task.description);
    const isValid = isValidLength(
      input,
      TASK_VALIDATION_DESCRIPTION_MIN_LENGTH,
      TASK_VALIDATION_DESCRIPTION_MAX_LENGTH
    );
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.task.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.task.invalid),
    };
  };

  const validateTaskBackground = (task: ChallengeTask) => {
    const input = safeString(task.background);
    const isValid = isValidLength(input, BACKGROUND_MIN_LENGTH, BACKGROUND_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.background.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.background.invalid),
    };
  };

  const validateGettingStarted = (task: ChallengeTask) => {
    const input = safeString(task.gettingStarted);
    const isValid = isValidLength(input, GETTING_STARTED_MIN_LENGTH, GETTING_STARTED_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.gettingStarted.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.gettingStarted.invalid),
    };
  };

  const validateInventory = (task: ChallengeTask) => {
    const input = safeString(task.inventory);
    const isValid = isValidLength(input, INVENTORY_MIN_LENGTH, INVENTORY_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.inventory.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.inventory.invalid),
    };
  };

  const validateServicesUsed = (task: ChallengeTask) => {
    const isValid = task.awsServicesUsed ? task.awsServicesUsed.length >= 1 : true;
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid ? '' : t(i18nKeys.challenges.subSections.tasks.subSections.fields.servicesUsed.error),
    };
  };

  const validateTaskValidationDescription = (task: ChallengeTask) => {
    const input = safeString(task.validationDescription);
    const isValid = isValidLength(
      input,
      TASK_VALIDATION_DESCRIPTION_MIN_LENGTH,
      TASK_VALIDATION_DESCRIPTION_MAX_LENGTH
    );
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.taskValidation.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.taskValidation.invalid),
    };
  };

  const validateGlobalStaticAnswer = (task: ChallengeTask) => {
    const isValidType = task.validationType === ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER;
    const isValid = !isValidType || (isValidType && !isEmpty(task.globalStaticAnswer));
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(task.globalStaticAnswer)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.globalStaticAnswer.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.globalStaticAnswer.invalid),
    };
  };

  const validateTaskValidationFunction = (task: ChallengeTask) => {
    const isValidType = task.validationType !== ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER;
    const isValid = !isValidType || (isValidType && !isEmpty(task.validationFunction));
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(task.validationFunction)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunction.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunction.invalid),
    };
  };

  const validateTaskContent = (task: ChallengeTask) => {
    const input = safeString(task.content);
    const isValid = isValidLength(input, CONTENT_MIN_LENGTH, CONTENT_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.content.required)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.content.invalid),
    };
  };

  const validateClueTitle = (clue: Clue) => {
    const input = safeString(clue.title);
    const isValid = isValidLength(input, CLUE_TITLE_MIN_LENGTH, CLUE_TITLE_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.clues.fields.titleRequired)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.clues.fields.titleInvalid),
    };
  };

  const validateClueDescription = (clue: Clue) => {
    const input = safeString(clue.description);
    const isValid = isValidLength(input, CLUE_DESCRIPTION_MIN_LENGTH, CLUE_DESCRIPTION_MAX_LENGTH);
    return {
      isValid: () => isValid,
      checkErrors: () =>
        isValid
          ? ''
          : isEmpty(input)
          ? t(i18nKeys.challenges.subSections.tasks.subSections.fields.clues.fields.descriptionRequired)
          : t(i18nKeys.challenges.subSections.tasks.subSections.fields.clues.fields.descriptionInvalid),
    };
  };

  const validateTasksScoring = (tasks: ChallengeTask[]) => {
    const isValid = tasks.map((task) => task.scorePercent).reduce((prev, next) => next + prev, 0) === 100;
    return {
      isValid: () => isValid,
      checkErrors: () => (isValid ? '' : t(i18nKeys.challenges.subSections.tasks.scoringError)),
    };
  };

  const validateField = (
    validatorHelperByField: Map<ChallengeValidationFields, InputValidationHelper>,
    errorSetterByField: Map<ChallengeValidationFields, (error: string) => void>,
    field: ChallengeValidationFields,
    setError = true
  ) => {
    const validation = validatorHelperByField.get(field);
    if (!validation) {
      throw new Error('Validation logic does not exist!');
    }
    const isValid = validation.isValid();
    const error = validation.checkErrors();
    if (setError) {
      const setErrorFn =
        errorSetterByField.get(field) ||
        (() => {
          // do nothing
        });
      setErrorFn(error);
    }
    // preProdLogger('Validated', field, 'isValid:', isValid, 'error:', error);
    return isValid;
  };

  const validateSection = (
    validatorHelperByField: Map<ChallengeValidationFields, InputValidationHelper>,
    errorSetterByField: Map<ChallengeValidationFields, (error: string) => void>,
    setErrors = false
  ): boolean => {
    const fieldIsValid: boolean[] = [];
    const fields = Array.from(errorSetterByField.keys());
    for (const key of fields) {
      fieldIsValid.push(validateField(validatorHelperByField, errorSetterByField, key, setErrors));
    }
    return fieldIsValid.reduce((prev, next) => prev && next);
  };

  const challengeSettingsValidator: (errorSetterByField: Map<ChallengeSettingsFields, (error: string) => void>) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ChallengeSettingsFields, setError?: boolean) => boolean;
  } = (errorSetterByField: Map<ChallengeSettingsFields, (error: string) => void>) => {
    const validatorHelperByField = new Map<ChallengeSettingsFields, InputValidationHelper>([
      [ChallengeSettingsFields.JAM_TYPE, validateType()],
      [ChallengeSettingsFields.CATEGORY, validateCategory()],
      [ChallengeSettingsFields.DIFFICULTY, validateDifficulty()],
      [ChallengeSettingsFields.LEARNING_TYPE, validateLearningType()],
      [ChallengeSettingsFields.AWS_SERVICES, validateServices()],
      [ChallengeSettingsFields.REGION_ALLOWLIST, validateRegions()],
      [ChallengeSettingsFields.SPONSOR_NAME, validateSponsorName()],
      [ChallengeSettingsFields.SPONSOR_URL, validateSponsorUrl()],
      [ChallengeSettingsFields.SPONSOR_LOGO, validateSponsorLogoUrl()],
      [ChallengeSettingsFields.CHALLENGE_ICON, validateChallengeIcon()],
      [ChallengeSettingsFields.SETTINGS_DEFAULT_LAB_PROVIDER, validateLabProvider()],
      [ChallengeSettingsFields.MODE, validateMode()],
    ]);

    const isValidField = (field: ChallengeSettingsFields, setError = true) => {
      return validateField(validatorHelperByField, errorSetterByField, field, setError);
    };

    const isValidSection = (setErrors = false): boolean => {
      return validateSection(validatorHelperByField, errorSetterByField, setErrors);
    };

    return {
      isValidSection,
      isValidField,
    };
  };

  const challengeLearningOutcomeValidator: (
    errorSetterByField: Map<ChallengeLearningOutcomeFields, (error: string) => void>
  ) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ChallengeLearningOutcomeFields, setError?: boolean) => boolean;
  } = (errorSetterByField: Map<ChallengeLearningOutcomeFields, (error: string) => void>) => {
    const validatorHelperByField = new Map<ChallengeLearningOutcomeFields, InputValidationHelper>([
      [ChallengeLearningOutcomeFields.SUMMARY, validateSummary()],
      [ChallengeLearningOutcomeFields.INTRODUCTION, validateIntroduction()],
      [ChallengeLearningOutcomeFields.TOPICS_COVERED, validateLearningObjectives()],
      [ChallengeLearningOutcomeFields.PRE_REQUISITES, validatePrerequisites()],
    ]);

    const isValidField = (field: ChallengeLearningOutcomeFields, setError = true) => {
      return validateField(validatorHelperByField, errorSetterByField, field, setError);
    };

    const isValidSection = (setErrors = false): boolean => {
      return validateSection(validatorHelperByField, errorSetterByField, setErrors);
    };

    return {
      isValidSection,
      isValidField,
    };
  };

  const challengeOverviewValidator: (errorSetterByField: Map<ChallengeOverviewFields, (error: string) => void>) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ChallengeOverviewFields, setError?: boolean) => boolean;
  } = (errorSetterByField: Map<ChallengeOverviewFields, (error: string) => void>) => {
    const validatorHelperByField = new Map<ChallengeOverviewFields, InputValidationHelper>([
      [ChallengeOverviewFields.CHALLENGE_ID, validateChallengeId()],
      [ChallengeOverviewFields.TITLE, validateTitle()],
      [ChallengeOverviewFields.DESCRIPTION, validateDescription()],
    ]);

    const isValidField = (field: ChallengeOverviewFields, setError = true) => {
      return validateField(validatorHelperByField, errorSetterByField, field, setError);
    };

    const isValidSection = (setErrors = false): boolean => {
      return validateSection(validatorHelperByField, errorSetterByField, setErrors);
    };

    return {
      isValidSection,
      isValidField,
    };
  };

  const challengeTaskValidator: (
    challengeTask: ChallengeTask,
    errorSetterByField: Map<ChallengeTaskFields, (error: string) => void>
  ) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ChallengeTaskFields, setError?: boolean) => boolean;
  } = (challengeTask: ChallengeTask, errorSetterByField: Map<ChallengeTaskFields, (error: string) => void>) => {
    const challenge = editedChallenge ? _.cloneDeep(editedChallenge) : new Challenge();
    const tasks = challenge.props.tasks;
    const task: ChallengeTask =
      tasks.length === 0 ? ChallengeTask.defaultChallengeTask() : tasks[challengeTask.taskNumber - 1];
    const validatorHelperByField = new Map<ChallengeTaskFields, InputValidationHelper>([
      [ChallengeTaskFields.WEIGHTED_SCORE, validateTaskScore(task)],
      [ChallengeTaskFields.TASK_TITLE, validateTaskTitle(task)],
      [ChallengeTaskFields.TASK_DESCRIPTION, validateTaskDescription(task)],
      [ChallengeTaskFields.BACKGROUND, validateTaskBackground(task)],
      [ChallengeTaskFields.GETTING_STARTED, validateGettingStarted(task)],
      [ChallengeTaskFields.INVENTORY, validateInventory(task)],
      [ChallengeTaskFields.AWS_SERVICES_USED, validateServicesUsed(task)],
      [ChallengeTaskFields.TASK_VALIDATION_DESCRIPTION, validateTaskValidationDescription(task)],
      [ChallengeTaskFields.GLOBAL_STATIC_ANSWER, validateGlobalStaticAnswer(task)],
      [ChallengeTaskFields.VALIDATION_FUNCTION, validateTaskValidationFunction(task)],
      [ChallengeTaskFields.CONTENT, validateTaskContent(challengeTask)],
    ]);

    const isValidField = (field: ChallengeTaskFields, setError = true) => {
      return validateField(validatorHelperByField, errorSetterByField, field, setError);
    };

    const isValidSection = (setErrors = false): boolean => {
      return validateSection(validatorHelperByField, errorSetterByField, setErrors);
    };

    return {
      isValidSection,
      isValidField,
    };
  };

  const challengeClueValidator: (
    clue: Clue,
    errorSetterByField: Map<ClueFields, (error: string) => void>
  ) => {
    isValidSection: (setErrors?: boolean) => boolean;
    isValidField: (field: ClueFields, setError?: boolean) => boolean;
  } = (clue: Clue, errorSetterByField: Map<ClueFields, (error: string) => void>) => {
    const validatorHelperByField = new Map<ClueFields, InputValidationHelper>([
      [ClueFields.TITLE, validateClueTitle(clue)],
      [ClueFields.DESCRIPTION, validateClueDescription(clue)],
    ]);

    const isValidField = (field: ClueFields, setError = true) => {
      return validateField(validatorHelperByField, errorSetterByField, field, setError);
    };

    const isValidSection = (setErrors = false): boolean => {
      return validateSection(validatorHelperByField, errorSetterByField, setErrors);
    };

    return {
      isValidSection,
      isValidField,
    };
  };

  const data: CreateChallengeContextValue = {
    editMode,
    toggleChallengeEditMode,
    initializeNewChallenge,
    saveEditedChallenge,
    editedChallenge,
    newChallenge,
    challengeResourcesToUpload,
    saveNewChallenge,
    handleUpdateChallengeId,
    handleUpdateChallengeProp,
    handleUpdateChallengeTask,
    handleUpdateChallengeClue,
    handleCreateChallenge,
    addChallengeTask,
    removeChallengeTask,
    challengeOverviewValidator,
    challengeLearningOutcomeValidator,
    challengeSettingsValidator,
    toolsTab,
    setToolsTab,
    helpPanelTopic,
    setHelpPanelTopic,
    makeHelpPanelHandler,
    tutorials,
    setTutorialCompleted,
    challengeTaskValidator,
    challengeClueValidator,
    validateTasksScoring,
  };

  return <CreateChallengeContext.Provider value={data}>{children}</CreateChallengeContext.Provider>;
};

const useCreateChallenge = () => {
  const context = useContext(CreateChallengeContext);
  if (context === undefined) {
    throw new Error('createChallengeContext can only be used inside CreateChallengeProvider');
  }
  return context;
};

export { CreateChallengeProvider, useCreateChallenge };
