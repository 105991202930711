export const EventType = {
  JAM: 'JAM',
  CTF: 'CTF',
  SPL: 'SPL',
  ONE_CLICK_TEST_EVENT: 'ONE_CLICK_TEST_EVENT',
  RECURRING_AUTOMATED_TEST_EVENT: 'RECURRING_AUTOMATED_TEST_EVENT',
  CAMPAIGN_GROUP: 'CAMPAIGN_GROUP',
};

export const EventTypeDescriptions = {
  JAM: 'A series of AWS challenges',
  CTF: 'A grid-style game board of Capture The Flag challenges',
  SPL: 'An event containing Self-Paced Labs',
  ONE_CLICK_TEST_EVENT: 'An event used to test a challenge',
  RECURRING_AUTOMATED_TEST_EVENT: 'An automated test event',
  CAMPAIGN_GROUP: 'An event created for the purpose of running a campaign',
};
