import React, { useContext, useState } from 'react';
import { useApi } from './api.context';
import { IJamChallengeFeedback, JamChallengeFeedback, JamEventFeedback } from '../types/JamFeedback';
import { useJamEventDetails } from './jam-event-details.context';
import { useJamChallenge } from './jam-challenge.context';

export interface JamFeedbackContextValue {
  isFetchingJamEventFeedback: boolean;
  saveJamEventFeedbackData: (params: { body: JamEventFeedback }) => Promise<{ eventId: string }>;
  jamChallengeFeedbackData?: IJamChallengeFeedback[];
  isFetchingJamChallengeFeedback: boolean;
  loadJamChallengeFeedbackData: (params: {
    challengeId: string;
    body: JamChallengeFeedback;
  }) => Promise<{ eventId: string; challengeId: string }>;
}

const JamFeedbackContext = React.createContext<JamFeedbackContextValue>({
  isFetchingJamEventFeedback: true,
  saveJamEventFeedbackData: () =>
    new Promise(() => {
      // do nothing
    }),
  jamChallengeFeedbackData: undefined,
  isFetchingJamChallengeFeedback: true,
  loadJamChallengeFeedbackData: () =>
    new Promise(() => {
      // do nothing
    }),
});

const JamFeedbackProvider: React.FC = ({ children }) => {
  const { loadJamChallengeFeedbackData: refreshFeedbackData, updateEventFeedback } = useJamChallenge();
  const [isFetchingJamEventFeedback, setIsFetchingJamEventFeedback] = useState(true);
  const [jamChallengeFeedbackData, setJamChallengeFeedbackData] = useState<IJamChallengeFeedback[]>();
  const [isFetchingJamChallengeFeedback, setIsFetchingJamChallengeFeedback] = useState(true);

  const { jamChallengeFeedbackApi } = useApi();
  const { eventName } = useJamEventDetails();

  const saveJamEventFeedbackData = async (params: { body: JamEventFeedback }): Promise<{ eventId: string }> => {
    const { body } = params;
    try {
      setIsFetchingJamEventFeedback(true);
      const response = await jamChallengeFeedbackApi.saveJamEventFeedbackData(eventName ?? '', body);
      setIsFetchingJamEventFeedback(false);
      updateEventFeedback(response.eventFeedback);
      return { eventId: eventName ?? '' };
    } catch (e: any) {
      setIsFetchingJamChallengeFeedback(false);
      // if API failed catch here.
      throw e;
    }
  };

  const loadJamChallengeFeedbackData = async (params: {
    challengeId: string;
    body: JamChallengeFeedback;
  }): Promise<{ eventId: string; challengeId: string }> => {
    const { challengeId, body } = params;
    if (!challengeId) {
      throw new Error('challengeId is required.');
    }
    try {
      const response = (await jamChallengeFeedbackApi.fetchJamChallengeFeedbackData(
        eventName ?? '',
        challengeId,
        body
      )) as unknown as IJamChallengeFeedback[];
      setJamChallengeFeedbackData(response);
      setIsFetchingJamChallengeFeedback(false);
      void refreshFeedbackData({ id: eventName });
      return { eventId: eventName ?? '', challengeId };
    } catch (e: any) {
      setIsFetchingJamChallengeFeedback(false);
      void refreshFeedbackData({ id: eventName });
      // if API failed catch here.
      throw e;
    }
  };

  const data: JamFeedbackContextValue = {
    saveJamEventFeedbackData,
    isFetchingJamEventFeedback,
    jamChallengeFeedbackData,
    loadJamChallengeFeedbackData,
    isFetchingJamChallengeFeedback,
  };
  return <JamFeedbackContext.Provider value={data}>{children}</JamFeedbackContext.Provider>;
};

const useJamFeedback = () => {
  const context = useContext(JamFeedbackContext);
  if (context === undefined) {
    throw new Error('useJamFeedback can only be used inside JamFeedbackProvider');
  }
  return context;
};

export { JamFeedbackProvider, useJamFeedback };
