import React, { ReactNode } from 'react';
import { ChallengeHotspot } from '../challenges/challengesCommon/ChallengeHotspots';
import { ChallengeSections, ChallengeTutorialInfoTopic } from '../../store/create-challenge.context';
import { TFunctionProvider } from '../common/TFunctionProvider';
import { i18nKeys } from '../../utils/i18n.utils';
import { randomId } from '../../utils/randomId';
import { HelpPanel, Icon } from '@amzn/awsui-components-react';
import { challengeAuthoringGuideUrl, challengeWritingStyleGuideUrl, jamContactEmail } from '../../utils/jam-urls';

const t = (key: string) => {
  return <TFunctionProvider>{(translate) => translate(key)}</TFunctionProvider>;
};

export const footer = (
  <div>
    <h3>
      <span>{t(i18nKeys.general.learnMore)}</span>
      <span style={{ marginLeft: '5px' }}>
        <Icon name="external" />
      </span>
    </h3>
    <ul>
      <li>
        <a href={challengeAuthoringGuideUrl} target={'_blank'} rel="noreferrer">
          {t(i18nKeys.documentation.cards.challenges.documents.challengeAuthoringGuide.label)}
        </a>
      </li>
      <li>
        <a href={challengeWritingStyleGuideUrl} target={'_blank'} rel="noreferrer">
          {t(i18nKeys.documentation.cards.challenges.documents.challengeWritingStyleGuide.label)}
        </a>
      </li>
    </ul>
  </div>
);

export const helpPanelContent = () => {
  return new Map<ChallengeTutorialInfoTopic, ReactNode>([
    // START: section headers

    [
      ChallengeSections.OVERVIEW,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.sections.overview.header)}</TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.sections.overview.content)}</TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeSections.LEARNING_OUTCOME,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.sections.learningPlan.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.sections.learningPlan.content)}
            </TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeSections.SETTINGS,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.sections.settings.header)}</TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.sections.settings.content)}</TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    // END: section headers

    // START: individual fields

    [
      ChallengeHotspot.challengeId,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.header)}</TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.content)}</TFunctionProvider>
          </p>

          <p>
            <ul>
              <li>
                <TFunctionProvider>
                  {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.restrictions.year)}
                </TFunctionProvider>
              </li>
              <li>
                <TFunctionProvider>
                  {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.restrictions.eventName)}
                </TFunctionProvider>
              </li>
              <li>
                <TFunctionProvider>
                  {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.restrictions.theme)}
                </TFunctionProvider>
              </li>
              <li>
                <TFunctionProvider>
                  {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.restrictions.alphanumeric)}
                </TFunctionProvider>
              </li>
            </ul>
          </p>

          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.examples.bad.intro)}
            </TFunctionProvider>
          </h4>
          <ol>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.examples.bad.example1)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.examples.bad.example2)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.examples.bad.example3)}
              </TFunctionProvider>
            </li>
          </ol>
          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.examples.good.intro)}
            </TFunctionProvider>
          </h4>
          <ol>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.examples.good.example1)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.challengeId.examples.good.example2)}
              </TFunctionProvider>
            </li>
          </ol>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.challengeTitle,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.header)}</TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.content)}</TFunctionProvider>
          </p>
          <ul>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.bestPractices1)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.bestPractices2)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.bestPractices3)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.bestPractices4)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.bestPractices5)}</TFunctionProvider>
            </li>
          </ul>

          <h3>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.examples)}</TFunctionProvider>
          </h3>
          <b>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.examplesHeaderColumn1)}</TFunctionProvider>
          </b>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.example1Column1)}</TFunctionProvider>
          </p>
          <b>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.examplesHeaderColumn2)}</TFunctionProvider>
          </b>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.example1Column2)}</TFunctionProvider>
          </p>
          <b>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.examplesHeaderColumn3)}</TFunctionProvider>
          </b>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.example1Column3)}</TFunctionProvider>
          </p>
          <br/>
          <b>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.examplesHeaderColumn1)}</TFunctionProvider>
          </b>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.example2Column1)}</TFunctionProvider>
          </p>
          <b>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.examplesHeaderColumn2)}</TFunctionProvider>
          </b>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.example2Column2)}</TFunctionProvider>
          </p>
          <b>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.examplesHeaderColumn3)}</TFunctionProvider>
          </b>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.title.example2Column3)}</TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.challengeDescription,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.header)}</TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.paragraph1)}</TFunctionProvider>
          </p>
          <ul>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.cpptv1)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.cpptv2)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.cpptv3)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.cpptv4)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.cpptv5)}</TFunctionProvider>
            </li>
          </ul>

          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.example)}
            </TFunctionProvider>
          </h4>
          <p>
            <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.exampleIntro)}</TFunctionProvider>
          </p>
          <cite>
            <p>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.exampleP1)}</TFunctionProvider>
            </p>
            <p>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.exampleP2)}</TFunctionProvider>
            </p>
            <ul>
              <li>
                <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.exampleTask1)}</TFunctionProvider>
              </li>
              <li>
                <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.exampleTask2)}</TFunctionProvider>
              </li>
              <li>
                <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.exampleTask3)}</TFunctionProvider>
              </li>
            </ul>
            <p>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.description.exampleP3)}</TFunctionProvider>
            </p>
          </cite>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.learningOutcomeSummary,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.summary.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.summary.content)}
            </TFunctionProvider>
          </p>
          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.summary.examplesIntro)}
            </TFunctionProvider>
          </h4>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.summary.example)}
            </TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.learningOutcomeIntroduction,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.introduction.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.introduction.content)}
            </TFunctionProvider>
          </p>
          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.introduction.examplesIntro)}
            </TFunctionProvider>
          </h4>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.introduction.example)}
            </TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.learningOutcomeTopicsCovered,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.learningObjectives.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.learningObjectives.content)}
            </TFunctionProvider>
          </p>
          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.learningObjectives.examplesIntro)}
            </TFunctionProvider>
          </h4>

          <TFunctionProvider>
            {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.learningObjectives.example)}
          </TFunctionProvider>
          <ul>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.learningObjectives.item1)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.learningObjectives.item2)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.learningObjectives.item3)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.learningObjectives.item4)}
              </TFunctionProvider>
            </li>
          </ul>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.learningOutcomeTechnicalKnowledgePrerequisites,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.preRequisites.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.preRequisites.content)}
            </TFunctionProvider>
          </p>
          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.preRequisites.examplesIntro)}
            </TFunctionProvider>
          </h4>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.learningPlan.preRequisites.example)}
            </TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.basicSettingsType,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.type.header)}</TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.type.content, { CONTACT_US: jamContactEmail })}
            </TFunctionProvider>
          </p>
          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.type.examplesIntro)}
            </TFunctionProvider>
          </h4>
          <ol>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.type.item1)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.type.item2)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.type.item3)}</TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>{(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.type.item4)}</TFunctionProvider>
            </li>
          </ol>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.basicSettingsCategory,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.category.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.category.content)}
            </TFunctionProvider>
          </p>
          <h4>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.category.examplesIntro)}
            </TFunctionProvider>
          </h4>
          <ol>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.category.item1)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.category.item2)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.category.item3)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.category.item4)}
              </TFunctionProvider>
            </li>
            <li>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.category.item5)}
              </TFunctionProvider>
            </li>
          </ol>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.basicSettingsDifficulty,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.content)}
            </TFunctionProvider>
          </p>
          <h5>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.easyHeader)}
            </TFunctionProvider>
          </h5>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.easyContent)}
            </TFunctionProvider>
          </p>
          <h5>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.mediumHeader)}
            </TFunctionProvider>
          </h5>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.mediumContent)}
            </TFunctionProvider>
          </p>
          <h5>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.hardHeader)}
            </TFunctionProvider>
          </h5>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.hardContent)}
            </TFunctionProvider>
          </p>
          <h5>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.expertHeader)}
            </TFunctionProvider>
          </h5>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.difficulty.expertContent)}
            </TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.basicSettingsLearningType,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.learningType.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.learningType.content)}
            </TFunctionProvider>
          </p>
          <h5>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.learningType.teamHeader)}
            </TFunctionProvider>
          </h5>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.learningType.teamContent)}
            </TFunctionProvider>
          </p>
          <h5>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.learningType.individualHeader)}
            </TFunctionProvider>
          </h5>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.learningType.individualContent)}
            </TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.awsSettingsServices,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.awsServicesUsed.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.awsServicesUsed.content)}
            </TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    [
      ChallengeHotspot.awsSettingsRegions,
      <div key={randomId()}>
        <HelpPanel
          footer={footer}
          header={
            <h2>
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.regionAllowlist.header)}
              </TFunctionProvider>
            </h2>
          }>
          <p>
            <TFunctionProvider>
              {(tr) => tr(i18nKeys.tutorial.infoTab.fields.settings.regionAllowlist.content)}
            </TFunctionProvider>
          </p>
        </HelpPanel>
      </div>,
    ],

    // END: individual fields
  ]);
};
