import { intersection } from 'lodash';
import { Challenge, ChallengeConfiguration } from '../types/Challenge';
import { EventBase } from '../types/Event';
import { EventLabSummary } from '../types/EventLabSummary';

const isInList =
  (list: string[]) =>
  (item: string): boolean =>
    list.includes(item);
const isNotInList =
  (list: string[]) =>
  (item: string): boolean =>
    !list.includes(item);
const removeAll = (source: string[], list: string[]) => source.filter(isNotInList(list));
const includesAny = (source: string[], list: string[]) => list.some(isInList(source));

/**
 * Get the list of regions that are Allowlisted and not Denylisted for the given Allowlist/Denylist of regions.
 *
 * @param allowlist
 */
export const getAllowedRegions = (allowlist?: string[], denylist?: string[]): string[] => {
  return removeAll(allowlist || [], denylist || []);
};

/**
 * Get the list of regions allowed for a given event by cross-referencing the Allowlist/Denylist and falling back
 * to the list of all supported regions if the event Allowlist is empty or undefined/null.
 *
 * @param event
 * @param challengeConfiguration - ChallengeConfiguration from which the list of supported lab regions is derived.
 */
export const getAllowedRegionsForEvent = (
  event: EventBase | EventLabSummary,
  challengeConfiguration: ChallengeConfiguration
): string[] => {
  // fall back to the list of supported regions for the Allowlist
  const allowlist =
    event?.regionAllowlist?.length > 0 ? event.regionAllowlist : challengeConfiguration.supportedLabRegionIds;

  const denylist = event?.regionDenylist;
  return getAllowedRegions(allowlist, denylist);
};

/**
 * Function to get the set of allowed regions for an event/challenge pair as list of regionIds,
 * computed as the intersection of their allowed regions.
 *
 * @param event Event to check for allowedRegions
 * @param challenge Challenge to check for allowedRegions
 * @param challengeConfiguration Challenge configuration to check for allowedRegions
 * @returns intersection of allowed regions from all three parameters
 */
export const getAllowedRegionsForEventChallenge = (
  event: EventBase,
  challenge: Challenge,
  challengeConfiguration: ChallengeConfiguration
): string[] => {
  const allowedRegions = event.getAllowedRegions(challengeConfiguration);
  return intersection(allowedRegions, challenge.props?.allowedRegions);
};

/**
 * Checks the event and challenge to see if the region is supported and not excluded by any denylist
 *
 * @param event Event to check for supported regions
 * @param challenge Challenge to check for supported regions
 * @returns boolean indicating event supports challenge
 */
export const doesEventSupportChallenge = (event: EventBase | EventLabSummary, challenge: Challenge) => {
  const { regionAllowlist: eventInclude, regionDenylist: eventExclude } = event;
  const {
    props: { regionAllowlist: challengeInclude, regionDenylist: challengeExclude },
  } = challenge;

  // if challenge has no Allowlist regions, then it is not supported by any events
  if (challengeInclude.length < 1) {
    return false;
  }

  if (eventInclude.length > 0) {
    let supportedRegions = [...eventInclude];
    // remove regions listed in the event Denylist
    if (eventExclude.length > 0) {
      supportedRegions = removeAll(supportedRegions, eventExclude);
    }
    // remove regions listed in the challenge Denylist
    if (challengeExclude.length > 0) {
      supportedRegions = removeAll(supportedRegions, challengeExclude);
    }

    // now check if any of the challenge Allowlisted regions
    // exist in the remaining list of supported regions.
    return includesAny(supportedRegions, challengeInclude);
  } else if (eventExclude.length > 0) {
    let supportedRegions = [...challengeInclude];

    // remove regions listed in the event Denylist
    supportedRegions = removeAll(supportedRegions, eventExclude);

    // now check if any supported regions are left
    return supportedRegions.length > 0;
  }

  // if we got here, then the event has neither a Allowlist nor a Denylist
  // so the challenge is supported by default, since this implies all regions
  // are supported
  return true;
};
