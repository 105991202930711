import { Wizard } from '@amzn/awsui-components-react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Event } from '../../types/Event';
import { EVENT_DETAILS_ROUTES } from '../../routes';
import { useApi } from '../../store/api.context';
import { useEditEvent } from '../../store/edit-event.context';
import { useSplitPanel } from '../../store/split-panel.context';
import { useToolPanel } from '../../store/tool-panel.context';
import { i18nKeys } from '../../utils/i18n.utils';
import { ConfirmModal } from '../common/ConfirmModal';
import TeamSettings from './eventDetailsSections/Summary/TeamSettings';
import AttendanceAndTesting from './newEventSteps/AttendanceAndTesting';
import NewEventChallenges from '../common/CampaignEventComponents/NewEventChallenges';
import NewEventDetails from './newEventSteps/NewEventDetails';
import OwnersAndPermissions from './newEventSteps/OwnersAndPermissions';
import { isEmpty } from '../../utils/list.utils';
import _ from 'lodash';
import { useFlashbars } from '../../store/flashbar.context';
import ReviewAndCreate from './newEventSteps/ReviewAndCreate';
import { preProdLogger } from '../../utils/log.utils';
import { JamEventRequest } from '../../types/Event';
import { fromPlainObject } from '../../utils/mapper.utils';
import { useChallenges } from '../../store/challenge.context';
import JamSpinner from '../common/JamSpinner';
import { customEventTrigger } from '../analytics/createEventTrigger';
import { NewEventSteps } from './EventModel';
import { useQuery } from '@/src/hooks/useQuery';

type NewEventProps = { paramLoadingDelayMS?: number };

const NewEvent: React.FC<NewEventProps> = ({ paramLoadingDelayMS = 500 }) => {
  const { t } = useTranslation();
  const { eventsApi } = useApi();
  const { addErrorFlashbar, clearFlashbars } = useFlashbars();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const { newEventMode, toggleNewEvent, newEditedEvent, destroyEdits, populateWithUrlParams } = useEditEvent();
  const { toggleShowSplitPanel, toggleSplitPanel, showSplitPanel } = useSplitPanel();
  const { toggleShowToolPanel, toggleToolPanel, showToolPanel } = useToolPanel();
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [notifyUsersAdded, setNotifyUsersAdded] = useState(false);
  const [populatedFields, setPopulatedFields] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [doneDelayBeforeParamLoading, doneDelayBeforeParamLoadingSet] = useState(false); 
  const history = useHistory();
  const params = useQuery();
  const { challengeWrappers, getChallenges } = useChallenges();
  const [preloadingChallenges] = useState(false);
  const timeoutRef = useRef(0);

  useEffect(() => {
    if (!newEventMode) {
      toggleNewEvent();
    }
    // TODO: This component has a race condition between this and it's children which can make the
    // params coming from the cloned event be overriden. This delay gives time for the children to
    // settle their state before adding the data coming from the cloned event, but a proper fix would
    // be to centraliza access to the new event object on this component so there's no risk of race
    // conditions
    // https://i.amazon.com/issues/JAM-12289
    timeoutRef.current = setTimeout(() => doneDelayBeforeParamLoadingSet(true), paramLoadingDelayMS);
    return () => clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    if (!challengeWrappers) {
      void getChallenges(false, false, true);
    }
  }, [challengeWrappers]);

  useEffect(() => {
    if (doneDelayBeforeParamLoading && newEventMode && params && challengeWrappers && challengeWrappers.length > 0) {
      
      const fieldsPopulated = populateWithUrlParams(params);
      if (!isEmpty(fieldsPopulated)) {
        setPopulatedFields(fieldsPopulated);
        setNotifyUsersAdded(true);
      }
    }
  }, [newEventMode, challengeWrappers, doneDelayBeforeParamLoading]);

  const handleCancel = () => {
    destroyEdits();
    history.push('/my-events');
  };

  const promptModal = () => {
    customEventTrigger('click', 'Cancel Event Create', `${window.location.href}`, 'Cancel Event Create', {});
    setConfirmCancelVisible(true);
  };

  let isCurrentSectionValid: () => Promise<boolean>;
  const validationHandler = (validateSection: () => Promise<boolean>) => {
    isCurrentSectionValid = validateSection;
  };

  const handleCreateEvent = () => {
    if (newEditedEvent) {
      customEventTrigger('submit', 'Submit Event', window.location.href, 'Actions - Submit Event', {});
      setLoading(true);
      const jamEventRequest = fromPlainObject(newEditedEvent, JamEventRequest) as JamEventRequest;

      eventsApi
        .createJamEventRequest(jamEventRequest)
        .then((res: Event) => {
          // Update event tags if needed
          if (res && !_.isEqual(res.tags, newEditedEvent.tags)) {
            res.tags = newEditedEvent.tags;
            eventsApi.updateTags(res).catch((err) => {
              preProdLogger(err);
            });
          }
          history.replace(EVENT_DETAILS_ROUTES.Summary.resolve(res?.name));
          destroyEdits();
          setLoading(false);
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          setLoading(false);
          preProdLogger(err);
        });
    }
  };

  const handleActiveStepChange = (index: number) => {
    // Allow user to go back to previous steps in-case they wish to change something
    if (index < activeStepIndex) {
      setActiveStepIndex(index);
      return;
    }
    isCurrentSectionValid()
      .then((isValid) => {
        if (isValid) {
          handleActiveStepIndex(index);
        }
      })
      .catch((error) => {
        preProdLogger('Could not complete validation.', error);
      });
  };

  const handleActiveStepIndex = (stepIndex: number) => {
    customEventTrigger(
      'click',
      'Event Create Next/Previous Button',
      window.location.href,
      'Event Create Next/Previous Button',
      {}
    );
    window.scrollTo(0, 0);
    const previousStep = stepsDictionary[(stepIndex - 1) as unknown as NewEventSteps];
    // Check for validation errors
    if (previousStep?.isValid && !previousStep.isValid() && previousStep.getErrors) {
      const errorList = previousStep.getErrors();
      clearFlashbars();
      requestAnimationFrame(() => {
        addErrorFlashbar(t(i18nKeys.newEvent.errors.message, { errors: errorList.join(', ') }));
      });
    } else {
      // If not on challenge step, hide challenge selection aid
      // Make sure to update this if we ever add additional steps or uncover a better way to track the activeStepIndex per step
      if (stepIndex !== NewEventSteps.CHALLENGES && (showSplitPanel || showToolPanel)) {
        if (showSplitPanel) {
          toggleShowSplitPanel(false);
          toggleSplitPanel(false);
        }
        if (showToolPanel) {
          toggleShowToolPanel(false);
          toggleToolPanel(false);
        }
      }
      setActiveStepIndex(stepIndex);
    }
  };

  /**
   * Add steps here to keep active step number inline with wizard
   */
  const stepsDictionary: {
    [key in NewEventSteps]: {
      title: string;
      content: ReactNode;
      isValid?: () => boolean;
      getErrors?: () => string[];
    };
  } = {
    [NewEventSteps.EVENT_DETAILS]: {
      title: t(i18nKeys.newEvent.steps.eventDetails),
      content: newEditedEvent && <NewEventDetails validationHandler={validationHandler} target={newEditedEvent} />,
      isValid: () => {
        return (
          !_.isEmpty(newEditedEvent?.title) &&
          !_.isEmpty(newEditedEvent?.audienceType) &&
          !_.isEmpty(newEditedEvent?.startDate) &&
          !_.isEmpty(newEditedEvent?.endDate)
        );
      },
      getErrors: () => {
        const errors: string[] = [];
        if (
          _.isEmpty(newEditedEvent?.title) &&
          _.isEmpty(newEditedEvent?.audienceType) &&
          _.isEmpty(newEditedEvent?.startDate) &&
          _.isEmpty(newEditedEvent?.endDate)
        ) {
          errors.push(t(i18nKeys.newEvent.errors.allFieldsEmpty));
        } else {
          if (_.isEmpty(newEditedEvent?.title)) {
            errors.push(t(i18nKeys.newEvent.errors.title));
          }
          if (_.isEmpty(newEditedEvent?.audienceType)) {
            errors.push(t(i18nKeys.newEvent.errors.audience));
          }
          if (_.isEmpty(newEditedEvent?.startDate)) {
            errors.push(t(i18nKeys.newEvent.errors.startDate));
          }
          if (_.isEmpty(newEditedEvent?.endDate)) {
            errors.push(t(i18nKeys.newEvent.errors.duration));
          }
        }
        return errors;
      },
    },
    [NewEventSteps.ATTENDANCE_AND_TESTING]: {
      title: t(i18nKeys.newEvent.steps.attendanceAndTesting),
      content: newEditedEvent && <AttendanceAndTesting target={newEditedEvent} validationHandler={validationHandler} />,
      isValid: () => {
        return (
          !_.isEmpty(newEditedEvent?.minExpectedParticipants) && !_.isEmpty(newEditedEvent?.maxExpectedParticipants)
        );
      },
    },
    [NewEventSteps.TEAM_SETTINGS]: {
      title: t(i18nKeys.newEvent.steps.teamAssignmentSettings),
      content: newEditedEvent && <TeamSettings target={newEditedEvent} validationHandler={validationHandler} />,
      isValid: () => {
        return (
          newEditedEvent?.formTeamsMinsBeforeEventStart != null && newEditedEvent?.formTeamsMinsBeforeEventStart >= 0
        );
      },
      getErrors: () => {
        const errors: string[] = [];
        if (!newEditedEvent?.formTeamsMinsBeforeEventStart || newEditedEvent?.formTeamsMinsBeforeEventStart < 0) {
          errors.push(t(i18nKeys.newEvent.errors.teamFormingStartTime));
        }
        return errors;
      },
    },
    [NewEventSteps.OWNERS_AND_PERMISSIONS]: {
      title: t(i18nKeys.newEvent.steps.ownersAndPermissions),
      content: newEditedEvent && <OwnersAndPermissions target={newEditedEvent} validationHandler={validationHandler} />,
    },
    [NewEventSteps.CHALLENGES]: {
      title: t(i18nKeys.newEvent.steps.challenges),
      content: newEditedEvent && <NewEventChallenges target={newEditedEvent} validationHandler={validationHandler} />,
      isValid: () => {
        return !_.isEmpty(newEditedEvent?.challengeDescriptors);
      },
      getErrors: () => {
        const errors: string[] = [];
        if (_.isEmpty(newEditedEvent?.challengeDescriptors)) {
          errors.push(t(i18nKeys.events.eventDetails.messages.errors.selectChallenges));
        }
        return errors;
      },
    },
    [NewEventSteps.REVIEW_AND_CREATE]: {
      title: t(i18nKeys.newEvent.steps.reviewAndCreate),
      content: newEditedEvent && <ReviewAndCreate target={newEditedEvent} navigate={handleActiveStepIndex} />,
    },
  };

  return (
    <React.Fragment>
      <ConfirmModal
        visible={confirmCancelVisible}
        message={<React.Fragment>{t(i18nKeys.newEvent.modal.message)}</React.Fragment>}
        cancelBtnLabel={t(i18nKeys.newEvent.modal.cancel)}
        confirmBtnLabel={t(i18nKeys.newEvent.modal.confirm)}
        onConfirm={handleCancel}
        onCancel={() => setConfirmCancelVisible(false)}
      />
      <ConfirmModal
        title={t(i18nKeys.events.templateUrls.modalTitle)}
        visible={notifyUsersAdded}
        message={
          <React.Fragment>
            {t(i18nKeys.events.templateUrls.message)}
            <div>
              <ul>
                {populatedFields.map((f) => (
                  <li key={f}>{f}</li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        }
        confirmBtnLabel={t(i18nKeys.events.templateUrls.modalOk)}
        onConfirm={() => setNotifyUsersAdded(false)}
        onDismiss={() => setNotifyUsersAdded(false)}
      />
      {!preloadingChallenges && (
        <Wizard
          className="mb-12"
          onCancel={() => promptModal()}
          i18nStrings={{
            stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
            cancelButton: t(i18nKeys.general.cancel),
            previousButton: t(i18nKeys.general.previous),
            nextButton: t(i18nKeys.general.next),
            submitButton: t(i18nKeys.events.eventDetails.buttons.createEventRequest),
            optional: t(i18nKeys.general.optional),
          }}
          activeStepIndex={activeStepIndex}
          steps={Object.keys(stepsDictionary).map((stepKey: string) => {
            return {
              title: stepsDictionary[stepKey as unknown as NewEventSteps].title,
              content: stepsDictionary[stepKey as unknown as NewEventSteps].content,
            };
          })}
          onNavigate={({ detail }) => {
            handleActiveStepChange(detail.requestedStepIndex);
          }}
          isLoadingNextStep={loading}
          onSubmit={handleCreateEvent}
          data-testid="newEventWizard"
        />
      )}
      {preloadingChallenges && <JamSpinner />}
    </React.Fragment>
  );
};
export default NewEvent;
