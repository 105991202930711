/* eslint-disable */
import {JamConstants} from './jam-constants';
import {Patterns} from "./patterns";
import {ChallengeTypes} from "./challenge-types";
import {AwsRegions, Region, RegionMap} from "./aws-regions";
import {SupportedLabRegions} from "./supported-lab-regions";
import {AwsServices} from './aws-services';
import {LanguageCodeOption, LanguageCodes} from './language-codes';
import {ErrorCodes} from "./error-codes";
import {AuthGroup} from "./auth-groups";
import {AuthRole} from "./auth-roles";
import {MapLocations} from "./map-locations";
import {MapLocationOrder} from "./map-location-order";
import {FeedbackQuestions} from "./feedback-questions";
import {ChallengeMode, ChallengeModes} from "./challenge-modes";
import {CastleDefenseProvider, CastleDefenseProviders} from "./castle-defense-providers";
import {EventType, EventTypeDescriptions} from "./event-type";
import {EventAudienceType, EventAudienceTypeDescriptions} from "./event-audience-type";

export * from './time';

// noinspection JSUnusedGlobalSymbols
export {
  // enums
  AuthGroup,
  AuthRole,
  ErrorCodes,
  LanguageCodeOption,
  Region,
  RegionMap,
  // constants
  AwsServices,
  AwsRegions,
  ChallengeTypes,
  ChallengeModes,
  CastleDefenseProviders,
  EventAudienceType,
  EventAudienceTypeDescriptions,
  EventType,
  EventTypeDescriptions,
  FeedbackQuestions,
  LanguageCodes,
  MapLocations,
  MapLocationOrder,
  Patterns,
  SupportedLabRegions
};
  export type {
    // types
    ChallengeMode,
    CastleDefenseProvider
  };

// noinspection JSUnusedGlobalSymbols
export default JamConstants;
