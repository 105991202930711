/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { ChallengeListItem } from '../../types/Challenge';
import { TFunctionProvider } from '../common/TFunctionProvider';
import { i18nKeys } from '../../utils/i18n.utils';
import { Link as ReactRouterLink } from 'react-router-dom';
import { CHALLENGE_DETAILS_ROUTES } from '../../routes';
import React from 'react';
import { compareValues } from '../common/Challenges/browse-challenges-list-config';
import { getTableFilterMatcher, TableFilterMatcher } from '../../utils/table.utils';
import { safeString } from '../../utils/string.utils';
import { getFormattedDate } from '../../utils/time.utils';
import { getReviewStatus } from './challengeDetailSections/challengeRevisions/revision-list-config';
import { useTranslation } from 'react-i18next';
import { ReviewChallengesListColumn } from './ChallengeModel';

export const filteringFunction = (item: ChallengeListItem, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item?.props.title) ||
    matcher.isMatch(item?.props.barRaiser?.emailAddress || '')
  );
};

// TODO: Add custom JSX for approved/needs work

export const COLUMN_DEFINITIONS = () => {
  const { i18n } = useTranslation();
  return [
    {
      id: ReviewChallengesListColumn.CHALLENGE_TITLE,
      sortingField: 'challengeTitle',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.title || null, b?.props.title || null);
      },
      minWidth: 200,
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.reviewChallenges.columns.challengeTitle)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div>
          {item && (
            <ReactRouterLink to={CHALLENGE_DETAILS_ROUTES.Summary.resolve(item.challengeId ?? '')}>
              {item.props.title}
            </ReactRouterLink>
          )}
        </div>
      ),
    },
    {
      id: ReviewChallengesListColumn.DATE_CREATED,
      sortingField: 'createdDate',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.createdDate || null, b?.createdDate || null);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.reviewChallenges.columns.createdDate)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div>{item?.createdDate ? getFormattedDate(new Date(item.createdDate), i18n.language) : '-'}</div>
      ),
      allowLineWrap: true,
    },
    {
      id: ReviewChallengesListColumn.BR_STATUS,
      sortingField: 'barReviewerReviewStatus',
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.reviewChallenges.columns.barReviewerStatus)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => getReviewStatus(item.barRaiserReviewStatus),
    },
    {
      id: ReviewChallengesListColumn.ASSIGNED_BR,
      sortingField: 'barRaiser',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.barRaiser || null, b?.props.barRaiser || null);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.reviewChallenges.columns.assignedBR)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div className="link">{safeString(item.props.barRaiser?.emailAddress, '-')}</div>
      ),
    },
  ];
};
