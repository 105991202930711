import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { i18nKeys } from '../../../utils/i18n.utils';
import { useCookieConsent } from '../../../store/cookie-consent.context';
import { isPreProd, isDev } from '../../../utils/env.utils';
import { useState } from 'react';
import { RoleSpoofingModal } from '../../spoofed-groups/RoleSpoofingModal';
import { Badge, Icon, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { getSpoofedGroups } from '../../../utils/spoofed-groups';
import { useUser } from '../../../store/user.context';
import { privacy, siteTerms } from '../../../utils/jam-urls';
import { config } from '../../../config/app-config';
import { MyJamFooter } from '../../event-templates-catalog/molecules/MyJamFooter/MyJamFooter';
import { useLocation } from 'react-router-dom';
import { jamChallengesRegex, jamChallengeTaskRegex, jamChatRegex } from '@/src/utils/myjam.utils';
import { useJamLeaderboard } from '@/src/store/jam-leaderboard.context';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { customizeCookies } = useCookieConsent();
  const { isLoggedIn } = useUser();
  const { mouseMoved } = useJamLeaderboard();
  const [showRoleSpoofingModal, setShowRoleSpoofingModal] = useState<boolean>(false);
  const location = useLocation();
  const { pathname } = location;

  if (!mouseMoved) {
    return null;
  }

  if (jamChallengesRegex.test(pathname) || jamChallengeTaskRegex.test(pathname) || jamChatRegex.test(pathname)) {
    return <MyJamFooter />;
  }

  return (
    <footer id="footer" style={{ position: 'fixed', width: '100%', zIndex: '1000' }}>
      <div style={{ textAlign: 'center', paddingRight: '2rem' }}>
        <div className="inline">
          <SpaceBetween direction="horizontal" size="s">
            {isPreProd() && <Badge className="inline">{config.stageName.toUpperCase()}</Badge>}
            <Link className="mr-8" href={privacy} target="_blank" rel="noopener noreferrer">
              {t(i18nKeys.footer.privacy)}
            </Link> |
            <Link href={siteTerms} target="_blank" rel="noopener noreferrer">
              {t(i18nKeys.footer.siteTerms)}
            </Link> |
            <span className="cookie-preferences">
              <button className="button-link" onClick={customizeCookies}>
                {t(i18nKeys.footer.cookie)}
              </button>
            </span> |
            <div id={'copyright'} className="mt-4">
              {t(i18nKeys.footer.copyright, { name: `©${new Date().getFullYear()} Amazon.com` })}
            </div>
          </SpaceBetween>
        </div>
        {isDev() && isLoggedIn && (
          <span className="role-spoofing" onClick={() => setShowRoleSpoofingModal(true)}>
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              <Icon name="settings" variant={getSpoofedGroups().length > 0 ? 'warning' : 'normal'} size="small" />
            }
          </span>
        )}
        {showRoleSpoofingModal && <RoleSpoofingModal handleCloseModal={() => setShowRoleSpoofingModal(false)} />}

      </div>
      <div id="shortbread-container" />
    </footer>
  );
};
