import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Alert,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { newCampaignRoute } from '../../routes';
import { useCampaigns } from '../../store/campaigns.context';
import { UserPreferenceKeys } from '../../store/user.context';
import { DateRangeFilter } from '../../types/common';
import { YYYY_MM_DD } from '../../utils/event-time.utils';
import { i18nKeys } from '../../utils/i18n.utils';
import { preProdLogger } from '../../utils/log.utils';
import { paginationLabels } from '../../utils/table.utils';
import { DateRangeFilterInput } from '../common/DateRangeFilterInput/DateRangeFilterInput';
import { TableEmptyState } from '../common/TableEmptyState';
import { TableHeader } from '../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './campaign-list.config';
import { DateTimeKeys } from '../common/CommonModel';

const CampaignList: React.FC = () => {
  const { t } = useTranslation();
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeFilter>({
    start: moment().subtract(1, 'year').format(YYYY_MM_DD),
    end: null,
  });
  const { loadCampaigns, campaigns } = useCampaigns();
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
  });

  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const history = useHistory();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    campaigns || [],
    {
      filtering: {
        filteringFunction,
        empty: <TableEmptyState title={t(i18nKeys.campaigns.labels.noCampaignsFound)} />,
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  /**
   * Sets the dateRangeFilter with desired selection
   *
   * @param startOrEnd Desired date range filter to set
   * @param date date selected
   */
  const handleDateSelection = (startOrEnd: DateTimeKeys, date: any): void => {
    const dateRangeFilterObject: DateRangeFilter = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      start: startOrEnd === DateTimeKeys.START ? moment(date).format(YYYY_MM_DD) : dateRangeFilter.start,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      end: startOrEnd === DateTimeKeys.END ? moment(date).format(YYYY_MM_DD) : dateRangeFilter.end,
    };

    setDateRangeFilter(dateRangeFilterObject);
  };

  const addDateSelectionToParams = () => {
    let newParamPath = `dateRangeStart=${dateRangeFilter.start}`;
    if (dateRangeFilter.end) {
      newParamPath = newParamPath.concat(`&dateRangeEnd=${dateRangeFilter.end}`);
    }
    history.push({ search: newParamPath });
  };

  const handleLoadParams = () => {
    const params = location.search.split('&');
    params.forEach((param) => {
      // Handles the case of a dateRangeEnd being passed to params
      if (param.search('dateRange') > -1) {
        const dateSplit = param.split('dateRange')[1].split('=');
        const startOrEnd: DateTimeKeys = dateSplit[0].toLowerCase() as DateTimeKeys;
        handleDateSelection(startOrEnd, dateSplit[1]);
      }
    });
  };

  const handleSetPreferences = (newPreferences: CollectionPreferencesProps.Preferences) => {
    if (newPreferences.pageSize) {
      sessionStorage.setItem(UserPreferenceKeys.PAGE_SIZE, newPreferences?.pageSize?.toString() || '');
    }
    setPreferences(newPreferences);
  };

  const handleOnLoadPreferences = () => {
    const pageSize = Number(sessionStorage.getItem(UserPreferenceKeys.PAGE_SIZE));
    const displayTime: string | null = sessionStorage.getItem(UserPreferenceKeys.DISPLAY_TIME);
    const userPreferences = {
      pageSize: pageSize ? pageSize : preferences.pageSize,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      custom: displayTime ? displayTime : preferences.custom,
    };
    setPreferences(userPreferences);
  };

  useEffect(() => {
    handleOnLoadPreferences();
    handleLoadParams();
    loadCampaigns(dateRangeFilter)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        preProdLogger('Error getting campaigns', err.message);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    addDateSelectionToParams();
    loadCampaigns(dateRangeFilter)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        preProdLogger('Error getting campaigns', err.message);
      });
  }, [dateRangeFilter]);

  return (
    <Table
      {...collectionProps}
      resizableColumns
      variant="full-page"
      stickyHeader
      header={
        <SpaceBetween direction="vertical" size="s">
          <Alert type="info">{t(i18nKeys.campaigns.messages.campaignDescription)}</Alert>
          <TableHeader
            totalItems={campaigns.length}
            title={t(i18nKeys.campaigns.title)}
            actionButtons={
              <SpaceBetween direction={isMobile ? 'vertical' : 'horizontal'} size="xs">
                {!isMobile && (
                  <DateRangeFilterInput
                    direction="horizontal"
                    dateRangeFilter={dateRangeFilter}
                    handleDateSelection={handleDateSelection}
                    className="date-selector-action-button"
                  />
                )}
                {
                  <Button variant="primary" onClick={() => history.replace(newCampaignRoute.path)}>
                    {t(i18nKeys.campaigns.buttons.create)}
                  </Button>
                }
              </SpaceBetween>
            }
          />
        </SpaceBetween>
      }
      columnDefinitions={COLUMN_DEFINITIONS(preferences)}
      items={items}
      loading={loading}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      preferences={
        <CollectionPreferences
          title={t(i18nKeys.campaigns.labels.preferences.title)}
          onConfirm={({ detail }) => handleSetPreferences(detail)}
          confirmLabel={t(i18nKeys.general.confirm)}
          cancelLabel={t(i18nKeys.general.cancel)}
          preferences={preferences}
          pageSizePreference={{
            title: t(i18nKeys.campaigns.labels.preferences.pageSize.label),
            options: [
              { value: 10, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 10 }) },
              { value: 20, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 20 }) },
              { value: 50, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 50 }) },
              { value: 100, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 100 }) },
            ],
          }}
        />
      }
      filter={
        <SpaceBetween direction={isMobile ? 'vertical' : 'horizontal'} size="m">
          {isMobile && (
            <DateRangeFilterInput
              direction="vertical"
              dateRangeFilter={dateRangeFilter}
              handleDateSelection={handleDateSelection}
              className="date-selector-filter"
            />
          )}
          <TextFilter
            className="text-filter"
            filteringPlaceholder={t(i18nKeys.campaigns.labels.searchCampaigns)}
            {...filterProps}
            countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
            filteringAriaLabel={t(i18nKeys.campaigns.labels.searchCampaigns)}
          />
        </SpaceBetween>
      }
    />
  );
};
export default CampaignList;
