import { StatusColors } from '../components/common/CommonModel';

export const colors = {
  green: '#006400',
  lightGreen: '#33b500',
  yellow: '#dacf16',
  gold: '#ffc91d',
  orange: '#ff8b2f',
  blue: '#1065cb',
  royalBlue: '#023c81',
  violet: '#694fe1',
  lightGrey: '#aaa',
  grey: '#333',
  red: '#d70000',
  white: '#fff',
  black: '#000',
};

export const getComplimentaryFontColor = (color: string) => {
  switch (color) {
    case colors.yellow:
    case colors.gold:
    case colors.orange:
    case colors.white:
      return colors.black;
    default:
      return colors.white;
  }
};

export const labStatusColors = {
  NOT_READY: colors.yellow,
  PREPARING_RESOURCES: colors.gold,
  ON_HOLD: colors.orange, // pseudo-status
  UNASSIGNED: colors.lightGreen,
  ASSIGNED: StatusColors.green,
  RESTARTED: colors.blue,
  COMPLETED: colors.violet,
  TIMED_OUT: colors.lightGrey,
  TERMINATED: colors.lightGrey,
  INVALID: colors.red,
  INELIGIBLE: colors.grey,
};

export const labProviderStatusColors = {
  CREATED: StatusColors.blue,
  INITIALIZING: StatusColors.blue,
  DEPLOYING: StatusColors.blue,
  PROVISIONING: StatusColors.blue,
  UNDEPLOYING: StatusColors.blue,
  UNDEPLOYED: StatusColors.blue,
  DEPLOYED: StatusColors.green,
  READY: StatusColors.green,
  RUNNING: StatusColors.green,
  TERMINATING: StatusColors.grey,
  TERMINATED: StatusColors.grey,
  EXPIRED: StatusColors.grey,
  ENDED: StatusColors.grey,
  FAILED: StatusColors.red,
  ERROR: StatusColors.red,
};
