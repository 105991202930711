/**
 * NOTE: the values of the below enum MUST stay in sync with the enum names
 * of the enum com.amazonaws.awsjam.model.data.enums.SkillBuilderSubscription (AWSJam_GameBackend).
 * AWS Builder Labs is the public-facing name for Event Horizon.
 */
export enum SkillBuilderSubscription {
  TEAM = 'TEAM',
  INDIVIDUAL = 'INDIVIDUAL',
  AWS_BUILDER_LABS = 'AWS_BUILDER_LABS',
}

/**
 * Get all the subscription names.
 * These names are used across the frontend and the backend to identify subscriptions and correspond to the Java enum names.
 */
export const AllSkillBuilderSubscriptionNames = Object.values(SkillBuilderSubscription);

/**
 * SkillBuilderSubscription names mapped to lowercase.
 */
export const SkillBuilderSubscriptionAlias = Object.entries(SkillBuilderSubscription).reduce((prev, curr) => {
  return {
    ...prev,
    [curr[0]]: curr[1].toLowerCase(),
  };
}, {}) as { [key in keyof typeof SkillBuilderSubscription]: string };
