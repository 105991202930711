import { ExpandableSection, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useUser } from '../../../store/user.context';
import { Campaign } from '../../../types/Campaign';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { DomainList } from '../DomainList';
interface AccessControlProps {
  target?: Event | Campaign;
}

const AccessControl: React.FC<AccessControlProps> = ({ target }) => {
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign } = useEditCampaign();
  const { user, isLoggedIn } = useUser();
  const { t } = useTranslation();
  const isEvent = target instanceof Event;
  const canEdit = campaignEditMode || editMode;
  const targetAccessControl = isEvent ? target : target?.campaignSettings;
  let participantControlDisabled = false;
  let facilitatorControlDisabled = false;
  if (user && isLoggedIn) {
    participantControlDisabled = !target?.canEditAttribute('participantDomainAllowlist', user) || false;
    facilitatorControlDisabled = !target?.canEditAttribute('participantDomainAllowlist', user) || false;
  }
  const addDomain = (domains: string[], newDomain: string, action: EditEventActions | EditCampaignActions) => {
    domains.push(newDomain);
    if (isEvent) {
      handleUpdateEditEvent(action, [...domains]);
    } else {
      handleUpdateEditCampaign(action, [...domains]);
    }
  };

  const removeDomain = (
    domains: string[],
    removeDomainIndex: number,
    action: EditEventActions | EditCampaignActions
  ) => {
    domains.splice(removeDomainIndex, 1);
    if (isEvent) {
      handleUpdateEditEvent(action, [...domains]);
    } else {
      handleUpdateEditCampaign(action, [...domains]);
    }
  };

  const editedTarget = isEvent ? editedEvent : editedCampaign?.campaignSettings;

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.accessControl)}</Header>}>
      {canEdit && (
        <SpaceBetween direction="vertical" size="s">
          <DomainList
            label={t(i18nKeys.events.eventDetails.labels.accessControl.registrationDomain.label)}
            description={t(i18nKeys.events.eventDetails.labels.accessControl.registrationDomain.description)}
            domains={editedTarget?.participantDomainAllowlist || []}
            addDomain={addDomain}
            removeDomain={removeDomain}
            disabled={participantControlDisabled}
            eventAction={
              isEvent ? EditEventActions.PARTICIPANT_DOMAIN_ALLOWLIST : EditCampaignActions.PARTICIPANT_DOMAIN_ALLOWLIST
            }
          />
          <DomainList
            label={t(i18nKeys.events.eventDetails.labels.accessControl.facilitatorDomain.label)}
            description={t(i18nKeys.events.eventDetails.labels.accessControl.facilitatorDomain.description)}
            domains={editedTarget?.facilitatorDomainAllowlist || []}
            addDomain={addDomain}
            removeDomain={removeDomain}
            disabled={facilitatorControlDisabled}
            eventAction={
              isEvent ? EditEventActions.FACILITATOR_DOMAIN_ALLOWLIST : EditCampaignActions.FACILITATOR_DOMAIN_ALLOWLIST
            }
          />
        </SpaceBetween>
      )}
      {!canEdit && (
        <React.Fragment>
          <div className="section-first-row">
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 6 }]}>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.accessControl.registrationDomain.label)}
              </div>
              <div>
                {targetAccessControl?.participantDomainAllowlist &&
                targetAccessControl?.participantDomainAllowlist.length > 0
                  ? targetAccessControl?.participantDomainAllowlist?.join(', ')
                  : t(i18nKeys.events.eventDetails.messages.thisListIsEmpty)}
              </div>
            </Grid>
          </div>
          <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 6 }]}>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.accessControl.facilitatorDomain.label)}
              </div>
              <div>
                {targetAccessControl?.facilitatorDomainAllowlist &&
                targetAccessControl?.facilitatorDomainAllowlist.length > 0
                  ? targetAccessControl?.facilitatorDomainAllowlist?.join(', ')
                  : t(i18nKeys.events.eventDetails.messages.thisListIsEmpty)}
              </div>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </ExpandableSection>
  );
};
export default AccessControl;
