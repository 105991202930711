import { Wizard } from '@amzn/awsui-components-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEditCampaign } from '../../store/edit-campaign.context';
import { useFlashbars } from '../../store/flashbar.context';
import { useSplitPanel } from '../../store/split-panel.context';
import { useToolPanel } from '../../store/tool-panel.context';
import { i18nKeys } from '../../utils/i18n.utils';
import { preProdLogger } from '../../utils/log.utils';
import { ConfirmModal } from '../common/ConfirmModal';
import NewEventChallenges from '../common/CampaignEventComponents/NewEventChallenges';
import NewCampaignDetails from './NewCampaignSections/NewCampaignDetails';
import NewCampaignPermissionsAndReporting from './NewCampaignSections/NewCampaignPermissionsAndReporting';
import NewCampaignSettings from './NewCampaignSections/NewCampaignSettings';
import NewCampaignReview from './NewCampaignSections/NewCampaignReview';
import _ from 'lodash';
import { fromPlainObject } from '../../utils/mapper.utils';
import { Campaign, JamCampaignRequest } from '../../types/Campaign';
import { useApi } from '../../store/api.context';
import { CAMPAIGN_DETAILS_ROUTES } from '../../routes';
import { NewCampaignSteps } from './CampaignModel';

const NewCampaign: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { newCampaignMode, toggleNewCampaign, destroyEdits, newEditedCampaign } = useEditCampaign();
  const [confirmCancelModalVisible, setConfirmCancelModalVisible] = useState(false);
  const { addErrorFlashbar } = useFlashbars();
  const { toggleShowSplitPanel, toggleSplitPanel, showSplitPanel } = useSplitPanel();
  const { toggleShowToolPanel, toggleToolPanel, showToolPanel } = useToolPanel();
  const { campaignApi } = useApi();

  const [activeStepIndex, setActiveStepIndex] = useState(NewCampaignSteps.CAMPAIGN_DETAILS);

  useEffect(() => {
    if (!newCampaignMode) {
      toggleNewCampaign();
    }
    return () => {
      // reset the new campaign mode when you leave the page
      toggleNewCampaign();
    };
  }, []);

  const handleCreateCampaign = () => {
    if (newEditedCampaign) {
      setLoading(true);
      const jamCampaignRequest = fromPlainObject(newEditedCampaign, JamCampaignRequest) as JamCampaignRequest;
      campaignApi
        .createJamCampaignRequest(jamCampaignRequest)
        .then((res: Campaign) => {
          if (res.id) {
            history.replace(CAMPAIGN_DETAILS_ROUTES.Summary.resolve(res?.id));
          }
          destroyEdits();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger(err);
        });
    }
  };

  const handleCancel = () => {
    destroyEdits();
    history.push('/campaigns');
  };

  const promptModal = () => {
    setConfirmCancelModalVisible(true);
  };

  const handleActiveStepChange = (stepIndex: number) => {
    const previousStep = stepsDictionary[(stepIndex - 1) as unknown as NewCampaignSteps];
    // Check for validation errors
    if (previousStep?.isValid && !previousStep.isValid() && previousStep.getErrors) {
      const errorList = previousStep.getErrors();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      addErrorFlashbar(t(i18nKeys.newEvent.errors.message, { errors: errorList.join(', ') }));
    } else {
      // If not on challenge step, hide challenge selection aid
      // Make sure to update this if we ever add additional steps or uncover a better way to track the activeStepIndex per step
      if (stepIndex !== NewCampaignSteps.CHALLENGES && (showSplitPanel || showToolPanel)) {
        if (showSplitPanel) {
          toggleShowSplitPanel(false);
          toggleSplitPanel(false);
        }
        if (showToolPanel) {
          toggleShowToolPanel(false);
          toggleToolPanel(false);
        }
      }
      setActiveStepIndex(stepIndex);
    }
  };

  /**
   * Add steps here to keep active step number inline with wizard
   */
  const stepsDictionary: {
    [key in NewCampaignSteps]: {
      title: string;
      content: ReactNode;
      isValid?: () => boolean;
      getErrors?: () => string[];
    };
  } = {
    [NewCampaignSteps.CAMPAIGN_DETAILS]: {
      title: t(i18nKeys.campaigns.newCampaign.steps.campaignDetails.title),
      content: <NewCampaignDetails target={newEditedCampaign} />,
      isValid: () => {
        return !_.isEmpty(newEditedCampaign?.title);
      },
      getErrors: () => {
        const errors: string[] = [];
        if (_.isEmpty(newEditedCampaign?.title)) {
          errors.push(t(i18nKeys.newEvent.errors.title));
        }
        return errors;
      },
    },
    [NewCampaignSteps.PERMISSIONS_AND_REPORTING]: {
      title: t(i18nKeys.campaigns.newCampaign.steps.permissionsAndReporting.title),
      content: <NewCampaignPermissionsAndReporting target={newEditedCampaign} />,
    },
    [NewCampaignSteps.CHALLENGES]: {
      title: t(i18nKeys.campaigns.newCampaign.steps.challenges.title),
      content: newEditedCampaign && <NewEventChallenges target={newEditedCampaign} />,
      isValid: () => {
        return !_.isEmpty(newEditedCampaign?.challengeDescriptors);
      },
      getErrors: () => {
        const errors: string[] = [];
        if (_.isEmpty(newEditedCampaign?.challengeDescriptors)) {
          errors.push(t(i18nKeys.campaigns.newCampaign.errors.challengesLength));
        }
        return errors;
      },
    },
    [NewCampaignSteps.SETTINGS]: {
      title: t(i18nKeys.campaigns.newCampaign.steps.settings.title),
      content: <NewCampaignSettings target={newEditedCampaign} />,
    },
    [NewCampaignSteps.REVIEW_AND_CREATE]: {
      title: t(i18nKeys.campaigns.newCampaign.steps.reviewAndCreate.title),
      content: <NewCampaignReview target={newEditedCampaign} navigate={handleActiveStepChange} />,
    },
  };

  return (
    <React.Fragment>
      <ConfirmModal
        visible={confirmCancelModalVisible}
        message={<React.Fragment>{t(i18nKeys.campaigns.modals.newCampaignCancel.message)}</React.Fragment>}
        cancelBtnLabel={t(i18nKeys.campaigns.modals.newCampaignCancel.denyButton)}
        confirmBtnLabel={t(i18nKeys.campaigns.modals.newCampaignCancel.confirmButton)}
        onConfirm={handleCancel}
        onCancel={() => setConfirmCancelModalVisible(false)}
      />
      <Wizard
        className="mb-12"
        onCancel={() => promptModal()}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          cancelButton: t(i18nKeys.general.cancel),
          previousButton: t(i18nKeys.general.previous),
          nextButton: t(i18nKeys.general.next),
          submitButton: t(i18nKeys.campaigns.buttons.createCampaignRequest),
          optional: t(i18nKeys.general.optional),
        }}
        activeStepIndex={activeStepIndex}
        steps={Object.keys(stepsDictionary).map((stepKey: string) => {
          return {
            title: stepsDictionary[stepKey as unknown as NewCampaignSteps].title,
            content: stepsDictionary[stepKey as unknown as NewCampaignSteps].content,
          };
        })}
        onNavigate={({ detail }) => {
          handleActiveStepChange(detail.requestedStepIndex);
        }}
        isLoadingNextStep={loading}
        onSubmit={() => handleCreateCampaign()}
      />
    </React.Fragment>
  );
};
export default NewCampaign;
