import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDropdownProps } from '@amzn/awsui-components-react';

// context
import { useUser } from '@/src/store/user.context';
import { useAuth } from '@/src/store/auth.context';
import { useTheme } from '@/src/store/theme.context';

// utils
import { getLanguageCodeSafe, getLocaleLabel, LANGUAGE_SELECT_OPTIONS } from '@/src/utils/locale.utils';
import { preProdLogger } from '@/src/utils/log.utils';
import { getNavMenuForUser } from '@/src/utils/navMenu.utils';

// ui components
import { Header as HeaderUI } from '@/src/components/ui/organisms/Header';

// constants
import { LOGO_SVG } from '@/src/constants/common';

// types
import type { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { useActiveRouteDetails } from '@/src/hooks/useActiveRouteDetails';
import { useHistory } from 'react-router';
import {RoutePath} from "@/src/RoutePath";
import { useLocation } from 'react-router-dom';
import { customEventTrigger } from '../../analytics/createEventTrigger';
import { useHeader } from '@/src/store/header.context';

export const NewHeader: React.FC = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguageCodeSafe(i18n.language));
  const { themeMode, setThemeMode } = useTheme();
  const { user, profile } = useUser();
  const { authClient } = useAuth();
  const { isCreateJamProfile } = useActiveRouteDetails();
  const {showThemeToggle } =  useHeader()

  const handleLangaugeSelect = useCallback(
    (code: string) => {
      void i18n.changeLanguage(code);
      setSelectedLanguage(code);
    },
    [i18n]
  );

  const langOptions = useMemo(() => {
    return {
      lang: {
        value: selectedLanguage,
        label: getLocaleLabel(selectedLanguage),
      },
      options: LANGUAGE_SELECT_OPTIONS,
      onLanguageSelect: handleLangaugeSelect,
    };
  }, [selectedLanguage, handleLangaugeSelect]);

  const userOptions = useMemo(() => {
    if (!user) return undefined;

    return {
      name: profile?.nickname || user.name,
      email: user.email,
      menuItems: [
        { id: 'user-profile', text: 'Jam Profile', href: RoutePath.JAM_PROFILE.valueOf() },
        // { id:'user-preferences', text: 'Preferences', href: '/preferences' },
        { id: 'user-signout', text: 'Sign Out' },
      ],
    };
  }, [user, profile]);

  const handleMenuItemClick: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails | object> = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    const detail = e.detail as ButtonDropdownProps.ItemClickDetails;
    const event = getEventName(detail);
    if(event){
      customEventTrigger("linkclick", event.eventName, window.location.href, event.description, {"url": event.url});
    }
    if (detail && detail?.href) {
      
      history.push(detail?.href)
    }
  }, []);

  const getEventName = (details: ButtonDropdownProps.ItemClickDetails) => {  
    switch(details.href) {
      case RoutePath.CATALOG:
        return {
          eventName: 'Nav Schedule a Jam Event',
          description: 'Browse our existing our experiences, and schedule your next event',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.NEW_EVENT:
        return {
          eventName: 'Nav Schedule a Custom Jam Event',
          description: 'Curate challenges to create your own unique event',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.EVENTS:
        return {
          eventName: 'Nav My Event Library',
          description: 'See the list of Events you are managing or facilitating',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.NEW_CHALLENGE:
        return {
          eventName: 'Nav Build a Jam Challenge',
          description: 'Build a new Jam challenge to include in events',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.NEW_CHALLENGE_SET:
        return {
          eventName: 'Nav Build a Jam Challenge Set',
          description: 'Compile a library of Jam challenges to include in events',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.CHALLENGES:
        return {
          eventName: 'Nav Challenge Library',
          description: 'View all challenges',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.ADMIN:
          return {
            eventName: 'Nav Admin',
            description: 'Nav Admin',
            url: `${window.location.host}${details.href}` 
          };
        case RoutePath.JOIN_AN_EVENT:
        return {
          eventName: 'Nav Join a Jam Event',
          description: 'Sign in and join an AWS Jam event',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.MY_JAMS_ACTIVE:
        return {
          eventName: 'Nav My Jam Events',
          description: 'Access Jam events you are enrolled in',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.PUBLIC_EVENTS:
        return {
          eventName: 'Nav View Public Jam Events',
          description: 'Browse current and upcoming Jam events that are free for all to join',
          url: `${window.location.host}${details.href}` 
        };
        case RoutePath.FAQ:
          return {
            eventName: 'Nav FAQ',
            description: 'Nav FAQ',
            url: `${window.location.host}${details.href}` 
          };
    }
  }

  const handleUserMenuItemClick: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails> = useCallback(
    (e) => {
      const id = e.detail.id;
      switch (id) {
        case 'user-signout':
          authClient
            .signOut()
            .then(() => preProdLogger('sign out'))
            .catch((err) => preProdLogger(err));
          break;
      }
    },
    [authClient]
  );

  const handleSignInClick = useCallback(() => {
    void authClient.signIn(history.location);
  }, [authClient]);

  const navMenu = useMemo(() => {
    return getNavMenuForUser(user);
  }, [user]);

  const handleLogoClick = useCallback<CancelableEventHandler>((e) => {
    e.preventDefault();
    if (location.pathname === RoutePath.ROOT) return
    history.push(RoutePath.ROOT)
  }, [history, location])

  return (
    <HeaderUI
      logo={{
        title: 'Jam',
        href: '/',
        svg: LOGO_SVG,
        onFollow: handleLogoClick
      }}
      navItems={isCreateJamProfile ? [] : navMenu}
      theme={{
        mode: themeMode,
        onToggleTheme: setThemeMode,
      }}
      showThemeToggle={showThemeToggle}
      userDetails={userOptions}
      lang={langOptions}
      onMenuItemClick={handleMenuItemClick}
      onUserMenuItemClick={handleUserMenuItemClick}
      onSignInClick={handleSignInClick}
    />
  );
};
