/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { jsonObject } from 'typedjson';
import { KeyValuePair } from '@/src/types/KeyValuePair';
import { ParticipantInfo } from '../components/game/FacilitatorParticipants/molecules/FacilitatorParticipantContainer';

export enum JamFacilitatorTeamName {
  Facilitators = 'Facilitators',
}

export class JamFacilitatorTeamParticipants {
  nickname: string;
  login: string;
  teamName?: string;
  excludeFromAutoAssignment: boolean;
  facilitator: boolean;

  static fromPlainObject(obj: JamFacilitatorTeamParticipants): JamFacilitatorTeamParticipants {
    const data = Object.assign(new JamFacilitatorTeamParticipants(), obj);
    return data;
  }
}

@jsonObject
export class JamFacilitatorParticipantsRes {
  participants: JamFacilitatorTeamParticipants[];
  participantSkills: {
    [participantLogin: string]: number;
  };
}

export enum TeamGoal {
  PLAY_TO_WIN = 'PLAY_TO_WIN',
  PLAY_TO_LEARN = 'PLAY_TO_LEARN',
}

export interface JamFacilitatorTeamsAvailability {
  teamId?: string;
  name: string;
  displayName: string;
  capacity: number;
  numSpotsFilled: number;
  suspectedCheater: boolean;
  cheatingEvidence: string[];
  facilitatorOnly: boolean;
  owner: string;
  goal: TeamGoal;
  renameEnabled: boolean;
  passwordProtected: boolean;
  members?: ParticipantInfo[];
}
@jsonObject
export class JamFacilitatorTeamsAvailabilityRes {
  teams: JamFacilitatorTeamsAvailability[];
  lastToken: string;

  static fromPlainObject(obj: JamFacilitatorTeamsAvailabilityRes): JamFacilitatorTeamsAvailabilityRes {
    const data = Object.assign(new JamFacilitatorTeamsAvailabilityRes(), obj);
    return data;
  }
}

export enum TEAM_ACTION {
  CHANGE_NAME = 'CHANGE_NAME',
  LOCK_NAME = 'LOCK_NAME',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  REMOVE_PASSWORD = 'REMOVE_PASSWORD',
}

export enum PARTICIPANT_ACTION {
  START_CHAT = 'START_CHAT',
  RESEND_INVITE_TEAM = 'RESEND_INVITE_TEAM',
  CHANGE_TEAM = 'CHANGE_TEAM',
  REMOVE_FROM_TEAM = 'REMOVE_FROM_TEAM',
  MAKE_TEAM_OWNER = 'MAKE_TEAM_OWNER',
}

export class AwsAccount {
  accountNumber: string;
  region: string;
  accessKey: string;
  secretKey: string;
  sessionToken: string;
  expiration: number;
  url: string;
  urlExpiration: number;
}

export class TeamChallengePropertiesResponse {
  properties: KeyValuePair[] = [];
  modules: TeamChallengeModule[] = [];
  scheduledTerminationTime: number;

  static fromPlainObject(obj: TeamChallengePropertiesResponse): TeamChallengePropertiesResponse {
    const response: TeamChallengePropertiesResponse = Object.assign(new TeamChallengePropertiesResponse(), obj);
    response.properties = (response.properties || []).map(KeyValuePair.fromPlainObject);
    response.modules = (response.modules || []).map(TeamChallengeModule.fromPlainObject);
    return response;
  }
}

export class TeamChallengeModule {
  id: string;
  label: string;
  description: string;
  readmeUrl: string;
  inputs: ModuleInput[];
  outputs: ModuleOutput[];

  static fromPlainObject(obj: TeamChallengeModule): TeamChallengeModule {
    const module: TeamChallengeModule = Object.assign(new TeamChallengeModule(), obj);
    module.inputs = (module.inputs || []).map(ModuleInput.fromPlainObject);
    module.outputs = (module.outputs || []).map(ModuleOutput.fromPlainObject);

    const sortFunction = (a: { order: number }, b: { order: number }) => a.order - b.order;
    module.inputs.sort(sortFunction);
    module.outputs.sort(sortFunction);
    return module;
  }
}

export class ModuleInput {
  id: string;
  label: string;
  value: string;
  updatedValue: string;
  description: string;
  order: number;

  static fromPlainObject(obj: any): ModuleOutput {
    return Object.assign(new ModuleOutput(), obj);
  }
}

export class ModuleOutput extends ModuleInput {
  markdown: boolean;

  static fromPlainObject(obj: any): ModuleOutput {
    return Object.assign(new ModuleOutput(), obj);
  }
}

export interface JamParticpantsStats {
  totalTeams: number;
  totalParticipants: number;
  totalFacilitators: number;
}

export interface FacilitatorsAndNoTeamParticpants {
  noTeamParticipants: ParticipantInfo[];
  facilitators: ParticipantInfo[];
  participantSkills: Record<string, number>;
}
