import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { JamConsent } from '../types/JamConsent';
import { GAME_API_PREFIX } from '../constants/api';

export class JamConsentAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }
  public async getEventConsent(eventName: string): Promise<JamConsent> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/consent/${eventName}`,
      responseMapper: (res) => (res.consent !== null ? JamConsent.fromPlainObject(res.consent) : null),
    })) as Promise<JamConsent>;
  }

  public async saveEventConsent(eventName: string, consent: JamConsent): Promise<void> {
    return (await this.apiClient.post({
      path: `${GAME_API_PREFIX}/participant/consent/${eventName}`,
      body: { consent }
    })) as Promise<void>;
  }
}
