import React, { useLayoutEffect, useState } from 'react';
import { ContentLayout, SpaceBetween, Header, Grid, Box, Button } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useApi } from '@/src/store/api.context';
import JamSpinner from '@/src/components/common/JamSpinner';
import JamSkillProfileForm from '../../ui/organisms/JamSkillProfileForm/JamSkillProfileForm';

interface skillProfileInterface {
  AWS: number;
}

const JamSkillProfile: React.FC = () => {
  const { jamProfileAPI } = useApi();
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const [value, setValue] = React.useState('');
  const onSubmit = async () => {
    setLoading(true);
    const body = {
      AWS: parseInt(value, 10),
    };
    try {
      await jamProfileAPI.updateSkillProfile(body);
    } catch (e) {
      // error handled in API
    }
    setLoading(false);
  };

  const getSkillProfile = async () => {
    setLoading(true);
    const res = await jamProfileAPI.getSkillProfile();
    if (res) {
      const { AWS }: skillProfileInterface = res;
      setValue(AWS.toString());
    }
    setLoading(false);
  };
  useLayoutEffect(() => {
    void getSkillProfile();
  }, []);

  if (loading) {
    return <JamSpinner />;
  }

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">{t(i18nKeys.JamProfile.jamSkillProfile.title)}</Header>
        </SpaceBetween>
      }>
      <Grid gridDefinition={[{ colspan: 12 }]}>
        <div>
          <JamSkillProfileForm value={value} setValue={setValue} />
          <div className="update-btn">
            <Box float="right">
              <Button
                variant="primary"
                ariaLabel={t(i18nKeys.JamProfile.jamSkillProfile.btn.updateSkillProfile)}
                onClick={() => void onSubmit()}>
                {t(i18nKeys.JamProfile.jamSkillProfile.btn.updateSkillProfile)}
              </Button>
            </Box>
          </div>
        </div>
      </Grid>
    </ContentLayout>
  );
};

export default JamSkillProfile;
