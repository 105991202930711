import React, { useContext, useState } from 'react';
import { useApi } from './api.context';
import { JamChallengeRecentFeedback } from '../types/JamChallengeRecentFeedback';
import { useJamEventDetails } from './jam-event-details.context';

export interface JamChallengeRecentFeedbackContextValue {
  jamChallengeRecentFeedbackData?: JamChallengeRecentFeedback[];
  isFetchingJamChallengeRecentFeedback: boolean;
  loadJamChallengeDetailsRecentFeedback: (params: {
    eventId?: string;
    challengeId?: string;
  }) => JamChallengeRecentFeedback | undefined;
  loadJamChallengeRecentFeedbackData: (params: {
    eventId: string;
    challengeId: string;
  }) => Promise<{ eventId: string; challengeId: string }>;
  selectedJamChallenge: JamChallengeRecentFeedback | undefined;
}

const JamChallengeRecentFeedbackContext = React.createContext<JamChallengeRecentFeedbackContextValue>({
  jamChallengeRecentFeedbackData: undefined,
  isFetchingJamChallengeRecentFeedback: true,
  loadJamChallengeDetailsRecentFeedback: () => undefined,
  loadJamChallengeRecentFeedbackData: () => new Promise(() => {
    // do nothing
  }),
  selectedJamChallenge: undefined,
});

const JamChallengeRecentFeedbackProvider: React.FC = ({ children }) => {
  const [jamChallengeRecentFeedbackData, setJamChallengeRecentFeedbackData] = useState<JamChallengeRecentFeedback[]>();
  const [isFetchingJamChallengeRecentFeedback, setIsFetchingJamChallengeRecentFeedback] = useState(true);
  const [selectedJamChallenge, setSelectedJamChallenge] = useState<JamChallengeRecentFeedback>();

  const { jamChallengeDetailsApi } = useApi();
  const { eventName } = useJamEventDetails();

  const loadJamChallengeRecentFeedbackData = async (params: {
    eventId: string;
    challengeId: string;
  }): Promise<{ eventId: string; challengeId: string }> => {
    const { eventId, challengeId } = params;
    if (!eventId || !challengeId) {
      throw new Error('eventId and challengeId is required.');
    }
    try {
      const response = (await jamChallengeDetailsApi.fetchJamChallengeRecentFeedbackData(
        eventName ?? '',
        challengeId
      )) as unknown as JamChallengeRecentFeedback[];
      setJamChallengeRecentFeedbackData(response);
      setIsFetchingJamChallengeRecentFeedback(false);
      return { eventId: response[0]?.challengeId ?? '', challengeId: response[0]?.challengeId ?? '' };
    } catch (e: any) {
      setIsFetchingJamChallengeRecentFeedback(false);
      // if API failed catch here.
      throw e;
    }
  };

  const loadJamChallengeDetailsRecentFeedback = (params: {
    eventId?: string;
    challengeId?: string;
  }): JamChallengeRecentFeedback | undefined => {
    const { eventId, challengeId } = params;
    if (!eventId || !challengeId) {
      throw new Error('eventId and challengeId is required.');
    }
    try {
      const response = jamChallengeRecentFeedbackData?.find(
        (challenge) => challenge.challengeId === challengeId
      ) as JamChallengeRecentFeedback;
      setIsFetchingJamChallengeRecentFeedback(false);
      setSelectedJamChallenge(response);
      return response;
    } catch (e: any) {
      setIsFetchingJamChallengeRecentFeedback(false);
      // if API failed catch here.
      throw e;
    }
    return undefined;
  };

  const data: JamChallengeRecentFeedbackContextValue = {
    jamChallengeRecentFeedbackData,
    loadJamChallengeRecentFeedbackData,
    isFetchingJamChallengeRecentFeedback,
    loadJamChallengeDetailsRecentFeedback,
    selectedJamChallenge,
  };
  return (
    <JamChallengeRecentFeedbackContext.Provider value={data}>{children}</JamChallengeRecentFeedbackContext.Provider>
  );
};

const useJamChallengeRecentFeedback = () => {
  const context = useContext(JamChallengeRecentFeedbackContext);
  if (context === undefined) {
    throw new Error('useJamChallengeRecentFeedback can only be used inside JamChallengeRecentFeedbackProvider');
  }
  return context;
};

export { JamChallengeRecentFeedbackProvider, useJamChallengeRecentFeedback };
