/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/unbound-method */
export class JamEventSponsorshipSettings {
  static readonly DEFAULT_FIRST_NAME_REQUIRED: boolean = true;
  static readonly DEFAULT_LAST_NAME_REQUIRED: boolean = true;
  static readonly DEFAULT_PHONE_NUMBER_REQUIRED: boolean = true;
  static readonly DEFAULT_ADDRESS_REQUIRED: boolean = true;
  static readonly DEFAULT_TITLE_REQUIRED: boolean = true;
  static readonly DEFAULT_COMPANY_REQUIRED: boolean = true;
  static readonly DEFAULT_COUNTRY_REQUIRED: boolean = true;
  static readonly DEFAULT_POSTAL_CODE_REQUIRED: boolean = true;

  sponsored = false;
  firstNameRequired = false;
  lastNameRequired = false;
  phoneNumberRequired = false;
  addressRequired = false;
  titleRequired = false;
  companyRequired = false;
  countryRequired = false;
  postalCodeRequired = false;

  static fromPlainObject(obj: any): JamEventSponsorshipSettings {
    const settings: JamEventSponsorshipSettings = Object.assign(new JamEventSponsorshipSettings(), obj);
    return settings.withDefaults();
  }

  withDefaults(): JamEventSponsorshipSettings {
    if (this.sponsored == null) {
      this.sponsored = false;
    }
    // Other settings are relevant only for sponsored events
    if (this.sponsored) {
      if (this.firstNameRequired == null) {
        this.firstNameRequired = JamEventSponsorshipSettings.DEFAULT_FIRST_NAME_REQUIRED;
      }
      if (this.lastNameRequired == null) {
        this.firstNameRequired = JamEventSponsorshipSettings.DEFAULT_LAST_NAME_REQUIRED;
      }
      if (this.phoneNumberRequired == null) {
        this.phoneNumberRequired = JamEventSponsorshipSettings.DEFAULT_PHONE_NUMBER_REQUIRED;
      }
      if (this.addressRequired == null) {
        this.addressRequired = JamEventSponsorshipSettings.DEFAULT_ADDRESS_REQUIRED;
      }
      if (this.titleRequired == null) {
        this.titleRequired = JamEventSponsorshipSettings.DEFAULT_TITLE_REQUIRED;
      }
      if (this.companyRequired == null) {
        this.companyRequired = JamEventSponsorshipSettings.DEFAULT_COMPANY_REQUIRED;
      }
      if (this.countryRequired == null) {
        this.countryRequired = JamEventSponsorshipSettings.DEFAULT_COUNTRY_REQUIRED;
      }
      if (this.postalCodeRequired == null) {
        this.countryRequired = JamEventSponsorshipSettings.DEFAULT_POSTAL_CODE_REQUIRED;
      }
    }
    return this;
  }
}
