export const MapLocations = {
  WestCoast:          { top: '24%', left: '12%' },
  Virginia:           { top: '27.5%', left: '22%' },
  Canada:             { top: '15%', left: '28%' },
  Brazil:             { top: '73%', left: '30.5%' },
  Ireland:            { top: '16.5%', left: '43%' },
  London:             { top: '20%', left: '46.5%' },
  India:              { top: '40%', left: '67%' },
  Singapore:          { top: '55%', left: '74.5%' },
  China:              { top: '27%', left: '78%' },
  Seoul:              { top: '34%', left: '57%' },
  Tokyo:              { top: '31%', left: '82%' },
  Australia:          { top: '78%', left: '87%' },
  Stockholm:          { top: '9.5%', left: '48%' },
  RandomAfrica:       { top: '36.5%', left: '48%' },
  NorthAfrica:        { top: '52%', left: '54%' },
  SouthAfrica:        { top: '72.5%', left: '50%' },
  Russia:             { top: '10.5%', left: '55%' },
  SouthAmerica:       { top: '57%', left: '25%' },
  Alaska:             { top: '12.5%', left: '14%' },
  Mongolia:           { top: '17.5%', left: '64%' },
};
