import React from 'react';

import { Container, Header, Icon, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { ChallengeListItem } from '@/src/types/Challenge';
import { CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { EventTemplateRationale } from '@/src/types/EventTemplate';

interface IRationaleProps {
  selectedChallengeListItems: ChallengeListItem[];
  rationale: EventTemplateRationale | undefined;
  onRationaleChange: (id: string, rational: string) => void;
}

const Rationale: React.FC<IRationaleProps> = ({ selectedChallengeListItems, rationale, onRationaleChange }) => {
  const { t } = useTranslation();

  const handleRationaleChange = (challengeId: string, value: string) => {
    onRationaleChange(challengeId, value);
  };

  return (
    <div className="rationale-container">
      <Container
        header={
          <Header variant="h2" counter="(Optional)">
            {t(i18nKeys.eventTemplates.step4.challengeHeader)}
          </Header>
        }>
        {selectedChallengeListItems.map((challenge: ChallengeListItem, index: number) => (
          <div className="challenge-container" key={index}>
            <ReactRouterLink
              id={`event-catalog-template-${challenge.challengeId}`}
              target="_blank"
              to={CHALLENGE_DETAILS_ROUTES.Summary.resolve(challenge.challengeId ?? '')}
              style={{ textDecoration: 'none', color: '#0972D3', fontStyle: '700', fontSize: '16px' }}>
              <SpaceBetween direction="horizontal" size="xs">
                {challenge.props.title}
                <div style={{ marginLeft: '0.1rem' }}>
                  <Icon name="external" />
                </div>
              </SpaceBetween>
            </ReactRouterLink>
            <h4>{t(i18nKeys.eventTemplates.step4.rationale.label)}</h4>
            <div className="textarea-container">
              <Textarea
                id={`event-catalog-template-textarea-${challenge.challengeId}`}
                onChange={(e) => handleRationaleChange(challenge?.id ?? '', e.detail.value)}
                value={rationale?.[challenge?.id ?? ''] || ''}
                placeholder={t(i18nKeys.eventTemplates.step4.rationale.placeholder)}
                rows={5}
              />
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default Rationale;
