import { TFunction } from 'i18next';
import { i18nKeys } from './i18n.utils';

/**
 * Takes an array of warnings and turns them into a displayable string
 *
 * @param warnings Array of string warnings
 * @returns string of warnings
 */
export const stringifyWarnings = (t: TFunction, warnings?: string[]): string | [] => {
  warnings = warnings || [];
  if (warnings.length < 1) {
    return [];
  }

  if (warnings.length === 1) {
    return warnings[0];
  }

  return warnings.reduce((output, warning, i) => {
    return `${output} ${i + 1}. ${warning}`;
  }, t(i18nKeys.general.warningsLabel, { count: warnings.length }));
};

export const stringifyTranslatedSSHWarning = (t: TFunction, prefix: string) => {
  return t(i18nKeys.general.sshWarnings, { prefix });
};
