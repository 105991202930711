import { EvaluationRequest } from '../../../types/usage-plan/UsagePlan';
import * as React from 'react';
import { useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState } from '../../common/TableEmptyState';
import { i18nKeys } from '../../../utils/i18n.utils';
import { Button, Pagination, Table, TextFilter } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { getTableFilterMatcher, paginationLabels, TableFilterMatcher } from '../../../utils/table.utils';
import { TableHeader } from '../../common/TableHeader';
import { Link } from 'react-router-dom';
import { RequestType } from '../../../types/common';
import moment from 'moment';
import { YYYY_MM_DD } from '../../../utils/event-time.utils';
import { usePlans } from '../../../store/usage-plan/usage-plan.context';
import { EVENT_DETAILS_ROUTES } from '../../../routes';
import { StatusBadge } from '../../common/StatusBadge';

interface UsagePlanDetailsRequestsProps {
  usagePlanId: string;
  requests: EvaluationRequest[];
}

const UsagePlanDetailsRequests: React.FC<UsagePlanDetailsRequestsProps> = ({ requests, usagePlanId }) => {
  const { t } = useTranslation();
  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['name', 'createDate', 'lastUpdatedDate', 'status'],
  });
  const { reevaluateRequests, getPlanDetails } = usePlans();

  const filteringFunction = (item: EvaluationRequest, filteringText: string): boolean => {
    const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
    return (
      matcher.filterEmpty ||
      matcher.isMatch(item.id) ||
      matcher.isMatch(item.name) ||
      matcher.isMatch(item.status) ||
      matcher.isMatch(item.type)
    );
  };

  const columnDefinition = [
    {
      id: 'name',
      sortingField: 'name',
      header: t(i18nKeys.usagePlan.fields.requestName),
      cell: (req: EvaluationRequest) => (
        <div>
          {/* note that the campaigns route is not yet created */}
          <Link
            to={req.type === RequestType.EVENT ? EVENT_DETAILS_ROUTES.Summary.resolve(req.id) : `/campaigns/${req.id}`}>
            {req.name}
          </Link>
        </div>
      ),
      minWidth: 200,
      allowLineWrap: true,
    },
    {
      id: 'createDate',
      sortingField: 'createDate',
      header: t(i18nKeys.usagePlan.overview.date),
      cell: (req: EvaluationRequest) => <span>{moment(req.createdDate).format(YYYY_MM_DD)}</span>,
      minWidth: 150,
      allowLineWrap: false,
    },
    {
      id: 'lastUpdatedDate',
      sortingField: 'lastUpdatedDate',
      header: t(i18nKeys.events.eventDetails.labels.lastModified),
      cell: (req: EvaluationRequest) => <span>{moment(req.lastUpdatedDate).format(YYYY_MM_DD)}</span>,
      minWidth: 150,
      allowLineWrap: false,
    },
    {
      id: 'status',
      sortingField: 'status',
      header: t(i18nKeys.events.eventDetails.headers.status),
      cell: (req: EvaluationRequest) => {
        return <StatusBadge status={req.toStatus()} />;
      },
      minWidth: 150,
      allowLineWrap: false,
    },
  ];

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    requests,
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.usagePlan.overview.empty.title)}
            subtitle={t(i18nKeys.usagePlan.overview.empty.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const onReevaluateRequests = () => {
    // reevaluate the usage plan on all active requests, then reload for the latest info on these plans
    reevaluateRequests(usagePlanId);
    getPlanDetails(usagePlanId);
  };

  return (
    <Table
      {...collectionProps}
      header={
        <TableHeader
          totalItems={requests.length}
          title={t(i18nKeys.usagePlan.overview.requests)}
          actionButtons={
            <Button variant="primary" onClick={onReevaluateRequests}>
              {t(i18nKeys.usagePlan.overview.reevaluatePlural)}
            </Button>
          }
        />
      }
      columnDefinitions={columnDefinition}
      visibleColumns={preferences.visibleColumns}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
          filteringAriaLabel={t(i18nKeys.usagePlan.filteringLabel)}
        />
      }
    />
  );
};

export default UsagePlanDetailsRequests;
