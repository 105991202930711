import { JamEventTeamAssignmentType } from '../types/JamCommon';
import { JamEventDetails } from '../types/JamEventDetails';

const JamEventWelcomeModalKey = 'welcome-modal';

export const isWaitingForAutoTeamAssignment = (event: JamEventDetails) => {
  return skillBasedAssignmentEnabled(event.teamAssignmentType) && !event.skillProfileUpdateRequired && !event.teamId;
};

export const skillBasedAssignmentEnabled = (teamAssignmentType: JamEventTeamAssignmentType) => {
  return teamAssignmentType === JamEventTeamAssignmentType.SKILL_BASED;
};

export const hasTeamPreCreated = (teamAssignmentType: JamEventTeamAssignmentType) => {
  return teamAssignmentType === JamEventTeamAssignmentType.PRE_CREATED;
};

type ModalRecord = { [key: string]: boolean };
export const didWelcomeModalShow = (eventId: string): boolean => {
  const keys = JSON.parse(localStorage.getItem(JamEventWelcomeModalKey) || '{}') as ModalRecord;
  return !!keys[eventId];
};

export const completeWelcomeModalShow = (eventId: string) => {
  const keys = JSON.parse(localStorage.getItem(JamEventWelcomeModalKey) || '{}') as ModalRecord;
  keys[eventId] = true;
  return localStorage.setItem(JamEventWelcomeModalKey, JSON.stringify(keys));
};
