import { Input } from '@amzn/awsui-components-react';
import React from 'react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';

interface InputWithIconProps {
  value: string;
  icon: JSX.Element;
  type?: any;
  onChange: (value: string) => void;
  disabled: boolean;
}

export const InputWithIcon: React.FC<InputWithIconProps> = ({ value, icon, type, onChange, disabled }) => {
  return (
    <React.Fragment>
      <Input
        className="inline numeric-input"
        value={value}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        type={type}
        onChange={({ detail }) => onChange(detail.value)}
        disabled={disabled}
      />
      <div className="input-icon" style={{ backgroundColor: awsui.colorBackgroundControlDisabled }}>
        {icon}
      </div>
    </React.Fragment>
  );
};
