import React from 'react';
import { SideNav } from './SideNav';
import JamProfileSideNav from './JamProfileSideNav';
import CatalogFilter from '../../event-templates-catalog/molecules/CatalogFilter/CatalogFilter';
import { useLocation } from 'react-router-dom';
import { RoutePath } from '@/src/RoutePath';
import { MyJamSidebar } from '../../event-templates-catalog/molecules/MyJamSidebar/MyJamSidebar';
import {
  campaignSideNavRegex,
  challengeSideNavRegex,
  eventCatalogSideNavRegex,
  eventSideNavRegex,
  jamChallengeTaskRegex,
  jamChallengesRegex,
} from '@/src/utils/myjam.utils';
import FAQSidebar from '../../faq/molecules/FAQSidebar/FAQSidebar';
import { faqRoute } from '@/src/routes';

const GlobalSideNav = () => {
  const location = useLocation();
  const { pathname } = location;

  if (jamChallengesRegex.test(pathname) || jamChallengeTaskRegex.test(pathname)) {
    return <MyJamSidebar />;
  }
  if (pathname.includes(faqRoute.path)) {
    return <FAQSidebar />;
  }
  if (pathname === RoutePath.CATALOG) {
    return <CatalogFilter />;
  }
  if (
    [
      RoutePath.JAM_PROFILE,
      RoutePath.JAM_PROFILE_DETAILS,
      RoutePath.JAM_SKILL_PROFILE,
      RoutePath.DATA_AND_PRIVACY,
      RoutePath.JAM_PRIZES,
    ].includes(pathname as RoutePath)
  ) {
    return <JamProfileSideNav />;
  }

  if (
    [RoutePath.EVENTS, RoutePath.CAMPAIGNS, RoutePath.ADMIN, RoutePath.SETTINGS, RoutePath.LABS].includes(
      pathname as RoutePath
    ) ||
    eventSideNavRegex.test(pathname) ||
    campaignSideNavRegex.test(pathname) ||
    eventCatalogSideNavRegex.test(pathname) ||
    challengeSideNavRegex.test(pathname)
  ) {
    return <SideNav />;
  }
  return null;
};

export default GlobalSideNav;
