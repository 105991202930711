import { LabShutoffStatus } from '@/src/types/LabShutoff';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Badge } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LabStatusProps {
  labShutoffStatus?: LabShutoffStatus;
}

const LabStatus: React.FC<LabStatusProps> = ({ labShutoffStatus }) => {
  const { t } = useTranslation();
  if (!labShutoffStatus) {
    return null;
  }
  if (!labShutoffStatus.hasCompleteShutoff) {
    return <Badge color="green">{t(i18nKeys.eventLabs.labOverview.infoSections.on)}</Badge>;
  }
  if (labShutoffStatus.selfShutoff) {
    return <Badge color="blue">{t(i18nKeys.eventLabs.labOverview.infoSections.shutOff)}</Badge>;
  }

  return null;
};

export default LabStatus;
