import { ExpandableSection, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengeOverviewFields,
  ChallengePropAction,
  TITLE_MAX_LENGTH,
  TITLE_MIN_LENGTH,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { Challenge } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { KeyValue } from '../../../common/KeyValue';
import { Markdown } from '../../../common/Markdown';
import MarkDownPreviewEditor from '@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor';

interface ChallengeDetailsDetailProp {
  challenge: Challenge;
}

const ChallengeDetailsDetail: React.FC<ChallengeDetailsDetailProp> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp, challengeOverviewValidator } = useCreateChallenge();
  const [expanded, setExpanded] = useState(true);

  // error texts and validation logic
  const [titleErrorText, setTitleErrorText] = useState('');
  const [descriptionErrorText, setDescriptionErrorText] = useState('');

  useEffect(() => {
    // reset error fields when editMode is changed
    if (!editMode) {
      setTitleErrorText('');
      setDescriptionErrorText('');
    }
  }, [editMode]);

  const validator = challengeOverviewValidator(
    new Map<ChallengeOverviewFields, (error: string) => void>([
      [ChallengeOverviewFields.TITLE, (error: string) => setTitleErrorText(error)],
      [ChallengeOverviewFields.DESCRIPTION, (error: string) => setDescriptionErrorText(error)],
    ])
  );

  const renderContent = () => {
    return !editMode ? (
      <Columns columns={1} variant="default">
        <Column size="s">
          <KeyValue className="primary-text" label={<strong> {t(i18nKeys.challenges.challengeDetails.titles.challengeTitle)}</strong>}>
            {challenge.props.title}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={<strong> {t(i18nKeys.challenges.challengeDetails.titles.description)}</strong>}>
            {challenge.props.description && (
              <Markdown content={challenge.props.description} />
            )}
          </KeyValue>
        </Column>
      </Columns>
    ) : (
      editMode && editedChallenge && (
              <SpaceBetween direction="vertical" size="s">
        <Columns columns={1} variant="default">
          <Column size="s">
            <KeyValue className="primary-text" label={<strong> {t(i18nKeys.challenges.challengeDetails.titles.challengeTitle)}</strong>}>
              <FormField
                errorText={titleErrorText}
                constraintText={t(i18nKeys.challenges.subSections.overview.fields.title.constraint, {
                  TITLE_MIN_LENGTH,
                  TITLE_MAX_LENGTH,
                })}>
                <Input
                  type="text"
                  value={editedChallenge.props.title || ''}
                  onChange={({ detail }) => {
                    handleUpdateChallengeProp(ChallengePropAction.TITLE, detail.value);
                  }}
                  onBlur={() => validator.isValidField(ChallengeOverviewFields.TITLE)}
                  placeholder={t(i18nKeys.challenges.fields.title.placeholder)}
                />
              </FormField>
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="primary-text" label={<strong> {t(i18nKeys.challenges.challengeDetails.titles.description)}</strong>}>
              {/* <FormField
                errorText={descriptionErrorText}
                constraintText={t(i18nKeys.challenges.subSections.overview.fields.description.constraint, {
                  DESCRIPTION_MIN_LENGTH,
                  DESCRIPTION_MAX_LENGTH,
                })}>
                <Textarea
                  onChange={({ detail }) => handleUpdateChallengeProp(ChallengePropAction.DESCRIPTION, detail.value)}
                  value={editedChallenge.props.description || ''}
                  placeholder={t(i18nKeys.challenges.fields.description.placeholder)}
                  onBlur={() => validator.isValidField(ChallengeOverviewFields.DESCRIPTION)}
                />
              </FormField> */}
              <div style={{color:"#414D5C",fontSize:'12px'}}>{t(i18nKeys.challenges.subSections.overview.fields.description.constraint)}</div>
              <FormField
               stretch
               errorText={descriptionErrorText}>
              <MarkDownPreviewEditor text={editedChallenge.props.description || ''} onTextChanged={e => handleUpdateChallengeProp(ChallengePropAction.DESCRIPTION, e)} />
             </FormField>
            </KeyValue>
          </Column>
        </Columns>
        </SpaceBetween>
      )
    );
  };

  return (
    <ExpandableSection
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.details)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  );
};

export default ChallengeDetailsDetail;
