/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../store/challenge.context';
import { i18nKeys } from '../../utils/i18n.utils';
import { Pagination, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState } from '../common/TableEmptyState';
import { TableHeader } from '../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './review-challenges-list-config';
import { paginationLabels } from '../../utils/table.utils';
import { preProdLogger } from '../../utils/log.utils';

const ChallengesInReview: React.FC = () => {
  const { t } = useTranslation();
  const { challengesInReview, getChallenges } = useChallenges();
  const [loading, setLoading] = useState(true);

  const [preferences] = useState({
    pageSize: 15,
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    challengesInReview || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.challenges.reviewChallenges.table.emptyChallengesState)}
            subtitle={t(i18nKeys.challenges.reviewChallenges.table.emptyChallengesSubtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: {
        pageSize: preferences.pageSize,
      },
      sorting: {},
    }
  );

  useEffect(() => {
    setLoading(true);
    // challenges must be force loaded to retrieve latest
    // challenges in review if a user hits back
    getChallenges(false, true, true)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        preProdLogger('Error loading challenges', err);
      });
  }, []);

  return (
    <React.Fragment>
      <Table
        {...collectionProps}
        header={
          <TableHeader
            title={t(i18nKeys.challenges.reviewChallenges.header)}
            totalItems={(challengesInReview || []).length}
          />
        }
        items={items}
        columnDefinitions={COLUMN_DEFINITIONS()}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
            filteringAriaLabel={t(i18nKeys.challenges.filteringLabel)}
            filteringPlaceholder={t(i18nKeys.challenges.filteringLabel)}
          />
        }
        resizableColumns
        loading={loading}
        loadingText={t(i18nKeys.challenges.loadingChallenges)}
      />
    </React.Fragment>
  );
};

export default ChallengesInReview;
