import React, { useEffect } from 'react';
import JamChatContainer from '../../ui/organisms/JamChatContainer/JamChatContainer';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';

const JamTeamChat = () => {
  const { event } = useJamEventDetails();
  const hasTeamChatEnabled = event?.teamChatEnabled;
  useEffect(() => {
    if (!hasTeamChatEnabled) {
      window.close();
    }
  }, [hasTeamChatEnabled]);
  return <JamChatContainer isModal={false} />;
};

export default JamTeamChat;
