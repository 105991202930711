import React, { useEffect } from 'react';
import { Box, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import Header from '@/src/components/ui/organisms/CatalogDetails/Header';
import Overview from '@/src/components/ui/molecules/EventTemplate/CatalogDetails/Overview/Overview';
import ChallengeList from '@/src/components/ui/molecules/EventTemplate/CatalogDetails/ChallengeList/ChallengeList';
import { useToolPanel } from '@/src/store/tool-panel.context';
import BreadCrumbs from '../../root/BreadCrumbs';
import { useEditEventTemplate } from '@/src/store/edit-event-template.context';
import { getSelectedTopics } from '@/src/utils/event-template.utils';
import { useParams } from 'react-router';
import { LoadingBar } from '../../common/LoadingBar';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';
import { useLocation } from 'react-router-dom';
import { useEvents } from '@/src/store/events.context';
import { useUser } from '@/src/store/user.context';
import { useTranslation } from "react-i18next";
import { i18nKeys } from '@/src/utils/i18n.utils';

const CatalogDetails: React.FC = () => {
  const { t } = useTranslation();
  const { catalogId }: { catalogId: string } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const eventId = query.get('event');
  const { user } = useUser();

  const { toggleSideNav } = useToolPanel();
  const { eventTemplate, fetchPublicEventTemplateById, getEventDurationInfo } = useEditEventTemplate();
  const { getEventByName } = useEvents();
  const { fetchEventTemplateOffers } = useEventTemplateOffers();
  const topics = eventTemplate && getSelectedTopics(eventTemplate.topics);
  const hours = getEventDurationInfo()?.hours;

  useEffect(() => {
    toggleSideNav(false);
    void fetchPublicEventTemplateById(catalogId);
    void fetchEventTemplateOffers();
  }, []);

  useEffect(() => {
    if (eventId) void getEventByName(eventId);
  }, [eventId]);

  useEffect(() => {
    window.scrollTo(0, 0); // scroll to top on page load
  }, []);

  if (!eventTemplate) {
    return <LoadingBar />;
  }

  const defectiveChallenges = eventTemplate.challenges.filter(item => !item.backup && item.isDefective);
  const includedChallenges = eventTemplate.challenges.filter(item => !item.backup && !item.isDefective && item.isPublic);
  const privateChallenges = eventTemplate.challenges.filter(item => !item.backup && !item.isDefective && !item.isPublic);
  const isArrayNotEmpty = (arr: any[]) => { return arr.length > 0 }

  const columnWidth: number = user?.canScheduleAnEvent ? 7 : 10;
  return (
    <Box margin={{ bottom: 'l' }}>
      <div
        className={eventTemplate?.imagePresignedUrl ? '' : 'custom-home__header'}
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundBlendMode: 'hard-light',
          backgroundPosition: 'center',
          backgroundImage: eventTemplate?.imagePresignedUrl
            ? `linear-gradient(to right, rgba(0,0,0,.75) 45%, rgba(0,0,0,0)), url(${eventTemplate.imagePresignedUrl})`
            : '',
        }}>
        <Box padding={{ top: 's', horizontal: 's' }}>
          <Grid gridDefinition={[{ colspan: { default: 12, xxs: 10 }, offset: { xxs: 1 } }]}>
            <BreadCrumbs />
          </Grid>
        </Box>
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Header hasDefectiveOrPrivateChallenge={isArrayNotEmpty(defectiveChallenges) || isArrayNotEmpty(privateChallenges)}/>
        </Box>
      </div>

      <SpaceBetween direction="vertical" size="xl">
        <Box margin={{ top: 'l', bottom: 'xxl' }}>
          <Grid
            gridDefinition={[
              { colspan: { default: 10, l: columnWidth, xxs: 10 }, offset: { default: 1, l: 1, xxs: 1 } },
              { colspan: { default: 10, l: 10, xxs: 10 }, offset: { default: 1, l: 1, xxs: 1 } },
            ]}>
            <Overview eventTemplate={eventTemplate} topics={topics} hours={hours} />
            {/* <EventInformation
              services={eventTemplate.awsServices ?? []}
              outcomes={challengeData?.map((item) => item?.props.learningOutcome.summary ?? '').filter((item) => item)}
            /> */}
          </Grid>
        </Box>
      </SpaceBetween>

      {defectiveChallenges.length > 0 && (
        <>
          <div className="expandables-container">
            <Grid gridDefinition={[{colspan: {default: 10}, offset: {default: 1}}]}>
              <ChallengeList
                data-testid='defective-challenge-list'
                selectedChallenges={defectiveChallenges}
                title={t(i18nKeys.eventTemplates.catalogDetails.challengesList.defective)}
              />
            </Grid>
          </div>
        </>
      )}

      {privateChallenges.length > 0 && (
        <>
          <div className="expandables-container">
            <Grid gridDefinition={[{colspan: {default: 10}, offset: {default: 1}}]}>
              <ChallengeList
                data-testid='private-challenge-list'
                selectedChallenges={privateChallenges}
                title={t(i18nKeys.eventTemplates.catalogDetails.challengesList.private)}
              />
            </Grid>
          </div>
        </>
      )}

      {includedChallenges.length > 0 && (
        <>
          <div className="expandables-container">
            <Grid gridDefinition={[{colspan: {default: 10}, offset: {default: 1}}]}>
              <ChallengeList
                data-testid='included-challenge-list'
                selectedChallenges={includedChallenges}
                title={t(i18nKeys.eventTemplates.catalogDetails.challengesList.included)}
              />
            </Grid>
          </div>
        </>
      )}

      {/* 
        // TODO: add this back when Event offer page API supports it
        /* <Grid gridDefinition={[{ colspan: { default: 10 }, offset: { default: 1 } }]}>
        <Box margin={{ top: 'l', bottom: 'xxxl' }}>
          <Feedback
            reviews={reviews}
            handleSeeMoreClick={handleSeeMoreClick}
            filteringText={filteringText}
            setFilteringText={setFilteringText}
            eventTemplateRating={eventTemplateRating}
            selectedEventTemplateFeedbackOption={selectedEventTemplateFeedbackOption}
            setSelectedEventTemplateFeedbackOption={setSelectedEventTemplateFeedbackOption}
            toggleEventTemplateFeedbackReviewLikeStatus={toggleEventTemplateFeedbackReviewLikeStatus}
            withContainer={false}
          />
        </Box>
          </Grid> */}
    </Box>
  );
};

export default CatalogDetails;
