import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormField,
  Input,
  SpaceBetween,
  ContentLayout,
  Header,
  TextContent,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useFlashbars } from '@/src/store/flashbar.context';
import LocateSecretKeyModal from '@/src/components/game/JoinAnEvent/locateSecretKeyModal';
import { useHistory } from 'react-router-dom';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { getErrorMessage } from '@/src/utils/errors.utils';
import './index.scss';
import JamSpinner from '@/src/components/common/JamSpinner';
import JoinEventErrorModal from '../../ui/molecules/JoinEventErrorModal/JoinEventErrorModal';
import { HTTP_403 } from '@/src/constants/common';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { customEventTrigger } from '../../analytics/createEventTrigger';
import { useCampaigns } from '@/src/store/campaigns.context';

interface JoinJamProps {
  onDismiss: () => void;
  onJoinJam: (key: string) => Promise<void>;
  isVisible: boolean;
  loading: boolean;
}
const JoinAnEvent: React.FC<JoinJamProps> = (): JSX.Element => {
  const { t } = useTranslation();
  const [secretKey, setSecretKey] = useState('');
  const { addErrorFlashbar, clearFlashbars } = useFlashbars();
  const history = useHistory();
  const { resetEvent } = useJamEventDetails();
  const { getCampaignEventDetails } = useCampaigns();

  const [locateKeyModal, setLocateKeyModal] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const onJoinSubmit = async (key: string): Promise<void> => {
    try {
      setSpinner(true);
      setIsValidating(true);
      resetEvent();

      const campaignEventDetails = await getCampaignEventDetails(key);
      const eventType = campaignEventDetails.type;

      if (eventType === 'CAMPAIGN_GROUP') {
        const eventName = campaignEventDetails?.eventName || '';
        history.push(JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventName));
      } else {
        if (!campaignEventDetails.eventPath) {
          throw new Error('Event not found.');
        }
        history.push(JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(campaignEventDetails.eventPath));
      }
    } catch (e: any) {
      if (e?.status === HTTP_403) {
        setErrorModalVisible(true);
      } else {
        addErrorFlashbar(getErrorMessage(e));
      }
    }
    setSpinner(false);
    setIsValidating(false);
    setLocateKeyModal(false);
  };
  const [secretKeyError, setSecretKeyError] = useState('');

  const joinEventClick = () => {
    customEventTrigger('click', 'Join an Event', window.location.href, 'Join an Event', {});
    clearFlashbars();
    if (!secretKey.trim()) {
      setSecretKeyError(t(i18nKeys.myJams.joinAnEvent.secretKeyError));
      return;
    }
    void onJoinSubmit(secretKey);
    setSecretKey('');
  };

  if (spinner) {
    return <JamSpinner />;
  }

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h2">{t(i18nKeys.myJams.joinAnEvent.title)}</Header>
        </SpaceBetween>
      }>
      <div className="container-custom">
        <Container header={<Header variant="h3">{t(i18nKeys.myJams.joinAnEvent.heading)}</Header>}>
          <TextContent>
            <p className="description">{t(i18nKeys.myJams.joinAnEvent.description)}</p>
          </TextContent>
          <SpaceBetween size="xs">
            <Box data-classname="join-an-event">
              <TextContent>
                <p className="text_secrets">{t(i18nKeys.myJams.joinAnEvent.secretKey)}</p>
              </TextContent>
              <div className="form_field">
                <FormField errorText={secretKeyError}>
                  <Input
                    id="secretKeyTextBox"
                    type="password"
                    onChange={({ detail }) => setSecretKey(detail.value)}
                    value={secretKey}
                    ariaLabel={t(i18nKeys.myJams.joinAnEvent.secretKey)}
                  />
                  {/* commenting this code as it will implement in the future
                   <div className="text_blue" onClick={() => setLocateKeyModal(true)}>
                    {t(i18nKeys.myJams.joinAnEvent.linkText)}
                  </div> */}
                  <Box float="right">
                    <div id="joinEventSecretKeyButton" data-testid="joinEventSecretKeyButton" onClick={joinEventClick}>
                      <Button variant="primary" ariaLabel="jam-event" disabled={spinner}>
                        {spinner ? <JamSpinner /> : <>{t(i18nKeys.myJams.joinAnEvent.btnText)}</>}
                      </Button>
                    </div>
                  </Box>
                </FormField>
              </div>
            </Box>
          </SpaceBetween>
        </Container>
        <LocateSecretKeyModal
          isVisible={locateKeyModal}
          loading={isValidating}
          onDismiss={() => setLocateKeyModal(false)}
          onSubmit={onJoinSubmit}
        />
        <JoinEventErrorModal visible={errorModalVisible} handleClose={() => setErrorModalVisible(false)} />
      </div>
    </ContentLayout>
  );
};

export default JoinAnEvent;
