import React, { useEffect, useState } from 'react';

import { PropertyFilterProperty, useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  PaginationProps,
  PropertyFilter,
  PropertyFilterProps,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';

import './CustomTable.scss';
import { IEventTemplate, IEventTemplatePage, ISortingState } from '@/src/types/EventTemplate';

import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { SelectionType } from '../EventTemplateModel';

const EmptyState = ({ title, subtitle, action }: { title: string; subtitle: string; action: React.ReactNode }) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
};

interface IProps<T> {
  visibleContent: string[];
  data: T[];
  columnDefinitions: TableProps.ColumnDefinition<T>[];
  headerTitle: string;
  filterPlaceholder: string;
  collectionPreferencesProps: CollectionPreferencesProps;
  paginationLabels: PaginationProps.Labels;
  filteringProperties: PropertyFilterProperty[];
  headerRightContent?: React.ReactNode;
  noAction?: boolean;
  selectionType?: SelectionType;
  variant?: TableProps.Variant;
  page?: IEventTemplatePage;
  totalCount?: number
  selectedItemsCb?: (selectedItems: T[]) => void;
  propertyFilterPropsCb?: (propertyFilterProps: PropertyFilterProps) => void;
  onFilterCb?: (propertyFilterQuery: PropertyFilterProps.Query) => void;
  onSortingChangeCb?: (sortingState: ISortingState) => void;
  allPageItemsCb?: (items: T[]) => void;
  pageChangeCb?: (currentPageIndex: number) => void;
  pageSizeChangeCb?: (pageSize: number) => void;
}

export const CustomTableServerSide = <T extends IEventTemplate>({
  visibleContent,
  data,
  columnDefinitions,
  headerTitle,
  filterPlaceholder,
  collectionPreferencesProps,
  paginationLabels,
  selectionType = undefined,
  filteringProperties,
  headerRightContent,
  page,
  totalCount = 0,
  noAction = false,
  selectedItemsCb = () => {
    // do nothing
  },
  propertyFilterPropsCb = () => {
    // do nothing
  },
  onFilterCb = () => {
    // do nothing
  },
  onSortingChangeCb = () => {
    // do nothing
  },
  allPageItemsCb = () => {
    // do nothing
  },
  pageChangeCb = () => {
    // do nothing
  },
  pageSizeChangeCb = () => {
    // do nothing
  },
  variant = 'full-page',
}: IProps<T>) => {
  const [preferences, setPreferences] = useState({
    pageSize: page?.limit, // default page size for event catelog template list
    visibleContent,
  });

  const { t } = useTranslation();

  const { allPageItems, items, actions, collectionProps, paginationProps, propertyFilterProps } = useCollection(data, {
    filtering: {
      empty: (
        <EmptyState
          title={t(i18nKeys.eventTemplates.customTable.empty.noTitle, { headerTitle })}
          subtitle={t(i18nKeys.eventTemplates.customTable.empty.noSubTitle, { headerTitle })}
          action={<Button>{t(i18nKeys.eventTemplates.customTable.empty.createTitle, { headerTitle })}</Button>}
        />
      ),
      noMatch: (
        <EmptyState
          title={t(i18nKeys.eventTemplates.customTable.noMatch.title)}
          subtitle={t(i18nKeys.eventTemplates.customTable.noMatch.subTitle)}
          action={
            <Button onClick={() => actions.setFiltering('')}>
              {t(i18nKeys.eventTemplates.customTable.noMatch.clearFilter)}
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize, },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties,
    },
  });
  const { selectedItems } = collectionProps;

  useEffect(() => {
    if (selectedItemsCb) {
      selectedItemsCb(selectedItems as T[]);
    }

    if (propertyFilterPropsCb) {
      propertyFilterPropsCb(propertyFilterProps as PropertyFilterProps);
    }

    if (allPageItemsCb) {
      allPageItemsCb(allPageItems as T[]);
    }
  }, [selectedItems, selectedItemsCb, propertyFilterProps, propertyFilterPropsCb, allPageItems, allPageItemsCb]);

  useEffect(() => {
    if (preferences.pageSize && preferences.pageSize !== page?.limit) {
      pageSizeChangeCb(preferences.pageSize);
    }
  }, [preferences.pageSize, page?.limit]);

  return (
    <Table
      {...collectionProps}
      variant={variant}
      selectionType={selectionType}
      stickyColumns={{ first: 0, last: 1 }}
      header={
        noAction ? undefined : (
          <div className="table-header">
            <Header counter={`(${totalCount ?? 0})`}>
              {headerTitle}
            </Header>
            {headerRightContent && <div>{headerRightContent}</div>}
          </div>
        )
      }
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences.visibleContent}
      items={items}
      pagination={
        noAction ? undefined : (
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
            currentPageIndex={(page?.currentPageNumber as number) || 1}
            pagesCount={(page?.pageCount as number) || 1}
            onChange={({ detail }) => pageChangeCb(detail.currentPageIndex)}
          />
        )
      }
      resizableColumns
      filter={
        noAction ? undefined : (
          <PropertyFilter
            id="filter-event-template"
            filteringProperties={propertyFilterProps.filteringProperties}
            filteringOptions={propertyFilterProps.filteringOptions}
            query={page?.query as PropertyFilterProps.Query}
            i18nStrings={{
              filteringAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.filteringAriaLabel),
              dismissAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.dismissAriaLabel),
              filteringPlaceholder: filterPlaceholder,
              groupValuesText: t(i18nKeys.eventTemplates.customTable.filter.groupValuesText),
              groupPropertiesText: t(i18nKeys.eventTemplates.customTable.filter.groupPropertiesText),
              operatorsText: t(i18nKeys.eventTemplates.customTable.filter.operatorsText),
              operationAndText: t(i18nKeys.eventTemplates.customTable.filter.operationAndText),
              operationOrText: t(i18nKeys.eventTemplates.customTable.filter.operationOrText),
              operatorLessText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessText),
              operatorLessOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessOrEqualText),
              operatorGreaterText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterText),
              operatorGreaterOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterOrEqualText),
              operatorContainsText: t(i18nKeys.eventTemplates.customTable.filter.operatorContainsText),
              operatorDoesNotContainText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotContainText),
              operatorEqualsText: t(i18nKeys.eventTemplates.customTable.filter.operatorEqualsText),
              operatorDoesNotEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotEqualText),
              editTokenHeader: t(i18nKeys.eventTemplates.customTable.filter.editTokenHeader),
              propertyText: t(i18nKeys.eventTemplates.customTable.filter.propertyText),
              operatorText: t(i18nKeys.eventTemplates.customTable.filter.operatorText),
              valueText: t(i18nKeys.eventTemplates.customTable.filter.valueText),
              cancelActionText: t(i18nKeys.eventTemplates.customTable.filter.cancelActionText),
              applyActionText: t(i18nKeys.eventTemplates.customTable.filter.applyActionText),
              allPropertiesLabel: t(i18nKeys.eventTemplates.customTable.filter.allPropertiesLabel),
              tokenLimitShowMore: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowMore),
              tokenLimitShowFewer: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowFewer),
              clearFiltersText: t(i18nKeys.eventTemplates.customTable.filter.clearFiltersText),
            }}
            onChange={({ detail }) => onFilterCb(detail)}
          />
        )
      }
      preferences={
        noAction ? undefined : (
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail as typeof preferences)}
          />
        )
      }
      sortingColumn={page?.sortingState.sortingColumn}
      sortingDescending={page?.sortingState.isDescending}
      onSortingChange={({ detail }) => onSortingChangeCb(detail as ISortingState)}
    />
  );
};
