import {
  Alert,
  Button,
  Container,
  FormField,
  Header,
  Icon,
  Link,
  RadioGroup,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_DETAILS_ROUTES } from '../../../routes';
import { useApi } from '../../../store/api.context';
import { useChallenges } from '../../../store/challenge.context';
import { useFlashbars } from '../../../store/flashbar.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { useUser } from '../../../store/user.context';
import { Campaign } from '../../../types/Campaign';
import {
  ChallengeDescriptor,
  ChallengeDifficulty,
  ChallengeForImport,
  ChallengeListItem,
  isMissingSupportedRegions,
} from '../../../types/Challenge';
import { ChallengeSet } from '../../../types/ChallengeSet';
import { Nullable } from '../../../types/common';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { fromPlainObject } from '../../../utils/mapper.utils';
import DifficultyIndicator from '../../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { ChallengeSetSelection } from '../ChallengeSetSelection';
import { HorizontalRule } from '../HorizontalRule';
import { KeyValue } from '../KeyValue';
import { LoadingBar } from '../LoadingBar';
import { TinyEventSelection } from '../TinyEventSelection';
import ChallengeDataImportModal from './ChallengeDataImportModal';
import { TFunction } from 'i18next';
import { ChallengeTabIds, ImportSource } from '../CommonModel';

interface ImportChallengesProps {
  handleChallengeAction?: (payload: ChallengeDescriptor[], toggleMode?: boolean) => void;
  currentChallengeDescriptors: ChallengeDescriptor[];
  target: Event | Campaign;
  navigateToTab: Dispatch<SetStateAction<string>>;
}

export const getChallengeForImport = (
  target: Event | Campaign,
  challengeListItem: Nullable<ChallengeListItem>,
  challengeId: string,
  t: TFunction,
  cd?: ChallengeDescriptor
): ChallengeForImport => {
  const challengeForImport: ChallengeForImport = {
    id: challengeListItem?.challengeId || '',
    title: null,
    warmup: false,
    difficulty: 0,
    difficultyDesc: t(ChallengeDifficulty.FUNDAMENTAL.i18nKeyShort),
    ssh: false,
    solvedRecently: true,
    defective: false,
    missingSupportedRegions: false,
    missing: false,
  };
  const isEvent = target instanceof Event;

  if (challengeListItem) {
    challengeForImport.title = challengeListItem.props.title;
    challengeForImport.ssh = challengeListItem.props.sshKeyPairRequired;
    challengeForImport.solvedRecently = challengeListItem.globalStatistics.solvedRecently();
    challengeForImport.defective = challengeListItem.isDefective;
    if (isEvent) {
      challengeForImport.missingSupportedRegions = isMissingSupportedRegions(challengeListItem, target);
    }

    challengeForImport.warmup =
      cd && cd.overrides.challengeAlwaysOn != null
        ? cd.overrides.challengeAlwaysOn
        : challengeListItem.props.challengeAlwaysOn;

    challengeForImport.difficulty =
      cd && cd.overrides.difficulty != null ? cd.overrides.difficulty : challengeListItem.props.difficulty;
  } else {
    challengeForImport.title = challengeId || '';
    challengeForImport.missing = true;

    challengeForImport.warmup = cd && cd.overrides.challengeAlwaysOn != null ? cd.overrides.challengeAlwaysOn : false;

    challengeForImport.difficulty = cd && cd.overrides.difficulty != null ? cd.overrides.difficulty : 0;
  }

  challengeForImport.difficultyDesc = t(ChallengeDifficulty.getByKey(challengeForImport.difficulty).i18nKeyShort);

  return fromPlainObject(challengeForImport, ChallengeForImport) as ChallengeForImport;
};

const ImportChallenges: React.FC<ImportChallengesProps> = ({
  handleChallengeAction,
  currentChallengeDescriptors,
  target,
  navigateToTab,
}) => {
  const [selectedEvent, setSelectedEvent] = useState<Nullable<Event>>(null);
  const [selectedChallengeSet, setSelectedChallengeSet] = useState<Nullable<ChallengeSet>>(null);
  const { toggleChallengeInfo } = useToolPanel();
  const { challengeSets, getChallengeListItemFromChallengeId, getChallengeDescriptor } = useChallenges();
  const [loading, setLoading] = useState(false);
  const { eventsApi } = useApi();
  const { addSuccessFlashbar } = useFlashbars();
  const { t } = useTranslation();
  const { user } = useUser();
  const importSourceOptions = [
    {
      label: t(i18nKeys.challenges.importChallenges.fields.chooseSource.previousEvent),
      value: ImportSource.PREVIOUS_EVENT,
    },
    {
      label: t(i18nKeys.challenges.importChallenges.fields.chooseSource.challengeSet),
      value: ImportSource.CHALLENGE_SET,
    },
  ];
  const [importSource, setImportSource] = useState(importSourceOptions[0].value);
  const [importTeamPropertiesVisible, setImportTeamPropertiesVisible] = useState(false);
  const isEvent = target instanceof Event;

  const getEventFromSelection = (option: OptionDefinition) => {
    if (!_.isEmpty(option?.value)) {
      setLoading(true);
      const getEvent = async () => {
        await eventsApi.getEvent(option?.value as string).then((res) => {
          setSelectedEvent(res.event);
          setLoading(false);
        });
      };
      getEvent().catch((err) => preProdLogger(err));
    } else {
      setSelectedEvent(null);
    }
  };

  const handleSelectedChallengeSet = (challengeSet: OptionDefinition) => {
    if (challengeSets && challengeSet) {
      const foundChallengeSet = challengeSets.find((cs: ChallengeSet) => cs.id === challengeSet.value);
      if (foundChallengeSet) {
        setSelectedChallengeSet(foundChallengeSet);
      }
    }
  };

  const createChallengesForImport = (challengeId: string, cd?: ChallengeDescriptor): ChallengeForImport => {
    const challengeListItem: Nullable<ChallengeListItem> = getChallengeListItemFromChallengeId(challengeId);
    return getChallengeForImport(target, challengeListItem, challengeId, t, cd);
  };

  const selectedSource = importSource === ImportSource.PREVIOUS_EVENT ? selectedEvent : selectedChallengeSet;
  const selectedSourceChallenges: ChallengeForImport[] | undefined =
    importSource === ImportSource.PREVIOUS_EVENT
      ? selectedEvent?.challengeDescriptors.map((cd) => createChallengesForImport(cd.challengeId || ''))
      : selectedChallengeSet?.challengeIds.map((challengeId) => createChallengesForImport(challengeId));

  const addToEvent = () => {
    const challengeDescriptorsToCopy: ChallengeDescriptor[] = [...currentChallengeDescriptors];
    selectedSourceChallenges?.forEach((challenge: ChallengeForImport | undefined) => {
      if (!challenge?.missing && challenge?.id) {
        if (currentChallengeDescriptors.findIndex((cd: ChallengeDescriptor) => cd.challengeId === challenge?.id) < 0) {
          const challengeListItem = getChallengeListItemFromChallengeId(challenge?.id);
          if (challengeListItem) {
            const challengeDescriptor = getChallengeDescriptor(challengeListItem);
            if (challengeDescriptor) {
              challengeDescriptorsToCopy.push(challengeDescriptor);
            }
          }
        }
      }
    });
    if (handleChallengeAction) {
      handleChallengeAction(challengeDescriptorsToCopy, true);
      setImportSource(importSourceOptions[0].value);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      addSuccessFlashbar(
        t(i18nKeys.challenges.importChallenges.successfullyAdded, {
          challengeDescriptorsToCopyLength: challengeDescriptorsToCopy.length - currentChallengeDescriptors.length,
        })
      );
      navigateToTab(ChallengeTabIds.SELECTED_CHALLENGES);
    }
  };

  return (
    <Container
      header={
        <Header
          variant="h2"
          description={t(i18nKeys.challenges.importChallenges.description)}
          actions={[
            user?.isEventAdmin && isEvent && (
              <Button
                key="import-team-properties"
                variant="primary"
                onClick={() => setImportTeamPropertiesVisible(true)}
                disabled={!target.canEditAttribute('importTeamProperties', user)}>
                <Icon name="upload" className="mr-5" />
                {t(i18nKeys.challenges.importChallenges.buttons.importTeamProperties)}
              </Button>
            ),
          ]}>
          {t(i18nKeys.challenges.importChallenges.title)}
        </Header>
      }>
      {isEvent && (
        <ChallengeDataImportModal
          target={target}
          isVisible={importTeamPropertiesVisible}
          toggleVisible={setImportTeamPropertiesVisible}
          challengeIds={target.challengeDescriptors.map((cd: ChallengeDescriptor) => cd.challengeId || '')}
        />
      )}
      <FormField label={<strong>{t(i18nKeys.challenges.importChallenges.fields.chooseSource.title)}</strong>}>
        <RadioGroup
          onChange={({ detail }) => {
            setSelectedEvent(null);
            setSelectedChallengeSet(null);
            setImportSource(detail.value as ImportSource);
          }}
          value={importSource}
          items={importSourceOptions}
        />
      </FormField>
      <HorizontalRule />
      {importSource === ImportSource.PREVIOUS_EVENT && (
        <TinyEventSelection handleSelectedEvent={getEventFromSelection} />
      )}
      {importSource === ImportSource.CHALLENGE_SET && (
        <ChallengeSetSelection handleSelectedChallengeSet={handleSelectedChallengeSet} />
      )}

      {selectedSource && !loading && (
        <React.Fragment>
          <h4>
            {importSource === ImportSource.PREVIOUS_EVENT ? (
              <React.Fragment>
                {t(i18nKeys.challenges.importChallenges.fields.reviewChallengeList)}
                {selectedEvent && selectedEvent.id && (
                  <Link href={EVENT_DETAILS_ROUTES.Challenges.resolve(selectedEvent?.id)} external>
                    {selectedEvent?.title}
                  </Link>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {t(i18nKeys.challenges.importChallenges.fields.reviewChallengeSet)}
                <Link href={`/challenges/set/${selectedChallengeSet?.id}`}>{selectedChallengeSet?.title}</Link>
              </React.Fragment>
            )}
          </h4>
          <SpaceBetween direction="vertical" size="s">
            <Alert type="info">{t(i18nKeys.challenges.importChallenges.fields.challengeSets.warning)}</Alert>
            {selectedSource instanceof ChallengeSet && selectedSource.description && (
              <KeyValue
                label={<strong>{t(i18nKeys.challenges.importChallenges.fields.challengeSets.description)}</strong>}>
                {selectedSource.description}
              </KeyValue>
            )}
            {selectedSourceChallenges?.map((challenge: ChallengeForImport | undefined, i) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              if (challenge) {
                const challengeListItem = getChallengeListItemFromChallengeId(challenge?.id);
                return (
                  <div key={`challenge-descriptor-${i}`}>
                    {!challenge?.missing && challengeListItem && (
                      <div onClick={() => toggleChallengeInfo(challengeListItem)} className="mr-5 inline">
                        {i + 1}. <Link>{challenge.title}</Link>
                      </div>
                    )}
                    {challenge.missing && <div className="mr-5 inline">{`${i + 1}. ${challenge.title}`}</div>}
                    <DifficultyIndicator inline inlineWarmup challenge={challenge} />
                  </div>
                );
              }
            })}
          </SpaceBetween>
          <HorizontalRule evenMargins />
          <div style={{ width: '100%', height: '24px' }}>
            <Button onClick={() => addToEvent()} className="button-right" variant="primary">
              {t(i18nKeys.challenges.importChallenges.buttons.addToEvent)}
            </Button>
          </div>
        </React.Fragment>
      )}
      {loading && <LoadingBar />}
    </Container>
  );
};

export default ImportChallenges;
