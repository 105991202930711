/* eslint-disable @typescript-eslint/unbound-method */
export class SkillProfile {
  login: string;
  lastUpdatedDate: string;
  skillRating: { [key: string]: number };

  static fromPlainObject(obj: any): SkillProfile {
    return Object.assign(new SkillProfile(), obj) as SkillProfile;
  }
}

export class Participant {
  login: string;
  nickname: string;
  optedInToPartnerDataSharing: boolean;
  prizeCode: string;
  preferredLanguageCode: string;
  meetingParticipantIdentifier: string;
  skillProfile: SkillProfile;

  static fromPlainObject(obj: any): Participant {
    return Object.assign(new Participant(), obj) as Participant;
  }
}

class DashboardJamParticipant {
  eventName: string;
  login: string;
  teamName: string;
  isFacilitator: boolean;

  static fromPlainObject(obj: any): DashboardJamParticipant {
    return Object.assign(new DashboardJamParticipant(), obj) as DashboardJamParticipant;
  }
}

export class DashboardJamEvent {
  name: string;
  id: string;
  title: string;
  urlPath: string;
  description: string;
  startDate: string;
  endDate: string;
  status: string;
  numChallenges: number;
  maxTeamSize: number;
  participant: DashboardJamParticipant;
  teamRank: number;
  teamNumSolved: number;
  numTeams: number;
  test = false;
  audienceType: string;
  isGamified: boolean;

  static fromPlainObject(obj: any): DashboardJamEvent {
    const event: DashboardJamEvent = Object.assign(new DashboardJamEvent(), obj) as DashboardJamEvent;
    if (event.participant) {
      event.participant = DashboardJamParticipant.fromPlainObject(event.participant);
    }
    return event;
  }
}

export class Prize {
  description: string;
  eventName: string;
  awardedDate: number;
  claimed = false;

  static fromPlainObject(obj: any): Prize {
    return Object.assign(new Prize(), obj) as Prize;
  }
}

export class UserDashboardData {
  participant: Participant;
  events: DashboardJamEvent[] = [];
  pastEvents: DashboardJamEvent[] = [];
  prizes: Prize[] = [];

  static fromPlainObject(obj: any): UserDashboardData {
    const data = Object.assign(new UserDashboardData(), obj) as UserDashboardData;
    if (data.participant) {
      data.participant = Participant.fromPlainObject(data.participant);
    }
    data.events = (data.events || []).map(DashboardJamEvent.fromPlainObject);
    data.pastEvents = (data.pastEvents || []).map(DashboardJamEvent.fromPlainObject);
    data.prizes = (data.prizes || []).map(Prize.fromPlainObject);

    // sort prizes by date
    data.prizes.sort((a: Prize, b: Prize) => b.awardedDate - a.awardedDate);

    return data;
  }
}

export interface UserDashboardEventResponse {
  events: DashboardJamMiniEvent[];
}

export enum JamEventTypes {
  ActiveEvents = 'activeEvents',
  UpcomingEvents = 'upcomingEvents',
  PastEvents = 'pastEvents',
}

export class DashboardJamMiniEvent {
  title: string;
  status: string;
  startTime: string;
  endTime: string;
  eventId: string;
  eventPath: string;
  campaignEvent: boolean;
  progress: {
    solvedChallenges: number;
    totalChallenges: number;
  };
}

export enum JamEventStatus {
  READY_TO_START = 'READY_TO_START',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
}
