import React, { useEffect } from 'react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import LearnSomethingAndFeedback from './LearnSomethingAndFeedback';
import RatingContainer from './RatingContainer';
import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import { useJamFeedback } from '@/src/store/jam-feedback';
import { JamEventFeedback } from '@/src/types/JamFeedback';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { useHistory } from 'react-router-dom';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';

interface IEventFeedbackData {
  challengeFeedback?: any;
  eventFeedback?: any;
}

const EventFeedback: React.FC<{ onFeedbackDone?: () => void }> = ({ onFeedbackDone }) => {
  const { t } = useTranslation();
  const { saveJamEventFeedbackData } = useJamFeedback();
  const { eventFeedbackData } = useJamChallenge();
  const { clearFlashbars } = useFlashbars();

  const { eventName } = useJamEventDetails();
  const history = useHistory();

  const [eventFeedback, setEventFeedback] = React.useState<JamEventFeedback>({
    eventRank: 0,
    speakerRank: 0,
    notes: '',
    didYouLearnSomethingNew: '',
  });

  useEffect(() => {
    if (eventFeedbackData && (eventFeedbackData as unknown as IEventFeedbackData)?.eventFeedback) {
      const feedback: JamEventFeedback = eventFeedbackData.eventFeedback;
      if (feedback) {
        const obj = {
          eventRank: feedback.eventRank || 0,
          speakerRank: feedback.speakerRank || 0,
          notes: feedback.notes || '',
          didYouLearnSomethingNew:
            feedback.didYouLearnSomethingNew === true ? 'yes' : feedback.didYouLearnSomethingNew === false ? 'no' : '',
        };
        setEventFeedback(obj);
      }
    }
  }, [eventFeedbackData]);

  const handleEventFeedback = (name: string, value: number | number) => {
    setEventFeedback({ ...eventFeedback, [name]: value });
  };

  const handleEventRadioAndComment = (name: string, value: string) => {
    setEventFeedback({ ...eventFeedback, [name]: value });
  };

  const handleSubmit = () => {
    clearFlashbars();
    void saveJamEventFeedbackData({
      body: {
        ...eventFeedback,
        didYouLearnSomethingNew: eventFeedback.didYouLearnSomethingNew === 'yes' ? true : false,
      },
    }).finally(() => {
      onFeedbackDone?.();
    });
    history.push(JAM_EVENT_DETAILS_ROUTES.Challenges.resolve([eventName]));
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      <RatingContainer
        label={t(i18nKeys.myJams.feedback.label.rating.overAll)}
        name="eventRank"
        rating={eventFeedback.eventRank}
        setRating={handleEventFeedback}
      />
      <RatingContainer
        label={t(i18nKeys.myJams.feedback.label.rating.staff)}
        name="speakerRank"
        rating={eventFeedback.speakerRank}
        setRating={handleEventFeedback}
      />
      <LearnSomethingAndFeedback
        radio={eventFeedback.didYouLearnSomethingNew}
        textArea={eventFeedback.notes}
        radioName="didYouLearnSomethingNew"
        textAreaName="notes"
        setValue={handleEventRadioAndComment}
      />
      <Box float="right">
        <Button variant="primary" onClick={handleSubmit}>
          {t(i18nKeys.myJams.feedback.buttons.submitFeedback)}
        </Button>
      </Box>
    </SpaceBetween>
  );
};

export default EventFeedback;
