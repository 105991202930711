import * as React from 'react';
import { Box, Container, ContentLayout, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getEnvVar } from '@/src/utils/env-var.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import CfnReadOnly from '@/src/components/common/Challenges/CfnReadOnly';
import { useChallenges } from '@/src/store/challenge.context';
import { useEffect } from 'react';


const ChallengeResources: React.FC = () => {
    const { t } = useTranslation();
    const { challengeResources, getChallengeResources } = useChallenges();
    const { challengeId }: { challengeId: string } = useParams();
    const [s3Key, setS3Key] = React.useState('');

    /**
     * Get the fully qualified S3 key of the object with the challenge id folder.
     */

    const getChallengeResourceFullS3Key = (resourceName: string): string => {
        return `${challengeId}/${resourceName}`;
    };

    useEffect(() => {
        if (challengeResources?.length > 0) {
            setS3Key(getChallengeResourceFullS3Key(challengeResources[0].key || ''))
        }
    }, []);

    React.useLayoutEffect(() => {
        void getChallengeResources(challengeId || '');
    }, []);

    const bucketCfn = `!Sub ${getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME')}-\${AWS::Region}`;

    const lambdaCodeCfn = ['Code:', '  S3Bucket:', `    ${bucketCfn}`, '  S3Key:', `    ${s3Key}`].join('\n');

    return (
        <ContentLayout header={
            <Header variant="h1">
                {t(i18nKeys.challenges.subSections.cfnTemplate.resourcesTitle)}
            </Header>
        }>
            <Container>
                <Box>
                    <SpaceBetween size="s">
                        <Box>
                            <h2>{t(i18nKeys.challenges.challengeDetails.titles.regionalResourceBucket)}</h2>
                            <p>{t(i18nKeys.challenges.challengeDetails.messages.resourceAutomaticallyReplicated)}</p>
                            <p>
                                {t(i18nKeys.challenges.challengeDetails.messages.preventLabFailuresPartOne)}{' '}
                                <strong>{t(i18nKeys.challenges.challengeDetails.messages.regionalBucket)}</strong>{' '}
                                {t(i18nKeys.challenges.challengeDetails.messages.preventLabFailuresPartTwo)}
                            </p>
                            <CfnReadOnly cfn={bucketCfn} />
                        </Box>
                        <Box>
                            <h2>{t(i18nKeys.challenges.challengeDetails.titles.resourceKey)}</h2>
                            <p>{t(i18nKeys.challenges.challengeDetails.messages.resourceKey)}</p>
                            <CfnReadOnly cfn={s3Key} />
                        </Box>
                        <Box>
                            <h2>{t(i18nKeys.challenges.challengeDetails.titles.lambdaFunctionExample)}</h2>
                            <p>{t(i18nKeys.challenges.challengeDetails.messages.lambdaFunctionExmaple)}</p>
                            <CfnReadOnly cfn={lambdaCodeCfn} />
                        </Box>
                    </SpaceBetween>
                </Box>
            </Container>
        </ContentLayout>
    );
};

export default ChallengeResources;
