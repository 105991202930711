import React from 'react';
import { Container, SpaceBetween, Box, Icon } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { EventDurationType, EventLearningType } from '@/src/types/EventTemplate';
import { getSelectedTopics } from '@/src/utils/event-template.utils';
import { eventTemplateCatalogDetailsRoute } from '@/src/routes';
import { EventLearningTypeBadge } from '@/src/components/ui/molecules/EventTemplate';
import './EventCard.scss';
import { customEventTrigger } from '@/src/components/analytics/createEventTrigger';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';

interface IProps {
  id: string;
  imgSrc?: string;
  rating: number;
  tag: string[];
  title: string;
  learningType: EventLearningType;
  duration: EventDurationType;
  teamSize: number;
  navigationDisabled?: boolean;
  isSubscribed?: boolean;
}

const EventCard: React.FC<IProps> = ({
  id,
  imgSrc,
  teamSize,
  tag,
  title,
  learningType,
  duration,
  navigationDisabled,
}) => {
  const { t } = useTranslation();
  const { offers } = useEventTemplateOffers();
  const hours = offers ? offers.get(duration)?.hours : '';
  const onCardClick = () => {
    customEventTrigger('linkclick', 'Event Details', `${window.location.href}`, 'Event Details', {});
  };

  return (
    <Link
      className="event-card__container"
      to={navigationDisabled ? '#' : eventTemplateCatalogDetailsRoute.resolvePath(id)}
      onClick={onCardClick}
      tabIndex={-1}>
      <Container
        disableContentPaddings
        media={{
          content: (
            <div className="container-content">
              <div className="learning-type-container">
                <EventLearningTypeBadge learningType={learningType} />
              </div>
              <img src={imgSrc || '/assets/event-template-palceholder.png'} alt="placeholder" />
            </div>
          ),
          height: 154,
        }}>
        <div className="event-card-content">
          <Box data-classname="topic">
            {getSelectedTopics(tag)
              .map((topic) => topic.label)
              .slice(0, 1)
              .join(', ') || <div>&nbsp;</div>}
          </Box>
          <Box margin={{ bottom: 'xxs' }}>
            <h4 title={title}>{title}</h4>
          </Box>
          <SpaceBetween data-classname="more-info" direction="horizontal" size="l">
            <Box data-classname="more-info" fontSize="body-s">
              <Icon name="group" />
              {t(i18nKeys.catalog.countParticipants, { count: teamSize > 2 ? 2 : teamSize })}
            </Box>
            <Box data-classname="more-info" fontSize="body-s">
              <Icon name="status-pending" />
              {hours} hrs
            </Box>
          </SpaceBetween>
        </div>
      </Container>
    </Link>
  );
};

export default EventCard;
