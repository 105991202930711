import moment from 'moment-timezone';
import { Moment } from 'moment';
import { DashboardJamEvent } from '../types/JamDashboard';
import { JamEventStatus } from '../types/JamCommon';

export const isStarted = (event: DashboardJamEvent) => {
  return event.status === JamEventStatus[JamEventStatus.ONGOING];
};

export const isEnded = (event: DashboardJamEvent) => {
  return event.status === JamEventStatus[JamEventStatus.ENDED];
};

export const isStartingSoon = (event: DashboardJamEvent) => {
  if (isEnded(event) || isStarted(event)) {
    return false;
  }

  const start: Moment = moment.parseZone(event.startDate);
  const now: Moment = moment();
  const hoursTillStart = start.diff(now, 'hours');

  return hoursTillStart < 25;
};

export const isEndedRecently = (event: DashboardJamEvent) => {
  if (!isEnded(event)) {
    return false;
  }

  const end: Moment = moment.parseZone(event.endDate);
  const now: Moment = moment();
  const hoursSinceEnd = now.diff(end, 'hours');

  return hoursSinceEnd < 5;
};

export const isActive = (e: DashboardJamEvent) => {
  return isStarted(e) || isStartingSoon(e) || isEndedRecently(e);
};

export const formatJamEventTime = (dateTime: string) => {
  return moment(dateTime).format('MM/DD/YY hh:mm A');
};

export const jamChallengesRegex =
  /^\/[a-zA-Z0-9_-]+\/(challenges|team|feedback|messages|meetings|leaderboard|facilitator)/;

export const eventSideNavRegex = /^\/events\/[a-zA-Z0-9_-]/;
export const campaignSideNavRegex = /^\/campaigns\/[a-zA-Z0-9_-]/;

export const eventCatalogSideNavRegex = /^\/event-catalog-templates\/[a-zA-Z0-9_-]/;

export const gradientWithoutSidebar = /^\/[a-zA-Z0-9_-]+\/(report)/;

export const jamChallengeTaskRegex =
  /^\/[a-zA-Z0-9_-]+\/challenges\/[a-zA-Z0-9_-]+\/(overview|recent-feedback|issue|facilitator-notes|output-properties|wiki)$/;

export const jamChallengeOverviewRegex = /^\/[a-zA-Z0-9_-]+\/challenges\/[a-zA-Z0-9_-]+\/(overview)$/;

export const jamChallengeTaskDetailsRegex =
  /^\/[a-zA-Z0-9_-]+\/challenges+\/[a-zA-Z0-9_-]+\/tasks+\/[a-zA-Z0-9_-]+\/details$/;

export const jamChatRegex = /^\/[a-zA-Z0-9_-]+\/team\/chat$/;

export const jamChallengesRegexForBreadCrumb = /^\/[a-zA-Z0-9_-]+\/challenges|feedback|meetings$/;

export const challengeSideNavRegex = /^\/challenge(s?)\/?[a-zA-Z0-9_-]?/;

export const disableRestartChallengeRegex =
  /^\/[a-zA-Z0-9_-]+\/challenges\/[a-zA-Z0-9_-]+\/(recent-feedback|issue|facilitator-notes)$/;
