import { Container, Header, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';

const ChallengeHelpDetail: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.help)}</Header>}>
      <h3>{t(i18nKeys.challenges.challengeDetails.titles.challengeStatusLifecycle)}</h3>
      <div style={{ padding: '20px 0' }}>
        <img alt="challenge help" src="/assets/challenge-help.png" />
      </div>
      <SpaceBetween size="s">
        <strong>{t(i18nKeys.challenges.challengeDetails.titles.changedFieldIndicators)}</strong>
        <Columns columns={1}>
          <Column size={'s'}>
            <div style={{ display: 'inline-block' }}>
              <SpaceBetween direction="horizontal" size="xs">
                <div style={{ backgroundColor: '#D91515', width: '6px', height: '16px', flexShrink: '0' }}> </div>
                <strong>{t(i18nKeys.challenges.challengeDetails.titles.title)}:</strong>
                <TextContent>{t(i18nKeys.challenges.challengeDetails.text.changedMostRecentIndicator)}</TextContent>
              </SpaceBetween>
            </div>
          </Column>
          <Column size={'s'}>
            <div style={{ display: 'inline-block' }}>
              <SpaceBetween direction="horizontal" size="xs">
                <div style={{ backgroundColor: '#0972D3', width: '6px', height: '16px', flexShrink: '0' }}> </div>
                <strong> {t(i18nKeys.challenges.challengeDetails.titles.title)}:</strong>
                <TextContent>{t(i18nKeys.challenges.challengeDetails.text.unsavedChange)}</TextContent>
              </SpaceBetween>
            </div>
          </Column>
        </Columns>
      </SpaceBetween>
    </Container>
  );
};

export default ChallengeHelpDetail;
