import React from 'react';
import { JamEventStatusForSidebar, JamTask, JamTaskStatus } from '@/src/types/JamChallengeDetails';
import { Box, Icon } from '@amzn/awsui-components-react';
import { JamEventStatus } from '@/src/types/JamCommon';

interface TaskStatusProps {
  task?: JamTask;
  name?: JamEventStatusForSidebar;
  status?: JamEventStatus | undefined;
  challengeStatus?: boolean | undefined;
}

const TaskStatus: React.FC<TaskStatusProps> = ({ task, name, status, challengeStatus }) => (
  <Box
    variant="span"
    className={`status-${task?.status} 
    ${name === JamEventStatusForSidebar.LOCKED ? 'status-INCOMPLETE' : ''}
    ${name === JamEventStatusForSidebar.SOLVED ? 'status-COMPLETE' : ''}`}>
    {task?.status === JamTaskStatus.COMPLETE || name === JamEventStatusForSidebar.SOLVED ? (
      <Icon name="status-positive" />
    ) : task?.locked ||
      name === JamEventStatusForSidebar.LOCKED ||
      status === JamEventStatus.ENDED ||
      !challengeStatus ? (
      <Icon name="lock-private" />
    ) : (
      ''
    )}
  </Box>
);

export default TaskStatus;
