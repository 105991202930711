import { Multiselect } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import './MultiSelectInput.scss';

interface MultiSelectInputProps {
  label: string;
  selectedOptions: OptionDefinition[];
  placeholder: string;
  options: {
    label: string;
    value: string;
  }[];
  onChange: (detail: any) => void;
}

/**
 *
 * @param param0 Takes in a label, selectedOptions, a placeholder, and selectable options
 * @returns A Polaris dropdown component aligned with ConsoleUI needs
 */
export const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
  label,
  selectedOptions,
  placeholder,
  options,
  onChange,
}) => {
  return (
    <label style={{ float: 'left', display: 'inline-block' }} className="multi-select-container">
      <span style={{ marginRight: 10 }}>{label}</span>
      <Multiselect
        selectedOptions={selectedOptions}
        placeholder={placeholder}
        className="inline"
        options={options}
        onChange={onChange}
      />
    </label>
  );
};
