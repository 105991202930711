import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { PublicEventData } from '../types/PublicEvents';

export class PublicEventsAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing.
  }
  /**
   * get public event data
   *
   */
  public async fetchPublicEventData(_languageCode: string) {
    return (await this.apiClient.get({
      path: `/public/events`,
    })) as Promise<PublicEventData[]>;
  }
}
