import * as React from 'react';
import { getTableFilterMatcher, TableFilterMatcher } from '../../utils/table.utils';
import { Event, getEventListStatus } from '../../types/Event';
import { i18nKeys } from '../../utils/i18n.utils';
import { TFunctionProvider } from '../common/TFunctionProvider';
import { DateTime } from '../common/DateTime';
import { Badge, CollectionPreferencesProps } from '@amzn/awsui-components-react';
import { EVENT_DETAILS_ROUTES } from '../../routes';
import { toTitleCase } from '../../utils/string.utils';
import { EventAudienceType } from '../../constants/shared/event-audience-type';
import { useUser } from '@/src/store/user.context';
import Link from '../ui/atoms/Link/Link';
import { DateTimeKeys } from '../common/CommonModel';

export const filteringFunction = (item: Event, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item.title) ||
    matcher.isMatch(item.audienceType) ||
    matcher.isMatch(item.name) ||
    matcher.isMatch(item.type) ||
    matcher.isMatch(getEventListStatus(item)) ||
    matcher.isMatch(item.startDate) ||
    matcher.isMatch(item.endDate)
  );
};

const getEventStatus = (item: Event): JSX.Element => {
  if (!item) {
    return <Badge color="grey">{'NOT_STARTED'}</Badge>;
  }
  if (item.status === 'CANCELLED') {
    return <Badge color="red">{item.status}</Badge>;
  }
  if (item.approvalStatus === 'REQUEST_APPROVED') {
    if (item.changeRequest){
      if (item.changeRequest.status === 'CHANGE_REQUESTED' || item.changeRequest.status === 'CHANGE_PENDING') {
        return <Badge color="blue">{item.changeRequest.status}</Badge>;
      }
    }
    if(item.status === 'ONGOING') {
      return <Badge color="green">{item.status}</Badge>;
    }
  }
  if (item.approvalStatus === 'REQUEST_SUBMITTED' || item.approvalStatus === 'REQUEST_PENDING') {
    return <Badge color="blue">{item.approvalStatus}</Badge>;
  }
  if (item.approvalStatus === 'REQUEST_CANCELLED' || item.approvalStatus === 'REQUEST_DENIED') {
    return <Badge color="red">{item.approvalStatus}</Badge>;
  }
  return <Badge color="grey">{item.status}</Badge>;
};

// audienceType/Channel dictiionary to fetch name based on constant
export const audienceTypeDictionary: { [key: string]: React.ReactElement } = {
  [EventAudienceType.GENERAL_PUBLIC]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.generalPublic)}
    </TFunctionProvider>
  ),
  [EventAudienceType.AWS_PRIVATE_ENGAGEMENT]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.awsPrivateEngagement)}
    </TFunctionProvider>
  ),
  [EventAudienceType.AWS_BOOTCAMP]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.awsBootCamp)}
    </TFunctionProvider>
  ),
  [EventAudienceType.AWS_INTERNAL]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.awsInternal)}
    </TFunctionProvider>
  ),
  [EventAudienceType.CUSTOMER_INTERNAL]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.customerInternal)}
    </TFunctionProvider>
  ),
  [EventAudienceType.TESTERS]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.testers)}
    </TFunctionProvider>
  ),
  [EventAudienceType.INDIVIDUAL]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.individual)}
    </TFunctionProvider>
  ),
  [EventAudienceType.SKILL_BUILDER_EVENT]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.skillBuilderEvent)}
    </TFunctionProvider>
  ),
  [EventAudienceType.AWS_CLASSROOMS_BUILDER_LABS]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.awsClassroomsBuilderLabs)}
    </TFunctionProvider>
  ),
  [EventAudienceType.INTERNAL]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.internal)}
    </TFunctionProvider>
  ),
  [EventAudienceType.PUBLIC]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.public)}
    </TFunctionProvider>
  ),
  [EventAudienceType.PAID_ALA_CARTE]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.paidAlaCarte)}
    </TFunctionProvider>
  ),
  [EventAudienceType.GLOBAL_PARTNERS]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.globalPartners)}
    </TFunctionProvider>
  ),
  [EventAudienceType.AWS_EDUCATION]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.awsEducation)}
    </TFunctionProvider>
  ),
  [EventAudienceType.RECRUITING]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.recruiting)}
    </TFunctionProvider>
  ),
  [EventAudienceType.JAM_JOURNEY]: (
    <TFunctionProvider>
      {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.jamJourney)}
    </TFunctionProvider>
  ),
};

export const COLUMN_DEFINITIONS = (preferences: CollectionPreferencesProps.Preferences) => {
  const { user } = useUser();

  return [
    {
      id: 'title',
      sortingField: 'title',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.title.title)}</TFunctionProvider>,
      cell: (item: Event) => (
        <div>
          <Link href={EVENT_DETAILS_ROUTES.Summary.resolve(item?.name ?? '')}>{item?.title}</Link>
          {!user?.hasBasicAccess && <div>{toTitleCase(item?.type)}</div>}
        </div>
      ),
      minWidth: 260,
      allowLineWrap: true,
    },
    ...(user?.isSubscribedUser
      ? [
          {
            id: 'audience',
            sortingField: 'audienceType',
            header: (
              <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.audience.title)}</TFunctionProvider>
            ),
            cell: (item: Event) => item.audienceType && <div>{audienceTypeDictionary[item.audienceType]}</div>,
            minWidth: 180,
            allowLineWrap: false,
          },
          // "Tags" column has been removed as per current requirement but may be reused in future
          // {
          //   id: 'tags',
          //   sortingField: 'tags',
          //   header: (
          //     <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.tags.title)}</TFunctionProvider>
          //   ),
          //   cell: (item: Event) => (
          //     <React.Fragment>
          //       {item?.tags?.map((tag, key) => {
          //         return (
          //           <div style={{ fontSize: '12px', marginBottom: '5px' }} key={key}>
          //             <Badge>{tag}</Badge>
          //           </div>
          //         );
          //       })}
          //     </React.Fragment>
          //   ),
          //   minWidth: 156,
          //   allowLineWrap: false,
          // },
        ]
      : []),
    {
      id: 'startDate',
      sortingField: 'startDate',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.startDate.title)}</TFunctionProvider>,
      cell: (item: Event) => (
        <DateTime
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          key={preferences.custom}
          startOrEnd={DateTimeKeys.START}
          event={item}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          timezoneFormat={preferences.custom}
        />
      ),
      minWidth: 215,
      allowLineWrap: false,
    },
    {
      id: 'endDate',
      sortingField: 'endDate',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.endDate.title)}</TFunctionProvider>,
      cell: (item: Event) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <DateTime startOrEnd={DateTimeKeys.END} event={item} timezoneFormat={preferences.custom} />
      ),
      minWidth: 215,
      allowLineWrap: false,
    },
    ...(user?.hasBasicAccess
      ? [
          {
            id: 'participants',
            sortingField: 'participants',
            header: (
              <TFunctionProvider>
                {(translate) => translate(i18nKeys.events.fields.numberOfParticipants.title)}
              </TFunctionProvider>
            ),
            cell: (item: Event) => item.maxExpectedParticipants,
            minWidth: 175,
            allowLineWrap: false,
          },
        ]
      : []),
    {
      id: 'status',
      sortingField: 'status',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.status.title)}</TFunctionProvider>,
      cell: (item: Event) => getEventStatus(item),
      ...(!user?.hasBasicAccess && { minWidth: 242 }),
      allowLineWrap: false,
    },
  ];
};
