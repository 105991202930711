import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Form,
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { joinSpecificTeamValidator } from '@/src/utils/join-specific-team.validation.utils';
import { JoinSpecificTeamFields } from '@/src/types/JoinSpecificTeam';
import { JoinOrCreateTeamProps } from '@/src/types/JamJoinTeam';
import { JamTeamItem } from '@/src/types/JamTeam';

const JoinSpecificTeamForm: React.FC<Omit<JoinOrCreateTeamProps, 'createTeam'>> = ({ joinTeam, getEventTeams, saving }) => {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState('');
  const [teamNameError, setTeamNameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [teams, setTeams] = useState<JamTeamItem[]>([]);
  const [options, setOptions] = useState<SelectProps.Options>([]);
  const [passwordRequired, setPasswordRequired] = useState(false);

  const [selectedOption, setSelectedOption] = React.useState<SelectProps.Option>({});

  const createOption = (teamItem : JamTeamItem, teamPrivacy : string) : SelectProps.Option => {
    return {
      label: teamItem.team.teamName,
      value: teamPrivacy,
      labelTag: t(i18nKeys.joinTeam.onboarding.spotsLeft, {count : teamItem.team.spotsLeft} ),
      disabled: (teamItem.team.spotsLeft === 0)
    };
  }

  const processList = (teamList : JamTeamItem[]) : SelectProps.Options => {
    const participantTeamList = teamList.filter(e => !e.team.facilitatorOnly);
    const publicTeamList = participantTeamList.filter(e => !e.team.passwordRequired);
    const privateTeamList = participantTeamList.filter(e => e.team.passwordRequired);
    return [
      {
        label: t(i18nKeys.general.public),
        options: publicTeamList.map(e => createOption(e, "public"))
      },
      {
        label: t(i18nKeys.general.private),
        options: privateTeamList.map(e => createOption(e, "private"))
      }
    ]
  }

  useEffect(() => {
    const getEventTeamsHelper = async()=> {
      if(getEventTeams) {
        const teamList : JamTeamItem[] = await getEventTeams();
        setTeams(teamList);
      }
      return;
    }
    void getEventTeamsHelper();
  }, []);

  useEffect(() => {
    setOptions(processList(teams));
  }, [teams]);

  const validator = useMemo(
    () =>
      joinSpecificTeamValidator(
        { teamName, password, passwordRequired },
        { teamName: t(i18nKeys.joinTeam.form.errors.teamName), password: t(i18nKeys.joinTeam.form.errors.password) },
        new Map<JoinSpecificTeamFields, (error: string) => void>([
          [JoinSpecificTeamFields.TEAM_NAME, (error: string) => setTeamNameError(error)],
          [JoinSpecificTeamFields.PASSWORD, (error: string) => setPasswordError(error)],
        ])
      ),
    [teamName, password]
  );

  const onSubmit = async () => {
    const isValid = validator.isValidSection(true);
    if (!isValid) {
      return;
    }
    await joinTeam(teamName, password);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form>
        <SpaceBetween direction="vertical" size="l">
          <FormField
            label={t(i18nKeys.joinTeam.form.commonFields.teamName)}
            description={t(i18nKeys.joinTeam.matchMeTeam.fields.selectTeam)}
            errorText={teamNameError}>
            <Select
              selectedOption={selectedOption}
              onChange={({ detail }) => {
                setSelectedOption(detail.selectedOption);
                if(detail.selectedOption.label) {
                  setTeamName(detail.selectedOption.label);
                }
                if(detail.selectedOption.value === "public") {
                  setPasswordRequired(false);
                }
                if(detail.selectedOption.value === "private") {
                  setPasswordRequired(true);
                }
              }}
              options={options}
            />
          </FormField>
          {passwordRequired && <FormField
            label={t(i18nKeys.joinTeam.form.commonFields.password) + '*'}
            description={t(i18nKeys.joinTeam.createTeam.fields.passwordCaption)}
            errorText={passwordError}>
            <Input type="password" value={password} onChange={({ detail }) => setPassword(detail.value)} />
          </FormField>}
          <Grid gridDefinition={[{ colspan: 8 }, { colspan: 8 }]}>
            <div className="divider" />
            <Box float="right">
              <Button loading={saving} variant="primary" onClick={() => void onSubmit()}>
                {t(i18nKeys.joinTeam.joinSpecificTeam.submitBtn)}
              </Button>
            </Box>
          </Grid>
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default JoinSpecificTeamForm;
