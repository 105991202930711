import React, { useState } from 'react';
import { Box, Button, FormField, Grid, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './index.scss';
import JamSpinner from "@/src/components/common/JamSpinner";

interface JoinJamProps {
  onDismiss: () => void;
  onSubmit: (key: string) => Promise<void>;
  isVisible: boolean;
  loading: boolean;
}

const LocateSecretKeyModal: React.FC<JoinJamProps> = ({ onDismiss, loading, isVisible, onSubmit }) => {
  const { t } = useTranslation();
  const [secretKey, setSecretKey] = useState('');
  const [secretKeyError, setSecretKeyError] = useState('');

  const onCancel = () => {
    setSecretKey('');
    onDismiss();
  };

  const onJoin = () => {
    if (!secretKey.trim()) {
      setSecretKeyError(t(i18nKeys.myJams.joinAnEvent.secretKeyError));
      return;
    }
    void onSubmit(secretKey);
    setSecretKey('');
  };

  return (
    <Modal
      onDismiss={onCancel}
      visible={isVisible}
      closeAriaLabel={t(i18nKeys.myJams.joinAnEvent.locateSecretKey.title)}
      header={t(i18nKeys.myJams.joinAnEvent.title)}
      size="medium">
      <div className="locate-secret-key-modal">
        <SpaceBetween size="l">
          <Grid>
            <Box>{t(i18nKeys.myJams.joinAnEvent.locateSecretKey.description)}</Box>
          </Grid>
          <Box data-classname="">
            <SpaceBetween size="xs">
              <Box fontWeight="bold">{t(i18nKeys.general.email)}</Box>
              <FormField i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}} errorText={secretKeyError}>
                <Input
                  onChange={({ detail }) => setSecretKey(detail.value)}
                  value={secretKey}
                  placeholder={t(i18nKeys.myJams.joinAnEvent.secretKey)}
                />
              </FormField>
            </SpaceBetween>
          </Box>
          <Box float="right">
            {loading ? (
              <JamSpinner />
            ) : (
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" ariaLabel="cancel" onClick={onCancel}>
                  {t(i18nKeys.general.cancel)}
                </Button>
                <Button variant="primary" ariaLabel="submit" onClick={onJoin}>
                  {t(i18nKeys.general.submit)}
                </Button>
              </SpaceBetween>
            )}
          </Box>
        </SpaceBetween>
      </div>
    </Modal>
  );
};

export default LocateSecretKeyModal;
