/* eslint-disable @typescript-eslint/unbound-method */
export interface LeaderboardTeam {
  rank: number;
  teamDisplayName: string;
  teamName: string;
  solvedChallenges: number;
  cluesUsed: number;
  score: number;
  teamMembers: string[];
  totalTime: number;
}

export interface LeaderboardResponse {
  leaderBoard: LeaderboardTeam[];
  count: number;
}

export interface LeaderboardMiniTeam {
  rank: number;
  teamName: string;
  score: number;
  teamDisplayName?: string;
}

export class LeaderboardTeamView {
  myTeam?: LeaderboardMiniTeam;
  beforeMyTeam?: LeaderboardMiniTeam;
  afterMyTeam?: LeaderboardMiniTeam;
  teamView: LeaderboardMiniTeam[] = [];

  static fromPlainObject(jsonObject: any): LeaderboardTeamView {
    const leaderboardTeamView = new LeaderboardTeamView();
    Object.assign(LeaderboardTeamView, jsonObject);
    if (!jsonObject) {
      return leaderboardTeamView;
    }
    if (jsonObject.beforeMyTeam) {
      leaderboardTeamView.teamView.push(jsonObject.beforeMyTeam as LeaderboardMiniTeam);
    }
    if (jsonObject.myTeam) {
      leaderboardTeamView.teamView.push(jsonObject.myTeam as LeaderboardMiniTeam);
    }
    if (jsonObject.afterMyTeam) {
      leaderboardTeamView.teamView.push(jsonObject.afterMyTeam as LeaderboardMiniTeam);
    }

    return leaderboardTeamView;
  }
}
