import React, { useState } from 'react';

// types
import { useCreateEventTemplate } from '@/src/store/create-event-template.context';
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';
import { ChallengeOrder } from '.';
import { challengeOrderValidator } from '@/src/utils/event-template.validation.utils';
import { getBackupChallengesListItems, getPrimaryChallengeListItems } from '@/src/utils/event-template.utils';

interface IChallengeOrderSectionProps {
  activeStepIndex: number;
  editMode?: boolean;
  setActiveStepIndex: (x: number) => void;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

export const ChallengeOrderSection: React.FC<IChallengeOrderSectionProps> = (props: IChallengeOrderSectionProps) => {
  const [challengeOrderError, setChallengeOrderError] = useState('');
  const { selectedChallengeListItems } = useEventTemplateChallenges();
  const { getEventDurationInfo, onPrimaryBackupChallengesChange, selectedChallenges } = useCreateEventTemplate();

  const primaryChallengeListItems = getPrimaryChallengeListItems(selectedChallenges, selectedChallengeListItems)
  const backupChallengeListItems = getBackupChallengesListItems(selectedChallenges, selectedChallengeListItems)

  return (
    <ChallengeOrder
      {...props}
      challengeOrderError={challengeOrderError}
      setChallengeOrderError={setChallengeOrderError}
      primaryChallengeListItems={primaryChallengeListItems}
      backupChallengeListItems={backupChallengeListItems}
      getEventDurationInfo={getEventDurationInfo}
      onPrimaryBackupChallengesChange={onPrimaryBackupChallengesChange}
      challengeOrderValidator={challengeOrderValidator}
    />
  );
};
