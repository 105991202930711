import { JamChallengeFeedback, JamEventFeedback } from './JamFeedback';

/* eslint-disable  @typescript-eslint/unbound-method */
export type Theme = 'dark' | 'light';
export type JamChallengeViewType = 'map' | 'list';

export enum JamEventStatus {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  ENDED = 'ENDED',
}

export enum JamEventTeamGoal {
  PLAY_TO_WIN = 'PLAY_TO_WIN',
  PLAY_TO_LEARN = 'PLAY_TO_LEARN',
}

export enum JamEventTeamAssignmentType {
  SELF_FORMING = 'SELF_FORMING',
  PRE_CREATED = 'PRE_CREATED',
  SKILL_BASED = 'SKILL_BASED',
}

export enum RestartChallengeReason {
  stuckReason = 'I have changed something in the account which is irreversible',
  badDeploymentReason = 'Something is deployed incorrectly in the account.',
  retryReason = 'Someone else on my team completed this challenge, and I would like to try it.',
  testingReason = 'Restarting for testing purposes',
  otherReason = 'Other',
}

export class JamEventCollaborationOptions {
  supportChatEnabled = false;
  teamChatEnabled = true;
  messagingEnabled = true;

  static fromPlainObject(obj: any): JamEventCollaborationOptions {
    return Object.assign(new JamEventCollaborationOptions(), obj || {}) as JamEventCollaborationOptions;
  }
}

export enum JamJoinTeamOptions {
  matchTeam = 'match-team',
  createTeam = 'create-team',
  joinSpecificTeam = 'join-specific-team',
}

/**
 * TODO: Remove once challenge CR merged and refernce statistics from there only
 */

export class ChallengeStatistics {
  eventName: string;
  challengeId: string;
  teamsStartedChallenge: string[] = [];
  teamsCompletedChallenge: string[] = [];
  teamsCompletedTask: string[] = [];
  teamsAnsweredIncorrectly: string[] = [];
  teamsRequestingClues: string[] = [];
  totalCompletedChallenge: number;
  totalCompletedTasks: number;
  totalAnsweredIncorrectly: number; // can be more than teams...
  totalRequestedClues: number;
  hasBeenAttempted: boolean; // front-end only, not persisted
  completionRate: number; // front-end only, not persisted

  static fromPlainObject(obj: any): ChallengeStatistics {
    const stats: ChallengeStatistics = Object.assign(new ChallengeStatistics(), obj) as ChallengeStatistics;
    stats.teamsStartedChallenge = [...(stats.teamsStartedChallenge || [])];
    stats.teamsCompletedChallenge = [...(stats.teamsCompletedChallenge || [])];
    stats.teamsCompletedTask = [...(stats.teamsCompletedTask || [])];
    stats.teamsAnsweredIncorrectly = [...(stats.teamsAnsweredIncorrectly || [])];
    stats.teamsRequestingClues = [...(stats.teamsRequestingClues || [])];
    return stats;
  }
}

export interface IEventFeedbackData {
  challengeFeedback: { [id: string]: JamChallengeFeedback };
  eventFeedback: { [id: string]: JamEventFeedback };
}
