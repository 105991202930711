import { ContentLayout, Header, Tabs } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import JamUILocalization from './Sections/JamUILocalization';
import GDPR from './Sections/GDPR';
import LSE from './Sections/LSE';
import UserManagement from './Sections/UserManagement';
import BreadCrumbs from '../root/BreadCrumbs';

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const Categories = {
    USER_MANAGEMENT: 'user-management',
    JAM_UI_LOCALIZATION: 'jam-ui-localization',
    GDPR: 'gdpr',
    LSE: 'lse',
  };

  const [activeChallengeTabId, setActiveChallengeTabId] = useState<string>(Categories.USER_MANAGEMENT);

  const handleTabChange = (activeTabId: string) => {
    setActiveChallengeTabId(activeTabId);
  };

  return (
    <React.Fragment>
      <ContentLayout
        header={
          <React.Fragment>
            <BreadCrumbs />
            <Header variant="h1">{t(i18nKeys.settings.advancedSettings)}</Header>
          </React.Fragment>
        }
        disableOverlap>
        <Tabs
          onChange={({ detail }) => handleTabChange(detail.activeTabId)}
          activeTabId={activeChallengeTabId}
          tabs={[
            {
              id: Categories.USER_MANAGEMENT,
              label: t(i18nKeys.settings.categories.userManagement),
              content: <UserManagement />,
            },
            {
              id: Categories.JAM_UI_LOCALIZATION,
              label: t(i18nKeys.settings.categories.jamUILocalization),
              content: <JamUILocalization />,
            },
            {
              id: Categories.GDPR,
              label: t(i18nKeys.settings.gdpr.dataPrivacyManagement),
              content: <GDPR />,
            },
            {
              id: Categories.LSE,
              label: 'LSE',
              content: <LSE />,
            },
          ]}
        />
      </ContentLayout>
    </React.Fragment>
  );
};

export default Settings;
