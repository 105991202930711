/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';
import EventFeedback from './EventFeedback';
import ChallengeFeedback from './ChallengeFeedback';

interface ExpandableFeedbackProps {
  headerText: string;
  isEvent?: boolean;
  challengeId?: string;
}

const ExpandableFeedback: React.FC<ExpandableFeedbackProps> = ({ headerText, isEvent = true, challengeId }) => {
  return (
    <ExpandableSection variant="container" headerText={headerText} defaultExpanded>
      <SpaceBetween direction="vertical" size="m">
        {isEvent ? <EventFeedback /> : <ChallengeFeedback challengeId={challengeId} />}
      </SpaceBetween>
    </ExpandableSection>
  );
};

export default ExpandableFeedback;
