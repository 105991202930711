import { useCollection } from '@amzn/awsui-collection-hooks';
import { ButtonDropdown, Pagination, SpaceBetween, Table, TextFilter } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengeDeploymentStatistics,
  DeploymentStatisticListItem,
  DeploymentStatistics,
} from '../../../../../types/Challenge';
import { Nullable } from '../../../../../types/common';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { paginationLabels } from '../../../../../utils/table.utils';
import { TableEmptyState } from '../../../../common/TableEmptyState';
import { TableHeader } from '../../../../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './deployment-statistics-config';

interface ChallengeDeploymentStatisticsDetailProps {
  challengeDeploymentStatistics?: ChallengeDeploymentStatistics;
}

const ChallengeDeploymentStatisticsDetail: React.FC<ChallengeDeploymentStatisticsDetailProps> = ({
  challengeDeploymentStatistics,
}) => {
  const { t } = useTranslation();
  const [deploymentStatisticListItems, setDeploymentStatisticListItems] = useState<DeploymentStatisticListItem[]>();

  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: [
      'region',
      'succuessfulLabs',
      'totalLabs',
      'percentLabsSuccessful',
      'stackDeployTime',
      'labVendTime',
    ],
  });

  useEffect(() => {
    const stats: DeploymentStatisticListItem[] = [];

    if (challengeDeploymentStatistics) {
      const globalStats: Nullable<DeploymentStatistics> = challengeDeploymentStatistics.globalStatistics;
      const regionalStats: Nullable<Map<string, DeploymentStatistics>> =
        challengeDeploymentStatistics.regionalStatistics;

      if (globalStats && regionalStats) {
        regionalStats.forEach((value, key) => {
          stats.push(
            new DeploymentStatisticListItem(
              key,
              value.successfulDeployments ? value.successfulDeployments.all : 0,
              value.totalDeployments ? value.totalDeployments.all : 0,
              value.deploymentSuccessRate ? value.deploymentSuccessRate.all : 0,
              value.avgStackDeployTime ? value.avgStackDeployTime.all : 0,
              value.avgVendTime ? value.avgVendTime.all : 0
            )
          );
        });

        stats.push(
          new DeploymentStatisticListItem(
            'global',
            globalStats.successfulDeployments ? globalStats.successfulDeployments.all : 0,
            globalStats.totalDeployments ? globalStats.totalDeployments?.all : 0,
            globalStats.deploymentSuccessRate ? globalStats.deploymentSuccessRate.all : 0,
            globalStats.avgStackDeployTime ? globalStats.avgStackDeployTime.all : 0,
            globalStats.avgVendTime ? globalStats.avgVendTime.all : 0
          )
        );
      }

      setDeploymentStatisticListItems(stats);
    }
  }, [challengeDeploymentStatistics]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    deploymentStatisticListItems || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.challenges.challengeDetails.table.emptyDeploymentStatisticsState)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  return (
    <Table
      {...collectionProps}
      variant="container"
      resizableColumns
      header={
        <TableHeader
          totalItems={deploymentStatisticListItems ? deploymentStatisticListItems.length : 0}
          title={t(i18nKeys.challenges.challengeDetails.headings.deploymentStatistics)}
          toggleTools={() => alert('challenge list tools not implemented yet')}
          actionButtons={
            <SpaceBetween direction="horizontal" size="s">
              <strong>{`${t(i18nKeys.challenges.challengeDetails.titles.timeRange)}:`}</strong>
              <ButtonDropdown
                id="challenge-deployment-staticstics-year"
                data-testid="challenge-deployment-staticstics-year"
                variant="primary"
                items={[]}>
                {t(i18nKeys.general.year)}
              </ButtonDropdown>
            </SpaceBetween>
          }
        />
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingIssues)}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
          filteringAriaLabel={t(i18nKeys.challenges.filteringLabel)}
        />
      }
    />
  );
};

export default ChallengeDeploymentStatisticsDetail;
