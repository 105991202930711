import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';

// components
import TextTruncation from '@/src/components/event-templates/EventTemplateDetails/Sections/TruncatedText';
import { i18nKeys } from '@/src/utils/i18n.utils';

// types
import { IEventTemplateChallenge } from '@/src/types/EventTemplate';

export interface IChallengeRationalDetailsProps {
  challenges: IEventTemplateChallenge[];
  challengeTitles: { [challengeId: string]: string };
}

const ChallengeRationalDetails: React.FC<IChallengeRationalDetailsProps> = ({ challenges, challengeTitles }) => {
  const { t } = useTranslation();

  return (
    <Container header={<Header variant="h2">{t(i18nKeys.eventTemplates.eventTemplateDetails.challenges)}</Header>}>
      <ColumnLayout columns={1} variant="text-grid">
        {challenges.map((challenge, index) => {
          return (
            <SpaceBetween key={index} size="l">
              <div>
                <Box variant="h4">{challengeTitles[challenge.id] ?? '...'}</Box>
                <Box margin={{ top: 'l' }}>
                  <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.eventTemplateDetails.rationale)}</Box>
                  <TextTruncation text={challenge?.rationale || '-'} maxLength={300} />
                </Box>
              </div>
            </SpaceBetween>
          );
        })}
      </ColumnLayout>
    </Container>
  );
};

export default ChallengeRationalDetails;
