export class JamEventSponsor {
  name: string | null = null;
  url: string | null = null;
  description: string | null = null;
  logo: string | null = null;

  static fromPlainObject(obj: any): JamEventSponsor {
    return Object.assign(new JamEventSponsor(), obj) as JamEventSponsor;
  }

  static isEmpty(sponsor: JamEventSponsor): boolean {
    return !sponsor || Object.values(sponsor).every((values) => !values);
  }
}
