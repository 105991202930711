import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from './common';

@jsonObject
export class ChallengeBoardColumn {
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;
  @jsonArrayMember(String)
  challengeIds: string[] = [];
}

export class ChallengeBoardRequest {
  @jsonMember(common.NullableStringValue)
  name: common.NullableString = null;
  @jsonArrayMember(ChallengeBoardColumn)
  columns: ChallengeBoardColumn[] = [];
  @jsonArrayMember(Number)
  scores: number[] = [];
}

@jsonObject
export class ChallengeBoard extends ChallengeBoardRequest {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  owner: common.NullableString = null;
  @jsonMember(common.NullableTimeStampValue)
  createDate: common.NullableTimeStamp = null;
  @jsonMember(common.NullableTimeStampValue)
  updatedDate: common.NullableTimeStamp = null;

  get challengeIds() {
    return this.columns.reduce((challengeIds: string[], column: ChallengeBoardColumn) => {
      return [...challengeIds, ...column.challengeIds];
    }, []);
  }

  hasChallenge(challengeId: string): boolean {
    return this.getBoardPosition(challengeId) != null;
  }

  getBoardPosition(challengeId: string): common.Nullable<ChallengeBoardPosition> {
    for (let columnIndex = 0; columnIndex < this.columns.length; columnIndex++) {
      const column: ChallengeBoardColumn = this.columns[columnIndex];

      const rowIndex: number = column.challengeIds.indexOf(challengeId);

      if (rowIndex > -1) {
        return {
          column: columnIndex,
          row: rowIndex,
        };
      }
    }

    return null;
  }

  get numChallenges() {
    return this.challengeIds.length;
  }

  get numCategories() {
    return this.columns.length;
  }

  get numLevels() {
    return this.scores.length;
  }

  get totalPoints() {
    return this.scores.reduce((total: number, levelScore: number) => {
      return total + levelScore * this.numCategories;
    }, 0);
  }
}

@jsonObject
export class ChallengeBoardPosition {
  @jsonMember(common.NullableNumberValue)
  column: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  row: common.NullableNumber = null;
}
