import React from 'react';
import { CollectionPreferencesProps, Container } from '@amzn/awsui-components-react';

// components
import { LoadingBar } from '@/src/components/common/LoadingBar';
import { ChallengeOrderingTitle } from '@/src/components/ui/molecules/EventTemplate';
import EventDetailChallengeTable from '@/src/components/ui/molecules/EventTemplate/EventDetailChallengeTable';

// types
import { ChallengeListItem } from '@/src/types/Challenge';

interface IEventDetailChallenge {
  title: string;
  challenges: ChallengeListItem[];
  eventTemplateChallengePreferences: CollectionPreferencesProps.Preferences;
  toggleChallengeDetailsInfo: (challenge: ChallengeListItem) => void;
}

const EventDetailChallenge: React.FC<IEventDetailChallenge> = ({
  title,
  challenges,
  eventTemplateChallengePreferences,
  toggleChallengeDetailsInfo,
}) => {
  if (!challenges.length) {
    return <LoadingBar />;
  }

  return (
    <Container header={<ChallengeOrderingTitle title={title} count={challenges.length} />}>
      <EventDetailChallengeTable
        challenges={challenges}
        eventTemplateChallengePreferences={eventTemplateChallengePreferences}
        toggleChallengeDetailsInfo={toggleChallengeDetailsInfo}
      />
    </Container>
  );
};

export default EventDetailChallenge;
