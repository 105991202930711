import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  Header,
  Input,
  Modal,
  Pagination,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import React, { useMemo, useState } from 'react';
import Link from '../../ui/atoms/Link/Link';
import { Lab, LabCloudFormationDetails, LabSignInDetails } from '@/src/types/LabModels';
import { Challenge } from '@/src/types/Challenge';
import { Dictionary, capitalize, pick } from 'lodash';
import { timeAgo } from '@/src/utils/time.utils';
import moment from 'moment';
import { LAB_PROVIDER_LABELS, LabProvider } from '@/src/types/LabProvider';
import { getComplimentaryFontColor, labProviderStatusColors, labStatusColors } from '@/src/constants/lab-status-color';
import { useUser } from '@/src/store/user.context';
import { useApi } from '@/src/store/api.context';
import { ResourceDeploymentItem } from '@/src/types/ResourceDeployment';
import { useFlashbars } from '@/src/store/flashbar.context';
import { getFailedStackEvents } from '@/src/utils/cfn.utils';
import { useUtility } from '@/src/store/utility.provider';
import { CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { toTitleCase } from '@/src/utils/string.utils';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { localLogger } from '@/src/utils/log.utils';

interface LabAccountsProps {
  allLabs: Lab[];
  getChallenge: (id: string) => Challenge;
  challengesById: Dictionary<Challenge>;
}

const initialFilters = {
  challengeId: null,
  region: '',
  status: null,
  providerStatus: null,
  assignedTeam: null,
};

const LabAccounts: React.FC<LabAccountsProps> = ({ allLabs, getChallenge, challengesById }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addErrorFlashbar } = useFlashbars();
  const { showLoader, hideLoader } = useUtility();
  const [pageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [labStatusHistory, setLabStatusHistory] = useState<React.ReactNode | undefined>();
  const [stackDetails, setStackDetails] = useState<LabCloudFormationDetails | undefined>();
  const [selectedLab, setSelectedLab] = useState<Lab | undefined>();
  const [metadata, setMetadata] = useState('');
  const [labErrors, setLabErrors] = useState('');
  const [signInDetails, setSignInDetails] = useState<{
    [labId: string]: {
      teamSignInDetails?: LabSignInDetails;
      adminSignInDetails?: LabSignInDetails;
      masterSignInDetails?: LabSignInDetails;
    };
  }>();
  const [filters, setFilters] = useState<{
    challengeId: OptionDefinition | null;
    region: string;
    status: OptionDefinition | null;
    providerStatus: OptionDefinition | null;
    assignedTeam: OptionDefinition | null;
  }>(initialFilters);
  const totalPages = Math.ceil(allLabs.length / pageSize);
  const { eventsApi } = useApi();

  const challengeIds = useMemo(() => {
    return Object.keys(challengesById || {});
  }, []);

  const filtered = useMemo(() => {
    return filters.challengeId || filters.assignedTeam || filters.providerStatus || filters.region || filters.status;
  }, [filters]);

  const filterDropdowns = useMemo(() => {
    const allExternalStatuses: OptionDefinition[] = [];
    const allInternalStatuses: OptionDefinition[] = [];
    const allAssignedTeams: OptionDefinition[] = [];
    let allChallenges: OptionDefinition[] = [];

    allChallenges = challengeIds.map((challenge) => ({
      label: `${getChallenge(challenge).props.title} (${challenge})`,
      value: challenge,
    }));

    // aggregate some lists for caching lists to be used for filter dropdowns
    (allLabs || []).forEach((lab) => {
      if (lab.extStatus && allExternalStatuses.findIndex((status) => status.value === lab.extStatus) < 0) {
        allExternalStatuses.push({ label: toTitleCase(lab.extStatus || '') || '', value: lab.extStatus });
      }
      if (lab.status && allInternalStatuses.findIndex((status) => status.value === lab.status) < 0) {
        allInternalStatuses.push({ label: toTitleCase(lab.status || '') || '', value: lab.status });
      }
      if (lab.onHold && allInternalStatuses.findIndex((status) => status.value === 'ON_HOLD') < 0) {
        allInternalStatuses.push({ label: 'On hold', value: 'ON_HOLD' });
      }
      if (lab.team?.name && allAssignedTeams.findIndex((team) => team.value === lab.team?.name) < 0) {
        allAssignedTeams.push({
          label: toTitleCase(lab.team?.name || '') || '',
          value: lab.team?.alias || '',
        });
      }
    });
    return { allAssignedTeams, allExternalStatuses, allInternalStatuses, allChallenges };
  }, [allLabs, challengeIds]);

  const labs = useMemo(() => {
    let filteredLabs = [...allLabs];
    const { challengeId, status, providerStatus, assignedTeam, region } = filters;

    if (challengeId) {
      filteredLabs = filteredLabs.filter((lab) => lab.challengeId === challengeId?.value);
    }
    if (region) {
      filteredLabs = filteredLabs.filter(
        (lab) => lab.regionDescription?.includes(region) || lab.awsAccountNumber?.includes(region)
      );
    }
    if (status) {
      filteredLabs = filteredLabs.filter((lab) => {
        if (lab.onHold) {
          return 'ON_HOLD' === status.value;
        }
        return lab.status === status.value;
      });
    }
    if (providerStatus) {
      filteredLabs = filteredLabs.filter((lab) => lab.extStatus === providerStatus.value);
    }
    if (assignedTeam) {
      filteredLabs = filteredLabs.filter((lab) => lab.team && lab.team.name === assignedTeam.value);
    }
    return filteredLabs.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [allLabs, currentPage, pageSize, filters]);

  const failedEvents = useMemo(() => {
    if (stackDetails) {
      return getFailedStackEvents(stackDetails.stackEvents);
    }
    return [];
  }, [stackDetails]);

  const formatStatus = (status: string): string => {
    return status.split('_').map(capitalize).join(' ');
  };

  const isLabUsable = (internalStatus: string) => {
    // non terminal states
    // for list of terminal states see CBE: com.amazonaws.awsjam.model.data.LabAccount.Status
    switch (internalStatus) {
      case 'NOT_READY':
      case 'UNASSIGNED':
      case 'ASSIGNED':
      case 'PREPARING_RESOURCES':
        return true;
      default:
        return false;
    }
  };

  const downloadSshKey = async (lab: Lab) => {
    try {
      showLoader();
      await eventsApi.getChallengeLabKeyPair(lab);
    } catch (e) {
      //
    }
    hideLoader();
  };

  const unassignAccount = async (lab: Lab) => {
    const message = t(i18nKeys.eventLabs.labAccounts.messages.unassignAccountSure);
    if (confirm(message)) {
      let reason = prompt(t(i18nKeys.eventLabs.labAccounts.messages.whatIsReasonAccount));
      reason = reason || t(i18nKeys.eventLabs.labAccounts.messages.defaultReasonAccount);
      try {
        showLoader();
        await eventsApi.teamRestart(lab, reason || '');
      } catch (e) {
        //
      }
      hideLoader();
    }
  };

  const terminateLab = async (lab: Lab) => {
    let message = t(i18nKeys.eventLabs.labAccounts.messages.terminateAccountSure);

    if (lab.team) {
      message += t(i18nKeys.eventLabs.labAccounts.messages.terminateTeamSure);
    }

    if (confirm(message)) {
      let reason = prompt(t(i18nKeys.eventLabs.labAccounts.messages.whatIsReasonLab));
      reason = reason || t(i18nKeys.eventLabs.labAccounts.messages.defaultReasonLab);
      try {
        showLoader();
        await eventsApi.terminateLab(lab, reason || '');
      } catch (e) {
        //
      }
      hideLoader();
    }
  };

  const viewStatusHistory = (lab: Lab) => {
    const messages = lab.statusHistory.map((item) => {
      const time = moment(item.time).format('MMM Da hh:mm A');
      return (
        <Box key={time}>
          [${time}] - ${item.status} - ${item.details}
        </Box>
      );
    });
    setLabStatusHistory(messages);
  };

  const viewResourceDeploymentHistory = async (lab: Lab) => {
    try {
      showLoader();
      const items: ResourceDeploymentItem[] = await eventsApi.getLabResourceDeploymentHistory(lab);
      lab.applyResourceTaskHistory(items);
      // TODOSAGAR: show modal check legacy code
    } catch (e) {
      //
    }
    hideLoader();
  };

  const getAccountStackDetails = async (lab: Lab) => {
    try {
      showLoader();
      const details = await eventsApi.getChallengeLabCfnDetails(lab);
      if (details) {
        setStackDetails(details);
      } else {
        addErrorFlashbar(t(i18nKeys.eventLabs.labAccounts.messages.failedStackDetails));
      }
    } catch (e) {
      //
    }
    hideLoader();
  };

  const getMetadata = async (lab: Lab): Promise<{ [key: string]: string }> => {
    const data = await eventsApi.getLabMetadata(lab);
    return Object.assign({}, data, pick(lab, ['holdUntil', 'masterAccount']));
  };

  const showMetadata = async (lab: Lab) => {
    const note =
      lab.labProvider === LabProvider.EVENT_ENGINE ? t(i18nKeys.eventLabs.labAccounts.messages.metadataNote) : '';
    try {
      showLoader();
      const message = note + JSON.stringify(await getMetadata(lab), null, 4);
      setMetadata(message);
    } catch (e) {
      //
    }
    hideLoader();
  };

  const showErrors = (lab: Lab) => {
    let error = lab.error && lab.error.replace(/\\"/g, '"').replace(/"{/g, '{').replace(/}"/g, '}');

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = error ? JSON.parse(error) : {};
      error = JSON.stringify(error, null, 4);
    } catch (e) {
      // ignore
      // console.log(e);
    }

    const message = `AWS Account Number: ${lab.awsAccountNumber}
        Session ID: ${lab.sessionId}
        Lab ID: ${lab.id}
        Error: ${error}
    `;
    setLabErrors(message);
  };

  const fetchSignInDetails = async (item: Lab) => {
    showLoader();
    try {
      const teamSignInDetails: LabSignInDetails = await eventsApi.getChallengeLabTeamSignIn(item);
      const adminSignInDetails: LabSignInDetails = await eventsApi.getChallengeLabAdminSignIn(item);

      let masterSignInDetails: LabSignInDetails;
      const challenge = getChallenge(item.challengeId || '');
      if (challenge && challenge.isGameDay) {
        masterSignInDetails = await eventsApi.getChallengeLabMasterSignIn(item);
      }
      setSignInDetails((prev) => {
        if (item.id) {
          return { ...prev, [item.id]: { teamSignInDetails, adminSignInDetails, masterSignInDetails } };
        }
      });
    } catch (e) {
      // e
    }
    hideLoader();
  };

  const onAction = (id: string, lab: Lab) => {
    setSelectedLab(lab);
    switch (id) {
      case 'SSH_KEY':
        void downloadSshKey(lab);
        break;
      case 'UNASSIGN_ACCOUNT':
        void unassignAccount(lab);
        break;
      case 'TERMINATE_LAB':
        void terminateLab(lab);
        break;
      case 'STATUS_HISTORY':
        viewStatusHistory(lab);
        break;
      case 'RESOURCE_DEPLOY_HISTORY':
        void viewResourceDeploymentHistory(lab);
        break;
      case 'STACK_DETAILS':
        void getAccountStackDetails(lab);
        break;
      case 'VIEW_METADATA':
        void showMetadata(lab);
        break;
    }
  };

  const getActionItems = (lab: Lab) => {
    const actions = [];
    const challenge = getChallenge(lab.challengeId || '');
    if (lab.canSignIn && challenge && challenge.props.sshKeyPairRequired) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.sshKey), id: 'SSH_KEY' });
    }
    if (lab.team && lab.status?.toLowerCase() === 'assigned') {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.unassignAccount), id: 'UNASSIGN_ACCOUNT' });
    }
    if (user?.isEventAdmin && isLabUsable(lab.status || '')) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.tearminateLab), id: 'TERMINATE_LAB' });
    }
    if (lab.statusHistory) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.statusHistory), id: 'STATUS_HISTORY' });
    }
    if (challenge && challenge.hasCustomResources) {
      actions.push({
        text: t(i18nKeys.eventLabs.labAccounts.actions.resourceDepHistory),
        id: 'RESOURCE_DEPLOY_HISTORY',
      });
    }
    if (lab.error) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.cloudFormStackDetails), id: 'STACK_DETAILS' });
    }
    if (user?.isEventAdmin) {
      actions.push({ text: t(i18nKeys.eventLabs.labAccounts.actions.viewMetadata), id: 'VIEW_METADATA' });
    }
    return actions;
  };

  const awsAccountNumber = selectedLab?.awsAccountNumber?.toString();
  localLogger({ signInDetails });
  return (
    <>
      <Table
        resizableColumns
        columnDefinitions={[
          {
            id: 'challenge',
            header: (
              <Box>
                {t(i18nKeys.eventLabs.labAccounts.table.header.challenge)}
                <Box>
                  <Select
                    placeholder="Any"
                    expandToViewport
                    options={filterDropdowns.allChallenges}
                    selectedOption={filters.challengeId as OptionDefinition}
                    onChange={({ detail }) => setFilters((prev) => ({ ...prev, challengeId: detail.selectedOption }))}
                  />
                </Box>
              </Box>
            ),
            cell: (item) => (
              <Link href={CHALLENGE_DETAILS_ROUTES.Summary.replaceTokens([item.challengeId || ''])}>
                {getChallenge(item.challengeId || '').props.title}
              </Link>
            ),
            sortingField: 'name',
            isRowHeader: true,
          },
          {
            id: 'awsregion_accountid',
            header: (
              <Box>
                {t(i18nKeys.eventLabs.labAccounts.table.header.awsRegion)}
                <Input
                  placeholder="Filter"
                  value={filters.region}
                  onChange={({ detail }) => setFilters((prev) => ({ ...prev, region: detail.value }))}
                />
              </Box>
            ),
            cell: (item) => {
              const details = signInDetails?.[item.id || ''];
              return (
                <Box>
                  {!!item.region && <Box>{item.regionDescription}</Box>}
                  {!!item.awsAccountNumber && <Box>{item.awsAccountNumber}</Box>}
                  {!item.expired && !item.isInFinalStatus && (
                    <Box color="text-body-secondary">
                      Expires: {moment(item.expiration).format('MM/DD/YY, hh:mm A')} (
                      {moment(item.expiration).fromNow()})
                    </Box>
                  )}
                  <Box margin={{ top: 'xxs' }}>
                    {item.canSignIn && (isLabUsable(item.status || '') || item.error) && (
                      <SpaceBetween direction="horizontal" size="s">
                        <Button
                          iconName={details ? 'refresh' : undefined}
                          onClick={() => void fetchSignInDetails(item)}
                          variant={details ? 'inline-icon' : 'inline-link'}>
                          {t(i18nKeys.eventLabs.labAccounts.actions.fetchSignInDetails)}
                        </Button>
                        {!!details && (
                          <>
                            {details.teamSignInDetails && (
                              <div style={{ border: '1px solid #ccc' }}>
                                Team:{' '}
                                <a
                                  href={details.teamSignInDetails?.url || ''}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  Login
                                </a>{' '}
                                <Button variant="inline-link">CLI</Button>
                              </div>
                            )}
                            {details.adminSignInDetails && (
                              <div style={{ border: '1px solid #ccc' }}>
                                Admin:{' '}
                                <a
                                  href={details.adminSignInDetails?.url || ''}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  Login
                                </a>{' '}
                                <Button variant="inline-link">CLI</Button>
                              </div>
                            )}
                            {details.masterSignInDetails && (
                              <div style={{ border: '1px solid #ccc' }}>
                                Master:{' '}
                                <a
                                  href={details.masterSignInDetails?.url || ''}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  Login
                                </a>{' '}
                                <Button variant="inline-link">CLI</Button>
                              </div>
                            )}
                          </>
                        )}
                      </SpaceBetween>
                    )}
                  </Box>
                </Box>
              );
            },
            sortingField: 'alt',
          },
          {
            id: 'status',
            header: (
              <Box>
                {t(i18nKeys.eventLabs.labAccounts.table.header.status)}
                <Box>
                  <Select
                    placeholder="Any"
                    expandToViewport
                    options={filterDropdowns.allInternalStatuses}
                    selectedOption={filters.status as OptionDefinition}
                    onChange={({ detail }) => setFilters((prev) => ({ ...prev, status: detail.selectedOption }))}
                  />
                </Box>
              </Box>
            ),
            cell: (item) => (
              <Box>
                {item.onHold && <Box>{t(i18nKeys.eventLabs.labAccounts.onHold)}</Box>}
                {!item.onHold && (
                  <div
                    style={{
                      display: 'inline',
                      padding: '2px 4px',
                      borderRadius: '4px',
                      backgroundColor: labStatusColors[item.status as keyof typeof labStatusColors],
                      color: getComplimentaryFontColor(labStatusColors[item.status as keyof typeof labStatusColors]),
                    }}>
                    {formatStatus(item.status || '')}
                  </div>
                )}
                {!!(item.status && item.statusHistory) && (
                  <Box>{timeAgo(item.statusHistory[item.statusHistory.length - 1].time || '')}</Box>
                )}
                {item.onHold && (
                  <Box>
                    {t(i18nKeys.eventLabs.labAccounts.table.cell.onHoldUntil)}{' '}
                    {moment(item.holdUntil).format('MM/DD/YYYY hh:mm A')}
                  </Box>
                )}
                {!item.status && 'Unknown'}
                {!!item.error && (
                  <Button onClick={() => showErrors(item)}>
                    {t(i18nKeys.eventLabs.labAccounts.actions.clickToViewError)}
                  </Button>
                )}
                {item.hasResourceHistory && (
                  <Box>
                    {item.hasFailedResource && (
                      <Button>{t(i18nKeys.eventLabs.labAccounts.actions.clickToFailedResources)}</Button>
                    )}
                    {t(i18nKeys.eventLabs.labAccounts.table.cell.deplyingResouces)} {item.resolvedTaskCount + 1} of{' '}
                    {item.totalTaskCount}
                  </Box>
                )}
              </Box>
            ),
          },
          {
            id: 'lab_provider_status',
            header: (
              <Box>
                Lab Provider Status
                <Box>
                  <Select
                    placeholder="Any"
                    expandToViewport
                    options={filterDropdowns.allExternalStatuses}
                    selectedOption={filters.providerStatus as OptionDefinition}
                    onChange={({ detail }) =>
                      setFilters((prev) => ({ ...prev, providerStatus: detail.selectedOption }))
                    }
                  />
                </Box>
              </Box>
            ),
            cell: (item) => (
              <Box>
                {!!item.extStatus && (
                  <Badge color={labProviderStatusColors[item.extStatus as keyof typeof labProviderStatusColors]}>
                    {formatStatus(item.extStatus)}
                  </Badge>
                )}
                {!item.extStatus && 'Unknown'}
                <Box fontWeight="bold">{LAB_PROVIDER_LABELS[item.labProvider || '']}</Box>
              </Box>
            ),
          },
          {
            id: 'assigned_team',
            header: (
              <Box>
                {t(i18nKeys.eventLabs.labAccounts.table.header.assignedTeam)}
                <Box>
                  <Select
                    placeholder="Any"
                    expandToViewport
                    options={filterDropdowns.allAssignedTeams}
                    selectedOption={filters.assignedTeam as OptionDefinition}
                    onChange={({ detail }) => setFilters((prev) => ({ ...prev, assignedTeam: detail.selectedOption }))}
                  />
                </Box>
              </Box>
            ),
            cell: (item) =>
              !!item.team && (
                <Box>
                  {item.team.alias === item.team.name ? item.team.alias : `${item.team.alias} (${item.team.name})`}
                </Box>
              ),
          },
          {
            id: 'actions',
            header: t(i18nKeys.eventLabs.labAccounts.table.header.actions),
            cell: (item) => (
              <Box>
                <ButtonDropdown
                  expandToViewport
                  ariaLabel="Control instance"
                  variant="icon"
                  items={getActionItems(item)}
                  onItemClick={({ detail }) => onAction(detail.id, item)}
                />
              </Box>
            ),
          },
        ]}
        items={labs}
        loadingText="Loading resources"
        sortingDisabled
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No Lab Accounts found</b>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="m" alignItems="center">
                {filtered && (
                  <Button iconName="close" onClick={() => setFilters({ ...initialFilters })}>
                    {t(i18nKeys.eventLabs.labAccounts.actions.clearFilters)}
                  </Button>
                )}
                <Pagination
                  onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
                  currentPageIndex={currentPage}
                  pagesCount={totalPages}
                />
              </SpaceBetween>
            }
            counter={`(${allLabs.length})`}>
            {' '}
            Lab Accounts{' '}
          </Header>
        }
      />
      <Modal
        onDismiss={() => setLabStatusHistory(undefined)}
        visible={!!labStatusHistory}
        footer={
          <Box float="right">
            <Button variant="primary" onClick={() => setLabStatusHistory(undefined)}>
              {t(i18nKeys.general.ok)}
            </Button>
          </Box>
        }
        header={t(i18nKeys.eventLabs.labAccounts.modal.labStatusHistory)}>
        <Box>{labStatusHistory}</Box>
      </Modal>
      <Modal
        onDismiss={() => setLabErrors('')}
        visible={!!labErrors}
        footer={
          <Box float="right">
            <Button variant="primary" onClick={() => setLabErrors('')}>
              {t(i18nKeys.general.ok)}
            </Button>
          </Box>
        }
        header={'Lab Error'}>
        <Box>{labErrors}</Box>
      </Modal>
      <Modal
        onDismiss={() => setStackDetails(undefined)}
        visible={!!stackDetails}
        footer={
          <Box float="right">
            <Button variant="primary" onClick={() => setStackDetails(undefined)}>
              {t(i18nKeys.general.ok)}
            </Button>
          </Box>
        }
        header={`${t(i18nKeys.eventLabs.labAccounts.modal.cfnDetails, { awsAccountNumber })} ${
          getChallenge(selectedLab?.challengeId || '')
            ? `[${t(i18nKeys.eventLabs.labAccounts.table.header.challenge)}: ${selectedLab?.challengeId}]`
            : ''
        }`}>
        {failedEvents.length > 0 && (
          <Box>
            <Box variant="h3">{t(i18nKeys.eventLabs.labAccounts.modal.mainFailedEvents)}</Box>
            <pre>{JSON.stringify(stackDetails, null, 2)}</pre>
          </Box>
        )}
        <Box>
          <Box variant="h3">{t(i18nKeys.eventLabs.labAccounts.modal.fullDetails)}</Box>
          <pre>{JSON.stringify(failedEvents, null, 2)}</pre>
        </Box>
      </Modal>
      <Modal
        size="large"
        onDismiss={() => setMetadata('')}
        visible={!!metadata}
        footer={
          <Box float="right">
            <Button variant="primary" onClick={() => setMetadata('')}>
              {t(i18nKeys.general.ok)}
            </Button>
          </Box>
        }
        header={t(i18nKeys.eventLabs.labAccounts.modal.labAccountMetadata)}>
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{metadata}</div>
      </Modal>
    </>
  );
};

export default LabAccounts;
