import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContentLayout, Header } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { JamEventTeamAssignmentType, JamEventTeamGoal, JamJoinTeamOptions } from '@/src/types/JamCommon';
import { hasTeamPreCreated } from '@/src/utils/jam-event.utils';
import { useApi } from '@/src/store/api.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { getErrorMessage } from '@/src/utils/errors.utils';
import { localLogger } from '@/src/utils/log.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import CreateTeamForm from '../../ui/molecules/JoinTeam/CreateTeamForm/CreateTeamForm';
import MatchMeTeamFrom from '../../ui/molecules/JoinTeam/MatchMeTeamFrom/MatchMeTeamFrom';
import JoinSpecificTeamForm from '../../ui/molecules/JoinTeam/JoinSpecificTeamForm/JoinSpecificTeamForm';
import './JoinTeam.scss';
import { JoinTeamHeaders } from './join-team.config';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useJamMyTeam } from '@/src/store/jam-myteam.context';
import { JamTeamItem } from '@/src/types/JamTeam';

const JoinTeam = () => {
  const { t } = useTranslation();
  const { 1: joinOption }: { 0: string; 1: JamJoinTeamOptions } = useParams();
  const history = useHistory();
  const { jamTeamApi } = useApi();
  const { addErrorFlashbar } = useFlashbars();
  const { eventTeams } = useJamMyTeam();
  const { eventName, eventSlug, event, loadEventDetails } = useJamEventDetails();
  const [isSaving, setIsSaving] = useState(false);

  const teams = useMemo(() => {
    return eventTeams.filter((team) => !team.team.facilitatorOnly);
  }, [eventTeams]);

  const teamsArePreCreated = event ? hasTeamPreCreated(event.teamAssignmentType) : false;

  const navigateToCreateTeam = () => {
    history.replace(JAM_EVENT_DETAILS_ROUTES.JoinTeam.resolve([eventSlug, JamJoinTeamOptions.createTeam]));
  };

  const joinTeam = async (teamName: string, password: string | null = null) => {
    try {
      if (!eventName) {
        throw new Error(t(i18nKeys.joinTeam.noEventFound));
        return;
      }
      setIsSaving(true);
      localLogger({ teamName, password });
      await jamTeamApi.joinTeam(teamName, password, eventName);
      await loadEventDetails(eventName);
      history.replace(JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventSlug));
    } catch (e: any) {
      addErrorFlashbar(getErrorMessage(e));
    }
    setIsSaving(false);
  };

  const createTeam = async (teamName: string, teamGoal: JamEventTeamGoal, password: string | null = null) => {
    try {
      if (!eventName) {
        throw new Error(t(i18nKeys.joinTeam.noEventFound));
        return;
      }
      setIsSaving(true);
      localLogger({ teamName, password });
      await jamTeamApi.createTeam(teamName, teamGoal, password, eventName);
      await loadEventDetails(eventName);
      history.replace(JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventSlug));
    } catch (e: any) {
      addErrorFlashbar(getErrorMessage(e));
    }
    setIsSaving(false);
  };

  const getEventTeams = async () : Promise<JamTeamItem[]> => {
    let teamList : JamTeamItem[] = [];
    try {
      if (!eventName) {
        throw new Error(t(i18nKeys.joinTeam.noEventFound));
      }
      setIsSaving(true);
      teamList = await jamTeamApi.getEventTeams(eventName);
    } catch (e: any) {
      addErrorFlashbar(getErrorMessage(e));
    }
    setIsSaving(false);
    return teamList;
  }

  useEffect(() => {
    if (!Object.values(JamJoinTeamOptions).includes(joinOption) || teamsArePreCreated) {
      history.replace(JAM_EVENT_DETAILS_ROUTES.Onboarding.resolve(eventSlug));
      return;
    }
  }, []);

  const renderForm = () => {
    switch (joinOption) {
      case JamJoinTeamOptions.createTeam:
        return <CreateTeamForm createTeam={createTeam} saving={isSaving} />;
      case JamJoinTeamOptions.matchTeam:
        return (
          <MatchMeTeamFrom
            teams={teams}
            navigateToCreateTeam={navigateToCreateTeam}
            joinTeam={joinTeam}
            saving={isSaving}
            teamAssignmentType={event?.teamAssignmentType as JamEventTeamAssignmentType}
          />
        );
      case JamJoinTeamOptions.joinSpecificTeam:
        return <JoinSpecificTeamForm joinTeam={joinTeam} getEventTeams={getEventTeams} saving={isSaving} />;
      default:
        return null;
    }
  };

  return (
    <ContentLayout header={<Header>{t(JoinTeamHeaders[joinOption])}</Header>}>
      <div className="join-team-container">{renderForm()}</div>
    </ContentLayout>
  );
};

export default JoinTeam;
