import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { i18nKeys } from '../utils/i18n.utils';
import { emailBody, emailParticipantsList, CountInterface } from '../types/EmailInvite';

export class EmailParticipantsAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  // Api To get the Get Email Participant
  public async participantList(eventId: string, pageSize: number, lastToken?: string): Promise<emailParticipantsList> {
    const payload = lastToken ? { pageSize, lastToken } : { pageSize };
    return (await this.apiClient.post({
      path: `/admin/event/${eventId}/participants`,
      body: payload,
      failMessage: this.t(i18nKeys.apiResponse.getParticipantFail),
    })) as Promise<emailParticipantsList>;
  }

  // Email invite to selected users.
  public async createParticipant(eventId: string, body: emailBody): Promise<emailBody> {
    return (await this.apiClient.post({
      path: `/admin/event/${eventId}/participants/create`,
      body,
      successMessage: this.t(i18nKeys.apiResponse.emailInviteSuccess),
    })) as Promise<emailBody>;
  }

  // Api to send Email invitation for event
  public async inviteParticipant(eventId: string, body: emailBody): Promise<emailBody> {
    return (await this.apiClient.post({
      path: `/admin/event/${eventId}/participants/invite`,
      body,
      failMessage: this.t(i18nKeys.apiResponse.getParticipantFail),
    })) as Promise<emailBody>;
  }

  // Remove Participant from the list
  public async removeParticipants(eventId: string, body: emailBody): Promise<emailBody> {
    return (await this.apiClient.post({
      path: `/admin/event/${eventId}/participants/remove`,
      body,
      failMessage: this.t(i18nKeys.apiResponse.error),
    })) as Promise<emailBody>;
  }
  // download Email invite template csv from the api
  public async getTemplateParticipant(eventId: string): Promise<Blob> {
    return (await this.apiClient.get({
      path: `/admin/event/${eventId}/participants/invite/template`,
      responseType: 'blob',
      failMessage: this.t(i18nKeys.apiResponse.error),
    })) as Promise<Blob>;
  }

  // upload csv and invite the participant fot event
  public async inviteUploadParticipant(eventId: string, body: any): Promise<void> {
    return (await this.apiClient.post(
      {
        path: `/admin/event/${eventId}/participants/invite/upload`,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        body,
        failMessage: this.t(i18nKeys.apiResponse.error),
        successMessage: this.t(i18nKeys.apiResponse.csvInviteSuccess),
      },
      true
    )) as Promise<void>;
  }

  // the the participant as per the event id
  public async countParticipant(eventId: string): Promise<CountInterface> {
    return (await this.apiClient.get({
      path: `/admin/event/${eventId}/participants/count`,
      responseMapper: (res) => res as CountInterface,
      failMessage: this.t(i18nKeys.apiResponse.countError),
    })) as Promise<CountInterface>;
  }

  // Api to search the participant in the list
  public async searchParticipant(eventId: string, term: string): Promise<string[]> {
    return (await this.apiClient.get({
      path: `/admin/event/${eventId}/participants/search?term=${term}`,
      responseMapper: (res) => res.participantsEmail as string[],
      failMessage: this.t(i18nKeys.apiResponse.getParticipantFail),
    })) as Promise<string[]>;
  }
}
