import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import TimeAgo from 'timeago-react';
import { ChallengeFeedback } from '../../../../../types/Challenge';
import { safeString } from '../../../../../utils/string.utils';
import CircleRating from '../../../../common/CircleRating';
import StarRating from '../../../../common/StarRating';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface FeedbackItemProps {
  challengeFeedback: ChallengeFeedback;
}

export const FeedbackItem: React.FC<FeedbackItemProps> = ({ challengeFeedback }) => {
  const { t } = useTranslation();
  return (
    <SpaceBetween size="xs" direction="vertical">
      <SpaceBetween size="l" direction="horizontal" alignItems="center">
        <SpaceBetween size="xxxs" direction="horizontal" alignItems="center">
          <Box fontWeight="bold">{t(i18nKeys.challenges.challengeDetails.text.feedbackItem.rating)} :</Box>
          <StarRating
            value={challengeFeedback.challengeRank}
            valueCount={challengeFeedback.challengeRank}
            customView={challengeFeedback ? true : false}
          />
        </SpaceBetween>
        <SpaceBetween size="xxxs" direction="horizontal" alignItems="center">
          <Box fontWeight="bold">{t(i18nKeys.challenges.challengeDetails.text.feedbackItem.difficulty)} :</Box>{' '}
          <CircleRating value={challengeFeedback.challengeDifficulty} customView={challengeFeedback ? true : false} />
        </SpaceBetween>
        <SpaceBetween size="xxxs" direction="horizontal" alignItems="center">
          <Box fontWeight="bold">{t(i18nKeys.challenges.challengeDetails.text.feedbackItem.learned)} :</Box>{' '}
          {challengeFeedback.didYouLearnSomethingNew ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}
        </SpaceBetween>
      </SpaceBetween>
      <Box color="text-status-inactive">
        <TimeAgo datetime={safeString(challengeFeedback.updatedDate)} />
      </Box>
      <Box>{challengeFeedback.notes}</Box>
    </SpaceBetween>
  );
};
