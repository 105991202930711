export class JamParticipantProfile {
  email: string | null = null;
  emailVerified = false;
  nickname: string | null = null;
  title: string | null = null;
  company: string | null = null;
  country: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  stateOrProvince: string | null = null;
  postalCode: string | null = null;
  address: string | null = null;
  phoneNumber: string | null = null;
  interest: string | null = null;

  static fromPlainObject(obj: any): JamParticipantProfile {
    return Object.assign(new JamParticipantProfile(), obj || {}) as JamParticipantProfile;
  }
}

export const INTERESTS = {
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS',
};
