import { TableHeader } from '../../../common/TableHeader';
import {
  Box,
  Button,
  CollectionPreferencesProps,
  Grid,
  Input,
  Pagination,
  Select,
  Table,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { paginationLabels } from '../../../../utils/table.utils';
import { COLUMN_DEFINITIONS, getGroupTitle } from './users-config';
import { useApi } from '../../../../store/api.context';
import { preProdLogger } from '../../../../utils/log.utils';
import { CognitoUser } from '../../../../types/User';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { AuthRole } from '../../../../constants/shared/auth-roles';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { cloneDeep } from 'lodash';
interface UsersTableProps {
  emailsLoading: boolean;
}

export const UsersTable: React.FC<UsersTableProps> = () => {
  const { t } = useTranslation();
  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 50,
  });
  const [users, setUsers] = useState<CognitoUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<CognitoUser[]>([]);
  const { accountApi } = useApi();
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState<CognitoUser[]>([]);
  const [search, setSearch] = useState('');
  const [confirmRemoveGroupModalVisible, setConfirmRemoveGroupModalVisible] = useState(false);
  const [confirmAddUserToAuthGroupModalVisible, setConfirmAddUserToAuthGroupModalVisible] = useState(false);
  const [confirmAddUserToCustomAuthGroupModalVisible, setConfirmAddUserToCustomAuthGroupModalVisible] = useState(false);
  const [confirmDeleteUserModalVisible, setConfirmDeleteUserModalVisible] = useState(false);
  const [confirmResendModalVisible, setConfirmResendModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedAuthGroupOption, setSelectedAuthGroupOption] = useState<OptionDefinition | null>(null);
  const [customAuthGroup, setCustomAuthGroup] = useState('');
  const [authGroupOptions, setAuthGroupOptions] = useState<{ label: string; value: string }[]>([]);

  const ColumnIDs = {
    USERNAME: 'login',
    EMAIL: 'email',
    GROUPS: 'groups',
    EMAIL_VERIFIED: 'email-verified',
    STATUS: 'status',
    ENABLED: 'enabled',
    ACTIONS: 'actions',
  };

  const ActionIDs = {
    ADD_USER_TO_AUTH_GROUP: 'add-user-to-auth-group',
    ADD_USER_TO_CUSTOM_AUTH_GROUP: 'add-user-to-custom-auth-group',
    DELETE_USER: 'delete-user',
  };

  const visibleColumns = [
    ColumnIDs.USERNAME,
    ColumnIDs.EMAIL,
    ColumnIDs.GROUPS,
    ColumnIDs.EMAIL_VERIFIED,
    ColumnIDs.STATUS,
    ColumnIDs.ENABLED,
    ColumnIDs.ACTIONS,
  ];

  const { items, collectionProps, paginationProps } = useCollection(users, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  const generateAuthGroupOptions = () => {
    // auth groups that may be added from the dropdown; some admin groups are excluded
    const authGroups: string[] = Object.values(AuthRole).filter(
      (group) => group !== AuthRole.JAM_ADMIN && group !== AuthRole.EVENT_SUPPORT
    );
    const newAuthGroupOptions = authGroups.map((group) => ({ label: getGroupTitle(group), value: group }));
    setAuthGroupOptions([...newAuthGroupOptions]);
  };

  const loadUsers = (includeGroups = false) => {
    setLoading(true);
    accountApi
      .listUsers(includeGroups)
      .then((res: CognitoUser[]) => {
        setLoading(false);
        setUsers(res);
      })
      .catch((err) => {
        setLoading(false);
        preProdLogger('Error loading users', err.message);
      });
  };

  const handleRemoveGroupClick = (group: string, user: CognitoUser) => {
    setSelectedGroup(group);
    setSelectedUser([user]);
    setConfirmRemoveGroupModalVisible(true);
  };

  const handleActions = (id: string, user: CognitoUser) => {
    setSelectedUser([user]);
    switch (id) {
      case ActionIDs.ADD_USER_TO_AUTH_GROUP:
        setConfirmAddUserToAuthGroupModalVisible(true);
        break;
      case ActionIDs.ADD_USER_TO_CUSTOM_AUTH_GROUP:
        setConfirmAddUserToCustomAuthGroupModalVisible(true);
        break;
      case ActionIDs.DELETE_USER:
        setConfirmDeleteUserModalVisible(true);
        break;
      default:
        return;
    }
    loadUsers();
  };

  const handleDeleteUser = () => {
    accountApi
      .deleteUser(selectedUser[0]?.email)
      .then(() => {
        handleResponse();
      })
      .catch((err) => {
        preProdLogger('Error deleting user', err.message);
        cleanupModals();
      });
  };

  const handleResend = () => {
    accountApi
      .resendInvite(selectedUser[0]?.email)
      .then(() => {
        handleResponse();
      })
      .catch((err) => {
        preProdLogger('Error resending invite', err.message);
        cleanupModals();
      });
  };

  const removeUserFromGroup = async () => {
    setLoading(true);
    await accountApi
      .removeUserFromGroup(selectedUser[0]?.email, selectedGroup)
      .then(() => {
        setLoading(false);
        handleResponse();
      })
      .catch((err) => {
        setLoading(false);
        preProdLogger('Error removing user from auth group', err.message);
        cleanupModals();
      });
  };

  const addUserToGroup = () => {
    if (selectedAuthGroupOption?.value || customAuthGroup) {
      setLoading(true);
      accountApi
        .addUserToGroup(selectedUser[0]?.email, selectedAuthGroupOption?.value || customAuthGroup)
        .then(() => {
          setLoading(false);
          handleResponse();
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger('Erroring adding user to auth group', err.message);
          cleanupModals();
        });
    }
  };

  const handleResponse = () => {
    cleanupModals();
  };

  const cleanupModals = () => {
    setSelectedGroup('');
    setSelectedUser([]);
    setSelectedAuthGroupOption(null);
    setCustomAuthGroup('');
    setConfirmRemoveGroupModalVisible(false);
    setConfirmAddUserToAuthGroupModalVisible(false);
    setConfirmAddUserToCustomAuthGroupModalVisible(false);
    setConfirmDeleteUserModalVisible(false);
    setConfirmResendModalVisible(false);
  };

  useEffect(() => {
    loadUsers();
    generateAuthGroupOptions();
  }, []);

  const handleSearchedValues = (value: string) => {
    setSearch(value);
    const clonedUsers = cloneDeep(users);
    const filteredValue = clonedUsers.filter((user) => user.email.includes(value));
    if (value && filteredValue) {
      setFilteredUsers(filteredValue);
    } else {
      setFilteredUsers(users);
    }
  };

  return (
    <React.Fragment>
      <ConfirmModal
        title={t(i18nKeys.settings.userManagement.modals.removeUserFromAuthGroup.title)}
        message={t(i18nKeys.settings.userManagement.modals.removeUserFromAuthGroup.message, {
          username: selectedUser[0]?.cognitoUsername,
          group: getGroupTitle(selectedGroup),
        })}
        confirmBtnLabel={t(i18nKeys.settings.userManagement.buttons.yesContinue)}
        onConfirm={() => removeUserFromGroup()}
        visible={confirmRemoveGroupModalVisible}
        onCancel={() => cleanupModals()}
      />
      <ConfirmModal
        title={t(i18nKeys.settings.userManagement.modals.addUserToAuthGroup.title)}
        message={
          <React.Fragment>
            <div>
              {t(i18nKeys.settings.userManagement.modals.addUserToAuthGroup.message, {
                username: selectedUser[0]?.cognitoUsername,
              })}
            </div>
            <Select
              options={authGroupOptions}
              selectedOption={selectedAuthGroupOption}
              onChange={({ detail }) => setSelectedAuthGroupOption(detail.selectedOption)}
            />
          </React.Fragment>
        }
        disabled={!selectedAuthGroupOption}
        onCancel={() => cleanupModals()}
        onConfirm={() => addUserToGroup()}
        visible={confirmAddUserToAuthGroupModalVisible}
      />
      <ConfirmModal
        title={t(i18nKeys.settings.userManagement.modals.addUserToCustomAuthGroup.title)}
        visible={confirmAddUserToCustomAuthGroupModalVisible}
        onCancel={() => cleanupModals()}
        onConfirm={() => addUserToGroup()}
        disabled={!customAuthGroup}
        message={
          <React.Fragment>
            <div>
              {t(i18nKeys.settings.userManagement.modals.addUserToCustomAuthGroup.message, {
                username: selectedUser[0]?.cognitoUsername,
              })}
            </div>
            <Input type="text" value={customAuthGroup} onChange={({ detail }) => setCustomAuthGroup(detail.value)} />
          </React.Fragment>
        }
      />
      <ConfirmModal
        visible={confirmDeleteUserModalVisible}
        onCancel={() => cleanupModals()}
        onConfirm={() => handleDeleteUser()}
      />
      <ConfirmModal
        visible={confirmResendModalVisible}
        onCancel={() => cleanupModals()}
        onConfirm={() => handleResend()}
      />
      <Table
        className="mb-12"
        {...collectionProps}
        header={
          <Box margin={{ vertical: 's' }}>
            <TableHeader
              title="Users"
              actionButtons={
                <Box float="right">
                  <Button iconName="refresh" onClick={() => loadUsers()}>
                    {t(i18nKeys.general.refresh)}
                  </Button>
                </Box>
              }
            />
          </Box>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
        items={search ? filteredUsers : items}
        loading={loading}
        visibleColumns={visibleColumns}
        resizableColumns
        wrapLines
        columnDefinitions={COLUMN_DEFINITIONS(preferences, handleRemoveGroupClick, handleActions)}
        filter={
          <Grid gridDefinition={[{ colspan: 6 }]}>
            <Input
              onChange={({ detail }) => handleSearchedValues(detail.value)}
              value={search}
              placeholder="Search"
              type="search"
            />
          </Grid>
        }
      />
    </React.Fragment>
  );
};
export default UsersTable;
