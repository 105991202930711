export interface Country {
  code: string;
  name: string;
}

export const COUNTRIES: Country[] = [
  {
    code: 'US',
    name: 'countries.united_states',
  },
  {
    code: 'AF',
    name: 'countries.afghanistan',
  },
  {
    code: 'AX',
    name: 'countries.aland_islands',
  },
  {
    code: 'AL',
    name: 'countries.albania',
  },
  {
    code: 'DZ',
    name: 'countries.algeria',
  },
  {
    code: 'AS',
    name: 'countries.american_samoa',
  },
  {
    code: 'AD',
    name: 'countries.andorra',
  },
  {
    code: 'AO',
    name: 'countries.angola',
  },
  {
    code: 'AI',
    name: 'countries.anguilla',
  },
  {
    code: 'AQ',
    name: 'countries.antarctica',
  },
  {
    code: 'AG',
    name: 'countries.antigua_and_barbuda',
  },
  {
    code: 'AR',
    name: 'countries.argentina',
  },
  {
    code: 'AM',
    name: 'countries.armenia',
  },
  {
    code: 'AW',
    name: 'countries.aruba',
  },
  {
    code: 'AU',
    name: 'countries.australia',
  },
  {
    code: 'AT',
    name: 'countries.austria',
  },
  {
    code: 'AZ',
    name: 'countries.azerbaijan',
  },
  {
    code: 'BS',
    name: 'countries.bahamas',
  },
  {
    code: 'BH',
    name: 'countries.bahrain',
  },
  {
    code: 'BD',
    name: 'countries.bangladesh',
  },
  {
    code: 'BB',
    name: 'countries.barbados',
  },
  {
    code: 'BY',
    name: 'countries.belarus',
  },
  {
    code: 'BE',
    name: 'countries.belgium',
  },
  {
    code: 'BZ',
    name: 'countries.belize',
  },
  {
    code: 'BJ',
    name: 'countries.benin',
  },
  {
    code: 'BM',
    name: 'countries.bermuda',
  },
  {
    code: 'BT',
    name: 'countries.bhutan',
  },
  {
    code: 'BO',
    name: 'countries.bolivia',
  },
  {
    code: 'BA',
    name: 'countries.bosnia_and_herzegovina',
  },
  {
    code: 'BW',
    name: 'countries.botswana',
  },
  {
    code: 'BV',
    name: 'countries.bouvet_island',
  },
  {
    code: 'BR',
    name: 'countries.brazil',
  },
  {
    code: 'IO',
    name: 'countries.british_indian_ocean_territory',
  },
  {
    code: 'BN',
    name: 'countries.brunei_darussalam',
  },
  {
    code: 'BG',
    name: 'countries.bulgaria',
  },
  {
    code: 'BF',
    name: 'countries.burkina_faso',
  },
  {
    code: 'BI',
    name: 'countries.burundi',
  },
  {
    code: 'KH',
    name: 'countries.cambodia',
  },
  {
    code: 'CM',
    name: 'countries.cameroon',
  },
  {
    code: 'CA',
    name: 'countries.canada',
  },
  {
    code: 'CV',
    name: 'countries.cape_verde',
  },
  {
    code: 'KY',
    name: 'countries.cayman_islands',
  },
  {
    code: 'CF',
    name: 'countries.central_african_republic',
  },
  {
    code: 'TD',
    name: 'countries.chad',
  },
  {
    code: 'CL',
    name: 'countries.chile',
  },
  {
    code: 'CN',
    name: 'countries.china',
  },
  {
    code: 'CX',
    name: 'countries.christmas_island',
  },
  {
    code: 'CC',
    name: 'countries.cocos_keeling_islands',
  },
  {
    code: 'CO',
    name: 'countries.colombia',
  },
  {
    code: 'KM',
    name: 'countries.comoros',
  },
  {
    code: 'CG',
    name: 'countries.congo',
  },
  {
    code: 'CD',
    name: 'countries.congo_the_democratic_republic_of_the',
  },
  {
    code: 'CK',
    name: 'countries.cook_islands',
  },
  {
    code: 'CR',
    name: 'countries.costa_rica',
  },
  {
    code: 'CI',
    name: `countries.cote_divoire`,
  },
  {
    code: 'HR',
    name: 'countries.croatia',
  },
  {
    code: 'CU',
    name: 'countries.cuba',
  },
  {
    code: 'CY',
    name: 'countries.cyprus',
  },
  {
    code: 'CZ',
    name: 'countries.czech_republic',
  },
  {
    code: 'DK',
    name: 'countries.denmark',
  },
  {
    code: 'DJ',
    name: 'countries.djibouti',
  },
  {
    code: 'DM',
    name: 'countries.dominica',
  },
  {
    code: 'DO',
    name: 'countries.dominican_republic',
  },
  {
    code: 'EC',
    name: 'countries.ecuador',
  },
  {
    code: 'EG',
    name: 'countries.egypt',
  },
  {
    code: 'SV',
    name: 'countries.el_salvador',
  },
  {
    code: 'GQ',
    name: 'countries.equatorial_guinea',
  },
  {
    code: 'ER',
    name: 'countries.eritrea',
  },
  {
    code: 'EE',
    name: 'countries.estonia',
  },
  {
    code: 'ET',
    name: 'countries.ethiopia',
  },
  {
    code: 'FK',
    name: 'countries.falkland_islands_malvinas',
  },
  {
    code: 'FO',
    name: 'countries.faroe_islands',
  },
  {
    code: 'FJ',
    name: 'countries.fiji',
  },
  {
    code: 'FI',
    name: 'countries.finland',
  },
  {
    code: 'FR',
    name: 'countries.france',
  },
  {
    code: 'GF',
    name: 'countries.french_guiana',
  },
  {
    code: 'PF',
    name: 'countries.french_polynesia',
  },
  {
    code: 'TF',
    name: 'countries.french_southern_territories',
  },
  {
    code: 'GA',
    name: 'countries.gabon',
  },
  {
    code: 'GM',
    name: 'countries.gambia',
  },
  {
    code: 'GE',
    name: 'countries.georgia',
  },
  {
    code: 'DE',
    name: 'countries.germany',
  },
  {
    code: 'GH',
    name: 'countries.ghana',
  },
  {
    code: 'GI',
    name: 'countries.gibraltar',
  },
  {
    code: 'GR',
    name: 'countries.greece',
  },
  {
    code: 'GL',
    name: 'countries.greenland',
  },
  {
    code: 'GD',
    name: 'countries.grenada',
  },
  {
    code: 'GP',
    name: 'countries.guadeloupe',
  },
  {
    code: 'GU',
    name: 'countries.guam',
  },
  {
    code: 'GT',
    name: 'countries.guatemala',
  },
  {
    code: 'GG',
    name: 'countries.guernsey',
  },
  {
    code: 'GN',
    name: 'countries.guinea',
  },
  {
    code: 'GW',
    name: 'countries.guinea_bissau',
  },
  {
    code: 'GY',
    name: 'countries.guyana',
  },
  {
    code: 'HT',
    name: 'countries.haiti',
  },
  {
    code: 'HM',
    name: 'countries.heard_island_and_mcdonald_islands',
  },
  {
    code: 'VA',
    name: 'countries.holy_see_vatican_city_state',
  },
  {
    code: 'HN',
    name: 'countries.honduras',
  },
  {
    code: 'HK',
    name: 'countries.hong_kong',
  },
  {
    code: 'HU',
    name: 'countries.hungary',
  },
  {
    code: 'IS',
    name: 'countries.iceland',
  },
  {
    code: 'IN',
    name: 'countries.india',
  },
  {
    code: 'ID',
    name: 'countries.indonesia',
  },
  {
    code: 'IR',
    name: 'countries.iran_islamic_republic_of',
  },
  {
    code: 'IQ',
    name: 'countries.iraq',
  },
  {
    code: 'IE',
    name: 'countries.ireland',
  },
  {
    code: 'IM',
    name: 'countries.isle_of_man',
  },
  {
    code: 'IL',
    name: 'countries.israel',
  },
  {
    code: 'IT',
    name: 'countries.italy',
  },
  {
    code: 'JM',
    name: 'countries.jamaica',
  },
  {
    code: 'JP',
    name: 'countries.japan',
  },
  {
    code: 'JE',
    name: 'countries.jersey',
  },
  {
    code: 'JO',
    name: 'countries.jordan',
  },
  {
    code: 'KZ',
    name: 'countries.kazakhstan',
  },
  {
    code: 'KE',
    name: 'countries.kenya',
  },
  {
    code: 'KI',
    name: 'countries.kiribati',
  },
  {
    code: 'KP',
    name: 'countries.korea_democratic_peoples_republic_of',
  },
  {
    code: 'KR',
    name: 'countries.korea_republic_of',
  },
  {
    code: 'KW',
    name: 'countries.kuwait',
  },
  {
    code: 'KG',
    name: 'countries.kyrgyzstan',
  },
  {
    code: 'LA',
    name: 'countries.lao_peoples_democratic_republic',
  },
  {
    code: 'LV',
    name: 'countries.latvia',
  },
  {
    code: 'LB',
    name: 'countries.lebanon',
  },
  {
    code: 'LS',
    name: 'countries.lesotho',
  },
  {
    code: 'LR',
    name: 'countries.liberia',
  },
  {
    code: 'LY',
    name: 'countries.libyan_arab_jamahiriya',
  },
  {
    code: 'LI',
    name: 'countries.liechtenstein',
  },
  {
    code: 'LT',
    name: 'countries.lithuania',
  },
  {
    code: 'LU',
    name: 'countries.luxembourg',
  },
  {
    code: 'MO',
    name: 'countries.macau',
  },
  {
    code: 'MK',
    name: 'countries.macedonia_the_former_yugoslav_republic_of',
  },
  {
    code: 'MG',
    name: 'countries.madagascar',
  },
  {
    code: 'MW',
    name: 'countries.malawi',
  },
  {
    code: 'MY',
    name: 'countries.malaysia',
  },
  {
    code: 'MV',
    name: 'countries.maldives',
  },
  {
    code: 'ML',
    name: 'countries.mali',
  },
  {
    code: 'MT',
    name: 'countries.malta',
  },
  {
    code: 'MH',
    name: 'countries.marshall_islands',
  },
  {
    code: 'MQ',
    name: 'countries.martinique',
  },
  {
    code: 'MR',
    name: 'countries.mauritania',
  },
  {
    code: 'MU',
    name: 'countries.mauritius',
  },
  {
    code: 'YT',
    name: 'countries.mayotte',
  },
  {
    code: 'MX',
    name: 'countries.mexico',
  },
  {
    code: 'FM',
    name: 'countries.micronesia_federated_states_of',
  },
  {
    code: 'MD',
    name: 'countries.moldova_republic_of',
  },
  {
    code: 'MC',
    name: 'countries.monaco',
  },
  {
    code: 'MN',
    name: 'countries.mongolia',
  },
  {
    code: 'ME',
    name: 'countries.montenegro',
  },
  {
    code: 'MS',
    name: 'countries.montserrat',
  },
  {
    code: 'MA',
    name: 'countries.morocco',
  },
  {
    code: 'MZ',
    name: 'countries.mozambique',
  },
  {
    code: 'MM',
    name: 'countries.myanmar',
  },
  {
    code: 'NA',
    name: 'countries.namibia',
  },
  {
    code: 'NR',
    name: 'countries.nauru',
  },
  {
    code: 'NP',
    name: 'countries.nepal',
  },
  {
    code: 'NL',
    name: 'countries.netherlands',
  },
  {
    code: 'AN',
    name: 'countries.netherlands_antilles',
  },
  {
    code: 'NC',
    name: 'countries.new_caledonia',
  },
  {
    code: 'NZ',
    name: 'countries.new_zealand',
  },
  {
    code: 'NI',
    name: 'countries.nicaragua',
  },
  {
    code: 'NE',
    name: 'countries.niger',
  },
  {
    code: 'NG',
    name: 'countries.nigeria',
  },
  {
    code: 'NU',
    name: 'countries.niue',
  },
  {
    code: 'NF',
    name: 'countries.norfolk_island',
  },
  {
    code: 'MP',
    name: 'countries.northern_mariana_islands',
  },
  {
    code: 'NO',
    name: 'countries.norway',
  },
  {
    code: 'OM',
    name: 'countries.oman',
  },
  {
    code: 'PK',
    name: 'countries.pakistan',
  },
  {
    code: 'PW',
    name: 'countries.palau',
  },
  {
    code: 'PS',
    name: 'countries.palestinian_territory_occupied',
  },
  {
    code: 'PA',
    name: 'countries.panama',
  },
  {
    code: 'PG',
    name: 'countries.papua_new_guinea',
  },
  {
    code: 'PY',
    name: 'countries.paraguay',
  },
  {
    code: 'PE',
    name: 'countries.peru',
  },
  {
    code: 'PH',
    name: 'countries.philippines',
  },
  {
    code: 'PN',
    name: 'countries.pitcairn',
  },
  {
    code: 'PL',
    name: 'countries.poland',
  },
  {
    code: 'PT',
    name: 'countries.portugal',
  },
  {
    code: 'PR',
    name: 'countries.puerto_rico',
  },
  {
    code: 'QA',
    name: 'countries.qatar',
  },
  {
    code: 'RE',
    name: 'countries.reunion',
  },
  {
    code: 'RO',
    name: 'countries.romania',
  },
  {
    code: 'RU',
    name: 'countries.russian_federation',
  },
  {
    code: 'RW',
    name: 'countries.rwanda',
  },
  {
    code: 'SH',
    name: 'countries.saint_helena',
  },
  {
    code: 'KN',
    name: 'countries.saint_kitts_and_nevis',
  },
  {
    code: 'LC',
    name: 'countries.saint_lucia',
  },
  {
    code: 'PM',
    name: 'countries.saint_pierre_and_miquelon',
  },
  {
    code: 'VC',
    name: 'countries.saint_vincent_and_the_grenadines',
  },
  {
    code: 'WS',
    name: 'countries.samoa',
  },
  {
    code: 'SM',
    name: 'countries.san_marino',
  },
  {
    code: 'ST',
    name: 'countries.sao_tome_and_principe',
  },
  {
    code: 'SA',
    name: 'countries.saudi_arabia',
  },
  {
    code: 'SN',
    name: 'countries.senegal',
  },
  {
    code: 'RS',
    name: 'countries.serbia',
  },
  {
    code: 'SC',
    name: 'countries.seychelles',
  },
  {
    code: 'SL',
    name: 'countries.sierra_leone',
  },
  {
    code: 'SG',
    name: 'countries.singapore',
  },
  {
    code: 'SK',
    name: 'countries.slovakia',
  },
  {
    code: 'SI',
    name: 'countries.slovenia',
  },
  {
    code: 'SB',
    name: 'countries.solomon_islands',
  },
  {
    code: 'SO',
    name: 'countries.somalia',
  },
  {
    code: 'ZA',
    name: 'countries.south_africa',
  },
  {
    code: 'GS',
    name: 'countries.south_georgia_and_the_south_sandwich_islands',
  },
  {
    code: 'ES',
    name: 'countries.spain',
  },
  {
    code: 'LK',
    name: 'countries.sri_lanka',
  },
  {
    code: 'SD',
    name: 'countries.sudan',
  },
  {
    code: 'SR',
    name: 'countries.suriname',
  },
  {
    code: 'SJ',
    name: 'countries.svalbard_and_jan_mayen',
  },
  {
    code: 'SZ',
    name: 'countries.swaziland',
  },
  {
    code: 'SE',
    name: 'countries.sweden',
  },
  {
    code: 'CH',
    name: 'countries.switzerland',
  },
  {
    code: 'SY',
    name: 'countries.syrian_arab_republic',
  },
  {
    code: 'TW',
    name: 'countries.taiwan',
  },
  {
    code: 'TJ',
    name: 'countries.tajikistan',
  },
  {
    code: 'TZ',
    name: 'countries.tanzania_united_republic_of',
  },
  {
    code: 'TH',
    name: 'countries.thailand',
  },
  {
    code: 'TL',
    name: 'countries.timor_leste',
  },
  {
    code: 'TG',
    name: 'countries.togo',
  },
  {
    code: 'TK',
    name: 'countries.tokelau',
  },
  {
    code: 'TO',
    name: 'countries.tonga',
  },
  {
    code: 'TT',
    name: 'countries.trinidad_and_tobago',
  },
  {
    code: 'TN',
    name: 'countries.tunisia',
  },
  {
    code: 'TR',
    name: 'countries.turkey',
  },
  {
    code: 'TM',
    name: 'countries.turkmenistan',
  },
  {
    code: 'TC',
    name: 'countries.turks_and_caicos_islands',
  },
  {
    code: 'TV',
    name: 'countries.tuvalu',
  },
  {
    code: 'UG',
    name: 'countries.uganda',
  },
  {
    code: 'UA',
    name: 'countries.ukraine',
  },
  {
    code: 'AE',
    name: 'countries.united_arab_emirates',
  },
  {
    code: 'GB',
    name: 'countries.united_kingdom',
  },
  {
    code: 'UM',
    name: 'countries.united_states_minor_outlying_islands',
  },
  {
    code: 'UY',
    name: 'countries.uruguay',
  },
  {
    code: 'UZ',
    name: 'countries.uzbekistan',
  },
  {
    code: 'VU',
    name: 'countries.vanuatu',
  },
  {
    code: 'VE',
    name: 'countries.venezuela',
  },
  {
    code: 'VN',
    name: 'countries.viet_nam',
  },
  {
    code: 'VG',
    name: 'countries.virgin_islands_british',
  },
  {
    code: 'VI',
    name: 'countries.virgin_islands_us',
  },
  {
    code: 'WF',
    name: 'countries.wallis_and_futuna',
  },
  {
    code: 'EH',
    name: 'countries.western_sahara',
  },
  {
    code: 'YE',
    name: 'countries.yemen',
  },
  {
    code: 'ZM',
    name: 'countries.zambia',
  },
  {
    code: 'ZW',
    name: 'countries.zimbabwe',
  },
];

export const EU_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PO',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'AL',
  'AD',
  'AM',
  'BY',
  'BA',
  'FO',
  'GE',
  'GI',
  'IS',
  'IM',
  'XK',
  'LI',
  'MK',
  'MD',
  'MC',
  'ME',
  'NO',
  'RU',
  'SM',
  'RS',
  'CH',
  'TR',
  'UA',
  'GB',
  'VA',
];
