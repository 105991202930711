import moment from 'moment-timezone';

const teamChatCounterKey = 'teamCounter';
const supportChatCounterKey = 'supportChatCounter';

export const getMessageSentTime = (timeSent: number) => {
  const date = moment(timeSent);
  let format = 'h:mm A';
  if (moment().diff(date, 'days') > 1) {
    format = 'MM/DD/YY h:mm A';
  }
  return date.format(format);
};

export const decodeHtmlCharCodes = (str: string) => {
  return str.replace(/(&#(\d+);)/g, (match, capture, charCode) => {
    return String.fromCharCode(charCode as number);
  });
};

export const getRightOffset = (element: HTMLDivElement): Record<string, string> => {
  const supportButtonLeft = element.offsetLeft + element.offsetWidth;
  const supportButtonParentLeft = element.parentElement?.parentElement?.offsetWidth || 0;
  const finalLeft = supportButtonParentLeft ? supportButtonParentLeft - supportButtonLeft : supportButtonLeft;
  const style = { right: `${finalLeft}px` };
  return style;
};

export const getTeamChatCounterKey = (eventName: string) => `${teamChatCounterKey}-${eventName}`;
export const getSupportChatCounterKey = (eventName: string) => `${supportChatCounterKey}-${eventName}`;
