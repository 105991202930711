import React, { useState, useMemo } from 'react';

import { Box, Container, Header, InputProps, SpaceBetween } from '@amzn/awsui-components-react';

// components
import { EventName } from '@/src/components/ui/molecules/EventTemplate';
import EventConfiguration from './EventConfiguration';

// context
import { useCreateEventTemplate } from '@/src/store/create-event-template.context';

// types
import { EventTemplateNameDurationFields } from '@/src/types/EventTemplate';
import { eventNameDurationValidator } from '@/src/utils/event-template.validation.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';

interface Props {
  activeStepIndex: number;
  setActiveStepIndex: (x: number) => void;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

export const NameDuration: React.FC<Props> = ({ validationHandler }) => {
  const [eventNameError, setEventNameError] = useState('');
  const [eventDurationError, setEventDurationError] = useState('');
  const { name, learningType, duration, onNameChange, onLearningTypeChange, onDurationChange } =
    useCreateEventTemplate();
  const { titleString } = i18nKeys.eventTemplates.step1;
  const { offers } = useEventTemplateOffers();
  const { t } = useTranslation();

  const handleEventNameChange: InputProps['onChange'] = (e) => {
    onNameChange(e.detail.value);
    validator.isValidField(EventTemplateNameDurationFields.EVENT_NAME);
  };

  const validator = useMemo(
    () =>
      eventNameDurationValidator(
        name,
        duration,
        `${t(titleString)} ${t(i18nKeys.generic.lengthLimit4to250)}`,
        t(i18nKeys.eventTemplates.step1.eventDurationValidationText),
        new Map<EventTemplateNameDurationFields, (error: string) => void>([
          [EventTemplateNameDurationFields.EVENT_NAME, (error: string) => setEventNameError(error)],
          [EventTemplateNameDurationFields.EVENT_DURATION, (error: string) => setEventDurationError(error)],
        ])
      ),
    [name, duration, eventNameDurationValidator]
  );

  validationHandler(() => {
    const result = validator.isValidSection(true);
    if (result) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  });

  return (
    <Box margin={{ bottom: 'l' }}>
      <SpaceBetween size="l">
        <EventName
          title={name}
          error={eventNameError}
          onChange={handleEventNameChange}
          onBlur={() => validator.isValidField(EventTemplateNameDurationFields.EVENT_NAME)}
        />
        <Container header={<Header variant="h2">{t(i18nKeys.eventTemplates.step1.eventConfiguration)}</Header>}>
          <EventConfiguration
            stretch
            learningType={learningType}
            onLearningTypeChange={onLearningTypeChange}
            offers={offers}
            duration={duration}
            eventDurationError={eventDurationError}
            onDurationChange={onDurationChange}
          />
        </Container>
      </SpaceBetween>
    </Box>
  );
};
