import React from 'react';

// components
import { LoadingBar } from '@/src/components/common/LoadingBar';
import Progress from '@/src/components/ui/molecules/Progress/Progress';
import ReviewStars from '@/src/components/ui/molecules/Reviews/Reviews';

// table-config
import { EventTemplateRating } from '@/src/types/EventTemplate';

interface IEventDetailFeedbackRating {
  eventTemplateRating: EventTemplateRating | null;
}

const EventDetailFeedbackRating: React.FC<IEventDetailFeedbackRating> = ({ eventTemplateRating }) => {
  if (!eventTemplateRating) {
    return <LoadingBar />;
  }

  return (
    <div className="feedback-section">
      <div className="review-average">
        <span>{eventTemplateRating.averageRating.toFixed(1)}</span>
        <ReviewStars rating={eventTemplateRating.averageRating} />
      </div>

      <div className="review-percentage">
        <Progress
          key={eventTemplateRating.fiveStarProportion}
          progress={eventTemplateRating.fiveStarProportion}
          rating={5}
          reviews
        />
        <Progress
          key={eventTemplateRating.fourStarProportion}
          progress={eventTemplateRating.fourStarProportion}
          rating={4}
          reviews
        />
        <Progress
          key={eventTemplateRating.threeStarProportion}
          progress={eventTemplateRating.threeStarProportion}
          rating={3}
          reviews
        />
        <Progress
          key={eventTemplateRating.twoStarProportion}
          progress={eventTemplateRating.twoStarProportion}
          rating={2}
          reviews
        />
        <Progress
          key={eventTemplateRating.oneStarProportion}
          progress={eventTemplateRating.oneStarProportion}
          rating={1}
          reviews
        />
      </div>
    </div>
  );
};

export default EventDetailFeedbackRating;
