import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from './common';
import { ApprovableDiff } from './ResourceDeployment';

export interface ChangeRequest {
  status: common.ChangeRequestStatus;
  requestedBy: common.NullableString;
  createdDate: common.NullableTimeStamp;
  lastUpdatedDate: common.NullableNumber;
  pending: boolean;
  resolved: boolean;
}

export interface Approvable {
  uuid: common.NullableString;
  createdDate: common.NullableTimeStamp;
  approvalStatus: common.Nullable<common.ApprovalStatus>;
  changeRequest: common.Nullable<ChangeRequest>;
  changeHistory: ApprovableDiff[];
  requestResolved: boolean;
  pendingChangeRequest: boolean;
}

@jsonObject
export abstract class WithApprovalAndChangeRequest implements Approvable {
  @jsonMember(common.NullableTimeStampValue)
  createdDate: common.NullableTimeStamp = null;

  @jsonMember(String)
  approvalStatus: common.Nullable<common.ApprovalStatus> = null;

  @jsonMember(common.NullableObjectValue)
  changeRequest: common.Nullable<ChangeRequest> = null;

  @jsonArrayMember(ApprovableDiff)
  changeHistory: ApprovableDiff[] = [];

  // must ovveride this method if implementing this class
  abstract get uuid(): common.NullableString;

  get final(): boolean {
    return this.cancelled || this.denied;
  }

  get approved(): boolean {
    // if there isn't a uuid, then it hasn't been saved yet and is therefore not approved
    if (!this.uuid) {
      return false;
    }

    // if there is a uuid, but no approval status, then this is a legacy event and is approved by default
    if (!this.approvalStatus) {
      return true;
    }

    // otherwise, the event must be in approved status
    return this.approvalStatus === common.ApprovalStatus.REQUEST_APPROVED;
  }

  get cancelled(): boolean {
    return this.approvalStatus === common.ApprovalStatus.REQUEST_CANCELLED;
  }

  get wasEverApproved(): boolean {
    return this.approved;
  }

  get neverApproved(): boolean {
    return !this.wasEverApproved;
  }

  get denied(): boolean {
    return this.approvalStatus === common.ApprovalStatus.REQUEST_DENIED;
  }

  get pendingChangeRequest(): boolean {
    const changeRequest = this.changeRequest;
    if (!changeRequest) {
      return false;
    }
    return changeRequest.resolved !== true;
  }

  get requestResolved(): boolean {
    return (
      this.approvalStatus === common.ApprovalStatus.REQUEST_APPROVED ||
      this.approvalStatus === common.ApprovalStatus.REQUEST_DENIED ||
      this.approvalStatus === common.ApprovalStatus.REQUEST_CANCELLED
    );
  }

  /**
   * If the request or change request is pending approval, then returns the time the request was submitted.
   */
  get pendingApprovalRequestSubmittedTime(): common.NullableNumber {
    // If this is not resolve (approved, cancelled, denied) and has a createdDate populated then show
    // the time since the request was submitted
    if (!this.requestResolved) {
      return this.createdDate || null;

      // Same for change requests. Show how long the change request has been open.
    } else if (this.requestResolved && this.pendingChangeRequest) {
      return this.changeRequest?.createdDate || null;
    }

    return null;
  }
}
