import React from 'react'
import { Header } from "@amzn/awsui-components-react";
import { useTranslation } from 'react-i18next';

export interface IChallengeOrderingTitleProps {
  title: string;
  count: number;
}

export const ChallengeOrderingTitle: React.FC<IChallengeOrderingTitleProps> = ({ title, count}) => {
  const { t } = useTranslation()
  return (
    <div className="challenge-ordering-header">
      <Header variant="h2">
        {t(title)} <span className="challenge-count">({count})</span>
      </Header>
    </div>
  );
};