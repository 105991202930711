/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from 'react';
import { Box, Button, FormField, RadioGroup, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import LearnSomethingAndFeedback from './LearnSomethingAndFeedback';
import RatingContainer from './RatingContainer';
import { JamChallengeFeedback } from '@/src/types/JamFeedback';
import { useJamFeedback } from '@/src/store/jam-feedback';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { IEventFeedbackData } from '@/src/types/JamCommon';
import { JAM_CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { useHistory } from 'react-router-dom';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useToolPanel } from '@/src/store/tool-panel.context';

interface IFeedbackRes {
  challengeId?: any;
}

const ChallengeFeedback: React.FC<IFeedbackRes> = ({ challengeId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loadJamChallengeFeedbackData } = useJamFeedback();
  const { eventFeedbackData } = useJamChallenge();
  const { eventName } = useJamEventDetails();

  const { showSidenav, toggleSideNav } = useToolPanel();
  const { clearFlashbars } = useFlashbars();
  const [challengeRatingRequired, setChallengeRatingRequired] = React.useState('');
  const [challengeDifficultyRequired, setChallengeDifficultyRequired] = React.useState('');
  const [challengeLearnSomethingRequired, setChallengeLearnSomethingRequired] = React.useState('');
  const [challengeFeedback, setChallengeFeedback] = React.useState<JamChallengeFeedback>({
    didYouLearnSomethingNew: '',
    challengeDifficulty: 0,
    challengeRank: 0,
    notes: '',
    updatedDate: '',
  });

  useEffect(() => {
    // Hide the side nav on the feedback page to discourage navigation, per product request
    const initialSideNavValue = showSidenav;
    toggleSideNav(false);

    if ((eventFeedbackData as unknown as IEventFeedbackData)?.challengeFeedback) {
      const feedback = (eventFeedbackData as unknown as IEventFeedbackData).challengeFeedback;

      const rank: JamChallengeFeedback = feedback[challengeId];
      if (rank) {
        const obj = {
          didYouLearnSomethingNew:
            rank.didYouLearnSomethingNew === true ? 'yes' : rank.didYouLearnSomethingNew === false ? 'no' : '',
          challengeDifficulty: rank.challengeDifficulty || 0,
          challengeRank: rank.challengeRank,
          notes: rank?.notes || '',
          updatedDate: rank.updatedDate || '',
        };
        setChallengeFeedback(obj);
      }
    }

    // Restore side nav state on component unload
    return () => {
      toggleSideNav(initialSideNavValue);
    };
  }, []);

  useEffect(() => {
    const errorText = t(i18nKeys.myJams.feedback.label.rating.ratingRequired);
    if (!challengeFeedback.challengeRank) {
      setChallengeRatingRequired(errorText);
    } else {
      setChallengeRatingRequired('');
    }

    if (!challengeFeedback.challengeDifficulty) {
      setChallengeDifficultyRequired(errorText);
    } else {
      setChallengeDifficultyRequired('');
    }

    if (challengeFeedback.didYouLearnSomethingNew === '') {
      setChallengeLearnSomethingRequired(errorText);
    } else {
      setChallengeLearnSomethingRequired('');
    }
  }, [challengeFeedback.challengeRank, challengeFeedback.challengeDifficulty, challengeFeedback.didYouLearnSomethingNew]);

  const handleChallengeFeedback = (name: string, value: number) => {
    setChallengeFeedback({ ...challengeFeedback, [name]: value });
  };

  const handleChallengeRadioAndComment = (name: string, value: string) => {
    setChallengeFeedback({ ...challengeFeedback, [name]: value });
  };

  const handleSubmit = async () => {
    clearFlashbars();
    await loadJamChallengeFeedbackData({
      challengeId,
      body: {
        ...challengeFeedback,
        didYouLearnSomethingNew: challengeFeedback.didYouLearnSomethingNew === 'yes',
        updatedDate: new Date().toISOString(),
      },
    });

    // Return to the challenge details.
    history.push(JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventName, challengeId]));
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      <RatingContainer
        label={t(i18nKeys.myJams.feedback.label.rating.quality)}
        name="challengeRank"
        rating={challengeFeedback.challengeRank}
        setRating={handleChallengeFeedback}
        constraintText={challengeRatingRequired}
      />
      <Box variant="div">
        <FormField
          label={t(i18nKeys.myJams.feedback.radio.levelOfDifficulty)}
          constraintText={challengeDifficultyRequired}
        >
          <RadioGroup
            onChange={({ detail }) => {
              setChallengeFeedback({ ...challengeFeedback, challengeDifficulty: Number(detail.value) })
            }}
            value={String(challengeFeedback.challengeDifficulty)}
            items={[
              { value: '1', label: `1 - ${t(i18nKeys.myJams.feedback.label.difficulty.veryEasy)}` },
              { value: '2', label: `2 - ${t(i18nKeys.myJams.feedback.label.difficulty.easy)}` },
              { value: '3', label: `3 - ${t(i18nKeys.myJams.feedback.label.difficulty.moderate)}` },
              { value: '4', label: `4 - ${t(i18nKeys.myJams.feedback.label.difficulty.difficult)}` },
              { value: '5', label: `5 - ${t(i18nKeys.myJams.feedback.label.difficulty.veryDifficult)}` },
            ]}
            data-testid="difficulty-radio-group"
          />
        </FormField>
      </Box>
      <LearnSomethingAndFeedback
        radio={challengeFeedback.didYouLearnSomethingNew}
        radioConstraintText={challengeLearnSomethingRequired}
        textArea={challengeFeedback.notes}
        radioName="didYouLearnSomethingNew"
        textAreaName="notes"
        setValue={handleChallengeRadioAndComment}
      />
      <Box float="right">
        <Button
          variant="primary"
          onClick={() => void handleSubmit()}
          disabled={Boolean(challengeRatingRequired || challengeDifficultyRequired || challengeLearnSomethingRequired)}
          data-testid="submit-feedback-btn"
        >
          {t(i18nKeys.myJams.feedback.buttons.submitFeedback)}
        </Button>
      </Box>
    </SpaceBetween>
  );
};

export default ChallengeFeedback;
