import { TFunction } from 'i18next';
import { omit, pick } from 'lodash';
import { Campaign, CampaignEventDetails, CampaignGroup, CampaignParticipant, JamCampaignRequest } from '../types/Campaign';
import { DateRangeFilter } from '../types/common';
import { i18nKeys } from '../utils/i18n.utils';
import { asList } from '../utils/list.utils';
import { fromPlainObject } from '../utils/mapper.utils';
import { ApiClient } from './ApiClient';
import { QueryParams } from './types';

export class CampaignAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  public async getJamCampaignsByDateRange(range?: DateRangeFilter, silent = false): Promise<Campaign[]> {
    const params: QueryParams = {};

    if (range) {
      if (range.start) {
        params.dateRangeStart = range.start;
      }
      if (range.end) {
        params.dateRangeEnd = range.end;
      }
    }

    return this.apiClient.get({
      path: '/admin/campaigns',
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.getJamCampaignsByDateRange),
      params,
      responseMapper: asList((res) => fromPlainObject(res, Campaign) as Campaign),
      silent,
    }) as Promise<Campaign[]>;
  }

  public async getCampaign(campaignId: string, silent = false): Promise<Campaign> {
    return this.apiClient.get({
      path: `/admin/campaigns/${campaignId}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.getCampaign, { campaignId }),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async createCampaign(campaign: Campaign, silent = false): Promise<Campaign> {
    const payload: Campaign = fromPlainObject(campaign, Campaign) as Campaign;

    return this.apiClient.post({
      path: '/admin/campaigns/create',
      body: payload,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.createCampaign),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.createCampaign),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async updateCampaign(campaign: Campaign, silent = false): Promise<Campaign> {
    const payload: Campaign = fromPlainObject(campaign, Campaign) as Campaign;

    return this.apiClient.post({
      path: `/admin/campaigns/update/${campaign.id}`,
      body: payload,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.updateCampaign),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.updateCampaign),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async createJamCampaignRequest(request: JamCampaignRequest, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: '/admin/campaigns/request/new',
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.createJamCampaignRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.createJamCampaignRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async updateJamCampaignRequest(
    campaignId: string,
    request: JamCampaignRequest,
    silent = false
  ): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/request/update`,
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.updateJamCampaignRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.updateJamCampaignRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async cancelJamCampaignRequest(campaignId: string, comment: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/request/cancel`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.cancelJamCampaignRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.cancelJamCampaignRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async setJamCampaignRequestPending(campaignId: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/request/pending`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.setJamCampaignRequestPending),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.setJamCampaignRequestPending),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async approveJamCampaignRequest(
    campaignId: string,
    slug: string,
    comment: string,
    silent = false
  ): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/request/approve`,
      body: { slug, comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.approveJamCampaignRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.approveJamCampaignRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async denyJamCampaignRequest(campaignId: string, comment: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/request/deny`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.denyJamCampaignRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.denyJamCampaignRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  async createJamCampaignChangeRequest(
    campaignId: string,
    request: JamCampaignRequest,
    silent = false
  ): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/change-request/new`,
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.createJamCampaignChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.createJamCampaignChangeRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async updateJamCampaignChangeRequest(
    campaignId: string,
    request: JamCampaignRequest,
    silent = false
  ): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/change-request/update`,
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.updateJamCampaignChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.updateJamCampaignChangeRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  async cancelJamCampaignChangeRequest(campaignId: string, comment: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/change-request/cancel`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.cancelJamCampaignChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.cancelJamCampaignChangeRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async setJamCampaignChangeRequestPending(campaignId: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/change-request/pending`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.setJamCampaignChangeRequestPending),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.setJamCampaignChangeRequestPending),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  async approveJamCampaignChangeRequest(campaignId: string, comment: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/change-request/approve`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.approveJamCampaignChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.approveJamCampaignChangeRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async denyJamCampaignChangeRequest(campaignId: string, comment: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/change-request/deny`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.denyJamCampaignChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.denyJamCampaignChangeRequest),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async addComment(campaignId: string, commentValue: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/comments/create`,
      body: { value: commentValue },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.addComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.addComment),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async updateComment(
    campaignId: string,
    commentId: string,
    commentValue: string,
    silent = false
  ): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/comments/update`,
      body: { id: commentId, value: commentValue },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.updateComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.updateComment),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async deleteComment(campaignId: string, commentId: string, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/comments/delete`,
      body: { id: commentId },
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.deleteComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.deleteComment),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async deleteCampaign(campaign: Campaign, silent = false): Promise<void> {
    return this.apiClient.delete({
      path: `/admin/campaigns/${campaign.id}`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.deleteCampaign),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.deleteCampaign),
      silent,
    }) as Promise<void>;
  }

  async updateTags(campaign: Campaign, silent = false): Promise<Campaign> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaign.id}/tags`,
      body: {
        tags: campaign.tags || [],
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.updateTags),
      responseMapper: (res) => fromPlainObject(res, Campaign),
      silent,
    }) as Promise<Campaign>;
  }

  public async getInvitePreview(campaign: Campaign, silent = false): Promise<string> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaign.id}/preview-invite`,
      body: pick(campaign.campaignSettings, ['inviteEmailSubject', 'inviteEmailMessage']),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.getInvitePreview),
      responseMapper: (res) => res.inviteHtml as string,
      silent,
    }) as Promise<string>;
  }

  public async getCampaignGroups(campaignId: string, silent = false): Promise<CampaignGroup[]> {
    return this.apiClient.get({
      path: `/admin/campaigns/${campaignId}/groups`,
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.getCampaignGroups),
      responseMapper: asList((res) => fromPlainObject(res, CampaignGroup)),
      silent,
    }) as Promise<CampaignGroup[]>;
  }

  public async getCampaignGroup(campaignId: string, campaignGroupId: string, silent = false): Promise<CampaignGroup> {
    return this.apiClient.get({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.getCampaignGroup),
      responseMapper: (res) => fromPlainObject(res, CampaignGroup),
      silent,
    }) as Promise<CampaignGroup>;
  }

  async createCampaignGroup(campaignGroup: CampaignGroup, silent = false): Promise<CampaignGroup> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignGroup.campaignId}/groups`,
      body: omit(campaignGroup, [
        'id',
        'campaignId',
        'createdBy',
        'lastUpdatedBy',
        'createdDate',
        'lastUpdatedDate',
        'publicCode',
        'closedForNewRegistrations',
      ]),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.createCampaignGroup),
      responseMapper: (res) => fromPlainObject(res, CampaignGroup),
      silent,
    }) as Promise<CampaignGroup>;
  }

  public async updateCampaignGroup(campaignGroup: CampaignGroup, silent = false): Promise<CampaignGroup> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignGroup.campaignId}/groups/${campaignGroup.id}`,
      body: pick(campaignGroup, [
        'title',
        'minExpectedParticipants',
        'maxExpectedParticipants',
        'closedForNewRegistrations',
        'startDate',
        'endDate',
        'timezone',
      ]),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.updateCampaignGroup),
      responseMapper: (res) => fromPlainObject(res, CampaignGroup),
      silent,
    }) as Promise<CampaignGroup>;
  }

  public async deleteCampaignGroup(campaignId: string, campaignGroupId: string, silent = false): Promise<void> {
    return this.apiClient.delete({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.deleteCampaignGroup),
      silent,
    }) as Promise<void>;
  }

  public async getCampaignGroupParticipants(
    campaignId: string,
    campaignGroupId: string,
    silent = false
  ): Promise<CampaignParticipant[]> {
    return this.apiClient.get({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}/participants`,
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.getCampaignGroupParticipants),
      responseMapper: asList((res) => CampaignParticipant.fromCampaignParticipantResponse(res)),
      silent,
    }) as Promise<CampaignParticipant[]>;
  }

  public async getCampaignParticipantByLogin(
    campaignId: string,
    campaignGroupId: string,
    participantLogin: string,
    silent = false
  ): Promise<CampaignParticipant> {
    return this.apiClient.get({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}/participants/participant`,
      params: { participantLogin },
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.getCampaignParticipantByLogin),
      responseMapper: (res) => CampaignParticipant.fromCampaignParticipantResponse(res),
      silent,
    }) as Promise<CampaignParticipant>;
  }

  public async getCampaignParticipantByEmail(
    campaignId: string,
    campaignGroupId: string,
    email: string,
    silent = false
  ): Promise<CampaignParticipant> {
    return this.apiClient.get({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}/participants/participant`,
      params: { email },
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.getCampaignParticipantByEmail),
      responseMapper: (res) => CampaignParticipant.fromCampaignParticipantResponse(res),
      silent,
    }) as Promise<CampaignParticipant>;
  }

  public async createCampaignGroupParticipants(
    campaignId: string,
    campaignGroupId: string,
    emails: string[],
    silent = false
  ): Promise<CampaignParticipant> {
    return this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}/participants`,
      body: {
        campaignId,
        campaignGroupId,
        emails,
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.createCampaignGroupParticipants),
      responseMapper: asList((res) => CampaignParticipant.fromCampaignParticipantResponse(res)),
      silent,
    }) as Promise<CampaignParticipant>;
  }

  async deleteCampaignGroupParticipants(
    campaignId: string,
    campaignGroupId: string,
    emails: string[],
    silent = false
  ): Promise<void> {
    await this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}/participants/remove`,
      body: {
        campaignId,
        campaignGroupId,
        emails,
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.deleteCampaignGroupParticipants),
      silent,
    });
  }

  async sendParticipantInvite(
    campaignId: string,
    campaignGroupId: string,
    email: string,
    silent = false
  ): Promise<void> {
    await this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}/participants/invite`,
      body: { email },
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.sendParticipantInvite),
      silent,
    });
  }

  async sendInvitesToGroup(
    campaignId: string,
    campaignGroupId: string,
    onlyNew: boolean,
    silent = false
  ): Promise<void> {
    await this.apiClient.post({
      path: `/admin/campaigns/${campaignId}/groups/${campaignGroupId}/participants/invite/group`,
      params: {
        onlyNew: onlyNew === true,
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.sendInvitesToGroup),
      silent,
    });
  }

  /**
   * This method creates new a campaign attempt for the event. If none exist, the attemptNumber 
   * is set to one, otherwise the attemptNumber gets incremented by 1.
   * 
   * @param eventName 
   * @param silent 
   */
  public async startCampaignAttempt(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/teamchallenge/${eventName}/campaign/start`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.startCampaignAttempt),
      failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.startCampaignAttempt),
      silent,
    });
  }

  /**
   * This method gets event data using the events name.
   * 
   * @param eventName 
   * @param silent 
   * @returns 
   */
    public async getCampaignEventDetails(eventName: string,  silent = false): Promise<CampaignEventDetails> {
      return this.apiClient.get({
        path: `/event/${eventName}/details`,
        successMessage: this.t(i18nKeys.success.requestSucceeded.campaigns.loadCampaignEvent),
        failMessage: this.t(i18nKeys.errors.requestFailed.campaigns.loadCampaignEvent),
        silent,
      }) as Promise<CampaignEventDetails>;
    }

}
