import React from 'react';
import RevisionHistory from './challengeRevisionHistory/RevisionHistory';
import { Box } from '@amzn/awsui-components-react';

export const ChallengeRevisionsDetail: React.FC = () => {
  return (
    <Box padding={'m'}>
      <RevisionHistory />
    </Box>
  );
};

export default ChallengeRevisionsDetail;
