import { NotificationData, OfflineNotificationMessage } from './JamNotifications';
import { JamSupportChat } from './JamSupportChat';
import { JamSupportChatMessage } from './JamSupportChatMessage';
import { ChatMessage } from './Chat';
import { JamEvent } from './JamEvent';
import { JamEventIncident } from '../api/JamEventIncident';
import { ChallengeStatistics } from './JamCommon';
import { JamEventLogEntry } from './JamEventLogEntry';
import { JamTeamParticipantModel } from '../api/JamTeamParticipantModel';
import { CampaignAttemptResponse } from './Campaign';

export abstract class WebSocketsMessage<T> {
  messageType: string;
  messageBody: T | undefined;

  protected constructor(messageBody: T | undefined, messageType: string) {
    this.messageBody = messageBody;
    this.messageType = messageType;
  }

  isAssignableFrom(message: WebSocketsMessage<any>): message is WebSocketsMessage<T> {
    return message.messageType === this.messageType;
  }
}

export class NotificationWSMessage extends WebSocketsMessage<NotificationData> {
  constructor(messageBody?: NotificationData) {
    super(messageBody, 'NotificationWSMessage');
  }
}

export class ParticipantMessagesWSMessage extends WebSocketsMessage<OfflineNotificationMessage[]> {
  constructor(messageBody?: OfflineNotificationMessage[]) {
    super(messageBody, 'ParticipantMessagesWSMessage');
  }
}

export class EventWSMessage extends WebSocketsMessage<JamEvent> {
  constructor(messageBody?: JamEvent) {
    super(messageBody, 'EventWSMessage');
  }
}

export class EventLogWSMessage extends WebSocketsMessage<JamEventLogEntry> {
  constructor(messageBody?: JamEventLogEntry) {
    super(messageBody, 'EventLogWSMessage');
  }
}

export class ChallengeStatsWSMessage extends WebSocketsMessage<ChallengeStatistics> {
  constructor(messageBody?: ChallengeStatistics) {
    super(messageBody, 'ChallengeStatsWSMessage');
  }
}

export class TeamChatWSMessage extends WebSocketsMessage<ChatMessage[]> {
  constructor(messageBody?: ChatMessage[]) {
    super(messageBody, 'TeamChatWSMessage');
  }
}

export class SupportChatWSMessage extends WebSocketsMessage<JamSupportChat> {
  constructor(messageBody?: JamSupportChat) {
    super(messageBody, 'SupportChatWSMessage');
  }
}

export class SupportChatUpdateWSMessage extends WebSocketsMessage<JamSupportChatMessage> {
  constructor(messageBody?: JamSupportChatMessage) {
    super(messageBody, 'SupportChatUpdateWSMessage');
  }
}

export class EventIncidentWSMessage extends WebSocketsMessage<JamEventIncident> {
  constructor(messageBody?: JamEventIncident) {
    super(messageBody, 'EventIncidentWSMessage');
  }
}

export class TeamParticipantModelWSMessage extends WebSocketsMessage<JamTeamParticipantModel> {
  constructor(messageBody?: JamTeamParticipantModel) {
    super(messageBody, 'TeamParticipantModelWSMessage');
  }
}

export class CampaignAttemptWSMessage extends WebSocketsMessage<CampaignAttemptResponse> {
  constructor(messageBody?: CampaignAttemptResponse) {
    super(messageBody, 'CampaignAttemptWSMessage');
  }
}
