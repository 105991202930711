export interface IJamChallengeFeedback {
  id?: string;
  challengeRank: number;
  challengeDifficulty: number;
  notes: string;
  didYouLearnSomethingNew: boolean | string;
  updatedDate: string;
}

export interface IJamEventFeedback {
  id?: string;
  eventRank: number;
  speakerRank: number;
  notes: string;
  didYouLearnSomethingNew: boolean | string;
}

export class JamChallengeFeedback {
  id?: string;
  challengeRank: number;
  challengeDifficulty: number;
  notes: string;
  didYouLearnSomethingNew: boolean | string;
  updatedDate: string;

  static fromPlainObject(obj: any): JamChallengeFeedback {
    const data = Object.assign(new JamChallengeFeedback(), obj) as JamChallengeFeedback;
    return data;
  }
}

export class JamEventFeedback {
  id?: string;
  eventRank: number;
  speakerRank: number;
  notes: string;
  didYouLearnSomethingNew: boolean | string;

  static fromPlainObject(obj: any): JamEventFeedback {
    const data = Object.assign(new JamEventFeedback(), obj) as JamEventFeedback;
    return data;
  }
}

export interface JamFeedbackRes {
  eventFeedback: JamEventFeedback;
  challengeFeedback: JamChallengeFeedback;
}