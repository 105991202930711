import { mqtt5, iot, auth } from 'aws-iot-device-sdk-v2/dist/browser';
import { config as awsConfig } from 'aws-sdk/global';
import { Credentials, EnableMessagingResponse } from '../types/EnableMessagingResponse';
import { localLogger } from './log.utils';
import { AuthClient } from '../api/AuthClient';

export class MQTTService {
  private client: mqtt5.Mqtt5Client;
  private topics: string[];
  private authClient: AuthClient;
  private onReceiveMessage: mqtt5.MessageReceivedEventListener;
  private onCredentialsUpdated: () => Promise<EnableMessagingResponse | undefined>;

  constructor(config: EnableMessagingResponse) {
    this.topics = config.topicNames;
    this.initializeClient(config.endpoint, config.credentials);
  }

  private initializeClient(endpoint: string, credentials: Credentials) {
    const credentialsProvider = new auth.StaticCredentialProvider({
      aws_region: awsConfig.region || 'us-west-2',
      aws_access_id: credentials.awsaccessKeyId,
      aws_secret_key: credentials.awssecretKey,
      aws_sts_token: credentials.sessionToken,
    });

    const mqtt5ClientConfig: mqtt5.Mqtt5ClientConfig =
      iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(endpoint, {
        credentialsProvider,
      }).build();

    this.client = new mqtt5.Mqtt5Client(mqtt5ClientConfig);
  }

  private async updateClient(onCredentialsUpdated: () => Promise<EnableMessagingResponse | undefined>) {
    const response = await onCredentialsUpdated();
    if (response) {
      await this.destroy();
      this.initializeClient(response.endpoint, response.credentials);
      this.listen();
    }
  }

  public listen(methods?: {
    onReceiveMessage: mqtt5.MessageReceivedEventListener;
    onCredentialsUpdated: () => Promise<EnableMessagingResponse | undefined>;
  }) {
    if (methods) {
      this.onCredentialsUpdated = methods.onCredentialsUpdated;
      this.onReceiveMessage = methods.onReceiveMessage;
    }
    this.client.on('error', (error) => {
      localLogger('mqttClient error', error);
      void this.updateClient(this.onCredentialsUpdated);
    });

    this.client.on('connectionFailure', () => {
      localLogger('connectionFailure');
      void this.updateClient(this.onCredentialsUpdated);
    });

    this.client.on('disconnection', () => {
      localLogger('disconnected');
    });

    this.client.on('connectionSuccess', () => {
      localLogger('connected');
      if (this.client) {
        const subscriptions = this.topics.map((topic) => ({ topicFilter: topic, qos: mqtt5.QoS.AtLeastOnce }));
        this.client.subscribe({ subscriptions }).catch(localLogger);
      }
    });

    this.client.on('messageReceived', this.onReceiveMessage);
    this.client.start();
  }

  public async destroy() {
    try {
      await this.client.unsubscribe({ topicFilters: this.topics });
      this.client.stop();
    } catch (e) {
      localLogger(e);
    }
  }
}
