import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { Campaign } from '../../../types/Campaign';
import OwnerPermissions from '../../events/eventDetailsSections/Summary/OwnerPermissions';
import ReportRecipients from '../CampaignSections/ReportRecipients';

interface NewCampaignPermissionsAndReportingProps {
  target: Campaign | null;
}

const NewCampaignPermissionsAndReporting: React.FC<NewCampaignPermissionsAndReportingProps> = ({ target }) => {
  return (
    <React.Fragment>
      {target && (
        <SpaceBetween direction="vertical" size="s">
          <OwnerPermissions target={target} targetType="campaign" />
          <ReportRecipients campaign={target} />
        </SpaceBetween>
      )}
    </React.Fragment>
  );
};

export default NewCampaignPermissionsAndReporting;
