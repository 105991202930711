import _, { kebabCase, pick } from 'lodash';
import { ChallengeDescriptor, getChallengesDiff, WithChallengesAndScoring } from './Challenge';
import * as common from './common';
import { EventScoringSettings, CollaborationOptions, EventPermission } from './Event';
import { DiffChange, diffObjects, DiffResult } from './Diff';
import * as EventTimeUtils from '../utils/event-time.utils';
import moment from 'moment-timezone';
import { ChangeRequest, WithApprovalAndChangeRequest } from './Approvable';
import { JamConstants } from '../constants/shared/jam-constants';
import { SkillBuilderSubscription } from './SkillBuilderSubscription';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { ApprovableDiff } from './ResourceDeployment';
import { fromPlainObject } from '../utils/mapper.utils';
import { User } from './User';
import { getCampaignUrl } from '../utils/jam-urls';
import { isEmailListValid } from '../utils/string.utils';
import { i18nKeys } from '../utils/i18n.utils';
import { JamEventDetails } from './JamEventDetails';

export type CampaignType = 'EVALUATION' | 'TRAINING';

export const CampaignTypes = {
  EVALUATION: 'EVALUATION',
  TRAINING: 'TRAINING',
};

export const CampaignTypeDescriptions = {
  EVALUATION: 'Evaluating participants for specific skills.',
  TRAINING: 'Education and sharing of best practices.',
};

@jsonObject
export class CampaignSettings {
  static readonly DEFAULT_CLUES_ALLOWED: boolean = true;
  static readonly DEFAULT_SUPPORT_CHAT_ENABLED: boolean = false;
  static readonly DEFAULT_SESSION_DURATION_LIMIT_HOURS: number = 8;
  static readonly MIN_SESSION_DURATION_LIMIT_HOURS: number = 1;
  static readonly MAX_SESSION_DURATION_LIMIT_HOURS: number = 4380; // 6 months
  static readonly DEFAULT_LAB_TIMEOUT_HOURS: number = 4;
  static readonly MIN_LAB_TIMEOUT_HOURS: number = 2;
  static readonly MAX_LAB_TIMEOUT_HOURS: number = 72;
  static readonly DEFAULT_PASS_SCORE_PERCENT: number = 80;
  static readonly MIN_PASS_SCORE_PERCENT: number = 0;
  static readonly MAX_PASS_SCORE_PERCENT: number = 100;
  // NOTE: When changing default allowed attempts below also make sure to change in the backend
  // https://code.amazon.com/packages/AWSJam_GameBackend/blobs/7b03ed9ed0cd6f687989d2e2ce82410ee895f0e2/--/aws-jam-backend/src/main/java/com/amazonaws/awsjam/service/campaigns/data/CampaignSettings.java#L72
  static readonly DEFAULT_ALLOWED_ATTEMPTS: number = 2;
  static readonly MIN_ALLOWED_ATTEMPTS: number = 1;
  static readonly MAX_ALLOWED_ATTEMPTS: number = 10;
  static readonly DEFAULT_MAX_EXPECTED_PARTICIPANTS: number = 1;
  @jsonMember(common.NullableBooleanValue)
  cluesAllowed: common.NullableBoolean = null;
  @jsonMember(common.NullableNumberValue)
  sessionDurationLimitHours: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  labTimeoutHours: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  passScorePercent: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  allowedAttempts: common.NullableNumber = null;
  @jsonMember(String)
  challengeViewType: 'map' | 'list' = 'map';
  @jsonMember(String)
  uiTheme: 'DARK' | 'LIGHT' = 'DARK';
  @jsonMember(EventScoringSettings)
  scoringSettings: EventScoringSettings = new EventScoringSettings();
  @jsonMember(CollaborationOptions)
  collaborationOptions: CollaborationOptions = new CollaborationOptions();
  @jsonMember(common.NullableStringValue)
  chimeWebHookUrl: common.NullableString = null;
  @jsonMember(Number)
  maxExpectedParticipants: number = CampaignSettings.DEFAULT_MAX_EXPECTED_PARTICIPANTS;
  @jsonArrayMember(EventPermission)
  eventPermissions: EventPermission[] = [];
  @jsonArrayMember(String)
  reportRecipients: string[] = [];
  @jsonArrayMember(String)
  facilitatorDomainAllowlist: string[] = [];
  @jsonArrayMember(String)
  participantDomainAllowlist: string[] = [];
  @jsonArrayMember(String)
  regionAllowlist: string[] = [];
  @jsonArrayMember(String)
  regionDenylist: string[] = [];
  @jsonMember(common.NullableStringValue)
  inviteEmailSubject: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  inviteEmailMessage: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  agreementId: common.NullableString = null;
  @jsonArrayMember(String)
  validSkillBuilderSubscriptions: SkillBuilderSubscription[] = [];
  @jsonMember(Boolean)
  gamified = true;

  static diff(previousSettings: CampaignSettings, updatedSettings: CampaignSettings): DiffChange[] {
    previousSettings.withDefaults();
    updatedSettings.withDefaults();

    const propertiesToDiff: string[] = Object.keys(new CampaignSettings()).filter(
      (property) =>
        property !== 'scoringSettings' && property !== 'collaborationOptions' && property !== 'eventPermissions'
    );

    return [
      ...diffObjects(previousSettings, updatedSettings, propertiesToDiff, {
        maxExpectedParticipants: 'Participant Limit',
      }),
      ...EventScoringSettings.diff(previousSettings.scoringSettings, updatedSettings.scoringSettings),
      ...CollaborationOptions.diff(previousSettings.collaborationOptions, updatedSettings.collaborationOptions),
      ...EventPermission.diff(previousSettings.eventPermissions, updatedSettings.eventPermissions),
    ];
  }

  withDefaults(): CampaignSettings {
    if (this.cluesAllowed == null) {
      this.cluesAllowed = CampaignSettings.DEFAULT_CLUES_ALLOWED;
    }
    if (this.maxExpectedParticipants == null) {
      this.maxExpectedParticipants = CampaignSettings.DEFAULT_MAX_EXPECTED_PARTICIPANTS;
    }
    if (this.labTimeoutHours == null) {
      this.labTimeoutHours = CampaignSettings.DEFAULT_LAB_TIMEOUT_HOURS;
    }
    if (this.passScorePercent == null) {
      this.passScorePercent = CampaignSettings.DEFAULT_PASS_SCORE_PERCENT;
    }
    if (this.allowedAttempts == null) {
      this.allowedAttempts = CampaignSettings.DEFAULT_ALLOWED_ATTEMPTS;
    }
    if (this.collaborationOptions == null) {
      this.collaborationOptions = new CollaborationOptions();
    }
    if (this.scoringSettings == null) {
      this.scoringSettings = new EventScoringSettings();
    }
    return this;
  }
}

export interface EditableCampaignProps {
  title: common.NullableString;
  type: common.Nullable<CampaignType>;
  challengeDescriptors: ChallengeDescriptor[];
  campaignSettings: CampaignSettings;
}

@jsonObject
export class JamCampaignRequest implements EditableCampaignProps {
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  type: common.Nullable<CampaignType> = null;
  @jsonArrayMember(ChallengeDescriptor)
  challengeDescriptors: ChallengeDescriptor[] = [];
  @jsonMember(CampaignSettings)
  campaignSettings: CampaignSettings = new CampaignSettings();

  static fromCampaign(campaign: Campaign): JamCampaignRequest {
    const request: JamCampaignRequest = Object.assign(
      new JamCampaignRequest(),
      pick(campaign, Object.keys(new JamCampaignRequest()))
    );
    request.challengeDescriptors = (request.challengeDescriptors || []).map(
      (cd) => fromPlainObject(cd, ChallengeDescriptor) as ChallengeDescriptor
    );
    if (request.campaignSettings) {
      request.campaignSettings = fromPlainObject(request.campaignSettings, CampaignSettings) as CampaignSettings;
    }
    return request;
  }

  withDefaults(): JamCampaignRequest {
    return Campaign.applyDefaults(this);
  }

  diffFromCampaign(campaign: Campaign): DiffChange[] {
    campaign.withDefaults();
    this.withDefaults();

    const propertiesToDiff: string[] = Object.keys(new JamCampaignRequest()).filter(
      (property) => !['challengeDescriptors', 'campaignSettings'].includes(property)
    );

    return [
      ...diffObjects(campaign, this, propertiesToDiff),
      ...getChallengesDiff(campaign.challengeDescriptors, this.challengeDescriptors),
      ...CampaignSettings.diff(campaign.campaignSettings || new CampaignSettings(), this.campaignSettings),
    ];
  }
}

@jsonObject
export class CampaignChangeRequest extends JamCampaignRequest implements ChangeRequest {
  @jsonMember(String)
  status: common.ChangeRequestStatus = common.ChangeRequestStatus.CHANGE_REQUESTED as common.ChangeRequestStatus;
  @jsonMember(common.NullableStringValue)
  requestedBy: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  requestResolvedBy: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  createdDate: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  lastUpdatedDate: common.NullableNumber = null;

  get pending() {
    return (
      this.status === common.ChangeRequestStatus.CHANGE_REQUESTED ||
      this.status === common.ChangeRequestStatus.CHANGE_PENDING
    );
  }

  get resolved() {
    return (
      this.status === common.ChangeRequestStatus.CHANGE_APPROVED ||
      this.status === common.ChangeRequestStatus.CHANGE_DENIED ||
      this.status === common.ChangeRequestStatus.CHANGE_CANCELLED
    );
  }
}

@jsonObject
export class Campaign
  extends WithApprovalAndChangeRequest
  implements EditableCampaignProps, WithChallengesAndScoring, common.WithEventPermissions
{
  static readonly DEFAULT_CAMPAIGN_TYPE: CampaignType = CampaignTypes.EVALUATION as CampaignType;
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  requestedBy: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  requestResolvedBy: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  lastUpdatedBy: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  createdDate: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  lastUpdatedDate: common.NullableNumber = null;
  @jsonMember(common.NullableStringValue)
  slug: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  type: common.Nullable<CampaignType> = Campaign.DEFAULT_CAMPAIGN_TYPE;
  @jsonArrayMember(ChallengeDescriptor)
  challengeDescriptors: ChallengeDescriptor[] = [];
  @jsonMember(CampaignSettings)
  campaignSettings: CampaignSettings = new CampaignSettings();
  @jsonArrayMember(common.Comment)
  comments: common.Comment[] = [];
  @jsonArrayMember(String)
  tags: string[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(CampaignChangeRequest))
  changeRequest: common.Nullable<CampaignChangeRequest> = null;
  @jsonArrayMember(ApprovableDiff)
  changeHistory: ApprovableDiff[] = [];
  @jsonMember(Boolean)
  started = false;
  @jsonMember(common.NullableStringValue)
  usagePlanId: common.NullableString = null;

  // required for the challenge-selection component (WithChallengesAndScoring interface)
  @jsonMember(String)
  idAttribute = 'id';

  static applyDefaults<T extends EditableCampaignProps>(campaignLikeObj: T): T {
    if (!campaignLikeObj.campaignSettings) {
      campaignLikeObj.campaignSettings = new CampaignSettings();
    }
    campaignLikeObj.campaignSettings.withDefaults();
    return campaignLikeObj;
  }

  static diff(previousCampaign: Campaign, updatedCampaign: Campaign): DiffResult {
    const excludedProperties: string[] = ['lastUpdatedDate', 'comments'];

    return DiffResult.of(
      previousCampaign.withDefaults(),
      updatedCampaign.withDefaults(),
      Object.keys(new Campaign()).filter((property) => !excludedProperties.includes(property))
    );
  }

  static applyDiff(campaign: Campaign, diff: any): Campaign {
    return Object.assign(campaign, diff) as Campaign;
  }

  static forCreate() {
    return (fromPlainObject({}, Campaign) as Campaign).withDefaults();
  }

  // required for the challenge-selection component (WithChallengesAndScoring interface)
  getScoringSettings(): EventScoringSettings {
    return this.campaignSettings?.scoringSettings || new CampaignSettings().scoringSettings;
  }

  withDefaults(): Campaign {
    return Campaign.applyDefaults(this);
  }

  clone(): Campaign {
    return _.cloneDeep(this);
  }

  removeTag(tag: string) {
    this.tags = (this.tags || []).filter((t) => t !== tag);
  }

  addTag(tag: string) {
    this.removeTag(tag);
    this.tags = [...(this.tags || []), kebabCase(tag)];
  }

  isAdmin(user: User): boolean {
    return user && user.isCampaignAdmin;
  }

  isOwner(user: User): boolean {
    return user && this.hasOwner(user.email);
  }

  hasOwner(email: string): boolean {
    const hasOwner = this.campaignSettings?.eventPermissions?.some(
      (eventPermission) => eventPermission?.email.toLowerCase() === email.toLowerCase() && eventPermission.isOwner
    );
    return (email && hasOwner) || false;
  }

  isCampaignRequestor(user: User): boolean {
    return (
      (user && user.email && this.requestedBy && this.requestedBy.toLowerCase() === user.email.toLowerCase()) || false
    );
  }

  get uuid(): common.NullableString {
    return this.id;
  }

  get status(): string {
    if (this.approved) {
      if (this.changeRequest && this.changeRequest.pending) {
        return this.changeRequest.status;
      }
      return 'ACTIVE';
    }
    return this.approvalStatus as common.ApprovalStatus;
  }

  getPendingChanges(): DiffChange[] {
    if (!this.pendingChangeRequest) {
      return [];
    }
    return this.changeRequest?.diffFromCampaign(this) || [];
  }

  get validReportRecipientEmails(): boolean {
    return isEmailListValid(this.campaignSettings?.reportRecipients || new CampaignSettings().reportRecipients);
  }

  get numChallenges(): number {
    return (this.challengeDescriptors || []).length;
  }

  canEditAttribute(attribute: string, user: User): boolean {
    if (!user) {
      return false;
    }

    // check if the campaign is in a final state
    if (this.final) {
      return this.canEditFinalAttribute(attribute, user);
    }

    if (this.pendingChangeRequest && !user.isCampaignAdmin) {
      return false;
    }

    // all attributes are editable since the campaign is NOT final
    return true;
  }

  /**
   * We must assume that this method was called knowing in advance that the campaign is in a final state.
   *
   * @param attribute
   * @param user
   */
  private canEditFinalAttribute(attribute: string, user: User) {
    // the event is in a final state. so only limited attributes can be modified
    switch (attribute) {
      case 'title':
      case 'type':
        return user.isEventAdmin;
      case 'eventPermissions':
      case 'reportRecipients':
        // always allow editing the event permissions and reportRecipients, this allows sharing this page with other individuals
        return true;
      default:
        // all other attributes are readonly since the campaign is final
        return false;
    }
  }

  getOwners(): string[] {
    return this.campaignSettings?.eventPermissions?.filter((item) => item.isOwner).map((item) => item.email) || [];
  }

  getFacilitators(): string[] {
    return this.campaignSettings?.eventPermissions?.filter((item) => item.isOwner).map((item) => item.email) || [];
  }
}

export interface CampaignFilterOptions {
  dateRangeStart?: string;
  dateRangeEnd?: string;
}

@jsonObject
export class CampaignGroup {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  createdBy: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  lastUpdatedBy: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  createdDate: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  lastUpdatedDate: common.NullableNumber = null;
  @jsonMember(common.NullableStringValue)
  campaignId: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  startDate: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  endDate: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  timezone: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  publicCode: common.NullableString = null;
  @jsonMember(Boolean)
  closedForNewRegistrations = false;
  @jsonMember(common.NullableNumberValue)
  minExpectedParticipants: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  maxExpectedParticipants: common.NullableNumber = null;
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  testCloneEventName: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  testCloneEventCode: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  testCloneEventId: common.NullableString = null;

  get slug(): common.NullableString {
    return this.id;
  }

  get startTimeInEventLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInEventLocalTime(this.startDate, this.timezone, {
      includeDate: false,
      includeTime: true,
    });
  }

  get endTimeInEventLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInEventLocalTime(this.endDate, this.timezone, {
      includeDate: false,
      includeTime: true,
    });
  }

  get startTimeInBrowserLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInBrowserLocalTime(this.startDate, { includeDate: true, includeTime: false });
  }

  get endTimeInBrowserLocalTime(): common.NullableString {
    return EventTimeUtils.getTimeInBrowserLocalTime(this.endDate, { includeDate: true, includeTime: false });
  }

  get utcOffset(): common.NullableString {
    return EventTimeUtils.getUTCOffset(this.startDate);
  }

  get inSameTimezoneAsBrowser(): boolean {
    return EventTimeUtils.isInSameTimezoneAsBrowser(this.startDate || '');
  }

  get isBeforeStart(): boolean {
    return !this.isAfterStart;
  }

  get isAfterStart(): boolean {
    if (this.startDate) {
      return moment().isAfter(moment.parseZone(this.startDate));
    }
    return false;
  }

  get isBeforeEnd(): boolean {
    return !this.isAfterEnd;
  }

  get isAfterEnd(): boolean {
    if (this.endDate) {
      return moment().isAfter(moment.parseZone(this.endDate));
    }
    return false;
  }

  get currentStatus(): string {
    if (this.isBeforeStart) {
      return i18nKeys.events.eventDetails.labels.statuses.notStarted;
    } else if (this.isAfterEnd) {
      return i18nKeys.events.eventDetails.labels.statuses.ended;
    } else {
      return i18nKeys.general.inProgress;
    }
  }

  getInviteUrl(campaign: Campaign): string {
    return `${getCampaignUrl(campaign.slug, this.slug)}?${JamConstants.EVENT_CODE_QUERY_PARAM}=${this.publicCode}`;
  }
}

export type CampaignParticipantStatus =
  | 'UNREGISTERED'
  | 'REGISTERED'
  | 'IN_PROGRESS'
  | 'PASSED'
  | 'FAILED'
  | 'INCOMPLETE';

@jsonObject
export class CampaignParticipantInvite {
  @jsonMember(common.NullableStringValue)
  sentBy: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  timeSent: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  expiration: common.NullableNumber = null;
}

@jsonObject
export class CampaignParticipantStatusItem {
  @jsonMember(common.NullableStringValue)
  status: common.Nullable<CampaignParticipantStatus> = null;
  @jsonMember(common.NullableNumberValue)
  time: common.NullableNumber = null;
}

@jsonObject
export class CampaignAttempt {
  @jsonMember(common.NullableNumberValue)
  attemptNumber: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  timeCreated: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  lastUpdated: common.NullableNumber = null;
  @jsonMember(common.NullableStringValue)
  participantLogin: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  email: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  campaignId: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  campaignGroupId: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  score: common.NullableNumber = 0;

  /**
   * This is the event that the participant will access
   */
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  /**
   * Indicates whether the participant received a passing score.
   */
  @jsonMember(Boolean)
  passed = false;

  /**
   * The time the participant started their assessment.
   */
  @jsonMember(common.NullableNumberValue)
  startTime: common.NullableNumber = null;

  /**
   * The time the participant completed their assessment.
   */
  @jsonMember(common.NullableNumberValue)
  completionTime: common.NullableNumber = null;

  /**
   * The deadline that the attempt needs to be completed by
   */
  @jsonMember(common.NullableStringValue)
  deadline: common.NullableString = null;

  /**
   * The total number of permitted attempts for this campaign
   */
  @jsonMember(common.NullableNumberValue)
  allowedAttempts: common.NullableNumber = null;
}

export class CampaignAttemptResponse {
  allowedAttempts: number;
  attemptNumber: number;
  deadline: string;
  started: boolean;
  sessionDurationLimitHours: number;
  labTimeoutHours: number;
  completed: boolean;
  passed: boolean;
}

export class CampaignEventDetails extends JamEventDetails {
  campaignAttempt: CampaignAttemptResponse;
}

export class TeamParticipantPermissions {
  isFacilitator: boolean;
  isSupport: boolean;
}

@jsonObject
export class CampaignParticipant {
  @jsonMember(common.NullableStringValue)
  createdBy: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  timeCreated: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  lastUpdated: common.NullableNumber = null;
  @jsonMember(common.NullableClassValue(CampaignParticipantStatusItem) as typeof CampaignParticipantStatusItem)
  currentStatus: common.Nullable<CampaignParticipantStatusItem> = null;
  @jsonArrayMember(CampaignParticipantStatusItem)
  statusHistory: CampaignParticipantStatusItem[] = [];
  @jsonMember(Boolean)
  registered = false;
  @jsonMember(common.NullableStringValue)
  participantLogin: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  email: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  campaignId: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  campaignGroupId: common.NullableString = null;
  @jsonArrayMember(CampaignParticipantInvite)
  invites: CampaignParticipantInvite[] = [];
  @jsonMember(common.NullableClassValue(CampaignAttempt) as typeof CampaignAttempt)
  mostRecentAttempt: common.Nullable<CampaignAttempt> = null;

  static fromCampaignParticipantResponse(obj: any): CampaignParticipant {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    obj = obj || {};
    const participant: CampaignParticipant = fromPlainObject(
      obj.participant || {},
      CampaignParticipant
    ) as CampaignParticipant;
    participant.mostRecentAttempt = fromPlainObject(obj.campaignAttempt || {}, CampaignAttempt) as CampaignAttempt;
    return participant;
  }

  get numInvitesSent(): number {
    return (this.invites || []).length;
  }

  get mostRecentInviteTime(): common.NullableNumber {
    if (this.numInvitesSent < 1) {
      return null;
    }
    return (this.invites || []).reduce((highest, i) => Math.max(highest, i.timeSent || 0), 0);
  }
}
