import React from 'react';
import { ExpandableSection, Header, Link } from '@amzn/awsui-components-react';
import { Columns } from '@/src/components/common/Columns';
import { Column } from '@/src/components/common/Column';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

import './PreparationGuide.scss';

const PreparationGuide: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ExpandableSection
      defaultExpanded
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.preparationGuide)}</Header>}>
      <div className="preparation-guide-container">
        <p className="description">{t(i18nKeys.events.eventDetails.messages.preparationGuideDescription)}</p>
        <Columns columns={3} variant="default">
          <Column size="s">
            <h4>{t(i18nKeys.header.nav.support.forEventOwners.text)}</h4>
            <Link href={'/faq/host'} target="_blank" className="custom-link">
              {t(i18nKeys.faq.title)}
            </Link>
          </Column>
          <Column size="s">
            <h4>{t(i18nKeys.header.nav.support.forFacilitators.text)}</h4>
            <Link href={'/faq/facilitating'} target="_blank" className="custom-link">
              {t(i18nKeys.faq.title)}
            </Link>
          </Column>
          <Column size="s">
            <h4>{t(i18nKeys.header.nav.support.forLearners.text)}</h4>
            <Link href={'/faq/play'} target="_blank" className="custom-link">
              {t(i18nKeys.faq.title)}
            </Link>
          </Column>
        </Columns>
      </div>
    </ExpandableSection>
  );
};
export default PreparationGuide;
