import { SplitPanel } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSplitPanel } from '../../store/split-panel.context';
import { i18nKeys } from '../../utils/i18n.utils';

const SplitPanelInterface: React.FC = () => {
  const { splitPanelContent, showSplitPanel, splitPanelHeader } = useSplitPanel();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {showSplitPanel && (
        <SplitPanel
          i18nStrings={{
            closeButtonAriaLabel: t(i18nKeys.splitPanel.closeButtonAriaLabel),
            openButtonAriaLabel: t(i18nKeys.splitPanel.openButtonAriaLabel),
            preferencesCancel: t(i18nKeys.splitPanel.preferencesCancel),
            preferencesConfirm: t(i18nKeys.splitPanel.preferencesConfirm),
            preferencesPositionBottom: t(i18nKeys.splitPanel.preferencesPositionBottom),
            preferencesPositionDescription: t(i18nKeys.splitPanel.preferencesPositionDescription),
            preferencesPositionLabel: t(i18nKeys.splitPanel.preferencesPositionLabel),
            preferencesPositionSide: t(i18nKeys.splitPanel.preferencesPositionSide),
            preferencesTitle: t(i18nKeys.splitPanel.preferencesTitle),
            resizeHandleAriaLabel: t(i18nKeys.splitPanel.resizeHandleAriaLabel),
          }}
          header={splitPanelHeader || ''}>
          {splitPanelContent}
        </SplitPanel>
      )}
    </React.Fragment>
  );
};
export default SplitPanelInterface;
