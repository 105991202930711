/**
 * Perform an asynchronous action within a try-catch, ignore any thrown exception or rejected promise, and return
 * the optional fallback value.
 *
 * @param action the action to invoke
 * @param fallbackValue (optional) returned if the action throws an exception. if a fallback value is not provided then
 * undefined is returned
 * @return the results of the action, or the fallback value if an exception is thrown.
 */
export const callIgnoringExceptionsAsync = async <T>(
  action: () => Promise<T>,
  fallbackValue?: T
): Promise<T | undefined> => {
  try {
    return await action();
  } catch (err) {
    // ignore any thrown exception and return the fallback value
    return fallbackValue;
  }
};

/**
 * Perform a synchronous action within a try-catch, ignore any thrown exception, and return the optional fallback value.
 *
 * @param action the action to invoke
 * @param fallbackValue (optional) returned if the action throws an exception. if a fallback value is not provided then
 * undefined is returned
 * @return the results of the action, or the fallback value if an exception is thrown.
 */
export const callIgnoringExceptions = <T>(action: () => T, fallbackValue?: T): T | undefined => {
  try {
    return action();
  } catch (err) {
    // ignore any thrown exception and return the fallback value
    return fallbackValue;
  }
};
