import React, { useMemo, useState } from 'react'
import { CHALLENGE_SET_TITLE_MIN_LENGTH, CHALLENGE_SET_TITLE_MAX_LENGTH, CHALLENGE_SET_DESCRIPTION_MIN_LENGTH, CHALLENGE_SET_DESCRIPTION_MAX_LENGTH } from '@/src/store/challenge-set.context'
import { i18nKeys } from '@/src/utils/i18n.utils'
import { Container, Header, SpaceBetween, FormField, Input } from '@amzn/awsui-components-react'
import { useTranslation } from 'react-i18next'
import { ChallengeSetRequest } from '@/src/types/ChallengeSet'
import { ChallengeSetTitleDescriptionFields, challengeSetTitleDescriptionValidator } from '@/src/utils/challenge-set.validation.utils'

interface Props {
  challengeSet: ChallengeSetRequest
  validationHandler: (validateSection: () => Promise<boolean>) => void;
  onChange: (challengeSet: ChallengeSetRequest) => void 
}


export const ChallengeSetTitleDescription: React.FC<Props> = ({validationHandler, challengeSet, onChange}) => {
  const { t } = useTranslation();
  const [titleError, setTitleError] = useState('')
  const [descriptipnError, setDescriptionError] = useState('')

  const validator = useMemo(
    () =>
    challengeSetTitleDescriptionValidator(
        challengeSet.title ?? '',
        challengeSet.description ?? '',
        t(i18nKeys.challenges.subSections.overview.fields.title.constraint, {
          TITLE_MIN_LENGTH: CHALLENGE_SET_TITLE_MIN_LENGTH,
          TITLE_MAX_LENGTH: CHALLENGE_SET_TITLE_MAX_LENGTH,
        }),
        t(i18nKeys.challenges.subSections.overview.fields.description.constraint, {
          DESCRIPTION_MIN_LENGTH: CHALLENGE_SET_DESCRIPTION_MIN_LENGTH,
          DESCRIPTION_MAX_LENGTH: CHALLENGE_SET_DESCRIPTION_MAX_LENGTH,
        }),
        new Map<ChallengeSetTitleDescriptionFields, (error: string) => void>([
          [ChallengeSetTitleDescriptionFields.CHALLENGE_SET_TITLE, (error: string) => setTitleError(error)],
          [ChallengeSetTitleDescriptionFields.CHALLENGE_SET_DESCRIPTION, (error: string) => setDescriptionError(error)],
        ])
      ),
    [challengeSet.title, challengeSet.description, challengeSetTitleDescriptionValidator]
  );

  validationHandler(() => {
    const result = validator.isValidSection(true);
    if (result) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  });
  
  return <Container header={<Header variant="h2">{t(i18nKeys.challenges.subSections.overview.title)}</Header>}>
    <SpaceBetween direction="vertical" size="l">
      <FormField
        label={t(i18nKeys.challenges.fields.title.title) + '*'}
        errorText={titleError}
        >
        <Input
          value={challengeSet.title || ''}
          data-testid="challengeSetTitleInput"
          onChange={({ detail }) =>
            onChange({ ...challengeSet, title: detail.value})
          }
          // onBlur={() => vlaidator}
          placeholder={t(i18nKeys.challenges.challengeSet.placeholder.title)}
        />
      </FormField>
      <FormField
        errorText={descriptipnError}
        label={t(i18nKeys.challenges.fields.description.title) + '*'}
        >
        <Input
          value={challengeSet.description || ''}
          data-testid="challengeSetDescriptionInput"
          onChange={({ detail }) =>
            onChange({ ...challengeSet, description: detail.value})
          }
          placeholder={t(i18nKeys.challenges.challengeSet.placeholder.description)}
        />
      </FormField>
    </SpaceBetween>
  </Container>
}