import React, { useContext, useMemo, useState } from 'react';
import { useApi } from './api.context';
import { JamEventTeamGoal } from '../types/JamCommon';
import { JamTeamDetails, JamTeamItem, TeamBrief } from '../types/JamTeam';
import { localLogger } from '../utils/log.utils';
import { useJamEventDetails } from './jam-event-details.context';
import { useUser } from './user.context';
import { JamEventLogEntry } from '../types/JamEventLogEntry';

export interface JamMyTeamContextValue {
  changeTeamName: (teamName: string) => Promise<void>;
  changeTeamPassword: (password: string) => Promise<void>;
  removeTeamPassword: () => Promise<void>;
  changeTeamGoal: (teamGoal: JamEventTeamGoal) => Promise<void>;
  loadMyTeam: () => Promise<void>;
  loadAllTeams: () => Promise<void>;
  myTeam?: JamTeamDetails;
  eventTeams: JamTeamItem[];
  hasTeamOwnerPermissions: boolean;
  handleTeamRenamedWSMessage: (log: JamEventLogEntry) => void;
}

const JamMyTeamContext = React.createContext<JamMyTeamContextValue>({
  changeTeamName: () => new Promise(() => {
     // do nothing
  }),
  changeTeamPassword: () => new Promise(() => {
     // do nothing
  }),
  removeTeamPassword: () => new Promise(() => {
     // do nothing
  }),
  changeTeamGoal: () => new Promise(() => {
     // do nothing
  }),
  loadMyTeam: () => new Promise(() => {
     // do nothing
  }),
  loadAllTeams: () => new Promise(() => {
     // do nothing
  }),
  myTeam: undefined,
  eventTeams: [],
  hasTeamOwnerPermissions: false,
  handleTeamRenamedWSMessage: () => {
     // do nothing
  },
});

const JamMyTeamProvider: React.FC = ({ children }) => {
  const { eventName, event } = useJamEventDetails();
  const { jamTeamApi } = useApi();
  const { user } = useUser();

  const [myTeam, setMyTeam] = useState<JamTeamDetails>();
  const [eventTeams, setEventTeams] = useState<JamTeamItem[]>([]);

  const hasTeamOwnerPermissions = useMemo(() => {
    if (!event || !myTeam) {
      return false;
    }
    const teamExists = !!event.teamId;
    const isTeamAutoAssigned = !!(teamExists && myTeam.team.autoCreated);
    const eventEnded = !!event?.ended;
    const allowViewChallenges = !!event?.allowViewChallenges;
    const eventLocked = eventEnded && !allowViewChallenges;
    if (eventLocked) {
      return false;
    }
    const isTeamOwner = !!(teamExists && user && [user.username, user.legacyLogin].includes(myTeam.team.teamOwner));
    return isTeamOwner || isTeamAutoAssigned;
  }, [event, user, myTeam]);

  const teamId = event?.teamId || '';

  const refreshTeamInfo = (teamParams: Partial<JamTeamDetails>) => {
    if (!myTeam) {
      return;
    }
    setMyTeam((prev) => ({ ...(prev as JamTeamDetails), ...teamParams }));
  };

  const changeTeamName = async (teamName: string) => {
    try {
      await jamTeamApi.renameTeam(eventName, teamName);
      refreshTeamInfo({ team: { ...(myTeam?.team as TeamBrief), teamName, teamId: teamName } });
    } catch (e) {
      // ERROR Handled in API
    }
  };

  const changeTeamPassword = async (password: string) => {
    try {
      await jamTeamApi.changeTeamPassword(eventName, password);
      refreshTeamInfo({ team: { ...(myTeam?.team as TeamBrief), passwordRequired: !!password } });
    } catch (e) {
      // ERROR Handled in API
    }
  };

  const removeTeamPassword = async () => {
    if (!event?.teamId) {
      return;
    }
    try {
      await jamTeamApi.changeTeamPassword(eventName, '');
      refreshTeamInfo({ team: { ...(myTeam?.team as TeamBrief), passwordRequired: false } });
    } catch (e) {
      // ERROR Handled in API
    }
  };

  const changeTeamGoal = async (goal: JamEventTeamGoal) => {
    try {
      await jamTeamApi.changeTeamGoal(eventName, goal);
      refreshTeamInfo({ team: { ...(myTeam?.team as TeamBrief), goal } });
    } catch (e) {
      // ERROR Handled in API
    }
  };

  const loadMyTeam = async () => {
    try {
      const response = await jamTeamApi.getTeamDetails(eventName, teamId);
      setMyTeam(response);
    } catch (e) {
      // error handled in API
    }
  };

  const loadAllTeams = async () => {
    try {
      const response = await jamTeamApi.getEventTeams(eventName);
      localLogger({ response });
      setEventTeams(response);
    } catch (e) {
      // error handled in API
    }
  };

  const handleTeamRenamedWSMessage = (logEntry: JamEventLogEntry) => {
    if (!logEntry) {
      return;
    }
    if (['team-renamed'].includes(logEntry.actionType) && event?.teamId === logEntry.teamName) {
      void loadMyTeam();
    }
  };

  const data: JamMyTeamContextValue = {
    myTeam,
    eventTeams,
    hasTeamOwnerPermissions,
    changeTeamName,
    changeTeamPassword,
    removeTeamPassword,
    changeTeamGoal,
    loadMyTeam,
    loadAllTeams,
    handleTeamRenamedWSMessage,
  };
  return <JamMyTeamContext.Provider value={data}>{children}</JamMyTeamContext.Provider>;
};

const useJamMyTeam = () => {
  const context = useContext(JamMyTeamContext);
  if (context === undefined) {
    throw new Error('useJamMyTeamClient can only be used inside JamMyTeamProvider');
  }
  return context;
};

export { JamMyTeamProvider, useJamMyTeam };
