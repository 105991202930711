import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Cards, Header, Select } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useEventTemplate } from '@/src/store/event-template.context';
import SEO from "@/src/components/common/SeoComponent"
import { useUser } from '@/src/store/user.context';
import { IEventTemplateWithSubscribedStatus } from '@/src/types/EventTemplate';
import { CARD_DEFINITIONS, SORT_OPTIONS } from './catalog.config';
import './Catalog.scss';


const Catalog = () => {
  const { t } = useTranslation();
  const {
    loading,
    eventTemplates,
    totalCount,
    onSortChange,
    fetchPublicEventTemplates,
    pageOptions,
  } = useEventTemplate();
  const { user } = useUser();
  const [firstLoad, setFirstLoad] = useState(true);
  const sortOptions = SORT_OPTIONS.map((sortOption) => ({
    ...sortOption,
    label: t(sortOption.label),
  }));

  const [sortOptionIndex, setSortOptionIndex] = useState<number>(1); // translation to take effect store only index
  const selectedSortOption = sortOptions[sortOptionIndex];

  useEffect(() => {
    setTimeout(() => {
      setFirstLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (firstLoad) return;
    fetchPublicEventTemplates();
  }, [pageOptions.query, firstLoad]);

  useEffect(() => {
    onSortChange({
      sortingColumn: {
        id: '',
        header: '',
        isRowHeader: false,
        sortingField: sortOptions[sortOptionIndex].field,
      },
      isDescending: sortOptions[sortOptionIndex].isDescending,
    });
  }, [sortOptionIndex, onSortChange]);

  const eventTemplatesWithSubscribedStatus: IEventTemplateWithSubscribedStatus[] = useMemo(() => {
    return eventTemplates.map((item) => ({ ...item, isSubscribed: !!(user && (user.isSubscribedUser || user.isSuperAdmin))}))
  }, [eventTemplates, user])

  return (
    <div className="catalog-events">
      <SEO
        useStatic
        page="catalog"
        title={`${t(i18nKeys.catalog.title)} | List of the All Events | Sort by ${selectedSortOption.label}`}
      />
      <Cards
        stickyHeader
        cardDefinition={CARD_DEFINITIONS}
        loading={loading}
        loadingText={t(i18nKeys.catalog.loadingEvents)}
        items={eventTemplatesWithSubscribedStatus}
        variant="full-page"
        totalItemsCount={totalCount}
        empty={
          <Box textAlign="center">
            <b>{t(i18nKeys.catalog.catalogFilter.emptyResult.title)}</b>
            <Box padding={{ bottom: 's' }} variant="p">
              {t(i18nKeys.catalog.catalogFilter.emptyResult.caption)}
            </Box>
          </Box>
        }
        header={
          <div className="catalog-title">
            <Header variant="awsui-h1-sticky" counter={`(${totalCount})`}>
              {t(i18nKeys.catalog.title)}
            </Header>
          </div>
        }
        filter={
          <div className="catalog-events__sortby">
            <Select
              selectedOption={{
                ...selectedSortOption,
                label: `${t(i18nKeys.general.sortBy)}: ${selectedSortOption.label}`,
              }}
              options={sortOptions}
              onChange={({ detail }) => {
                const currentSortIndex = sortOptions.findIndex(
                  (sortOption) => sortOption.value === detail.selectedOption.value
                );
                setSortOptionIndex(currentSortIndex);
              }}
              selectedAriaLabel={t(i18nKeys.general.selected)}
            />
          </div>
        }
      />
    </div>
  );
};

export default Catalog;
