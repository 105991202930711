import React from 'react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

interface ISponsorProps {
  sponsor: {
    logo: string;
    name: string;
  };
}

const Sponsored: React.FC<ISponsorProps> = ({ sponsor }) => {
  const { t } = useTranslation();
  return (
    <Box className="sponsor-info">
      <Box variant="span" color="inherit" className="content">
        {t(i18nKeys.myJams.challenges.sponsoredBy)}:
      </Box>
      <Box margin={{ top: 'xxxs' }}>
        <img src={sponsor.logo} alt={sponsor.name} width={75} style={{ marginBottom: '-5px' }} />
      </Box>
    </Box>
  );
};

export default Sponsored;
