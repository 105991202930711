import React from 'react';
import { Box, Button, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './JamChallengeStatusModal.scss';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import JamChallengeQueueInfo from '../JamChallengeQueueInfo/JamChallengeQueueInfo';

const JamChallengeStatusModal: React.FC = () => {
  const { t } = useTranslation();
  const { showChallengeStatusProgress, showChallengeStatusSuccess, setEnableChallengeStatusModalFunc, queueInfo } =
    useJamChallengeDetails();

  return (
    <div>
      <Container
        variant="default"
        disableContentPaddings
        disableHeaderPaddings
        className={`jam-challenge-status-modal-container 
      ${showChallengeStatusProgress ? 'yellow-bg' : ''} 
      ${showChallengeStatusSuccess ? 'green-bg' : ''}`}>
        {showChallengeStatusSuccess && (
          <Box float="right" margin={{ top: 's', right: 'l' }} className="cross-bar">
            <Button
              iconName="close"
              variant="icon"
              className="text-dark"
              onClick={() => setEnableChallengeStatusModalFunc()}
            />
          </Box>
        )}
        <div className="lab-status-modal">
          <Box className="content-center">
            <div className="lab-status-modal-info">
              <SpaceBetween size="xs" direction="vertical">
                <Box variant="h3" className="text-dark">
                  {showChallengeStatusProgress &&
                    t(i18nKeys.myJams.challenges.details.challengeStatusModal.progress.title)}
                  {showChallengeStatusSuccess &&
                    t(i18nKeys.myJams.challenges.details.challengeStatusModal.success.title)}
                </Box>
                <Box variant="p" className="text-dark">
                  {showChallengeStatusProgress &&
                    t(i18nKeys.myJams.challenges.details.challengeStatusModal.progress.subtitle)}
                  {showChallengeStatusSuccess &&
                    t(i18nKeys.myJams.challenges.details.challengeStatusModal.success.subtitle)}
                </Box>
                {showChallengeStatusProgress && <Box className="Loading-progress" />}
              </SpaceBetween>
            </div>
            <div className="lab-status-modal-image">
              {showChallengeStatusProgress && <img src="/assets/WavingHandMonkey.png" alt="waving-hand-monkey" />}
              {showChallengeStatusSuccess && <img src="/assets/ThumsUpMonkey.png" alt="thumbs-up-monkey" />}
            </div>
          </Box>
        </div>
      </Container>
      {showChallengeStatusProgress && !!queueInfo?.queuePosition && <JamChallengeQueueInfo queueInfo={queueInfo} />}
    </div>
  );
};

export default JamChallengeStatusModal;
