import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useChallenges } from '../../../store/challenge.context';
import { useEditEvent } from '../../../store/edit-event.context';
import { useFlashbars } from '../../../store/flashbar.context';
import { ChallengeListItem } from '../../../types/Challenge';
import { Nullable, NullableString } from '../../../types/common';
import { TeamChallengeProperties } from '../../../types/Event';
import { ConfirmModal } from '../ConfirmModal';
import { Event } from '../../../types/Event';
import { KeyValue } from '../KeyValue';
import { Select, SpaceBetween } from '@amzn/awsui-components-react';
import _ from 'lodash';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import { IMPORT_TYPES } from '../CommonModel';

interface ChallengeDataImportModalProps {
  target: Event;
  challengeIds: string[];
  isVisible: boolean;
  toggleVisible: Dispatch<SetStateAction<boolean>>;
}

const ChallengeDataImportModal: React.FC<ChallengeDataImportModalProps> = ({
  target,
  challengeIds,
  isVisible,
  toggleVisible,
}) => {
  const { t } = useTranslation();
 
  const OPTION_VALUE_NONE = {
    label: `-- ${t(i18nKeys.general.none)} --`,
    value: undefined,
  };

  const ImportTypeOptions = [
    {
      label: t(i18nKeys.challenges.importChallenges.importTeamProperties.modal.labels.defaultFormat),
      value: IMPORT_TYPES.IMPORT_FORMAT_DEFAULT,
    },
    { label: IMPORT_TYPES.IMPORT_FORMAT_TREND_MICRO, value: IMPORT_TYPES.IMPORT_FORMAT_TREND_MICRO },
  ];

  const [challengesById, setChallengesById] = useState<{ [challengeId: string]: Nullable<ChallengeListItem> }>({});
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const { addErrorFlashbar } = useFlashbars();
  const [challengeId, setChallengeId] = useState<OptionDefinition>(OPTION_VALUE_NONE);
  const [data, setData] = useState<string[][]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [importFormat, setImportFormat] = useState<OptionDefinition>(ImportTypeOptions[0]);
  const [validationMessage, setValidationMessage] = useState('');
  const { uploadTeamProperties } = useEditEvent();
  const [challengeSelectOptions, setChallengeSelectOptions] = useState<OptionDefinition[]>();

  useEffect(() => {
    if (challengeIds && isVisible && Object.keys(challengesById).length === 0) {
      loadChallenges();
    }
  }, [isVisible]);

  useEffect(() => {
    generateChallengeSelectOptions();
  }, [challengesById]);

  const generateChallengeSelectOptions = () => {
    const newOptions = [];
    newOptions.push(OPTION_VALUE_NONE);
    Object.keys(challengesById).forEach((id: string) => {
      if (hasChallenge(id)) {
        newOptions.push({ label: `${challengesById[id]?.props?.title} (${id})` || '', value: id });
      }
    });
    setChallengeSelectOptions(newOptions);
  };

  const cleanUp = () => {
    setImportFormat(ImportTypeOptions[0]);
    setChallengeId(OPTION_VALUE_NONE);
    setData([]);
    setHeaders([]);
    setChallengesById({});
    setValidationMessage('');
    toggleVisible(false);
  };

  const validate = () => {
    if (challengeId.value && !challengesById[challengeId.value]) {
      setValidationMessage(t(i18nKeys.errors.validation.challengeNotFound));
    } else {
      setValidationMessage('');
    }
  };

  const changeListener = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file: Nullable<File> = files.item(0);

      const reader: FileReader = new FileReader();
      if (file) {
        reader.readAsText(file);

        reader.onload = () => {
          const csv: string | undefined = reader?.result?.toString();
          const lines = csv?.split(/\n/);
          if (lines) {
            setHeaders(splitCsv(lines[0]));
            setData([]);

            const rows = lines.slice(1);
            const newData: string[][] = [];
            rows.forEach((d) => {
              newData.push(splitCsv(d));
            });
            setData(newData);
          }
        };
      }
    }

    validate();
  };

  const loadChallenges = () => {
    if (challengeIds) {
      const newChallengesById: { [id: string]: Nullable<ChallengeListItem> } = {};
      challengeIds.forEach((id: string) => {
        newChallengesById[id] = getChallengeListItemFromChallengeId(id);
      });
      setChallengesById(newChallengesById);
    }
  };

  const hasChallenge = (id: string) => {
    return challengesById[id] != null;
  };

  const formatImportData = (): TeamChallengeProperties[] | undefined => {
    switch (importFormat.value) {
      case IMPORT_TYPES.IMPORT_FORMAT_DEFAULT:
        return formatDefaultImport();
      case IMPORT_TYPES.IMPORT_FORMAT_TREND_MICRO:
        return formatTrendMicroImport();
      default:
        return [];
    }
  };

  const formatDefaultImport = () => {
    try {
      return (
        // filter out rows with no truthy values
        data
          ?.filter((values: string[]) => values && values.some((value: string) => !!value))
          .map((values: string[]) => {
            if (values.length !== headers.length) {
              throw new Error('Data must have the same number of headers and value fields');
            }
            const teamChallengeProperties: TeamChallengeProperties = new TeamChallengeProperties();

            teamChallengeProperties.challengeId = challengeId.value || null;

            teamChallengeProperties.properties = values.map((value, index) => {
              return {
                key: headers[index],
                value,
              };
            });

            return teamChallengeProperties;
          })
      );
    } catch (err) {
      addErrorFlashbar(t(i18nKeys.errors.validation.error));
      return;
    }
  };

  const formatTrendMicroImport = () => {
    // headers from TrendMicro:
    // Table Name, Password, Task System, User, Password, DSM, Tenant, User, Password
    try {
      return data.map((values) => {
        const teamChallengeProperties: TeamChallengeProperties = new TeamChallengeProperties();

        teamChallengeProperties.challengeId = challengeId.value || null;
        teamChallengeProperties.teamName = values[0];

        teamChallengeProperties.properties = [
          { key: '1ds console', value: `<a href='${values[5]}' target='_blank'>click here</a>` },
          { key: '2ds tenant', value: values[6] },
          { key: '3ds username', value: values[7] },
          { key: '4ds password', value: values[8] },
          { key: '5pentest system url', value: `<a href='${values[2]}' target='_blank'>click here</a>` },
          { key: '6pentest system username', value: values[3] },
          { key: '7pentest system password', value: values[4] },
        ];

        return teamChallengeProperties;
      });
    } catch {
      addErrorFlashbar(t(i18nKeys.errors.validation.error));
      return;
    }
  };

  const importData = () => {
    const teamProperties: TeamChallengeProperties[] | undefined = formatImportData();

    if (teamProperties) {
      void uploadTeamProperties(target, teamProperties);
    }
  };

  const splitCsv = (row: string): string[] => {
    row = row || '';

    const values: string[] = [];

    let value = '';
    let openQuote = false;
    let prevChar: NullableString = null;

    row.split('').forEach((char, i) => {
      // detect closing quote
      if (char === '"' && openQuote) {
        openQuote = false;
        values.push(value);
        value = '';
      } else if (value.length < 1 && char === '"') {
        openQuote = true;
        value = '';
      } else if (!openQuote && char === ',') {
        if (prevChar !== '"') {
          values.push(value);
          value = '';
        }
      } else {
        prevChar = char;
        value += char;

        if (i === row.length - 1) {
          values.push(value);
        }
      }

      prevChar = char;
    });

    return values;
  };
  return (
    <ConfirmModal
      title={t(i18nKeys.challenges.importChallenges.importTeamProperties.modal.title)}
      onCancel={() => cleanUp()}
      visible={isVisible}
      confirmBtnLabel={t(i18nKeys.challenges.importChallenges.importTeamProperties.modal.buttons.yesImportFile)}
      onConfirm={() => {
        importData();
        cleanUp();
      }}
      disabled={
        !importFormat.value ||
        challengeId.value === OPTION_VALUE_NONE.value ||
        (challengeId.value && !challengesById[challengeId.value]) ||
        data.length < 1 ||
        !_.isEmpty(validationMessage)
      }
      message={
        <React.Fragment>
          <KeyValue label={t(i18nKeys.challenges.importChallenges.importTeamProperties.modal.labels.challengeId)}>
            <Select
              data-testid="import-modal__challenge-id"
              selectedOption={challengeId}
              options={challengeSelectOptions}
              onChange={({ detail }) => setChallengeId(detail.selectedOption)}
            />
          </KeyValue>
          <KeyValue label={t(i18nKeys.challenges.importChallenges.importTeamProperties.modal.labels.importFormat)}>
            <Select
              options={ImportTypeOptions}
              selectedOption={importFormat}
              onChange={({ detail }) => setImportFormat(detail.selectedOption)}
            />
          </KeyValue>
          {challengeId.value !== OPTION_VALUE_NONE.value && (
            <label className="primary-btn">
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={($event) => changeListener($event.target.files)}
              />
              {t(i18nKeys.challenges.importChallenges.importTeamProperties.modal.labels.importFromFile)}
            </label>
          )}
          <SpaceBetween direction="vertical" size="s">
            {data.length > 0 &&
              challengeId !== OPTION_VALUE_NONE &&
              !!challengeId.value &&
              challengesById[challengeId.value] && (
                <div>
                  {t(i18nKeys.challenges.importChallenges.importTeamProperties.modal.message, {
                    challengeTitle: challengesById[challengeId.value]?.props?.title,
                    format: importFormat.value,
                  })}
                </div>
              )}
            {!_.isEmpty(validationMessage) && <span className="warning">{validationMessage}</span>}
          </SpaceBetween>
        </React.Fragment>
      }
    />
  );
};

export default ChallengeDataImportModal;
