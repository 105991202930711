/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { i18nKeys } from '../utils/i18n.utils';
import { JamChallengeRecentFeedback } from '../types/JamChallengeRecentFeedback';
import { asList } from '../utils/list.utils';
import { JamChallengeDetails, JamProgress, JamTaskClue, SolveChallengeButtonKey } from '../types/JamChallengeDetails';
import { TEAM_CHALLENGE_API_PREFIX } from '../constants/api';
import { TaskValidationResponse } from '../types/JamChallenges';
import { TeamChallengePropertiesResponse } from '../types/JamFacilitator';
import { downloadAsPEM } from '../utils/download.utils';
import { TeamChallengeQueueInfo } from '../types/TeamChallengeQueueInfo';

export class JamChallengeDetailsAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * get jam challenge data
   *
   */
  public async fetchJamChallengeDetailsData(eventName: string, challengeId: string) {
    return (await this.apiClient.get({
      path: `/event/${eventName}/challenges/${challengeId}`,
      responseMapper: JamChallengeDetails.fromPlainObject,
      silent: true,
    })) as Promise<JamChallengeDetails>;
  }

  /**
   * get jam challenge task clue data
   *
   */
  public async fetchJamChallengeTaskClueData(
    eventName: string,
    challengeId: string,
    taskId: string,
    clueOrder: number
  ) {
    return (await this.apiClient.post({
      path: `${TEAM_CHALLENGE_API_PREFIX}/${eventName}/${challengeId}/clue`,
      responseMapper: JamTaskClue.fromPlainObject,
      failMessage: this.t(i18nKeys.myJams.challenges.errors.jamChallengeTaskClueNotFound),
      body: {
        taskId,
        clueOrder,
      },
    })) as Promise<JamTaskClue>;
  }

  /**
   * jam challenge start
   *
   */
  public async startJamChallenge(eventName: string, challengeId: string) {
    return (await this.apiClient.get({
      path: `${TEAM_CHALLENGE_API_PREFIX}/${eventName}/${challengeId}/start`,
      successMessage: this.t(i18nKeys.myJams.challenges.success.startJamChallenge),
      failMessage: this.t(i18nKeys.myJams.challenges.errors.notStartJamChallenge),
      silent: true,
    })) as Promise<void>;
  }

  /**
   * jam challenge restart
   *
   */
  public async restartJamChallenge(eventName: string, challengeId: string, reason: string) {
    return (await this.apiClient.post({
      path: `${TEAM_CHALLENGE_API_PREFIX}/${eventName}/${challengeId}/restart`,
      body: { reason },
      successMessage: this.t(i18nKeys.myJams.challenges.success.restartJamChallenge),
      failMessage: this.t(i18nKeys.myJams.challenges.errors.notreStartJamChallenge),
      silent: true,
    })) as Promise<void>;
  }

  /**
   * get jam challenge progress data
   *
   */
  public async fetchJamChallengeProgress(eventName: string, challengeId: string) {
    return (await this.apiClient.get({
      path: `${TEAM_CHALLENGE_API_PREFIX}/${eventName}/${challengeId}/progress`,
      responseMapper: JamProgress.fromPlainObject,
      failMessage: this.t(i18nKeys.myJams.challenges.errors.jamChallengeProgress),
    })) as Promise<JamProgress>;
  }

  /**
   * get jam challenge detail's recent feedback data
   *
   */
  public async fetchJamChallengeRecentFeedbackData(eventId: string, challengeId: string) {
    return (await this.apiClient.get({
      path: `/facilitator/${eventId}/recent-feedback/${challengeId}`,
      responseMapper: asList(JamChallengeRecentFeedback.fromPlainObject),
      failMessage: this.t(i18nKeys.myJams.challenges.errors.jamChallengesNotFound),
    })) as Promise<JamChallengeRecentFeedback>;
  }

  /**
   * validate jam challenge answer data
   *
   */
  public async validateTaskAnswer(
    eventName: string,
    challengeId: string,
    userInput: string,
    taskId: string | undefined
  ) {
    return (await this.apiClient.post({
      path: `${TEAM_CHALLENGE_API_PREFIX}/${eventName}/${challengeId}/task/validate`,
      responseMapper: JamProgress.fromPlainObject,
      failMessage: this.t(i18nKeys.myJams.challenges.errors.jamChallengeProgress),
      body: {
        taskId,
        userInput,
      },
    })) as Promise<TaskValidationResponse>;
  }

  /**
   * get jam challenge team properties
   *
   */
  public async getTeamProperties(eventName: string, challengeId: string): Promise<TeamChallengePropertiesResponse> {
    return this.apiClient.get({
      path: `/teamchallenge/${eventName}/${challengeId}/properties`,
      responseMapper: TeamChallengePropertiesResponse.fromPlainObject,
      silent: true,
    }) as Promise<TeamChallengePropertiesResponse>;
  }

  public async trackSolveButtonUsed(
    eventName: string,
    challengeId: string,
    solveButtonUsedKey: SolveChallengeButtonKey
  ) {
    return this.apiClient.post({
      path: `/teamchallenge/${eventName}/${challengeId}/track-solve-button-used/${solveButtonUsedKey}`,
      silent: true,
      // this is a silent background action, no success or fail message needed
    }) as Promise<void>;
  }

  public async getTeamKeyPair(eventName: string, challengeId: string): Promise<void> {
    await this.apiClient
      .get({
        path: `/teamchallenge/${eventName}/${challengeId}/keyPair`,
        failMessage: 'jam.ui.api.fail.download-ssh-key',
      })
      .then((obj: { name: string; key: string }) => {
        if (obj.key) {
          downloadAsPEM(obj.key, obj.name || 'keypair');
        }
      });
  }

  public async getChallengeQueueInfo(
    eventName: string,
    challengeId: string,
    silent = false
  ): Promise<TeamChallengeQueueInfo> {
    return this.apiClient.get({
      path: `/teamchallenge/${eventName}/${challengeId}/queue-info`,
      responseMapper: TeamChallengeQueueInfo.fromPlainObject,
      failMessage: this.t(i18nKeys.myJams.challenges.errors.challengeQueue),
      silent,
    }) as Promise<TeamChallengeQueueInfo>;
  }
}
