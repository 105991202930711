import { ColumnLayout, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { LoadingBar } from '../common/LoadingBar';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Challenge, ChallengeListItem } from '@/src/types/Challenge';
import { ChallengeSet } from '@/src/types/ChallengeSet';
import DifficultyIndicator from '../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';

interface ChallengeSetProps {
  challengeSet: ChallengeSet;
}

export const ChallengeSetDetail: React.FC<ChallengeSetProps> = ({ challengeSet }) => {
  const { challengeListItemMap } = useEventTemplateChallenges();

  const [filteredChallenges, setFilteredChallenges] = useState<ChallengeListItem[] | undefined>([]);

  useEffect(() => {
    filterChallenges();
  }, [challengeListItemMap, challengeSet]);

  const filterChallenges = () => {
    if (challengeSet.challengeIds.length > 0 && Object.keys(challengeListItemMap).length > 0) {
      const newFilteredChallenges = challengeSet.challengeIds
        .map((challengeId: string) => challengeListItemMap[challengeId])
        .filter((challengeListItem: ChallengeListItem) => challengeListItem);
      setFilteredChallenges(newFilteredChallenges);
    }
  };

  if (Object.keys(challengeListItemMap).length === 0) {
    return (
      <div style={{ marginTop: '32px' }}>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <h4>Description:</h4>
        <p>{challengeSet.description}</p>
        <h4>Included Challenges:</h4>
        {filteredChallenges &&
          filteredChallenges.map((challenge: ChallengeListItem, index: number) => (
            <ColumnLayout columns={1} key={challenge.id}>
              <div style={{ borderBottom: '1px solid #eaeded', paddingBottom: '1rem' }}>
                <ColumnLayout columns={2}>
                  <div>
                    <SpaceBetween direction="horizontal" size="xs">
                      <div>{index + 1}</div>
                      <div>
                        <SpaceBetween direction="vertical" size="xs">
                          <ReactRouterLink
                            to={CHALLENGE_DETAILS_ROUTES.Summary.resolve(challenge.challengeId ?? '')}
                            style={{ textDecoration: 'none', color: '#0972d3' }}>
                            <SpaceBetween direction="horizontal" size="xs">
                              {challenge.props.title}
                              <div style={{ marginLeft: '0.1rem' }}>
                                <Icon name="external" />
                              </div>
                            </SpaceBetween>
                          </ReactRouterLink>
                          <DifficultyIndicator challenge={challenge as Challenge} />
                        </SpaceBetween>
                      </div>
                    </SpaceBetween>
                  </div>
                </ColumnLayout>
              </div>
            </ColumnLayout>
          ))}
      </React.Fragment>
    );
  }
};

export default ChallengeSetDetail;
