import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from './common';
import { DiffChange } from './Diff';

export enum ResourceStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

@jsonObject
export class ApprovableDiff {
  @jsonMember(Boolean)
  approvalRequired = false;

  @jsonMember(Boolean)
  autoApproved = false;

  @jsonMember(Boolean)
  admin = false;

  @jsonMember(common.NullableTimeStampValue)
  changeRequestedDate: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  changeAppliedDate: common.NullableTimeStamp = null;

  @jsonMember(common.NullableDateStringValue)
  changeRequestedBy: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  changeApprovedBy: common.NullableDateString = null;

  @jsonArrayMember(DiffChange)
  changes: DiffChange[] = [];
}

@jsonObject
export class ResourceStatusItem {
  @jsonMember(common.NullableStringValue)
  status: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  details: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  time: common.NullableTimeStamp = null;
}

@jsonObject
export class ResourceDeploymentItem {
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  awsAccountNumber: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  type: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  batchId: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  timeCreated: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  lastUpdated: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  timeResolved: common.NullableTimeStamp = null;

  @jsonMember(common.NullableStringValue)
  resolutionStatus: common.NullableString = null;

  @jsonMember(ResourceStatusItem)
  status: ResourceStatusItem = new ResourceStatusItem();

  @jsonArrayMember(ResourceStatusItem)
  statusHistory: ResourceStatusItem[] = [];

  @jsonMember(Object)
  metadata: { [key: string]: string } = {};

  get resolved(): boolean {
    return this.status.status === ResourceStatus.COMPLETED || this.status.status === ResourceStatus.FAILED;
  }

  get completed(): boolean {
    return this.status.status === ResourceStatus.COMPLETED;
  }

  get failed(): boolean {
    return this.status.status === ResourceStatus.FAILED;
  }

  get errorMessage(): common.NullableString {
    if (this.status.details) {
      const search = /error=([^,]*),/.exec(this.status.details);
      if (search && search.length > 0) {
        return search[1];
      }
      return this.status.details;
    }
    return null;
  }
}
