import React from 'react';
import {
  Container,
  Button,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '@/src/RoutePath';
import { useHistory } from 'react-router-dom';
import './ClickableProfileOption.scss';

interface ClickableProfileOptionProps {
  title: string;
  description: string;
  link: RoutePath;
}

const ClickableProfileOption: React.FC<ClickableProfileOptionProps> = ({ title, description, link }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const redirectToLink = (route: RoutePath) => {
    history.push(route);
  };
 

  return (
    <div
      className="clickable-profile-option"
      onClick={() => {
        redirectToLink(link);
      }}>
      <Container
        data-testid="link-container"
        header={
          <Button data-colorid="container-header" variant="inline-link">
            {t(title)}
          </Button>
          // <Header className="custom-header" data-colorid="container-header" variant="h2">
          //   {t(title)}
          // </Header>
        }>
        {t(description)}
      </Container>
    </div>
  );
};

export default ClickableProfileOption;
