import React, { useMemo } from 'react';
import { FormField, Tiles, TilesProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { i18nKeys } from '@/src/utils/i18n.utils';

import { EventLearningTypeItem, EventLearningType } from '@/src/types/EventTemplate';

// Localization keys
const { eventLearningType } = i18nKeys.eventTemplates.step1;

interface EventLearningTypeTilesProps {
  stretch?: boolean;
  items: EventLearningTypeItem[];
  selectedValue?: EventLearningType;
  onOptionChange: TilesProps['onChange'];
}

export const EventLearningTypeTiles: React.FC<EventLearningTypeTilesProps> = ({
  stretch,
  items,
  selectedValue,
  onOptionChange,
}) => {
  const { t } = useTranslation();

  const learningTypeItems = useMemo(() => {
    return items.map(({ label, value, description }) => {
      return { label: t(label), value, description: t(description) };
    });
  }, [items, t]);

  return (
    <FormField label={t(eventLearningType.label)} stretch={stretch}>
      <Tiles
        ariaLabel={t(eventLearningType.ariaLabel)}
        items={learningTypeItems}
        value={selectedValue ?? null}
        onChange={onOptionChange}
      />
    </FormField>
  );
};
