import * as React from 'react';
import { SideNavigation } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '@/src/RoutePath';
import { useHistory } from 'react-router-dom';

const JamProfileSideNav: React.FC = () => {
  const { t } = useTranslation();
  const [activeHref, setActiveHref] = React.useState(RoutePath.JAM_PROFILE.valueOf());
  const history = useHistory();

  return (
    <div className="jam-profile-sidenav">
      <SideNavigation
        activeHref={activeHref}
        header={{ href: RoutePath.JAM_PROFILE, text: `${t(i18nKeys.JamProfile.title)}` }}
        onFollow={(e) => {
          if (!e.detail.external) {
            e.preventDefault();
            setActiveHref(e.detail.href);
            history.push(e.detail.href);
          }
        }}
        items={[
          {
            type: 'link',
            text: `${t(i18nKeys.JamProfile.jamProfileDetails.title)}`,
            href: RoutePath.JAM_PROFILE_DETAILS,
          },
          {
            type: 'link',
            text: `${t(i18nKeys.JamProfile.jamSkillProfile.title)}`,
            href: RoutePath.JAM_SKILL_PROFILE,
          },
          {
            type: 'link',
            text: `${t(i18nKeys.JamProfile.prizes.title)}`,
            href: RoutePath.JAM_PRIZES,
          },
          { type: 'link', text: `${t(i18nKeys.JamProfile.DataAndPrivacy.title)}`, href: RoutePath.DATA_AND_PRIVACY },
        ]}
      />
    </div>
  );
};

export default JamProfileSideNav;
