import React, { useCallback, memo } from 'react';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { Mode } from '@amzn/awsui-global-styles';

import './ThemeToggle.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

export interface IThemeToggleProps {
  /** current selected theme light or dark */
  mode: Mode;
  /** on theme change handler */
  onToggleTheme: (mode: Mode) => void;
}

export const ThemeToggle = memo<IThemeToggleProps>(({ mode, onToggleTheme }: IThemeToggleProps) => {
  const { t } = useTranslation();
  const handleThemeToggle = useCallback(
    (selectedMode: Mode) => {
      onToggleTheme(selectedMode);
    },
    [onToggleTheme]
  );

  return (
    <div className="theme-toggle">
      <SpaceBetween direction="horizontal" size="xs">
        <Button data-active={mode === Mode.Light} variant="link" onClick={handleThemeToggle.bind(this, Mode.Light)}>
          {t(i18nKeys.general.lightMode)}
        </Button>
        <Button data-active={mode === Mode.Dark} variant="link" onClick={handleThemeToggle.bind(this, Mode.Dark)}>
          {t(i18nKeys.general.darkMode)}
        </Button>
      </SpaceBetween>
    </div>
  );
});

ThemeToggle.displayName = 'ThemeToggle';
