import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from './common';
import { ChallengeListItem } from './Challenge';
import { STABILITY_HEALTH_CRITERIA } from '../constants/event-template.constants';



export interface ChallengeSnippetsTemplate{
  createdDate: number;
  description: string;
  id: string;
  lastUpdatedDate: number
  negativeFeedbackNum: number;
  owner:string;
  positiveFeedbackNum: number;
  tags?: string[];
  title: string;
  type: string;
}

export interface Snippets {
    createdBy: string;
    createdDate: number;
    description: string;
    id: string;
    lastUpdatedBy: string;
    lastUpdatedDate: number;
    negativeFeedbackNum: number;
    owner: string;
    positiveFeedbackNum: number;
    snippet: string;
    title: string;
    type: string;
  }

@jsonObject
export class ChallengeSetRequest {
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;
  @jsonArrayMember(String)
  challengeIds: string[] = [];
  @jsonMember(Boolean)
  featured = false;
}

@jsonObject
export class ChallengeSnippts{
  @jsonMember(Number)
  createdDate: number;
  @jsonMember(String)
  description: string;
  @jsonMember(String)
  id: string;
  @jsonMember(Number)
  lastUpdatedDate: number
  @jsonMember(Number)
  negativeFeedbackNum: number;
  @jsonMember(String)
  owner:string;
  @jsonMember(Number)
  positiveFeedbackNum: number;
  @jsonArrayMember(String || null)
  tags: string[] | null;
  @jsonMember(String)
  title: string;
  @jsonMember(String)
  type: string;
}

@jsonObject
export class ChallengeCFN {
  @jsonArrayMember(ChallengeSnippts)
  sharableSnippet: ChallengeSnippts[] = [];
  @jsonArrayMember(String || null)
  suggestedTags:  string[] | null = null;
  @jsonMember(String)
  voteStatus: string;
}

@jsonObject
export class ChallengeSet extends ChallengeSetRequest {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  owner: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  createDate: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  updatedDate: common.NullableNumber = null;
  isSelected?: common.NullableBoolean = null;

  hasChallenge(challengeId: string): boolean {
    return this.challengeIds.includes(challengeId);
  }

  get numChallenges() {
    return this.challengeIds.length;
  }

  topics(challengeListItemMap: { [id: string]: ChallengeListItem }) {
    const challenges = this.challengeIds
      .map((challengeId: string) => challengeListItemMap[challengeId])
      .filter((challengeListItem: ChallengeListItem) => challengeListItem && challengeListItem.props.jamType);
    const topics = challenges.flatMap((challenge: ChallengeListItem) => challenge.props.jamType);
    return Array.from(new Set(topics)).join(',');
  }

  healthStatus(challengeListItemMap: { [id: string]: ChallengeListItem }) {
    const challenges = this.challengeIds
      .map((challengeId: string) => challengeListItemMap[challengeId])
      .filter(
        (challengeListItem: ChallengeListItem) =>
          challengeListItem && challengeListItem.stability && challengeListItem.stability > STABILITY_HEALTH_CRITERIA
      );

    return challenges.length === this.challengeIds.length;
  }
}

@jsonObject
export class SimpleChallengeSetChallenge {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  challengeTitle: common.NullableString = null;
  @jsonMember(Boolean)
  isDefective = false;
  @jsonMember(Boolean)
  isPrivate = false;
}
