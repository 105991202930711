/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ChatMessage } from './Chat';
import { JamEventTeamGoal } from './JamCommon';

export class JamTeamChat {
  eventName: string;
  teamName: string;
  chatMessages: ChatMessage[] = [];

  static fromPlainObject(obj: any): JamTeamChat {
    const chat: JamTeamChat = Object.assign(new JamTeamChat(), obj) as JamTeamChat;
    chat.chatMessages = (chat.chatMessages || []).map(ChatMessage.fromPlainObject);
    return chat;
  }
}

export class TeamBrief {
  teamId: string;
  teamName: string;
  createdTimestamp: number;
  goal: JamEventTeamGoal;
  passwordRequired: boolean;
  canRename: boolean;
  teamOwner: string;
  autoCreated: boolean;
  capacity: number;
  facilitatorOnly: boolean;
  numSpotsFilled: number;

  static fromPlainObject(obj: any): TeamBrief {
    return Object.assign(new TeamBrief(), obj) as TeamBrief;
  }
}

export class TeamStatistics {
  score: number;
  maxScore: number;
  challengesCompleted: number;
  totalChallenges: number;
  cluesUsed: number;
  cluePenalties: number;
  startTimestamp: number;
  rank: number;

  static fromPlainObject(obj: any): TeamStatistics {
    return Object.assign(new TeamStatistics(), obj) as TeamStatistics;
  }
}

export class TeamParticipants {
  participantId: string;
  // hack
  login: string;
  nickname: string;
  excludeFromAutoAssignment: boolean;
  facilitator: boolean;
  skillRating: number;
  // hack
  skillRank?: number;
  teamName?: string;
  // hack
  teamId?: string;

  static fromPlainObject(obj: any): TeamParticipants {
    return Object.assign(new TeamParticipants(), obj) as TeamParticipants;
  }
}

export class JamTeamDetails {
  team: TeamBrief;
  statistics: TeamStatistics;
  participants: TeamParticipants[];

  static fromPlainObject(obj: any): JamTeamDetails {
    const teamDetails: JamTeamDetails = Object.assign(new JamTeamDetails(), obj) as JamTeamDetails;
    teamDetails.team = TeamBrief.fromPlainObject(obj.team);
    teamDetails.statistics = TeamStatistics.fromPlainObject(obj.statistics);
    teamDetails.participants = (obj.participants || []).map(TeamParticipants.fromPlainObject) as TeamParticipants[];
    return teamDetails;
  }
}

export class JamEventTeam {
  facilitatorOnly: boolean;
  goal: JamEventTeamGoal;
  passwordRequired: boolean;
  spotsLeft: number;
  teamId: string;
  teamName: string;

  static fromPlainObject(obj: any): JamEventTeam {
    return Object.assign(new JamEventTeam(), obj) as JamEventTeam;
  }
}

export class JamTeamItem {
  team: JamEventTeam;
  participants: TeamParticipants[];

  static fromPlainObject(obj: any): JamTeamItem {
    const teamItem: JamTeamItem = Object.assign(new JamTeamItem(), obj) as JamTeamItem;
    teamItem.team = JamEventTeam.fromPlainObject(obj.team);
    teamItem.participants = (obj.participants || []).map(TeamParticipants.fromPlainObject) as TeamParticipants[];
    return teamItem;
  }
}
