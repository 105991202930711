import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import './Reporting.scss';
import { EventTemplateReportResponse } from '@/src/types/EventTemplate';
import EventTemplateDetailOverview from '@/src/components/ui/molecules/EventTemplate/EventDetailReportingOverview';
import EventTemplateDetailReportingTable from '@/src/components/ui/molecules/EventTemplate/EventDetailsReportingTable';

interface IEventTemplateDetailReporting {
  eventTemplateReport: EventTemplateReportResponse | null;
}

const EventTemplateDetailReporting = ({ eventTemplateReport }: IEventTemplateDetailReporting) => {
  return (
    <SpaceBetween size="l">
      <EventTemplateDetailOverview eventTemplateReport={eventTemplateReport} />
      <EventTemplateDetailReportingTable eventTemplateReport={eventTemplateReport} />
    </SpaceBetween>
  );
};

export default EventTemplateDetailReporting;
