import React from 'react';
import { Box, Button, Icon, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

export interface IQuitConfirmModal {
  message: string;
  visible: boolean;
  unsaved: boolean;
  onOkClick: () => void;
  onDismiss: () => void;
}

const { okText, cancelText, title } = i18nKeys.eventTemplates.quitModal;

export const QuitConfirmModal: React.FC<IQuitConfirmModal> = ({ message, unsaved, visible, onOkClick, onDismiss }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onDismiss={onDismiss}
      closeAriaLabel={t(i18nKeys.general.close)}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <div id="cancel-button" data-testid="cancel-button" onClick={onDismiss}>
              <Button>{t(cancelText)}</Button>
            </div>
            <div id="leave-button" data-testid="leave-button" onClick={onOkClick}>
              <Button variant="primary">{t(okText)}</Button>
            </div>
          </SpaceBetween>
        </Box>
      }
      header={t(title)}>
      {unsaved && <Icon name="status-warning" variant={'warning'} />} {t(message)}
    </Modal>
  );
};
