import React from 'react';
import {switchToLegacyConsole} from "@/src/utils/hybrid-console";

const SwitchToLegacyComponent = () => {
    // Switch to legacy console until we have a page ready for polaris console
    // eslint-disable-next-line
    switchToLegacyConsole();
    return(<div />);
}
export default SwitchToLegacyComponent;
