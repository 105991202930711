export class KeyValuePair {
  key: string;
  value: string;
  type: string;
  label: string;
  group: string;

  static fromPlainObject(obj: any): KeyValuePair {
    return Object.assign(new KeyValuePair(), obj) as KeyValuePair;
  }
}
