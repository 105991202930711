import { MultiselectProps, PropertyFilterProps } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { ChallengeListItem, ChallengeProps } from './Challenge';

export enum DifficultyLevel {
  Fundamental,
  Intermediate,
  Advanced,
  Expert,
}

export enum EventLearningType {
  TEAM = 'TEAM',
  INDIVIDUAL = 'INDIVIDUAL',
}

export interface EventLearningTypeItem {
  label: string;
  value: EventLearningType;
  description: string;
}

export enum EventDurationType {
  SHORT = 'SHORT',
  MEDIUM = 'MEDIUM',
  LONG = 'LONG',
  SPL = 'SPL',
  CUSTOM = 'custom',
}

export type EventTemplateImage = {
  name: string;
  src: string;
};

export enum EventTemplateStatusType {
  DRAFT = 'DRAFT',
  PRIVATE = 'PRIVATE',
  PUBLISHED = 'PUBLISHED',
  DEFECTIVE = 'DEFECTIVE',
}

export type EventTemplateDetail = {
  summary: string;
  topic: readonly MultiselectProps.Option[];
  teamSize: number;
  minParticipants: number;
  maxParticipants: number;
};

export interface EventDurationItem {
  label: string;
  value: EventDurationType;
  data: EventDurationInfo;
}

export interface EventTopicItem {
  label: string;
  value: string;
  description?: string;
}

export type EventDurationInfo = {
  hours: number;
  minChallenges: number;
  maxChallenges: number;
  price: number;
  learningType: EventLearningType;
};

export interface IEventBasicInfo {
  title: string;
  durationConfig: {
    selection: string;
    duration: {
      value: string;
    } | null;
    pricePerLearner: string;
    offerId: string;
    challenges: number | null;
  };
}

export interface IEventTemplatePage {
  currentPageNumber: number;
  limit: number;
  pageCount: number;
  query: PropertyFilterProps.Query;
  sortingState: ISortingState;
}

export interface IGetEventTemplatesOptions {
  maxResults?: number;
  nextToken?: string;
  name?: string;
  statuses?: EventTemplateStatusType[];
  topics?: string[];
  tags?: string[];
  learningTypes?: EventLearningType[];
  durations?: EventDurationType[];
  awsServices?: string[];
  prices?: string[];
  deleted?: boolean;
}

export interface ISortingState {
  sortingColumn: {
    id: string;
    header: string;
    isRowHeader: boolean;
    sortingField: string;
  };
  isDescending: boolean;
}
export interface IEventConfig {
  eventDetails: {
    summary: string;
    topic: OptionDefinition[];
    teamSize: number;
  };
  image: File[];
}

export enum SelectionType {
  single = 'single',
  multi = 'multi',
}

export type EventTemplateChallenge = {
  id: string;
  rationale?: string;
  backup?: boolean;
};

export type EventTemplateRationale = { [challengeId: string]: string };
export type EventTemplateOptions = {
  name: string;
  learningType: EventLearningType;
  duration: EventDurationType;
  challenges?: EventTemplateChallenge[];
  summary?: string;
  topics?: string[];
  tags?: string[];
  recommendedTeamSize?: number;
  minParticipants?: number;
  maxParticipants?: number;
  rating?: number;
  status?: EventTemplateStatusType;
  awsServices: string[];
  challengeList?: ChallengeListItem[];
};

export type IEventTemplateChallenge = {
  id: string;
  rationale?: string;
  backup?: boolean;
  data?: ChallengeProps;
  isDefective?: boolean;
  isPublic?: boolean;
};

export type IEventTemplate = {
  id: string;
  name: string;
  learningType: EventLearningType;
  duration: EventDurationType;
  price: number;
  challenges: IEventTemplateChallenge[];
  summary: string;
  topics: string[];
  recommendedTeamSize: number;
  minParticipants: number;
  maxParticipants: number;
  rating: number;
  status: EventTemplateStatusType;
  createdDate: string;
  lastUpdatedDate: string;
  imagePresignedUrl?: string;
  tags: string[];
  awsServices?: string[];
  challengeList?: ChallengeListItem[];
  numberOfChallenges?: number;
};

export interface IEventTemplateWithSubscribedStatus extends IEventTemplate {
  isSubscribed: boolean;
}

export type EventTemplateReportResponse = {
  eventsUsed: number;
  learnersParticipated: number;
  revenueGenerated: number;
  labCostGenerated: number;
  data: EventTemplateReport[];
};

export type EventTemplateReport = {
  title: string;
  owner: string;
  date: Date;
  learners: number;
  totalRevenue: string;
};

export type EventTemplateRating = {
  averageRating: number;
  numberOfRatings: number;
  fiveStarProportion: number;
  fourStarProportion: number;
  threeStarProportion: number;
  twoStarProportion: number;
  oneStarProportion: number;
};

export type EventTemplateReview = {
  id: string;
  imgURL: string;
  userName: string;
  rating: number;
  date: string;
  review: string;
  liked: boolean;
};

export interface EventTemplateReviewResponse {
  totalData: number;
  pageNumber: number;
  itemsPerPage: number;
  data: EventTemplateReview[];
  allData?: EventTemplateReview[];
}

export interface EventTemplateListResponse {
  totalData: number;
  pageNumber: number;
  itemsPerPage: number;
  data: IEventTemplate[];
  allData?: IEventTemplate[];
}

export type EventTemplateCreateOptions = {
  name: string;
  learningType: EventLearningType;
  duration: EventDurationType;
};

// export type IPublicEventTemplate = {
//   date: string,
//   startDate: string,
//   endDate: string,
//   title: string,
//   description: string,
//   location: string,
//   moreInfoURL: string,
//   imageFilename: string
// }

// eslint-disable-next-line no-shadow
export enum EventTemplateNameDurationFields {
  EVENT_NAME = 'event-name',
  EVENT_DURATION = 'event-duration',
}

// eslint-disable-next-line no-shadow
export enum EventTemplateChallengeOrderFields {
  CHALLENGE_ORDER = 'challenge-order',
}

// eslint-disable-next-line no-shadow
export enum EventTemplateDetailFields {
  EVENT_SUMMARY = 'event-summary',
  EVENT_TOPIC = 'event-topic',
  EVENT_TEAM_SIZE = 'event-team-size',
  EVENT_MIN_PARTICIPANTS = 'event-min-participants',
  EVENT_MAX_PARTICIPANTS = 'event-max-participants',
  EVENT_TAGS = 'event-tags',
}

// eslint-disable-next-line no-shadow
export enum EventTemplateChallengeSelectFields {
  EVENT_CHALLENGE_SELECT = 'event-challenge-select',
}

// eslint-disable-next-line no-shadow
export enum EventCatalogTemplateDetailsFields {
  HEAD_COUNT = 'head-count',
  AGREEMENT_ID = 'agreement-id',
  START_DATE = 'start-date',
  START_TIME = 'start-time',
}

export interface InputValidationHelper {
  isValid: () => boolean;
  checkErrors: () => string;
}

export type EventTemplateValidationFields =
  | EventTemplateNameDurationFields
  | EventTemplateChallengeSelectFields
  | EventTemplateChallengeOrderFields
  | EventTemplateDetailFields
  | EventCatalogTemplateDetailsFields;

export interface GetEventTemplateOffersResponse {
  offers: IEventTemplateOffer[];
}

export interface IEventTemplateOffer {
  duration: EventDurationType;
  learningType: EventLearningType;
  hours: number;
  id: string;
  maxChallenges: number;
  minChallenges: number;
  price: number;
}

export type EventTemplateOfferMap = Map<EventDurationType, IEventTemplateOffer>;
