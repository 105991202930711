/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextContent,
  ButtonDropdown,
  ButtonDropdownProps,
  Link,
} from '@amzn/awsui-components-react';
import { Credentials } from 'aws-sdk';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';
import { useApi } from '../../../../store/api.context';
import { useChallenges } from '../../../../store/challenge.context';
import { useFlashbars } from '@/src//store/flashbar.context';
import { successFlashbar } from '@/src//utils/notification.utils';

import {
  Challenge,
  ChallengeReviewableSection,
  ChallengeUtils,
  TemplateScannerResponse,
} from '../../../../types/Challenge';
import { Nullable } from '../../../../types/common';
import { AccountCredentials } from '../../../../types/LabModels';
import { getEnvVar } from '../../../../utils/env-var.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { uploadFile } from '../../../../utils/s3-utils';
import { safeString } from '../../../../utils/string.utils';
import AwsAccountCLIModal from '../../../common/Challenges/AwsAccountCLIModal';
import { CopyToClipboard } from '../../../common/CopyToClipboard';
import { copyToClipboard } from '@/src/utils/copy-to-clipboard';

import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { useUser } from '../../../../store/user.context';
import './Resources.scss';

interface ChallengeResourcesDetailProps {
  challenge: Challenge;
}

const ChallengeResourcesDetail: React.FC<ChallengeResourcesDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();
  const { challengeResources, getChallengeResources } = useChallenges();
  const { user } = useUser();
  const { addFlashbar, addInfoFlashbar } = useFlashbars();

  const resourceBucket = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME');
  const resourcesBucketUrl = `https://${resourceBucket}.s3.amazonaws.com`;

  const [credentials, setCredentials] = useState<AccountCredentials | undefined>();
  const [templateScanResults, setTemplateScanResults] = useState('');
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const [visibleSTSCredentialsModal, setVisibleSTSCredentialsModal] = useState(false);

  useEffect(() => {
    if (ChallengeUtils.isCollaborator(challenge, user)) {
      const getSTSCreds = async () =>
        setCredentials(await challengesApi.getCredentialsToUploadChallengeResources(challenge.challengeId || ''));

      getSTSCreds().catch((err) => preProdLogger(err));
      void getChallengeResources(challenge.challengeId || '');
    }
  }, []);

  const getResourcesBucketChallengePath = (challengeId: string): string => {
    return `${resourcesBucketUrl}/${challengeId}`;
  };

  const getS3Uri = (challengeId: string): string => {
    return `s3://${resourceBucket}/${challengeId}`;
  };

  const getS3SyncCommand = () => {
    return `aws s3 sync . ${getS3Uri(challenge.challengeId || '')}`;
  };

  const deleteChallengeResource = async (key: string) => {
    await challengesApi.deleteChallengeResource(challenge.challengeId || '', key);
  };

  const validateS3Template = () => {
    challengesApi
      .validateS3Template(challenge.challengeId || '', true)
      .then((response: TemplateScannerResponse) => setTemplateScanResults(response.result))
      .catch((err) => preProdLogger(err));
  };

  const onReceiveFiles = (files: File[]) => {
    challengesApi
      .getCredentialsToUploadChallengeResources(challenge.challengeId || '')
      .then((accountCredentials) => {
        const creds: Nullable<Credentials> = accountCredentials.toAWSCredentials();
        if (creds) {
          Promise.all(
            Object.keys(files)?.map((file, i) => {
              return uploadFile(creds, resourceBucket, files[i], challenge.challengeId);
            })
          ).catch((err) => preProdLogger(err));
        }
        setTimeout(() => {
          // refresh the table after upload the element after 5 seconds
          void getChallengeResources(challenge.challengeId || '');
        }, 5000);
      })
      .catch((err) => preProdLogger(err));
  };

  const tableDataClick = async (e: ButtonDropdownProps.ItemClickDetails) => {
    const data = e.id.split('||');
    if (data[0] && data[1]) {
      if (data[0] === 'copy') {
        const copyData = `${getResourcesBucketChallengePath(challenge.challengeId || '')}/${data[1]}`;
        copyToClipboard(copyData);
        addFlashbar(successFlashbar(t(i18nKeys.clipboard.successCopiedToClipboard), 'Clipboard'));
      }
      if (data[0] === 'delete') {
        await deleteChallengeResource(data[1]);
        addInfoFlashbar(t(i18nKeys.challenges.subSections.assetsResources.assestDelete));
        // refresh the table after deleting the element
        void getChallengeResources(challenge.challengeId || '');
      }
    }
  };

  return (
    <div className="challenge-resources">
      <SpaceBetween direction="vertical" size="m">
        {challengeResources === undefined ||
          (challengeResources.length === 0 && (
            <Container
              header={
                <Header
                  variant="h2"
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        id="challenge-resources-refresh"
                        data-testid="challenge-resources-refresh"
                        onClick={() => void getChallengeResources(challenge.challengeId || '')}>
                        {t(i18nKeys.challenges.subSections.assetsResources.refresh)}
                      </Button>
                      <Button
                        id="challenge-resources-validate-template"
                        data-testid="challenge-resources-validate-template"
                        variant="primary"
                        onClick={validateS3Template}>
                        {t(i18nKeys.challenges.subSections.assetsResources.validateTemplates)}
                      </Button>
                      <ChallengeReviewPopover section={ChallengeReviewableSection.ASSETS_RESOURCES} />
                    </SpaceBetween>
                  }>
                  {t(i18nKeys.challenges.challengeDetails.headings.assetsResources)}
                </Header>
              }>
              <SpaceBetween direction="vertical" size="m">
                <Box padding={'l'} textAlign="center">
                  <p>{t(i18nKeys.challenges.subSections.assetsResources.noResourceMessage)}</p>
                  <Button
                    id="challenge-resources-assets-refresh"
                    data-testid="challenge-resources-assets-refresh"
                    onClick={() => void getChallengeResources(challenge.challengeId || '')}>
                    {t(i18nKeys.challenges.subSections.assetsResources.refresh)}
                  </Button>
                </Box>
              </SpaceBetween>
            </Container>
          ))}
        {challengeResources.length > 0 && (
          <Table
            className="resource-table"
            columnDefinitions={[
              {
                id: 'file',
                header: t(i18nKeys.challenges.challengeDetails.table.titles.fileName),
                cell: (e) => e.key,
              },
              {
                id: 'size',
                header: t(i18nKeys.challenges.challengeDetails.table.titles.size),
                cell: (e) => e.size,
                sortingField: 'size',
              },
              {
                id: 'lastModified',
                header: t(i18nKeys.challenges.challengeDetails.table.titles.lastModified),
                cell: (e) => (e.lastModified ? <TimeAgo datetime={safeString(e.lastModified.toString())} /> : '--'),
              },
              {
                id: 'actions',
                header: 'Actions',
                cell: (e) => (
                  <div>
                    {' '}
                    <ButtonDropdown
                      items={[
                        { id: `copy||${e.key}`, text: 'Copy to clipboard' },
                        {
                          id: `view||${e.key}`,
                          text: 'View CloudFormation Snippet',
                        },
                        {
                          id: `delete||${e.key}`,
                          text: 'Delete',
                        },
                      ]}
                      onItemClick={({ detail }) => void tableDataClick(detail)}
                      ariaLabel="Actions"
                      variant="inline-icon"
                    />
                  </div>
                ),
              },
            ]}
            items={challengeResources}
            loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingResources)}
            empty={
              <Box textAlign="center" color="inherit">
                <b>{t(i18nKeys.challenges.subSections.assetsResources.noResourceMessage)}</b>
                <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                  {t(i18nKeys.challenges.subSections.assetsResources.noResourceMessage)}
                </Box>
                <Button>{t(i18nKeys.challenges.subSections.assetsResources.refresh)}</Button>
              </Box>
            }
            header={
              <Header
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      id="challenge-resources-assets-resource"
                      data-testid="challenge-resources-assets-resource"
                      onClick={() => void getChallengeResources(challenge.challengeId || '')}>
                      {t(i18nKeys.challenges.subSections.assetsResources.refresh)}
                    </Button>
                    <Button
                      id="challenge-resources-validate-template-asset"
                      data-testid="challenge-resources-validate-template-asset"
                      variant="primary"
                      onClick={validateS3Template}>
                      {t(i18nKeys.challenges.subSections.assetsResources.validateTemplates)}
                    </Button>
                    <ChallengeReviewPopover section={ChallengeReviewableSection.ASSETS_RESOURCES} />
                  </SpaceBetween>
                }>
                {t(i18nKeys.challenges.challengeDetails.table.headers.resources)}
              </Header>
            }
            pagination={
              <Pagination
                currentPageIndex={1}
                pagesCount={1}
                ariaLabels={{
                  nextPageLabel: t(i18nKeys.tables.pagination.nextPage),
                  previousPageLabel: t(i18nKeys.tables.pagination.prevPage),
                  pageLabel: (pageNumber) => t(i18nKeys.tables.pagination.label, { count: pageNumber }),
                }}
              />
            }
          />
        )}
        {
          <Container
            header={
              <Header
                variant="h2"
                actions={<ChallengeReviewPopover section={ChallengeReviewableSection.ASSETS_RESOURCES} />}>
                Upload Files
              </Header>
            }>
            <SpaceBetween size="s">
              <div className="resources-dropzone">
                <Box padding={'l'} textAlign="center">
                  <Button
                    id="challenge-resources-upload-resources"
                    data-testid="challenge-resources-upload-resources"
                    iconName="upload"
                    variant="link"
                    formAction="none"
                    onClick={() => {
                      // @ts-ignore
                      hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                      // @ts-ignore
                      hiddenFileInput.current.click();
                    }}
                  />

                  <input
                    ref={hiddenFileInput}
                    id="chooseFileInput"
                    type="file"
                    hidden
                    multiple
                    accept="any"
                    // @ts-ignore
                    onChange={(event) => onReceiveFiles(event.target.files)}
                  />
                  <p>
                    {t(i18nKeys.challenges.subSections.assetsResources.dropFiles)}{' '}
                    <Link
                      onFollow={() => {
                        // @ts-ignore
                        hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                        // @ts-ignore
                        hiddenFileInput.current.click();
                      }}>
                      {t(i18nKeys.challenges.subSections.assetsResources.browseFiles)}
                    </Link>
                  </p>
                </Box>
              </div>
              <div>
                <Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.cliInstructions)}</Header>
                <TextContent>
                  <ol>
                    <li>{t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step1)}</li>
                    <li>
                      {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step2Copy)}
                      <Link
                        id="challenge-resources-show-credential-modal"
                        data-testid="challenge-resources-show-credential-modal"
                        onFollow={() => setVisibleSTSCredentialsModal(true)}>
                        {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step2Credentials)}
                      </Link>
                      {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step2Terminal)}
                    </li>
                    <li>
                      {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step3)}
                      <SpaceBetween direction="horizontal" size="s">
                        <span style={{ color: '#962249' }}>{getS3SyncCommand()} </span>
                        <CopyToClipboard
                          variant="inline-link"
                          value={getS3SyncCommand()}
                          buttonLabel="Copy to clipboard"
                          successMessage="Copied to clipboard"
                        />
                      </SpaceBetween>
                    </li>
                    <li>
                      {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step4)} <br />
                      <strong> {getResourcesBucketChallengePath(challenge.challengeId || '')}</strong>
                    </li>
                  </ol>
                </TextContent>
              </div>
            </SpaceBetween>
          </Container>
        }
        {templateScanResults.length > 0 && (
          <ExpandableSection
            variant="container"
            header={<Header>{t(i18nKeys.challenges.challengeDetails.headings.templateSecurityFindings)}</Header>}>
            <JamCodeEditor
              language="yaml"
              value={templateScanResults}
              setValue={() => {
                preProdLogger('none');
              }}
            />
          </ExpandableSection>
        )}
        {credentials && (
          <AwsAccountCLIModal
            visible={visibleSTSCredentialsModal}
            onCancel={() => setVisibleSTSCredentialsModal(false)}
            onConfirm={() => setVisibleSTSCredentialsModal(false)}
            credentials={credentials}
          />
        )}
      </SpaceBetween>
    </div>
  );
};

export default ChallengeResourcesDetail;
