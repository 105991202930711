import { Checkbox, Container, ExpandableSection, Grid, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';

interface CampaignCluesProps {
  campaign: Campaign | undefined;
}

const CampaignClues: React.FC<CampaignCluesProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { campaignEditMode, handleUpdateEditCampaign, newCampaignMode } = useEditCampaign();

  const renderContent = () => {
    return !campaignEditMode && !newCampaignMode ? (
      <div className="section-first-row">
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
          <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.enableClues)}</div>
          <div>{campaign?.campaignSettings.cluesAllowed ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
        </Grid>
      </div>
    ) : (
      (campaignEditMode || newCampaignMode) && (
        <Checkbox
          checked={campaign?.campaignSettings.cluesAllowed ? campaign.campaignSettings.cluesAllowed : false}
          onChange={({ detail }) => handleUpdateEditCampaign(EditCampaignActions.ENABLE_CLUES, detail.checked)}>
          {t(i18nKeys.campaigns.labels.campaignDetails.enableClues)}
        </Checkbox>
      )
    );
  };

  const headerElement = (
    <Header variant="h2" description={t(i18nKeys.campaigns.headers.campaignDetails.descriptions.clues)}>
      {t(i18nKeys.campaigns.headers.campaignDetails.clues)}
    </Header>
  );

  return (
    <React.Fragment>
      {!newCampaignMode && (
        <ExpandableSection variant="container" header={headerElement}>
          {renderContent()}
        </ExpandableSection>
      )}
      {newCampaignMode && <Container header={headerElement}>{renderContent()}</Container>}
    </React.Fragment>
  );
};

export default CampaignClues;
