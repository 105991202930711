import React from 'react';
import moment from 'moment';
import { Icon, Link, Popover } from '@amzn/awsui-components-react';
import { ChangeWithRevisionNumber } from './Revisions';
import { i18nKeys } from '../../../utils/i18n.utils';
import { TFunctionProvider } from '../TFunctionProvider';
import RevisionChangeColumn, { RevisionChangeColumnProps } from './RevisionChangeColumn';

export const COLUMN_DEFINITIONS = (selectRevision: (selectedRevision: ChangeWithRevisionNumber) => void) => {
  return [
    {
      id: 'revision-number',
      sortingField: 'revision-number',
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.revisions.publishedRevisions.headers.revisionNumber)}</TFunctionProvider>
      ),
      width: 130,
      cell: (item: ChangeWithRevisionNumber) => (
        <div onClick={() => selectRevision(item)}>
          <Link>{item?.revisionNumber}</Link>
        </div>
      ),
      allowLineWrap: true,
    },
    {
      id: 'changeAppliedDate',
      sortingField: 'changeAppliedDate',
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.revisions.publishedRevisions.headers.datePublished)}</TFunctionProvider>
      ),
      cell: (item: ChangeWithRevisionNumber) => (
        <div className="aws-positive-green">
          <Icon name="status-positive" /> {moment(item?.changeAppliedDate).format('lll')}
        </div>
      ),
      allowLineWrap: true,
    },
    {
      id: 'change-type',
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.revisions.publishedRevisions.headers.changeType)}</TFunctionProvider>
      ),
      minWidth: 580,
      cell: (item: ChangeWithRevisionNumber) => {
        const changesToDisplay: string[] = [];
        item.changes.forEach((change) => {
          const property = change?.property.includes('.') ? change.property.split('.')[0] : change.property;
          if (!changesToDisplay.includes(property)) {
            changesToDisplay.push(property);
          }
        });
        if (changesToDisplay.length < 6) {
          return <div>{changesToDisplay.join(', ')}</div>;
        } else {
          return (
            <Popover
              content={
                <div>
                  <h3>
                    <TFunctionProvider>
                      {(t) => t(i18nKeys.revisions.publishedRevisions.headers.sectionsChanged)}
                    </TFunctionProvider>
                  </h3>
                  {changesToDisplay.map((changedProperty, i) => {
                    return <div key={`changed-property-${i}`}>{changedProperty}</div>;
                  })}
                </div>
              }>
              <TFunctionProvider>
                {(t) =>
                  t(i18nKeys.revisions.publishedRevisions.sectionsChanged, {
                    sectionsChangedCount: changesToDisplay.length,
                  })
                }
              </TFunctionProvider>
            </Popover>
          );
        }
      },
      allowLineWrap: true,
    },
    {
      id: 'changeRequestedBy',
      sortingField: 'changeRequestedBy',
      minWidth: 600,
      header: <TFunctionProvider>{(t) => t(i18nKeys.revisions.publishedRevisions.headers.author)}</TFunctionProvider>,
      cell: (item: ChangeWithRevisionNumber) => <div>{item?.changeRequestedBy}</div>,
      allowLineWrap: true,
    },
  ];
};

export const NORMAL_USER_COLUMN_DEFINITIONS = (params: Omit<RevisionChangeColumnProps, 'item'>) => {
  return [
    {
      id: 'changeAppliedDate',
      sortingField: 'changeAppliedDate',
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.revisions.publishedRevisions.headers.revisionDate)}</TFunctionProvider>
      ),
      cell: (item: ChangeWithRevisionNumber) => <div>{moment(item?.changeAppliedDate).format('MM/DD/yyyy')}</div>,
    },
    {
      id: 'change-type',
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.revisions.publishedRevisions.headers.revisionType)}</TFunctionProvider>
      ),
      cell: (item: ChangeWithRevisionNumber) => {
        return (
          <RevisionChangeColumn
            {...{
              item,
              ...params,
            }}
          />
        );
      },
      allowLineWrap: true,
    },
    {
      id: 'changeRequestedBy',
      sortingField: 'changeRequestedBy',
      header: <TFunctionProvider>{(t) => t(i18nKeys.revisions.publishedRevisions.headers.author)}</TFunctionProvider>,
      cell: (item: ChangeWithRevisionNumber) => <div>{item?.changeRequestedBy}</div>,
      allowLineWrap: true,
    },
  ];
};
