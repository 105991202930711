import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Cards, ContentLayout, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  hasTeamPreCreated,
  isWaitingForAutoTeamAssignment,
  skillBasedAssignmentEnabled,
} from '@/src/utils/jam-event.utils';
import { useApi } from '@/src/store/api.context';
import { localLogger } from '@/src/utils/log.utils';
import { JamJoinTeamOptions } from '@/src/types/JamCommon';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { useFlashbars } from '@/src/store/flashbar.context';
import { getErrorMessage } from '@/src/utils/errors.utils';
import JoinExistingTeamForm from '../../ui/molecules/JoinTeam/JoinExistingTeamFrom/JoinExistingTeamFrom';
import ConsentForm from '../../ui/molecules/JoinTeam/ConsentForm/ConsentForm';
import AutoAssignment from '../../ui/molecules/JoinTeam/AutoAssignment/AutoAssignment';
import EventEndInfo from '../../ui/molecules/JoinTeam/EventEndInfo/EventEndInfo';
import './JamEventOnboarding.scss';
import EventStartInfo from '../../ui/molecules/JoinTeam/EventStartInfo/EventStartInfo';
import { JamConsent } from '@/src/types/JamConsent';
import { useUser } from '@/src/store/user.context';
import { ParticipantProfile } from '@/src/types/ParticipantProfile';
import { JamParticipantProfile } from '@/src/types/JamParticipantProfile';
import JamSpinner from '@/src/components/common/JamSpinner';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useJamMyTeam } from '@/src/store/jam-myteam.context';
import { JamTeamItem } from '@/src/types/JamTeam';
import JamSkillProfileFormModal from '../../ui/organisms/JamSkillProfileFormModal/JamSkillProfileFormModal';

const joinTeamOptions = [
  {
    id: JamJoinTeamOptions.matchTeam,
    name: i18nKeys.joinTeam.joinOptions.matchMeTeam,
    description: i18nKeys.joinTeam.joinOptions.matchMeTeamCaption,
  },
  {
    id: JamJoinTeamOptions.createTeam,
    name: i18nKeys.joinTeam.joinOptions.createTeam,
    description: i18nKeys.joinTeam.joinOptions.createTeamCaption,
  },
  {
    id: JamJoinTeamOptions.joinSpecificTeam,
    name: i18nKeys.joinTeam.joinOptions.joinSpecificTeam,
    description: i18nKeys.joinTeam.joinOptions.joinSpecificTeamCaption,
  },
];

const JamEventOnboardingWrapper:React.FC = ({children}) => {
  const { t } = useTranslation();
  return <ContentLayout disableOverlap header={<Header>{t(i18nKeys.joinTeam.title)}</Header>}>
    <div className="onboarding-container">
      {children}
    </div>
  </ContentLayout>
}

const JamEventOnboarding = () => {
  const { t } = useTranslation();
  const { event, eventName, eventSlug, loadEventDetails } = useJamEventDetails();
  const sponsorshipSettings = event?.sponsorshipSettings;
  const needConsent = event?.sponsorshipSettings?.sponsored;
  const { jamConsentApi, jamTeamApi, participantAPI } = useApi();
  const history = useHistory();
  const { user } = useUser();
  const { loadAllTeams, eventTeams } = useJamMyTeam();
  const { addErrorFlashbar } = useFlashbars();
  const [isConsentLoading, setIsConsentLoading] = useState(needConsent);
  const [hasConsented, setHasConsented] = useState(true);
  const [selectedJoinOption, setSelectedJoinOption] = useState([joinTeamOptions[0]]);
  const [isSaving, setIsSaving] = useState(false);
  const [consentData, setConsent] = useState<JamConsent>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [consentProfile, setConsentProfile] = useState<ParticipantProfile | any>();
  const eventStartsInMinutes = moment(event?.eventStartDate).diff(moment(), 'minutes');

  const { eventStarted, canFormTeams, isSkillBasedAssignmentEnabled, teamsArePreCreated } = useMemo(() => {
    if (!event) {
      return {
        eventStarted: false,
        canFormTeams: false,
        isSkillBasedAssignmentEnabled: false,
        teamsArePreCreated: false,
      };
    }
    const { teamAssignmentType, timeUntilCanFormTeams } = event;
    const startDate = new Date(event.eventStartDate);

    return {
      eventStarted: startDate.getTime() - moment.now() <= 0,
      canFormTeams: timeUntilCanFormTeams <= 0,
      isSkillBasedAssignmentEnabled: skillBasedAssignmentEnabled(teamAssignmentType),
      teamsArePreCreated: hasTeamPreCreated(teamAssignmentType),
    };
  }, [event]);

  const teams = useMemo(() => {
    return eventTeams.filter((team: JamTeamItem) => !team.team.facilitatorOnly);
  }, [eventTeams]);

  const getConsent = async () => {
    setIsConsentLoading(true);
    const consent = await jamConsentApi.getEventConsent(eventName);
    setConsent(consent);
    await getDefaultProfile();
    setIsConsentLoading(false);
    setHasConsented(!isEmpty(consent));
    localLogger({ consent, empty: isEmpty(consent) });
  };

  const onConsentAccepted = () => {
    setTimeout(() => {
      void loadEventDetails(eventName);
    }, 1000);
  };

  const onContinue = () => {
    if (!eventName) {
      return;
    }
    history.push(JAM_EVENT_DETAILS_ROUTES.JoinTeam.resolve([eventSlug, selectedJoinOption[0].id]));
  };

  const joinTeam = async (teamName: string, password: string | null = null) => {
    try {
      if (!event) {
        throw new Error(t(i18nKeys.joinTeam.noEventFound));
        return;
      }
      setIsSaving(true);
      localLogger({ teamName, password });
      await jamTeamApi.joinTeam(teamName, password, eventName);
      await loadEventDetails(eventName);
      history.replace(JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventSlug));
    } catch (e: any) {
      addErrorFlashbar(getErrorMessage(e));
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (needConsent) {
      void getConsent();
    }
  }, []);

  useEffect(() => {
    if (eventName) {
      void loadAllTeams();
    }
  }, [eventName]);

  if (!event) {
    return <Box>{t(i18nKeys.joinTeam.noEventFound)}</Box>;
  }

  const renderJoinOptions = () => {
    return (
      <SpaceBetween direction="vertical" size="l">
        <Box fontSize="heading-s" margin={{ top: 'l' }}>
          {t(i18nKeys.joinTeam.onboarding.selectJoinOption)}
        </Box>
        <Cards
          className="awsui_native-input_1wepg_12w0t_106"
          onSelectionChange={({ detail }) => setSelectedJoinOption(detail.selectedItems)}
          selectedItems={selectedJoinOption}
          ariaLabels={{
            itemSelectionLabel: (e, item) => t(i18nKeys.joinTeam.onboarding.selectOptionLabel, { option: item.name }),
            selectionGroupLabel: t(i18nKeys.joinTeam.onboarding.selectGroupLabel),
          }}
          cardDefinition={{
            header: (item) => <Box variant="h3">{t(item.name)}</Box>,
            sections: [
              {
                id: 'description',
                content: (item) => t(item.description),
              },
            ],
          }}
          cardsPerRow={[{ cards: 3 }]}
          items={joinTeamOptions}
          selectionType="single"
          trackBy="name"
        />
        <div className="divider" />
        <Box float="right">
          <div id="joinTeamContinueButton" data-testid="joinTeamContinueButton" onClick={onContinue}>
            <Button variant="primary">{t(i18nKeys.general.continue)}</Button>
          </div>
        </Box>
      </SpaceBetween>
    );
  };

  const getDefaultProfile = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await participantAPI.getParticipantProfile().then((res) => {
      if (res) {
        setConsentProfile(res);
      } else {
        setConsentProfile({ email: user?.email as string, nickname: user?.name as string });
      }
    });
  };

  if (!eventStarted && eventStartsInMinutes > event.formTeamsMinsBeforeEventStart) {
    return <JamEventOnboardingWrapper><EventStartInfo /></JamEventOnboardingWrapper>;
  }

  if (event?.ended) {
    return  <JamEventOnboardingWrapper><EventEndInfo /></JamEventOnboardingWrapper>;
  }

  if (isConsentLoading) {
    return <JamEventOnboardingWrapper><JamSpinner /></JamEventOnboardingWrapper>;
  }

  if (!hasConsented) {
    const consentProfileData = consentData?.profile
      ? consentData?.profile
      : (consentProfile as JamParticipantProfile);
    return (
      (<JamEventOnboardingWrapper>
      <ConsentForm
        visible={!hasConsented}
        eventName={eventName}
        sponsorshipSettings={sponsorshipSettings}
        consent={consentData}
        consentProfile={consentProfileData}
        onConsentAccepted={onConsentAccepted}
      />
      </JamEventOnboardingWrapper>)
    );
  }

  if (event.skillProfileUpdateRequired) {
    return (<JamEventOnboardingWrapper>
      <JamSkillProfileFormModal />
    </JamEventOnboardingWrapper>
    )
  }

  if (event.enrolledToEvent && isWaitingForAutoTeamAssignment(event)) {
    return <AutoAssignment />;
  }

  if (teamsArePreCreated) {
    return (
      <JamEventOnboardingWrapper>
        <JoinExistingTeamForm
          teams={teams}
          joinTeam={joinTeam}
          saving={isSaving}
          teamAssignmentType={event.teamAssignmentType}
        />
      </JamEventOnboardingWrapper>
    );
  }

  if (canFormTeams && !isSkillBasedAssignmentEnabled) {
    return <JamEventOnboardingWrapper>{renderJoinOptions()}</JamEventOnboardingWrapper> 
  }
  
  return null;
};

export default JamEventOnboarding;
