import React from 'react';
import { ChallengeListItem } from '@/src/types/Challenge';
import {
  Box,
  ColumnLayout,
  Container,
  ExpandableSection,
  Header,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { Nullable } from '@/src/types/common';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import DifficultyIndicator from '../../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';

interface IProps {
  challenge: ChallengeListItem;
  index: number;
  getChallengePointsPossible: (challenge: Nullable<ChallengeListItem>) => number;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
}

const AbstractChallengeCard: React.FC<IProps> = ({
  challenge,
  index,
  getChallengePointsPossible,
  toggleChallengeInfo,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Container
        header={
          <Header
            variant="h2"
            info={
              <span onClick={() => toggleChallengeInfo(challenge)}>
                <Link variant="info">{t(i18nKeys.general.info)}</Link>
              </span>
            }>
            {index + 1}. {challenge.props.title}
          </Header>
        }>
        <Box margin={{ bottom: 'l' }}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween direction="vertical" size="xxxs">
              <Box fontWeight="bold">{t(i18nKeys.challenges.challengeDetails.field.difficultyLevel.title)}</Box>
              <DifficultyIndicator challenge={challenge} />
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="xxxs">
              <Box fontWeight="bold">{t(i18nKeys.challenges.challengeDetails.field.points.title)}</Box>
              <Box>{getChallengePointsPossible(challenge)}</Box>
            </SpaceBetween>
            {challenge.props.jamType && (
              <SpaceBetween direction="vertical" size="xxxs">
                <Box fontWeight="bold">{t(i18nKeys.challenges.challengeDetails.field.type.title)}</Box>
                <Box>{challenge.props.jamType}</Box>
              </SpaceBetween>
            )}
            {challenge.props.category && (
              <SpaceBetween direction="vertical" size="xxxs">
                <Box fontWeight="bold">{t(i18nKeys.challenges.challengeDetails.field.category.title)}</Box>
                <Box>{challenge.props.category}</Box>
              </SpaceBetween>
            )}
            {!!challenge.props.awsServices.length && (
              <SpaceBetween direction="vertical" size="xxxs">
                <Box fontWeight="bold">{t(i18nKeys.challenges.challengeDetails.field.awsServices.title)}</Box>
                <Box>
                  {challenge.props.awsServices.map((awsService, servicesIndex) => {
                    if (servicesIndex + 1 !== challenge.props.awsServices.length) {
                      return <span key={`awsService-${servicesIndex}`}>{`${awsService}, `}</span>;
                    } else {
                      return <span key={`awsService-${servicesIndex}`}>{awsService}</span>;
                    }
                  })}
                </Box>
              </SpaceBetween>
            )}
          </ColumnLayout>
        </Box>
        {challenge.props.description && (
          <ExpandableSection expanded headerText={t(i18nKeys.challenges.challengeDetails.field.descripton.title)}>
            {challenge.props.description}
          </ExpandableSection>
        )}
      </Container>
    </div>
  );
};

export default AbstractChallengeCard;
