import React, { useState, useContext, ReactNode } from 'react';
import { NullableString } from '../types/common';

export interface SplitPanelContextValue {
  splitPanelContent: ReactNode | null;
  splitPanelHeader: NullableString;
  showSplitPanel: boolean;
  splitPanelOpen: boolean;
  toggleSplitPanel: (toggle?: boolean) => void;
  toggleHideAfterClose: (hide?: boolean) => void;
  toggleShowSplitPanel: (toggle: boolean) => void;
  renderSplitPanelContent: (header: string, content: ReactNode) => void;
}

const SplitPanelContext = React.createContext<SplitPanelContextValue>({
  splitPanelContent: null,
  splitPanelHeader: null,
  showSplitPanel: false,
  splitPanelOpen: false,
  toggleSplitPanel: (_toggle?: boolean) => {
     // do nothing
  },
  toggleHideAfterClose: (_hide?: boolean) => {
     // do nothing
  },
  toggleShowSplitPanel: (_toggle: boolean) => {
     // do nothing
  },
  renderSplitPanelContent: (_header: string, _content: ReactNode) => {
     // do nothing
  },
});

const SplitPanelProvider: React.FC = ({ children }) => {
  const [showSplitPanel, setShowSplitPanel] = useState(false);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelContent, setSplitPanelContent] = useState<ReactNode>(null);
  const [splitPanelHeader, setSplitPanelHeader] = useState('');
  const [hideAfterClose, setHideAfterClose] = useState(false);

  const toggleSplitPanel = (toggle?: boolean) => {
    if (hideAfterClose && splitPanelOpen) {
      setShowSplitPanel(false);
      setSplitPanelOpen(false);
    } else {
      setSplitPanelOpen(toggle || !splitPanelOpen);
    }
  };

  const toggleShowSplitPanel = (toggle: boolean) => {
    setShowSplitPanel(toggle);
  };

  const renderSplitPanelContent = (header: string, content: ReactNode) => {
    setSplitPanelHeader(header);
    setSplitPanelContent(content);
  };

  const toggleHideAfterClose = (hide?: boolean) => {
    setHideAfterClose(hide || !hideAfterClose);
  };

  const data: SplitPanelContextValue = {
    splitPanelContent,
    splitPanelHeader,
    toggleSplitPanel,
    toggleShowSplitPanel,
    toggleHideAfterClose,
    showSplitPanel,
    splitPanelOpen,
    renderSplitPanelContent,
  };

  return <SplitPanelContext.Provider value={data}>{children}</SplitPanelContext.Provider>;
};

const useSplitPanel = () => {
  const context = useContext(SplitPanelContext);
  if (context === undefined) {
    throw new Error('useSplitPanels can only be used inside SplitPanelProvider');
  }
  return context;
};

export { SplitPanelProvider, useSplitPanel };
