import { NullableString } from '../types/common';
import { EventBase } from '../types/Event';
import { config } from '../config/app-config';

/**
 * Returns a URL to the GameUI.
 */
export const getGameUIUrl = (): string => {
  return `https://${config.domains.gameUI}`;
};

/**
 * Returns the full url to a Jam event
 *
 * @param eventId EventID for given event
 * @returns full jam url
 */
export const getEventUrl = (eventId: string): string => {
  return `/${eventId}`;
};

/**
 * Returns the full Jam Campaign Url
 *
 * @param eventId EventId for given event
 * @param campaignGroupId CampaignId for given event
 * @returns Full url for Jam Campaign
 */
export const getCampaignUrl = (eventId: NullableString, campaignGroupId: NullableString): string => {
  if (!eventId || !campaignGroupId) {
    return '';
  }
  return `/${eventId}/${campaignGroupId}`;
};

/**
 * Constructs the full testJamUrl based on the provided event.
 *
 * This function checks if the event has a name and an optional testCloneSuffix.
 * If both are present, it combines them to form the URL; otherwise, it uses only the event name.
 *
 * @param event - The event object containing properties to construct the testJamUrl.
 * @returns The constructed testJamUrl as a string, or null if the event name is not defined.
 */
export const getTestEventUrl = (event: EventBase): NullableString => {
  if (event.name) {
    if(event.testCloneSuffix) {
      return getEventUrl(`${event?.name}-${event.testCloneSuffix}`);
    } else {
      return getEventUrl(`${event?.name}`);
    }
  }
  return null;
};

export const jamDocsS3Location = 'https://aws-jam-docs.s3-us-west-2.amazonaws.com';
export const awsJamIntro = 'https://broadcast.amazon.com/videos/153261';
export const eventOwnerGuide = 'https://broadcast.amazon.com/videos/153266';
export const jamIntroPptUrl = `${jamDocsS3Location}/jam-intro-presentation.pptx`;
export const jamFacilitatorGuideUrl = `${jamDocsS3Location}/facilitator-guide.pdf`;
export const facilitatorChecklistUrl = `${jamDocsS3Location}/facilitator-checklist.pdf`;
export const eventOwnerChecklistUrl = `${jamDocsS3Location}/event-owner-checklist.pdf`;
export const awsJamFlyerUrl = `${jamDocsS3Location}/aws-jam-flyer.pdf`;
export const challengeAuthoringGuideUrl = `${jamDocsS3Location}/aws-jam-challenge-authoring-guide.pdf`;
export const challengeWritingStyleGuideUrl = `${jamDocsS3Location}/aws-jam-writing-style-guide.pdf`;
export const supportContactsUrl = `${jamDocsS3Location}/aws-jam-support-contacts.pdf`;
export const gettingStartedGuideUrl = `${jamDocsS3Location}/aws-jam-getting-started.pdf`;
export const skillBuilderEventOwnerGuideUrl = `${jamDocsS3Location}/event-owner-guide-skill-builder.pdf`;
export const emailInviteTemplateUrl = `${jamDocsS3Location}/event-invite-email-template.pdf`;
export const skillBuilderSupportUrl = `${jamDocsS3Location}/aws-jam-support-skill-builder.pdf`;
export const userGuideUrl = `${jamDocsS3Location}/aws-jam-user-guide.pdf`;
export const jamContactEmail = 'aws-jam@amazon.com';
export const privacy = 'https://aws.amazon.com/privacy/';
export const siteTerms = 'https://aws.amazon.com/terms/';
export const wiki = 'https://w.amazon.com/bin/view/AWS/Teams/Proserve/Jam/';
