import { ChallengeLearningType } from './Challenge';

export enum JamTaskStatus {
  READY_TO_START = 'READY_TO_START',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export enum JamEventStatusForSidebar {
  LOCKED = 'LOCKED',
  SOLVED = 'SOLVED',
}

export enum SolveChallengeButtonKey {
  AWS_CLI = 'aws-cli',
  AWS_CONSOLE = 'aws-console',
  CODE_WHISPERER = 'amazon-Q',
}

export interface JamTaskProgress {
  taskId: string;
  taskNumber: number;
  locked: boolean;
  started: boolean;
  completed: boolean;
  pointsPossible: number;
  pointsEarned: number;
  score: number;
  cluePenalties: [];
  usedClues: [];
}
export class JamProgress {
  eventName: string;
  challengeId: string;
  pointsPossible: number;
  pointsEarned: number;
  taskProgress: JamTaskProgress[];
  score: number;
  started: boolean;
  completed: boolean;
  remainingRestarts: number;
  hasAwsResources: boolean;
  hasExternalResources: boolean;

  static fromPlainObject(obj: any): JamProgress {
    const data = Object.assign(new JamProgress(), obj) as JamProgress;
    return data;
  }
}

export class JamTaskClue {
  order: number;
  title: string;
  description?: string;
  cluePenalty: number;
  used: boolean;

  static fromPlainObject(obj: any): JamTaskClue {
    const data = Object.assign(new JamTaskClue(), obj) as JamTaskClue;
    return data;
  }
}

export interface JamCastleDefenseDetails {
  provider: string;
}

export interface JamLearningOutcome {
  summary: string;
  content: string;
  introduction: string;
  topicsCovered: string;
  technicalKnowledgePrerequisites: string;
  challengeDifficulty: string;
}

export interface JamTask {
  id: string;
  taskNumber: number;
  scorePercentage: number;
  title: string;
  background?: string;
  content: string;
  clues: JamTaskClue[];
  allowInputAnswer: boolean;
  validatedByLambda: boolean;
  pointsPossible: number;
  pointsEarned: number;
  score: number;
  status: JamTaskStatus;
  locked: boolean;
  cluesPenalty: number;
  inventory?: string;
}

export class JamChallengeDetails {
  title: string;
  category: string;
  description: string;
  learningType: ChallengeLearningType;
  difficulty: number;
  possiblePoints: number;
  pointsEarned: number;
  score: number;
  mode: string;
  challengeIcon: string;
  awsServices: string[];
  sshKeyPairRequired: boolean;
  castleDefenseDetails: JamCastleDefenseDetails;
  tasks: JamTask[];
  learningOutcome: JamLearningOutcome;
  status: JamTaskStatus;
  awsLabAccount: string;
  accountRegion: string;
  labExpirationTime: string;
  id: string;
  locked: boolean;

  static fromPlainObject(obj: any): JamChallengeDetails {
    const data = Object.assign(new JamChallengeDetails(), obj) as JamChallengeDetails;
    return data;
  }
}
