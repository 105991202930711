import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  ButtonDropdown,
  ButtonDropdownProps,
  ColumnLayout,
  Container,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { TeamChallengeProgress, TeamProgressResponse } from '@/src/types/JamTeamChallengeAnswer';
import moment from 'moment';
import ShowTasks from './ShowTasks';
import { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { useApi } from '@/src/store/api.context';
import { downloadAsPEM } from '@/src/utils/download.utils';
import { ConfirmModal } from '@/src/components/common/ConfirmModal';
import { preProdLogger } from '@/src/utils/log.utils';
import AwsCliModal from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamOverview/Modals/AwsCLI/AwsCLIModal';
import { AccountCredentials } from '@/src/types/LabModels';
import { useJamFacilitator } from '@/src/store/jam-facilitator.context';
import { sortBy } from 'lodash';

interface TeamChallengeProgressAnswerProps {
  challenge: TeamChallengeProgress;
}

enum TEAM_CHALLENGE_DROPDOWN_ITEMS {
  RESTART_CHALLENGE = 'restart-challenge',
  MARK_TASK_AS_COMPLETED = 'mark-task-as-completed',
  DOWNLOAD_SSH_KEY_PAIR = 'download-ssh-keypair',
  LOGIN_AS_TEAM_ROLE = 'login-as-team-role',
  LOGIN_AS_ADMIN_ROLE = 'login-as-admin-role',
  TEAM_ROLE_CLI_ACCESS = 'team-role-cli-access',
  ADMIN_ROLE_CLI_ACCESS = 'admin-role-cli-access',
  REFUND_TASK_CLUE = 'refund-task-clue',
}

enum TEAM_CHALLENGE_CONFIRM_MODAL_TYPE {
  RESTART_CHALLENGE_MODAL = 'restart-challenge-modal',
  MARK_TASK_COMPLETED = 'mark-task-completed',
  REFUND_TASK_CLUE = 'refund-task-clue',
}

const TeamChallengeProgressAnswer: React.FC<TeamChallengeProgressAnswerProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { loadTeamProgressData } = useJamFacilitator();
  const { jamFacilitatorApi } = useApi();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAWSCLIModal, setShowAWSCLIModal] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState<TEAM_CHALLENGE_CONFIRM_MODAL_TYPE>();
  const [taskId, setTaskId] = useState<string>();
  const [clueOrder, setClueOrder] = useState<number>();
  const [credentials, setCredentials] = useState<AccountCredentials>();

  const startTime = moment(challenge.lastUpdatedTime).format('h:mm A');
  const totalTasks = challenge.taskProgress?.length;
  const completedTasks = challenge.taskProgress.filter(
    ({ pointsEarned, pointsPossible }) => pointsEarned === pointsPossible
  )?.length;
  const completedPercentage: number = (completedTasks / totalTasks) * 100;

  const teamChallengeProgressDropdownItems = useMemo<ButtonDropdownProps.ItemOrGroup[]>(() => {
    const {
      restartChallenge,
      markTaskCompleted,
      downloadSSHKeyPair,
      loginTeamRole,
      loginAdminRole,
      teamRoleCLI,
      adminRoleCLI,
      refundCluePenalty,
    } = i18nKeys.facilitator.participants.buttons;
    const items: ButtonDropdownProps.ItemOrGroup[] = [
      { text: t(restartChallenge) ?? '', id: TEAM_CHALLENGE_DROPDOWN_ITEMS.RESTART_CHALLENGE, iconName: 'redo' },
    ];
    const tasks = sortBy(challenge.taskProgress || [], [({ taskNumber }) => taskNumber]);
    for (const task of tasks) {
      if (task.completedTime) continue;

      items.push({
        text: t(markTaskCompleted, { taskNumber: task.taskNumber }) ?? '',
        id: `${TEAM_CHALLENGE_DROPDOWN_ITEMS.MARK_TASK_AS_COMPLETED}#${task.taskId}`,
        iconName: 'thumbs-up-filled',
      });
      if (!task.cluePenalties) continue;
      for (const cluePenalty of task.cluePenalties) {
        if (cluePenalty.refunded) continue;
        items.push({
          text:
            t(refundCluePenalty, {
              taskNumber: task.taskNumber,
              clueOrder: cluePenalty.order,
              penalty: cluePenalty.penalty,
            }) ?? '',
          id: `${TEAM_CHALLENGE_DROPDOWN_ITEMS.REFUND_TASK_CLUE}#${task.taskId}#${cluePenalty.order}`,
          iconName: 'ticket',
        });
      }
    }
    items.push({
      text: t(downloadSSHKeyPair) ?? '',
      id: TEAM_CHALLENGE_DROPDOWN_ITEMS.DOWNLOAD_SSH_KEY_PAIR,
      iconName: 'download',
    });

    if (challenge.teamSignInDetails?.url) {
      items.push({
        text: t(loginTeamRole) ?? '',
        id: TEAM_CHALLENGE_DROPDOWN_ITEMS.LOGIN_AS_TEAM_ROLE,
        iconName: 'external',
      });
    }
    if (challenge.adminSignInDetails?.url) {
      items.push({
        text: t(loginAdminRole) ?? '',
        id: TEAM_CHALLENGE_DROPDOWN_ITEMS.LOGIN_AS_ADMIN_ROLE,
        iconName: 'external',
      });
    }
    if (challenge.teamSignInDetails) {
      items.push({
        text: t(teamRoleCLI) ?? '',
        id: TEAM_CHALLENGE_DROPDOWN_ITEMS.TEAM_ROLE_CLI_ACCESS,
        iconName: 'script',
      });
    }
    if (challenge.adminSignInDetails) {
      items.push({
        text: t(adminRoleCLI) ?? '',
        id: TEAM_CHALLENGE_DROPDOWN_ITEMS.ADMIN_ROLE_CLI_ACCESS,
        iconName: 'script',
      });
    }
    return items;
  }, [t, challenge]);

  const handleItemClick = useCallback<CancelableEventHandler<ButtonDropdownProps.ItemClickDetails>>(
    async (e) => {
      const { detail } = e;
      const [id, currTaskId, currClueOrder] = detail.id.split('#');
      switch (id) {
        case TEAM_CHALLENGE_DROPDOWN_ITEMS.RESTART_CHALLENGE: {
          setShowConfirmModal(true);
          setConfirmModalType(TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.RESTART_CHALLENGE_MODAL);
          break;
        }
        case TEAM_CHALLENGE_DROPDOWN_ITEMS.MARK_TASK_AS_COMPLETED: {
          setShowConfirmModal(true);
          setConfirmModalType(TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.MARK_TASK_COMPLETED);
          setTaskId(currTaskId);
          break;
        }
        case TEAM_CHALLENGE_DROPDOWN_ITEMS.REFUND_TASK_CLUE: {
          setShowConfirmModal(true);
          setConfirmModalType(TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.REFUND_TASK_CLUE);
          setTaskId(currTaskId);
          setClueOrder(parseInt(currClueOrder, 10));
          break;
        }
        case TEAM_CHALLENGE_DROPDOWN_ITEMS.DOWNLOAD_SSH_KEY_PAIR: {
          const blob = await jamFacilitatorApi.downloadSSHKeyPair(
            challenge.eventName,
            challenge.challengeId,
            challenge.teamName
          );
          const text = await blob.text();
          downloadAsPEM(text, 'keypair');
          break;
        }
        case TEAM_CHALLENGE_DROPDOWN_ITEMS.LOGIN_AS_TEAM_ROLE: {
          if (!challenge.teamSignInDetails?.url) return;
          window.open(challenge.teamSignInDetails?.url, '_blank');
          break;
        }
        case TEAM_CHALLENGE_DROPDOWN_ITEMS.LOGIN_AS_ADMIN_ROLE: {
          if (!challenge.adminSignInDetails?.url) return;
          window.open(challenge.adminSignInDetails?.url, '_blank');
          break;
        }
        case TEAM_CHALLENGE_DROPDOWN_ITEMS.TEAM_ROLE_CLI_ACCESS: {
          setShowAWSCLIModal(true);
          setCredentials(challenge.teamSignInDetails?.credentials);
          break;
        }
        case TEAM_CHALLENGE_DROPDOWN_ITEMS.ADMIN_ROLE_CLI_ACCESS: {
          setShowAWSCLIModal(true);
          setCredentials(challenge.adminSignInDetails?.credentials);
          break;
        }
      }
    },
    [challenge, jamFacilitatorApi]
  );

  const handleConfirmAction = useCallback(async () => {
    try {
      switch (confirmModalType) {
        case TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.RESTART_CHALLENGE_MODAL: {
          await jamFacilitatorApi.restartChallenge(challenge.eventName, challenge.challengeId, challenge.teamName);
          break;
        }
        case TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.MARK_TASK_COMPLETED: {
          if (!taskId) throw new Error('TaskId not defined');
          await jamFacilitatorApi.markChallengeSolved(
            challenge.eventName,
            challenge.challengeId,
            challenge.teamName,
            taskId
          );
          break;
        }
        case TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.REFUND_TASK_CLUE: {
          if (!taskId) throw new Error('TaskId not defined');
          if (!clueOrder) throw new Error('ClueOrder not defined');
          await jamFacilitatorApi.refundTaskCluePoints(
            challenge.eventName,
            challenge.challengeId,
            challenge.teamName,
            taskId,
            clueOrder
          );
          break;
        }
      }
    } catch (err) {
      preProdLogger(err);
    } finally {
      handleCancelAction();
      void loadTeamProgressData(challenge.eventName, challenge.teamName);
    }
  }, [confirmModalType, challenge, taskId, jamFacilitatorApi, clueOrder, loadTeamProgressData]);

  const handleCancelAction = useCallback(() => {
    setConfirmModalType(undefined);
    setShowConfirmModal(false);
    setTaskId(undefined);
    setShowAWSCLIModal(false);
    setCredentials(undefined);
    setClueOrder(undefined);
  }, []);

  const modalMessage = useMemo(() => {
    switch (confirmModalType) {
      case TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.RESTART_CHALLENGE_MODAL: {
        return t(i18nKeys.facilitator.participants.modals.confirmModal.restartChallenge);
      }
      case TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.MARK_TASK_COMPLETED: {
        return t(i18nKeys.facilitator.participants.modals.confirmModal.markTaskCompleted);
      }
      case TEAM_CHALLENGE_CONFIRM_MODAL_TYPE.REFUND_TASK_CLUE: {
        return t(i18nKeys.facilitator.participants.modals.confirmModal.refundClue);
      }
    }
  }, [t, confirmModalType]);
  return (
    <>
      <Container className="dark-bg">
        <SpaceBetween direction="vertical" size="s">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box variant="h3">{challenge.challengeTitle}</Box>
            {teamChallengeProgressDropdownItems &&
              teamChallengeProgressDropdownItems.length > 0 &&
              challenge.startTime !== null && (
                <ButtonDropdown
                  items={teamChallengeProgressDropdownItems}
                  variant="icon"
                  onItemClick={handleItemClick}
                />
              )}
          </div>
          <ColumnLayout borders="horizontal" columns={2}>
            <Box className="space-between">
              <Box>{t(i18nKeys.facilitator.participants.label.startTime)}</Box>
              <Box>{startTime}</Box>
            </Box>
            <Box className="space-between">
              <Box>{t(i18nKeys.facilitator.participants.label.completed)}</Box>
              <Box>
                {challenge.completed ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}{' '}
                {`(${
                  completedPercentage > 0 ? completedPercentage.toFixed(2) : completedPercentage
                }%, ${completedTasks}/${totalTasks})`}
              </Box>
            </Box>
            <Box className="space-between">
              <Box>{t(i18nKeys.facilitator.participants.label.restartUsed)}</Box>
              <Box>{challenge.numRestartsUsed ?? 0}</Box>
            </Box>
          </ColumnLayout>
          <ShowTasks taskProgress={challenge?.taskProgress ?? []} />
        </SpaceBetween>
      </Container>
      <ConfirmModal
        message={modalMessage}
        visible={showConfirmModal}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
      />
      {credentials && <AwsCliModal value={showAWSCLIModal} setValue={setShowAWSCLIModal} awsAccount={credentials} />}
    </>
  );
};

interface TeamProgressProps {
  teamProgress: TeamProgressResponse | undefined;
}

const TeamProgress: React.FC<TeamProgressProps> = ({ teamProgress }) => {
  return (
    <SpaceBetween direction="vertical" size="s">
      {teamProgress?.teamChallengeAnswers?.map((challenge, i) => (
        <TeamChallengeProgressAnswer key={i} challenge={challenge} />
      ))}
    </SpaceBetween>
  );
};

export default TeamProgress;
