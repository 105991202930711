import { Badge } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus, ChangeRequestPendingStatus, RequestUnapprovedStatus } from '../../types/common';
import { EventStatus } from '../../types/Event';
import { i18nKeys } from '../../utils/i18n.utils';
import { StatusColors } from './CommonModel';

const ACTIVE = 'ACTIVE';

interface StatusBadgeProps {
  status: EventStatus | ChangeRequestPendingStatus | ApprovalStatus | RequestUnapprovedStatus | string | null;
}

const statusColors: { [key: string]: StatusColors | undefined } = {
  [ACTIVE]: StatusColors.green,
  [EventStatus.ONGOING]: StatusColors.green,
  [EventStatus.NOT_STARTED]: StatusColors.blue,
  [EventStatus.ENDED]: StatusColors.grey,
  defaultColor: StatusColors.grey,
};

/**
 * Takes event and generates a badge with color based on status of event
 *
 * @param param0 Status of event
 * @returns React.FC of a badge depicting status of event
 */
export const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation();

  const eventStatuses: { [key: string]: EventStatus | RequestUnapprovedStatus | ChangeRequestPendingStatus } = {
    [ACTIVE]: t(i18nKeys.campaigns.labels.statuses.active),
    [EventStatus.ONGOING]: t(i18nKeys.events.eventDetails.labels.statuses.ongoing),
    [EventStatus.NOT_STARTED]: t(i18nKeys.events.eventDetails.labels.statuses.notStarted),
    [EventStatus.ENDED]: t(i18nKeys.events.eventDetails.labels.statuses.ended),
    [EventStatus.CANCELLED]: t(i18nKeys.events.eventDetails.labels.statuses.cancelled),
    [RequestUnapprovedStatus.REQUEST_SUBMITTED]: t(i18nKeys.events.eventDetails.labels.statuses.requestSubmitted),
    [RequestUnapprovedStatus.REQUEST_PENDING]: t(i18nKeys.events.eventDetails.labels.statuses.requestPending),
    [RequestUnapprovedStatus.REQUEST_DENIED]: t(i18nKeys.events.eventDetails.labels.statuses.requestDenied),
    [RequestUnapprovedStatus.REQUEST_CANCELLED]: t(i18nKeys.events.eventDetails.labels.statuses.requestCancelled),
    [ChangeRequestPendingStatus.CHANGE_PENDING]: t(i18nKeys.events.eventDetails.labels.statuses.changePending),
    [ChangeRequestPendingStatus.CHANGE_REQUESTED]: t(i18nKeys.events.eventDetails.labels.statuses.changeRequested),
  };

  return (
    <div>
      <Badge color={status ? statusColors[status] : statusColors.defaultColor}>
        {status ? eventStatuses[status] : null}
      </Badge>
    </div>
  );
};
