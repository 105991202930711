import { TFunction } from "i18next";
import { ChallengeSet, ChallengeSetRequest, SimpleChallengeSetChallenge } from "../types/ChallengeSet";
import { i18nKeys } from "../utils/i18n.utils";
import { asList } from "../utils/list.utils";
import { fromPlainObject } from "../utils/mapper.utils";
import { ApiClient } from "./ApiClient";

export class ChallengeSetAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  public async findAll(silent = false): Promise<ChallengeSet[]> {
    return this.apiClient.get({
      path: '/admin/challenge-sets',
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSet.findAll),
      silent,
      responseMapper: asList((obj) => fromPlainObject(obj, ChallengeSet)),
    }) as Promise<ChallengeSet[]>;
  }

  public async findOneById(challengeSetId: string, silent = false): Promise<ChallengeSet> {
    return this.apiClient.get({
      path: `/admin/challenge-sets/${challengeSetId}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSet.findOneById),
      silent,
      responseMapper: (obj) => fromPlainObject(obj, ChallengeSet),
    }) as Promise<ChallengeSet>;
  }

  public async create(request: ChallengeSetRequest, silent = false): Promise<ChallengeSet> {
    return this.apiClient.post({
      path: '/admin/challenge-sets',
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.challengeSet.create),
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSet.create),
      silent,
      responseMapper: (obj) => fromPlainObject(obj, ChallengeSet),
    }) as Promise<ChallengeSet>;
  }

  public async update(challengeSetId: string, request: ChallengeSetRequest, silent = false): Promise<ChallengeSet> {
    return this.apiClient.post({
      path: `/admin/challenge-sets/${challengeSetId}`,
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.challengeSet.update),
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSet.update),
      silent,
      responseMapper: (obj) => fromPlainObject(obj, ChallengeSet),
    }) as Promise<ChallengeSet>;
  }

  public async deleteChallengeSet(challengeSetId: string, silent = false): Promise<void> {
    await this.apiClient.delete({
      path: `/admin/challenge-sets/${challengeSetId}`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.challengeSet.deleteChallengeSet),
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSet.deleteChallengeSet),
      silent,
    });
  }

  async getUnusableChallenges(challengeSetId: string, silent = false): Promise<SimpleChallengeSetChallenge[]> {
    return await this.apiClient.get({
      path: `/admin/challenge-sets/${challengeSetId}/challenges`,
      failMessage: this.t(i18nKeys.errors.requestFailed.challengeSet.getUnusableChallenges),
      silent,
      responseMapper: asList((obj) => fromPlainObject(obj, SimpleChallengeSetChallenge))
    }) as Promise<SimpleChallengeSetChallenge[]>;
  }
}