/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { isObject, isArray } from 'lodash';
import { StackEvent } from '../types/cloud-formation';
import { getEnvVar } from './env-var.utils';
const yaml = require('js-yaml');

const awsJamResourcesBucketName: string = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME');
const awsJamResourcesBucketNameWithRegion = awsJamResourcesBucketName + '-${AWS::Region}';

export const getFailedStackEvents = (stackEvents: StackEvent[]): StackEvent[] => {
  // terms indicating a failed stack event
  const FAILURE_TERMS = ['FAILED', 'ROLLBACK', 'DELETE'].map((s) => s.toLowerCase());
  // if the resourceStatusReason matches one of these reasons then don't consider the stack event a failed stack event
  const EXCLUDED_REASONS = ['Resource creation cancelled', 'User Initiated'].map((s) => s.toLowerCase());

  return (stackEvents || []).filter((stackEvent: StackEvent) => {
    const resourceStatus = (stackEvent.resourceStatus || '').toLowerCase();
    const resourceStatusReason = (stackEvent.resourceStatusReason || '').toLowerCase();
    return (
      FAILURE_TERMS.some((term) => resourceStatus.includes(term)) &&
      !EXCLUDED_REASONS.some((reason) => resourceStatusReason.includes(reason))
    );
  });
};

export const parseCfnToJson = (cfnTemplate: string) => {
  if (cfnTemplate) {
    // Gracefully fail if unanticipated macro causes error while parsing
    try {
      let safeSnippet = cfnTemplate;
      safeSnippet = safeSnippet.split('!Base64').join('Fn::Base64');
      safeSnippet = safeSnippet.split('!Cidr').join('Fn::Cidr');
      safeSnippet = safeSnippet.split('!GetAtt').join('Fn::GetAtt');
      safeSnippet = safeSnippet.split('!GetAZs').join('Fn::GetAZs');
      safeSnippet = safeSnippet.split('!ImportValue').join('Fn::ImportValue');
      safeSnippet = safeSnippet.split('!Join').join('Fn::Join');
      safeSnippet = safeSnippet.split('!Select').join('Fn::Select');
      safeSnippet = safeSnippet.split('!Split').join('Fn::Split');
      safeSnippet = safeSnippet.split('ZipFile: !Sub ').join('ZipFile: | #Fn::Sub');
      safeSnippet = safeSnippet.split('!Sub').join('');
      safeSnippet = safeSnippet.split('!Ref').join('Fn::Ref');
      safeSnippet = safeSnippet.split('!Transform').join('Fn::Transform');
      safeSnippet = safeSnippet.split('!FindInMap').join('Fn::FindInMap');
      return yaml.safeLoad(safeSnippet, 'utf8');
    } catch {
      return null;
    }
  } else {
    return null;
  }
};

export const hasAwsJamResourceWithoutRegion = (cfnTemplate: string) => {
  // Checks for presence of awsJamResource url with missing region
  let resourceIndexCount = 0;
  let indexOccurance = cfnTemplate.indexOf(awsJamResourcesBucketName, 0);
  while (indexOccurance >= 0) {
    resourceIndexCount++;
    indexOccurance = cfnTemplate.indexOf(awsJamResourcesBucketName, indexOccurance + 1);
  }
  let regionResourceIndexCount = 0;
  let regionalIndexOccurance = cfnTemplate.indexOf(awsJamResourcesBucketNameWithRegion, 0);
  while (regionalIndexOccurance >= 0) {
    regionResourceIndexCount++;
    regionalIndexOccurance = cfnTemplate.indexOf(awsJamResourcesBucketNameWithRegion, regionalIndexOccurance + 1);
  }
  return resourceIndexCount !== regionResourceIndexCount;
};

export const hasValidCfnTemplateResource = (parsedCfnTemplate: any) => {
  // Will check for direct subs, joins, and params for aws-jam-resources and regionID
  // !Sub aws-jam-challenge-resources-${AWS::Region}
  // https://s3.${AWS::Region}.${AWS::URLSuffix}/${Param1}/*
  const resources = parsedCfnTemplate && parsedCfnTemplate.Resources ? parsedCfnTemplate.Resources : {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const resourceKeys = Object.keys(resources);
  if (resourceKeys.length < 1) {
    return true;
  }
  // Properties.TemplateUrl = Nested CfnTemplate
  // Some TemplateUrls have multiple parameters, this ensures that we can iterate over each TemplateUrl to look for the proper aws-jam-challenge-resources urls existence once per TemplateUrl obj
  for (const resourceKey of resourceKeys) {
    let templateUrl = resources[resourceKey].Properties?.TemplateURL;
    if (templateUrl) {
      let isValidTemplateUrl = false;
      if (!isArray(templateUrl)) {
        templateUrl = [templateUrl];
      }
      for (const value of templateUrl) {
        if (isObject(value)) {
          if (
            templateUrl[templateUrl.indexOf(value)][Object.keys(value)[0]].includes(awsJamResourcesBucketNameWithRegion)
          ) {
            isValidTemplateUrl = true;
          }
        } else {
          if (value?.includes(awsJamResourcesBucketNameWithRegion)) {
            isValidTemplateUrl = true;
          }
        }
      }
      if (!isValidTemplateUrl) {
        return false;
      }
    }
  }
  return true;
};
