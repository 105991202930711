import React, { useContext, useState, createContext, useCallback, useMemo, useEffect } from 'react';
import {
  EventDurationType,
  EventLearningType,
  EventTemplateStatusType,
  IEventTemplate,
  IEventTemplatePage,
  IGetEventTemplatesOptions,
  ISortingState,
} from '@/src/types/EventTemplate';
import { useApi } from './api.context';
import { PropertyFilterProps } from '@amzn/awsui-components-react';
import {
  DEFAULT_FILTER,
  DEFAULT_PAGE_COUNT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  DEFAULT_SORT,
  EVENT_TEMPLATE_PAGE_SIZE,
} from '../constants/event-template.constants';
import { localLogger } from '../utils/log.utils';
import { LoggingService } from '../utils/logging-service.utils';

export interface EventTemplateContextValue {
  loading: boolean;
  error: string;
  eventTemplates: IEventTemplate[];
  pageOptions: IEventTemplatePage;
  onPageChange: (page: number) => void;
  onPageSizeChange: (page: number) => void;
  onFilterChange: (query: PropertyFilterProps.Query) => void;
  onSortChange: (sorting: ISortingState) => void;
  totalCount: number;
  fetchEventTemplates: () => void;
  fetchPublicEventTemplates: () => void;
  fetchTrendingEventTemplate: () => void;
  trendingEventTemplates: IEventTemplate[];
  trendingTemplateLoading: boolean;
  fetchBeginnersEventTemplate: () => void;
  beginnersEventTemplates: IEventTemplate[];
  beginnersTemplateLoading: boolean;
  resetEventTemplates: () => void;
  onDeleteEventCatalogTemplate: (id: string) => Promise<void>;
}

const DEFAULT_EVENT_TEMPLATE_VALUE: EventTemplateContextValue = {
  loading: false,
  error: '',
  eventTemplates: [] as IEventTemplate[],
  pageOptions: DEFAULT_PAGINATION,
  onPageChange: () => null,
  onPageSizeChange: () => null,
  onFilterChange: () => null,
  onSortChange: () => null,
  totalCount: 0,
  fetchEventTemplates: () => null,
  fetchPublicEventTemplates: () => null,
  fetchTrendingEventTemplate: () => null,
  trendingEventTemplates: [],
  trendingTemplateLoading: false,
  fetchBeginnersEventTemplate: () => null,
  beginnersEventTemplates: [],
  beginnersTemplateLoading: false,
  resetEventTemplates: () => {
    // do nothing
  },
  onDeleteEventCatalogTemplate: (_id: string) => Promise.resolve()
};

const EventTemplateContext = createContext<EventTemplateContextValue>(DEFAULT_EVENT_TEMPLATE_VALUE);

const EventTemplateProvider: React.FC = ({ children }) => {
  const { eventTemplateApi } = useApi();
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching ] = useState(false);
  const [error, setError] = useState('');
  const [eventTemplates, setEventTemplates] = useState<IEventTemplate[]>([]);
  const [currPage, setCurrPage] = useState(DEFAULT_PAGE_NO);
  const [currPageSize, setCurrPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageCount, setPageCount] = useState(DEFAULT_PAGE_COUNT);
  const [filter, setFilter] = useState<PropertyFilterProps.Query>(DEFAULT_FILTER);
  const [sort, setSort] = useState<ISortingState>(DEFAULT_SORT);
  const [totalCount, setTotalCount] = useState(0);

  /* -- Homepage API Varaibles: start -- */
  const [trendingTemplateLoading, setTrendingTemplateLoading] = useState(false);
  const [trendingEventTemplates, setTrendingEventTemplates] = useState<IEventTemplate[]>([]);
  const [beginnersTemplateLoading, setBeginnersTemplateLoading] = useState(false);
  const [beginnersEventTemplates, setBeginnersEventTemplates] = useState<IEventTemplate[]>([]);
  /* -- Homepage API Varaibles: end -- */

  const resetEventTemplates = useCallback(() => {
    setLoading(true)
    setFetching(false)
    setError('')
    setEventTemplates([])
    setCurrPage(DEFAULT_PAGE_NO)
    setCurrPageSize(DEFAULT_PAGE_SIZE)
    setPageCount(DEFAULT_PAGE_COUNT)
    setFilter(DEFAULT_FILTER)
    setSort(DEFAULT_SORT)
    setTotalCount(0)
    setTrendingTemplateLoading(false)
    setTrendingEventTemplates([])
    setBeginnersTemplateLoading(false)
    setBeginnersEventTemplates([])
  }, [])

  const pageOptions = useMemo<IEventTemplatePage>(
    () => ({
      currentPageNumber: currPage,
      limit: currPageSize,
      pageCount,
      query: filter,
      sortingState: sort,
    }),
    [currPage, currPageSize, pageCount, filter, sort]
  );

  const fetchEventTemplates = useCallback(async () => {
    setLoading(true);
    setFetching(true)
    if (fetching) return
    const options = getEventTemplatesOptions(pageOptions);
    const { data, totalCount: total } = await eventTemplateApi.getEventTemplates(options); // pageOptions
    // eslint-disable-next-line no-console
    setFetching(false)
    setLoading(false);
    const { sortingColumn, isDescending } = pageOptions.sortingState
    const { sortingField } = sortingColumn
    setEventTemplates(sortEventTemplates(data, sortingField, isDescending));
    setPageCount(Math.ceil(total / currPageSize));
    // eslint-disable-next-line no-console
    console.log(Math.ceil(total / currPageSize), total, currPageSize )
    setTotalCount(total);
  }, [eventTemplateApi, pageOptions, currPageSize, loading]);

  const fetchPublicEventTemplates = useCallback(async () => {
    setLoading(true);
    setFetching(true)
    if (fetching) return
    const options = getEventTemplatesOptions(pageOptions);
    const { data, totalCount: total } = await eventTemplateApi.getPublicEventTemplates(options); // pageOptions
    setFetching(false)
    setLoading(false);
    const { sortingColumn, isDescending } = pageOptions.sortingState
    const { sortingField } = sortingColumn
    setEventTemplates(sortEventTemplates(data, sortingField, isDescending));
    setPageCount(Math.ceil(total / currPageSize));
    setTotalCount(total);
  }, [eventTemplateApi, pageOptions, currPageSize, loading]);

  // TODO: move to utils
  const getEventTemplatesOptions = useCallback(
    ({ query }: IEventTemplatePage): IGetEventTemplatesOptions => {
      const options: IGetEventTemplatesOptions = {};
      for (const token of query.tokens) {
        switch (token.propertyKey) {
          case 'learningTypes': {
            options.learningTypes = token.value as EventLearningType[];
            break;
          }
          case 'title': {
            options.name = token.value as string;
            break;
          }
          case 'topics': {
            options.topics = token.value as string[];
            break;
          }
          case 'durations': {
            options.durations = token.value as EventDurationType[];
            break;
          }
          case 'duration': {
            options.durations = token.value as EventDurationType[];
            break;
          }
          case 'services': {
            options.awsServices = token.value as string[];
            break;
          }
          case 'tags': {
            options.tags = token.value as string[];
            break;
          }
          case 'prices': {
            options.prices = token.value as string[];
            break;
          }
          case 'status': {
            options.statuses = token.value as EventTemplateStatusType[];
            break;
          }
        }
      }
      options.maxResults = EVENT_TEMPLATE_PAGE_SIZE;
      return options;
    },
    []
  );

  const fetchTrendingEventTemplate = useCallback(async () => {
    try {
      setTrendingTemplateLoading(true);
      const { data } = await eventTemplateApi.getPublicEventTemplates();
      setTrendingEventTemplates(data);
    } catch (e) {
      localLogger(e);
    }
    setTrendingTemplateLoading(false);
  }, []);

  const fetchBeginnersEventTemplate = useCallback(async () => {
    try {
      setBeginnersTemplateLoading(true);
      const { data } = await eventTemplateApi.getPublicEventTemplates({ tags: ['Good For Beginners'] });
      setBeginnersEventTemplates(data);
    } catch (e) {
      localLogger(e);
    }
    setBeginnersTemplateLoading(false);
  }, []);

  const onPageChange = (pageNumber: number) => {
    setCurrPage(pageNumber);
  };

  const onPageSizeChange = useCallback((pageSize: number) => {
    setCurrPage(DEFAULT_PAGE_NO);
    setCurrPageSize(pageSize);
    setPageCount(Math.ceil(totalCount / pageSize));
    setFilter(DEFAULT_FILTER);
    setSort(DEFAULT_SORT);
  }, [totalCount]);

  const onFilterChange = (propFilter: PropertyFilterProps.Query) => {
    setFilter(propFilter);
  };

  const sortEventTemplates = useCallback((templates: IEventTemplate[], sortingField: string, isDescending: boolean) => {
    const templatesCopy = [...templates]
    switch (sortingField) {
      case "createdDate": {
        templatesCopy.sort((a, b) => {
          if (!isDescending) return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
          return  new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        })
        break
      }
      case "price": {
        templatesCopy.sort((a, b) => {
          if (!isDescending) return a.price - b.price
          return b.price - a.price
        })
        break
      }
    }
    return templatesCopy
  }, [])

  useEffect(() => {
    const { sortingColumn, isDescending } = sort
    const { sortingField } = sortingColumn
    const sortedTemplates = sortEventTemplates(eventTemplates, sortingField, isDescending)
    setEventTemplates(sortedTemplates)
  }, [sort])

  const onSortChange = useCallback((sortState: ISortingState) => {
    setSort(sortState);
  }, []);

  const onDeleteEventCatalogTemplate = async (eventCatalogId: string) => {
    try {
      await eventTemplateApi.deleteEventTemplateById(eventCatalogId)
      setEventTemplates(eventTemplates.filter(item => item.id !== eventCatalogId))
    } catch (err) {
      LoggingService.debug(`Error deleting event catalog template ${eventCatalogId} ${JSON.stringify(error)}`);
    }
  };


  const value: EventTemplateContextValue = {
    loading,
    error,
    eventTemplates,
    pageOptions,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
    onSortChange,
    totalCount,
    fetchEventTemplates: () => void fetchEventTemplates(),
    fetchPublicEventTemplates: () => void fetchPublicEventTemplates(),
    fetchTrendingEventTemplate: () => void fetchTrendingEventTemplate(),
    trendingEventTemplates,
    trendingTemplateLoading,
    fetchBeginnersEventTemplate: () => void fetchBeginnersEventTemplate(),
    beginnersEventTemplates,
    beginnersTemplateLoading,
    resetEventTemplates,
    onDeleteEventCatalogTemplate
  };

  return <EventTemplateContext.Provider value={value}>{children}</EventTemplateContext.Provider>;
};

const useEventTemplate = () => {
  const context = useContext(EventTemplateContext);
  if (context === undefined) {
    throw new Error('useEventTemplate can only be used inside EventTemplateProvider');
  }
  return context;
};

export { EventTemplateProvider, useEventTemplate };
