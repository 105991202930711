import * as _ from 'lodash';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { isProd } from '../utils/env.utils';
import { localLogger } from '../utils/log.utils';
import { AnyValue } from './common';

/**
 * Used for checkRemoteChanges to add cloneable() to type
 */
export interface Cloneable<T> {
  clone(): T;
}

/**
 * Class depicting a change between two properties
 */
@jsonObject
export class DiffChange {
  @jsonMember(String)
  property = '';

  @jsonMember(AnyValue)
  previousValue: any = null;

  @jsonMember(AnyValue)
  updatedValue: any = null;
}

/**
 * Class for finding and representing changes/differences between two objects
 */
@jsonObject
export class DiffResult {
  @jsonMember(Object)
  diff: { [key: string]: any } = {};

  @jsonArrayMember(DiffChange)
  changes: DiffChange[] = [];

  /**
   * @param previousObj Previous instance of Object
   * @param updatedObj Updated instance of Object
   * @param properties Properties to find differences for
   * Disables eslint unsafe any assignment due to need for the method to take in truly any class and return a diff
   * @returns an instance of DiffResult with differences between supplied properties
   */
  static of(previousObj: any, updatedObj: any, properties: string[]): DiffResult {
    const diffResult: DiffResult = new DiffResult();

    properties.forEach((property) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const previousValue: any = previousObj[property];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const updatedValue: any = updatedObj[property];

      if (!previousValue && !updatedValue) {
        return;
      }

      if (!_.isEqual(previousValue, updatedValue)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        diffResult.diff[property] = updatedValue;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        diffResult.changes.push(Object.assign(new DiffChange(), { property, previousValue, updatedValue }));
      }
    });

    return diffResult;
  }

  /**
   * Checks DiffResult for changes
   *
   * @returns boolean depicting presences of differences on DiffResult
   */
  get hasChanges(): boolean {
    return this.changes.length > 0 || Object.keys(this.diff).length > 0;
  }

  /**
   * This method allows you to check if any property exists in this diff,
   * including partial matches on the path of a nested object.
   *
   * If x.items[0].y is changed, then this method will return true for:
   * "x"
   * "x.items"
   * "x.items[0]"
   * "x.items[0].y"
   *
   * @param property Property to check for changes
   * @returns boolean depicting presence of changes
   */
  isChanged(property: string): boolean {
    return Object.keys(this.diff).some((key) => {
      // exact match on full deep property name x.items[0].y
      if (key === property) {
        return true;
      }

      // match on partial property path
      // example: x.items[0].y
      // would be able to match on "x" or "x.items[0]"
      if (key.startsWith(property + '.')) {
        return true;
      }

      // match on partial property with name of array property
      // example: x.items[0].y
      // would be able to match on "x.items"
      return key.startsWith(property + '[');
    });
  }

  /**
   * Gets change of supplied property.
   *
   * Note: `property` should be a full property name, not a substring of a property name.
   *
   * If `property` is a substring of a property name, this method has undefined behavior.
   *
   * See https://sim.amazon.com/issues/JAM-2204
   *
   * @param property property to retrieve change for
   * @returns an instance of DiffChange for the supplied property
   */
  getChange(property: string): DiffChange | undefined {
    if (this.isChanged(property)) {
      return this.changes.find((change) => change.property === property);
    }
    return undefined;
  }

  /**
   * Applies array to supplied property
   *
   * @param property property to applyArray to
   * @param previousValue previous value of the property
   * @param updatedValue updated value of the property
   */
  applyArray(property: string, previousValue: any[], updatedValue: any[]): void {
    const length = Math.max(previousValue.length, updatedValue.length);
    for (let i = 0; i < length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const previousItem = previousValue[i] ?? undefined;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const updatedItem = updatedValue[i] ?? undefined;
      this.apply(`${property}[${i}]`, previousItem, updatedItem);
    }
  }

  /**
   * Applies Object to supplied property
   *
   * @param property Property to apply Object to
   * @param previousValue Previous value of Object property
   * @param updatedValue Updated value of Object property
   */
  applyObject(property: string, previousValue: object, updatedValue: object): void {
    const keys = [...Object.keys(previousValue), ...Object.keys(updatedValue)];
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const previousPropertyValue = (previousValue as any)[key] ?? undefined;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const updatedPropertyValue = (updatedValue as any)[key] ?? undefined;
      this.apply(`${property}.${key}`, previousPropertyValue, updatedPropertyValue);
    });
  }

  /**
   * Applies respective Array/Object values to specified property
   *
   * @param property Property to apply changes to
   * @param previousValue Previous value of property to apply changes to
   * @param updatedValue Updated value of property to apply changes to
   */
  apply(property: string, previousValue: any, updatedValue: any): void {
    const isArray = Array.isArray(previousValue) || Array.isArray(updatedValue);
    const isObject = _.isObject(previousValue) || _.isObject(updatedValue);
    if (isArray) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.applyArray(property, previousValue, updatedValue);
    } else if (isObject) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.applyObject(property, previousValue, updatedValue);
    } else if (previousValue !== updatedValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.diff[property] = updatedValue;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.changes.push({ property, previousValue, updatedValue });
    }
  }
}

/**
 * Generates a formatted label of specified property
 *
 * @param property Property to retrieve label for
 * @param overrides Provided labels to override default logic
 * @returns a formatted label of provided property
 */
export const getPropertyLabel = (property: string, overrides: { [key: string]: string } = {}): string => {
  if (overrides && overrides[property]) {
    return overrides[property];
  }
  return _.kebabCase(property)
    .split('-')
    .map((label) => _.capitalize(label))
    .join(' ');
};

/**
 * Generates a DiffChange between two provided objects, using their labels and any provided overrides
 *
 * @param previousObj Previous version of object
 * @param updatedObj New version of object
 * @param properties Properties to check
 * @param propertyLabelOverrides Property overrides to use
 * @returns a DiffChange of the provided Objects and their overrides
 */
export const diffObjects = (
  previousObj: any,
  updatedObj: any,
  properties: string[],
  propertyLabelOverrides: { [key: string]: string } = {}
): DiffChange[] => {
  return properties
    .map((property) => ({
      property: getPropertyLabel(property, propertyLabelOverrides),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      previousValue: previousObj[property],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      updatedValue: updatedObj[property],
    }))
    .filter((change) => !_.isEqual(change.previousValue, change.updatedValue));
};

/**
 * Compare previous and current remote versions of an object, if changed then apply the latest copy.
 * If the current local copy has changes, and the current remote copy has changes, then prompt to
 * see if the user wants to keep their changes.
 *
 * @param targetType
 * @param versions
 * @param hasUnsavedChanges
 * @param confirmKeepChanges
 * @param diff
 * @param applyDiff
 * @param applyLatestCopy
 */
export const checkForRemoteChanges = async <T extends Cloneable<T>>(
  targetType: 'event' | 'campaign',
  versions: {
    previousRemoteCopy: T;
    currentRemoteCopy: T;
    currentLocalCopy: T;
  },
  hasUnsavedChanges: boolean,
  confirmKeepChanges: (message: string) => Promise<boolean>,
  diff: (previous: T, updated: T) => DiffResult,
  applyDiff: (obj: T, diff: any) => T,
  applyLatestCopy: (newMasterCopy: T, newMutableCopy?: T) => Promise<any>
): Promise<void> => {
  const myChanges: DiffResult = diff(versions.previousRemoteCopy, versions.currentLocalCopy);
  const diffResult: DiffResult = diff(versions.previousRemoteCopy, versions.currentRemoteCopy);
  const hasRemoteCopyChanged = diffResult.hasChanges;

  if (hasRemoteCopyChanged) {
    localLogger('remote changes', diffResult);
  }

  let keepLocalChanges = true;

  /**
   * If the remote copy and the local copy both have changed, then prompt the user for whether they want to keep their changes.
   *
   * If they keep their changes then the local changes will be applied onto the latest remote copy.
   * If they do not keep their changes, then the latest remote copy is kept and the local changes are discarded.
   */
  if (hasRemoteCopyChanged && hasUnsavedChanges) {
    // tslint:disable-next-line:max-line-length
    let message = `You are no longer viewing the latest version of this ${targetType}. Would you like to apply your changes to the latest version?`;

    if (!isProd()) {
      message += '\n\nMy Changes:\n\n' + JSON.stringify(myChanges.changes, null, 4);
    }

    keepLocalChanges = await confirmKeepChanges(message);
  }

  if (hasUnsavedChanges && keepLocalChanges) {
    // merge the local changes onto the latest remote copy
    const newMutableCopy: T = applyDiff(versions.currentRemoteCopy.clone(), myChanges.diff);
    await applyLatestCopy(versions.currentRemoteCopy, newMutableCopy);
  } else {
    await applyLatestCopy(versions.currentRemoteCopy);
  }
};
