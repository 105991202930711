import React from 'react';
import { Box, Container, Header } from '@amzn/awsui-components-react';
import { LoadingBar } from '@/src/components/common/LoadingBar';
import { useTranslation } from 'react-i18next';

// components
import EventDetailFeedbackRating from '@/src/components/ui/molecules/EventTemplate/EventDetailFeedbackRating';
import EventDetailFeedbackComment from '@/src/components/ui/molecules/EventTemplate/EventDetailFeedbackComment';

// styles
import './Feedback.scss';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

// types
import { EventTemplateRating, EventTemplateReview } from '@/src/types/EventTemplate';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

interface IProps {
  reviews: EventTemplateReview[];
  handleSeeMoreClick: () => void;
  filteringText: string;
  setFilteringText: (text: string) => void;
  eventTemplateRating: EventTemplateRating | null;
  selectedEventTemplateFeedbackOption: OptionDefinition;
  setSelectedEventTemplateFeedbackOption: (optionDefinition: OptionDefinition) => void;
  toggleEventTemplateFeedbackReviewLikeStatus: (id: string) => void;
  withContainer?: boolean;
}

const Feedback = ({
  reviews: reviewsComment,
  handleSeeMoreClick,
  filteringText,
  setFilteringText,
  eventTemplateRating,
  selectedEventTemplateFeedbackOption,
  setSelectedEventTemplateFeedbackOption,
  toggleEventTemplateFeedbackReviewLikeStatus,
  withContainer = true,
}: IProps) => {
  const { t } = useTranslation();
  if (!eventTemplateRating) {
    return <LoadingBar />;
  }

  return (
    <>
      <div className="challenges feedback">
        {withContainer ? (
          <Container
            header={
              <Header variant="h1">
                {t(i18nKeys.eventTemplates.eventTemplateDetails.feedback.reviews)}
                <span className="challenge-total">{`(${eventTemplateRating.numberOfRatings})`}</span>
              </Header>
            }>
            <EventDetailFeedbackRating eventTemplateRating={eventTemplateRating} />
            <EventDetailFeedbackComment
              reviews={reviewsComment}
              handleSeeMoreClick={handleSeeMoreClick}
              filteringText={filteringText}
              setFilteringText={setFilteringText}
              selectedEventTemplateFeedbackOption={selectedEventTemplateFeedbackOption}
              setSelectedEventTemplateFeedbackOption={setSelectedEventTemplateFeedbackOption}
              toggleEventTemplateFeedbackReviewLikeStatus={toggleEventTemplateFeedbackReviewLikeStatus}
            />
          </Container>
        ) : (
          <>
            <Box margin={{ bottom: 'xl' }}>
              <Header variant="h1">{t(i18nKeys.eventTemplates.eventTemplateDetails.feedback.reviews)}</Header>
            </Box>
            <EventDetailFeedbackRating eventTemplateRating={eventTemplateRating} />
            <EventDetailFeedbackComment
              reviews={reviewsComment}
              handleSeeMoreClick={handleSeeMoreClick}
              filteringText={filteringText}
              setFilteringText={setFilteringText}
              selectedEventTemplateFeedbackOption={selectedEventTemplateFeedbackOption}
              setSelectedEventTemplateFeedbackOption={setSelectedEventTemplateFeedbackOption}
              toggleEventTemplateFeedbackReviewLikeStatus={toggleEventTemplateFeedbackReviewLikeStatus}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Feedback;
