import { Box, Button, Checkbox, CheckboxProps, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useState } from 'react';
import './JamWelcomeModal.scss';
import { completeWelcomeModalShow } from '@/src/utils/jam-event.utils';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import localStorageTTL from '@/src/utils/localStorageTTL.utils';
import { JamConstants } from '@/src/constants/shared/jam-constants'
const maxSlides = 6;

interface JamWelcomeModalProps {
  eventId: string;
}

const JamWelcomeModal: React.FC<JamWelcomeModalProps> = ({ eventId }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dontShowChecked, setDontShowChecked] = useState(false);

  const isLast = currentIndex === maxSlides - 1;

  const onHide = () => {
    completeWelcomeModalShow(eventId);
    setVisible(false);
  };

  const onNextPress = () => {
    if (isLast) {
      onHide();
      return;
    }
    setCurrentIndex((prev) => prev + 1);
  };

  const handleOnDontShowAgain = useCallback<NonCancelableEventHandler<CheckboxProps.ChangeDetail>>(({detail}) => {
    setDontShowChecked(detail.checked)
    if (detail.checked) {
      localStorageTTL.setItem(JamConstants.DONT_SHOW_JAM_EVENT_ONBOARDING_POPUP_KEY, 'true', 356, "days");
    }
  }, [])

  return (
    <Modal
      className="welcome-modal"
      onDismiss={onHide}
      visible={visible}
      footer={
        <div className="welcome-modal-footer">
          <SpaceBetween direction="horizontal" size="m" alignItems="center">
            {currentIndex !== 0 && (
              <Button iconName="angle-left" onClick={() => setCurrentIndex((prev) => prev - 1)}>
                {t(i18nKeys.general.back)}
              </Button>
            )}
            <Box fontWeight="bold">
              {currentIndex + 1}/{maxSlides}
            </Box>
          </SpaceBetween>
          <SpaceBetween direction="horizontal" size="xxs" alignItems='center'>
            <Checkbox
              onChange={handleOnDontShowAgain}
              checked={dontShowChecked}
            >
              {t(i18nKeys.myJams.welcomeModal.dontShowAgainText)}
            </Checkbox>
            {!isLast && (
              <div id="introCloseButton" data-testid="introCloseButton" onClick={onHide}>
                <Button variant="link">{t(i18nKeys.general.close)}</Button>
              </div>
            )}
            <Button variant="primary" onClick={onNextPress}>
              {isLast ? t(i18nKeys.myJams.welcomeModal.actions.getStarted) : t(i18nKeys.general.next)}
            </Button>
          </SpaceBetween>
        </div>
      }>
      {currentIndex === 0 && (
        <>
          <Box variant="h3">{t(i18nKeys.myJams.welcomeModal.slides.slide1.title)}</Box>
          <Box margin={{ top: 'l', bottom: 'l' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide1.description)}</Box>
          <div className="slide-center-img">
            <img src="/assets/slide-1.1.png" alt="welcome slide 1" />
          </div>
        </>
      )}
      {currentIndex === 1 && (
        <>
          <Box variant="h3">{t(i18nKeys.myJams.welcomeModal.slides.slide2.title)}</Box>
          <Box margin={{ top: 'l', bottom: 's' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide2.description)}</Box>
          <div className="slide-column-img slide-center-img">
            <img className="slide-2-img1" src="/assets/slide-2.1.png" alt="welcome slide 1" />
            <div className="slide-center-img">
              <img src="/assets/slide-2.2.png" alt="welcome slide 1" />
            </div>
          </div>
        </>
      )}
      {currentIndex === 2 && (
        <>
          <Box variant="h3">{t(i18nKeys.myJams.welcomeModal.slides.slide3.title)}</Box>
          <Box margin={{ top: 'l', bottom: 's' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide3.description1)}</Box>
          <div className="slide-column-img slide-center-img">
            <img className="slide-2-img1" src="/assets/slide-3.1.png" alt="welcome slide 1" />
          </div>
          <Box margin={{ top: 's', bottom: 's' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide3.description2)}</Box>
          <div className="slide-column-img slide-center-img">
            <img className="slide-2-img1" src="/assets/slide-3.2.png" alt="welcome slide 1" />
          </div>
          <Box margin={{ bottom: 'l' }} fontSize="body-s">
            {t(i18nKeys.myJams.welcomeModal.slides.slide3.caption1)}
          </Box>
          <div className="slide-column-img slide-center-img">
            <img className="slide-2-img1" src="/assets/slide-3.3.png" alt="welcome slide 1" />
          </div>
          <Box margin={{ bottom: 'l' }} fontSize="body-s">
            {t(i18nKeys.myJams.welcomeModal.slides.slide3.caption2)}
          </Box>
        </>
      )}
      {currentIndex === 3 && (
        <>
          <Box variant="h3">{t(i18nKeys.myJams.welcomeModal.slides.slide4.title)}</Box>
          <Box margin={{ top: 's', bottom: 's' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide4.description)}</Box>
          <Box margin={{ bottom: 's' }}>
            <div className="slide-center-img">
              <img src="/assets/slide-4.1.png" alt="welcome slide 1" />
            </div>
          </Box>
        </>
      )}
      {currentIndex === 4 && (
        <>
          <Box variant="h3">{t(i18nKeys.myJams.welcomeModal.slides.slide5.title)}</Box>
          <Box margin={{ top: 's' }} fontWeight="bold">
            <span className="welcome-highlight">{t(i18nKeys.myJams.welcomeModal.slides.slide5.q1)}</span>
          </Box>
          <Box>
            <ul>
              <li>{t(i18nKeys.myJams.welcomeModal.slides.slide5.a11)}</li>
              <li>{t(i18nKeys.myJams.welcomeModal.slides.slide5.a12)}</li>
            </ul>
          </Box>
          <Box margin={{ top: 's' }}>
            <span className="welcome-highlight">{t(i18nKeys.myJams.welcomeModal.slides.slide5.q2)}</span>
          </Box>
          <Box margin={{ top: 'xxs' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide5.a21)}</Box>
          <Box margin={{ top: 's' }}>
            <div className="slide-center-img">
              <img src="/assets/slide-5.1.png" alt="welcome slide 1" />
            </div>
          </Box>
        </>
      )}
      {currentIndex === 5 && (
        <>
          <Box variant="h3">{t(i18nKeys.myJams.welcomeModal.slides.slide6.title)}</Box>
          <Box margin={{ top: 'l' }}>
            <span className="welcome-highlight">{t(i18nKeys.myJams.welcomeModal.slides.slide6.t1Title)}</span>
          </Box>
          <Box margin={{ top: 'xxs' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide6.t1Desc)}</Box>
          <Box margin={{ top: 'l' }}>
            <span className="welcome-highlight">{t(i18nKeys.myJams.welcomeModal.slides.slide6.t2Title)}</span>
          </Box>
          <Box margin={{ top: 'xxs' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide6.t2Desc)}</Box>
          <Box margin={{ top: 'l' }}>
            <span className="welcome-highlight">{t(i18nKeys.myJams.welcomeModal.slides.slide6.t3Title)}</span>
          </Box>
          <Box margin={{ top: 'xxs' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide6.t3Desc)}</Box>
          <Box margin={{ top: 's' }}>
            <div className="slide-center-img">
              <img src="/assets/slide-6.1.png" alt="welcome slide 1" />
            </div>
          </Box>
          <Box margin={{ top: 'l' }}>
            <span className="welcome-highlight">{t(i18nKeys.myJams.welcomeModal.slides.slide6.t4Title)}</span>
          </Box>
          <Box margin={{ top: 'xxs' }}>{t(i18nKeys.myJams.welcomeModal.slides.slide6.t4Desc)}</Box>
          <Box margin={{ top: 's' }}>
            <div className="slide-center-img">
              <img src="/assets/slide-6.2.png" alt="welcome slide 1" />
            </div>
          </Box>
        </>
      )}
    </Modal>
  );
};

export default JamWelcomeModal;
