import { ColumnLayout, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../store/user.context';
import { i18nKeys } from '../../utils/i18n.utils';
import {
  awsJamFlyerUrl,
  awsJamIntro,
  challengeAuthoringGuideUrl,
  challengeWritingStyleGuideUrl,
  emailInviteTemplateUrl,
  eventOwnerChecklistUrl,
  eventOwnerGuide,
  facilitatorChecklistUrl,
  gettingStartedGuideUrl,
  jamFacilitatorGuideUrl,
  jamIntroPptUrl,
  skillBuilderEventOwnerGuideUrl,
  skillBuilderSupportUrl,
  supportContactsUrl,
} from '../../utils/jam-urls';
import DocumentationCard from './DocumentationCard';

export interface DocumentationLink {
  href: string;
  label: string;
  description: string;
  canView: boolean;
}

export interface DocumentationGroup {
  title: string;
  documentLinks: DocumentationLink[];
  videoLinks?: DocumentationLink[];
}

const Documentation: React.FC = () => {
  const { isLoggedIn, user } = useUser();
  const { t } = useTranslation();

  const DocumentationGroupLinks: DocumentationGroup[] = [
    {
      title: t(i18nKeys.documentation.cards.general.title),
      documentLinks: [
        {
          href: awsJamFlyerUrl,
          label: t(i18nKeys.documentation.cards.general.documents.awsJamFlyer.label, { awsJam: 'AWS Jam' }),
          description: t(i18nKeys.documentation.cards.general.documents.awsJamFlyer.description, { awsJam: 'AWS Jam' }),
          canView: isLoggedIn,
        },
        {
          href: jamIntroPptUrl,
          label: t(i18nKeys.documentation.cards.general.documents.overviewPresentation.label),
          description: t(i18nKeys.documentation.cards.general.documents.overviewPresentation.description),
          canView: isLoggedIn,
        },
      ],
      videoLinks: [
        {
          href: awsJamIntro,
          label: t(i18nKeys.documentation.cards.general.documents.awsJamIntro.label, { awsJam: 'AWS Jam' }),
          description: t(i18nKeys.documentation.cards.general.documents.awsJamIntro.description, { awsJam: 'AWS Jam' }),
          canView: (isLoggedIn && user?.isAmazonian) || false,
        },
      ],
    },
    {
      title: t(i18nKeys.documentation.cards.eventOwners.title),
      documentLinks: [
        {
          href: eventOwnerChecklistUrl,
          label: t(i18nKeys.documentation.cards.eventOwners.documents.eventOwnerChecklist.label),
          description: t(i18nKeys.documentation.cards.eventOwners.documents.eventOwnerChecklist.description),
          canView: isLoggedIn && !user?.isTCUser,
        },
        {
          href: skillBuilderEventOwnerGuideUrl,
          label: t(i18nKeys.documentation.cards.eventOwners.documents.skillBuilderEventOwnerGuide.label),
          description: t(i18nKeys.documentation.cards.eventOwners.documents.skillBuilderEventOwnerGuide.description),
          canView: (isLoggedIn && user?.isTCUser) || false,
        },
        {
          href: emailInviteTemplateUrl,
          label: t(i18nKeys.documentation.cards.eventOwners.documents.inviteEmailTemplate.label),
          description: t(i18nKeys.documentation.cards.eventOwners.documents.inviteEmailTemplate.description),
          canView: isLoggedIn,
        },
      ],
      videoLinks: [
        {
          href: eventOwnerGuide,
          label: t(i18nKeys.documentation.cards.eventOwners.documents.eventOwnerGuide.label),
          description: t(i18nKeys.documentation.cards.eventOwners.documents.eventOwnerGuide.description),
          canView: (isLoggedIn && user?.isAmazonian) || false,
        },
      ],
    },
    {
      title: t(i18nKeys.documentation.cards.facilitators.title),
      documentLinks: [
        {
          href: jamFacilitatorGuideUrl,
          label: t(i18nKeys.documentation.cards.facilitators.documents.facilitatorGuide.label),
          description: t(i18nKeys.documentation.cards.facilitators.documents.facilitatorGuide.description),
          canView: isLoggedIn,
        },
        {
          href: facilitatorChecklistUrl,
          label: t(i18nKeys.documentation.cards.facilitators.documents.facilitatorChecklist.label),
          description: t(i18nKeys.documentation.cards.facilitators.documents.facilitatorChecklist.description),
          canView: isLoggedIn,
        },
      ],
    },
    {
      title: t(i18nKeys.documentation.cards.participants.title),
      documentLinks: [
        {
          href: gettingStartedGuideUrl,
          label: t(i18nKeys.documentation.cards.participants.documents.gettingStartedGuide.label),
          description: t(i18nKeys.documentation.cards.participants.documents.gettingStartedGuide.description),
          canView: isLoggedIn,
        },
      ],
    },
    {
      title: t(i18nKeys.documentation.cards.challenges.title),
      documentLinks: [
        {
          href: challengeAuthoringGuideUrl,
          label: t(i18nKeys.documentation.cards.challenges.documents.challengeAuthoringGuide.label),
          description: t(i18nKeys.documentation.cards.challenges.documents.challengeAuthoringGuide.description),
          canView: (isLoggedIn && (user?.isChallengeAdmin || user?.isChallengeBuilder)) || false,
        },
        {
          href: challengeWritingStyleGuideUrl,
          label: t(i18nKeys.documentation.cards.challenges.documents.challengeWritingStyleGuide.label),
          description: t(i18nKeys.documentation.cards.challenges.documents.challengeAuthoringGuide.description),
          canView: (isLoggedIn && (user?.isChallengeAdmin || user?.isChallengeBuilder)) || false,
        },
      ],
    },
    {
      title: t(i18nKeys.documentation.cards.support.title),
      documentLinks: [
        {
          href: supportContactsUrl,
          label: t(i18nKeys.documentation.cards.support.documents.supportContacts.label),
          description: t(i18nKeys.documentation.cards.support.documents.supportContacts.description),
          canView: isLoggedIn && !user?.isTCUser,
        },
        {
          href: skillBuilderSupportUrl,
          label: t(i18nKeys.documentation.cards.support.documents.supportContacts.label),
          description: t(i18nKeys.documentation.cards.support.documents.supportContacts.description),
          canView: (isLoggedIn && user?.isTCUser) || false,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <Header variant="h1" description={t(i18nKeys.documentation.description)}>
        {t(i18nKeys.documentation.title)}
      </Header>
      <ColumnLayout columns={4}>
        {DocumentationGroupLinks.map((documentGroup: DocumentationGroup) => {
          return (
            <DocumentationCard
              key={`document-card-${documentGroup.title}`}
              title={documentGroup.title}
              documentationLinks={documentGroup.documentLinks}
              videoLinks={documentGroup.videoLinks || undefined}
            />
          );
        })}
      </ColumnLayout>
    </React.Fragment>
  );
};
export default Documentation;
