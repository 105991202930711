import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, ContentLayout, Header, Table } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useUser } from '@/src/store/user.context';
import { useApi } from '@/src/store/api.context';
import { sanitizeSupportChats } from '@/src/utils/support-chat-queue.utils';
import { JamSupportChat } from '@/src/types/JamSupportChat';
import moment from 'moment';
import { useJamChat } from '@/src/store/jam-chat.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';

const FacilitatorSupportChats = () => {
  const { t } = useTranslation();
  const { eventName } = useJamEventDetails();
  const { clearFlashbars } = useFlashbars();
  const {
    loadAllSupportChats,
    allSupportChats,
    setSelectedSupportChatId,
    setActiveChatType,
    setViewAllSupportChats,
    updateSupportChatReadCounter,
  } = useJamChat();
  const { user } = useUser();
  const { jamSupportChatApi } = useApi();

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const supportChats = useMemo((): JamSupportChat[] => {
    if (!allSupportChats.length || !user) {
      return [];
    }
    return sanitizeSupportChats(allSupportChats, user);
  }, [allSupportChats]);

  const beginChat = async (id: string) => {
    try {
      clearFlashbars();
      setIsLoading(true);
      await jamSupportChatApi.selfAssignSupportChat(id, true);
      await loadAllSupportChats(eventName);
      setTimeout(() => {
        setSelectedSupportChatId(id);
        setActiveChatType('support');
      }, 0);
    } catch (e) {
      // Error handled by API.
    }
    setIsLoading(false);
  };

  const openChat = (id: string) => {
    setSelectedSupportChatId(id);
    setActiveChatType('support');
    setViewAllSupportChats(true);
    updateSupportChatReadCounter(id, 0);
  };

  const initialize = async () => {
    try {
      clearFlashbars();
      setIsFetching(true);
      await loadAllSupportChats(eventName);
    } catch (e) {
      // error handled
    }
    setIsFetching(false);
  };

  useEffect(() => {
    void initialize();
  }, []);

  return (
    <div>
      <ContentLayout header={<Header variant="h1">{t(i18nKeys.facilitator.supportChats.title)}</Header>}>
        <Table
          header={
            <Header description={t(i18nKeys.facilitator.supportChats.caption)}>
              {t(i18nKeys.facilitator.supportChats.subTitle)}
            </Header>
          }
          items={supportChats}
          columnDefinitions={[
            {
              id: 'time',
              header: t(i18nKeys.facilitator.supportChats.list.titles.time),
              cell: (item) => (item.timestamp ? moment(item.timestamp).format('MM/DD/YYYY hh:mm A') : '--'),
            },
            {
              id: 'user',
              header: t(i18nKeys.facilitator.supportChats.list.titles.user),
              cell: (item) => item.creatorDisplayName,
            },
            {
              id: 'team',
              header: t(i18nKeys.facilitator.supportChats.list.titles.team),
              cell: (item) => item.creatorTeamName || '--',
            },
            {
              id: 'chat-status',
              header: t(i18nKeys.facilitator.supportChats.list.titles.chatStatus),
              cell: (item) =>
                item.assignedToDisplayName ? (
                  <Button loading={isLoading} variant="inline-link" onClick={() => openChat(item.id)}>
                    {t(i18nKeys.facilitator.supportChats.actions.viewChat)}
                  </Button>
                ) : (
                  <Button loading={isLoading} variant="inline-link" onClick={() => void beginChat(item.id)}>
                    {t(i18nKeys.facilitator.supportChats.actions.beginChat)}
                  </Button>
                ),
            },
          ]}
          empty={
            <Box textAlign="center">
              <b> {t(i18nKeys.facilitator.supportChats.list.empty)}</b>
            </Box>
          }
          loading={isFetching}
        />
      </ContentLayout>
    </div>
  );
};

export default FacilitatorSupportChats;
