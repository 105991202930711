import React from 'react';
import { Box, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import TeamProgress from '../game/FacilitatorParticipants/molecules/TeamProgress';
import { useJamFacilitator } from '@/src/store/jam-facilitator.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

export const FacilitatorParticipantsPanel: React.FC = () => {
  const { t } = useTranslation();
  const { jamTeamProgress, isFetchingJamTeamProgress } = useJamFacilitator();
  const teamName = jamTeamProgress?.teamChallengeAnswers[0].teamName;

  if (!jamTeamProgress?.teamChallengeAnswers || !(jamTeamProgress?.teamChallengeAnswers?.length > 0) || isFetchingJamTeamProgress) {
    return (
      <Box textAlign="center" margin={{ top: 'xxxl' }}>
        <Spinner />
      </Box>
    );
  }
  return (
    <Box margin={{ horizontal: 'm', bottom: 'xxxl' }} padding={{ vertical: 'xxl' }}>
      <SpaceBetween direction="vertical" size="s">
        <Box variant="h2">
          {t(i18nKeys.facilitator.participants.label.team)}: {teamName ?? '[Team Name]'}
        </Box>
        <TeamProgress teamProgress={jamTeamProgress} />
      </SpaceBetween>
    </Box>
  );
};

export default FacilitatorParticipantsPanel;
