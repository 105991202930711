import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Alert,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CAMPAIGN_DETAILS_ROUTES } from '../../../../routes';
import { useApi } from '../../../../store/api.context';
import { UserPreferenceKeys } from '../../../../store/user.context';
import { Campaign, CampaignGroup } from '../../../../types/Campaign';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { paginationLabels } from '../../../../utils/table.utils';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { TableEmptyState } from '../../../common/TableEmptyState';
import { TableHeader } from '../../../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './groups-list.config';

interface GroupsListProps {
  campaign: Campaign;
}
const GroupsList: React.FC<GroupsListProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const [groups, setGroups] = useState<CampaignGroup[]>([]);
  const [loading, setLoading] = useState(false);
  const { campaignApi } = useApi();
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
  });
  const history = useHistory();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    groups || [],
    {
      filtering: {
        filteringFunction,
        empty: <TableEmptyState title={t(i18nKeys.campaigns.labels.groups.noGroupsFound)} />,
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const getGroups = () => {
    if (campaign?.id) {
      setLoading(true);
      campaignApi
        .getCampaignGroups(campaign.id)
        .then((res) => {
          setLoading(false);
          setGroups(res);
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger('Error retrieving groups', err.message);
        });
    }
  };

  const handleCreateGroup = () => {
    if (campaign.id) {
      history.push(CAMPAIGN_DETAILS_ROUTES.Groups.resolve(campaign.id) + '/new');
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const handleSetPreferences = (newPreferences: CollectionPreferencesProps.Preferences) => {
    if (preferences.pageSize) {
      sessionStorage.setItem(UserPreferenceKeys.PAGE_SIZE, newPreferences?.pageSize?.toString() || '');
    }
    setPreferences(preferences);
  };

  return (
    <React.Fragment>
      <Header variant="h2">{t(i18nKeys.campaigns.headers.groups.groups)}</Header>
      <HorizontalRule />
      <SpaceBetween direction="vertical" size="s">
        <Alert type="info">{t(i18nKeys.campaigns.messages.groups.alert)}</Alert>
        <Table
          {...collectionProps}
          resizableColumns
          header={
            <TableHeader
              totalItems={groups.length}
              title={t(i18nKeys.campaigns.headers.groups.groups)}
              actionButtons={
                <Button variant="primary" onClick={() => handleCreateGroup()}>
                  {t(i18nKeys.campaigns.buttons.createGroup)}
                </Button>
              }
            />
          }
          columnDefinitions={COLUMN_DEFINITIONS(preferences)}
          items={items}
          loading={loading}
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
          preferences={
            <CollectionPreferences
              title={t(i18nKeys.campaigns.labels.preferences.title)}
              onConfirm={({ detail }) => handleSetPreferences(detail)}
              confirmLabel={t(i18nKeys.general.confirm)}
              cancelLabel={t(i18nKeys.general.cancel)}
              preferences={preferences}
              pageSizePreference={{
                title: t(i18nKeys.campaigns.labels.preferences.pageSize.label),
                options: [
                  { value: 10, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 10 }) },
                  { value: 20, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 20 }) },
                  { value: 50, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 50 }) },
                  { value: 100, label: t(i18nKeys.campaigns.labels.preferences.pageSize.value, { count: 100 }) },
                ],
              }}
            />
          }
          filter={
            <TextFilter
              className="text-filter"
              filteringPlaceholder={t(i18nKeys.campaigns.labels.groups.searchGroups)}
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.campaigns.labels.groups.searchGroups)}
            />
          }
        />
      </SpaceBetween>
    </React.Fragment>
  );
};
export default GroupsList;
