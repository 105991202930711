/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useContext, useState } from 'react';
import { useApi } from './api.context';
import i18n from '../i18n';
import { getLanguageCodeSafe } from '../utils/locale.utils';
import { PublicEventData } from '../types/PublicEvents';
import { localLogger } from '../utils/log.utils';

export interface PublicEventsContextValue {
  events: PublicEventData[];
  loadEvents: () => Promise<void>;
}

const PublicEventsContext = React.createContext<PublicEventsContextValue>({
  events: [],
  loadEvents: () => new Promise(() => {
     // do nothing
  }),
});

const PublicEventsProvider: React.FC = ({ children }) => {
  const { publicEventsAPI } = useApi();

  const [events, setEvents] = useState<PublicEventData[]>([]);

  const loadEvents = async () => {
    const languageCode: string = getLanguageCodeSafe(i18n.language);
    localLogger('test');
    const response = await publicEventsAPI.fetchPublicEventData(languageCode);
    setEvents(response);
  };

  const data: PublicEventsContextValue = {
    events,
    loadEvents,
  };
  return <PublicEventsContext.Provider value={data}>{children}</PublicEventsContext.Provider>;
};

const usePublicEvents = () => {
  const context = useContext(PublicEventsContext);
  if (context === undefined) {
    throw new Error('usePublicEvents can only be used inside JamMyTeamProvider');
  }
  return context;
};

export { PublicEventsProvider, usePublicEvents };
