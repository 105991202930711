import React from 'react';
import { Box, Button, Link } from '@amzn/awsui-components-react';
import { LeaderboardTeam } from '@/src/types/JamLeaderboard';
import { TFunctionProvider } from '../../common/TFunctionProvider';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { ChallengeMetricsRow } from '@/src/types/JamReport';
import { getPassRate } from '@/src/utils/leaderboard.utils';
import moment from 'moment';
import { toOrdinal } from '@/src/utils/number.utils';

export const COLUMN_DEFINITIONS = (showTeamDetails: (id: string | null) => void) => {
  return [
    {
      id: 'rank',
      header: (
        <Box variant="h3" tagOverride="span">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.rank)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ rank }: LeaderboardTeam) => (
        <Box padding={{ left: 'm' }} variant="h4" tagOverride="span">
          {toOrdinal(rank)}
        </Box>
      ),
    },
    {
      id: 'teamName',
      header: (
        <Box variant="h3" tagOverride="span">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.teamName)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ teamName, teamDisplayName }: LeaderboardTeam) => (
        <Link onFollow={() => showTeamDetails(teamName)}>
          <Box variant="h4" tagOverride="span" color="text-status-info">
            {teamDisplayName}
          </Box>
        </Link>
      ),
    },
    {
      id: 'completedChallenges',
      header: (
        <Box variant="h3" tagOverride="span">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.completedChallenges)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ solvedChallenges }: LeaderboardTeam) => <Box variant="h4" tagOverride="span">{solvedChallenges}</Box>,
    },
    {
      id: 'cluesUsed',
      header: (
        <Box variant="h3" tagOverride="span">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.cluesUsed)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ cluesUsed }: LeaderboardTeam) => <Box variant="h4" tagOverride="span">{cluesUsed}</Box>,
    },
    {
      id: 'score',
      header: (
        <Box variant="h3" tagOverride="span">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.score)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ score }: LeaderboardTeam) => <Box variant="h4" tagOverride="span">{score < 0 ? 0 : score}</Box>,
    },
    {
      id: 'totalTime',
      header: (
        <Box variant="h3" tagOverride="span">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.totalTime)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ totalTime }: LeaderboardTeam) => <Box variant="h4" tagOverride="span">{moment.utc(totalTime*1000).format('HH:mm:ss')}</Box>,
    },
  ];
};

export const COLUMN_DEFINITIONS_CHALLNGES = (challengeTitles: { [key: string]: string }, showSolvedChallengesStats: (challengeId: string) => void) => {
  return [
    {
      id: 'title',
      header: (
        <Box padding={{ left: 'm' }} variant="h3" tagOverride="span">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.challengeTitle)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ challengeId }: ChallengeMetricsRow) => (
        <Box color="text-status-info" padding={{ left: 'm' }} variant="h4" tagOverride="span">
          {challengeId ? challengeTitles[challengeId] : challengeId}
        </Box>
      ),
    },
    {
      id: 'numTeamsStarted',
      header: (
        <Box padding={{ left: 'm' }} variant="h3" tagOverride="span">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.teamsStarted)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ numTeamsStarted }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h4" tagOverride="span">
          {numTeamsStarted}
        </Box>
      ),
    },
    {
      id: 'numTeamsAttempted',
      header: (
        <Box padding={{ left: 'm' }} variant="h3" tagOverride="span">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.teamsAttempted)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ numTeamsAttempted }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h4" tagOverride="span">
          {numTeamsAttempted}
        </Box>
      ),
    },
    {
      id: 'teamsSolved',
      header: (
        <Box padding={{ left: 'm' }} variant="h3" tagOverride="span">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.teamsSolved)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ numTeamsSolved, challengeId }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }}>
          {!!numTeamsSolved ? (
          <Button variant="inline-link" iconName="status-info" iconAlign='right' data-testid={`challenge-stats-launcher__${challengeId}`} onClick={() => showSolvedChallengesStats(challengeId || "")}>
            {numTeamsSolved}
          </Button>) : <Box variant="h4" tagOverride="span" display='inline-block'>{numTeamsSolved}</Box>}
        </Box>
      ),
    },
    {
      id: 'incorrectAnswers',
      header: (
        <Box padding={{ left: 'm' }} variant="h3" tagOverride="span">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.incorrectAnswers)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ numIncorrectAnswers }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h4" tagOverride="span">
          {numIncorrectAnswers}
        </Box>
      ),
    },
    {
      id: 'requestedClues',
      header: (
        <Box padding={{ left: 'm' }} variant="h3" tagOverride="span">
          <TFunctionProvider>
            {(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.requestedClues)}
          </TFunctionProvider>
        </Box>
      ),
      cell: ({ numCluesRequested }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h4" tagOverride="span">
          {numCluesRequested}
        </Box>
      ),
    },
    {
      id: 'passRate',
      header: (
        <Box padding={{ left: 'm' }} variant="h3" tagOverride="span">
          <TFunctionProvider>{(t) => t(i18nKeys.jamDashboard.leaderboard.table.headers.passRate)}</TFunctionProvider>
        </Box>
      ),
      cell: ({ numCompletedTasks, numIncorrectAnswers }: ChallengeMetricsRow) => (
        <Box padding={{ left: 'm' }} variant="h4" tagOverride="span">
          {(getPassRate(numCompletedTasks || 0, numIncorrectAnswers || 0) * 100).toFixed(2)}%
        </Box>
      ),
    },
  ];
};
