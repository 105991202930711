/* eslint-disable @typescript-eslint/unbound-method */
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import {
  JamChallengeViewType,
  JamEventCollaborationOptions,
  JamEventStatus,
  JamEventTeamAssignmentType,
  Theme,
} from './JamCommon';
import { JamEventConfiguration } from './JamEventConfiguration';
import { JamEventTeam } from './JamEventTeam';
import { JamOnboardingVideoDetails } from './JamOnboardingVideo';
import { JamTeamChat } from './JamTeam';
import { JamSupportChat } from './JamSupportChat';
import { JamTeamChallengeAnswer } from './JamTeamChallengeAnswer';
import { JamLeaderboardEntry } from './JamLeaderboardEntry';
import { OfflineNotificationMessage } from './JamNotifications';
import { CampaignAttemptResponse } from './Campaign';

export class ParticipantEventPermissions {
  isFacilitator: boolean;
  isSupport: boolean;
}

export class JamEvent {
  name: string;
  startDate: string;
  endDate: string;
  status: JamEventStatus;
  type: string;
  audienceType: string;
  // map of {challengeId: labProvider}
  challengeLabProviders: { [challengeId: string]: string };
  labExtensionHours: number;
  maxTeamSize: number;
  challengeViewType: JamChallengeViewType;
  uiTheme: Theme;
  // challengeBoards: ChallengeBoard[] = [];
  title: string;
  classID: string;
  allowViewChallenges: boolean;
  test = false;
  collaborationOptions: JamEventCollaborationOptions;
  numChallenges = 0;
  numUnlockedChallenges = 0;
  teamAssignmentType: JamEventTeamAssignmentType = JamEventTeamAssignmentType.SELF_FORMING;
  formTeamsMinsBeforeEventStart = 0;
  autoUnlockChallengesTime: number | null = null;
  teamRenameEnabled = false;
  requiresSkillBuilderSubscription = false;
  onboardingVideo: JamOnboardingVideoDetails | null = null;
  /**
   * Whether the event is gamified.
   */
  isGamified: boolean;

  static fromPlainObject(obj: any): JamEvent {
    const event: JamEvent = Object.assign(new JamEvent(), obj || {}) as JamEvent;

    event.status = obj.status as unknown as JamEventStatus;

    if (event.onboardingVideo) {
      event.onboardingVideo = JamOnboardingVideoDetails.fromPlainObject(event.onboardingVideo);
    }
    event.challengeLabProviders = cloneDeep(event.challengeLabProviders || {});
    return event;
  }

  // noinspection JSUnusedGlobalSymbols
  getStatusName(): string {
    return JamEventStatus[this.status];
  }

  endedMoreThanXHoursAgo(hours: number): boolean {
    return moment(this.endDate).add(hours, 'hours').valueOf() < Date.now();
  }

  lessThanXMinutesRemaining(mins: number): boolean {
    return moment(this.endDate).subtract(mins, 'minutes').valueOf() < Date.now();
  }

  get ended(): boolean {
    return JamEventStatus[this.status] === JamEventStatus[JamEventStatus.ENDED];
  }

  get inProgress(): boolean {
    return JamEventStatus[this.status] === JamEventStatus[JamEventStatus.ONGOING];
  }

  get notStarted(): boolean {
    return JamEventStatus[this.status] === JamEventStatus[JamEventStatus.NOT_STARTED];
  }

  get isSingleParticipantEvent(): boolean {
    return this.audienceType === 'INDIVIDUAL';
  }

  get isCampaignEvent(): boolean {
    return this.type === 'CAMPAIGN_GROUP';
  }
}

export class JamParticipantEvent {
  isEnrolledToEvent: boolean;
  team: JamEventTeam | null;
  teamMembers: string[] = [];
  isEventCodeRequired: boolean;
  messages: OfflineNotificationMessage[];
  forceAgreeTermsAndConditions = false;
  termsAndConditionsUrl: string | null = null;
  canViewChallenges = false;
  skillProfileUpdateRequired: boolean;
  permissions: ParticipantEventPermissions;

  static fromPlainObject(jsonObject: any): JamParticipantEvent | null {
    if (!jsonObject) {
      return null;
    }

    const participantEvent = new JamParticipantEvent();
    Object.assign(participantEvent, jsonObject);

    participantEvent.team = participantEvent.team ? JamEventTeam.fromPlainObject(participantEvent.team) : null;
    participantEvent.messages = (participantEvent.messages || []).map(OfflineNotificationMessage.fromPlainObject);
    return participantEvent;
  }

  isOnboardingComplete(): boolean {
    // step 1: must be enrolled to the event
    if (!this.isEnrolledToEvent) {
      return false;
    }
    // step 2: must update skill profile if required
    if (this.skillProfileUpdateRequired) {
      return false;
    }
    // step 3: must be assigned to a team
    if (!this.team) {
      return false;
    }
    // onboarding is completed. see: https://tinyurl.com/yyz8vfta
    return true;
  }

  get requireAcceptTerms(): boolean {
    return !!this.termsAndConditionsUrl && this.forceAgreeTermsAndConditions;
  }
}

export class JamEventDataResponse {
  // TODO: update challenges type to real type once Challenges APIs are integrated
  challenges: any[];
  eventId: string;
  eventName: string;
  participantEvent: JamParticipantEvent;
  eventConfiguration: JamEventConfiguration;
  event: JamEvent | null = null;
  leaderboardEntries: JamLeaderboardEntry[] = [];
  teamChat: JamTeamChat | null = null;
  activeChats: JamSupportChat[] = [];
  unassignedSupportChats: JamSupportChat[] = [];
  teamChallengeAnswers: JamTeamChallengeAnswer[] = [];
  numTeams = 0;
  campaignAttempt: CampaignAttemptResponse

  static fromPlainObject(obj: any): JamEventDataResponse {
    const response: JamEventDataResponse = Object.assign(new JamEventDataResponse(), obj) as JamEventDataResponse;
    response.teamChallengeAnswers = (response.teamChallengeAnswers || []).map(JamTeamChallengeAnswer.fromPlainObject);
    response.leaderboardEntries = (response.leaderboardEntries || []).map(JamLeaderboardEntry.fromPlainObject);
    response.activeChats = (response.activeChats || []).map(JamSupportChat.fromPlainObject) as JamSupportChat[];
    response.unassignedSupportChats = (response.unassignedSupportChats || []).map(
      JamSupportChat.fromPlainObject
    ) as JamSupportChat[];
    if (response.participantEvent) {
      response.participantEvent = JamParticipantEvent.fromPlainObject(response.participantEvent) as JamParticipantEvent;
    }
    if (response.event) {
      response.event = JamEvent.fromPlainObject(response.event);
    }
    if (response.eventConfiguration) {
      response.eventConfiguration = JamEventConfiguration.fromPlainObject(response.eventConfiguration);
    }
    if (response.teamChat) {
      response.teamChat = JamTeamChat.fromPlainObject(response.teamChat);
    }
    return response;
  }
}

export interface JamEventTeamStats {
  teamScore: number;
  totalCluePenalty: number;
  totalAppliedCluePenalty: number;
  maxPossibleEventScore: number;
  maxPossibleRemainingScore: number;
  numCluesUsed: number;
  completedChallenges: number;
  totalChallenges: number;
}

export class JamMinimalParticipant {
  nickname: string;
  login: string;
  teamName: string;
  facilitator: boolean;
  excludeFromAutoAssignment: boolean;

  searchMatch = false;
}
