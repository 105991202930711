import React, { Dispatch, SetStateAction, useContext, useMemo, useRef, useState } from 'react';
import { useApi } from './api.context';
import {
  FacilitatorsAndNoTeamParticpants,
  JamFacilitatorTeamsAvailability,
  JamFacilitatorTeamsAvailabilityRes,
  JamParticpantsStats,
  TeamGoal,
} from '../types/JamFacilitator';
import { TeamProgressResponse } from '../types/JamTeamChallengeAnswer';
import { ParticipantInfo } from '../components/game/FacilitatorParticipants/molecules/FacilitatorParticipantContainer';
import { noop } from 'lodash';
import { TeamParticipants } from '../types/JamTeam';

export interface JamFacilitatorContextValue {
  isFetchingJamFacilitatorParticipants: boolean;
  jamFacilitatorTeamsAvailability?: JamFacilitatorTeamsAvailabilityRes;
  isFetchingJamTeamsAvailability: boolean;
  loadJamFacilitatorTeamsAvailabilityData: (eventName: string, page: number) => Promise<{ eventName: string }>;
  loadJamTeamDetailsAsAvailability: (eventName: string, teamId: string) => Promise<void>;
  loadJamFacilitatorParticipantsStats: (eventName: string) => Promise<void>;
  loadJamFacilitatorNoTeamParticipants: (eventName: string) => Promise<void>;
  jamTeamProgress?: TeamProgressResponse;
  isFetchingJamTeamProgress: boolean;
  loadTeamProgressData: (eventName: string, teamName: string) => Promise<void>;
  restartChallenge: (eventName: string, challengeId: string, teamName: string) => Promise<void>;
  startChat: (eventName: string, userId: string) => Promise<void>;
  changeTeamName: (eventName: string, alias: string, teamName: string) => Promise<void>;
  lockTeamName: (eventName: string, teamName: string) => Promise<void>;
  unlockTeamName: (eventName: string, teamName: string) => Promise<void>;
  changeTeamPassword: (eventName: string, teamName: string, password: string) => Promise<void>;
  changeTeam: (eventName: string, userId: string, teamName: string) => Promise<void>;
  removeFromTeam: (eventName: string, userId: string, isInactive: boolean) => Promise<void>;
  makeTeamOwner: (eventName: string, teamName: string, userId: string) => Promise<void>;
  facilitators: ParticipantInfo[];
  notAssignedToTeam: ParticipantInfo[];
  teams: JamFacilitatorTeamsAvailability[];
  loading: boolean;
  pageSize: number;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  setSearchTerm: (term: string) => void;
  facilitatorParticipantsStats?: JamParticpantsStats;
  pageTokens: Record<number, string>;
  facilitatorsNoTeamParticpants?: FacilitatorsAndNoTeamParticpants;
  loadParticpiantsByTeam: (eventName: string, teamId: string) => Promise<void>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  updateTeamInfoFlag: (flag: boolean) => void;
}

const JamFacilitatorContext = React.createContext<JamFacilitatorContextValue>({
  isFetchingJamFacilitatorParticipants: true,
  jamFacilitatorTeamsAvailability: undefined,
  isFetchingJamTeamsAvailability: true,
  loadJamFacilitatorTeamsAvailabilityData: () => new Promise(noop),
  loadJamTeamDetailsAsAvailability: () => new Promise(noop),
  loadJamFacilitatorParticipantsStats: () => new Promise(noop),
  loadJamFacilitatorNoTeamParticipants: () => new Promise(noop),
  jamTeamProgress: undefined,
  isFetchingJamTeamProgress: false,
  loadTeamProgressData: () => Promise.resolve(),
  restartChallenge: () => new Promise(noop),
  startChat: () => new Promise(noop),
  changeTeamName: () => new Promise(noop),
  lockTeamName: () => new Promise(noop),
  unlockTeamName: () => new Promise(noop),
  changeTeamPassword: () => new Promise(noop),
  changeTeam: () => new Promise(noop),
  removeFromTeam: () => new Promise(noop),
  makeTeamOwner: () => new Promise(noop),
  facilitators: [],
  notAssignedToTeam: [],
  teams: [],
  loading: false,
  pageSize: 20,
  setLoading: noop,
  setPageSize: noop,
  setSearchTerm: noop,
  facilitatorParticipantsStats: undefined,
  pageTokens: { 1: '' },
  facilitatorsNoTeamParticpants: undefined,
  loadParticpiantsByTeam: () => new Promise(noop),
  currentPage: 1,
  setCurrentPage: noop,
  updateTeamInfoFlag: noop,
});

const JamFacilitatorProvider: React.FC = ({ children }) => {
  const [isFetchingJamFacilitatorParticipants] = useState(true);
  const [jamFacilitatorTeamsAvailability, setFacilitatorTeamsAvailability] =
    useState<JamFacilitatorTeamsAvailabilityRes>();
  const [isFetchingJamTeamsAvailability, setFetchingJamTeamsAvailability] = useState(true);
  const [isFetchingJamTeamProgress, setFetchingJamTeamProgress] = useState(false);
  const [jamTeamProgress, setJamTeamProgress] = useState<TeamProgressResponse>();
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [loading, setLoading] = React.useState(false);
  const [, setSearchTerm] = React.useState('');
  const [facilitatorParticipantsStats, setFacilitatorParticipantsStats] = React.useState<JamParticpantsStats>();
  const [facilitatorsNoTeamParticpants, setFacilitatorsNoTeamParticpants] =
    React.useState<FacilitatorsAndNoTeamParticpants>();
  const [pageTokens, setPageTokens] = useState<Record<number, string>>({ 1: '' });
  const [teamParticipants, setTeamParticipants] = useState<TeamParticipants[]>([]);
  const pageTokensRef = useRef(pageTokens);
  const [currentPage, setCurrentPage] = useState(1);
  const isTeamInfoPage = useRef(false);

  pageTokensRef.current = useMemo(() => pageTokens, [pageTokens]);

  const facilitators = facilitatorsNoTeamParticpants?.facilitators || [];

  const notAssignedToTeam = facilitatorsNoTeamParticpants?.noTeamParticipants || [];

  const teams = useMemo(() => {
    return (jamFacilitatorTeamsAvailability?.teams || []).map((team) => ({
      ...team,
      members: teamParticipants.filter((p) => p.teamId === team.name) as ParticipantInfo[],
    }));
  }, [teamParticipants, jamFacilitatorTeamsAvailability]);

  const { jamFacilitatorApi, jamTeamApi } = useApi();

  const updateParticipantList = (participants: TeamParticipants[], teamId: string) => {
    const allParticipants = teamParticipants.filter(({ teamId: teamName }) => teamId !== teamName);

    // TODO: maybe update the type of team members itself?
    setTeamParticipants([
      ...allParticipants,
      ...participants.map((participant) => ({
        ...participant,
        teamId,
        login: participant.participantId,
        skillRank:
          participant.skillRating ||
          facilitatorsNoTeamParticpants?.participantSkills[participant.participantId] ||
          undefined,
      })),
    ]);
  };

  const loadJamFacilitatorParticipantsStats = async (eventName: string) => {
    try {
      const response: JamParticpantsStats = await jamFacilitatorApi.getJamFacilitatorParticipantStats(eventName);
      setFacilitatorParticipantsStats(response);
    } catch (e: any) {
      throw e;
    }
  };

  const loadJamFacilitatorNoTeamParticipants = async (eventName: string) => {
    try {
      const response: FacilitatorsAndNoTeamParticpants =
        await jamFacilitatorApi.getJamFacilitatorsAndNoTeamParticipants(eventName);
      response.facilitators = response.facilitators?.map((facilitator) => ({
        ...facilitator,
        skillRank: response?.participantSkills ? response.participantSkills[facilitator.login]: undefined,
      }));
      response.noTeamParticipants = response.noTeamParticipants?.map((noTeamParticipant) => ({
        ...noTeamParticipant,
        skillRank: response?.participantSkills ? response.participantSkills[noTeamParticipant.login]: undefined,
      }));
      setFacilitatorsNoTeamParticpants(response);
    } catch (e: any) {
      throw e;
    }
  };

  const loadJamTeamDetailsAsAvailability = async (eventName: string, teamId: string) => {
    const teamDetails = await jamTeamApi.getTeamDetails(eventName, teamId);
    if (!teamDetails) {
      return;
    }
    const { team, participants } = teamDetails;
    setFacilitatorTeamsAvailability({
      teams: [
        {
          capacity: team.capacity,
          cheatingEvidence: [],
          displayName: team.teamName,
          facilitatorOnly: team.facilitatorOnly,
          goal: team.goal as unknown as TeamGoal,
          name: team.teamId,
          numSpotsFilled: team.numSpotsFilled,
          owner: team.teamOwner,
          passwordProtected: team.passwordRequired,
          renameEnabled: team.canRename,
          suspectedCheater: false,
          teamId: team.teamId,
        },
      ],
      lastToken: '',
    });
    updateParticipantList(participants, team.teamId);
  };

  const loadJamFacilitatorTeamsAvailabilityData = async (
    eventName: string,
    page = 1
  ): Promise<{ eventName: string }> => {
    try {
      const response = await jamFacilitatorApi.getJamFacilitatorTeamsAvailability(
        eventName,
        pageSize,
        pageTokensRef.current[page]
      );
      setFacilitatorTeamsAvailability(response);
      if (response.lastToken) {
        setPageTokens((tokens) => ({
          ...tokens,
          [page + 1]: response.lastToken,
        }));
      }
      setFetchingJamTeamsAvailability(false);
      return { eventName };
    } catch (e: any) {
      setFetchingJamTeamsAvailability(false);
      setLoading(false);
      // if API failed catch here.
      throw e;
    }
  };
  const loadParticpiantsByTeam = async (eventName: string, teamId: string) => {
    try {
      const response = await jamTeamApi.getTeamDetails(eventName, teamId);
      updateParticipantList(response.participants, response.team.teamId);
    } catch (e) {
      // error handled in API
    }
  };

  const loadTeamProgressData = async (eventName: string, teamName: string): Promise<void> => {
    if (isFetchingJamTeamProgress) return;
    setFetchingJamTeamProgress(true);
    try {
      const response = await jamFacilitatorApi.getJamFacilitatorParticipantTeamProgress(eventName, teamName);
      setJamTeamProgress(response);
      setFetchingJamTeamProgress(false);
    } catch (e: any) {
      setFetchingJamTeamProgress(false);
      // if API failed catch here.
      throw e;
    }
  };

  const refreshAvailabilityData = (eventName: string, teamId?: string) => {
    if (isTeamInfoPage.current) {
      if (!teamId) {
        return;
      }
      void loadJamTeamDetailsAsAvailability(eventName, teamId);
    } else {
      void loadJamFacilitatorTeamsAvailabilityData(eventName, currentPage);
    }
  };

  const restartChallenge = async (eventName: string, challengeId: string, teamName: string): Promise<void> => {
    try {
      await jamFacilitatorApi.restartChallenge(eventName, challengeId, teamName);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const startChat = async (eventName: string, userId: string): Promise<void> => {
    try {
      await jamFacilitatorApi.startChat(eventName, userId);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const changeTeamName = async (eventName: string, alias: string, teamName: string): Promise<void> => {
    try {
      await jamFacilitatorApi.changeTeamName(eventName, alias, teamName);
      refreshAvailabilityData(eventName, alias);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const makeTeamOwner = async (eventName: string, teamName: string, userId: string): Promise<void> => {
    try {
      // const teamId = teamParticipants.find(({ login }) => login === userId)?.teamId;
      await jamFacilitatorApi.changeTeamOwner(eventName, teamName, userId);
      refreshAvailabilityData(eventName, teamName);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const lockTeamName = async (eventName: string, teamName: string): Promise<void> => {
    try {
      await jamFacilitatorApi.lockTeamName(eventName, teamName);
      refreshAvailabilityData(eventName, teamName);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const unlockTeamName = async (eventName: string, teamName: string): Promise<void> => {
    try {
      await jamFacilitatorApi.unlockTeamName(eventName, teamName);
      refreshAvailabilityData(eventName, teamName);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const changeTeamPassword = async (eventName: string, teamName: string, password: string): Promise<void> => {
    try {
      await jamFacilitatorApi.changeTeamPassword(eventName, teamName, password);
      refreshAvailabilityData(eventName, teamName);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const changeTeam = async (eventName: string, userId: string, teamName: string): Promise<void> => {
    try {
      const teamId = teamParticipants.find(({ login }) => login === userId)?.teamId as string;
      await jamFacilitatorApi.changeTeam(eventName, userId, teamName);

      if (teamId) {
        void loadParticpiantsByTeam(eventName, teamId);
      } else {
        // if user assigned a team from not assigned to team
        void loadJamFacilitatorNoTeamParticipants(eventName);
      }
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const removeFromTeam = async (eventName: string, userId: string, isInactive: boolean): Promise<void> => {
    try {
      const teamId = teamParticipants.find(({ login }) => login === userId)?.teamId as string;
      await jamFacilitatorApi.removeFromTeam(eventName, userId, isInactive);
      if (teamId) {
        await loadParticpiantsByTeam(eventName, teamId);
        void loadJamFacilitatorNoTeamParticipants(eventName);
      }
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const updateTeamInfoFlag = (flag: boolean) => {
    isTeamInfoPage.current = flag;
  };

  const data: JamFacilitatorContextValue = {
    isFetchingJamFacilitatorParticipants,
    jamFacilitatorTeamsAvailability,
    isFetchingJamTeamsAvailability,
    loadJamFacilitatorTeamsAvailabilityData,
    loadJamTeamDetailsAsAvailability,
    loadJamFacilitatorParticipantsStats,
    jamTeamProgress,
    isFetchingJamTeamProgress,
    loadTeamProgressData,
    restartChallenge,
    startChat,
    changeTeamName,
    lockTeamName,
    unlockTeamName,
    changeTeamPassword,
    changeTeam,
    removeFromTeam,
    makeTeamOwner,
    facilitators,
    notAssignedToTeam,
    teams,
    loading,
    setLoading,
    pageSize,
    setPageSize,
    setSearchTerm,
    facilitatorParticipantsStats,
    pageTokens,
    facilitatorsNoTeamParticpants,
    loadJamFacilitatorNoTeamParticipants,
    loadParticpiantsByTeam,
    currentPage,
    setCurrentPage,
    updateTeamInfoFlag,
  };
  return <JamFacilitatorContext.Provider value={data}>{children}</JamFacilitatorContext.Provider>;
};

const useJamFacilitator = () => {
  const context = useContext(JamFacilitatorContext);
  if (context === undefined) {
    throw new Error('useJamFacilitator can only be used inside JamFacilitatorProvider');
  }
  return context;
};

export { JamFacilitatorProvider, useJamFacilitator };
