/* eslint-disable @typescript-eslint/unbound-method */
import moment from 'moment-timezone';
import { JamClue } from './JamChallenges';
import { LeaderboardEntry } from './JamReport';
import { LabSignInDetails } from './LabModels';

export class TeamChallengeTaskProgressMin {
  taskId: string;
  taskNumber: number;
  locked = true;
  started = false;
  completed = false;
  pointsPossible = 0;

  /**
   * Reflects the points received from completing the task before deductions
   */
  pointsEarned = 0;

  /**
   * Reflects the points received from completing the task after deductions
   */
  score = 0;

  cluePenalties: CluePenalty[] = [];
  usedClues: JamClue[] = [];

  static fromPlainObject(obj: any): TeamChallengeTaskProgressMin {
    const taskProgress: TeamChallengeTaskProgressMin = Object.assign(
      new TeamChallengeTaskProgressMin(),
      obj
    ) as TeamChallengeTaskProgressMin;
    taskProgress.cluePenalties = (taskProgress.cluePenalties || []).map(CluePenalty.fromPlainObject);
    taskProgress.cluePenalties.sort((a, b) => a.order - b.order);
    taskProgress.usedClues = (taskProgress.usedClues || []).map(JamClue.fromPlainObject);
    taskProgress.usedClues.sort((a, b) => a.order - b.order);
    return taskProgress;
  }

  getHighestClueUsed(): number {
    return (this.cluePenalties || []).reduce((acc, cluePenalty) => Math.max(acc, cluePenalty.order), 0);
  }

  getNextClueOrder(numCluesForTask: number): number {
    return Math.min(numCluesForTask, this.getHighestClueUsed() + 1);
  }

  isClueUsed(clueOrder: number): boolean {
    const cluePenalty = (this.cluePenalties || []).find((cp) => cp.order === clueOrder);
    return cluePenalty != null;
  }

  isClueRefunded(clueOrder: number): boolean {
    const cluePenalty = (this.cluePenalties || []).find((cp) => cp.order === clueOrder);
    if (cluePenalty == null) return false;
    return cluePenalty.refunded;
  }

  getUsedClue(clueOrder: number): JamClue {
    return (this.usedClues || []).find((clue) => clue.order === clueOrder) as JamClue;
  }

  getNumRefundedClues(): number {
    return (this.cluePenalties || []).filter((cluePenalty) => cluePenalty.refunded).length;
  }

  getTotalCluePenalty(): number {
    return (this.cluePenalties || [])
      .filter((cluePenalty) => !cluePenalty.refunded)
      .reduce((sum, cluePenalty) => {
        return Math.max(0, sum + cluePenalty.penalty);
      }, 0);
  }

  getTotalAppliedCluePenalty(): number {
    return this.completed ? this.getTotalCluePenalty() : 0;
  }
}

export class JamTeamChallengeAnswer {
  eventName: string;
  challengeId: string;
  pointsPossible = 0;
  pointsEarned = 0;
  score = 0;
  started = false;
  completed = false;
  taskProgress: TeamChallengeTaskProgressMin[] = [];
  remainingRestarts = 0;
  hasAwsResources = false;
  hasExternalResources = false;

  static fromPlainObject(obj: any): JamTeamChallengeAnswer {
    const tca: JamTeamChallengeAnswer = Object.assign(new JamTeamChallengeAnswer(), obj) as JamTeamChallengeAnswer;
    tca.taskProgress = tca.taskProgress.map(TeamChallengeTaskProgressMin.fromPlainObject);
    tca.taskProgress.sort((a, b) => a.taskNumber - b.taskNumber);
    return tca;
  }

  getTaskProgress(taskId: string): TeamChallengeTaskProgressMin {
    return this.taskProgress.find((taskProgress) => taskProgress.taskId === taskId) as TeamChallengeTaskProgressMin;
  }

  getUsedClue(taskId: string, clueOrder: number): JamClue | null {
    const taskProgress: TeamChallengeTaskProgressMin = this.getTaskProgress(taskId);
    if (taskProgress) {
      return taskProgress.getUsedClue(clueOrder);
    }
    return null;
  }

  getNumCluesUsed(): number {
    return (this.taskProgress || []).reduce((sum, task) => {
      return Math.max(0, sum + (task.usedClues || []).length);
    }, 0);
  }

  getNumRefundedClues(): number {
    return (this.taskProgress || []).reduce((sum, task) => {
      return Math.max(0, sum + task.getNumRefundedClues());
    }, 0);
  }

  getTotalCluePenalty(): number {
    return (this.taskProgress || []).reduce((sum, task) => {
      return Math.max(0, sum + task.getTotalCluePenalty());
    }, 0);
  }

  getTotalAppliedCluePenalty(): number {
    return (this.taskProgress || []).reduce((sum, task) => {
      return Math.max(0, sum + task.getTotalAppliedCluePenalty());
    }, 0);
  }
}

export interface ChallengeProgressMap {
  [challengeId: string]: JamTeamChallengeAnswer;
}

export class CluePenalty {
  order = 0;
  penalty = 0;
  refunded = false;

  static fromPlainObject(obj: any): CluePenalty {
    return Object.assign(new CluePenalty(), obj) as CluePenalty;
  }
}

export class TeamChallengeProgress {
  eventName: string;
  teamName: string;
  challengeId: string;
  challengeTitle: string;
  pointsPossible: number;
  pointsEarned: number;
  numRestartsUsed = 0;
  lastActionBy: string;
  lastUpdatedTime: number;
  startedBy: string;
  startTime: number;
  taskProgress: TeamChallengeTaskProgress[] = [];
  completed: boolean;
  teamSignInDetails: LabSignInDetails | null;
  adminSignInDetails: LabSignInDetails | null;

  static fromPlainObject(obj: any): TeamChallengeProgress {
    const progress: TeamChallengeProgress = Object.assign(new TeamChallengeProgress(), obj) as TeamChallengeProgress;
    progress.taskProgress = (progress.taskProgress || []).map(TeamChallengeTaskProgress.fromPlainObject);
    progress.taskProgress.sort((a, b) => a.taskNumber - b.taskNumber);
    return progress;
  }

  get completedTasks(): TeamChallengeTaskProgress[] {
    return (this.taskProgress || []).filter((task) => task.completedTime != null);
  }

  get numTasks(): number {
    return (this.taskProgress || []).length;
  }

  get numCompletedTasks(): number {
    return this.completedTasks.length;
  }

  get allTasksCompleted(): boolean {
    return this.numCompletedTasks === this.numTasks;
  }

  getMostRecentCompletionTime(): number | null {
    const completedTasks = this.completedTasks;
    if (completedTasks.length < 1) return null;
    return completedTasks.reduce((time, task) => {
      return Math.max(time, task.completedTimeSafe || 0);
    }, 0);
  }

  getNumCluesUsed(): number {
    return (this.taskProgress || []).reduce((sum, task) => {
      return Math.max(0, sum + (task.cluePenalties || []).length);
    }, 0);
  }

  getSubmittedAnswers(): string[] {
    return (this.taskProgress || []).reduce((answers, task) => {
      return [...answers, ...(task.submittedAnswers || [])];
    }, [] as string[]);
  }
}

export class TeamProgressResponse {
  teamChallengeAnswers: TeamChallengeProgress[] = [];
  leaderBoardEntry: LeaderboardEntry;

  static fromPlainObject(obj: any): TeamProgressResponse {
    const response: TeamProgressResponse = Object.assign(new TeamProgressResponse(), obj) as TeamProgressResponse;
    response.teamChallengeAnswers = response.teamChallengeAnswers.map(TeamChallengeProgress.fromPlainObject);
    // response.leaderBoardEntry = LeaderboardEntry.fromPlainObject(response.leaderBoardEntry);
    return response;
  }

  //   /**
  //    * Whether any credentials on this team progress object expire soon.
  //    */
  //   public anyCredentialsExpireSoon(): boolean {
  //     if (!this.teamChallengeAnswers) {
  //       return false;
  //     }

  //     return this.teamChallengeAnswers.some((tca) =>
  //       anySignInDetailsExpireSoon(tca.teamSignInDetails, tca.adminSignInDetails)
  //     );
  //   }
}

export class TeamChallengeTaskProgress {
  taskId: string;
  taskNumber: number;
  pointsPossible: number;
  pointsEarned: number;
  cluePenalties: CluePenalty[] = [];
  startTime: number | null = null;
  startedBy: string | null = null;
  completedTime: number | string | null = null;
  completedBy: string | null = null;
  lastUpdatedTime: number | null = null;
  lastActionBy: string | null = null;
  mostRecentScoreIncreaseTime: number | null = null;
  mostRecentScoreChangeTime: number | null = null;
  mostRecentSubmittedAnswer: string | null = null;
  submittedAnswers: string[] = [];

  static fromPlainObject(obj: any): TeamChallengeTaskProgress {
    const taskProgress: TeamChallengeTaskProgress = Object.assign(
      new TeamChallengeTaskProgress(),
      obj
    ) as TeamChallengeTaskProgress;
    taskProgress.cluePenalties = (taskProgress.cluePenalties || []).map(CluePenalty.fromPlainObject);
    taskProgress.cluePenalties.sort((a, b) => a.order - b.order);
    taskProgress.submittedAnswers = [...(taskProgress.submittedAnswers || [])];
    return taskProgress;
  }

  get completedTimeSafe(): number | null {
    if (!this.completedTime) {
      return null;
    }
    return typeof this.completedTime === 'string' ? moment(this.completedTime).valueOf() : this.completedTime;
  }
}

export class SupportChatTeamChallengeAnswer {
  eventName: string;
  challengeId: string;
  teamName: string;
  numRestartsUsed = 0;
  pointsPossible = 0;
  taskProgress: TeamChallengeTaskProgress[] = [];
  startTime: number | null = null;
  startedBy: string | null = null;
  lastUpdatedTime: number;
  lastActionBy: string | null = null;

  static fromPlainObject(obj: any): SupportChatTeamChallengeAnswer {
    const tca: SupportChatTeamChallengeAnswer = Object.assign(
      new SupportChatTeamChallengeAnswer(),
      obj
    ) as SupportChatTeamChallengeAnswer;
    tca.taskProgress = tca.taskProgress.map(TeamChallengeTaskProgress.fromPlainObject);
    tca.taskProgress.sort((a, b) => a.taskNumber - b.taskNumber);
    return tca;
  }

  getTaskProgress(taskId: string): TeamChallengeTaskProgress {
    return this.taskProgress.find((taskProgress) => taskProgress.taskId === taskId) as TeamChallengeTaskProgress;
  }

  get completed(): boolean {
    return this.taskProgress.every((task) => task.completedTime != null);
  }
}
