import {
  InputValidationHelper,
  JamChallengeTaskAnswerFields,
  JamChallengeTaskValidationFields,
} from '../types/JamChallenges';
import { safeString } from './string.utils';

const validateField = (
  validatorHelperByField: Map<JamChallengeTaskValidationFields, InputValidationHelper>,
  errorSetterByField: Map<JamChallengeTaskValidationFields, (error: string) => void>,
  field: JamChallengeTaskValidationFields,
  setError = true
) => {
  const validation = validatorHelperByField.get(field);
  if (!validation) {
    throw new Error('Validation logic does not exist!');
  }
  const isValid = validation.isValid();
  const error = validation.checkErrors();
  if (setError) {
    const setErrorFn = errorSetterByField.get(field) || (() => {
       // do nothing
    });
    setErrorFn(error);
  }
  // preProdLogger('Validated', field, 'isValid:', isValid, 'error:', error);
  return isValid;
};

const validateSection = (
  validatorHelperByField: Map<JamChallengeTaskValidationFields, InputValidationHelper>,
  errorSetterByField: Map<JamChallengeTaskValidationFields, (error: string) => void>,
  setErrors = false
): boolean => {
  const fieldIsValid: boolean[] = [];
  const fields = Array.from(errorSetterByField.keys());
  for (const key of fields) {
    fieldIsValid.push(validateField(validatorHelperByField, errorSetterByField, key, setErrors));
  }
  return fieldIsValid.reduce((prev, next) => prev && next);
};

const validateAnswer = (name: string, errorText: string) => {
  const input = safeString(name);
  const isValid = !!input;

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const jamChallengeTaskAnswerValidator: (
  answer: string,
  answerErrorText: string,
  errorSetterByField: Map<JamChallengeTaskAnswerFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: JamChallengeTaskAnswerFields, setError?: boolean) => boolean;
} = (
  answer: string,
  answerErrorText: string,
  errorSetterByField: Map<JamChallengeTaskAnswerFields, (error: string) => void>
) => {
  const validatorHelperByField = new Map<JamChallengeTaskAnswerFields, InputValidationHelper>([
    [JamChallengeTaskAnswerFields.ANSWER, validateAnswer(answer, answerErrorText)],
  ]);

  const isValidField = (field: JamChallengeTaskAnswerFields, setError = true) => {
    return validateField(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};
