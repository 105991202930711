/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useRef } from 'react';
import { useJamLeaderboard } from '../store/jam-leaderboard.context';
import { LEADERBOARD_MOUSE_TIMEOUT } from '../constants/common';

export const useMouseActivity = () => {
  const { isPresenterMode, updateMouseActivity } = useJamLeaderboard();
  const lastMouseMoved = useRef<number>();
  const mouseTimeout = useRef<number | undefined >();

  useEffect(() => {
    if (!isPresenterMode) {
      return;
    }
    lastMouseMoved.current = new Date().getTime();
    const handleWindowMouseMove = () => {
      clearTimeout(mouseTimeout.current);
      lastMouseMoved.current = new Date().getTime();
      updateMouseActivity(true);

      mouseTimeout.current = setTimeout(() => {
        const diff = new Date().getTime() - (lastMouseMoved.current || 0);
        if (diff > LEADERBOARD_MOUSE_TIMEOUT) {
          updateMouseActivity(false);
        }
      }, LEADERBOARD_MOUSE_TIMEOUT);
    };

    window.addEventListener('mousemove', handleWindowMouseMove);

    return () => {
      clearTimeout(mouseTimeout.current);
      updateMouseActivity(true);
      window.removeEventListener('mousemove', handleWindowMouseMove);
    };
  }, [isPresenterMode]);
};
