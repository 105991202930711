import React, { useState, useContext } from 'react';
import { noop } from 'lodash';

export interface UtilityContextValue {
  isLoading: boolean;
  toggleLoader: () => void;
  showLoader: () => void;
  hideLoader: () => void;
}

const UtilityContext = React.createContext<UtilityContextValue>({
  isLoading: false,
  toggleLoader: noop,
  showLoader: noop,
  hideLoader: noop,
});

const UtilityProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoader = () => {
    setIsLoading((prev) => !prev);
  };
  const showLoader = () => {
    setIsLoading(true);
  };
  const hideLoader = () => {
    setIsLoading(false);
  };

  const data: UtilityContextValue = {
    isLoading,
    toggleLoader,
    showLoader,
    hideLoader,
  };

  return <UtilityContext.Provider value={data}>{children}</UtilityContext.Provider>;
};

const useUtility = () => {
  const context = useContext(UtilityContext);
  if (context === undefined) {
    throw new Error('useUtility can only be used inside UtilityProvider');
  }
  return context;
};

export { UtilityProvider, useUtility };
