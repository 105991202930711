import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
// import { JamProfile } from '../types/JamProfile';
import { GAME_API_PREFIX } from '../constants/api';
import { i18nKeys } from '../utils/i18n.utils';
import { JamProfile, SkillProfile, Participant } from '../types/JamProfile';

export class JamProfileAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  // Api To get the Jam profile
  public async getProfile(): Promise<JamProfile> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/profile`,
      responseMapper: (res) => res.profile as JamProfile,
      failMessage: this.t(i18nKeys.errors.requestFailed.getUserAccountInfo),
    })) as Promise<JamProfile>;
  }

  // Api To update user language
  public async updateLanguage(lan: string | undefined): Promise<void> {
    return (await this.apiClient.post({
      path: `${GAME_API_PREFIX}/participant/preferences/language/${lan}`,
      body: {},
      failMessage: this.t(i18nKeys.errors.requestFailed.getUserAccountInfo),
    })) as Promise<void>;
  }

  // Api to Update User Profile
  public async updateProfile(body: JamProfile): Promise<any> {
    return (await this.apiClient.post({
      path: `${GAME_API_PREFIX}/participant/profile`,
      body: { profile: body },
      failMessage: this.t(i18nKeys.errors.requestFailed.updateUser),
      successMessage: this.t(i18nKeys.JamProfile.jamSkillProfile.api.success),
    })) as Promise<any>;
  }

  // Api to get Skill Profile
  public async getSkillProfile(): Promise<SkillProfile> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/skill`,
      responseMapper: (res) => res?.skillRating as SkillProfile,
      failMessage: this.t(i18nKeys.errors.requestFailed.getUserAccountInfo),
    })) as Promise<SkillProfile>;
  }

  // APi to Update Skill Profile
  public async updateSkillProfile(body: SkillProfile): Promise<any> {
    return (await this.apiClient.post({
      path: `${GAME_API_PREFIX}/participant/skill/update`,
      body: { skillProfile: body },
      failMessage: this.t(i18nKeys.errors.requestFailed.updateSkillProfile),
      successMessage: this.t(i18nKeys.JamProfile.jamSkillProfile.api.success),
    })) as Promise<any>;
  }

  // Api to get Paricipant details like event prizes
  public async getPricipantDashboard(): Promise<Participant> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/dashboard`,
      responseMapper: (res) => res as Participant,
      failMessage: this.t(i18nKeys.errors.requestFailed.getUserAccountInfo),
    })) as Promise<Participant>;
  }

  // API for  Delete User Permanently
  public async deleteParicipant(): Promise<void> {
    return (await this.apiClient.delete({
      path: `${GAME_API_PREFIX}/participant/account`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.deleteUser),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.deleteUser),
    })) as Promise<void>;
  }
}
