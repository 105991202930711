export const roundFloat = (floatRating: number, precision = 2) => {
  const multiplier = Math.pow(10, precision);
  return Math.round(floatRating * multiplier) / multiplier;
};

export const toOrdinal = (num: number): string => {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) {
    return `${num}st`;
  }
  if (j === 2 && k !== 12) {
    return `${num}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${num}rd`;
  }
  return `${num}th`;
};
