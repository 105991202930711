import * as React from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react';

interface ColumnsProps {
  columns: 1 | 2 | 3 | 4 | undefined;
  borders?: 'none' | 'vertical' | 'horizontal' | 'all';
  variant?: 'text-grid' | 'default';
}

export const Columns: React.FC<ColumnsProps> = ({ columns, borders, variant, children }) => {
  return (
    <ColumnLayout
      columns={columns}
      borders={borders || 'none'}
      variant={variant || 'text-grid'}
      data-awsui-column-layout-root="true">
      {children}
    </ColumnLayout>
  );
};
