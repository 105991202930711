import React from 'react';
import {
  SpaceBetween,
  FormField,
  Input,
  TextContent,
  InputProps,
  Container,
  Header,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { i18nKeys } from '@/src/utils/i18n.utils';

// Localization keys
const { title: tipsTitle, tips, defaultEventName, titleString } = i18nKeys.eventTemplates.step1;
const { tip1, tip2, tip3, tip4, tip5 } = tips;

const TIPS = [
  { title: tip1.title, desctipion: tip1.description },
  { title: tip2.title, desctipion: tip2.description },
  { title: tip3.title, desctipion: tip3.description },
  { title: tip4.title, desctipion: tip4.description },
  { title: tip5.title, desctipion: tip5.description },
];

export interface IEventNameProps {
  title?: string;
  error?: string;
  onChange: InputProps['onChange'];
  onBlur: InputProps['onBlur'];
}

export const EventName: React.FC<IEventNameProps> = ({ title = '', error = '', onChange, onBlur }) => {
  const { t } = useTranslation();

  return (
    <Container header={<Header variant="h2">{title || t(defaultEventName)}</Header>}>
      <SpaceBetween size="s">
        <FormField
          label={t(titleString)}
          errorText={error}
          constraintText={`${t(titleString)} ${t(i18nKeys.generic.lengthLimit4to250)}`}
          i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}}>
          <Input placeholder={t(defaultEventName)} value={title} onChange={onChange} onBlur={onBlur} />
        </FormField>

        <SpaceBetween size="l">
          <TextContent>
            <div className="text-darkgray">
              <p>
                <strong>{t(tipsTitle)}</strong>
              </p>
              <ol>
                {TIPS.map((tip, index) => (
                  <li key={index}>
                    <strong>{t(tip.title)}</strong> {t(tip.desctipion)}
                  </li>
                ))}
              </ol>
            </div>
          </TextContent>
        </SpaceBetween>
      </SpaceBetween>
    </Container>
  );
};
