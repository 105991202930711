/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  ExpandableSection,
  Header,
  Link,
  Modal,
  SpaceBetween,
  Spinner,
  Table,
  Textarea,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { KeyValue } from '../common/KeyValue';
import LabShutoffStatusBadge from '../common/LabShutoffStatusBadge';
import { useUtility } from '@/src/store/utility.provider';
import { useApi } from '@/src/store/api.context';
import { AwsRegions, Region } from '@/src/constants/shared';
import { Challenge, ChallengeConfiguration, ChallengeDescriptor } from '@/src/types/Challenge';
import { LAB_PROVIDER_LABELS, LabProvider } from '@/src/types/LabProvider';
import moment from 'moment';
import { useChallenges } from '@/src/store/challenge.context';
import { DateRangeFilter } from '@/src/types/common';
import { useEvents } from '@/src/store/events.context';
import { CandidateStatusMap, LabShutoffAction, LabShutoffCandidate, LabShutoffStatus } from '@/src/types/LabShutoff';
import { eventRoute } from '@/src/routes';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Event } from '@/src/types/Event';

type CandidateOptions = LabProvider | Region | Event | ChallengeDescriptor;

enum LabActions {
  turnon = 'TURNON_DEP',
  shutoff = 'SHUTOFF_DEP',
}

const LabShutoffs = () => {
  const { t } = useTranslation();
  const { showLoader, hideLoader } = useUtility();
  const { getChallenges, challenges: allChallenges } = useChallenges();
  const { loadEventsByDateRange, events: allEvents } = useEvents();
  const { challengesApi, labshutoffAPI } = useApi();
  const [challengeConfig, setChallengeConfig] = useState<ChallengeConfiguration>();
  const [, setAuditTrail] = useState<LabShutoffAction[]>([]);
  const [shutoffStatuses, setShutoffStatuses] = useState<CandidateStatusMap>({});
  const [shutoffCandidate, setShutoffCandidate] = useState<LabShutoffCandidate | undefined>();
  const [turnonCandidate, setTurnonCandidate] = useState<LabShutoffCandidate | undefined>();
  const [modalString, setModalString] = useState('');
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reason, setReason] = useState('');

  const shutoffAction = [
    { id: LabActions.shutoff, text: t(i18nKeys.labDashboard.shutoffs.actions.shutOffLabDeployment) },
  ];
  const turnonAction = [
    { id: LabActions.turnon, text: t(i18nKeys.labDashboard.shutoffs.actions.shutOffLabDeployment) },
  ];

  const dateFilter = useMemo(() => {
    const defaultDateStart = moment().subtract('30', 'days').format('YYYY-MM-DD');
    return {
      start: defaultDateStart,
      end: null,
    };
  }, []);

  const supportedLabRegions = useMemo(() => {
    if (!challengeConfig) {
      return [];
    }

    return challengeConfig.supportedLabRegions
      .map((regionId) => AwsRegions.ALL_AWS_REGIONS_BY_ID[regionId])
      .sort((a, b) => (a.id < b.id ? -1 : 1));
  }, [challengeConfig, shutoffStatuses]);

  const labProviders = useMemo(() => Object.values(LabProvider), []);

  const labProvidersToLabels = useMemo(() => {
    const labels: Map<LabProvider, string> = new Map();
    // initialize labProviderToLabels
    Object.values(LabProvider).forEach((labProvider: LabProvider) => {
      labels.set(labProvider, LAB_PROVIDER_LABELS[labProvider]);
    });
    return labels;
  }, [labProviders, shutoffStatuses]);

  const platformCandidate = useMemo(() => LabShutoffCandidate.ofPlatform(), []);

  // set the platform shutoff status
  const platformShutoffStatus = useMemo(
    () => shutoffStatuses[platformCandidate.toCandidateString() as string],
    [shutoffStatuses, platformCandidate]
  );

  const regionStatuses = useMemo(() => {
    const statuses: Map<string, LabShutoffStatus> = new Map();
    supportedLabRegions.forEach((region: Region) => {
      statuses.set(region.id, shutoffStatuses[LabShutoffCandidate.ofRegion(region).toCandidateString() as string]);
    });
    return statuses;
  }, [supportedLabRegions, shutoffStatuses]);

  const labProviderStatuses = useMemo(() => {
    const statuses: Map<LabProvider, LabShutoffStatus> = new Map();
    labProviders.forEach((labProvider: LabProvider) => {
      statuses.set(
        labProvider,
        shutoffStatuses[LabShutoffCandidate.ofLabProvider(labProvider).toCandidateString() as string]
      );
    });
    return statuses;
  }, [supportedLabRegions, shutoffStatuses, labProviders]);

  const events = useMemo(() => {
    // get every event that has not ended / has an anything shutoff
    return (allEvents || []).filter((event) => event.status !== 'ENDED' || event.shutoffStatus?.hasAnyShutoff);
  }, [allEvents]);

  const challenges = useMemo(() => {
    const filteredChallenges = (allChallenges || []).filter((challenge) => challenge.awsAccountBased);
    filteredChallenges.forEach((challenge) => {
      challenge.shutoffStatus =
        shutoffStatuses[LabShutoffCandidate.ofChallenge(challenge.challengeId || '').toCandidateString() as string];
    });
    return filteredChallenges;
  }, [allChallenges, shutoffStatuses]);

  const challengesById = useMemo(() => {
    const data: Map<string, Challenge> = new Map();
    challenges.forEach((challenge) => {
      data.set(challenge.challengeId || '', challenge);
    });
    return data;
  }, [challenges]);

  /**
   * Update the shutoff status of every event and challenge of this component, as well as the platform.
   */
  const updateShutoffStatuses = async () => {
    // include the global platform shutoff in the candidates list
    const candidates = [platformCandidate];

    // for each region, create its corresponding platform-wide region shutoff as a candidate
    supportedLabRegions.forEach((region: Region) => {
      candidates.push(LabShutoffCandidate.ofRegion(region));
    });

    // for each lab provider, create its corresponding platform-wide labProvider shutoff as a candidate
    labProviders.forEach((labProvider: LabProvider) => {
      candidates.push(LabShutoffCandidate.ofLabProvider(labProvider));
    });

    // for each challenge, add its platform-wide candidate
    challenges.forEach((challenge) => {
      candidates.push(LabShutoffCandidate.ofChallenge(challenge.challengeId || ''));
    });

    // make the API call to get shutoff statuses + populate event/challenge shutoff statuses in parallel
    const [getShutoffStatusResponse] = await Promise.all([
      labshutoffAPI.getShutoffStatuses(candidates, false),
      labshutoffAPI.populateEventChallengeShutoffs(events, false), // populate event/challenge shutoffs
    ]);

    const statuses = getShutoffStatusResponse.statuses;
    setShutoffStatuses(statuses);
  };

  /**
   * Update this component's audit trail by making an API call.
   */
  const updateAuditTrail = async () => {
    const dateRangeFilter: DateRangeFilter = {
      start: dateFilter.start,
      end: dateFilter.end,
    };

    const trail = (await labshutoffAPI.getAuditTrail(dateRangeFilter)).shutoffActions;

    // sort by descending time
    trail.sort((a, b) => (b.timeCreated || 0) - (a.timeCreated || 0));
    setAuditTrail(trail);
  };

  /**
   * Update this component's list of events by making an API call.
   */
  const updateEventsList = () => {
    const eventFilterOptions: DateRangeFilter = {
      start: dateFilter.start,
      end: dateFilter.end,
    };

    loadEventsByDateRange(eventFilterOptions);
  };

  /**
   * Update this component's list of challenges by making an API call.
   * This list of challenges is not for event challenges, but rather for platform-wide challenges.
   */
  const updateChallengesList = async () => {
    // get every challenge that uses lab accounts
    await getChallenges(false, false, false);
  };

  const load = async () => {
    try {
      updateEventsList();
      await updateAuditTrail();
      // await updateShutoffStatuses();
      void updateChallengesList();
    } catch (e) {
      // e
    }
  };

  const initialize = async () => {
    showLoader();
    try {
      const config = await challengesApi.getChallengeConfig();
      setChallengeConfig(config);
      await load();
    } catch (e) {
      // e
    }
    hideLoader();
  };

  const onStatusChange = () => {
    setTimeout(() => {
      void updateShutoffStatuses();
      void updateAuditTrail();
    }, 500);
    closeCandidateModal();
    closeReasonModal();
  };

  const onShutoffCandidate = async () => {
    if (shutoffCandidate) {
      try {
        showLoader();
        await labshutoffAPI.shutoffCandidate('', shutoffCandidate);
        onStatusChange();
      } catch (e) {
        // e
      }
      hideLoader();
    }
  };
  const onTurnOnCandidate = async () => {
    if (turnonCandidate) {
      try {
        showLoader();
        await labshutoffAPI.turnOnCandidate(turnonCandidate);
        onStatusChange();
      } catch (e) {
        // e
      }
      hideLoader();
    }
  };

  const onClickShutoffCandidate = (candiate: LabShutoffCandidate, str = '') => {
    setShutoffCandidate(candiate);
    setModalString(str);
  };
  const onClickTurnOnCandidate = (candiate: LabShutoffCandidate, str = '') => {
    setTurnonCandidate(candiate);
    setModalString(str);
  };
  const onDeploymentActionClick = (
    id: string,
    candidate: CandidateOptions,
    type: 'LAB' | 'REGION' | 'CHALLENGE' | 'EVENT',
    event?: Event
  ) => {
    let cd!: LabShutoffCandidate;
    let str = '';
    switch (type) {
      case 'LAB':
        cd = LabShutoffCandidate.ofLabProvider(candidate as LabProvider);
        str = `${labProvidersToLabels.get(candidate as LabProvider)} (${candidate})`;
        break;
      case 'REGION':
        cd = LabShutoffCandidate.ofRegion(candidate as Region);
        str = `${(candidate as Region).name} (${(candidate as Region).id})`;
        break;
      case 'EVENT':
        cd = LabShutoffCandidate.ofEvent((candidate as Event).name);
        str = `${(candidate as Event).title}`;
        break;
      case 'CHALLENGE':
        cd = LabShutoffCandidate.ofEventChallengePair(
          (event as Event).name,
          (candidate as ChallengeDescriptor).challengeId || ''
        );
        str = `${challengesById.get((candidate as ChallengeDescriptor).challengeId || '')?.props.title}`;
        break;
    }
    switch (id) {
      case LabActions.shutoff:
        onClickShutoffCandidate(cd, str);
        break;
      case LabActions.turnon:
        onClickTurnOnCandidate(cd, str);
        break;
    }
  };

  const closeCandidateModal = () => {
    setShutoffCandidate(undefined);
    setTurnonCandidate(undefined);
    setModalString('');
  };
  const closeReasonModal = () => {
    closeCandidateModal();
    setShowReasonModal(false);
    setReason('');
  };
  const openReasonModal = () => {
    setShowReasonModal(true);
  };

  useEffect(() => {
    void initialize();
  }, []);

  useEffect(() => {
    if (events.length) {
      void updateShutoffStatuses();
    }
  }, [events]);

  return (
    <SpaceBetween size="m">
      <ExpandableSection
        defaultExpanded
        variant="container"
        headerText={t(i18nKeys.labDashboard.shutoffs.headers.platformShutoff)}
        headerActions={
          !platformShutoffStatus?.selfShutoff ? (
            <Button
              variant="primary"
              onClick={() =>
                onClickShutoffCandidate(
                  LabShutoffCandidate.ofPlatform(),
                  t(i18nKeys.labDashboard.shutoffs.modal.entirePlatform)
                )
              }>
              {t(i18nKeys.labDashboard.shutoffs.actions.shutOffLabDeployment)}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => onClickTurnOnCandidate(LabShutoffCandidate.ofPlatform(), 'the entire platform')}>
              {t(i18nKeys.labDashboard.shutoffs.actions.turnonLabDep)}
            </Button>
          )
        }>
        <Box fontWeight="bold">{t(i18nKeys.labDashboard.shutoffs.headers.currentStatus)}</Box>
        <LabShutoffStatusBadge shutoffStatus={platformShutoffStatus} showRegionShutoffs />
      </ExpandableSection>

      <ExpandableSection
        variant="container"
        defaultExpanded
        headerText={t(i18nKeys.labDashboard.shutoffs.headers.labProviders)}>
        <Table
          items={labProviders}
          variant="embedded"
          columnDefinitions={[
            {
              id: 'labProviderLabel',
              header: t(i18nKeys.labDashboard.shutoffs.table.labProviderLabel),
              cell: (labProvider) => <Box fontWeight="bold">{labProvidersToLabels.get(labProvider)}</Box>,
            },
            {
              id: 'labProvider',
              header: t(i18nKeys.labDashboard.shutoffs.table.labProviderId),
              cell: (labProvider) => <Badge color="blue">{labProvider}</Badge>,
            },
            {
              id: 'status',
              header: t(i18nKeys.labDashboard.shutoffs.table.status),
              cell: (labProvider) => <LabShutoffStatusBadge shutoffStatus={labProviderStatuses.get(labProvider)} />,
            },
            {
              id: 'actions',
              header: t(i18nKeys.labDashboard.shutoffs.table.action),
              cell: (labProvider) => (
                <ButtonDropdown
                  expandToViewport
                  variant="icon"
                  items={!labProviderStatuses.get(labProvider)?.selfShutoff ? shutoffAction : turnonAction}
                  onItemClick={({ detail }) => onDeploymentActionClick(detail.id, labProvider, 'LAB')}
                />
              ),
            },
          ]}
        />
      </ExpandableSection>

      <ExpandableSection
        defaultExpanded
        variant="container"
        headerText={t(i18nKeys.labDashboard.shutoffs.headers.regions)}>
        <Table
          variant="embedded"
          items={supportedLabRegions}
          columnDefinitions={[
            {
              id: 'regionTitle',
              header: t(i18nKeys.labDashboard.shutoffs.table.regionTitle),
              cell: (item) => item.name,
            },
            {
              id: 'regionId',
              header: t(i18nKeys.labDashboard.shutoffs.table.regionId),
              cell: (item) => item.id,
            },
            {
              id: 'status',
              header: t(i18nKeys.labDashboard.shutoffs.table.status),
              cell: (item) => <LabShutoffStatusBadge shutoffStatus={regionStatuses.get(item.id)} />,
            },
            {
              id: 'actions',
              header: t(i18nKeys.labDashboard.shutoffs.table.action),
              cell: (item) => (
                <ButtonDropdown
                  expandToViewport
                  variant="icon"
                  items={!regionStatuses.get(item.id)?.selfShutoff ? shutoffAction : turnonAction}
                  onItemClick={({ detail }) => onDeploymentActionClick(detail.id, item, 'REGION')}
                />
              ),
            },
          ]}
        />
      </ExpandableSection>
      {challenges.length ? (
        <ExpandableSection defaultExpanded variant="container" headerText={t(i18nKeys.labDashboard.events.title)}>
          <SpaceBetween size="m">
            {events.map((event) => (
              <ExpandableSection
                key={`${event.id}-${event.createdDate}`}
                variant="container"
                headerText={event.title}
                headerActions={
                  !event.shutoffStatus?.selfShutoff ? (
                    <Button
                      onClick={() => {
                        onDeploymentActionClick(LabActions.shutoff, event, 'EVENT');
                      }}>
                      {t(i18nKeys.labDashboard.shutoffs.actions.shutOffLabDeployment)}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        onDeploymentActionClick(LabActions.turnon, event, 'EVENT');
                      }}>
                      {t(i18nKeys.labDashboard.shutoffs.actions.turnonLabDep)}
                    </Button>
                  )
                }>
                <ColumnLayout columns={3} variant="text-grid">
                  <KeyValue label={<strong>{t(i18nKeys.labDashboard.shutoffs.headers.eventLink)}</strong>}>
                    <Link external href={eventRoute.resolvePath(event.id as string)} target="_blank">
                      {t(i18nKeys.labDashboard.shutoffs.actions.goToEvent)}
                    </Link>
                  </KeyValue>
                  <KeyValue label={<strong>{t(i18nKeys.labDashboard.shutoffs.headers.currentStatus)}</strong>}>
                    <LabShutoffStatusBadge
                      challengeDescriptors={event.challengeDescriptors}
                      shutoffStatus={event.shutoffStatus}
                      showRegionShutoffs
                    />
                  </KeyValue>
                  <KeyValue label={<strong>{t(i18nKeys.labDashboard.shutoffs.headers.eventId)}</strong>}>
                    <Box>{event.name}</Box>
                  </KeyValue>
                </ColumnLayout>
                <Box margin={{ top: 'l', bottom: 'l' }} fontWeight="bold">
                  {t(i18nKeys.labDashboard.shutoffs.headers.challenges)}
                </Box>
                <Table
                  items={event.challengeDescriptors.filter((cd) => challengesById.get(cd.challengeId || ''))}
                  columnDefinitions={[
                    {
                      id: 'challengeTitle',
                      header: t(i18nKeys.labDashboard.shutoffs.table.challengeTitle),
                      cell: (item) => <Link href="#">{challengesById.get(item.challengeId || '')?.props.title}</Link>,
                    },
                    {
                      id: 'challengeId',
                      header: t(i18nKeys.labDashboard.shutoffs.table.challengeId),
                      cell: (item) => item.challengeId,
                    },
                    {
                      id: 'labStatus',
                      header: t(i18nKeys.labDashboard.shutoffs.table.labStatus),
                      cell: (item) => <LabShutoffStatusBadge shutoffStatus={item.shutoffStatus} />,
                    },
                    {
                      id: 'actions',
                      header: t(i18nKeys.labDashboard.shutoffs.table.action),
                      cell: (item) => (
                        <ButtonDropdown
                          expandToViewport
                          variant="icon"
                          items={!item.shutoffStatus?.selfShutoff ? shutoffAction : turnonAction}
                          onItemClick={({ detail }) => onDeploymentActionClick(detail.id, item, 'CHALLENGE', event)}
                        />
                      ),
                    },
                  ]}
                  empty={<Box>No Challenges</Box>}
                />
              </ExpandableSection>
            ))}
          </SpaceBetween>
        </ExpandableSection>
      ) : (
        <Box>
          <Spinner />
        </Box>
      )}
      <Modal
        visible={!!shutoffCandidate}
        header={<Header>{t(i18nKeys.labDashboard.shutoffs.modal.shutoffLab)}</Header>}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="m">
              <Button onClick={closeCandidateModal}>{t(i18nKeys.general.cancel)}</Button>
              <Button variant="primary" onClick={openReasonModal}>
                {t(i18nKeys.labDashboard.shutoffs.actions.shutOffLabDeployment)}
              </Button>
            </SpaceBetween>
          </Box>
        }>
        {t(i18nKeys.labDashboard.shutoffs.modal.areYouSureShutOff, { str: modalString })}
      </Modal>
      <Modal
        visible={!!turnonCandidate}
        header={<Header>{t(i18nKeys.labDashboard.shutoffs.modal.turnonLab)}</Header>}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="m">
              <Button onClick={closeCandidateModal}>{t(i18nKeys.general.cancel)}</Button>
              <Button variant="primary" onClick={() => void onTurnOnCandidate()}>
                {t(i18nKeys.labDashboard.shutoffs.actions.turnonLabDep)}
              </Button>
            </SpaceBetween>
          </Box>
        }>
        {t(i18nKeys.labDashboard.shutoffs.modal.areYouSureTurnOn, { str: modalString })}
      </Modal>
      <Modal
        visible={showReasonModal}
        onDismiss={closeReasonModal}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="m">
              <Button variant="link" onClick={closeReasonModal}>
                {t(i18nKeys.general.skip)}
              </Button>
              <Button variant="primary" onClick={() => void onShutoffCandidate()}>
                {t(i18nKeys.general.submit)}
              </Button>
            </SpaceBetween>
          </Box>
        }>
        <Box>{t(i18nKeys.eventLabs.challengeLab.messages.enterReason)}</Box>
        <Textarea placeholder="reason" value={reason} onChange={({ detail }) => setReason(detail.value)} />
      </Modal>
    </SpaceBetween>
  );
};

export default LabShutoffs;
