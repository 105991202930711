export const isDateNotInThePast = (date: Date, startDate?: string) => {
  const prevDay = startDate ? new Date(startDate) : new Date();
  prevDay.setDate(prevDay.getDate() - 1);
  return date.getTime() > prevDay.getTime();
};

const CHALLENGE_ID_REGEX = /\/challenges\/([^/]+)/;

export const getChallengeIdFromPath = (pathname: string) => {
  const reg = pathname.match(CHALLENGE_ID_REGEX);
  return (reg?.length ?? 0) > 1 ? ((reg && reg[1]) ?? "") : "";
};