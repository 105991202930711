export enum SAVE_ACTIONS {
    UPDATE_CHANGE_REQUEST = 'update-change-request',
    UPDATE_EVENT_REQUEST = 'update-event-request',
    SUBMIT_CHANGE_REQUEST = 'submit-change-request',
  }

  export enum ActionButtonNames {
    CANCEL_CHANGE_REQUEST = 'cancel-change-request',
    CANCEL_EVENT = 'cancel-event',
    APPROVE_CHANGE_REQUEST = 'approve-change-request',
    DENY_CHANGE_REQUEST = 'deny-change-request',
    CANCEL_EVENT_REQUEST = 'cancel-event-request',
    APPROVE_EVENT_REQUEST = 'approve-event-request',
    DENY_EVENT_REQUEST = 'deny-event-request',
    CLONE_EVENT = 'clone-event',
    UNLOCK_CHALLENGES = 'unlock-challenges',
    LOCK_CHALLENGES = 'lock-challenges',
    DELETE_EVENT = 'delete-event',
    ACTIONS = 'actions',
    RESET_EVENT = 'reset-event',
    RESET_EVENT_TEAMS = 'reset-event-teams',
    VIEW_INVOICE = 'view-invoice',
    RESEND_EMAIL_INVITE = 'Re-send email invite',
  }

  export enum NewEventSteps {
    EVENT_DETAILS = 0,
    ATTENDANCE_AND_TESTING = 1,
    TEAM_SETTINGS = 2,
    OWNERS_AND_PERMISSIONS = 3,
    CHALLENGES = 4,
    REVIEW_AND_CREATE = 5,
  }