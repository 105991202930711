import React from 'react';
import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { EventTemplateReportResponse } from '@/src/types/EventTemplate';
import { LoadingBar } from '@/src/components/common/LoadingBar';

interface IEventTemplateDetailReportingOverviewProps {
  eventTemplateReport: EventTemplateReportResponse | null;
}

const EventTemplateDetailReportingOverview: React.FC<IEventTemplateDetailReportingOverviewProps> = ({
  eventTemplateReport,
}) => {
  if (!eventTemplateReport) {
    return <LoadingBar />;
  }
  const { t } = useTranslation();
  const formattedTotalRevenue: string =
    eventTemplateReport.revenueGenerated > 1000
      ? `$${(eventTemplateReport.revenueGenerated / 1000).toFixed(1)}K`
      : `$${eventTemplateReport.revenueGenerated.toFixed(0)}`;
  return (
    <Container
      header={<Header variant="h2">{t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.overview)}</Header>}>
      <div className="overview-container">
        <div className="overview-item">
          <h4>{t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.eventsBooked)}</h4>
          <h1>{eventTemplateReport.eventsUsed}</h1>
        </div>
        <div className="overview-item">
          <h4 id={'revenue_label'}>{t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.totalRevenue)}</h4>

          <SpaceBetween direction="horizontal" size="s">
            <h1 id={'revenue'}>{formattedTotalRevenue}</h1>
            <h3>(USD)</h3>
          </SpaceBetween>
        </div>
        <div className="overview-item">
          <h4>{t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.totalLearners)}</h4>
          <h1>{eventTemplateReport.learnersParticipated}</h1>
        </div>
      </div>
    </Container>
  );
};

export default EventTemplateDetailReportingOverview;
