/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { i18nKeys } from '../utils/i18n.utils';
import { asList } from '../utils/list.utils';
import { JamChallengeFeedback, JamEventFeedback, JamFeedbackRes } from '../types/JamFeedback';

export class JamChallengeFeedbackAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * get jam event feedback data
   *
   */
  public async saveJamEventFeedbackData(eventId: string, body: JamEventFeedback) {
    return (await this.apiClient.post({
      path: `/event/${eventId}/feedback`,
      body,
      successMessage: this.t(i18nKeys.myJams.feedback.label.successMessage),
      failMessage: this.t(i18nKeys.myJams.feedback.label.errorMessage),
    })) as Promise<JamFeedbackRes>;
  }

  public async getJamEventFeedbackData(eventId: string) {
    return (await this.apiClient.get({
      path: `/event/${eventId}/participant/feedback`,
      failMessage: this.t(i18nKeys.myJams.feedback.label.errorMessage),
    })) as Promise<JamFeedbackRes>;
  }

  /**
   * get jam challenge feedback data
   *
   */
  public async fetchJamChallengeFeedbackData(eventId: string, challengeId: string, body: JamChallengeFeedback) {
    return (await this.apiClient.post({
      path: `/event/${eventId}/${challengeId}/feedback`,
      body,
      responseMapper: asList(JamChallengeFeedback.fromPlainObject),
      successMessage: this.t(i18nKeys.myJams.feedback.label.successMessage),
      failMessage: this.t(i18nKeys.myJams.feedback.label.errorMessage),
    })) as Promise<JamChallengeFeedback>;
  }
}
