import React from 'react';
import { ChallengeSet } from '../../../types/ChallengeSet';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../utils/table.utils';
import { timeAgo } from '../../../utils/time.utils';
import { Link as ReactRouterLink } from 'react-router-dom';
import { CHALLENGE_SETS_ROUTES } from '../../../routes';
import { Badge, Button, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { copyToClipboard } from '../../../utils/copy-to-clipboard';
import { useUser } from '../../../store/user.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';

export const filteringFunction = (item: ChallengeSet, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item?.title || null) ||
    matcher.isMatch(item?.description || null) ||
    matcher.isMatch(item?.owner || null)
  );
};

export const COLUMN_DEFINITIONS = (handleDelete: (set: ChallengeSet) => void) => {
  const { t } = useTranslation();
  const { user } = useUser();
  return [
    {
      id: 'title',
      sortingField: 'title',
      header: t(i18nKeys.challenges.challengeSet.table.titles.title),
      cell: (item: ChallengeSet) => (
        <SpaceBetween direction="vertical" size="xs">
          <ReactRouterLink to={CHALLENGE_SETS_ROUTES.Summary.resolve(item.id ?? '')}>{item.title}</ReactRouterLink>
          {item.featured && <Badge color="green">{t(i18nKeys.challenges.challengeSet.titles.featured)}</Badge>}
        </SpaceBetween>
      ),
    },
    {
      id: 'challenges',
      header: t(i18nKeys.challenges.challengeSet.table.titles.challenges),
      cell: (item: ChallengeSet) => item.numChallenges,
    },
    {
      id: 'createDate',
      header: t(i18nKeys.challenges.challengeSet.table.titles.created),
      cell: (item: ChallengeSet) => timeAgo(item.createDate || 0),
    },
    {
      id: 'updatedDate',
      header: t(i18nKeys.challenges.challengeSet.table.titles.lastUpdated),
      cell: (item: ChallengeSet) => timeAgo(item.updatedDate || 0),
    },
    {
      id: 'owner',
      header: t(i18nKeys.challenges.challengeSet.table.titles.owner),
      cell: (item: ChallengeSet) => {
        return user?.isEventAdmin ? (
          <SpaceBetween direction="horizontal" size="s">
            <Link href={`mailto:${item.owner}`}>{item.owner}</Link>
            <Button iconName="copy" ariaLabel="copy" variant="inline-icon" onClick={() => copyToClipboard(item.owner)} />
          </SpaceBetween>
        ) : (
          <div>--</div>
        );
      },
    },
    {
      id: 'actions',
      header: t(i18nKeys.challenges.challengeSet.table.titles.actions),
      cell: (item: ChallengeSet) => {
        return (
          (user?.isChallengeAdmin || user?.email === item.owner) && (
            <Button onClick={() => handleDelete(item)} iconName="close">
              {t(i18nKeys.general.delete)}
            </Button>
          )
        );
      },
    },
  ];
};
