import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsagePlan } from '../../../types/usage-plan/UsagePlan';
import { paginationLabels } from '../../../utils/table.utils';
import { i18nKeys } from '../../../utils/i18n.utils';
import moment from 'moment';
import { getTimeFormat } from '../../../utils/event-time.utils';
import { Pagination, Table } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState } from '../../common/TableEmptyState';
import { TableHeader } from '../../common/TableHeader';
import { useSplitPanel } from '../../../store/split-panel.context';
import UsagePlanDetailsOverview from './UsagePlanDetailsOverview';

interface UsagePlanDetailsVersionProps {
  versions: UsagePlan[];
}

const UsagePlanDetailsVersion: React.FC<UsagePlanDetailsVersionProps> = ({ versions }) => {
  const { t } = useTranslation();
  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['name', 'createDate', 'lastUpdatedDate', 'status'],
  });
  const versionItems = versions.filter((v) => !UsagePlan.isLatest(v)); // we don't want the v0 version since it's just a clone of the latest version
  const [selectedVersion, setSelectedVersion] = useState<UsagePlan>(versionItems[0]);
  const { renderSplitPanelContent } = useSplitPanel();

  useEffect(() => {
    renderSplitPanelContent(
      t(i18nKeys.usagePlan.overview.panel, {
        planName: selectedVersion.name,
        version: UsagePlan.versionNumber(selectedVersion.version),
      }),
      <UsagePlanDetailsOverview usagePlan={selectedVersion} />
    );
  }, []);

  const handleVersionChange = (selectedPlan: UsagePlan) => {
    setSelectedVersion(selectedPlan);
    renderSplitPanelContent(
      t(i18nKeys.usagePlan.overview.panel, {
        planName: selectedPlan.name,
        version: UsagePlan.versionNumber(selectedPlan.version),
      }),
      <UsagePlanDetailsOverview usagePlan={selectedPlan} />
    );
  };

  const versionComparator = (a: UsagePlan, b: UsagePlan) =>
    UsagePlan.versionNumber(a.version) - UsagePlan.versionNumber(b.version);

  const columnDefinition = [
    {
      id: 'version',
      sortingField: 'version',
      header: t(i18nKeys.general.version),
      cell: (plan: UsagePlan) => UsagePlan.versionNumber(plan.version),
      minWidth: 200,
      allowLineWrap: true,
      sortingComparator: versionComparator,
    },
    {
      id: 'createDate',
      sortingField: 'createDate',
      header: t(i18nKeys.usagePlan.overview.createdDate),
      cell: (version: UsagePlan) =>
        moment(version.createDate).format(
          getTimeFormat(moment(version.createDate), moment(), { includeDate: true, includeTime: true })
        ),
      minWidth: 150,
      allowLineWrap: false,
    },
    {
      id: 'changedBy',
      sortingField: 'changedBy',
      header: t(i18nKeys.events.eventDetails.labels.createdBy),
      cell: (version: UsagePlan) => version.createdBy.emailAddress,
      minWidth: 150,
      allowLineWrap: false,
    },
  ];

  const { items, collectionProps, paginationProps } = useCollection(versionItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'version',
          sortingComparator: versionComparator,
        },
      },
    },
  });

  return (
    <div>
      <Table
        {...collectionProps}
        stickyHeader
        resizableColumns
        header={<TableHeader totalItems={versionItems.length} title={t(i18nKeys.general.versions)} />}
        columnDefinitions={columnDefinition}
        items={items}
        selectionType="single"
        onSelectionChange={({ detail }) => handleVersionChange(detail.selectedItems[0])}
        selectedItems={[selectedVersion]}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
        empty={
          <TableEmptyState
            title={t(i18nKeys.usagePlan.overview.empty.title)}
            subtitle={t(i18nKeys.usagePlan.overview.empty.subtitle)}
          />
        }
      />
    </div>
  );
};

export default UsagePlanDetailsVersion;
