import { Checkbox, ExpandableSection, Header } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengePropAction,
  ChallengeSettingsFields,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { Challenge } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { KeyValue } from '../../../common/KeyValue';

interface ChallengeSSHSettingDetailProps {
  challenge: Challenge;
}

const ChallengeSSHSettingDetail: React.FC<ChallengeSSHSettingDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();

  const [sshSettings, setSshSettings] = useState({
    sshKeyPairRequired: false,
  });

  useEffect(() => {
    if (editedChallenge) {
      setSshSettings({ ...sshSettings, sshKeyPairRequired: editedChallenge.props.sshKeyPairRequired });
    }
  }, [editedChallenge]);

  const handleSshSettingsUpdate = (event: { target: { name: string; value: boolean } }) => {
    const { name, value } = event.target;
    const newSSHSettings = {
      ...sshSettings,
      [name]: value,
    };

    handleUpdateChallengeProp(ChallengePropAction.SSH_SETTINGS, newSSHSettings);
    setSshSettings(newSSHSettings);
  };

  const [expanded, setExpanded] = useState(true);

  return (
    <ExpandableSection
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.sshSettings)}</Header>}>
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.sshKeyRequired)}>
        {!editMode ? (
          challenge.props.sshKeyPairRequired ? (
            t(i18nKeys.general.yes)
          ) : (
            t(i18nKeys.general.no)
          )
        ) : (
          <Checkbox
            onChange={({ detail }) =>
              handleSshSettingsUpdate({
                target: { name: ChallengeSettingsFields.SSH_KEYPAIR_REQUIRED, value: detail.checked },
              })
            }
            description={t(i18nKeys.challenges.subSections.settings.container_2.fields.required.description)}
            checked={sshSettings.sshKeyPairRequired}>
            {t(i18nKeys.challenges.subSections.settings.container_2.fields.required.title)}
          </Checkbox>
        )}
      </KeyValue>
    </ExpandableSection>
  );
};

export default ChallengeSSHSettingDetail;
