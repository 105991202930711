import { ExpandableSection, FormField, Header, Input, Grid } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useUser } from '../../../store/user.context';
import { Campaign } from '../../../types/Campaign';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';

interface ChimeNotificationsProps {
  target?: Event | Campaign;
}

const ChimeNotifications: React.FC<ChimeNotificationsProps> = ({ target }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, handleUpdateEditEvent, editedEvent } = useEditEvent();
  const { campaignEditMode, handleUpdateEditCampaign, editedCampaign } = useEditCampaign();
  const isEvent = target instanceof Event;
  const canEdit = editMode || campaignEditMode;
  const chimeWebHookUrl = isEvent ? target.chimeWebHookUrl : target?.campaignSettings?.chimeWebHookUrl;

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.chimeNotifications.header)}</Header>}>
      <Grid gridDefinition={[{ colspan: 8 }]}>
        {!canEdit && (
          <div className="section-first-row">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.webhookUrl)}</div>
              <div>{chimeWebHookUrl || t(i18nKeys.events.eventDetails.labels.none)}</div>
            </Grid>
          </div>
        )}
        {canEdit && user && (
          <FormField
            constraintText={t(i18nKeys.events.eventDetails.messages.webHookURLDescription)}
            label={t(i18nKeys.events.eventDetails.labels.webhookUrl)}>
            <Input
              placeholder="https://hooks.chime.aws/..."
              value={
                (isEvent ? editedEvent?.chimeWebHookUrl : editedCampaign?.campaignSettings?.chimeWebHookUrl) ||
                chimeWebHookUrl ||
                ''
              }
              disabled={!target?.canEditAttribute('chimeWebhookUrl', user)}
              onChange={({ detail }) =>
                isEvent
                  ? handleUpdateEditEvent(EditEventActions.CHIME_WEBHOOK_URL, detail.value)
                  : handleUpdateEditCampaign(EditCampaignActions.CHIME_WEBHOOK_URL, detail.value)
              }
            />
          </FormField>
        )}
      </Grid>
    </ExpandableSection>
  );
};

export default ChimeNotifications;
