import React, { useEffect, useState } from 'react';
import { Box, ColumnLayout, Container, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import Sponsored from '../../../molecules/MyJams/JamChallenges/Sponsored';
import Difficulty from '../../../molecules/MyJams/JamChallenges/Difficulty';
import Points from '../../../molecules/MyJams/JamChallenges/Points';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useToolPanel } from '@/src/store/tool-panel.context';
import { IJamChallenge } from '@/src/types/JamChallenges';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import WarmUp from '../../../molecules/MyJams/JamChallenges/Warmup';

interface IChallengeDetailsCardProps {
  challenges: IJamChallenge[] | undefined;
}

const ChallengeDetailsCard: React.FC<IChallengeDetailsCardProps> = ({ challenges }) => {
  const { t } = useTranslation();
  const { jamChallengeData } = useJamChallenge();
  const { toggleJamChallengeDetailsInfo, showToolPanel } = useToolPanel();
  const [selectedChallenge, setSelectedChallenge] = useState('');

  const handleToggle = (currentChallenge: IJamChallenge) => {
    if (jamChallengeData && currentChallenge) {
      toggleJamChallengeDetailsInfo(currentChallenge);
      setSelectedChallenge(currentChallenge.id);
    }
  };

  useEffect(() => {
    if (!showToolPanel) {
      setSelectedChallenge('');
    }
  }, [showToolPanel]);

  return (
    <ColumnLayout columns={2} className="awsui_restore-pointer-events_14yj0_t39q3_282">
      {challenges?.map((challenge: IJamChallenge) => (
        <div
          id={`${challenge.title} challengeContainer`}
          data-testid={`${challenge.title} challengeContainer`}
          key={challenge.id}
          className="pos-relative">
          {challenge.isSolvedbyOtherTeam && (
            <Box variant="span" className="solved-challenge">
              15/20 {t(i18nKeys.myJams.challenges.teamsSolvedThisChallenge)}
            </Box>
          )}
          <Container
            disableContentPaddings
            className={`${challenge.locked ? '' : 'dark-bg'} 
            ${selectedChallenge === challenge.id ? 'card-selected' : ''} 
            ${challenge.solved ? 'solved' : ''}`}
            key={challenge.id}>
            <div
              onClick={() => !challenge.locked && handleToggle(challenge)}
              className="challenge-detail-card-container">
              <ColumnLayout columns={challenge?.sponsor && challenge.sponsor?.logo?.length > 0 ? 2 : 1}>
                {challenge?.sponsor && challenge.sponsor?.logo?.length > 0 && <Sponsored sponsor={challenge.sponsor} />}
                <SpaceBetween
                  size="xs"
                  direction="vertical"
                  className={`challenge-container ${
                    challenge?.sponsor && challenge.sponsor?.logo?.length > 0 ? 'has-sponsor' : ''
                  }`}>
                  <Box className="space-between">
                    <Box className="flex-grow" color="text-status-inactive">
                      {challenge.topic ?? t(i18nKeys.myJams.challenges.noTopic)}
                    </Box>
                    {challenge.locked && (
                      <Box className="locked-badge">
                        <Icon name="lock-private" />
                        <Box variant="span" padding={{ left: 'xxs' }} className="text-dark">
                          {t(i18nKeys.myJams.challenges.locked)}
                        </Box>
                      </Box>
                    )}
                    {challenge.solved && (
                      <Box className="card-badge" margin={{ left: 'xs' }}>
                        <Icon name="status-positive" />
                        <Box variant="span" padding={{ left: 'xxs' }} className="text-dark">
                          {t(i18nKeys.myJams.challenges.solved)}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box variant="h2">
                    {challenge.title.length > 100 ? `${challenge.title.substring(0, 98)}...` : challenge.title}
                  </Box>
                  <SpaceBetween
                    alignItems="center"
                    direction="horizontal"
                    size="xs"
                    className={challenge.warmup ? 'challenge-card-footer' : ''}>
                    <Difficulty level={challenge.difficulty} />
                    <Points challenge={challenge} />
                    <Box>
                      {t(i18nKeys.myJams.challenges.cluesUsed)}: {challenge?.cluesUsed}
                    </Box>
                    {challenge.warmup && <WarmUp />}
                  </SpaceBetween>
                </SpaceBetween>
              </ColumnLayout>
            </div>
          </Container>
        </div>
      ))}
    </ColumnLayout>
  );
};

export default ChallengeDetailsCard;
