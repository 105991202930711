import React, { useState, useContext } from 'react';

export interface HeaderContextValue {
  showThemeToggle: boolean;
  setShowThemeToggle: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderContext = React.createContext<HeaderContextValue>({
  showThemeToggle: false,
  setShowThemeToggle: (_showThemeToggle: React.SetStateAction<boolean>) => { return }
});

const HeaderProvider: React.FC = ({ children }) => {
  const [showThemeToggle, setShowThemeToggle] = useState<boolean>(true);
  const data: HeaderContextValue = { showThemeToggle, setShowThemeToggle };

  return <HeaderContext.Provider value={data}>{children}</HeaderContext.Provider>;
};

const useHeader = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useHeader can only be used inside HeaderProvider');
  }
  return context;
};

export { HeaderProvider, useHeader };
