import { Badge } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Challenge, ChallengeForImport, ChallengeListItem } from '@/src/types/Challenge';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './DifficultyIndicator.scss';

interface DifficultyIndicatorProps {
  challenge: Challenge | ChallengeForImport | ChallengeListItem | undefined;
  inline?: boolean;
  inlineWarmup?: boolean;
  enableBlock?: boolean;
}

interface DifficultyIndicatorTextProps {
  difficulty: number
  hideBlock: boolean
}

export const DifficultyIndicatorText:React.FC<DifficultyIndicatorTextProps> = ({difficulty, hideBlock}) => {
  const { t } = useTranslation();

  const colorAndText = React.useMemo(() => {
    switch (difficulty) {
      case 1: {
        return ['yellow', t(i18nKeys.general.intermediate)];
      }
      case 2: {
        return ['orange', t(i18nKeys.general.advanced)];
      }
      case 3: {
        return ['red', t(i18nKeys.general.expert)];
      }
      default: {
        return ['green', t(i18nKeys.general.fundamental)];
      }
    }
  }, [difficulty])
  
  
  const [ color, text ] = colorAndText
  return (
    <div className="default-text" style={{ display: 'inline-block' }}>
      {hideBlock && (
        <div className="block" style={{ backgroundColor: color }}>
          {' '}
        </div>
      )}
      {text}
    </div>
  )
}

const DifficultyIndicator: React.FC<DifficultyIndicatorProps> = ({
  challenge,
  inline = false,
  inlineWarmup = false,
  enableBlock = true,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: inline ? 'inline' : undefined }}>
      {challenge instanceof ChallengeForImport && challenge.missing ? (
        <div
          className="badge-aws badge-aws-warning inline"
          title={t(i18nKeys.challenges.challengeDetails.field.warmup.title)}>
          <Badge className="ml-5" color="red">
            {t(i18nKeys.general.notAvailable)}
          </Badge>
        </div>
      ) : (
        <React.Fragment>
          <DifficultyIndicatorText
            difficulty={(challenge && challenge instanceof Challenge ? challenge.props.difficulty : challenge?.difficulty) || 0}
            hideBlock={enableBlock}
          />
          {(challenge instanceof Challenge ? challenge?.challengeAlwaysOn : challenge?.warmup) && enableBlock ? (
            <div
              className={`badge-aws badge-aws-warning warmup ${inlineWarmup ? 'inline ml-5' : undefined}`}
              title={t(i18nKeys.challenges.challengeDetails.field.warmup.title)}>
              <img src="/assets/warmup.png" alt='warmup-image' style={{ width: '7px' }} />
              {t(i18nKeys.challenges.challengeDetails.field.warmup.name)}
            </div>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default DifficultyIndicator;
