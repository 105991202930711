import { DetailedEventStatisticsReport } from '@/src/types/DetailedEventStatisticsReport';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { fromPlainObject } from '@/src/utils/mapper.utils';
import { ApiClient } from './ApiClient';
import { TFunction } from 'i18next';

export class ReportsAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  public async getEventReport(eventName: string, silent = false): Promise<DetailedEventStatisticsReport> {
    return (await this.apiClient.get({
      path: `/admin/events/${eventName}/report`,
      failMessage: this.t(i18nKeys.errors.requestFailed.report.loadReport),
      responseMapper: (object) => fromPlainObject(object, DetailedEventStatisticsReport),
      silent,
    })) as Promise<DetailedEventStatisticsReport>;
  }
}
