import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box } from '@amzn/awsui-components-react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EventStartInfo = () => {
  const { t } = useTranslation();
  const { event } = useJamEventDetails();
  if (!event) {
    return null;
  }
  return (
    <Box textAlign="center" margin={{ top: 'l' }}>
      <Box variant="h1" margin={{ bottom: 'l' }}>
        {t(i18nKeys.joinTeam.onboarding.eventStarts, {
          date: moment(event?.eventStartDate).format('MMMM, DD YYYY hh:mm A ([GMT] Z)'),
        })}
      </Box>
      <Box>
        {t(i18nKeys.joinTeam.onboarding.forTeam, {
          minutes: event?.formTeamsMinsBeforeEventStart,
        })}
      </Box>
    </Box>
  );
};

export default EventStartInfo;
