import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from '../common';
import { LabProvider } from '../LabProvider';
import { User } from '../User';
import { ChallengeListItem } from '../Challenge';
import { Comment, DateString } from '../../types/common';
import moment from 'moment-timezone';
import { YYYY_MM_DD_SLASH } from '../../utils/event-time.utils';
import { isEmpty } from '../../utils/string.utils';

export const LATEST_VERSION = 'v0';
export const DEFAULT_REQUEST_FREQUENCY = { frequency: 0, unit: common.FrequencyUnit.DAY };
export const DEFAULT_MIN_MAX = { min: 0, max: 0 };

export const MIN_NAME_LENGTH = 3;
export const MAX_NAME_LENGTH = 40;
export const MIN_FREQUENCY = 1;
export const MAX_DAY_FREQUENCY = 10;
export const MAX_WEEK_FREQUENCY = 50;
export const MAX_MONTH_FREQUENCY = 250;
export const MAX_YEAR_FREQUENCY = 3000;
export const MIN_DURATION = 1;
export const MAX_DURATION = 720;
export const MIN_LEAD_TIME = 0;
export const MAX_LEAD_TIME = 90;
export const MIN_TEAM_SIZE = 1;
export const MAX_TEAM_SIZE = 50;
export const MIN_NUM_CHALLENGES = 1;
export const MAX_NUM_CHALLENGES = 50;
export const MIN_PARTICIPANTS = 1;
export const MAX_PARTICIPANTS = 25_000;
export const MIN_FREE_FORM = 0;
export const MAX_FREE_FORM = 5000;
export const MAX_BLOCKED_CHALLENGES = 500;
export const MAX_MAINTAINERS = 200;
export const MAX_ALLOWLIST = 200;

export interface IUsagePlanComment extends Comment {
  usagePlanId: common.NullableString;
  id: common.NullableString;
  value: common.NullableString;
  author: common.NullableString;
  createdAt: common.NullableDateString;
  updatedAt?: common.NullableDateString;
}

@jsonObject
export class UsagePlanComment implements IUsagePlanComment {
  @jsonMember(common.NullableStringValue)
  usagePlanId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  value: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  author: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  createdAt: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  updatedAt: common.NullableString = null;
}

// ------ Usage plan parameters start------

@jsonObject
export class RequestFrequency {
  @jsonMember(Number)
  frequency = 0;

  @jsonMember(String)
  unit: common.FrequencyUnit = common.FrequencyUnit.DAY;
}

export class MinMax {
  @jsonMember(Number)
  min = 0;

  @jsonMember(Number)
  max = 0;
}

// ------ Usage plan parameters end ------

export interface EditableUsagePlanProps {
  name: common.NullableString;

  expiration: common.NullableDateString;

  notes: common.NullableString;

  description: common.NullableString;

  requestType: common.Nullable<common.RequestType>;

  allowedLabProviders: LabProvider[];

  minDaysBeforeEvent: common.NullableNumber;

  blockedChallenges: string[];

  maxTeamSize: common.Nullable<MinMax>;

  eventDuration: common.NullableNumber;

  numOfChallenges: number;

  numOfParticipants: MinMax;

  requestFrequency: common.Nullable<RequestFrequency>;
}

@jsonObject
export class UsagePlan implements Required<EditableUsagePlanProps> {
  @jsonMember(String)
  id = '';

  @jsonMember(String)
  version = '';

  @jsonMember(Object)
  createdBy: common.Email = { emailAddress: '' };

  @jsonMember(common.NullableStringValue)
  latest: common.NullableString = null;

  @jsonMember(common.NullableDateStringValue)
  createDate: common.NullableDateString = null;

  @jsonMember(Boolean)
  archived = false;

  @jsonMember(common.NullableStringValue)
  name: common.NullableString = null;

  @jsonArrayMember(Object)
  allowlist: common.Email[] = [];

  @jsonArrayMember(Object)
  maintainers: common.Email[] = [];

  @jsonMember(common.NullableDateStringValue)
  expiration: common.NullableDateString = null;

  @jsonMember(common.NullableStringValue)
  notes: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;

  @jsonMember(String)
  requestType: common.Nullable<common.RequestType> = null;

  @jsonArrayMember(String)
  allowedLabProviders: LabProvider[] = [];

  @jsonMember(Number)
  minDaysBeforeEvent: number | null = null;

  @jsonArrayMember(String)
  blockedChallenges: string[] = [];

  @jsonMember(Object)
  maxTeamSize: common.Nullable<MinMax> = null;

  @jsonMember(Number)
  eventDuration: common.NullableNumber = null;

  @jsonMember(Number)
  numOfChallenges = 0;

  @jsonMember(Object)
  numOfParticipants: MinMax = { max: 0, min: 0 };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(RequestFrequency))
  requestFrequency: common.Nullable<RequestFrequency> = null;

  isMaintainer(user: User | null): boolean {
    return (user && this.getMaintainers().includes(user.email)) || false;
  }

  getBlockedChallengesFromListItems(challengeList?: ChallengeListItem[]) {
    return (challengeList ?? []).filter((c) => {
      if (c.challengeId) return this.blockedChallenges.includes(c.challengeId);
    });
  }

  getMaintainers(): string[] {
    return this.getEmailAddresses(this.maintainers);
  }

  getAllowlist(): string[] {
    return this.getEmailAddresses(this.allowlist);
  }

  setMaintainers(emails: string[]) {
    this.maintainers = this.getEmails(emails);
  }

  setAllowlist(emails: string[]) {
    this.allowlist = this.getEmails(emails);
  }

  setExpiration(expiration: DateString) {
    this.expiration = moment(expiration).toISOString();
  }

  getExpirationFormatted() {
    return this.expiration ? moment(this.expiration).format(YYYY_MM_DD_SLASH) : null;
  }

  private getEmails(emails: string[]): common.Email[] {
    return emails.map((email) => Object.assign(new common.Email(), { emailAddress: email }));
  }

  private getEmailAddresses(emails: common.Email[]): string[] {
    return emails.flatMap((email) => (email.emailAddress ? [email.emailAddress] : []));
  }

  static isLatest(plan: UsagePlan): boolean {
    return plan.version === LATEST_VERSION;
  }

  static versionNumber(version: string) {
    if (isEmpty(version.trim())) {
      return NaN;
    }
    return Number(version.substring(1));
  }

  static setDefaults(plan: UsagePlan) {
    plan.allowedLabProviders = [LabProvider.AWS_LABS];
    plan.requestFrequency = DEFAULT_REQUEST_FREQUENCY;
    return plan;
  }
}

@jsonObject
export class EvaluationRequest {
  @jsonMember(common.NullableDateStringValue)
  createdDate: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  lastUpdatedDate: common.NullableDateString = null;

  @jsonMember(String)
  name = '';

  @jsonMember(String)
  id = '';

  @jsonMember(common.NullableStringValue)
  status: common.Nullable<common.ApprovalStatus> = null;

  @jsonMember(common.NullableStringValue)
  changeRequestStatus: common.Nullable<common.ChangeRequestStatus> = null;

  @jsonMember(common.NullableStringValue)
  type: common.Nullable<common.RequestType> = null;

  isApproved(): boolean {
    // a request is approved if:
    // - the request is approved AND there is no change request, OR
    // - the request is approved AND the change request is approved
    return (
      this.status === common.ApprovalStatus.REQUEST_APPROVED &&
      (!this.changeRequestStatus || this.changeRequestStatus === common.ChangeRequestStatus.CHANGE_APPROVED)
    );
  }

  toStatus(): common.ChangeRequestPendingStatus | common.ApprovalStatus {
    if (
      this.changeRequestStatus &&
      (this.changeRequestStatus === common.ChangeRequestStatus.CHANGE_REQUESTED ||
        this.changeRequestStatus === common.ChangeRequestStatus.CHANGE_PENDING)
    ) {
      return this.changeRequestStatus as unknown as common.ChangeRequestPendingStatus;
    }
    return this.status ?? common.ApprovalStatus.REQUEST_PENDING;
  }
}

@jsonObject
export class UsagePlanDetails {
  @jsonArrayMember(UsagePlan)
  versions: UsagePlan[] = [];

  @jsonArrayMember(EvaluationRequest)
  requests: EvaluationRequest[] = [];

  findLatestVersion(): UsagePlan | undefined {
    return this.versions.find((p) => p.version === LATEST_VERSION);
  }
}

@jsonObject
export class CreateUsagePlanRequest implements EditableUsagePlanProps {
  @jsonMember(common.NullableStringValue)
  name: common.NullableString = null;

  @jsonArrayMember(common.NullableStringValue)
  allowlist: string[] = [];

  @jsonArrayMember(common.NullableStringValue)
  maintainers: string[] = [];

  @jsonMember(common.NullableDateStringValue)
  expiration: common.NullableDateString = null;

  @jsonMember(common.NullableStringValue)
  notes: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  requestType: common.Nullable<common.RequestType> = null;

  @jsonArrayMember(Object)
  allowedLabProviders: LabProvider[] = [];

  @jsonMember(Number)
  minDaysBeforeEvent: common.NullableNumber = null;

  @jsonArrayMember(common.NullableStringValue)
  blockedChallenges: string[] = [];

  @jsonMember(Object)
  maxTeamSize: common.Nullable<MinMax> = null;

  @jsonMember(Number)
  eventDuration: common.NullableNumber = null;

  @jsonMember(Number)
  numOfChallenges = 0;

  @jsonMember(Object)
  numOfParticipants: MinMax = { max: 0, min: 0 };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(RequestFrequency))
  requestFrequency: common.Nullable<RequestFrequency> = null;

  static forCreate(plan: UsagePlan): CreateUsagePlanRequest {
    const req: CreateUsagePlanRequest = {
      name: plan.name,
      allowlist: plan.getAllowlist(),
      maintainers: plan.getMaintainers(),
      expiration: plan.expiration,
      notes: plan.notes,
      description: plan.description,
      requestType: plan.requestType,
      allowedLabProviders: plan.allowedLabProviders,
      minDaysBeforeEvent: plan.minDaysBeforeEvent,
      blockedChallenges: plan.blockedChallenges,
      maxTeamSize: plan.maxTeamSize,
      eventDuration: plan.eventDuration,
      numOfChallenges: plan.numOfChallenges,
      numOfParticipants: plan.numOfParticipants,
      requestFrequency: plan.requestFrequency,
    };

    return req;
  }
}
