import React from 'react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';

interface HorizontalRuleProps {
  evenMargins?: boolean;
  className?: string;
}

export const HorizontalRule: React.FC<HorizontalRuleProps> = ({ evenMargins = false, className }) => {
  return (
    <div
      style={{
        borderTop: `1px solid ${awsui.colorBorderDividerDefault}`,
        marginTop: '12px',
        marginBottom: evenMargins ? '12px' : '24px',
      }}
      className={className}>
      {' '}
    </div>
  );
};
