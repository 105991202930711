import React from 'react';
import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import Difficulty from '../ui/molecules/MyJams/JamChallenges/Difficulty';
import Points from '../ui/molecules/MyJams/JamChallenges/Points';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { IJamChallenge } from '@/src/types/JamChallenges';
import { useHistory } from 'react-router-dom';
import { JAM_CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { useToolPanel } from '@/src/store/tool-panel.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { Markdown } from '@/src/components/common/Markdown';
import './JamChallengeDetails.scss';

interface JamChallengeProps {
  challenge: IJamChallenge;
}

export const JamChallengesDetail: React.FC<JamChallengeProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleToolPanel } = useToolPanel();
  const { eventSlug } = useJamEventDetails();

  const handleNavigation = () => {
    history.push(JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventSlug, challenge.id]));
    toggleToolPanel(false);
  };

  return (
    <div className="jam-challenge-details">
      <Box margin={{ horizontal: 'xxl', top: 'xxl', bottom: 'xxxl' }} padding={{ top: 'xxl', bottom: 'xxxl' }}>
        {challenge.sponsor?.logo?.length > 0 && (
          <SpaceBetween direction="vertical" alignItems="center" size="xxs">
            <Box variant="span" color="inherit" className="content">
              {t(i18nKeys.myJams.challenges.sponsoredBy)}
            </Box>
            <Box margin={{ top: 's', bottom: 'xl' }}>
              <img src={challenge.sponsor?.logo} alt={challenge.sponsor?.name} width={75} />
            </Box>
          </SpaceBetween>
        )}
        <SpaceBetween direction="vertical" alignItems="start" size="l">
          <Box variant="span">{challenge.topic}</Box>
          <Box variant="h3">{challenge.title}</Box>
          <SpaceBetween alignItems="center" direction="horizontal" size="xs">
            <Difficulty level={challenge.difficulty} />
            <Points challenge={challenge} />
          </SpaceBetween>
          <Box>
            <Markdown className="markdown-div" content={challenge.description} />
          </Box>
        </SpaceBetween>
        <Box margin={{ top: 'xl' }}>
          <div id="openChallengeButton" data-testid="openChallengeButton" onClick={handleNavigation}>
            <Button fullWidth variant="primary">
              {t(i18nKeys.myJams.challenges.buttons.openChallenge)}
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default JamChallengesDetail;
