/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from 'i18next';
import { GAME_API_PREFIX } from '../constants/api';
import { EnableMessagingResponse } from '../types/EnableMessagingResponse';
import { NotificationData } from '../types/JamNotifications';
import { JamTeamChat } from '../types/JamTeam';
import { ApiClient } from './ApiClient';
import { i18nKeys } from '../utils/i18n.utils';

interface CommonParams {
  eventName: string;
  silent?: boolean;
}

interface WithNotification extends CommonParams {
  notificationData?: NotificationData;
  participantLogin?: string;
  teamName?: string;
  message?: string;
}

export class JamMessageAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  public async enableMessaging({ eventName, silent = false }: CommonParams): Promise<EnableMessagingResponse> {
    return this.apiClient.post({
      path: `${GAME_API_PREFIX}/messaging/enable/${eventName}`,
      silent,
    }) as Promise<EnableMessagingResponse>;
  }

  async getTeamChat({ eventName, silent = false }: CommonParams): Promise<JamTeamChat> {
    return this.apiClient.get({
      path: `${GAME_API_PREFIX}/messaging/${eventName}/team/chat`,
      silent,
    }) as Promise<JamTeamChat>;
  }

  async sendTeamChatMessage({ eventName, message, silent = false }: WithNotification): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/messaging/${eventName}/team/chat`,
      body: { message },
      failMessage: this.t(i18nKeys.jamChat.supportChat.errors.sendFail),
      silent,
    });
  }

  async markAllMessagesAsReadForParticipant({ eventName, silent = false }: CommonParams): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/messaging/participantMessages/${eventName}/markAsRead`,
      failMessage: this.t(i18nKeys.jamMessages.errors.failedMarkRead),
      silent,
    });
  }

  async deleteAllMessagesForParticipant({ eventName, silent = false }: CommonParams): Promise<void> {
    await this.apiClient.delete({
      path: `${GAME_API_PREFIX}/messaging/participantMessages/${eventName}`,
      successMessage: this.t(i18nKeys.jamMessages.success.messagesDeleted),
      failMessage: this.t(i18nKeys.jamMessages.errors.failedDeleteMessages),
      silent,
    });
  }

  async sendMessageToEvent({ eventName, notificationData, silent = false }: WithNotification): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/messaging/send`,
      body: notificationData,
      successMessage: this.t(i18nKeys.facilitator.messaging.form.actions.messageSent),
      failMessage: this.t(i18nKeys.facilitator.messaging.form.errors.failedMessage),
      silent,
    });
  }

  async sendMessageToTeam({ eventName, notificationData, teamName, silent = false }: WithNotification): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/messaging/send/teams`,
      body: { notificationData, teamName },
      successMessage: this.t(i18nKeys.facilitator.messaging.form.actions.messageSent),
      failMessage: this.t(i18nKeys.facilitator.messaging.form.errors.failedMessage),
      silent,
    });
  }

  async sendMessageToParticipant({
    eventName,
    notificationData,
    participantLogin,
    silent = false,
  }: WithNotification): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/facilitator/${eventName}/messaging/send/participants/${participantLogin}`,
      body: notificationData,
      successMessage: this.t(i18nKeys.facilitator.messaging.form.actions.messageSent),
      failMessage: this.t(i18nKeys.facilitator.messaging.form.errors.failedMessage),
      silent,
    });
  }
}
