import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useUser } from '../../store/user.context';
import { AuthRole } from '../../constants/shared/auth-roles';
import { RoutePath } from '@/src/RoutePath';
import { useAuth } from '@/src/store/auth.context';

interface RestrictedRouteProps {
  exact: boolean | undefined;
  path: string;
  key: number;
  requiredGroups: AuthRole[] | undefined;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

/**
 * This is used to determine if a user is authenticated and if they are allowed to visit the page they navigated to.
 * If they are: they proceed to the page, but if not, they are redirected to the root with a flashbar error message.
 *
 * @param Component the route component to direct the user to the correct path
 * @param requiredGroups array of groups that are allowed access to the component
 * @param rest any other parameters necessary for Route to function
 * @constructor
 */
const RestrictedRoute: React.FC<RestrictedRouteProps> = ({ component: Component, requiredGroups, ...rest }) => {
  const { user } = useUser();
  const { authClient } = useAuth();
  const history = useHistory();

  // check if user has access to all requiredGroups
  let hasAccess = false;
  if (user && requiredGroups) {
    hasAccess = requiredGroups.some(group => user.memberOfGroup(group as string)) // match of any group in the list gives user the access
  } else if (user && !requiredGroups) {
    hasAccess = true // if required groups is empty allow access
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user == null) {
          return authClient.signIn(history.location);
        } else if (user && hasAccess) {
          return (
            <div className="mb-20">
              <Component {...props} />
            </div>
          );
        } else {
          return <Redirect to={RoutePath.JAM_403}/>
        }
      }}
    />
  );
};

export default RestrictedRoute;
