export class ChatMessage {
  sender: string;
  message: string;
  timeSent: number;
  automated = false;
  senderParticipantId: string;

  static fromPlainObject(obj: any): ChatMessage {
    return Object.assign(new ChatMessage(), obj) as ChatMessage;
  }
}

export type ChatType = 'team' | 'support';
