import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  ExpandableSection,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import JamFacilitatorTeamModal from './JamFacilitatorTeamModal';
import { JamFacilitatorTeamsAvailability, TEAM_ACTION } from '@/src/types/JamFacilitator';
import { useToolPanel } from '@/src/store/tool-panel.context';
import { useJamFacilitator } from '@/src/store/jam-facilitator.context';
import { SkillRank } from './FacilitatorParticipantContainer';
import { useFlashbars } from '@/src/store/flashbar.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';

interface ExpandableDropdownProps {
  header: string | React.ReactElement;
  counter: string;
  enableActions?: boolean;
  enableViewProgress?: boolean;
  teamInfo?: JamFacilitatorTeamsAvailability;
  teamRank?: number;
  updateTeam?: (updateTeam: boolean) => void;
  defaultExpanded?: boolean;
  onSectionToggle?: (toggle: boolean) => void;
}

interface ChangeRequestModal {
  cheating: boolean;
  teamNameChange: boolean;
  teamPasswordChange: boolean;
}

const ExpandableDetails: React.FC<ExpandableDropdownProps> = ({
  header,
  counter,
  enableActions = true,
  enableViewProgress = true,
  teamInfo,
  children,
  teamRank,
  updateTeam,
  defaultExpanded,
  onSectionToggle,
}) => {
  const { t } = useTranslation();
  const { eventName } = useJamEventDetails();
  const { loadTeamProgressData, changeTeamName, changeTeamPassword, lockTeamName, unlockTeamName } =
    useJamFacilitator();
  const { toggleFacilitatorParticipantsInfo } = useToolPanel();
  const { addErrorFlashbar } = useFlashbars();
  const [newTeamName, setNewTeamName] = React.useState('');
  const [newTeamPassword, setNewTeamPassword] = React.useState({ pass: '', confirmPass: '' });
  const [visibleModal, setVisibleModal] = React.useState<ChangeRequestModal>({
    cheating: false,
    teamNameChange: false,
    teamPasswordChange: false,
  });
  const { modals, label, buttons, teamActions } = i18nKeys.facilitator.participants;

  const handleModal = (value: string) => {
    switch (value) {
      case TEAM_ACTION.CHANGE_NAME:
        return setVisibleModal({ ...visibleModal, teamNameChange: true });
      case TEAM_ACTION.LOCK_NAME:
        void (!teamInfo?.renameEnabled ? unlockTeamName : lockTeamName)(eventName, teamInfo?.name ?? '');
        return;
      case TEAM_ACTION.CHANGE_PASSWORD:
        return setVisibleModal({ ...visibleModal, teamPasswordChange: true });
      case TEAM_ACTION.REMOVE_PASSWORD:
        void changeTeamPassword(eventName, teamInfo?.name ?? '', '');
        return;
      default:
        return;
    }
  };

  const handleVisibleModal = (value: string, isSubmitted: boolean) => {
    if (value === 'cheating') {
      setVisibleModal({ ...visibleModal, cheating: false });
    } else if (value === 'teamNameChange') {
      if (isSubmitted) void changeTeamName(eventName, teamInfo?.name ?? '', newTeamName);
      setNewTeamName('');
      setVisibleModal({ ...visibleModal, teamNameChange: false });
      if (updateTeam) updateTeam(true);
    } else if (value === 'teamPasswordChange') {
      if (newTeamPassword.pass !== newTeamPassword.confirmPass) {
        addErrorFlashbar(t(i18nKeys.facilitator.participants.errors.passwordNotMatched));
      } else {
        if (isSubmitted) void changeTeamPassword(eventName, teamInfo?.name ?? '', newTeamPassword.confirmPass);
        setNewTeamPassword({ pass: '', confirmPass: '' });
        setVisibleModal({ ...visibleModal, teamPasswordChange: false });
      }
    }
  };

  const handleToolPanelToggle = (teamId: string) => {
    toggleFacilitatorParticipantsInfo();
    void loadTeamProgressData(eventName, teamId);
  };

  const TeamAction = [
    { text: t(teamActions.changeTeamName), id: TEAM_ACTION.CHANGE_NAME },
    {
      text: teamInfo && !teamInfo.renameEnabled ? t(teamActions.UnlockTeamName) : t(teamActions.lockTeamName),
      id: TEAM_ACTION.LOCK_NAME,
    },
    { text: t(teamActions.changeTeamPassword), id: TEAM_ACTION.CHANGE_PASSWORD },
    { text: t(teamActions.removeTeamPassword), id: TEAM_ACTION.REMOVE_PASSWORD },
  ];

  return (
    <React.Fragment>
      <ExpandableSection
        variant="container"
        defaultExpanded={defaultExpanded}
        headerText={header}
        headerCounter={!enableActions && counter ? counter : ''}
        headerInfo={teamRank && teamRank > 0 ? <SkillRank rank={parseFloat(teamRank.toFixed(1))} /> : ''}
        onChange={({ detail }) => onSectionToggle?.(detail.expanded)}
        headerActions={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="l">
              {enableActions && (
                <>
                  {teamInfo?.suspectedCheater && (
                    <Box padding={{ top: 'xs' }}>
                      <span onClick={() => setVisibleModal({ ...visibleModal, cheating: true })}>
                        <Badge color="red">{t(label.teamSuspectedOfCheating)}</Badge>
                      </span>
                    </Box>
                  )}
                  <ButtonDropdown onItemClick={({ detail }) => handleModal(detail.id)} items={TeamAction}>
                    {t(buttons.actions)}
                  </ButtonDropdown>
                </>
              )}
              {enableViewProgress && (
                <Button onClick={() => handleToolPanelToggle((teamInfo && teamInfo.name) || 'Facilitators')}>
                  {t(buttons.viewProgress)}
                </Button>
              )}
            </SpaceBetween>
          </Box>
        }>
        {children}
      </ExpandableSection>

      {visibleModal.cheating && (
        <JamFacilitatorTeamModal
          name="cheating"
          headingText={t(modals.cheatingModal.title)}
          handleModal={handleVisibleModal}>
          <SpaceBetween direction="vertical" size="xxs">
            <Box variant="h5">{t(modals.cheatingModal.subtitle)}:</Box>
            <Box variant="p">{t(modals.cheatingModal.body)}</Box>
          </SpaceBetween>
        </JamFacilitatorTeamModal>
      )}

      {visibleModal.teamNameChange && (
        <JamFacilitatorTeamModal
          name="teamNameChange"
          headingText={t(modals.changeTeamName.title)}
          withInput={visibleModal.teamNameChange}
          visibleTeamNameChange={visibleModal.teamNameChange}
          handleModal={handleVisibleModal}>
          <SpaceBetween direction="vertical" size="xxs">
            <Box variant="p">{t(modals.changeTeamName.enterNewTeamName)}</Box>
            <Input onChange={({ detail }) => setNewTeamName(detail.value)} value={newTeamName} />
          </SpaceBetween>
        </JamFacilitatorTeamModal>
      )}

      {visibleModal.teamPasswordChange && (
        <JamFacilitatorTeamModal
          name="teamPasswordChange"
          headingText={t(modals.changeTeamPassword.title)}
          withInput={visibleModal.teamPasswordChange}
          visibleTeamNameChange={false}
          handleModal={handleVisibleModal}>
          <SpaceBetween direction="vertical" size="xxs">
            <Box variant="div">
              <Box variant="p">
                {t(modals.changeTeamPassword.enterNewPassword, { teamName: teamInfo?.displayName ?? 'Current Team' })}
              </Box>
              <Input
                onChange={({ detail }) => setNewTeamPassword({ ...newTeamPassword, pass: detail.value })}
                value={newTeamPassword.pass}
                type="password"
              />
            </Box>
            <Box variant="div">
              <Box variant="p">{t(modals.changeTeamPassword.confirmPassword)}</Box>
              <Input
                onChange={({ detail }) => setNewTeamPassword({ ...newTeamPassword, confirmPass: detail.value })}
                value={newTeamPassword.confirmPass}
                type="password"
              />
            </Box>
          </SpaceBetween>
        </JamFacilitatorTeamModal>
      )}
    </React.Fragment>
  );
};

export default ExpandableDetails;
