import { JamEventSponsor } from './JamEventSponsor';
import { JamParticipantProfile } from './JamParticipantProfile';

export class JamConsent {
  sponsor: JamEventSponsor = new JamEventSponsor();
  optedIn = false;
  eventName: string | null = null;
  challengeId: string | null = null;
  challengeTitle: string | null = null;
  eventTitle: string | null = null;
  profile: JamParticipantProfile | null = null;
  eventUrlPath: string | null = null;

  static fromPlainObject(obj: any): JamConsent {
    const consent: JamConsent = Object.assign(new JamConsent(), obj || {}) as JamConsent;
    if (consent.profile) {
      consent.profile = JamParticipantProfile.fromPlainObject(consent.profile);
    }
    return consent;
  }

  isEventConsent(): boolean {
    return !this.challengeId;
  }
}
