import React from 'react';
import EventCard from '../molecules/EventCard/EventCard';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { IEventTemplateWithSubscribedStatus } from '@/src/types/EventTemplate';

export const CARD_DEFINITIONS = {
  header: (_item: IEventTemplateWithSubscribedStatus) => null,
  sections: [
    {
      id: 'event-card',
      content: (item: IEventTemplateWithSubscribedStatus) => (
        <div className="event-card" key={item.id}>
          <EventCard
            id={item.id}
            imgSrc={item.imagePresignedUrl}
            rating={item.rating}
            title={item.name}
            tag={item.topics}
            learningType={item.learningType}
            duration={item.duration}
            isSubscribed={item.isSubscribed}
            teamSize={3}
          />
        </div>
      ),
    },
  ],
};

export const PAGE_SIZE_OPTIONS = [
  { value: 12, label: i18nKeys.catalog.catalogFilter.preferences.pageSize.value },
  { value: 24, label: i18nKeys.catalog.catalogFilter.preferences.pageSize.value },
  { value: 48, label: i18nKeys.catalog.catalogFilter.preferences.pageSize.value },
];

export const SORT_OPTIONS = [
  {
    label: i18nKeys.catalog.catalogFilter.sortType.newest,
    value: '0',
    field: 'createdDate',
    isDescending: true,
  },
  {
    label: i18nKeys.catalog.catalogFilter.sortType.oldest,
    value: '1',
    field: 'createdDate',
    isDescending: false,
  },
  {
    label: i18nKeys.catalog.catalogFilter.sortType.lowestPrice,
    value: '2',
    field: 'price',
    isDescending: false,
  },
  {
    label: i18nKeys.catalog.catalogFilter.sortType.highestPrice,
    value: '3',
    field: 'price',
    isDescending: true,
  },
];
