/* eslint-disable */
import {capitalize, snakeCase} from 'lodash';

export class LanguageCodeOption {
  label: string;
  value: string;
}

const LanguageCodeMap: any = {
  ARABIC: 'ar',
  CHINESE_SIMPLIFIED: 'zh',
  CHINESE_TRADITIONAL: 'zh-TW',
  CZECH: 'cs',
  DANISH: 'da',
  DUTCH: 'nl',
  ENGLISH: 'en',
  FINNISH: 'fi',
  FRENCH: 'fr',
  GERMAN: 'de',
  HEBREW: 'he',
  HINDI: 'hi',
  INDONESIAN: 'id',
  ITALIAN: 'it',
  JAPANESE: 'ja',
  KOREAN: 'ko',
  MALAY: 'ms',
  NORWEGIAN: 'no',
  PERSIAN: 'fa',
  POLISH: 'pl',
  PORTUGUESE: 'pt',
  RUSSIAN: 'ru',
  SPANISH: 'es',
  SWEDISH: 'sv',
  TURKISH: 'tr',
};

const labels: any = {
  ARABIC: 'العربية',
  CHINESE_SIMPLIFIED: '中文',
  CHINESE_TRADITIONAL: '繁體中文',
  CZECH: 'český',
  DANISH: 'Dansk',
  DUTCH: 'Nederlands',
  ENGLISH: 'English',
  FINNISH: 'Suomalainen',
  FRENCH: 'Français',
  GERMAN: 'Deutsche',
  HEBREW: 'עברית',
  HINDI: 'हिंदी',
  INDONESIAN: 'Bahasa Indonesia',
  ITALIAN: 'Italiano',
  JAPANESE: '日本語',
  KOREAN: '한국어',
  MALAY: 'Melayu',
  NORWEGIAN: 'Norsk',
  PERSIAN: 'ايراني',
  POLISH: 'Polski',
  PORTUGUESE: 'portuguesa',
  RUSSIAN: 'Русский',
  SPANISH: 'Español',
  SWEDISH: 'Svenska',
  TURKISH: 'Türk',
};

const displayName = (name: string): string => {
  return snakeCase(name || '').split('_').map(capitalize).join(' ');
};

const LanguageCodesList: LanguageCodeOption[] = Object.keys(LanguageCodeMap).map(name => {
  let label = labels[name];
  if (name !== 'ENGLISH') {
    label += ' ('+ displayName(name) + ')';
  }
  return {
    label: label,
    value: LanguageCodeMap[name],
  }
});

const findByValue = (value: string): LanguageCodeOption => {
  return LanguageCodesList.find((item: LanguageCodeOption) => item.value === value)!;
};

// noinspection JSUnusedGlobalSymbols
export const LanguageCodes = {
  map: LanguageCodeMap,
  list: LanguageCodesList,
  findByValue,
};