import React from 'react';
import { Container, Header, ColumnLayout, SpaceBetween, Box } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { DetailHeader } from '@/src/components/ui/molecules/EventTemplate';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

// types
import { EventDurationInfo, EventDurationType, EventLearningType } from '@/src/types/EventTemplate';
import { EVENT_DURATION_DICTIONARY } from '@/src/constants/event-template.constants';

export interface IEventNameDurationReviewProps {
  name: string;
  learningType: EventLearningType;
  type: EventDurationType;
  data: EventDurationInfo;
  onEditClick: () => void;
}

const { stepTitle, cardTitle, eventDurationTitle, eventLearningTypeTitle, eventNameTitle } =
  i18nKeys.eventTemplates.step5.steps.step1;
const { hours, challenges, perPerson } = i18nKeys.eventTemplates.step1.eventDuration;
const { team, individual } = i18nKeys.eventTemplates.step1.eventLearningType;

export const EventNameDurationReview: React.FC<IEventNameDurationReviewProps> = ({
  name,
  learningType,
  type,
  data,
  onEditClick,
}) => {
  const { t } = useTranslation();

  const getDurationInfo = () => {
    return `${t(EVENT_DURATION_DICTIONARY[type])} (${data.hours} ${t(hours)}, ${data.minChallenges} ${t(
      challenges
    )}, $${data.price}.00 ${t(perPerson)})`;
  };

  return (
    <div>
      <DetailHeader marginTop={0} heading={t(stepTitle)} onEditClick={onEditClick} />
      <Container header={<Header variant="h2">{t(cardTitle)}</Header>}>
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">{t(eventNameTitle)}</Box>
              <div>{name}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">{t(eventLearningTypeTitle)}</Box>
              <div>{t(learningType === EventLearningType.INDIVIDUAL ? individual.label : team.label)}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">{t(eventDurationTitle)}</Box>
              <div>{getDurationInfo()}</div>
            </div>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
};
