import React, { useEffect, useMemo, useState } from 'react';
import { Form } from '@amzn/awsui-components-react';

// components
import DetailSection from '@/src/components/event-templates/EventTemplateCreate/Sections/ConfigureEvent/DetailSection';
import ImageSection from '@/src/components/event-templates/EventTemplateCreate/Sections/ConfigureEvent/ImageSection';
import Rationale from '@/src/components/event-templates/EventTemplateCreate/Sections/ConfigureEvent/Rationale';
import {
  EventDurationType,
  EventLearningType,
  EventTemplateDetailFields,
  EventTemplateRationale,
  IEventTemplate,
  IEventTemplateOffer,
} from '@/src/types/EventTemplate';
import { ChallengeListItem } from '@/src/types/Challenge';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { eventTemplateDetailsValidator } from '@/src/utils/event-template.validation.utils';
import JamSpinner from "@/src/components/common/JamSpinner";

export interface IEventDetailGeneralEditProps {
  eventTemplate: IEventTemplate | null;
  rationale: EventTemplateRationale | undefined;
  eventTemplateEditMode: boolean;
  selectedChallenges: ChallengeListItem[];
  offers?: Map<EventDurationType, IEventTemplateOffer>
  onSummaryChange: (summmary: string) => void;
  onTopicsChange: (topics: string[]) => void;
  onTeamSizeChange: (teamSize: number) => void;
  onMinParticipantsChange: (value: number) => void;
  onMaxParticipantsChange: (value: number) => void;
  onTagsChange: (tags: string[]) => void;
  onLearningTypeChange: (learningType: EventLearningType) => void;
  onDurationChange: (eventDuration: EventDurationType) => void;
  onImageChange: (imageFile?: File) => void;
  onRationaleChange: (id: string, rational: string) => void;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
  handleCancel: () => void;
}

const EventDetailGeneralEdit: React.FC<IEventDetailGeneralEditProps> = ({
  eventTemplate,
  selectedChallenges,
  rationale,
  offers,
  onSummaryChange,
  onTopicsChange,
  onTeamSizeChange,
  onMinParticipantsChange,
  onMaxParticipantsChange,
  onLearningTypeChange,
  onDurationChange,
  onTagsChange,
  onImageChange,
  onRationaleChange,
  validationHandler,
  handleCancel,
}) => {
  if (!eventTemplate) {
    return <JamSpinner />;
  }

  const { t } = useTranslation();

  const [eventDetailsSummaryError, setEventDetailsSummaryError] = useState('');
  const [eventDetailsTopicError, setEventDetailsTopicError] = useState('');
  const [eventDetailsTeamSizeError, setEventDetailsTeamSizeError] = useState('');
  const [eventDetailsMinParticipantsError, setEventDetailsMinParticipantsError] = useState('');
  const [eventDetailsMaxParticipantsError, setEventDetailsMaxParticipantsError] = useState('');
  const [eventDetailsTagsError, setEventDetailsTagsError] = useState('');

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []);

  const validator = useMemo(
    () =>
      eventTemplateDetailsValidator(
        eventTemplate.summary,
        eventTemplate.topics,
        eventTemplate.recommendedTeamSize,
        eventTemplate.minParticipants,
        eventTemplate.maxParticipants,
        eventTemplate.tags,
        t(i18nKeys.eventTemplates.step4.summary.errorText),
        t(i18nKeys.eventTemplates.step4.topic.errorText),
        t(i18nKeys.eventTemplates.step4.recommendedTeamSize.errorText),
        {
          required: t(i18nKeys.eventTemplates.step4.participants.min.required),
          notGreaterThanMax: t(i18nKeys.eventTemplates.step4.participants.min.notGreaterThanMax),
        },
        {
          required: t(i18nKeys.eventTemplates.step4.participants.max.required),
          notLessThanMin: t(i18nKeys.eventTemplates.step4.participants.max.notLessThanMin),
        },
        t(i18nKeys.eventTemplates.step4.tags.errorText),
        new Map<EventTemplateDetailFields, (error: string) => void>([
          [EventTemplateDetailFields.EVENT_SUMMARY, (error: string) => setEventDetailsSummaryError(error)],
          [EventTemplateDetailFields.EVENT_TOPIC, (error: string) => setEventDetailsTopicError(error)],
          [EventTemplateDetailFields.EVENT_TEAM_SIZE, (error: string) => setEventDetailsTeamSizeError(error)],
          [
            EventTemplateDetailFields.EVENT_MIN_PARTICIPANTS,
            (error: string) => setEventDetailsMinParticipantsError(error),
          ],
          [
            EventTemplateDetailFields.EVENT_MAX_PARTICIPANTS,
            (error: string) => setEventDetailsMaxParticipantsError(error),
          ],
          [EventTemplateDetailFields.EVENT_TAGS, (error: string) => setEventDetailsTagsError(error)],
        ])
      ),
    [
      eventTemplate.summary,
      eventTemplate.topics,
      eventTemplate.recommendedTeamSize,
      eventTemplate.minParticipants,
      eventTemplate.maxParticipants,
      eventTemplate.tags,
      eventTemplateDetailsValidator,
    ]
  );

  validationHandler(() => {
    const result = validator.isValidSection(true);
    if (result) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  });

  return (
    <>
      <form>
        <Form>
          <DetailSection
            summary={eventTemplate.summary}
            teamSize={eventTemplate.recommendedTeamSize}
            minParticipants={eventTemplate.minParticipants}
            maxParticipants={eventTemplate.maxParticipants}
            topics={eventTemplate.topics}
            learningType={eventTemplate.learningType}
            duration={eventTemplate.duration}
            tags={eventTemplate.tags}
            onSummaryChange={onSummaryChange}
            onTeamSizeChange={onTeamSizeChange}
            onMinParticipantsChange={onMinParticipantsChange}
            onMaxParticipantsChange={onMaxParticipantsChange}
            onTopicsChange={onTopicsChange}
            onLearningTypeChange={onLearningTypeChange}
            onDurationChange={onDurationChange}
            onTagsChange={onTagsChange}
            validator={validator}
            offers={offers}
            eventDetailsSummaryError={eventDetailsSummaryError}
            eventDetailsTopicError={eventDetailsTopicError}
            eventDetailsTeamSizeError={eventDetailsTeamSizeError}
            eventDetailsMinParticipantsError={eventDetailsMinParticipantsError}
            eventDetailsMaxParticipantsError={eventDetailsMaxParticipantsError}
            eventDetailsTagsError={eventDetailsTagsError}
          />
          <ImageSection onImageChange={onImageChange} imageUrl={eventTemplate.imagePresignedUrl} />
        </Form>
      </form>
      <Rationale
        selectedChallengeListItems={selectedChallenges}
        rationale={rationale}
        onRationaleChange={onRationaleChange}
      />
    </>
  );
};

export default EventDetailGeneralEdit;
