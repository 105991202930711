import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';

// components
import { EventImageReview } from '@/src/components/ui/molecules/EventTemplate';
import ChallengeRationalDetails from '@/src/components/ui/molecules/EventTemplate/ChallengeRationaleDetails';
import EventDetails from '@/src/components/ui/molecules/EventTemplate/EventDetails';

// types
import { EventDurationInfo, IEventTemplate } from '@/src/types/EventTemplate';

// utils
import { getSelectedTags, getSelectedTopics } from '@/src/utils/event-template.utils';

export interface IEventDetailGeneralSectionProps {
  eventTemplate: IEventTemplate;
  challengeTitles: { [challengeId: string]: string };
  getEventDurationInfo: () => EventDurationInfo | undefined;
}

const EventDetailGeneralSection: React.FC<IEventDetailGeneralSectionProps> = ({
  eventTemplate,
  challengeTitles,
  getEventDurationInfo,
}) => {
  const topics = getSelectedTopics(eventTemplate?.topics);
  const imageUrl = eventTemplate.imagePresignedUrl && eventTemplate.imagePresignedUrl;
  const hours = getEventDurationInfo()?.hours;
  const tags = getSelectedTags(eventTemplate?.tags);

  return (
    <SpaceBetween size="l">
      <EventDetails eventTemplate={eventTemplate} topics={topics} hours={hours} tags={tags} />
      <EventImageReview imageUrl={imageUrl} />
      <ChallengeRationalDetails challenges={eventTemplate.challenges} challengeTitles={challengeTitles} />
    </SpaceBetween>
  );
};

export default EventDetailGeneralSection;
