/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { isCustomReporting } from "@/src/utils/env.utils";

declare global {
    interface Window {
        AWSMA: any;
    }
}

const createEventTrigger = (eventType: string, eventName: string, pageUrl: string, description: string, data: object = {}) => {
    try {
        return () => {
            window.AWSMA.ready(() => {
                document.dispatchEvent(
                    new CustomEvent(window.AWSMA.TRIGGER_EVENT, {
                        detail: {
                            event: {
                                type: eventType,
                                name: eventName,
                                description
                            },
                            data,
                        },
                    })
                );
            });
        };
    }
    catch (error) {
        // handle error
    }
};

export const customEventTrigger = (eventType: string, eventName: string, pageUrl: string, description: string, data: object) => {
    const customReporting = isCustomReporting();
    if (customReporting) {
        const customEvent = createEventTrigger(eventType, eventName, pageUrl, description, data);
        if (typeof customEvent === 'function') {
            customEvent();
        }
    }
}

