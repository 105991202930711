import { isLocal, isPreProd } from './env.utils';

/**
 * Writes to console.log only if the current env is not prod.
 * Usage is exactly the same as console.log
 *
 * @param args
 */
export const preProdLogger = (...args: any[]) => {
  if (isPreProd()) {
    // eslint-disable-next-line no-console, @typescript-eslint/no-unsafe-argument
    console.log(...args);
  }
};

/**
 * Writes to console.log only if the current env is localhost.
 * Usage is exactly the same as console.log
 *
 * @param args
 */
export const localLogger = (...args: any[]) => {
  if (isLocal()) {
    // eslint-disable-next-line no-console, @typescript-eslint/no-unsafe-argument
    console.log(...args);
  }
};
