/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Box, ColumnLayout } from '@amzn/awsui-components-react';
import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { KeyValue } from '../../common/KeyValue';
import { ChartProperties } from '@/src/utils/chart.utils';
import AutoScalingChart from './Charts/AutoScaling';
import LabDashboard from './Charts/LabDashboard';
import { EventLabSummary } from '@/src/types/EventLabSummary';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface MetricsProps {
  autoScalingChartProperties: ChartProperties[];
  labChartProperties: ChartProperties[];
  summary: EventLabSummary;
}

const Metrics: React.FC<MetricsProps> = ({ autoScalingChartProperties, labChartProperties, summary }) => {
  const { t } = useTranslation();
  const stats = useMemo(() => {
    const labs = summary.nonMasterLabs;
    const totalAccounts = labs.length;
    const unusedAccounts = totalAccounts - labs.filter((lab) => lab.wasEverAssigned).length;
    const readyLabs = labs.filter((lab) => lab.ready).length;
    const terminatedLabs = labs.filter((lab) => lab.isInFinalStatus).length;
    const completedLabs = labs.filter((lab) => lab.wasEverInStatus('COMPLETED')).length;
    const liveLabs = labs.filter((lab) => lab.wasEverAssignable && !lab.isInFinalStatus).length;
    const restarts = labs.filter((lab) => lab.wasRestarted).length;

    return {
      totalAccounts,
      unusedAccounts,
      unusedRatio: totalAccounts > 0 ? unusedAccounts / totalAccounts : 0,
      restarts,
      readyLabs,
      completedLabs,
      terminatedLabs,
      liveLabs,
    };
  }, [summary]);

  return (
    <SpaceBetween size="m">
      <Container header={<Header variant="h3">{t(i18nKeys.eventLabs.metrics.title)}</Header>}>
        <ColumnLayout variant="text-grid" columns={4}>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.metrics.infoSections.challenges)}</strong>}>
            {summary.numChallenges}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.metrics.infoSections.participants)}</strong>}>
            {summary.numRegisteredParticipants} ({summary.minExpectedParticipants} expected)
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.metrics.infoSections.teams)}</strong>}>
            {summary.numTeams}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.metrics.infoSections.facilitators)}</strong>}>
            {summary.numFacilitators}
          </KeyValue>
        </ColumnLayout>
        <ColumnLayout columns={4}>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.metrics.infoSections.totalLabsNeeded)}</strong>}>
            <Box fontSize="body-s" color="text-body-secondary">
              {t(i18nKeys.eventLabs.metrics.infoSections.numLabsAccountNeeded)}
            </Box>
            {summary.totalDesiredLabs}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.metrics.infoSections.liveLabs)}</strong>}>
            <Box fontSize="body-s" color="text-body-secondary">
              {t(i18nKeys.eventLabs.metrics.infoSections.numDeployed)}
            </Box>
            {stats.liveLabs}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.metrics.infoSections.awsAccounts)}</strong>}>
            <Box fontSize="body-s" color="text-body-secondary">
              {t(i18nKeys.eventLabs.metrics.infoSections.numAccounts)}
            </Box>
            {stats.totalAccounts}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.metrics.infoSections.unusedAccounts)}</strong>}>
            <Box fontSize="body-s" color="text-body-secondary">
              {t(i18nKeys.eventLabs.metrics.infoSections.numWasted, { percent: Math.round(stats.unusedRatio * 100) })}
            </Box>
            <Box color={stats.unusedRatio < 0.1 ? 'text-status-error' : 'text-status-success'}>
              {stats.unusedAccounts}
            </Box>
          </KeyValue>
        </ColumnLayout>
      </Container>
      <SpaceBetween size="m">
        {autoScalingChartProperties.map((chartProperty) => (
          <Container key={chartProperty.id}>
            <Header variant="h3" description={chartProperty.subtitle}>
              {chartProperty.title}
            </Header>
            <Box margin={{ top: 'm' }}>
              <AutoScalingChart chartProperty={chartProperty} />
            </Box>
          </Container>
        ))}
        {labChartProperties.map((chartProperty) => (
          <Container key={chartProperty.id}>
            <Header variant="h3" description={chartProperty.subtitle}>
              {chartProperty.title}
            </Header>
            <Box margin={{ top: 'm' }}>
              <LabDashboard chartProperty={chartProperty} />
            </Box>
          </Container>
        ))}
      </SpaceBetween>
    </SpaceBetween>
  );
};

export default Metrics;
