import React from 'react';
import { Badge } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { i18nKeys } from '@/src/utils/i18n.utils';

import { EventLearningType } from '@/src/types/EventTemplate';

import './EventLearningTypeBadge.scss';

interface IEventLearningTypeBadgeProps {
  learningType?: EventLearningType;
}

export const EventLearningTypeBadge: React.FC<IEventLearningTypeBadgeProps> = ({
  learningType = EventLearningType.TEAM,
}) => {
  const { t } = useTranslation();

  return (
    <Badge data-learning-type={learningType}>
      {t(
        learningType === EventLearningType.INDIVIDUAL
          ? i18nKeys.catalog.learningType.individual
          : i18nKeys.catalog.learningType.team
      )}
    </Badge>
  );
};
