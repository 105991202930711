import { ExpandableSection, FormField, Header, Multiselect } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengePropAction,
  ChallengeSettingsFields,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { Challenge } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { KeyValue } from '../../../common/KeyValue';
import { AWSServicesDefinitions, SupportedRegionsDefinitions } from '../../challengesCommon/ChallengeOptionDefinitions';

interface ChallengeAWSSettingDetailProps {
  challenge: Challenge;
}

const ChallengeAWSSettingDetail: React.FC<ChallengeAWSSettingDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const field = i18nKeys.challenges.subSections.settings;
  const { editMode, editedChallenge, handleUpdateChallengeProp, challengeSettingsValidator } = useCreateChallenge();

  const [awsSettings, setAwsSettings] = useState({
    awsServices: challenge.props.awsServices || [],
    regionAllowList: challenge.props.regionAllowlist || [],
  });

  const [awsServicesErrorText, setAwsServicesErrorText] = useState<string>('');
  const [supportedRegionsErrorText, setSupportedRegionsErrorText] = useState<string>('');

  useEffect(() => {
    // reset error fields when editMode is changed
    if (!editMode) {
      setAwsServicesErrorText('');
      setSupportedRegionsErrorText('');
    }
  }, [editMode]);

  const validator = challengeSettingsValidator(
    new Map<ChallengeSettingsFields, (error: string) => void>([
      [ChallengeSettingsFields.AWS_SERVICES, (error: string) => setAwsServicesErrorText(error)],
      [ChallengeSettingsFields.REGION_ALLOWLIST, (error: string) => setSupportedRegionsErrorText(error)],
    ])
  );

  useEffect(() => {
    if (editedChallenge) {
      setAwsSettings({
        awsServices: editedChallenge.props.awsServices,
        regionAllowList: editedChallenge.props.regionAllowlist,
      });
    }
  }, [editedChallenge]);

  const handleAwsSettingsUpdate = (event: {
    target: { name: string; selectedOptions: readonly OptionDefinition[] };
  }) => {
    const { name, selectedOptions } = event.target;
    const newSettings = {
      ...awsSettings,
      [name]: selectedOptions.map((option) => option.value),
    };

    handleUpdateChallengeProp(ChallengePropAction.AWS_SETTINGS, newSettings);
    setAwsSettings(newSettings);
  };

  const [expanded, setExpanded] = useState(true);

  return (
    <ExpandableSection
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.awsSettings)}</Header>}>
      <Columns columns={2} variant="default">
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.awsServices)}>
            {!editMode &&
              challenge.props.awsServices &&
              challenge.props.awsServices.map((serviceName, i) => (
                <ul key={i} style={{ listStyleType: 'none' }}>
                  {serviceName}
                </ul>
              ))}
            {!editMode && !challenge.props.awsServices && t(i18nKeys.general.none)}
            {editMode && (
              <FormField
                constraintText={t(field.container_3.fields.awsServices.constraint)}
                errorText={awsServicesErrorText}>
                <Multiselect
                  onBlur={() => validator.isValidField(ChallengeSettingsFields.AWS_SERVICES)}
                  selectedOptions={
                    awsSettings.awsServices.map(
                      (serviceName) =>
                        AWSServicesDefinitions.find((service) => service.value === serviceName) as OptionDefinition
                    ) || []
                  }
                  onChange={({ detail }) =>
                    handleAwsSettingsUpdate({
                      target: { name: ChallengeSettingsFields.AWS_SERVICES, selectedOptions: detail.selectedOptions },
                    })
                  }
                  options={AWSServicesDefinitions}
                  selectedAriaLabel={t(i18nKeys.general.selected)}
                  placeholder={t(i18nKeys.challenges.subSections.settings.container_3.fields.awsServices.placeholder)}
                />
              </FormField>
            )}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.supportedRegions)}>
            {!editMode &&
              challenge.props.allowedRegions &&
              challenge.props.allowedRegions.map((region, i) => (
                <ul key={i} style={{ listStyleType: 'none' }}>
                  <li>{region}</li>
                </ul>
              ))}
            {!editMode && !challenge.props.allowedRegions && t(i18nKeys.general.none)}
            {editMode && (
              <FormField
                constraintText={t(field.container_3.fields.supportedRegions.constraint)}
                errorText={supportedRegionsErrorText}>
                <Multiselect
                  selectedOptions={
                    awsSettings.regionAllowList.map(
                      (region) =>
                        SupportedRegionsDefinitions.find(
                          (supportedRegion) => supportedRegion.value === region
                        ) as OptionDefinition
                    ) || []
                  }
                  onChange={({ detail }) =>
                    handleAwsSettingsUpdate({
                      target: {
                        name: ChallengeSettingsFields.REGION_ALLOWLIST,
                        selectedOptions: detail.selectedOptions,
                      },
                    })
                  }
                  onBlur={() => validator.isValidField(ChallengeSettingsFields.REGION_ALLOWLIST)}
                  options={SupportedRegionsDefinitions}
                  selectedAriaLabel={t(i18nKeys.general.selected)}
                  placeholder={t(
                    i18nKeys.challenges.subSections.settings.container_3.fields.supportedRegions.placeholder
                  )}
                />
              </FormField>
            )}
          </KeyValue>
        </Column>
      </Columns>
    </ExpandableSection>
  );
};

export default ChallengeAWSSettingDetail;
