import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Button } from '@amzn/awsui-components-react';

interface RefreshButtonProps {
  onClickHandler: () => void;
  disabled: boolean;
}

const RefreshButton: React.FC<RefreshButtonProps> = ({
  onClickHandler,
  disabled = false,
}: RefreshButtonProps) => {
  const { t } = useTranslation();
  return <Button
    variant="normal"
    iconName="refresh"
    data-testid="refresh-button"
    ariaLabel={t(i18nKeys.general.refresh)}
    disabled={disabled}
    onClick={onClickHandler}
  />;
};

export default RefreshButton;
