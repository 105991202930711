import React, { useMemo } from 'react';
import { Box } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import ChallengeDetailsCard from './ChallengeDetailsCard';
import { IJamChallenge } from '@/src/types/JamChallenges';
import { useJamChallenge } from '@/src/store/jam-challenge.context';

const JamChallengeDetails: React.FC = () => {
  const { t } = useTranslation();
  const { jamChallengeData } = useJamChallenge();
  const SolvedChallenges: IJamChallenge[] | undefined = useMemo(
    () => jamChallengeData?.challenges?.filter((challenge: IJamChallenge) => challenge?.solved),
    [jamChallengeData?.challenges]
  );

  const UnSolvedChallenges: IJamChallenge[] | undefined = useMemo(
    () => jamChallengeData?.challenges?.filter((challenge: IJamChallenge) => !challenge?.solved),
    [jamChallengeData?.challenges]
  );

  return (
    <React.Fragment>
      <ChallengeDetailsCard challenges={UnSolvedChallenges} />
      {SolvedChallenges && SolvedChallenges.length > 0 && (
        <Box margin={{ bottom: 'xxxl' }}>
          <Box variant="h2" margin={{ vertical: 's' }}>
            {t(i18nKeys.myJams.challenges.solved)}
          </Box>
          <ChallengeDetailsCard challenges={SolvedChallenges} />
        </Box>
      )}
    </React.Fragment>
  );
};

export default JamChallengeDetails;
