import React from 'react';
import { Box } from '@amzn/awsui-components-react';
import { Button } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';

interface TableEmptyStateProps {
  title: string;
  subtitle?: string;
  onClearFilter?: () => void;
}

export const TableEmptyState: React.FC<TableEmptyStateProps> = ({ title, subtitle, onClearFilter }) => {
  const { t } = useTranslation();
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {onClearFilter && <Button onClick={() => onClearFilter()}>{t(i18nKeys.tables.noMatch.clearFilter)}</Button>}
    </Box>
  );
};
