import React from 'react';
import { Box, Link, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { DashboardJamMiniEvent, JamEventTypes } from '@/src/types/JamDashboard';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { formatJamEventTime } from '@/src/utils/myjam.utils';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';

interface JamListProps {
  events: DashboardJamMiniEvent[];
  eventType: JamEventTypes;
}

const JamList: React.FC<JamListProps> = ({ events, eventType }) => {
  const { t } = useTranslation();

  const getLink = (eventSlug: string) => {
    let link = '';

    if (eventType === JamEventTypes.PastEvents) {
      link = JAM_EVENT_DETAILS_ROUTES.Report.resolve(eventSlug);
    } else {
      link = JAM_EVENT_DETAILS_ROUTES.Challenges.resolve(eventSlug);
    }
    /**
     * there are lots of information saved in the
     * context for existing opened event
     *
     * reloading the page would ensure all the
     * contexts are reset and won't conflict
     * with previous event content
     */
    return link;
  };

  return (
    <Table<DashboardJamMiniEvent>
      variant="embedded"
      columnDefinitions={[
        {
          id: 'title',
          header: t(i18nKeys.myJams.eventTabs.table.title),
          cell: ({ title, eventPath, campaignEvent, eventId }) => (
            /**
             * for campaign we can not use slug
             * hence we will always use eventId
             * for campaign events and not slug
             */
            <Link href={getLink(campaignEvent ? eventId : eventPath)}>{title}</Link>
          ),
        },
        {
          id: 'progress',
          header: t(i18nKeys.myJams.eventTabs.table.progress),
          cell: ({ progress }) =>
            progress
              ? t(i18nKeys.myJams.eventTabs.table.progressChallenges, {
                  count: progress.solvedChallenges,
                  total: progress.totalChallenges,
                })
              : '--',
        },
        {
          id: 'startTime',
          header: t(i18nKeys.myJams.eventTabs.table.startTime),
          cell: ({ startTime }) => formatJamEventTime(startTime),
        },
        {
          id: 'endTime',
          header: t(i18nKeys.myJams.eventTabs.table.endTime),
          cell: ({ endTime }) => formatJamEventTime(endTime),
        },
      ]}
      items={events}
      empty={
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>{t(i18nKeys.myJams.eventTabs.table.emptyListMessage)}</b>
          </SpaceBetween>
        </Box>
      }
    />
  );
};

export default JamList;
