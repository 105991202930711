import { isEmpty, noop } from 'lodash';
import { CreateJamProfileFields, InputValidationHelper } from '../types/CreateJamProfile';
import { isEmailValid } from './string.utils';

const validateIsEmpty = (summary: string | undefined, errorText: string) => {
  const isValid = !isEmpty(summary);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

const validateEmailField = (email: string, errorText: string) => {
  let isValid = !isEmpty(email);
  if (isValid) {
    isValid = isEmailValid(email);
  }

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

const validateField = (
  validatorHelperByField: Map<CreateJamProfileFields, InputValidationHelper>,
  errorSetterByField: Map<CreateJamProfileFields, (error: string) => void>,
  field: CreateJamProfileFields,
  setError = true
) => {
  const validation = validatorHelperByField.get(field);
  if (!validation) {
    throw new Error('Validation logic does not exist!');
  }
  const isValid = validation.isValid();
  const error = validation.checkErrors();
  if (setError) {
    const setErrorFn = errorSetterByField.get(field) || noop;
    setErrorFn(error);
  }
  // preProdLogger('Validated', field, 'isValid:', isValid, 'error:', error);
  return isValid;
};

const validateSection = (
  validatorHelperByField: Map<CreateJamProfileFields, InputValidationHelper>,
  errorSetterByField: Map<CreateJamProfileFields, (error: string) => void>,
  setErrors = false
): boolean => {
  const fieldIsValid: boolean[] = [];
  const fields = Array.from(errorSetterByField.keys());
  for (const key of fields) {
    fieldIsValid.push(validateField(validatorHelperByField, errorSetterByField, key, setErrors));
  }
  return fieldIsValid.reduce((prev, next) => prev && next);
};

export const createJamProfileValidator: (
  email: string,
  displayName: string,
  country: string | undefined,
  language: string | undefined,
  emailError: string,
  displayNameError: string,
  countryError: string,
  languageError: string,
  errorSetterByField: Map<CreateJamProfileFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: CreateJamProfileFields, setError?: boolean) => boolean;
} = (
  email,
  displayName,
  country,
  language,
  emailError,
  displayNameError,
  countryError,
  languageError,
  errorSetterByField: Map<CreateJamProfileFields, (error: string) => void>
) => {
  const validatorHelperByField = new Map<CreateJamProfileFields, InputValidationHelper>([
    [CreateJamProfileFields.EMAIL, validateEmailField(email, emailError)],
    [CreateJamProfileFields.DISPLAY_NAME, validateIsEmpty(displayName, displayNameError)],
    [CreateJamProfileFields.LANGUAGE, validateIsEmpty(language, languageError)],
    [CreateJamProfileFields.COUNTRY, validateIsEmpty(country, countryError)],
  ]);

  const isValidField = (field: CreateJamProfileFields, setError = true) => {
    return validateField(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};
