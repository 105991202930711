import * as React from 'react';

interface ColumnProps {
  size: 's' | 'm' | 'l';
}

export const Column: React.FC<ColumnProps> = ({ children, size }) => {
  return (
    <div>
      <div className={`awsui-util-spacing-v-${size}`}>{children}</div>
    </div>
  );
};
