import React from 'react';
import { ChallengeFeedbackSummary } from '@/src/types/Challenge';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { toPercentage } from '@/src/utils/percentage.utils';
import { Box, ColumnLayout } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

const Block = ({ label, value }: { label: string; value: number | string }) => (
  <Box>
    <Box variant="h5">{label}</Box>
    <Box>{value}</Box>
  </Box>
);

interface FeedbackLearningOutcome {
  summary: ChallengeFeedbackSummary | undefined;
}

const FeedbackLearningOutcome: React.FC<FeedbackLearningOutcome> = ({ summary }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box variant="h2" margin={{ bottom: 'm' }}>
        {t(i18nKeys.challenges.challengeDetails.text.learningOutcome.title)}
      </Box>
      <ColumnLayout columns={4} variant="text-grid">
        <Block
          label={t(i18nKeys.challenges.challengeDetails.text.learningOutcome.learnedSomethingNew)}
          value={toPercentage(
            summary ? summary.learnedSomethingNew / (summary.learnedSomethingNew + summary.didNotLearnSomethingNew) : 0
          )}
        />
        <Block label={t(i18nKeys.general.yes)} value={summary?.learnedSomethingNew ?? 0} />
        <Block label={t(i18nKeys.general.no)} value={summary?.didNotLearnSomethingNew ?? 0} />
        <Block
          label={t(i18nKeys.general.nA)}
          value={summary ? summary.total - summary.learnedSomethingNew - summary.didNotLearnSomethingNew : 0}
        />
      </ColumnLayout>
    </React.Fragment>
  );
};

export default FeedbackLearningOutcome;
