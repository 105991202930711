/**
 * states list is copied from Game UI
 */

export interface State {
    code: string;
    countryCode: string;
    name: string;
  }
  
  export const STATES: State[] = [
    {
      code: "AL",
      name: "states.alabama",
      countryCode: "US",
    },
    {
      code: "AK",
      name: "states.alaska",
      countryCode: "US",
    },
    {
      code: "AZ",
      name: "states.arizona",
      countryCode: "US",
    },
    {
      code: "AR",
      name: "states.arkansas",
      countryCode: "US",
    },
    {
      code: "CA",
      name: "states.california",
      countryCode: "US",
    },
    {
      code: "CO",
      name: "states.colorado",
      countryCode: "US",
    },
    {
      code: "CT",
      name: "states.connecticut",
      countryCode: "US",
    },
    {
      code: "DE",
      name: "states.delaware",
      countryCode: "US",
    },
    {
      code: "DC",
      name: "states.districtOfColumbiaDc",
      countryCode: "US",
    },
    {
      code: "FL",
      name: "states.florida",
      countryCode: "US",
    },
    {
      code: "GA",
      name: "states.georgia",
      countryCode: "US",
    },
    {
      code: "HI",
      name: "states.hawaii",
      countryCode: "US",
    },
    {
      code: "ID",
      name: "states.idaho",
      countryCode: "US",
    },
    {
      code: "IL",
      name: "states.illinois",
      countryCode: "US",
    },
    {
      code: "IN",
      name: "states.indiana",
      countryCode: "US",
    },
    {
      code: "IA",
      name: "states.iowa",
      countryCode: "US",
    },
    {
      code: "KS",
      name: "states.kansas",
      countryCode: "US",
    },
    {
      code: "KY",
      name: "states.kentucky",
      countryCode: "US",
    },
    {
      code: "LA",
      name: "states.louisiana",
      countryCode: "US",
    },
    {
      code: "ME",
      name: "states.maine",
      countryCode: "US",
    },
    {
      code: "MD",
      name: "states.maryland",
      countryCode: "US",
    },
    {
      code: "MA",
      name: "states.massachusetts",
      countryCode: "US",
    },
    {
      code: "MI",
      name: "states.michigan",
      countryCode: "US",
    },
    {
      code: "MN",
      name: "states.minnesota",
      countryCode: "US",
    },
    {
      code: "MS",
      name: "states.mississippi",
      countryCode: "US",
    },
    {
      code: "MO",
      name: "states.missouri",
      countryCode: "US",
    },
    {
      code: "MT",
      name: "states.montana",
      countryCode: "US",
    },
    {
      code: "NE",
      name: "states.nebraska",
      countryCode: "US",
    },
    {
      code: "NV",
      name: "states.nevada",
      countryCode: "US",
    },
    {
      code: "NH",
      name: "states.newHampshire",
      countryCode: "US",
    },
    {
      code: "NJ",
      name: "states.newJersey",
      countryCode: "US",
    },
    {
      code: "NM",
      name: "states.newMexico",
      countryCode: "US",
    },
    {
      code: "NY",
      name: "states.newYork",
      countryCode: "US",
    },
    {
      code: "NC",
      name: "states.northCarolina",
      countryCode: "US",
    },
    {
      code: "ND",
      name: "states.northDakota",
      countryCode: "US",
    },
    {
      code: "OH",
      name: "states.ohio",
      countryCode: "US",
    },
    {
      code: "OK",
      name: "states.oklahoma",
      countryCode: "US",
    },
    {
      code: "OR",
      name: "states.oregon",
      countryCode: "US",
    },
    {
      code: "PA",
      name: "states.pennsylvania",
      countryCode: "US",
    },
    {
      code: "RI",
      name: "states.rhodeIsland",
      countryCode: "US",
    },
    {
      code: "SC",
      name: "states.southCarolina",
      countryCode: "US",
    },
    {
      code: "SD",
      name: "states.southDakota",
      countryCode: "US",
    },
    {
      code: "TN",
      name: "states.tennessee",
      countryCode: "US",
    },
    {
      code: "TX",
      name: "states.texas",
      countryCode: "US",
    },
    {
      code: "UT",
      name: "states.utah",
      countryCode: "US",
    },
    {
      code: "VT",
      name: "states.vermont",
      countryCode: "US",
    },
    {
      code: "VA",
      name: "states.virginia",
      countryCode: "US",
    },
    {
      code: "WA",
      name: "states.washington",
      countryCode: "US",
    },
    {
      code: "WV",
      name: "states.westVirginia",
      countryCode: "US",
    },
    {
      code: "WI",
      name: "states.wisconsin",
      countryCode: "US",
    },
    {
      code: "WY",
      name: "states.wyoming",
      countryCode: "US",
    },
    {
      code: "AB",
      name: "states.alberta",
      countryCode: "CA",
    },
    {
      code: "BC",
      name: "states.britishColumbia",
      countryCode: "CA",
    },
    {
      code: "MB",
      name: "states.manitoba",
      countryCode: "CA",
    },
    {
      code: "NB",
      name: "states.newBrunswick",
      countryCode: "CA",
    },
    {
      code: "NL",
      name: "states.newfoundland",
      countryCode: "CA",
    },
    {
      code: "NT",
      name: "states.northwestTerritories",
      countryCode: "CA",
    },
    {
      code: "NS",
      name: "states.novaScotia",
      countryCode: "CA",
    },
    {
      code: "NU",
      name: "states.nunavut",
      countryCode: "CA",
    },
    {
      code: "ON",
      name: "states.ontario",
      countryCode: "CA",
    },
    {
      code: "PE",
      name: "states.princeEdwardIsland",
      countryCode: "CA",
    },
    {
      code: "QC",
      name: "states.quebec",
      countryCode: "CA",
    },
    {
      code: "SK",
      name: "states.saskatchewan",
      countryCode: "CA",
    },
    {
      code: "YT",
      name: "states.yukon",
      countryCode: "CA",
    },
    {
      code: "Australian Capital Territory",
      name: "states.australianCapitalTerritory",
      countryCode: "AU",
    },
    {
      code: "New South Wales",
      name: "states.newSouthWales",
      countryCode: "AU",
    },
    {
      code: "Victoria",
      name: "states.victoria",
      countryCode: "AU",
    },
    {
      code: "Queensland",
      name: "states.queensland",
      countryCode: "AU",
    },
    {
      code: "South Australia",
      name: "states.southAustralia",
      countryCode: "AU",
    },
    {
      code: "Western Australia",
      name: "states.westernAustralia",
      countryCode: "AU",
    },
    {
      code: "Tasmania",
      name: "states.tasmania",
      countryCode: "AU",
    },
    {
      code: "Northern Territory",
      name: "states.northernTerritory",
      countryCode: "AU",
    },
    {
      code: "Anhui",
      name: "states.anhui",
      countryCode: "CN",
    },
    {
      code: "Beijing",
      name: "states.beijing",
      countryCode: "CN",
    },
    {
      code: "Chongqing",
      name: "states.chongqing",
      countryCode: "CN",
    },
    {
      code: "Fujian",
      name: "states.fujian",
      countryCode: "CN",
    },
    {
      code: "Gansu",
      name: "states.gansu",
      countryCode: "CN",
    },
    {
      code: "Guangdong",
      name: "states.guangdong",
      countryCode: "CN",
    },
    {
      code: "Guangxi",
      name: "states.guangxi",
      countryCode: "CN",
    },
    {
      code: "Guizhou",
      name: "states.guizhou",
      countryCode: "CN",
    },
    {
      code: "Hainan",
      name: "states.hainan",
      countryCode: "CN",
    },
    {
      code: "Hebei",
      name: "states.hebei",
      countryCode: "CN",
    },
    {
      code: "Heilongjiang",
      name: "states.heilongjiang",
      countryCode: "CN",
    },
    {
      code: "Henan",
      name: "states.henan",
      countryCode: "CN",
    },
    {
      code: "Hubei",
      name: "states.hubei",
      countryCode: "CN",
    },
    {
      code: "Hunan",
      name: "states.hunan",
      countryCode: "CN",
    },
    {
      code: "Inner Mongolia",
      name: "states.innerMongolia",
      countryCode: "CN",
    },
    {
      code: "Jiangsu",
      name: "states.jiangsu",
      countryCode: "CN",
    },
    {
      code: "Jiangxi",
      name: "states.jiangxi",
      countryCode: "CN",
    },
    {
      code: "Jilin",
      name: "states.jilin",
      countryCode: "CN",
    },
    {
      code: "Liaoning",
      name: "states.liaoning",
      countryCode: "CN",
    },
    {
      code: "Ningxia",
      name: "states.ningxia",
      countryCode: "CN",
    },
    {
      code: "Qinghai",
      name: "states.qinghai",
      countryCode: "CN",
    },
    {
      code: "Shaanxi",
      name: "states.shaanxi",
      countryCode: "CN",
    },
    {
      code: "Shandong",
      name: "states.shandong",
      countryCode: "CN",
    },
    {
      code: "Shanghai",
      name: "states.shanghai",
      countryCode: "CN",
    },
    {
      code: "Shanxi",
      name: "states.shanxi",
      countryCode: "CN",
    },
    {
      code: "Sichuan",
      name: "states.sichuan",
      countryCode: "CN",
    },
    {
      code: "Tianjin",
      name: "states.tianjin",
      countryCode: "CN",
    },
    {
      code: "Tibet",
      name: "states.tibet",
      countryCode: "CN",
    },
    {
      code: "Xinjiang",
      name: "states.xinjiang",
      countryCode: "CN",
    },
    {
      code: "Yunnan",
      name: "states.yunnan",
      countryCode: "CN",
    },
    {
      code: "Zhejiang",
      name: "states.zhejiang",
      countryCode: "CN",
    },
    {
      code: "AC",
      name: "states.acre",
      countryCode: "BR",
    },
    {
      code: "AL",
      name: "states.alagoas",
      countryCode: "BR",
    },
    {
      code: "AP",
      name: "states.amapa",
      countryCode: "BR",
    },
    {
      code: "AM",
      name: "states.amazon",
      countryCode: "BR",
    },
    {
      code: "BA",
      name: "states.bahia",
      countryCode: "BR",
    },
    {
      code: "CE",
      name: "states.ceara",
      countryCode: "BR",
    },
    {
      code: "DF",
      name: "states.federalDistrict",
      countryCode: "BR",
    },
    {
      code: "EN",
      name: "states.holySpirit",
      countryCode: "BR",
    },
    {
      code: "GO",
      name: "states.goias",
      countryCode: "BR",
    },
    {
      code: "MA",
      name: "states.maranhão",
      countryCode: "BR",
    },
    {
      code: "MT",
      name: "states.matoGrosso",
      countryCode: "BR",
    },
    {
      code: "MS",
      name: "states.southMatoGrosso",
      countryCode: "BR",
    },
    {
      code: "MG",
      name: "states.minasGerais",
      countryCode: "BR",
    },
    {
      code: "PA",
      name: "states.para",
      countryCode: "BR",
    },
    {
      code: "PB",
      name: "states.paraiba",
      countryCode: "BR",
    },
    {
      code: "PR",
      name: "states.parana",
      countryCode: "BR",
    },
    {
      code: "PE",
      name: "states.pernambuco",
      countryCode: "BR",
    },
    {
      code: "PI",
      name: "states.piaui",
      countryCode: "BR",
    },
    {
      code: "RJ",
      name: "states.rioDeJaneiro",
      countryCode: "BR",
    },
    {
      code: "RN",
      name: "states.rioGrandeDoNorte",
      countryCode: "BR",
    },
    {
      code: "RS",
      name: "states.rioGrandeDoSul",
      countryCode: "BR",
    },
    {
      code: "RO",
      name: "states.rondonia",
      countryCode: "BR",
    },
    {
      code: "RR",
      name: "states.roraima",
      countryCode: "BR",
    },
    {
      code: "SC",
      name: "states.santaCatarina",
      countryCode: "BR",
    },
    {
      code: "SP",
      name: "states.sãoPaulo",
      countryCode: "BR",
    },
    {
      code: "SE",
      name: "states.sergipe",
      countryCode: "BR",
    },
    {
      code: "TO",
      name: "states.tocantins",
      countryCode: "BR",
    },
    {
      code: "Baden-Wuerttemberg",
      name: "states.badenWuerttemberg",
      countryCode: "DE",
    },
    {
      code: "Bavaria",
      name: "states.bavaria",
      countryCode: "DE",
    },
    {
      code: "Berlin",
      name: "states.berlin",
      countryCode: "DE",
    },
    {
      code: "Brandenburg",
      name: "states.brandenburg",
      countryCode: "DE",
    },
    {
      code: "Bremen",
      name: "states.bremen",
      countryCode: "DE",
    },
    {
      code: "Hamburg",
      name: "states.hamburg",
      countryCode: "DE",
    },
    {
      code: "Hesse",
      name: "states.hesse",
      countryCode: "DE",
    },
    {
      code: "Lower Saxony",
      name: "states.lowerSaxony",
      countryCode: "DE",
    },
    {
      code: "Mecklenburg-Vorpommern",
      name: "states.mecklenburgVorpommern",
      countryCode: "DE",
    },
    {
      code: "North Rhine-Westphalia",
      name: "states.northRhineWestphalia",
      countryCode: "DE",
    },
    {
      code: "Rhineland-Palatinate",
      name: "states.rhinelandPalatinate",
      countryCode: "DE",
    },
    {
      code: "Saarland",
      name: "states.saarland",
      countryCode: "DE",
    },
    {
      code: "Saxony",
      name: "states.saxony",
      countryCode: "DE",
    },
    {
      code: "Saxony-Anhalt",
      name: "states.saxonyAnhalt",
      countryCode: "DE",
    },
    {
      code: "Schleswig-Holstein",
      name: "states.schleswigHolstein",
      countryCode: "DE",
    },
    {
      code: "Thuringia",
      name: "states.thuringia",
      countryCode: "DE",
    },
    {
      code: "Hong Kong Island",
      name: "states.hongKongIsland",
      countryCode: "HK",
    },
    {
      code: "Kowloon",
      name: "states.kowloon",
      countryCode: "HK",
    },
    {
      code: "Lantau Island",
      name: "states.lantauIsland",
      countryCode: "HK",
    },
    {
      code: "New Territories",
      name: "states.newTerritories",
      countryCode: "HK",
    },
    {
      code: "Andaman Nicobar Islands",
      name: "states.andamanNicobarIslands",
      countryCode: "IN",
    },
    {
      code: "Andhra Pradesh",
      name: "states.andhraPradesh",
      countryCode: "IN",
    },
    {
      code: "Arunachal Pradesh",
      name: "states.arunachalPradesh",
      countryCode: "IN",
    },
    {
      code: "Assam",
      name: "states.assam",
      countryCode: "IN",
    },
    {
      code: "Bihar",
      name: "states.bihar",
      countryCode: "IN",
    },
    {
      code: "Chandigarh",
      name: "states.chandigarh",
      countryCode: "IN",
    },
    {
      code: "Chhattisgarh",
      name: "states.chhattisgarh",
      countryCode: "IN",
    },
    {
      code: "Dadra Nagar Haveli",
      name: "states.dadraNagarHaveli",
      countryCode: "IN",
    },
    {
      code: "Daman Diu",
      name: "states.damanDiu",
      countryCode: "IN",
    },
    {
      code: "Delhi",
      name: "states.delhi",
      countryCode: "IN",
    },
    {
      code: "Goa",
      name: "states.goa",
      countryCode: "IN",
    },
    {
      code: "Gujarat",
      name: "states.gujarat",
      countryCode: "IN",
    },
    {
      code: "Haryana",
      name: "states.haryana",
      countryCode: "IN",
    },
    {
      code: "Himachal Pradesh",
      name: "states.himachalPradesh",
      countryCode: "IN",
    },
    {
      code: "Jammu & Kashmir",
      name: "states.jammuKashmir",
      countryCode: "IN",
    },
    {
      code: "Jharkhand",
      name: "states.jharkhand",
      countryCode: "IN",
    },
    {
      code: "Karnataka",
      name: "states.karnataka",
      countryCode: "IN",
    },
    {
      code: "Kerala",
      name: "states.kerala",
      countryCode: "IN",
    },
    {
      code: "Lakshwadeep",
      name: "states.lakshwadeep",
      countryCode: "IN",
    },
    {
      code: "Madhya Pradesh",
      name: "states.madhyaPradesh",
      countryCode: "IN",
    },
    {
      code: "Maharashtra",
      name: "states.maharashtra",
      countryCode: "IN",
    },
    {
      code: "Manipur",
      name: "states.manipur",
      countryCode: "IN",
    },
    {
      code: "Meghalaya",
      name: "states.meghalaya",
      countryCode: "IN",
    },
    {
      code: "Mizoram",
      name: "states.mizoram",
      countryCode: "IN",
    },
    {
      code: "Nagaland",
      name: "states.nagaland",
      countryCode: "IN",
    },
    {
      code: "Orrisa",
      name: "states.orrisa",
      countryCode: "IN",
    },
    {
      code: "Puducherry",
      name: "states.puducherry",
      countryCode: "IN",
    },
    {
      code: "Punjab",
      name: "states.punjab",
      countryCode: "IN",
    },
    {
      code: "Rajasthan",
      name: "states.rajasthan",
      countryCode: "IN",
    },
    {
      code: "Sikkim",
      name: "states.sikkim",
      countryCode: "IN",
    },
    {
      code: "Tamil Nadu",
      name: "states.tamilNadu",
      countryCode: "IN",
    },
    {
      code: "Tripura",
      name: "states.tripura",
      countryCode: "IN",
    },
    {
      code: "Uttar Pradesh",
      name: "states.uttarPradesh",
      countryCode: "IN",
    },
    {
      code: "Uttrakhand",
      name: "states.uttrakhand",
      countryCode: "IN",
    },
    {
      code: "West Bengal",
      name: "states.westBengal",
      countryCode: "IN",
    },
    {
      code: "Aguascalientes",
      name: "states.aguascalientes",
      countryCode: "MX",
    },
    {
      code: "Baja California",
      name: "states.bajaCalifornia",
      countryCode: "MX",
    },
  ];

  export const COUNTRIES_WITH_STATES: Set<string> = new Set<string>(
    STATES.filter((state) => !!state.countryCode).map(
      (state) => state.countryCode
    )
  );
