/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from 'i18next';
import { i18nKeys } from '../utils/i18n.utils';
import { ApiClient } from './ApiClient';
import { JamEventDetails } from '../types/JamEventDetails';
import { JamEventDataResponse } from '../types/JamEvent';

export class JamEventDetailsAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * get jam challenges data
   *
   */
  public async getEventDetails(eventId: string, errorHandled = false) {
    return (await this.apiClient.get({
      path: `/event/${eventId}/details`,
      responseMapper: JamEventDetails.fromPlainObject,
      failMessage: errorHandled ? undefined : this.t(i18nKeys.jamDashboard.error.failedEventDetail),
      errorHandled,
    })) as Promise<JamEventDetails>;
  }

  public async getEventNameFromCampaignParams(campaignSlug: string, campaignGroupId: string, errorHandled = false) {
    return (await this.apiClient.get({
      path: `/event/campaign/${campaignSlug}/${campaignGroupId}/load`,
      responseMapper: JamEventDataResponse.fromPlainObject,
      failMessage: errorHandled ? undefined : this.t(i18nKeys.jamDashboard.error.failedEventDetail),
      errorHandled,
    })) as Promise<JamEventDataResponse>;
  }
}
