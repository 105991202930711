import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Rating } from 'react-simple-star-rating';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CircleRatingProps {
  value: number;
  customView?: boolean;
}

const CircleRating: React.FC<CircleRatingProps> = ({ value, customView }) => {
  const customColor = customView ? '#015292' : '#00A1C9';
  return (
    <div style={{ display: 'inline-block' }}>
      <Rating
        readonly
        allowFraction
        size={customView ? 25 : 15}
        iconsCount={5}
        initialValue={value}
        fillIcon={
          <span className="fa-layers fa-fw" style={{ width: '1.55em' }}>
            <FontAwesomeIcon icon={faCircle as IconProp} color={customColor} size="lg" />
          </span>
        }
        emptyIcon={
          <span className="fa-layers fa-fw" style={{ width: '1.55em' }}>
            <FontAwesomeIcon icon={faCircle as IconProp} color={customColor} size="lg" />
            <FontAwesomeIcon icon={faCircle as IconProp} size="lg" inverse transform="shrink-7" />
          </span>
        }
      />
    </div>
  );
};
export default CircleRating;
