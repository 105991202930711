/* eslint-disable react/display-name */
import { ChatType } from '@/src/types/Chat';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, Button } from '@amzn/awsui-components-react';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ChatButtonProps {
  onClick: () => void;
  chatType?: ChatType;
  counter: number;
  title: string;
  isActive: boolean;
  id?: string;
}

const teamChatIcon = '/assets/chat-icon.svg';
const supportChatIcon = '/assets/support-chat-icon.svg';
const closeIcon = '/assets/close-icon.svg';

const ChatButton = forwardRef<HTMLDivElement, ChatButtonProps>(
  ({ onClick, chatType, counter, title, isActive, id }, ref) => {
    const { t } = useTranslation();
    const isSupport = chatType === 'support';
    const icon = isSupport ? supportChatIcon : teamChatIcon;
    return (
      <div ref={ref}>
        <Button id={id} data-classname={isSupport ? 'support' : ''} onClick={onClick}>
          <div style={{ width: '30px' }}>
            <img alt="message icon" src={!!isActive ? closeIcon : icon} height={20} />
          </div>
          <Box fontWeight="bold" padding={{ top: 'xxxs' }} variant="span" color="text-status-info">
            {title}
          </Box>
          {!!counter && (
            <div
              aria-label={t(i18nKeys.jamChat.ariaLabels.unreadMessagesCounter, { count: counter })}
              className="unread-counter">
              {counter}
            </div>
          )}
        </Button>
      </div>
    );
  }
);

export default ChatButton;
