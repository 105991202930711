import { TFunction } from 'i18next';
import { uniq } from 'lodash';

type Values = { [key: string]: string | number } | undefined;

/**
 * A type of Error that contains the necessary data to localize an error message.
 */
export class LocalizedError extends Error {
  // the key of the localized message to display
  readonly i18nKey: string;

  // values to interpolate into the localized message
  readonly values: Values;

  // if true, then bypass localization and just return the value of the i18nKey
  readonly raw: boolean;

  /**
   * LocalizedError
   *
   * @param i18nKey - required, the key of the localized message to display
   * @param values - optional, values to interpolate into the localized message
   * @param raw - optional, if true, then bypass localization and just return the value of the i18nKey
   */
  constructor(i18nKey: string, values?: Values, raw?: boolean) {
    super(i18nKey);
    this.i18nKey = i18nKey;
    this.values = values;
    this.raw = raw === true;
  }

  /**
   * Map a list of errors to a list localized messages.
   *
   * @param errors
   * @param t
   */
  static mapToErrorMessages(errors: (LocalizedError | null)[], t: TFunction): string[] {
    return uniq(errors.filter((e) => !!e).map((e) => e?.getLocalizedErrorMessage(t) || ''));
  }

  static isLocalizedError(e: any): boolean {
    // Disabling eslint as we are checking that any class coming in to see if it is a localizedError with this method
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return e != null && typeof e.getLocalizedErrorMessage === 'function';
  }

  /**
   * Get the localized message from the corresponding i18n key
   *
   * @param t
   */
  getLocalizedErrorMessage(t: TFunction): string {
    return this.raw ? this.i18nKey : t(this.i18nKey, this.values);
  }
}
