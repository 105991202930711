/**
 * List of domains amazon currently uses.  Please update if more become available.  Full list of region domain extensions can be found at
 * https://code.amazon.com/packages/CorpSysSMTPINRelayDomains/blobs/mainline/--/configuration/config/relay-domains
 */

export const DEFAULT_EMAIL_DOMAINS = [
  '@amazon.ac',
  '@amazon.ae',
  '@amazon.at',
  '@amazon.ca',
  '@amazon.cc',
  '@amazon.cl',
  '@amazon.co.in',
  '@amazon.co.jp',
  '@amazon.my',
  '@amazon.co.uk',
  '@amazon.co.za',
  '@amazon.com',
  '@amazon.com.ar',
  '@amazon.com.au',
  '@amazon.com.be',
  '@amazon.com.bh',
  '@amazon.com.br',
  '@amazon.com.co',
  '@amazon.com.mx',
  '@amazon.com.ng',
  '@amazon.com.kw',
  '@amazon.com.sa',
  '@amazon.com.th',
  '@amazon.com.tr',
  '@amazon.com.vg',
  '@amazon.com.vn',
  '@amazon.ch',
  '@amazon.cz',
  '@amazon.de',
  '@amazon.eg',
  '@amazon.es',
  '@amazon.eu',
  '@amazon.fi',
  '@amazon.fr',
  '@amazon.id',
  '@amazon.ie',
  '@amazon.in',
  '@amazon.it',
  '@amazon.jp',
  '@amazon.lu',
  '@amazon.nl',
  '@amazon.ph',
  '@amazon.pl',
  '@amazon.pt',
  '@amazon.ro',
  '@amazon.ru',
  '@amazon.sa',
  '@amazon.se',
  '@amazon.sg',
  '@amazon.sh',
];
