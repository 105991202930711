import { Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';
import { HorizontalRule } from '../../common/HorizontalRule';
import Scoring from '../../common/CampaignEventComponents/Scoring';
import SupportAndCollaboration from '../../common/CampaignEventComponents/SupportAndCollaboration';
import ChimeNotifications from '../../common/CampaignEventComponents/ChimeNotifications';
import Gamified from '../../common/CampaignEventComponents/Gamified';
import AccessControl from '../../common/CampaignEventComponents/AccessControl';
import SkillBuilder from '../../common/CampaignEventComponents/SkillBuilder';
import LabAvailabilty from '../../common/CampaignEventComponents/LabAvailability';
import InvitationEmail from './InvitationEmail';

interface CampaignAdvancedSettingsProps {
  campaign: Campaign | undefined;
}

const CampaignAdvancedSettings: React.FC<CampaignAdvancedSettingsProps> = ({ campaign }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid gridDefinition={[{ colspan: 8 }]}>
        <Header variant="h2" description={t(i18nKeys.events.eventDetails.headers.advancedSettings.description)}>
          {t(i18nKeys.campaigns.headers.campaignDetails.advancedSettings)}
        </Header>
      </Grid>
      <HorizontalRule />
      {campaign && (
        <SpaceBetween direction="vertical" size="s">
          <Scoring target={campaign} />
          <SupportAndCollaboration target={campaign} />
          <ChimeNotifications target={campaign} />
          <Gamified target={campaign} />
          <AccessControl target={campaign} />
          <SkillBuilder target={campaign} />
          <LabAvailabilty target={campaign} />
          {campaign.wasEverApproved && <InvitationEmail campaign={campaign} />}
        </SpaceBetween>
      )}
    </React.Fragment>
  );
};
export default CampaignAdvancedSettings;
