/**
 * An object where the deepest value from any path taken is eventually a string
 *
 */
// {
//   a: 'value',
//   b: {
//     c: 'value',
//     d: {
//       e: 'value',
//     }
//   }
// }
export interface StringTree {
  [key: string]: string | StringTree;
}

/** Replaces all string values in a StringTree with the path/location of that value in the tree.
 *
 * @param obj
 * @param path
 */
// Before:
// {
//   a: 'value',
//   b: {
//     c: 'value',
//     d: {
//       e: 'value',
//     }
//   }
// }
//
// After:
// {
//   a: 'a',
//   b: {
//     c: 'b.c',
//     d: {
//       e: 'b.d.e',
//     }
//   }
// }
export const replaceValuesWithTreeLocation = <T extends StringTree>(
  obj: { [key: string]: string | StringTree },
  path = ''
): T | { [key: string]: string | StringTree } => {
  Object.entries(obj).forEach(([key, value]) => {
    const currentLocation = path.length ? `${path}.${key}` : key;
    if (typeof value === 'string') {
      obj[key] = currentLocation;
    } else if (value && typeof value === 'object' && !Array.isArray(value)) {
      obj[key] = replaceValuesWithTreeLocation(value, currentLocation);
    } else {
      const type = value === null ? 'null' : Array.isArray(value) ? 'Array' : typeof value;
      // invariant violation
      throw new Error(
        `Encountered a value in a StringTree which is neither an object nor a string. path=${currentLocation} type=${type}`
      );
    }
  });

  return obj;
};
