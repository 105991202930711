import { JamEventTeamGoal } from '@/src/types/JamCommon';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, Button, Form, FormField, Input, Modal, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType } from '../../UIModel';

interface JamTeamFormModalProps {
  handleClose: () => void;
  handleSubmit: (value?: string) => void;
  modalType?: ModalType;
  teamName: string;
  goal: JamEventTeamGoal;
}

const JamTeamFormModal: React.FC<JamTeamFormModalProps> = ({
  modalType,
  handleClose,
  handleSubmit,
  teamName,
  goal: currentGoal,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(ModalType.EditTeamGoal === modalType ? currentGoal : '');
  const [confirmValue, setConfirmValue] = useState('');
  const [error, setError] = useState('');
  const [confirmError, setConfirmError] = useState('');

  const goalOptions = [
    { value: JamEventTeamGoal.PLAY_TO_LEARN, label: t(i18nKeys.joinTeam.form.commonFields.playToLearn) },
    { value: JamEventTeamGoal.PLAY_TO_WIN, label: t(i18nKeys.joinTeam.form.commonFields.playToWin) },
  ];

  const onSubmit = () => {
    setError('');
    if (modalType === ModalType.RemovePassword) {
      handleSubmit();
      return;
    }
    if (modalType === ModalType.ChangePassword && value !== confirmValue) {
      setConfirmError(t(i18nKeys.jamTeam.errors.confirmPassSame));
      return;
    }
    if (isEmpty(value)) {
      setError(t(i18nKeys.jamTeam.errors.canNotBeEmpty));
      return;
    }
    setError('');
    setConfirmError('');
    handleSubmit(value);
  };

  const title = useMemo(() => {
    switch (modalType) {
      case ModalType.ChangePassword:
        return t(i18nKeys.jamTeam.modal.changeTeamPassword);
      case ModalType.EditTeamGoal:
        return t(i18nKeys.jamTeam.modal.changeTeamGoal);
      case ModalType.RemovePassword:
        return t(i18nKeys.jamTeam.modal.removeTeamPassword);
      case ModalType.RenameTeam:
        return t(i18nKeys.jamTeam.modal.changeTeamName);
      default:
        return '';
    }
  }, [modalType]);

  const submitButtonTitle = useMemo(() => {
    switch (modalType) {
      case ModalType.ChangePassword:
        return t(i18nKeys.jamTeam.actions.changeTeamPassword);
      case ModalType.EditTeamGoal:
        return t(i18nKeys.jamTeam.actions.changeTeamGoal);
      case ModalType.RemovePassword:
        return t(i18nKeys.jamTeam.actions.removeTeamPassword);
      case ModalType.RenameTeam:
        return t(i18nKeys.jamTeam.actions.changeTeamName);
      default:
        return '';
    }
  }, [modalType]);

  const renderForm = () => {
    switch (modalType) {
      case ModalType.ChangePassword:
        return (
          <SpaceBetween size="s">
            <FormField label={t(i18nKeys.jamTeam.modal.fields.password, { teamName })} errorText={error}>
              <Input
                placeholder={t(i18nKeys.general.password)}
                type="password"
                value={value}
                onChange={({ detail }) => setValue(detail.value)}
              />
            </FormField>
            <FormField label={t(i18nKeys.jamTeam.modal.fields.confirmPassword)} errorText={confirmError}>
              <Input
                placeholder={t(i18nKeys.general.password)}
                type="password"
                value={confirmValue}
                onChange={({ detail }) => setConfirmValue(detail.value)}
              />
            </FormField>
          </SpaceBetween>
        );
      case ModalType.RenameTeam:
        return (
          <FormField label={t(i18nKeys.jamTeam.modal.fields.newTeam)} errorText={error}>
            <Input
              placeholder={t(i18nKeys.joinTeam.form.commonFields.teamName)}
              value={value}
              onChange={({ detail }) => setValue(detail.value)}
            />
          </FormField>
        );
      case ModalType.RemovePassword:
        return;
      case ModalType.EditTeamGoal:
        return (
          <FormField label={t(i18nKeys.jamTeam.modal.fields.teamGoal)} errorText={error}>
            <Select
              onChange={({ detail }) => setValue(detail.selectedOption.value as string)}
              selectedOption={goalOptions.find((option) => option.value === value) || null}
              options={goalOptions}
              placeholder={t(i18nKeys.general.select)}
            />
          </FormField>
        );
    }
  };

  return (
    <Modal
      onDismiss={handleClose}
      visible={!!modalType}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={handleClose}>
              {t(i18nKeys.general.cancel)}
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              {submitButtonTitle}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={title}>
      {modalType === ModalType.RemovePassword ? (
        <Box>{t(i18nKeys.jamTeam.modal.removeDescription)}</Box>
      ) : (
        <Form>{renderForm()}</Form>
      )}
    </Modal>
  );
};

export default JamTeamFormModal;
