import { TFunction } from 'i18next';
import { i18nKeys } from './i18n.utils';

export const getHeaderCounterText = (totalItems: number, selectedItems: any[], showZero?: boolean) => {
  return selectedItems && (selectedItems.length > 0 || showZero)
    ? `(${selectedItems.length}/${totalItems})`
    : `(${totalItems})`;
};

export const paginationLabels = (t: TFunction) => ({
  nextPageLabel: t(i18nKeys.tables.pagination.nextPage),
  previousPageLabel: t(i18nKeys.tables.pagination.prevPage),
  pageLabel: (pageNumber: number) => t(i18nKeys.tables.pagination.label, { pageNumber }),
});

export interface TableFilterMatcher {
  filterEmpty: boolean;
  isMatch: (value: string | null) => boolean;
  listContainsMatch: (list: string[]) => boolean;
}

export const getTableFilterMatcher = (filteringText: string): TableFilterMatcher => {
  filteringText = (filteringText || '').trim().toLowerCase();
  const isMatch = (value: string | null): boolean => value != null && value.toLowerCase().includes(filteringText);
  const listContainsMatch = (list: string[]): boolean => list && list.length > 0 && list.some(isMatch);
  return {
    filterEmpty: !filteringText,
    isMatch,
    listContainsMatch,
  };
};
