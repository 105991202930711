import React, { useState } from 'react';
import { Box, Button, FormField, Modal, Select, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import './SupportChatStartModal.scss';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface ChallengeOption {
  value: string;
  label: string;
}

interface SupportChatStartModalProps {
  handleClose: () => void;
  challengeOptions: ChallengeOption[];
  onStartSupportChat: (params: { challengeId: string | null; reason: string }) => Promise<void>;
}
const SupportChatStartModal: React.FC<SupportChatStartModalProps> = ({
  handleClose,
  challengeOptions,
  onStartSupportChat,
}) => {
  const { t } = useTranslation();
  const [canAskReason, setCanAskReason] = useState(false);
  const [reason, setReason] = useState('');
  const [selectedChallenge, setSelecteChallenge] = useState<ChallengeOption | null>(null);
  const [reasonError, setReasonError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onContinue = () => {
    setCanAskReason(true);
  };

  const onSubmit = async () => {
    if (!reason.trim()) {
      setReasonError(t(i18nKeys.jamChat.supportChat.errors.detailsEmpty));
      return;
    }
    try {
      setIsLoading(true);
      await onStartSupportChat({ challengeId: selectedChallenge?.value || null, reason });
      handleClose();
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  const renderChallengeForm = () => {
    return (
      <Box>
        <FormField stretch label={t(i18nKeys.jamChat.supportChat.form.challengeSelectTitle)}>
          <Select
            selectedOption={selectedChallenge}
            options={challengeOptions}
            onChange={({ detail }) => setSelecteChallenge(detail.selectedOption as ChallengeOption)}
            placeholder={t(i18nKeys.general.select)}
          />
        </FormField>
        <Box margin={{ top: 'm', bottom: 'l' }}>{t(i18nKeys.jamChat.supportChat.form.challengeSelectCaption)}</Box>
      </Box>
    );
  };

  const renderReasonForm = () => {
    return (
      <Box margin={{ bottom: 's' }}>
        <Box fontWeight="bold">{t(i18nKeys.jamChat.supportChat.form.reasonFormTitle)}</Box>
        <ul className="chat-reason-checklist">
          <li>{t(i18nKeys.jamChat.supportChat.form.reasonList1)}</li>
          <li>{t(i18nKeys.jamChat.supportChat.form.reasonList2)}</li>
        </ul>
        <FormField stretch label={t(i18nKeys.jamChat.supportChat.form.reasonTitle)} errorText={reasonError}>
          <Textarea
            id="issueDetailsTextBox"
            className="awsui_root_qk1j1_1xe70_93"
            placeholder={t(i18nKeys.jamChat.supportChat.form.reasonPlaceholder)}
            value={reason}
            onChange={({ detail }) => setReason(detail.value)}
          />
        </FormField>
      </Box>
    );
  };

  return (
    <Modal
      onDismiss={handleClose}
      visible
      header={t(i18nKeys.jamChat.supportChat.title)}
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button disabled={isLoading} variant="link" onClick={handleClose}>
              {t(i18nKeys.general.cancel)}
            </Button>
            {!canAskReason && (
              <Button variant="primary" id="supportChatContinueButton" onClick={onContinue}>
                {t(i18nKeys.general.continue)}
              </Button>
            )}
            {canAskReason && (
              <Button
                loading={isLoading}
                id="supportchatRequestButton"
                variant="primary"
                onClick={() => void onSubmit()}>
                {t(i18nKeys.jamChat.actions.requestSupportChat)}
              </Button>
            )}
          </SpaceBetween>
        </Box>
      }>
      {!canAskReason && renderChallengeForm()}
      {canAskReason && renderReasonForm()}
    </Modal>
  );
};

export default SupportChatStartModal;
