import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
// import { JamProfile } from '../types/JamProfile';
import { GAME_API_PREFIX } from '../constants/api';
import { i18nKeys } from '../utils/i18n.utils';
import { PostEventReport } from '../types/JamReport';

export class JamReportAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }
  public async getJamReport(eventName: string): Promise<PostEventReport> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/participant/event/${eventName}/report`,
      failMessage: this.t(i18nKeys.apiResponse.error),
    })) as Promise<PostEventReport>;
  }
}
