import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Grid, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import '../../styles/landing-page.scss';
import { i18nKeys } from '../../utils/i18n.utils';
import { useAuth } from '../../store/auth.context';
import { useUser } from '../../store/user.context';
import { HorizontalRule } from '../common/HorizontalRule';
import { Link } from '@amzn/awsui-components-react/uxdg';
import { Link as ReactLink } from 'react-router-dom';
import {
  awsJamFlyerUrl,
  challengeAuthoringGuideUrl,
  challengeWritingStyleGuideUrl,
  eventOwnerChecklistUrl,
  facilitatorChecklistUrl,
  jamFacilitatorGuideUrl,
  jamIntroPptUrl,
  skillBuilderEventOwnerGuideUrl,
} from '../../utils/jam-urls';
import { RoutePath } from '../../RoutePath';

export const Home: React.FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn, user } = useUser();
  const { authClient } = useAuth();

  return (
    <Box margin={{ bottom: 'l' }}>
      <div className="custom-home__header">
        <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { colspan: { l: 8, xxs: 10 }, offset: { l: 1, xxs: 1 } },
              { colspan: { l: 6, xxs: 6 }, offset: { l: 1, xxs: 1 } },
              { colspan: { l: 3, xxs: 3 } },
            ]}>
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              <span className="custom-home__category">{t(i18nKeys.home.category)}</span>
            </Box>
            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                AWS Jam
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                {t(i18nKeys.home.subtitle)}
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-home__header-sub-title">{t(i18nKeys.home.description)}</span>
              </Box>
            </div>
            {!isLoggedIn ? (
              <Container>
                <h3> {t(i18nKeys.account.signOut.description)}. </h3>
                <Button variant={'primary'} onClick={() => void authClient.signIn()}>
                  {t(i18nKeys.account.login.button)}
                </Button>
              </Container>
            ) : (
              <div style={{ minWidth: '235px' }}>
                <SpaceBetween direction="vertical" size="l">
                  <Container>
                    <h3> {t(i18nKeys.home.requestAnEvent.title)} </h3>
                    <p> {t(i18nKeys.home.requestAnEvent.subtitle)} </p>
                    <ReactLink to={RoutePath.NEW_EVENT}>
                      <Button variant={'primary'}>{t(i18nKeys.home.requestAnEvent.button)}</Button>
                    </ReactLink>
                  </Container>
                  <Container className="overlap-card">
                    <h3 style={{ marginTop: '-4px' }}>{t(i18nKeys.home.gettingStarted.title)}</h3>
                    <Link external href={awsJamFlyerUrl}>
                      {t(i18nKeys.home.gettingStarted.links.whatIsAWSJam)}
                    </Link>
                    <HorizontalRule evenMargins />
                    <Link external href={challengeAuthoringGuideUrl}>
                      {t(i18nKeys.home.gettingStarted.links.challengeAuthoringGuide)}
                    </Link>
                    <HorizontalRule evenMargins />
                    <Link external href={challengeWritingStyleGuideUrl}>
                      {t(i18nKeys.home.gettingStarted.links.challengeWritingStyleGuide)}
                    </Link>
                    {!user?.isTCUser && (
                      <React.Fragment>
                        <HorizontalRule evenMargins />
                        <Link external href={eventOwnerChecklistUrl}>
                          {t(i18nKeys.home.gettingStarted.links.eventOwnerChecklist)}
                        </Link>
                      </React.Fragment>
                    )}
                    {user?.isTCUser && (
                      <React.Fragment>
                        <HorizontalRule evenMargins />
                        <Link external href={skillBuilderEventOwnerGuideUrl}>
                          {t(i18nKeys.home.gettingStarted.links.skillBuilderEventOwnerGuide)}
                        </Link>
                      </React.Fragment>
                    )}
                    <HorizontalRule evenMargins />
                    <Link external href={jamFacilitatorGuideUrl}>
                      {t(i18nKeys.home.gettingStarted.links.facilitatorGuide)}
                    </Link>
                    <HorizontalRule evenMargins />
                    <Link external href={facilitatorChecklistUrl}>
                      {t(i18nKeys.home.gettingStarted.links.facilitatorChecklist)}
                    </Link>
                    <HorizontalRule evenMargins />
                    <Link external href={jamIntroPptUrl}>
                      {t(i18nKeys.home.gettingStarted.links.kickoffPresentation)}
                    </Link>
                  </Container>
                </SpaceBetween>
              </div>
            )}
          </Grid>
        </Box>
      </div>
      {!isLoggedIn ? (
        <div style={{ width: '50%', textAlign: 'center', margin: '0 auto', marginTop: '20px', paddingBottom: '20px' }}>
          <Header variant="h2">{t(i18nKeys.home.learnMoreAboutJamEvents)}</Header>
          <Container>
            <div style={{ textAlign: 'center' }}>
              <video style={{ width: '100%' }} controls>
                <source src={'/assets/aws-jam-short.mp4'} type="video/mp4" />
                {t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.noVideoSupport)}
              </video>
            </div>
          </Container>
        </div>
      ) : (
        <Grid
          gridDefinition={[
            { colspan: { l: 8, xxs: 10 }, offset: { l: 1, xxs: 1 } },
            { colspan: { l: 6, xxs: 6 }, offset: { l: 1, xxs: 1 } },
          ]}>
          <h3>{t(i18nKeys.home.challengeBuilding.title)}</h3>
          <Container>
            <p>{t(i18nKeys.home.challengeBuilding.description)}</p>
            <ReactLink to={RoutePath.NEW_CHALLENGE}>
              <Button>{t(i18nKeys.home.challengeBuilding.button.label)}</Button>
            </ReactLink>
          </Container>
        </Grid>
      )}
    </Box>
  );
};
