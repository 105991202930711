import { AccountCredentials } from '../types/LabModels';

export interface IJamTask {
  id: string;
  topic: string;
  title: string;
  status: string;
  maxPoints: number;
  earnedPoints: number;
  clueDeductionPoints: number;
  isCompleted: boolean;
}

export const MockJamTasks: IJamTask[] = [
  {
    id: 'task1',
    topic: 'Task 1',
    title: 'Linear Regression Analysis Using Python',
    status: 'Complete',
    maxPoints: 75,
    earnedPoints: 75,
    clueDeductionPoints: 0,
    isCompleted: true,
  },
  {
    id: 'task2',
    topic: 'Task 2',
    title: 'K-means clustering using Python',
    status: 'Incomplete',
    maxPoints: 75,
    earnedPoints: 0,
    clueDeductionPoints: -7,
    isCompleted: false,
  },
];

export interface ICollapsiableListItem {
  value?: string;
  isImageExist?: boolean;
  image?: string;
  penalty?: number;
  lock?: {
    value: boolean;
    btnText: string;
  };
  commands?: string[];
  link?: string[];
}

export interface ICollapsiableList {
  title: string;
  items: ICollapsiableListItem[];
}

export interface ICollapsibleTabContentItem {
  title?: string;
  subtitle?: string;
  data?: ICollapsiableList[];
  html?: string;
}

export const Background: ICollapsibleTabContentItem = {
  title: 'Background',
  subtitle:
    'AnyData has been given a dataset containing recently sold houses in the northern New Jersey area. The customer wants to use this dataset to predict price of a home given its size in sq. ft. Your task is to quickly create a linear regression model using the given data and provide the predicted cost of a house of size 5000 sq. ft.',
  data: [],
};

export const PreRequisiteSetup: ICollapsibleTabContentItem = {
  title: 'Pre-requisite setup',
  subtitle: 'Please complete the pre-requiste steps here, then proceed to the Your Task section.',
  data: [
    {
      title: 'Launch SageMaker Studio',
      items: [
        { value: "Click 'Open AWS Console' in the top right to authenticate" },
        { value: 'Open the SageMaker Console, from the left navigation panel, click on Studio.' },
        {
          value:
            "On the right hand side, click the 'Open Studio' button with <code>default-user<code> selected as the user profile. This will launch the studio on a new web brower Tab or Window.",
          isImageExist: true,
          image: '/assets/JamChallengeDetails_image16.png',
        },
      ],
    },
    {
      title: 'Copy the Lab data data files and install necessary libraries',
      items: [
        {
          value:
            'On this page, go to the outputs tab on the left and note the S3 bucket name that we will use for this Challenge. It will start with <code>jam-bucket</code>',
        },
        {
          value: "In SageMaker Studio, click ‘Open Launcher’ under 'Quick Actions'",
          isImageExist: true,
          image: '/assets/JamChallengeDetails_image17.png',
        },
        {
          value: 'In the ‘Launcher’ tab, under ‘Utilities and files’, click ’System terminal’',
          isImageExist: true,
          image: '/assets/JamChallengeDetails_image18.png',
        },
        {
          value:
            'Run the following CLI commmands to copy the given data files. Replace BUCKET-NAME with your specific S3 bucket name in commands below.',
          commands: ['aws s3 cp s3://BUCKET-NAME/data.csv', 'aws s3 cp s3://BUCKET-NAME/data.json.'],
        },
        {
          value: 'Install the necessary python libraries using the commands below.',
          commands: ['pip install boto3 pandas scikit-learn matplotlib'],
        },
      ],
    },
    {
      title: 'Enable CodeWhisperer for SageMaker Studio',
      items: [
        {
          value: 'Open a system terminal inside SageMaker Studio',
          isImageExist: true,
          image: '/assets/JamChallengeDetails_image18.png',
        },
        {
          value: 'Run the following commands to enable CodeWhisperer with SageMaker studio: ',
          commands: [
            'conda activate studio',
            'pip install amazon-codewhisperer-jupyterlab-ext',
            'jupyter server extension enable amazon_codewhisperer_jupyterlab_ext',
            'conda deactivate',
            'restart-jupyter-server',
          ],
        },
        {
          value: 'Create a new Python 3 notebook. Note: it may take up to 10 minutes to start the notebook kernel.',
          isImageExist: true,
          image: '/assets/JamChallengeDetails_image20.png',
        },
        {
          value: 'You should see the Amazon CodeWhisperer extension at the bottom menu bar.',
          isImageExist: true,
          image: '/assets/JamChallengeDetails_image21.png',
        },
        {
          value:
            'If you do not see it, please refresh the browser window, or close the existing window, then launch SageMaker studio in a new browser window.',
        },
        {
          value: 'More information please refer to Step 4 the documentation here:',
          link: ['Setting up CodeWhisperer with Amazon SageMaker Studio'],
        },
      ],
    },
  ],
};

export const YourTask: ICollapsibleTabContentItem = {
  title: 'Your Task',
  subtitle:
    'Please create a new Python 3 notebook for this task by using the Create notebook button from the SageMaker Studio Launcher page. Leveraging CodeWhisperer, write and run code to perform the following in the Python 3 notebook you just created.',
  data: [
    {
      title: '',
      items: [
        { value: 'Import the <code>pandas</code> and <code>matplotlib</code> libraries' },
        { value: 'Read <code>data.csv</code> into a pandas dataframe' },
        { value: 'Cleanse the data by dropping rows containing null values' },
        { value: 'Plot <code>sq. ft.</code> vs. <code>price</code> as x and y variables to see a visual distribution' },
        { value: 'Create a linear regression model using sklearn' },
        { value: 'Fit the linear regression model' },
        { value: 'Predict the home price of a house of size 5000 sq. ft.' },
        {
          value:
            'Write the predicted price from step 7 to a file called <code>result.csv</code>. The file should contain at least one line with 5000 in one column and the predicted price in the next column.',
        },
      ],
    },
    {
      title: 'Upload results to S3',
      items: [
        {
          value:
            'Upload the <code>result.csv</code> file to the S3 bucket starting with <code>jam-bucket</code>. This is the same bucket where you downloaded the <code>data.csv</code> file from. There are two ways to upload the file:',
        },
        {
          value: 'a. Use the <code>boto3</code> API in your notebook to upload file (use CodeWhisperer to help you!).',
        },
        { value: 'b. Or, open a System Terminal, then run the following command:' },
        { value: '<code>aws s3 cp result.csv s3:</code>//BUCKET-NAME' },
      ],
    },
    {
      title: 'Task Validation',
      items: [
        {
          value:
            "You can use the 'Check my progress' button at the top of this page to check on your progress. The task will be completed when there is a <code>result.csv</code> file with the correct value for the predicted price.",
        },
      ],
    },
  ],
};

export const Clues: ICollapsibleTabContentItem = {
  title: 'Clues',
  subtitle: '',
  data: [
    {
      title: '',
      items: [
        {
          value: 'Clue 1: Import statements and comments for context',
          penalty: 7,
          lock: { value: true, btnText: 'Unlock Clue' },
        },
      ],
    },
    {
      title: '',
      items: [
        {
          value: 'Clue 2: Prompts for CodeWhisperer context',
          penalty: 9,
          lock: { value: true, btnText: 'Unlock Clue' },
        },
      ],
    },
    {
      title: '',
      items: [
        {
          value: 'Clue 3: Working solution for Linear Regression',
          penalty: 11,
          lock: { value: true, btnText: 'Unlock Clue' },
        },
      ],
    },
  ],
};

export const OverviewData: string[] = [
  'You are a newly hired Data Scientist at AnyData company. You have been tasked with performing some exploratory data analysis. You will need to access the data from a private Amazon S3 bucket, perform a set of analysis, and then upload the results back into the S3 bucket. You decide to use Jupyter notebooks and few common Python  libraries such as pandas, Matplotlib and Sklearn.',
  'Thankfully AnyData has standardized on Amazon SageMaker which provides fully managed infrastructure, tools, and  workflows for all Machine Learning and Data Science applications.',
  'There is a tight timeframe to complete the tasks and you decide to utilize Amazon CodeWhisperer to help you  complete the tasks quickly and accurately.',
];

export const ClueInfo = {
  0: {
    title:
      'To provide context for CodeWhisperer, ensure that you have the correct imports at the top of the file. Then use sample prompts to work through the tasks. Below are example prompts for the first few subtasks.',
  },
  1: {
    title:
      'Once you have completed the solution, don&apos;t forget to use the command <code className="light-violet">aws s3 cp result.csv s3://BUCKET-NAME</code> from a SageMaker Studio System Terminal to upload the solution.',
  },
  2: {
    title: '1. Import the pandas and matplotlib libraries',
    command: {
      0: 'import pandas as pd',
      1: 'import matplotlib.pyplot as plt',
    },
  },
  3: {
    title: '2. Read data.csv into a pandas dataframe',
    command: {
      0: 'Read the <code className="light-violet">data.csv</code> file as a pandas dataframe',
      1: 'print the dataframe',
      2: 'get information about the dataframe',
    },
  },
  4: {
    title: '3. Cleanse the data by dropping rows containing null values',
    command: {
      0: 'Drop the rows with null values',
    },
  },
};

export interface IModalContent {
  text: string;
  link: string;
  commands: string[];
  import?: string;
  session?: string;
  list?: string[];
}
export interface IAwsCLICommands {
  LinuxAndMacOS: IModalContent;
  Powershell: IModalContent;
  Windows: IModalContent;
  Python: IModalContent;
}

export const AwsCLICommands = (awsAccount: AccountCredentials | undefined) => {
  const accessKey = awsAccount ? awsAccount.accessKey : '';
  const secretKey = awsAccount ? awsAccount.secretKey : '';
  const sessionToken = awsAccount ? awsAccount.sessionToken : '';

  return {
    LinuxAndMacOS: {
      text: 'You can paste the following commands into your shell to set up your AWS CLI environment variables',
      link: 'https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-getting-started.html#cli-environment',
      commands: [
        `export AWS_ACCESS_KEY_ID=${accessKey}`,
        `export AWS_SECRET_ACCESS_KEY=${secretKey}`,
        `export AWS_SESSION_TOKEN=${sessionToken}`,
      ],
    },
    Powershell: {
      text: 'For Powershell, you can paste the following command into your shell to set your AWS CLI session credentials using Set-AWSCredential',
      link: 'https://docs.aws.amazon.com/powershell/latest/reference/items/Set-AWSCredential.html',
      commands: [`Set-AWSCredential -AccessKey ${accessKey} -SecretKey ${secretKey} -SessionToken ${sessionToken}`],
    },
    Windows: {
      text: 'For Windows, you can paste the following commands into your command prompt to set up AWS CLI environment variables',
      link: 'https://docs.aws.amazon.com/cli/latest/userguide/cli-configure-envvars.html',
      commands: [
        `set AWS_ACCESS_KEY_ID=${accessKey}`,
        `set AWS_SECRET_ACCESS_KEY=${secretKey}`,
        `set AWS_SESSION_TOKEN=${sessionToken}`,
      ],
    },
    Python: {
      import: 'import boto3',
      text: 'For Python, you can paste the following lines at the beginning of a python file to setup a boto3 client',
      link: 'https://boto3.amazonaws.com/v1/documentation/api/latest/index.html',
      commands: [
        `AWS_ACCESS_KEY_ID=${accessKey}`,
        `AWS_SECRET_ACCESS_KEY=${secretKey}`,
        `AWS_SESSION_TOKEN=${sessionToken}`,
      ],
      session: `session = boto3.session.Session(
      aws_access_key_id=${accessKey},
      aws_secret_access_key=${secretKey},
      aws_session_token=${sessionToken})`,
      list: [
        '# proceed to create clients / resources from the session ',
        '# for example, an s3 client:',
        '# client = session.client("s3")',
      ],
    },
  };
};
