import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, ColumnLayout, PropertyFilterProps, Table, Tabs } from '@amzn/awsui-components-react';

import {
  columnDefinitions,
  filteringProperties,
  visibleContentForChallenge,
  visibleContentForChallengeSet,
} from './table-config';

import { EventTemplateChallengeTabId, useCreateEventTemplate } from '@/src/store/create-event-template.context';

import { useSplitPanel } from '@/src/store/split-panel.context';
import { i18nKeys } from '@/src//utils/i18n.utils';
import { useToolPanel } from '@/src/store/tool-panel.context';
import { ChallengeSet } from '@/src/types/ChallengeSet';
import { ChallengeUtils } from '@/src/types/Challenge';

// hook
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';

// components
import { ChallengesTable } from './ChallengesTable';
import { ChallengeSetTable } from './ChallengeSetTable';

import { ChallengeListItem } from '@/src/types/Challenge';
import { challengeSelectValidator } from '@/src/utils/event-template.validation.utils';
import { BACKUP_CHALLENGE_LIMIT } from '@/src/constants/event-template.constants';
import { EventLearningType, EventTemplateChallengeSelectFields } from '@/src/types/EventTemplate';
import { useChallenges } from '@/src/store/challenge.context';

interface IProps {
  activeStepIndex: number;
  setActiveStepIndex: (x: number) => void;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

export const SelectChallenges = ({ validationHandler }: IProps) => {
  const { t } = useTranslation();
  const [selectChallengesError, setSelectChallengesError] = useState<string>('');
  const {
    propertyFilterRef,
    // challengeListItems,
    challengeSets,
    selectedChallengeListItems,
    propertyFilterProps: propertyFilterPropsFromContext,
    setPropertyFilterProps,
    allPagesItems: allPagesItemsFromContext,
    setAllPagesItems,
    activeChallengeTabId,
    setActiveChallengeTabId,
    currentSelectedChallengeSet,
    onCurrentSelectedChallengeSetChange,
    setFiltertedEventTemplateChallengeOrSetCount,
    eventTemplateChallengeOrSetPreferences,
    setEventTemplateChallengeOrSetPreferences,
    challengeListItemMap,
    paginationRef,
    collectionPrefRef,
    challengeToggleEnable,
  } = useEventTemplateChallenges();
  const {
    challengeListOpenSearchItems,
    getOpenSearchChallenges,
    challengeRequestOptions,
    setChallengeRequestOptions,
    challengesCount,
    handleFilterValues,
  } = useChallenges();

  const [currPageIndex, setCurrPageIndex] = useState<number>(1);
  const [numOfPages, setNumOfPages] = useState<number>(0);
  const [query, setQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'and',
  });

  useEffect(() => {
    void getOpenSearchChallenges(false, true, false);
  }, [challengeRequestOptions]);

  useEffect(() => {
    handleNumOfPages(challengeRequestOptions.limit);
  }, [challengesCount]);

  const handleNumOfPages = (limit: number) => {
    if (challengesCount > 0) {
      const page = Math.ceil(challengesCount / limit);
      setNumOfPages(page);
    }
  };

  // on pagination click eventList updates
  const onPageClick = (currentPageIndex: number) => {
    if (currentPageIndex !== currPageIndex) {
      setCurrPageIndex(currentPageIndex);
      const limit = challengeRequestOptions.limit;
      const newOffset = currentPageIndex === 1 ? 0 : (currentPageIndex - 1) * limit;
      setChallengeRequestOptions({ ...challengeRequestOptions, stability: false, offset: newOffset });
    }
  };

  const setFilterValues = (details: PropertyFilterProps.Query, toggleStabilityChecked: boolean) => {
    handleFilterValues(details, toggleStabilityChecked, true);
    setQuery(details);
    setCurrPageIndex(1);
  };

  useEffect(() => {
    setFilterValues(query, challengeToggleEnable);
  }, [challengeToggleEnable]);

  useEffect(() => {
    if (eventTemplateChallengeOrSetPreferences.pageSize) {
      setChallengeRequestOptions({
        ...challengeRequestOptions,
        limit: eventTemplateChallengeOrSetPreferences.pageSize,
      });
      handleNumOfPages(eventTemplateChallengeOrSetPreferences.pageSize);
    }
  }, [eventTemplateChallengeOrSetPreferences.pageSize]);

  const { loading, learningType, onSelectedChallengesChange, getEventDurationInfo } = useCreateEventTemplate();
  const { toggleChallengeDetailsInfo, toggleChallengeSetDetailsInfo } = useToolPanel();
  const { toggleShowSplitPanel, renderSplitPanelContent } = useSplitPanel();

  const validator = useMemo(() => {
    const minChallenges = getEventDurationInfo()?.minChallenges ?? 0;
    const maxChallenges = getEventDurationInfo()?.maxChallenges ?? 0;
    return challengeSelectValidator(
      minChallenges + BACKUP_CHALLENGE_LIMIT,
      Infinity,
      selectedChallengeListItems,
      t(i18nKeys.eventTemplates.step2.header.alert, {
        minChallenges,
        maxChallenges,
        backupChallengesLimit: BACKUP_CHALLENGE_LIMIT,
      }),
      new Map<EventTemplateChallengeSelectFields, (error: string) => void>([
        [EventTemplateChallengeSelectFields.EVENT_CHALLENGE_SELECT, (error: string) => setSelectChallengesError(error)],
      ])
    );
  }, [selectedChallengeListItems, getEventDurationInfo]);

  validationHandler(() => {
    const result = validator.isValidSection(true);
    if (result) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  });

  const handleSelectedChallengesChange = (challenges: ChallengeListItem[]) => {
    onSelectedChallengesChange(challenges);
    if (selectedChallengeListItems.length !== challenges.length) {
      onCurrentSelectedChallengeSetChange(undefined);
    }
    updateEventTemplateChallengeSplitPanelContent(challenges);
  };

  const handleSelectedChallengeSetChange = (selectedChallengeSet: ChallengeSet[]) => {
    if (selectedChallengeSet.length > 0) {
      const challengeSet = selectedChallengeSet[0];
      onCurrentSelectedChallengeSetChange(challengeSet);
      const challenges: ChallengeListItem[] = challengeSet.challengeIds
        .map((challengeId: string) => challengeListItemMap[challengeId])
        .filter((challengeListItem: ChallengeListItem) => challengeListItem);
      if (challenges) {
        onSelectedChallengesChange(challenges);
        updateEventTemplateChallengeSplitPanelContent(challenges);
      }
    } else {
      updateEventTemplateChallengeSplitPanelContent([]);
    }
  };

  const updateEventTemplateChallengeSplitPanelContent = (challenges: ChallengeListItem[]) => {
    toggleShowSplitPanel(challenges.length > 0);
    renderSplitPanelContent(
      `${challenges.length} ${t(i18nKeys.eventTemplates.tabs.common.challengeSelected)}`,
      <ColumnLayout variant="text-grid">
        <Table
          variant="borderless"
          columnDefinitions={columnDefinitions(toggleChallengeDetailsInfo, t)}
          items={challenges}
        />
      </ColumnLayout>
    );
  };

  const handleTabChange = (activeTabId: string) => {
    setActiveChallengeTabId(activeTabId);
    setPropertyFilterProps(undefined);
    setEventTemplateChallengeOrSetPreferences({
      ...eventTemplateChallengeOrSetPreferences,
      pageSize: 10,
      visibleContent:
        activeTabId === EventTemplateChallengeTabId.CHALLENGE
          ? visibleContentForChallenge
          : visibleContentForChallengeSet,
    });
  };

  const handlePropertyFilterChange = (propertyFilterProps: PropertyFilterProps) => {
    if (!propertyFilterPropsFromContext) {
      setPropertyFilterProps(propertyFilterProps);
    }
  };

  const handleAllPageItemsChange = (allPageItems: ChallengeListItem[] | ChallengeSet[]) => {
    return allPageItems.length !== allPagesItemsFromContext?.length && setAllPagesItems(allPageItems);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {selectChallengesError && (
        <Alert statusIconAriaLabel="Error" type="error">
          {selectChallengesError}
        </Alert>
      )}
      <Tabs
        onChange={({ detail }) => handleTabChange(detail.activeTabId)}
        activeTabId={activeChallengeTabId}
        tabs={[
          {
            id: EventTemplateChallengeTabId.CHALLENGE,
            label: t(i18nKeys.eventTemplates.tabs.label.challenges),
            content: (
              <ChallengesTable
                propertyFilterRef={propertyFilterRef}
                paginationRef={paginationRef}
                collectionPrefRef={collectionPrefRef}
                loading={loading || !challengeListOpenSearchItems}
                data={challengeListOpenSearchItems?.filter(
                  (c) => ChallengeUtils.isIndividualLearningType(c) === (learningType === EventLearningType.INDIVIDUAL)
                )}
                filterQuery={query}
                selectedItems={selectedChallengeListItems}
                filterProps={filteringProperties}
                preferences={eventTemplateChallengeOrSetPreferences}
                setPreferences={setEventTemplateChallengeOrSetPreferences}
                onSelectedItemsChange={handleSelectedChallengesChange}
                onPropertyFilterPropsChange={handlePropertyFilterChange}
                onAllPageItemsChange={handleAllPageItemsChange}
                onFilteredCountChange={setFiltertedEventTemplateChallengeOrSetCount}
                onToggleDetails={toggleChallengeDetailsInfo}
                currPageIndex={currPageIndex}
                numOfPages={numOfPages}
                challengesCount={challengesCount}
                onPageClick={onPageClick}
                setFilterValues={setFilterValues}
              />
            ),
          },
          {
            id: EventTemplateChallengeTabId.CHALLENGE_SETS,
            label: t(i18nKeys.eventTemplates.tabs.label.challengeSets),
            content: (
              <ChallengeSetTable
                propertyFilterRef={propertyFilterRef}
                paginationRef={paginationRef}
                collectionPrefRef={collectionPrefRef}
                data={challengeSets}
                challengeListItemMap={challengeListItemMap}
                loading={!challengeSets}
                selectedItems={currentSelectedChallengeSet ? [currentSelectedChallengeSet] : []}
                filterProps={filteringProperties}
                preferences={eventTemplateChallengeOrSetPreferences}
                setPreferences={setEventTemplateChallengeOrSetPreferences}
                onSelectedItemsChange={handleSelectedChallengeSetChange}
                onPropertyFilterPropsChange={handlePropertyFilterChange}
                onAllPageItemsChange={handleAllPageItemsChange}
                onFilteredCountChange={setFiltertedEventTemplateChallengeOrSetCount}
                onToggleDetails={toggleChallengeSetDetailsInfo}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
