/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from 'i18next';
import { ApiClient } from './ApiClient';
import { JamSupportChat, JamSupportChatDetails } from '../types/JamSupportChat';
import { asList } from '../utils/list.utils';
import { GAME_API_PREFIX } from '../constants/api';
import { i18nKeys } from '../utils/i18n.utils';

export class JamSupportChatAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  async createSupportChat(
    eventName: string,
    challengeId: string | null,
    reason: string,
    silent = false
  ): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/support-chat/start`,
      body: {
        eventName,
        reason,
        challengeId,
      },
      failMessage: this.t(i18nKeys.jamChat.supportChat.errors.createFail),
      silent,
    });
  }

  async getActiveSupportChats(eventName: string, silent = false): Promise<JamSupportChat[]> {
    return this.apiClient.get({
      path: `${GAME_API_PREFIX}/support-chat/${eventName}`,
      responseMapper: asList(JamSupportChat.fromPlainObject),
      failMessage: this.t(i18nKeys.jamChat.supportChat.errors.loadFail),
      silent,
    }) as Promise<JamSupportChat[]>;
  }

  async getAllSupportChats(eventName: string, silent = false): Promise<JamSupportChat[]> {
    return this.apiClient.get({
      path: `${GAME_API_PREFIX}/support-chat/${eventName}/all`,
      responseMapper: asList(JamSupportChat.fromPlainObject),
      failMessage: this.t(i18nKeys.jamChat.supportChat.errors.loadFail),
      silent,
    }) as Promise<JamSupportChat[]>;
  }

  async getUnassignedSupportChats(eventName: string, silent = false): Promise<JamSupportChat[]> {
    return this.apiClient.get({
      path: `${GAME_API_PREFIX}/support-chat/${eventName}/unassigned`,
      responseMapper: asList(JamSupportChat.fromPlainObject),
      silent,
    }) as Promise<JamSupportChat[]>;
  }

  async sendSupportChatMessage(chat: JamSupportChat, message: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/support-chat/${chat.id}/message`,
      body: { message },
      failMessage: this.t(i18nKeys.jamChat.supportChat.errors.sendFail),
      silent,
    });
  }

  async endSupportChat(chat: JamSupportChat, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/support-chat/${chat.id}/end`,
      failMessage: this.t(i18nKeys.jamChat.supportChat.errors.endFail),
      silent,
    });
  }

  async getSupportChatDetails(chatId: string, silent = false): Promise<JamSupportChatDetails> {
    return this.apiClient.get({
      path: `${GAME_API_PREFIX}/support-chat/${chatId}/details`,
      responseMapper: JamSupportChatDetails.fromPlainObject,
      failMessage: this.t(i18nKeys.jamChat.supportChat.errors.detailFail),
      silent,
    }) as Promise<JamSupportChatDetails>;
  }

  //   async getSupportChatLabKeyPair(chat: JamSupportChat, silent = false): Promise<any> {
  //     const response: string = await this.apiClient.get({
  //       path: `/support-chat/${chat.id}/labKeyPair`,
  //       responseType: 'text',
  //       silent,
  //     });

  //       if (response) {
  //         downloadAsPEM(response, "keypair");
  //       }
  //   }

  async selfAssignSupportChat(chatId: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/support-chat/${chatId}/assign`,
      failMessage: this.t(i18nKeys.facilitator.supportChats.errors.failedAssign),
      silent,
    });
  }

  async unassignSupportChat(chat: JamSupportChat, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/support-chat/${chat.id}/unassign`,
      silent,
    });
  }

  async supportChatRestartChallenge(chat: JamSupportChat, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/support-chat/${chat.id}/restartChallenge`,
      silent,
    });
  }

  async supportChatMarkChallengeSolved(chat: JamSupportChat, challengeTaskId: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `${GAME_API_PREFIX}/support-chat/${chat.id}/markChallengeSolved/${challengeTaskId}`,
      silent,
    });
  }
}
