import { faker } from '@faker-js/faker';
import { TFunction } from 'i18next';
// api client
import { ApiClient } from './ApiClient';

import {
  IEventTemplate,
  EventTemplateCreateOptions,
  EventDurationType,
  EventLearningType,
  EventTemplateStatusType,
  EventTemplateReviewResponse,
  EventTemplateReview,
  EventTemplateRating,
  ISortingState,
  EventTemplateReport,
  EventTemplateReportResponse,
  EventTemplateListResponse,
  IGetEventTemplatesOptions,
  GetEventTemplateOffersResponse,
} from '@/src/types/EventTemplate';
import { PropertyFilterProps } from '@amzn/awsui-components-react';
import { first } from 'lodash';
import { ChallengeListItem } from '../types/Challenge';
import { getMockEventTemplates } from '../__mocks__/mock-event-templates';

// TODO: move to types
type EventTemplateOptions = {
  name: string;
  learningType: EventLearningType;
  duration: EventDurationType;
  price: number;
  challenges?: { id: string; rationale?: string }[];
  summary?: string;
  topics?: string[];
  recommendedTeamSize?: number;
  minParticipants?: number;
  maxParticipants?: number;
  rating?: number;
  status?: EventTemplateStatusType;
  awsServices?: string[];
  challengeList?: ChallengeListItem[];
};


const EVENT_CATALOG_TEMPLATES_KEY = 'EVENT_CATALOG_TEMPLATES';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getAllEventTemplates = (sortingState?: ISortingState, filterQuery?: PropertyFilterProps.Query) => {
  const eventTemplateRawData = localStorage.getItem(EVENT_CATALOG_TEMPLATES_KEY);
  let eventTemplates: IEventTemplate[] = [];

  if (eventTemplateRawData) {
    eventTemplates = eventTemplates.concat(JSON.parse(eventTemplateRawData) as IEventTemplate[]);
  }

  if (sortingState) {
    const { sortingColumn, isDescending } = sortingState;
    const sortingField = sortingColumn.sortingField;
    if (isDescending) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      eventTemplates.sort((a, b) => (a?.[sortingField] < b?.[sortingField] ? -1 : 1));
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      eventTemplates.sort((a, b) => (a?.[sortingField] > b?.[sortingField] ? -1 : 1));
    }
  }

  if (filterQuery) {
    const { tokens } = filterQuery;
    eventTemplates = eventTemplates.filter((eventTemplate) => eventTemplate.status !== EventTemplateStatusType.DRAFT);
    if (Array.isArray(tokens) && tokens.length > 0) {
      const token = first(tokens) as PropertyFilterProps.Token;
      if (token.operator === '=' && token.propertyKey === 'title') {
        eventTemplates = eventTemplates.filter((eventTemplate: IEventTemplate) =>
          eventTemplate.name.includes(token.value as string)
        );
      }
      if (token.operator === '=' && token.propertyKey === 'challenges') {
        eventTemplates = eventTemplates.filter(
          (eventTemplate: IEventTemplate) => eventTemplate.challenges.length === Number(token.value)
        );
      }
      tokens.forEach((filterToken) => {
        if (filterToken.propertyKey === 'learningType') {
          eventTemplates = eventTemplates.filter((eventTemplate: IEventTemplate) =>
            (filterToken.value as EventLearningType[]).includes(eventTemplate.learningType)
          );
        }
        if (filterToken.propertyKey === 'duration') {
          eventTemplates = eventTemplates.filter((eventTemplate: IEventTemplate) =>
            (filterToken.value as EventDurationType).includes(eventTemplate.duration)
          );
        }
        if (filterToken.propertyKey === 'topic') {
          eventTemplates = eventTemplates.filter((eventTemplate: IEventTemplate) => {
            return eventTemplate.topics.some((topic) => {
              return (filterToken.value as string[]).includes(topic);
            });
          });
        }
        if (filterToken.propertyKey === 'price') {
          eventTemplates = eventTemplates.filter((eventTemplate: IEventTemplate) =>
            (filterToken.value as number[]).includes(eventTemplate.price)
          );
        }
        if (filterToken.propertyKey === 'awsservices') {
          eventTemplates = eventTemplates.filter(
            (eventTemplate: IEventTemplate, index) => index % filterToken.value.length !== 0
          );
        }
        if (filterToken.propertyKey === 'tag') {
          eventTemplates = eventTemplates.filter(
            (eventTemplate: IEventTemplate, index) => index % 2 === filterToken.value.length - 1
          );
        }
      });
    }
  }

  return eventTemplates;
};

// const saveSingleEventTemplate = (newEventTemplate: IEventTemplate) => {
//   const allEventTemplates = getAllEventTemplates();
//   allEventTemplates.push(newEventTemplate);
//   localStorage.setItem(EVENT_CATALOG_TEMPLATES_KEY, JSON.stringify(allEventTemplates));
//   return newEventTemplate;
// };

// const updateSingleEventTemplate = (id: string, updatedEventTemplate: Partial<EventTemplateOptions>) => {
//   const allEventTemplates = getAllEventTemplates();
//   const idx = allEventTemplates.findIndex((item) => item.id === id);
//   if (idx !== -1) {
//     allEventTemplates[idx] = {
//       ...allEventTemplates[idx],
//       ...updatedEventTemplate,
//     };
//     localStorage.setItem(EVENT_CATALOG_TEMPLATES_KEY, JSON.stringify(allEventTemplates));
//     return allEventTemplates[idx];
//   }
//   return undefined;
// };

// const deleteEventTemplate = (id: string) => {
//   const allEventTemplates = getAllEventTemplates();
//   const idx = allEventTemplates.findIndex((item) => item.id === id);
//   if (idx !== -1) {
//     allEventTemplates.splice(idx, 1);
//     localStorage.setItem(EVENT_CATALOG_TEMPLATES_KEY, JSON.stringify(allEventTemplates));
//     return allEventTemplates[idx];
//   }
//   return undefined;
// };

const generateReview = (): EventTemplateReview => {
  return {
    id: faker.string.uuid(),
    imgURL: 'https://static.thenounproject.com/png/363639-200.png',
    userName: faker.person.firstName(),
    rating: Math.floor(Math.random() * 5) + 1,
    review: faker.lorem.sentences(),
    date: faker.date.past().toLocaleDateString(),
    liked: true,
  };
};

const fetchEventTemplateReviews = async (
  count: number,
  pageNumber: number,
  itemsPerPage: number
): Promise<EventTemplateReviewResponse> => {
  const allData: EventTemplateReview[] = [];

  for (let i = 0; i < count; i++) {
    allData.push(generateReview());
  }

  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const data = allData.slice(startIndex, endIndex);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        totalData: count,
        pageNumber,
        itemsPerPage,
        data,
        allData,
      });
    }, 400);
  });
};

const fetchEventTemplateRatings = async (): Promise<EventTemplateRating> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        averageRating: Math.floor(Math.random() * 5) + 1,
        numberOfRatings: faker.number.int({ min: 1, max: 300 }),
        fiveStarProportion: faker.number.int({ min: 1, max: 100 }),
        fourStarProportion: faker.number.int({ min: 1, max: 100 }),
        threeStarProportion: faker.number.int({ min: 1, max: 100 }),
        twoStarProportion: faker.number.int({ min: 1, max: 100 }),
        oneStarProportion: faker.number.int({ min: 1, max: 100 }),
      });
    }, 400);
  });
};

export const fetchEventTemplateReports = (): Promise<EventTemplateReportResponse> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const data = generateReport(500);

      const response: EventTemplateReportResponse = {
        data,
        eventsUsed: data.length,
        revenueGenerated: faker.number.int({ min: 1, max: 1000 }),
        learnersParticipated: faker.number.int({ min: 1, max: 1000 }),
        labCostGenerated: faker.number.int({ min: 1, max: 1000 }),
      };

      resolve(response);
    }, 100);
  });
};

const generateReport = (rows: number): EventTemplateReport[] => {
  const data: EventTemplateReport[] = [];

  for (let i = 0; i < rows; i++) {
    const rowData: EventTemplateReport = {
      title: faker.lorem.words(3),
      owner: faker.person.firstName(),
      date: faker.date.past(),
      learners: faker.number.int({ min: 1, max: 1000 }),
      totalRevenue: faker.finance.amount(1000, 100000, 2),
    };

    data.push(rowData);
  }

  return data;
};

export const fetchTrendingEventTemplate = async (
  count: number,
  pageNumber: number,
  itemsPerPage: number
): Promise<EventTemplateListResponse> => {
  const allData = getMockEventTemplates();

  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const data = allData.slice(startIndex, endIndex);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        totalData: count,
        pageNumber,
        itemsPerPage,
        data,
        allData,
      });
    }, 400);
  });
};

export class EventTemplateAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  public async getEventTemplates(options?: IGetEventTemplatesOptions) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = (await this.apiClient.get({ path: '/catalog/templates', params: options })) as {
      templates: IEventTemplate[];
      nextToken?: string;
    };
    const templates = response.templates;
    return { data: templates, totalCount: templates.length };
  }

  public async getPublicEventTemplates(options?: Omit<IGetEventTemplatesOptions, 'statuses' | 'deleted'>) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = (await this.apiClient.get({ path: '/public/catalog/templates', params: options })) as {
      templates: IEventTemplate[];
      nextToken?: string;
    };
    const templates = response.templates;
    return { data: templates, totalCount: templates.length };
  }

  public async createNewEventTemplate(options: EventTemplateCreateOptions) {
    const response = (await this.apiClient.post({
      path: '/catalog/templates',
      body: options,
    })) as IEventTemplate;
    return response;
  }

  public async getEventTemplateById(id: string) {
    const response = (await this.apiClient.get({ path: `/catalog/templates/${id}` })) as IEventTemplate;
    return response;
  }

  public async getPublicEventTemplateById(id: string) {
    const response = (await this.apiClient.get({ path: `/public/catalog/templates/${id}` })) as IEventTemplate;
    return response;
  }

  public async updateEventTemplateById(id: string, options: Partial<EventTemplateOptions>) {
    const response = (await this.apiClient.put({ path: `/catalog/templates/${id}`, body: options })) as IEventTemplate;
    return response;
  }

  public async uploadEventTemplateImage(id: string, image: File) {
    const form = new FormData();
    form.append('image', image);
    const response = (await this.apiClient.post({ path: `/catalog/image/${id}`, body: form }, true)) as {
      presignedUrl: string;
    };
    return response.presignedUrl;
  }

  public async deleteEventTemplateById(id: string) {
    const response = (await this.apiClient.delete({ path: `/catalog/templates/${id}` })) as IEventTemplate;
    return response;
  }

  public async getPublicEventTemplateOffers() {
    const response = (await this.apiClient.get({ path: '/public/catalog/offers' })) as GetEventTemplateOffersResponse;
    return response;
  }

  public async getEventTemplateReports(id: string) {
    return fetchEventTemplateReports();
    const response = (await this.apiClient.get({
      path: `/catalog/templates/${id}/reports`,
    })) as EventTemplateReportResponse;
    return response;
  }

  public async getEventTemplateRating(id: string) {
    return fetchEventTemplateRatings();
    const response = (await this.apiClient.get({
      path: `/catalog/templates/${id}/rating`,
    })) as EventTemplateRating;
    return response;
  }

  public async getEventTemplateReviews(id: string, count: number, pageNumber: number, itemsPerPage: number) {
    return fetchEventTemplateReviews(count, pageNumber, itemsPerPage);
    const response = (await this.apiClient.get({
      path: `/catalog/templates/${id}/reviews`,
    })) as EventTemplateReviewResponse;
    return response;
  }
}
