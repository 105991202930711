import { BACKUP_CHALLENGE_LIMIT, MIN_TEAM_SIZE } from '@/src/constants/event-template.constants';
import { safeString } from './string.utils';
import {
  EventCatalogTemplateDetailsFields,
  EventDurationType,
  EventTemplateChallengeOrderFields,
  EventTemplateChallengeSelectFields,
  EventTemplateDetailFields,
  EventTemplateNameDurationFields,
  EventTemplateValidationFields,
  InputValidationHelper,
} from '@/src/types/EventTemplate';
import { ChallengeListItem } from '../types/Challenge';
import { isEmpty } from 'lodash';
import { isValidLength } from './validation.utils';
import moment from 'moment-timezone';

const validateField = (
  validatorHelperByField: Map<EventTemplateValidationFields, InputValidationHelper>,
  errorSetterByField: Map<EventTemplateValidationFields, (error: string) => void>,
  field: EventTemplateValidationFields,
  setError = true
) => {
  const validation = validatorHelperByField.get(field);
  if (!validation) {
    throw new Error('Validation logic does not exist!');
  }
  const isValid = validation.isValid();
  const error = validation.checkErrors();
  if (setError) {
    const setErrorFn =
      errorSetterByField.get(field) ||
      (() => {
        // do nothing
      });
    setErrorFn(error);
  }
  // preProdLogger('Validated', field, 'isValid:', isValid, 'error:', error);
  return isValid;
};

const validateSection = (
  validatorHelperByField: Map<EventTemplateValidationFields, InputValidationHelper>,
  errorSetterByField: Map<EventTemplateValidationFields, (error: string) => void>,
  setErrors = false
): boolean => {
  const fieldIsValid: boolean[] = [];
  const fields = Array.from(errorSetterByField.keys());
  for (const key of fields) {
    fieldIsValid.push(validateField(validatorHelperByField, errorSetterByField, key, setErrors));
  }
  return fieldIsValid.reduce((prev, next) => prev && next);
};

const validateEventDuration = (duration: EventDurationType | undefined, errorText: string) => {
  const isValid = !!duration;
  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

const validateECTTitle = (title: string, errorText: string) => {
  const input = safeString(title);
  const isValid = isValidLength(input, 4, 250);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const eventNameDurationValidator: (
  name: string,
  duration: EventDurationType | undefined,
  nameErrorText: string,
  durationErrorText: string,
  errorSetterByField: Map<EventTemplateNameDurationFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventTemplateNameDurationFields, setError?: boolean) => boolean;
} = (
  name: string,
  duration: EventDurationType | undefined,
  nameErrorText: string,
  durationErrorText: string,
  errorSetterByField: Map<EventTemplateNameDurationFields, (error: string) => void>
) => {
  const validatorHelperByField = new Map<EventTemplateNameDurationFields, InputValidationHelper>([
    [EventTemplateNameDurationFields.EVENT_NAME, validateECTTitle(name, nameErrorText)],
    [EventTemplateNameDurationFields.EVENT_DURATION, validateEventDuration(duration, durationErrorText)],
  ]);

  const isValidField = (field: EventTemplateNameDurationFields, setError = true) => {
    return validateField(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};

const validateSelectedChallenges = (
  minChallenges: number,
  maxChallenges: number,
  selectedChallenges: ChallengeListItem[],
  errorText: string
) => {
  const isValid = selectedChallenges.length >= minChallenges && selectedChallenges.length <= maxChallenges;
  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const challengeSelectValidator: (
  minChallenges: number,
  maxChallenges: number,
  selectedChallenges: ChallengeListItem[],
  errorText: string,
  errorSetterByField: Map<EventTemplateChallengeSelectFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventTemplateChallengeSelectFields, setError?: boolean) => boolean;
} = (
  minChallenges: number,
  maxChallenges: number,
  selectedChallenges: ChallengeListItem[],
  errorText: string,
  errorSetterByField: Map<EventTemplateChallengeSelectFields, (error: string) => void>
) => {
  const validatorHelperByField = new Map<EventTemplateChallengeSelectFields, InputValidationHelper>([
    [
      EventTemplateChallengeSelectFields.EVENT_CHALLENGE_SELECT,
      validateSelectedChallenges(minChallenges, maxChallenges, selectedChallenges, errorText),
    ],
  ]);

  const isValidField = (field: EventTemplateChallengeSelectFields, setError = true) => {
    return validateField(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};

const validateChallengeOrder = (
  minChallenges: number,
  maxChallenges: number,
  primaryChallenges: ChallengeListItem[],
  backupChallenges: ChallengeListItem[],
  errorText: string
) => {
  const isValid =
    primaryChallenges.length >= minChallenges &&
    primaryChallenges.length <= maxChallenges &&
    backupChallenges.length >= BACKUP_CHALLENGE_LIMIT;

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const challengeOrderValidator: (
  minChallenges: number,
  maxChallenges: number,
  primaryChallenges: ChallengeListItem[],
  backupChallenges: ChallengeListItem[],
  errorText: string,
  errorSetterByField: Map<EventTemplateChallengeOrderFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventTemplateChallengeOrderFields, setError?: boolean) => boolean;
} = (minChallenges, maxChallenges, primaryChallenges, backupChallenges, errorText, errorSetterByField) => {
  const validatorHelperByField = new Map<EventTemplateChallengeOrderFields, InputValidationHelper>([
    [
      EventTemplateChallengeOrderFields.CHALLENGE_ORDER,
      validateChallengeOrder(minChallenges, maxChallenges, primaryChallenges, backupChallenges, errorText),
    ],
  ]);

  const isValidField = (field: EventTemplateChallengeOrderFields, setError = true) => {
    return validateField(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};

const validateEventSummary = (summary: string, errorText: string) => {
  const isValid = !isEmpty(summary);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

const validateEventTopics = (topics: string[], errorText: string) => {
  const isValid = Array.isArray(topics) && topics?.length > 0;

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

const validateEventTeamSize = (teamSize: number, errorText: string) => {
  const isValid = teamSize !== 0 && teamSize >= MIN_TEAM_SIZE;

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

const validateMinParticipants = (
  value: number | string,
  maxParticipants: undefined | number | string,
  errors: {
    required: string;
    notGreaterThanMax: string;
  }
) => {
  const num = parseInt(value as string, 10);
  let isValid = Number.isSafeInteger(num) && num > 0;
  let error = errors.required;

  if (isValid) {
    // validate against maximum only when it's set
    const maxNum = parseInt(maxParticipants as string, 10);
    if (Number.isSafeInteger(maxNum)) {
      isValid = num <= maxNum;
      error = errors.notGreaterThanMax;
    }
  }

  return {
    isValid: () => isValid,
    checkErrors: () => (isValid ? '' : error),
  };
};

const validateMaxParticipants = (
  value: number | string,
  minParticipants: number | string,
  errors: {
    required: string;
    notLessThanMin: string;
  }
) => {
  const num = parseInt(value as string, 10);
  let isValid = Number.isSafeInteger(num) && num > 0;
  let error = errors.required;

  if (isValid) {
    isValid = num >= parseInt(minParticipants as string, 10);
    error = errors.notLessThanMin;
  }

  return {
    isValid: () => isValid,
    checkErrors: () => (isValid ? '' : error),
  };
};

const validateEventTags = (topics: string[], errorText: string) => {
  const isValid = topics === undefined || topics === null || Array.isArray(topics);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const eventTemplateDetailsValidator: (
  summary: string,
  topics: string[],
  teamSize: number,
  minParticipants: number,
  maxParticipants: number,
  tags: string[],
  summaryErrorText: string,
  topicsErrorText: string,
  teamSizeErrorText: string,
  minParticipantsErrors: {
    required: string;
    notGreaterThanMax: string;
  },
  maxParticipantsErrors: {
    required: string;
    notLessThanMin: string;
  },
  tagsErrorText: string,
  errorSetterByField: Map<EventTemplateDetailFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventTemplateDetailFields, setError?: boolean) => boolean;
} = (
  summary,
  topics,
  teamSize,
  minParticipants,
  maxParticipants,
  tags,
  summaryErrorText,
  topicsErrorText,
  teamSizeErrorText,
  minParticipantsErrors,
  maxParticipantsErrors,
  tagsErrorText,
  errorSetterByField: Map<EventTemplateDetailFields, (error: string) => void>
) => {
  const validatorHelperByField = new Map<EventTemplateDetailFields, InputValidationHelper>([
    [EventTemplateDetailFields.EVENT_SUMMARY, validateEventSummary(summary, summaryErrorText)],
    [EventTemplateDetailFields.EVENT_TOPIC, validateEventTopics(topics, topicsErrorText)],
    [EventTemplateDetailFields.EVENT_TEAM_SIZE, validateEventTeamSize(teamSize, teamSizeErrorText)],
    [
      EventTemplateDetailFields.EVENT_MIN_PARTICIPANTS,
      validateMinParticipants(minParticipants, maxParticipants, minParticipantsErrors),
    ],
    [
      EventTemplateDetailFields.EVENT_MAX_PARTICIPANTS,
      validateMaxParticipants(maxParticipants, minParticipants, maxParticipantsErrors),
    ],
    [EventTemplateDetailFields.EVENT_TAGS, validateEventTags(tags, tagsErrorText)],
  ]);

  const isValidField = (field: EventTemplateDetailFields, setError = true) => {
    return validateField(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};

const validateHeadCount = (
  headCount: string,
  minParticipants: number,
  maxParticipants: number,
  errorText: { required: string; minimum: string; maximum: string }
) => {
  let isValid = !isEmpty(headCount);
  let error = errorText.required;
  if (isValid) {
    isValid = parseInt(headCount, 10) >= minParticipants;
    error = `${errorText.minimum} ${minParticipants}`;

    if (isValid) {
      isValid = parseInt(headCount, 10) <= maxParticipants;
      error = `${errorText.maximum} ${maxParticipants}`;
    }
  }
  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? error : ''),
  };
};

const validateStartDate = (startDate: string, timeZone: string, hoursBefore: number, errorText: { required: string; minimum: string }) => {
  const isRequired = isEmpty(startDate);
  const isInPast = moment(startDate).tz(timeZone).isBefore(moment().add(hoursBefore, 'hours').tz(timeZone), 'date');
  return {
    isValid: () => !isRequired && !isInPast,
    checkErrors: () => {
      if (isRequired) return errorText.required;
      return isInPast ? errorText.minimum : '';
    },
  };
};

const validateStartTime = (
  startDate: string,
  startTime: string,
  timeFormat: string,
  timeZone: string,
  hoursBefore: number, 
  errorText: { required: string; minimum: string }
) => {
  const isRequired = isEmpty(startTime);
  let isInPast = false;
  if (startDate && !isRequired) {
    const startDateTime = moment(`${startDate} ${startTime} ${timeFormat}`, 'YYYY/MM/DD hh:mm A').tz(timeZone);
    isInPast = startDateTime.isBefore(moment().add(hoursBefore, 'hours').tz(timeZone));
  }
  return {
    isValid: () => !isRequired && !isInPast,
    checkErrors: () => {
      if (isRequired) return errorText.required;
      return isInPast ? errorText.minimum : '';
    },
  };
};

export const eventCatalogTemplateDetailsValidator = (
  headCount: string,
  minParticipants: number,
  maxParticipants: number,
  headCountErrorText: { required: string; minimum: string; maximum: string },
  startDate: string,
  startDateErrorText: { required: string; minimum: string },
  startTime: string,
  startTimeErrorText: { required: string; minimum: string },
  timeFormat: string,
  timeZone: string,
  hoursBefore: number,
  errorSetterByField: Map<EventCatalogTemplateDetailsFields, (error: string) => void>
): {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventCatalogTemplateDetailsFields, setError?: boolean) => boolean;
} => {
  const validatorHelperByField = new Map<EventCatalogTemplateDetailsFields, InputValidationHelper>([
    [
      EventCatalogTemplateDetailsFields.HEAD_COUNT,
      validateHeadCount(headCount, minParticipants, maxParticipants, headCountErrorText),
    ],
    [EventCatalogTemplateDetailsFields.START_DATE, validateStartDate(startDate, timeZone, hoursBefore, startDateErrorText)],
    [
      EventCatalogTemplateDetailsFields.START_TIME,
      validateStartTime(startDate, startTime, timeFormat, timeZone, hoursBefore, startTimeErrorText),
    ],
  ]);

  const isValidField = (field: EventCatalogTemplateDetailsFields, setError = true) => {
    return validateField(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};
