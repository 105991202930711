import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useApi } from './api.context';
import {
  JamChallengeDetails,
  JamProgress,
  JamTask,
  JamTaskClue,
  SolveChallengeButtonKey,
} from '../types/JamChallengeDetails';
import { TaskValidationResponse } from '../types/JamChallenges';
import { AwsAccount, TeamChallengePropertiesResponse } from '../types/JamFacilitator';
import { KeyValuePair } from '../types/KeyValuePair';
import { getErrorCode, getErrorMessage } from '../utils/errors.utils';
import { safeString } from '../utils/string.utils';
import { noop } from 'lodash';
import { JamEventSupportDetails } from '../types/JamChallengeSupportDetails';
import { TeamChallengeQueueInfo } from '../types/TeamChallengeQueueInfo';
import localStorageTTL from '@/src/utils/localStorageTTL.utils';

export interface JamChallengeDetailsContextValue {
  isFetchingJamChallengeDetails: boolean;
  loadJamChallengeDetails: (params: { id: string; challengeId: string }) => Promise<JamChallengeDetails>;
  loadJamChallengeTaskClue: (
    params: { id: string; challengeId: string },
    taskId: string,
    clueOrder: number
  ) => Promise<JamTaskClue>;
  selectedJamChallenge: JamChallengeDetails | undefined;
  startJamChallenge: (params: { id: string; challengeId: string }) => Promise<void>;
  reStartJamChallenge: (params: { id: string; challengeId: string; reason: string }) => Promise<void>;
  loadJamChallengeProgressData: (params: { id: string; challengeId: string }) => Promise<JamProgress>;
  validateTaskAnswer: (
    params: { id: string; challengeId: string },
    answer: string,
    taskId: string | undefined
  ) => Promise<boolean>;
  challengeProgress: JamProgress | undefined;
  codeWhispererUrl: string | undefined;
  task: JamTask | undefined;
  awsAccount: AwsAccount | undefined;
  awsAccountProperties: KeyValuePair[];
  outputProperties: KeyValuePair[] | undefined;
  getJamChallengeTask: (params: { taskId: string }) => void;
  taskValidationResponse: TaskValidationResponse | undefined;
  loadTeamProperties: (eventName: string, challengeId: string) => Promise<void>;
  teamPropertiesResponse: TeamChallengePropertiesResponse | undefined;
  trackSolveWithButtonClick: (
    eventName: string,
    challengeId: string,
    buttonKey: SolveChallengeButtonKey
  ) => Promise<void>;
  loadTeamKeyPair: (eventName: string, challengeId: string) => Promise<void>;
  jamChallengeId: string;
  isStartingChallange: boolean;
  showChallengeStatusProgress: boolean;
  showChallengeStatusSuccess: boolean;
  enableChallengeStatusModal: boolean;
  setEnableChallengeStatusModalFunc: () => void;
  isFetchingTaskClue: boolean;
  fetchingtaskValidationResponse: boolean;
  loadChallengeSupportDetails: (eventName: string, challengeId: string) => Promise<void>;
  challengeSupportDetails?: JamEventSupportDetails;
  loadJamChallengeQueueInfo: (eventName: string, challengeId: string) => Promise<void>;
  queueInfo?: TeamChallengeQueueInfo;
  challengeModalMap?: { [id: string]: boolean };
  handleWSChallengeProgressUpdate: (o: JamProgress) => Promise<void> | void;
}

const JamChallengeDetailsContext = React.createContext<JamChallengeDetailsContextValue>({
  isFetchingJamChallengeDetails: true,
  loadJamChallengeDetails: () => new Promise(noop),
  loadJamChallengeTaskClue: () => new Promise(noop),
  selectedJamChallenge: undefined,
  startJamChallenge: () => new Promise(noop),
  reStartJamChallenge: () => new Promise(noop),
  loadJamChallengeProgressData: () => new Promise(noop),
  validateTaskAnswer: () => new Promise(noop),
  challengeProgress: undefined,
  codeWhispererUrl: undefined,
  task: undefined,
  awsAccount: undefined,
  awsAccountProperties: [],
  outputProperties: undefined,
  getJamChallengeTask: noop,
  taskValidationResponse: undefined,
  loadTeamProperties: () => new Promise(noop),
  teamPropertiesResponse: undefined,
  trackSolveWithButtonClick: () => new Promise(noop),
  loadTeamKeyPair: () => new Promise(noop),
  jamChallengeId: '',
  isStartingChallange: false,
  showChallengeStatusProgress: false,
  showChallengeStatusSuccess: false,
  enableChallengeStatusModal: false,
  setEnableChallengeStatusModalFunc: noop,
  isFetchingTaskClue: false,
  fetchingtaskValidationResponse: false,
  loadChallengeSupportDetails: () => new Promise(noop),
  challengeSupportDetails: undefined,
  loadJamChallengeQueueInfo: () => new Promise(noop),
  queueInfo: undefined,
  challengeModalMap: {},
  handleWSChallengeProgressUpdate: noop
});

const JamChallengeDetailsProvider: React.FC = ({ children }) => {
  const { jamChallengeDetailsApi, jamFacilitatorApi } = useApi();
  const [selectedJamChallenge, setSelectedJamChallenge] = useState<JamChallengeDetails>();
  const [selectedJamChallengeId, setSelectedJamChallengeId] = useState<string>();
  const [selectedJamEventId, setSelectedJamEventId] = useState<string>();
  const [challengeProgress, setChallengeProgress] = useState<JamProgress>();
  const [fetchingtaskValidationResponse, setFetchingTaskValidationResponse] = useState(false);
  const [taskValidationResponse, setTaskValidationResponse] = useState<TaskValidationResponse>();
  const [teamPropertiesResponse, setTeamPropertiesResponse] = useState<TeamChallengePropertiesResponse>();
  const [isFetchingJamChallengeDetails, setIsFetchingJamChallenge] = useState(true);
  const [task, setTask] = useState<JamTask>();
  const [isFetchingTaskClue, setIsFetchingTaskClue] = useState(false);
  const [codeWhispererUrl, setCodeWhispererUrl] = useState<string | undefined>(undefined);
  const [awsAccount, setAwsAccount] = useState<AwsAccount | undefined>(undefined);
  const [awsAccountProperties, setAwsAccountProperties] = useState<KeyValuePair[]>([]);
  const [outputProperties, setOutputProperties] = useState<KeyValuePair[]>();
  const [isStartingChallange, setIsStartingChallange] = useState(false);
  const [showChallengeStatusProgress, setShowChallengeStatusProgress] = useState<boolean>(false);
  const [showChallengeStatusSuccess, setShowChallengeStatusSuccess] = useState<boolean>(false);
  const [enableChallengeStatusModal, setEnableChallengeStatusModal] = useState<boolean>(false);
  const [challengeModalMap, setChallengeModalMap] = useState<{ [id: string]: boolean }>({});
  const [challengeSupportDetails, setChallengeSupportDetails] = useState<JamEventSupportDetails>();
  const [queueInfo, setQueueInfo] = useState<TeamChallengeQueueInfo>();
  const jamChallengeId = selectedJamChallenge?.id || '';

  const resetChallengeState = () => {
    setOutputProperties(undefined);
    setEnableChallengeStatusModal(false);
    setAwsAccountProperties([]);
    setAwsAccount(undefined);
    setCodeWhispererUrl(undefined);
    setSelectedJamChallenge(undefined);
  };

  const loadJamChallengeDetails = async (params: { id: string; challengeId: string }): Promise<JamChallengeDetails> => {
    resetChallengeState();
    const { id, challengeId } = params;
    if (!id && !challengeId) {
      throw new Error('id and challengeId is required.');
    }
    setSelectedJamChallengeId(challengeId);
    setSelectedJamEventId(id);
    setChallengeModalMapFromStorage(id);
    try {
      const response = await jamChallengeDetailsApi.fetchJamChallengeDetailsData(id, challengeId);
      localStorageTTL.setItem(`challenge-details-${id}-${challengeId}`, response, 1, "hours");
      setIsFetchingJamChallenge(false);
      setSelectedJamChallenge(response);
      return response;
    } catch (e: any) {
      setIsFetchingJamChallenge(false);
      const errorCode = getErrorCode(e);
      if (errorCode === 710) {
        const previousData = localStorageTTL.getItem(`challenge-details-${id}-${challengeId}`) as JamChallengeDetails | undefined;
        if (!!previousData?.id) {
          setSelectedJamChallenge(previousData);
        }
        await loadJamChallengeProgressData({ id, challengeId });
        toggleChallengeStatusModal(true, false, challengeId);
      }
      // if API failed catch here.
      throw e;
    }
  };

  useEffect(() => {
    setShowChallengeStatusProgress(false);
    setShowChallengeStatusSuccess(false);
  }, [selectedJamChallengeId]);

  const setChallengeModalMapFromStorage = (eventId: string) => {
    const challangeModalMapRaw: string | null = localStorage.getItem(eventId);
    if (challangeModalMapRaw) {
      const newChallengeModalMap: { [id: string]: boolean } = JSON.parse(challangeModalMapRaw) as {
        [id: string]: boolean;
      };
      setChallengeModalMap(newChallengeModalMap);
    }
  };

  const loadJamChallengeQueueInfo = async (eventName: string, challengeId: string) => {
    try {
      setQueueInfo(undefined);
      const response = await jamChallengeDetailsApi.getChallengeQueueInfo(eventName, challengeId);
      setQueueInfo(response);
    } catch (e) {
      // API handled error
    }
  };

  const loadJamChallengeTaskClue = async (
    params: { id: string; challengeId: string },
    taskId: string,
    clueOrder: number
  ): Promise<JamTaskClue> => {
    const { id, challengeId } = params;
    setIsFetchingTaskClue(true);

    try {
      const jamClue: JamTaskClue = await jamChallengeDetailsApi.fetchJamChallengeTaskClueData(
        id,
        challengeId,
        taskId,
        clueOrder
      );

      if (task?.clues) {
        const filteredClueIndex = task.clues?.findIndex((clue: JamTaskClue) => clue.order === jamClue.order);
        if (filteredClueIndex !== -1) {
          const newClues = task.clues;
          newClues[filteredClueIndex].description = jamClue.description;
          // if we want unlock clue button to
          // convert to show clue after above API call
          // we need to do `used = true` explicitly
          newClues[filteredClueIndex].used = true;
          setTask({ ...task, clues: newClues });
        }
      }
      setIsFetchingTaskClue(false);
      return jamClue;
    } catch (e: any) {
      // if API failed catch here.
      // If the error is 104 (clue already requested), the error message is the clue description
      if(getErrorCode(e) === 104 && task?.clues){
        const filteredClueIndex = task.clues?.findIndex((clue: JamTaskClue) => clue.order === clueOrder);
        if (filteredClueIndex !== -1) {
          const newClues = task.clues;
          newClues[filteredClueIndex].description = getErrorMessage(e);
          newClues[filteredClueIndex].used = true;
          setTask({ ...task, clues: newClues });
        }
      }
      setIsFetchingTaskClue(false);
      throw e;
    }
  };

  const loadChallengeSupportDetails = async (eventName: string, challengeId: string) => {
    try {
      const response = await jamFacilitatorApi.getEventSupportDetails(safeString(eventName));
      setChallengeSupportDetails(response);
    } catch (e) {
      setIsFetchingJamChallenge(false);
      const errorCode = getErrorCode(e);
      if (errorCode === 710) {
        // TODO: move to errorcodes list
        toggleChallengeStatusModal(true, false, challengeId);
      }
      // if API failed catch here.
      throw e;
    }
  };

  const startJamChallenge = async (params: { id: string; challengeId: string }) => {
    setIsStartingChallange(true);
    const { id, challengeId } = params;
    try {
      const response = await jamChallengeDetailsApi.startJamChallenge(id, challengeId);
      await loadJamChallengeProgressData({ id, challengeId });
      await loadJamChallengeDetails({ id, challengeId });
      if (selectedJamChallenge?.awsLabAccount) {
        await loadTeamProperties(id, challengeId);
      }
      setIsStartingChallange(false);
      // hack: it takes a small amount to get the lab set and start the queue.
      // maybe we can use websocket. not sure though. need to check
      // for now load it here
      setTimeout(() => {
        void loadJamChallengeQueueInfo(id, challengeId);
      }, 1500);
      return response;
    } catch (e: any) {
      setIsStartingChallange(false);
      // if API failed catch here.
      throw e;
    }
  };

  const reStartJamChallenge = async (params: { id: string; challengeId: string; reason: string }) => {
    setIsStartingChallange(true);
    const { id, challengeId, reason } = params;
    try {
      await jamChallengeDetailsApi.restartJamChallenge(id, challengeId, reason);
      await loadJamChallengeProgressData({ id, challengeId });
      if (selectedJamChallenge?.awsLabAccount) {
        await loadTeamProperties(id, challengeId);
      }
      setIsStartingChallange(false);
    } catch (e: any) {
      setIsStartingChallange(false);
      // if API failed catch here.
      throw e;
    }
  };

  const toggleChallengeStatusModal = useCallback(
    (progress: boolean, success: boolean, challengeId: string) => {
      if (challengeId) {
        if (!challengeModalMap[challengeId]) setEnableChallengeStatusModal(true);
        setShowChallengeStatusProgress(progress);
        setShowChallengeStatusSuccess(success);
      }
    },
    [challengeModalMap]
  );

  const loadJamChallengeProgressData = useCallback(
    async (params: { id: string; challengeId: string }): Promise<JamProgress> => {
      setEnableChallengeStatusModal(false);
      const { id, challengeId } = params;
      try {
        const response = await jamChallengeDetailsApi.fetchJamChallengeProgress(id, challengeId);
        setChallengeProgress(response);
        if (response?.started && (response?.hasAwsResources || response?.hasExternalResources)) {
          void loadTeamProperties(id, challengeId);
        }
        return Promise.resolve(response);
      } catch (e: any) {
        // if API failed catch here.
        throw e;
      }
    },
    [jamChallengeDetailsApi, toggleChallengeStatusModal]
  );

  const getJamChallengeTask = (params: { taskId: string }) => {
    const { taskId } = params;
    const foundTask = selectedJamChallenge?.tasks?.find((taskItem) => taskItem.id === taskId);
    setTask(foundTask);
  };

  const validateTaskAnswer = async (
    params: { id: string; challengeId: string },
    answer: string,
    taskId: string | undefined
  ): Promise<boolean> => {
    const { id, challengeId } = params;
    setFetchingTaskValidationResponse(true);
    try {
      const response = await jamChallengeDetailsApi.validateTaskAnswer(id, challengeId, answer, taskId);
      setTaskValidationResponse(response);
      if (response.taskCompleted) {
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    } finally {
      setFetchingTaskValidationResponse(false);
    }
  };

  const loadTeamProperties = async (eventName: string, challengeId: string) => {
    try {
      const response = await jamChallengeDetailsApi.getTeamProperties(eventName, challengeId);
      setTeamPropertiesResponse(response);
      onTeamChallengePropertiesLoaded(response.properties || []);
      toggleChallengeStatusModal(false, true, challengeId);
    } catch (e: any) {
      const errorCode = getErrorCode(e);
      if (errorCode === 710) {
        // TODO: move to errorcodes list
        toggleChallengeStatusModal(true, false, challengeId);
      }
      // if API failed catch here.
      throw e;
    }
  };

  const onTeamChallengePropertiesLoaded = (properties: KeyValuePair[]) => {
    setCodeWhispererUrl(undefined);
    setAwsAccount(undefined);

    if (!properties) {
      setAwsAccount(undefined);
      return;
    }

    const accountParams: string[] = [
      'team.accountNumber',
      'team.region',
      'team.url',
      'team.accessKey',
      'team.secretKey',
      'team.sessionToken',
      'team.token.expiration',
      'team.url.expiration',
    ];
    const propertiesMap: { [key: string]: number | string } = {
      'team.accountNumber': '',
      'team.region': '',
      'team.url': '',
      'team.accessKey': '',
      'team.secretKey': '',
      'team.sessionToken': '',
      'team.token.expiration': 0,
      'team.url.expiration': 0,
    };
    const codeWhisperKey = 'team.stackOutput.CodeWhispererCloud9URL';

    //* calculation of remaining properties
    const remainingProperties: KeyValuePair[] = [];
    for (const keyValue of properties) {
      if (!propertiesMap.hasOwnProperty(keyValue.key) && keyValue.key !== codeWhisperKey) {
        remainingProperties.push(keyValue);
      }
    }
    setOutputProperties(remainingProperties);

    let codeWhispererUrlFromOutput = '';

    properties
      .filter((property) => property.key != null)
      .forEach(({ key, value, type, label, group }) => {
        if (accountParams.includes(key)) {
          propertiesMap[key] = value;
        } else {
          if (!properties) {
            setAwsAccountProperties([]);
          }

          key = key.replace('team.', '').replace('stackOutput.', '');

          if (key === 'CodeWhispererCloud9URL') {
            codeWhispererUrlFromOutput = value;
          } else {
            awsAccountProperties.push({ key, value, type, label, group });
          }
        }
      });

    const accountNumber = propertiesMap['team.accountNumber'];

    if (accountNumber) {
      const AwsAccountInfo = new AwsAccount();
      AwsAccountInfo.accountNumber = accountNumber.toString();
      AwsAccountInfo.region = propertiesMap['team.region'].toString();
      AwsAccountInfo.url = propertiesMap['team.url'].toString();
      AwsAccountInfo.accessKey = propertiesMap['team.accessKey'].toString();
      AwsAccountInfo.secretKey = propertiesMap['team.secretKey'].toString();
      AwsAccountInfo.sessionToken = propertiesMap['team.sessionToken'].toString();
      AwsAccountInfo.expiration = Number(propertiesMap['team.token.expiration']);
      AwsAccountInfo.urlExpiration = Number(propertiesMap['team.url.expiration']);
      setAwsAccount(AwsAccountInfo);

      if (AwsAccountInfo.url && codeWhispererUrlFromOutput) {
        try {
          const codeWhispererUrlFromOutputObj = new URL(codeWhispererUrlFromOutput);
          const codeWhispererURL = new URL(AwsAccountInfo.url);

          codeWhispererURL.searchParams.forEach((value, key) => {
            if (key.toLowerCase() === 'destination') {
              const url = new URL(value);
              url.pathname = codeWhispererUrlFromOutputObj.pathname;
              url.search = codeWhispererUrlFromOutputObj.search;

              codeWhispererURL.searchParams.set(key, url.toString());
            }
          });

          setCodeWhispererUrl(codeWhispererURL.toString());
        } catch (_) {
          // ignore invalid url
        }
      }
    } else {
      setAwsAccount(undefined);
    }
  };

  const trackSolveWithButtonClick = async (
    eventName: string,
    challengeId: string,
    buttonKey: SolveChallengeButtonKey
  ) => {
    try {
      await jamChallengeDetailsApi.trackSolveButtonUsed(eventName, challengeId, buttonKey);
    } catch (e: any) {
      // if API failed catch here.
      throw e;
    }
  };

  const loadTeamKeyPair = async (eventName: string, challengeId: string) => {
    setIsStartingChallange(true);
    await jamChallengeDetailsApi.getTeamKeyPair(eventName, challengeId);
    setIsStartingChallange(false);
  };

  const setEnableChallengeStatusModalFunc = () => {
    if (selectedJamChallengeId) {
      setEnableChallengeStatusModal(false);
      challengeModalMap[selectedJamChallengeId] = true;
      if (selectedJamEventId) localStorage.setItem(selectedJamEventId, JSON.stringify(challengeModalMap));
      setChallengeModalMap(challengeModalMap);
    }
  };

  const data: JamChallengeDetailsContextValue = {
    isFetchingJamChallengeDetails,
    loadJamChallengeTaskClue,
    loadJamChallengeDetails,
    selectedJamChallenge,
    startJamChallenge,
    reStartJamChallenge,
    loadJamChallengeProgressData,
    validateTaskAnswer,
    challengeProgress,
    codeWhispererUrl,
    task,
    awsAccount,
    awsAccountProperties,
    outputProperties,
    getJamChallengeTask,
    taskValidationResponse,
    loadTeamProperties,
    teamPropertiesResponse,
    trackSolveWithButtonClick,
    loadTeamKeyPair,
    jamChallengeId,
    isStartingChallange,
    showChallengeStatusProgress,
    showChallengeStatusSuccess,
    enableChallengeStatusModal,
    setEnableChallengeStatusModalFunc,
    isFetchingTaskClue,
    fetchingtaskValidationResponse,
    loadChallengeSupportDetails,
    challengeSupportDetails,
    loadJamChallengeQueueInfo,
    queueInfo,
    handleWSChallengeProgressUpdate: (messageBody: JamProgress) => {
      setChallengeProgress(messageBody);
    }
  };
  return <JamChallengeDetailsContext.Provider value={data}>{children}</JamChallengeDetailsContext.Provider>;
};

const useJamChallengeDetails = () => {
  const context = useContext(JamChallengeDetailsContext);
  if (context === undefined) {
    throw new Error('useJamChallengeDetails can only be used inside JamChallengeDetailsProvider');
  }
  return context;
};

export { JamChallengeDetailsProvider, useJamChallengeDetails };
