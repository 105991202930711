import React, { useEffect, useState } from 'react';
import { Box, Link } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { ChallengeDescriptor, ChallengeListItem, getChallengePointsPossible } from '@/src/types/Challenge';
import { useChallenges } from '@/src/store/challenge.context';
import { Nullable } from '@/src/types/common';
import { Event } from '@/src/types/Event';
import { IEventTemplate, IEventTemplateChallenge } from '@/src/types/EventTemplate';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { LoadingBar } from '../LoadingBar';
import AbstractChallengeCard from './AbstractChallengeCard';

interface CatalogEventChallengesProps {
  challengeDescriptors: IEventTemplateChallenge[];
  event: Event;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
  eventTemplate?: IEventTemplate;
  onChooseDifferentEvent: () => void;
  isEventEdit: boolean;
}

const CatalogEventChallenges: React.FC<CatalogEventChallengesProps> = ({
  challengeDescriptors,
  event,
  toggleChallengeInfo,
  eventTemplate,
  onChooseDifferentEvent,
  isEventEdit,
}) => {
  const { getChallengeListItemFromChallengeId, wrapperMapInitalized, getChallengeDescriptor } = useChallenges();
  const { t } = useTranslation();
  const [selectedChallenges, setSelectedChallenges] = useState<Nullable<ChallengeListItem>[] | undefined>(undefined);

  const doesEventHaveChallenge = (challengeId: string): boolean => {
    return (selectedChallenges || []).some((c) => c?.challengeId === challengeId);
  };

  const getCurrentChallengePointsPossible = (challenge: Nullable<ChallengeListItem>): number => {
    if (!challenge || !challenge.challengeId) {
      return 0;
    }

    const cd: Nullable<ChallengeDescriptor> = doesEventHaveChallenge(challenge.challengeId)
      ? getChallengeDescriptor(challenge, event.challengeBoards ? event.challengeBoards[0] : undefined)
      : ChallengeDescriptor.fromChallenge(challenge);
    if (cd) {
      return getChallengePointsPossible(event.getScoringSettings(), challenge, cd);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const challengeItemList: Nullable<ChallengeListItem>[] = [];
    challengeDescriptors.forEach((challengeDescriptor) => {
      if (challengeDescriptor.id !== null && challengeDescriptor.backup === false) { // don't include backup challenges
        const newChallengeListItem = getChallengeListItemFromChallengeId(challengeDescriptor.id);
        if (newChallengeListItem) {
          challengeItemList.push(newChallengeListItem);
        }
      }
    });
    setSelectedChallenges(challengeItemList);
  }, [wrapperMapInitalized]);

  return (
    <div>
      {eventTemplate && (
        <Box variant="h2" fontWeight="normal" margin={{ bottom: 'xl' }}>
          {t(i18nKeys.challenges.tabHeaders.learningEvent, { name: eventTemplate.name })}
          {!isEventEdit && (
            <Box>
              {t(i18nKeys.challenges.learningEventCaption)}{' '}
              <Link variant="primary" onFollow={onChooseDifferentEvent}>
                {t(i18nKeys.challenges.tabHeaders.chooseDifferentEvent)}
              </Link>
            </Box>
          )}
        </Box>
      )}
      {!wrapperMapInitalized && <LoadingBar />}
      {selectedChallenges?.map(
        (selectedChallenge, index) =>
          selectedChallenge && (
            <Box key={selectedChallenge.id} margin={{ bottom: 'l' }}>
              <AbstractChallengeCard
                key={selectedChallenge.id}
                index={index}
                challenge={selectedChallenge}
                getChallengePointsPossible={getCurrentChallengePointsPossible}
                toggleChallengeInfo={toggleChallengeInfo}
              />
            </Box>
          )
      )}
    </div>
  );
};

export default CatalogEventChallenges;
