import React from 'react';
import { Grid, Textarea } from '@amzn/awsui-components-react';

import BORKMarkdownRenderer from './BORKMarkdownRenderer';

interface BORKMarkdownEditorProps {
  onChange: (value: string) => void;
  onBlur?: () => void;
  value: string;
  placeholder?: string;
}

const BORKMarkdownEditor: React.FC<BORKMarkdownEditorProps> = ({ onChange, onBlur, value, placeholder }) => {
  return (
    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
      <Textarea
        rows={40}
        onChange={({ detail }) => onChange(detail.value)}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
      />
      <BORKMarkdownRenderer markdown={value} />
    </Grid>
  );
};

export default BORKMarkdownEditor;
