import * as React from 'react';
import { Box, Container, ContentLayout, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import ChallengeSshExample from '@/src/components/common/Challenges/ChallengeSshExample';
import { useChallenges } from '@/src/store/challenge.context';
import { useLayoutEffect } from 'react';

const SSHAccess: React.FC = () => {
    const { challengeConfig, getChallengeConfiguration } = useChallenges();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        getChallengeConfiguration();
    }, []);

    return (
        <ContentLayout header={
            <Header variant="h1">
                {t(i18nKeys.challenges.subSections.cfnTemplate.sshAccessTitle)}
            </Header>
        }>
            <Container>
                <Box>
                    <SpaceBetween size="s">
                        <div>
                            <h2>{t(i18nKeys.general.stepCounter, { count: 1 })}</h2>
                            <p>{t(i18nKeys.challenges.challengeDetails.messages.challengeSshSnippetStepOne)}</p>
                            {challengeConfig && <ChallengeSshExample challengeConfig={challengeConfig} />}
                        </div>
                        <div>
                            <h2>{t(i18nKeys.general.stepCounter, { count: 2 })}</h2>
                            <p>{t(i18nKeys.challenges.challengeDetails.messages.challengeSshSnippetStepTwo)}</p>
                        </div>
                    </SpaceBetween>
                </Box>
            </Container>
        </ContentLayout>
    );
};

export default SSHAccess;
