import React, { useLayoutEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Grid,
  Header,
  SpaceBetween,
  TextContent,
  Textarea,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './JamFacilitatorNotes.scss';
import { useApi } from '@/src/store/api.context';
import { safeString } from '@/src/utils/string.utils';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { unescape } from 'lodash';
import { JamChallengeSupportDetails, JamEventSupportDetails } from '@/src/types/JamChallengeSupportDetails';
import JamChallengeHeader from '../JamChallengeHeader/JamChallengeHeader';

const JamFacilitatorNotes = () => {
  const { t } = useTranslation();
  const { jamFacilitatorApi } = useApi();
  const { selectedJamChallenge } = useJamChallengeDetails();
  const { eventName } = useJamEventDetails();
  const { clearFlashbars } = useFlashbars();
  const [supportDetails, setSupportDetails] = useState<JamChallengeSupportDetails | undefined>();
  const [editMode, setEditMode] = useState(!!supportDetails?.facilitatorNotes);
  const [notes, setNotes] = useState(supportDetails?.facilitatorNotes || '');

  const getSupportDetails = async () => {
    const eventSupportDetails: JamEventSupportDetails = await jamFacilitatorApi.getEventSupportDetails(
      safeString(eventName)
    );
    if (eventSupportDetails) {
      const challengeSupportDetails = eventSupportDetails.challenges.find(
        (details) => details.id === selectedJamChallenge?.id
      );
      if (challengeSupportDetails) {
        setSupportDetails(challengeSupportDetails);
      }
    }
  };

  useLayoutEffect(() => {
    clearFlashbars();
    void getSupportDetails();
  }, []);

  const isValid = (): boolean => {
    return selectedJamChallenge?.id !== null && notes !== '' && (eventName !== '' || eventName !== undefined);
  };

  const onSaveNotes = () => {
    if (!isValid) {
      return false;
    }

    void jamFacilitatorApi
      .addFacilitatorNotes(safeString(eventName), safeString(selectedJamChallenge?.id), safeString(notes))
      .then(() => {
        setEditMode(false);
        void getSupportDetails();
      });
  };

  return (
    <ContentLayout header={<Header variant="h1">{selectedJamChallenge?.title ?? 'Untitled Jam Challenge'}</Header>}>
      <SpaceBetween direction="vertical" size="xxl">
        <JamChallengeHeader hideProperties />
        <Box margin={{ vertical: 'm' }}>
          <SpaceBetween direction="vertical" size="l">
            <Flashbar
              items={[
                {
                  type: 'info',
                  content: t(i18nKeys.myJams.challenges.details.facilitatorNotes.alert),
                  id: 'facilitator-notes-alert',
                },
              ]}
            />
            <Flashbar
              items={[
                {
                  type: 'info',
                  content: t(i18nKeys.myJams.challenges.details.facilitatorNotes.notesInstructions),
                  id: 'facilitator-notes-instructions',
                },
              ]}
            />
          </SpaceBetween>
          <Container
            className="comments-wrapper"
            header={<Header>{t(i18nKeys.myJams.challenges.details.facilitatorNotes.title)}</Header>}>
            {!editMode && (
              <Box margin={{ top: 'l' }}>
                <Grid gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}>
                  <TextContent>
                    {supportDetails?.facilitatorNotes
                      ? unescape(supportDetails?.facilitatorNotes || notes)
                      : t(i18nKeys.myJams.challenges.details.facilitatorNotes.emptyNote)}
                  </TextContent>
                  <Button variant="link" onClick={() => setEditMode(true)}>
                    {t(i18nKeys.myJams.challenges.details.facilitatorNotes.add)}
                  </Button>
                </Grid>
              </Box>
            )}
            {editMode && (
              <Box margin={{ top: 'l' }}>
                <SpaceBetween direction="vertical" alignItems="end" size="s">
                  <SpaceBetween size="l" direction="horizontal">
                    <Button variant="link" onClick={() => setEditMode(false)}>
                      {t(i18nKeys.myJams.challenges.details.facilitatorNotes.cancel)}
                    </Button>
                    <Button variant="primary" onClick={() => onSaveNotes()}>
                      {t(i18nKeys.myJams.challenges.details.facilitatorNotes.save)}
                    </Button>
                  </SpaceBetween>
                </SpaceBetween>
                <Box margin={{ top: 's' }}>
                  <Textarea value={notes} onChange={({ detail }) => setNotes(detail.value)} />
                </Box>
              </Box>
            )}
          </Container>
        </Box>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default JamFacilitatorNotes;
