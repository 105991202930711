import { Tabs } from '@amzn/awsui-components-react';
import React, { useMemo, useState } from 'react';
import ChallengeLabs from './ChallengeLabs';
import LabAccounts from './LabAccounts';
import Metrics from './Metrics';
import { EventLabSummary } from '@/src/types/EventLabSummary';
import { keyBy } from 'lodash';
import { LabDashboardChartData, LabStatusSnapshot } from '@/src/types/LabModels';
import { ChartProperties } from '@/src/utils/chart.utils';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface LabTabsProps {
  summary: EventLabSummary;
  autoScalingChartProperties: ChartProperties[];
  labChartProperties: ChartProperties[];
  eventName: string;
  showAuditTrail: (showFor: string) => void;
  labDashboardChartData?: LabDashboardChartData;
}
const LabTabs: React.FC<LabTabsProps> = ({
  summary,
  autoScalingChartProperties,
  labChartProperties,
  eventName,
  showAuditTrail,
  labDashboardChartData,
}) => {
  const { t } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState('CHALLENGE_LABS');
  const challengeIds = useMemo(() => {
    const snapshots: any = {};

    return Object.keys(summary.desiredLabCounts).sort((challengeIdA, challengeIdB) => {
      // cached the lab data so we dont need to calculate it on every sort iteration
      if (!snapshots[challengeIdA]) {
        snapshots[challengeIdA] = summary.getLabSnapshot(challengeIdA);
      }
      if (!snapshots[challengeIdB]) {
        snapshots[challengeIdB] = summary.getLabSnapshot(challengeIdB);
      }

      const dataA = snapshots[challengeIdA] as LabStatusSnapshot;
      const dataB = snapshots[challengeIdB] as LabStatusSnapshot;

      if (!dataA || !dataB) {
        return 0;
      }

      if (dataA.readyPercent < dataB.readyPercent) {
        return -1;
      }
      if (dataA.readyPercent > dataB.readyPercent) {
        return 1;
      }
      return 0;
    });
  }, [summary]);

  const challengesById = useMemo(() => keyBy(summary.challenges, 'challengeId'), [summary.challenges]);
  const challenges = useMemo(() => {
    return challengeIds.map((id) => challengesById[id]).filter((challenge) => challenge.awsAccountBased);
  }, [summary.challenges, challengeIds]);

  return (
    <Tabs
      activeTabId={currentTabId}
      onChange={({ detail }) => setCurrentTabId(detail.activeTabId)}
      tabs={[
        {
          label: t(i18nKeys.eventLabs.labTabs.headers.challengeLabs),
          id: 'CHALLENGE_LABS',
          content: (
            <ChallengeLabs
              challenges={challenges}
              summary={summary}
              eventName={eventName}
              changeTab={(id: string) => setCurrentTabId(id)}
              showAuditTrail={showAuditTrail}
              labDashboardChartData={labDashboardChartData}
            />
          ),
        },
        {
          label: t(i18nKeys.eventLabs.labTabs.headers.labAccounts),
          id: 'LAB_ACCOUNTS',
          content: (
            <LabAccounts
              allLabs={summary.nonMasterLabs}
              challengesById={challengesById}
              getChallenge={(id: string) => challengesById[id]}
            />
          ),
        },
        {
          label: t(i18nKeys.eventLabs.labTabs.headers.metrics),
          id: 'METRICS',
          content: (
            <Metrics
              autoScalingChartProperties={autoScalingChartProperties}
              labChartProperties={labChartProperties}
              summary={summary}
            />
          ),
        },
      ]}
    />
  );
};

export default LabTabs;
