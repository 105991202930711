import {
  Box,
  Button,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { decodeForMarkdown } from '../../../../utils/string.utils';
import { Markdown } from '../../../common/Markdown';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { useUser } from '../../../../store/user.context';
import './AdvancedSettings.scss';

interface EventCapacityRedirectSettingsProps {
  event?: Event;
}

type MESSAGE_VIEW_TYPE = 'markdown' | 'markdown_preview' | 'preview';

const markdownSvg = (
  <svg strokeWidth={0} xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10">
    <path d="M2.41421 5.00008L5.20711 2.20718L3.79289 0.792969L0.292893 4.29297C-0.0976311 4.68349 -0.0976311 5.31666 0.292893 5.70718L3.79289 9.20718L5.20711 7.79297L2.41421 5.00008ZM5.54611 8.31787L8.60611 0.917871L10.4543 1.68213L7.39432 9.08213L5.54611 8.31787ZM15.7071 4.29297L12.2071 0.792969L10.7929 2.20718L13.5858 5.00008L10.7929 7.79297L12.2071 9.20718L15.7071 5.70718C16.0976 5.31666 16.0976 4.68349 15.7071 4.29297Z" />
  </svg>
);
const preivewSvg = (
  <svg strokeWidth={0} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_717_3135)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15188 5C2.64379 7.43527 5.17159 9 8 9C10.8284 9 13.3562 7.43527 14.8481 5C13.3562 2.56473 10.8284 1 8 1C5.17159 1 2.64378 2.56473 1.15188 5ZM16 5C14.4031 2.01099 11.4185 0 8 0C4.58154 0 1.59687 2.011 0 5C1.59687 7.98901 4.58154 10 8 10C11.4185 10 14.4031 7.98901 16 5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9C10.2091 9 12 7.20914 12 5C12 2.79086 10.2091 1 8 1C5.79086 1 4 2.79086 4 5C4 7.20914 5.79086 9 8 9ZM8 10C10.7614 10 13 7.76142 13 5C13 2.23858 10.7614 0 8 0C5.23858 0 3 2.23858 3 5C3 7.76142 5.23857 10 8 10Z"
      />
      <path d="M10 5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5Z" />
    </g>
    <defs>
      <clipPath id="clip0_717_3135">
        <rect width="16" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const EventCapacityRedirectSettings: React.FC<EventCapacityRedirectSettingsProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const [viewType, setViewType] = useState<MESSAGE_VIEW_TYPE>('markdown_preview');

  return (
    <div className="event-capacity-redirect-settings">
      <ExpandableSection
        variant="container"
        header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventCapacityRedirectSettings)}</Header>}>
        {!editMode && (
          <>
            <Header variant="h2" description={t(i18nKeys.events.eventDetails.headers.eventCapacityMessage.description)}>
              {t(i18nKeys.events.eventDetails.headers.eventCapacityMessage.header)}
            </Header>
            <Grid gridDefinition={[{ colspan: 8 }]}>
              <Markdown content={event?.eventFullMessage} />
            </Grid>
          </>
        )}
        {editMode && user && (
          <div>
            <Box>{t(i18nKeys.events.eventDetails.headers.eventCapacityMessage.description)}</Box>
            <div className="toolbar">
              <Box padding="s">
                <SpaceBetween direction="horizontal" size="m">
                  <Button
                    data-selected={viewType === 'markdown' ? 'true' : 'false'}
                    onClick={() => setViewType('markdown')}>
                    <SpaceBetween direction="horizontal" size="xs">
                      <div>{markdownSvg}</div>
                      {t(i18nKeys.events.eventDetails.buttons.markdown)}
                    </SpaceBetween>
                  </Button>
                  <Button
                    data-selected={viewType === 'markdown_preview' ? 'true' : 'false'}
                    onClick={() => setViewType('markdown_preview')}>
                    <SpaceBetween direction="horizontal" size="xs">
                      <SpaceBetween direction="horizontal" size="xxs">
                        {markdownSvg}
                        {preivewSvg}
                      </SpaceBetween>
                      {t(i18nKeys.events.eventDetails.buttons.markdownPreview)}
                    </SpaceBetween>
                  </Button>
                  <Button
                    data-selected={viewType === 'preview' ? 'true' : 'false'}
                    onClick={() => setViewType('preview')}>
                    <SpaceBetween direction="horizontal" size="xs">
                      <div>{preivewSvg}</div>
                      {t(i18nKeys.events.eventDetails.buttons.preview)}
                    </SpaceBetween>
                  </Button>
                </SpaceBetween>
              </Box>
            </div>
            <Grid
              gridDefinition={Array(viewType === 'markdown_preview' ? 2 : 1).fill({
                colspan: viewType === 'markdown_preview' ? 6 : 12,
              })}>
              {['markdown', 'markdown_preview'].includes(viewType) && (
                <FormField label="">
                  <div style={{ marginTop: '16px' }}>
                    <Textarea
                      value={editedEvent?.eventFullMessage || ''}
                      disabled={!editedEvent?.canEditAttribute('eventFullMessage', user)}
                      onChange={({ detail }) =>
                        handleUpdateEditEvent(EditEventActions.EVENT_FULL_MESSAGE, decodeForMarkdown(detail.value))
                      }
                    />
                    <span style={{ color: awsui.colorTextFormSecondary }}>
                      {t(i18nKeys.events.eventDetails.headers.eventCapacityMessage.footer)}
                    </span>
                  </div>
                </FormField>
              )}
              {['preview', 'markdown_preview'].includes(viewType) && (
                <Markdown content={editedEvent?.eventFullMessage} />
              )}
            </Grid>
          </div>
        )}
      </ExpandableSection>
    </div>
  );
};
export default EventCapacityRedirectSettings;
