import React, { useState, useEffect } from 'react';
import JamContainer from '@/src/components/ui/molecules/JamContainer/JamContainer';
import { ChallengeExtras } from '@/src/types/JamReport';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';
import { Markdown } from '@/src/components/common/Markdown';
import { useJamReport } from '@/src/store/jam-report.context';

const NextStep = () => {
  const { reportApiData } = useJamReport();
  const [nextStepData, setNextStepData] = useState<ChallengeExtras[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    if (reportApiData) {
      const { challengeExtras } = reportApiData;
      if (challengeExtras) setNextStepData(challengeExtras || []);
    }
  }, [reportApiData]);
  return (
    <>
      <SpaceBetween size="s">
        {nextStepData?.length > 0 &&
          nextStepData.map((item, index) => (
            <JamContainer key={index}>
              <ExpandableSection variant="default" headerText={item?.challengeTitle || ''}>
                {item?.learningOutcome?.summary && (
                  <>
                    <h3>
                      <b> {t(i18nKeys.eventReport.nextSteps.heading)}</b>
                    </h3>
                    <p>{item?.learningOutcome?.summary || ''}</p>
                  </>
                )}
                {item?.servicesUsed.length > 0 && (
                  <>
                    <h3>
                      <b>{t(i18nKeys.eventReport.nextSteps.serviceUsed)}</b>
                    </h3>
                    <p>{item?.servicesUsed?.join(', ') || ''}</p>
                  </>
                )}
                {item?.learningOutcome?.summary && (
                  <>
                    <h3>
                      <b>{t(i18nKeys.eventReport.nextSteps.learningOutcome)}</b>
                    </h3>
                    <p>{item?.learningOutcome?.summary || ''}</p>
                  </>
                )}
                <Markdown content={item?.learningOutcome?.content || ''} />
              </ExpandableSection>
            </JamContainer>
          ))}
      </SpaceBetween>
    </>
  );
};

export default NextStep;
