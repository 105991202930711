import React from 'react';
import { TFunctionProvider } from '@/src/components/common/TFunctionProvider';
import { EVENT_TEMPLATE_TOPICS } from '@/src/constants/event-template.constants';
import { EventDurationType, EventLearningType } from '@/src/types/EventTemplate';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { AwsServices } from '../../../../constants/shared/aws-services';

export const LearningType = [
  {
    label: <TFunctionProvider>{(t) => t(i18nKeys.eventTemplates.learningType.team)}</TFunctionProvider>,
    value: EventLearningType.TEAM,
  },
  {
    label: <TFunctionProvider>{(t) => t(i18nKeys.eventTemplates.learningType.individual)}</TFunctionProvider>,
    value: EventLearningType.INDIVIDUAL,
  },
];

export const Topic = EVENT_TEMPLATE_TOPICS;

export const AWSServices = AwsServices.list.map((awsService) => ({ label: awsService, value: awsService }));

export const Tag = [
  {
    label: <TFunctionProvider>{(t) => t(i18nKeys.catalog.catalogFilter.tags.trendingEvents)}</TFunctionProvider>,
    value: 'trending-events',
  },
  {
    label: <TFunctionProvider>{(t) => t(i18nKeys.catalog.catalogFilter.tags.goodForBeginners)}</TFunctionProvider>,
    value: 'Good For Beginners',
  },
];

export const learningTypeKey = i18nKeys.catalog.catalogFilter.learningType;
export const topicKey = i18nKeys.catalog.catalogFilter.topic;
export const eventDurationKey = i18nKeys.catalog.catalogFilter.eventDuration;
export const awsServicesKey = i18nKeys.catalog.catalogFilter.awsServices;
export const tagKey = i18nKeys.catalog.catalogFilter.tag;

type EventDurationFilterOptions = {
  label: JSX.Element;
  value: EventDurationType;
}[]

export const allFilters = {
  [learningTypeKey]: LearningType,
  [topicKey]: Topic,
  // [eventDurationKey]: EventDuration,
  [awsServicesKey]: AWSServices,
  [tagKey]: Tag,
};

export const filtersPropertyKey = {
  [learningTypeKey]: 'learningTypes',
  [topicKey]: 'topics',
  [eventDurationKey]: 'durations',
  [awsServicesKey]: 'awsServices',
  [tagKey]: 'tags',
};
export type Filter = keyof typeof allFilters | keyof EventDurationFilterOptions;

export type FilterOption =
  | (typeof LearningType)[number]
  | (typeof Topic)[number]
  | (EventDurationFilterOptions)[number]
  | (typeof AWSServices)[number]
  | (typeof Tag)[number];

export type FilterValue = FilterOption['value'];

export type CheckedState = {
  [key in Filter]: FilterValue[];
};

export const MAX_VISIBLE_FILTER_OPTIONS = 5;
