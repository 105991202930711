import { getTableFilterMatcher, TableFilterMatcher } from '../../utils/table.utils';
import { UsagePlan } from '../../types/usage-plan/UsagePlan';
import { TFunctionProvider } from '../common/TFunctionProvider';
import { i18nKeys } from '../../utils/i18n.utils';
import { Link } from 'react-router-dom';
import * as React from 'react';

export const filteringFunction = (item: UsagePlan, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return matcher.filterEmpty || matcher.isMatch(item.id) || matcher.isMatch(item.name) || matcher.isMatch(item.notes);
};

export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    sortingField: 'name',
    header: <TFunctionProvider>{(t) => t(i18nKeys.usagePlan.fields.name.title)}</TFunctionProvider>,
    cell: (item: UsagePlan) => (
      <div>
        <Link to={`/usage-plans/${item.id}`}>{item.name}</Link>
      </div>
    ),
    minWidth: 200,
    allowLineWrap: true,
  },
  {
    id: 'description',
    sortingField: 'description',
    header: <TFunctionProvider>{(t) => t(i18nKeys.usagePlan.fields.description.title)}</TFunctionProvider>,
    cell: (item: UsagePlan) => <span>{item.description}</span>,
    minWidth: 400,
    allowLineWrap: false,
  },
];
