import React from 'react';
import { Popover } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

interface TPopoverProps {
  headerKey: string;
  children: React.ReactNode;
  content: React.ReactNode;
}

const TPopover: React.FC<TPopoverProps> = ({ headerKey, children, content }) => {
  const { t } = useTranslation();
  const header = t(headerKey);

  return (
    <Popover header={header} content={content}>
      {children}
    </Popover>
  );
};

export default TPopover;
