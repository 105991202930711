import React from 'react';
import { Box, ColumnLayout, Container, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { FeedbackComment } from '@/src/__mocks__/mock-jam-feedback';
import Reviews from '@/src/components/ui/molecules/Reviews/Reviews';
import FeedbackDifficulty from '@/src/components/ui/molecules/MyJams/JamRecentFeedback/FeedbackDifficulty/FeedbackDifficulty';
import moment from 'moment';
import { MM_DD_YYYY } from '@/src/constants/common';

interface JamCommentProps {
  review: FeedbackComment;
}

const JamComment: React.FC<JamCommentProps> = ({ review }) => {
  const { t } = useTranslation();
  return (
    <Box margin={{ vertical: 'm' }}>
      <Container className="dark-bg">
        <SpaceBetween direction="vertical" size="s">
          <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
            <ColumnLayout columns={2} variant="text-grid">
              <SpaceBetween size="xxs" direction="horizontal">
                <Box variant="span">
                  {t(i18nKeys.myJams.challenges.details.recentFeedback.recentComment.overallRating)}:
                </Box>
                <Reviews rating={review?.challengeRank} />
              </SpaceBetween>
              <SpaceBetween size="xxs" direction="horizontal">
                <Box variant="span">
                  {t(i18nKeys.myJams.challenges.details.recentFeedback.recentComment.difficultyRating)}:
                </Box>
                <FeedbackDifficulty difficulty={review?.challengeDifficulty} />
              </SpaceBetween>
            </ColumnLayout>
            <ColumnLayout columns={2} variant="text-grid">
              <SpaceBetween size="xxs" direction="horizontal">
                <Box variant="span">{t(i18nKeys.myJams.challenges.details.recentFeedback.recentComment.learned)}:</Box>
                <Box className="light-violet">
                  {review?.didYouLearnSomethingNew ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}
                </Box>
              </SpaceBetween>
              <SpaceBetween size="xxs" direction="horizontal">
                <Box variant="span">{t(i18nKeys.myJams.challenges.details.recentFeedback.recentComment.reviewOn)}:</Box>
                <Box className="light-violet">{moment(review?.createdDate).format(MM_DD_YYYY)}</Box>
              </SpaceBetween>
            </ColumnLayout>
          </Grid>
          <Box variant="div">{review?.notes}</Box>
        </SpaceBetween>
      </Container>
    </Box>
  );
};

export default JamComment;
