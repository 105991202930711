import { useApi } from "@/src/store/api.context";
import { SolvedChallengesReportEntry } from "@/src/types/SolvedChallengesReportEntry";
import { i18nKeys } from "@/src/utils/i18n.utils";
import { timeAgo } from "@/src/utils/time.utils";
import { Spinner, Table } from "@amzn/awsui-components-react";
import moment from "moment";
import { toOrdinal } from "number-to-words";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; 

interface RowItem extends SolvedChallengesReportEntry {
  rank: string;
}

const SolvedChallengeMetrics = ({eventId, challengeId} : {eventId: string; challengeId: string; }) => {
    const [isLoading, isLoadingSet] = useState(true);
    const { t }= useTranslation();
    const [data, dataSet] = useState<SolvedChallengesReportEntry[]>([]);
    const { jamDashboardApi } =  useApi();
    useEffect(() => {
      dataSet([]);
      if (!!eventId && !!challengeId) {
        isLoadingSet(true);
        void jamDashboardApi.fetchSolvedChallenges(eventId, challengeId)
          .then((response) => void dataSet(response))
          .finally(() => isLoadingSet(false));
      }
    }, [challengeId, eventId]);
    if (isLoading) return <Spinner />;
  
    const colDefs = [{
      header: t(i18nKeys.jamDashboard.leaderboard.table.headers.rank),
      cell: (item: RowItem) => item?.rank || '-',
    },{
      header: t(i18nKeys.report.headers.teamTable.teamName),
      cell: (item: RowItem) => item?.teamDisplayName || '-'
    }, {
      header: t(i18nKeys.report.headers.teamTable.completionTime),
      cell: (item: RowItem) => {
        if (!item?.solveChallengeTime) return '-';
        const formattedDate = moment(item.solveChallengeTime).format('MMM D, YYYY, h:mm:ss A');
        return `${formattedDate} (${timeAgo(item.solveChallengeTime)})`;
      }
    }, {
      header: t(i18nKeys.jamDashboard.leaderboard.table.headers.cluesUsed),
      cell: (item: RowItem) => item?.numCluesUsed || 0
    }];
    return <Table columnDefinitions={colDefs}
        items={data.map((row, i) => ({...row, rank: toOrdinal(i + 1)}))}
        loadingText="loading"
        sortingDisabled
        variant="embedded"
        empty={t(i18nKeys.challenges.challengeDetails.chart.feedback.emptyState)}
      />;
  };

  export default SolvedChallengeMetrics;