import { Container, ExpandableSection, FormField, Grid, Header, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { Campaign, CampaignTypes } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';

interface CampaignTypeProps {
  campaign: Campaign | undefined;
}

const CampaignType: React.FC<CampaignTypeProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign, newCampaignMode } = useEditCampaign();
  const CAMPAIGN_TYPE_LABEL: { [key: string]: string } = {
    [CampaignTypes.TRAINING]: t(i18nKeys.campaigns.labels.campaignDetails.campaignTypeTraining),
    [CampaignTypes.EVALUATION]: t(i18nKeys.campaigns.labels.campaignDetails.campaignTypeEvaluation),
  };
  const campaignTypeOptions: OptionDefinition[] = [
    { label: CAMPAIGN_TYPE_LABEL[CampaignTypes.TRAINING], value: CampaignTypes.TRAINING },
    { label: CAMPAIGN_TYPE_LABEL[CampaignTypes.EVALUATION], value: CampaignTypes.EVALUATION },
  ];
  const [selectedOption, setSelectedOption] = useState<OptionDefinition>(campaignTypeOptions[1]);

  useEffect(() => {
    if (campaignEditMode) {
      const newSelectedOption = campaignTypeOptions.find((option) => option.value === editedCampaign?.type);
      if (newSelectedOption) {
        setSelectedOption(newSelectedOption);
      }
    }
  }, [campaignEditMode]);

  const onOptionSelection = (newSelectedOption: OptionDefinition) => {
    handleUpdateEditCampaign(EditCampaignActions.CAMPAIGN_TYPE, newSelectedOption.value);
    setSelectedOption(newSelectedOption);
  };

  const renderContent = () => {
    return !campaignEditMode && !newCampaignMode ? (
      <div className="section-first-row">
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
          <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.campaignType)}</div>
          <div>
            {campaign?.type ? CAMPAIGN_TYPE_LABEL[campaign?.type] : CAMPAIGN_TYPE_LABEL[CampaignTypes.TRAINING]}
          </div>
        </Grid>
      </div>
    ) : (
      (campaignEditMode || newCampaignMode) && (
        <FormField label={t(i18nKeys.campaigns.labels.campaignDetails.campaignType)}>
          <Select
            selectedOption={selectedOption}
            options={campaignTypeOptions}
            onChange={({ detail }) => onOptionSelection(detail.selectedOption)}
          />
        </FormField>
      )
    );
  };

  const headerElement = <Header variant="h2">{t(i18nKeys.campaigns.headers.campaignDetails.campaignType)}</Header>;

  return (
    <React.Fragment>
      {!newCampaignMode && (
        <ExpandableSection variant="container" header={headerElement}>
          {renderContent()}
        </ExpandableSection>
      )}
      {newCampaignMode && <Container header={headerElement}>{renderContent()}</Container>}
    </React.Fragment>
  );
};

export default CampaignType;
