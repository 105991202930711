import {
  Badge,
  Box,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Icon,
  Link,
  RadioGroup,
  Select,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import { useParams } from 'react-router-dom';

import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../store/api.context';
import { Challenge, ChallengeIssue } from '../../../../types/Challenge';
import { Columns } from '@/src/components/common/Columns';
import { Column } from '@/src/components/common/Column';
import { KeyValue } from '@/src/components/common/KeyValue';
import { Markdown } from '@/src/components/common/Markdown';
import moment from 'moment';
import JamSpinner from '@/src/components/common/JamSpinner';
import { ChallengeIssueSeverity, ChallengeIssueSeverityDescriptions, labelData, inputWidth } from './NewIssue';
import { ChallengePropAction, useCreateChallenge } from '@/src/store/create-challenge.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import MarkDownPreviewEditor from '@/src/components/common/MarkdownPreviewEditor/MarkDownPreviewEditor';
import { safeString } from '@/src/utils/string.utils';
import { TinyEvent } from '@/src/types/Event';

export interface IUpdateIssue {
  status?: string;
  eventName: string | null;
  eventId: string | null;
  assignee: string;
  description: string;
  severity: string;
  isPrivate: boolean;
}
interface ChallengeIssuesDetailProps {
  challenge: Challenge;
}
const ChallengeIssuesDetail: React.FC<ChallengeIssuesDetailProps> = ({ challenge }) => {
  const { editMode, handleUpdateChallengeProp } = useCreateChallenge();

  const { t } = useTranslation();
  const { challengesApi } = useApi();
  const { id }: { id: string } = useParams();
  const [challengeIssues, setChallengeIssues] = useState<ChallengeIssue | undefined>();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const initialData = {
    status: '',
    eventName: '',
    eventId: id,
    assignee: '',
    description: '',
    severity: ChallengeIssueSeverity.LOW,
    isPrivate: false,
  };
  const [formData, setFormData] = useState<IUpdateIssue>(initialData);
  interface Iselect {
    label: string;
    value: string;
  }
  const [associateEvent, setAssociateEvent] = useState<Iselect[] | []>([]);

  const getSingleIssue = async () => {
    setLoading(true);
    const res = await challengesApi.getChallengeIssueById(id);
    const tempFormData = {
      status: res?.status || '',
      eventName: '',
      eventId: res?.id,
      assignee: res?.assignee || '',
      description: res?.description || '',
      severity: res?.severity || ChallengeIssueSeverity.LOW,
      isPrivate: res?.isPrivate || false,
    };
    setChallengeIssues(res);
    const eventRes = await challengesApi.getRecentEventsChallengeUsedIn(
      safeString(challenge.challengeId),
      false,
      false
    );
    if (eventRes && eventRes.length > 0) {
      const newData = eventRes.map((event: TinyEvent) => {
        if (event.name === formData.eventId) tempFormData.eventName = event.name;
        return { label: event.title, value: event.name };
      });
      setAssociateEvent(newData ? (newData as unknown as Iselect[]) : []);
    }
    setFormData(tempFormData);
    setLoading(false);
  };

  useEffect(() => {
    void getSingleIssue();
  }, []);

  const onFormSubmit = async () => {
    await challengesApi.updateChallengeIssueCommentById(id, comment);
    await getSingleIssue();
    setComment(''); // empty the comment box
  };

  const updateEditorData = (text: string) => {
    setFormData({ ...formData, description: text });
  };

  useEffect(() => {
    if (editMode)
      handleUpdateChallengeProp(ChallengePropAction.ISSUE, {
        id,
        severity: formData.severity,
        challengeVersion: challengeIssues?.challengeVersion,
        challengeMajorVersion: challengeIssues?.challengeMajorVersion,
        challengeMinorVersion: challengeIssues?.challengeMinorVersion,
        challengeVersionStatus: challengeIssues?.challengeVersionStatus,
        eventName: formData.eventId !== id ? formData.eventId : undefined,
        isPrivate: formData.isPrivate,
      });
  }, [formData]);

  if (loading) {
    return <JamSpinner />;
  }

  if (editMode) {
    return (
      <SpaceBetween size="xl">
        <Container header={<Header variant="h3">Details</Header>}>
          <ColumnLayout borders="none" columns={2}>
            <Column size="s">
              <div style={{ width: inputWidth }}>
                <SpaceBetween size="m">
                  <FormField label="Status">
                    <Select
                      selectedOption={{
                        value: formData?.status,
                        label: formData?.status,
                      }}
                      onChange={({ detail }) => setFormData({ ...formData, status: detail.selectedOption.value || '' })}
                      options={[
                        {
                          value: 'OPEN',
                          label: 'OPEN',
                        },
                        {
                          value: 'RESOLVED',
                          label: 'RESOLVED',
                        },
                      ]}
                    />
                  </FormField>
                  <FormField label="Sevirity">
                    <Select
                      selectedOption={{ label: labelData(formData.severity), value: formData.severity }}
                      onChange={({ detail }) =>
                        setFormData({ ...formData, severity: detail.selectedOption.value || '' })
                      }
                      options={[
                        {
                          value: ChallengeIssueSeverity.CRITICAL,
                          label: ChallengeIssueSeverityDescriptions.CRITICAL,
                        },
                        { value: ChallengeIssueSeverity.HIGH, label: ChallengeIssueSeverityDescriptions.HIGH },
                        { value: ChallengeIssueSeverity.MEDIUM, label: ChallengeIssueSeverityDescriptions.MEDIUM },
                        { value: ChallengeIssueSeverity.LOW, label: ChallengeIssueSeverityDescriptions.LOW },
                      ]}
                    />
                  </FormField>
                  <FormField label="Associated event">
                    <Select
                      selectedOption={{
                        label: formData?.eventName || '',
                        value: (formData?.eventName && formData?.eventId) || '',
                      }}
                      onChange={({ detail }) =>
                        setFormData({ ...formData, eventName: detail.selectedOption.value || '' })
                      }
                      options={associateEvent || []}
                      filteringType="auto"
                    />
                  </FormField>
                  <FormField label="Assigned to">
                    <Select
                      selectedOption={{
                        value: formData.assignee,
                        label: formData.assignee,
                      }}
                      onChange={({ detail }) =>
                        setFormData({ ...formData, assignee: detail.selectedOption.value || '' })
                      }
                      options={[
                        {
                          value: 'Unassigned',
                          label: 'Unassigned',
                        },
                        {
                          value: challengeIssues?.assignee || '',
                          label: challengeIssues?.assignee || '',
                        },
                        {
                          value: challengeIssues?.openedBy || '',
                          label: challengeIssues?.openedBy || '',
                        },
                      ]}
                    />
                  </FormField>
                  <FormField label="Is this a private issue?">
                    <RadioGroup
                      onChange={({ detail }) =>
                        setFormData({ ...formData, isPrivate: detail.value === 'true' ? true : false })
                      }
                      value={formData.isPrivate === true ? 'true' : 'false'}
                      items={[
                        { value: 'true', label: `${t(i18nKeys.challenges.challengeDetails.labels.private.yes)}` },
                        { value: 'false', label: `${t(i18nKeys.challenges.challengeDetails.labels.private.no)}` },
                      ]}
                    />
                  </FormField>
                </SpaceBetween>
              </div>
            </Column>
            <Column size="s">
              <KeyValue className="secondary-text" label="Challenge version">
                {`${challengeIssues?.challengeMajorVersion}.${
                  (challengeIssues?.challengeMajorVersion && challengeIssues?.challengeMinorVersion) || 0
                }`}{' '}
                - Approved
              </KeyValue>
            </Column>
          </ColumnLayout>
        </Container>
        <Container>
          <MarkDownPreviewEditor
            editorContentHeight={150}
            text={formData.description}
            onTextChanged={(e) => updateEditorData(e)}
          />
        </Container>
      </SpaceBetween>
    );
  }

  return (
    <SpaceBetween size="xl">
      <Container header={<Header variant="h3">Details</Header>}>
        <Columns columns={4}>
          <Column size="s">
            <KeyValue className="secondary-text" label="Status">
              {challengeIssues?.status}
            </KeyValue>
            <KeyValue className="secondary-text" label="Assigned to">
              {challengeIssues?.assignee}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="secondary-text" label="Severity">
              <Badge color="red">{challengeIssues?.severity}</Badge>
            </KeyValue>
            <KeyValue className="secondary-text" label="Private issue">
              {challengeIssues?.isPrivate}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="secondary-text" label="Associated event">
              <Link href="#">
                <SpaceBetween size="xs" direction="horizontal">
                  <Icon name="external" variant="link" />
                  <span>[{challengeIssues?.title}]</span>
                </SpaceBetween>
              </Link>
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="secondary-text" label="Challenge version">
              {`${challengeIssues?.challengeMajorVersion}.${
                (challengeIssues?.challengeMajorVersion && challengeIssues?.challengeMinorVersion) || 0
              }`}{' '}
              - Approved
            </KeyValue>
          </Column>
        </Columns>
      </Container>
      <Container header={<Header variant="h3">Description</Header>}>
        <Markdown content={challengeIssues?.description || ''} />
      </Container>
      <Container header={<Header variant="h3">Activity Feed</Header>}>
        <ColumnLayout borders="horizontal" columns={1}>
          <Column size="s">
            <KeyValue
              className="secondary-text"
              label={moment(challengeIssues?.createdDate).format('MMM Do YYYY, h:mm:ss A')}>
              Issue opened by: {challengeIssues?.openedBy}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue
              className="secondary-text"
              label={moment(challengeIssues?.lastUpdatedDate).format('MMM Do YYYY, h:mm:ss A')}>
              Assignee update to: {challengeIssues?.assignee}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue
              className="secondary-text"
              label={moment(challengeIssues?.lastUpdatedDate).format('MMM Do YYYY, h:mm:ss A')}>
              Status updated to:{' '}
              <Badge color={challengeIssues?.status.toLocaleLowerCase() === 'open' ? 'green' : 'blue'}>
                {challengeIssues?.status}
              </Badge>
            </KeyValue>
          </Column>
          {challengeIssues?.comments?.map((item, index) => (
            <Column size="s" key={index}>
              <KeyValue
                className="secondary-text"
                label={`${item?.author}: ${moment(item?.createdAt).format('MMM Do YYYY, h:mm:ss A')}`}>
                {item?.value}
              </KeyValue>
            </Column>
          ))}
          <Column size="s">
            <FormField label="Comment">
              <SpaceBetween size="s">
                <Textarea
                  onChange={({ detail }) => setComment(detail.value)}
                  value={comment}
                  placeholder="Enter comment"
                />
                <Box float="right">
                  <Button id='challenge-issue-view-single-issue' data-testid='challenge-issue-view-single-issue' variant="primary" onClick={() => void onFormSubmit()} disabled={comment?.length === 0}>
                    Submit Comment
                  </Button>
                </Box>
              </SpaceBetween>
            </FormField>
          </Column>
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  );
};

export default ChallengeIssuesDetail;
