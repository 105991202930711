import { Box, Checkbox, ExpandableSection, FormField, Grid, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
interface SponsorshipProps {
  event?: Event;
}

const Sponsorship: React.FC<SponsorshipProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.sponsorship)}</Header>}>
      {editMode && user && (
        <Grid gridDefinition={[{ colspan: 10 }]}>
          <FormField stretch>
            <Checkbox
              checked={editedEvent?.sponsorshipSettings?.sponsored || false}
              disabled={!event?.canEditAttribute('sponsorshipSettings', user)}
              onChange={({ detail }) => {
                const sponsorshipSettings = detail.checked
                  ? editedEvent?.sponsorshipSettings.defaultForSponsoredEvents()
                  : editedEvent?.sponsorshipSettings.defaultForNonSponsoredEvents();
                handleUpdateEditEvent(EditEventActions.SPONSORED, sponsorshipSettings);
              }}>
              <div style={{ marginTop: '-4px' }}>
                <Box variant="h4">{t(i18nKeys.events.eventDetails.labels.sponsored.default)}</Box>
                <Box fontSize="body-s" color="text-body-secondary">
                  {t(i18nKeys.events.eventDetails.labels.sponsored.label)}
                </Box>
              </div>
            </Checkbox>
          </FormField>
        </Grid>
      )}
      {!editMode && (
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.sponsored.default)}</div>
            <div>{event?.sponsorshipSettings?.sponsored ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
          </Grid>
        </div>
      )}
    </ExpandableSection>
  );
};
export default Sponsorship;
