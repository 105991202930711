import * as React from 'react';
import { Box, Grid, ProgressBar, SpaceBetween } from '@amzn/awsui-components-react';
import StarRating from '@/src/components/common/StarRating';
import CircleRating from '@/src/components/common/CircleRating';
import { HorizontalRule } from '@/src/components/common/HorizontalRule';
import { roundFloat } from '@/src/utils/number.utils';
import { ChallengeFeedbackSummary } from '@/src/types/Challenge';
import './FeedbackProgress.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface FeedbackProgressProps {
  label: string;
  hasDifficulty?: boolean;
  summary: ChallengeFeedbackSummary | undefined;
}

const FeedbackProgress: React.FC<FeedbackProgressProps> = ({ label, hasDifficulty = false, summary }) => {
  const { t } = useTranslation();
  const ratings = summary ? [summary.rating5, summary.rating4, summary.rating3, summary.rating2, summary.rating1] : [];
  const difficulties = summary
    ? [summary.difficulty5, summary.difficulty4, summary.difficulty3, summary.difficulty2, summary.difficulty1]
    : [];

  const percentOfTotal = (num: number) => {
    if (num < 1) return 0;
    return Math.ceil((num / (summary?.total ?? 0)) * 100);
  };

  const averageChallengeRating = React.useMemo(() => {
    const totalScore = summary
      ? summary?.rating5 * 5 + summary?.rating4 * 4 + summary?.rating3 * 3 + summary?.rating2 * 2 + summary?.rating1
      : 0;

    if (totalScore < 1) {
      return 0;
    } else {
      return Number((totalScore / (summary?.total ?? 0)).toFixed(2));
    }
  }, [summary]);

  const averageDifficultyRating = React.useMemo(() => {
    const totalScore = summary
      ? summary?.difficulty5 * 5 +
        summary?.difficulty4 * 4 +
        summary?.difficulty3 * 3 +
        summary?.difficulty2 * 2 +
        summary?.difficulty1
      : 0;

    if (totalScore < 1) {
      return 0;
    } else {
      return Number((totalScore / (summary?.total ?? 0)).toFixed(2));
    }
  }, [summary]);

  return (
    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]} className="feedback-progress-container">
      <SpaceBetween direction="vertical" size="xxxs">
        <Box variant="h2">{label}</Box>
        <HorizontalRule evenMargins />
        <Box variant="h4">
          {roundFloat(hasDifficulty ? averageDifficultyRating : averageChallengeRating).toFixed(2)}
        </Box>
        {hasDifficulty ? (
          <CircleRating value={averageDifficultyRating} customView={averageDifficultyRating ? true : false} />
        ) : (
          <StarRating
            value={averageChallengeRating}
            valueCount={summary?.total ?? 0}
            customView={averageChallengeRating ? true : false}
          />
        )}
        <Box variant="small">
          {summary?.total} {t(i18nKeys.challenges.challengeDetails.text.feedbackProgress.ratings)}
        </Box>
      </SpaceBetween>
      <Box>
        {(label === t(i18nKeys.challenges.challengeDetails.text.feedbackItem.rating) ? ratings : difficulties).map(
          (el, i, arr) => (
            <SpaceBetween key={i} size="s" direction="horizontal" alignItems="center">
              <Box>{arr.length - i} stars</Box>
              <Box className="fixed-width" margin={{ vertical: 'xs' }}>
                <ProgressBar className="custom-progress" value={percentOfTotal(el ?? 0)} variant="key-value" />
              </Box>
              <Box>
                {percentOfTotal(el ?? 0)}% ({el ?? 0})
              </Box>
            </SpaceBetween>
          )
        )}
      </Box>
    </Grid>
  );
};

export default FeedbackProgress;
