import React from 'react';
import { useEditEventTemplate } from '@/src/store/edit-event-template.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Badge, Box, ButtonProps, Flashbar, FlashbarProps, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
// import { Rating } from 'react-simple-star-rating';
import PlanEvent from '../../../molecules/EventTemplate/CatalogDetails/PlanEvent.tsx/PlanEvent';
import { getSelectedTags } from '@/src/utils/event-template.utils';
import { EventLearningTypeBadge } from '@/src/components/ui/molecules/EventTemplate';
import SEO from '@/src/components/common/SeoComponent';

interface MessageDefinition {
  header?: React.ReactNode;
  content?: React.ReactNode;
  dismissible?: boolean;
  dismissLabel?: string;
  statusIconAriaLabel?: string;
  loading?: boolean;
  type?: FlashbarProps.Type;
  ariaRole?: FlashbarProps.AriaRole;
  action?: React.ReactNode;
  id?: string;
  buttonText?: ButtonProps['children'];
  onButtonClick?: ButtonProps['onClick'];
  onDismiss?: ButtonProps['onClick'];
}

export interface ICatalogDetailsHeader {
  hasDefectiveOrPrivateChallenge: boolean;
}

const Header: React.FC<ICatalogDetailsHeader> = ({ hasDefectiveOrPrivateChallenge }) => {
  const { t } = useTranslation();
  const { eventTemplate } = useEditEventTemplate();
  const tags = eventTemplate?.tags && getSelectedTags(eventTemplate?.tags);
  const [items, setItems] = React.useState<MessageDefinition[]>([]);

  const onError = (error: string): void => {
    if (error) {
      setItems([
        {
          header: `${t(i18nKeys.general.error)}`,
          type: 'error',
          content: `${t(i18nKeys.errors.requestFailed.events.createJamEventRequest)}. ${error}`,
          dismissible: true,
          onDismiss: () => setItems([]),
          id: 'catalog-details',
        },
      ]);
    } else {
      setItems([]);
    }
  };

  const hasDefectiveOrPrivateWarning: FlashbarProps.MessageDefinition[] = [
    {
      type: 'info',
      content: (t(i18nKeys.eventTemplates.catalogDetails.hasDefectiveOrPrivateWarning)),
      id: 'hasDefectiveOrPrivateWarning',
    },
  ];

  return (
    <div>
      <div style={{ width: '70%', margin: 'auto' }}>
        <Box margin={{ bottom: 'xl' }}>
          <Flashbar items={items} />
        </Box>
      </div>
      <Grid
        gridDefinition={[
          { colspan: { l: 6, xxs: 10 }, offset: { l: 1, xxs: 1 } },
          { colspan: { l: 3, xxs: 10 }, offset: { l: 1, xxs: 1 } },
        ]}>
        <div className="custom-home__header-title">
          <SEO title={`${eventTemplate?.name} | AWS JAM event details `} description={`${eventTemplate?.summary}`} />
          <SpaceBetween size="s">
            <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
              {hasDefectiveOrPrivateChallenge && (<Flashbar items={hasDefectiveOrPrivateWarning} />)}
              {eventTemplate?.name}
            </Box>
            <div>
              <Box variant="h4" fontWeight="normal" color="text-label">
                <span className="custom-home__header-sub-title title-highlight">
                  {t(i18nKeys.eventTemplates.catalogDetails.subtitlePrefix)}{' '}
                </span>
                <span className="custom-home__header-sub-title">{eventTemplate?.summary}</span>
              </Box>
            </div>
            {/** Due to static data we comment this part */}
            {/* <Box variant="div" color="inherit">
              {eventTemplateRating?.averageRating.toFixed(1)}&nbsp;
              <Rating readonly initialValue={eventTemplateRating?.averageRating} size={15} iconsCount={5} allowFraction />
              &nbsp;({eventTemplateRating?.numberOfRatings})
            </Box> */}
            <SpaceBetween size="s" direction="horizontal">
              {!!eventTemplate && <EventLearningTypeBadge learningType={eventTemplate.learningType} />}
              {tags?.map((badge) => (
                <Badge key={badge.value} color="green">
                  {badge.label[0].toUpperCase() + badge.label.slice(1)}
                </Badge>
              ))}
            </SpaceBetween>
          </SpaceBetween>
        </div>
        <div className="custom-home__header-cta">
          <PlanEvent onAddFlashbar={onError} />
        </div>
      </Grid>
    </div>
  );
};

export default Header;
