import React, { useEffect } from 'react';
import { ContentLayout, Header } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { useParams } from 'react-router';
import { LoadingBar } from '../../common/LoadingBar';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { useHistory } from 'react-router-dom';
import { JAM_CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import JamChallengeHeader from '../JamChallengeHeader/JamChallengeHeader';

const JamChallengeOutputProperties: React.FC = () => {
  const { t } = useTranslation();
  const { eventName } = useJamEventDetails();
  const history = useHistory();
  const params: { 0: string; 1: string } = useParams();
  const { challengeProgress, selectedJamChallenge, outputProperties } = useJamChallengeDetails();
  const { clearFlashbars } = useFlashbars();

  React.useEffect(() => {
    clearFlashbars();
  }, [params[1]]);

  useEffect(() => {
    if (!challengeProgress?.started && !outputProperties) {
      history.push(JAM_CHALLENGE_DETAILS_ROUTES.Overview.resolve([eventName, params[1]]));
    }
  }, [eventName, outputProperties, challengeProgress, params[1]]);

  if (!selectedJamChallenge) {
    return <LoadingBar />;
  }

  return (
    <ContentLayout
      header={
        <Header variant="h1" description={t(i18nKeys.myJams.challenges.subtitle)}>
          {selectedJamChallenge?.title.length > 50
            ? `${selectedJamChallenge.title.slice(0, 50)}...`
            : selectedJamChallenge?.title}
        </Header>
      }>
      <JamChallengeHeader defaultExpanded />
    </ContentLayout>
  );
};

export default JamChallengeOutputProperties;
