import {
  Badge,
  Checkbox,
  Container,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { KeyValue } from '../KeyValue';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { AudienceTypeBasedOnUserGroup, EventAudienceType } from '../../../constants/shared/event-audience-type';
import { useUser } from '../../../store/user.context';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { toTitleCase } from '../../../utils/string.utils';
import { EventTags } from '../EventTags';
import { Campaign } from '../../../types/Campaign';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import '../../../styles/tags.scss';
import { DateTime } from '../DateTime';
import EventTimes from '../../events/eventDetailsSections/Summary/EventTimes';
import { EventFields } from '@/src/utils/event.validation.utils';
import { audienceTypeDictionary } from '../../events/event-list-config';
import { EventPrivacyType } from '@/src/types/EventPrivacyType';
import { DateTimeKeys, TimezoneFormat } from '../CommonModel';
import { IEventTemplate } from '@/src/types/EventTemplate';

interface TargetSummaryDetailsProps {
  target: Event | Campaign | undefined;
  eventTemplate?: IEventTemplate | undefined;
  handleCancelOrDeleteEvent?: () => void;
  eventTitleValidation?: {
    isValidSection: (setErrors?: boolean | undefined) => boolean;
    isValidField: (field: EventFields, setError?: boolean | undefined) => boolean;
  };
  titleError?: string;
  channelError?: string;
}

const TargetSummaryDetails: React.FC<TargetSummaryDetailsProps> = ({
  target,
  eventTemplate,
  eventTitleValidation,
  titleError,
  channelError,
}) => {
  const { t } = useTranslation();
  const { editMode, newEventMode, editedEvent, isGuestUserAndEditModeEnabled, handleUpdateEditEvent } = useEditEvent();
  const { campaignEditMode, newCampaignMode, editedCampaign, handleUpdateEditCampaign } = useEditCampaign();
  const { user } = useUser();
  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(null);

  const isEvent = target instanceof Event;

  const canEdit = useMemo(() => {
    return target instanceof Event ? editMode || newEventMode : campaignEditMode || newCampaignMode;
  }, [editMode, newEventMode, campaignEditMode, newCampaignMode]);

  const privacyType = (target as Event)?.eventPrivacyType as keyof typeof EventPrivacyType;

  // TODO: Fix design token import errors and replace javascript implementation below
  const SecondaryText = {
    color: awsui.colorTextFormSecondary,
  };

  const EventAudienceTypeDescriptionDictionary = {
    [EventAudienceType.SKILL_BUILDER_EVENT]: `${toTitleCase(EventAudienceType.SKILL_BUILDER_EVENT)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.skillBuilderEvent
    )}`,
    [EventAudienceType.AWS_CLASSROOMS_BUILDER_LABS]: `${toTitleCase(
      EventAudienceType.AWS_CLASSROOMS_BUILDER_LABS
    )} - ${t(i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.awsClassroomsBuilderLabs)}`,
    [EventAudienceType.INTERNAL]: `${toTitleCase(EventAudienceType.INTERNAL)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.internal
    )}`,
    [EventAudienceType.PUBLIC]: `${toTitleCase(EventAudienceType.PUBLIC)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.public
    )}`,
    [EventAudienceType.PAID_ALA_CARTE]: `${toTitleCase(EventAudienceType.PAID_ALA_CARTE)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.paidAlaCarte
    )}`,
    [EventAudienceType.GLOBAL_PARTNERS]: `${toTitleCase(EventAudienceType.GLOBAL_PARTNERS)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.globalPartners
    )}`,
    [EventAudienceType.AWS_EDUCATION]: `${toTitleCase(EventAudienceType.AWS_EDUCATION)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.awsEducation
    )}`,
    [EventAudienceType.RECRUITING]: `${toTitleCase(EventAudienceType.RECRUITING)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.recruiting
    )}`,
    [EventAudienceType.JAM_JOURNEY]: `${toTitleCase(EventAudienceType.JAM_JOURNEY)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.jamJourney
    )}`,
  };

  const updateAudienceType = (audienceType: OptionDefinition) => {
    if (audienceType) {
      setSelectedOption(audienceType);
      handleUpdateEditEvent(EditEventActions.AUDIENCE, audienceType.value);
    }
  };

  const channelsForGroup: Record<string, boolean> = useMemo(() => {
    if (!user) return {};
    if (user.isSuperAdmin) {
      return AudienceTypeBasedOnUserGroup.JAM_ADMIN_USER;
    }
    if (user.isAmazonian) {
      return AudienceTypeBasedOnUserGroup.FEDERATE_USER;
    }
    if (user.isSubscribedUser) {
      return AudienceTypeBasedOnUserGroup.SB_ADMIN;
    }
    return {};
  }, [user]);

  const selectionOptions = useMemo(() => {
    if (!user) return [];
    if (!canEdit) return [];

    const newSelection: OptionDefinition[] = [];

    // If audienceType is not null, set the selected audience type.
    if ((target as Event).audienceType !== null) {
      setTimeout(() => {
        updateAudienceType({
          label: EventAudienceTypeDescriptionDictionary[(target as Event).audienceType || ""],
          value: (target as Event).audienceType || "",
        });
      }, 100);
    }

    for (const [audience, isDefault] of Object.entries(channelsForGroup)) {
      newSelection.push({
        label: EventAudienceTypeDescriptionDictionary[audience],
        value: audience,
      });

      // If audienceType is null, set to default.
      if ((target as Event).audienceType === null && isDefault) {
        setTimeout(() => {
          updateAudienceType({
            label: EventAudienceTypeDescriptionDictionary[audience],
            value: audience,
          });
        }, 100);
      }
    }

    return newSelection;
  }, [user, canEdit]);

  /**
   * select the default channel or
   * if there is only one option
   * preselect that option..
   * let selectionOptions get assigned to Select before
   * we set the value.. somehow it doesn't work
   * otherwise. need to investigate
   */
  useEffect(() => {
    if (!selectionOptions.length) {
      return;
    }
    if (selectionOptions.length === 1) {
      setTimeout(() => {
        updateAudienceType(selectionOptions[selectionOptions.length - 1]);
      }, 0);
      return;
    }
    const defaultChannelKey = Object.keys(channelsForGroup).find((key) => channelsForGroup[key]);
    setTimeout(() => {
      let option;
      if (defaultChannelKey && (option = selectionOptions.find((item) => item.value === defaultChannelKey))) {
        if (option) updateAudienceType(option);
      }
    }, 0);
  }, [selectionOptions, channelsForGroup]);

  const updateDisableCodeWhisperer = (value: boolean) => {
    handleUpdateEditEvent(EditEventActions.CODE_WHISPERER_DISABLED, value);
  };

  const handleTitleUpdate = (value: string) => {
    if ((value && typeof value !== 'string') || !value.trim()) {
      if (isEvent) {
        handleUpdateEditEvent(EditEventActions.TITLE, '');
      } else {
        handleUpdateEditCampaign(EditCampaignActions.TITLE, '');
      }
      return;
    }
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.TITLE, value);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.TITLE, value);
    }
  };

  const handleChangeSlug = (value: string) => {
    if ((value && typeof value !== 'string') || !value.trim()) {
      if (isEvent) {
        handleUpdateEditEvent(EditEventActions.SLUG, '');
      } else {
        handleUpdateEditCampaign(EditCampaignActions.SLUG, '');
      }
      return;
    }
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.SLUG, value);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.SLUG, value);
    }
  };

  const showCampaignSlug = (): boolean => {
    if (target instanceof Campaign) {
      if (target.cancelled) {
        return false;
      }
      if (target.denied) {
        return false;
      }

      return target.approved;
    }
    return false;
  };

  const editedSlug = isEvent ? target.name : target?.slug;

  const renderContent = () => {
    return !canEdit ? (
      <React.Fragment>
        {user?.hasBasicAccess ? (
          <div>
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.eventTitle.title)}</div>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.startTime.title)}</div>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.endTime.title)}</div>
            </Grid>
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
              <div>{target?.title}</div>
              <DateTime
                startOrEnd={DateTimeKeys.START}
                event={target as Event}
                timezoneFormat={TimezoneFormat.LOCAL}
                displayInlineDateTime
              />
              <DateTime
                startOrEnd={DateTimeKeys.END}
                event={target as Event}
                timezoneFormat={TimezoneFormat.LOCAL}
                displayInlineDateTime
              />
            </Grid>
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.timezone.title)}</div>
              {(target as Event).catalogId === null && <div style={SecondaryText}>{t(i18nKeys.challenges.challengeDetails.titles.minExpectedParticipants)}</div>}
              <div style={SecondaryText}>{t(i18nKeys.events.fields.numberOfParticipants.title)}</div>
            </Grid>
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
              <div>{(target as Event)?.timezone}</div>
              {(target as Event).catalogId === null && <div>{(target as Event).minExpectedParticipants}</div>}
              <div>{(target as Event).maxExpectedParticipants}</div>
            </Grid>
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.audience.title)}</div>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.eventPrivacyType.label)}</div>
            </Grid>
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
              <div>{audienceTypeDictionary[(target as Event)?.audienceType ?? '']}</div>
              <div>
                {t(i18nKeys.events.eventDetails.labels.evenPrivacyType[privacyType]) ||
                  t(i18nKeys.events.eventDetails.labels.evenPrivacyType.PRIVATE_CODE)}
              </div>
            </Grid>
          </div>
        ) : (
          <div>
            <div className="section-first-row">
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                <div style={SecondaryText}>
                  {isEvent
                    ? t(i18nKeys.events.fields.eventTitle.title)
                    : t(i18nKeys.campaigns.labels.campaignDetails.campaignTitle)}
                </div>
                <div>{target?.title}</div>
              </Grid>
            </div>
            {((isEvent && target?.showEventId) || (target instanceof Campaign && showCampaignSlug())) && (
              <div className="grey-section-divider-top">
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                  <div style={SecondaryText}>{t(i18nKeys.events.fields.slug.title)}</div>
                  <div>{isEvent ? target?.id : target.slug}</div>
                </Grid>
              </div>
            )}
            {isEvent && (
              <div className="grey-section-divider-top">
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                  <div style={SecondaryText}>{t(i18nKeys.events.fields.audience.title)}</div>
                  <div>{EventAudienceTypeDescriptionDictionary[target?.audienceType as string]}</div>
                </Grid>
              </div>
            )}
            <div className="grey-section-divider-top">
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                <div style={SecondaryText}>{t(i18nKeys.events.fields.tags.title)}</div>
                <div className="tag-group">
                  {target?.tags?.map((tag, key) => {
                    return (
                      <Badge color="grey" key={key}>
                        {tag}
                      </Badge>
                    );
                  })}
                </div>
              </Grid>
            </div>
          </div>
        )}
      </React.Fragment>
    ) : (
      canEdit && user && (
        <SpaceBetween direction="vertical" size="m">
          <KeyValue
            label={
              <b>
                {isEvent
                  ? t(i18nKeys.events.fields.eventTitle.title)
                  : t(i18nKeys.campaigns.labels.campaignDetails.campaignTitle)}
              </b>
            }
            required>
            <FormField
              errorText={titleError}
              i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
              constraintText={`${
                isEvent
                  ? t(i18nKeys.events.fields.eventTitle.title)
                  : t(i18nKeys.campaigns.labels.campaignDetails.campaignTitle)
              } ${t(i18nKeys.generic.lengthLimit4to250)}`}>
              <Input
                value={target?.title || ''}
                placeholder={
                  isEvent
                    ? t(i18nKeys.events.eventDetails.labels.exampleJamTitle)
                    : t(i18nKeys.campaigns.labels.campaignDetails.exampleCampaignTitle)
                }
                readOnly={!target?.canEditAttribute('title', user)}
                onChange={({ detail }) => handleTitleUpdate(detail.value)}
                onBlur={() => eventTitleValidation && eventTitleValidation.isValidField(EventFields.EVENT_TITLE)}
                data-testid="eventTitleInput"
              />{' '}
            </FormField>
          </KeyValue>
          {editMode && <EventTimes target={target as Event} eventTemplate={eventTemplate} />}
          {(isEvent && !user.hasBasicAccess && target?.showEventId && !newEventMode) ||
            (target instanceof Campaign && showCampaignSlug() && !newCampaignMode && (
              <KeyValue label={<b>{t(i18nKeys.events.fields.slug.title)}</b>} required>
                <div style={SecondaryText}>
                  {t(i18nKeys.general.example)}: https://jam.awsevents.com/
                  <span style={{ color: awsui.colorTextStatusSuccess }}>my-jam-event</span>
                </div>
                <Input
                  value={editedSlug || ''}
                  onChange={({ detail }) => handleChangeSlug(detail.value)}
                  placeholder="aws-slug-example"
                  readOnly={isEvent ? !newEventMode : !newCampaignMode}
                  disabled={isEvent ? !newEventMode : !newCampaignMode}
                  data-testid="eventSlugInput"
                />
              </KeyValue>
            ))}
          {isEvent && !user.isOnlyBasicUser && selectionOptions.length > 1 && (
            <KeyValue label={<b>{t(i18nKeys.events.fields.audience.title)}</b>} required>
              <div style={SecondaryText}>{t(i18nKeys.events.eventDetails.messages.whoIsThisEventIntendedFor)}</div>
              <FormField i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }} errorText={channelError}>
                <Select
                  disabled={!!isGuestUserAndEditModeEnabled}
                  options={selectionOptions}
                  selectedOption={selectedOption}
                  onChange={({ detail }) => updateAudienceType(detail.selectedOption)}
                  onBlur={() => eventTitleValidation && eventTitleValidation.isValidField(EventFields.EVENT_CHANNEL)}
                  data-testid="eventChannelSelect"
                />
              </FormField>
            </KeyValue>
          )}
          {!user.hasBasicAccess && (editedEvent || newEventMode || editedCampaign || newCampaignMode) && (
            <KeyValue label={<b>{t(i18nKeys.events.eventDetails.headers.tags)}</b>}>
              <EventTags />
            </KeyValue>
          )}
          {isEvent && !user.hasBasicAccess && (
            <KeyValue label={<b>{t(i18nKeys.events.fields.disableCodeWhisperer.sectionTitle)}</b>}>
              <label>
                <SpaceBetween direction="horizontal" size="xxxs">
                  <Checkbox
                    checked={target.codeWhispererDisabled ?? false}
                    onChange={({ detail }) => updateDisableCodeWhisperer(detail.checked)}
                    disabled={user?.hasBasicAccess}
                    data-testid="amazonQCheckbox"
                  />
                  <div style={{ marginLeft: '1rem' }}>
                    <div>{t(i18nKeys.events.fields.disableCodeWhisperer.title)}</div>
                    <div style={SecondaryText}>{t(i18nKeys.events.fields.disableCodeWhisperer.description)}</div>
                  </div>
                </SpaceBetween>
              </label>
            </KeyValue>
          )}
        </SpaceBetween>
      )
    );
  };

  return (
    <React.Fragment>
      {(isEvent ? !newEventMode : !newCampaignMode) && (
        <ExpandableSection
          defaultExpanded
          variant="container"
          header={
            <Header variant="h2">
              {isEvent
                ? t(i18nKeys.events.eventDetails.headers.eventDetails)
                : t(i18nKeys.campaigns.headers.campaignDetails.campaignDetails)}
            </Header>
          }>
          {renderContent()}
        </ExpandableSection>
      )}
      {(isEvent ? newEventMode : newCampaignMode) && (
        <Container
          header={
            <Header variant="h2">
              {isEvent
                ? t(i18nKeys.events.eventDetails.headers.eventDetails)
                : t(i18nKeys.campaigns.headers.campaignDetails.campaignDetails)}
            </Header>
          }>
          {renderContent()}
        </Container>
      )}
    </React.Fragment>
  );
};
export default TargetSummaryDetails;
