import {
  Checkbox,
  ExpandableSection,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengePropAction,
  ChallengeSettingsFields,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { Challenge } from '../../../../types/Challenge';
import { formatLabProviderLabel } from '../../../../types/LabProvider';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { toTitleCase } from '../../../../utils/string.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { KeyValue } from '../../../common/KeyValue';
import {
  AWSServicesDefinitions,
  LabProviderDefinitions,
  ModeDefinitions,
} from '../../challengesCommon/ChallengeOptionDefinitions';

interface ChallengeAdvancedSettingDetailProps {
  challenge: Challenge;
}

const ChallengeAdvancedSettingDetail: React.FC<ChallengeAdvancedSettingDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp, challengeSettingsValidator } = useCreateChallenge();

  const field = i18nKeys.challenges.subSections.settings;
  const [labProviderErrorText, setLabProviderErrorText] = useState<string>('');
  const [modeErrorText, setModeErrorText] = useState<string>('');

  useEffect(() => {
    // reset error fields when editMode is changed
    if (!editMode) {
      setLabProviderErrorText('');
      setModeErrorText('');
    }
  }, [editMode]);

  const validator = challengeSettingsValidator(
    new Map<ChallengeSettingsFields, (error: string) => void>([
      [ChallengeSettingsFields.SETTINGS_DEFAULT_LAB_PROVIDER, (error: string) => setLabProviderErrorText(error)],
      [ChallengeSettingsFields.MODE, (error: string) => setModeErrorText(error)],
    ])
  );

  const labProviders: OptionDefinition[] = [];

  const challengeModes: OptionDefinition[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  ModeDefinitions.forEach((mode: OptionDefinition) => {
    challengeModes.push({ label: t(mode.label as string), value: mode.value });
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  LabProviderDefinitions.forEach((labProvider: OptionDefinition) => {
    labProviders.push({ label: t(labProvider.label as string), value: labProvider.value });
  });

  const [advancedSettings, setAdvancedSettings] = useState({
    challengeIcon: challenge.props.challengeIcon || '',
    defaultLabProvider: challenge.props.defaultLabProvider || [],
    challengeAlwaysOn: challenge.props.challengeAlwaysOn || false,
    mode: challenge.props.mode,
    allowlistServicesRequired: challenge.props.allowlistServicesRequired || [],
    idleMinsBeforeReady: challenge.props.idleMinsBeforeReady || '',
  });

  useEffect(() => {
    if (editedChallenge) {
      setAdvancedSettings({
        challengeIcon: editedChallenge.props.challengeIcon || '',
        defaultLabProvider: editedChallenge.props.defaultLabProvider || [],
        challengeAlwaysOn: editedChallenge.props.challengeAlwaysOn || false,
        mode: editedChallenge.props.mode,
        allowlistServicesRequired: editedChallenge.props.allowlistServicesRequired || [],
        idleMinsBeforeReady: editedChallenge.props.idleMinsBeforeReady || '',
      });
    }
  }, [editedChallenge]);

  const handleAdvancedSettingsUpdate = (event: {
    target: { name: ChallengeSettingsFields; value: boolean | string | readonly OptionDefinition[] | undefined };
  }) => {
    const { name, value } = event.target;
    let uiSettings;
    if (value instanceof Array) {
      uiSettings = {
        ...advancedSettings,
        [name]: value.map((option) => option.value),
      };
    } else {
      uiSettings = {
        ...advancedSettings,
        [name]: value,
      };
    }
    setAdvancedSettings(uiSettings);
    handleUpdateChallengeProp(ChallengePropAction.ADVANCED_SETTINGS, uiSettings);
  };

  const [expanded, setExpanded] = useState(true);

  return (
    <ExpandableSection
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.advancedSettings)}</Header>}>
      <Columns columns={4} variant="default">
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.labProvider)}>
            {!editMode ? (
              formatLabProviderLabel(challenge.props.defaultLabProvider)
            ) : (
              <FormField
                errorText={labProviderErrorText}
                constraintText={t(field.container_5.fields.labProvider.constraint)}>
                <Select
                  onBlur={() => validator.isValidField(ChallengeSettingsFields.SETTINGS_DEFAULT_LAB_PROVIDER)}
                  selectedOption={
                    labProviders.find((provider) => provider.value === advancedSettings.defaultLabProvider) || null
                  }
                  onChange={({ detail }) =>
                    handleAdvancedSettingsUpdate({
                      target: {
                        name: ChallengeSettingsFields.SETTINGS_DEFAULT_LAB_PROVIDER,
                        value: detail.selectedOption.value,
                      },
                    })
                  }
                  options={labProviders}
                  selectedAriaLabel={t(i18nKeys.general.selected)}
                />
              </FormField>
            )}
          </KeyValue>
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.warmup)}>
            {!editMode && advancedSettings.challengeAlwaysOn && t(i18nKeys.general.yes)}
            {!editMode && !advancedSettings.challengeAlwaysOn && t(i18nKeys.general.no)}
            {editMode && (
              <Checkbox
                onChange={({ detail }) =>
                  handleAdvancedSettingsUpdate({
                    target: { name: ChallengeSettingsFields.CHALLENGE_ALWAYS_ON, value: detail.checked },
                  })
                }
                checked={advancedSettings.challengeAlwaysOn || false}>
                {t(i18nKeys.challenges.subSections.settings.container_5.fields.warmup.title)}
              </Checkbox>
            )}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.mode)}>
            {!editMode ? (
              toTitleCase(challenge.props.mode)
            ) : (
              <FormField errorText={modeErrorText} constraintText={t(field.container_5.fields.mode.constraint)}>
                <Select
                  onBlur={() => validator.isValidField(ChallengeSettingsFields.MODE)}
                  selectedOption={challengeModes.find((mode) => mode.value === advancedSettings.mode) || null}
                  onChange={({ detail }) =>
                    handleAdvancedSettingsUpdate({
                      target: { name: ChallengeSettingsFields.MODE, value: detail.selectedOption.value },
                    })
                  }
                  options={challengeModes}
                  selectedAriaLabel={t(i18nKeys.general.selected)}
                />
              </FormField>
            )}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.allowlist)}>
            {!editMode && advancedSettings.allowlistServicesRequired && (
              <ul>
                {challenge.props.allowlistServicesRequired.map((services, i) => (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {services}
                  </li>
                ))}
              </ul>
            )}
            {!editMode && !challenge.props.allowlistServicesRequired && t(i18nKeys.general.none)}
            {editMode && (
              <Multiselect
                selectedOptions={
                  advancedSettings.allowlistServicesRequired.map(
                    (serviceName) =>
                      AWSServicesDefinitions.find((service) => service.value === serviceName) as OptionDefinition
                  ) || []
                }
                onChange={({ detail }) =>
                  handleAdvancedSettingsUpdate({
                    target: { name: ChallengeSettingsFields.ALLOWLISTED_SERVICES, value: detail.selectedOptions },
                  })
                }
                options={AWSServicesDefinitions}
                placeholder={t(i18nKeys.challenges.subSections.settings.container_5.fields.allowlist.placeholder)}
                selectedAriaLabel={t(i18nKeys.general.selected)}
              />
            )}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.idleTime)}>
            {!editMode &&
              advancedSettings.idleMinsBeforeReady &&
              `${advancedSettings.idleMinsBeforeReady} ${t(i18nKeys.general.minutes)}`}
            {!editMode && !advancedSettings.idleMinsBeforeReady && '0 min'}
            {editMode && (
              <Input
                onChange={({ detail }) =>
                  handleAdvancedSettingsUpdate({
                    target: {
                      name: ChallengeSettingsFields.IDLE_MINS_BEFORE_READY,
                      value: detail.value.replaceAll('-', ''),
                    },
                  })
                }
                inputMode="numeric"
                type="number"
                step={1}
                placeholder={t(i18nKeys.challenges.subSections.settings.container_5.fields.idleTime.placeholder)}
                value={advancedSettings.idleMinsBeforeReady.toString() || ''}
                autoComplete
              />
            )}
          </KeyValue>
        </Column>
      </Columns>
    </ExpandableSection>
  );
};

export default ChallengeAdvancedSettingDetail;
