import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  text: string;
  maxLength: number;
}

export const TruncatedText = ({ text, maxLength }: IProps) => {
  const { t } = useTranslation();
  const truncatedText = text.slice(0, maxLength);
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  if (!text || text === '-' || text.length <= maxLength) {
    return <Box variant="p">{text}</Box>;
  }

  return (
    <div>
      {expanded ? (
        <div>
          <Box variant="p">{text}</Box>
          <button className="link-button" onClick={handleClick}>
            {t(i18nKeys.general.showLess)}
          </button>
        </div>
      ) : (
        <div>
          <Box variant="p">
            {truncatedText}...{' '}
            <button className="link-button" onClick={handleClick}>
              {t(i18nKeys.general.showMore)}
            </button>
          </Box>
        </div>
      )}
    </div>
  );
};
