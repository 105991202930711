import {
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ContentLayout,
  Grid,
  Header,
  Icon,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useJamMyTeam } from '@/src/store/jam-myteam.context';
import { JamEventTeamGoal } from '@/src/types/JamCommon';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useFlashbars } from '@/src/store/flashbar.context';
import { getErrorMessage } from '@/src/utils/errors.utils';
import JamContainer from '../../ui/molecules/JamContainer/JamContainer';
import KeyValuePairRow, { KeyValuePairRowProps } from '../../ui/molecules/KeyValuePairRow/KeyValuePairRow';
import JamTeamFormModal from '../../ui/molecules/JamTeam/JamTeamFormModal/JamTeamFormModal';
import CountDown from '../../ui/molecules/CountDown/CountDown';
import Link from '../../ui/atoms/Link/Link';
import './JamTeam.scss';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { useJamChat } from '@/src/store/jam-chat.context';
import JamSpinner from '../../common/JamSpinner';
import { JamTeamDetails } from '@/src/types/JamTeam';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import moment from 'moment';
import _ from 'lodash';
import { ModalType } from '../../ui/molecules/UIModel';

const JamTeam = () => {
  const { t } = useTranslation();
  const { event, eventSlug } = useJamEventDetails();
  const { setActiveChatType } = useJamChat();
  const {
    changeTeamGoal,
    changeTeamName,
    changeTeamPassword,
    removeTeamPassword,
    loadMyTeam,
    myTeam,
    hasTeamOwnerPermissions,
  } = useJamMyTeam();
  const { addErrorFlashbar, clearFlashbars } = useFlashbars();
  const [modalType, setModalType] = useState<ModalType | undefined>();
  const [loading, setLoading] = useState(true);
  const teamId = event?.teamId;

  const { team, statistics, participants } = (myTeam || {}) as JamTeamDetails;

  const pairProps: Partial<KeyValuePairRowProps> = { variant: 'bordered', color: 'primary' };

  const onChangeTeamName = async (newName: string) => {
    clearFlashbars();
    await changeTeamName(newName);
  };

  const onChangeTeamPassword = async (password: string) => {
    clearFlashbars();
    await changeTeamPassword(password);
  };

  const onRemoveTeamPassword = async () => {
    clearFlashbars();
    await removeTeamPassword();
  };

  const onChangeTeamGoal = async (goal: string) => {
    clearFlashbars();
    await changeTeamGoal(goal as JamEventTeamGoal);
  };

  const onClose = () => setModalType(undefined);

  const onSubmit = async (value?: string) => {
    clearFlashbars();
    if (!modalType) {
      return;
    }
    const method = {
      [ModalType.ChangePassword]: onChangeTeamPassword,
      [ModalType.RemovePassword]: onRemoveTeamPassword,
      [ModalType.EditTeamGoal]: onChangeTeamGoal,
      [ModalType.RenameTeam]: onChangeTeamName,
    };
    try {
      void (await method[modalType](value as string));
    } catch (e: any) {
      addErrorFlashbar(getErrorMessage(e));
    }
    onClose();
  };

  const initialize = async () => {
    try {
      setLoading(true);
      await loadMyTeam();
    } catch (e) {
      // error
    }
    setLoading(false);
  };

  useEffect(() => {
    void initialize();
  }, [teamId]);

  const renderAction = (): React.ReactNode => {
    const chatButton = (
      <Button variant="primary" iconName="contact" onClick={() => setActiveChatType('team')}>
        {t(i18nKeys.jamTeam.actions.teamChat)}
      </Button>
    );
    if (hasTeamOwnerPermissions && !event?.facilitator) {
      const renameAction = team.canRename
        ? [{ id: ModalType.RenameTeam as unknown as string, text: t(i18nKeys.jamTeam.actions.changeTeamName) }]
        : [];
      const subPasswordActions = team.passwordRequired
        ? [
            { id: ModalType.ChangePassword as unknown as string, text: t(i18nKeys.jamTeam.actions.changeTeamPassword) },
            { id: ModalType.RemovePassword as unknown as string, text: t(i18nKeys.jamTeam.actions.removeTeamPassword) },
          ]
        : [{ id: ModalType.ChangePassword as unknown as string, text: t(i18nKeys.jamTeam.actions.AddTeamPassword) }];
      return (
        <SpaceBetween direction="horizontal" size="m">
          <ButtonDropdown
            onItemClick={({ detail }) => setModalType(detail.id as unknown as ModalType)}
            items={[
              ...renameAction,
              ...subPasswordActions,
              {
                id: ModalType.EditTeamGoal as unknown as string,
                text: _.toString(t(i18nKeys.jamTeam.actions.changeTeamGoal)),
              },
            ]}>
            Actions
          </ButtonDropdown>
          {chatButton}
        </SpaceBetween>
      );
    }
    return chatButton;
  };

  if (loading) {
    return <JamSpinner />;
  }

  if (!team) {
    return (
      <ContentLayout
        header={
          <Header>
            <Box variant="h2">{t(i18nKeys.jamTeam.title)}</Box>
          </Header>
        }>
        <Container>
          <Box color="text-status-error">{t(i18nKeys.jamTeam.errors.failedTeamDetails)}</Box>
        </Container>
      </ContentLayout>
    );
  }

  return (
    <div className="jam-team-container">
      <ContentLayout
        header={
          <Header actions={renderAction()}>
            <Box variant="h2">
              {t(i18nKeys.jamTeam.title)}: {team.teamName}
            </Box>
          </Header>
        }>
        <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}>
          <JamContainer>
            <ColumnLayout columns={2} variant="text-grid">
              <Box variant="h3">
                {t(i18nKeys.jamTeam.timeLeft)}
                <CountDown targetDate={moment(event?.eventEndDate).valueOf()} />
              </Box>
              <Box variant="h3">
                {t(i18nKeys.jamTeam.currentRank)}
                <div className="leaderboard-link">
                  <Link href={JAM_EVENT_DETAILS_ROUTES.LeaderBoard.resolve(eventSlug)} variant="info">
                    {t(i18nKeys.myJams.challenges.details.overview.goToLeaderboard)}{' '}
                    <Icon name="angle-right" size="inherit" />
                  </Link>
                </div>
              </Box>
            </ColumnLayout>
          </JamContainer>
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            <JamContainer>
              <Box>{t(i18nKeys.jamTeam.statistics.title)}</Box>
              <Box>
                <KeyValuePairRow
                  {...pairProps}
                  param={t(i18nKeys.jamTeam.statistics.teamGoal)}
                  value={t(i18nKeys.joinTeam.form.commonFields[team.goal])}
                />
                <KeyValuePairRow
                  {...pairProps}
                  param={t(i18nKeys.jamTeam.statistics.completedChallenges)}
                  value={`${statistics.challengesCompleted} of ${statistics.totalChallenges}`}
                />
                <KeyValuePairRow
                  {...pairProps}
                  param={t(i18nKeys.jamTeam.statistics.score)}
                  value={`${statistics.score} points`}
                />
                <KeyValuePairRow
                  {...pairProps}
                  param={t(i18nKeys.jamTeam.statistics.maxScore)}
                  value={t(i18nKeys.jamTeam.statistics.points, { points: statistics.maxScore })}
                />
                <KeyValuePairRow
                  {...pairProps}
                  param={t(i18nKeys.jamTeam.statistics.cluesUsed)}
                  value={statistics.cluesUsed}
                />
                <KeyValuePairRow
                  color="primary"
                  param={t(i18nKeys.jamTeam.statistics.cluesPenalties)}
                  value={statistics.cluePenalties}
                  isLast
                />
              </Box>
            </JamContainer>
            <JamContainer>
              <Header variant="h3">{t(i18nKeys.jamTeam.teamMembers)}</Header>
              {participants.map((teamMember, index) => (
                <KeyValuePairRow
                  key={teamMember.participantId}
                  variant={index !== participants.length - 1 ? 'bordered' : 'none'}
                  isLast={index !== participants.length - 1}
                  param={teamMember.nickname}
                  value=""
                />
              ))}
            </JamContainer>
          </Grid>
        </Grid>
        {!!modalType && (
          <JamTeamFormModal
            modalType={modalType}
            handleClose={onClose}
            handleSubmit={(value) => void onSubmit(value)}
            teamName={team.teamName}
            goal={team.goal}
          />
        )}
      </ContentLayout>
    </div>
  );
};

export default JamTeam;
