import { CollectionPreferencesProps, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignParticipant } from '../../../../types/Campaign';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { toTitleCase } from '../../../../utils/string.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../utils/table.utils';
import { compareValues } from '../../../common/Challenges/browse-challenges-list-config';
import { CopyToClipboard } from '../../../common/CopyToClipboard';

export const filteringFunction = (item: CampaignParticipant, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    matcher.isMatch(item.email) ||
    (item.currentStatus?.status && matcher.isMatch(item.currentStatus?.status)) ||
    (item.mostRecentAttempt?.score && matcher.isMatch(item.mostRecentAttempt?.score.toString())) ||
    matcher.isMatch(item.numInvitesSent.toString())
  );
};

export const COLUMN_DEFINITIONS = (_preferences: CollectionPreferencesProps.Preferences) => {
  const { t } = useTranslation();

  return [
    {
      id: 'email',
      sortingField: 'email',
      header: t(i18nKeys.campaigns.headers.groups.email),
      cell: (item: CampaignParticipant) => {
        return (
          <React.Fragment>
            {item.email && (
              <div>
                <Link href={`mailto:${item.email}`}>{item.email}</Link>
                <CopyToClipboard value={item.email} icon />
              </div>
            )}
          </React.Fragment>
        );
      },
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'status',
      sortingField: 'status',
      header: t(i18nKeys.campaigns.headers.groups.status),
      sortingComparator: (a: CampaignParticipant, b: CampaignParticipant) => {
        return compareValues(a?.currentStatus?.status || null, b?.currentStatus?.status || null);
      },
      cell: (item: CampaignParticipant) => toTitleCase(item.currentStatus?.status),
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'score',
      sortingField: 'score',
      header: t(i18nKeys.campaigns.headers.groups.score),
      sortingComparator: (a: CampaignParticipant, b: CampaignParticipant) => {
        return compareValues(a?.mostRecentAttempt?.score || null, b?.mostRecentAttempt?.score || null);
      },
      cell: (item: CampaignParticipant) => item?.mostRecentAttempt?.score,
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'invites-sent',
      sortingField: 'numInvitesSent',
      header: t(i18nKeys.campaigns.headers.groups.invitesSent),
      cell: (item: CampaignParticipant) => item?.numInvitesSent,
      minWidth: 260,
      allowLineWrap: true,
    },
  ];
};
