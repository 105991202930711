import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Wizard, WizardProps } from '@amzn/awsui-components-react';

// context
import { useCreateEventTemplate } from '@/src/store/create-event-template.context';
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';
import { useSplitPanel } from '@/src/store/split-panel.context';

// utils
import { LoggingService } from '@/src/utils/logging-service.utils';
import { preProdLogger } from '@/src/utils/log.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { IEventTemplate } from '@/src/types/EventTemplate';

// types
import { RoutePath } from '@/src/RoutePath';

// components
import { SuccessModal, QuitConfirmModal } from '@/src/components/ui/molecules/EventTemplate';
import { ConfigureEvent, NameDuration, SelectChallenges, ReviewCreate, SelectChallengesTableHeader } from './Sections';

import './EventTemplateCreate.scss';
import { ChallengeOrderSection } from './Sections/ChallengeOrder/ChallengeOrderSection';
import JamSpinner from '../../common/JamSpinner';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';
import { useChallenges } from '@/src/store/challenge.context';

const steps = [
  {
    title: i18nKeys.eventTemplates.step1.title,
    StepContent: NameDuration,
  },
  {
    title: i18nKeys.eventTemplates.step2.title,
    StepContent: SelectChallenges,
    Description: SelectChallengesTableHeader,
    hideTitle: true,
  },
  {
    title: i18nKeys.eventTemplates.step3.title,
    StepContent: ChallengeOrderSection,
  },
  {
    title: i18nKeys.eventTemplates.step4.title,
    StepContent: ConfigureEvent,
  },
  {
    title: i18nKeys.eventTemplates.step5.title,
    StepContent: ReviewCreate,
  },
];
const {
  stepNumberLabel,
  navigationAriaLabel,
  errorIconAriaLabel,
  cancelButton,
  previousButton,
  submitButton,
  optional,
  saveContinueButton,
} = i18nKeys.eventTemplates.createEventTemplateWizard;

interface IEventTemplateCreateProps {
  event: IEventTemplate;
}

const { unsavedChangesMessage, confirmQuitMessage } = i18nKeys.eventTemplates.quitModal;

const EventTemplateCreate: React.FC<IEventTemplateCreateProps> = ({ event }) => {
  const { loading, name, saveEventTemplate, loadEventTemplate, submitEventTemplate, getStepNumber, hasUnsavedChanges } =
    useCreateEventTemplate();
  const { fetchEventTemplateOffers } = useEventTemplateOffers();
  const { challengeSets, fetchChallengeSets } = useEventTemplateChallenges();
  const { challengeListOpenSearchItems } = useChallenges();
  const { toggleShowSplitPanel } = useSplitPanel();

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const isCurrentSectionValid = useRef<() => Promise<boolean>>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showQuitConfirmModal, setShowQuitConfirmModal] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    void loadEventTemplate(event);
  }, [event, loadEventTemplate]);

  useEffect(() => {
    void fetchEventTemplateOffers();
  }, []);

  useEffect(() => {
    if (!challengeSets || challengeSets.length === 0) {
      void fetchChallengeSets();
    }
  }, [challengeSets, fetchChallengeSets]);

  useEffect(() => {
    if (!challengeSets || challengeSets.length === 0) {
      void fetchChallengeSets();
    }
  }, [challengeSets, fetchChallengeSets]);

  useEffect(() => {
    if (activeStepIndex !== 1) {
      toggleShowSplitPanel(false);
    }
  }, [toggleShowSplitPanel, activeStepIndex]);

  useEffect(() => {
    if (loading) return;
    if (!event) return setActiveStepIndex(0);
    const queryParams = new URLSearchParams(search);
    const stepQueryParam = queryParams.get('step');
    const goToStep = stepQueryParam ? getStepNumber(parseInt(stepQueryParam, 10) - 1) : getStepNumber();
    history.replace(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${event.id}/edit?step=${goToStep + 1}`);
    setActiveStepIndex(goToStep);
  }, [loading, event, search, getStepNumber, history]);

  useEffect(() => {
    if (loading || !challengeListOpenSearchItems || challengeListOpenSearchItems.length === 0) return;
    hideShowTopTitle(activeStepIndex);
  }, [loading, challengeListOpenSearchItems, activeStepIndex]);

  const handleCancel = useCallback(() => {
    setShowQuitConfirmModal(true);
  }, []);

  const isSectionValid = useCallback(async () => {
    if (!isCurrentSectionValid.current) return false;
    try {
      const res = await isCurrentSectionValid.current();
      return res;
    } catch (error) {
      preProdLogger('Could not complete validation.', error);
      throw error;
    }
  }, [isCurrentSectionValid.current]);

  const validationHandler = (validateSection: () => Promise<boolean>) => {
    isCurrentSectionValid.current = validateSection;
  };

  const handleWizardNavigation: WizardProps['onNavigate'] = (e) => {
    const { requestedStepIndex, reason } = e.detail;
    if (reason !== 'next') {
      if (event) {
        history.replace(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${event.id}/edit?step=${requestedStepIndex + 1}`);
      } else {
        setActiveStepIndex(requestedStepIndex);
      }
      return;
    }
    navigateWithValidation(requestedStepIndex);
  };

  const hideShowTopTitle = (stepIndex: number) => {
    const headerElements = document.querySelectorAll<HTMLElement>('span[class^="awsui_form-header-component-wrapper"]');
    headerElements.forEach((element) => {
      if (stepIndex === 1) {
        element.style.display = 'none';
      } else {
        element.style.display = 'unset';
      }
    });
  };

  const navigateWithValidation = useCallback(
    (requestedStepIndex: number) => {
      isSectionValid()
        .then(saveEventTemplate)
        .then((response) => {
          if (response) {
            history.replace(`${RoutePath.EVENT_CATALOG_TEMPLATES}/${response.id}/edit?step=${requestedStepIndex + 1}`);
            setActiveStepIndex(requestedStepIndex);
          }
        })
        .catch((err) => {
          LoggingService.error('Validation failed', err);
          preProdLogger('Could not complete validation.', err);
        });
    },
    [isSectionValid, saveEventTemplate]
  );

  const wizardSteps = steps.map(({ title, StepContent, Description }) => {
    return {
      title: t(title),
      info: false,
      description: Description && (
        <Description
          validationHandler={validationHandler}
          setActiveStepIndex={navigateWithValidation}
          activeStepIndex={activeStepIndex}
        />
      ),
      content: (
        <StepContent
          validationHandler={validationHandler}
          setActiveStepIndex={navigateWithValidation}
          activeStepIndex={activeStepIndex}
        />
      ),
    };
  });

  const i18nStrings = useMemo<WizardProps.I18nStrings>(() => {
    return {
      stepNumberLabel: (stepNumber: number) => t(stepNumberLabel, { stepNumber }),
      collapsedStepsLabel: (stepNumber: number, stepsCount: number) => t(stepNumberLabel, { stepNumber, stepsCount }),
      errorIconAriaLabel: t(errorIconAriaLabel),
      navigationAriaLabel: t(navigationAriaLabel),
      cancelButton: t(cancelButton),
      previousButton: t(previousButton),
      nextButton: t(saveContinueButton),
      submitButton: t(submitButton),
      optional: t(optional),
    };
  }, [t, activeStepIndex]);

  const handleSubmit = useCallback(() => {
    isSectionValid()
      .then(submitEventTemplate)
      .then((response) => {
        if (response) {
          setShowSuccessModal(true);
        }
      })
      .catch((err) => {
        LoggingService.error('Validation failed', err);
        preProdLogger('Could not complete validation.', err);
      });
  }, [submitEventTemplate, history, isSectionValid]);

  const handleSuccessDismiss = () => {
    setShowSuccessModal(false);
    history.push(RoutePath.EVENT_CATALOG_TEMPLATES);
  };

  const handleQuitDismiss = () => {
    setShowQuitConfirmModal(false);
  };

  const handleQuitOkClick = () => {
    setShowQuitConfirmModal(false);
    history.push(RoutePath.EVENT_CATALOG_TEMPLATES);
  };

  const unsaved = hasUnsavedChanges();
  return (
    <div className="mh-100 pb-50 wizard">
      {loading ? (
        <div className="event-template-loading">
          <JamSpinner />
        </div>
      ) : (
        <Wizard
          steps={wizardSteps}
          activeStepIndex={activeStepIndex}
          i18nStrings={i18nStrings}
          onNavigate={handleWizardNavigation}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
      <SuccessModal name={name} status={'created'} visible={showSuccessModal} onDismiss={handleSuccessDismiss} />
      <QuitConfirmModal
        unsaved={unsaved}
        visible={showQuitConfirmModal}
        onOkClick={handleQuitOkClick}
        onDismiss={handleQuitDismiss}
        message={unsaved ? t(unsavedChangesMessage) : t(confirmQuitMessage)}
      />
    </div>
  );
};

export default EventTemplateCreate;
