import React from 'react';
import { Box } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './FeedbackDifficulty.scss';

interface FeedbackDifficultyProps {
  difficulty: number;
}

const FeedbackDifficulty: React.FC<FeedbackDifficultyProps> = ({ difficulty }) => {
  const { t } = useTranslation();
  const severity = [
    t(i18nKeys.myJams.feedback.label.difficulty.veryEasy),
    t(i18nKeys.myJams.feedback.label.difficulty.easy),
    t(i18nKeys.myJams.feedback.label.difficulty.moderate),
    t(i18nKeys.myJams.feedback.label.difficulty.difficult),
    t(i18nKeys.myJams.feedback.label.difficulty.veryDifficult),
  ];

  return <Box className="light-violet">{severity[difficulty - 1]}</Box>;
};

export default FeedbackDifficulty;
