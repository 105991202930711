import React, { useState, useContext } from 'react';
import { LSEMessage } from '../types/Lse';
import { preProdLogger } from '../utils/log.utils';
import { useApi } from './api.context';
import { RoutePath } from '@/src/RoutePath';
import { Redirect } from 'react-router-dom';
import { NoMatch } from '@/src/components/common/NoMatch/NoMatch';

export interface LSEContextValue {
  currentLSE: LSEMessage | undefined;
  loadCurrentLSE: () => void;
  createLSE: (lseMessage: LSEMessage) => void;
  updateLSE: (lseMessage: LSEMessage) => void;
  deactivateLSE: () => void;
  loading: boolean;
}

const LSEContext = React.createContext<LSEContextValue>({
  currentLSE: undefined,
  loadCurrentLSE: () => {
    // do nothing
  },
  createLSE: (_lseMessage: LSEMessage) => {
    // do nothing
  },
  updateLSE: (_lseMessage: LSEMessage) => {
    // do nothing
  },
  deactivateLSE: () => {
    // do nothing
  },
  loading: false,
});

const LSEProvider: React.FC = ({ children }) => {
  const [currentLSE, setCurrentLSE] = useState<LSEMessage | undefined>(undefined);
  const { lseApi } = useApi();
  const [caughtError, setCaughtError] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadCurrentLSE = () => {
    setLoading(true);
    lseApi
      .getLSEMessage()
      .then((res) => {
        setLoading(false);
        if (res) {
          setCurrentLSE(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCaughtError(true);
        preProdLogger('Error retrieving LSE Message', err.message);
      });
  };

  const createLSE = (lseMessage: LSEMessage) => {
    setLoading(true);
    if (lseMessage.text) {
      lseApi
        .createLSE(lseMessage)
        .then(() => {
          setLoading(false);
          loadCurrentLSE();
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger('Error creating LSE', err.message);
        });
    }
  };

  const updateLSE = (lseMessage: LSEMessage) => {
    setLoading(true);
    if (lseMessage.text) {
      lseApi
        .updateLSE(lseMessage)
        .then(() => {
          setLoading(false);
          setCurrentLSE(undefined);
          loadCurrentLSE();
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger('Error creating LSE', err.message);
        });
    }
  };

  const deactivateLSE = () => {
    setLoading(true);
    if (currentLSE) {
      lseApi
        .deactivateLSE()
        .then(() => {
          setLoading(false);
          setCurrentLSE(undefined);
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger('Error deactivating LSE', err.message);
        });
    }
  };

  const data: LSEContextValue = {
    currentLSE,
    loadCurrentLSE,
    createLSE,
    updateLSE,
    deactivateLSE,
    loading,
  };

  if (caughtError) {
    <Redirect to={{ pathname: RoutePath.JAM_500 }} />;
    return <NoMatch path={RoutePath.JAM_500} />;
  }
  return <LSEContext.Provider value={data}>{children}</LSEContext.Provider>;
};

const useLSE = () => {
  const context = useContext(LSEContext);
  if (context === undefined) {
    throw new Error('useLSEs can only be used inside LSEProvider');
  }
  return context;
};

export { LSEProvider, useLSE };
