import {MapLocations} from "./map-locations";

export const MapLocationOrder = [
  MapLocations.WestCoast,
  MapLocations.Virginia,
  MapLocations.Canada,
  MapLocations.Brazil,
  MapLocations.Ireland,
  MapLocations.London,
  MapLocations.India,
  MapLocations.Singapore,
  MapLocations.China,
  MapLocations.Seoul,
  MapLocations.Tokyo,
  MapLocations.Australia,
  MapLocations.Stockholm,
  MapLocations.RandomAfrica,
  MapLocations.Russia,
  MapLocations.SouthAmerica,
  MapLocations.SouthAfrica,
  MapLocations.Alaska,
  MapLocations.Mongolia,
  MapLocations.NorthAfrica,
];