/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from 'i18next';
import { GAME_API_PREFIX } from '../constants/api';
import { ApiClient } from './ApiClient';
import { JamEventDataResponse, JamParticipantEvent } from '../types/JamEvent';
import { LeaderboardResponse, LeaderboardTeamView } from '../types/JamLeaderboard';
import { i18nKeys } from '../utils/i18n.utils';

interface EventPath {
  path: string;
  emailVerificationRequired: boolean;
  skipEmailVerification: boolean;
}

export class ParticipantEventAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * check event exists and get its path
   *
   * @param eventCode string
   */
  public async getEventPathByEventCode(eventCode: string): Promise<EventPath> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/event/path`,
      params: { eventCode },
    })) as Promise<EventPath>;
  }

  public async getEventByName(eventName: string): Promise<JamEventDataResponse> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/event/${eventName}/load`,
      errorHandled: true,
      responseMapper: JamEventDataResponse.fromPlainObject,
    })) as Promise<JamEventDataResponse>;
  }

  public async getEventById(id: string): Promise<JamEventDataResponse> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/event/${id}/load`,
      errorHandled: true,
      responseMapper: JamEventDataResponse.fromPlainObject,
    })) as Promise<JamEventDataResponse>;
  }

  public async getEventLeaderboard(
    eventName: string,
    params: { offset: number; limit: number }
  ): Promise<LeaderboardResponse> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/event/${eventName}/leaderboard`,
      failMessage: this.t(i18nKeys.jamDashboard.leaderboard.errors.failedLeaderboard),
      params,
    })) as Promise<LeaderboardResponse>;
  }

  public async getEventLeaderboardTeamView(eventName: string, teamId: string): Promise<LeaderboardTeamView> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/event/${eventName}/leaderboard/teamView/${teamId}`,
      failMessage: this.t(i18nKeys.jamDashboard.leaderboard.errors.failedLeaderboard),
      responseMapper: LeaderboardTeamView.fromPlainObject,
    })) as Promise<LeaderboardTeamView>;
  }

  public async getParticipantEvent(eventName: string, silent = false): Promise<JamParticipantEvent> {
    return (await this.apiClient.get({
      path: `${GAME_API_PREFIX}/event/${eventName}/participant`,
      responseMapper: JamParticipantEvent.fromPlainObject,
      failMessage: this.t(i18nKeys.jamMessages.errors.failedUserData),
      silent,
    })) as Promise<JamParticipantEvent>;
  }
}
