import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Checkbox,
  CollectionPreferencesProps,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../store/api.context';
import { Campaign, CampaignGroup, CampaignParticipant } from '../../../../types/Campaign';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { paginationLabels } from '../../../../utils/table.utils';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { TableEmptyState } from '../../../common/TableEmptyState';
import { TableHeader } from '../../../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './participants-list.config';

interface ParticipantListProps {
  campaign: Campaign;
  campaignGroup: CampaignGroup;
}

export const ParticipantList: React.FC<ParticipantListProps> = ({ campaign, campaignGroup }) => {
  const { t } = useTranslation();
  const [participants, setParticipants] = useState<CampaignParticipant[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<CampaignParticipant[]>([]);
  const [filteredParticipants, setFilteredParticipants] = useState<CampaignParticipant[]>([]);
  const [excludeInvitedParticipants, setExcludeInvitedParticipants] = useState(false);
  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 50,
  });
  const { campaignApi } = useApi();
  const [confirmRemoveParticipantModalVisible, setConfirmRemoveParticipantModalVisible] = useState(false);
  const [confirmSendInvitationModalVisible, setConfirmSendInvitationModalVisible] = useState(false);
  const [confirmSendInvitationsModalVisible, setConfirmSendInvitationsModalVisible] = useState(false);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    filteredParticipants || [],
    {
      filtering: {
        filteringFunction,
        empty: <TableEmptyState title={t(i18nKeys.campaigns.labels.groups.noParticipantsFound)} />,
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const handleSendInvitation = () => {
    setLoading(true);
    if (campaign.id && campaignGroup.id && selectedParticipant[0].email) {
      campaignApi
        .sendParticipantInvite(campaign.id, campaignGroup.id, selectedParticipant[0].email)
        .then(async () => {
          await getParticipants();
          setConfirmSendInvitationModalVisible(false);
        })
        .catch((err) => {
          preProdLogger('Error sending individual invitation', err.message);
          setConfirmSendInvitationModalVisible(false);
          setLoading(false);
        });
    }
  };

  const handleSendInvitations = () => {
    setLoading(true);
    if (campaign.id && campaignGroup.id && filteredParticipants.length > 0) {
      campaignApi
        .sendInvitesToGroup(campaign.id, campaignGroup.id, excludeInvitedParticipants)
        .then(async () => {
          await getParticipants();
          setConfirmSendInvitationsModalVisible(false);
        })
        .catch((err) => {
          preProdLogger('Error sending out invitations', err.message);
          setConfirmSendInvitationsModalVisible(false);
          setLoading(false);
        });
    }
  };

  const handleRemoveParticipant = () => {
    setLoading(true);
    if (campaign.id && campaignGroup.id && selectedParticipant[0].email) {
      campaignApi
        .deleteCampaignGroupParticipants(campaign.id, campaignGroup.id, [selectedParticipant[0].email])
        .then(async () => {
          await getParticipants();
          setConfirmRemoveParticipantModalVisible(false);
        })
        .catch((err) => {
          preProdLogger('Error deleting participant', err.message);
          setConfirmRemoveParticipantModalVisible(false);
          setLoading(false);
        });
    }
  };

  const getParticipants = async () => {
    if (campaign.id && campaignGroup.id) {
      await campaignApi
        .getCampaignGroupParticipants(campaign.id, campaignGroup.id)
        .then((res) => {
          setParticipants(res);
          setSelectedParticipant([]);
          setConfirmRemoveParticipantModalVisible(false);
          setLoading(false);
        })
        .catch((err) => {
          preProdLogger('Error getting campaign group participants', err.message);
          setConfirmRemoveParticipantModalVisible(false);
          setLoading(false);
        });
    }
  };

  useComponentDidMountEffect(async () => {
    setLoading(true);
    if (campaign.id && campaignGroup.id) {
      await getParticipants();
    }
  });

  useEffect(() => {
    setLoading(true);
    if (campaign.id && campaignGroup.id) {
      getParticipants()
        .then(() => {
          preProdLogger('Updated Participants');
        })
        .catch((err) => {
          preProdLogger('Error updating participants', err.message);
        });
    }
  }, [campaignGroup]);

  const filterParticipants = () => {
    if (participants) {
      let newFilteredParticipants = [];
      if (excludeInvitedParticipants) {
        newFilteredParticipants = participants.filter((p) => p.numInvitesSent < 1);
      } else {
        newFilteredParticipants = participants;
      }
      setFilteredParticipants(newFilteredParticipants);
    }
  };

  useEffect(() => {
    filterParticipants();
  }, [participants, excludeInvitedParticipants]);

  return (
    <React.Fragment>
      <ConfirmModal
        visible={confirmRemoveParticipantModalVisible}
        title={t(i18nKeys.campaigns.modals.removeParticipant.title)}
        confirmBtnLabel={t(i18nKeys.campaigns.modals.removeParticipant.confirmButton)}
        onConfirm={() => handleRemoveParticipant()}
        disabled={loading}
        onCancel={() => setConfirmRemoveParticipantModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmSendInvitationModalVisible}
        title={t(i18nKeys.campaigns.modals.sendInvitation.title)}
        confirmBtnLabel={t(i18nKeys.campaigns.modals.sendInvitation.confirmButton)}
        disabled={loading}
        onConfirm={() => handleSendInvitation()}
        onCancel={() => setConfirmSendInvitationModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmSendInvitationsModalVisible}
        title={t(i18nKeys.campaigns.modals.sendInvitations.title)}
        message={
          <React.Fragment>
            <div>
              {t(i18nKeys.campaigns.modals.sendInvitations.message, {
                numberOfParticipants: filteredParticipants.length,
              })}
            </div>
            <ul>
              {filteredParticipants.map((participant, i) => {
                return <li key={`participant-email-${i}`}>{participant.email}</li>;
              })}
            </ul>
          </React.Fragment>
        }
        onConfirm={() => handleSendInvitations()}
        onCancel={() => setConfirmSendInvitationsModalVisible(false)}
        confirmBtnLabel={t(i18nKeys.campaigns.modals.sendInvitations.confirmButton)}
      />
      <Table
        className="mb-12"
        {...collectionProps}
        header={
          <TableHeader
            title={t(i18nKeys.campaigns.headers.groups.participants)}
            totalItems={participants.length}
            actionButtons={
              <SpaceBetween direction="horizontal" size="s">
                <Button
                  disabled={filteredParticipants.length < 1}
                  onClick={() => setConfirmSendInvitationsModalVisible(true)}>
                  {t(i18nKeys.campaigns.buttons.sendInvitations, { numberOfInvitations: filteredParticipants.length })}
                </Button>
                <Button
                  disabled={selectedParticipant.length < 1}
                  onClick={() => setConfirmSendInvitationModalVisible(true)}>
                  {t(i18nKeys.campaigns.buttons.sendInvitation)}
                </Button>
                <Button
                  disabled={selectedParticipant.length < 1}
                  onClick={() => setConfirmRemoveParticipantModalVisible(true)}>
                  {t(i18nKeys.campaigns.buttons.removeParticipant)}
                </Button>
              </SpaceBetween>
            }
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
        items={items}
        loading={loading}
        selectionType="single"
        selectedItems={selectedParticipant}
        onSelectionChange={({ detail }) => setSelectedParticipant(detail.selectedItems)}
        filter={
          <SpaceBetween direction="horizontal" size="s">
            <TextFilter
              className="text-filter"
              filteringPlaceholder={t(i18nKeys.campaigns.labels.groups.searchParticipants)}
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.events.filteringLabel)}
            />
            <Checkbox
              className="mt-4"
              checked={excludeInvitedParticipants}
              onChange={() => setExcludeInvitedParticipants(!excludeInvitedParticipants)}>
              {t(i18nKeys.campaigns.buttons.excludeInvitedParticipants)}
            </Checkbox>
          </SpaceBetween>
        }
        columnDefinitions={COLUMN_DEFINITIONS(preferences)}
      />
    </React.Fragment>
  );
};
