import {
  Badge,
  Button,
  Container,
  FormField,
  Header,
  Icon,
  Popover,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { ChallengeReviewableSection, ChallengeReviewStatus, ChallengeUtils } from '../../../types/Challenge';
import { useChallenges } from '../../../store/challenge.context';
import { getReviewStatus } from '../challengeDetailSections/challengeRevisions/revision-list-config';
import { TFunctionProvider } from '../../common/TFunctionProvider';
import { i18nKeys } from '../../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';

export interface ChallengeReviewPopoverProps {
  section: ChallengeReviewableSection;
}

export const ChallengeReviewPopover: React.FC<ChallengeReviewPopoverProps> = ({ section }) => {
  const { t } = useTranslation();
  const COMMENT_MIN_LENGTH = 2;
  const COMMENT_MAX_LENGTH = 1200;
  const commentPlaceholder = t(i18nKeys.challenges.challengeDetails.popover.commentPlaceholder);
  const commentConstraint = t(i18nKeys.challenges.challengeDetails.popover.commentConstraint, {
    minLength: COMMENT_MIN_LENGTH,
    maxLength: COMMENT_MAX_LENGTH,
  });
  const { currentReview, reviewMode, updateReview } = useChallenges();
  const sectionFeedback = ChallengeUtils.getSectionFeedback(currentReview?.challengeReviewFeedback?.feedback, section);
  const [comment, setComment] = useState<string>(sectionFeedback?.comment || '');
  const isRequired = sectionFeedback?.required || false;
  const isComplete =
    sectionFeedback?.status === ChallengeReviewStatus.APPROVED ||
    sectionFeedback?.status === ChallengeReviewStatus.NEEDS_WORK;
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingNeedsWork, setLoadingNeedsWork] = useState(false);
  const [sectionFeedbackStatus, setSectionFeedbackStatus] = useState(
    sectionFeedback?.status || ChallengeReviewStatus.READY_FOR_REVIEW
  );
  const [commentErrorText, setCommentErrorText] = useState<string>('');

  if (!reviewMode) {
    return <></>;
  }

  const isValidComment = () => {
    if (!comment || comment === '') {
      setCommentErrorText(t(i18nKeys.challenges.challengeDetails.popover.commentRequiredError));
      return false;
    } else if (!comment || comment.length < 2 || comment.length > 1200) {
      setCommentErrorText(t(i18nKeys.challenges.challengeDetails.popover.commentInvalidError));
      return false;
    }
    setCommentErrorText('');
    return true;
  };

  const handlePositiveFeedback = () => {
    if (!isValidComment()) {
      return;
    }
    setLoadingApprove(true);
    const status = ChallengeReviewStatus.APPROVED;
    void updateReview(comment, section, status);
    setLoadingApprove(false);
    setSectionFeedbackStatus(status);
  };

  const handleNegativeFeedback = () => {
    if (!isValidComment()) {
      return;
    }
    setLoadingNeedsWork(true);
    const status = ChallengeReviewStatus.NEEDS_WORK;
    void updateReview(comment, section, status);
    setLoadingNeedsWork(false);
    setSectionFeedbackStatus(status);
  };

  return (
    <SpaceBetween size={'xxl'} direction={'horizontal'}>
      <Popover
        dismissButton={false}
        fixedWidth
        position="bottom"
        size="large"
        triggerType="custom"
        content={
          <Container
            header={
              <Header variant={'h2'}>
                <TFunctionProvider>{(tr) => tr(i18nKeys.challenges.challengeDetails.popover.header)}</TFunctionProvider>
              </Header>
            }
            footer={
              <SpaceBetween size={'s'} direction={'horizontal'}>
                <SpaceBetween size={'xs'} direction={'horizontal'}>
                  <Button onClick={handlePositiveFeedback} loading={loadingApprove}>
                    <Icon name={'status-positive'} variant={'success'} />
                    <span style={{ paddingLeft: '8px' }}>
                      <TFunctionProvider>
                        {(tr) => tr(i18nKeys.challenges.challengeDetails.buttons.approve)}
                      </TFunctionProvider>
                    </span>
                  </Button>
                </SpaceBetween>
                <SpaceBetween size={'xs'} direction={'horizontal'}>
                  <Button onClick={handleNegativeFeedback} loading={loadingNeedsWork}>
                    <Icon name={'status-negative'} variant={'error'} />
                    <span style={{ paddingLeft: '8px' }}>
                      <TFunctionProvider>
                        {(tr) => tr(i18nKeys.challenges.challengeDetails.buttons.needsWork)}
                      </TFunctionProvider>
                    </span>
                  </Button>
                </SpaceBetween>
              </SpaceBetween>
            }>
            <FormField constraintText={commentConstraint} errorText={commentErrorText}>
              <Textarea
                rows={10}
                value={comment}
                placeholder={commentPlaceholder}
                onChange={(event) => {
                  if (commentErrorText !== '') {
                    setCommentErrorText('');
                  }
                  setComment(event.detail.value);
                }}
              />
            </FormField>
          </Container>
        }>
        <SpaceBetween direction={'horizontal'} size={'m'}>
          <div style={{ marginTop: '5px' }}>{getReviewStatus(sectionFeedbackStatus)}</div>
          <Button
            key={'review-button'}
            variant={'normal'}
            onClick={() => {
              setSectionFeedbackStatus(ChallengeReviewStatus.IN_REVIEW);
            }}>
            {isComplete ? (
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.challenges.challengeDetails.buttons.editReview)}
              </TFunctionProvider>
            ) : (
              <TFunctionProvider>
                {(tr) => tr(i18nKeys.challenges.challengeDetails.buttons.reviewNow)}
              </TFunctionProvider>
            )}
          </Button>
          {!isComplete && isRequired && (
            <div style={{ marginTop: '5px' }}>
              <Badge color={'red'}>
                <TFunctionProvider>
                  {(tr) => tr(i18nKeys.challenges.challengeDetails.popover.required)}
                </TFunctionProvider>
              </Badge>
            </div>
          )}
        </SpaceBetween>
      </Popover>
    </SpaceBetween>
  );
};
