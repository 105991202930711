import React from 'react';
import { BORKMarkdown } from '@amzn/bork-markdown';

import '@amzn/bork-markdown/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { useTheme } from '@/src/store/theme.context';

interface BORKMarkdownRendererProps {
  markdown: string;
}

const BORKMarkdownRenderer: React.FC<BORKMarkdownRendererProps> = ({ markdown }) => {
  const { themeMode } = useTheme();

  return <BORKMarkdown markdown={markdown} padding={false} theme={themeMode} />;
};

export default BORKMarkdownRenderer;
