import { jsonMember, jsonObject } from 'typedjson';
import * as common from './common';

export interface LSEMessage {
  text: common.NullableString;
  url?: common.NullableString;
}

@jsonObject
export class LSEMessage {
  @jsonMember(common.NullableStringValue)
  text: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  url?: common.NullableString | undefined = undefined;
}

@jsonObject
export class LSEResponse {
  @jsonMember(LSEMessage)
  message?: LSEMessage = undefined;
}

export interface CreateLSERequest {
  readonly message: LSEMessage;
}

export interface UpdateLSERequest {
  readonly message: LSEMessage;
}
