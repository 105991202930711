import { SECONDS_PER_MINUTE } from '../constants/shared/time';
import { MILLIS_PER_SECOND, SECONDS_PER_DAY, SECONDS_PER_HOUR } from '../constants/DateTimeConstants';
import { NullableNumber } from '../types/common';
import moment from 'moment-timezone';
import { isEmpty } from './string.utils';
import { roundFloat } from './number.utils';

export const getDuration = (seconds: NullableNumber, includeSeconds = true) => {
  if (seconds) {
    const days = Math.floor(seconds / SECONDS_PER_DAY);
    seconds -= days * SECONDS_PER_DAY;
    const hours = Math.floor(seconds / SECONDS_PER_HOUR);
    seconds -= hours * SECONDS_PER_HOUR;
    let minutes = Math.floor(seconds / SECONDS_PER_MINUTE);
    seconds -= minutes * SECONDS_PER_MINUTE;
    if (!includeSeconds && seconds >= 30) {
      minutes += 1;
    }
    let duration = '';
    duration += days ? `${days}d ` : '';
    duration += hours ? `${hours}h ` : '';
    duration += minutes ? `${minutes}m ` : '';
    if (includeSeconds) {
      duration += seconds ? `${seconds}s` : '';
    }
    return duration;
  } else {
    return null;
  }
};

export const millisecondsToSeconds = (milliseconds: number) => {
  return milliseconds / MILLIS_PER_SECOND;
};

export const getDisplayDuration = (milliseconds: number) => {
  return getDuration(roundFloat(millisecondsToSeconds(milliseconds), 0));
};

export const timeAgo = (value: string | number) => {
  const d = typeof value === 'string' ? new Date(value) : new Date(value);
  const now = new Date();
  const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
  const minutes = Math.round(Math.abs(seconds / 60));
  const hours = Math.round(Math.abs(minutes / 60));
  const days = Math.round(Math.abs(hours / 24));
  const months = Math.round(Math.abs(days / 30.416));
  const years = Math.round(Math.abs(days / 365));
  if (Number.isNaN(seconds)) {
    return '';
  } else if (seconds <= 45) {
    return 'a few seconds ago';
  } else if (seconds <= 90) {
    return 'a minute ago';
  } else if (minutes <= 45) {
    return `${minutes} minutes ago`;
  } else if (minutes <= 90) {
    return 'an hour ago';
  } else if (hours <= 22) {
    return `${hours} hours ago`;
  } else if (hours <= 36) {
    return 'a day ago';
  } else if (days <= 25) {
    return `${days} days ago`;
  } else if (days <= 45) {
    return 'a month ago';
  } else if (days <= 345) {
    return `${months} months ago`;
  } else if (days <= 545) {
    return 'a year ago';
  } else {
    // (days > 545)
    return `${years} years ago`;
  }
};

export const timeUntil = (value: string | number) => {
  const d = typeof value === 'string' ? new Date(value) : new Date(value);
  const now = new Date();
  const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
  const minutes = Math.round(Math.abs(seconds / 60));
  const hours = Math.round(Math.abs(minutes / 60));
  const days = Math.round(Math.abs(hours / 24));
  const months = Math.round(Math.abs(days / 30.416));
  const years = Math.round(Math.abs(days / 365));
  if (Number.isNaN(seconds)) {
    return '';
  } else if (seconds <= 45) {
    return 'a few seconds from now';
  } else if (seconds <= 90) {
    return 'a minute from now';
  } else if (minutes <= 45) {
    return `${minutes} minutes from now`;
  } else if (minutes <= 90) {
    return 'an hour from now';
  } else if (hours <= 22) {
    return `${hours} hours from now`;
  } else if (hours <= 36) {
    return 'a day from now';
  } else if (days <= 25) {
    return `${days} days from now`;
  } else if (days <= 45) {
    return 'a month from now';
  } else if (days <= 345) {
    return `${months} months from now`;
  } else if (days <= 545) {
    return 'a year from now';
  } else {
    // (days > 545)
    return `${years} years from now`;
  }
};

/**
 * Returns true if the provided date string is in a valid format, otherwise false.
 * Valid formats are documented at https://momentjs.com/docs/#/parsing/string/
 */
export const isValidDateString = (dateString: string | null) => {
  if (isEmpty(dateString)) {
    return false;
  }
  return moment(dateString).isValid();
};

/**
 * Returns date in dd Mon YYYY format. For e.g. 01 Oct 2022
 *
 * @param date
 */
export const getFormattedDate = (date: Date, languageCode = 'en-gb') =>
  date.toLocaleDateString(languageCode, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

export const getTimelineFormattedDate = (date: Date) => moment(date).format('MM/DD/YYYY hh:mm A');
export const getRevisionFormattedDate = (date: Date) => moment(date).format('LLL');

/**
 *
 * @param date string | null
 * @returns boolean
 *
 * check if the given string is valid date
 * and it is in ISO format.
 */
export const isValidISODate = (date: string | null) =>
  isEmpty(date) ? false : moment(date, moment.ISO_8601).isValid();

/**
 *
 * @param date string | null
 * @param format valid date format (optional)
 * @returns string formatted date string
 *
 * it verifies the string to be valid
 * ISO date and returns string in
 * valid date format
 */
export const getValidatedFormattedDate = (date: string | null, format = 'MM/DD/YYYY') => {
  if (isValidISODate(date)) {
    return moment(date).format(format);
  }
  return date;
};
