/* eslint-disable */
import { parse } from 'url';

export enum RouteType {
  LegacyOnly = 'LegacyOnly',
  PolarisOnly = 'PolarisOnly',
  Both = 'Both',
}

/**
 * Path prefixes for consoles hosted in the hybrid console.
 */
export enum HybridConsolePrefix {
  /**
   * Prefix for the legacy console.
   */
  Legacy = '/v1',

  /**
   * Prefix for the Polaris console.
   */
  Polaris = '/v2',
}

export const CONSOLE_PREFIXES: HybridConsolePrefix[] = Object.values(HybridConsolePrefix);

export enum ConsolePreference {
  /**
   * Opt the user into the new experience.
   * In this mode, the console will use the new UI for every page that supports it, and the old UI otherwise.
   */
  Polaris = 'Polaris',
  /**
   * Opt the user out of the new experience. This is the default mode.
   * In this mode, the console will use the old UI for every page that supports it, and the new UI otherwise.
   */
  Legacy = 'Legacy',
}

export enum LocalStorageKey {
  /**
   * Key storing the user's console preference.
   */
  ConsolePreference = 'ConsolePreference',
  /**
   * Key storing whether the user acknowledged the banner about the new console.
   */
  AcknowledgedNewConsoleBanner = 'AcknowledgedNewConsoleBanner',
}

/**
 * Path without the console prefix.
 */
export class NormalizedPath {
  public readonly consolePrefix: HybridConsolePrefix | undefined = undefined;

  private normalizedPath: string;

  constructor(path: string) {
    // SIM : https://issues.amazon.com/issues/JAM-10217 (Yugank : 3rd point)
    // As of now we comment below line because redirection issue but we will remove it if we got build issue in future
    // path = path.toLowerCase();

    const prefixMatch = new RegExp(`^(${HybridConsolePrefix.Legacy}|${HybridConsolePrefix.Polaris})(/.*)?$`).exec(path);

    if (prefixMatch != null) {
      this.consolePrefix = prefixMatch[1] as HybridConsolePrefix;
      path = prefixMatch[2] ?? '';
    }

    const pathname = parse(path ?? '').pathname ?? '';

    this.normalizedPath =
      '/' +
      pathname
        .trim()
        .split('/')
        .map((subPath) => subPath.replace('/', ''))
        .map((subPath) => subPath.trim())
        .filter((subPath) => !!subPath)
        .join('/');
  }

  public asString(): string {
    return this.normalizedPath;
  }

  public withPrefix(prefix: HybridConsolePrefix): string {
    return `${prefix}${this.asString()}`;
  }
}
