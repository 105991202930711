import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import JamCommonHeader from './molecules/JamCommonHeader';
import ExpandableDetails from './molecules/ExpendableSection';
import { useJamFacilitator } from '@/src/store/jam-facilitator.context';
import ParticipantContainer from './molecules/FacilitatorParticipantContainer';
import { debounce, sum } from 'lodash';
import { LoadingBar } from '../../common/LoadingBar';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import OpenEndedPagination from './molecules/OpenEndedPagination';
import { useApi } from '@/src/store/api.context';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useFlashbars } from '@/src/store/flashbar.context';
import { preProdLogger } from '@/src/utils/log.utils';
import { JamFacilitatorTeamsAvailability } from '@/src/types/JamFacilitator';
import './FacilitatorParticipants.scss';
import { JamEventTeamAssignmentType } from '@/src/types/JamCommon';

const FacilitatorParticipants = () => {
  const { t } = useTranslation();
  const { jamFacilitatorApi } = useApi();
  const { eventName, event } = useJamEventDetails();
  const { addErrorFlashbar } = useFlashbars();
  const {
    loadJamFacilitatorTeamsAvailabilityData,
    loadJamFacilitatorParticipantsStats,
    loadJamFacilitatorNoTeamParticipants,
    loading,
    setLoading,
    pageSize,
    setPageSize,
    teams,
    notAssignedToTeam,
    facilitators,
    setSearchTerm,
    facilitatorParticipantsStats,
    pageTokens,
    loadParticpiantsByTeam,
    currentPage,
    setCurrentPage,
  } = useJamFacilitator();
  const [search, setSearch] = React.useState('');
  const [refreshing, setRefreshing] = React.useState(false);
  const [fetchingTeamName, setFetchingTeamName] = React.useState<string>('');
  const [showAutoAssignModal, setShowAutoAssignModal] = useState<boolean>(false);
  const [autoAssignStrategy, setShowAutoAssignStrategy] = useState<OptionDefinition | null>(null);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const pageCounts = Object.values(pageTokens).length;

  const pageSizeOptions = useMemo(
    () => [
      { label: t(i18nKeys.facilitator.participants.teamSizeDropdown.teamsPerPage10), value: '10' },
      { label: t(i18nKeys.facilitator.participants.teamSizeDropdown.teamsPerPage20), value: '20' },
      { label: t(i18nKeys.facilitator.participants.teamSizeDropdown.teamsPerPage50), value: '50' },
      { label: t(i18nKeys.facilitator.participants.teamSizeDropdown.teamsPerPage100), value: '100' },
    ],
    [t]
  );

  const onTeamToggle = (expanded: boolean, teamId: string) => {
    if (!expanded) {
      return;
    }
    setFetchingTeamName(teamId);
    void loadParticpiantsByTeam(eventName, teamId).finally(() => setFetchingTeamName(''));
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    // to trigger useEffect in case currentPage = 1
    // first change currentPage to 0 which will not call
    // any api
    setCurrentPage(0);
    setTimeout(setCurrentPage, 1000, 1);
  }, []);

  const handleSearchParticipants = (value: string) => {
    setSearch(value);
    debouncedSearch(value);
  };

  const debouncedSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  const getTeamTitle = (team: JamFacilitatorTeamsAvailability) => {
    if (!team) return '';
    const { displayName, name } = team;
    if (displayName !== name) {
      return (
        <>
          {displayName} <span className="original-team">(original name: {name})</span>
        </>
      );
    }
    return displayName;
  };

  const initialize = async () => {
    try {
      setIsPageLoading(true);
      const p1 = loadJamFacilitatorParticipantsStats(eventName);
      const p2 = loadJamFacilitatorNoTeamParticipants(eventName);
      const p3 = loadJamFacilitatorTeamsAvailabilityData(eventName, currentPage);
      await Promise.allSettled([p1, p2, p3]);
    } catch (err) {
      preProdLogger(err);
      // error
    } finally {
      setRefreshing(false);
      setIsPageLoading(false);
    }
  };

  const handleAutoAssignButtonClick = useCallback(() => {
    setShowAutoAssignModal(true);
  }, []);

  const handleAutoAssignModalDismiss = useCallback(() => {
    setShowAutoAssignModal(false);
  }, []);

  const handleAutoAssign = useCallback(async () => {
    if (autoAssignStrategy === null || autoAssignStrategy.value === undefined) {
      addErrorFlashbar(t(i18nKeys.facilitator.participants.errors.emptyAutoAssignTeams));
      return;
    }
    try {
      await jamFacilitatorApi.autoAssignTeams(eventName, parseInt(autoAssignStrategy.value, 10));
      setRefreshing(true);
      setTimeout(onRefresh, 3125);
    } catch (err) {
      preProdLogger('Failed to autoassign participants to teams', err);
    } finally {
      handleAutoAssignModalDismiss();
    }
  }, [t, eventName, onRefresh, addErrorFlashbar, autoAssignStrategy, jamFacilitatorApi]);

  useEffect(() => {
    if (eventName && currentPage) {
      void initialize();
    }
  }, [eventName, pageSize, currentPage]);

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <div className="facilitator-participants">
      <ContentLayout
        header={
          <SpaceBetween direction="vertical" size="xs">
            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
              <Header variant="h1">{t(i18nKeys.facilitator.participants.title)}</Header>
              <Box float="right">
                <SpaceBetween direction="horizontal" size="m">
                  <Button
                    loading={refreshing}
                    variant="normal"
                    ariaLabel="Refresh"
                    iconName="refresh"
                    onClick={() => void onRefresh()}>
                    {t(i18nKeys.facilitator.participants.buttons.refresh)}
                  </Button>
                  {event?.teamAssignmentType === JamEventTeamAssignmentType.SKILL_BASED &&
                    notAssignedToTeam &&
                    notAssignedToTeam?.length > 0 && (
                      <Button
                        variant="primary"
                        ariaLabel={t(i18nKeys.facilitator.participants.buttons.autoAssign)}
                        onClick={handleAutoAssignButtonClick}>
                        {t(i18nKeys.facilitator.participants.buttons.autoAssign)}
                      </Button>
                    )}
                </SpaceBetween>
              </Box>
            </Grid>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 2 }]}>
              <Input
                onChange={({ detail }) => handleSearchParticipants(detail.value)}
                value={search}
                placeholder={t(i18nKeys.facilitator.participants.label.searchForTeam)}
                type="search"
              />
              <Select
                selectedOption={pageSizeOptions.find((option) => option.value === `${pageSize}`) || null}
                onChange={({ detail }) => setPageSize(parseInt(detail.selectedOption?.value as string, 10))}
                options={pageSizeOptions}
              />
            </Grid>
          </SpaceBetween>
        }>
        <SpaceBetween direction="vertical" size="l">
          <JamCommonHeader
            participants={facilitatorParticipantsStats?.totalParticipants ?? 0}
            teams={facilitatorParticipantsStats?.totalTeams ?? 0}
            facilitators={facilitatorParticipantsStats?.totalFacilitators ?? 0}
          />

          { refreshing && <Spinner />}

          {!refreshing && notAssignedToTeam && notAssignedToTeam?.length > 0 && (
            <ExpandableDetails
              header={t(i18nKeys.facilitator.participants.label.notAssignedToTeam)}
              counter={`(${notAssignedToTeam.length})`}
              enableActions={false}
              enableViewProgress={false}>
              <ColumnLayout columns={2}>
                {notAssignedToTeam?.map((participant, i) => (
                  <ParticipantContainer key={i} participant={participant} setLoading={setLoading} />
                ))}
              </ColumnLayout>
            </ExpandableDetails>
          )}

          {!refreshing && facilitators && facilitators?.length > 0 && (
            <ExpandableDetails
              header={t(i18nKeys.facilitator.participants.header.facilitators)}
              counter={`(${facilitators.length})`}
              enableActions={false}
              enableViewProgress={false}>
              <ColumnLayout columns={2}>
                {facilitators?.map((participant, i) => (
                  <ParticipantContainer key={i} participant={participant} setLoading={setLoading} />
                ))}
              </ColumnLayout>
            </ExpandableDetails>
          )}

          {teams && teams.length > 0 && (
            <SpaceBetween size="m" direction="vertical">
              <SpaceBetween direction="horizontal" size="xs">
                <Box variant="h1">{t(i18nKeys.facilitator.participants.header.teams)}</Box>
                <Box variant="h1" color="text-status-inactive">
                  ({facilitatorParticipantsStats?.totalTeams ?? 0})
                </Box>
              </SpaceBetween>
              {isPageLoading && (
                <Container>
                  <Spinner />
                </Container>
              )}
              {!isPageLoading &&
                teams.map((team, teamIndex) => {
                  const teamRank = Array.isArray(team?.members)
                    ? sum(team.members?.map((item) => item.skillRank ?? 0)) / team.members?.length
                    : 0;
                  return (
                    <ExpandableDetails
                      key={teamIndex}
                      header={getTeamTitle(team)}
                      counter={team.facilitatorOnly ? '' : `(${teams.length ?? 0})`}
                      teamInfo={team}
                      teamRank={teamRank}
                      enableActions={!team.facilitatorOnly}
                      onSectionToggle={(toggle: boolean) => void onTeamToggle(toggle, team.name)}>
                      <Box margin={{ bottom: 's' }}>
                        <SpaceBetween direction="horizontal" size="xs">
                          {team?.goal && (
                            <Badge color="green">{t(i18nKeys.joinTeam.form.commonFields[team.goal])}</Badge>
                          )}
                          {team?.passwordProtected && (
                            <>
                              <span role="img" aria-label={t(i18nKeys.facilitator.participants.label.passwordProtected)}>
                                <Icon name="key" />
                              </span>
                              <Box variant="span" margin={{ left: 'xxs' }} aria-hidden>
                                {t(i18nKeys.facilitator.participants.label.passwordProtected)}
                              </Box>
                            </>
                          )}
                        </SpaceBetween>
                      </Box>
                      {fetchingTeamName === team.name && !team?.members?.length && <Spinner />}
                      <ColumnLayout columns={2}>
                        {team?.members?.map((participant, i) => (
                          <ParticipantContainer key={i} participant={participant} setLoading={setLoading} team={team} />
                        ))}
                      </ColumnLayout>
                    </ExpandableDetails>
                  );
                })}
              <OpenEndedPagination
                currentPage={currentPage}
                onPageChange={(newPage) => setCurrentPage(newPage)}
                openEnded={pageCounts !== Math.ceil((facilitatorParticipantsStats?.totalTeams || 0) / pageSize)}
                pageCounts={pageCounts}
              />
            </SpaceBetween>
          )}
        </SpaceBetween>
        <Modal
          onDismiss={handleAutoAssignModalDismiss}
          visible={showAutoAssignModal}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={handleAutoAssignModalDismiss}>
                  {t(i18nKeys.general.cancel)}
                </Button>
                <Button variant="primary" data-testid="autoassign-submit" onClick={() => void handleAutoAssign()}>
                  {t(i18nKeys.general.submit)}
                </Button>
              </SpaceBetween>
            </Box>
          }
          header={t(i18nKeys.facilitator.participants.modals.autoAssignModal.title)}>
          <TextContent>
            <p>{t(i18nKeys.facilitator.participants.modals.autoAssignModal.text)}</p>
            <ul>
              <li>{t(i18nKeys.facilitator.participants.modals.autoAssignModal.bullet1)}</li>
              <li>{t(i18nKeys.facilitator.participants.modals.autoAssignModal.bullet2)}</li>
            </ul>
            <Select
              selectedOption={autoAssignStrategy}
              onChange={({ detail }) => setShowAutoAssignStrategy(detail.selectedOption)}
              options={[
                { label: t(i18nKeys.facilitator.participants.modals.autoAssignModal.option1), value: '0' },
                { label: t(i18nKeys.facilitator.participants.modals.autoAssignModal.option2), value: '1' },
              ]}
            />
          </TextContent>
        </Modal>
      </ContentLayout>
    </div>
  );
};

export default FacilitatorParticipants;
