import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import dragula from 'dragula';
import 'dragula/dist/dragula.css';
import './ChallengeOrdering.scss';

import type { ChallengeListItem } from '@/src/types/Challenge';

// molecules
import { ChallengeOrderingTitle, ChallengeOrderingItem } from '@/src/components/ui/molecules/EventTemplate';
import { i18nKeys } from '@/src/utils/i18n.utils';

export type IChallengeOrderingSection = {
  title: string;
  items: ChallengeListItem[];
  onChange: (updatedItems: ChallengeListItem[]) => void;
};

export interface IChallengeOrderingProps {
  alertText: string;
  error?: string;
  primary: IChallengeOrderingSection;
  backup: IChallengeOrderingSection;
  editMode?: boolean;
  handleRemove?: (challengeId: string, isPrimary: boolean) => void;
}

export const ChallengeOrdering: React.FC<IChallengeOrderingProps> = ({
  alertText,
  error,
  primary,
  backup,
  editMode,
  handleRemove,
}) => {
  const { t } = useTranslation();
  const [sortedPrimaryChallenges, setSortedPrimaryChallenges] = useState<ChallengeListItem[]>([]);
  const [sortedBackupChallenges, setSortedBackupChallenges] = useState<ChallengeListItem[]>([]);
  const primaryContainerRef = useRef<HTMLDivElement>(null);
  const backupContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSortedPrimaryChallenges(primary.items);
    setSortedBackupChallenges(backup.items);
  }, []);

  const handleRemovePrimaryChallange = useCallback(
    (challengeId: string) => {
      setSortedPrimaryChallenges([...primary.items.filter((item) => item.id !== challengeId)]);
    },
    [primary.items]
  );

  const handleRemoveBackUpChallange = useCallback(
    (challengeId: string) => {
      setSortedBackupChallenges([...backup.items.filter((item) => item.id !== challengeId)]);
    },
    [backup.items]
  );

  const handleRemoveChallange = (challengeId: string, isPrimary: boolean) => {
    if (isPrimary) handleRemovePrimaryChallange(challengeId);
    else handleRemoveBackUpChallange(challengeId);
    if (handleRemove) handleRemove(challengeId, isPrimary);
  };

  const getChallengeItems = (items: HTMLElement[]) => {
    return items
      .map((item) => {
        const challenge = (item.firstChild as HTMLElement).getAttribute('data-item');
        if (challenge) return JSON.parse(challenge) as ChallengeListItem;
        return undefined;
      })
      .filter((item) => !!item) as ChallengeListItem[];
  };

  const onDropChallengeItem = useCallback(() => {
    const primaryChallengeItems = getChallengeItems(
      Array.from((primaryContainerRef.current as HTMLElement)?.querySelectorAll('.challenge-ordering-listitem'))
    );
    const backupChallengeItems = getChallengeItems(
      Array.from((backupContainerRef.current as HTMLElement)?.querySelectorAll('.challenge-ordering-listitem'))
    );

    primary.onChange(primaryChallengeItems);
    backup.onChange(backupChallengeItems);
  }, [primaryContainerRef.current, backupContainerRef.current, primary.onChange, backup.onChange]);

  useEffect(() => {
    if (primaryContainerRef.current) {
      const container = [primaryContainerRef.current];
      if (backupContainerRef.current) container.push(backupContainerRef.current);
      const drake = dragula(container);
      drake.on('drop', onDropChallengeItem);
    }
  }, []);

  return (
    <div className={`${editMode ? '' : 'challenge-ordering-container'}`}>
      {editMode && <h1>{t(i18nKeys.eventTemplates.step3.title)}</h1>}
      <div className={`${editMode ? '' : 'challenge-alert'}`}>
        <Alert statusIconAriaLabel={t(i18nKeys.general.info)}>{t(alertText)}</Alert>
      </div>
      {error && (
        <Alert statusIconAriaLabel={t(i18nKeys.general.error)} type="error">
          {error}
        </Alert>
      )}
      <>
        <ChallengeOrderingTitle title={primary.title} count={primary.items.length} />
        <div ref={primaryContainerRef} className="challenge-ordering-list">
          {!!sortedPrimaryChallenges.length &&
            sortedPrimaryChallenges?.map((challenge) => (
              <ChallengeOrderingItem
                key={challenge.id}
                item={challenge}
                editMode={editMode}
                isPrimary
                handleRemove={handleRemoveChallange}
              />
            ))}
        </div>
      </>
      <>
        <ChallengeOrderingTitle title={backup.title} count={backup.items.length} />
        <div ref={backupContainerRef} className="challenge-ordering-list">
          {!!sortedBackupChallenges.length &&
            sortedBackupChallenges?.map((challenge) => (
              <ChallengeOrderingItem
                key={challenge.id}
                item={challenge}
                editMode={editMode}
                handleRemove={handleRemoveChallange}
              />
            ))}
        </div>
      </>
    </div>
  );
};
