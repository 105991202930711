import {
  Badge,
  Button,
  Container,
  ExpandableSection,
  Grid,
  Header,
  Icon,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';
import { isEmailListValid, splitDelimitedEmails } from '../../../utils/string.utils';

interface ReportRecipientsProps {
  campaign: Campaign | undefined;
}

const ReportRecipients: React.FC<ReportRecipientsProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { campaignEditMode, handleUpdateEditCampaign, newCampaignMode } = useEditCampaign();
  const [newEmails, setNewEmails] = useState('');

  useEffect(() => {
    if (!campaignEditMode) {
      setNewEmails('');
    }
  }, [campaignEditMode]);

  const removeEmail = (index: number) => {
    const newRecipients = _.clone(campaign?.campaignSettings.reportRecipients) || [];
    newRecipients.splice(index, 1);
    handleUpdateEditCampaign(EditCampaignActions.REPORT_RECIPIENTS, newRecipients);
  };

  const handleAddEmails = () => {
    const emailList: string[] = splitDelimitedEmails(newEmails);
    if (isEmailListValid(emailList)) {
      const newEmailList = emailList.filter((email) => !campaign?.campaignSettings.reportRecipients.includes(email));
      handleUpdateEditCampaign(EditCampaignActions.REPORT_RECIPIENTS, [
        ...newEmailList,
        ...(campaign?.campaignSettings.reportRecipients || []),
      ]);
    }
    setNewEmails('');
  };

  const isValidEmailInput = () => {
    return isEmailListValid(splitDelimitedEmails(newEmails));
  };

  const headerElement = (
    <Header variant="h2" description={t(i18nKeys.campaigns.headers.campaignDetails.descriptions.reportRecipients)}>
      {t(i18nKeys.campaigns.headers.campaignDetails.reportRecipients)}
    </Header>
  );

  const renderContent = () => {
    return !campaignEditMode && !newCampaignMode ? (
      <Grid gridDefinition={[{ colspan: 1 }, { colspan: 8 }]}>
        <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.emails)}</div>
        <div>
          {campaign?.campaignSettings?.reportRecipients.join(', ') ||
            t(i18nKeys.campaigns.labels.campaignDetails.thisListIsEmpty)}
        </div>
      </Grid>
    ) : (
      <SpaceBetween direction="vertical" size="s">
        {campaign?.campaignSettings?.reportRecipients && campaign.campaignSettings.reportRecipients.length > 0 ? (
          <SpaceBetween direction="horizontal" size="s">
            {campaign.campaignSettings.reportRecipients.map((reportRecipient: string, i) => {
              return (
                <Badge key={`recipient-badge-${i}`}>
                  {reportRecipient}
                  <div className="inline" onClick={() => removeEmail(i)} style={{ cursor: 'pointer' }}>
                    <Icon name="close" />
                  </div>
                </Badge>
              );
            })}
          </SpaceBetween>
        ) : (
          <span>{t(i18nKeys.campaigns.labels.campaignDetails.noReportRecipients)}</span>
        )}
        <SpaceBetween direction="horizontal" size="s">
          <Input
            className="inline email-input mr-4"
            type="text"
            value={newEmails}
            placeholder="user@example.com"
            onChange={({ detail }) => setNewEmails(detail.value)}
          />
          <Button
            className="inline"
            variant="normal"
            onClick={() => handleAddEmails()}
            disabled={!newEmails || !isValidEmailInput()}>
            {t(i18nKeys.campaigns.buttons.addReportRecipient)}
          </Button>
        </SpaceBetween>
        {newEmails && !isValidEmailInput() && (
          <div className="warning">{t(i18nKeys.campaigns.errors.emailFormatInvalid)}</div>
        )}
      </SpaceBetween>
    );
  };

  return (
    <React.Fragment>
      {!newCampaignMode && (
        <ExpandableSection variant="container" header={headerElement}>
          {renderContent()}
        </ExpandableSection>
      )}
      {newCampaignMode && <Container header={headerElement}>{renderContent()}</Container>}
    </React.Fragment>
  );
};
export default ReportRecipients;
