import React from 'react';
import { useTranslation } from 'react-i18next';
import { Campaign } from '../../../types/Campaign';
import { Event, EventStatus } from '../../../types/Event';
import './TargetTimeline.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { getTimelineFormattedDate } from '@/src/utils/time.utils';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { ApprovalStatus } from '@/src/types/common';
import moment from 'moment';

interface TargetTimelineProps {
  target?: Event | Campaign;
}

const getFormattedDate = (dateVal: number | string | null ) => {
  if (!dateVal) return ''

  const date = new Date(dateVal)
  return `${getTimelineFormattedDate(date)} (GMT${moment(date).format('Z')})`
}


const TargetTimeline: React.FC<TargetTimelineProps> = ({ target }) => {
  const { t } = useTranslation();
  const event = target as Event;

  const isEventStarted = (): boolean => {
    return (new Date(event?.startDate || '') < new Date()) && (event?.status !== EventStatus.NOT_STARTED);
  };

  const isEventEnd = (): boolean => {
    return new Date(event?.endDate || '') < new Date();
  };

  const circleWithDiv = (num: number): JSX.Element => <div className="circle-new">{num}</div>;
  const checkSvg = (): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Status icon">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM3.58579 8L5 6.58579L7 8.58579L10.52 5.06579L11.9342 6.48L7 11.4142L3.58579 8Z"
          fill="#219b02"
        />
      </g>
    </svg>
  );

  const cancelSvg = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="45" fill="none" stroke="#d13212" strokeWidth={11} />
      <path
        d="M30 30 L70 70 M30 70 L70 30"
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        stroke="#d13212"
        strokeWidth={11}
      />
    </svg>
  );

  return (
    <ol className="c-stepper">
      {target?.approvalStatus !== ApprovalStatus.REQUEST_CANCELLED ? (
        <>
          <li className="c-stepper__item_new active">
            <div>
              <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                <span> {checkSvg()}</span>
                <h3 className="c-stepper__title">{t(i18nKeys.events.eventDetails.timeline.scheduled)}</h3>
              </SpaceBetween>
              <div className="c-stepper__subtitle">
                <div className="c-stepper__subtitle__text">{t(i18nKeys.events.eventDetails.timeline.createdOn)}</div>{' '}
                {getFormattedDate(event?.createdDate)}
              </div>
            </div>
          </li>
          <li className={`c-stepper__item_new ${isEventStarted() ? 'active' : ''}`}>
            <div>
              <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                <span>{isEventStarted() ? checkSvg() : circleWithDiv(2)}</span>
                <h3 className="c-stepper__title">{t(i18nKeys.events.eventDetails.timeline.start)}</h3>
              </SpaceBetween>
              <div className="c-stepper__subtitle">
                <div className="c-stepper__subtitle__text">{t(i18nKeys.events.eventDetails.timeline.scheduledFor)}</div>
                {getFormattedDate(event?.startDate)}
              </div>
            </div>
          </li>
          <li className={`c-stepper__item_new ${isEventEnd() ? 'active' : ''}`}>
            <div>
              <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                <span>{isEventEnd() ? checkSvg() : circleWithDiv(3)}</span>
                <h3 className="c-stepper__title">{t(i18nKeys.events.eventDetails.timeline.end)}</h3>
              </SpaceBetween>
              <div className="c-stepper__subtitle">
                {getFormattedDate(event?.endDate)}
              </div>
            </div>
          </li>
        </>
      ) : (
        <>
          <li className={`c-stepper__item`}>
            <div>
              <h3 className="c-stepper__title">{t(i18nKeys.general.purchased)}</h3>
              <div className="c-stepper__subtitle">
                <div className="c-stepper__subtitle__text">{t(i18nKeys.general.purchased)} on</div>
                  {getFormattedDate(event?.startDate)}
              </div>
            </div>
          </li>
          <li className={`c-stepper__item_new cancelled`}>
            <div>
              <h3 className="c-stepper__title flex-item">
                {cancelSvg()}
                <span className="pl-4">{t(i18nKeys.general.cancelled)}</span>
              </h3>
              <div className="c-stepper__subtitle">
                <div className="c-stepper__subtitle__text">{t(i18nKeys.general.cancelled)} on</div>
                  {getFormattedDate(event?.createdDate)}
              </div>
            </div>
          </li>
        </>
      )}
    </ol>
  );
};
export default TargetTimeline;
