import { TypedJSON } from 'typedjson';

/**
 * Converts plain javascript object to instance of desired class
 *
 * @param object Javascript Plain Object
 * @param className Class to cast object to
 */
export const fromPlainObject = (object: any, className: any): object => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const serializer: TypedJSON<any> = new TypedJSON(className);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return serializer.parse(object);
};
