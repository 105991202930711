export enum SAVE_ACTIONS {
    UPDATE_CHANGE_REQUEST = 'update-change-request',
    UPDATE_CAMPAIGN_REQUEST = 'update-campaign-request',
    SUBMIT_CHANGE_REQUEST = 'submit-change-request',
  }

  export enum NewCampaignSteps {
    CAMPAIGN_DETAILS = 0,
    PERMISSIONS_AND_REPORTING = 1,
    CHALLENGES = 2,
    SETTINGS = 3,
    REVIEW_AND_CREATE = 4,
  }
  