import React from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { getErrorCode, getErrorMessage } from '@/src/utils/errors.utils';

interface ClueDetailsModalProps {
  value: boolean;
  order: number;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  setShowClue: React.Dispatch<React.SetStateAction<boolean>>;
  penalty: number;
}

const ClueDetailsModal: React.FC<ClueDetailsModalProps> = ({ value, setValue, setShowClue, order, penalty }) => {
  const { t } = useTranslation();
  const { eventName } = useJamEventDetails();
  const { loadJamChallengeTaskClue, jamChallengeId, task } = useJamChallengeDetails();
  const { addWarningFlashbar, addErrorFlashbar } = useFlashbars();

  const handleModal = async (enableClue = false) => {
    if (enableClue) {
      try {
        await loadJamChallengeTaskClue({ id: eventName, challengeId: jamChallengeId }, task?.id ?? '', order);
        setShowClue(true);
      } catch (e) {
        // If the error is 104 (clue already requested), add a warning bar and show the clue.
        if (getErrorCode(e) === 104){
          addWarningFlashbar("Team has already requested this clue.");
          setShowClue(true);
        } else {
          addErrorFlashbar(getErrorMessage(e));
        }
      }
    }
    setValue(!value);
  };

  return (
    <Modal
      onDismiss={() => void handleModal()}
      closeAriaLabel={t(i18nKeys.general.close)}
      visible={value}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => void handleModal()}>
              {t(i18nKeys.myJams.challenges.details.task.modal.unlockClue.cancel)}
            </Button>
            <Button variant="primary" onClick={() => void handleModal(true)}>
              {t(i18nKeys.myJams.challenges.details.task.modal.unlockClue.confirm)}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Clue Details">
      <Box variant="p">{t(i18nKeys.myJams.challenges.details.task.modal.unlockClue.subtitle1, { point: penalty })}</Box>
      <Box variant="p">{t(i18nKeys.myJams.challenges.details.task.modal.unlockClue.subtitle2)}</Box>
    </Modal>
  );
};

export default ClueDetailsModal;
