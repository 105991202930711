const AWSServiceList = [
  'Alexa for Business',
  'Amazon API Gateway',
  'Amazon AppStream 2.0',
  'Amazon Athena',
  'Amazon Aurora - MySQL-compatible',
  'Amazon Aurora - PostgreSQL-compatible',
  'Amazon Aurora',
  'Amazon Braket',
  'Amazon Bedrock',
  'Amazon CodeWhisperer',
  'Amazon CodeGuru',
  'Amazon CodeGuru Reviewer',
  'Amazon CodeGuru Security',
  'Amazon Chime',
  'Amazon Cloud Directory',
  'Amazon CloudSearch',
  'Amazon CloudWatch',
  'Amazon CloudWatch RUM',
  'Amazon CloudWatch Internet Monitor',
  'Amazon CloudWatch Evidently',
  'Amazon CloudWatch Synthetics',
  'Amazon CloudWatch Events',
  'Amazon CloudWatch Logs',
  'Amazon Cognito',
  'Amazon Comprehend',
  'Amazon Connect',
  'Amazon DeepLens',
  'Amazon DynamoDB',
  'Amazon DynamoDB Accelerator (DAX)',
  'Amazon DocumentDB elastic clusters',
  'Amazon Elastic Container Registry (ECR)',
  'Amazon Elastic Container Service (ECS)',
  'Amazon Elastic Container Service for Kubernetes (EKS)',
  'Amazon ElastiCache',
  'Amazon Elastic Block Store (EBS)',
  'Amazon Elastic Compute Cloud (EC2)',
  'Amazon Elastic Compute Cloud (EC2) Auto Scaling',
  'Amazon Elastic File System (EFS)',
  'Amazon Elastic MapReduce (EMR)',
  'Amazon Elasticsearch Service',
  'Amazon Elastic Transcoder',
  'Amazon EventBridge Pipes',
  'Amazon Polly',
  'Amazon FSx',
  'Amazon Fargate',
  'Amazon FreeRTOS',
  'Amazon GroundTruth Labeling',
  'Amazon GameLift',
  'Amazon Glacier',
  'Amazon GuardDuty',
  'Amazon Inspector',
  'Amazon Kendra',
  'Amazon Kinesis Data Analytics',
  'Amazon Kinesis Data Firehose',
  'Amazon Kinesis Data Streams',
  'Amazon Kinesis Video Streams',
  'Amazon Location Service',
  'Amazon Lookout for Equipment',
  'Amazon Lookout for Vision',
  'Amazon Lex',
  'Amazon Lightsail',
  'Amazon Managed Grafana',
  'Amazon Message Delivery Service',
  'Amazon MSK',
  'Amazon MSK Connect',
  'Amazon Managed Blockchain (AMB)',
  'Amazon Message Gateway Service',
  'Amazon MemoryDB',
  'Amazon Machine Learning',
  'Amazon Macie',
  'Amazon Mobile Analytics',
  'Amazon MQ',
  'Amazon Neptune',
  'Amazon Omics',
  'Amazon OpenSearch Serverless (AOSS)',
  'Amazon Personalize',
  'Amazon Pinpoint',
  'Amazon Polly',
  'Amazon QuickSight',
  'Amazon Q',
  'Amazon Redshift Serverless',
  'Amazon Redshift',
  'Amazon Rekognition Image',
  'Amazon Rekognition Video',
  'Amazon Relational Database Service (RDS)',
  'Amazon Route 53 Resolver',
  'Amazon Route 53 Auto Naming',
  'Amazon Sagemaker',
  'Amazon SimpleDB',
  'Amazon Simple Email Service (SES)',
  'Amazon Simple Notification Service (SNS)',
  'Amazon Simple Queue Service (SQS)',
  'Amazon Simple Storage Service (S3)',
  'Amazon Simple Workflow Service (SWF)',
  'Amazon Timestream',
  'Amazon Textract',
  'Amazon Transcribe',
  'Amazon Translate',
  'Amazon VPC Lattice',
  'Amazon Virtual Private Cloud (VPC)',
  'Amazon WorkDocs',
  'Amazon WorkMail',
  'Amazon WorkSpaces',
  'Amazon WorkSpaces Application Manager',
  'AWS Auto Scaling',
  'AWS Application Discovery Service',
  'AWS Application Migration Service',
  'AWS IAM Access Analyzer',
  'AWS AppSync',
  'AWS Amplify',
  'AWS AppMesh',
  'AWS AppRunner',
  'AWS Batch',
  'AWS Backup',
  'AWS Compute Optimizer',
  'AWS CodeArtifact',
  'AWS Certificate Manager',
  'AWS Certificate Manager (ACM)',
  'AWS Private Certificate Authority (PCA)',
  'AWS Security Token Service (STS)',
  'AWS CloudFormation',
  'AWS Cloud9',
  'AWS CloudHSM',
  'AWS CloudHSM Classic',
  'AWS CloudTrail',
  'AWS CodeBuild',
  'AWS CodeCommit',
  'AWS CodeDeploy',
  'AWS CodeDeploy secure host commands service',
  'AWS CodePipeline',
  'AWS CodeStar',
  'AWS Config',
  'AWS DeepRacer',
  'AWS Directory Service(DS)',
  'AWS Database Migration Service (DMS)',
  'AWS Elastic Disaster Recovery (DRS)',
  'AWS Data Pipeline',
  'AWS DataSync',
  'AWS Device Farm',
  'AWS Direct Connect',
  'AWS Directory Service',
  'AWS Elastic Beanstalk',
  'AWS Elemental MediaConvert',
  'AWS Elemental MediaLive',
  'AWS Elemental MediaPackage',
  'AWS Elemental MediaStore',
  'AWS Elemental MediaTailor',
  'AWS Fault Injection Service',
  'AWS Global Accelerator',
  'AWS Glue',
  'AWS Greengrass',
  'AWS Health',
  'AWS HealthLake',
  'AWS IoT',
  'AWS IoT Events',
  'AWS IoT FleetWise',
  'AWS IoT TwinMaker',
  'AWS IoT Core',
  'AWS IoT 1-Click',
  'AWS IoT Analytics',
  'AWS IoT Device Management',
  'AWS Key Management Service',
  'AWS Lake Formation',
  'AWS Launch Wizard',
  'AWS Lambda',
  'AWS License Manager',
  'AWS Elemental MediaConnect',
  'AWS Migration Hub Refactor Spaces',
  'AWS Mainframe Modernization(M2)',
  'AWS Managed Services',
  'AWS Marketplace',
  'AWS Migration Hub',
  'AWS Mobile Hub',
  'AWS Network Firewall',
  'AWS Network Manager',
  'AWS OpsWorks Stacks',
  'AWS OpsWorks for Chef Automate',
  'AWS OpsWorks for Puppet Enterprise',
  'AWS Price List',
  'AWS Personal Health Dashboard',
  'AWS Resource Access Manager (RAM)',
  'AWS SQL Workbench',
  'AWS Serverless Application Repository',
  'AWS Systems Manager Incident Manager',
  'AWS Systems Manager GUI Connect',
  'AWS Secrets Manager',
  'AWS Server Migration Service',
  'AWS Service Catalog',
  'AWS Shield Standard',
  'AWS Shield Advanced',
  'AWS Single Sign-On',
  'AWS Snowball',
  'AWS Snowball Edge',
  'AWS Snowmobile',
  'AWS Step Functions',
  'AWS Storage Gateway',
  'AWS Sumerian',
  'AWS Support',
  'AWS Systems Manager (SSM)',
  'AWS Trusted Advisor',
  'AWS Tiros',
  'AWS WAF',
  'AWS WAF Regional',
  'AWS WAF V2',
  'AWS Well-Architected',
  'AWS X-Ray',
  'Elastic Load Balancing',
  'VM Import/Export',
  'AWS Identity and Access Management (IAM)',
  'Amazon CloudFront',
  'Amazon Route 53',
  'AWS Shield',
  'AWS Security Hub',
  'AWS Mobile Hub',
  'AWS Organizations',
];

const shortName = (service: string): string => {
  if (/.*\(\w{2,3}\)/.test(service)) {
    return service.split(/\(/g)
      .reverse()[0]
      .split(')')[0];
  }
  if (service.startsWith('Amazon ')) {
    return service.replace('Amazon ', '');
  }
  if (service.startsWith('AWS ')) {
    return service.replace('AWS ', '');
  }
  return service;
};

// noinspection JSUnusedGlobalSymbols
export const AwsServices = {
  list: AWSServiceList,
  shortName,
};
