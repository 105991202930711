import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Cards, CardsProps } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { JamTeamItem } from '@/src/types/JamTeam';
import { JamEventTeamAssignmentType } from '@/src/types/JamCommon';

interface TeamListSelectionProps extends Partial<CardsProps> {
  items: JamTeamItem[];
  teamAssignmentType: JamEventTeamAssignmentType;
}

const TeamListSelection: React.FC<TeamListSelectionProps> = ({ items, teamAssignmentType, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Cards
      ariaLabels={{
        itemSelectionLabel: (e, item: JamTeamItem) =>
          t(i18nKeys.joinTeam.onboarding.selectOptionLabel, { option: item.team.teamName }),
        selectionGroupLabel: t(i18nKeys.joinTeam.onboarding.selectGroupLabel),
      }}
      selectionType="single"
      cardDefinition={{
        header: (item: JamTeamItem) => <Box variant="h4">{item.team.teamName}</Box>,
        sections: [
          {
            id: 'goal',
            content: (item: JamTeamItem) => (
              <Box>
                <Box variant="strong">{t(i18nKeys.joinTeam.onboarding.goal)} </Box>
                {t(i18nKeys.joinTeam.form.commonFields[item.team.goal])}
              </Box>
            ),
          },
          {
            id: 'teamMembers',
            header: t(i18nKeys.joinTeam.onboarding.teamMembers),
            content: (item: JamTeamItem) => (
              <Box>
                {item.participants.map((participant) => (
                  <Box key={participant.participantId}>{participant.nickname}</Box>
                ))}
              </Box>
            ),
          },
          {
            id: 'size',
            content: (item: JamTeamItem) =>
              teamAssignmentType !== JamEventTeamAssignmentType.PRE_CREATED && (
                <Box data-classname="card-pin">
                  {t(i18nKeys.joinTeam.onboarding.spotsLeft, { count: item.team.spotsLeft })}
                </Box>
              ),
          },
        ],
      }}
      items={items}
      {...rest}
    />
  );
};

export default TeamListSelection;
