import { Box, Button, Container, Header, Link, SpaceBetween, Table } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../../store/challenge.context';
import { useToolPanel } from '../../../../../store/tool-panel.context';
import { Nullable } from '../../../../../types/common';
import { TeamChallengeMetricRow, TeamMetricsRow } from '../../../../../types/DetailedEventStatisticsReport';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { getDisplayDuration } from '../../../../../utils/time.utils';
import { TableEmptyState } from '../../../../common/TableEmptyState';
import TeamOverview from '../molecules/TeamOverview';

interface TeamMetricDetailsProps {
  teamMetrics: TeamMetricsRow;
  selectTeam: Dispatch<SetStateAction<Nullable<TeamMetricsRow>>>;
}

const TeamMetricDetails: React.FC<TeamMetricDetailsProps> = ({ teamMetrics, selectTeam }) => {
  const { t } = useTranslation();
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const { toggleChallengeInfo } = useToolPanel();

  /**
   * TeamMetricDetails.tsx is conditionally displayed by parent component, setting
   * selectedTeam to null goes back to reports
   */
  const goBack = () => {
    selectTeam(null);
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      <Header
        actions={
          <Button variant="link" onClick={() => goBack()}>
            {t(i18nKeys.report.buttons.backToReports)}
          </Button>
        }
        variant="h1">
        {teamMetrics.teamName}
      </Header>
      <Container>
        <Box margin={{ bottom: 's' }} variant="h2">
          {t(i18nKeys.report.headers.teamDetails.teamOverview)}
        </Box>
        <TeamOverview teamMetrics={teamMetrics} />
      </Container>
      <Table
        header={
          <Header variant="h2" counter={`(${teamMetrics.startedChallenges.length})`}>
            {t(i18nKeys.report.headers.teamDetails.startedChallenges.header)}
          </Header>
        }
        items={teamMetrics.startedChallenges}
        columnDefinitions={[
          {
            id: 'challenge',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.challenge),
            cell: (item: TeamChallengeMetricRow) => {
              if (item.challengeId) {
                const challengeItem = getChallengeListItemFromChallengeId(item.challengeId);
                if (challengeItem) {
                  return <Link onFollow={() => toggleChallengeInfo(challengeItem)}>{challengeItem.props.title}</Link>;
                } else {
                  return <div>{item.challengeId}</div>;
                }
              }
            },
          },
          {
            id: 'completed-tasks',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.completedTasks),
            cell: (item: TeamChallengeMetricRow) => <div>{item.numCompletedTasks}</div>,
          },
          {
            id: 'incorrect-answers',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.incorrectAnswers),
            cell: (item: TeamChallengeMetricRow) => <div>{item.numIncorrectAnswers}</div>,
          },
          {
            id: 'time-to-first-attempt',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.timeToFirstAttempt),
            cell: (item: TeamChallengeMetricRow) => (
              <div>{item.timeToFirstAttempt ? getDisplayDuration(item.timeToFirstAttempt) : '--'}</div>
            ),
          },
          {
            id: 'time-to-clue-used',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.timeToClueUsed),
            cell: (item: TeamChallengeMetricRow) => (
              <div>{item.timeToNthClueUsed[0] ? getDisplayDuration(item.timeToNthClueUsed[0]) : '--'}</div>
            ),
          },
          {
            id: 'challenge-rating',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.challengeRating),
            cell: (item: TeamChallengeMetricRow) => (
              <div>{item.teamChallengeRating ? item.teamChallengeRating : t(i18nKeys.general.nA)}</div>
            ),
          },
          {
            id: 'challenge-difficulty',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.challengeDifficulty),
            cell: (item: TeamChallengeMetricRow) => (
              <div>
                {item.teamChallengeDifficultyRating ? item.teamChallengeDifficultyRating : t(i18nKeys.general.nA)}
              </div>
            ),
          },
          {
            id: 'learned',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.learned),
            cell: (item: TeamChallengeMetricRow) => <div>{item.learnedSomethingNew || 0}</div>,
          },
          {
            id: 'did-not-learn',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.didntLearn),
            cell: (item: TeamChallengeMetricRow) => <div>{item.didNotLearnSomethingNew || 0}</div>,
          },
          {
            id: 'feedback',
            header: t(i18nKeys.report.headers.teamDetails.startedChallenges.feedback),
            cell: (item: TeamChallengeMetricRow) => <div>{item.feedbackComments.length || 0}</div>,
          },
        ]}
      />
      <Table
        header={
          <Header variant="h2" counter={`(${teamMetrics.notStartedChallenges.length})`}>
            {t(i18nKeys.report.headers.teamDetails.unstartedChallenges.header)}
          </Header>
        }
        items={teamMetrics.notStartedChallenges}
        empty={
          <TableEmptyState title={t(i18nKeys.report.headers.teamDetails.unstartedChallenges.noUnstartedChallenges)} />
        }
        columnDefinitions={[
          {
            id: 'not-started-challenge',
            header: t(i18nKeys.report.headers.teamDetails.unstartedChallenges.challenge),
            cell: (item: string) => <div>{item}</div>,
          },
        ]}
      />
    </SpaceBetween>
  );
};
export default TeamMetricDetails;
