export enum CreateJamProfileFields {
  EMAIL = 'email',
  LANGUAGE = 'language',
  DISPLAY_NAME = 'display-name',
  COUNTRY = 'country',
}

export interface InputValidationHelper {
  isValid: () => boolean;
  checkErrors: () => string;
}
