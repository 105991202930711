import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';

// components
import { i18nKeys } from '@/src/utils/i18n.utils';

// types
import { EventLearningType, EventTopicItem, IEventTemplate } from '@/src/types/EventTemplate';

export interface IEventDetailsProps {
  eventTemplate: IEventTemplate;
  topics: (EventTopicItem | undefined)[];
  hours: number | undefined;
  tags: (EventTopicItem | undefined)[];
}

const EventDetails: React.FC<IEventDetailsProps> = ({ eventTemplate, topics, hours, tags }) => {
  const { t } = useTranslation();

  return (
    <Container header={<Header variant="h2">{t(i18nKeys.eventTemplates.eventTemplateDetails.heading)}</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.eventTemplateDetails.summary)}</Box>
            <Box>{eventTemplate?.summary || '-'}</Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.eventTemplateDetails.topic)}</Box>
            {!!eventTemplate?.topics?.length ? (
              topics.map((topic, i) => (
                <span key={topic?.value}>
                  {topic?.label}
                  {topics.length - 1 !== i ? ', ' : ''}
                </span>
              ))
            ) : (
              <Box>-</Box>
            )}
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.eventTemplateDetails.duration)}</Box>
            <Box>{hours} hours</Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.eventTemplateDetails.learningType)}</Box>
            <Box>
              {eventTemplate.learningType === EventLearningType.INDIVIDUAL
                ? t(i18nKeys.catalog.learningType.individual)
                : t(i18nKeys.catalog.learningType.team)}
            </Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.eventTemplateDetails.teamSize)}</Box>
            <Box>
              {eventTemplate?.recommendedTeamSize || '0'} {t(i18nKeys.eventTemplates.eventTemplateDetails.participants)}
            </Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.eventTemplateDetails.numParticipants)}</Box>
            <Box>
              {eventTemplate?.minParticipants || '2'} &mdash; {eventTemplate.maxParticipants || '200'}
            </Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.step4.tags.label)}</Box>
            {!!eventTemplate?.tags?.length ? (
              tags.map((badge, i) => (
                <span key={badge?.value}>
                  {badge?.label}
                  {tags.length - 1 !== i ? ', ' : ''}
                </span>
              ))
            ) : (
              <Box>-</Box>
            )}
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{t(i18nKeys.eventTemplates.eventTemplateDetails.pricePerLearner)}</Box>
            <Box>${eventTemplate?.price}.00 (USD)</Box>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default EventDetails;
