import React from 'react';
import { Box } from '@amzn/awsui-components-react';
import { IJamChallenge } from '@/src/types/JamChallenges';
import { useJamChallenge } from '@/src/store/jam-challenge.context';

interface PointsProps {
  challenge: IJamChallenge;
}
const Points: React.FC<PointsProps> = ({ challenge }) => {
  const { jamChallengeData } = useJamChallenge();
  const PointsEarned: number | undefined = jamChallengeData?.challenges?.find(
    (answer: IJamChallenge) => answer.id === challenge.id
  )?.earnedPoints;

  return (
    <Box variant="span" padding={{ horizontal: 'm' }}>
      {PointsEarned ?? 0}/{challenge.maxPoints} pts
    </Box>
  );
};

export default Points;
