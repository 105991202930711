/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from 'react';
import { Button, Modal, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import { AccountCredentials } from '../../../types/LabModels';
import { preProdLogger } from '../../../utils/log.utils';
import { CopyToClipboard } from '../CopyToClipboard';
import { useState } from 'react';
import JamCodeEditor from '../../challenges/challengesCommon/JamCodeEditor';

interface AwsAccountCLIModalProps {
  visible: boolean;
  onCancel: () => any;
  onConfirm: () => any;
  credentials: AccountCredentials;
  region?: string;
}

const AwsAccountCLIModal: React.FC<AwsAccountCLIModalProps> = ({
  visible,
  onCancel,
  onConfirm,
  credentials,
  region = 'us-west-2',
}) => {
  const { t } = useTranslation();
  const [terminalType, setTerminalType] = useState('unix');

  const getCLICommand = () => {
    if (terminalType === 'powershell') {
      // powershell
      return `Set-DefaultAWSRegion "${region}"
Set-AWSCredential -AccessKey "${credentials.accessKey}" -SecretKey "${credentials.secretKey}" -SessionToken "${credentials.sessionToken}"`;
    } else if (terminalType === 'cmd') {
      // cmd
      return `set AWS_REGION=${region}
set AWS_ACCESS_KEY_ID=${credentials.accessKey}
set AWS_SECRET_ACCESS_KEY=${credentials.secretKey}
set AWS_SESSION_TOKEN=${credentials.sessionToken}`;
    } else if (terminalType === 'unix') {
      // unix
      return `export AWS_REGION="${region}"
export AWS_ACCESS_KEY_ID="${credentials.accessKey}"
export AWS_SECRET_ACCESS_KEY="${credentials.secretKey}"
export AWS_SESSION_TOKEN="${credentials.sessionToken}"`;
    } else if (terminalType === 'python') {
      // python
      return `import boto3

AWS_ACCESS_KEY_ID="${credentials.accessKey}"
AWS_SECRET_ACCESS_KEY="${credentials.secretKey}"
AWS_SESSION_TOKEN="${credentials.sessionToken}"
AWS_REGION="${region}"

session = boto3.session.Session(
  aws_access_key_id=AWS_ACCESS_KEY_ID,
  aws_secret_access_key=AWS_SECRET_ACCESS_KEY,
  aws_session_token=AWS_SESSION_TOKEN,
  region_name=AWS_REGION)

# proceed to create clients / resources from the session
# for example, an s3 client:

# client = session.client('s3')
`;
    } else {
      preProdLogger('unknown terminal type');
    }
  };

  return (
    <Modal
      header={t(i18nKeys.challenges.challengeDetails.headings.usingChallengeResources)}
      onDismiss={() => onCancel()}
      size={'large'}
      footer={<Button onClick={() => onConfirm()}>{t(i18nKeys.general.close)}</Button>}
      visible={visible}>
      <SpaceBetween size="s">
        <SpaceBetween size="s" direction="horizontal">
          <Button onClick={() => setTerminalType('unix')}>Linux, macOS, or Unix</Button>
          <Button onClick={() => setTerminalType('powershell')}>Powershell</Button>
          <Button onClick={() => setTerminalType('cmd')}>Windows cmd</Button>
          <Button onClick={() => setTerminalType('python')}>Python - Boto3</Button>
        </SpaceBetween>
        {terminalType === 'unix' && (
          <TextContent>
            {t(i18nKeys.challenges.challengeDetails.text.awsAccountUnix)}{' '}
            <a
              href="https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-getting-started.html#cli-environment"
              target="_blank"
              rel="noopener noreferrer">
              {t(i18nKeys.challenges.challengeDetails.text.environmentVariables)}
            </a>
            :
          </TextContent>
        )}
        {terminalType === 'powershell' && (
          <TextContent>
            {t(i18nKeys.challenges.challengeDetails.text.awsAccountPowershell)}{' '}
            <a
              href="https://docs.aws.amazon.com/powershell/latest/reference/items/Set-AWSCredential.html"
              target="_blank"
              rel="noopener noreferrer">
              Set-AWSCredential
            </a>
            :
          </TextContent>
        )}
        {terminalType === 'cmd' && (
          <TextContent>
            {t(i18nKeys.challenges.challengeDetails.text.awsAccountCmd)}{' '}
            <a
              href="https://docs.aws.amazon.com/cli/latest/userguide/cli-environment.html"
              target="_blank"
              rel="noopener noreferrer">
              {t(i18nKeys.challenges.challengeDetails.text.environmentVariables)}
            </a>
            :
          </TextContent>
        )}
        {terminalType === 'python' && (
          <TextContent>
            {t(i18nKeys.challenges.challengeDetails.text.awsAccountPython)}{' '}
            <a
              href="https://boto3.amazonaws.com/v1/documentation/api/latest/index.html"
              target="_blank"
              rel="noopener noreferrer">
              boto3
            </a>{' '}
            {t(i18nKeys.challenges.challengeDetails.text.client)}:
          </TextContent>
        )}
        <JamCodeEditor
          editorContentHeight={300}
          language={terminalType}
          value={getCLICommand() || ''}
          setValue={() => preProdLogger('none')}
        />
        <CopyToClipboard value={getCLICommand() || ''} />
      </SpaceBetween>
    </Modal>
  );
};

export default AwsAccountCLIModal;
