import React from 'react';
import '../../styles/JamSpinner.scss';

const JamSpinner: React.FC = () => (
  <div>
    <div className="backdrop">
      <div className="jam-spinner">
        <div className="loading">
          <img src="/assets/spinner-image.png" alt="spinner-image" />
        </div>
      </div>
    </div>
  </div>
);

export default JamSpinner;
