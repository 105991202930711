import * as React from 'react';
import { Box, ColumnLayout, Grid, Link } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { jamChallengeOverviewRegex, jamChallengeTaskDetailsRegex, jamChatRegex } from '@/src/utils/myjam.utils';
import JamChatContainer from '@/src/components/ui/organisms/JamChatContainer/JamChatContainer';
import './MyJamFooter.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { useJamLeaderboard } from '@/src/store/jam-leaderboard.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { AwsRegions } from '@/src/constants/shared';
import CountDown from '@/src/components/ui/molecules/CountDown/CountDown';
import { useCampaigns } from '@/src/store/campaigns.context';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import { IJamChallenge } from '@/src/types/JamChallenges';
import { sum } from 'lodash';

interface IColumnContentProps {
  name: string;
  value?: string;
}

const ColumnContent: React.FC<IColumnContentProps> = ({ name, value, children }) => (
  <Box variant="p" className='column-content'>
    <span style={{ color: '#fff' }}>{name}:</span>
    <Box variant="span" className="light-violet">
      &nbsp;{value ? value : children}
    </Box>
  </Box>
);

export const MyJamFooter: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = history.location;
  const [scheduledTerminationTime, setScheduledTerminationTime] = React.useState<number | null>();
  const { selectedJamChallenge, challengeProgress, awsAccount, teamPropertiesResponse } = useJamChallengeDetails();
  const { jamChallengeData } = useJamChallenge();
  const { isPresenterMode, togglePresenterMode } = useJamLeaderboard();
  const { eventSlug, event } = useJamEventDetails();
  const isTeamChatScreen = jamChatRegex.test(pathname);
  const isLeaderboard = pathname === JAM_EVENT_DETAILS_ROUTES.LeaderBoard.resolve(eventSlug);
  const isTeamInfo = pathname.includes(JAM_EVENT_DETAILS_ROUTES.TeamInfo.resolve([eventSlug, '']));
  const JamChallengesScreen = jamChallengeOverviewRegex.test(pathname) || jamChallengeTaskDetailsRegex.test(pathname);
  const Score = `${selectedJamChallenge?.score ?? '0'} / ${selectedJamChallenge?.possiblePoints ?? '150'}`;
  const { showCampaignRules, campaignAttempt, campaignStarted } = useCampaigns();
  const isCampaign = event?.type === "CAMPAIGN_GROUP";
  const totalPoints = sum(jamChallengeData?.challenges?.map((challenge: IJamChallenge) => challenge.earnedPoints));

  const AwsAccount = awsAccount
    ? `${awsAccount.accountNumber} ${
        AwsRegions.ALL_AWS_REGIONS_BY_ID[awsAccount.region]
          ? AwsRegions.ALL_AWS_REGIONS_BY_ID[awsAccount.region].name
          : ''
      }`
    : '';

  const challengeStatus = React.useMemo(() => {
    if (challengeProgress?.completed) {
      return t(i18nKeys.myJams.eventTabs.label.completed);
    }
    if (challengeProgress?.started) {
      return t(i18nKeys.myJams.eventTabs.label.incomplete);
    }
    return t(i18nKeys.myJams.eventTabs.label.readyToStart);
  }, [challengeProgress]);

  /**
   * Determine the status of the current campaign attempt
   */
  const campaignAttemptStatus = React.useMemo(() => {
    // In Progress
    if (campaignAttempt && campaignAttempt?.started && !campaignAttempt?.completed && campaignAttempt.attemptNumber > 0) {
      return t(i18nKeys.myJams.eventTabs.label.inProgress);
    }

    // Failed
    if (campaignAttempt && campaignAttempt?.completed && !campaignAttempt?.passed && campaignAttempt.attemptNumber > 0) {
      return t(i18nKeys.myJams.eventTabs.label.failed);
    }

    // Passed
    if (campaignAttempt && campaignAttempt?.passed && campaignAttempt.attemptNumber > 0) {
      return t(i18nKeys.myJams.eventTabs.label.passed);
    }

    // Incomplete
    if (campaignAttempt?.started && campaignAttempt.attemptNumber > 0) {
      return t(i18nKeys.myJams.eventTabs.label.incomplete);
    }

    // Completed
    if (campaignAttempt?.completed && campaignAttempt.attemptNumber > 0) {
      return t(i18nKeys.myJams.eventTabs.label.completed);
    }

    return t(i18nKeys.myJams.eventTabs.label.readyToStart);
  }, [campaignAttempt, t]);

  React.useEffect(() => {
    if (teamPropertiesResponse && teamPropertiesResponse.scheduledTerminationTime) {
      setScheduledTerminationTime(teamPropertiesResponse.scheduledTerminationTime);
    }
  }, [teamPropertiesResponse]);

  if (isTeamInfo) {
    return null;
  }

  return (
    <footer id="footer" className="my-jam-footer">
      {isLeaderboard && (
        <Link data-classname="presenter-mode" onFollow={togglePresenterMode} href="">
          <img alt="presenter mode" src="/assets/presenter.svg" />
          {!isPresenterMode ? t(i18nKeys.footer.presenterMode) : t(i18nKeys.footer.closePresenterMode)}
        </Link>
      )}
      {JamChallengesScreen && (
        <Grid gridDefinition={[{ colspan: 10 }]}>
          <ColumnLayout columns={5} variant="text-grid">
            <ColumnContent name={t(i18nKeys.footer.status)} value={challengeStatus} />
            <ColumnContent name={t(i18nKeys.footer.score)} value={`${Score} pts`} />
            {awsAccount && <ColumnContent name={t(i18nKeys.footer.awsAccount)} value={AwsAccount} />}
            {scheduledTerminationTime && (
              <ColumnContent name={t(i18nKeys.footer.labExpiration)}>
                <CountDown targetDate={scheduledTerminationTime} />
              </ColumnContent>
            )}
            {event?.eventEndDate && (
              <ColumnContent name={t(i18nKeys.footer.timeLeft)}>
                <CountDown targetDate={moment(event.eventEndDate).valueOf()} />
              </ColumnContent>
            )}
          </ColumnLayout>
        </Grid>
      )}
      {!isTeamChatScreen && <JamChatContainer />}
      {isCampaign && campaignAttempt && !event.ended && (showCampaignRules || !campaignStarted) && (
        <Grid gridDefinition={[{ colspan: 10 }]}>
          <ColumnLayout columns={4} variant="text-grid">
            <ColumnContent name={t(i18nKeys.footer.status)} value={campaignAttemptStatus} />
            <ColumnContent name={t(i18nKeys.footer.points)} value={`${totalPoints}`} />
          </ColumnLayout>
        </Grid>
      )}
    </footer>
  );
};
