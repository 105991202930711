import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Form, FormField, Grid, Input, RadioGroup, SpaceBetween } from '@amzn/awsui-components-react';
import { JamEventTeamAssignmentType, JamEventTeamGoal } from '@/src/types/JamCommon';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { localLogger } from '@/src/utils/log.utils';
import { JoinOrCreateTeamProps } from '@/src/types/JamJoinTeam';
import TeamListSelection from '../TeamListSelection/TeamListSelection';
import { JamTeamItem } from '@/src/types/JamTeam';
import { useJamMyTeam } from '@/src/store/jam-myteam.context';

interface MatchMeTeamFromProps extends Omit<JoinOrCreateTeamProps, 'createTeam'> {
  teams: JamTeamItem[];
  navigateToCreateTeam: () => void;
  joinTeam: (teamName: string, password: string | null) => Promise<void>;
  teamAssignmentType: JamEventTeamAssignmentType;
}

const TEAMS_PER_ROW = 3;

const MatchMeTeamFrom: React.FC<MatchMeTeamFromProps> = ({
  teams,
  navigateToCreateTeam,
  joinTeam,
  saving,
  teamAssignmentType,
}) => {
  const { t } = useTranslation();
  const [teamGoal, setTeamGoal] = useState<JamEventTeamGoal>();
  const [selectedTeam, setSelectedTeam] = useState<JamTeamItem[]>([]);
  const [selectedTeamError, setSelectedTeamError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showMoreTeams, setShowMoreTeams] = useState(false);
  const [password, setPassword] = useState('');

  const { loadAllTeams, eventTeams } = useJamMyTeam();
  const teamsLength = eventTeams.length;

  const filteredTeams = useMemo(() => {
    return teams.filter(({ team }) => team.goal === teamGoal);
  }, [teams, teamGoal]);

  const onSubmit = async () => {
    if (!selectedTeam.length) {
      setSelectedTeamError(t(i18nKeys.joinTeam.joinExistingTeam.field.errors.teamError));
      return;
    }
    if (selectedTeam[0]?.team.passwordRequired && !password) {
      setPasswordError(t(i18nKeys.joinTeam.form.errors.password));
      return;
    }
    setSelectedTeamError('');
    setPasswordError('');
    localLogger({ selectedTeam, teamGoal });
    await joinTeam(selectedTeam[0].team.teamId, password || null);
  };

  useEffect(() => {
    if (!teamsLength) {
      // if user reloads the page from this component
      // loaded team will be voided. we need to load it here again
      void loadAllTeams();
    }
  }, [teamsLength]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form>
        <SpaceBetween direction="vertical" size="l">
          <Box>
            <Box variant="h3">{t(i18nKeys.joinTeam.matchMeTeam.teamPreference)}</Box>
            <Box>{t(i18nKeys.joinTeam.matchMeTeam.teamPreferenceDesc)}</Box>
          </Box>
          <FormField label={'What is your goal?'}>
            <RadioGroup
              onChange={({ detail }) => setTeamGoal(detail.value as JamEventTeamGoal)}
              value={teamGoal as string}
              items={[
                {
                  value: JamEventTeamGoal.PLAY_TO_WIN,
                  label: t(i18nKeys.joinTeam.form.commonFields.playToWin),
                  description: t(i18nKeys.joinTeam.form.commonFields.playToWinDesc),
                },
                {
                  value: JamEventTeamGoal.PLAY_TO_LEARN,
                  label: t(i18nKeys.joinTeam.form.commonFields.playToLearn),
                  description: t(i18nKeys.joinTeam.form.commonFields.playToLearnDesc),
                },
              ]}
            />
          </FormField>
          {teamGoal && (
            <Grid gridDefinition={[{ colspan: 9 }, { colspan: 9 }, { colspan: 9 }, { colspan: 9 }]}>
              <FormField
                stretch
                label={t(i18nKeys.joinTeam.matchMeTeam.fields.selectTeam)}
                i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
                errorText={selectedTeamError}>
                <TeamListSelection
                  items={filteredTeams.slice(0, showMoreTeams ? undefined : TEAMS_PER_ROW)}
                  cardsPerRow={[{ cards: TEAMS_PER_ROW }]}
                  empty={
                    <Box color="inherit" textAlign="left">
                      <SpaceBetween size="xs">
                        <Box>{t(i18nKeys.joinTeam.matchMeTeam.emptyList)}</Box>
                        <Button onClick={navigateToCreateTeam}>{t(i18nKeys.joinTeam.joinOptions.createTeam)}</Button>
                      </SpaceBetween>
                    </Box>
                  }
                  onSelectionChange={({ detail }) => {
                    setSelectedTeam(detail.selectedItems);
                    setSelectedTeamError('');
                  }}
                  selectedItems={selectedTeam}
                  teamAssignmentType={teamAssignmentType}
                />
                {!showMoreTeams && filteredTeams.length > TEAMS_PER_ROW && (
                  <Box textAlign="center">
                    <Button onClick={() => setShowMoreTeams(true)}>{t(i18nKeys.joinTeam.matchMeTeam.showMore)}</Button>
                  </Box>
                )}
              </FormField>
              {!!selectedTeam?.[0]?.team.passwordRequired && (
                <FormField label={t(i18nKeys.general.password)} errorText={passwordError}>
                  <Input type="password" value={password} onChange={({ detail }) => setPassword(detail.value)} />
                </FormField>
              )}

              {!!filteredTeams.length && (
                <>
                  <div className="divider" />
                  <Box float="right">
                    <Button
                      loading={saving}
                      variant="primary"
                      ariaLabel={t(i18nKeys.joinTeam.matchMeTeam.submitBtn)}
                      onClick={() => void onSubmit()}>
                      {t(i18nKeys.joinTeam.matchMeTeam.submitBtn)}
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default MatchMeTeamFrom;
