/**
 * Map of existing Auth groups in the backend
 *
 * If this enum is updated, be sure to update the enum in AWSJam_GameBackend -> AuthGroup.java
 */
// ToDo remove this file after account simplification release
export enum AuthGroup {
  GENERAL_ACCESS = 'general-access',
  SUPER_ADMIN = 'super-admin',
  EVENT_ADMIN = 'event-admin',
  CAMPAIGN_ADMIN = 'campaign-admin',
  CHALLENGE_ADMIN = 'challenge-admin',
  CHALLENGE_BUILDER = 'challenge-builder',
  CHALLENGE_BAR_RAISER = 'challenge-bar-raiser',
  EVENT_PRIZES_ADMIN = 'event-prizes-admin',
}
