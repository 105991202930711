import React, { useMemo } from 'react';
import { BarMeterData } from './ChallengeLab';
import { colors, labStatusColors } from '@/src/constants/lab-status-color';
import { LabStatusSnapshot } from '@/src/types/LabModels';
import { Box, Button, ProgressBar, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface ProgressMeterProps {
  snapshot: LabStatusSnapshot;
}

const ProgressMeter: React.FC<ProgressMeterProps> = ({ snapshot }) => {
  const { t } = useTranslation();
  const total = snapshot.adjustedDesiredLabCount;
  const values = useMemo((): BarMeterData[] => {
    const rows = [] as BarMeterData[];
    if (!snapshot) {
      return rows;
    }

    if (snapshot.assigned && snapshot.assigned > 0) {
      rows.push({
        value: snapshot.assigned,
        label: t(i18nKeys.eventLabs.challengeLab.progressMeter.assigned),
        info: t(i18nKeys.eventLabs.challengeLab.progressMeter.assignedInfo),
        color: colors.lightGreen,
      });
    }

    const ready: number = snapshot.unassigned;

    if (ready && ready > 0) {
      rows.push({
        value: ready,
        label: t(i18nKeys.eventLabs.challengeLab.progressMeter.ready),
        info: t(i18nKeys.eventLabs.challengeLab.progressMeter.readyInfo),
        color: labStatusColors.UNASSIGNED,
      });
    }

    if (snapshot.onHold && snapshot.onHold > 0) {
      rows.push({
        value: snapshot.onHold,
        label: t(i18nKeys.eventLabs.challengeLab.progressMeter.onHold),
        info: t(i18nKeys.eventLabs.challengeLab.progressMeter.onHoldInfo),
        color: labStatusColors.ON_HOLD,
      });
    }

    if (snapshot.preparingResources && snapshot.preparingResources > 0) {
      rows.push({
        value: snapshot.preparingResources,
        label: t(i18nKeys.eventLabs.challengeLab.progressMeter.finalizing),
        info: t(i18nKeys.eventLabs.challengeLab.progressMeter.finalizingInfo),
        color: labStatusColors.PREPARING_RESOURCES,
      });
    }

    if (snapshot.notReady && snapshot.notReady > 0) {
      rows.push({
        value: snapshot.notReady,
        label: t(i18nKeys.eventLabs.challengeLab.progressMeter.deplying),
        info: t(i18nKeys.eventLabs.challengeLab.progressMeter.deplyingInfo),
        color: labStatusColors.NOT_READY,
      });
    }

    const deficit = Math.max(0, snapshot.adjustedDesiredLabCount - snapshot.usable);
    const surplus = Math.max(0, snapshot.usable - snapshot.adjustedDesiredLabCount);
    if (surplus > 0) {
      rows.push({
        value: surplus,
        label: t(i18nKeys.eventLabs.challengeLab.progressMeter.surplus),
        info: t(i18nKeys.eventLabs.challengeLab.progressMeter.surplusInfo),
        color: colors.royalBlue,
      });
    }

    if (deficit > 0) {
      rows.push({
        value: deficit,
        label: t(i18nKeys.eventLabs.challengeLab.progressMeter.deficit),
        info: t(i18nKeys.eventLabs.challengeLab.progressMeter.deficitInfo),
        color: colors.red,
      });
    }
    return rows;
  }, [snapshot]);

  const getPercent = (value: number) => {
    if (total < 1) {
      return 0;
    }
    return Math.max(0, Math.floor((value / total) * 100));
  };

  //   const isEmpty = useMemo(() => {
  //     return total < 1 || values.every((d) => d.value < 1);
  //   }, [values, total]);

  const data = useMemo(() => {
    return (values || []).map((d) => {
      d.percent = Math.max(0, getPercent(d.value));
      return d;
    });
  }, [values]);

  const sum = useMemo(() => {
    return (data || []).reduce((result, v) => result + (v.percent || 0), 0);
  }, [data]);

  return (
    <SpaceBetween size="m">
      <ProgressBar
        className={`progress-meter`}
        value={sum}
        additionalInfo={
          <SpaceBetween size="m">
            {data.map((bar) => (
              <SpaceBetween key={bar.label} direction="horizontal" size="m" alignItems="center">
                <Box>
                  {bar.label} <Button variant="inline-icon" iconName="status-info" onClick={() => alert(bar.info)} />{' '}
                </Box>
                <div style={{ background: bar.color, padding: '0 5px', borderRadius: '2px', color: 'white' }}>
                  {bar.value}
                </div>
                <Box>{bar.percent}%</Box>
              </SpaceBetween>
            ))}
          </SpaceBetween>
        }
        label={t(i18nKeys.eventLabs.challengeLab.progressMeter.labAvailability)}
        variant="key-value"
      />
    </SpaceBetween>
  );
};

export default ProgressMeter;
