import * as React from 'react';

import { EventTemplateStatusType, IEventTemplate } from '../../../types/EventTemplate';
import { Link } from 'react-router-dom';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { TFunction } from 'react-i18next';
import { RoutePath } from '@/src/RoutePath';
import { getTimeInBrowserLocalTime } from '@/src/utils/event-time.utils';
import { Button, Badge } from '@amzn/awsui-components-react';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';

const setBadgeColor = (item: string): JSX.Element => {
  if (!item) return <Badge color="grey">{item}</Badge>;

  const itemL = item?.toLocaleLowerCase();
  if (itemL === 'published') {
    return <Badge color="green">{item}</Badge>;
  }
  if (itemL === 'defective') {
    return <Badge color="red">{item}</Badge>;
  }
  if (itemL === 'private') {
    return <Badge color="blue">{item}</Badge>;
  }
  return <Badge color="grey">{item}</Badge>;
};
export const columnDefinitions = (
  t: TFunction<'translation', undefined>,
  onDeleteClicked?: (item: IEventTemplate) => void
) => [
  {
    id: 'name',
    header: t(i18nKeys.tables.tableConfig.name),
    cell: (item: IEventTemplate) => {
      if (item.status === EventTemplateStatusType.DRAFT) {
        return <Link to={`${RoutePath.EVENT_CATALOG_TEMPLATES}/${item.id}/edit`}>{item.name}</Link>;
      }
      return <Link to={`${RoutePath.EVENT_CATALOG_TEMPLATES}/${item.id}`}>{item.name}</Link>;
    },
    width: 400,
    isRowHeader: true,
    // sortingField: 'name',
  },
  {
    id: 'challenges',
    header: t(i18nKeys.tables.tableConfig.challenges),
    cell: (e: IEventTemplate) => e.numberOfChallenges,
    // sortingField: 'challenges',
  },
  {
    id: 'duration',
    header: t(i18nKeys.tables.tableConfig.duration),
    cell: (e: IEventTemplate) => e.duration,
  },
  {
    id: 'status',
    header: t(i18nKeys.tables.tableConfig.status),
    cell: (e: IEventTemplate) => setBadgeColor(e.status),
    // sortingField: 'status',
  },
  {
    id: 'createdDate',
    header: t(i18nKeys.tables.tableConfig.createdDate),
    cell: (e: IEventTemplate) =>
      getTimeInBrowserLocalTime(e.createdDate, {
        includeDate: true,
        includeTime: true,
      }),
    sortingField: 'createdDate',
    minWidth: 165,
    // sortingComparator: (a: IEventTemplate, b: IEventTemplate) =>
    //   new Date(a.lastUpdatedDate).getTime() - new Date(b.lastUpdatedDate).getTime(),
  },
  {
    id: 'lastUpdated',
    header: t(i18nKeys.tables.tableConfig.lastUpdate),
    cell: (e: IEventTemplate) =>
      getTimeInBrowserLocalTime(e.lastUpdatedDate, {
        includeDate: true,
        includeTime: true,
      }),
    minWidth: 165,
    // sortingField: 'lastUpdate',
    // sortingComparator: (a: IEventTemplate, b: IEventTemplate) =>
    //   new Date(a.lastUpdatedDate).getTime() - new Date(b.lastUpdatedDate).getTime(),
  },
  {
    id: 'actions',
    header: t(i18nKeys.tables.tableConfig.actions),
    minWidth: 130,
    cell: (item: IEventTemplate) => {
      if (item.status === EventTemplateStatusType.PRIVATE || item.status === 'DRAFT') {
        return (
          <div id={`delete-${item.id}`} data-testid={`delete-${item.id}`} onClick={onDeleteClicked?.bind(this, item)}>
            <Button>{t(i18nKeys.general.delete)}</Button>
          </div>
        );
      }
    },
  },
];

export const filteringProperties = (t: TFunction<'translation', undefined>):PropertyFilterProperty[] => [
  {
    key: 'title',
    operators: [
      {
        operator: '=',
        match: (itemValue: unknown, tokenValue: string) => tokenValue.split(',').includes(itemValue as string),
      },
      {
        operator: ':'
      },
    ],
    groupValuesLabel: t(i18nKeys.tables.tableConfig.title),
    propertyLabel: t(i18nKeys.tables.tableConfig.title),
  },
  {
    key: 'duration',
    groupValuesLabel: t(i18nKeys.tables.tableConfig.duration),
    propertyLabel: t(i18nKeys.tables.tableConfig.duration),
  },
  // {
  //   key: 'author',
  //   groupValuesLabel: t(i18nKeys.tables.tableConfig.author),
  //   propertyLabel: t(i18nKeys.tables.tableConfig.author),
  // },
  {
    key: 'status',
    groupValuesLabel: t(i18nKeys.tables.tableConfig.status),
    propertyLabel: t(i18nKeys.tables.tableConfig.status),
  },
  // {
  //   key: 'lastUpdate',
  //   operators: [
  //     { operator: '=', match: 'date' },
  //     { operator: '!=', match: 'date' },
  //     { operator: '<', match: 'date' },
  //     { operator: '<=', match: 'date' },
  //     { operator: '>', match: 'date' },
  //     { operator: '>=', match: 'date' },
  //   ],
  //   groupValuesLabel: t(i18nKeys.tables.tableConfig.lastUpdate),
  //   propertyLabel: t(i18nKeys.tables.tableConfig.lastUpdate),
  // },
];

export const paginationLabels = (t: TFunction<'translation', undefined>) => {
  return {
    nextPageLabel: t(i18nKeys.tables.pagination.nextPage),
    pageLabel: (pageNumber: number) => `${t(i18nKeys.tables.pagination.goToPage)} ${pageNumber}`,
    previousPageLabel: t(i18nKeys.tables.pagination.prevPage),
  };
};

const pageSizePreference = (t: TFunction<'translation', undefined>) => {
  return {
    title: t(i18nKeys.general.preferences.pageSize),
    options: [
      { value: 10, label: t(i18nKeys.general.preferences.resources10) },
      { value: 20, label: t(i18nKeys.general.preferences.resources20) },
    ],
  };
};

const visibleContentPreference = (t: TFunction<'translation', undefined>) => {
  return {
    title: t(i18nKeys.general.preferences.visibleContent.title),
    options: [
      {
        label: t(i18nKeys.general.preferences.visibleContent.optionLabel),
        options: columnDefinitions(t).map(({ id, header }) => ({ id, label: header, editable: id !== 'id' })),
      },
    ],
  };
};

export const visibleContent = ['challengeTitle', 'type', 'category', 'difficulty', 'healthStatus'];

export const collectionPreferencesProps = (t: TFunction<'translation', undefined>) => {
  return {
    pageSizePreference: pageSizePreference(t),
    visibleContentPreference: visibleContentPreference(t),
    cancelLabel: t(i18nKeys.general.cancel),
    confirmLabel: t(i18nKeys.general.confirm),
    title: t(i18nKeys.general.preferences.title),
  };
};
