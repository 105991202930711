import React from 'react';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface IDifficultyProps {
  level: number;
}

const Difficulty: React.FC<IDifficultyProps> = ({ level }) => {
  const { t } = useTranslation();
  const severity: string[] = [
    t(i18nKeys.myJams.challenges.difficulty.easy),
    t(i18nKeys.myJams.challenges.difficulty.medium),
    t(i18nKeys.myJams.challenges.difficulty.hard),
  ];

  return (
    <SpaceBetween direction="horizontal" size="xs" alignItems="center" className={`severity-container-${level}`}>
      <Box className={`circle severity-bg-${level}`} />
      <Box className={`severity-${level}`} fontWeight="bold">
        {severity[level]}
      </Box>
    </SpaceBetween>
  );
};

export default Difficulty;
