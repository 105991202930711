/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext, useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';
import {
  EventDurationInfo,
  EventDurationType,
  EventLearningType,
  EventTemplateRating,
  EventTemplateRationale,
  EventTemplateReportResponse,
  EventTemplateReview,
  EventTemplateReviewResponse,
  EventTemplateStatusType,
  IEventTemplate,
  IEventTemplateChallenge,
} from '@/src/types/EventTemplate';
import { LoggingService } from '@/src/utils/logging-service.utils';
import { useApi } from '@/src/store/api.context';
import {
  BACKUP_CHALLENGE_LIMIT,
  EVENT_TEMPLATE_FEEDBACK_COMMENT_SORT_OPTIONS,
} from '../constants/event-template.constants';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react';
import { visibleContentForChallengeDetail } from '../components/event-templates/EventTemplateCreate/Sections/SelectChallenges/table-config';
import { useHistory, useParams } from 'react-router-dom';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useChallenges } from './challenge.context';
import { ChallengeListItem } from '../types/Challenge';
import { RoutePath } from '../RoutePath';
import { isEqual } from 'lodash';
import { getAwsServicesFromChallengeList, getChallengeLimit } from '../utils/event-template.utils';
import { useEventTemplateOffers } from './event-template-offers.context';

export interface EditEventTemplateContextValue {
  editedEventTemplate: IEventTemplate | null;
  newEditedEventTemplate: IEventTemplate | null;
  eventTemplateEditMode: boolean;
  newEventTemplateMode: boolean;
  eventTemplate: IEventTemplate | null | undefined;
  resetEditEventTemplateContext: () => void;
  fetchEventTemplateById: (id: string) => Promise<void>;
  fetchPublicEventTemplateById: (id: string) => Promise<void>;
  eventTemplateChallengePreferences: CollectionPreferencesProps.Preferences;
  updateEventTemplateByStatus: (status: EventTemplateStatusType) => Promise<void>;
  setEventTemplateEditMode: (value: boolean) => void;
  setNewEditedEventTemplate: (value: IEventTemplate | null) => void;
  setNewEventTemplateMode: (value: boolean) => void;
  getEventDurationInfo: () => EventDurationInfo | undefined;
  reviews: EventTemplateReview[];
  handleSeeMoreClick: () => void;
  filteringText: string;
  setFilteringText: Dispatch<SetStateAction<string>>;
  eventTemplateRating: EventTemplateRating | null;
  selectedEventTemplateFeedbackOption: OptionDefinition;
  setSelectedEventTemplateFeedbackOption: Dispatch<SetStateAction<OptionDefinition>>;
  toggleEventTemplateFeedbackReviewLikeStatus: (id: string) => void;
  eventTemplateReport: EventTemplateReportResponse | null;
  onLearningTypeChange: (learningType: EventLearningType) => void;
  onDurationChange: (eventDuration: EventDurationType) => void;
  onSummaryChange: (summmary: string) => void;
  onTopicsChange: (topics: string[]) => void;
  onTeamSizeChange: (teamSize: number) => void;
  onMinParticipantsChange: (value: number) => void;
  onMaxParticipantsChange: (value: number) => void;
  onTagsChange: (tags: string[]) => void;
  updateEventTemplate: () => Promise<void>;
  deleteEventTemplate: () => void;
  onImageChange: (image?: File) => Promise<void>;
  selectedChallenges: ChallengeListItem[];
  rationale: EventTemplateRationale | undefined;
  onRationaleChange: (id: string, rational: string) => void;
  onSelectedChallengesChange: (items: ChallengeListItem[]) => void;
  onPrimaryBackupChallengesChange: (
    primaryChallenges: ChallengeListItem[],
    backupChallenges: ChallengeListItem[]
  ) => void;
  hasUnsavedChanges: () => boolean;
}

const EditEventTemplateContext = React.createContext<EditEventTemplateContextValue>({
  editedEventTemplate: null,
  newEditedEventTemplate: null,
  eventTemplateEditMode: false,
  newEventTemplateMode: false,
  eventTemplate: null,
  resetEditEventTemplateContext: () => {
    // do nothing
  },
  fetchEventTemplateById: async (_id: string) => {
    // do nothing
  },
  fetchPublicEventTemplateById: async (_id: string) => {
    // do nothing
  },
  updateEventTemplateByStatus: (_status: EventTemplateStatusType) => Promise.resolve(),
  setEventTemplateEditMode: (_value: boolean) => {
    // do nothing
  },
  setNewEditedEventTemplate: (_value: IEventTemplate | null) => {
    // do nothing
  },
  setNewEventTemplateMode: (_value: boolean) => {
    // do nothing
  },
  getEventDurationInfo: () => undefined,
  eventTemplateChallengePreferences: {
    pageSize: 10,
    visibleContent: visibleContentForChallengeDetail,
  } as CollectionPreferencesProps.Preferences,
  reviews: [],
  handleSeeMoreClick: () => {
    // do nothing
  },
  filteringText: '',
  setFilteringText: () => {
    // do nothing
  },
  eventTemplateRating: null,
  selectedEventTemplateFeedbackOption: EVENT_TEMPLATE_FEEDBACK_COMMENT_SORT_OPTIONS[0],
  setSelectedEventTemplateFeedbackOption: () => {
    // do nothing
  },
  toggleEventTemplateFeedbackReviewLikeStatus: (_id: string) => {
    // do nothing
  },
  eventTemplateReport: null,
  onLearningTypeChange: (_learningType: EventLearningType) => {
    // do nothing
  },
  onDurationChange: (_eventDuration: EventDurationType) => {
    // do nothing
  },
  onSummaryChange: (_summmary: string) => {
    // do nothing
  },
  onTopicsChange: (_topics: string[]) => {
    // do nothing
  },
  onTeamSizeChange: (_teamSize: number) => {
    // do nothing
  },
  onMinParticipantsChange: (_value: number) => {
    // do nothing
  },
  onMaxParticipantsChange: (_value: number) => {
    // do nothing
  },
  onTagsChange: (_tags: string[]) => {
    // do nothing
  },
  updateEventTemplate: () => Promise.resolve(),
  deleteEventTemplate: () => {
    // do nothing
  },
  onImageChange: (_imageFile?: File) => Promise.resolve(undefined),
  selectedChallenges: [],
  rationale: undefined,
  onRationaleChange: (_id: string, _rationaleText: string) => {
    // do nothing
  },
  onSelectedChallengesChange: (_items: ChallengeListItem[]) => {
    // do nothing
  },
  onPrimaryBackupChallengesChange: (
    _primaryChallenges: ChallengeListItem[],
    _backupChallenges: ChallengeListItem[]
  ) => {
    // do nothing
  },
  hasUnsavedChanges: () => false,
});

const EditEventTemplateProvider: React.FC = ({ children }) => {
  const { eventTemplateApi } = useApi();
  const { offers } = useEventTemplateOffers();
  const { selectedChallengesById } = useChallenges();
  const itemsPerPage = 3;

  const [editedEventTemplate, setEditedEventTemplate] = useState<IEventTemplate | null>(null);
  const [eventTemplateEditMode, setEventTemplateEditMode] = useState(false);
  const [newEditedEventTemplate, setNewEditedEventTemplate] = useState<IEventTemplate | null>(null);
  const [newEventTemplateMode, setNewEventTemplateMode] = useState(false);
  const [eventTemplate, setEventTemplate] = useState<IEventTemplate>();
  const [eventTemplateChallengePreferences, setEventTemplateChallengePreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      pageSize: 10,
      visibleContent: visibleContentForChallengeDetail,
    } as CollectionPreferencesProps.Preferences);
  const [reviewPageNumber, setReviewPageNumber] = useState(1);
  const [reviews, setReviews] = useState<EventTemplateReview[]>([]);
  const [filteringText, setFilteringText] = React.useState('');
  const [eventTemplateRating, setEventTemplateRating] = useState<EventTemplateRating | null>(null);
  const { eventTemplateId }: { eventTemplateId: string } = useParams();
  const [selectedEventTemplateFeedbackOption, setSelectedEventTemplateFeedbackOption] =
    React.useState<OptionDefinition>(EVENT_TEMPLATE_FEEDBACK_COMMENT_SORT_OPTIONS[0]);

  const [eventTemplateReport, setEventTemplateReport] = useState<EventTemplateReportResponse | null>(null);

  const [selectedChallenges, setSelectedChallenges] = useState<ChallengeListItem[]>([]);
  const [rationale, setRationale] = useState<EventTemplateRationale>();

  const navigate = useHistory();

  useEffect(() => {
    if (eventTemplate) setEditedEventTemplate(!eventTemplateEditMode ? null : { ...eventTemplate });
  }, [eventTemplateEditMode]);

  useEffect(() => {
    void fetchEventTemplateReviewsById(eventTemplateId);
    void fetchEventTemplateRatingsById(eventTemplateId);
  }, [reviewPageNumber, eventTemplateId]);

  useEffect(() => {
    if (filteringText) {
      setReviews(
        reviews.filter(
          (review: EventTemplateReview) =>
            review.review.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase()) ||
            review.userName.toLocaleLowerCase().includes(filteringText.toLocaleLowerCase())
        )
      );
    } else {
      void fetchEventTemplateReviewsById(eventTemplateId);
    }
  }, [filteringText]);

  useEffect(() => {
    if (
      eventTemplate &&
      eventTemplate.challenges.length > 0 &&
      selectedChallengesById &&
      selectedChallengesById.length > 0
    ) {
      const rationaleValues: EventTemplateRationale = {};
      eventTemplate.challenges.forEach((challenge: IEventTemplateChallenge) => {
        if (challenge.id && challenge.rationale) rationaleValues[challenge.id] = challenge.rationale;
      });
      setSelectedChallenges([...selectedChallengesById]);
      setRationale({ ...rationaleValues });
    }
  }, [eventTemplate?.challenges, selectedChallengesById]);

  useEffect(() => {
    if (selectedEventTemplateFeedbackOption) {
      const sortedReviews = [...reviews];
      switch (selectedEventTemplateFeedbackOption.value) {
        case 'rating':
          sortedReviews.sort((a: EventTemplateReview, b: EventTemplateReview) => b.rating - a.rating);
          break;

        case 'createdAt':
          sortedReviews.sort(
            (a: EventTemplateReview, b: EventTemplateReview) => Date.parse(b.date) - Date.parse(a.date)
          );
          break;

        default:
          void fetchEventTemplateReviewsById(eventTemplateId);
          break;
      }
      setReviews(sortedReviews);
    }
  }, [selectedEventTemplateFeedbackOption]);

  const resetEditEventTemplateContext = useCallback(() => {
    setEventTemplate(undefined);
    setEditedEventTemplate(null);
    setNewEditedEventTemplate(null);
    setNewEventTemplateMode(false);
    setEventTemplateChallengePreferences({
      pageSize: 10,
      visibleContent: visibleContentForChallengeDetail,
    });
    setReviewPageNumber(1);
    setReviews([]);
    setFilteringText('');
    setEventTemplateRating(null);
    setSelectedEventTemplateFeedbackOption(EVENT_TEMPLATE_FEEDBACK_COMMENT_SORT_OPTIONS[0]);
    setEventTemplateReport(null);
    setSelectedChallenges([]);
    setRationale(undefined);
  }, []);

  const getEventDurationInfo = useCallback(() => {
    if (!offers || !eventTemplate?.duration) return;
    return offers.get(eventTemplate.duration);
  }, [offers, eventTemplate?.duration]);

  const fetchEventTemplateById = useCallback((id: string) => {
    return eventTemplateApi
      .getEventTemplateById(id)
      .then((response) => {
        if (response) {
          setEventTemplate(response);
        }
      })
      .catch((error: Error) => {
        LoggingService.debug(`Error fetching event template by id: ${JSON.stringify(error)}`);
      });
  }, []);

  const fetchPublicEventTemplateById = useCallback((id: string) => {
    return eventTemplateApi
      .getPublicEventTemplateById(id)
      .then((response) => {
        if (response) {
          setEventTemplate(response);
        }
      })
      .catch((error: Error) => {
        LoggingService.debug(`Error fetching event template by id: ${JSON.stringify(error)}`);
      });
  }, []);

  const updateEventTemplate = useCallback(async () => {
    if (!editedEventTemplate) {
      throw new Error('Edited Template not found');
    }
    try {
      const response = await eventTemplateApi.updateEventTemplateById(editedEventTemplate.id, editedEventTemplate);
      setEventTemplateEditMode(false);
      setEventTemplate(response);
      setEditedEventTemplate(response);
    } catch (error) {
      LoggingService.debug(`Error fetching event template by id: ${JSON.stringify(error)}`);
    }
  }, [editedEventTemplate, eventTemplateApi]);

  const deleteEventTemplate = () => {
    if (eventTemplate) {
      eventTemplateApi
        .deleteEventTemplateById(eventTemplate.id)
        .then((_response) => {
          navigate.replace(`${RoutePath.EVENT_CATALOG_TEMPLATES}`);
        })
        .catch((error: Error) => {
          LoggingService.debug(`Error delete event template by id: ${JSON.stringify(error)}`);
        });
    }
  };

  const fetchEventTemplateReviewsById = useCallback((id: string) => {
    return eventTemplateApi
      .getEventTemplateReviews(id, 50, reviewPageNumber, itemsPerPage)
      .then((eventTemplateReviewResponse: EventTemplateReviewResponse) => {
        if (eventTemplateReviewResponse) {
          setReviews((prevReviews) => [...prevReviews, ...eventTemplateReviewResponse.data]);
        }
      })
      .catch((error: Error) => {
        LoggingService.debug(`Error fetching event template reviews by id: ${JSON.stringify(error)}`);
      });
  }, []);

  const fetchEventTemplateRatingsById = useCallback((id: string) => {
    return eventTemplateApi
      .getEventTemplateRating(id)
      .then((eventTemplateRatingResponse: EventTemplateRating) => {
        if (eventTemplateRatingResponse) {
          setEventTemplateRating(eventTemplateRatingResponse);
        }
      })
      .catch((error: Error) => {
        LoggingService.debug(`Error fetching event template rating by id: ${JSON.stringify(error)}`);
      });
  }, []);

  const handleSeeMoreClick = () => {
    setReviewPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const toggleEventTemplateFeedbackReviewLikeStatus = (id: string): void => {
    const clonedReviews = [...reviews];
    const likedUnlikedReviewIndex: number = clonedReviews.findIndex((review: EventTemplateReview) => review.id === id);
    if (likedUnlikedReviewIndex !== -1) {
      clonedReviews[likedUnlikedReviewIndex].liked = !clonedReviews[likedUnlikedReviewIndex].liked;
      setReviews([...clonedReviews]);
    }
  };

  const fetchEventTemplateDetailReport = useCallback(async (id: string) => {
    return eventTemplateApi
      .getEventTemplateReports(id)
      .then((eventTemplateReportResponse: EventTemplateReportResponse) => {
        if (eventTemplateReportResponse) {
          setEventTemplateReport(eventTemplateReportResponse);
        }
      })
      .catch((error: Error) => {
        LoggingService.debug(`Error fetching event template report by id: ${JSON.stringify(error)}`);
      });
  }, []);

  useEffect(() => {
    void fetchEventTemplateDetailReport(eventTemplateId);
  }, [eventTemplateId]);

  const updateEventTemplateByStatus = useCallback(
    async (newStatus: EventTemplateStatusType) => {
      if (!eventTemplate) {
        throw new Error('Event Template not defined');
      }
      try {
        const response = await eventTemplateApi.updateEventTemplateById(eventTemplate.id, { status: newStatus });
        setEventTemplate(response);
      } catch (error) {
        LoggingService.error('Failed to update Event Template Status');
      }
    },
    [eventTemplate, eventTemplateApi]
  );

  const onDurationChange = useCallback(
    (newDuration: EventDurationType) => {
      if (!editedEventTemplate) return;
      setEditedEventTemplate({
        ...editedEventTemplate,
        duration: newDuration,
      });
    },
    [editedEventTemplate]
  );

  const onLearningTypeChange = (newLearningType: EventLearningType) => {
    if (eventTemplate && editedEventTemplate) {
      if (eventTemplate.learningType === newLearningType) {
        setEditedEventTemplate({
          ...editedEventTemplate,
          learningType: newLearningType,
          duration: eventTemplate.duration,
          recommendedTeamSize: eventTemplate.recommendedTeamSize,
        });
        return;
      }
      const newDuration =
        newLearningType === EventLearningType.INDIVIDUAL ? EventDurationType.SPL : EventDurationType.MEDIUM;
      const newTeamSize = newLearningType === EventLearningType.INDIVIDUAL ? 1 : 0;
      setEditedEventTemplate({
        ...editedEventTemplate,
        learningType: newLearningType,
        duration: newDuration,
        recommendedTeamSize: newTeamSize,
      });
    }
  };

  const onSummaryChange = (newSummary: string) => {
    if (editedEventTemplate) {
      setEditedEventTemplate({
        ...editedEventTemplate,
        summary: newSummary,
      });
    }
  };

  const onTopicsChange = (newTopics: string[]) => {
    if (editedEventTemplate) {
      setEditedEventTemplate({
        ...editedEventTemplate,
        topics: newTopics,
      });
    }
  };

  const onTeamSizeChange = (newTeamSize: number) => {
    if (editedEventTemplate) {
      setEditedEventTemplate({
        ...editedEventTemplate,
        recommendedTeamSize: newTeamSize,
      });
    }
  };

  const onMinParticipantsChange = (value: number) => {
    if (editedEventTemplate) {
      setEditedEventTemplate({
        ...editedEventTemplate,
        minParticipants: value,
      });
    }
  };

  const onMaxParticipantsChange = (value: number) => {
    if (editedEventTemplate) {
      setEditedEventTemplate({
        ...editedEventTemplate,
        maxParticipants: value,
      });
    }
  };

  const onTagsChange = (newTags: string[]) => {
    if (editedEventTemplate) {
      setEditedEventTemplate({
        ...editedEventTemplate,
        tags: newTags,
      });
    }
  };

  const onImageChange = useCallback(
    async (newImageFile?: File) => {
      if (!editedEventTemplate || !editedEventTemplate.id) {
        throw new Error('eventTempalte not defined');
      }

      if (!newImageFile) {
        setEditedEventTemplate({ ...editedEventTemplate, imagePresignedUrl: undefined });
        return;
      }

      const presignedImageUrl = await eventTemplateApi.uploadEventTemplateImage(editedEventTemplate.id, newImageFile);
      setEditedEventTemplate({ ...editedEventTemplate, imagePresignedUrl: presignedImageUrl });
    },
    [editedEventTemplate, eventTemplateApi]
  );

  const onRationaleChange = (challengeId: string, rationaleText: string) => {
    setRationale({
      ...rationale,
      [challengeId]: rationaleText,
    });
    if (editedEventTemplate) {
      const clonedChallenges = [...editedEventTemplate.challenges];
      const challengeIndex = clonedChallenges.findIndex(
        (challenge: IEventTemplateChallenge) => challenge.id === challengeId
      );
      clonedChallenges[challengeIndex].rationale = rationaleText;
      setEditedEventTemplate({
        ...editedEventTemplate,
        challenges: clonedChallenges,
      });
    }
  };

  const onSelectedChallengesChange = (items: ChallengeListItem[]) => {
    if (!editedEventTemplate) return;
    const challengeLimit = getChallengeLimit({
      minChallenges: getEventDurationInfo()?.minChallenges ?? 0,
      maxChallenges: getEventDurationInfo()?.maxChallenges ?? 0,
      backupChallenges: BACKUP_CHALLENGE_LIMIT,
      challengesLen: items.length,
    });

    setEditedEventTemplate({
      ...editedEventTemplate,
      challengeList: items,
      challenges: items.map((item, index) => {
        return {
          id: item.challengeId ?? '',
          rationale: rationale?.[item?.challengeId ?? ''] ?? '',
          backup: index >= challengeLimit,
        };
      }),
      awsServices: getAwsServicesFromChallengeList(items),
    });
    setSelectedChallenges([...items]);
  };

  const onPrimaryBackupChallengesChange = (primaryItems: ChallengeListItem[], backupItems: ChallengeListItem[]) => {
    if (!editedEventTemplate) return;

    const items = [...primaryItems, ...backupItems];
    const selectedChallengeItems = [];
    for (const item of primaryItems) {
      selectedChallengeItems.push({
        id: item.challengeId ?? '',
        rationale: rationale?.[item?.challengeId ?? ''] ?? '',
        backup: false,
      });
    }
    for (const item of backupItems) {
      selectedChallengeItems.push({
        id: item.challengeId ?? '',
        rationale: rationale?.[item?.challengeId ?? ''] ?? '',
        backup: true,
      });
    }
    setEditedEventTemplate({
      ...editedEventTemplate,
      challengeList: items,
      challenges: selectedChallengeItems,
      awsServices: getAwsServicesFromChallengeList(items),
    });
  };

  const hasUnsavedChanges = useCallback(() => {
    if (!eventTemplateEditMode || !editedEventTemplate) {
      return false;
    }
    return !isEqual(eventTemplate, editedEventTemplate);
  }, [eventTemplate, editedEventTemplate, eventTemplateEditMode]);

  const data: EditEventTemplateContextValue = {
    editedEventTemplate,
    newEditedEventTemplate,
    eventTemplateEditMode,
    newEventTemplateMode,
    eventTemplate,
    fetchEventTemplateById,
    fetchPublicEventTemplateById,
    updateEventTemplateByStatus,
    setEventTemplateEditMode,
    setNewEditedEventTemplate,
    setNewEventTemplateMode,
    getEventDurationInfo,
    eventTemplateChallengePreferences,
    resetEditEventTemplateContext,
    reviews,
    handleSeeMoreClick,
    filteringText,
    setFilteringText,
    eventTemplateRating,
    selectedEventTemplateFeedbackOption,
    setSelectedEventTemplateFeedbackOption,
    toggleEventTemplateFeedbackReviewLikeStatus,
    eventTemplateReport,
    onLearningTypeChange,
    onDurationChange,
    onSummaryChange,
    onTopicsChange,
    onTeamSizeChange,
    onMinParticipantsChange,
    onMaxParticipantsChange,
    onTagsChange,
    updateEventTemplate,
    onImageChange,
    selectedChallenges,
    rationale,
    onRationaleChange,
    onSelectedChallengesChange,
    onPrimaryBackupChallengesChange,
    deleteEventTemplate,
    hasUnsavedChanges,
  };

  return <EditEventTemplateContext.Provider value={data}>{children}</EditEventTemplateContext.Provider>;
};

const useEditEventTemplate = () => {
  const context = useContext(EditEventTemplateContext);
  if (context === undefined) {
    throw new Error('useEditEventTemplate can only be used inside EditEventTemplateProvider');
  }
  return context;
};

export { EditEventTemplateProvider, useEditEventTemplate };
