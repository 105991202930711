/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Container,
  FormField,
  Header,
  Hotspot,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengePropAction,
  ChallengeSettingsFields,
  useCreateChallenge,
} from '../../../store/create-challenge.context';
import { i18nKeys } from '../../../utils/i18n.utils';
import { safeString } from '../../../utils/string.utils';
import { ChallengeHotspot } from '../challengesCommon/ChallengeHotspots';
import {
  AWSServicesDefinitions,
  DifficultyDefinitions,
  LearningTypeDefinitions,
  SettingTypeDefinitions,
  SupportedRegionsDefinitionsWithID,
} from '../challengesCommon/ChallengeOptionDefinitions';

export interface ChallengeSettingsOutcomeSectionProps {
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

const ChallengeSettings: React.FC<ChallengeSettingsOutcomeSectionProps> = ({ validationHandler }) => {
  const { t } = useTranslation();
  const { handleUpdateChallengeProp, editedChallenge, challengeSettingsValidator } = useCreateChallenge();
  const field = i18nKeys.challenges.subSections.settings;

  const getTranslatedOptions: (opt: OptionDefinition[]) => OptionDefinition[] = (optDefns: OptionDefinition[]) => {
    return optDefns.map((i) => {
      return { label: t(safeString(i.label)), value: safeString(i.value), description: t(safeString(i.description)) };
    });
  };

  // Basic Settings
  const settingTypeDefs: OptionDefinition[] = getTranslatedOptions(SettingTypeDefinitions);
  const difficulties: OptionDefinition[] = getTranslatedOptions(DifficultyDefinitions);
  const learningTypes: OptionDefinition[] = getTranslatedOptions(LearningTypeDefinitions);

  const props = editedChallenge?.props;
  const [basicSettings, setBasicSettings] = useState(() => {
    return {
      jamType: props?.jamType || '',
      category: props?.category || '',
      difficulty: (props?.difficulty ?? '').toString(),
      learningType: props?.learningType || '',
    };
  });
  const handleBasicSettingsUpdate = (event: {
    target: { name: ChallengeSettingsFields; selectedOption: OptionDefinition | undefined };
  }) => {
    const { name, selectedOption } = event.target;
    const updatedSettings = {
      ...basicSettings,
      [name]: selectedOption?.value,
    };
    setBasicSettings(updatedSettings);
    handleUpdateChallengeProp(ChallengePropAction.BASIC_SETTINGS, updatedSettings);
  };

  // AWS Settings
  const [awsSettings, setAwsSettings] = useState(() => {
    return {
      awsServices: props?.awsServices || [],
      regionAllowList: props?.regionAllowlist || [],
    };
  });
  const handleAwsSettingsUpdate = (event: { target: { name: string; value: readonly OptionDefinition[] } }) => {
    const { name, value } = event.target;
    const newSettings = {
      ...awsSettings,
      [name]: value.map((option) => option.value),
    };

    handleUpdateChallengeProp(ChallengePropAction.AWS_SETTINGS, newSettings);
    setAwsSettings(newSettings);
  };

  // error texts and validation logic
  const [awsServicesErrorText, setAwsServicesErrorText] = useState<string>('');
  const [supportedRegionsErrorText, setSupportedRegionsErrorText] = useState<string>('');
  const [typeErrorText, setTypeErrorText] = useState<string>('');
  const [categoryErrorText, setCategoryErrorText] = useState<string>('');
  const [difficultyErrorText, setDifficultyErrorText] = useState<string>('');
  const [learningTypeErrorText, setLearningTypeErrorText] = useState<string>('');

  const validator = challengeSettingsValidator(
    new Map<ChallengeSettingsFields, (error: string) => void>([
      [ChallengeSettingsFields.JAM_TYPE, (error: string) => setTypeErrorText(error)],
      [ChallengeSettingsFields.CATEGORY, (error: string) => setCategoryErrorText(error)],
      [ChallengeSettingsFields.DIFFICULTY, (error: string) => setDifficultyErrorText(error)],
      [ChallengeSettingsFields.LEARNING_TYPE, (error: string) => setLearningTypeErrorText(error)],
      [ChallengeSettingsFields.AWS_SERVICES, (error: string) => setAwsServicesErrorText(error)],
      [ChallengeSettingsFields.REGION_ALLOWLIST, (error: string) => setSupportedRegionsErrorText(error)],
    ])
  );

  validationHandler(() => {
    return Promise.resolve(validator.isValidSection(true));
  });

  return (
    <SpaceBetween direction="vertical" size="l">
      <Container header={<Header variant="h2"> {t(field.container_1.title)} </Header>}>
        <SpaceBetween direction="vertical" size="l">
          <FormField
            label={t(field.container_1.fields.type.title) + '*'}
            constraintText={t(field.container_1.fields.type.constraint)}
            errorText={typeErrorText}>
            <Hotspot direction="right" hotspotId={ChallengeHotspot.basicSettingsType}>
              <Select
                onBlur={() => validator.isValidField(ChallengeSettingsFields.JAM_TYPE)}
                selectedOption={settingTypeDefs.find((item) => item.value === basicSettings.jamType) || null}
                data-testid="challengeSelectType"
                onChange={({ detail }) => {
                  handleBasicSettingsUpdate({
                    target: {
                      name: ChallengeSettingsFields.JAM_TYPE,
                      selectedOption: detail.selectedOption,
                    },
                  });
                }}
                options={settingTypeDefs}
                selectedAriaLabel={t(i18nKeys.general.selected)}
                placeholder={t(field.container_1.fields.type.placeholder)}
              />
            </Hotspot>
          </FormField>
          <FormField
            label={t(field.container_1.fields.category.title) + '*'}
            constraintText={t(field.container_1.fields.category.constraint)}
            errorText={categoryErrorText}>
            <Hotspot direction="right" hotspotId={ChallengeHotspot.basicSettingsCategory}>
              <Input
                onBlur={() => validator.isValidField(ChallengeSettingsFields.CATEGORY)}
                onChange={({ detail }) => {
                  handleBasicSettingsUpdate({
                    target: {
                      name: ChallengeSettingsFields.CATEGORY,
                      selectedOption: detail,
                    },
                  });
                }}
                data-testid="challengeCategoryInput"
                value={safeString(basicSettings.category)}
                autoComplete
                placeholder={t(field.container_1.fields.category.placeholder)}
              />
            </Hotspot>
          </FormField>
          <FormField
            label={t(field.container_1.fields.difficulty.title) + '*'}
            constraintText={t(field.container_1.fields.difficulty.constraint)}
            errorText={difficultyErrorText}>
            <Hotspot direction="right" hotspotId={ChallengeHotspot.basicSettingsDifficulty}>
              <Select
                selectedOption={difficulties.find((item) => item.value === basicSettings.difficulty) || null}
                data-testid="challengeDifficultyInput"
                onChange={({ detail }) =>
                  handleBasicSettingsUpdate({
                    target: {
                      name: ChallengeSettingsFields.DIFFICULTY,
                      selectedOption: detail.selectedOption,
                    },
                  })
                }
                options={difficulties}
                selectedAriaLabel={t(i18nKeys.general.selected)}
                placeholder={t(field.container_1.fields.difficulty.placeholder)}
              />
            </Hotspot>
          </FormField>
          <FormField
            label={t(field.container_1.fields.learningType.title) + '*'}
            constraintText={t(field.container_1.fields.learningType.constraint)}
            errorText={learningTypeErrorText}>
            <Hotspot direction="right" hotspotId={ChallengeHotspot.basicSettingsLearningType}>
              <Select
                selectedOption={learningTypes.find((item) => item.value === basicSettings.learningType) || null}
                data-testid="challengeLearningTypeInput"
                onChange={({ detail }) =>
                  handleBasicSettingsUpdate({
                    target: {
                      name: ChallengeSettingsFields.LEARNING_TYPE,
                      selectedOption: detail.selectedOption,
                    },
                  })
                }
                options={learningTypes}
                selectedAriaLabel={t(i18nKeys.general.selected)}
                placeholder={t(field.container_1.fields.learningType.placeholder)}
              />
            </Hotspot>
          </FormField>
        </SpaceBetween>
      </Container>
      <Container header={<Header variant={'h2'}> {t(field.container_3.title)} </Header>}>
        <SpaceBetween direction="vertical" size="l">
          <FormField
            label={t(field.container_3.fields.awsServices.title) + '*'}
            constraintText={t(field.container_3.fields.awsServices.constraint)}
            errorText={awsServicesErrorText}>
            <Hotspot direction="right" hotspotId={ChallengeHotspot.awsSettingsServices}>
              <Multiselect
                ariaRequired
                data-testid="challengeAwsServicesSelect"
                selectedOptions={
                  awsSettings.awsServices.map(
                    (serviceName) =>
                      AWSServicesDefinitions.find((service) => service.value === serviceName) as OptionDefinition
                  ) || []
                }
                onBlur={() => validator.isValidField(ChallengeSettingsFields.AWS_SERVICES)}
                onChange={({ detail }) => {
                  handleAwsSettingsUpdate({
                    target: {
                      name: ChallengeSettingsFields.AWS_SERVICES,
                      value: detail.selectedOptions,
                    },
                  });
                  validator.isValidField(ChallengeSettingsFields.AWS_SERVICES);
                }}
                options={AWSServicesDefinitions}
                ariaLabel={t(i18nKeys.general.selected)}
                deselectAriaLabel={(e) => `${t(i18nKeys.general.remove)} ${e.label}`}
                selectedAriaLabel={t(i18nKeys.general.selected)}
                placeholder={t(field.container_3.fields.awsServices.placeholder)}
              />
            </Hotspot>
          </FormField>
          <FormField
            label={t(field.container_3.fields.supportedRegions.title) + '*'}
            constraintText={t(field.container_3.fields.supportedRegions.constraint)}
            errorText={supportedRegionsErrorText}>
            <Hotspot direction="right" hotspotId={ChallengeHotspot.awsSettingsRegions}>
              <Multiselect
                ariaRequired
                data-testid="challengeRegionSelect"
                selectedOptions={
                  awsSettings.regionAllowList.map(
                    (region) =>
                      SupportedRegionsDefinitionsWithID.find(
                        (supportedRegion) => supportedRegion.value === region
                      ) as OptionDefinition
                  ) || []
                }
                onBlur={() => validator.isValidField(ChallengeSettingsFields.REGION_ALLOWLIST)}
                onChange={({ detail }) => {
                  handleAwsSettingsUpdate({
                    target: {
                      name: ChallengeSettingsFields.REGION_ALLOWLIST,
                      value: detail.selectedOptions,
                    },
                  });
                  validator.isValidField(ChallengeSettingsFields.REGION_ALLOWLIST);
                }}
                options={SupportedRegionsDefinitionsWithID}
                ariaLabel={t(i18nKeys.general.selected)}
                deselectAriaLabel={(e) => `${t(i18nKeys.general.remove)} ${e.label}`}
                selectedAriaLabel={t(i18nKeys.general.selected)}
                placeholder={t(field.container_3.fields.supportedRegions.placeholder)}
              />
            </Hotspot>
          </FormField>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};

export default ChallengeSettings;
