import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Icon, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useApi } from '@/src/store/api.context';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import { JamSupportChat } from '@/src/types/JamSupportChat';
import ChatButton from '../../atoms/ChatButton/ChatButton';
import { getRightOffset } from '@/src/utils/chat.utils';
import SupportChatStartModal from '../../molecules/MyJams/SupportChatStartModal/SupportChatStartModal';
import JamChat from '../../molecules/MyJams/JamChat/JamChat';
import './JamChatContainer.scss';
import { ChatMessage } from '@/src/types/Chat';
import { useJamChat } from '@/src/store/jam-chat.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { useCampaigns } from '@/src/store/campaigns.context';

interface JamChatContainerProps {
  isModal?: boolean;
}

const JamChatContainer: React.FC<JamChatContainerProps> = ({ isModal = true }) => {
  const { t } = useTranslation();
  const { event, eventName, eventSlug } = useJamEventDetails();
  const {
    teamChat,
    supportChats: allSupportChats,
    activeChatType,
    setActiveChatType,
    unreadTeamChatMessage,
    selectedSupportChatId,
    setSelectedSupportChatId,
    viewAllSupportChats,
    setViewAllSupportChats,
    unreadSupportChatCounter,
    updateSupportChatReadCounter,
  } = useJamChat();
  const { showCampaignRules, campaignStarted } = useCampaigns();
  const { jamMessageApi, jamSupportChatApi } = useApi();
  const { addErrorFlashbar } = useFlashbars();
  const [showEndConfirm, setShowEndConfirm] = useState(false);
  const [isEnding, setIsEnding] = useState(false);
  const [showSupportChatModal, setShowSupportChatModal] = useState(false);

  // open the support chat modal if
  // user just started the chat
  const openSupportChat = useRef(false);
  const buttonRefs = useRef<Record<string, HTMLDivElement>>({});

  const activeChats = allSupportChats || [];
  const teamMessages = teamChat?.chatMessages || [];

  const isSupportUser = !!event?.supportUser;
  const hasTeamChatEnabled = event?.teamChatEnabled;
  const isSupportChatActive = activeChatType === 'support';
  const isTeamChatActive = activeChatType === 'team';
  const isModalActive = isTeamChatActive || (!event?.facilitator && isSupportChatActive) || viewAllSupportChats;
  const hideForCampaign = event?.type === "CAMPAIGN_GROUP" && !isSupportUser && !event.ended && (!campaignStarted || showCampaignRules)

  const selectedSupportChat = useMemo(() => {
    if (!selectedSupportChatId) return undefined;
    const chat = activeChats.find(({ id }) => id === selectedSupportChatId) as JamSupportChat;
    return chat;
  }, [activeChats, selectedSupportChatId]);

  const supportChats = useMemo(() => {
    if (!activeChats.length) {
      return [];
    }
    return activeChats.filter((chat) => chat.active);
  }, [activeChats]);

  const chatMessages = useMemo<ChatMessage[]>(() => {
    if (isTeamChatActive) {
      return teamMessages;
    }
    if (isSupportChatActive && selectedSupportChatId) {
      return selectedSupportChat?.messages || [];
    }
    return [];
  }, [teamMessages, selectedSupportChat, activeChatType, selectedSupportChatId]);

  const sendTeamChatMessage = async (message: string) => {
    try {
      await jamMessageApi.sendTeamChatMessage({
        eventName,
        message,
        silent: true,
      });
    } catch (e) {
      // error handled in API
    }
  };

  const sendSupportChatMessage = (message: string) => {
    if (allSupportChats.length) {
      void jamSupportChatApi.sendSupportChatMessage(selectedSupportChat as JamSupportChat, message, true);
    }
  };

  const onCloseWindow = () => {
    setActiveChatType(undefined);
    setSelectedSupportChatId(undefined);
  };

  const sendMessage = (message: string) => {
    if (isTeamChatActive) {
      void sendTeamChatMessage(message);
    } else {
      void sendSupportChatMessage(message);
    }
  };

  const startSupportChat = async (params: { challengeId: string | null; reason: string }) => {
    try {
      openSupportChat.current = true;
      await jamSupportChatApi.createSupportChat(eventName, params.challengeId, params.reason, true);
    } catch (e) {
      openSupportChat.current = false;
    }
  };

  const endSupportChat = async () => {
    try {
      if (allSupportChats.length) {
        await jamSupportChatApi.endSupportChat(selectedSupportChat as JamSupportChat, true);
        setShowEndConfirm(false);
      }
    } catch (e) {
      setIsEnding(false);
    }
  };

  const onTeamChatPress = () => {
    setViewAllSupportChats(false);
    setActiveChatType(isTeamChatActive ? undefined : 'team');
  };

  const onSupportChatPress = (id?: string) => {
    if (id) {
      updateSupportChatReadCounter(id, 0);
    }
    if (selectedSupportChatId) {
      if (selectedSupportChatId !== id) {
        setSelectedSupportChatId(id);
        setActiveChatType('support');
        return;
      }
      setActiveChatType(isSupportChatActive ? undefined : 'support');
      return;
    }
    setShowSupportChatModal(true);
  };

  const onSupportChatSelect = (chat: JamSupportChat) => {
    updateSupportChatReadCounter(chat.id, 0);
    setSelectedSupportChatId(chat.id);
    setActiveChatType('support');
  };

  const onSupportChatBackPress = () => {
    setSelectedSupportChatId(undefined);
    setActiveChatType(undefined);
  };

  const onSupportChatMinimizePress = (id: string) => {
    if (!event?.facilitator) {
      // if the chat is ended by facilitator. remove modal on minimize
      if (isSupportChatActive && !selectedSupportChat?.active) {
        onCloseWindow();
        return;
      }
      onSupportChatPress(id);
    }
    setViewAllSupportChats(false);
  };

  const openChatExternal = () => {
    window.open(JAM_EVENT_DETAILS_ROUTES.TeamChat.resolve(eventSlug), '_blank', 'toolbar=0,location=0,menubar=0');
  };

  const openTeamExternal = () => {
    if (!selectedSupportChat?.creatorTeamName) {
      addErrorFlashbar(t(i18nKeys.jamChat.supportChat.errors.noTeam));
      return;
    }
    window.open(JAM_EVENT_DETAILS_ROUTES.TeamInfo.resolve([eventSlug, selectedSupportChat.teamId]), '_blank');
  };

  useEffect(() => {
    // this is for full screen where chat is not a modal
    // and for now we are only supporting full screen for
    // team chat
    if (!isModal) {
      setActiveChatType('team');
    }
  }, [isModal]);

  useEffect(() => {
    if (
      // switch support chat if selected chat is not active anymore
      (selectedSupportChatId && supportChats.find(({ id }) => id === selectedSupportChatId)?.active) ||
      !supportChats.length ||
      event?.facilitator
    ) {
      return;
    }
    setSelectedSupportChatId(supportChats[0].id);
    if (openSupportChat.current) {
      setActiveChatType('support');
      openSupportChat.current = false;
    }
  }, [selectedSupportChatId, supportChats]);

  useEffect(() => {
    // hide support chat for facilitator
    // if all the active support chats are
    // ended
    if (!supportChats.length) {
      setViewAllSupportChats(false);
    }
  }, [supportChats]);

  useEffect(() => {
    // reset all data when you leave the game ui
    return () => {
      setViewAllSupportChats(false);
      setSelectedSupportChatId(undefined);
      setActiveChatType(undefined);
    };
  }, []);

  const renderEndChatButton = () => {
    return (
      <Button data-classname="danger" onClick={() => setShowEndConfirm(true)}>
        {t(i18nKeys.jamChat.actions.endChat)}
      </Button>
    );
  };

  const renderChatHeader = () => {
    if (isSupportChatActive || viewAllSupportChats) {
      return (
        <div className="chat-header-outer">
          <div className="chat-header support-header">
            <Box variant="h3">{getSupportChatTitle(selectedSupportChat)}</Box>
            <SpaceBetween direction="horizontal" size="xs">
              {selectedSupportChat?.active && !isSupportUser && renderEndChatButton()}
              <button
                aria-label={t(i18nKeys.jamChat.ariaLabels.minimizeChat)}
                type="button"
                className="minimize"
                onClick={() => onSupportChatMinimizePress(selectedSupportChat?.id as string)}>
                <img alt="minimize" src="/assets/minus.svg" />
              </button>
            </SpaceBetween>
          </div>
          {isSupportUser && selectedSupportChatId && (
            <div>
              <div className="action-bar">
                <Button
                  iconName="angle-left"
                  variant="inline-link"
                  data-classname="back"
                  onClick={onSupportChatBackPress}>
                  {t(i18nKeys.general.back)}
                </Button>
                <div className="flexed" />
                <SpaceBetween direction="horizontal" size="xs">
                  <Button data-classname="info" iconName="external" onClick={openTeamExternal}>
                    {t(i18nKeys.jamChat.titles.teamInfo)}
                  </Button>

                  {selectedSupportChat?.active && renderEndChatButton()}
                </SpaceBetween>
              </div>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="chat-header">
        <Box variant="h3">{t(i18nKeys.myJams.team.teamChat)}</Box>
        {isModal && (
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              ariaLabel={t(i18nKeys.jamChat.ariaLabels.openTeamChat)}
              variant="icon"
              iconName="external"
              onClick={openChatExternal}
            />
            <button
              aria-label={t(i18nKeys.jamChat.ariaLabels.minimizeChat)}
              type="button"
              className="minimize"
              onClick={onTeamChatPress}>
              <img alt="minimize" src="/assets/minus.svg" />
            </button>
          </SpaceBetween>
        )}
      </div>
    );
  };

  const getChatOuterOffset = (): Record<string, string> => {
    if ((isTeamChatActive && !viewAllSupportChats) || !buttonRefs.current.supportChats) {
      return {};
    }

    return getRightOffset(buttonRefs.current.supportChats);
  };

  const renderChatModal = () => {
    if (viewAllSupportChats && !selectedSupportChatId && event?.facilitator) {
      return (
        <div className="chat-outer" style={getChatOuterOffset()}>
          {renderChatHeader()}
          {supportChats.map((supportChat) => (
            <button
              className="supportchat-item"
              onClick={(e) => {
                e.preventDefault();
                onSupportChatSelect(supportChat);
              }}
              key={supportChat.id}>
              {!!unreadSupportChatCounter[supportChat.id] && (
                <span className="counter">{unreadSupportChatCounter[supportChat.id] || 0}</span>
              )}
              <span className="chat-title">{supportChat.creatorDisplayName}</span>
              <Icon name="angle-right" />
            </button>
          ))}
        </div>
      );
    }
    return (
      <div className="chat-outer" style={getChatOuterOffset()}>
        {renderChatHeader()}
        <div className="chat" key={`${activeChatType}-${selectedSupportChatId}`}>
          <JamChat
            disabled={isSupportChatActive ? !selectedSupportChat?.active : false}
            messages={chatMessages}
            sendMessage={sendMessage}
            closeWindow={onCloseWindow}
          />
        </div>
      </div>
    );
  };

  const getSupportChatTitle = (chat?: JamSupportChat) => {
    if (!chat) {
      if (viewAllSupportChats) {
        return t(i18nKeys.jamChat.supportChat.supportChats);
      }
      return t(i18nKeys.jamChat.supportChat.title);
    }
    return `${t(i18nKeys.jamChat.supportChat.title)} ${isSupportUser ? ` - ${chat.creatorDisplayName}` : ''}`;
  };

  const renderSupportChatButtons = () => {
    if (!event?.supportChatEnabled) {
      return null;
    }

    if (!supportChats.length && !event.facilitator) {
      return (
        <ChatButton
          onClick={() => onSupportChatPress()}
          chatType="support"
          title={t(i18nKeys.jamChat.supportChat.title)}
          counter={0}
          isActive={false}
          key={'Support-Chat'}
          id="supportChatButton"
        />
      );
    }

    if (event.facilitator && supportChats.length) {
      return (
        <ChatButton
          onClick={() => {
            setViewAllSupportChats((prev) => !prev);
          }}
          chatType="support"
          title={t(i18nKeys.jamChat.supportChat.supportChats)}
          counter={Object.values(unreadSupportChatCounter).filter((count) => count).length}
          isActive={viewAllSupportChats}
          key={'Support-Chats'}
          ref={(ref: HTMLDivElement) => (buttonRefs.current.supportChats = ref)}
          id="supportChatButton"
        />
      );
    }

    return supportChats.map((chat, index) => (
      <ChatButton
        onClick={() => onSupportChatPress(chat.id)}
        chatType="support"
        title={getSupportChatTitle(chat)}
        counter={unreadSupportChatCounter[chat.id] || 0}
        isActive={isSupportChatActive && selectedSupportChatId === chat.id}
        key={chat.id}
        ref={(ref: HTMLDivElement) => (buttonRefs.current.supportChats = ref)}
        id={`supportChatButton-${index}`}
      />
    ));
  };

  const renderChatButton = () => (
    <div className="chat-buttons">
      <SpaceBetween size="xs" direction="horizontal">
        {renderSupportChatButtons()}
        {hasTeamChatEnabled && (
          <ChatButton
            onClick={onTeamChatPress}
            chatType="team"
            title={t(i18nKeys.myJams.team.teamChat)}
            counter={unreadTeamChatMessage}
            isActive={isTeamChatActive}
            id="teamChatButton"
          />
        )}
      </SpaceBetween>
    </div>
  );

  return (
    <div className={`jam-chat-container ${isModal ? 'chat-modal' : 'fullscreen'}`}>
      {isModalActive && renderChatModal()}
      {!hideForCampaign && isModal && renderChatButton()}
      {showSupportChatModal && (
        <SupportChatStartModal
          handleClose={() => setShowSupportChatModal(false)}
          challengeOptions={
            (event?.challenges || []).map((challenge) => ({
              value: challenge.id,
              label: challenge?.title || challenge.id,
            })) || []
          }
          onStartSupportChat={startSupportChat}
        />
      )}

      <Modal
        visible={showEndConfirm}
        header={t(i18nKeys.jamChat.actions.endChatConfirm)}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={isEnding} variant="link" onClick={() => setShowEndConfirm(false)}>
                {t(i18nKeys.general.cancel)}
              </Button>

              <Button loading={isEnding} variant="primary" onClick={() => void endSupportChat()}>
                {t(i18nKeys.jamChat.actions.endChat)}
              </Button>
            </SpaceBetween>
          </Box>
        }>
        {t(i18nKeys.jamChat.actions.areYouSureEnd)}
      </Modal>
    </div>
  );
};

export default JamChatContainer;
