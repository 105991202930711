import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlans } from '../../../store/usage-plan/usage-plan.context';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../common/PageHeader';
import { UsagePlan } from '../../../types/usage-plan/UsagePlan';
import { Columns } from '../../common/Columns';
import { Container, Header, Tabs } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { Column } from '../../common/Column';
import { KeyValue } from '../../common/KeyValue';
import UsagePlanDetailsSummary from './UsagePlanDetailsSummary';
import UsagePlanDetailsRequests from './UsagePlanDetailsRequests';
import UsagePlanDetailsVersion from './UsagePlanDetailsVersion';
import Comments from '../../common/Comments/Comments';
import { useSplitPanel } from '../../../store/split-panel.context';
import UsagePlanDetailsUtilization from './UsagePlanDetailsUtilization';
import { useUser } from '../../../store/user.context';
import { LoadingBar } from '../../common/LoadingBar';

const UsagePlanDetails: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { planId }: { planId: string } = useParams();
  const { usagePlanDetails, getPlanDetails, comments, getComments, addComment, updateComment, deleteComment } =
    usePlans();
  const { toggleShowSplitPanel } = useSplitPanel();

  const getLatestVersion = () => {
    return usagePlanDetails?.findLatestVersion() || new UsagePlan();
  };

  const loading = getLatestVersion().id !== planId;

  useEffect(() => {
    if (loading) {
      getPlanDetails(planId);
      if (user?.isSuperAdmin) getComments(planId);
    }
  }, [usagePlanDetails]);

  if (loading) {
    return <LoadingBar />;
  }

  const handleTabChange = (activeTabId: string) => {
    if (activeTabId === 'versions') {
      toggleShowSplitPanel(true);
    } else {
      toggleShowSplitPanel(false);
    }
  };

  const visibleTabs = () => {
    const tabs = [
      {
        label: t(i18nKeys.general.details),
        id: 'details',
        content: (
          <UsagePlanDetailsSummary
            usagePlan={getLatestVersion()}
            canEdit={user?.isSuperAdmin || getLatestVersion().isMaintainer(user)}
          />
        ),
      },
    ];
    if (user?.isSuperAdmin) {
      tabs.push(
        ...[
          {
            label: t(i18nKeys.general.utilization),
            id: 'utilization',
            content: <UsagePlanDetailsUtilization usagePlan={getLatestVersion()} />,
          },
          {
            label: t(i18nKeys.usagePlan.overview.requests),
            id: 'requests',
            content: (
              <UsagePlanDetailsRequests
                requests={usagePlanDetails?.requests || []}
                usagePlanId={getLatestVersion().id}
              />
            ),
          },
          {
            label: t(i18nKeys.general.versions),
            id: 'versions',
            content: <UsagePlanDetailsVersion versions={usagePlanDetails?.versions || []} />,
          },
          {
            label: t(i18nKeys.comments.header),
            id: 'comments',
            content: (
              <Comments
                comments={comments}
                addNewComment={addComment}
                deleteComment={deleteComment}
                updateComment={updateComment}
              />
            ),
          },
        ]
      );
    }
    return tabs;
  };

  return (
    <div>
      <PageHeader title={getLatestVersion().name} />
      {user?.isSuperAdmin && (
        <Container header={<Header variant="h2">{t(i18nKeys.usagePlan.overview.description)}</Header>}>
          <Columns columns={3} variant={'text-grid'}>
            <Column size="s">
              <KeyValue className="secondary-text" label={t(i18nKeys.usagePlan.overview.total)}>
                {usagePlanDetails?.requests.length}
              </KeyValue>
            </Column>
            <Column size="s">
              <KeyValue className="secondary-text" label={t(i18nKeys.usagePlan.overview.restricted)}>
                {(usagePlanDetails?.requests ?? []).filter((r) => !r.isApproved()).length}
              </KeyValue>
            </Column>
            <Column size="s">
              <KeyValue className="secondary-text" label={t(i18nKeys.usagePlan.overview.approved)}>
                {(usagePlanDetails?.requests ?? []).filter((r) => r.isApproved()).length}
              </KeyValue>
            </Column>
          </Columns>
        </Container>
      )}
      <Tabs onChange={({ detail }) => handleTabChange(detail.activeTabId)} tabs={visibleTabs()} />
    </div>
  );
};

export default UsagePlanDetails;
