/* eslint-disable @typescript-eslint/unbound-method */
import { ChatMessage } from './Chat';


export class JamSupportChatMessage {
  id: string;
  active = true;
  createdBy: string;
  chatMessage: ChatMessage;
  creatorDisplayName: string;
  creatorTeamName: string;
  teamId: string;
  assignedToDisplayName: string;
  assignedTo: string;
  isAssignee: boolean;
  timestamp: number;

  static fromPlainObject(jsonObject: any): JamSupportChatMessage {
    const supportChat = new JamSupportChatMessage();
    Object.assign(supportChat, jsonObject);
    supportChat.assignedToDisplayName = supportChat.assignedTo;

    return supportChat;
  }
}