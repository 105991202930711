import { Container, FormField, Header, Hotspot, Input, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengeOverviewFields,
  ChallengePropAction,
  CHALLENGE_ID_MAX_LENGTH,
  CHALLENGE_ID_MIN_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  TITLE_MAX_LENGTH,
  TITLE_MIN_LENGTH,
  useCreateChallenge,
} from '../../../store/create-challenge.context';
import { i18nKeys } from '../../../utils/i18n.utils';
import { ChallengeHotspot } from '../challengesCommon/ChallengeHotspots';
import { debounce } from 'lodash';

export interface ChallengeOverviewProps {
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

const ChallengeOverviewSection: React.FC<ChallengeOverviewProps> = ({ validationHandler }) => {
  const { t } = useTranslation();
  const { editedChallenge, handleUpdateChallengeId, handleUpdateChallengeProp, challengeOverviewValidator } =
    useCreateChallenge();

  // error texts and validation logic
  const [challengeIdErrorText, setChallengeIdErrorText] = useState('');
  const [titleErrorText, setTitleErrorText] = useState('');
  const [descriptionErrorText, setDescriptionErrorText] = useState('');

  const validator = challengeOverviewValidator(
    new Map<ChallengeOverviewFields, (error: string) => void>([
      [ChallengeOverviewFields.CHALLENGE_ID, (error: string) => setChallengeIdErrorText(error)],
      [ChallengeOverviewFields.TITLE, (error: string) => setTitleErrorText(error)],
      [ChallengeOverviewFields.DESCRIPTION, (error: string) => setDescriptionErrorText(error)],
    ])
  );

  validationHandler(() => {
    return Promise.resolve(validator.isValidSection(true));
  });

  const validateChallengeId = useMemo(() => debounce(() => {
    // eslint-disable-next-line no-console
    console.log("bouncy");
    validator.isValidField(ChallengeOverviewFields.CHALLENGE_ID, true);
  }, 250, { leading: false }), [validator]);

  const validateTitle = useMemo(() => debounce(() => {
    // eslint-disable-next-line no-console
    console.log("bouncy2");
    validator.isValidField(ChallengeOverviewFields.TITLE, true);
  }, 250, { leading: false }), [validator]);

  const validateDescription = useMemo(() => debounce(() => {
    // eslint-disable-next-line no-console
    console.log("bouncy3");
    validator.isValidField(ChallengeOverviewFields.DESCRIPTION, true);
  }, 250, { leading: false }), [validator]);

  const handleChallengeId = (changedId: string) => {
    handleUpdateChallengeId(changedId.toLowerCase());
    if (!!changedId.trim()) {
      validateChallengeId();
    }
  };

  const handleChallengeTitle = (changedTitle: string) => {
    handleUpdateChallengeProp(ChallengePropAction.TITLE, changedTitle);
    if (!!changedTitle.trim()) {
      validateTitle();
    }
  };

  const handleChallengeDescription = (changedDescription: string) => {
    handleUpdateChallengeProp(ChallengePropAction.DESCRIPTION, changedDescription);
    if (!!changedDescription.trim()) {
      validateDescription();
    }
  };

  return (
    <Container header={<Header variant={'h2'}> {t(i18nKeys.challenges.challengeDetails.titles.summary)} </Header>}>
      <SpaceBetween direction="vertical" size="l">
        <FormField
          label={`${t(i18nKeys.challenges.fields.challengeId.title)}*`}
          errorText={challengeIdErrorText}
          i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}}
          constraintText={t(i18nKeys.challenges.subSections.overview.fields.challengeId.constraint, {
            CHALLENGE_ID_MIN_LENGTH,
            CHALLENGE_ID_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.challengeId}>
            <Input
              type="text"
              value={editedChallenge?.challengeId || ''}
              data-testid="challengeIdInput"
              onChange={({ detail }) => handleChallengeId(detail.value)}
              placeholder={t(i18nKeys.challenges.fields.challengeId.placeholder)}
              onBlur={() => validateChallengeId()}
            />
          </Hotspot>
        </FormField>
        <FormField
          label={t(i18nKeys.challenges.fields.title.title) + '*'}
          errorText={titleErrorText}
          i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}}
          constraintText={t(i18nKeys.challenges.subSections.overview.fields.title.constraint, {
            TITLE_MIN_LENGTH,
            TITLE_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.challengeTitle}>
            <Input
              type="text"
              value={editedChallenge?.props?.title || ''}
              data-testid="challengeTitleInput"
              onChange={({ detail }) => handleChallengeTitle(detail.value)}
              placeholder={t(i18nKeys.challenges.fields.title.placeholder)}
              onBlur={() => validateTitle()}
            />
          </Hotspot>
        </FormField>
        <FormField
          label={t(i18nKeys.challenges.fields.description.title) + '*'}
          errorText={descriptionErrorText}
          i18nStrings={{errorIconAriaLabel: t(i18nKeys.general.error)}}
          constraintText={t(i18nKeys.challenges.subSections.overview.fields.description.constraint, {
            DESCRIPTION_MIN_LENGTH,
            DESCRIPTION_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.challengeDescription}>
            <Textarea
              onChange={({ detail }) => handleChallengeDescription(detail.value || '')}
              data-testid="challengeDescriptionInput"
              value={editedChallenge?.props?.description || ''}
              placeholder={t(i18nKeys.challenges.fields.description.placeholder)}
              onBlur={() => validateDescription()}
            />
          </Hotspot>
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default ChallengeOverviewSection;
