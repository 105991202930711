import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Container, ContentLayout, ExpandableSection, Grid, Header } from '@amzn/awsui-components-react';
import { QuestionSectionKeys, QuestionSections, questions } from './faq.config';
import './FAQ.scss';
import SEO from '@/src/components/common/SeoComponent';


const FAQ = () => {
  const { section = 'general' }: { section: QuestionSectionKeys } = useParams();
  const { t } = useTranslation();
  const sectionQuestions = questions[section];
  return (
    <div className="faq-container">
      <ContentLayout header={<Header>{t(QuestionSections[section].title)}</Header>}>
        <SEO useStatic page={QuestionSections[section].seoPageName} />
        <Grid
          gridDefinition={Array(sectionQuestions.length)
            .fill(0)
            .map(() => ({ colspan: 12 }))}>
          {sectionQuestions.map((question, index) => (
            <Container key={question.question}>
              <ExpandableSection headerText={t(question.question)} defaultExpanded={index === 0}>
                <div className="ans-div-style">
                  <Grid gridDefinition={[{ colspan: { xl: 9, l: 9, m: 9, s: 9, xs: 12, xxs: 12 } }]}>
                    <Box>{question.answer}</Box>
                  </Grid>
                </div>
              </ExpandableSection>
            </Container>
          ))}
        </Grid>
      </ContentLayout>
    </div>
  );
};

export default FAQ;
