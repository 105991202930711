import React from 'react';
import { Box, Grid, GridProps, Link } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import './FooterLinks.scss';
import { useHistory, withRouter } from 'react-router-dom';
import { FOOTER_LINKS_TEXT } from './footer-links.config';
import { RoutePath } from '@/src/RoutePath';

const FooterLinks = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const colspan: GridProps.BreakpointMapping = { xl: 4, l: 4, m: 4, s: 4, xs: 12, xxs: 12 };

  const redirectToLink = (route: RoutePath) => {
    history.push(route);
  };

  return (
    <div className="footer-links">
      <Grid gridDefinition={[{ colspan }, { colspan }, { colspan }]}>
        {FOOTER_LINKS_TEXT.map((section) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <div key={section.title} className="section">
            <div className="section__title">
              <h3>{t(section.title)}</h3>
            </div>
            {section.sections.map((item) => (
              <div key={item.text} className="section__item">
                <div className="section__item__title">
                  {/* <Button variant="inline-link" href={t(item.link)} onClick={() => redirectToLink(item.link)}>
                      {t(item.text)}
                    </Button> */}
                  <Link onFollow={() => redirectToLink(item.link)} data-testid="link-button" fontSize="inherit">
                    {t(item.text)}
                  </Link>
                </div>
                <div className="section__item__description">
                  <Box variant="p" fontSize="body-m" color='inherit'>
                    {t(item.description)}
                  </Box>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Grid>
    </div>
  );
};

export default withRouter(FooterLinks);
