import { FacilitatorMessagingFields as Fields } from '../types/FacilitatorMessaging';
import { ErrorSetterByField, InputValidationHelper } from '../types/InputValidator';
import { validateField, validateIsEmpty, validateSection } from './validation.utils';

interface Params {
  title: string;
  message: string;
  teamName?: string;
  participantLogin?: string;
}

type FieldKey = keyof typeof Fields;

export const facilitatorMessagingValidator: (
  fields: Params,
  errors: Params,
  errorSetterByField: ErrorSetterByField<Fields>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: Fields, setError?: boolean) => boolean;
} = (fields, errors, errorSetterByField: Map<Fields, (error: string) => void>) => {
  const validatorHelperByField = new Map<Fields, InputValidationHelper>();

  Object.keys(Fields).forEach((key) => {
    validatorHelperByField.set(
      Fields[key as FieldKey],
      validateIsEmpty(fields[Fields[key as FieldKey]], errors[Fields[key as FieldKey]] as string)
    );
  });

  const isValidField = (field: Fields, setError = true) => {
    return validateField<Fields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection<Fields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};
