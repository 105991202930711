import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, FormField, Header, Input, Multiselect, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { OptionDefinition } from '@amzn/awsui-components-react/uxdg/internal/components/option/interfaces';
import { EditUsagePlanActions, usePlans } from '../../../store/usage-plan/usage-plan.context';
import { Challenge } from '../../../types/Challenge';
import { useChallenges } from '../../../store/challenge.context';
import { UsagePlan } from '../../../types/usage-plan/UsagePlan';

interface CreateUsagePlanChallengeProps {
  target: UsagePlan | undefined;
  errorText: string;
}

const CreateUsagePlanChallenge: React.FC<CreateUsagePlanChallengeProps> = ({ target, errorText }) => {
  const { t } = useTranslation();
  const { handleUpdateUsagePlan } = usePlans();
  const [challengeLimit, setChallengeLimit] = useState(target?.numOfChallenges || 0);
  const [challengeLimitErr, setChallengeLimitErr] = useState('');
  const [deniedChallenges, setDeniedChallenges] = useState<OptionDefinition[]>([]);
  const [deniedChallengesErr, setDeniedChallengesErr] = useState('');
  const [allChallenges, setAllChallenges] = useState<OptionDefinition[]>([]);
  const { challengeListItems } = useChallenges();

  useEffect(() => {
    // if this string is set, it means the usage plan failed validation on save in the parent component
    // in that case, re-validate the inputs on this component and update the UI with individual error strings if applicable
    if (errorText) {
      onNumOfChallengesChange(challengeLimit);
      onDenyListChange(deniedChallenges);
    }
  }, [errorText]);

  const challengesToOptions = (challengeList: Challenge[]) => {
    return challengeList.map((c) => {
      return {
        label: c.props.title,
        value: c.challengeId,
        labelTag: c.challengeId,
      } as OptionDefinition;
    });
  };

  useEffect(() => {
    setAllChallenges(challengesToOptions(challengeListItems ?? []));
    // set up challenge selection if the plan already exists
    if (target?.blockedChallenges) {
      setDeniedChallenges(challengesToOptions(target.getBlockedChallengesFromListItems(challengeListItems)));
    }
  }, [challengeListItems]);

  const onNumOfChallengesChange = (num: number) => {
    setChallengeLimit(num);
    setChallengeLimitErr(handleUpdateUsagePlan(EditUsagePlanActions.NUM_OF_CHALLENGES, num));
  };

  const onDenyListChange = (denyList: OptionDefinition[]) => {
    setDeniedChallenges(denyList);
    setDeniedChallengesErr(
      handleUpdateUsagePlan(
        EditUsagePlanActions.BLOCKED_CHALLENGES,
        denyList.map((c) => c.value)
      )
    );
  };

  return (
    <Container header={<Header variant={'h2'}>{t(i18nKeys.usagePlan.sections.challenge)}</Header>}>
      <SpaceBetween direction={'vertical'} size={'l'}>
        {/* Challenge limits */}
        <FormField
          errorText={challengeLimitErr}
          label={t(i18nKeys.usagePlan.fields.challenge.title)}
          description={t(i18nKeys.usagePlan.fields.challenge.description)}>
          <div>
            <Input
              inputMode="numeric"
              type="number"
              value={challengeLimit.toString()}
              onChange={({ detail }) => {
                onNumOfChallengesChange(Number(detail.value));
              }}
              className="numeric-input"
              placeholder={t(i18nKeys.general.numPlaceholder)}
              ariaRequired
            />{' '}
            {t(i18nKeys.general.max)}
          </div>
        </FormField>

        {/* Deny list */}
        <FormField
          errorText={deniedChallengesErr}
          label={t(i18nKeys.usagePlan.fields.challengeDeny.title)}
          description={t(i18nKeys.usagePlan.fields.challengeDeny.description)}>
          <Multiselect
            selectedOptions={deniedChallenges}
            placeholder={t(i18nKeys.usagePlan.fields.challengeDeny.placeholder)}
            onChange={({ detail }) => onDenyListChange(detail.selectedOptions as OptionDefinition[])}
            options={allChallenges}
            filteringType="auto"
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default CreateUsagePlanChallenge;
