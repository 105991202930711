/**
 * Map of existing Auth roles in the backend
 *
 * If this enum is updated, be sure to update the enum in AWSJamGameService and JamAdminService -> AuthRole.java
 */
export enum AuthRole {
  GUEST_USER = 'guest-user',
  SUBSCRIBED_USER = 'subscribed-user',
  EVENT_SUPPORT = 'event-support',
  CHALLENGE_SUPPORT = 'challenge-support',
  CHALLENGE_BUILDER = 'challenge-builder',
  CHALLENGE_REVIEWER = 'challenge-reviewer',
  EVENT_PRIZES_ADMIN = 'event-prizes-admin',
  JAM_ADMIN = 'jam-admin',
}
