import { Tabs, TabsProps } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEventTemplate } from '@/src/types/EventTemplate';
import CatalogEventChallenges from './CatalogEventChallenges';
import { useUser } from '@/src/store/user.context';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useSplitPanel } from '../../../store/split-panel.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { ChallengeDescriptor, ChallengeListItem } from '../../../types/Challenge';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import BrowseChallenges from './BrowseChallenges';
import ChallengeSelection from './ChallengeSelection';
import ImportChallenges from './ImportChallenges';
import BackupChallenges from './BackupChallenges';
import _ from 'lodash';
import { Campaign } from '../../../types/Campaign';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import ChooseDifferentLearningEvent from './ChooseDifferentLearningEvent';
import { truncateString } from '@/src/utils/string.utils';
import { ChallengeTabIds } from '../CommonModel';

interface ChallengeTabsProps {
  challengeDescriptors: ChallengeDescriptor[];
  target: Event | Campaign;
  action: EditEventActions | EditCampaignActions;
  handleChallengeAction: (payload: ChallengeDescriptor[]) => void;
  handleEventTemplateChangeAction?: (payload: IEventTemplate) => void;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
  eventTemplate?: IEventTemplate;
  onChooseDifferentEvent?: () => void;
  tabToSwitch?: ChallengeTabIds;
}

const ChallengeTabs: React.FC<ChallengeTabsProps> = ({
  challengeDescriptors,
  handleChallengeAction,
  target,
  toggleChallengeInfo,
  handleEventTemplateChangeAction,
  eventTemplate,
  onChooseDifferentEvent,
  tabToSwitch,
}) => {
  const isEvent = target instanceof Event;
  const { toggleShowSplitPanel } = useSplitPanel();
  const { editMode: eventEditMode } = useEditEvent();
  const { campaignEditMode } = useEditCampaign();
  const { t } = useTranslation();
  const { user } = useUser();
  const defaultTab = (target as Event).catalogId ? ChallengeTabIds.LEARNING_EVENT : ChallengeTabIds.SELECTED_CHALLENGES;
  const [activeTabId, setActiveTabId] = useState<ChallengeTabIds>(defaultTab);
  const [tabs, setTabs] = useState<TabsProps.Tab[]>([]);

  const { toggleShowToolPanel } = useToolPanel();

  const handleTabSelection = (tabId: ChallengeTabIds) => {
    if (tabId === ChallengeTabIds.BROWSE_CHALLENGES) {
      toggleShowSplitPanel(true);
    } else {
      toggleShowSplitPanel(false);
    }
    setActiveTabId(tabId);
  };

  const ChallengeTabDictionary: { [key: string]: TabsProps.Tab } = {
    CHALLENGE_SELECTION: {
      label: t(i18nKeys.challenges.tabHeaders.selectedChallenges, {
        selectedChallengeCount: challengeDescriptors.length,
      }),
      id: ChallengeTabIds.SELECTED_CHALLENGES,
      content: (
        <ChallengeSelection
          target={target}
          targetChallengeDescriptors={challengeDescriptors}
          handleRemoveChallenge={handleChallengeAction}
          toggleChallengeInfo={toggleChallengeInfo}
        />
      ),
    },
    BACKUP_CHALLENGES: {
      label: t(i18nKeys.challenges.tabHeaders.backupChallenges),
      id: ChallengeTabIds.BACKUP_CHALLENGES,
      content: isEvent ? <BackupChallenges target={target} /> : <></>,
    },
    BROWSE_CHALLENGES: {
      label: t(i18nKeys.challenges.tabHeaders.browseChallenges),
      id: ChallengeTabIds.BROWSE_CHALLENGES,
      content: (
        <BrowseChallenges
          handleChallengeAction={handleChallengeAction}
          currentChallengeDescriptors={challengeDescriptors}
          toggleChallengeInfo={toggleChallengeInfo}
          editMode={isEvent ? eventEditMode : campaignEditMode}
        />
      ),
    },
    IMPORT_CHALLENGES: {
      label: t(i18nKeys.challenges.tabHeaders.importChallenges),
      id: ChallengeTabIds.IMPORT_CHALLENGES,
      content: (
        <ImportChallenges
          currentChallengeDescriptors={challengeDescriptors}
          handleChallengeAction={handleChallengeAction}
          target={target}
          // @ts-expect-error tab will always be ChallengeTabIds
          navigateToTab={setActiveTabId}
        />
      ),
    },
    /**
     * TODO: for now it is hidden. In future we need to introduce this
     * again
    GAME_BOARD: {
      label: t(i18nKeys.challenges.tabHeaders.gameBoard),
      id: ChallengeTabIds.GAME_BOARD,
      content: isEvent ? <GameBoard target={target} /> : <></>,
    },*/
    CHOOSE_DIFFERENT_EVENT: {
      label: t(i18nKeys.challenges.tabHeaders.chooseDifferentEvent),
      id: ChallengeTabIds.CHOOSE_DIFFERENT_EVENT,
      content: handleEventTemplateChangeAction && (
        <ChooseDifferentLearningEvent
          handleEventTemplateChangeAction={handleEventTemplateChangeAction}
          eventTemplate={eventTemplate}
        />
      ),
    },
    LEARNING_EVENT: {
      label: t(i18nKeys.challenges.tabHeaders.learningEvent, { name: truncateString(eventTemplate?.name as string) }),
      id: ChallengeTabIds.LEARNING_EVENT,
      content: handleEventTemplateChangeAction && onChooseDifferentEvent && (
        <CatalogEventChallenges
          challengeDescriptors={eventTemplate?.challenges ?? []}
          event={target as Event}
          toggleChallengeInfo={toggleChallengeInfo}
          eventTemplate={eventTemplate}
          onChooseDifferentEvent={onChooseDifferentEvent}
          isEventEdit={eventEditMode}
        />
      ),
    },
  };

  const getTabs = useCallback(() => {
    const newTabs = [];

    if ((target as Event).catalogId) {
      newTabs.push(...[ChallengeTabDictionary.LEARNING_EVENT]);
      if (eventEditMode) newTabs.push(...[ChallengeTabDictionary.CHOOSE_DIFFERENT_EVENT]);
    } else {
      newTabs.push(
        ...[
          ChallengeTabDictionary.CHALLENGE_SELECTION,
          ChallengeTabDictionary.BACKUP_CHALLENGES,
          ChallengeTabDictionary.BROWSE_CHALLENGES,
        ]
      );
      if (
        user?.isSuperAdmin ||
        user?.isEventAdmin ||
        (user && (target as Event).isEventOwner(user))
      ) {
        // TODO: GAME_BOARD was here. removed for now
        newTabs.push(...[ChallengeTabDictionary.IMPORT_CHALLENGES]);
      }
    }
    setTabs(_.clone(newTabs));
  }, [eventEditMode, user, target, campaignEditMode, eventTemplate]);

  useEffect(() => {
    getTabs();
  }, [getTabs]);

  useEffect(() => {
    // run on cleanup
    return () => toggleShowToolPanel(false);
  }, []);

  // this will switch the active tab to default tab
  // if you cancel the edit
  useEffect(() => {
    if (!eventEditMode && !campaignEditMode && !tabToSwitch) {
      setActiveTabId(defaultTab);
    }
  }, [eventEditMode, tabToSwitch]);

  // this will switch to tabToSwitch tab
  // this is used to navigate to any tab
  // explicity without clicking on the tab
  // i.e. Choose a different learning event link on learning event tab
  useEffect(() => {
    if (tabToSwitch) {
      setActiveTabId(tabToSwitch);
    }
  }, [tabToSwitch]);

  return (
    <Tabs
      activeTabId={activeTabId}
      onChange={({ detail }) => handleTabSelection(detail.activeTabId as ChallengeTabIds)}
      tabs={tabs}
    />
  );
};
export default ChallengeTabs;
