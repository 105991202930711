import { safeString } from './string.utils';
import {
  EventParticipantsFields,
  EventValidationFields, MAX_DURATION_NON_ADMIN,
  MAX_EXPECTED_PARTICIPANTS,
  MIN_EXPECTED_PARTICIPANTS
} from '@/src/types/Event';
import { InputValidationHelper } from '@/src/types/EventTemplate';
import {
  isValidEmail,
  isValidLength,
  validateSection as commonValidationSection,
  validateField as commonValidateField,
} from './validation.utils';
import { EVENT_TITLE_MIN_LENGTH, EVENT_TITLE_MAX_LENGTH } from '@/src/store/edit-event.context';
import { isEmpty } from 'lodash';
import { NullableString } from '../types/common';
import moment from 'moment';

const validateField = (
  validatorHelperByField: Map<EventValidationFields, InputValidationHelper>,
  errorSetterByField: Map<EventValidationFields, (error: string) => void>,
  field: EventValidationFields,
  setError = true
) => {
  const validation = validatorHelperByField.get(field);
  if (!validation) {
    throw new Error('Validation logic does not exist!');
  }
  const isValid = validation.isValid();
  const error = validation.checkErrors();
  if (setError) {
    const setErrorFn =
      errorSetterByField.get(field) ||
      (() => {
        // do nothing
      });
    setErrorFn(error);
  }
  // preProdLogger('Validated', field, 'isValid:', isValid, 'error:', error);
  return isValid;
};

const validateSection = (
  validatorHelperByField: Map<EventValidationFields, InputValidationHelper>,
  errorSetterByField: Map<EventValidationFields, (error: string) => void>,
  setErrors = false
): boolean => {
  const fieldIsValid: boolean[] = [];
  const fields = Array.from(errorSetterByField.keys());
  for (const key of fields) {
    fieldIsValid.push(validateField(validatorHelperByField, errorSetterByField, key, setErrors));
  }
  return fieldIsValid.reduce((prev, next) => prev && next);
};

const validateEmail = (email: string, errorText: string) => {
  const input = safeString(email);
  const isValid = !!input && isValidEmail(input);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const eventParticipantValidator: (
  email: string,
  emailErrorText: string,
  errorSetterByField: Map<EventParticipantsFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventParticipantsFields, setError?: boolean) => boolean;
} = (
  email: string,
  emailErrorText: string,
  errorSetterByField: Map<EventParticipantsFields, (error: string) => void>
) => {
  const validatorHelperByField = new Map<EventParticipantsFields, InputValidationHelper>([
    [EventParticipantsFields.PARTICIPANT, validateEmail(email, emailErrorText)],
  ]);

  const isValidField = (field: EventParticipantsFields, setError = true) => {
    return validateField(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};

export enum EventFields {
  EVENT_TITLE = 'title',
  EVENT_CHANNEL = 'channel',
  EVENT_START_DATE = 'event-start-date',
  EVENT_START_TIME = 'event-start-time',
  DURATION = 'duration',
  TEAMSIZE = 'team-size',
  PARTICIPANT = 'participant',
  REASON = 'reason'
}

const validateEventTitle = (title: string, errorText: string) => {
  const input = safeString(title);
  const isValid = isValidLength(input, EVENT_TITLE_MIN_LENGTH, EVENT_TITLE_MAX_LENGTH);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

const validateEventChannel = (channel: NullableString, errorText: string) => {
  const isValid = !isEmpty(channel);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const eventTitleFieldValidater: (
  title: string,
  titleErrorText: string,
  channel: NullableString,
  channelErrorText: string,
  errorSetterByField: Map<EventFields, (error: string) => void>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventFields, setError?: boolean) => boolean;
} = (
  title: string,
  titleErrorText: string,
  channel: NullableString,
  channelErrorText: string,
  errorSetterByField: Map<EventFields, (error: string) => void>
) => {
  const validatorHelperByField = new Map<EventFields, InputValidationHelper>([
    [EventFields.EVENT_TITLE, validateEventTitle(title, titleErrorText)],
    [EventFields.EVENT_CHANNEL, validateEventChannel(channel, channelErrorText)],
  ]);

  const isValidField = (field: EventFields, setError = true) => {
    return commonValidateField<EventFields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return commonValidationSection<EventFields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};

const convertDurationNonAdmin = () : string => {
  if (MAX_DURATION_NON_ADMIN <= 9) {
    return ('0' + String(MAX_DURATION_NON_ADMIN) + ':00');
  } else {
    return (String(MAX_DURATION_NON_ADMIN) + ':00');
  }
}

const validateDuration = (
  value: {
    isAdmin: boolean;
    duration: string | null;
  },
  errorText: {
    required: string;
    maximum: string
  }
) => {
  const isValid = !isEmpty(value.duration);
  const isWithinNonAdminRange = value.duration ? value.duration <= convertDurationNonAdmin() : false;
  return {
    isValid: () => isValid && (value.isAdmin || isWithinNonAdminRange),
    checkErrors: () => {
      if(!isValid) {
        return errorText.required;
      }
      if(!value.isAdmin && !isWithinNonAdminRange) {
        return errorText.maximum;
      }
      return '';
    },
  };
};

const validateStartDate = (startDate: string, timeZone: string, errorText: { required: string; minimum: string }) => {
  const isRequired = isEmpty(startDate);
  const isInPast = moment(startDate).tz(timeZone).isBefore(moment().tz(timeZone), 'date');
  return {
    isValid: () => !isRequired && !isInPast,
    checkErrors: () => {
      if (isRequired) return errorText.required;
      return isInPast ? errorText.minimum : '';
    },
  };
};

const validateStartTime = (
  startDate: string,
  startTime: string,
  timeFormat: string,
  timeZone: string,
  errorText: { required: string; minimum: string }
) => {
  const isRequired = isEmpty(startTime);
  let isInPast = false;
  if (startDate && !isRequired) {
    const startDateTime = moment(`${startDate} ${startTime} ${timeFormat}`, 'YYYY/MM/DD hh:mm A').tz(timeZone);
    isInPast = startDateTime.isBefore(moment().tz(timeZone));
  }
  return {
    isValid: () => !isRequired && !isInPast,
    checkErrors: () => {
      if (isRequired) return errorText.required;
      return isInPast ? errorText.minimum : '';
    },
  };
};

export const eventTimesValidator = (
  startDate: string,
  startDateErrorText: { required: string; minimum: string },
  startTime: string,
  startTimeErrorText: { required: string; minimum: string },
  durationValue: {isAdmin: boolean; duration: string | null},
  durationError: { maximum: string; required: string },
  timeFormat: string,
  timeZone: string,
  errorSetterByField: Map<EventFields, (error: string) => void>
): {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventFields, setError?: boolean) => boolean
} => {
  const validatorHelperByField = new Map<EventFields, InputValidationHelper>([
    [EventFields.DURATION, validateDuration(durationValue, durationError)],
    [EventFields.EVENT_START_DATE, validateStartDate(startDate, timeZone, startDateErrorText)],
    [EventFields.EVENT_START_TIME, validateStartTime(startDate, startTime, timeFormat, timeZone, startTimeErrorText)],
  ]);

  const isValidField = (field: EventFields, setError = true) => {
    return commonValidateField<EventFields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return commonValidationSection<EventFields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};

const validateTeamSize = (teamSize: string | null, errorText: string) => {
  const isValid = !isEmpty(teamSize) && Number(teamSize) > 0;

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const eventTeamSizeValidator = (
  teamSize: string,
  teamSizeErrorText: string,
  errorSetterByField: Map<EventFields, (error: string) => void>
): {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventFields, setError?: boolean) => boolean;
} => {
  const validatorHelperByField = new Map<EventFields, InputValidationHelper>([
    [EventFields.TEAMSIZE, validateTeamSize(teamSize, teamSizeErrorText)],
  ]);

  const isValidField = (field: EventFields, setError = true) => {
    return commonValidateField<EventFields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return commonValidationSection<EventFields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};

const validateParticipantRange = (
  minParticipant: string,
  maxParticipant: string,
  errorText: { maxRequired: string; minNoGreaterMax: string; minRequired: string; minGreaterX: string; maxLowerX: string }
) => {
  const isMinRequired= isEmpty(minParticipant);
  const isMaxRequired = isEmpty(maxParticipant);
  let isMinGreaterX = false;
  let isMaxLowerX = false;
  let isMinNoGreaterMax = false;
  if (!isMinRequired){
    isMinGreaterX = Number(minParticipant) >= MIN_EXPECTED_PARTICIPANTS;
  }
  if (!isMaxRequired){
    isMaxLowerX = Number(maxParticipant) <= MAX_EXPECTED_PARTICIPANTS;
  }
  if (!isMinRequired && !isMaxRequired){
    isMinNoGreaterMax = Number(minParticipant) <= Number(maxParticipant);
  }
  return {
    isValid: () => !isMinRequired && !isMaxRequired && isMinGreaterX && isMaxLowerX && isMinNoGreaterMax,
    checkErrors: () => {
      if (isMinRequired) {
        return errorText.minRequired;
      } else if (isMaxRequired) {
        return errorText.maxRequired;
      } else if (!isMinGreaterX) {
        return errorText.minGreaterX;
      } else if (!isMaxLowerX) {
        return errorText.maxLowerX;
      } else if (!isMinNoGreaterMax) {
        return errorText.minNoGreaterMax;
      } else {
        return '';
      }
    }
  }
}

export const eventParticipantRangeValidator = (
  minParticipant: string,
  maxParticipant: string,
  participantErrorText: {
    maxRequired: string;
    minNoGreaterMax: string;
    minRequired: string;
    minGreaterX: string;
    maxLowerX: string
  },
  errorSetterByField: Map<EventFields, (error: string) => void>
): {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventFields, setError?: boolean) => boolean
} => {
  const validatorHelperByField = new Map<EventFields, InputValidationHelper>([
    [EventFields.PARTICIPANT, validateParticipantRange(minParticipant, maxParticipant, participantErrorText)],
  ]);

  const isValidField = (field: EventFields, setError = true) => {
    return commonValidateField<EventFields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return commonValidationSection<EventFields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
}


const validateReason = (reason: string | null, errorText: string) => {
  const regexPattern = new RegExp('^[a-z0-9A-Z\\u00D8-\\u00F6- @,.#&$!É_/|()]{0,255}$');
  let isValid = !isEmpty(reason);

  if (reason) {
    isValid = isValid && regexPattern.test(reason);
  }

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const eventCancelReasonValidator = (
  reason: string,
  reasonErrorText: string,
  errorSetterByField: Map<EventFields, (error: string) => void>
): {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: EventFields, setError?: boolean) => boolean;
} => {
  const validatorHelperByField = new Map<EventFields, InputValidationHelper>([
    [EventFields.REASON, validateReason(reason, reasonErrorText)],
  ]);

  const isValidField = (field: EventFields, setError = true) => {
    return commonValidateField<EventFields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return commonValidationSection<EventFields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};
