import React, { useEffect, useRef, useState } from 'react';
import { Box, BoxProps, Icon, Link, SpaceBetween } from '@amzn/awsui-components-react';
import Slider, { ResponsiveObject } from 'react-slick';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { IEventTemplate } from '@/src/types/EventTemplate';
import { IReview } from '@/src/types/Review';
import EventCard from '../EventCard/EventCard';
import ReviewCard from '../ReviewCard/ReviewCard';
import './CustomSlider.scss';

interface IProps {
  items: IEventTemplate[] | IReview[];
  heading: string;
  slidesToShow?: number;
  reviewCard?: boolean;
  headingVariation?: BoxProps.Variant;
  responsive?: ResponsiveObject[];
  viewAllLink?: string;
  isSubscribed?: boolean
}

const CustomSlider: React.FC<IProps> = ({
  items = [],
  heading,
  slidesToShow = 4,
  reviewCard = false,
  headingVariation,
  responsive,
  viewAllLink,
  isSubscribed = false
}) => {
  const { t } = useTranslation();
  const sliderRef = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(items.length <= slidesToShow);

  const handleClickNext = () => {
    if (!disableNext) if (sliderRef.current !== null) sliderRef.current.slickNext();
  };

  const handleClickPrev = () => {
    if (!disablePrev) if (sliderRef.current !== null) sliderRef.current.slickPrev();
  };

  useEffect(() => {
    setDisablePrev(currentSlide === 0);
    setDisableNext(currentSlide + slidesToShow >= items.length);
  }, [currentSlide, items.length, slidesToShow]);

  const renderSlide = (slideItem: IEventTemplate | IReview) => {
    let item: IReview | IEventTemplate;
    if (reviewCard) {
      item = slideItem as IReview;
      return (
        <ReviewCard
          key={item.id}
          imgSrc={item.image}
          description={item.description}
          rating={item.rating}
          title={item.title}
          position={item.position}
          name={item.name}
          company={item.company}
        />
      );
    }

    item = slideItem as IEventTemplate;
    return (
      <EventCard
        key={item.id}
        id={item.id}
        imgSrc={item.imagePresignedUrl}
        rating={item.rating}
        title={item.name}
        tag={item.topics}
        learningType={item.learningType}
        duration={item.duration}
        teamSize={3}
        isSubscribed={isSubscribed}
      />
    );
  };

  return (
    <div className="event-slider-container">
      <div className="slider-header">
        <Box variant={headingVariation ? headingVariation : 'h3'}>{heading}</Box>
        <SpaceBetween direction="horizontal" size="s">
          <div className="view-link">
            {!!viewAllLink && (
              <Link href={viewAllLink}>{t(i18nKeys.general.viewAllCount)}</Link>
            )}
          </div>
          <div className="arrows-container">
            <button onClick={handleClickPrev} title="previous-button" className={disablePrev ? 'disabled' : ''}>
              <Icon name="angle-left" />
            </button>
            <button onClick={handleClickNext} title="next-button" className={disableNext ? 'disabled' : ''}>
              <Icon name="angle-right" />
            </button>
          </div>
        </SpaceBetween>
      </div>
      <Slider
        accessibility
        ref={sliderRef}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        infinite={false}
        className={reviewCard ? '' : 'slider'}
        initialSlide={0}
        beforeChange={(current: number, next: number) => {
          setCurrentSlide(next);
        }}
        arrows={false}
        dots={false}
        responsive={responsive}>
        {items.map(renderSlide)}
      </Slider>
    </div>
  );
};

export default CustomSlider;
