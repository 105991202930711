import moment from 'moment-timezone';

export class TeamChallengeQueueInfo {
  eventName: string;
  challengeId: string;
  teamName: string;
  queueSize = 0;
  queuePosition: number | null = null;
  timeEnteredQueue: number | null = null;
  estimatedReadyTime: number | null = null;

  get waitTimeInMinutes(): number {
    if (!this.estimatedReadyTime || moment(this.estimatedReadyTime).isBefore(moment())) return 0;

    return Math.abs(moment(this.estimatedReadyTime).diff(moment(), 'minutes'));
  }

  static fromPlainObject(obj: any): TeamChallengeQueueInfo {
    return Object.assign(new TeamChallengeQueueInfo(), obj) as TeamChallengeQueueInfo;
  }
}
