import React from 'react';
import { Container, Header, ColumnLayout, SpaceBetween, Box, MultiselectProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { i18nKeys } from '@/src/utils/i18n.utils';

export interface IEventDetailReviewProps {
  summary: string;
  topic: readonly MultiselectProps.Option[];
  teamSize: number;
  minParticipants: number;
  maxParticipants: number;
  tags: readonly MultiselectProps.Option[];
}

const { eventDetails } = i18nKeys.eventTemplates.step5.steps.step4;

export const EventDetailReview: React.FC<IEventDetailReviewProps> = ({
  summary,
  topic,
  teamSize,
  minParticipants,
  maxParticipants,
  tags,
}) => {
  const { t } = useTranslation();

  return (
    <Container header={<Header variant="h2">{t(eventDetails.title)}</Header>}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <div>
            <Box variant="h4">{t(eventDetails.summary)}</Box>
            <Box>{summary || '-'}</Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="h4">{t(eventDetails.topic)}</Box>
            <Box>{!!topic.length ? topic.map((item) => item.description).join(',') : <Box>-</Box>}</Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="h4">{t(eventDetails.recommendedTeamSize)}</Box>
            <Box>
              {teamSize || '0'} {t(eventDetails.participants)}
            </Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="h4">{t(eventDetails.numParticipants)}</Box>
            <Box>
              {minParticipants || '2'} - {maxParticipants || '200'}
            </Box>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="h4">{t(eventDetails.tags)}</Box>
            <Box>{!!tags.length ? tags.map((item) => item.description).join(',') : <Box>-</Box>}</Box>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};
