import React, { useContext, useState, createContext, useCallback } from 'react';
import { EventTemplateOfferMap } from '@/src/types/EventTemplate';
import { useApi } from './api.context';
import { preProdLogger } from '../utils/log.utils';

export interface EventTemplateOffersContextValue {
  loading: boolean;
  error: string;
  offers?: EventTemplateOfferMap;
  fetchEventTemplateOffers: () => Promise<void>;
}

const DEFAULT_EVENT_TEMPLATE_OFFERS_VALUE: EventTemplateOffersContextValue = {
  loading: false,
  error: '',
  fetchEventTemplateOffers: () => Promise.resolve(),
};

const EventTemplateOffersContext = createContext<EventTemplateOffersContextValue>(DEFAULT_EVENT_TEMPLATE_OFFERS_VALUE);

const EventTemplateOffersProvider: React.FC = ({ children }) => {
  const { eventTemplateApi } = useApi();
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');
  const [offers, setOffers] = useState<EventTemplateOfferMap>();

  useCallback(() => {
    setLoading(true);
    setFetching(false);
    setError('');
    setOffers(undefined);
  }, []);

  const fetchEventTemplateOffers = useCallback(async () => {
    if (offers && offers?.size > 0) return; // don't call offers api if offers exist
    if (fetching) return;

    setFetching(true);
    try {
      const res = await eventTemplateApi.getPublicEventTemplateOffers();
      const offersMap: EventTemplateOfferMap = new Map();
      for (const offer of res.offers) {
        offersMap.set(offer.duration, offer);
      }
      setOffers(offersMap);
    } catch (err) {
      preProdLogger('Failed to fetch offers', err);
    } finally {
      setFetching(false);
    }
  }, [offers, eventTemplateApi]);

  const value: EventTemplateOffersContextValue = {
    loading,
    error,
    offers,
    fetchEventTemplateOffers,
  };

  return <EventTemplateOffersContext.Provider value={value}>{children}</EventTemplateOffersContext.Provider>;
};

const useEventTemplateOffers = () => {
  const context = useContext(EventTemplateOffersContext);
  if (context === undefined) {
    throw new Error('useEventTemplate can only be used inside EventTemplateProvider');
  }
  return context;
};

export { EventTemplateOffersProvider, useEventTemplateOffers };
