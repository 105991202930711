import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { ContentLayout, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Markdown } from '../../common/Markdown';
import JamContainer from '../../ui/molecules/JamContainer/JamContainer';

const JamChallengeWiki = () => {
  const params: { 0: string; 1: string } = useParams();
  const { challengeSupportDetails } = useJamChallengeDetails();
  return (
    <ContentLayout header={<Header variant="h1">Wiki</Header>}>
      <JamContainer>
        <Markdown content={challengeSupportDetails?.getChallengeSupportDetails(params[1])?.wiki} />
      </JamContainer>
    </ContentLayout>
  );
};

export default JamChallengeWiki;
