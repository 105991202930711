import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Header } from '@amzn/awsui-components-react';
import { ChallengeListItem } from '@/src/types/Challenge';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

// component
import { DetailHeader } from '@/src/components/ui/molecules/EventTemplate';

// styles
import './SelectChallengesReview.scss';

export interface ISelectChallengesReviewProps {
  challenges: ChallengeListItem[];
  onEditClick: () => void;
}

const { stepTitle, cardTitle, selectedChallengesTitle } = i18nKeys.eventTemplates.step5.steps.step2;

export const SelectChallengesReview: React.FC<ISelectChallengesReviewProps> = ({ challenges, onEditClick }) => {
  const { t } = useTranslation();
  return (
    <div>
      <DetailHeader marginTop={0} heading={t(stepTitle)} onEditClick={onEditClick} />
      <Container header={<Header variant="h2">{t(cardTitle)}</Header>}>
        <span>
          <b>{t(selectedChallengesTitle)}</b>
        </span>
        <ul className="selected-challenges-list">
          {challenges.map((challenge: ChallengeListItem, index) => (
            <li key={index} className="selected-challenges-listitem">
              {challenge.props.title}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};
