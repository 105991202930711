import { uniq, kebabCase } from 'lodash';

export type ChartType = 'value-over-time-line' | 'value-over-time-area' | 'bar';

export class DataEntry {
  key: string;
  time?: number;
  label?: string;
  value: number;
  status?: string;
}

export class DataSet {
  key: string;
  label?: string;
  entries: DataEntry[];
  color: string | string[];
  fill?: boolean;
  pointRadius?: number[];
}

export class ChartProperties {
  id: string;
  type: ChartType;
  title: string;
  subtitle?: string;
  timeRangeStart: number;
  timeRangeEnd: number;
  dataSets: DataSet[] = [];
  loading?: boolean = false;
  xLabel: string;
  yLabel: string;
  ySuggestedMax: number;
  noInitial?: boolean;
}

export const makeChart = (
  id: string,
  type: ChartType,
  xLabel: string,
  yLabel: string,
  ySuggestedMax: number,
  title: string,
  subtitle: string | null,
  timeRangeStart: number,
  timeRangeEnd: number,
  dataSets: DataSet[],
  noInitial?: boolean
): ChartProperties => {
  if (type === 'value-over-time-line' || type === 'value-over-time-area') {
    dataSets.forEach((dataSet) => {
      dataSet.entries.forEach((entry: any) => {
        if (entry.time < timeRangeStart) {
          timeRangeStart = entry.time as number;
        }
        if (entry.time > timeRangeEnd) {
          timeRangeEnd = entry.time as number;
        }
      });
    });
  }

  return Object.assign(new ChartProperties(), {
    id: 'jam-ui-chart-' + kebabCase(title) + '-' + id,
    type,
    title,
    subtitle,
    timeRangeStart,
    timeRangeEnd,
    dataSets,
    xLabel,
    yLabel,
    ySuggestedMax,
    noInitial,
  });
};

export const makeDataSet = (dataSet: DataSet): DataSet => {
  return Object.assign(new DataSet(), dataSet);
};

export const mapTimesToEntries = (key: string, times: number[]) => {
  return uniq(times || [])
    .sort((a, b) => (a > b ? 1 : -1))
    .map((time, i) => {
      return Object.assign(new DataEntry(), {
        key,
        time,
        value: i + 1,
      });
    });
};
