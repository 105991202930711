import React from 'react';
import { PublicEventData } from '@/src/types/PublicEvents';
import PublicEventCard from './PublicEventCard';

export const CARD_DEFINITIONS = {
  //   header: (item: PublicEventData) => null,
  sections: [
    {
      id: 'public-event-card',
      content: (item: PublicEventData) => (
        <div className="public-event-card" key={item.title}>
          <PublicEventCard
            date={item.date}
            startDate={item.startDate}
            endDate={item.endDate}
            title={item.title}
            description={item.description}
            location={item.location}
            moreInfoURL={item.moreInfoURL}
            imageFilename={item.imageFilename}
            eventId={item.eventId}
          />
        </div>
      ),
    },
  ],
};
