import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';
import { ChallengeFeedback } from '../../../../types/Challenge';
import { EventFeedback } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import CircleRating from '../../../common/CircleRating';
import StarRating from '../../../common/StarRating';

interface FeedbackRowProps {
  eventFeedback?: EventFeedback | undefined;
  challengeFeedback?: ChallengeFeedback | undefined;
}

const FeedbackRow: React.FC<FeedbackRowProps> = ({ eventFeedback, challengeFeedback }) => {
  const { t } = useTranslation();

  const hasFeedback = !!eventFeedback || !!challengeFeedback;
  const commonFeedbackValues = eventFeedback || challengeFeedback;

  return (
    <Box>
      {hasFeedback && (
        <React.Fragment>
          <SpaceBetween direction="horizontal" size="m">
            {eventFeedback && (
              <React.Fragment>
                <strong>
                  {t(i18nKeys.feedback.labels.eventRating)}
                  <StarRating value={eventFeedback.eventRank} valueCount={5} />
                </strong>
                <strong>
                  {t(i18nKeys.feedback.labels.speakerRating)}
                  <StarRating value={eventFeedback.speakerRank} valueCount={5} />
                </strong>
              </React.Fragment>
            )}
            {challengeFeedback && (
              <React.Fragment>
                <strong>
                  {t(i18nKeys.feedback.labels.challengeRating)}
                  <StarRating value={challengeFeedback.challengeRank} valueCount={5} />
                </strong>
                <strong>
                  {t(i18nKeys.feedback.labels.difficulty)}
                  <CircleRating value={challengeFeedback.challengeDifficulty} />
                </strong>
              </React.Fragment>
            )}
            {commonFeedbackValues && (
              <strong>
                {t(i18nKeys.feedback.labels.learnedSomething)}
                {commonFeedbackValues.didYouLearnSomethingNew ? (
                  <span className="aws-positive-green">{t(i18nKeys.general.yes)}</span>
                ) : (
                  <span className="warning">{t(i18nKeys.general.no)}</span>
                )}
              </strong>
            )}
          </SpaceBetween>
          {commonFeedbackValues && commonFeedbackValues.createdDate && (
            <React.Fragment>
              <div className="secondary-text">
                <TimeAgo datetime={commonFeedbackValues.createdDate} />
              </div>
              <div className="mt-8">{commonFeedbackValues.notes}</div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};
export default FeedbackRow;
