import { useTranslation } from 'react-i18next';
import { Button, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import CreateUsagePlanDetails from './CreateUsagePlanDetails';
import * as React from 'react';
import { i18nKeys } from '../../../utils/i18n.utils';
import CreateUsagePlanRequestDetails from './CreateUsagePlanRequestDetails';
import CreateUsagePlanParticipant from './CreateUsagePlanParticipant';
import CreateUsagePlanChallenge from './CreateUsagePlanChallenge';
import CreateUsagePlanOptions from './CreateUsagePlanOptions';
import { RoutePath } from '../../../RoutePath';
import { Link, useHistory } from 'react-router-dom';
import { usePlans } from '../../../store/usage-plan/usage-plan.context';
import { useEffect, useState } from 'react';
import { usagePlanDetailsRoute } from '../../../routes';

const CreateUsagePlan: React.FC = () => {
  const { t } = useTranslation();
  const { isValidUsagePlan, editedUsagePlan, createUsagePlan, initializeCreateEditPlan } = usePlans();
  const history = useHistory();
  const [errorText, setErrorText] = useState('');

  const onSubmit = async () => {
    if (isValidUsagePlan(editedUsagePlan)) {
      setErrorText('');
      const planId = await createUsagePlan(editedUsagePlan);
      history.push(usagePlanDetailsRoute.resolvePath(planId));
    } else {
      setErrorText(t(i18nKeys.usagePlan.invalidField));
    }
  };

  useEffect(() => {
    initializeCreateEditPlan();
    return initializeCreateEditPlan();
  }, []);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form
        errorText={errorText}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Link to={RoutePath.USAGE_PLANS}>
              <Button formAction="none" variant="link">
                {t(i18nKeys.general.cancel)}{' '}
              </Button>
            </Link>
            <Button formAction="submit" variant="primary" onClick={() => void onSubmit()}>
              {t(i18nKeys.general.submit)}
            </Button>
          </SpaceBetween>
        }
        header={<Header variant="h1">{t(i18nKeys.usagePlan.createUsagePlan)}</Header>}>
        <SpaceBetween direction="vertical" size="m">
          <CreateUsagePlanDetails target={editedUsagePlan} errorText={errorText} />
          <CreateUsagePlanRequestDetails target={editedUsagePlan} errorText={errorText} />
          <CreateUsagePlanParticipant target={editedUsagePlan} errorText={errorText} />
          <CreateUsagePlanChallenge target={editedUsagePlan} errorText={errorText} />
          <CreateUsagePlanOptions target={editedUsagePlan} errorText={errorText} />
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateUsagePlan;
