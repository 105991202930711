import { isEmailValid, isEmpty, isEmpty as isStringEmpty } from './string.utils';
import { EventURLParameter } from '../store/edit-event.context';
import { isLocal } from './env.utils';
import { EventPrivacyType } from '../types/EventPrivacyType';
import { DateString } from '../types/common';

export interface CloneEvent {
  title: string | null;
  notes: string | null;
  agreementId: string | null;
  audienceType: string | null;
  tags: string | null;
  owners: string | null;
  facilitators: string | null;
  skillbuilderSubs: string[] | null;
  challenges: string | null;
  challengeSets?: string | null;
  id?: string | null;
  catalogId?: string | null;
  eventPrivacyType?: EventPrivacyType | null;
  startDate?: DateString | null;
  autoUnlockChallengesOffsetMinutes?: number | null;
}

export const encodeParam = (parameter: string, value: string): string => {
  return `${parameter}=${encodeURIComponent(value)}`;
};

export const getEncodedEmails = (emailList: string) => {
  const emails = emailList
    .split(',')
    .map((e) => e.trim())
    .filter((e) => isEmailValid(e));
  return emails.join(',');
};

export const cleanUpSpaces = (input: string) => {
  const inputs = input.split(',').map((e) => e.trim());
  return inputs.join(',');
};

export const allFieldsEmpty = (cloneEvent: CloneEvent): boolean => {
  const { title, notes, agreementId, tags, owners, facilitators, skillbuilderSubs, challenges, challengeSets } =
    cloneEvent;
  // Optional parameter, setting to safe value if not supplied
  const safeChallengeSets = challengeSets ? challengeSets : '';
  return (
    isStringEmpty(title) &&
    isStringEmpty(notes) &&
    isStringEmpty(agreementId) &&
    isStringEmpty(tags) &&
    isStringEmpty(owners) &&
    isStringEmpty(facilitators) &&
    isEmpty(skillbuilderSubs?.join(',') || '') &&
    isStringEmpty(challenges) &&
    isStringEmpty(safeChallengeSets)
  );
};

export const generateTemplateUrl = (cloneEvent: CloneEvent, withHost = true) => {
  const templateParams: string[] = [];
  const catalogTemplateParams: string[] = [];
  if (allFieldsEmpty(cloneEvent)) {
    return;
  }

  const {
    id,
    title,
    notes,
    agreementId,
    audienceType,
    tags,
    owners,
    facilitators,
    skillbuilderSubs,
    challenges,
    challengeSets,
    catalogId,
    startDate,
    eventPrivacyType,
    autoUnlockChallengesOffsetMinutes,
  } = cloneEvent;

  if (!!title && !isStringEmpty(title)) {
    templateParams.push(encodeParam(EventURLParameter.TITLE, title));
  }
  if (!!notes && !isStringEmpty(notes)) {
    templateParams.push(encodeParam(EventURLParameter.NOTES, notes));
  }
  if (!!agreementId && !isStringEmpty(agreementId)) {
    templateParams.push(encodeParam(EventURLParameter.AGREEMENT_ID, agreementId));
  }
  if (!!audienceType && !isStringEmpty(audienceType)) {
    templateParams.push(encodeParam(EventURLParameter.AUDIENCE_TYPE, audienceType));
  }
  if (!!tags && !isStringEmpty(tags)) {
    templateParams.push(encodeParam(EventURLParameter.TAGS, cleanUpSpaces(tags)));
  }
  if (!!owners && !isStringEmpty(owners)) {
    const ownersList = getEncodedEmails(owners);
    if (!isStringEmpty(ownersList)) {
      templateParams.push(encodeParam(EventURLParameter.OWNERS, ownersList));
    }
  }
  if (!!facilitators && !isStringEmpty(facilitators)) {
    const facilitatorsList = getEncodedEmails(facilitators);
    if (!isStringEmpty(facilitatorsList)) {
      templateParams.push(encodeParam(EventURLParameter.FACILITATORS, facilitatorsList));
    }
  }
  if (!!skillbuilderSubs && !isEmpty(skillbuilderSubs.join(','))) {
    // get the subscriptions which are checked
    templateParams.push(encodeParam(EventURLParameter.SKILL_BUILDER_SUBSCRIPTION, skillbuilderSubs.join(',')));
  }
  if (!!challenges && !isStringEmpty(challenges)) {
    templateParams.push(encodeParam(EventURLParameter.CHALLENGES, cleanUpSpaces(challenges)));
  }
  if (!!challengeSets && !isStringEmpty(challengeSets)) {
    templateParams.push(encodeParam(EventURLParameter.CHALLENGE_SETS, cleanUpSpaces(challengeSets)));
  }
  if (!!catalogId && !isStringEmpty(catalogId)) {
    catalogTemplateParams.push(encodeParam(EventURLParameter.EVENT, cleanUpSpaces(id ?? '')));
  }
  if (!!startDate && !isStringEmpty(startDate)) {
    templateParams.push(encodeParam(EventURLParameter.START_DATE, cleanUpSpaces(startDate)));
  }
  if (!!eventPrivacyType && !isStringEmpty(eventPrivacyType)) {
    templateParams.push(encodeParam(EventURLParameter.EVENT_PRIVACY_TYPE, cleanUpSpaces(eventPrivacyType)));
  }
  if (autoUnlockChallengesOffsetMinutes) {
    templateParams.push(
      encodeParam(EventURLParameter.AUTO_UNLOCK_CHALLENGES_OFFSET_MINUTES, String(autoUnlockChallengesOffsetMinutes))
    );
  }

  const host = isLocal() ? window.location.host : window.location.hostname;
  let templateUrl = !!catalogId
    ? `/events/details/${catalogId}?${catalogTemplateParams.join('&')}`
    : `/events/new?${templateParams.join('&')}`;
  if (withHost) {
    templateUrl = `https://${host}${templateUrl}`;
  }

  return templateUrl;
};
