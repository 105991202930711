import React from 'react';
import { Box } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

const EventEndInfo = () => {
  const { t } = useTranslation();
  return <Box>{t(i18nKeys.joinTeam.onboarding.canNotJoin)}</Box>;
};

export default EventEndInfo;
