import React from 'react';
import { HelpPanel } from '../common/HelpPanel';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import { useChallenges } from '../../store/challenge.context';
import { LoadingBar } from '../common/LoadingBar';
import ChallengeDetails from '../tools/ChallengeDetails';
import Challenge from '../tools/Challenge';
import { ChallengeForImport } from '../../types/Challenge';
import DifficultyIndicator from '../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { HorizontalRule } from '../common/HorizontalRule';
import { Header, Icon, Link } from '@amzn/awsui-components-react';
import { EVENT_DETAILS_ROUTES } from '../../routes';
import ChallengeSetDetails from '../tools/ChallengeSetDetails';
import { ChallengeSet } from '@/src/types/ChallengeSet';
import { useChallengeSet } from '@/src/store/challenge-set.context';
import JamChallengesDetail from '../tools/JamChallengeDetails';
import { IJamChallenge } from '@/src/types/JamChallenges';

export const AWSJamDescription: React.FC = () => {
  const { t } = useTranslation();
  return <p>{t(i18nKeys.home.description)}</p>;
};

export const AWSJamInfo: React.FC = () => {
  return (
    <HelpPanel title="AWS Jam">
      <AWSJamDescription />
    </HelpPanel>
  );
};

export const EventsInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <HelpPanel title={t(i18nKeys.events.title)}>
      <p>{t(i18nKeys.events.list.description)}</p>
    </HelpPanel>
  );
};

export const ChallengesInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <HelpPanel title={t(i18nKeys.challenges.title)}>
      <p>{t(i18nKeys.challenges.list.description)}</p>
    </HelpPanel>
  );
};

const renderChallengeList = (description: string, challenges: (ChallengeForImport | undefined)[]) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <p className="default-text">{description}</p>
      {challenges.map((challenge: ChallengeForImport | undefined, i) => {
        return (
          <div key={`challenge-descriptor-${i}`} className="default-text">
            {i + 1}.{' '}
            {!challenge?.missing ? (
              <Link href={`/challenges/challenge-details/${challenge?.id}`} external>
                {challenge?.title}
              </Link>
            ) : (
              challenge.title
            )}
            {challenge && (
              <div style={{ marginLeft: '15px', marginTop: '5px' }}>
                <DifficultyIndicator challenge={challenge} inlineWarmup />
              </div>
            )}
            {challenge?.defective && (
              <span className="warning">
                <Icon name="status-warning" />
                {t(i18nKeys.general.defective)}
              </span>
            )}
            <HorizontalRule evenMargins />
          </div>
        );
      })}
    </React.Fragment>
  );
};

export const EventChallengeList: React.FC<{
  eventTitle: string;
  eventId: string;
  challenges: (ChallengeForImport | undefined)[];
}> = ({ eventTitle, eventId, challenges }) => {
  const { t } = useTranslation();
  return (
    <HelpPanel title={eventTitle}>
      <Header variant="h3">{t(i18nKeys.general.description)}</Header>
      {renderChallengeList(
        `${t(i18nKeys.challenges.importChallenges.fields.challengeThatWerePartOfEvent)}${eventTitle}`,
        challenges
      )}
      <Link href={EVENT_DETAILS_ROUTES.Challenges.resolve(eventId)} external>
        {t(i18nKeys.challenges.importChallenges.buttons.goToEventChallengeList)}
      </Link>
    </HelpPanel>
  );
};

export const ChallengeSetList: React.FC<{
  challengeSet: ChallengeSet;
  challenges: (ChallengeForImport | undefined)[];
}> = ({ challengeSet, challenges }) => {
  const { t } = useTranslation();
  return (
    <HelpPanel title={challengeSet.title}>
      <Header variant="h3">{t(i18nKeys.general.description)}</Header>
      {renderChallengeList(challengeSet.description || '', challenges)}
      <Link href={`/challenges/set/${challengeSet.id}`} external>
        {t(i18nKeys.challenges.importChallenges.buttons.goToChallengeSetList)}
      </Link>
    </HelpPanel>
  );
};

export const ChallengeInfo: React.FC = () => {
  const { fullChallenge } = useChallenges();

  if (!fullChallenge) {
    return (
      <div style={{ marginTop: '32px' }}>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <HelpPanel title={fullChallenge.latestApproved?.props.title}>
        <ChallengeDetails fullChallenge={fullChallenge} />
      </HelpPanel>
    );
  }
};

export const ChallengeDetailsInfo: React.FC = () => {
  const { fullChallenge } = useChallenges();

  if (!fullChallenge) {
    return (
      <div style={{ marginTop: '32px' }}>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <HelpPanel title={fullChallenge.latest?.props.title}>
        <Challenge challenge={fullChallenge} />
      </HelpPanel>
    );
  }
};

export const ChallengeSetDetailsInfo: React.FC = () => {
  const { challengeSet } = useChallengeSet();

  if (!challengeSet) {
    return (
      <div style={{ marginTop: '32px' }}>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <HelpPanel title={challengeSet.title}>
        <ChallengeSetDetails challengeSet={challengeSet} />
      </HelpPanel>
    );
  }
};

export const JamChallengeDetailsInfo: React.FC<IJamChallenge> = (challenge) => {
  if (!challenge) {
    return (
      <div style={{ marginTop: '32px' }}>
        <LoadingBar />
      </div>
    );
  } else {
    return (
      <HelpPanel title={challenge.title}>
        <JamChallengesDetail challenge={challenge as IJamChallenge} />
      </HelpPanel>
    );
  }
};
