/* eslint-disable */
import { getUniversalRoute, UniversalRoute } from "./routes";
import {
  ConsolePreference,
  HybridConsolePrefix,
  LocalStorageKey,
  NormalizedPath,
  RouteType,
} from "./types";

/**
 * Perform a redirect in-place.
 */
function redirectTo(path: string, newWindow: boolean = false) {
  if (newWindow) {
    window.open(path, "_blank");
  } else {
    window.location.replace(path);
  }
}

function getRedirectPath(destination?: string): string | undefined {
  const normalizedPath = new NormalizedPath(destination ?? window.location.pathname);
  const currentRoute: UniversalRoute | undefined =
    getUniversalRoute(normalizedPath);
  const currentRouteType: RouteType | undefined =
    currentRoute?.routeType;
  const originalPrefix = normalizedPath.consolePrefix;

  if (currentRoute?.disableRedirects) {
    return;
  }

  if (!currentRouteType && !originalPrefix) {
    // if this path matches no route, and the user has no path prefix, redirect the user to the same path in the Polaris console;
    // otherwise, do not redirect the user, as the single page app will handle sending the user to a 404 page.
    return normalizedPath.withPrefix(HybridConsolePrefix.Polaris) + window.location.search;
  }

  let newPrefix: HybridConsolePrefix = originalPrefix!;

  switch (currentRouteType) {
    case RouteType.Both:
      const preference: ConsolePreference = getConsolePreference();

      if (preference === ConsolePreference.Polaris) {
        newPrefix = HybridConsolePrefix.Polaris;
      } else if (preference === ConsolePreference.Legacy) {
        newPrefix = HybridConsolePrefix.Legacy;
      }
      break;
    case RouteType.PolarisOnly:
      newPrefix = HybridConsolePrefix.Polaris;
      break;
    case RouteType.LegacyOnly:
      newPrefix = HybridConsolePrefix.Legacy;
      break;
  }

  // i.e. if the user should have a different prefix than their current one, redirect them
  if (newPrefix !== originalPrefix) {
    return normalizedPath.withPrefix(newPrefix) + window.location.search;
  }
}

/**
 * Set the user's console preference to the given preference.
 * If the destination route is available in the console being switched to, redirect to it;
 * if there is no destination route, use the current route as the destination route.
 * If the destination/current route is not available, redirect to home.
 */
function switchConsoles(newPreference: ConsolePreference, destination?: string) {
  setConsolePreference(newPreference);

  const redirectedTo = performRedirect(destination);

  if (!redirectedTo) {
    redirectTo(
      newPreference === ConsolePreference.Polaris
        ? HybridConsolePrefix.Polaris
        : HybridConsolePrefix.Legacy
    );
  }
}

export function switchToPolarisConsole(destination?: string) {
  switchConsoles(ConsolePreference.Polaris, destination);
}

export function switchToLegacyConsole(destination?: string) {
  switchConsoles(ConsolePreference.Legacy, destination);
}

/**
 * Using the user's current location (according to window.location),
 * determine the final path (including the `/v1`, `/v2` prefix)
 * to redirect the user to, accounting for the route's configuration and the
 * user preference. If the user does not need to be redirected, this performs no action.
 *
 * If the given path matches no route and the user has no prefix, redirect the user to the Polaris console.
 *
 *
 * @param destination - destination to redirect a user to
 * @param newWindow - whether to open the redirect path in a new window. Defaults to current window
 *
 * @returns - The full path the user was redirected to, or no path if the user was not redirected.
 */
export function performRedirect(destination?: string, newWindow: boolean = false): string | undefined {
  const redirectPath = getRedirectPath(destination);

  if (redirectPath) {
    redirectTo(redirectPath, newWindow);
  }

  return redirectPath;
}

function isValidConsolePreference(preference: string | undefined) {
  return (
    preference &&
    Object.values(ConsolePreference).includes(preference as ConsolePreference)
  );
}

function getConsolePreference(): ConsolePreference {
  const savedPreference = localStorage.getItem(
    LocalStorageKey.ConsolePreference
  );

  if (!isValidConsolePreference(savedPreference!)) {
    return ConsolePreference.Polaris;
  }

  return savedPreference as ConsolePreference;
}

export function setConsolePreference(preference: ConsolePreference) {
  localStorage.setItem(LocalStorageKey.ConsolePreference, preference);
}

export function getConsoleBannerAcknowledged(): boolean {
  return (
    localStorage.getItem(LocalStorageKey.AcknowledgedNewConsoleBanner) != null
  );
}

export function setConsoleBannerAcknowledged(): void {
  localStorage.setItem(LocalStorageKey.AcknowledgedNewConsoleBanner, "true");
}

function getBaseUrl(basePathPrefix: string): string {
  let port: string | null = window.location.port;
  if (port === "443" || port === "80") {
    port = null;
  }

  // i.e. https://localhost:4201/v2
  return `${window.location.protocol}//${window.location.hostname}${
    port ? ":" + port : ""
  }${basePathPrefix}`;
}

/**
 * Get the oauth sign-in URL for the console with the given base path prefix. Used for Amplify.
 */
export function getSignInUrl(basePathPrefix: string): string {
  return `${getBaseUrl(basePathPrefix)}/oauth2`;
}

/**
 * Get the oauth sign-out URL for the console with the given base path prefix. Used for Amplify.
 */
export function getSignOutUrl(basePathPrefix: string): string {
  return `${getBaseUrl(basePathPrefix)}/signout`;
}
