import React, { useState, useContext, useEffect } from 'react';
import { applyMode, Mode } from '@amzn/awsui-global-styles';

import { DARK_MODE_KEY } from '@/src/constants/common';

const getCurrentTheme = (): Mode => {
  if (localStorage.getItem(DARK_MODE_KEY) === 'yes') {
    return Mode.Dark;
  }
  return Mode.Light;
};

export interface ThemeContextValue {
  themeMode: Mode;
  setThemeMode: React.Dispatch<React.SetStateAction<Mode>>
}

const ThemeContext = React.createContext<ThemeContextValue>({
  themeMode: Mode.Light,
  setThemeMode: (_mode: React.SetStateAction<Mode>) => { return }
});

const ThemeProvider: React.FC = ({ children }) => {
  const [themeMode, setThemeMode] = useState<Mode>(getCurrentTheme());

  useEffect(() => {
    if (themeMode === Mode.Dark) {
      applyMode(Mode.Dark);
      localStorage.setItem(DARK_MODE_KEY, 'yes');
    } else {
      applyMode(Mode.Light);
      localStorage.removeItem(DARK_MODE_KEY);
    }
  }, [themeMode]);

  const data: ThemeContextValue = { themeMode, setThemeMode };

  return <ThemeContext.Provider value={data}>{children}</ThemeContext.Provider>;
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme can only be used inside ThemeProvider');
  }
  return context;
};

export { ThemeProvider, useTheme };
