import React from 'react';
import { Event } from '../../types/Event';
import { getBrowserTimezoneName, getBrowserUTCOffset } from '../../utils/event-time.utils';
import { DateTimeKeys, TimezoneFormat } from './CommonModel';
interface DateTimeProps {
  startOrEnd: DateTimeKeys;
  event?: Event | null;
  timezoneFormat: TimezoneFormat;
  displayEventTimezone?: boolean;
  displayLocalTimezone?: boolean;
  displayInlineDateTime?: boolean;
  displayEventTimezoneInGMT?: boolean;
}

/**
 * Date Component that will format date to appropriate timezone and local format per JAM-2326
 *
 * @param param0 Date to format
 * @returns React.Fragment of date split into date and time
 */
export const DateTime: React.FC<DateTimeProps> = ({
  startOrEnd,
  event,
  timezoneFormat,
  displayEventTimezone,
  displayLocalTimezone,
  displayInlineDateTime,
  displayEventTimezoneInGMT,
}) => {
  const browserTimezoneName = getBrowserTimezoneName();
  const browserUTCOffset = getBrowserUTCOffset();
  const dateTimes = {
    [DateTimeKeys.START]: {
      date: event?.startDateInEventLocalTime,
      time: event?.startTimeInEventLocalTime,
      localDate: event?.startDateInBrowserTime,
      [TimezoneFormat.LOCAL]: event?.startTimeInBrowserLocalTime,
      [TimezoneFormat.UTC]: event?.startTimeInUTCTime,
    },
    [DateTimeKeys.END]: {
      date: event?.endDateInEventLocalTime,
      time: event?.endTimeInEventLocalTime,
      localDate: event?.endDateInBrowserTime,
      [TimezoneFormat.LOCAL]: event?.endTimeInBrowserLocalTime,
      [TimezoneFormat.UTC]: event?.endTimeInUTCTime,
    },
  };

  return (
    (event && (
      <React.Fragment>
        {!displayInlineDateTime && <div>{`${dateTimes[startOrEnd].date} (${dateTimes[startOrEnd].time})`}</div>}
        {displayInlineDateTime && (
          <div>
            {timezoneFormat === TimezoneFormat.LOCAL ? dateTimes[startOrEnd].localDate : dateTimes[startOrEnd].date},{' '}
            {timezoneFormat === TimezoneFormat.LOCAL
              ? dateTimes[startOrEnd][timezoneFormat]
              : dateTimes[startOrEnd].time}
          </div>
        )}
        {!displayInlineDateTime && (
          <div className="secondary-text">
            {dateTimes[startOrEnd][timezoneFormat] !== dateTimes[startOrEnd].time && (
              <span>
                {dateTimes[startOrEnd].localDate} ({dateTimes[startOrEnd][timezoneFormat]})
              </span>
            )}
          </div>
        )}
        {displayEventTimezone && <div className="secondary-text">{`${event?.timezone} ${event?.utcOffset}`}</div>}
        {displayEventTimezoneInGMT && <div className="ml-5">{`(GMT ${event?.utcOffset})`}</div>}
        {displayLocalTimezone && <div className="secondary-text">{`${browserTimezoneName} ${browserUTCOffset}`}</div>}
      </React.Fragment>
    )) ||
    null
  );
};
