import React from 'react';
import { Box, Button, Modal, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { AwsCLICommands } from '@/src/__mocks__/mock-jam-challenge-tasks';
import AwsCLIModalContent from './AwsCLIModalContent';
import { AccountCredentials } from '@/src/types/LabModels';

interface AwsCliProps {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  awsAccount: AccountCredentials | undefined;
}

const AwsCliModal: React.FC<AwsCliProps> = ({ value, setValue, awsAccount }) => {
  const { t } = useTranslation();
  const handleModal = () => setValue(!value);
  const awsCliCommands = AwsCLICommands(awsAccount);
  return (
    <Modal
      onDismiss={handleModal}
      visible={value}
      size="large"
      disableContentPaddings
      footer={
        <Box float="right" margin={{ vertical: 'xs' }}>
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={handleModal}>
              {t(i18nKeys.general.close)}
            </Button>
          </SpaceBetween>
        </Box>
      }>
      <Box padding={'m'}>
        <Tabs
          tabs={[
            {
              label: t(i18nKeys.myJams.challenges.tabs.linuxAndMacOs),
              id: 'linux-macos',
              content: <AwsCLIModalContent content={awsCliCommands.LinuxAndMacOS} />,
            },
            {
              label: t(i18nKeys.myJams.challenges.tabs.powershell),
              id: 'powershell',
              content: <AwsCLIModalContent content={awsCliCommands.Powershell} />,
            },
            {
              label: t(i18nKeys.myJams.challenges.tabs.windowsCmd),
              id: 'windows',
              content: <AwsCLIModalContent content={awsCliCommands.Windows} />,
            },
            {
              label: t(i18nKeys.myJams.challenges.tabs.python),
              id: 'pyhton',
              content: <AwsCLIModalContent content={awsCliCommands.Python} />,
            },
          ]}
        />
      </Box>
    </Modal>
  );
};

export default AwsCliModal;
