import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { config as appConfig } from '../config/app-config';

let awsRum: AwsRum;

export const configureAwsRum = (): AwsRum => {
  if (awsRum || !appConfig.rum.applicationId || !appConfig.rum.identityPoolId || !appConfig.rum.guestRoleArn) {
    return awsRum;
  }
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: appConfig.rum.guestRoleArn,
      identityPoolId: appConfig.rum.identityPoolId,
      endpoint: `https://dataplane.rum.${appConfig.rum.applicationRegion}.amazonaws.com`,
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
    };

    const APPLICATION_ID = appConfig.rum.applicationId;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = appConfig.rum.applicationRegion;

    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
  return awsRum;
};
