import { isPreProd } from './env.utils';
export interface SpoofedGroup {
  value: string;
  label: string;
}
export const SPOOFED_GROUP_KEY = '__jam_groups__';
export const getSpoofedGroups = (): string[] => {
  if (isPreProd() && localStorage.getItem(SPOOFED_GROUP_KEY)) {
    return JSON.parse(localStorage.getItem(SPOOFED_GROUP_KEY) || '[]') as string[];
  }
  return [];
};
