import {
  Box,
  Button,
  Checkbox,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useEvents } from '../../../../store/events.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Markdown } from '../../../common/Markdown';

interface PreEventLobbyVideoProps {
  event?: Event;
}

const PreEventLobbyVideo: React.FC<PreEventLobbyVideoProps> = ({ event }) => {
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { eventConfig } = useEvents();
  const { user, isLoggedIn } = useUser();
  const { t } = useTranslation();
  const [preview, setPreview] = useState(false);

  let disabled = false;
  if (user && isLoggedIn) {
    disabled = !event?.canEditAttribute('onboardingVideo', user) || false;
  }

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.preEventLobbyVideo)}</Header>}>
      {editMode && user?.isEventAdmin && (
        <Grid gridDefinition={[{ colspan: 9 }]}>
          <SpaceBetween direction="vertical" size="s">
            <Checkbox
              description={t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.showVideo.description)}
              checked={editedEvent?.onboardingVideo.show || false}
              disabled={disabled}
              onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.LOBBY_VIDEO_SHOW, detail.checked)}>
              {t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.showVideo.label)}
            </Checkbox>
            <FormField
              label={t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoURL.label)}
              constraintText={t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoURL.description, {
                firstURL: 'https://static.jam.awsevents.com',
                secondURL: 'https://aws-jam-challenge-resources.s3.amazonaws.com',
              })}>
              <SpaceBetween direction="vertical" size="s">
                <Input
                  value={editedEvent?.onboardingVideo?.url || ''}
                  disabled={disabled}
                  onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.LOBBY_VIDEO_URL, detail.value)}
                />
                <div>
                  {eventConfig?.defaultOnboardingVideo?.url && (
                    <Button
                      disabled={disabled}
                      onClick={() =>
                        handleUpdateEditEvent(EditEventActions.DEFAULT_LOBBY_VIDEO, eventConfig.defaultOnboardingVideo)
                      }>
                      {t(i18nKeys.events.eventDetails.buttons.useDefaultVideo)}
                    </Button>
                  )}
                  {editedEvent?.onboardingVideo.url && (
                    <Button variant="link" onClick={() => setPreview(!preview)}>
                      {preview
                        ? t(i18nKeys.events.eventDetails.buttons.closePreview)
                        : t(i18nKeys.events.eventDetails.buttons.preview)}
                    </Button>
                  )}
                </div>
              </SpaceBetween>
            </FormField>
            <FormField label={t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoTitle.label)}>
              <Input
                value={editedEvent?.onboardingVideo?.title || ''}
                disabled={disabled}
                onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.LOBBY_VIDEO_TITLE, detail.value)}
              />
            </FormField>
            <FormField
              label={t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoDescription.label)}
              description={t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoDescription.description)}>
              <Textarea
                rows={4}
                placeholder={t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoDescription.label)}
                value={editedEvent?.onboardingVideo?.description || ''}
                disabled={disabled}
                onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.LOBBY_VIDEO_DESCRIPTION, detail.value)}
              />
            </FormField>
            {preview && editedEvent?.onboardingVideo.url && (
              <React.Fragment>
                <hr />
                <div>
                  <h4>{t(i18nKeys.events.eventDetails.buttons.preview)}</h4>
                  <video style={{ width: '100%' }}>
                    <source src={editedEvent?.onboardingVideo.url || ''} />
                    {t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.noVideoSupport)}
                  </video>
                  <Box variant="h4">{editedEvent?.onboardingVideo.title}</Box>
                  <Markdown content={editedEvent?.onboardingVideo.description || ''} />
                </div>
              </React.Fragment>
            )}
          </SpaceBetween>
        </Grid>
      )}
      {!editMode && (
        <React.Fragment>
          <div className="section-first-row">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.showVideo.label)}
              </div>
              <div>{event?.onboardingVideo.show ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
            </Grid>
          </div>
          <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoURL.label)}
              </div>
              <div>
                {event?.onboardingVideo?.url ? event?.onboardingVideo.url : t(i18nKeys.events.eventDetails.labels.none)}
              </div>
            </Grid>
          </div>
          <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoTitle.label)}
              </div>
              <div>
                {event?.onboardingVideo?.title
                  ? event?.onboardingVideo.title
                  : t(i18nKeys.events.eventDetails.labels.none)}
              </div>
            </Grid>
          </div>
          <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.videoDescription.label)}
              </div>
              <div>
                {event?.onboardingVideo?.description
                  ? event?.onboardingVideo.description
                  : t(i18nKeys.events.eventDetails.labels.none)}
              </div>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </ExpandableSection>
  );
};
export default PreEventLobbyVideo;
