import { TeamChallengeQueueInfo } from '@/src/types/TeamChallengeQueueInfo';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { toOrdinal } from '@/src/utils/number.utils';
import { Box, Grid } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';

interface JamChallengeQueueInfoProps {
  queueInfo: TeamChallengeQueueInfo;
}

const JamChallengeQueueInfo: React.FC<JamChallengeQueueInfoProps> = ({ queueInfo }) => {
  const { t } = useTranslation();
  const [, forceUpdate] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => forceUpdate((prev) => !prev), 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
      <Box>
        {t(i18nKeys.myJams.challenges.details.challengeStatusModal.queue.queuePosition)}
        {t(i18nKeys.myJams.challenges.details.challengeStatusModal.queue.countWaiting, {
          position: toOrdinal(queueInfo.queuePosition as number),
          total: queueInfo.queueSize,
        })}
      </Box>
      <Box>
        {t(i18nKeys.myJams.challenges.details.challengeStatusModal.queue.enteredQueue)}
        <TimeAgo datetime={queueInfo.timeEnteredQueue as number} />
      </Box>
      <Box>
        {t(i18nKeys.myJams.challenges.details.challengeStatusModal.queue.remainingWaitTime)}
        {queueInfo.waitTimeInMinutes} {t(i18nKeys.general.minutes)}
      </Box>
    </Grid>
  );
};

export default JamChallengeQueueInfo;
