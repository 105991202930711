import { useApi } from '@/src/store/api.context';
import { EventFilterOptions, TinyEvent } from '@/src/types/Event';
import { preProdLogger } from '@/src/utils/log.utils';
import { 
  Table,
  Box,
  Header,
  CollectionPreferences,
  Button,
  Pagination,
  CollectionPreferencesProps,
  PropertyFilter,
} from '@amzn/awsui-components-react';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  COLUMN_DEFINITIONS,
  DEFAULT_COLLECTION_PREFERENCES,
  LabEventsCollectionPreferences,
  PROPERTY_FILTER_PROPERTIES,
  COLECTION_PREFERENCES_PROPS
} from './LabEvents.config';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { DateRangeFilterInput } from '../../common/DateRangeFilterInput/DateRangeFilterInput';
import { DateRangeFilter } from '@/src/types/common';
import { DateTimeKeys } from '../../common/CommonModel';

import './LabEvents.scss'

const EmptyState = ({ title, subtitle, action }: { title: string; subtitle?: string; action: React.ReactNode }) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
};

const DEFAULT_START_DATE = moment().subtract(30, 'd').format('YYYY-MM-DD')
const LabEvents:React.FC = () => {
  const { eventsApi } = useApi()
  const { t } = useTranslation()
  const [ fetchingLabEvents, setFetchingLabEvents ] = useState(false)
  const [ startDate, setStartDate ] = useState<string>(DEFAULT_START_DATE)
  const [ endDate, setEndDate ] = useState<string>()
  const [ labEvents, setLabEvents ] = useState<TinyEvent[]>([])
  const [ preferences, setPreferences] = useState<LabEventsCollectionPreferences>(DEFAULT_COLLECTION_PREFERENCES)
  const { items, actions, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    labEvents,
    {
      filtering: {
        empty: <EmptyState title="No events" action={<Button>Create Event</Button>} />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      propertyFiltering: { filteringProperties: PROPERTY_FILTER_PROPERTIES },
      sorting: {},
      selection: {},
    }
  );

  useEffect(() => {
    const fetchLabEvents = async () => {
      setFetchingLabEvents(true)
      try {
        const options: EventFilterOptions = {
          dateRangeStart: startDate,
          dateRangeEnd: endDate,
          excludeNeverApproved: true
        }
        const result = await eventsApi.getTinyEvents(options)
        setLabEvents(result)
      } catch (error) {
        preProdLogger(error)
      } finally {
        setFetchingLabEvents(false)
      }
      
    }
    if (fetchingLabEvents) return 
    if (labEvents.length > 0) return 
    void fetchLabEvents()
  }, [eventsApi, fetchingLabEvents, labEvents, startDate, endDate])

  const onCollectionPreferencesChange:CollectionPreferencesProps['onConfirm'] = useCallback(({detail}) => {
    setPreferences(detail as LabEventsCollectionPreferences)
  }, []) 
  
  const i18nStrings = useMemo(() => ({
    filteringAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.filteringAriaLabel),
    dismissAriaLabel: t(i18nKeys.eventTemplates.customTable.filter.dismissAriaLabel),
    filteringPlaceholder: t(i18nKeys.eventTemplates.table.eventPlaceholder),
    groupValuesText: t(i18nKeys.eventTemplates.customTable.filter.groupValuesText),
    groupPropertiesText: t(i18nKeys.eventTemplates.customTable.filter.groupPropertiesText),
    operatorsText: t(i18nKeys.eventTemplates.customTable.filter.operatorsText),
    operationAndText: t(i18nKeys.eventTemplates.customTable.filter.operationAndText),
    operationOrText: t(i18nKeys.eventTemplates.customTable.filter.operationOrText),
    operatorLessText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessText),
    operatorLessOrEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorLessOrEqualText),
    operatorGreaterText: t(i18nKeys.eventTemplates.customTable.filter.operatorGreaterText),
    operatorGreaterOrEqualText: t(
      i18nKeys.eventTemplates.customTable.filter.operatorGreaterOrEqualText
    ),
    operatorContainsText: t(i18nKeys.eventTemplates.customTable.filter.operatorContainsText),
    operatorDoesNotContainText: t(
      i18nKeys.eventTemplates.customTable.filter.operatorDoesNotContainText
    ),
    operatorEqualsText: t(i18nKeys.eventTemplates.customTable.filter.operatorEqualsText),
    operatorDoesNotEqualText: t(i18nKeys.eventTemplates.customTable.filter.operatorDoesNotEqualText),
    editTokenHeader: t(i18nKeys.eventTemplates.customTable.filter.editTokenHeader),
    propertyText: t(i18nKeys.eventTemplates.customTable.filter.propertyText),
    operatorText: t(i18nKeys.eventTemplates.customTable.filter.operatorText),
    valueText: t(i18nKeys.eventTemplates.customTable.filter.valueText),
    cancelActionText: t(i18nKeys.eventTemplates.customTable.filter.cancelActionText),
    applyActionText: t(i18nKeys.eventTemplates.customTable.filter.applyActionText),
    allPropertiesLabel: t(i18nKeys.eventTemplates.customTable.filter.allPropertiesLabel),
    tokenLimitShowMore: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowMore),
    tokenLimitShowFewer: t(i18nKeys.eventTemplates.customTable.filter.tokenLimitShowFewer),
    clearFiltersText: t(i18nKeys.eventTemplates.customTable.filter.clearFiltersText),
  }), [t])

  const dateRangeFilter:DateRangeFilter =  useMemo(() => {
    return {
      start: startDate,
      end: endDate ?? null
    }
  }, [startDate, endDate])

  const onDateRangeSelected = useCallback((startOrEnd: DateTimeKeys, date: string) => {
    let refresh = false
    const formattedDate = moment(date).format('YYYY-MM-DD')
    switch(startOrEnd) {
      case DateTimeKeys.START: {
        if (formattedDate === startDate) break
        refresh = true
        setStartDate(formattedDate)
        break;
      }
      case DateTimeKeys.END: {
        if (formattedDate === endDate) break
        refresh = true
        setEndDate(formattedDate)
        break;
      }
    }
    if (refresh) {
      setLabEvents([]) // reloads content
    }
  }, [startDate, endDate])

  return <div>
    <Table
      {...collectionProps}
      loading={fetchingLabEvents}
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loadingText="Loading events"
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          i18nStrings={i18nStrings}
        />
      }
      header={
        <div className='lab-events-header-container'>
          <div className='lab-events-header'>
            <Header counter={`(${labEvents?.length ?? 0})`}>Events </Header>
          </div>
          <div className='lab-events-date-select'>
            <DateRangeFilterInput 
              direction='horizontal'
              dateRangeFilter={dateRangeFilter}
              className='labs-date-range-input'
              handleDateSelection={onDateRangeSelected}
            />
          </div>
        </div>
      }
      pagination={
        <Pagination {...paginationProps} />
      }
      visibleColumns={preferences.visibleContent}
      preferences={
        <CollectionPreferences
          {...COLECTION_PREFERENCES_PROPS}
          preferences={preferences}
          onConfirm={onCollectionPreferencesChange}
        />
      }
    />
  </div>
};

export default LabEvents;
