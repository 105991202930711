import { Box, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

import { Challenge } from '@/src/types/Challenge';

import { EventLabSummary } from '@/src/types/EventLabSummary';
import ChallengeLab from './ChallengeLab';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { LabDashboardChartData } from '@/src/types/LabModels';

interface ChallengeLabsProps {
  challenges: Challenge[];
  summary: EventLabSummary;
  eventName: string;
  changeTab: (id: string) => void;
  showAuditTrail: (showFor: string) => void;
  labDashboardChartData?: LabDashboardChartData;
}

const ChallengeLabs: React.FC<ChallengeLabsProps> = ({
  challenges,
  summary,
  eventName,
  changeTab,
  showAuditTrail,
  labDashboardChartData,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Box margin={{ bottom: 'm' }}>
        <Header variant="h3" counter={`(${challenges.length})`}>
          {t(i18nKeys.eventLabs.challengeLab.challengeLabs)}
        </Header>
      </Box>
      <SpaceBetween size="m">
        {challenges.map((challenge) => (
          <ChallengeLab
            key={challenge.id}
            challenge={challenge}
            snapshot={summary.getLabSnapshot(challenge.id || '')}
            getUnusedImportedPropsCount={(id: string) => summary.unusedImportedPropsCounts[id] || 0}
            getUsedImportedPropsCount={(id: string) => summary.usedImportedPropsCounts[id] || 0}
            labProvider={summary.challengeLabProviders[challenge.id || '']}
            eventName={eventName}
            labShutoffStatus={summary.getChallengeDescriptorById(challenge.id || '')?.shutoffStatus}
            changeTab={changeTab}
            metadata={summary.labEventMetadata[challenge.id || '']}
            showAuditTrail={showAuditTrail}
            labInternalStatusCounts={EventLabSummary.getLabInternalStatusCounts(summary, challenge.id || '')}
            labDashboardChartData={labDashboardChartData}
          />
        ))}
      </SpaceBetween>
    </div>
  );
};

export default ChallengeLabs;
