import * as React from 'react';
import { Link } from 'react-router-dom';
import { CHALLENGE_DETAILS_ROUTES } from '../../../../routes';
import { ChallengeIssue } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../utils/table.utils';
import { TFunctionProvider } from '../../../common/TFunctionProvider';
import moment from 'moment';
import { MM_DD_YYYY } from '@/src/constants/common';
import { Badge, Button } from '@amzn/awsui-components-react';
import { CustomBadge } from '@/src/components/common/CustomBadge/CustomBadge';

export const filteringFunction = (item: ChallengeIssue, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item?.id) ||
    matcher.isMatch(item?.title) ||
    matcher.isMatch(item?.openedBy) ||
    matcher.isMatch(item?.severity)
  );
};

const CustomSeverity = (item: string) => {
  if (item.toLocaleLowerCase() === 'critical') {
    return <Badge color="red">{item}</Badge>;
  }
  if (item.toLocaleLowerCase() === 'high') {
    return <CustomBadge color="#EB5F07">{item}</CustomBadge>;
  }
  if (item.toLocaleLowerCase() === 'medium') {
    return <Badge color="blue">{item}</Badge>;
  }
  if (item.toLocaleLowerCase() === 'low') {
    return <CustomBadge color="#014B44">{item}</CustomBadge>;
  }
};


export const getColumnDefinitions = (onTitleClicked?: (item: ChallengeIssue) => void) => [
  {
    id: 'title',
    header: <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.file)}</TFunctionProvider>,
    cell: (item: ChallengeIssue) => {
      if (onTitleClicked) {
        return (
          <Button id='challenge-issue-table-title' data-testid='challenge-issue-table-title' variant="inline-link" onClick={onTitleClicked.bind(this, item)}>
            {item.title}
          </Button>
        );
      }
      return (
        <Link to={`${CHALLENGE_DETAILS_ROUTES.Issues.resolve(item.challengeId ?? '')}/${item.id}`}>{item.title}</Link>
      );
    },
    sortingField: 'title',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'createdDate',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.dateCreated)}</TFunctionProvider>
    ),
    cell: (item: ChallengeIssue) => (item ? moment(item.createdDate).format(MM_DD_YYYY) : '-'),
    sortingField: 'createdDate',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'openedBy',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.openedBy)}</TFunctionProvider>
    ),
    cell: (item: ChallengeIssue) => (item ? item.openedBy : '-'),
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'status',
    header: <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.status)}</TFunctionProvider>,
    cell: (item: ChallengeIssue) =>
      item ? <Badge color={item.status.toLocaleLowerCase() === 'open' ? 'green' : 'grey'}>{item.status}</Badge> : '-',
    sortingField: 'status',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'severity',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.severity)}</TFunctionProvider>
    ),
    cell: (item: ChallengeIssue) => (item ? CustomSeverity(item.severity) : '-'),
    sortingField: 'severity',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'comments',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.comments)}</TFunctionProvider>
    ),
    cell: (item: ChallengeIssue) => (item ? item.comments.length : '-'),
    minWidth: 100,
    allowLineWrap: true,
  },
];
