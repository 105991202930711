import React, { useCallback, useMemo, useState } from 'react';
import { Form } from '@amzn/awsui-components-react';

// components
import DetailSection from './DetailSection';
import Rationale from './Rationale';
import ImageSection from './ImageSection';
import { useCreateEventTemplate } from '@/src/store/create-event-template.context';
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';
import { eventTemplateDetailsValidator } from '@/src/utils/event-template.validation.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { EventTemplateDetailFields } from '@/src/types/EventTemplate';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';

interface IProps {
  activeStepIndex: number;
  setActiveStepIndex: (x: number) => void;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

export const ConfigureEvent = ({ validationHandler }: IProps) => {
  const { t } = useTranslation();
  const {
    summary,
    teamSize,
    minParticipants,
    maxParticipants,
    topics,
    tags,
    learningType,
    duration,
    imageUrl,
    rationale,
    onSummaryChange,
    onTeamSizeChange,
    onMinParticipantsChange,
    onMaxParticipantsChange,
    onTopicsChange,
    onLearningTypeChange,
    onDurationChange,
    onTagsChange,
    onImageChange,
    onRationaleChange,
  } = useCreateEventTemplate();
  const { offers } = useEventTemplateOffers() 

  const { selectedChallengeListItems } = useEventTemplateChallenges();
  const [eventDetailsSummaryError, setEventDetailsSummaryError] = useState('');
  const [eventDetailsTopicError, setEventDetailsTopicError] = useState('');
  const [eventDetailsTeamSizeError, setEventDetailsTeamSizeError] = useState('');
  const [eventDetailsMinParticipantsError, setEventDetailsMinParticipantsError] = useState('');
  const [eventDetailsMaxParticipantsError, setEventDetailsMaxParticipantsError] = useState('');
  const [eventDetailsTagsError, setEventDetailsTagsError] = useState('');

  const validator = useMemo(
    () =>
      eventTemplateDetailsValidator(
        summary,
        topics,
        teamSize,
        minParticipants,
        maxParticipants,
        tags,
        t(i18nKeys.eventTemplates.step4.summary.errorText),
        t(i18nKeys.eventTemplates.step4.topic.errorText),
        t(i18nKeys.eventTemplates.step4.recommendedTeamSize.errorText),
        {
          required: t(i18nKeys.eventTemplates.step4.participants.min.required),
          notGreaterThanMax: t(i18nKeys.eventTemplates.step4.participants.min.notGreaterThanMax),
        },
        {
          required: t(i18nKeys.eventTemplates.step4.participants.max.required),
          notLessThanMin: t(i18nKeys.eventTemplates.step4.participants.max.notLessThanMin),
        },
        t(i18nKeys.eventTemplates.step4.tags.errorText),
        new Map<EventTemplateDetailFields, (error: string) => void>([
          [EventTemplateDetailFields.EVENT_SUMMARY, (error: string) => setEventDetailsSummaryError(error)],
          [EventTemplateDetailFields.EVENT_TOPIC, (error: string) => setEventDetailsTopicError(error)],
          [EventTemplateDetailFields.EVENT_TEAM_SIZE, (error: string) => setEventDetailsTeamSizeError(error)],
          [
            EventTemplateDetailFields.EVENT_MIN_PARTICIPANTS,
            (error: string) => setEventDetailsMinParticipantsError(error),
          ],
          [
            EventTemplateDetailFields.EVENT_MAX_PARTICIPANTS,
            (error: string) => setEventDetailsMaxParticipantsError(error),
          ],
          [EventTemplateDetailFields.EVENT_TAGS, (error: string) => setEventDetailsTagsError(error)],
        ])
      ),
    [summary, topics, tags, teamSize, minParticipants, maxParticipants, eventTemplateDetailsValidator]
  );

  validationHandler(() => {
    const result = validator.isValidSection(true);
    if (result) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  });

  const handleImageChange = useCallback(
    (file?: File) => {
      void onImageChange(file);
    },
    [onImageChange]
  );

  return (
    <div>
      <form>
        <Form>
          <DetailSection
            hideEventConfiguration
            summary={summary}
            teamSize={teamSize}
            minParticipants={minParticipants}
            maxParticipants={maxParticipants}
            topics={topics}
            tags={tags}
            learningType={learningType}
            duration={duration}
            offers={offers}
            onSummaryChange={onSummaryChange}
            onTeamSizeChange={onTeamSizeChange}
            onMinParticipantsChange={onMinParticipantsChange}
            onMaxParticipantsChange={onMaxParticipantsChange}
            onTopicsChange={onTopicsChange}
            onLearningTypeChange={onLearningTypeChange}
            onDurationChange={onDurationChange}
            onTagsChange={onTagsChange}
            validator={validator}
            eventDetailsSummaryError={eventDetailsSummaryError}
            eventDetailsTopicError={eventDetailsTopicError}
            eventDetailsTeamSizeError={eventDetailsTeamSizeError}
            eventDetailsMinParticipantsError={eventDetailsMinParticipantsError}
            eventDetailsMaxParticipantsError={eventDetailsMaxParticipantsError}
            eventDetailsTagsError={eventDetailsTagsError}
          />
          <ImageSection imageUrl={imageUrl} onImageChange={handleImageChange} />
        </Form>
      </form>
      <Rationale
        selectedChallengeListItems={selectedChallengeListItems}
        rationale={rationale}
        onRationaleChange={onRationaleChange}
      />
    </div>
  );
};
