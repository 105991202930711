import { DateRangeFilter } from '../../../types/common';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getLanguageCodeSafe } from '../../../utils/locale.utils';
import { DateTimeKeys } from '../CommonModel';

export interface DateRangeFilterInputProps {
  direction: string;
  dateRangeFilter: DateRangeFilter;
  handleDateSelection: (startOrEnd: DateTimeKeys, date: string) => void;
  className: string;
}

export const DateRangeFilterInput: React.FC<DateRangeFilterInputProps> = ({
                                                                            direction,
                                                                            dateRangeFilter,
                                                                            handleDateSelection,
                                                                            className,
                                                                          }) => {
  const { i18n, t } = useTranslation();
  const languageCode: string = getLanguageCodeSafe(i18n.language);

  return (
      <SpaceBetween direction={direction as 'vertical' | 'horizontal'} size="xs">
        <div>
          <label htmlFor="startDate" className="date-label">
            {t(i18nKeys.events.fields.filters.rangeStart.title)}
          </label>
          <DatePicker
            value={dateRangeFilter.start || ''}
            nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
            previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
            todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
            className={className}
            ariaLabel={t(i18nKeys.events.fields.filters.labels.rangeStart)}
            openCalendarAriaLabel={(selectedDate) =>
              t(i18nKeys.general.chooseDate) + (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
            }
            controlId="startDate"
            locale={languageCode}
            onChange={(e) => handleDateSelection(DateTimeKeys.START, e.detail.value)}
          />
        </div>
        <div>
          <label htmlFor="endDate" className="date-label">
            {t(i18nKeys.events.fields.filters.rangeEnd.title)}
          </label>
          <DatePicker
            value={dateRangeFilter.end || ''}
            nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
            previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
            todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
            placeholder="YYYY/MM/DD"
            ariaLabel={t(i18nKeys.events.fields.filters.labels.rangeEnd)}
            openCalendarAriaLabel={(selectedDate) =>
              t(i18nKeys.general.chooseDate) + (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
            }
            className={className}
            controlId="endDate"
            locale={languageCode}
            onChange={(e) => handleDateSelection(DateTimeKeys.END, e.detail.value)}
          />
        </div>
      </SpaceBetween>
  );
};
