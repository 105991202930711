import { Button, ExpandableSection, FormField, Grid, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import SecretKey from '../../../common/SecretKey';
import { generateRandomKey } from '@/src/utils/string.utils';
interface SecretKeyDetailsProps {
  event: Event;
}

const SecretKeyDetails: React.FC<SecretKeyDetailsProps> = ({ event }) => {
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { user } = useUser();
  const { t } = useTranslation();

  const showSecretKey = (): boolean => {
    if (event.isCampaignEvent) {
      return false;
    }
    if (user?.isEventAdmin) {
      return true;
    }
    return event.approved;
  };

  const generateKey = () => {
    handleUpdateEditEvent(EditEventActions.EVENT_CODE, generateRandomKey());
  };

  return (
    <React.Fragment>
      {showSecretKey() && !event?.isCampaignEvent && (
        <ExpandableSection
          variant="container"
          header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.secretKey)}</Header>}>
          {editMode && user && (
            <Grid gridDefinition={[{ colspan: 8 }]}>
              <FormField
                constraintText={t(i18nKeys.events.eventDetails.labels.secretKey.description)}
                label={t(i18nKeys.events.eventDetails.headers.secretKey)}
                secondaryControl={
                  <Button variant="primary" onClick={generateKey}>
                    Generate new key
                  </Button>
                }>
                <SpaceBetween direction="vertical" size="s">
                  <div className="hover-reveal">
                    <Input
                      value={editedEvent?.eventCode || ''}
                      disabled={!event?.canEditAttribute('secretKey', user)}
                      onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.EVENT_CODE, detail.value)}
                    />
                  </div>
                  <div>
                    {t(i18nKeys.events.eventDetails.labels.secretKey.key)}
                    <SecretKey secretKey={editedEvent?.eventCode} />
                  </div>
                </SpaceBetween>
              </FormField>
            </Grid>
          )}
          {!editMode && (
            <div className="section-first-row">
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                <div className="secondary-text">{t(i18nKeys.events.eventDetails.headers.secretKey)}</div>
                <SecretKey secretKey={event?.eventCode} />
              </Grid>
            </div>
          )}
        </ExpandableSection>
      )}
    </React.Fragment>
  );
};
export default SecretKeyDetails;
