import React, { useMemo } from 'react';
import { SpaceBetween, TilesProps } from '@amzn/awsui-components-react';

// components
import { EventDuration, EventLearningTypeTiles } from '@/src/components/ui/molecules/EventTemplate';

// constants
import { EVENT_DURATION_DICTIONARY, EVENT_LEARNING_TYPES } from '@/src/constants/event-template.constants';

// types
import {
  EventDurationItem,
  EventDurationType,
  EventLearningType,
  EventTemplateOfferMap,
} from '@/src/types/EventTemplate';

interface Props {
  stretch?: boolean;
  learningType?: EventLearningType;
  onLearningTypeChange: (learningType: EventLearningType) => void;
  offers?: EventTemplateOfferMap;
  duration?: EventDurationType;
  eventDurationError: string;
  onDurationChange: (eventDuration: EventDurationType) => void;
}

const EventConfiguration: React.FC<Props> = ({
  stretch,
  learningType = EventLearningType.TEAM,
  onLearningTypeChange,
  offers,
  duration,
  eventDurationError,
  onDurationChange,
}) => {
  const handleLearningTypeChange: TilesProps['onChange'] = (e) => {
    onLearningTypeChange(e.detail.value as EventLearningType);
  };

  const handleEventDurationChange: TilesProps['onChange'] = (e) => {
    onDurationChange(e.detail.value as EventDurationType);
  };

  const durationItems = useMemo(() => {
    if (!offers) return [];
    const items: EventDurationItem[] = [];
    offers.forEach((offer, d) => {
      if (offer.learningType === learningType) {
        items.push({
          label: EVENT_DURATION_DICTIONARY[d],
          value: d,
          data: offer,
        });
      }
    });
    items.sort((a, b) => a.data.hours - b.data.hours);
    return items;
  }, [offers, learningType]);

  return (
    <SpaceBetween size="l">
      <EventLearningTypeTiles
        stretch={stretch}
        items={EVENT_LEARNING_TYPES}
        selectedValue={learningType}
        onOptionChange={handleLearningTypeChange}
      />
      <EventDuration
        stretch={stretch}
        items={durationItems}
        error={eventDurationError}
        selectedValue={duration}
        onOptionChange={handleEventDurationChange}
      />
    </SpaceBetween>
  );
};

export default EventConfiguration;
