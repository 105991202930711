/* eslint-disable @typescript-eslint/unbound-method */
import { extend } from 'lodash';
import { ChatMessage } from './Chat';
import { JamEventTeamGoal } from './JamCommon';
// import { JamLeaderboardEntry } from './JamLeaderboard';
import { AwsAccount } from './AwsAccount';
import { anySignInDetailsExpireSoon } from '../utils/account.utils';
import { KeyValuePair } from './KeyValuePair';

export class JamSupportChat {
  id: string;
  eventName: string;
  active = true;
  createdBy: string;
  challengeId: string;
  challengeTitle: string;
  messages: ChatMessage[];
  creatorDisplayName: string;
  creatorTeamName: string;
  teamId: string;
  assignedToDisplayName: string;
  isAssignee: boolean;
  timestamp: number;

  static fromPlainObject(jsonObject: any): JamSupportChat | null {
    if (!jsonObject) {
      return null;
    }

    const supportChat = new JamSupportChat();
    extend(supportChat, jsonObject);
    supportChat.messages = (supportChat.messages || []).map(ChatMessage.fromPlainObject);

    return supportChat;
  }
}

export class JamSupportChatDetails {
  chatCreatorLogin: string;
  teamMembers: { [login: string]: string }; // map of { login: nickname }
  // leaderBoardEntry: JamLeaderboardEntry;
  // challengeSupportDetails: ChallengeSupportDetails;
  // unresolvedIssues: FacilitatorChallengeIssueResponse[];
  teamChallengeProperties: KeyValuePair[];
  // teamChallengeAnswer: JamSupportChatTeamChallengeAnswer;
  teamSignInDetails: LabSignInDetails;
  teamGoal: JamEventTeamGoal;
  teamPasswordProtected: boolean;
  adminSignInDetails: LabSignInDetails;
  numChallenges: number;
  numTeams: number;

  static fromPlainObject(obj: any): JamSupportChatDetails | null {
    if (!obj) {
      return null;
    }
    const chatDetails: JamSupportChatDetails = Object.assign(new JamSupportChatDetails(), obj) as JamSupportChatDetails;
    chatDetails.teamChallengeProperties = (chatDetails.teamChallengeProperties || []).map(KeyValuePair.fromPlainObject);
    // chatDetails.unresolvedIssues = (chatDetails.unresolvedIssues || []).map(
    //   FacilitatorChallengeIssueResponse.fromPlainObject
    // );
    chatDetails.teamMembers = Object.assign({}, chatDetails.teamMembers || {});
    // if (chatDetails.leaderBoardEntry) {
    //   chatDetails.leaderBoardEntry = JamLeaderboardEntry.fromPlainObject(chatDetails.leaderBoardEntry);
    // }
    // if (chatDetails.challengeSupportDetails) {
    //   chatDetails.challengeSupportDetails = ChallengeSupportDetails.fromPlainObject(
    //     chatDetails.challengeSupportDetails
    //   );
    // }
    // if (chatDetails.teamChallengeAnswer) {
    //   chatDetails.teamChallengeAnswer = JamSupportChatTeamChallengeAnswer.fromPlainObject(chatDetails.teamChallengeAnswer);
    // }
    if (chatDetails.teamSignInDetails) {
      chatDetails.teamSignInDetails = LabSignInDetails.fromPlainObject(chatDetails.teamSignInDetails);
    }
    if (chatDetails.adminSignInDetails) {
      chatDetails.adminSignInDetails = LabSignInDetails.fromPlainObject(chatDetails.adminSignInDetails);
    }
    return chatDetails;
  }

  /**
   * Whether any credentials on this support chat object expire soon.
   */
  public anyCredentialsExpireSoon(): boolean {
    return anySignInDetailsExpireSoon(this.teamSignInDetails, this.adminSignInDetails);
  }
}

export class LabSignInDetails {
  url: string;
  region: string;
  expiration: number;
  urlExpiration: number;
  credentials: LabAccountCredentials;

  static fromPlainObject(obj: any): LabSignInDetails {
    const details: LabSignInDetails = Object.assign(new LabSignInDetails(), obj) as LabSignInDetails;
    if (details.credentials) {
      details.credentials = LabAccountCredentials.fromPlainObject(details.credentials);
    }
    return details;
  }
}

export class LabAccountCredentials {
  accessKey: string;
  secretKey: string;
  sessionToken: string;

  static fromAccount(account: AwsAccount): LabAccountCredentials | null {
    if (account) {
      return Object.assign(new LabAccountCredentials(), {
        accessKey: account.accessKey,
        secretKey: account.secretKey,
        sessionToken: account.sessionToken,
      });
    }
    return null;
  }

  static fromPlainObject(obj: any): LabAccountCredentials {
    return Object.assign(new LabAccountCredentials(), obj) as LabAccountCredentials;
  }
}
