export class JamChallengeRecentFeedback {
  challengeRank: number;
  challengeDifficulty: number;
  notes: string;
  eventName: string;
  createdDate: string;
  updatedDate: string;
  challengeId: string;
  version: number;
  participantLogin: string;
  test: boolean;
  facilitator: boolean;
  didYouLearnSomethingNew: boolean;

  static fromPlainObject(obj: any): JamChallengeRecentFeedback {
    const data = Object.assign(new JamChallengeRecentFeedback(), obj) as JamChallengeRecentFeedback;
    return data;
  }
}
