import React, { useContext, useState } from 'react';
import { useApi } from './api.context';
import {
  DashboardJamMiniEvent,
  JamEventTypes,
  UserDashboardData,
  UserDashboardEventResponse,
} from '../types/JamDashboard';

export interface JamDashboardContextValue {
  userDashboardData?: UserDashboardData;
  getUserDashboardData: () => Promise<void>;
  getEventsByType: (type: JamEventTypes) => Promise<void>;
  isFetchingEvents: boolean;
  events: DashboardJamMiniEvent[];
}

const JamDashboardContext = React.createContext<JamDashboardContextValue>({
  userDashboardData: undefined,
  getUserDashboardData: () => new Promise(() => {
    // do nothing
  }),
  isFetchingEvents: true,
  getEventsByType: (_type: JamEventTypes) => new Promise(() => {
    // do nothing
  }),
  events: [],
});

const JamDashboardProvider: React.FC = ({ children }) => {
  const [userDashboardData, setUserDashboardData] = useState<UserDashboardData>();
  const [isFetchingEvents, setIsFetchingEvents] = useState(true);
  const [events, setEvents] = useState<DashboardJamMiniEvent[]>([]);

  const { jamDashboardApi } = useApi();

  const getUserDashboardData = async () => {
    try {
      setIsFetchingEvents(true);
      const response: UserDashboardData = await jamDashboardApi.fetchUserDashboardData();
      setUserDashboardData(response);
      setIsFetchingEvents(false);
    } catch (e: any) {
      setIsFetchingEvents(false);
      throw e;
    }
    setIsFetchingEvents(false);
  };

  const getEventsByType = async (type: JamEventTypes) => {
    try {
      setIsFetchingEvents(true);
      let response: UserDashboardEventResponse = { events: [] };
      switch (type) {
        case JamEventTypes.ActiveEvents:
          response = await jamDashboardApi.fetchActiveEvents();
          break;
        case JamEventTypes.UpcomingEvents:
          response = await jamDashboardApi.fetchUpcomingEvents();
          break;
        case JamEventTypes.PastEvents:
          response = await jamDashboardApi.fetchPastEvents();
          break;
      }
      setEvents(response.events || []);
    } catch (e: any) {
      setEvents([]);
      setIsFetchingEvents(false);
      throw e;
    }
    setIsFetchingEvents(false);
  };

  const data: JamDashboardContextValue = {
    userDashboardData,
    getUserDashboardData,
    isFetchingEvents,
    getEventsByType,
    events,
  };
  return <JamDashboardContext.Provider value={data}>{children}</JamDashboardContext.Provider>;
};

const useJamDashboard = () => {
  const context = useContext(JamDashboardContext);
  if (context === undefined) {
    throw new Error('useJamDashboardClient can only be used inside JamDashboardProvider');
  }
  return context;
};

export { JamDashboardProvider, useJamDashboard };
