import React from 'react';
import { FormField, Select, Input, InputProps, SelectProps, Link } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

import { i18nKeys } from '@/src/utils/i18n.utils';

export interface ICustomDurationProps {
  value?: {
    duration: { value: string };
    pricePerLearner: string;
    offerId: string;
  };
  durations: SelectProps.Options;
  onPricePerLearnerChange: InputProps['onChange'];
  onDurationChange: SelectProps['onChange'];
  onOfferIdChange: InputProps['onChange'];
}

// Localization keys
const { customDuration } = i18nKeys.eventTemplates;
const { eventDuration } = i18nKeys.eventTemplates.step1;

export const CustomDuration: React.FC<ICustomDurationProps> = ({
  value,
  durations,
  onPricePerLearnerChange,
  onDurationChange,
  onOfferIdChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <FormField label={t(eventDuration.duration)}>
        <Select
          options={durations}
          onChange={onDurationChange}
          selectedAriaLabel={t(eventDuration.duration)}
          selectedOption={value?.duration ?? null}
        />
      </FormField>

      <FormField
        label={customDuration.pricePerLearner}
        info={<Link ariaLabel={customDuration.informationAboutClass}>{t(i18nKeys.general.info)}</Link>}>
        <Input
          placeholder={customDuration.pricePerLearner}
          value={value?.pricePerLearner ?? ''}
          onChange={onPricePerLearnerChange}
        />
      </FormField>

      <FormField
        label={customDuration.offerId}
        info={<Link ariaLabel={customDuration.informationAboutClass}>{t(i18nKeys.general.info)}</Link>}>
        <Input placeholder={customDuration.offerId} value={value?.offerId ?? ''} onChange={onOfferIdChange} />
      </FormField>
    </>
  );
};
