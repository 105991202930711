import { i18nKeys } from '@/src/utils/i18n.utils';
import { Pagination } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface OpenEndedPaginationProps {
  currentPage: number;
  onPageChange: (page: number) => void;
  pageCounts: number;
  openEnded: boolean;
}

const OpenEndedPagination: React.FC<OpenEndedPaginationProps> = ({
  currentPage,
  onPageChange,
  pageCounts,
  openEnded,
}) => {
  const { t } = useTranslation();

  return (
    <Pagination
      currentPageIndex={currentPage}
      onChange={({ detail }) => onPageChange(detail.currentPageIndex)}
      pagesCount={pageCounts}
      openEnd={openEnded}
      ariaLabels={{
        nextPageLabel: t(i18nKeys.challenges.nextPageLabel),
        paginationLabel: t(i18nKeys.challenges.paginationLabel),
        previousPageLabel: t(i18nKeys.challenges.previousPageLabel),
        pageLabel: (pageNumber) => `page ${pageNumber}`,
      }}
    />
  );
};

export default OpenEndedPagination;
