import React from 'react';
import { Box, ColumnLayout, Container, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { JAM_EVENT_DETAILS_ROUTES } from '@/src/routes';
import Link from '@/src/components/ui/atoms/Link/Link';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';

export interface JamCommonHeaderProps {
  participants: number;
  teams: number;
  facilitators: number;
}

const JamCommonHeader: React.FC<JamCommonHeaderProps> = ({ participants, teams, facilitators }) => {
  const { t } = useTranslation();
  const { eventSlug } = useJamEventDetails();

  return (
    <Container variant="default" className="dark-bg">
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="xxxs" direction="vertical">
          <Box>{t(i18nKeys.facilitator.participants.header.teams)}</Box>
          <Box variant="h1" className="global-value">
            {teams}
          </Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs" direction="vertical">
          <Box>{t(i18nKeys.facilitator.participants.header.participants)}</Box>
          <Box variant="h1" className="global-value">
            {participants}
          </Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs" direction="vertical">
          <Box>{t(i18nKeys.facilitator.participants.header.facilitators)}</Box>
          <Box variant="h1" className="global-value">
            {facilitators}
          </Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs" direction="vertical">
          <Box>{t(i18nKeys.facilitator.participants.header.currentRank)}</Box>
          <SpaceBetween size="s" direction="horizontal">
            <Box margin={{ top: 's' }}>
              <Link href={JAM_EVENT_DETAILS_ROUTES.LeaderBoard.resolve(eventSlug)} variant="info">
                {t(i18nKeys.jamTeam.actions.leaderboard)} <Icon name="angle-right" size="inherit" />
              </Link>
            </Box>
          </SpaceBetween>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default JamCommonHeader;
