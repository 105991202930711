import { ContentLayout, Header, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import LabEvents from './LabEvents/LabEvents';
import LabShutoffs from './LabShutoffs';
import { useUser } from '@/src/store/user.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

const LabDashboard = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  return (
    <ContentLayout disableOverlap header={<Header>{t(i18nKeys.labDashboard.title)}</Header>}>
      <Tabs
        tabs={[
          { id: 'EVENTS', label: t(i18nKeys.labDashboard.events.title), content: <LabEvents /> },
          ...(user?.isSuperAdmin
            ? [{ id: 'SHUTOFFS', label: t(i18nKeys.labDashboard.shutoffs.title), content: <LabShutoffs /> }]
            : []),
        ]}
      />
    </ContentLayout>
  );
};

export default LabDashboard;
