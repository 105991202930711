import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useJamFacilitator } from '@/src/store/jam-facilitator.context';
import { i18nKeys } from '@/src/utils/i18n.utils';

import {
  Badge,
  Box,
  ColumnLayout,
  ContentLayout,
  Header,
  Icon,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import JamSpinner from '../../common/JamSpinner';
import { sum } from 'lodash';
import ExpandableDetails from '../FacilitatorParticipants/molecules/ExpendableSection';
import ParticipantContainer from '../FacilitatorParticipants/molecules/FacilitatorParticipantContainer';

const JamTeamInfo = () => {
  const { t } = useTranslation();
  const params: { 1: string } = useParams();
  const { eventName } = useJamEventDetails();
  const { loadJamTeamDetailsAsAvailability, teams, loading, setLoading, updateTeamInfoFlag } = useJamFacilitator();

  useEffect(() => {
    if (eventName) {
      void loadJamTeamDetailsAsAvailability(eventName, params[1]);
    }
  }, [eventName]);

  useEffect(() => {
    updateTeamInfoFlag(true);
    return () => updateTeamInfoFlag(false);
  }, []);

  if (loading) {
    return <JamSpinner />;
  }
  const team = teams.find(({ name }) => name === params[1]);
  if (!team) {
    return <Box color="text-status-error">{t(i18nKeys.jamChat.supportChat.errors.noTeamFound)}</Box>;
  }

  const teamRank = Array.isArray(team?.members)
    ? sum(team.members?.map((member) => member.skillRank ?? 0)) / team.members?.length
    : 0;

  return (
    <ContentLayout header={<Header variant="h1">{t(i18nKeys.jamChat.titles.teamInfo)}</Header>}>
      <ExpandableDetails
        defaultExpanded
        header={team?.displayName}
        counter={`(${teams.length ?? 0})`}
        teamInfo={team}
        teamRank={teamRank}>
        <Box margin={{ bottom: 's' }}>
          <SpaceBetween direction="horizontal" size="xs">
            {team?.goal && <Badge color="green">{team?.goal}</Badge>}
            {team?.passwordProtected && (
              <React.Fragment>
                <Icon name="key" />
                <Box variant="span" margin={{ left: 'xxs' }}>
                  {t(i18nKeys.facilitator.participants.label.passwordProtected)}
                </Box>
              </React.Fragment>
            )}
          </SpaceBetween>
        </Box>
        {!team?.members?.length && <Spinner />}
        <ColumnLayout columns={2}>
          {team?.members?.map((participants, i) => (
            <ParticipantContainer team={team} key={i} participant={participants} setLoading={setLoading} />
          ))}
        </ColumnLayout>
      </ExpandableDetails>
    </ContentLayout>
  );
};

export default JamTeamInfo;
