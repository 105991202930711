import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';

import {
  EventNameDurationReview,
  SelectChallengesReview,
  ChallengeOrderReview,
  ConfigureEventReview,
} from '@/src/components/ui/organisms/EventTemplate';

import { useCreateEventTemplate } from '@/src/store/create-event-template.context';
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';

// utils
import { getSelectedTags, getSelectedTopics } from '@/src/utils/event-template.utils';

interface Props {
  activeStepIndex: number;
  setActiveStepIndex: (x: number) => void;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

export const ReviewCreate: React.FC<Props> = ({ setActiveStepIndex }) => {
  const { selectedChallengeListItems } = useEventTemplateChallenges();
  const {
    name,
    learningType,
    duration,
    summary,
    teamSize,
    minParticipants,
    maxParticipants,
    topics,
    tags,
    imageUrl,
    rationale,
    getEventDurationInfo,
  } = useCreateEventTemplate();

  const handleEditClicked = (goToStep: number) => {
    setActiveStepIndex(goToStep);
  };

  const eventDurationInfo = getEventDurationInfo();
  return (
    <SpaceBetween size="s">
      {eventDurationInfo && learningType && duration && (
        <EventNameDurationReview
          name={name}
          learningType={learningType}
          type={duration}
          data={eventDurationInfo}
          onEditClick={handleEditClicked.bind(this, 0)}
        />
      )}
      <SelectChallengesReview challenges={selectedChallengeListItems} onEditClick={handleEditClicked.bind(this, 1)} />
      <ChallengeOrderReview challenges={selectedChallengeListItems} onEditClick={handleEditClicked.bind(this, 2)} />
      <ConfigureEventReview
        challenges={selectedChallengeListItems}
        rationale={rationale}
        eventDetails={{
          summary,
          topic: getSelectedTopics(topics),
          teamSize,
          minParticipants,
          maxParticipants,
          tags: getSelectedTags(tags),
        }}
        imageUrl={imageUrl}
        onEditClick={handleEditClicked.bind(this, 3)}
      />
    </SpaceBetween>
  );
};
