import { UserStatusType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

export type UserStatus = UserStatusType | CustomUserStatus;

export enum UserStatuses {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
  UNKNOWN = 'UNKNOWN',
  RESET_REQUIRED = 'RESET_REQUIRED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export enum CustomUserStatus {
  EXTERNAL_PROVIDER = 'EXTERNAL_PROVIDER',
}

export interface CognitoUserModel {
  userAttributes: { [key: string]: any };
  userStatus: UserStatus;
  isMFAEnabled: boolean;
  username: string;
  isEnabled: boolean;
  groups?: string[];
}
