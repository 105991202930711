import * as React from 'react';
import { ReactNode } from 'react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { Icon, Popover } from '@amzn/awsui-components-react';

interface KeyValueProps {
  label: ReactNode;
  children: ReactNode;
  className?: string;
  required?: boolean;
  info?: string;
  description?: string;
  alignWithDescriptiveKeyValues?: boolean;
  secondaryLabelColor?: boolean;
  inline?: boolean;
}

export const KeyValue: React.FC<KeyValueProps> = ({
  label,
  children,
  className,
  required,
  info,
  description,
  alignWithDescriptiveKeyValues = false,
  secondaryLabelColor = false,
  inline = false,
}) => {
  return (
    <div className={inline ? 'inline' : undefined} style={{ marginBottom: '1rem' }}>
      <div className="awsui-util-label">
        <div
          className={`${className} ${secondaryLabelColor ? 'secondary-text' : ''}`}
          style={{ marginTop: alignWithDescriptiveKeyValues ? '8px' : '0px' }}>
          {label}
          {required && <span style={{ color: awsui.colorTextStatusError }}> *</span>}
          {info && (
            <div className="ml-5" style={{ display: 'inline-block' }}>
              <Popover dismissButton={false} position="top" size="medium" triggerType="custom" content={info}>
                <Icon name="status-info" size="normal" variant="link" />
              </Popover>
            </div>
          )}
        </div>
        {description && <div className="secondary-text">{description}</div>}
      </div>
      <div style={{ marginTop: alignWithDescriptiveKeyValues ? '14px' : '0px' }}>{children}</div>
    </div>
  );
};
