/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useContext, useState } from 'react';

import { preProdLogger } from '../utils/log.utils';

import { AWSCShortbread } from '@amzn/shortbread'

export interface CookieConsentValue {
  shortbread: AWSCShortbread | undefined;
  initializeCookies: () => void;
  customizeCookies: () => void;
}

/**
 * This context is intended to store the AWS shortbread which incorporates cookie preferences
 * as well as method to customize cookies
 */
const CookieConsentContent = React.createContext<CookieConsentValue>({
  shortbread: undefined,
  initializeCookies: () => {
     // do nothing
  },
  customizeCookies: () => {
     // do nothing
  },
});

const CookieConsentProvider: React.FC = ({ children }) => {
  const [shortbread, setShortbread] = useState<AWSCShortbread | undefined>(undefined);

  const getDomain = () => {
    const hostname = window.location.hostname;
    switch (hostname) {
      case 'admin.jam.awsevents.com':
        // return the awsevents so that users only get one cookie consent popup across all AWS event websites
        return '.awsevents.com';
      case 'legacy.console.dev.jam.proserve.aws.dev':
        return '.dev.jam.proserve.aws.dev';
      default:
        // if the hostname is not hardcoded, simply return the hostname itself
        return `.${hostname}`;
    }
  };

  /**
   * Initializes the shortbread with parent element and domain name
   */
  const initializeCookies = (): void => {
    const parent = document.getElementById('shortbread-container');
    const domain = getDomain();

    preProdLogger('Initializing shortbread', parent, domain);
    setShortbread(new (AWSCShortbread as any)({ parent, domain }));
  };

  const customizeCookies = (): void => {
    if (shortbread === undefined) {
      throw new Error('Invalid state, shortbread is not yet initialized');
    }

    // opens the cookie customization page (for the domain)
    shortbread?.customizeCookies();
  };

  const data: CookieConsentValue = { shortbread, initializeCookies, customizeCookies };

  return <CookieConsentContent.Provider value={data}>{children}</CookieConsentContent.Provider>;
};

const useCookieConsent = () => {
  const context = useContext(CookieConsentContent);
  if (context === undefined) {
    throw new Error('useCookieConsent can only be used inside CookieConsentProvider');
  }
  return context;
};

export { CookieConsentProvider, useCookieConsent };
