import { Box, Container, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../store/api.context';
import { Challenge, ChallengeDeploymentStatistics } from '../../../../types/Challenge';
import { safeString } from '../../../../utils/string.utils';
import ChallengeDeploymentStatisticsDetail from './deploymentStatistics/DeploymentStatistics';
import ChallengeRecentEventsDetail from './recentEvents/RecentEvents';
import ChallengeStatisticSummarysDetail from './StatisticsSummary';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';


interface ChallengeStatsDetailsProps {
  challenge: Challenge;
}

const ChallengeStatsDetails: React.FC<ChallengeStatsDetailsProps> = ({ challenge }) => {
  const { challengesApi } = useApi();
  const { t } = useTranslation();


  const [deploymentStatistics, setDeploymentStatistics] = useState<ChallengeDeploymentStatistics>();

  useComponentDidMountEffect(async () => {
    await challengesApi.getChallengeDeploymentStatistics(safeString(challenge.challengeId), false).then((r) => {
      if (r.statistics) {
        setDeploymentStatistics(r.statistics);
      }
    });
  });

  return (
    <Box margin={'xxl'}>
    <SpaceBetween direction="vertical" size="m">
      <Tabs tabs={[{id:t(i18nKeys.challenges.challengeStats.id1),
       label:t(i18nKeys.challenges.challengeStats.label1),
       content:<Container ><ChallengeStatisticSummarysDetail challenge={challenge} challengeDeploymentStatistics={deploymentStatistics} /></Container>},
       {id:t(i18nKeys.challenges.challengeStats.id2),
        label:t(i18nKeys.challenges.challengeStats.label2),
         content:<ChallengeDeploymentStatisticsDetail challengeDeploymentStatistics={deploymentStatistics} />},
       {id:t(i18nKeys.challenges.challengeStats.id3),
        label:t(i18nKeys.challenges.challengeStats.label3),
         content:<ChallengeRecentEventsDetail challenge={challenge} />
         }]}/> 
    </SpaceBetween>
    </Box>
  );
};

export default ChallengeStatsDetails;
