/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */
let timer: any;

const cleanup = () => {
  Object.keys(localStorage).forEach((key) => {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        const parsed: any = JSON.parse(value);
        if (parsed && parsed.ttl && typeof parsed.ttl === 'number') {
          evictIfExpired(key, parsed);
        }
      } catch (e) {
        // ignore
      }
    }
  });
};

const isExpired = (ttl: number) => {
  return ttl < Date.now();
};

const evictIfExpired = (key: string, obj: { value: any; ttl: number }) => {
  if (isExpired(obj.ttl)) {
    removeItem(key);
    return null;
  }
  return obj.value;
};

const init = (frequency = 60000) => {
  timer = window.setInterval(cleanup, frequency);
  cleanup();
};

const destroy = () => {
  if (timer) {
    window.clearInterval(timer);
  }
};

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

const setItem = (key: string, value: any, ttl: number, unit: 'days' | 'hours' | 'minutes' | 'seconds' = 'days') => {
  // convert to milliseconds
  switch (unit) {
    case 'days':
      ttl *= ONE_DAY;
      break;
    case 'hours':
      ttl *= ONE_HOUR;
      break;
    case 'minutes':
      ttl *= ONE_MINUTE;
      break;
    case 'seconds':
      ttl *= ONE_SECOND;
      break;
  }

  ttl = Date.now() + ttl;

  localStorage.setItem(key, JSON.stringify({ value, ttl }));
};

const getItem = (key: string) => {
  const value = localStorage.getItem(key);
  if (value) {
    try {
      const parsed: any = JSON.parse(value);

      // if parsed object has a value and ttl, then return the value from the parsed object
      if (parsed && parsed.hasOwnProperty('value') && parsed.hasOwnProperty('ttl') && typeof parsed.ttl === 'number') {
        return evictIfExpired(key, parsed);
      }
    } catch (e) {
      // ignore
    }
  }
  return value;
};

const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

const clearStorage = () => localStorage.clear();

export default {
  destroy,
  getItem,
  init,
  removeItem,
  setItem,
  clearStorage,
};
