import { Button, Icon, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { Comment } from '../../../types/common';
import { getTimeInBrowserLocalTime } from '../../../utils/event-time.utils';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { useUser } from '../../../store/user.context';
import { ConfirmModal } from '../ConfirmModal';
import { i18nKeys } from '../../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { Markdown } from '../Markdown';
import './Comments.scss';

interface CommentProps {
  commentDetails: Comment;
  key: string;
  updateComment: (id: string, value: string) => Promise<void>;
  deleteComment: (id: string) => Promise<void>;
}

const DetailedComment: React.FC<CommentProps> = ({ commentDetails, updateComment, deleteComment }) => {
  const { user } = useUser();
  const [editing, setEditing] = useState(false);
  const [editedComment, setEditedComment] = useState<string>('');
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const { t } = useTranslation();

  const canEditComment = () => {
    return commentDetails.author === user?.email;
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditedComment('');
  };

  const saveEditedComment = () => {
    if (commentDetails.id && editedComment) {
      void updateComment(commentDetails.id, editedComment);
      cancelEdit();
    }
  };

  const handleDeleteComment = () => {
    if (commentDetails?.id) {
      void deleteComment(commentDetails.id);
      setConfirmDeleteVisible(false);
    }
  };

  useEffect(() => {
    if (commentDetails.value) {
      setEditedComment(commentDetails.value);
    }
  }, [editing]);

  return (
    <div className='challenge-comments-scs' style={{position: 'relative'}}>
      <ConfirmModal
        visible={confirmDeleteVisible}
        message={t(i18nKeys.general.areYouSure)}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesDeleteComment)}
        onConfirm={handleDeleteComment}
        onCancel={() => setConfirmDeleteVisible(false)}
      />
      <SpaceBetween direction="horizontal" size="s">
        <Icon name="user-profile" />
        <strong>{commentDetails.author}</strong>
        {canEditComment() && (
          <div style={{ float: 'right' }}>
            {editing && (
              <div>
                <SpaceBetween size="s" direction="horizontal">
                  <Button variant="link" onClick={() => cancelEdit()}>
                    {t(i18nKeys.general.cancel)}
                  </Button>
                  <Button variant="primary" onClick={saveEditedComment}>
                    {t(i18nKeys.general.save)}
                  </Button>
                </SpaceBetween>
              </div>
            )}
          </div>
        )}
        {!commentDetails.updatedAt && (
          <div style={{ color: awsui.colorTextFormSecondary }}>
            {getTimeInBrowserLocalTime(commentDetails.createdAt, {
              includeDate: true,
              includeTime: true,
              includeComma: true,
            })}
          </div>
        )}
        {commentDetails.updatedAt && (
          <div style={{ color: awsui.colorTextFormSecondary }}>
            {t(i18nKeys.events.eventDetails.labels.edited)}
            {getTimeInBrowserLocalTime(commentDetails.updatedAt, {
              includeDate: true,
              includeTime: true,
              includeComma: true,
            })}
          </div>
        )}
        
        {commentDetails?.tags && commentDetails?.tags?.length > 0 && commentDetails?.tags.map((tag, index)=> <div style={{
            backgroundColor: awsui.colorBackgroundCellShaded,
            padding: '2px'
          }} key={index}>{tag}</div>)}
          <div style={{position:'absolute', right: '20px'}}>
            <SpaceBetween direction="horizontal" size={"xxl"} alignItems="end">
            {canEditComment() && (<>{!editing && (<Button iconName="edit" variant="inline-link" onClick={() => setEditing(!editing)}>{t(i18nKeys.general.edit)}</Button>)}</>)}
              <Button iconName="remove" variant="inline-link" onClick={() => setConfirmDeleteVisible(true)}>{t(i18nKeys.general.delete)}</Button>
            </SpaceBetween>
          </div>
        </SpaceBetween>
        {!editing && <Markdown content={commentDetails.value || ''} />}
        {editing && <Textarea value={editedComment} onChange={({ detail }) => setEditedComment(detail.value)} />}
      
    </div>
  );
};

export default DetailedComment;
