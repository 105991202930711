/* eslint-disable @typescript-eslint/unbound-method */
import { TFunction } from 'i18next';
import { i18nKeys } from '../utils/i18n.utils';
import { ApiClient } from './ApiClient';
import { JamChallengesData } from '../types/JamChallenges';

export class JamChallengeAPI {
  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  /**
   * get jam challenges data
   *
   */
  public async fetchJamChallengeData(eventId: string) {
    return (await this.apiClient.get({
      path: `/event/${eventId}/details`,
      responseMapper: JamChallengesData.fromPlainObject,
      failMessage: this.t(i18nKeys.myJams.challenges.errors.jamChallengesNotFound),
    })) as Promise<JamChallengesData>;
  }
}
