import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Checkbox, ExpandableSection, PropertyFilterProps } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useEventTemplate } from '@/src/store/event-template.context';
import { useQuery } from '@/src/hooks/useQuery';
import { cloneObject } from '@/src/utils/object.utils';
import './CatalogFilter.scss';
import {
  CheckedState,
  FilterValue,
  MAX_VISIBLE_FILTER_OPTIONS,
  awsServicesKey,
  eventDurationKey,
  learningTypeKey,
  filtersPropertyKey,
  topicKey,
  FilterOption,
  tagKey,
  LearningType,
  AWSServices,
  Tag,
  Topic,
} from './catalog-filter.config';
import { EventDurationType } from '@/src/types/EventTemplate';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';

const initialCheckedFilterState = {
  [learningTypeKey]: [],
  [topicKey]: [],
  [eventDurationKey]: [],
  [awsServicesKey]: [],
  [tagKey]: [],
};

const CatalogFilter = () => {
  const { t } = useTranslation();
  const queryParams = useQuery();
  const history = useHistory();
  const { onFilterChange, loading } = useEventTemplate();
  const { offers, fetchEventTemplateOffers } = useEventTemplateOffers();
  const mounted = useRef(false);
  const [checkedFilters, setCheckedFilters] = useState<CheckedState>(
    cloneObject(initialCheckedFilterState) as CheckedState
  );
  const [showAllFilters, setShowAllFilters] = useState<number[]>([]);

  useEffect(() => {
    void fetchEventTemplateOffers();
  }, []);

  const clearAllFilter = () => {
    setCheckedFilters(cloneObject(initialCheckedFilterState) as CheckedState);
    history.push({ pathname: history.location.pathname });
  };

  const onFilterUpdate = (key: string, option: FilterValue) => {
    if (loading) return; // don't update filter if loading
    const searchParams = new URLSearchParams();
    Object.keys(checkedFilters).map((filterKey) => {
      if (filterKey === key) {
        checkedFilters[filterKey] = checkedFilters[key].includes(option)
          ? checkedFilters[key].filter((prevOption) => prevOption !== option)
          : [...checkedFilters[key], option];
      }
      if (checkedFilters[filterKey].length) {
        checkedFilters[filterKey].forEach((filterOption) =>
          searchParams.append(filtersPropertyKey[filterKey], `${filterOption}`)
        );
      }
    });

    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString() ? '?' + searchParams.toString() : '',
    });
  };

  const durationLabels = useMemo(() => {
    if (!offers) return [];
    return [
      {
        label: t(i18nKeys.general.countHours, { count: offers.get(EventDurationType.SHORT)?.hours }),
        value: EventDurationType.SHORT,
      },
      {
        label: t(i18nKeys.general.countHours, { count: offers.get(EventDurationType.MEDIUM)?.hours }),
        value: EventDurationType.MEDIUM,
      },
      {
        label: t(i18nKeys.general.countHours, { count: offers.get(EventDurationType.LONG)?.hours }),
        value: EventDurationType.LONG,
      },
    ];
  }, [t, offers]);

  const allFilters = useMemo(() => {
    return {
      [learningTypeKey]: LearningType,
      [topicKey]: Topic,
      [eventDurationKey]: durationLabels,
      [awsServicesKey]: AWSServices,
      [tagKey]: Tag,
    };
  }, [durationLabels]);

  useEffect(() => {
    const filtersFromUrl = cloneObject(initialCheckedFilterState) as CheckedState;
    Object.keys(filtersPropertyKey).forEach((key) => {
      const filterFromUrl = queryParams.getAll(filtersPropertyKey[key]);
      if (filterFromUrl.length) {
        filterFromUrl.forEach((filterValue) => {
          const option = (allFilters[key] as FilterOption[]).find((filterOption) => filterOption.value === filterValue);
          if (option) {
            filtersFromUrl[key].push(option.value);
          }
        });
      }
    });

    if (mounted.current) {
      setCheckedFilters(filtersFromUrl);
    } else {
      // TODO: once all the CR merges, we should redo the event-template.context and expose fetchEventTemplate method
      // rather calling it by default on useEffect and it will solve below timeout hack
      setTimeout(setCheckedFilters, 1000, filtersFromUrl);
      mounted.current = true;
    }
  }, [queryParams, allFilters]);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }
    const propertyFilter: PropertyFilterProps.Token[] = [];
    Object.keys(checkedFilters).map((key) => {
      if (checkedFilters[key].length) {
        propertyFilter.push({
          propertyKey: filtersPropertyKey[key],
          operator: '=',
          value: checkedFilters[key],
        });
      }
    });
    onFilterChange({
      operation: 'and',
      tokens: propertyFilter,
    });
  }, [checkedFilters]);

  const renderClearAllButton = () => {
    const checked = Object.values(checkedFilters).some((value) => value.length);
    if (!checked) {
      return null;
    }
    return (
      <div className="filter__clearall-button">
        <Button variant="link" onClick={clearAllFilter}>
          Clear all filters
        </Button>
      </div>
    );
  };

  return (
    <div className="filters">
      <div className="filters-title">
        <Box variant="h2" padding={'m'}>
          {t(i18nKeys.catalog.catalogFilter.refineResults)}
        </Box>
      </div>
      <div className="divider" />
      <div className="filters-container">
        {renderClearAllButton()}
        {Object.keys(allFilters).map((key, index) => {
          if (!allFilters[key] || allFilters[key].length === 0) return;
          const options = allFilters[key].slice(
            0,
            showAllFilters.includes(index) ? undefined : MAX_VISIBLE_FILTER_OPTIONS
          );
          return (
            <div key={key}>
              {index !== 0 && <div className="divider" />}
              <ExpandableSection headerText={t(key)} variant="footer" defaultExpanded>
                <div className="filter__options">
                  {options.map((option, i) => (
                    // eslint-disable-next-line react/jsx-key
                    <div className="checkbox" key={`checkbox-${i}`}>
                      <Checkbox
                        id={String(option.value).toLocaleLowerCase()}
                        name={`${option.value}`}
                        key={option.value}
                        onChange={() => onFilterUpdate(key, option.value)}
                        checked={checkedFilters[key].some((checkedFilter) => checkedFilter === option.value)}>
                        {option.label}
                      </Checkbox>
                    </div>
                  ))}
                  {allFilters[key].length > MAX_VISIBLE_FILTER_OPTIONS && !showAllFilters.includes(index) && (
                    <div className="filter__viewall-button">
                      <Button onClick={() => setShowAllFilters((prev) => [...prev, index])} variant="link">
                        {t(i18nKeys.general.viewAll)}
                      </Button>
                    </div>
                  )}
                </div>
              </ExpandableSection>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CatalogFilter;
