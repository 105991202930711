export const MILLIS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * SECONDS_PER_MINUTE;

export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
export const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * MINUTES_PER_HOUR;

export const HOURS_PER_DAY = 24;
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * HOURS_PER_DAY;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * HOURS_PER_DAY;
export const MILLIS_PER_DAY = MILLIS_PER_HOUR * HOURS_PER_DAY;

export const DAYS_PER_WEEK = 7;
export const DAYS_PER_MONTH = 30.4167;
export const DAYS_PER_NON_LEAP_YEAR = 365;
export const DAYS_PER_LEAP_YEAR = DAYS_PER_NON_LEAP_YEAR + 1;
export const HOURS_PER_WEEK = HOURS_PER_DAY * DAYS_PER_WEEK;
export const HOURS_PER_MONTH = DAYS_PER_MONTH * HOURS_PER_DAY;
export const MINUTES_PER_WEEK = MINUTES_PER_DAY * DAYS_PER_WEEK;
export const SECONDS_PER_WEEK = SECONDS_PER_DAY * DAYS_PER_WEEK;
export const MILLIS_PER_WEEK = MILLIS_PER_DAY * DAYS_PER_WEEK;
export const MILLIS_PER_FIVE_MINUTES = MILLIS_PER_MINUTE * 5;
// number of days in 10 years
export const TEN_YEARS_DAYS = 3653;

export const MeridiemOptions = {
  AM: { label: 'AM', value: 'AM' },
  PM: { label: 'PM', value: 'PM' },
};
