
import React from 'react';
import { Box, Container, Grid, GridProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import "./RealReviews.scss"

const RealReviews = () => {
  const { t } = useTranslation();
  //   const [mockArray] = useState(CUSTOMER_REVIEWS);
  const colspan: GridProps.BreakpointMapping = { xl: 6, l: 6, m: 6, s: 6, xs: 12, xxs: 12 };
  return (
    <div className="real-reviews">
      <Box padding={{ top: 'xxxl', bottom: 'xxl' }} variant="h1" textAlign="center">
        {t(i18nKeys.catalog.reviewFromCustomers)}
      </Box>
      <Grid gridDefinition={[{ colspan }, { colspan }]}>
        <div className="reviewCard">
          <Container header={<p>{t(i18nKeys.catalog.realReviewsList.review1.review)}</p>}>
            <b>{t(i18nKeys.catalog.realReviewsList.review1.author)}</b>
            <br />
            <i>{t(i18nKeys.catalog.realReviewsList.review1.title)}</i>
            <br />
            {t(i18nKeys.catalog.realReviewsList.review1.company)}
          </Container>
        </div>
        <div className="reviewCard">
          <Container header={<p>{t(i18nKeys.catalog.realReviewsList.review2.review)}</p>}>
            <b>{t(i18nKeys.catalog.realReviewsList.review2.author)}</b>
            <br />
            <i>{t(i18nKeys.catalog.realReviewsList.review2.title)}</i>
            <br />
            {t(i18nKeys.catalog.realReviewsList.review2.company)}
          </Container>
        </div>
      </Grid>
      {/* <CustomSlider
        heading=""
        items={mockArray}
        headingVariation="h1"
        reviewCard
        slidesToShow={2}
        responsive={[
          {
            breakpoint: 600,
            settings: { slidesToShow: 1 },
          },
        ]}
      /> */}
    </div>
  );
};

export default RealReviews;
