import React from 'react';
import { Box, Button, Modal } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

export interface ISuccessModal {
  name: string;
  visible: boolean;
  status: string;
  onDismiss: () => void;
}

const { title, message, close } = i18nKeys.eventTemplates.step5.successModal;

export const SuccessModal: React.FC<ISuccessModal> = ({ name, visible, status, onDismiss }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onDismiss={onDismiss}
      closeAriaLabel={t(i18nKeys.general.close)}
      visible={visible}
      footer={
        <Box float="right">
          <div id="close-button" data-testid="close-button" onClick={onDismiss}>
            <Button variant="primary">{t(close)}</Button>
          </div>
        </Box>
      }
      header={t(title)}>
      {t(message, { name, status })}
    </Modal>
  );
};
