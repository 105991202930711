import React, { useEffect, useState } from 'react';
import { Box, Table } from '@amzn/awsui-components-react';
import './JamReport.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useJamReport } from '@/src/store/jam-report.context';
import { TeamMetricsRow } from '@/src/types/JamReport';
import JamContainer from '@/src/components/ui/molecules/JamContainer/JamContainer';
import { getDuration } from '@/src/utils/time.utils';

const TeamMetrics = () => {
  const { reportApiData } = useJamReport();
  const [tableData, setTableData] = useState<TeamMetricsRow[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    if (reportApiData) {
      const { teamMetrics, challengeTitles } = reportApiData;
      const tempData = teamMetrics;
      if (teamMetrics && teamMetrics?.length > 0) {
        tempData?.forEach((obj) => {
          const newData = obj.notStartedChallenges.map((key) => challengeTitles[key]);
          obj.newNotStartedChallenges = newData;
        });
        setTableData(tempData);
      }
    }
  }, [reportApiData]);

  return (
    <JamContainer>
      <Box>
        <h2>{t(i18nKeys.eventReport.teamMetrics.heading)}</h2>
      </Box>
      <Table
        contentDensity="compact"
        columnDefinitions={[
          {
            header: t(i18nKeys.eventReport.teamMetrics.teamName),
            cell: (item) => item?.teamName || '',
            minWidth: 200,
          },
          {
            header: t(i18nKeys.eventReport.teamMetrics.finalScore),
            cell: (item) => item?.finalScore || 0,
            minWidth: 100,
          },
          {
            header: t(i18nKeys.eventReport.teamMetrics.finalRank),
            cell: (item) => item?.finalRank || '-',
            minWidth: 100,
          },
          {
            header: t(i18nKeys.eventReport.teamMetrics.challengeStarted),
            cell: (item) => item?.startedChallenges?.length || 0,
            minWidth: 100,
          },
          {
            header: t(i18nKeys.eventReport.teamMetrics.challengeSolved),
            cell: (item) => item?.numberChallengesSolved || 0,
            minWidth: 100,
          },
          {
            header: (
              <>
                {t(i18nKeys.eventReport.teamMetrics.challenge)}
                <br />
                {t(i18nKeys.eventReport.teamMetrics.notStarted)}
              </>
            ),
            cell: (item) =>
              (item?.newNotStartedChallenges?.length > 0 && item?.newNotStartedChallenges?.join(', ')) || '-',
            minWidth: 130,
          },
          {
            header: t(i18nKeys.eventReport.teamMetrics.totalTime),
            cell: (item) => <div>{item.timeFromFirstStartedChallengeToLastCompletionTime
              ? getDuration(item.timeFromFirstStartedChallengeToLastCompletionTime) ?? '--'
              : '--'}</div>,
            minWidth: 130,
          },
        ]}
        items={tableData || []}
        loadingText={t(i18nKeys.eventReport.loadigResource)}
        sortingDisabled
        variant="embedded"
      />
    </JamContainer>
  );
};

export default TeamMetrics;
