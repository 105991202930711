import { SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import JamCodeEditor from '../../challenges/challengesCommon/JamCodeEditor';
import { CopyToClipboard } from '../CopyToClipboard';

interface CfnReadOnlyProps {
  readonly cfn: string;
}

const CfnReadOnly: React.FC<CfnReadOnlyProps> = ({ cfn }) => {
  return (
    <SpaceBetween size="s">
      <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
        <JamCodeEditor language={'yaml'} value={cfn} setValue={() => {
           // do nothing
        }} />
      </div>
      <CopyToClipboard value={cfn} />
    </SpaceBetween>
  );
};

export default CfnReadOnly;
