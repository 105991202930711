import React, { useMemo } from 'react';

import { Box, Button, Header, Icon, Link, Popover, SpaceBetween, Toggle } from '@amzn/awsui-components-react';

import { EventTemplateChallengeTabId, useCreateEventTemplate } from '@/src/store/create-event-template.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useEventTemplateChallenges } from '@/src/store/event-template-challenges.context';
import { BACKUP_CHALLENGE_LIMIT } from '@/src/constants/event-template.constants';

import './SelectChallengesTableHeader.scss';
import { useChallenges } from '@/src/store/challenge.context';

interface IProps {
  activeStepIndex: number;
  setActiveStepIndex: (x: number) => void;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

export const SelectChallengesTableHeader = ({ activeStepIndex, setActiveStepIndex }: IProps) => {
  const {
    challengeSets,
    activeChallengeTabId,
    challengeToggleEnable,
    isChallengeToggleEnable,
    challengeSetToggleEnable,
    isChallengeSetToggleEnable,
    propertyFilterRef,
    paginationRef,
    collectionPrefRef,
  } = useEventTemplateChallenges();
  const { selectedChallenges, getEventDurationInfo } = useCreateEventTemplate();
  const { challengesCount } = useChallenges();

  const { t } = useTranslation();

  const getSelectedEventChallengeOrSetCount = () => {
    return activeChallengeTabId === EventTemplateChallengeTabId.CHALLENGE
      ? selectedChallenges.length > 0
        ? `(${selectedChallenges.length}/${challengesCount})`
        : `(${challengesCount})`
      : `(${challengeSets.length})`;
  };

  const addChallengesConfig = useMemo<{ disabled: boolean; challengesNeeded?: number }>(() => {
    const eventDurationInfo = getEventDurationInfo();
    if (!eventDurationInfo) throw new Error('Event Duration info not found');
    const minChallengesNeeded = eventDurationInfo.minChallenges + BACKUP_CHALLENGE_LIMIT;
    if (selectedChallenges.length < minChallengesNeeded)
      return { disabled: true, challengesNeeded: minChallengesNeeded - selectedChallenges.length };
    return { disabled: false };
  }, [getEventDurationInfo, selectedChallenges]);

  return (
    <div style={{ width: '100%' }}>
      <div className="table-header" style={{ width: '100%' }}>
        <Header
          counter={getSelectedEventChallengeOrSetCount()}
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="m">
              <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                {activeChallengeTabId === EventTemplateChallengeTabId.CHALLENGE ? (
                  <Toggle
                    checked={challengeToggleEnable}
                    onChange={({ detail }) => isChallengeToggleEnable(detail.checked)}>
                    {t(i18nKeys.eventTemplates.table.header.actionToggleChallenges)}
                  </Toggle>
                ) : (
                  <Toggle
                    checked={challengeSetToggleEnable}
                    onChange={({ detail }) => isChallengeSetToggleEnable(detail.checked)}>
                    {t(i18nKeys.eventTemplates.table.header.actionToggleChallengeSet)}
                  </Toggle>
                )}
                <div style={{ marginLeft: '0.3rem' }}>
                  <Popover content={t(i18nKeys.eventTemplates.table.header.popoverContent)} triggerType="custom">
                    <Link ariaLabel={t(i18nKeys.general.info)}>
                      <Icon name="status-info" alt={t(i18nKeys.general.info)} />
                    </Link>
                  </Popover>
                </div>
              </div>
              <Button
                variant="primary"
                disabled={addChallengesConfig.disabled}
                onClick={() => setActiveStepIndex(activeStepIndex + 1)}>
                {addChallengesConfig.disabled
                  ? t(i18nKeys.eventTemplates.table.header.actionMoreButton, {
                      challenges: addChallengesConfig.challengesNeeded,
                    })
                  : t(i18nKeys.eventTemplates.table.header.actionSelectedButton)}
              </Button>
            </SpaceBetween>
          }>
          {activeChallengeTabId === EventTemplateChallengeTabId.CHALLENGE
            ? t(i18nKeys.eventTemplates.tabs.title.challenges)
            : t(i18nKeys.eventTemplates.tabs.title.challengeSets)}
        </Header>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <Box margin={{ top: 'l' }}>
            <div ref={propertyFilterRef} />
          </Box>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div ref={paginationRef} />
          <div ref={collectionPrefRef} />
        </div>
      </div>
    </div>
  );
};
