import React, { Suspense } from 'react';
import { StatusIndicator } from './StatusIndicator';

export const LoadingBar: React.FC = () => (
  <h3 style={{ textAlign: 'center' }}>
    <Suspense fallback={<span>...</span>}>
      <StatusIndicator status={'loading'} />
    </Suspense>
  </h3>
);
