import React, { useEffect, useState } from 'react';
import { ContentLayout, SpaceBetween, Header, Container, Grid } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import './index.scss';
import QRCode from 'react-qr-code';
import { useApi } from '@/src/store/api.context';
import JamContainer from '@/src/components/ui/molecules/JamContainer/JamContainer';
import JamSpinner from "@/src/components/common/JamSpinner";

interface participantObj {
  prizeCode: string;
}

interface prizesInterface {
  eventName: string;
  updatedDate: string;
  awardedDate?: string;
  description?: string;
}

interface participantInterface {
  participant: participantObj;
  prizes: prizesInterface[];
  pastEvents?: { name: string }[];
  events?: object;
}

const PrizesComponent: React.FC = () => {
  const { t } = useTranslation();
  const { jamProfileAPI } = useApi();
  const [prizeC, setPrizeC] = useState<string>('');
  const [prizesState, setPrizesState] = useState<prizesInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setAllEvents] = useState<participantInterface>();

  const getPrize = async () => {
    setLoading(true);
    const res = await jamProfileAPI.getPricipantDashboard();
    const { participant, prizes }: participantInterface = res;
    const { prizeCode }: participantObj = participant;
    setAllEvents(res);
    setPrizeC(prizeCode);
    // eslint-disable-next-line no-console
    console.log(prizes, 'prizes');
    setPrizesState(prizes);
    setLoading(false);
  };

  useEffect(() => {
    void getPrize();
  }, []);

  if (loading) {
    return <JamSpinner />;
  }

  return (
    <ContentLayout
      header={
        <SpaceBetween size="s">
          <Header description={t(i18nKeys.JamProfile.prizes.desc)} variant="h1">
            {t(i18nKeys.JamProfile.prizes.title)}
          </Header>
        </SpaceBetween>
      }>
      <Grid gridDefinition={[{ colspan: 12 }]}>
        <Container
          header={
            <Header description={t(i18nKeys.JamProfile.prizes.page.desc)} variant="h3">
              {t(i18nKeys.JamProfile.prizes.page.personalQrCode)}
            </Header>
          }>
          <div className="qr-div">
            <QRCode value={prizeC} size={195} />
          </div>
        </Container>
      </Grid>
      {prizesState && prizesState?.length !== 0 && <h2>{t(i18nKeys.JamProfile.prizes.page.availablePrizes)}</h2>}
      {prizesState &&
        prizesState?.map((item, index): JSX.Element => {
          return (
            <Grid gridDefinition={[{ colspan: 5 }, { colspan: 5 }]} key={index}>
              <JamContainer>
                <h2>
                  {t(i18nKeys.JamProfile.prizes.page.prize)} {index}
                </h2>
                <p>
                  <strong>{t(i18nKeys.JamProfile.prizes.page.dateWon)}:</strong> {item.updatedDate}
                </p>
                <p>
                  <strong>{t(i18nKeys.JamProfile.prizes.page.event)}Event:</strong> {item.eventName}
                </p>
                <p>
                  <strong>{t(i18nKeys.JamProfile.prizes.page.solvedChallenge)}:</strong> {item.description}
                </p>
              </JamContainer>
            </Grid>
          );
        })}
    </ContentLayout>
  );
};

export default PrizesComponent;
