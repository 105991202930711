import { ErrorSetterByField, InputValidationHelper } from '../types/InputValidator';
import { JoinSpecificTeamFields } from '../types/JoinSpecificTeam';
import { validateField, validateIsEmpty, validateSection } from './validation.utils';
import { isEmpty } from 'lodash';

interface TeamValidatorParams {
  teamName: string;
  password: string;
  passwordRequired?: boolean;
}

export const validateIsRequiredEmpty = (value: string | undefined, required: boolean, errorText: string) => {
  const isValid = !required || !isEmpty(value);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const joinSpecificTeamValidator: (
  fields: TeamValidatorParams,
  errors: TeamValidatorParams,
  errorSetterByField: ErrorSetterByField<JoinSpecificTeamFields>
) => {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: JoinSpecificTeamFields, setError?: boolean) => boolean;
} = (fields, errors, errorSetterByField: Map<JoinSpecificTeamFields, (error: string) => void>) => {
  const validatorHelperByField = new Map<JoinSpecificTeamFields, InputValidationHelper>([
    [JoinSpecificTeamFields.TEAM_NAME, validateIsEmpty(fields.teamName, errors.teamName)],
    [JoinSpecificTeamFields.PASSWORD, validateIsRequiredEmpty(fields.password, (fields.passwordRequired === undefined) ? true : fields.passwordRequired, errors.password)],
  ]);

  const isValidField = (field: JoinSpecificTeamFields, setError = true) => {
    return validateField<JoinSpecificTeamFields>(validatorHelperByField, errorSetterByField, field, setError);
  };

  const isValidSection = (setErrors = false): boolean => {
    return validateSection<JoinSpecificTeamFields>(validatorHelperByField, errorSetterByField, setErrors);
  };

  return {
    isValidSection,
    isValidField,
  };
};
