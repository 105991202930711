/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { ChallengeSet } from '../types/ChallengeSet';
import { Nullable } from '../types/common';
import _, { isArray, uniq } from 'lodash';
import {
  BackupChallengeConfig,
  Event,
  EventBase,
  EventConfiguration,
  EventPermission,
  EventPermissionType,
  JamEventRequest,
  TeamChallengeProperties,
} from '../types/Event';
import { OnboardingVideoDetails } from '../types/OnboardingVideo';
import { parseQueryParam, parseQueryParamList } from '../utils/route.utils';
import { SkillBuilderSubscription } from '../types/SkillBuilderSubscription';
import { isEmailValid, isNotEmpty } from '../utils/string.utils';
import { isValidDateString } from '../utils/time.utils';
import { isEmpty, safeArray, safeFilterNulls } from '../utils/list.utils';
import { useChallenges } from './challenge.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../utils/i18n.utils';
import { ChallengeDescriptor } from '../types/Challenge';
import { User } from '../types/User';
import { useApi } from './api.context';
import { useEvents } from './events.context';
import { fromPlainObject } from '../utils/mapper.utils';
import { UserRow } from '../types/UserRow';
import { faker } from '@faker-js/faker';
import { TeamMember } from '../types/Team';
import { useFlashbars } from '@/src/store/flashbar.context';
import { useUser } from './user.context';
import { SAVE_ACTIONS } from '../components/events/EventModel';
import { useEventTemplateOffers } from './event-template-offers.context';
import { EventDurationInfo, IEventTemplate } from '../types/EventTemplate';

export type SetNewEventImportSources = Dispatch<SetStateAction<NewEventImportSources>>;
export type SetNewDuration = Dispatch<SetStateAction<string | null>>;
export type ToggleEditMode = () => void;
export type ToggleNewEvent = () => void;
export type InitializeEditEvent = (_event: Event) => void;
export type InitializeNewEvent = () => void;
export type UploadTeamProperties = (event: Event, teamProperties: TeamChallengeProperties[]) => Promise<void>;
export type HandleUpdateEditEvent = (_action: string, _payload: any) => void;
export type BulkHandleUpdateEvent = (edits: EventEdit[]) => void;
export type DestroyEdits = () => void;
export type PopulateWithUrlParams = (params: URLSearchParams) => string[];
export type HasChanges = (user: User, eventConfig: EventConfiguration, event: Event) => boolean;
export type SaveUpdatedEvent = (saveType: SAVE_ACTIONS) => Promise<void>;
export type SetEditMode = (flag: boolean) => void;

// eslint-disable-next-line no-shadow
export enum EditEventActions {
  ADD_TAG = 'add-tag',
  REMOVE_TAG = 'remove-tag',
  AUDIENCE = 'audience',
  TITLE = 'title',
  SKILLBUILDER_SUBSCRIPTION = 'skill-builder-subscription',
  AGREEMENT_ID = 'agreement-id',
  TIMEZONE = 'timezone',
  END_DATE = 'end-date',
  START_AND_END_DATETIMES = 'start-date',
  AUTO_UNLOCK_CHALLENGES_MINUTES = 'auto-unlock-challenges-minutes',
  MIN_EXPECTED_PARTICIPANTS = 'min-expected-participants',
  MAX_EXPECTED_PARTICIPANTS = 'max-expected-participants',
  MAX_TEAM_SIZE = 'max-team-size',
  TYPE = 'type',
  SUPPORT_AND_COLLABORATION = 'support-and-collaboration',
  TEAM_RENAME = 'team-rename',
  TEAM_TYPE = 'team-type',
  TEAM_FORM_MINUTES_BEFORE_START = 'team-form-minutes-before-start',
  EVENT_PERMISSIONS = 'event-permissions',
  SCORING_SETTINGS = 'scoring-settings',
  CHIME_WEBHOOK_URL = 'chime-webhook-url',
  SPONSORED = 'sponsored',
  EVENT_FULL_MESSAGE = 'event-full-message',
  GAMIFICATION = 'gamification',
  CLOSED_FOR_NEW_REGISTRATIONS = 'closed-for-new-registrations',
  CHALLENGE_VIEW = 'challenge-view',
  UI_THEME = 'ui-theme',
  PRIZE_COUNT = 'prize-count',
  MAX_CLUES = 'max-clues',
  TEST = 'test',
  TEST_EVENT_PUBLIC = 'test-event-public',
  PUBLIC_DETAILS_VISIBLE = 'public-details-visible',
  PUBLIC_TITLE = 'public-title',
  PUBLIC_DESCRIPTION = 'public-description',
  PUBLIC_LOCATION = 'public-location',
  PUBLIC_MORE_INFO_URL = 'public-more-info-url',
  PUBLIC_IMAGE_FILE_NAME = 'public-image-file-name',
  LOBBY_VIDEO_SHOW = 'lobby-video-show',
  LOBBY_VIDEO_URL = 'lobby-video-url',
  LOBBY_VIDEO_TITLE = 'lobby-video-title',
  LOBBY_VIDEO_DESCRIPTION = 'lobby-video-description',
  DEFAULT_LOBBY_VIDEO = 'default-lobby-video',
  PARTICIPANT_DOMAIN_ALLOWLIST = 'participant-domain-allowlist',
  FACILITATOR_DOMAIN_ALLOWLIST = 'facilitator-domain-allowlist',
  LAB_AUTO_SCALE_MIN_PERCENTAGE = 'lab-auto-scale-min-percentage',
  WARMUP_OFFSET_HOURS = 'warmup-offset-hours',
  LAB_EXTENSION_HOURS = 'lab-extension-hours',
  REGION_ALLOWLIST = 'region-allowlist',
  REGION_DENYLIST = 'region-denylist',
  USAGE_PLAN = 'usage-plan',
  CHALLENGE_DESCRIPTORS = 'challenge-descriptors',
  CHALLENGE_BOARDS = 'challenge-boards',
  GENERAL_BACKUP_CHALLENGES = 'general-backup-challenges',
  PER_CHALLENGE_BACKUP_CHALLENGES = 'per-challenge-backup-challenges',
  SLUG = 'SLUG',
  NOTES = 'NOTES',
  CODE_WHISPERER_DISABLED = 'amazon-Q-disabled',
  ADD_PARTICIPANTS = 'add-participants',
  REMOVE_PARTICIPANTS = 'remove-participants',
  CHANGE_CATALOG = 'change-catalog',
  EVENT_PRIVACY_TYPE = 'event-privacy-type',
  EVENT_CODE = 'event-code',
}

// eslint-disable-next-line no-shadow
export enum EventURLParameter {
  TITLE = 'title',
  ID = 'id',
  NOTES = 'notes',
  OWNERS = 'owners',
  FACILITATORS = 'facilitators',
  TAGS = 'tags',
  CHALLENGES = 'challenges',
  CHALLENGE_SETS = 'challengeSets',
  AGREEMENT_ID = 'agreementId',
  SKILL_BUILDER_SUBSCRIPTION = 'skillBuilderSubscription',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  AUDIENCE_TYPE = 'audienceType',
  EVENT = 'event',
  EVENT_PRIVACY_TYPE = 'eventPrivacyType',
  AUTO_UNLOCK_CHALLENGES_OFFSET_MINUTES = 'autoUnlockChallengesOffsetMinutes',
}

export const EVENT_TITLE_MIN_LENGTH = 4;
export const EVENT_TITLE_MAX_LENGTH = 250;

export interface EventEdit {
  action: string;
  payload: any;
}
export interface NewEventImportSources {
  challengeSet: Nullable<ChallengeSet>;
  event: Nullable<Event>;
}

export interface EditEventContextValue {
  editedEvent: Event | null;
  newEditedEvent: Event | null;
  editMode: boolean;
  newEventMode: boolean;
  isGuestUserAndEditModeEnabled: boolean | undefined;
  newEventImportSources: NewEventImportSources;
  newDuration: string | null;
  setNewEventImportSources: SetNewEventImportSources;
  selectedSpecificDuration: string | null;
  setSelectedSpecificDuration: Dispatch<SetStateAction<string | null>>;
  setNewDuration: SetNewDuration;
  toggleEditMode: ToggleEditMode;
  toggleNewEvent: ToggleNewEvent;
  initializeEditEvent: InitializeEditEvent;
  initializeNewEvent: InitializeNewEvent;
  handleUpdateEditEvent: HandleUpdateEditEvent;
  bulkHandleUpdateEditEvent: BulkHandleUpdateEvent;
  destroyEdits: DestroyEdits;
  populateWithUrlParams: PopulateWithUrlParams;
  hasChanges: HasChanges;
  saveUpdatedEvent: SaveUpdatedEvent;
  uploadTeamProperties: UploadTeamProperties;
  setEditMode: SetEditMode;
  getEventDurationInfo: (eventTemplate: IEventTemplate) => EventDurationInfo | undefined;
}

export const EditEventContext = React.createContext<EditEventContextValue>({
  editedEvent: null,
  newEditedEvent: null,
  editMode: false,
  newEventMode: false,
  isGuestUserAndEditModeEnabled: false,
  newEventImportSources: { challengeSet: null, event: null },
  newDuration: null,
  setNewDuration: () => {
    // do nothing
  },
  selectedSpecificDuration: null,
  setSelectedSpecificDuration: () => {
    // do nothing
  },
  setNewEventImportSources: () => {
    // do nothing
  },
  toggleEditMode: () => {
    // do nothing
  },
  toggleNewEvent: () => {
    // do nothing
  },
  initializeEditEvent: () => {
    // do nothing
  },
  initializeNewEvent: () => {
    // do nothing
  },
  handleUpdateEditEvent: () => {
    // do nothing
  },
  bulkHandleUpdateEditEvent: (_edits: EventEdit[]) => {
    // do nothing
  },
  destroyEdits: () => {
    // do nothing
  },
  populateWithUrlParams: () => [],
  hasChanges: (_user: User, _eventConfig: EventConfiguration, _event: Event) => false,
  saveUpdatedEvent: (_saveType: SAVE_ACTIONS) =>
    new Promise(() => {
      // do nothing
    }),
  uploadTeamProperties: (_event: Event, _teamChallengeProperties: TeamChallengeProperties[]) =>
    new Promise(() => {
      // do nothing
    }),
  setEditMode: (_flag: boolean) => {
    // do nothing
  },
  getEventDurationInfo: (_eventTemplate: IEventTemplate) => undefined,
});

const EditEventProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [editedEvent, setEditedEvent] = useState<Event | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [newEditedEvent, setNewEditedEvent] = useState<Event | null>(null);
  const [newEventMode, setNewEventMode] = useState(false);
  const [newDuration, setNewDuration] = useState<string | null>(null);
  const [selectedSpecificDuration, setSelectedSpecificDuration] = useState<string | null>(null);
  const [newEventImportSources, setNewEventImportSources] = useState<NewEventImportSources>({
    challengeSet: null,
    event: null,
  });
  const { clearFlashbars } = useFlashbars();
  const { getChallengeDescriptors, getChallengeDescriptorsFromSet } = useChallenges();
  const { eventsApi } = useApi();
  const { getEventByName, event } = useEvents();
  const { offers } = useEventTemplateOffers();
  const { user: loggedInUser } = useUser();

  const getEventDurationInfo = useCallback(
    (eventTemplate: IEventTemplate) => {
      if (!offers || !eventTemplate?.duration) return;
      return offers.get(eventTemplate.duration);
    },
    [offers]
  );

  const toggleEditMode = () => {
    if (editMode) {
      setEditedEvent(null);
    }
    if (newEventMode) {
      setNewEventMode(false);
    }
    setEditMode(!editMode);
  };

  const destroyEdits = () => {
    setNewEditedEvent(null);
    setEditedEvent(null);
    setNewEventMode(false);
    setEditMode(false);
    setNewEventImportSources({ challengeSet: null, event: null });
    setNewDuration(null);
  };

  const toggleNewEvent = () => {
    if (newEventMode) {
      setNewEditedEvent(null);
    } else {
      initializeNewEvent();
      if (editMode) {
        setEditMode(false);
      }
    }
    setNewEventMode(!newEventMode);
  };

  const initializeNewEvent = () => {
    const newEvent = new Event();
    // By default, unlock challenges at event start time
    newEvent.autoUnlockChallengesOffsetMinutes = 0;
    setNewEditedEvent(newEvent);
  };

  const initializeEditEvent = (oldEvent: Event) => {
    setEditedEvent(_.cloneDeep(oldEvent));
  };

  const saveUpdatedEvent = async (saveType: SAVE_ACTIONS) => {
    if (editedEvent) {
      if (event && !_.isEqual(event?.tags, editedEvent.tags)) {
        await eventsApi.updateTags(editedEvent);
      }

      const jamEventRequest = fromPlainObject(editedEvent, JamEventRequest) as JamEventRequest;

      switch (saveType) {
        case SAVE_ACTIONS.SUBMIT_CHANGE_REQUEST: {
          const updatedEvent = event?.catalogId
            ? event?.orderId
              ? // we can update only limited number of fields on ECT events with order ID
                await eventsApi.createJamLimitedEctEventChangeRequest(editedEvent.name, {
                  catalogId: jamEventRequest.catalogId,
                  title: jamEventRequest.title,
                  eventPermissions: jamEventRequest.eventPermissions,
                })
              : // allow updating all the fields via /update-ect-event since this event was free
                await eventsApi.createJamEctEventChangeRequest(editedEvent.name, jamEventRequest)
            : // use change-request/new API for non-ECT events
              await eventsApi.createJamEventChangeRequest(editedEvent.name, jamEventRequest);

          getEventByName(updatedEvent.name);
          destroyEdits();
          break;
        }
        case SAVE_ACTIONS.UPDATE_CHANGE_REQUEST:
          return await eventsApi.updateJamEventChangeRequest(editedEvent.name, jamEventRequest).then((res: Event) => {
            getEventByName(res.name);
            destroyEdits();
          });
        case SAVE_ACTIONS.UPDATE_EVENT_REQUEST:
          return await eventsApi.updateJamEventRequest(editedEvent.name, jamEventRequest).then((res: Event) => {
            getEventByName(res.name);
            destroyEdits();
          });
      }
    }
  };

  const bulkHandleUpdateEditEvent = (edits: EventEdit[]) => {
    // remove the previous message everytime user change any input
    clearFlashbars();
    if (isEmpty(edits)) {
      return;
    }
    const newEvent = updateEvent(edits);
    if (newEventMode) {
      setNewEditedEvent(newEvent);
    } else {
      setEditedEvent(newEvent);
    }
  };

  const handleUpdateEditEvent = (action: string, payload: any) => {
    bulkHandleUpdateEditEvent([{ action, payload }]);
  };

  const updateEvent = (edits: EventEdit[]): Event => {
    const eventToEdit = newEventMode ? newEditedEvent : editedEvent;
    const newEvent = _.cloneDeep(eventToEdit);
    if (newEvent) {
      for (const edit of edits) {
        switch (edit.action) {
          case EditEventActions.ADD_TAG:
            newEvent?.addTag(edit.payload);
            break;
          case EditEventActions.REMOVE_TAG:
            newEvent?.removeTag(edit.payload);
            break;
          case EditEventActions.AUDIENCE:
            newEvent.audienceType = edit.payload;
            break;
          case EditEventActions.TITLE:
            newEvent.title = edit.payload;
            break;
          case EditEventActions.SKILLBUILDER_SUBSCRIPTION:
            if (newEvent.validSkillBuilderSubscriptions.includes(edit.payload)) {
              newEvent.validSkillBuilderSubscriptions = newEvent.validSkillBuilderSubscriptions.filter(
                (skillbuilderType) => skillbuilderType !== edit.payload
              );
            } else {
              newEvent.validSkillBuilderSubscriptions.push(edit.payload);
            }
            break;
          case EditEventActions.START_AND_END_DATETIMES:
            newEvent.startDate = edit.payload.startDateTime;
            newEvent.endDate = edit.payload.endDateTime;
            break;
          case EditEventActions.AUTO_UNLOCK_CHALLENGES_MINUTES:
            newEvent.autoUnlockChallengesOffsetMinutes = edit.payload;
            break;
          case EditEventActions.AGREEMENT_ID:
            newEvent.agreementId = edit.payload;
            break;
          case EditEventActions.TIMEZONE:
            newEvent.timezone = edit.payload;
            break;
          case EditEventActions.END_DATE:
            newEvent.endDate = edit.payload;
            break;
          case EditEventActions.MIN_EXPECTED_PARTICIPANTS:
            newEvent.minExpectedParticipants = Number(edit.payload);
            break;
          case EditEventActions.MAX_EXPECTED_PARTICIPANTS:
            newEvent.maxExpectedParticipants = Number(edit.payload);
            break;
          case EditEventActions.MAX_TEAM_SIZE:
            newEvent.maxTeamSize = Number(edit.payload);
            break;
          case EditEventActions.TYPE:
            newEvent.type = edit.payload;
            break;
          case EditEventActions.TEAM_RENAME:
            newEvent.teamRenameEnabled = edit.payload;
            break;
          case EditEventActions.TEAM_TYPE:
            newEvent.teamAssignmentType = edit.payload;
            break;
          case EditEventActions.TEAM_FORM_MINUTES_BEFORE_START:
            newEvent.formTeamsMinsBeforeEventStart = Number(edit.payload);
            break;
          case EditEventActions.EVENT_PERMISSIONS:
            newEvent.eventPermissions = edit.payload;
            break;
          case EditEventActions.SCORING_SETTINGS:
            newEvent.scoringSettings = edit.payload;
            break;
          case EditEventActions.SUPPORT_AND_COLLABORATION:
            if (isArray(edit.payload)) {
              newEvent.collaborationOptions.messagingEnabled = edit.payload.includes('messagingEnabled');
              newEvent.collaborationOptions.supportChatEnabled = edit.payload.includes('supportChatEnabled');
              newEvent.collaborationOptions.teamChatEnabled = edit.payload.includes('teamChatEnabled');
            }
            break;
          case EditEventActions.CHIME_WEBHOOK_URL:
            newEvent.chimeWebHookUrl = edit.payload;
            break;
          case EditEventActions.SPONSORED:
            newEvent.sponsorshipSettings = edit.payload;
            break;
          case EditEventActions.EVENT_FULL_MESSAGE:
            newEvent.eventFullMessage = edit.payload;
            break;
          case EditEventActions.GAMIFICATION:
            newEvent.gamified = edit.payload;
            break;
          case EditEventActions.CLOSED_FOR_NEW_REGISTRATIONS:
            newEvent.closedForNewRegistrations = edit.payload;
            break;
          case EditEventActions.CHALLENGE_VIEW:
            newEvent.challengeViewType = edit.payload;
            break;
          case EditEventActions.UI_THEME:
            newEvent.uiTheme = edit.payload;
            break;
          case EditEventActions.PRIZE_COUNT:
            newEvent.prizeInformation.prizeCount = edit.payload;
            break;
          case EditEventActions.MAX_CLUES:
            newEvent.prizeInformation.maxClues = edit.payload;
            break;
          case EditEventActions.TEST:
            newEvent.test = edit.payload;
            break;
          case EditEventActions.TEST_EVENT_PUBLIC:
            newEvent.testEventPublic = edit.payload;
            break;
          case EditEventActions.PUBLIC_DETAILS_VISIBLE:
            newEvent.publicEventDetails.visible = edit.payload;
            break;
          case EditEventActions.PUBLIC_TITLE:
            newEvent.publicEventDetails.title = edit.payload;
            break;
          case EditEventActions.PUBLIC_DESCRIPTION:
            newEvent.publicEventDetails.description = edit.payload;
            break;
          case EditEventActions.PUBLIC_LOCATION:
            newEvent.publicEventDetails.location = edit.payload;
            break;
          case EditEventActions.PUBLIC_MORE_INFO_URL:
            newEvent.publicEventDetails.moreInfoURL = edit.payload;
            break;
          case EditEventActions.PUBLIC_IMAGE_FILE_NAME:
            newEvent.publicEventDetails.imageFilename = edit.payload;
            break;
          case EditEventActions.LOBBY_VIDEO_SHOW:
            newEvent.onboardingVideo.show = edit.payload;
            break;
          case EditEventActions.LOBBY_VIDEO_URL:
            newEvent.onboardingVideo.url = edit.payload;
            break;
          case EditEventActions.LOBBY_VIDEO_TITLE:
            newEvent.onboardingVideo.title = edit.payload;
            break;
          case EditEventActions.LOBBY_VIDEO_DESCRIPTION:
            newEvent.onboardingVideo.description = edit.payload;
            break;
          case EditEventActions.DEFAULT_LOBBY_VIDEO:
            newEvent.onboardingVideo = newEvent.onboardingVideo || new OnboardingVideoDetails();
            if (edit.payload?.url) {
              newEvent.onboardingVideo.url = edit.payload.url;
            }
            if (edit.payload?.title) {
              newEvent.onboardingVideo.title = edit.payload.title;
            }
            if (edit.payload?.description) {
              newEvent.onboardingVideo.description = edit.payload.description;
            }
            break;
          case EditEventActions.PARTICIPANT_DOMAIN_ALLOWLIST:
            newEvent.participantDomainAllowlist = edit.payload;
            break;
          case EditEventActions.FACILITATOR_DOMAIN_ALLOWLIST:
            newEvent.facilitatorDomainAllowlist = edit.payload;
            break;
          case EditEventActions.LAB_AUTO_SCALE_MIN_PERCENTAGE:
            newEvent.labAutoScaleMinPercent = edit.payload;
            break;
          case EditEventActions.WARMUP_OFFSET_HOURS:
            newEvent.warmupOffsetHours = edit.payload;
            break;
          case EditEventActions.LAB_EXTENSION_HOURS:
            newEvent.labExtensionHours = edit.payload;
            break;
          case EditEventActions.REGION_ALLOWLIST:
            newEvent.regionAllowlist = edit.payload;
            break;
          case EditEventActions.REGION_DENYLIST:
            newEvent.regionDenylist = edit.payload;
            break;
          case EditEventActions.USAGE_PLAN:
            newEvent.usagePlanId = edit.payload;
            break;
          case EditEventActions.CHALLENGE_DESCRIPTORS:
            newEvent.challengeDescriptors = edit.payload;
            break;
          case EditEventActions.CHALLENGE_BOARDS:
            newEvent.challengeBoards = edit.payload;
            break;
          case EditEventActions.GENERAL_BACKUP_CHALLENGES:
            if (!newEvent.backupChallengeConfig) {
              newEvent.backupChallengeConfig = new BackupChallengeConfig();
            }
            newEvent.backupChallengeConfig.generalBackups = edit.payload;
            break;
          case EditEventActions.PER_CHALLENGE_BACKUP_CHALLENGES:
            if (!newEvent.backupChallengeConfig) {
              newEvent.backupChallengeConfig = new BackupChallengeConfig();
            }
            newEvent.backupChallengeConfig.perChallengeBackups = edit.payload;
            break;
          case EditEventActions.SLUG:
            newEvent.id = edit.payload;
            break;
          case EditEventActions.NOTES:
            newEvent.notes = edit.payload;
            break;
          case EditEventActions.CODE_WHISPERER_DISABLED:
            newEvent.codeWhispererDisabled = edit.payload;
            break;
          case EditEventActions.ADD_PARTICIPANTS:
            /* mock userRow */
            const emails = edit.payload;
            const teamMembers: TeamMember[] = emails.map((email: string) => {
              const teamMember: TeamMember = fromPlainObject(
                {
                  userId: faker.string.uuid(),
                  nickname: email,
                },
                TeamMember
              ) as TeamMember;

              return teamMember;
            });
            newEvent.unassignedParticipants = [...newEvent.unassignedParticipants, ...teamMembers];
            /* End mock userRow */
            break;
          case EditEventActions.REMOVE_PARTICIPANTS:
            /* mock userRow */
            const selectedParticipants = edit.payload;
            const selectedParticipantNickNames = selectedParticipants.map(
              (participant: UserRow) => participant.nickname
            );
            newEvent.unassignedParticipants = newEvent.unassignedParticipants.filter(
              (participant: TeamMember) => !selectedParticipantNickNames.includes(participant.nickname)
            );
            /* End mock userRow */
            break;
          case EditEventActions.CHANGE_CATALOG:
            newEvent.catalogId = edit.payload;
            break;
          case EditEventActions.EVENT_PRIVACY_TYPE:
            newEvent.eventPrivacyType = edit.payload;
            break;
          case EditEventActions.EVENT_CODE:
            newEvent.eventCode = edit.payload;
            break;
          default:
            break;
        }
      }
    }
    return newEvent || new Event();
  };

  const populateWithUrlParams = (urlParams: URLSearchParams): string[] => {
    if (!newEventMode || !newEditedEvent) {
      return [];
    }
    const edits: (EventEdit | null)[] = [];

    // Event title
    edits.push(getEditActionFromParam(urlParams, EventURLParameter.TITLE, EditEventActions.TITLE));

    // Event id/slug
    edits.push(getEditActionFromParam(urlParams, EventURLParameter.ID, EditEventActions.SLUG));

    // Event notes
    edits.push(getEditActionFromParam(urlParams, EventURLParameter.NOTES, EditEventActions.NOTES));

    // Event Audience Type
    edits.push(getEditActionFromParam(urlParams, EventURLParameter.AUDIENCE_TYPE, EditEventActions.AUDIENCE));

    // Event start and end date
    let startDateTime = parseQueryParam(urlParams, EventURLParameter.START_DATE) || newEditedEvent.startDate;
    let endDateTime = parseQueryParam(urlParams, EventURLParameter.END_DATE) || newEditedEvent.endDate;
    startDateTime = isValidDateString(startDateTime) ? startDateTime : '';
    endDateTime = isValidDateString(endDateTime) ? endDateTime : '';

    if (isNotEmpty(startDateTime) || isNotEmpty(endDateTime)) {
      edits.push({
        action: EditEventActions.START_AND_END_DATETIMES,
        payload: { startDateTime, endDateTime },
      });
    }

    // Event permissions
    const addedPermissions = createEventPermissions(
      parseQueryParamList(urlParams.getAll(EventURLParameter.OWNERS)),
      parseQueryParamList(urlParams.getAll(EventURLParameter.FACILITATORS))
    );

    // Event tags
    const tags = parseQueryParamList(urlParams.getAll(EventURLParameter.TAGS));
    tags?.forEach((tag) => {
      edits.push({
        action: EditEventActions.ADD_TAG,
        payload: tag,
      });
    });

    // Skill Builder subscription types
    const subscriptionTypes = urlParams.getAll(EventURLParameter.SKILL_BUILDER_SUBSCRIPTION);
    if (subscriptionTypes?.length) {
      // Get all values from the query params, casting to lower case and validating against SkillBuilderSubscriptionAlias enum.
      const types = parseQueryParamList(
        [...subscriptionTypes, ...safeArray(newEditedEvent.validSkillBuilderSubscriptions)],
        (v: string) => (v ? v.toUpperCase() : v),
        (v: string) => Object.values(SkillBuilderSubscription).includes(v as any)
      );

      types.forEach((type) => {
        edits.push({
          action: EditEventActions.SKILLBUILDER_SUBSCRIPTION,
          payload: SkillBuilderSubscription[type as keyof typeof SkillBuilderSubscription],
        });
      });
    }

    const challengeIds = parseQueryParamList(urlParams.getAll(EventURLParameter.CHALLENGES));
    if (challengeIds?.length) {
      edits.push({
        action: EditEventActions.CHALLENGE_DESCRIPTORS,
        payload: [
          ...safeArray(getChallengeDescriptors(challengeIds)),
          ...safeArray(newEditedEvent.challengeDescriptors),
        ],
      });
    }

    const challengeSets = parseQueryParamList(urlParams.getAll(EventURLParameter.CHALLENGE_SETS));
    if (challengeSets?.length) {
      const challenges = challengeSets.map((id) => getChallengeDescriptorsFromSet(id)).flat();
      edits.push({
        action: EditEventActions.CHALLENGE_DESCRIPTORS,
        payload: [
          ...safeArray(newEditedEvent.challengeDescriptors),
          ...ChallengeDescriptor.filterNonUniqueChallengeDescriptorsFromOriginal(
            newEditedEvent.challengeDescriptors,
            challenges
          ),
        ],
      });
    }

    if (!isEmpty(addedPermissions)) {
      // populate new event with permissions and notify of the change
      edits.push({ action: EditEventActions.EVENT_PERMISSIONS, payload: addedPermissions });
    }

    bulkHandleUpdateEditEvent(safeFilterNulls(edits));
    return uniq(
      safeFilterNulls(
        edits.map((e) => {
          if (e) return editEventActionTranslated(e.action) || null;
        })
      )
    ) as string[];
  };

  const getEditActionFromParam = (
    urlParams: URLSearchParams,
    paramKey: EventURLParameter,
    action: EditEventActions
  ): EventEdit | null => {
    const payload = parseQueryParam(urlParams, paramKey);
    if (isNotEmpty(payload)) {
      return { action, payload };
    }
    return null;
  };

  const uploadTeamProperties = async (oldEvent: Event, teamProperties: TeamChallengeProperties[]) => {
    await eventsApi.uploadTeamProperties(oldEvent, teamProperties);
  };

  /**
   * make a list of eventPermissions object for the event from lists of owners and facilitators
   */
  const createEventPermissions = (rawOwnerParams: string[], rawFacilitatorParams: string[]) => {
    const owners = parseQueryParamList(
      [...safeArray(rawOwnerParams), ...safeArray(newEditedEvent?.getOwners())],
      (v: string) => (v ? v.toLowerCase() : v),
      isEmailValid
    );

    const facilitators = parseQueryParamList(
      [...safeArray(rawFacilitatorParams), ...safeArray(newEditedEvent?.getFacilitators())],
      (v: string) => (v ? v.toLowerCase() : v),
      isEmailValid
    );

    const users = uniq([...owners, ...facilitators]);
    const permissions: EventPermission[] = [];

    users.forEach((email) => {
      const isOwner = owners.includes(email);
      const isFacilitator = facilitators.includes(email);
      permissions.push(
        Object.assign(new EventPermission(), {
          email,
          eventPermissionType: isOwner
            ? EventPermissionType.OWNER
            : isFacilitator
            ? EventPermissionType.FACILITATOR
            : null,
        })
      );
    });
    return permissions;
  };

  const editEventActionTranslated = (action: string): string => {
    switch (action) {
      case EditEventActions.TITLE:
        return t(i18nKeys.events.fields.title.title);
      case EditEventActions.SLUG:
        return t(i18nKeys.events.fields.slug.title);
      case EditEventActions.AUDIENCE:
        return t(i18nKeys.events.fields.audience.title);
      // TODO: add notes after it's translated
      case EditEventActions.EVENT_PERMISSIONS:
        return t(i18nKeys.events.eventDetails.headers.ownersAndPermissions);
      case EditEventActions.ADD_TAG:
        return t(i18nKeys.events.eventDetails.headers.tags);
      case EditEventActions.CHALLENGE_DESCRIPTORS:
        return t(i18nKeys.challenges.title);
      case EditEventActions.AGREEMENT_ID:
        return t(i18nKeys.events.eventDetails.headers.agreementId);
      case EditEventActions.SKILLBUILDER_SUBSCRIPTION:
        return t(i18nKeys.events.eventDetails.headers.awsSkillBuilderSubscription);
      case EditEventActions.START_AND_END_DATETIMES:
        return t(i18nKeys.events.eventDetails.headers.eventTimes);
      default:
        return '';
    }
  };

  const hasChanges = (user: User, eventConfig: EventConfiguration, oldEvent: Event) => {
    if (editedEvent) {
      return EventBase.diff(user, eventConfig, oldEvent, editedEvent).changes.length > 0;
    }
    return false;
  };

  const data: EditEventContextValue = {
    editedEvent,
    newEditedEvent,
    editMode,
    newEventMode,
    isGuestUserAndEditModeEnabled:
      editMode &&
      (((loggedInUser?.isOnlyBasicUser as boolean) &&
        loggedInUser &&
        !event?.hasOwnerPermission(loggedInUser?.email)) ||
        event?.isAfterStart),
    newEventImportSources,
    setNewEventImportSources,
    newDuration,
    setNewDuration,
    selectedSpecificDuration,
    setSelectedSpecificDuration,
    toggleEditMode,
    toggleNewEvent,
    initializeEditEvent,
    initializeNewEvent,
    handleUpdateEditEvent,
    bulkHandleUpdateEditEvent,
    destroyEdits,
    uploadTeamProperties,
    populateWithUrlParams,
    hasChanges,
    saveUpdatedEvent,
    setEditMode,
    getEventDurationInfo,
  };

  return <EditEventContext.Provider value={data}>{children}</EditEventContext.Provider>;
};

const useEditEvent = () => {
  const context = useContext(EditEventContext);
  if (context === undefined) {
    throw new Error('useEditEvent can only be used inside EditEventProvider');
  }
  return context;
};

export { EditEventProvider, useEditEvent };
