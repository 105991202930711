export enum DateTimeKeys {
    START = 'start',
    END = 'end',
  }

  export enum TimezoneFormat {
    LOCAL = 'local',
    UTC = 'utc',
  }

  export enum StatusColors {
    green = 'green',
    blue = 'blue',
    grey = 'grey',
    red = 'red',
  }

  export enum ChallengeTargetType {
    EVENT = 'event',
    CHALLENGE_SET = 'challenge-set',
  }

  export enum IMPORT_TYPES {
    IMPORT_FORMAT_DEFAULT = 'Default Format',
    IMPORT_FORMAT_TREND_MICRO = 'TrendMicro',
  }

  export enum ChallengeTabIds {
    SELECTED_CHALLENGES = 'selected-challenges-tab',
    BACKUP_CHALLENGES = 'backup-challenges',
    BROWSE_CHALLENGES = 'browse-challenges-tab',
    IMPORT_CHALLENGES = 'import-challenges-tab',
    GAME_BOARD = 'game-board-tab',
    CHOOSE_DIFFERENT_EVENT = 'choose-different-event-tab',
    LEARNING_EVENT = 'learning-event-tab',
  }

  export enum ImportSource {
    PREVIOUS_EVENT = 'previousEvent',
    CHALLENGE_SET = 'challengeSet',
  }

  export enum LabAccountAction {
    SSH_KEY = 'sshKey',
    UNASSIGN_ACCOUNT = 'unassignAccount',
    TERMINATE_LAB = 'terminateLab',
    STATUS_HISTORY = 'statusHistory',
    RESOURCE_DEPLOYMENT_HISTORY = 'resourceDeploymentHistory',
    CLOUDFORMATION_DETAILS = 'cloudformationStackDetails',
    VIEW_METADATA = 'viewMetadata',
  }

  export enum DocumentGroups {
    GENERAL,
    EVENT_OWNERS,
    FACILITATORS,
    PARTICIPANTS,
    CHALLENGES,
    SUPPORT,
  }