import * as React from 'react';
import { Container, Tabs } from '@amzn/awsui-components-react';
import { Challenge } from '../../../../types/Challenge';
import ChallengeLatestFeedbackTrendDetail from './LatestFeedbackTrend';
import ChallengeReviewsDetail from './Reviews';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface ChallengeFeedbackDetailsProps {
  challenge: Challenge;
}

const ChallengeFeedbackDetails: React.FC<ChallengeFeedbackDetailsProps> = ({ challenge }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Tabs
        tabs={[
          {
            id: 'feedback',
            label: t(i18nKeys.feedback.headers.feedback),
            content: <ChallengeReviewsDetail challenge={challenge} />,
          },
          {
            id: 'feedback-trends',
            label: t(i18nKeys.feedback.headers.feedbackTrends),
            content: <ChallengeLatestFeedbackTrendDetail challenge={challenge} />,
          },
        ]}
      />
    </Container>
  );
};

export default ChallengeFeedbackDetails;
