import React, { useEffect, useState } from 'react';
import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { BackupChallengeRow } from '../BackupChallengeRow';
import { Event } from '../../../types/Event';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { NullableString } from '../../../types/common';
import { useSplitPanel } from '../../../store/split-panel.context';
import BrowseChallengesInSplitPanel from './BrowseChallengesInSplitPanel';
import { useToolPanel } from '../../../store/tool-panel.context';

interface PerChallengeBackupChallenges {
  target: Event;
  challengeId: string;
}

const PerChallengeBackupChallenges: React.FC<PerChallengeBackupChallenges> = ({ target, challengeId }) => {
  const { t } = useTranslation();
  const { handleUpdateEditEvent, editMode, newEventMode } = useEditEvent();
  const [selectedPerChallengeBackUpId, setSelectedPerChallengeBackupId] = useState<NullableString>(null);
  const { toggleChallengeInfo } = useToolPanel();
  const { toggleShowSplitPanel, toggleHideAfterClose, toggleSplitPanel, renderSplitPanelContent } = useSplitPanel();

  const moveChallengeBackupUp = (id: string, backup: ChallengeDescriptor) => {
    moveChallengeBackup(id, backup, -1);
  };

  const moveChallengeBackupDown = (id: string, backup: ChallengeDescriptor) => {
    moveChallengeBackup(id, backup, 1);
  };

  const moveChallengeBackup = (id: NullableString, backup: ChallengeDescriptor, offset: number) => {
    if (target?.backupChallengeConfig && id) {
      const backupChallenges = target?.backupChallengeConfig.perChallengeBackups[id] ?? [];
      target.backupChallengeConfig.perChallengeBackups[id] = sortMove(backup, backupChallenges, offset) ?? [];
      handleUpdateEditEvent(
        EditEventActions.PER_CHALLENGE_BACKUP_CHALLENGES,
        target.backupChallengeConfig.perChallengeBackups
      );
    }
  };

  const sortMove = (backup: ChallengeDescriptor, backupChallenges: ChallengeDescriptor[], offset: number) => {
    const index1 = backupChallenges.findIndex((c) => c?.challengeId === backup.challengeId);
    const index2 = index1 + offset;

    if (index1 < 0 || index1 > backupChallenges.length - 1 || index2 < 0 || index2 > backupChallenges.length - 1) {
      return;
    }

    const temp = backupChallenges[index1];
    backupChallenges[index1] = backupChallenges[index2];
    backupChallenges[index2] = temp;
    return backupChallenges;
  };

  const removeChallengeBackup = (id: NullableString, backup: ChallengeDescriptor) => {
    if (target?.backupChallengeConfig && id) {
      const backupChallenges = (target.backupChallengeConfig.perChallengeBackups[id] ?? []).filter(
        (c) => c?.challengeId !== backup.challengeId
      );
      target.backupChallengeConfig.perChallengeBackups[id] = backupChallenges;
      handleUpdateEditEvent(
        EditEventActions.PER_CHALLENGE_BACKUP_CHALLENGES,
        target.backupChallengeConfig.perChallengeBackups
      );
    }
  };

  const updateSplitPanelSelection = (
    challenges: ChallengeDescriptor[],
    handleSelection: (selectedChallenges: ChallengeDescriptor[]) => void,
    excludedChallenges?: ChallengeDescriptor[]
  ) => {
    renderSplitPanelContent(
      t(i18nKeys.newEvent.splitPanel.header),
      <BrowseChallengesInSplitPanel
        handleChallengeAction={handleSelection}
        toggleChallengeInfo={toggleChallengeInfo}
        currentChallengeDescriptors={challenges}
        excludedChallenges={excludedChallenges}
      />
    );
  };

  const handleSelectPerChallengeBackupChallenges = (challenges: ChallengeDescriptor[]) => {
    if (selectedPerChallengeBackUpId) {
      target.backupChallengeConfig?.addPerChallengeBackups(selectedPerChallengeBackUpId, challenges);
      handleUpdateEditEvent(
        EditEventActions.PER_CHALLENGE_BACKUP_CHALLENGES,
        target.backupChallengeConfig?.perChallengeBackups
      );
    }
  };

  const togglePerChallengeBrowseBackupChallenges = (id: string) => {
    const challengesToExclude = [...target.challengeDescriptors];
    if (id) {
      toggleShowSplitPanel(true);
      toggleHideAfterClose(true);
      toggleSplitPanel(true);
      updateSplitPanelSelection(
        target.backupChallengeConfig?.perChallengeBackups[id] || [],
        handleSelectPerChallengeBackupChallenges,
        challengesToExclude
      );
    }
  };

  // Watch for selectedPerChallengeId and toggle once it's set
  useEffect(() => {
    if (selectedPerChallengeBackUpId) {
      togglePerChallengeBrowseBackupChallenges(selectedPerChallengeBackUpId);
    }
  }, [selectedPerChallengeBackUpId]);

  return (
    <React.Fragment>
      {challengeId && (
        <SpaceBetween direction="vertical" size="s">
          <Header variant="h2">{t(i18nKeys.challenges.backupChallenges.headers.choosePerChallengeBackups)}</Header>
          {target.backupChallengeConfig?.perChallengeBackups[challengeId]?.map((challenge, i) => {
            return (
              <BackupChallengeRow
                key={`per-challenge-backup-challenge-${i}`}
                challengeDescriptor={challenge}
                moveDown={() => moveChallengeBackupDown(challengeId, challenge)}
                moveUp={() => moveChallengeBackupUp(challengeId, challenge)}
                remove={() => removeChallengeBackup(challengeId, challenge)}
                index={i}
                toggleChallengeInfo={toggleChallengeInfo}
                totalCount={target.backupChallengeConfig?.perChallengeBackups[challengeId].length || 0}
              />
            );
          })}
          {!editMode &&
            !newEventMode &&
            (!target.backupChallengeConfig?.perChallengeBackups[challengeId] ||
              target.backupChallengeConfig?.perChallengeBackups[challengeId].length < 1) && (
              <div>{t(i18nKeys.challenges.backupChallenges.messages.noneSelected)}</div>
            )}
          {(editMode || newEventMode) && (
            <Button variant="primary" onClick={() => setSelectedPerChallengeBackupId(challengeId)} data-testid="specificChallengeBackupButton">
              {t(i18nKeys.newEvent.buttons.addBackupChallenges)}
            </Button>
          )}
        </SpaceBetween>
      )}
    </React.Fragment>
  );
};
export default PerChallengeBackupChallenges;
