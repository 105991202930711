import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BreadcrumbGroup, SideNavigationProps } from '@amzn/awsui-components-react';
import { useActiveRouteDetails } from '../../hooks/useActiveRouteDetails';
import { I18N_PREFIX, i18nKeys, withoutI18nPrefix } from '../../utils/i18n.utils';
import { useEvents } from '../../store/events.context';
import { RouteItem } from '../../routes';
import { Challenge, ChallengeWrapper } from '../../types/Challenge';
import { usePlans } from '../../store/usage-plan/usage-plan.context';
import { UsagePlan } from '../../types/usage-plan/UsagePlan';
import { useChallenges } from '../../store/challenge.context';
import { Nullable } from '../../types/common';
import { useCampaigns } from '../../store/campaigns.context';
import { useEditEvent } from '../../store/edit-event.context';
import { useChallengeSet } from '../../store/challenge-set.context';
import { useEditCampaign } from '../../store/edit-campaign.context';
import { useEditEventTemplate } from '@/src/store/edit-event-template.context';
import { JoinTeamHeaders, JoinTeamHeadersKey } from '../game/JoinTeam/join-team.config';
import { QuestionSections } from '../faq/faq.config';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { localLogger } from '@/src/utils/log.utils';

const BreadCrumbs: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { breadcrumbs, isHome, isCatalogDetails } = useActiveRouteDetails();
  const { event } = useEvents();
  const { editedEvent, newEditedEvent } = useEditEvent();
  const { campaign } = useCampaigns();
  const { editedCampaign, newEditedCampaign } = useEditCampaign();
  const { challengeWrapperMap } = useChallenges();
  const { challengeSet } = useChallengeSet();
  const { usagePlans } = usePlans();
  const { eventTemplate } = useEditEventTemplate();
  const { event: eventData } = useJamEventDetails();

  // If the provided link is empty, do not redirect pages
  const onFollow = (e: CustomEvent<SideNavigationProps.FollowDetail>) => {
    e.preventDefault();
    if (e.detail.href) {
      history.push(e.detail.href);
    }
  };

  /**
   * If a route is designated with a 'entityType' attribute, then replace the route default name with
   * the name for that entity.
   *
   * example: the default route name "Event" is replaced with the title of the event "Best Event Ever".
   *
   * @param match
   * @param routeItem
   */
  const resolveEntityName = (match: Record<string, any> | null, routeItem: RouteItem): string => {
    // TODO: get from react context
    // You can't use a context hook outside of a component, investigating another way to do this
    const plans: UsagePlan[] | undefined = usagePlans;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (match != null && Object.keys(match.params).length) {
      switch (routeItem.entityType) {
        case 'event':
          if (event || editedEvent || newEditedEvent) {
            return newEditedEvent?.title || editedEvent?.title || event?.title || 'Untitled Event';
          }
          break;
        case 'campaign':
          if (campaign || editedCampaign || newEditedCampaign) {
            return newEditedCampaign?.title || editedCampaign?.title || campaign?.title || 'Untitled Campaign';
          }
          break;
        case 'challengeSet':
          if (challengeSet) {
            return challengeSet.title as string;
          }
          break;
        case 'challenge':
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { challengeId } = match.params;
          const challengeWrapper: ChallengeWrapper | undefined = challengeWrapperMap[challengeId];
          const challenge: Nullable<Challenge> = challengeWrapper?.latest;
          if (challenge) {
            return challenge.props.title as string;
          }
          break;
        case 'usagePlan':
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { planId } = match.params;
          const plan: UsagePlan | undefined = plans?.find((p) => p.id === planId);
          if (plan) {
            return plan.name as string;
          }
          break;
        case 'catalog':
          localLogger({ breadcrumbs });
          breadcrumbs.splice(3);
          if (eventTemplate) {
            return eventTemplate.name || 'Untitled Event';
          }
          break;
        case 'faq':
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { section } = match.params;
          return t(QuestionSections[section as keyof typeof QuestionSections].title);
        case 'jam-event-onboarding':
          if (match.params.eventId && eventData?.title) {
            return eventData?.title;
          }
          return match.param?.eventId as string;
          break;
        case 'jam-event-onboarding-options':
          if (JoinTeamHeaders[match.params.joinOption as JoinTeamHeadersKey]) {
            return t(JoinTeamHeaders[match.params.joinOption as JoinTeamHeadersKey]);
          }
          return match.params.joinOption as string;
          break;
      }
    }
    return routeItem.name;
  };

  if (isHome && !isCatalogDetails) {
    return null;
  }

  // any route that is prefixed with I18N_PREFIX is actually just a key that needs to be translated
  breadcrumbs.forEach((b: { text: string; match: Record<string, any>; routeItem: RouteItem }) => {
    b.text = resolveEntityName(b.match, b.routeItem);
    if (b.text && b.text.startsWith(I18N_PREFIX)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      b.text = t(withoutI18nPrefix(b.text));
    }
  });

  const defaultColor = breadcrumbs.find((item) => item.href.includes('/events/details'));

  return (
    <>
      <BreadcrumbGroup
        className={!!defaultColor ? 'custom_breadcrumb_color' : ''}
        ariaLabel={t(i18nKeys.general.breadcrumbs)}
        items={breadcrumbs.filter((breadcrumb) => !!breadcrumb.text)}
        onFollow={onFollow}
      />
    </>
  );
};

export default BreadCrumbs;
