import { labProviderStatusColors, labStatusColors } from '@/src/constants/lab-status-color';
import { ChartProperties } from '@/src/utils/chart.utils';
import { labProviderStatusColorsKey, labStatusColorsKey } from '@/src/utils/lab-dashboard-chart.utils';
import { localLogger } from '@/src/utils/log.utils';
import { BarChart } from '@amzn/awsui-components-react';
import { groupBy } from 'lodash';
import React, { useMemo } from 'react';

interface ChartProps {
  chartProperty: ChartProperties;
}

const LabDashboard: React.FC<ChartProps> = ({ chartProperty }) => {
  const getChartData = () => {
    const data = chartProperty.dataSets.map((dataSet) => {
      const groupedEntries = groupBy(dataSet.entries, ({ label }) => label);
      return Object.keys(groupedEntries).map((groupedEntry) => {
        const dataEntries = groupedEntries[groupedEntry];
        let color = '';
        if (dataEntries[0]?.status) {
          color =
            labStatusColors[dataEntries[0].status as labStatusColorsKey] ||
            labProviderStatusColors[dataEntries[0].status as labProviderStatusColorsKey];
        }

        return {
          title: dataEntries?.[0].label || '',
          data: dataEntries.map((entry) => ({ x: entry.label || '', y: entry.value })),
          type: 'bar' as const,
          color,
        };
      });
    });
    return {
      data,
      labels: chartProperty.dataSets.reduce<string[]>((labels, dataSet) => {
        return [...labels, ...dataSet.entries.map((item) => item.label as string)];
      }, []),
    };
  };
  const chartFeed = useMemo(getChartData, [chartProperty]);
  localLogger({ chartFeed });
  return (
    <BarChart
      series={chartFeed.data[0]}
      xDomain={chartFeed.labels}
      stackedBars
      yDomain={[0, chartProperty.ySuggestedMax]}
      ariaLabel={chartProperty.title}
      height={300}
      xTitle={chartProperty.xLabel}
      yTitle={chartProperty.yLabel}
      hideLegend
      hideFilter
    />
  );
};

export default LabDashboard;
