import { Header, Table } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengeListItem } from '../../../types/Challenge';
import { i18nKeys } from '../../../utils/i18n.utils';

interface BrowseChallengesSplitPanelProps {
  selectedChallenges: any[];
}

const BrowseChallengesSplitPanel: React.FC<BrowseChallengesSplitPanelProps> = ({ selectedChallenges }) => {
  const { t } = useTranslation();
  return (
    <div style={{ paddingBottom: '2rem' }}>
      {selectedChallenges.length > 0 && (
        <Table
          header={<Header>{t(i18nKeys.challenges.browseChallenges.headers.selectedChallenges)}</Header>}
          items={selectedChallenges}
          columnDefinitions={[
            {
              id: 'title',
              sortingField: 'title',
              header: t(i18nKeys.challenges.browseChallenges.headers.title),
              cell: (item: ChallengeListItem) => <div>{item?.props?.title}</div>,
              minWidth: 100,
            },
          ]}
        />
      )}
      {selectedChallenges.length < 1 && (
        <span>{t(i18nKeys.challenges.browseChallenges.messages.splitPanelSelectChallenges)}</span>
      )}
    </div>
  );
};
export default BrowseChallengesSplitPanel;
