import React from 'react';
import { Box, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

const WarmUp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SpaceBetween direction="horizontal" size="xs" alignItems="center" className={`warm-up`}>
      <Icon
        svg={
          <div>
            <svg
              strokeWidth={0}
              width="11"
              height="16"
              viewBox="0 0 11 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_4040_575)">
                <path
                  d="M5.83579 0C2.73434 3.35184 5.20511 8.15184 3.65627 8.40038C2.97292 8.50991 2.38288 8.12767 1.6491 6.68857C0.424629 7.88215 0.0332797 9.34542 0.00167071 10.7173C-0.0645576 13.5675 2.18344 15.932 5.02298 15.9985C7.86252 16.065 10.2181 13.8085 10.2844 10.9583C10.3807 6.80869 4.70464 4.42077 5.83579 0Z"
                  fill="url(#paint0_linear_4040_575)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_4040_575"
                  x1="2.67413"
                  y1="2.76714"
                  x2="6.31"
                  y2="14.6432"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F6921E" />
                  <stop offset="1" stopColor="#EC1C24" />
                </linearGradient>
                <clipPath id="clip0_4040_575">
                  <rect width="10.2857" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        }
      />
      <Box className={`severity-2`} fontWeight="bold">
        {t(i18nKeys.challenges.challengeDetails.field.warmup.name)}
      </Box>
    </SpaceBetween>
  );
};

export default WarmUp;
