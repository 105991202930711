import { Container, ExpandableSection, FormField, Grid, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';
import { InputWithIcon } from '../../common/InputWithIcon';

interface CampaignPassingScoreProps {
  campaign: Campaign | undefined;
}

const CampaignPassingScore: React.FC<CampaignPassingScoreProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { campaignEditMode, handleUpdateEditCampaign, newCampaignMode } = useEditCampaign();

  const renderContent = () => {
    return !campaignEditMode && !newCampaignMode ? (
      <div className="section-first-row">
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
          <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.passingScore)}</div>
          <div>
            {(campaign?.campaignSettings.passScorePercent &&
              !isNaN(campaign.campaignSettings.passScorePercent) &&
              campaign?.campaignSettings.passScorePercent) ||
              0}
            %
          </div>
        </Grid>
      </div>
    ) : (
      (campaignEditMode || newCampaignMode) && (
        <FormField label={<strong>{t(i18nKeys.campaigns.labels.campaignDetails.passingScore)}</strong>}>
          <InputWithIcon
            type="number"
            disabled={false}
            icon={<span>%</span>}
            value={campaign?.campaignSettings.passScorePercent?.toString() || '0'}
            onChange={(value) =>
              handleUpdateEditCampaign(EditCampaignActions.PASSING_SCORE, Number(value.replaceAll('-', '')))
            }
          />
        </FormField>
      )
    );
  };

  const headerElement = (
    <Header variant="h2" description={t(i18nKeys.campaigns.headers.campaignDetails.descriptions.passingScore)}>
      {t(i18nKeys.campaigns.headers.campaignDetails.passingScore)}
    </Header>
  );

  return (
    <React.Fragment>
      {!newCampaignMode && (
        <ExpandableSection variant="container" header={headerElement}>
          {renderContent()}
        </ExpandableSection>
      )}
      {newCampaignMode && <Container header={headerElement}>{renderContent()}</Container>}
    </React.Fragment>
  );
};

export default CampaignPassingScore;
