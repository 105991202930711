/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../utils/i18n.utils';

export interface ConfirmModalProps {
  title?: string;
  label?: string;
  message?: string | React.ReactElement;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  visible: boolean;
  size?: 'small' | 'medium' | 'large';
  textInput?: string;
  onTextInput?: (input: string) => any;
  disabled?: boolean;
  onCancel?: () => any;
  onConfirm: () => any;
  onDismiss?: () => any;
  errorText?: string;
  constraintText?: string;
  cancelButtonId?: string;
  submitButtonId?: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  label,
  message,
  confirmBtnLabel,
  cancelBtnLabel,
  visible,
  size,
  textInput,
  onTextInput,
  onCancel,
  onDismiss=onCancel,
  onConfirm,
  disabled = false,
  errorText = '',
  constraintText = '',
  cancelButtonId,
  submitButtonId,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      size={size || 'medium'}
      header={title || t(i18nKeys.general.confirm)}
      onDismiss={onDismiss}
      closeAriaLabel={t(i18nKeys.general.close)}
      footer={
        <Box float="right" className="awsui-util-f-r">
          <SpaceBetween direction="horizontal" size="xxs">
            {onCancel && (
              <div
                key="cancel-button"
                id={cancelButtonId ?? 'cancel-button'}
                data-testid={cancelButtonId ?? 'cancel-button'}
                onClick={() => onCancel()}>
                <Button variant="link">{cancelBtnLabel || t(i18nKeys.general.cancel)}</Button>
              </div>
            )}
            <div
              key="submit-button"
              id={submitButtonId ?? 'submit-button'}
              data-testid={submitButtonId ?? 'submit-button'}
              onClick={() => onConfirm()}>
              <Button disabled={disabled} variant="primary">
                {confirmBtnLabel || t(i18nKeys.general.confirm)}
              </Button>
            </div>
          </SpaceBetween>
        </Box>
      }>
      <SpaceBetween size={'s'}>
        <span key="message">
          {message || t(i18nKeys.general.areYouSure)}
        </span>

        {textInput !== undefined && onTextInput !== undefined && (
          <FormField
            key="formField"
            label={label || ''}
            errorText={errorText}
            i18nStrings={{ errorIconAriaLabel: t(i18nKeys.general.error) }}
            constraintText={constraintText}>
            <Input onChange={({ detail }) => onTextInput(detail.value)} value={textInput} />
          </FormField>
        )}
      </SpaceBetween>
    </Modal>
  );
};
