/* eslint-disable  @typescript-eslint/unbound-method */
export class JamEventSupportDetails {
  eventName: string;
  eventTitle: string;
  challenges: JamChallengeSupportDetails[];

  static fromPlainObject(obj: any): JamEventSupportDetails {
    const details: JamEventSupportDetails = Object.assign(new JamEventSupportDetails(), obj) as JamEventSupportDetails;
    details.challenges = (details.challenges || []).map(JamChallengeSupportDetails.fromPlainObject);
    return details;
  }

  getChallengeSupportDetails(challengeId: string): JamChallengeSupportDetails | undefined {
    return (this.challenges || []).find(({ id }) => id === challengeId);
  }
}

export class JamChallengeSupportDetails {
  id: string;
  title: string;
  owner: string;
  description: string;
  score = 0;
  tasks: ChallengeTaskSupportDetails[] = [];
  maintainers: string[] = [];
  wiki: string;
  wikiHTML?: string;
  facilitatorNotes?: string;

  static fromPlainObject(obj: any): JamChallengeSupportDetails {
    const details: JamChallengeSupportDetails = Object.assign(
      new JamChallengeSupportDetails(),
      obj
    ) as JamChallengeSupportDetails;

    details.tasks = (details.tasks || []).map(ChallengeTaskSupportDetails.fromPlainObject);
    return details;
  }

  getTaskDetails(taskId: string): ChallengeTaskSupportDetails {
    return (this.tasks || []).find((task) => task.taskId === taskId) as ChallengeTaskSupportDetails;
  }
}

export class ChallengeSupportClue {
  order = 0;
  title: string;
  description: string;
  penalty = 0;

  static fromPlainObject(obj: any): ChallengeSupportClue {
    return Object.assign(new ChallengeSupportClue(), obj) as ChallengeSupportClue;
  }
}

export class ChallengeTaskSupportDetails {
  taskId: string;
  taskNumber: number;
  scorePercent = 100;
  title = null;
  content = null;
  clues: ChallengeSupportClue[] = [];
  allowInputAnswer = false;
  answerOutputParamName = null;
  globalStaticAnswer = null;
  validationType = null;
  validationFunction = null;

  static fromPlainObject(obj: any): ChallengeTaskSupportDetails {
    const details: ChallengeTaskSupportDetails = Object.assign(
      new ChallengeTaskSupportDetails(),
      obj
    ) as ChallengeTaskSupportDetails;
    details.clues = (details.clues || []).map(ChallengeSupportClue.fromPlainObject);
    return details;
  }

  getClue(clueOrder: number): ChallengeSupportClue {
    return (this.clues || []).find((c) => c.order === clueOrder) as ChallengeSupportClue;
  }
}
