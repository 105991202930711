import React from 'react';
import { SpaceBetween, TextContent, ContentLayout, Header } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import moment from 'moment';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import './AutoAssignment.scss'

const AutoAssignment = () => {
  const { t } = useTranslation();
  const { event } = useJamEventDetails();
  const endDate = event?.eventEndDate;

  return (
    <ContentLayout disableOverlap header={<Header>
      <div className='autoassignment-header'>
        <SpaceBetween alignItems="start" size={'s'}>
          <TextContent>
            <h1 style={{fontSize: '42px', fontWeight: 'bold'}}>{t(i18nKeys.joinTeam.onboarding.assign)}</h1>
          </TextContent>
          <TextContent>
            <h2 style={{fontSize: '24px', color: '#DA8AF6'}}>{t(i18nKeys.jamEventGuard.eventConclude, { date: moment(endDate).format('MMMM DD, yyyy'), time:  moment(endDate).format('h:mm A')})}</h2>
          </TextContent>
          <TextContent>
            <p>{t(i18nKeys.joinTeam.onboarding.autoAssignInfo)}</p>
          </TextContent>
        </SpaceBetween>
        <div className='autoassignment-image-container'>
          <img src='/assets/slide-6.2.png' />
        </div>
      </div>
    </Header>}>
      <div className='autoassignment-jam-overview'>
        <div style={{flexGrow: 1}}>
          <SpaceBetween direction='vertical' size='s'>
            <TextContent>
              <h3 style={{fontSize: '24px', fontWeight: 'bold'}}>AWS Jam Overview</h3>
            </TextContent>
            <TextContent>
              <p>A brief history on what Jam is all about.</p>
            </TextContent>
          </SpaceBetween>
        </div>
        <div>
          <video controls>
            <source src={'/assets/aws-jam-short.mp4'} type="video/mp4" />
            {t(i18nKeys.events.eventDetails.labels.preEventLobbyVideo.noVideoSupport)}
          </video>
        </div>
      </div>
    </ContentLayout>
  );
};

export default AutoAssignment;
