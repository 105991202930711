import * as React from 'react';
import { useActiveRouteDetails } from '../../hooks/useActiveRouteDetails';
import { ReactNode } from 'react';
import { ChallengeToolSet } from './ChallengeToolSet';
import { RoutePath } from '../../RoutePath';
import { useToolPanel } from '../../store/tool-panel.context';

export const ToolSets: React.FC = () => {
  const { toolPanelContent, toggleShowToolPanel } = useToolPanel();
  const { activeRoute } = useActiveRouteDetails();
  const renderToolView = (): ReactNode => {
    switch (activeRoute) {
      case RoutePath.NEW_CHALLENGE:
        return <ChallengeToolSet />;
      default:
        // Hides the toolPanel if no route is assigning a tool panel
        toggleShowToolPanel(false);
        return null;
    }
  };

  return (toolPanelContent && <React.Fragment>{toolPanelContent}</React.Fragment>) || <div>{renderToolView()}</div>;
};
