import { Container, ExpandableSection, FormField, Grid, Header, Input } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';

interface ParticipantLimitProps {
  campaign: Campaign | undefined;
}

const ParticipantLimit: React.FC<ParticipantLimitProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { campaignEditMode, handleUpdateEditCampaign, newCampaignMode } = useEditCampaign();

  const renderContent = () => {
    return !campaignEditMode && !newCampaignMode ? (
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
        <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.participantLimit)}</div>
        <div>{campaign?.campaignSettings?.maxExpectedParticipants || 0}</div>
      </Grid>
    ) : (
      <FormField label={<strong>{t(i18nKeys.campaigns.labels.campaignDetails.numberOfParticipants)}</strong>}>
        {t(i18nKeys.campaigns.labels.campaignDetails.participantLimitPrefix)}
        <Input
          className="numeric-input"
          type="number"
          onChange={({ detail }) =>
            handleUpdateEditCampaign(EditCampaignActions.PARTICIPANT_LIMIT, Number(detail.value))
          }
          value={campaign?.campaignSettings?.maxExpectedParticipants.toString() || '0'}
        />{' '}
        {t(i18nKeys.campaigns.labels.campaignDetails.participantLimitSuffix)}
      </FormField>
    );
  };
  const headerElement = (
    <Header variant="h2" description={t(i18nKeys.campaigns.headers.campaignDetails.descriptions.participantLimit)}>
      {t(i18nKeys.campaigns.headers.campaignDetails.participantLimit)}
    </Header>
  );
  return (
    <React.Fragment>
      {!newCampaignMode && (
        <ExpandableSection variant="container" header={headerElement}>
          {renderContent()}
        </ExpandableSection>
      )}
      {newCampaignMode && <Container header={headerElement}>{renderContent()}</Container>}
    </React.Fragment>
  );
};
export default ParticipantLimit;
