import React from 'react';
import { IEventTemplateWithSubscribedStatus } from '@/src/types/EventTemplate';
import EventCard from '../../event-templates-catalog/molecules/EventCard/EventCard';
import { AwsServices } from '../../../constants/shared/aws-services';
import { i18nKeys } from '@/src/utils/i18n.utils';

export const CARD_DEFINITIONS = {
  header: (_item: IEventTemplateWithSubscribedStatus) => null,
  sections: [
    {
      id: 'event-card',
      content: (item: IEventTemplateWithSubscribedStatus) => (
        <div className="event-card" key={item.id}>
          <EventCard
            imgSrc={item.imagePresignedUrl}
            id={item.id}
            rating={item.rating}
            title={item.name}
            tag={item.topics}
            learningType={item.learningType}
            duration={item.duration}
            navigationDisabled
            teamSize={3}
            isSubscribed={item.isSubscribed}
          />
        </div>
      ),
    },
  ],
};

export const AWSServices = AwsServices.list.map((awsService) => ({ label: awsService, value: awsService }));

export const allOption = {
  label: i18nKeys.general.all,
  value: 'all',
};

export const SORT_OPTIONS = [
  {
    label: i18nKeys.catalog.catalogFilter.sortType.newest,
    value: '2',
    field: 'createdDate',
    isDescending: false,
  },
  {
    label: i18nKeys.catalog.catalogFilter.sortType.oldest,
    value: '3',
    field: 'createdDate',
    isDescending: true,
  },
];
