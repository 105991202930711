import React from 'react';
import { Helmet } from 'react-helmet-async';
import data from './seoMetaTags.json';

/* to use this JSX component we have three senario 
  1) dynamic meta tags from the component select the list of tags whic you want to proceed as jsx Component
    <SEO title="the quick brown fox", description="the quick brown fox" etc tag... />
  2) Static tags just write the page name in seoMetaTags.json like forate given
    <SEO useStatic page="home" etc tag... />
  3) combination of static and dynamic tags 
    <SEO useStatic page="home" description="the quick brown fox" etc tag... />
  Note: if you provide static tags in seoMetaTags.json it will be override the dynamic tags. 
*/ 


// list of available seoMetaTags
interface metaTags {
  keywords?: string;
  subject?: string;
  copyright?: string;
  language?: string;
  robots?: string;
  revised?: string;
  abstract?: string;
  topic?: string;
  summary?: string;
  Classification?: string;
  author?: string;
  designer?: string;
  replyTo?: string;
  owner?: string;
  url?: string;
  identifierUrl?: string;
  directory?: string;
  pagename?: string;
  category?: string;
  coverage?: string;
  distribution?: string;
  rating?: string;
  revisitAfter?: string;
  subtitle?: string;
  target?: string;
  HandheldFriendly?: string;
  MobileOptimized?: string;
  date?: string;
  searchDate?: string;
  medium?: string;
}

// dynamic required properties
interface useDynamicData extends metaTags {
  title: string;
  description: string
  useStatic?: boolean;
  page?: string;
}

// static required properties from seoMetaTags.json file
interface useStaticData extends metaTags {
  title?: string;
  description?: string;
  useStatic: boolean;
  page: string;
}

type seoProps = useDynamicData | useStaticData;

const SEO: React.FC<seoProps> = (props: seoProps): JSX.Element => {
  const { useStatic, page } = props;
  let metaTagProps = {...props};
  if (useStatic && page) {
    const DataExistInLocal = data[page as keyof typeof data];
    if (DataExistInLocal) {
        DataExistInLocal.forEach((item): void => {
          metaTagProps = { ...metaTagProps, ...{ [item.name]: item.content } };
        });
    }
  }
  const {
    title,
    description,
    keywords,
    subject,
    copyright,
    language,
    robots,
    revised,
    abstract,
    topic,
    summary,
    Classification,
    author,
    designer,
    replyTo,
    owner,
    url,
    identifierUrl,
    directory,
    pagename,
    category,
    coverage,
    distribution,
    rating,
    revisitAfter,
    subtitle,
    target,
    HandheldFriendly,
    MobileOptimized,
    date,
    searchDate,
    medium,
  } = metaTagProps;
  return (
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {subject && <meta name="subject" content={description} />}
        {copyright && <meta name="copyright" content={copyright} />}
        {language && <meta name="language" content={language} />}
        {robots && <meta name="robots" content={robots} />}
        {revised && <meta name="revised" content={revised} />}
        {abstract && <meta name="abstract" content={abstract} />}
        {topic && <meta name="topic" content={topic} />}
        {summary && <meta name="summary" content={summary} />}
        {Classification && <meta name="Classification" content={Classification} />}
        {author && <meta name="author" content={author} />}
        {designer && <meta name="designer" content={designer} />}
        {replyTo && <meta name="reply-to" content={replyTo} />}
        {owner && <meta name="owner" content={owner} />}
        {url && <meta name="url" content={url} />}
        {identifierUrl && <meta name="identifier-URL" content={identifierUrl} />}
        {directory && <meta name="directory" content={directory} />}
        {pagename && <meta name="pagename" content={pagename} />}
        {category && <meta name="category" content={category} />}
        {coverage && <meta name="coverage" content={coverage} />}
        {distribution && <meta name="distribution" content={distribution} />}
        {rating && <meta name="rating" content={rating} />}
        {revisitAfter && <meta name="revisit-after" content={revisitAfter} />}
        {subtitle && <meta name="subtitle" content={subtitle} />}
        {target && <meta name="target" content={target} />}
        {HandheldFriendly && <meta name="HandheldFriendly" content={HandheldFriendly} />}
        {MobileOptimized && <meta name="MobileOptimized" content={MobileOptimized} />}
        {date && <meta name="date" content={date} />}
        {searchDate && <meta name="search_date" content={searchDate} />}
        {medium && <meta name="medium" content={medium} />}
      </Helmet>
  );
};

export default SEO;
