import React from 'react';
import { Box, ColumnLayout, Container, Grid, Icon, IconProps, SpaceBetween } from '@amzn/awsui-components-react';
import { EventTopicItem, IEventTemplate } from '@/src/types/EventTemplate';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import './Overview.scss';

export interface IOverviewProps {
  eventTemplate: IEventTemplate | null | undefined;
  topics: EventTopicItem[] | null | undefined;
  hours: number | undefined;
}
interface IOverviewItems {
  title: string;
  subTitle: string | JSX.Element[];
  icon: IconProps.Name;
}

const Overview: React.FC<IOverviewProps> = ({ eventTemplate, topics, hours }) => {
  const { t } = useTranslation();
  const totalChallengeText = t(i18nKeys.eventTemplates.catalogDetails.overview.challenges.title);
  const defectiveChallengeText = t(i18nKeys.eventTemplates.catalogDetails.overview.challenges.defective);
  const privateChallengeText = t(i18nKeys.eventTemplates.catalogDetails.overview.challenges.private);

  const totalChallengeCount = eventTemplate?.challenges.filter(({ backup }) => !backup).length;
  const defectiveChallengeCount = eventTemplate?.challenges.filter(({ isDefective }) => isDefective).length;
  const privateChallengeCount = eventTemplate?.challenges.filter(({ isDefective, isPublic }) => !isDefective && !isPublic).length;

  const items: IOverviewItems[] = [
    {
      title: t(i18nKeys.eventTemplates.catalogDetails.overview.duration.title),
      subTitle: `${hours} ${t(i18nKeys.eventTemplates.catalogDetails.overview.duration.subtitle)}`,
      icon: 'status-pending',
    },
    {
      title: `${totalChallengeText} (${defectiveChallengeText}, ${privateChallengeText})`,
      subTitle: `${totalChallengeCount} (${defectiveChallengeCount}, ${privateChallengeCount})`,
      icon: 'key',
    },
    {
      title: t(i18nKeys.eventTemplates.catalogDetails.overview.topic.title),
      subTitle:
        !!eventTemplate?.topics?.length && topics
          ? topics.map((topic, i) => (
              <span key={topic?.value}>
                {topic?.label}
                {topics.length - 1 !== i ? ', ' : ''}
              </span>
            ))
          : '',
      icon: 'flag',
    },
    {
      title: t(i18nKeys.eventTemplates.catalogDetails.overview.recommendedTeamSize.title),
      subTitle: eventTemplate?.recommendedTeamSize.toString() || '0',
      icon: 'group',
    },
    {
      title: t(i18nKeys.eventTemplates.catalogDetails.overview.numberOfParticipants.title),
      subTitle: `${eventTemplate?.minParticipants || '2'} - ${eventTemplate?.maxParticipants || '200'}`,
      icon: 'group',
    },
    {
      title: t(i18nKeys.eventTemplates.catalogDetails.overview.intendedAudience.title),
      subTitle: t(i18nKeys.eventTemplates.catalogDetails.overview.intendedAudience.subtitle),
      icon: 'group-active',
    },
    {
      title: t(i18nKeys.eventTemplates.catalogDetails.overview.eventRequirements.title),
      subTitle: t(i18nKeys.eventTemplates.catalogDetails.overview.eventRequirements.subtitle),
      icon: 'lock-private',
    },
  ];

  return (
    <div className="catalog-details-overview">
      <Box variant="h1" margin={{ bottom: 'xs' }}>
        {t(i18nKeys.eventTemplates.catalogDetails.overview.title)}
      </Box>
      <Container>
        <ColumnLayout columns={2} variant="text-grid">
          {items.map((item, i) => (
            <div key={i} style={{ margin: '2px 0' }}>
              <Grid gridDefinition={[{ colspan: 1 }, { colspan: 11 }]}>
                <Icon size="medium" name={item.icon} />
                <SpaceBetween direction="vertical" size="xxxs">
                  <Box variant="h4" fontWeight="normal">
                    {item.title}
                  </Box>
                  <Box variant="span" color="text-status-inactive">
                    {item.subTitle}
                  </Box>
                </SpaceBetween>
              </Grid>
            </div>
          ))}
        </ColumnLayout>
      </Container>
    </div>
  );
};

export default Overview;
