import { ReactNode } from 'react';
import { uniq } from 'lodash';
import { randomId } from './randomId';
import { FLASHBAR_REGION_CLASSNAME } from '../constants/common';
import { tick } from './tick';
import { LocalizedError } from '../types/LocalizedError';
import { FlashbarItem, Renderable } from '../types/common';
import { i18nKeys, withI18NPrefix, withoutI18nPrefix } from './i18n.utils';
import { preProdLogger } from './log.utils';

export enum FlashbarType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning'
}

export type TypedFlashbarItemCreator = (
  header: Renderable<() => ReactNode> | null,
  content: Renderable<() => ReactNode>,
  I18NKeys?: string[],
  onDismiss?: () => any
) => FlashbarItem;

export const flashbarItem =
  (type: FlashbarType, dismissible = true): TypedFlashbarItemCreator =>
  (
    header: Renderable<() => ReactNode> | null,
    content: Renderable<() => ReactNode>,
    I18NKeys: string[] = [],
    onDismiss?: () => any
  ): FlashbarItem => {
    // detect if the header or content is a localization key
    if (typeof header === 'string' && header.startsWith('i18n')) {
      I18NKeys.push(withoutI18nPrefix(header));
    }
    if (typeof content === 'string' && content.startsWith('i18n')) {
      I18NKeys.push(withoutI18nPrefix(content));
    }

    return {
      // assign a unique id to the flashbar item so that we can remove this item by its id
      id: randomId(),
      header,
      type,
      content,
      i18nKeys: uniq(I18NKeys),
      dismissible,
      onDismiss,
    };
  };

export const infoFlashbar: TypedFlashbarItemCreator = flashbarItem(FlashbarType.INFO);
export const successFlashbar: TypedFlashbarItemCreator = flashbarItem(FlashbarType.SUCCESS);
export const warningFlashbar: TypedFlashbarItemCreator = flashbarItem(FlashbarType.WARNING);
export const errorFlashbar: TypedFlashbarItemCreator = flashbarItem(FlashbarType.ERROR);
export const bannerFlashbar: TypedFlashbarItemCreator = flashbarItem(FlashbarType.WARNING, false);

export const apiErrorFlashbar = (err: Error, message: string): FlashbarItem => {
  const localI18nKeys = [];
  if (message.startsWith('i18n')) {
    localI18nKeys.push(withoutI18nPrefix(message));
  }

  let errMessage = err.message;

  if (LocalizedError.isLocalizedError(err)) {
    const key = (err as LocalizedError).i18nKey;
    localI18nKeys.push(key);
    errMessage = withI18NPrefix(key);
  }

  const content = message === errMessage ? `${message}.` : `${message}. ${errMessage}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return errorFlashbar(withI18NPrefix(i18nKeys.general.error), content, localI18nKeys);
};

export const loadingFlashbar = (flashbar: FlashbarItem): FlashbarItem => {
  flashbar.loading = true;
  return flashbar;
};

export const scrollFlashbarIntoView = (): void => {
  const notificationsRegions: HTMLCollectionOf<Element> = document.getElementsByClassName(FLASHBAR_REGION_CLASSNAME);
  if (notificationsRegions.length > 0) {
    notificationsRegions[0].scrollIntoView();
  }
};

export const scrollFlashbarIntoViewOnNextTick = (): void => {
  tick()
    .then(() => scrollFlashbarIntoView())
    .catch(() => preProdLogger('Flashbar: Something went wrong'));
};
