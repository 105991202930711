import { Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditCampaign } from '../../../store/edit-campaign.context';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';
import CampaignAttempts from './CampaignAttempts';
import CampaignClues from './CampaignClues';
import CampaignPassingScore from './CampaignPassingScore';
import CampaignTimeLimit from './CampaignTimeLimit';
import CampaignType from './CampaignType';

interface CampaignSettingsProps {
  campaign: Campaign | undefined;
}

const CampaignSettings: React.FC<CampaignSettingsProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { editedCampaign } = useEditCampaign();
  return (
    <React.Fragment>
      <Header variant="h2">{t(i18nKeys.campaigns.headers.campaignDetails.settings)}</Header>
      <SpaceBetween direction="vertical" size="s">
        <CampaignType campaign={editedCampaign || campaign} />
        <CampaignClues campaign={editedCampaign || campaign} />
        <CampaignTimeLimit campaign={editedCampaign || campaign} />
        <CampaignPassingScore campaign={editedCampaign || campaign} />
        <CampaignAttempts campaign={editedCampaign || campaign} />
      </SpaceBetween>
    </React.Fragment>
  );
};

export default CampaignSettings;
