import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from './common';

@jsonObject
export class ChallengeTitles {
  [challengeId: string]: string;
}

@jsonObject
export class SupportChatCount {
  [challengeId: string]: number;
}

@jsonObject
export class MinMaxMean {
  @jsonMember(Number)
  min = 0;
  @jsonMember(Number)
  max = 0;
  @jsonMember(Number)
  mean = 0;
}

@jsonObject
export class EventFeedbackModel {
  @jsonMember(Number)
  eventRank = 0;
  @jsonMember(Number)
  speakerRank = 0;
  @jsonMember(common.NullableStringValue)
  notes: common.NullableString = null;
}

@jsonObject
export class TeamChallengeMetricRow {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  numCompletedTasks: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  numIncorrectAnswers: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  timeToFirstAttempt: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  timeToCompletedChallenge: common.NullableNumber = null;
  @jsonMember(Number)
  numCluesUsed = 0;
  @jsonArrayMember(Number)
  timeToNthClueUsed: number[] = [];
  @jsonMember(Number)
  teamChallengeRating: number | 'NaN' = 'NaN';
  @jsonMember(Number)
  teamChallengeDifficultyRating: number | 'NaN' = 'NaN';
  @jsonArrayMember(String)
  feedbackComments: string[] = [];
  @jsonArrayMember(String)
  teamMembersSolved: string[] = [];
  @jsonMember(common.NullableNumberValue)
  totalNumberOfAttempts: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  learnedSomethingNew: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  didNotLearnSomethingNew: common.NullableNumber = null;
}

@jsonObject
export class TeamMetricsRow {
  @jsonMember(common.NullableStringValue)
  teamName: common.NullableString = null;
  @jsonArrayMember(TeamChallengeMetricRow)
  startedChallenges: TeamChallengeMetricRow[] = [];
  @jsonArrayMember(String)
  notStartedChallenges: string[] = [];
  @jsonMember(Number)
  finalScore = 0;
  @jsonMember(Number)
  finalRank = 0;
  @jsonMember(Number)
  numberChallengesSolved = 0;
  @jsonMember(common.NullableNumberValue)
  timeFromFirstStartedChallengeToLastCompletionTime: common.NullableNumber = null;
  @jsonArrayMember(String)
  teamMembers: string[] = [];
  @jsonArrayMember(String)
  awsServicesUsed: string[] = [];
  @jsonMember(Number)
  numberOfRestartsUsed = 0;
  @jsonMember(Number)
  numberOfAWSAccountsUsed = 0;
  @jsonMember(Number)
  numberOfSupportChatsRequested = 0;
  @jsonMember(Boolean)
  suspectedOfCheating = false;
}

export class ChallengeMetricsRow {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;
  @jsonMember(Number)
  numTeamsStarted = 0;
  @jsonMember(Number)
  numTeamsSolved = 0;
  @jsonMember(Number)
  numTeamsAttempted = 0;
  @jsonMember(Number)
  numCluesRequested = 0;
  @jsonMember(Number)
  numCompletedTasks = 0;
  @jsonMember(Number)
  numIncorrectAnswers = 0;
  @jsonMember(Number)
  avgChallengeRank: number | 'NaN' = 'NaN';
  @jsonMember(Number)
  avgChallengeDifficulty: number | 'NaN' = 'NaN';
  @jsonMember(Number)
  challengeFeedbackCount = 0;
  @jsonMember(MinMaxMean)
  globalSolveTime: MinMaxMean = new MinMaxMean();
  @jsonMember(MinMaxMean)
  timeToFirstAttempt: MinMaxMean = new MinMaxMean();
  @jsonMember(MinMaxMean)
  timeToCompletedChallenge: MinMaxMean = new MinMaxMean();
  @jsonArrayMember(AnyT)
  timeToNthClueUsed: MinMaxMean[] = [];
  @jsonMember(Number)
  numberOfRestartsUsed = 0;
  @jsonMember(Number)
  totalNumberOfAWSAccountsUsed = 0;
  @jsonArrayMember(String)
  feedbackComments: string[] = [];
  @jsonArrayMember(String)
  awsServicesUsed: string[] = [];
  @jsonMember(common.NullableNumberValue)
  supportChatRequests: common.NullableNumber = null;
  @jsonMember(Number)
  totalNumberOfAttempts = 0;
  @jsonMember(Number)
  learnedSomethingNew = 0;
  @jsonMember(Number)
  didNotLearnSomethingNew = 0;
  @jsonMember(Number)
  numParticipantsUsedAwsCli = 0;
  @jsonMember(Number)
  numParticipantsUsedAwsConsole = 0;
  @jsonMember(Number)
  numParticipantsUsedCodeWhisperer = 0;
}

/**
 * Event Report Interface
 *
 * @lastGeneratedDate - The last time the report was generated by a user
 * @lastUpdatedDate - The last time the report was updated, i.e supportChatCount update
 */
@jsonObject
export class DetailedEventStatisticsReport {
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;
  @jsonArrayMember(TeamMetricsRow)
  teamMetrics: TeamMetricsRow[] = [];
  @jsonArrayMember(AnyT)
  challengeMetrics: ChallengeMetricsRow[] = [];
  @jsonArrayMember(EventFeedbackModel)
  eventFeedback: EventFeedbackModel[] = [];
  // AnyT allows typedJson to map the values to the class, TypedJson currently does not support dictionary style objects so this is the work around
  @jsonMember(AnyT)
  challengeTitles: { [key: string]: string } = {};
  @jsonMember(common.NullableNumberValue)
  lastUpdatedDate: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  lastGeneratedDate: common.NullableNumber = null;
  @jsonMember(SupportChatCount)
  supportChatCount: SupportChatCount = new SupportChatCount();
}
