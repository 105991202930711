import { jsonMember, jsonObject } from 'typedjson';
import * as common from './common';

@jsonObject
export class OnboardingVideoDetails {
  @jsonMember(common.NullableStringValue)
  url: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;

  @jsonMember(Boolean)
  show = false;
}
