/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApiClient } from './ApiClient';
import { UserRow } from '../types/UserRow';
import { NullableString } from '../types/common';
import { TFunction } from 'i18next';
import { i18nKeys } from '../utils/i18n.utils';
import { PrizeDetails } from '../types/PrizeDetails';

export class UserAPI {

  constructor(private apiClient: ApiClient, private t: TFunction) {
    // do nothing
  }

  public async fetchUserEmail(eventName: string, userId: string, silent = false): Promise<any> {
    return (await this.apiClient.post({
      path: `/admin/events/${eventName}/users/fetchEmail`,
      body: { userId },
      failMessage: this.t(i18nKeys.errors.requestFailed.user.fetchEmail),
      silent,
    })) as Promise<any>;
  }

  public async fetchUserByEmail(email: string | undefined, silent = false): Promise<any> {
    return (await this.apiClient.get({
      path: `/admin/users/${email}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.user.fetchEmail),
      silent,
    })) as Promise<any>;
  }

  public async resetUserPassword(eventName: string, userId: string, nickname: string, silent = false): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/users/resetPassword`,
      body: { userId },
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.passwordReset, { nickname }),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.resetPassword, { nickname }),
      silent,
    });
  }

  public async promoteUserToFacilitator(
    eventName: string,
    userId: string,
    nickname: string,
    silent = false
  ): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/users/changeRole`,
      body: {
        userId,
        role: 'facilitator',
      },
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.promoteToFacilitator, { nickname }),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.promoteToFacilitator, { nickname }),
      silent,
    });
  }

  public async demoteUserToParticipant(
    eventName: string,
    userId: string,
    nickname: string,
    silent = false
  ): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/users/changeRole`,
      body: {
        userId,
        role: 'participant',
      },
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.demoteToParticipant, { nickname }),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.demoteToParticipant, { nickname }),
      silent,
    });
  }

  public async disableUserAccount(eventName: string, userId: string, nickname: string, silent = false): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/users/disableAccount`,
      body: { userId },
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.disable, { nickname }),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.disable, { nickname }),
      silent,
    });
  }

  public async enableUserAccount(eventName: string, userId: string, nickname: string, silent = false): Promise<any> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/users/enableAccount`,
      body: { userId },
      successMessage: this.t(i18nKeys.success.requestSucceeded.user.enable, { nickname }),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.enable, { nickname }),
      silent,
    });
  }

  public async assignUserToTeam(
    eventName: string,
    teamName: NullableString,
    user: UserRow,
    silent = false
  ): Promise<any> {
    const { userId } = user;

    return this.apiClient.post({
      path: `/admin/events/${eventName}/users/assignTeam`,
      body: { userId, teamName },
      successMessage:
        teamName == null
          ? this.t(i18nKeys.success.requestSucceeded.user.unassign, { nickname: user.nickname })
          : this.t(i18nKeys.success.requestSucceeded.user.assign, { nickname: user.nickname, teamName }),
      failMessage: this.t(i18nKeys.errors.requestFailed.user.assign, { nickname: user.nickname }),
      silent,
    });
  }

  public async getUserPrizesByCode(prizeCode: string, silent = false): Promise<PrizeDetails[]> {
    return this.apiClient.get({
      path: `/admin/prizes/${prizeCode}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.prizeRedemption.getUserPrizesByCode),
      silent,
    }) as Promise<PrizeDetails[]>;
  }

  public async markParticipantPrizeAsClaimed(prize: PrizeDetails, silent = false) {
    const { eventName, challengeId, prizeCode } = prize;

    let path = `/admin/prizes/${eventName}`;

    if (challengeId) {
      path += `/${challengeId}`;
    }

    return this.apiClient.post({
      path: `${path}/${prizeCode}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.prizeRedemption.markParticipantPrizeAsClaimed),
      silent,
    });
  }
}
