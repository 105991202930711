import React from 'react';
import { Box, ContentLayout, Header, SpaceBetween } from '@amzn/awsui-components-react';
import ExpandableFeedback from '../../ui/organisms/MyJams/JamFeedback/ExpandableFeedback/index';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { IJamChallenge } from '@/src/types/JamChallenges';
import { useLocation } from 'react-router-dom';
import { Location } from 'history'

const getChallengeIdFromLocation= (location: Location) => {
 const search = location.search
 const searchParams = new URLSearchParams(search)
 return searchParams.get('challengeId')
}

const JamFeedback = () => {
  const { t } = useTranslation();
  const { event } = useJamEventDetails();
  const { jamChallengeData } = useJamChallenge();
  const location = useLocation();

  const challengeId = getChallengeIdFromLocation(location)

  // eslint-disable-next-line no-console
  console.log(jamChallengeData?.challenges, challengeId)
  return (
    <ContentLayout header={<Header variant="h1">{t(i18nKeys.myJams.feedback.title)}</Header>}>
      <Box margin={{ bottom: 'xxl' }}>
        <SpaceBetween direction="vertical" size="l">
          {
            !challengeId &&
            <ExpandableFeedback headerText={event?.title ?? 'Jam Event Feedback'} />
          }
          {jamChallengeData?.challenges &&
            jamChallengeData?.challenges
              ?.filter((challenge: IJamChallenge) => {
                if (challengeId) {
                  return challengeId === challenge.id && !challenge.locked
                }
                return !challenge.locked
              })
              .map((challenge: IJamChallenge) => (
                <ExpandableFeedback
                  key={challenge.id}
                  challengeId={challenge.id}
                  headerText={`${t(i18nKeys.myJams.challenges.challenge)}: ${challenge.title ?? '[Challenge Title]'}`}
                  isEvent={false}
                />
              ))}
        </SpaceBetween>
      </Box>
    </ContentLayout>
  );
};

export default JamFeedback;
