import React, { useEffect, useState } from 'react';
import { Checkbox, ExpandableSection, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../store/user.context';
import { i18nKeys } from '../../../utils/i18n.utils';
import { SkillBuilderSubscription } from '../../../types/SkillBuilderSubscription';
import { Event } from '../../../types/Event';
import { Campaign } from '../../../types/Campaign';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';

interface SkillBuilderProps {
  target?: Event | Campaign;
}

const SkillBuilder: React.FC<SkillBuilderProps> = ({ target }) => {
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign } = useEditCampaign();
  const [subscriptions, setSubscriptions] = useState<JSX.Element[] | JSX.Element | undefined>();
  const { t } = useTranslation();
  const { user } = useUser();
  const isEvent = target instanceof Event;
  const canEdit = editMode || campaignEditMode;
  const targetSubscriptions = isEvent ? target : target?.campaignSettings;

  const generateSubscriptions = () => {
    if (
      targetSubscriptions?.validSkillBuilderSubscriptions &&
      targetSubscriptions.validSkillBuilderSubscriptions.length > 0
    ) {
      setSubscriptions(
        targetSubscriptions.validSkillBuilderSubscriptions.map((sub, i) => (
          <div key={`${sub}${i}`}>
            <strong>{sub}</strong>
          </div>
        ))
      );
    } else {
      setSubscriptions(
        <div key="no-subscriptions">
          <strong>{t(i18nKeys.events.eventDetails.labels.none)}</strong>
        </div>
      );
    }
  };

  useEffect(() => {
    generateSubscriptions();
  }, [editMode]);

  const editedTarget = isEvent ? editedEvent : editedCampaign?.campaignSettings;

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.awsSkillBuilder.header)}</Header>}>
      {canEdit && user && (
        <div>
          <b>{t(i18nKeys.events.eventDetails.labels.awsSkillBuilderSubscriptionsAllowingAccessToEvent)}</b>
          <ul style={{ padding: 0, paddingLeft: 16 }}>
            <li>{t(i18nKeys.events.eventDetails.labels.awsSkillBuilder.info1)}</li>
            <li>{t(i18nKeys.events.eventDetails.labels.awsSkillBuilder.info2)}</li>
            <li>{t(i18nKeys.events.eventDetails.labels.awsSkillBuilder.info3)}</li>
            <li>{t(i18nKeys.events.eventDetails.labels.awsSkillBuilder.info4)}</li>
          </ul>
          <SpaceBetween size="xs">
            <Checkbox
              checked={editedTarget?.validSkillBuilderSubscriptions.includes(SkillBuilderSubscription.TEAM) || false}
              onChange={() =>
                isEvent
                  ? handleUpdateEditEvent(EditEventActions.SKILLBUILDER_SUBSCRIPTION, SkillBuilderSubscription.TEAM)
                  : handleUpdateEditCampaign(
                      EditCampaignActions.SKILLBUILDER_SUBSCRIPTION,
                      SkillBuilderSubscription.TEAM
                    )
              }
              disabled={!target?.canEditAttribute('skillBuilderSubscription', user)}>
              {t(i18nKeys.events.eventDetails.labels.teamSubscription)}
            </Checkbox>
            <Checkbox
              checked={
                editedTarget?.validSkillBuilderSubscriptions.includes(SkillBuilderSubscription.INDIVIDUAL) || false
              }
              onChange={() =>
                isEvent
                  ? handleUpdateEditEvent(
                      EditEventActions.SKILLBUILDER_SUBSCRIPTION,
                      SkillBuilderSubscription.INDIVIDUAL
                    )
                  : handleUpdateEditCampaign(
                      EditCampaignActions.SKILLBUILDER_SUBSCRIPTION,
                      SkillBuilderSubscription.INDIVIDUAL
                    )
              }
              disabled={!target?.canEditAttribute('skillBuilderSubscription', user)}>
              {t(i18nKeys.events.eventDetails.labels.individualSubscription)}
            </Checkbox>
            <Checkbox
              checked={
                editedTarget?.validSkillBuilderSubscriptions.includes(SkillBuilderSubscription.AWS_BUILDER_LABS) || false
              }
              onChange={() =>
                isEvent
                  ? handleUpdateEditEvent(
                    EditEventActions.SKILLBUILDER_SUBSCRIPTION,
                    SkillBuilderSubscription.AWS_BUILDER_LABS
                  )
                  : handleUpdateEditCampaign(
                    EditCampaignActions.SKILLBUILDER_SUBSCRIPTION,
                    SkillBuilderSubscription.AWS_BUILDER_LABS
                  )
              }
              disabled={!target?.canEditAttribute('skillBuilderSubscription', user)}
            >
              {/* Intentionally not translating AWS Builder Labs as it's a product name. */}
              AWS Builder Labs
            </Checkbox>
          </SpaceBetween>
        </div>
      )}
      {!canEdit && (
        <div>
          <div>{t(i18nKeys.events.eventDetails.headers.awsSkillBuilderSubscription)}</div>
          {subscriptions}
        </div>
      )}
    </ExpandableSection>
  );
};
export default SkillBuilder;
