import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  ContentLayout,
  FormField,
  Header,
  Input,
  Modal,
  RadioGroup,
  Select,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import Divider from '../../ui/atoms/Divider/Divider';
import './FacilitatorMessaging.scss';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useFlashbars } from '@/src/store/flashbar.context';
import { FlashbarType } from '@/src/utils/notification.utils';
import { useApi } from '@/src/store/api.context';
import { JamMinimalParticipant } from '@/src/types/JamEvent';
import { facilitatorMessagingValidator } from '@/src/utils/facilitator-messaging.validations.utils';
import { NotificationMessage, NotificationOptions } from '@/src/types/JamNotifications';
import { FacilitatorMessagingFields } from '@/src/types/FacilitatorMessaging';
import { uniq } from 'lodash';
import { EventTopicItem } from '@/src/types/EventTemplate';
import { useQuery } from '@/src/hooks/useQuery';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';

const RecipientOptions: EventTopicItem[] = [
  {
    label: i18nKeys.facilitator.messaging.form.fields.allParticipants,
    value: 'event',
  },
  {
    label: i18nKeys.facilitator.messaging.form.fields.specificTeam,
    value: 'team',
  },
  {
    label: i18nKeys.facilitator.messaging.form.fields.specificUser,
    value: 'participant',
  },
];

const MessageTypeOptions = [
  {
    label: i18nKeys.facilitator.messaging.form.fields.information,
    value: 'info',
  },
  {
    label: i18nKeys.facilitator.messaging.form.fields.warning,
    value: 'warning',
  },
];
const defaultAutoDismissSeconds = '5';

const FacilitatorMessaging = () => {
  const { t } = useTranslation();
  const { addFlashbar, clearFlashbars } = useFlashbars();
  const { jamFacilitatorApi, jamMessageApi } = useApi();
  const { eventName, event } = useJamEventDetails();
  const queryParams = useQuery();

  const recipientOptions = RecipientOptions.map((item) => ({ ...item, label: t(item.label) }));
  const messageTypeOptions = MessageTypeOptions.map((item) => ({ ...item, label: t(item.label) }));

  const [sending, setSending] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [autoDismiss, setAutoDismiss] = useState(true);
  const [recipient, setRecipient] = useState<OptionDefinition | null>(recipientOptions[0]);
  const [messageType, setMessageType] = useState<OptionDefinition | null>(messageTypeOptions[0]);
  const [title, setTitle] = useState(queryParams.get('title') || '');
  const [seconds, setSeconds] = useState(defaultAutoDismissSeconds);
  const [message, setMessage] = useState(queryParams.get('message') || '');
  const [notificationType, setNotificationType] = useState<'message' | 'email'>('message');
  const [allParticipants, setAllParticipants] = useState<JamMinimalParticipant[]>([]);
  const [selectedTeam, setSelecteTeam] = useState<OptionDefinition | null>();
  const [selectedParticipant, setSelectedParticipant] = useState<OptionDefinition | null>();

  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [teamError, setTeamError] = useState('');
  const [participantError, setParticipantError] = useState('');

  const secondsInNumber = seconds ? parseInt(seconds, 10) * 1000 : 0;

  const teamsList = useMemo(() => {
    const teams = uniq(allParticipants.filter(({ teamName }) => teamName).map(({ teamName }) => teamName));
    return teams.map((team) => ({ label: team, value: team }));
  }, [allParticipants]);

  const participantsList = useMemo(() => {
    return allParticipants.map(({ nickname, login, teamName }) => ({
      label: `${nickname} (${t(i18nKeys.jamTeam.title)}: ${teamName})`,
      value: login,
    }));
  }, [allParticipants]);

  const validator = useMemo(() => {
    const fields: [FacilitatorMessagingFields, (error: string) => void][] = [
      [FacilitatorMessagingFields.TITLE, (error: string) => setTitleError(error)],
      [FacilitatorMessagingFields.MESSAGE, (error: string) => setMessageError(error)],
    ];
    if (recipient?.value === 'team') {
      fields.push([FacilitatorMessagingFields.TEAM_NAME, (error: string) => setTeamError(error)]);
    }
    if (recipient?.value === 'participant') {
      fields.push([FacilitatorMessagingFields.PARTICIPANT_LOGIN, (error: string) => setParticipantError(error)]);
    }
    return facilitatorMessagingValidator(
      { title, message, teamName: selectedTeam?.value, participantLogin: selectedParticipant?.value },
      {
        title: t(i18nKeys.facilitator.messaging.form.errors.title),
        message: t(i18nKeys.facilitator.messaging.form.errors.message),
        teamName: t(i18nKeys.facilitator.messaging.form.errors.teamName),
        participantLogin: t(i18nKeys.facilitator.messaging.form.errors.participantLogin),
      },
      new Map<FacilitatorMessagingFields, (error: string) => void>(fields)
    );
  }, [title, message, selectedParticipant, selectedTeam, messageType, recipient]);

  const getMessageType = () => {
    let type = messageType?.value;
    const emailNotif = notificationType === 'email';
    if (type === 'info' && emailNotif) {
      type = 'info_with_email';
    } else if (type === 'warning' && emailNotif) {
      type = 'warning_with_email';
    }
    return type;
  };

  const loadParticipants = async () => {
    if (!eventName) {
      return;
    }
    const response = await jamFacilitatorApi.getEventParticipants(eventName);
    setAllParticipants(response.participants);
  };

  const onSend = () => {
    clearFlashbars();
    if (!validator.isValidSection(true)) {
      return;
    }
    if (recipient?.value === 'event') {
      setShowConfirmation(true);
      return;
    }
    void sendMessage();
  };

  const sendMessage = async () => {
    if (!event || !validator.isValidSection(true) || !recipient) {
      return;
    }
    try {
      closeConfirmation();
      setSending(true);
      const notificationData = {
        notificationMessage: NotificationMessage.fromPlainObject({
          title: encodeURIComponent(title),
          message: encodeURIComponent(message),
          type: getMessageType(),
        }),
        options: NotificationOptions.fromPlainObject({ timeOut: autoDismiss ? secondsInNumber : 0 }),
      };

      switch (recipient.value) {
        case 'event':
          await jamMessageApi.sendMessageToEvent({
            eventName,
            notificationData,
          });
          break;
        case 'team':
          await jamMessageApi.sendMessageToTeam({
            eventName,
            notificationData,
            teamName: selectedTeam?.value,
          });
          break;
        case 'participant':
          await jamMessageApi.sendMessageToParticipant({
            eventName,
            notificationData,
            participantLogin: selectedParticipant?.value,
          });
          break;
      }
    } catch (e) {
      // error handled in API call already
    }
    setSending(false);
  };

  const closeConfirmation = () => {
    setShowConfirmation(false);
  };

  const onResetForm = () => {
    setAutoDismiss(false);
    setRecipient(recipientOptions[0]);
    setMessageType(messageTypeOptions[0]);
    setTitle('');
    setSeconds('');
    setMessage('');
    setNotificationType('message');
    setSelecteTeam(null);
    setSelectedParticipant(null);
  };

  const onPreviewNotification = () => {
    clearFlashbars();
    if (!validator.isValidSection(true)) {
      return;
    }
    const type = messageType?.value === 'info' ? FlashbarType.INFO : FlashbarType.WARNING;
    addFlashbar({
      id: `${new Date().getTime()}`,
      header: title,
      content: message,
      i18nKeys: [''],
      type,
      dismissible: true,
      autoDismiss,
      dismissDuration: secondsInNumber,
    });
  };

  useEffect(() => {
    if (!autoDismiss) {
      setSeconds(defaultAutoDismissSeconds);
    }
  }, [autoDismiss]);

  useEffect(() => {
    setSelecteTeam(null);
    setSelectedParticipant(null);
    void loadParticipants();
  }, [recipient]);

  return (
    <div className="facilitator-messaging">
      <ContentLayout header={<Header variant="h1">{t(i18nKeys.facilitator.messaging.title)}</Header>}>
        <Container>
          <ColumnLayout columns={2}>
            <FormField>
              <RadioGroup
                onChange={({ detail }) => setNotificationType(detail.value as 'message' | 'email')}
                value={notificationType}
                items={[
                  { value: 'message', label: t(i18nKeys.facilitator.messaging.form.fields.sendMessageNotif) },
                  { value: 'email', label: t(i18nKeys.facilitator.messaging.form.fields.sendEmailNotif) },
                ]}
              />
            </FormField>
            <div />
            <SpaceBetween size="xs">
              <FormField label={t(i18nKeys.facilitator.messaging.form.fields.recipient)}>
                <Select
                  selectedOption={recipient}
                  options={recipientOptions}
                  onChange={({ detail }) => setRecipient(detail.selectedOption)}
                  placeholder={t(i18nKeys.general.select)}
                />
              </FormField>
              {recipient?.value === 'team' && (
                <FormField errorText={teamError} label={t(i18nKeys.facilitator.messaging.form.fields.teamName)}>
                  <Select
                    selectedOption={selectedTeam || null}
                    options={teamsList}
                    onChange={({ detail }) => setSelecteTeam(detail.selectedOption)}
                    placeholder={t(i18nKeys.general.select)}
                  />
                </FormField>
              )}
              {recipient?.value === 'participant' && (
                <FormField
                  errorText={participantError}
                  label={t(i18nKeys.facilitator.messaging.form.fields.participant)}>
                  <Select
                    selectedOption={selectedParticipant || null}
                    options={participantsList}
                    onChange={({ detail }) => setSelectedParticipant(detail.selectedOption)}
                  />
                </FormField>
              )}
            </SpaceBetween>
            <FormField label={t(i18nKeys.facilitator.messaging.form.fields.messageType)}>
              <Select
                selectedOption={messageType}
                options={messageTypeOptions}
                onChange={({ detail }) => setMessageType(detail.selectedOption)}
              />
            </FormField>
            <FormField errorText={titleError} label={t(i18nKeys.facilitator.messaging.form.fields.title)}>
              <Input id="broadcastTitleTextBox" value={title} onChange={({ detail }) => setTitle(detail.value)} />
            </FormField>
            <FormField>
              <Checkbox
                disabled={notificationType === 'email'}
                onChange={({ detail }) => setAutoDismiss(detail.checked)}
                checked={autoDismiss}>
                {t(i18nKeys.facilitator.messaging.form.fields.autoDismiss)}
              </Checkbox>

              <div className="dismiss-seconds">
                <Input
                  ariaLabel={t(i18nKeys.facilitator.messaging.form.fields.autoDismiss)}
                  disabled={!autoDismiss || notificationType === 'email'}
                  type="number"
                  value={seconds}
                  onChange={({ detail }) => setSeconds(detail.value)}
                />{' '}
                {t(i18nKeys.facilitator.messaging.form.fields.seconds)}
              </div>
            </FormField>
            <FormField
              className="awsui_root_qk1j1_1xe70_93"
              errorText={messageError}
              label={t(i18nKeys.facilitator.messaging.form.fields.message)}>
              <Textarea
                id="broadcastMessageTextBox"
                ariaLabel={t(i18nKeys.facilitator.messaging.form.fields.message)}
                value={message}
                onChange={({ detail }) => setMessage(detail.value)}
              />
            </FormField>
          </ColumnLayout>
          <Box margin={{ top: 'l', bottom: 'l' }}>
            <Divider />
          </Box>
          <div className="actions">
            <Button iconName="refresh" onClick={onResetForm}>
              {t(i18nKeys.facilitator.messaging.form.actions.reset)}
            </Button>
            <SpaceBetween direction="horizontal" size="s">
              <Button disabled={sending} onClick={onPreviewNotification}>
                {t(i18nKeys.facilitator.messaging.form.actions.preview)}
              </Button>
              <div id="sendBroadCastMessageButton" data-testid="sendBroadCastMessageButton" onClick={onSend}>
                <Button variant="primary" disabled={sending} loading={sending}>
                  {t(
                    i18nKeys.facilitator.messaging.form.actions[
                      notificationType === 'message' ? 'sendMessage' : 'sendEmail'
                    ]
                  )}
                </Button>
              </div>
            </SpaceBetween>
          </div>
        </Container>
      </ContentLayout>
      <Modal
        onDismiss={closeConfirmation}
        visible={showConfirmation}
        header={<Header>{t(i18nKeys.facilitator.messaging.form.modal.sendConfirm.title)}</Header>}
        footer={
          <SpaceBetween direction="horizontal" size="s">
            <Button onClick={closeConfirmation}>{t(i18nKeys.general.cancel)}</Button>
            <Button onClick={() => void sendMessage()}>{t(i18nKeys.general.send)}</Button>
          </SpaceBetween>
        }>
        {t(i18nKeys.facilitator.messaging.form.modal.sendConfirm.caption)}
      </Modal>
    </div>
  );
};

export default FacilitatorMessaging;
