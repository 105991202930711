import React from 'react';
import { last } from 'lodash';
import { Box, Button, Popover } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { GetChallengeListItemFromChallengeId } from '@/src/store/challenge.context';
import { getValidatedFormattedDate } from '@/src/utils/time.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { ChallengeDescriptor, ChallengeListItem } from '@/src/types/Challenge';
import { DiffChange } from '@/src/types/Diff';
import { ChangeWithRevisionNumber } from './Revisions';
import { EventPermission } from '@/src/types/Event';

const propertyMap = {
  maxClues: i18nKeys.events.eventDetails.labels.prizes.maxClues,
  challengeId: i18nKeys.challenges.importChallenges.importTeamProperties.modal.labels.challengeId,
  labProvider: i18nKeys.challenges.challengeSelection.advancedSettings.labels.labProvider,
  defaultLabProvider: i18nKeys.challenges.challengeSelection.advancedSettings.labels.defaultLabProvider,
  clue1PenaltyPercent: i18nKeys.events.eventDetails.headers.scoring.clue1PenaltyPercent,
  clue2PenaltyPercent: i18nKeys.events.eventDetails.headers.scoring.clue2PenaltyPercent,
  clue3PenaltyPercent: i18nKeys.events.eventDetails.headers.scoring.clue3PenaltyPercent,
  startDate: i18nKeys.events.fields.startDate.title,
  endDate: i18nKeys.events.fields.endDate.title,
  timezone: i18nKeys.events.fields.timezone.title,
  title: i18nKeys.events.fields.title.title,
  description: i18nKeys.challenges.challengeDetails.titles.description,
  type: i18nKeys.challenges.challengeDetails.titles.type,
  audienceType: i18nKeys.challenges.challengeDetails.titles.audienceType,
  agreementId: i18nKeys.events.eventDetails.headers.agreementId,
  notes: i18nKeys.events.eventDetails.headers.notes.title,
  maxTeamSize: i18nKeys.events.eventDetails.labels.participants.maxTeamSizeHeader,
  minExpectedParticipants: i18nKeys.challenges.challengeDetails.titles.minExpectedParticipants,
  maxExpectedParticipants: i18nKeys.challenges.challengeDetails.titles.maxExpectedParticipants,
  teamAssignmentType: i18nKeys.challenges.challengeDetails.titles.teamAssignmentType,
  test: i18nKeys.general.test,
  testCloneSuffix: i18nKeys.challenges.challengeDetails.titles.testCloneSuffix,
  uiTheme: i18nKeys.challenges.challengeDetails.titles.uiTheme,
  challengeViewType: i18nKeys.challenges.challengeDetails.titles.challengeViewType,
  formTeamsMinsBeforeEventStart: i18nKeys.challenges.challengeDetails.titles.formTeamsMinsBeforeEventStart,
  teamRenameEnabled: i18nKeys.challenges.challengeDetails.titles.teamRenameEnabled,
  gamified: i18nKeys.events.eventDetails.labels.gamified,
  messagingEnabled: i18nKeys.challenges.challengeDetails.titles.messagingEnabled,
  teamChatEnabled: i18nKeys.challenges.challengeDetails.titles.teamChatEnabled,
  email: i18nKeys.challenges.challengeDetails.table.titles.email,
  eventPermissionType: i18nKeys.events.eventDetails.headers.eventPermissionType,
  eventPermissions: i18nKeys.events.eventDetails.headers.eventPermissions,
  autoUnlockChallengesOffsetMinutes: i18nKeys.challenges.challengeDetails.titles.autoUnlockChallengesOffsetMinutes,
  autoApprovedDate: i18nKeys.challenges.challengeDetails.titles.autoApprovedDate,
};

export interface RevisionChangeColumnProps {
  revisionTypeExpanded: number[];
  getChallengeListItemFromChallengeId: GetChallengeListItemFromChallengeId;
  toggleRevisionTypeExpanded: (index: number) => void;
  item: ChangeWithRevisionNumber;
  challengeDescriptors: ChallengeDescriptor[];
  eventPermissions: EventPermission[];
}

const RevisionChangeColumn: React.FC<RevisionChangeColumnProps> = ({
  item,
  revisionTypeExpanded,
  getChallengeListItemFromChallengeId,
  toggleRevisionTypeExpanded,
  challengeDescriptors,
  eventPermissions,
}) => {
  const { t } = useTranslation();
  const isCollapsed = !revisionTypeExpanded.includes(item.revisionNumber);
  const uniqChanges = item.changes.filter((change) => change.previousValue !== change.updatedValue);
  const length = uniqChanges.length;

  const renderChange = (change: DiffChange) => {
    let propertyType: 'challenge' | 'eventPermission' | undefined;
    let challenge: ChallengeListItem | undefined;
    let subLabel: string | undefined;
    const labelProperty = last<string>(change.property.split('.'));

    if (change.property.includes('challengeDescriptors')) {
      const index = change.property.split('.')[1];
      const challengeDescriptor = challengeDescriptors.at(parseInt(index, 10));
      if (challengeDescriptor?.challengeId) {
        challenge = getChallengeListItemFromChallengeId(challengeDescriptor.challengeId) || undefined;
        if (challenge) {
          propertyType = 'challenge';
        } else {
          return null;
        }
      }
    } else if (change.property.includes('eventPermissions')) {
      if (labelProperty !== 'email') {
        const index = change.property.split('.')[1];
        const eventPermission = eventPermissions.at(parseInt(index, 10));
        subLabel = eventPermission?.email;
      }
      propertyType = 'eventPermission';
    }

    const key = propertyMap[labelProperty as keyof typeof propertyMap];
    const label = key ? t(key) : change.property;
    let mainLabel = propertyType === 'challenge' ? challenge?.props.title : '';
    if (propertyType === 'eventPermission') {
      mainLabel = t(propertyMap.eventPermissions);
    }

    return (
      <Box key={change.property} margin={{ bottom: 'xxs' }}>
        <Popover
          content={
            <div style={{ minWidth: 150 }}>
              <Box>
                {!!mainLabel && (
                  <Box variant="h4" margin={{ bottom: 's' }}>
                    {label}
                  </Box>
                )}
                {!!subLabel && (
                  <>
                    <Box fontWeight="bold">User</Box>
                    <Box margin={{ bottom: 'xxs' }}>{subLabel}</Box>
                  </>
                )}
                <Box fontWeight="bold">{t(i18nKeys.changeRequest.fields.oldValue.title)}</Box>
                <Box>{getValidatedFormattedDate(change.previousValue as string) || '-'}</Box>
                <Box fontWeight="bold" margin={{ top: 'xxs' }}>
                  {t(i18nKeys.changeRequest.fields.newValue.title)}
                </Box>
                <Box>{getValidatedFormattedDate(change.updatedValue as string) || '-'}</Box>
              </Box>
            </div>
          }>
          {mainLabel || label}
        </Popover>
      </Box>
    );
  };

  return (
    <div className="revision-changes__list">
      {uniqChanges.slice(0, !isCollapsed ? undefined : 1).map(renderChange)}
      {length > 1 && (
        <div className="revision-changes__toggle-button">
          <Button variant="link" onClick={() => toggleRevisionTypeExpanded(item.revisionNumber)}>
            {isCollapsed
              ? t(i18nKeys.revisions.publishedRevisions.countMore, { count: uniqChanges.length - 1 })
              : t(i18nKeys.revisions.publishedRevisions.collapse)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default RevisionChangeColumn;
