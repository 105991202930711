import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CollectionPreferencesProps, ColumnLayout, TableProps } from '@amzn/awsui-components-react';

// components
import { LoadingBar } from '@/src/components/common/LoadingBar';
import { CustomTable } from '@/src/components/event-templates/CustomTable';

// types
import { ChallengeListItem } from '@/src/types/Challenge';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';

// table-config
import {
  collectionPreferencesProps,
  columnDefinitions,
  paginationLabels,
} from '@/src/components/event-templates/EventTemplateCreate/Sections/SelectChallenges/table-config';

interface IEventDetailChallengeTable {
  challenges: ChallengeListItem[];
  eventTemplateChallengePreferences: CollectionPreferencesProps.Preferences;
  toggleChallengeDetailsInfo: (challenge: ChallengeListItem) => void;
}

const { challengeProps, common } = i18nKeys.eventTemplates.tabs;
const { headerTitle, filterPlaceholder } = challengeProps;
const { headerRightContentButton } = common;

const EventDetailChallengeTable: React.FC<IEventDetailChallengeTable> = ({
  challenges,
  eventTemplateChallengePreferences,
  toggleChallengeDetailsInfo,
}) => {
  const { t } = useTranslation();

  if (!challenges.length) {
    return <LoadingBar />;
  }

  const props = {
    data: challenges || [],
    columnDefinitions: columnDefinitions(
      toggleChallengeDetailsInfo,
      t
    ) as TableProps.ColumnDefinition<ChallengeListItem>[],
    collectionPreferencesProps: collectionPreferencesProps(toggleChallengeDetailsInfo, t),
    headerTitle: t(headerTitle),
    filterPlaceholder: t(filterPlaceholder),
    headerRightContent: (
      <div style={{ width: 250 }} id="header-right-content-button" data-testid="header-right-content-button">
        <Button variant="primary">{t(headerRightContentButton)}</Button>
      </div>
    ),
    paginationLabels,
  };

  return (
    <ColumnLayout variant="text-grid">
      <CustomTable
        {...props}
        noAction
        variant="borderless"
        selectedChallegeItems={[]}
        preferences={eventTemplateChallengePreferences}
      />
    </ColumnLayout>
  );
};

export default EventDetailChallengeTable;
