import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Popover,
  RadioGroup,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { FrequencyUnit, NullableString, RequestType } from '../../../types/common';
import { EditUsagePlanActions, usePlans } from '../../../store/usage-plan/usage-plan.context';
import { DEFAULT_REQUEST_FREQUENCY, RequestFrequency, UsagePlan } from '../../../types/usage-plan/UsagePlan';

interface CreateUsagePlanRequestDetailsProps {
  target: UsagePlan | undefined;
  errorText: string;
}

const CreateUsagePlanRequestDetails: React.FC<CreateUsagePlanRequestDetailsProps> = ({ target, errorText }) => {
  const specificDurationLabel = 'specific';

  const { t } = useTranslation();
  const { handleUpdateUsagePlan } = usePlans();
  const [reqType, setReqType] = useState<RequestType | null>(target?.requestType || null);
  const [reqTypeErr, setReqTypeErr] = useState('');
  const [minDays, setMinDays] = useState((target?.minDaysBeforeEvent ?? '').toString());
  const [minDaysErr, setMinDaysErr] = useState('');
  const [selectedDuration, setSelectedDuration] = useState('');
  const [durationErr, setDurationErr] = useState('');
  const [specificDuration, setSpecificDuration] = useState<NullableString>(null);
  const [reqFreq, setReqFreq] = useState<RequestFrequency>(target?.requestFrequency ?? DEFAULT_REQUEST_FREQUENCY);
  const [reqFreqErr, setReqFreqErr] = useState('');
  const [durationInputInvalid, setDurationInputInvalid] = useState(false);

  const requestTypeItems = [
    { label: t(i18nKeys.events.event), value: RequestType.EVENT },
    { label: t(i18nKeys.events.eventDetails.labels.campaign), value: RequestType.CAMPAIGN },
  ];

  const durationItems = [
    { label: t(i18nKeys.events.eventDetails.labels.durationItems.fourHours), value: '4' },
    { label: t(i18nKeys.events.eventDetails.labels.durationItems.eightHours), value: '8' },
    { label: t(i18nKeys.events.eventDetails.labels.durationItems.oneDay), value: '24' },
    { label: t(i18nKeys.events.eventDetails.labels.durationItems.threeDays), value: '72' },
    { label: t(i18nKeys.events.eventDetails.labels.durationItems.oneWeek), value: '168' },
    { label: t(i18nKeys.events.eventDetails.labels.durationItems.oneMonth), value: '720' },
  ];

  const frequencyItems = [
    { label: t(i18nKeys.general.day), value: FrequencyUnit.DAY },
    { label: t(i18nKeys.general.week), value: FrequencyUnit.WEEK },
    { label: t(i18nKeys.general.month), value: FrequencyUnit.MONTH },
    { label: t(i18nKeys.general.year), value: FrequencyUnit.YEAR },
  ];

  useEffect(() => {
    // if this string is set, it means the usage plan failed validation on save in the parent component
    // in that case, re-validate the inputs on this component and update the UI with individual error strings if applicable
    if (errorText) {
      onRequestTypeChange(reqType);
      onMinDaysChange(minDays);
      onRequestFrequencyChange(reqFreq);
      updateDuration();
    }
  }, [errorText]);

  const onRequestTypeChange = (requestType: RequestType | null) => {
    setReqType(requestType);
    setReqTypeErr(handleUpdateUsagePlan(EditUsagePlanActions.REQUEST_TYPE, requestType));
  };

  const onMinDaysChange = (days: string) => {
    setMinDays(days);
    setMinDaysErr(handleUpdateUsagePlan(EditUsagePlanActions.MIN_DAYS_BEFORE_EVENT, Number(days)));
  };

  const onRequestFrequencyChange = (freq: RequestFrequency) => {
    setReqFreq(freq);
    setReqFreqErr(handleUpdateUsagePlan(EditUsagePlanActions.REQUEST_FREQUENCY, freq));
  };

  const updateDuration = () => {
    let hours;
    if (selectedDuration === specificDurationLabel) {
      hours = parseInt(specificDuration || '0', 10);
      if (isNaN(hours)) {
        setDurationInputInvalid(true);
        return;
      }
    } else {
      hours = Number(selectedDuration);
    }
    setDurationInputInvalid(false);
    setDurationErr(handleUpdateUsagePlan(EditUsagePlanActions.EVENT_DURATION, hours));
  };

  useEffect(() => {
    updateDuration();
  }, [selectedDuration, specificDuration]);

  useEffect(() => {
    // set up the duration selection if the plan has an existing field
    if (target?.eventDuration) {
      const duration = durationItems.find((d) => d.value === (target.eventDuration ?? '').toString());
      if (duration) {
        setSelectedDuration(duration.value);
      } else {
        setSpecificDuration(target.eventDuration.toString());
        setSelectedDuration(specificDurationLabel);
      }
    }
  }, []);

  return (
    <Container header={<Header variant={'h2'}>{t(i18nKeys.usagePlan.sections.request)}</Header>}>
      <SpaceBetween direction={'vertical'} size={'l'}>
        {/* Request type */}
        <FormField errorText={reqTypeErr} label={t(i18nKeys.usagePlan.fields.requestType.title)}>
          <Box color="text-status-info">
            <Popover
              dismissAriaLabel="Close"
              header={t(i18nKeys.usagePlan.fields.requestType.infoTitle)}
              content={t(i18nKeys.usagePlan.fields.requestType.infoDetail)}>
              <StatusIndicator type="info">{t(i18nKeys.usagePlan.fields.requestType.info)}</StatusIndicator>
            </Popover>
          </Box>
          <RadioGroup
            value={reqType}
            onChange={({ detail }) => onRequestTypeChange(detail.value as RequestType)}
            items={requestTypeItems}
            ariaRequired
          />
        </FormField>

        {/* Number of days for approval */}
        {/* This field should only be used for event request types */}
        {target && target.requestType === RequestType.EVENT && (
          <FormField
            errorText={minDaysErr}
            label={t(i18nKeys.usagePlan.fields.minDaysBeforeEvent.title)}
            description={t(i18nKeys.usagePlan.fields.minDaysBeforeEvent.description)}>
            <Input
              inputMode="numeric"
              type="number"
              placeholder={t(i18nKeys.general.numPlaceholder)}
              value={minDays}
              onChange={({ detail }) => onMinDaysChange(detail.value)}
              className="numeric-input"
              ariaRequired
            />{' '}
            {t(i18nKeys.general.days)}
          </FormField>
        )}

        {/* Duration */}
        {/* This field should only be used for event request types */}
        {target && target.requestType === RequestType.EVENT && (
          <FormField
            errorText={
              selectedDuration === specificDurationLabel && specificDuration != null && durationInputInvalid
                ? t(i18nKeys.errors.validation.dayHourError)
                : durationErr
            }
            label={t(i18nKeys.usagePlan.fields.duration.title)}>
            <RadioGroup
              value={selectedDuration}
              onChange={({ detail }) => setSelectedDuration(detail.value)}
              items={[
                {
                  label: (
                    <div style={{ marginTop: '-6px' }}>
                      {t(i18nKeys.events.eventDetails.labels.specific)}{' '}
                      <Input
                        type="text"
                        placeholder={t(i18nKeys.usagePlan.fields.minDaysBeforeEvent.placeholder)}
                        value={specificDuration || ''}
                        onChange={({ detail }) => {
                          setSpecificDuration(detail.value);
                        }}
                        disabled={selectedDuration !== specificDurationLabel}
                        className={'inline'}
                      />{' '}
                    </div>
                  ),
                  value: specificDurationLabel,
                },
                ...durationItems,
              ]}
              ariaRequired
            />
          </FormField>
        )}

        {/* Request limits */}
        <FormField
          errorText={reqFreqErr}
          stretch
          label={t(i18nKeys.usagePlan.fields.requestLimit.title)}
          description={t(i18nKeys.usagePlan.fields.requestLimit.description)}
          constraintText={t(i18nKeys.usagePlan.fields.requestLimit.example)}>
          <ColumnLayout columns={4}>
            <div>
              <Input
                placeholder={t(i18nKeys.general.numPlaceholder)}
                inputMode="numeric"
                type="number"
                value={reqFreq.frequency.toString()}
                onChange={({ detail }) => {
                  const freq = { frequency: Number(detail.value), unit: reqFreq.unit };
                  onRequestFrequencyChange(freq);
                }}
                className="numeric-input"
                ariaRequired
              />{' '}
              {t(i18nKeys.usagePlan.fields.requestLimit.requests)}
            </div>
            <div>
              {t(i18nKeys.usagePlan.fields.requestLimit.per)}
              <RadioGroup
                value={reqFreq.unit}
                onChange={({ detail }) => {
                  const freq = { frequency: reqFreq.frequency, unit: detail.value as FrequencyUnit };
                  onRequestFrequencyChange(freq);
                }}
                items={frequencyItems}
                ariaRequired
              />
            </div>
          </ColumnLayout>
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default CreateUsagePlanRequestDetails;
