import { Grid } from '@amzn/awsui-components-react';
import { TFunctionResult } from 'i18next';
import React from 'react';
interface ReviewRowProps {
  label: string;
  value: string | number | undefined | null | TFunctionResult;
}

const ReviewRow: React.FC<ReviewRowProps> = ({ label, value }) => {
  return (
    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
      <div className="secondary-text">{label}</div>
      <div>{value}</div>
    </Grid>
  );
};
export default ReviewRow;
