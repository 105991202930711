import {
  Cards,
  Checkbox,
  Container,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent, EventEdit } from '../../../../store/edit-event.context';
import { Event, TeamAssignmentType } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { KeyValue } from '../../../common/KeyValue';
import { EventFields, eventTeamSizeValidator } from '@/src/utils/event.validation.utils';
import { Validator } from '@/src/utils/validation.utils';

interface TeamSettingsProps {
  target: Event | undefined;
  validationHandler?: (validateSection: () => Promise<boolean>) => void;
  isAdvancedSettings?: boolean;
}

const TeamSettings: React.FC<TeamSettingsProps> = ({ target, validationHandler, isAdvancedSettings }) => {
  const { t } = useTranslation();
  const eventTeamTypes = {
    SELF_FORMING: {
      name: t(i18nKeys.events.eventDetails.labels.teamSettings.teamTypes.selfForming.name),
      description: t(i18nKeys.events.eventDetails.labels.teamSettings.teamTypes.selfForming.description),
      value: 'SELF_FORMING',
    },
    SKILL_BASED: {
      name: t(i18nKeys.events.eventDetails.labels.teamSettings.teamTypes.skillBased.name),
      description: t(i18nKeys.events.eventDetails.labels.teamSettings.teamTypes.skillBased.description),
      value: 'SKILL_BASED',
    },
    PRE_CREATED: {
      name: t(i18nKeys.events.eventDetails.labels.teamSettings.teamTypes.preCreated.name),
      description: t(i18nKeys.events.eventDetails.labels.teamSettings.teamTypes.preCreated.description),
      value: 'PRE_CREATED',
    },
  };
  const { editMode, newEventMode, isGuestUserAndEditModeEnabled, handleUpdateEditEvent, bulkHandleUpdateEditEvent } =
    useEditEvent();
  const TeamAssignmentTypeDictionary: { [key in TeamAssignmentType]: string } = {
    [TeamAssignmentType.PRE_CREATED]: t(i18nKeys.events.eventDetails.labels.preCreated),
    [TeamAssignmentType.SELF_FORMING]: t(i18nKeys.events.eventDetails.labels.selfForming),
    [TeamAssignmentType.SKILL_BASED]: t(i18nKeys.events.eventDetails.labels.skillBased),
  };
  const [teamRenameEnabled, setTeamRenameEnabled] = useState(false);
  const defaultTeamMins = '90';
  const [formTeamsMinsBeforeEventStart, setFormTeamsMinsBeforeEventStart] = useState(defaultTeamMins);
  const [formTeamsMinsBeforeEventStartError, SetFormTeamsMinsBeforeEventStartError] = useState('');
  const [teamType, setTeamTypes] = useState<{
    name: string;
    description: string;
    value: string;
    footer?: string;
  }>(eventTeamTypes.SELF_FORMING);
  const [maxTeamSize, setMaxTeamSize] = useState(target?.maxTeamSize.toString() || '');

  const canEdit = editMode || newEventMode;

  const eventTeamSizeValidation: Validator<EventFields> = useMemo(
    () =>
      eventTeamSizeValidator(
        formTeamsMinsBeforeEventStart,
        t(i18nKeys.newEvent.errors.formTeamsMinsBeforeEventStart),
        new Map<EventFields, (error: string) => void>([
          [EventFields.TEAMSIZE, (error: string) => SetFormTeamsMinsBeforeEventStartError(error)],
        ])
      ),
    [formTeamsMinsBeforeEventStart, eventTeamSizeValidator]
  );

  if (validationHandler) {
    validationHandler(() => {
      const eventTeamSizeValidationResult = eventTeamSizeValidation.isValidSection(true);
      if (eventTeamSizeValidationResult) {
        return Promise.resolve(eventTeamSizeValidationResult);
      } else {
        return Promise.reject(eventTeamSizeValidationResult);
      }
    });
  }

  useEffect(() => {
    if (newEventMode) {
      const teamAssignmentType = target?.teamAssignmentType != null ? target.teamAssignmentType : teamType.value;
      const mins =
        target?.formTeamsMinsBeforeEventStart != null
          ? target.formTeamsMinsBeforeEventStart.toString()
          : formTeamsMinsBeforeEventStart;

      const edits: EventEdit[] = [];
      edits.push({ action: EditEventActions.TEAM_TYPE, payload: teamAssignmentType });
      edits.push({ action: EditEventActions.TEAM_FORM_MINUTES_BEFORE_START, payload: mins });
      bulkHandleUpdateEditEvent(edits);

      setTeamTypes(eventTeamTypes[teamAssignmentType as keyof typeof eventTeamTypes]);
      setFormTeamsMinsBeforeEventStart(mins);
    }
  }, []);

  useEffect(() => {
    if (target) {
      setTeamRenameEnabled(target?.teamRenameEnabled);
      setFormTeamsMinsBeforeEventStart(target?.formTeamsMinsBeforeEventStart?.toString() || defaultTeamMins);
      if (target?.teamAssignmentType) setTeamTypes(eventTeamTypes[target?.teamAssignmentType]);
    }
  }, [editMode, newEventMode]);

  useEffect(() => {
    setMaxTeamSize(target?.maxTeamSize?.toString() || '')
  }, [target?.maxTeamSize]);

  const handleSelectedItems = (itemsSelected: { name: string; description: string; value: string }[]) => {
    setTeamTypes(itemsSelected[0]);
    handleUpdateEditEvent(EditEventActions.TEAM_TYPE, itemsSelected[0].value);
  };

  const handleFormTeamsMinutesBeforeEventStartChange = (minutes: string) => {
    if (Number(minutes) < 0) {
      return;
    }
    setFormTeamsMinsBeforeEventStart(minutes);
    handleUpdateEditEvent(EditEventActions.TEAM_FORM_MINUTES_BEFORE_START, minutes);
  };

  const handleTeamRenameEnabledCheck = (checked: boolean) => {
    setTeamRenameEnabled(checked);
    handleUpdateEditEvent(EditEventActions.TEAM_RENAME, checked);
  };

  const handleMaxTeamSizeChange = (size: string) => {
    setMaxTeamSize(size);
    handleUpdateEditEvent(EditEventActions.MAX_TEAM_SIZE, size);
  };

  const renderContent = () => {
    return !canEdit ? (
      <React.Fragment>
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.teamAssignment)}</div>
            {target?.teamAssignmentType && <div>{TeamAssignmentTypeDictionary[target?.teamAssignmentType]}</div>}
          </Grid>
        </div>
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
            <div className="secondary-text">{t(i18nKeys.events.fields.teamSize.label)}</div>
            <div> {maxTeamSize} </div>
          </Grid>
        </div>
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.teamFormingStartTime)}</div>
            <div>
              {target?.formTeamsMinsBeforeEventStart
                ? t(i18nKeys.events.eventDetails.labels.minutesBeforeEvent, {
                  minutes: target?.formTeamsMinsBeforeEventStart,
                })
                : t(i18nKeys.general.nA)}
            </div>
          </Grid>
        </div>
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.allowTeamOwnerToRenameTeam)}</div>
            <div>{target?.teamRenameEnabled ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
          </Grid>
        </div>
      </React.Fragment>
    ) : (
      canEdit && (
        <Grid gridDefinition={[{ colspan: isAdvancedSettings ? 12 : 8 }]}>
          <div>
            <KeyValue
              label={
                <h3 style={{ display: 'inline-block' }}>
                  {t(i18nKeys.events.eventDetails.labels.teamSettings.teamTypes.name)}
                </h3>
              }
              required>
              <Cards
                selectionType="single"
                trackBy="value"
                selectedItems={[teamType]}
                data-testid="teamTypeCards"
                ariaLabels={{
                  itemSelectionLabel: (e, n) => `select ${n.name}`,
                  selectionGroupLabel: t(i18nKeys.events.eventDetails.labels.eventTeamSelection),
                }}
                onSelectionChange={({ detail }) => handleSelectedItems(detail.selectedItems)}
                cardDefinition={{
                  header: (e) => <b>{e.name}</b>,
                  sections: [
                    {
                      id: 'description',
                      content: (e) => <span className="secondary-text">{e.description}</span>,
                    },
                  ],
                }}
                items={[eventTeamTypes.SELF_FORMING, eventTeamTypes.SKILL_BASED, eventTeamTypes.PRE_CREATED]}
                cardsPerRow={[{ cards: isAdvancedSettings ? 3 : 1 }]}
                isItemDisabled={() => !!isGuestUserAndEditModeEnabled && !isAdvancedSettings}
              />
            </KeyValue>
            <KeyValue
              label={
                <h3 style={{ display: 'inline-block' }}>
                  {t(i18nKeys.events.fields.teamSize.label)}
                </h3>
              }
              info={t(i18nKeys.events.eventDetails.labels.participants.maxTeamSizeInfo)}
              required>
              {/* Under development !!! */}
              <FormField errorText={ '' }>
                <Input
                  inputMode="numeric"
                  value={maxTeamSize}
                  data-testid="maxTeamSizeInput"
                  onChange={({ detail }) => handleMaxTeamSizeChange(detail.value)}
                  type="number"
                  className="numeric-input"
                  ariaLabel={t(i18nKeys.events.eventDetails.labels.numericInputTeamSize)}
                  step={1}
                  onBlur={() => eventTeamSizeValidation.isValidField(EventFields.TEAMSIZE)}
                />{' '}
                {t(i18nKeys.events.eventDetails.labels.participants.maxTeamSize)}
              </FormField>
            </KeyValue>
            <KeyValue
              label={
                <h3 style={{ display: 'inline-block' }}>
                  {t(i18nKeys.events.eventDetails.labels.teamSettings.teamFormingStartTime.name)}
                </h3>
              }
              required>
              <FormField errorText={formTeamsMinsBeforeEventStartError}>
                <b>{t(i18nKeys.events.eventDetails.labels.teamSettings.teamFormingStartTime.labelPart1)}</b>
                <Input
                  type="number"
                  inputMode="numeric"
                  className="numeric-long-input"
                  placeholder="mm"
                  data-testid="teamFormingStartTimeInput"
                  onChange={({ detail }) => handleFormTeamsMinutesBeforeEventStartChange(detail.value)}
                  onBlur={() => eventTeamSizeValidation.isValidField(EventFields.TEAMSIZE)}
                  value={formTeamsMinsBeforeEventStart}
                  disabled={isGuestUserAndEditModeEnabled && !isAdvancedSettings}
                />
                <b>{t(i18nKeys.events.eventDetails.labels.teamSettings.teamFormingStartTime.labelPart2)}</b>
              </FormField>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.teamSettings.teamFormingStartTime.description)}
              </div>
            </KeyValue>
            <KeyValue
              label={
                <h3 style={{ display: 'inline-block' }}>
                  {t(i18nKeys.events.eventDetails.labels.teamSettings.teamRename.name)}
                </h3>
              }>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.teamSettings.teamRename.description)}
              </div>
              <Checkbox
                checked={teamRenameEnabled}
                onChange={({ detail }) => {
                  handleTeamRenameEnabledCheck(detail.checked);
                }}
                data-testid="teamRenameEnabledCheckbox"
                disabled={isGuestUserAndEditModeEnabled && !isAdvancedSettings}>
                <b>{t(i18nKeys.events.eventDetails.labels.teamSettings.teamRename.inputLabel)}</b>
              </Checkbox>
            </KeyValue>
          </div>
        </Grid>
      )
    );
  };

  return !newEventMode ? (
    <ExpandableSection
      defaultExpanded
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.teamSettings)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.teamSettings)}</Header>}>
      {renderContent()}
    </Container>
  );
};

export default TeamSettings;