/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  ButtonDropdown,
  CollectionPreferencesProps,
  ExpandableSection,
  Icon,
  Input,
  Link,
  Modal,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { useEvents } from '../../../store/events.context';
import { useFlashbars } from '../../../store/flashbar.context';
import { Nullable, NullableString } from '../../../types/common';
import { DownloadParticipantActions, Event } from '../../../types/Event';
import { Team } from '../../../types/Team';
import { UserRow } from '../../../types/UserRow';
import { downloadObjectAsCsv, downloadObjectAsJson } from '../../../utils/download.utils';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { paginationLabels } from '../../../utils/table.utils';
import { ConfirmModal } from '../ConfirmModal';
import { HorizontalRule } from '../HorizontalRule';
import { KeyValue } from '../KeyValue';
import { TableEmptyState } from '../TableEmptyState';
import { COLUMN_DEFINITIONS, filteringFunction } from './participants-config-code';
import { TeamTable } from './TeamTable';
import { TimezoneFormat } from '../CommonModel';

interface ParticipantsProps {
  target: Event;
}

export const ParticipantsCode: React.FC<ParticipantsProps> = ({ target }) => {
  const { t } = useTranslation();
  const TEAM_OPTION_VALUE_NONE = '__NONE__';
  const noTeamOption: OptionDefinition = {
    label: t(i18nKeys.participants.labels.noTeam),
    value: TEAM_OPTION_VALUE_NONE,
  };
  const noOwnerOption: OptionDefinition = {
    label: t(i18nKeys.participants.labels.noOwner),
    value: TEAM_OPTION_VALUE_NONE,
  };
  const roleOptions: OptionDefinition[] = [
    { label: t(i18nKeys.participants.labels.allRoles), value: 'ALL' },
    { label: t(i18nKeys.participants.labels.facilitators), value: 'FACILITATORS' },
    { label: t(i18nKeys.participants.labels.participants), value: 'PARTICIPANTS' },
  ];
  const preferences: CollectionPreferencesProps.Preferences = { pageSize: 10, custom: TimezoneFormat.LOCAL };
  const [users, setUsers] = useState<UserRow[]>();
  const { teams, fetchUserEmail, emailCache, getEventByName } = useEvents();
  const { eventsApi, userApi, teamApi } = useApi();
  const { addWarningFlashbar, addErrorFlashbar } = useFlashbars();
  const [confirmResetPasswordVisible, setConfirmResetPasswordVisible] = useState(false);
  const [confirmPromoteUserToFacilitatorVisible, setConfirmPromoteUserToFacilitatorVisible] = useState(false);
  const [confirmDemoteUserVisible, setConfirmDemoteUserVisible] = useState(false);
  const [confirmDisableUserVisible, setConfirmDisableUserVisible] = useState(false);
  const [confirmEnableUserVisible, setConfirmEnableUserVisible] = useState(false);
  const [confirmAssignTeamVisible, setConfirmAssignTeamVisible] = useState(false);
  const [saveTeamAssignmentVisible, setSaveTeamAssignmentVisible] = useState(false);
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const [teamDetailsVisible, setTeamDetailsVisible] = useState(false);
  const [renameTeamAliasVisible, setRenameTeamAliasVisible] = useState(false);
  const [disableTeamVisible, setDisableTeamVisible] = useState(false);
  const [deleteTeamVisible, setDeleteTeamVisible] = useState(false);
  const [createTeamsVisible, setCreateTeamsVisible] = useState(false);
  const [createTeamVisible, setCreateTeamVisible] = useState(false);
  const [confirmCreateTeamVisible, setConfirmCreateTeamVisible] = useState(false);
  const [numberOfTeamsToCreate, setNumberOfTeamsToCreate] = useState(0);
  const [createTeamsPrefix, setCreateTeamsPrefix] = useState<NullableString>(null);
  const [newTeamAlias, setNewTeamAlias] = useState<NullableString>(null);
  const [selectedTeamOption, setSelectedTeamOption] = useState<OptionDefinition>(noTeamOption);
  const [selectedUser, setSelectedUser] = useState<Nullable<UserRow>>(null);
  const [selectedTeam, setSelectedTeam] = useState<Nullable<Team>>(null);
  const [teamSelectOptions, setTeamSelectOptions] = useState<OptionDefinition[]>([]);
  const [teamOwnerOptions, setTeamOwnerOptions] = useState<OptionDefinition[]>([]);
  const [teamOwnerOption, setTeamOwnerOption] = useState<OptionDefinition>(noOwnerOption);
  const [newTeamName, setNewTeamName] = useState<NullableString>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState<NullableString>(null);
  const [roleFilter, setRoleFilter] = useState<OptionDefinition>(roleOptions[0]);
  const [allUserRows, setAllUserRows] = useState<UserRow[]>([]);
  const [piiModalVisible, setPIIModalVisible] = useState(false);
  const [participantDataDownloadType, setParticipantDataDownloadType] = useState('');

  const DOWNLOAD_ALL_PARTICIPANT_DATA = 'ALL';
  const DOWNLOAD_SOLVED_CHALLENGE_PARTICIPANT_DATA = 'SOLVED_ONLY';

  /**
   * Idempotent method for updating the list of teams and users from this.event.
   *
   * If the teams or users did not change, then the lists are not updated.
   */
  const setTeamsFromEvent = (): void => {
    // if there is no event, set teams and users to empty and return
    if (!target) {
      setUsers([]);
      return;
    }

    const userRows: UserRow[] = getUserRowsFromEvent(target);
    setupTeamOwnerOptions();

    // if no teams on event, set them to event response teams
    if (target.teams.length < 1) {
      target.teams = teams || [];
    }

    setUsers(userRows);
    setAllUserRows(userRows);
  };

  /**
   * Get a list of all users for an event. Unassigned and assigned users.
   *
   * @param event
   */
  const getUserRowsFromEvent = (event: Event): UserRow[] => {
    return [...getUnassignedUserRows(event), ...getAssignedUserRows(event)];
  };

  /**
   * Get a list of assigned users for an event.
   *
   * @param event
   */
  const getAssignedUserRows = (_event: Event): UserRow[] => {
    return _.flatMap(teams || [], getTeamMemberUserRows);
  };

  /**
   * Get a list of assigned users for a team.
   *
   * @param team
   */
  const getTeamMemberUserRows = (team: Team): UserRow[] => {
    return (team.members || []).map((m) => UserRow.fromTeamMember(m, team));
  };

  /**
   * Get a list of unassigned participants for an event.
   *
   * @param event
   */
  const getUnassignedUserRows = (event: Event): UserRow[] => {
    return (event.unassignedParticipants || []).map((teamMember) => UserRow.fromUnassignedUser(teamMember));
  };

  const getUserById = (userId: string) => {
    return users?.find((userRow: UserRow) => userRow.userId === userId);
  };

  const getTeamOptionByName = (teamName: string, teamOptions: OptionDefinition[]) => {
    return teamOptions.find((teamOption) => teamOption.value === teamName);
  };

  const getTeamByName = (teamName: string) => {
    return teams?.find((team: Team) => {
      return team.name === teamName || team.teamLabel === teamName;
    });
  };

  const downloadParticipantSolvedExport = () => {
    setParticipantDataDownloadType(DOWNLOAD_SOLVED_CHALLENGE_PARTICIPANT_DATA);
    setPIIModalVisible(true);
  };

  const downloadAllParticipantEmails = () => {
    setParticipantDataDownloadType(DOWNLOAD_ALL_PARTICIPANT_DATA);
    setPIIModalVisible(true);
  };

  const cleanupModals = () => {
    setConfirmPromoteUserToFacilitatorVisible(false);
    setConfirmResetPasswordVisible(false);
  };

  const toggleResetPassword = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void handleCheckEmail(userRow);
    setConfirmResetPasswordVisible(true);
  };

  const toggleHandleDemoteUser = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void handleCheckEmail(userRow, false);
    setConfirmDemoteUserVisible(true);
  };

  const toggleHandlePromoteUser = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void handleCheckEmail(userRow);

    const { facilitatorDomainAllowlist: allowlist } = target;
    // validate the email is in the facilitator allowlist for this event.
    if (selectedUser && allowlist != null && allowlist.length > 0) {
      const endsWithSuffix = (value: string) => (suffix: string) => value.endsWith(suffix);
      const endsWithAtLeastOne = (value: string, list: string[]) => list.some(endsWithSuffix(value));

      if (selectedUser.email && !endsWithAtLeastOne(selectedUser.email, allowlist)) {
        addErrorFlashbar(t(i18nKeys.participants.warnings.notEligibleForFacilitator));
        cleanupModals();
        return;
      }
    }
    setConfirmPromoteUserToFacilitatorVisible(true);
  };

  //   const toggleUserDetails = (userRow: UserRow) => {
  //     setSelectedUser(userRow);
  //     void handleCheckEmail(userRow, false);
  //     setUserDetailsVisible(true);
  //   };

  const toggleDisableUser = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void handleCheckEmail(userRow, false);
    setConfirmDisableUserVisible(true);
  };

  const selectTeamName = (teamName: string) => {
    if (teamName) {
      const newSelectedTeam = getTeamByName(teamName);
      if (newSelectedTeam) {
        setSelectedTeam(newSelectedTeam);
      }
    }
  };

  const toggleTeamDetails = (teamName: string) => {
    setTeamDetailsVisible(true);
    selectTeamName(teamName);
  };

  const toggleEnableUser = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void handleCheckEmail(userRow, false);
    setConfirmEnableUserVisible(true);
  };

  const toggleAssignTeam = (userRow: UserRow) => {
    setSelectedUser(userRow);
    void handleCheckEmail(userRow, false);
    setSaveTeamAssignmentVisible(true);
    setupTeamSelectOptions(userRow);
  };

  const setupTeamOwnerOptions = () => {
    if (target) {
      const newUserOptions: OptionDefinition[] =
        target.unassignedParticipants
          ?.filter((teamMember) => !teamMember.facilitator)
          .map((teamMember) => {
            return {
              label: teamMember.nickname || '',
              value: teamMember.userId || '',
            };
          }) || [];
      sortAndAddDefaultSelection(newUserOptions, noOwnerOption, setTeamOwnerOptions);
    }
  };

  const setupTeamSelectOptions = (userRow: UserRow) => {
    if (!target || !teams) {
      return;
    }
    const newTeamSelectOptions: OptionDefinition[] = teams.map((team) => {
      const name = team.alias ? team.teamLabel : team.name;
      const remainingSpots = `${Math.max(0, target.maxTeamSize - (team.members || []).length)}/${target.maxTeamSize}${t(
        i18nKeys.participants.labels.spotsAvailable
      )}`;
      return {
        value: team.name as string,
        label: `${remainingSpots} :: ${name}` || '',
      };
    });

    sortAndAddDefaultSelection(newTeamSelectOptions, noTeamOption, setTeamSelectOptions);
    if (userRow) {
      const currentSelectedOption = userRow.teamName
        ? getTeamOptionByName(userRow.teamName, newTeamSelectOptions) || noTeamOption
        : noTeamOption;
      setSelectedTeamOption(currentSelectedOption);
    }
  };

  const sortAndAddDefaultSelection = (
    options: OptionDefinition[],
    noOptionPlaceholder: OptionDefinition,
    setSelection: any
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    options.sort((a, b) => (a.value! > b.value! ? 1 : -1));
    options.unshift(noOptionPlaceholder);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setSelection(options);
  };

  const handleSaveTeamAssignment = () => {
    setSaveTeamAssignmentVisible(false);
    setConfirmAssignTeamVisible(true);
  };

  const handleCreateTeams = async () => {
    if (isNaN(numberOfTeamsToCreate) || !numberOfTeamsToCreate || numberOfTeamsToCreate < 1) {
      return;
    }
    if (!createTeamsPrefix) {
      return;
    }
    await teamApi.bulkCreateTeam(target.name, numberOfTeamsToCreate, createTeamsPrefix).then(() => {
      getEventByName(target.name);
    });
    setNumberOfTeamsToCreate(0);
    setCreateTeamsPrefix(null);
    setCreateTeamsVisible(false);
  };

  const handleCreateTeam = () => {
    setCreateTeamVisible(false);
    setConfirmCreateTeamVisible(true);
  };

  const handleConfirmCreateTeam = async () => {
    const owner = teamOwnerOption.value ? getUserById(teamOwnerOption?.value) || null : null;
    if (newTeamName) {
      await teamApi.createTeam(target.name, newTeamName, newPassword, owner).then(() => {
        getEventByName(target.name);
      });
      setConfirmCreateTeamVisible(false);
      setNewPassword(null);
      setTeamOwnerOption(noOwnerOption);
      setNewTeamName(null);
    }
  };

  const handleConfirmSaveTeamAssignment = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname && selectedTeamOption.value) {
      const teamName = selectedTeamOption.value === noTeamOption.value ? null : selectedTeamOption.value;
      await userApi.assignUserToTeam(target.name, teamName, selectedUser).then(() => {
        getEventByName(target.name);
      });

      setSelectedUser(null);
      setSelectedTeamOption(noTeamOption);
      setConfirmAssignTeamVisible(false);
    }
  };

  const handleEnableUser = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname) {
      await userApi.enableUserAccount(target.name, selectedUser.userId, selectedUser.nickname).then(() => {
        getEventByName(target.name);
      });
      setConfirmEnableUserVisible(false);
      setSelectedUser(null);
    }
  };

  const handleDisableUser = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname) {
      await userApi.disableUserAccount(target.name, selectedUser.userId, selectedUser.nickname).then(() => {
        getEventByName(target.name);
      });

      setConfirmDisableUserVisible(false);
      setSelectedUser(null);
    }
  };

  const handleResetPassword = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname) {
      await userApi.resetUserPassword(target.name, selectedUser.userId, selectedUser.nickname).then(() => {
        setConfirmResetPasswordVisible(false);
        setSelectedUser(null);
      });
    }
  };

  const handleDemoteUser = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname) {
      await userApi.demoteUserToParticipant(target.name, selectedUser.userId, selectedUser.nickname).then(() => {
        getEventByName(target.name);
      });
      setConfirmDemoteUserVisible(false);
      setSelectedUser(null);
    }
  };

  const handlePromoteUser = async () => {
    if (selectedUser && selectedUser.userId && selectedUser.nickname) {
      await userApi.promoteUserToFacilitator(target.name, selectedUser.userId, selectedUser.nickname).then(() => {
        getEventByName(target.name);
      });
      setConfirmPromoteUserToFacilitatorVisible(false);
      setSelectedUser(null);
    }
  };

  const handleRenameTeamAlias = async () => {
    if (!_.isEmpty(newTeamAlias) && selectedTeam?.name) {
      await teamApi.changeTeamAlias(target.name, selectedTeam?.name, newTeamAlias || '').then(() => {
        getEventByName(target.name);
      });

      setSelectedTeam(null);
      setRenameTeamAliasVisible(false);
      setNewTeamAlias(null);
    }
  };

  const handleDisableTeam = async () => {
    if (selectedTeam && selectedTeam.name) {
      await teamApi.disableTeam(target.name, selectedTeam.name).then(() => {
        getEventByName(target.name);
      });

      setDisableTeamVisible(false);
      setSelectedTeam(null);
    }
  };

  const handleDeleteTeam = async () => {
    if (selectedTeam && selectedTeam.name) {
      await teamApi.deleteTeam(target.name, selectedTeam.name).then(() => {
        getEventByName(target.name);
      });
      setDeleteTeamVisible(false);
      setSelectedTeam(null);
    }
  };

  const handleCheckEmail = async (userRow: UserRow, throwIfNotVerified = true) => {
    if (userRow) {
      try {
        const email = await checkEmail(userRow, throwIfNotVerified);
        if (email) {
          userRow.email = email;
          setSelectedUser(_.cloneDeep(userRow));
        }
      } catch (err) {
        cleanupModals();
        setSelectedUser(null);
        return;
      }
    }
  };

  const checkEmail = async (userRow: UserRow, throwIfNotVerified = true) => {
    if (userRow && userRow.userId) {
      let userEmail: { email: string; isVerified: boolean } | null = emailCache[userRow.userId] || null;
      if (!userEmail || !userEmail.email || !userEmail.isVerified) {
        const revealedEmail = await fetchUserEmail(userRow.userId);
        if (revealedEmail) {
          userEmail = revealedEmail;
        }
      }
      if (userEmail) {
        const { email, isVerified } = userEmail;
        if (!email) {
          addErrorFlashbar(t(i18nKeys.participants.errors.fetchEmail));
          throw new Error('error with user email');
        }
        if (throwIfNotVerified && !isVerified) {
          addErrorFlashbar(t(i18nKeys.participants.errors.emailVerification));
          throw new Error('error with user verification');
        }
        return userEmail.email;
      } else {
        throw new Error('error with user email');
      }
    }
  };

  const handleRoleFilterChange = (selectedRole: OptionDefinition) => {
    setRoleFilter(selectedRole);
    let allUsers = _.cloneDeep(allUserRows);
    switch (selectedRole.value) {
      /**
       * roleOptions[0] is for all users
       */
      case roleOptions[0].value:
        break;
      /**
       * roleOptions[1] is for facilitators
       */
      case roleOptions[1].value:
        allUsers = allUsers.filter((userRow) => userRow.facilitator);
        break;
      /**
       * roleOptions[2] is for participants
       */
      case roleOptions[2].value:
        allUsers = allUsers.filter((userRow) => !userRow.facilitator);
        break;
    }
    setUsers(allUsers);
  };

  useEffect(() => {
    setTeamsFromEvent();
  }, [teams, target]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    users || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.participants.labels.empty.participants.title)}
            subtitle={t(i18nKeys.participants.labels.empty.participants.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const cancelPIIWarningModal = () => {
    setParticipantDataDownloadType('');
    setPIIModalVisible(false);
  };

  const exportData = () => {
    if (!target.name) {
      addWarningFlashbar(t(i18nKeys.participants.warnings.selectEvent));
      return;
    }

    if (participantDataDownloadType === DOWNLOAD_ALL_PARTICIPANT_DATA) {
      eventsApi
        .getAllParticipantsExport(target.name)
        .then((value) => {
          downloadObjectAsCsv(value, `participant-all-export-${target.name}`);
          cancelPIIWarningModal();
        })
        .catch((err) => preProdLogger(err));
    } else if (participantDataDownloadType === DOWNLOAD_SOLVED_CHALLENGE_PARTICIPANT_DATA) {
      eventsApi
        .getParticipantSolvedExport(target.name)
        .then((value) => {
          downloadObjectAsJson(value, `participant-solved-export-${target.name}`);
          cancelPIIWarningModal();
        })
        .catch((err) => preProdLogger(err));
    } else {
      return;
    }
  };

  const handleDownloadAction = (id: string) => {
    switch (id) {
      case DownloadParticipantActions.CHALLENGE_RESOLVER_PARTICIPANTS:
        downloadParticipantSolvedExport();
        break;

      case DownloadParticipantActions.ALL_PARTICIPANTS_EMAIL:
        downloadAllParticipantEmails();
        break;

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <ConfirmModal
        onCancel={cancelPIIWarningModal}
        confirmBtnLabel="Yes, Continue"
        onConfirm={exportData}
        title="PII Data Access Warning"
        visible={piiModalVisible}
        message={
          <>
            <div>{t(i18nKeys.participants.messages.piiDataWarningDiv1)}</div>
            <div>
              {t(i18nKeys.participants.messages.piiDataWarningDiv2)}
              <br /> {t(i18nKeys.participants.messages.piiDataWarningDiv3)} <br />{' '}
              {t(i18nKeys.participants.messages.piiDataWarningDiv4)}
            </div>
            <div>{t(i18nKeys.participants.messages.piiDataWarningDiv5)}</div>
            <div>{t(i18nKeys.participants.messages.piiDataWarningDiv6)}</div>
          </>
        }
      />
      {selectedUser && (
        <React.Fragment>
          <ConfirmModal
            visible={confirmResetPasswordVisible}
            message={`${t(i18nKeys.participants.messages.resetPassword)}${selectedUser?.nickname} <${
              selectedUser.email
            }>`}
            confirmBtnLabel={t(i18nKeys.participants.buttons.confirmResetPassword)}
            onConfirm={() => handleResetPassword()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmResetPasswordVisible(false);
            }}
          />
          <ConfirmModal
            visible={confirmPromoteUserToFacilitatorVisible}
            message={`${t(i18nKeys.participants.messages.promoteToFacilitator)}${selectedUser?.nickname} <${
              selectedUser.email
            }>`}
            confirmBtnLabel={t(i18nKeys.participants.buttons.confirmPromoteUser)}
            onConfirm={() => handlePromoteUser()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmPromoteUserToFacilitatorVisible(false);
            }}
          />
          <ConfirmModal
            visible={confirmDemoteUserVisible}
            message={`${t(i18nKeys.participants.messages.demoteToParticipant)}${selectedUser?.nickname} <${
              selectedUser.email
            }>`}
            confirmBtnLabel={t(i18nKeys.participants.buttons.confirmDemoteUser)}
            onConfirm={() => handleDemoteUser()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmDemoteUserVisible(false);
            }}
          />
          <ConfirmModal
            visible={confirmDisableUserVisible}
            message={`${t(i18nKeys.participants.messages.disable)}${selectedUser?.nickname} <${selectedUser.email}>`}
            confirmBtnLabel={t(i18nKeys.participants.buttons.confirmDisbleUser)}
            onConfirm={() => handleDisableUser()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmDisableUserVisible(false);
            }}
          />
          <ConfirmModal
            visible={confirmEnableUserVisible}
            message={`${t(i18nKeys.participants.messages.enable)}${selectedUser?.nickname} <${selectedUser.email}>`}
            confirmBtnLabel={t(i18nKeys.participants.buttons.confirmEnableUser)}
            onConfirm={() => handleEnableUser()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmEnableUserVisible(false);
            }}
          />
          <ConfirmModal
            visible={saveTeamAssignmentVisible}
            title={t(i18nKeys.participants.headers.teamAssignment)}
            message={
              <div>
                <KeyValue label={t(i18nKeys.participants.headers.event)}>{`${target.title} (${target.name})`}</KeyValue>
                <KeyValue
                  label={t(
                    i18nKeys.participants.headers.user
                  )}>{`${selectedUser.nickname} (${selectedUser.email})`}</KeyValue>
                <KeyValue label={t(i18nKeys.participants.labels.selectTeam)}>
                  <Select
                    options={teamSelectOptions}
                    selectedOption={selectedTeamOption}
                    onChange={({ detail }) => setSelectedTeamOption(detail.selectedOption)}
                  />
                </KeyValue>
              </div>
            }
            confirmBtnLabel={t(i18nKeys.participants.buttons.saveTeamAssignment)}
            disabled={
              selectedUser.teamName
                ? selectedUser.teamName === selectedTeamOption.value
                : selectedTeamOption.value === TEAM_OPTION_VALUE_NONE
            }
            onConfirm={() => handleSaveTeamAssignment()}
            onCancel={() => {
              setSelectedUser(null);
              setSaveTeamAssignmentVisible(false);
            }}
          />
          <Modal
            visible={userDetailsVisible}
            header={t(i18nKeys.participants.headers.userDetails)}
            onDismiss={() => {
              setSelectedUser(null);
              setUserDetailsVisible(false);
            }}
            footer={
              <Box float="right">
                <Button
                  variant="link"
                  onClick={() => {
                    setSelectedUser(null);
                    setUserDetailsVisible(false);
                  }}>
                  {t(i18nKeys.general.cancel)}
                </Button>
              </Box>
            }>
            <div>
              <KeyValue
                label={
                  <strong>{t(i18nKeys.participants.headers.user)}</strong>
                }>{`${selectedUser.nickname} (${selectedUser.email})`}</KeyValue>
              <KeyValue
                label={
                  <strong>{t(i18nKeys.participants.headers.event)}</strong>
                }>{`${target.title} (${target.name})`}</KeyValue>
              <KeyValue label={<strong>{t(i18nKeys.participants.headers.team)}</strong>}>
                {selectedUser.teamName}
                <Link
                  onFollow={() => {
                    setUserDetailsVisible(false);
                    toggleAssignTeam(selectedUser);
                  }}>
                  <Icon name="edit" />
                </Link>
              </KeyValue>
              <KeyValue label={<strong>{t(i18nKeys.participants.headers.role)}</strong>}>
                {t(selectedUser.getRole)}
              </KeyValue>
              <HorizontalRule evenMargins />
              <SpaceBetween direction="horizontal" size="s">
                {!selectedUser.disabled && (
                  <Link
                    className="right-border pr-12"
                    onFollow={() => {
                      setUserDetailsVisible(false);
                      toggleResetPassword(selectedUser);
                    }}>
                    {t(i18nKeys.participants.labels.resetPassword)}
                  </Link>
                )}
                {!selectedUser.disabled && !selectedUser.facilitator && (
                  <Link
                    className="right-border pr-12"
                    onFollow={() => {
                      setUserDetailsVisible(false);
                      toggleHandlePromoteUser(selectedUser);
                    }}>
                    {t(i18nKeys.participants.labels.promoteToFacilitator)}
                  </Link>
                )}
                {!selectedUser.disabled && selectedUser.facilitator && (
                  <Link
                    className="right-border pr-12"
                    onFollow={() => {
                      setUserDetailsVisible(false);
                      toggleHandleDemoteUser(selectedUser);
                    }}>
                    {t(i18nKeys.participants.labels.demoteToParticipant)}
                  </Link>
                )}
                {!selectedUser.disabled && (
                  <Link
                    onFollow={() => {
                      setUserDetailsVisible(false);
                      toggleDisableUser(selectedUser);
                    }}>
                    {t(i18nKeys.participants.labels.disable)}
                  </Link>
                )}
                {selectedUser.disabled && (
                  <Link
                    onFollow={() => {
                      setUserDetailsVisible(false);
                      toggleEnableUser(selectedUser);
                    }}>
                    {t(i18nKeys.participants.labels.enable)}
                  </Link>
                )}
              </SpaceBetween>
            </div>
          </Modal>
          {t(i18nKeys.participants.buttons.handleAssignment, {
            trailingMessage:
              selectedTeamOption.value !== TEAM_OPTION_VALUE_NONE
                ? t(i18nKeys.participants.buttons.assignToTeam, { teamName: selectedTeamOption.value })
                : t(i18nKeys.participants.buttons.unassign),
          })}
          <ConfirmModal
            visible={confirmAssignTeamVisible}
            message={
              selectedTeamOption.value !== TEAM_OPTION_VALUE_NONE
                ? t(i18nKeys.participants.messages.assignUserToTeam, {
                    nickName: selectedUser.nickname,
                    teamName: selectedTeamOption.value,
                  })
                : t(i18nKeys.participants.messages.unassignUserFromTeam, { nickname: selectedUser.nickname })
            }
            confirmBtnLabel={t(i18nKeys.participants.buttons.handleAssignment, {
              trailingMessage:
                selectedTeamOption.value !== TEAM_OPTION_VALUE_NONE
                  ? t(i18nKeys.participants.buttons.assignToTeam, { teamName: selectedTeamOption.value })
                  : t(i18nKeys.participants.buttons.unassign),
            })}
            onConfirm={() => handleConfirmSaveTeamAssignment()}
            onCancel={() => {
              setSelectedUser(null);
              setConfirmAssignTeamVisible(false);
            }}
          />
        </React.Fragment>
      )}
      {selectedTeam && (
        <React.Fragment>
          <Modal
            header={t(i18nKeys.participants.headers.teamDetails)}
            visible={teamDetailsVisible}
            onDismiss={() => {
              setSelectedTeam(null);
              setTeamDetailsVisible(false);
            }}
            footer={
              <Box float="right">
                <Button
                  variant="link"
                  onClick={() => {
                    setSelectedTeam(null);
                    setTeamDetailsVisible(false);
                  }}>
                  {t(i18nKeys.general.cancel)}
                </Button>
              </Box>
            }>
            <KeyValue
              label={
                <strong>{t(i18nKeys.participants.headers.event)}</strong>
              }>{`${target.title} (${target.name})`}</KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.participants.headers.teamName)}</strong>}>
              {selectedTeam?.name}
            </KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.participants.headers.teamAlias)}</strong>}>
              {selectedTeam?.alias}
            </KeyValue>
            <KeyValue label={<strong>{t(i18nKeys.participants.headers.members)}</strong>}>
              {selectedTeam?.members.map((member, i) => {
                return (
                  <div key={`team-member-${i}`}>
                    {member.nickname}
                    <Link
                      onFollow={() => {
                        if (member.userId) {
                          const userRow = getUserById(member.userId);
                          if (userRow) {
                            setSelectedUser(userRow);
                            setTeamDetailsVisible(false);
                            handleSaveTeamAssignment();
                          }
                        }
                      }}>
                      <Icon name="close" className="ml-5" />
                    </Link>
                  </div>
                );
              })}
            </KeyValue>
            <HorizontalRule evenMargins />
            <SpaceBetween direction="horizontal" size="s">
              <Link
                className="right-border pr-12"
                onFollow={() => {
                  setTeamDetailsVisible(false);
                  setRenameTeamAliasVisible(true);
                }}>
                {t(i18nKeys.participants.labels.changeTeamAlias)}
              </Link>
              <Link
                className="right-border pr-12"
                onFollow={() => {
                  setDisableTeamVisible(true);
                  setTeamDetailsVisible(false);
                }}>
                {t(i18nKeys.participants.labels.disableTeam)}
              </Link>
              <Link
                onFollow={() => {
                  setDisableTeamVisible(false);
                  setDeleteTeamVisible(true);
                }}>
                {t(i18nKeys.participants.labels.deleteTeam)}
              </Link>
            </SpaceBetween>
          </Modal>
          <ConfirmModal
            visible={renameTeamAliasVisible}
            title={t(i18nKeys.participants.headers.renameTeamAlias)}
            confirmBtnLabel={t(i18nKeys.general.submit)}
            message={
              <KeyValue label={t(i18nKeys.participants.labels.pleaseEnterNewTeamAlias)}>
                <Input
                  type="text"
                  value={newTeamAlias || ''}
                  onChange={({ detail }) => setNewTeamAlias(detail.value)}
                />
              </KeyValue>
            }
            onConfirm={() => handleRenameTeamAlias()}
            onCancel={() => {
              setNewTeamAlias(null);
              setSelectedTeam(null);
              setRenameTeamAliasVisible(false);
            }}
          />
          <ConfirmModal
            visible={disableTeamVisible}
            confirmBtnLabel={t(i18nKeys.participants.buttons.disableTeam)}
            message={t(i18nKeys.participants.messages.disableTeam, { teamName: selectedTeam.name })}
            onConfirm={() => handleDisableTeam()}
            onCancel={() => {
              setSelectedTeam(null);
              setDisableTeamVisible(false);
            }}
          />
          <ConfirmModal
            visible={deleteTeamVisible}
            confirmBtnLabel={t(i18nKeys.participants.buttons.deleteTeam)}
            message={t(i18nKeys.participants.messages.unassignTeam, { teamName: selectedTeam.name })}
            onConfirm={() => handleDeleteTeam()}
            onCancel={() => {
              setSelectedTeam(null);
              setDeleteTeamVisible(false);
            }}
          />
        </React.Fragment>
      )}
      <ConfirmModal
        visible={createTeamsVisible}
        confirmBtnLabel={t(i18nKeys.participants.buttons.createTeams)}
        title={t(i18nKeys.participants.headers.bulkCreateTeams)}
        message={
          <div>
            <KeyValue label={t(i18nKeys.participants.labels.howManyTeams)}>
              <Input
                type="number"
                value={numberOfTeamsToCreate.toString() || ''}
                onChange={({ detail }) => setNumberOfTeamsToCreate(Number(detail.value))}
              />
            </KeyValue>
            <KeyValue label={t(i18nKeys.participants.labels.teamPrefix)}>
              <Input
                type="text"
                value={createTeamsPrefix || ''}
                onChange={({ detail }) => setCreateTeamsPrefix(detail.value)}
              />
            </KeyValue>
          </div>
        }
        disabled={numberOfTeamsToCreate <= 0 || _.isEmpty(createTeamsPrefix)}
        onConfirm={() => handleCreateTeams()}
        onCancel={() => {
          setCreateTeamsVisible(false);
        }}
      />
      <ConfirmModal
        visible={createTeamVisible}
        title={t(i18nKeys.participants.headers.createTeam)}
        confirmBtnLabel={t(i18nKeys.participants.buttons.createTeam)}
        message={
          <div>
            <KeyValue label={t(i18nKeys.participants.headers.event)}>{`${target?.title} (${target?.name})`}</KeyValue>
            <KeyValue label={t(i18nKeys.participants.headers.teamName)}>
              <Input type="text" value={newTeamName || ''} onChange={({ detail }) => setNewTeamName(detail.value)} />
            </KeyValue>
            <KeyValue label={t(i18nKeys.participants.headers.teamPassword)}>
              <Input
                value={newPassword || ''}
                type={showPassword ? 'text' : 'password'}
                onChange={({ detail }) => setNewPassword(detail.value)}
              />
              <Link onFollow={() => setShowPassword(!showPassword)}>
                {showPassword
                  ? t(i18nKeys.participants.labels.showPassword)
                  : t(i18nKeys.participants.labels.hidePassword)}
              </Link>
            </KeyValue>
            <KeyValue label={t(i18nKeys.participants.headers.teamOwner)}>
              <Select
                selectedOption={teamOwnerOption}
                onChange={({ detail }) => setTeamOwnerOption(detail.selectedOption)}
                options={teamOwnerOptions}
              />
            </KeyValue>
          </div>
        }
        disabled={_.isEmpty(newTeamName)}
        onConfirm={() => handleCreateTeam()}
        onCancel={() => {
          setCreateTeamVisible(false);
        }}
      />
      <ConfirmModal
        visible={confirmCreateTeamVisible}
        confirmBtnLabel={t(i18nKeys.participants.buttons.confirmCreateTeam, {
          teamType: newPassword ? t(i18nKeys.participants.labels.private) : t(i18nKeys.participants.labels.public),
        })}
        message={t(i18nKeys.participants.messages.createTeam, {
          teamType: newPassword ? t(i18nKeys.participants.labels.private) : t(i18nKeys.participants.labels.public),
          teamName: newTeamName,
          trailingMessage:
            teamOwnerOption && teamOwnerOption.value !== TEAM_OPTION_VALUE_NONE
              ? t(i18nKeys.participants.messages.createTeamTrail, { teamOwner: teamOwnerOption.label })
              : '',
        })}
        onConfirm={() => handleConfirmCreateTeam()}
        onCancel={() => {
          setTeamOwnerOption(noOwnerOption);
          setNewPassword(null);
          setNewTeamName(null);
          setConfirmCreateTeamVisible(false);
        }}
      />
      <SpaceBetween direction="vertical" size="s">
        <ExpandableSection
          defaultExpanded
          variant="container"
          headerText={t(i18nKeys.participants.headers.participantsLabel)}
          headerCounter={`(${String(allUserRows.length)})`}
          headerActions={
            <ButtonDropdown
              key={'download-dropdown'}
              onItemClick={(e) => handleDownloadAction(e.detail.id)}
              items={[
                {
                  id: DownloadParticipantActions.CHALLENGE_RESOLVER_PARTICIPANTS,
                  text: t(i18nKeys.participants.buttons.DownloadParticipantsWhoSolvedAChallenge) ?? '',
                },
                {
                  disabled: !users || users?.length === 0,
                  id: DownloadParticipantActions.ALL_PARTICIPANTS_EMAIL,
                  text: t(i18nKeys.participants.buttons.downloadAllParticipantEmails) ?? '',
                },
              ]}>
              {t(i18nKeys.participants.labels.download)}
            </ButtonDropdown>
          }>
          <Table
            variant="borderless"
            {...collectionProps}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
            items={items}
            filter={
              <SpaceBetween direction="horizontal" size="s">
                <TextFilter
                  className="text-filter"
                  filteringPlaceholder={t(i18nKeys.participants.labels.searchParticipants)}
                  {...filterProps}
                  countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
                  filteringAriaLabel={t(i18nKeys.participants.labels.userFilteringLabel)}
                />
                <Select
                  options={roleOptions}
                  selectedOption={roleFilter}
                  onChange={({ detail }) => handleRoleFilterChange(detail.selectedOption)}
                />
              </SpaceBetween>
            }
            columnDefinitions={COLUMN_DEFINITIONS(target, toggleResetPassword, toggleDisableUser)}
          />
        </ExpandableSection>

        <TeamTable
          target={target}
          teams={teams || []}
          toggleTeamDetails={toggleTeamDetails}
          setCreateTeamsVisible={setCreateTeamsVisible}
          setCreateTeamVisible={setCreateTeamVisible}
          setTeamDetailsVisible={setTeamDetailsVisible}
          setRenameTeamAliasVisible={setRenameTeamAliasVisible}
          setDisableTeamVisible={setDisableTeamVisible}
          setDeleteTeamVisible={setDeleteTeamVisible}
          selectTeamName={selectTeamName}
        />
      </SpaceBetween>
    </React.Fragment>
  );
};
