import React, { useEffect, useState } from 'react';
import { Box, Button, Spinner } from '@amzn/awsui-components-react';
import './TeamStatsInfo.scss';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { JamTeamDetails } from '@/src/types/JamTeam';
import { useApi } from '@/src/store/api.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { LeaderboardMiniTeam, LeaderboardTeam } from '@/src/types/JamLeaderboard';
import moment from 'moment';

interface TeamStatsInfoProps {
  onClose: () => void;
  teamId: string;
  eventName: string;
  leaderboardInfo: LeaderboardMiniTeam | LeaderboardTeam;
}

const TeamStatsInfo: React.FC<TeamStatsInfoProps> = ({ onClose, teamId, eventName, leaderboardInfo }) => {
  const { t } = useTranslation();
  const { jamTeamApi } = useApi();
  const { clearFlashbars } = useFlashbars();
  const [isLoadingMembers, setIsLoadingMembers] = useState(true);
  const [team, setTeam] = useState<JamTeamDetails>();

  const getTeamDetails = async (teamName: string) => {
    try {
      clearFlashbars();
      setIsLoadingMembers(true);
      const response = await jamTeamApi.getTeamDetails(eventName, teamName);
      setTeam(response);
    } catch (e) {
      // error API hadndled
    }
    setIsLoadingMembers(false);
  };

  useEffect(() => {
    void getTeamDetails(teamId);
  }, [teamId]);

  return (
    <div className="team-stats-info">
      <Box margin={{ top: 'xxxl', left: 'xl', right: 'xl' }}>
        <Box variant="h3" margin={{ bottom: 'l' }}>
          {team?.team.teamName}
        </Box>
        {isLoadingMembers ? (
          <Spinner />
        ) : (
          <>
            <Box margin={{ bottom: 'l' }}>
              <div className="stats-item">
                <Box variant="h4">{t(i18nKeys.jamDashboard.leaderboard.table.headers.rank)}:</Box>{' '}
                <Box variant="h4">{leaderboardInfo.rank}</Box>
              </div>
              <div className="stats-item">
                <Box variant="h4">{t(i18nKeys.jamDashboard.leaderboard.table.headers.completedChallenges)}:</Box>
                <Box variant="h4">{team?.statistics.challengesCompleted}</Box>
              </div>
              <div className="stats-item">
                <Box variant="h4">{t(i18nKeys.jamDashboard.leaderboard.table.headers.cluesUsed)}:</Box>
                <Box variant="h4">{team?.statistics.cluesUsed}</Box>
              </div>
              <div className="stats-item">
                <Box variant="h4">{t(i18nKeys.jamDashboard.leaderboard.table.headers.score)}:</Box>
                <Box variant="h4">{team?.statistics.score}</Box>
              </div>
              <div className="stats-item">
                <Box variant="h4">{t(i18nKeys.jamDashboard.leaderboard.details.totalTime)}:</Box>{' '}
                <Box variant="h4">
                  {(leaderboardInfo as LeaderboardTeam).totalTime
                    ? moment.utc((leaderboardInfo as LeaderboardTeam).totalTime * 1000).format('HH:mm:ss')
                    : '--'}
                </Box>
              </div>
            </Box>
            <Box variant="h4">{t(i18nKeys.jamDashboard.leaderboard.details.teamMembers)}:</Box>

            {team?.participants.map((member) => (
              <Box key={member.participantId}>{member.nickname}</Box>
            ))}
          </>
        )}
        <Box margin={{ top: 'l' }}>
          <div className="actions">
            <Button variant="primary" onClick={onClose}>
              {t(i18nKeys.general.close)}
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default TeamStatsInfo;
