import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

const { eventDuration } = i18nKeys.eventTemplates.step1;

interface IEventDurationTileProps {
  // ** Time to complete challenged **/
  hours: number;
  /** Number of challenged **/
  minChallenges: number;
  /* Maximum number of challenges */
  maxChallenges: number;
  /** Price in USD **/
  price: number;
}

const MIN_BACKUP_CHALLENGES = 2;

export const EventDurationTile: React.FC<IEventDurationTileProps> = ({
  hours,
  minChallenges,
  maxChallenges,
  price,
}) => {
  const { t } = useTranslation();
  return (
    <span>
      {t(eventDuration.hours)}: {hours}
      <br />
      {t(eventDuration.challenges)}:{' '}
      {minChallenges === maxChallenges ? minChallenges : `${minChallenges} - ${maxChallenges}`}
      <br />
      {t(eventDuration.backupChallenges)}:{' '}
      {t(eventDuration.backupChallengeValue, { minBackupChallenges: MIN_BACKUP_CHALLENGES })}
      <br />
      {t(eventDuration.price)}: ${price} (USD) {t(eventDuration.perPerson)}
    </span>
  );
};
