import React, { ReactNode } from 'react';
import { Header, Spinner } from '@amzn/awsui-components-react';
import { getHeaderCounterText } from '../../utils/table.utils';
import './TableHeader.scss';

interface TableHeaderProps {
  totalItems?: number;
  title: string;
  description?: string;
  actionButtons?: ReactNode;
  toggleTools?: () => any;
  selectedItems?: any[];
  showZero?: boolean;
  isLoadingInProgress?: boolean;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  totalItems,
  title,
  description,
  actionButtons,
  selectedItems = [],
  showZero = false,
  isLoadingInProgress = false,
}) => {
  const counter = totalItems ? getHeaderCounterText(totalItems, selectedItems, showZero) : undefined;

  const HeaderTitle = () => (
    <div className="table-header-title">
      {title}
      <div className="table-header-counter">
        {counter}
      </div>
      {isLoadingInProgress && <Spinner />}
    </div>
  );

  return (
    <Header 
      variant="awsui-h1-sticky"
      description={description} 
      actions={actionButtons}>
      <HeaderTitle />
    </Header>
  );
};
