import { JamTeamItem } from '@/src/types/JamTeam';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, Button, Form, FormField, Modal, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ChangeTeamModalProps {
  teams: JamTeamItem[];
  visible: boolean;
  handleClose: () => void;
  currentTeamName?: string;
  nickName: string;
  handleTeamChange: (name: string) => Promise<void>;
}

const ChangeTeamModal: React.FC<ChangeTeamModalProps> = ({
  visible,
  teams,
  handleClose,
  currentTeamName,
  nickName,
  handleTeamChange,
}) => {
  const { t } = useTranslation();
  const [selectedTeam, setSelectedTeam] = useState<OptionDefinition>({
    label: currentTeamName,
    value: currentTeamName,
  });
  const [askAssign, setAskAssign] = useState(false);

  const onSubmitPress = () => {
    if (!askAssign) {
      setAskAssign(true);
      return;
    }
    void handleTeamChange(selectedTeam.value as string);
    handleClose();
  };

  return (
    <Modal
      onDismiss={handleClose}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={handleClose}>
              {t(i18nKeys.general.cancel)}
            </Button>
            <Button variant="primary" onClick={onSubmitPress}>
              {askAssign ? t(i18nKeys.general.continue) : t(i18nKeys.general.submit)}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        askAssign
          ? t(i18nKeys.facilitator.participants.modals.changeTeam.assignParticipantTeam)
          : t(i18nKeys.facilitator.participants.modals.changeTeam.chooseTeam)
      }>
      {askAssign ? (
        <Box>
          {t(i18nKeys.facilitator.participants.modals.changeTeam.areYouSureAssign)} {nickName} -&gt;{' '}
          {selectedTeam.label}
        </Box>
      ) : (
        <Form>
          <FormField label={t(i18nKeys.facilitator.participants.modals.changeTeam.selectTeam)}>
            <Select
              selectedOption={selectedTeam}
              onChange={({ detail }) => setSelectedTeam(detail.selectedOption)}
              options={teams.map((team) => ({ label: team.team.teamName, value: team.team.teamId }))}
            />
          </FormField>
        </Form>
      )}
    </Modal>
  );
};

export default ChangeTeamModal;
