import React, { useEffect, useState } from 'react';
import { Box, ContentLayout, Header, SpaceBetween, Grid, Tabs } from '@amzn/awsui-components-react';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import './JamReport.scss';
import JamContainer from '@/src/components/ui/molecules/JamContainer/JamContainer';
import ChallengeMetrics from './ChallengeMetricsTable';
import TeamMetrics from './TeamMetricsTable';
import Leaderboard from './LeaderboardTable';
import NotableMoment from './NotableMomentTable';
import NextStep from './NextStep';
import { useJamReport } from '@/src/store/jam-report.context';
import { LoadingBar } from '@/src/components/common/LoadingBar';
import moment from 'moment';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';

const JamReport = () => {
  const { eventName } = useJamEventDetails();
  const { clearFlashbars } = useFlashbars();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { loadJamReport, reportApiData } = useJamReport();
  const [tabs, setTabs] = useState([
    {
      label: t(i18nKeys.eventReport.challengeTable.heading),
      id: 'first',
      content: <ChallengeMetrics />,
    },
    {
      label: t(i18nKeys.eventReport.teamMetrics.heading),
      id: 'second',
      content: <TeamMetrics />,
    },
    {
      label: t(i18nKeys.eventReport.notableMoment.heading),
      id: 'four',
      content: <NotableMoment />,
    },
  ]);

  interface Itab {
    label: string;
    id: string;
    content: JSX.Element | string;
  }

  const getData = async () => {
    if (eventName) {
      await loadJamReport(eventName);
    }
  };

  // if leaderboard have data then only show tab
  const checkLeaderBoardHaveData = (): void => {
    clearFlashbars();
    if (reportApiData?.topTenTeamsLeaderBoardEntry) {
      const newArr = [...tabs];
      const LeaderboardTab = {
        label: t(i18nKeys.eventReport.leaderboardTable.heading),
        id: 'third',
        content: <Leaderboard />,
      };
      const checkUsername = (obj: Itab): boolean => obj.id === 'third';
      if (!newArr.some(checkUsername)) {
        newArr.splice(2, 0, LeaderboardTab);
        setTabs(newArr);
      }
    }
  };

  // if Next tab have data then only show tab
  const checkIfNextStepHaveData = (): void => {
    if (reportApiData?.challengeExtras) {
      const newArr = [...tabs];
      const nextStepTab = {
        label: t(i18nKeys.eventReport.nextSteps.heading),
        id: 'five',
        content: <NextStep />,
      };
      const tabName = (obj: Itab): boolean => obj.id === 'five';
      if (!newArr.some(tabName)) {
        newArr.splice(4, 0, nextStepTab);
        setTabs(newArr);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    checkLeaderBoardHaveData();
    checkIfNextStepHaveData();
    setLoading(false);
  }, [reportApiData]);

  useEffect(() => {
    setLoading(true);
    void getData();
    setLoading(false);
  }, []);

  return (
    <>
      {' '}
      {loading ? (
        <LoadingBar />
      ) : (
        <div className="report">
          <ContentLayout
            header={
              <Header data-classname="main-header" variant="h1" description={t(i18nKeys.eventReport.description)}>
                <Box>
                  <h1 style={{ fontSize: '42px' }}>{`AWS Jam Report ${moment(reportApiData?.eventEndDate).format(
                    'DD/MM/YYYY'
                  )}`}</h1>
                  <h2 className="title">{reportApiData?.eventTitle}</h2>
                </Box>
              </Header>
            }>
            <SpaceBetween size="xl">
              <JamContainer>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
                  <Box>
                    <Box>{t(i18nKeys.eventReport.leaderboardTable.rank)}</Box>
                    <Box>
                      <span className="big-text"> {reportApiData?.teamLeaderBoardEntry?.rank || '-'}</span>{' '}
                      <span className="small-text">out of {reportApiData?.teamMetrics?.length || 0}</span>
                    </Box>
                  </Box>
                  <Box>
                    <Box>{t(i18nKeys.eventReport.points)}</Box>
                    <span className="big-text">
                      {reportApiData?.teamLeaderBoardEntry?.teamTotalEffectiveScore || 0}
                    </span>{' '}
                    <span className="small-text">
                      out of {reportApiData?.teamLeaderBoardEntry?.maxPossibleEventScore || 0}
                    </span>
                  </Box>
                  <Box>
                    <Box>{t(i18nKeys.eventReport.challenges)}</Box>
                    <span className="big-text">
                      {' '}
                      {reportApiData?.teamLeaderBoardEntry?.teamNumSolvedChallenges || 0}
                    </span>{' '}
                    <span className="small-text">out of {reportApiData?.challengeMetrics?.length || 0}</span>
                  </Box>
                  <Box>
                    <Box>{t(i18nKeys.eventReport.cluesUsed)}</Box>
                    <span className="big-text">{reportApiData?.teamLeaderBoardEntry?.teamNumUsedClues || 0}</span>{' '}
                  </Box>
                </Grid>
              </JamContainer>

              <Tabs tabs={tabs} />
            </SpaceBetween>
          </ContentLayout>
        </div>
      )}
    </>
  );
};

export default JamReport;
