import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { Campaign } from '../../../types/Campaign';
import CampaignAttempts from '../CampaignSections/CampaignAttempts';
import CampaignClues from '../CampaignSections/CampaignClues';
import CampaignPassingScore from '../CampaignSections/CampaignPassingScore';
import CampaignTimeLimit from '../CampaignSections/CampaignTimeLimit';
import CampaignType from '../CampaignSections/CampaignType';

interface NewCampaignSettingsProps {
  target: Campaign | null;
}

const NewCampaignSettings: React.FC<NewCampaignSettingsProps> = ({ target }) => {
  return (
    <React.Fragment>
      {target && (
        <SpaceBetween direction="vertical" size="s">
          <CampaignType campaign={target} />
          <CampaignClues campaign={target} />
          <CampaignTimeLimit campaign={target} />
          <CampaignPassingScore campaign={target} />
          <CampaignAttempts campaign={target} />
        </SpaceBetween>
      )}
    </React.Fragment>
  );
};

export default NewCampaignSettings;
