import React, { useState, useMemo, useEffect } from 'react';
import {
  ContentLayout,
  SpaceBetween,
  Header,
  Container,
  Grid,
  FormField,
  Input,
  Button,
  Box,
  Select,
  Checkbox,
} from '@amzn/awsui-components-react';
import { delay } from '@/src/utils/delay';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { createJamProfileValidator } from '@/src/utils/create-jam-profile.validation.utils';
import { CreateJamProfileFields } from '@/src/types/CreateJamProfile';
import { COUNTRIES } from '@/src/constants/countries';
import { localLogger } from '@/src/utils/log.utils';
import { useApi } from '@/src/store/api.context';
import { useDebounce } from '@/src/hooks/useDebounce';
import { JamProfile } from '@/src/types/JamProfile';
import { getLanguageCodeSafe, getLocaleLabel, LANGUAGE_SELECT_OPTIONS } from '@/src/utils/locale.utils';

import './index.scss';
import JamSpinner from '@/src/components/common/JamSpinner';
import { useHistory } from 'react-router-dom';
import { useUser } from '@/src/store/user.context';

type LanguageOption = {
  value: string;
  label: string;
};

export interface ILanguageSelectProps {
  /** selected language code **/
  lang: LanguageOption;
  /** Available langauges */
  options: LanguageOption[];
  /** on language changed callback */
  onLanguageSelect: (code: string) => void;
}

const jamProfileDetails: React.FC = () => {
  const { jamProfileAPI } = useApi();
  const { t, i18n } = useTranslation();
  const { onProfileChange } = useUser();
  const history = useHistory();
  const [selectedLanguage] = useState(getLanguageCodeSafe(i18n.language));

  const [language, setLanguage] = useState<OptionDefinition | null>(null);
  const [countryState, setCountry] = useState<OptionDefinition | null>(null);
  const countries = COUNTRIES.map((countryEl) => ({ value: countryEl.code, label: t(countryEl.name) }));
  const [userProfile, setUserProfile] = useState<JamProfile>({
    email: '',
    company: '',
    nickname: '',
    title: '',
    promotionNewsOptIn: false,
  });
  const displayNameSearch = useDebounce<string>(userProfile.nickname, 300);

  const [emailError, setUserProfileError] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [displayNameUniqueError, setDisplayNameUniqueError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [loading, setLoading] = useState<boolean>(true);

  const getUser = async () => {
    setLoading(true);
    const res = await jamProfileAPI.getProfile();
    if (res) {
      const { email, nickname, country, company, title, promotionNewsOptIn }: JamProfile = res;
      const countryData = COUNTRIES.filter((item) => item.code === country);
      if (countryData && countryData.length) {
        setCountry({ value: country, label: t(countryData[0].name) });
      }
      setUserProfile({ ...userProfile, email, nickname, company, title, country, promotionNewsOptIn });
      setLanguage({ value: selectedLanguage, label: getLocaleLabel(selectedLanguage) });
    }
    setLoading(false);
  };

  useEffect(() => {
    void getUser();
  }, []);

  useEffect(() => {
    if (loading) return;

    history.block(() => {
      if (!(userProfile.nickname && userProfile.country)) {
        alert(t(i18nKeys.JamProfile.jamProfileDetails.incompleteProfile));
        return false;
      }
    });
  }, [t, loading, history, userProfile]);

  const onSubmit = async () => {
    const isValid = validator.isValidSection(true);
    if (!isValid && !displayNameUniqueError) {
      localLogger('valid', isValid);
      return;
    }
    localLogger('invalid', isValid);

    const body = {
      ...userProfile,
      country: countryState?.value,
    };
    void i18n.changeLanguage(language?.value);

    setLoading(true);
    const p1 = jamProfileAPI.updateLanguage(language?.value);
    const p2 = jamProfileAPI.updateProfile(body);
    try {
      await Promise.all([p1, p2]);
      onProfileChange(userProfile);
    } catch (error) {
      localLogger('error', error);
    }
    setLoading(false);
  };

  const validator = useMemo(
    () =>
      createJamProfileValidator(
        userProfile.email,
        userProfile.nickname,
        countryState?.value,
        language?.value,
        t(i18nKeys.createJamProfile.form.validation.emailError),
        t(i18nKeys.createJamProfile.form.validation.displayNameError),
        t(i18nKeys.createJamProfile.form.validation.countryError),
        t(i18nKeys.createJamProfile.form.validation.languageError),
        new Map<CreateJamProfileFields, (error: string) => void>([
          [CreateJamProfileFields.EMAIL, (error: string) => setUserProfileError(error)],
          [CreateJamProfileFields.DISPLAY_NAME, (error: string) => setDisplayNameError(error)],
          [CreateJamProfileFields.COUNTRY, (error: string) => setCountryError(error)],
          [CreateJamProfileFields.LANGUAGE, (error: string) => setLanguageError(error)],
        ])
      ),
    [userProfile.email, countryState, userProfile.nickname, language]
  );
  const checkDisplayNameUnique = async () => {
    await delay(300);
    if (displayNameSearch.length <= 3) {
      setDisplayNameUniqueError(t(i18nKeys.createJamProfile.form.validation.displayNameUniqueAndShortError));
      return;
    } else {
      setDisplayNameUniqueError('');
    }
  };

  useEffect(() => {
    if (!displayNameSearch) {
      return;
    }
    void checkDisplayNameUnique();
  }, [displayNameSearch]);

  if (loading) {
    return <JamSpinner />;
  }

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">{t(i18nKeys.JamProfile.jamProfileDetails.title)}</Header>
        </SpaceBetween>
      }>
      <Grid gridDefinition={[{ colspan: 10 }]}>
        <div>
          <Container>
            <div className="form-space">
              <form onSubmit={(e) => e.preventDefault()}>
                <SpaceBetween direction="vertical" size="l">
                  <FormField
                    stretch
                    label={`${t(i18nKeys.createJamProfile.form.preferredLanguage)}*`}
                    description={`${t(i18nKeys.createJamProfile.form.preferredLanguageDesc)}*`}
                    errorText={languageError}>
                    <Select
                      placeholder={t(i18nKeys.createJamProfile.form.select)}
                      selectedOption={language}
                      options={LANGUAGE_SELECT_OPTIONS}
                      onChange={({ detail }) => {
                        setLanguage(detail.selectedOption);
                      }}
                      onBlur={() => validator.isValidField(CreateJamProfileFields.LANGUAGE)}
                    />
                  </FormField>
                  <FormField stretch label={`${t(i18nKeys.createJamProfile.form.email)}`} errorText={emailError}>
                    <Input
                      value={userProfile.email}
                      onChange={({ detail }) => setUserProfile({ ...userProfile, email: detail.value })}
                      placeholder={t(i18nKeys.createJamProfile.form.email)}
                      onBlur={() => validator.isValidField(CreateJamProfileFields.EMAIL)}
                      disabled
                    />
                  </FormField>
                  <FormField
                    stretch
                    label={`${t(i18nKeys.createJamProfile.form.displayName)}*`}
                    description={t(i18nKeys.createJamProfile.form.displayNameDesc)}
                    errorText={displayNameUniqueError || displayNameError}>
                    <Input
                      value={userProfile.nickname}
                      onChange={({ detail }) => setUserProfile({ ...userProfile, nickname: detail.value })}
                      onBlur={() => validator.isValidField(CreateJamProfileFields.DISPLAY_NAME)}
                    />
                  </FormField>
                  <FormField stretch label={`${t(i18nKeys.createJamProfile.form.country)}*`} errorText={countryError}>
                    <Select
                      placeholder={t(i18nKeys.createJamProfile.form.select)}
                      selectedOption={countryState}
                      onChange={({ detail }) => {
                        setUserProfile({ ...userProfile, country: detail.selectedOption.value });
                        setCountry(detail.selectedOption);
                      }}
                      options={countries}
                      onBlur={() => validator.isValidField(CreateJamProfileFields.COUNTRY)}
                    />
                  </FormField>
                  <FormField stretch label={t(i18nKeys.JamProfile.jamProfileDetails.company)}>
                    <Input
                      value={userProfile.company ? userProfile.company : ''}
                      onChange={({ detail }) => setUserProfile({ ...userProfile, company: detail.value })}
                    />
                  </FormField>
                  <FormField stretch label={t(i18nKeys.JamProfile.jamProfileDetails.jobTitle)}>
                    <Input
                      value={userProfile.title ? userProfile.title : ''}
                      onChange={({ detail }) => setUserProfile({ ...userProfile, title: detail.value })}
                    />
                  </FormField>
                  <FormField stretch>
                    <Checkbox
                      checked={userProfile.promotionNewsOptIn ? userProfile.promotionNewsOptIn : false}
                      onChange={({ detail }) => setUserProfile({ ...userProfile, promotionNewsOptIn: detail.checked })}>
                      <Box>{t(i18nKeys.createJamProfile.form.subscribeDesc)}</Box>
                    </Checkbox>
                  </FormField>
                </SpaceBetween>
              </form>
            </div>
          </Container>
          <div className="update-btn">
            <Box float="right">
              <Button
                variant="primary"
                ariaLabel={t(i18nKeys.JamProfile.jamProfileDetails.btn.updateProfile)}
                onClick={() => void onSubmit()}>
                {t(i18nKeys.JamProfile.jamProfileDetails.btn.updateProfile)}
              </Button>
            </Box>
          </div>
        </div>
      </Grid>
    </ContentLayout>
  );
};

export default jamProfileDetails;
