import { chain } from 'lodash';
import { NullableArray } from '../types/common';
import { CognitoUser } from '../types/User';

type FromPlainObject = (x: any) => any;

export const safeArray = (list: NullableArray | undefined) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return list || [];
};

export const toMapBy = <T>(items: T[], attributeName: string): { [key: string]: T } => {
  return chain(items).keyBy(attributeName).value();
};

export const asList =
  (serializer: FromPlainObject) =>
  (arr: any[] | null | undefined): any[] =>
    (arr || []).map(serializer);

interface asUserResponsesListProps {
  nextToken: string;
  userResponses: CognitoUser[];
}

export const asUserResponsesList =
  (serializer: FromPlainObject) =>
  (arr: asUserResponsesListProps | null | undefined): any[] => {
    return (arr?.userResponses || []).map(serializer);
  };

/**
 * returns true if the input list is null, empty, or undefined
 */
export const isEmpty = (list: NullableArray) => {
  return !safeArray(list).length;
};

/**
 * returns a list without any null or undefined values
 * returns empty list if list is null, empty, or undefined
 */
export const safeFilterNulls = (list: NullableArray) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return safeArray(list).filter((val) => val !== null && val !== undefined);
};
