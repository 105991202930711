export enum ReviewChallengesListColumn {
    CHALLENGE_TITLE = 'CHALLENGE_TITLE',
    DATE_CREATED = 'DATE_CREATED',
    BR_STATUS = 'BR_STATUS',
    ASSIGNED_BR = 'ASSIGNED_BR',
  }

  export enum ReviewButtonType {
    REQUEST_REVIEW = 'REQUEST_REVIEW',
    COMPLETE_REVIEW = 'COMPLETE_REVIEW',
    CANCEL_REVIEW = 'CANCEL_REVIEW',
    START_REVIEW = 'START_REVIEW',
    APPROVE_REVIEW = 'APPROVE_REVIEW',
    REVIEW_MODE = 'REVIEW_MODE',
  }

  export enum RevisionsNeededColumn {
    SECTION = 'section',
    STATUS = 'status',
    COMMENT = 'comment',
  }

  export enum RevisionsNeededColumns {
    REVIEW_TYPE = 'type',
    STATUS = 'status',
    REVIEWER = 'reviewer',
    DATE_REVIEWED = 'lastUpdatedDate',
  }

  export enum ClueViewOption {
    EDIT = 'edit',
    PREVIEW = 'preview',
    BOTH = 'both',
  }