import React, { useState } from 'react';
import { Box, Button, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { Markdown } from '@/src/components/common/Markdown';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';

const JamChallengeOverview: React.FC = () => {
  const { t } = useTranslation();
  const { selectedJamChallenge } = useJamChallengeDetails();
  const [fullOverview, setFullOverview] = useState(false);
  // Attempt to fall back to description if no content is present in the learning outcome
  const content = selectedJamChallenge?.learningOutcome?.content ?? (selectedJamChallenge?.description ?? '');
  const contentLengthLimit = 500;
  const hasLongContent = content.length > contentLengthLimit;
  const { readFullScenario, readLess } = i18nKeys.myJams.challenges.details.overview;
  const toggleOverview = () => setFullOverview(!fullOverview);

  return (
    <Container className="dark-bg">
      <Box variant="h4" padding={{ bottom: 's' }}>
        {selectedJamChallenge?.learningOutcome?.summary}
      </Box>
      <Box variant="div">
        {content && (
          <SpaceBetween size="s">
            <Markdown content={(fullOverview || !hasLongContent) ? content : content.substring(0, contentLengthLimit)} />
            {
              hasLongContent &&
              <Box padding={{ bottom: 'l' }}>
                <Button href="#" variant="inline-link" data-testid="show-full-scenario-btn" onClick={toggleOverview}>
                  {fullOverview ? t(readLess) : t(readFullScenario)}
                </Button>
              </Box>
            }          
          </SpaceBetween>
        )}
      </Box>
    </Container>
  );
};

export default JamChallengeOverview;
