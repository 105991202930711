import React from 'react';
import './Reviews.scss';

const ReviewStars = ({ rating, maxRating = 5 }: { rating: number; maxRating?: number }) => {
  const stars = [];

  for (let i = 1; i <= maxRating; i++) {
    stars.push(
      <span key={i} className={`star-rating ${i <= rating ? 'filled' : 'unfilled'}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-star">
          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
        </svg>
      </span>
    );
  }

  return <div>{stars}</div>;
};

export default ReviewStars;
