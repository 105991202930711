import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { toTitleCase } from '../../../../../../utils/string.utils';
import { Checkbox, ExpandableSection, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { DiffChange } from '../../../../../../types/Diff';
import './ChallengeDiffTable.scss';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface ChallengeDiffTableProps {
  tableHeader: string;
  challengeDiffs: DiffChange[];
}

const ChallengeDiffTable: React.FC<ChallengeDiffTableProps> = ({ tableHeader, challengeDiffs }) => {
  const { t } = useTranslation();
  const [openedDetails, setOpenedDetails] = React.useState<string[]>([]);

  const handleChecked = (value: boolean, id: string) => {
    if (value) {
      setOpenedDetails([...openedDetails, id]);
    } else {
      setOpenedDetails(openedDetails.filter((el) => el !== id));
    }
  };

  return (
    <ExpandableSection variant="container" header={<Header>{tableHeader}</Header>}>
      <SpaceBetween direction="vertical" size="l">
        {challengeDiffs.map((diff, i) => {
          const isOpened = openedDetails.find((ele) => ele === `${tableHeader}${i}`);
          return (
            <ExpandableSection
              key={i}
              variant="container"
              headerText={toTitleCase(diff.property)}
              headerActions={
                <Checkbox
                  onChange={({ detail }) => handleChecked(detail.checked, `${tableHeader}${i}`)}
                  checked={isOpened ? true : false}>
                  {t(i18nKeys.challenges.challengeDetails.titles.hideUnchangedLines)}
                </Checkbox>
              }>
              {!isOpened && (
                <ReactDiffViewer oldValue={String(diff.previousValue)} newValue={String(diff.updatedValue)} splitView />
              )}
            </ExpandableSection>
          );
        })}
      </SpaceBetween>
    </ExpandableSection>
  );
};

export default ChallengeDiffTable;
