import { Alert, Button, Container, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { useEditEvent } from '../../../store/edit-event.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { toTitleCase } from '../../../utils/string.utils';
import { HorizontalRule } from '../../common/HorizontalRule';
import { KeyValue } from '../../common/KeyValue';
import ReviewRow from './ReviewRow';

interface ReviewAndCreateProps {
  target: Event;
  navigate: (step: number) => void;
}

const ReviewAndCreate: React.FC<ReviewAndCreateProps> = ({ target, navigate }) => {
  const { newEventImportSources } = useEditEvent();
  const { t } = useTranslation();
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const { toggleChallengeInfo } = useToolPanel();

  return (
    <SpaceBetween direction="vertical" size="s">
      <Container
        header={
          <Header variant="h2" actions={<Button onClick={() => navigate(0)}>{t(i18nKeys.general.edit)}</Button>}>
            {t(i18nKeys.newEvent.steps.eventDetails)}
          </Header>
        }>
        <ReviewRow label={t(i18nKeys.newEvent.labels.eventTitle)} value={target.title} />
        <HorizontalRule evenMargins />
        <ReviewRow label={t(i18nKeys.newEvent.labels.audience)} value={toTitleCase(target.audienceType)} />
        <HorizontalRule evenMargins />
        <ReviewRow label={t(i18nKeys.newEvent.labels.eventPrivacyType)} value={toTitleCase(target.eventPrivacyType)} />
        <HorizontalRule evenMargins />
        <ReviewRow label={t(i18nKeys.newEvent.labels.timeZone)} value={target.timezone} />
        <HorizontalRule evenMargins />
        <ReviewRow label={t(i18nKeys.newEvent.labels.startTime)} value={target.startDateAndTimeInEventLocalTime} />
        <HorizontalRule evenMargins />
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.duration)}
          value={t(i18nKeys.newEvent.messages.hoursMinutes, {
            hours: target.duration.hours,
            minutes: target.duration.minutes
              ? t(i18nKeys.newEvent.messages.minutes, { minutes: target.duration.minutes })
              : '',
          })}
        />
        <HorizontalRule evenMargins />
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.unlockChallenges)}
          value={
              target.autoUnlockChallengesOffsetMinutes !== null &&
              target.autoUnlockChallengesOffsetMinutes !== undefined
                  ? target.autoUnlockChallengesLocalTime
                  : t(i18nKeys.general.nA)
          }
        />
        <HorizontalRule evenMargins />
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.notes)}
          value={target.notes ? target.notes : t(i18nKeys.general.none)}
        />
      </Container>
      <Container
        header={
          <Header variant="h2" actions={<Button onClick={() => navigate(1)}>{t(i18nKeys.general.edit)}</Button>}>
            {t(i18nKeys.newEvent.steps.attendanceAndTesting)}
          </Header>
        }>
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.numberOfExpectedParticipants)}
          value={t(i18nKeys.newEvent.messages.expectedParticipants, {
            minExpectedParticipants: target.minExpectedParticipants,
            maxExpectedParticipants: target.maxExpectedParticipants,
          })}
        />
        <HorizontalRule evenMargins />
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.teamSize)}
          value={t(i18nKeys.newEvent.messages.maxTeamSize, { teamSize: target.maxTeamSize })}
        />
        <HorizontalRule evenMargins />
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.eventOptions)}
          value={
            !target.test
              ? t(i18nKeys.newEvent.messages.liveEvent, {
                  testTrail: target.testUrlWithSecretKey ? t(i18nKeys.newEvent.messages.testTrail) : '',
                })
              : t(i18nKeys.newEvent.messages.testEvent)
          }
        />
        <HorizontalRule evenMargins />
        <ReviewRow label={t(i18nKeys.newEvent.labels.daysForTesting)} value={target.testingDays} />
      </Container>
      <Container
        header={
          <Header variant="h2" actions={<Button onClick={() => navigate(2)}>{t(i18nKeys.general.edit)}</Button>}>
            {t(i18nKeys.newEvent.steps.teamAssignmentSettings)}
          </Header>
        }>
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.teamType)}
          value={
            target.teamAssignmentType === 'SELF_FORMING'
              ? t(i18nKeys.events.eventDetails.labels.selfForming)
              : toTitleCase(target.teamAssignmentType)
          }
        />
        <HorizontalRule evenMargins />
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.teamFormingStartTime)}
          value={
            target.formTeamsMinsBeforeEventStart
              ? t(i18nKeys.newEvent.messages.minutes, { minutes: target.formTeamsMinsBeforeEventStart })
              : t(i18nKeys.general.nA)
          }
        />
      </Container>
      <Container
        header={
          <Header variant="h2" actions={<Button onClick={() => navigate(3)}>{t(i18nKeys.general.edit)}</Button>}>
            {t(i18nKeys.newEvent.steps.ownersAndPermissions)}
          </Header>
        }>
        {target.eventPermissions.map((eventPermission, key) => {
          return (
            <React.Fragment key={`event-permission-${key}`}>
              <ReviewRow label={eventPermission.email} value={toTitleCase(eventPermission.eventPermissionType)} />
              <HorizontalRule evenMargins />
            </React.Fragment>
          );
        })}
        <Header variant="h3">{t(i18nKeys.newEvent.steps.eventDetails)}</Header>
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.supportChat)}
          value={
            target.collaborationOptions.supportChatEnabled
              ? t(i18nKeys.newEvent.labels.enabled)
              : t(i18nKeys.newEvent.labels.disabled)
          }
        />
        <HorizontalRule evenMargins />
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.teamChat)}
          value={
            target.collaborationOptions.teamChatEnabled
              ? t(i18nKeys.newEvent.labels.enabled)
              : t(i18nKeys.newEvent.labels.disabled)
          }
        />
        <HorizontalRule evenMargins />
        <ReviewRow
          label={t(i18nKeys.newEvent.labels.facilitatorMessaging)}
          value={
            target.collaborationOptions.messagingEnabled
              ? t(i18nKeys.newEvent.labels.enabled)
              : t(i18nKeys.newEvent.labels.disabled)
          }
        />
      </Container>
      <Container
        header={
          <Header variant="h2" actions={<Button onClick={() => navigate(4)}>{t(i18nKeys.general.edit)}</Button>}>
            {t(i18nKeys.newEvent.steps.challenges)}
          </Header>
        }>
        {(newEventImportSources.challengeSet || newEventImportSources.event) && (
          <Alert className="mb-8" type="info">
            {t(i18nKeys.challenges.importChallenges.fields.challengeSets.warning)}
          </Alert>
        )}
        {newEventImportSources.challengeSet && (
          <KeyValue label={<strong>{t(i18nKeys.newEvent.labels.importedChallengeSet)}</strong>}>
            <div>{newEventImportSources.challengeSet.title}</div>
            <div className="secondary-text">
              {t(i18nKeys.newEvent.labels.challengeCount, {
                challengeCount: newEventImportSources.challengeSet.challengeIds.length,
              })}
            </div>
          </KeyValue>
        )}
        {newEventImportSources.event && (
          <KeyValue label={<strong>{t(i18nKeys.newEvent.labels.importedEventChallenges)}</strong>}>
            <div>{newEventImportSources.event.title}</div>
            <div className="secondary-text">
              {t(i18nKeys.newEvent.labels.challengeCount, {
                challengeCount: newEventImportSources.event.challengeDescriptors.length,
              })}
            </div>
          </KeyValue>
        )}
        {target.challengeDescriptors.length > 0 && (
          <KeyValue
            label={
              <strong>
                {t(i18nKeys.newEvent.labels.selectedChallenges, { challengeCount: target.challengeDescriptors.length })}
              </strong>
            }>
            <SpaceBetween direction="vertical" size="s">
              {target.challengeDescriptors.map((cd: ChallengeDescriptor, i) => {
                const challengeListItem = getChallengeListItemFromChallengeId(cd.challengeId || '');
                if (challengeListItem) {
                  return (
                    <div key={challengeListItem.challengeId}>
                      <strong>{i + 1}. </strong>
                      <Link onFollow={() => toggleChallengeInfo(challengeListItem)} key={`challenge-list-item-${i}`}>
                        {challengeListItem?.props.title}
                      </Link>
                      <span> ({challengeListItem.challengeId})</span>
                    </div>
                  );
                }
              })}
            </SpaceBetween>
          </KeyValue>
        )}
        {target.backupChallengeConfig?.generalBackups && target.backupChallengeConfig.generalBackups.length > 0 && (
          <KeyValue
            label={
              <strong>
                {t(i18nKeys.newEvent.labels.selectedGeneralBackupChallenges, {
                  challengeCount: target.backupChallengeConfig.generalBackups.length,
                })}
              </strong>
            }>
            <SpaceBetween direction="vertical" size="s">
              {target.backupChallengeConfig.generalBackups.map((cd: ChallengeDescriptor, i) => {
                const challengeListItem = getChallengeListItemFromChallengeId(cd.challengeId || '');
                if (challengeListItem) {
                  return (
                    <div key={challengeListItem.challengeId}>
                      <strong>{i + 1}. </strong>
                      <Link onFollow={() => toggleChallengeInfo(challengeListItem)} key={`challenge-list-item-${i}`}>
                        {challengeListItem?.props.title}
                      </Link>
                      <span> ({challengeListItem.challengeId})</span>
                    </div>
                  );
                }
              })}
            </SpaceBetween>
          </KeyValue>
        )}
        {target.challengeDescriptors.map((challengeDescriptor: ChallengeDescriptor) => {
          if (
            target.backupChallengeConfig &&
            target.backupChallengeConfig.perChallengeBackups[challengeDescriptor.challengeId || ''] &&
            challengeDescriptor.challengeId
          ) {
            const challengeItem = getChallengeListItemFromChallengeId(challengeDescriptor.challengeId);
            return (
              <KeyValue
                key={challengeDescriptor.challengeId}
                label={
                  <strong>{`${challengeItem?.props.title} (${challengeItem?.challengeId}) ${t(
                    i18nKeys.newEvent.labels.specificChallengeBackupChallenges,
                    {
                      challengeCount:
                        target.backupChallengeConfig.perChallengeBackups[challengeDescriptor.challengeId].length,
                    }
                  )}`}</strong>
                }>
                <SpaceBetween direction="vertical" size="s">
                  {target.backupChallengeConfig.perChallengeBackups[challengeDescriptor.challengeId].map(
                    (cd: ChallengeDescriptor, i) => {
                      const challengeListItem = getChallengeListItemFromChallengeId(cd.challengeId || '');
                      if (challengeListItem) {
                        return (
                          <div key={challengeListItem.challengeId}>
                            <strong>{i + 1}. </strong>
                            <Link
                              onFollow={() => toggleChallengeInfo(challengeListItem)}
                              key={`challenge-list-item-${i}`}>
                              {challengeListItem?.props.title}
                            </Link>
                            <span> ({challengeListItem.challengeId})</span>
                          </div>
                        );
                      }
                    }
                  )}
                </SpaceBetween>
              </KeyValue>
            );
          }
        })}
      </Container>
    </SpaceBetween>
  );
};

export default ReviewAndCreate;
