/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/unbound-method */
import { cloneDeep } from 'lodash';
import { JamChallengeViewType, JamEventCollaborationOptions, JamEventTeamAssignmentType, Theme } from './JamCommon';
import { JamEventSponsor } from './JamEventSponsor';
import { JamEventSponsorshipSettings } from './JamEventSponsorshipSettings';
import { JamEventTeam, JamEventTeamSuggestions } from './JamEventTeam';
import { JamOnboardingVideoDetails } from './JamOnboardingVideo';
import { ONE_SECOND, SECONDS_PER_MINUTE } from '../constants/shared/time';
import { JamEvent } from './JamEvent';

export class JamEventConfiguration {
  challengeViewType: JamChallengeViewType = 'map';
  uiTheme: Theme = 'dark';
  eventType?: string;
  challengeLabProviders: { [challengeId: string]: string }; // map of {challengeId: labProvider}
  collaborationOptions: JamEventCollaborationOptions;
  teamAssignmentType: JamEventTeamAssignmentType = JamEventTeamAssignmentType.SELF_FORMING;
  formTeamsMinsBeforeEventStart = 0;
  onboardingVideo: JamOnboardingVideoDetails | null = null;
  startDate = 0;
  endDate = 0;
  title: string | null = null;
  eventFullMessage: string | null = null;
  eventSponsorshipSettings?: JamEventSponsorshipSettings = new JamEventSponsorshipSettings();

  // these attributes are not present on event object, only event configuration
  eventName: string | null = null;
  sponsors: JamEventSponsor[] = [];
  teamSuggestions?: JamEventTeamSuggestions = new JamEventTeamSuggestions();
  eventCodeRequired?: boolean = true;

  static fromPlainObject(obj: any): JamEventConfiguration {
    const config = Object.assign(new JamEventConfiguration(), obj || {});
    config.teams = (config.teams || []).map(JamEventTeam.fromPlainObject);
    config.challengeLabProviders = cloneDeep(config.challengeLabProviders || {});
    config.collaborationOptions = JamEventTeamSuggestions.fromPlainObject(config.collaborationOptions || {});
    config.teamSuggestions = JamEventTeamSuggestions.fromPlainObject(config.teamSuggestions || {});
    config.sponsors = (config.sponsors || []).map(JamEventSponsor.fromPlainObject);
    config.eventSponsorshipSettings = JamEventSponsorshipSettings.fromPlainObject(
      config.eventSponsorshipSettings || {}
    );
    if (config.onboardingVideo) {
      config.onboardingVideo = JamOnboardingVideoDetails.fromPlainObject(config.onboardingVideo);
    }
    return config as JamEventConfiguration;
  }

  static fromEvent(event: JamEvent, previousEventConfig: JamEventConfiguration) {
    previousEventConfig.uiTheme = event.uiTheme || previousEventConfig.uiTheme;
    previousEventConfig.challengeViewType = event.challengeViewType || previousEventConfig.challengeViewType;
    previousEventConfig.challengeLabProviders =
      event.challengeLabProviders || previousEventConfig.challengeLabProviders;
    previousEventConfig.collaborationOptions = event.collaborationOptions || previousEventConfig.collaborationOptions;
    previousEventConfig.eventType = event.type || previousEventConfig.eventType;
    previousEventConfig.startDate = event.startDate
      ? new Date(event.startDate).getTime()
      : previousEventConfig.startDate;
    previousEventConfig.endDate = event.endDate ? new Date(event.endDate).getTime() : previousEventConfig.endDate;
    previousEventConfig.title = event.title || previousEventConfig.title;
    previousEventConfig.teamAssignmentType = event.teamAssignmentType || previousEventConfig.teamAssignmentType;
    previousEventConfig.formTeamsMinsBeforeEventStart =
      event.formTeamsMinsBeforeEventStart || previousEventConfig.formTeamsMinsBeforeEventStart;
    previousEventConfig.onboardingVideo = event.onboardingVideo || previousEventConfig.onboardingVideo;
    previousEventConfig.eventName = event.name || previousEventConfig.eventName;
  }

  /**
   * Get the number of milliseconds until team forming can begin.
   */
  get timeUntilCanFormTeams(): number {
    const secondsBeforeEventStart = this.formTeamsMinsBeforeEventStart * SECONDS_PER_MINUTE;
    const millisBeforeEventStart = secondsBeforeEventStart * ONE_SECOND;
    const teamFormingStartTime = this.startDate - millisBeforeEventStart;
    const timeTillCanFormTeams = teamFormingStartTime - Date.now();
    return Math.max(0, timeTillCanFormTeams);
  }
}
