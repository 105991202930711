import React, { useEffect } from 'react';
import { JamEventIncident } from '@/src/api/JamEventIncident';
import { JAM_FACILITATOR_DETAILS_ROUTES } from '@/src/routes';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, Button, Container, ContentLayout, Grid, Header } from '@amzn/awsui-components-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const FacilitatorNotifications = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loadEventIncidents, eventIncidents, eventSlug } = useJamEventDetails();

  const goToMessaging = (incident: JamEventIncident) => {
    history.push(
      `${JAM_FACILITATOR_DETAILS_ROUTES.Messaging.resolve(eventSlug)}?message=${incident.message.body}&title=${
        incident.message.title
      }`
    );
  };

  const initialize = async () => {
    try {
      await loadEventIncidents();
    } catch (e) {
      // error handled in API
    }
  };

  useEffect(() => {
    void initialize();
  }, []);

  return (
    <ContentLayout header={<Header variant="h1">{t(i18nKeys.facilitator.notifications.title)}</Header>}>
      {!eventIncidents.length && (
        <Container>
          <Box>{t(i18nKeys.facilitator.notifications.list.empty)}</Box>
        </Container>
      )}

      {!!eventIncidents.length && (
        <Grid gridDefinition={eventIncidents.map(() => ({ colspan: 8 }))}>
          {eventIncidents.map((incident) => (
            <Container
              header={
                <Header
                  actions={
                    <Box margin={{ top: 'xs' }}>
                      <Button variant="primary" onClick={() => goToMessaging(incident)}>
                        {t(i18nKeys.facilitator.notifications.list.notify)}
                      </Button>
                    </Box>
                  }>
                  {incident.message.title}
                </Header>
              }
              footer={<Box color="text-status-inactive">{moment(incident.time).fromNow()}</Box>}
              key={incident.message.body}>
              <Box>{incident.message.body}</Box>
            </Container>
          ))}
        </Grid>
      )}
    </ContentLayout>
  );
};

export default FacilitatorNotifications;
