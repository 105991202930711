import React, { useState } from 'react';
import { Container, TableProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import {
  EventTemplateReport,
  EventTemplateReportResponse,
} from '@/src/types/EventTemplate';
import { LoadingBar } from '@/src/components/common/LoadingBar';
import { CustomTable } from '@/src/components/event-templates/CustomTable';
import {
  collectionPreferencesProps,
  columnDefinitions,
  filteringProperties,
  paginationLabels,
  visibleContent,
} from './table-config';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/uxdg';

interface IEventTemplateDetailReportingTableProps {
  eventTemplateReport: EventTemplateReportResponse | null;
}

const EventTemplateDetailReportingTable: React.FC<IEventTemplateDetailReportingTableProps> = ({
  eventTemplateReport,
}) => {
  if (!eventTemplateReport) {
    return <LoadingBar />;
  }
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
    visibleContent,
  });
  const { t } = useTranslation();

  const props = {
    data: eventTemplateReport.data || [],
    columnDefinitions: columnDefinitions(t) as unknown as TableProps.ColumnDefinition<EventTemplateReport>[],
    collectionPreferencesProps: collectionPreferencesProps(t),
    headerTitle: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.eventsBooked),
    filteringProperties: filteringProperties(t),
    filterPlaceholder: t(i18nKeys.eventTemplates.eventTemplateDetails.reporting.searchEvents),
    paginationLabels,
    preferences,
  };

  return (
    <div className="event-booked">
      <Container>
        <CustomTable
          {...props}
          variant="borderless"
          selectedChallegeItems={[]}
          setPreferences={(updatedPreferences) => setPreferences({ ...updatedPreferences })}
        />
      </Container>
    </div>
  );
};

export default EventTemplateDetailReportingTable;
