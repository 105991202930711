/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useCallback, createContext, useContext, ReactNode } from 'react';
import { isEqual } from 'lodash';
import {
  EventTemplateCreateOptions,
  EventDurationInfo,
  EventDurationType,
  EventLearningType,
  IEventTemplate,
  EventTemplateOptions,
  EventTemplateStatusType,
  EventTemplateChallenge,
  EventTemplateRationale,
} from '@/src/types/EventTemplate';

// context
import { useApi } from '@/src/store/api.context';

// constants
import { BACKUP_CHALLENGE_LIMIT } from '../constants/event-template.constants';

import { LoggingService } from '@/src/utils/logging-service.utils';

import { ChallengeListItem } from '../types/Challenge';
import { getAwsServicesFromChallengeList, getChallengeLimit } from '../utils/event-template.utils';
import { useEventTemplateOffers } from './event-template-offers.context';

// eslint-disable-next-line no-shadow
export enum EventTemplateChallengeTabId {
  CHALLENGE_ORDER = 'challenge-order',
  CHALLENGE = 'challege',
  CHALLENGE_SETS = 'challenge-sets',
}
interface IProps {
  children: ReactNode;
}

interface ICreateEventContextValue {
  loading: boolean;
  name: string;
  learningType: EventLearningType | undefined;
  duration: EventDurationType | undefined;
  summary: string;
  topics: string[];
  tags: string[];
  teamSize: number;
  minParticipants: number;
  maxParticipants: number;
  imageUrl?: string;
  selectedChallenges: EventTemplateChallenge[];
  challengeList: ChallengeListItem[];
  rationale: EventTemplateRationale | undefined;
  initEventTemplate: () => void;
  saveEventTemplate: () => Promise<IEventTemplate | undefined>;
  loadEventTemplate: (item?: IEventTemplate) => void;
  submitEventTemplate: () => Promise<IEventTemplate | undefined>;
  getStepNumber: (requestedStep?: number) => number;
  hasUnsavedChanges: () => boolean;
  onNameChange: (name: string) => void;
  onLearningTypeChange: (learningType: EventLearningType) => void;
  onDurationChange: (eventDuration: EventDurationType) => void;
  onSummaryChange: (summmary: string) => void;
  onTopicsChange: (topics: string[]) => void;
  onTagsChange: (tags: string[]) => void;
  onTeamSizeChange: (teamSize: number) => void;
  onMinParticipantsChange: (value: number) => void;
  onMaxParticipantsChange: (value: number) => void;
  onImageChange: (image: File | undefined) => Promise<void>;
  onSelectedChallengesChange: (items: ChallengeListItem[]) => void;
  onPrimaryBackupChallengesChange: (
    primaryChallenges: ChallengeListItem[],
    backupChallenges: ChallengeListItem[]
  ) => void;
  onRationaleChange: (id: string, rational: string) => void;
  getEventDurationInfo: () => EventDurationInfo | undefined;
}

const CreateEventContext = createContext<ICreateEventContextValue>({
  loading: true,
  name: '',
  learningType: undefined,
  duration: undefined,
  summary: '',
  topics: [],
  tags: [],
  teamSize: 0,
  minParticipants: 0,
  maxParticipants: 0,
  imageUrl: undefined,
  selectedChallenges: [],
  challengeList: [],
  rationale: undefined,
  onNameChange: (_name: string) => {
    // do nothing
  },
  onLearningTypeChange: (_learningType: EventLearningType) => {
    // do nothing
  },
  onDurationChange: (_eventDuration: EventDurationType) => {
    // do nothing
  },
  onSummaryChange: (_summmary: string) => {
    // do nothing
  },
  onTopicsChange: (_topics: string[]) => {
    // do nothing
  },
  onTagsChange: (_tags: string[]) => {
    // do nothing
  },
  onTeamSizeChange: (_teamSize: number) => {
    // do nothing
  },
  onMinParticipantsChange: (_value: number) => {
    // do nothing
  },
  onMaxParticipantsChange: (_value: number) => {
    // do nothing
  },
  onImageChange: (_imageFile: File | undefined) => Promise.resolve(undefined),
  onSelectedChallengesChange: (_items: ChallengeListItem[]) => {
    // do nothing
  },
  onPrimaryBackupChallengesChange: (
    _primaryChallenges: ChallengeListItem[],
    _backupChallenges: ChallengeListItem[]
  ) => {
    // do nothing
  },
  onRationaleChange: (_id: string, _rationaleText: string) => {
    // do nothing
  },
  initEventTemplate: () => undefined,
  getEventDurationInfo: () => undefined,
  saveEventTemplate: () => Promise.resolve(undefined),
  loadEventTemplate: (_item?: IEventTemplate) => undefined,
  submitEventTemplate: () => Promise.resolve(undefined),
  getStepNumber: (_requestedStep?: number) => 0,
  hasUnsavedChanges: () => false,
});

const CreateEventTemplateProvider: React.FC<IProps> = ({ children }) => {
  const { eventTemplateApi } = useApi();
  const { offers } = useEventTemplateOffers();
  const [loading, setLoading] = useState(true);
  const [eventTempalteId, setEventTemplateId] = useState<string>();
  const [savedEventTemplate, setSavedEventTemplate] = useState<IEventTemplate>();
  const [name, setName] = useState('');
  const [learningType, setLearningType] = useState<EventLearningType>(EventLearningType.TEAM);
  const [duration, setDuration] = useState<EventDurationType>(EventDurationType.MEDIUM);
  const [summary, setSummary] = useState<string>('');
  const [topics, setTopics] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [teamSize, setTeamSize] = useState<number>(3);
  const [minParticipants, setMinParticipants] = useState<number>(1);
  const [maxParticipants, setMaxParticipants] = useState<number>(1);
  const [imageUrl, setImageUrl] = useState<string>();
  const [selectedChallenges, setSelectedChallenges] = useState<EventTemplateChallenge[]>([]);
  const [challengeList, setChallengeList] = useState<ChallengeListItem[]>([]);
  const [rationale, setRationale] = useState<EventTemplateRationale>();

  const getEventDurationInfo = useCallback(() => {
    if (!offers) return;
    return offers.get(duration);
  }, [duration, offers]);

  const initEventTemplate = useCallback(() => {
    LoggingService.info('event template context', 'initEventTemplate');
    setEventTemplateId(undefined);
    setName('');
    setLearningType(EventLearningType.TEAM);
    setDuration(EventDurationType.MEDIUM);
    setSummary('');
    setTopics([]);
    setTeamSize(3);
    setMinParticipants(1);
    setMaxParticipants(1);
    setSelectedChallenges([]);
    setImageUrl(undefined);
    setRationale(undefined);
    setLoading(false);
    setSavedEventTemplate(undefined);
  }, []);

  const getCurrSaveOptions = useCallback(
    (): EventTemplateOptions => ({
      status: EventTemplateStatusType.DRAFT,
      name,
      learningType,
      duration,
      challenges: selectedChallenges.map((item) => {
        return {
          id: item.id ?? '',
          rationale: rationale?.[item.id] ?? '',
          backup: item.backup,
        };
      }),
      summary,
      topics,
      tags,
      recommendedTeamSize: teamSize,
      minParticipants,
      maxParticipants,
      awsServices: getAwsServicesFromChallengeList(challengeList),
    }),
    [
      getEventDurationInfo,
      name,
      learningType,
      summary,
      topics,
      tags,
      teamSize,
      minParticipants,
      maxParticipants,
      selectedChallenges,
      rationale,
      challengeList,
    ]
  );

  const saveEventTemplate = useCallback(async () => {
    if (eventTempalteId) {
      const options: Partial<EventTemplateOptions> = getCurrSaveOptions();
      const response = await eventTemplateApi.updateEventTemplateById(eventTempalteId, options);
      setSavedEventTemplate(response);
      return response;
    } else {
      const options: EventTemplateCreateOptions = {
        name,
        learningType,
        duration,
      };
      const response = await eventTemplateApi.createNewEventTemplate(options);
      if (response && response.id) {
        setEventTemplateId(response.id);
      }
      setSavedEventTemplate(response);
      return response;
    }
  }, [eventTempalteId, eventTemplateApi, getCurrSaveOptions, name, learningType, duration, getEventDurationInfo]);

  const getPrevSaveOptions = useCallback(
    (): EventTemplateOptions => ({
      name: savedEventTemplate?.name || '',
      learningType: savedEventTemplate?.learningType || EventLearningType.TEAM,
      duration: savedEventTemplate?.duration || EventDurationType.MEDIUM,
      status: EventTemplateStatusType.DRAFT,
      challenges: savedEventTemplate?.challenges,
      summary: savedEventTemplate?.summary || '',
      topics: savedEventTemplate?.topics || [],
      tags: savedEventTemplate?.tags || [],
      recommendedTeamSize: savedEventTemplate?.recommendedTeamSize || 0,
      minParticipants: savedEventTemplate?.minParticipants || 0,
      maxParticipants: savedEventTemplate?.maxParticipants || 0,
      awsServices: savedEventTemplate?.awsServices ?? [],
    }),
    [savedEventTemplate]
  );

  const hasUnsavedChanges = useCallback(() => {
    if (!savedEventTemplate) {
      return false;
    }
    const currSaveOptions = getCurrSaveOptions();
    const prevSavedOptions: EventTemplateOptions = getPrevSaveOptions();
    return !isEqual(currSaveOptions, prevSavedOptions);
  }, [saveEventTemplate]);

  const loadEventTemplate = useCallback(
    (eventTemplate?: IEventTemplate) => {
      if (!eventTemplate) {
        initEventTemplate();
        return;
      }
      setEventTemplateId(eventTemplate.id);
      setSavedEventTemplate(eventTemplate);
      setName(eventTemplate.name);
      setLearningType(eventTemplate.learningType);
      setDuration(eventTemplate.duration);
      setSummary(eventTemplate.summary);
      setTopics(eventTemplate.topics);
      setTopics(eventTemplate.tags);
      setTeamSize(
        eventTemplate.recommendedTeamSize || eventTemplate.learningType === EventLearningType.INDIVIDUAL ? 1 : 3
      );
      setMinParticipants(eventTemplate.minParticipants);
      setMaxParticipants(eventTemplate.maxParticipants);
      // setImage(eventTemplate?.images ? eventTemplate?.images[0] : undefined);
      setSelectedChallenges(eventTemplate.challenges);
      setChallengeList(eventTemplate?.challengeList ?? []);
      const rationaleObj: EventTemplateRationale = {};
      eventTemplate.challenges.forEach((item) => {
        rationaleObj[item?.id] = item?.rationale ?? '';
      });
      setRationale(rationaleObj);
      setLoading(false);
    },
    [initEventTemplate]
  );

  const getStepNumber = useCallback(
    (requestedStep?: number) => {
      let maxStepNumber = 0;
      const challengesLimit = getEventDurationInfo()?.minChallenges ?? 0 + BACKUP_CHALLENGE_LIMIT;
      if (name && duration) maxStepNumber = 1;
      if (selectedChallenges.length >= challengesLimit) maxStepNumber = 3;
      if (summary && teamSize && minParticipants && maxParticipants && topics?.length > 0) maxStepNumber = 4;
      if (requestedStep === undefined) return maxStepNumber;
      return Math.min(requestedStep, maxStepNumber);
    },
    [
      getEventDurationInfo,
      name,
      duration,
      selectedChallenges,
      summary,
      teamSize,
      minParticipants,
      maxParticipants,
      topics,
      tags,
    ]
  );

  const submitEventTemplate = useCallback(async () => {
    if (!eventTempalteId) {
      throw new Error('Failed to submit eventTempalteId not defined');
    }
    const options: Partial<EventTemplateOptions> = {
      status: EventTemplateStatusType.PRIVATE,
    };

    const response = await eventTemplateApi.updateEventTemplateById(eventTempalteId, options);
    return response;
  }, [eventTempalteId, eventTemplateApi]);

  const onNameChange = (newName: string) => {
    setName(newName);
  };

  const onLearningTypeChange = (newLearningType: EventLearningType) => {
    setLearningType(newLearningType);
    setDuration(newLearningType === EventLearningType.INDIVIDUAL ? EventDurationType.SPL : EventDurationType.MEDIUM);
    setTeamSize(newLearningType === EventLearningType.INDIVIDUAL ? 1 : 3);
  };

  const onDurationChange = (newDuration: EventDurationType) => {
    setDuration(newDuration);
  };

  const onSummaryChange = (newSummary: string) => {
    setSummary(newSummary);
  };

  const onTopicsChange = (newTopics: string[]) => {
    setTopics([...newTopics]);
  };

  const onTagsChange = (Tags: string[]) => {
    setTags([...Tags]);
  };

  const onTeamSizeChange = (newTeamSize: number) => {
    setTeamSize(newTeamSize);
  };

  const onMinParticipantsChange = (val: number) => {
    setMinParticipants(val);
  };

  const onMaxParticipantsChange = (val: number) => {
    setMaxParticipants(val);
  };

  const onImageChange = useCallback(
    async (newImageFile?: File) => {
      if (!newImageFile) {
        setImageUrl(undefined);
        return;
      }
      if (!eventTempalteId) {
        throw new Error('eventTempalteId not define');
      }
      // TODO call save API
      const presignedImageUrl = await eventTemplateApi.uploadEventTemplateImage(eventTempalteId, newImageFile);
      setImageUrl(presignedImageUrl);
    },
    [eventTempalteId, eventTemplateApi]
  );

  const onSelectedChallengesChange = (items: ChallengeListItem[]) => {
    setChallengeList(items);
    const challengeLimit = getChallengeLimit({
      minChallenges: getEventDurationInfo()?.minChallenges ?? 0,
      maxChallenges: getEventDurationInfo()?.maxChallenges ?? 0,
      backupChallenges: BACKUP_CHALLENGE_LIMIT,
      challengesLen: items.length,
    });

    setSelectedChallenges(
      items.map((item, index) => {
        return {
          id: item.challengeId ?? '',
          rationale: rationale?.[item?.challengeId ?? ''] ?? '',
          backup: index >= challengeLimit,
        };
      })
    );
  };

  const onPrimaryBackupChallengesChange = (primaryItems: ChallengeListItem[], backupItems: ChallengeListItem[]) => {
    const allItems = [...primaryItems, ...backupItems];
    setChallengeList(allItems);
    const selectedChallengeItems = [];
    for (const item of primaryItems) {
      selectedChallengeItems.push({
        id: item.challengeId ?? '',
        rationale: rationale?.[item?.challengeId ?? ''] ?? '',
        backup: false,
      });
    }
    for (const item of backupItems) {
      selectedChallengeItems.push({
        id: item.challengeId ?? '',
        rationale: rationale?.[item?.challengeId ?? ''] ?? '',
        backup: true,
      });
    }
    setSelectedChallenges(selectedChallengeItems);
  };

  const onRationaleChange = (challengeId: string, rationaleText: string) => {
    LoggingService.debug(`setRationale :`, { [challengeId]: rationaleText });
    setRationale({
      ...rationale,
      [challengeId]: rationaleText,
    });
  };

  const value: ICreateEventContextValue = {
    name,
    learningType,
    duration,
    summary,
    teamSize,
    minParticipants,
    maxParticipants,
    topics,
    tags,
    imageUrl,
    selectedChallenges,
    challengeList,
    rationale,
    loading,
    onNameChange,
    onLearningTypeChange,
    onDurationChange,
    onSummaryChange,
    onTopicsChange,
    onTagsChange,
    onTeamSizeChange,
    onMinParticipantsChange,
    onMaxParticipantsChange,
    onImageChange,
    onSelectedChallengesChange,
    onPrimaryBackupChallengesChange,
    onRationaleChange,
    getEventDurationInfo,
    initEventTemplate,
    saveEventTemplate,
    loadEventTemplate,
    submitEventTemplate,
    getStepNumber,
    hasUnsavedChanges,
  };
  return <CreateEventContext.Provider value={value}>{children}</CreateEventContext.Provider>;
};

const useCreateEventTemplate = () => {
  const context = useContext(CreateEventContext);
  if (context === undefined) {
    throw new Error('useChallenges can only be used inside ChallengesProvider');
  }
  return context;
};

export { CreateEventTemplateProvider, useCreateEventTemplate };
