import React from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface AlertModalProps {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const AlertModal: React.FC<AlertModalProps> = ({ value, setValue }) => {
  const { t } = useTranslation();
  const handleModal = () => setValue(!value);
  return (
    <Modal
      onDismiss={handleModal}
      visible={value}
      header={
        <SpaceBetween size="xs" direction="horizontal">
          <img src="/assets/aws-jam-logo-70x70.png" alt="logo" style={{ width: '40px' }} />
          <Box variant="h2">{t(i18nKeys.myJams.challenges.common.alert)}</Box>
        </SpaceBetween>
      }
      footer={
        <Box float="right" margin={{ vertical: 'xs' }}>
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={handleModal}>
              {t(i18nKeys.myJams.challenges.common.ok)}
            </Button>
          </SpaceBetween>
        </Box>
      }>
      <Box>{t(i18nKeys.myJams.challenges.details.solveUsing.buttons.restartChallenge.subtitle)}</Box>
    </Modal>
  );
};

export default AlertModal;
