import { ProgressBar } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';

interface LearningOutcomeProps {
  totalCount: number;
  totalLearned: number;
}

export const LearningOutcome: React.FC<LearningOutcomeProps> = ({ totalCount, totalLearned }) => {
  const { t } = useTranslation();
  const percentage = (totalLearned / totalCount || 0) * 100;
  return (
    <ProgressBar
      variant="key-value"
      label={t(i18nKeys.report.labels.learningPlan)}
      additionalInfo={t(i18nKeys.report.labels.numOfCount, { num: totalLearned, totalCount })}
      description={t(i18nKeys.report.labels.feedbackCount, { feedbackCount: totalCount })}
      value={percentage}
    />
  );
};
