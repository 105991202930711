import React, { MouseEventHandler, useState } from 'react';
import { useFlashbars } from '../../store/flashbar.context';
import { NullableString } from '../../types/common';
import { copyToClipboard } from '../../utils/copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import { useTheme } from '@/src/store/theme.context';
import { Mode } from '@amzn/awsui-global-styles';

interface SecretKeyProps {
  secretKey: NullableString | undefined;
}

const SecretKey: React.FC<SecretKeyProps> = ({ secretKey }) => {
  const { t } = useTranslation();
  const { addSuccessFlashbar } = useFlashbars();
  const { themeMode } = useTheme();
  const [show, setShow] = useState(false);
  const isDark = themeMode === Mode.Dark;

  const getSecretKey = () => {
    if (!show) {
      return Array(secretKey?.length).fill('*').join('');
    }
    return secretKey;
  };

  const copySecretKey = (): MouseEventHandler<HTMLSpanElement> | undefined => {
    copyToClipboard(secretKey);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    addSuccessFlashbar(t(i18nKeys.events.eventDetails.labels.secretKey.message));
    return;
  };

  return (
    <React.Fragment>
      <span
        className="badge-aws badge-aws-medium"
        style={{ cursor: 'pointer' }}
        onClick={copySecretKey}
        title={t(i18nKeys.events.eventDetails.labels.secretKey.title)}>
        <span
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          className={isDark ? '' : 'hover-reveal'}>
          {isDark ? getSecretKey() : secretKey}
        </span>{' '}
      </span>
    </React.Fragment>
  );
};
export default SecretKey;
