import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, TextFilter } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

// components
import { LoadingBar } from '@/src/components/common/LoadingBar';
import Comment from '@/src/components/ui/molecules/Comments/Comments';

// types
import { EventTemplateReview } from '@/src/types/EventTemplate';

// utils
import { i18nKeys } from '@/src/utils/i18n.utils';
import { EVENT_TEMPLATE_FEEDBACK_COMMENT_SORT_OPTIONS } from '@/src/constants/event-template.constants';

interface IEventDetailFeedbackComment {
  reviews: EventTemplateReview[];
  handleSeeMoreClick: () => void;
  filteringText: string;
  setFilteringText: (text: string) => void;
  selectedEventTemplateFeedbackOption: OptionDefinition;
  setSelectedEventTemplateFeedbackOption: (optionDefinition: OptionDefinition) => void;
  toggleEventTemplateFeedbackReviewLikeStatus: (id: string) => void;
}

const EventDetailFeedbackComment: React.FC<IEventDetailFeedbackComment> = ({
  reviews: reviewsComment,
  handleSeeMoreClick,
  filteringText,
  setFilteringText,
  selectedEventTemplateFeedbackOption,
  setSelectedEventTemplateFeedbackOption,
  toggleEventTemplateFeedbackReviewLikeStatus,
}) => {
  const { t } = useTranslation();

  if (!reviewsComment) {
    return <LoadingBar />;
  }

  return (
    <div>
      <div className="filter-wrapper">
        <TextFilter
          filteringText={filteringText}
          filteringPlaceholder={t(i18nKeys.eventTemplates.eventDetailFeedbackComment.searchReviews)}
          filteringAriaLabel={t(i18nKeys.eventTemplates.eventDetailFeedbackComment.searchReviews)}
          onChange={({ detail }) => {
            setFilteringText(detail.filteringText);
          }}
          data-testid="filter"
        />
        <Select
          selectedOption={selectedEventTemplateFeedbackOption}
          onChange={({ detail }) => setSelectedEventTemplateFeedbackOption(detail.selectedOption)}
          options={EVENT_TEMPLATE_FEEDBACK_COMMENT_SORT_OPTIONS}
          data-testid="select"
        />
      </div>
      <div className="comments-wrapper">
        {reviewsComment.map((review, index) => (
          <div key={index} className="comments-layout">
            <Comment
              review={review}
              toggleEventTemplateFeedbackReviewLikeStatus={toggleEventTemplateFeedbackReviewLikeStatus}
            />
            <div className="comment-divider" />
          </div>
        ))}
        <Button onClick={handleSeeMoreClick}>
          {t(i18nKeys.eventTemplates.eventTemplateDetails.feedback.moreReviews)}
        </Button>
      </div>
    </div>
  );
};

export default EventDetailFeedbackComment;
