import {
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@amzn/awsui-components-react';
import React, { useMemo, useState } from 'react';
import { HorizontalRule } from '../../common/HorizontalRule';
import { KeyValue } from '../../common/KeyValue';
import { EventLabSummary } from '@/src/types/EventLabSummary';
import moment from 'moment-timezone';
import { useUser } from '@/src/store/user.context';
import { LabShutoffCandidate, LabShutoffStatus } from '@/src/types/LabShutoff';
import { useApi } from '@/src/store/api.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

interface LabOverviewProps {
  summary: EventLabSummary;
  autoRefreshInterval: number;
  onChangeAutoRefreshInterval: (interval: number) => void;
  onRefresh: () => void;
  refreshing: boolean;
  lastUpdated: moment.Moment;
  showAuditTrail: (showFor: string) => void;
}

const LabOverview: React.FC<LabOverviewProps> = ({
  summary,
  autoRefreshInterval,
  onChangeAutoRefreshInterval,
  onRefresh,
  refreshing,
  lastUpdated,
  showAuditTrail,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [isTerminating, setIsTerminating] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reason, setReason] = useState('');
  const { eventsApi, labshutoffAPI } = useApi();

  const terminateLabs = async () => {
    if (summary.eventId) {
      try {
        await eventsApi.terminateEventLabs(summary.eventId);
        void onRefresh();
      } catch (e) {
        // e
      }
    }
    setIsTerminating(false);
  };

  /**
   * Turn on lab deployment for this event.
   */
  const turnOnEventLabs = async () => {
    try {
      const labShutoffCandidate = LabShutoffCandidate.ofEvent(summary.eventName || '');
      await labshutoffAPI.turnOnCandidate(labShutoffCandidate, false);
      onRefresh();
    } catch (e) {
      // e
    }
  };

  /**
   * Shut Off lab deployment for this event.
   */
  const shutoffEventLabs = async () => {
    try {
      const labShutoffCandidate = LabShutoffCandidate.ofEvent(summary.eventName || '');
      await labshutoffAPI.shutoffCandidate(reason, labShutoffCandidate, false);
      onRefresh();
    } catch (e) {
      // e
    }
    setShowReasonModal(false);
    setReason('');
  };

  /**
   * Return whether this component's eventlabsummary is for a test event.
   */
  const isTestEvent = useMemo((): boolean => {
    return summary?.testClone || summary?.testEvent;
  }, [summary]);

  /**
   * Return the de-facto shutoff status for the event of this component,
   * which depends on whether this component's event is a test event.
   */
  const shutoffStatus = useMemo((): LabShutoffStatus | undefined => {
    return isTestEvent ? summary?.testEventShutoffStatus : summary?.shutoffStatus;
  }, [summary]);

  const onAction = (id: string) => {
    switch (id) {
      case 'AUDIT_TRAIL':
        showAuditTrail('ALL');
        break;
      case 'TERMINATE_LABS':
        setIsTerminating(true);
        break;
      case 'TURN_ON_DEPLOYMENT':
        void turnOnEventLabs();
        break;
      case 'SHUT_OFF_DEPLOYMENT':
        setShowReasonModal(true);
        break;
    }
  };

  return (
    <Container
      header={
        <Header
          variant="h2"
          description={
            refreshing ? (
              <Spinner />
            ) : (
              t(i18nKeys.eventLabs.labOverview.messages.lastUpdated, { time: lastUpdated.format('MMM D hh:mm:ss A') })
            )
          }
          actions={
            <SpaceBetween direction="horizontal" size="m">
              <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                {t(i18nKeys.eventLabs.labOverview.messages.autoRefreshEvery)}
                <Input
                  type="number"
                  value={autoRefreshInterval.toString()}
                  onChange={({ detail }) => onChangeAutoRefreshInterval(detail.value ? parseInt(detail.value, 10) : 15)}
                />
                {t(i18nKeys.eventLabs.labOverview.messages.seconds)}
              </SpaceBetween>
              <Button iconName="refresh" onClick={onRefresh}>
                {t(i18nKeys.eventLabs.labOverview.actions.refresh)}
              </Button>
              {(user?.isEventAdmin || user?.isSuperAdmin) && (
                <ButtonDropdown
                  onItemClick={({ detail }) => onAction(detail.id)}
                  items={[
                    ...(user.isEventAdmin
                      ? [
                          { id: 'AUDIT_TRAIL', text: t(i18nKeys.eventLabs.labOverview.actions.auditTrail) },
                          {
                            id: 'TERMINATE_LABS',
                            text: t(i18nKeys.eventLabs.labOverview.actions.terminateLabs, {
                              count: summary ? summary.totalNonTerminatedAccounts : 0,
                            }),
                          },
                        ]
                      : []),
                    ...(user.isSuperAdmin
                      ? [
                          ...(shutoffStatus?.selfShutoff
                            ? [
                                {
                                  id: 'TURN_ON_DEPLOYMENT',
                                  text: t(i18nKeys.eventLabs.labOverview.actions.turnonLabDep),
                                },
                              ]
                            : []),
                          ...(!shutoffStatus?.selfShutoff
                            ? [
                                {
                                  id: 'SHUT_OFF_DEPLOYMENT',
                                  text: t(i18nKeys.eventLabs.labOverview.actions.shutoffLabDep),
                                },
                              ]
                            : []),
                        ]
                      : []),
                  ]}>
                  {t(i18nKeys.eventLabs.labOverview.actions.actions)}
                </ButtonDropdown>
              )}
            </SpaceBetween>
          }>
          {t(i18nKeys.eventLabs.labOverview.messages.labs)}
        </Header>
      }>
      <HorizontalRule />
      <ColumnLayout columns={4} variant="text-grid">
        <KeyValue label={<strong>{t(i18nKeys.eventLabs.labOverview.infoSections.labWarmup)}</strong>}>
          {moment(summary.labStartTime).format('MM/DD/YYYY, hh:mm A')}
        </KeyValue>
        <KeyValue label={<strong>{t(i18nKeys.eventLabs.labOverview.infoSections.eventStart)}</strong>}>
          {moment(summary.eventStartTime).format('MM/DD/YYYY, hh:mm A')}
        </KeyValue>
        <KeyValue label={<strong>{t(i18nKeys.eventLabs.labOverview.infoSections.eventEnd)}</strong>}>
          {moment(summary.eventEndTime).format('MM/DD/YYYY')}
        </KeyValue>
        <KeyValue label={<strong>{t(i18nKeys.eventLabs.labOverview.infoSections.labTermination)}</strong>}>
          {moment(summary.labEndTime).format('MM/DD/YYYY')}
        </KeyValue>
      </ColumnLayout>
      <Modal
        onDismiss={() => setIsTerminating(false)}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="m">
              <Button onClick={() => setIsTerminating(false)}>{t(i18nKeys.general.cancel)}</Button>
              <Button variant="primary" onClick={() => void terminateLabs()}>
                {t(i18nKeys.general.continue)}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header>
            {t(i18nKeys.eventLabs.labOverview.modal.confirmTerminate, { count: summary.totalNonTerminatedAccounts })}
          </Header>
        }
        visible={isTerminating}>
        <Box>
          {t(i18nKeys.eventLabs.labOverview.modal.warnTerminateAll, { count: summary.totalNonTerminatedAccounts })}
        </Box>
      </Modal>
      <Modal
        visible={showReasonModal}
        onDismiss={() => setShowReasonModal(false)}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="m">
              <Button variant="link" onClick={() => setShowReasonModal(false)}>
                {t(i18nKeys.general.skip)}
              </Button>
              <Button variant="primary" onClick={() => void shutoffEventLabs()}>
                {t(i18nKeys.general.submit)}
              </Button>
            </SpaceBetween>
          </Box>
        }>
        <Box>{t(i18nKeys.eventLabs.labOverview.modal.getReason)}</Box>
        <Textarea placeholder="reason" value={reason} onChange={({ detail }) => setReason(detail.value)} />
      </Modal>
    </Container>
  );
};

export default LabOverview;
