import { SpaceBetween } from '@amzn/awsui-components-react';
import React, { useMemo, useState } from 'react';
import { Event } from '../../../types/Event';
import EventTimes from '../eventDetailsSections/Summary/EventTimes';
import EventDetailsNotes from '../eventDetailsSections/Summary/EventDetailsNotes';
import TargetSummaryDetails from '../../common/CampaignEventComponents/TargetSummaryDetails';
import PrivacyType from '../eventDetailsSections/Summary/PrivacyType';
import { EventFields, eventTitleFieldValidater } from '@/src/utils/event.validation.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
// import TargetDetailsUsagePlan from '../../common/CampaignEventComponents/TargetDetailsUsagePlan';

interface NewEventDetailsProps {
  target: Event;
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

const NewEventDetails: React.FC<NewEventDetailsProps> = ({ target, validationHandler }) => {
  const { t } = useTranslation();
  const [titleError, setTitleError] = useState('');
  const [channelError, setChannelError] = useState('');

  const isEvent = target instanceof Event;
  // this will validate the field and show the message
  const eventTitleValidation = useMemo(
    () =>
      eventTitleFieldValidater(
        target?.title ?? '',
        `${
          isEvent
            ? t(i18nKeys.events.fields.eventTitle.title)
            : t(i18nKeys.campaigns.labels.campaignDetails.campaignTitle)
        } ${t(i18nKeys.generic.lengthLimit4to250)}`,
        target?.audienceType,
        t(i18nKeys.newEvent.errors.audience),
        new Map<EventFields, (error: string) => void>([
          [EventFields.EVENT_TITLE, (error: string) => setTitleError(error)],
          [EventFields.EVENT_CHANNEL, (error: string) => setChannelError(error)],
        ])
      ),
    [target?.title, target?.audienceType, eventTitleFieldValidater]
  );
  return (
    <SpaceBetween direction="vertical" size="s">
      <TargetSummaryDetails
        target={target}
        eventTitleValidation={eventTitleValidation}
        titleError={titleError}
        channelError={channelError}
      />
      <PrivacyType target={target} />
      <EventTimes target={target} validationHandler={validationHandler} eventTitleValidation={eventTitleValidation} />
      <EventDetailsNotes target={target} />
    </SpaceBetween>
  );
};

export default NewEventDetails;
