import { i18nKeys } from '@/src/utils/i18n.utils';
import { Box, Cards, Multiselect, PropertyFilterProps, Select, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventTemplate } from '@/src/store/event-template.context';
import { EventDurationType, IEventTemplate } from '@/src/types/EventTemplate';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useUser } from '@/src/store/user.context';
import { AWSServices, CARD_DEFINITIONS, SORT_OPTIONS, allOption } from './choose-different-learning-event.config';
import './ChooseDifferentLearningEvent.scss';
import { EVENT_TEMPLATE_TOPICS } from '@/src/constants/event-template.constants';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';

interface ChooseDifferentLearningEventProps {
  handleEventTemplateChangeAction: (payload: IEventTemplate) => void;
  eventTemplate?: IEventTemplate;
}

const ChooseDifferentLearningEvent: React.FC<ChooseDifferentLearningEventProps> = ({
  handleEventTemplateChangeAction,
  eventTemplate,
}) => {
  const { loading, eventTemplates, totalCount, pageOptions, onSortChange, onFilterChange, fetchPublicEventTemplates } =
    useEventTemplate();
  const { offers, fetchEventTemplateOffers } = useEventTemplateOffers();
  const { t } = useTranslation();
  const allParams = { ...allOption, label: t(allOption.label) };

  const { user } = useUser();
  const [selectedEventCatalog, setSelectedEventCatalog] = useState<IEventTemplate[]>([]);
  const [sortOptionIndex, setSortOptionIndex] = useState<number>(0);
  const [selectedTopics, setSelectedTopics] = useState<readonly OptionDefinition[]>([allParams]);
  const [selectedAwsServices, setSelectedAwsServices] = useState<readonly OptionDefinition[]>([allParams]);
  const [firstLoad, setFirstLoad] = useState(true);
  const isLoading = useRef(true);

  const sortOptions = SORT_OPTIONS.map((option) => ({ ...option, label: t(option.label) }));
  const selectedSortOption = sortOptions[sortOptionIndex];

  const templates = useMemo(() => {
    return eventTemplates.map((item) => ({
      ...item,
      isSubscribed: user?.isSubscribedUser || user?.isSuperAdmin,
    }));
  }, [eventTemplates]);

  const onFilterStateChange = (
    selectedOptions: readonly OptionDefinition[],
    existingOptions: readonly OptionDefinition[],
    setFilterState: (options: readonly OptionDefinition[]) => void
  ) => {
    const wasAllSelected = existingOptions.find((option) => option.value === 'all');
    const isAllSelected = selectedOptions.find((option) => option.value === 'all');
    let finalOptions = selectedOptions;
    if (wasAllSelected && selectedOptions.length > 1) {
      finalOptions = selectedOptions.filter((option) => option.value !== 'all');
    } else if (!wasAllSelected && isAllSelected) {
      finalOptions = [isAllSelected];
    }

    setFilterState(finalOptions);
  };

  const onTopicFilterChange = (selectedOptions: readonly OptionDefinition[]) => {
    onFilterStateChange(selectedOptions, selectedTopics, setSelectedTopics);
  };

  const onAwsServicesFilterChange = (selectedOptions: readonly OptionDefinition[]) => {
    onFilterStateChange(selectedOptions, selectedAwsServices, setSelectedAwsServices);
  };

  useEffect(() => {
    void fetchEventTemplateOffers();
  }, []);

  useEffect(() => {
    onSortChange({
      sortingColumn: {
        id: '',
        header: '',
        isRowHeader: false,
        sortingField: sortOptions[sortOptionIndex].field,
      },
      isDescending: sortOptions[sortOptionIndex].isDescending,
    });
  }, [sortOptionIndex]);

  useEffect(() => {
    const propertyFilter: PropertyFilterProps.Token[] = [];
    const filters = { topics: selectedTopics, services: selectedAwsServices };
    Object.keys(filters).forEach((key) => {
      const filterItems = filters[key as keyof typeof filters];
      if (!filterItems.length || filterItems.find((item) => ['all', 'All'].includes(item.value as string))) {
        // if all is selected
        return;
      }
      filterItems.filter((item) => item.value !== 'all');

      propertyFilter.push({
        propertyKey: key,
        operator: '=',
        value: filterItems.map((item) => item.value),
      });
    });

    // if user has only basic access filter the event templates with same duration as current event
    if (user?.hasBasicAccess && eventTemplate) {
      propertyFilter.push({
        propertyKey: 'durations',
        operator: '=',
        value: eventTemplate.duration,
      });
    }
    onFilterChange({
      operation: 'and',
      tokens: propertyFilter,
    });
  }, [selectedTopics, selectedAwsServices]);

  useEffect(() => {
    setTimeout(() => {
      setFirstLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (firstLoad) return;
    fetchPublicEventTemplates();
    isLoading.current = false;
  }, [pageOptions, firstLoad]);

  useEffect(() => {
    const existingSelectedCatalog = templates?.find(({ id }) => id === eventTemplate?.id);
    if (existingSelectedCatalog) {
      setSelectedEventCatalog([existingSelectedCatalog]);
    }
  }, [templates]);

  const durationLabels = useMemo(() => {
    if (!offers) return [];
    return [
      {
        label: t(i18nKeys.general.countHours, { count: offers.get(EventDurationType.SHORT)?.hours }),
        value: EventDurationType.SHORT,
      },
      {
        label: t(i18nKeys.general.countHours, { count: offers.get(EventDurationType.MEDIUM)?.hours }),
        value: EventDurationType.MEDIUM,
      },
      {
        label: t(i18nKeys.general.countHours, { count: offers.get(EventDurationType.LONG)?.hours }),
        value: EventDurationType.LONG,
      },
    ];
  }, [t, offers]);

  const allFilters = useMemo(() => {
    return {
      topic: EVENT_TEMPLATE_TOPICS,
      awsservices: AWSServices,
      duration: durationLabels,
    };
  }, [durationLabels]);

  return (
    <div className="choose-learning-event">
      <Box variant="h2" margin={{ bottom: 'l' }}>
        {t(i18nKeys.challenges.tabHeaders.chooseDifferentEvent)}
      </Box>
      <SpaceBetween size="m" direction="horizontal">
        <div style={{ width: '226px' }}>
          <Select
            expandToViewport
            selectedOption={{
              ...selectedSortOption,
              label: `${t(i18nKeys.general.sortBy)}: ${selectedSortOption.label}`,
            }}
            options={sortOptions}
            onChange={({ detail }) => {
              const currentSortIndex = sortOptions.findIndex(
                (sortOption) => sortOption.value === detail.selectedOption.value
              );
              setSortOptionIndex(currentSortIndex);
            }}
          />
        </div>
        <div style={{ width: '360px' }}>
          <Multiselect
            selectedOptions={selectedTopics as OptionDefinition[]}
            options={[allParams, ...allFilters.topic]}
            placeholder={`${t(i18nKeys.catalog.catalogFilter.topic)}: (${selectedTopics.length})`}
            onChange={({ detail }) => onTopicFilterChange(detail.selectedOptions)}
            hideTokens
          />
        </div>
        <div style={{ width: '360px' }}>
          <Multiselect
            selectedOptions={selectedAwsServices as OptionDefinition[]}
            options={[allParams, ...allFilters.awsservices]}
            placeholder={`${t(i18nKeys.catalog.catalogFilter.awsServices)}: (${selectedAwsServices.length})`}
            onChange={({ detail }) => onAwsServicesFilterChange(detail.selectedOptions)}
            hideTokens
          />
        </div>
        <div className="match-count">
          {t(i18nKeys.challenges.chooseDifferentEvent.totalMatches, { count: totalCount })}
        </div>
      </SpaceBetween>
      <Box margin={{ top: 'l' }}>
        <Cards
          cardDefinition={CARD_DEFINITIONS}
          loading={loading || isLoading.current}
          loadingText={t(i18nKeys.challenges.chooseDifferentEvent.loadingEvents)}
          items={templates}
          variant="full-page"
          selectionType="single"
          selectedItems={selectedEventCatalog}
          onSelectionChange={({ detail }) => {
            setSelectedEventCatalog(detail.selectedItems);
            handleEventTemplateChangeAction(detail.selectedItems[0]);
          }}
          empty={
            <Box textAlign="center">
              <b>{t(i18nKeys.catalog.catalogFilter.emptyResult.title)}</b>
              <Box padding={{ bottom: 's' }} variant="p">
                {t(i18nKeys.catalog.catalogFilter.emptyResult.caption)}
              </Box>
            </Box>
          }
        />
      </Box>
    </div>
  );
};

export default ChooseDifferentLearningEvent;
