import { RoutePath } from '@/src/RoutePath';
import { i18nKeys } from '@/src/utils/i18n.utils';

export const JAM_PROFILE_LINKS = [
  {
    title: i18nKeys.JamProfile.jamProfileDetails.title,
    description: i18nKeys.JamProfile.jamProfileDetails.desc,
    link: RoutePath.JAM_PROFILE_DETAILS,
  },
  {
    title: i18nKeys.JamProfile.jamSkillProfile.title,
    description: i18nKeys.JamProfile.jamSkillProfile.desc,
    link: RoutePath.JAM_SKILL_PROFILE,
  },
  {
    title: i18nKeys.JamProfile.prizes.title,
    description: i18nKeys.JamProfile.prizes.desc,
    link: RoutePath.JAM_PRIZES,
  },
  {
    title: i18nKeys.JamProfile.DataAndPrivacy.title,
    description: i18nKeys.JamProfile.DataAndPrivacy.desc,
    link: RoutePath.DATA_AND_PRIVACY,
  },
];
