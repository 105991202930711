import React from 'react';
import { Box, Button, ButtonDropdown, CollectionPreferencesProps, SpaceBetween } from '@amzn/awsui-components-react';
import { CognitoUser, DEFAULT_STATUS } from '../../../../types/User';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../utils/table.utils';
import { useTranslation } from 'react-i18next';
import { UserStatuses } from '../../../../types/cognito';
import { capitalize, snakeCase } from 'lodash';
import { AuthRole } from '../../../../constants/shared/auth-roles';
import { i18nKeys } from '../../../../utils/i18n.utils';

const formatStatus = (status: string): string => {
  return snakeCase(status).split('_').map(capitalize).join(' ');
};

export const getGroupTitle = (group: string): string => {
  if (isCustomGroup(group)) {
    return formatStatus(group);
  }

  return group.split('-').map(capitalize).join(' ');
};

const isCustomGroup = (group: string): boolean => {
  return !(Object.values(AuthRole) as string[]).includes(group);
};

export const filteringFunction = (item: CognitoUser, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    matcher.isMatch(item.email) ||
    matcher.isMatch(item.cognitoUsername) ||
    (item.roles?.findIndex((group) => getGroupTitle(group).includes(filteringText)) || -1) > -1 ||
    matcher.isMatch(formatStatus(item.status))
  );
};

export const COLUMN_DEFINITIONS = (
  preferences: CollectionPreferencesProps.Preferences,
  handleRemoveGroupClick: (group: string, user: CognitoUser) => void,
  handleActions: (id: string, user: CognitoUser) => void
) => {
  const { t } = useTranslation();

  return [
    {
      id: 'login',
      sortingField: 'login',
      header: t(i18nKeys.settings.userManagement.table.headers.username),
      cell: (item: CognitoUser) => <Box>{item.login}</Box>,
      width: '15%',
    },
    {
      id: 'email',
      sortingField: 'email',
      header: t(i18nKeys.settings.userManagement.table.headers.email),
      cell: (item: CognitoUser) => <Box color="text-status-info">{item.email}</Box>,
      width: '25%',
    },
    {
      id: 'groups',
      sortingField: 'groups',
      header: t(i18nKeys.settings.userManagement.table.headers.groups),
      cell: (item: CognitoUser) => (
        <SpaceBetween direction="vertical" size="s">
          {item.roles?.map((group, i) => (
            <SpaceBetween alignItems="center" direction="horizontal" key={`group-${i}`} size="xxs">
              <Box>{getGroupTitle(group)}</Box>
              <Box color="text-status-info">
                <Button variant="icon" iconName="close" onClick={() => handleRemoveGroupClick(group, item)} />
              </Box>
            </SpaceBetween>
          ))}
        </SpaceBetween>
      ),
      width: '15%',
    },
    {
      id: 'email-verified',
      sortingField: 'emailVerified',
      header: t(i18nKeys.settings.userManagement.table.headers.emailVerified),
      cell: (item: CognitoUser) => (item.emailVerified ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)),
      width: '15%',
    },
    {
      id: 'status',
      sortingField: 'status',
      header: t(i18nKeys.settings.userManagement.table.headers.status),
      cell: (item: CognitoUser) => {
        const knownStatuses = [
          UserStatuses.CONFIRMED,
          DEFAULT_STATUS,
          UserStatuses.UNCONFIRMED,
          UserStatuses.FORCE_CHANGE_PASSWORD,
        ];
        return (
          <React.Fragment>
            {item.status === UserStatuses.CONFIRMED && (
              <span>{t(i18nKeys.settings.userManagement.table.statuses.confirmed)}</span>
            )}
            {item.status === DEFAULT_STATUS && (
              <span>{t(i18nKeys.settings.userManagement.table.statuses.externalProvider)}</span>
            )}
            {item.status === UserStatuses.UNCONFIRMED && (
              <span>{t(i18nKeys.settings.userManagement.table.statuses.unconfirmed)}</span>
            )}
            {item.status === UserStatuses.FORCE_CHANGE_PASSWORD && (
              <span>{t(i18nKeys.settings.userManagement.table.statuses.forcePasswordChange)}</span>
            )}
            {!knownStatuses.includes(item.status) && <span>{formatStatus(item.status)}</span>}
          </React.Fragment>
        );
      },
      width: '10%',
    },
    {
      id: 'enabled',
      sortingField: 'enabled',
      header: t(i18nKeys.settings.userManagement.table.headers.enabled),
      cell: (item: CognitoUser) => (item.enabled ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)),
      width: '10%',
    },
    {
      id: 'actions',
      sortingField: 'actions',
      header: t(i18nKeys.settings.userManagement.table.headers.actions),
      cell: (item: CognitoUser) => (
        <ButtonDropdown
          expandToViewport
          items={[
            {
              id: 'add-user-to-auth-group',
              text: t(i18nKeys.settings.userManagement.buttons.addUserToAuthGroup) ?? '',
            },
            {
              id: 'add-user-to-custom-auth-group',
              text: t(i18nKeys.settings.userManagement.buttons.addUserToCustomAuthGroup) ?? '',
            },
            { id: 'delete-user', text: t(i18nKeys.settings.userManagement.buttons.deleteUser) ?? '' },
          ]}
          variant="icon"
          onItemClick={(ele) => handleActions(ele.detail.id, item)}
        />
      ),
      width: '10%',
    },
  ];
};
