export type NotificationType = 'danger' | 'warning' | 'info' | 'success' | 'info_with_email' | 'warning_with_email';

export const TOAST_TYPE_MAP = {
  danger: 'error',
  warning: 'warn',
  info: 'info',
  success: 'success',
};

export interface NotificationData {
  options: NotificationOptions;
  notificationMessage: NotificationMessage;
}

export class NotificationMessage {
  title: string;
  message: string;
  type: NotificationType;

  static fromPlainObject(obj: any): NotificationMessage {
    const data = Object.assign(new NotificationMessage(), obj) as NotificationMessage;
    data.title = decodeURIComponent(data.title);
    data.message = decodeURIComponent(data.message);
    return data;
  }
}

export class NotificationOptions {
  timeOut?: number = 5000;
  toast?: boolean = true;
  showProgressBar?: boolean = true;
  pauseOnHover?: boolean = true;

  static fromPlainObject(obj: any): NotificationOptions {
    return Object.assign(new NotificationOptions(), obj) as NotificationOptions;
  }

  merge?(options: NotificationOptions) {
    return Object.assign(this, options);
  }
}

export class OfflineNotificationMessage {
  id: string;
  read: boolean;
  received: number;
  notificationMessage: NotificationMessage;

  static fromPlainObject(obj: any): OfflineNotificationMessage {
    const message: OfflineNotificationMessage = Object.assign(
      new OfflineNotificationMessage(),
      obj
    ) as OfflineNotificationMessage;
    if (message.notificationMessage) {
      message.notificationMessage = NotificationMessage.fromPlainObject(message.notificationMessage);
    }
    return message;
  }
}

/**
 * A recipient item contains a label and value. Primarily used in cases where there's a
 * hash value for a user and there needs to be a human readable value displayed.
 *
 * Note: Replaces the pre-existing AutoCompleteItem to keep similar functionality on the messaging page..
 */
export class RecipientItem {
  label: string;
  value: string;
}
