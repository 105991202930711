/* eslint-disable @typescript-eslint/no-unsafe-call */
import { CodeEditor, CodeEditorProps } from '@amzn/awsui-components-react';
import * as React from 'react';
import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';

interface JamCodeEditorProps {
  language: CodeEditorProps.Language;
  value: string;
  setValue: (e: string) => void;
  editorContentHeight?: number;
}

const JamCodeEditor: React.FC<JamCodeEditorProps> = ({ language, value, setValue, editorContentHeight }) => {
  const { t } = useTranslation();
  const [preferences, setPreferences] = useState<CodeEditorProps.Preferences | undefined>(undefined);
  const [loading] = useState(false);

  ace.config.set('useStrictCSP', true);
  ace.config.set('loadWorkerFromBlob', false);
  ace.config.set('basePath', "https://unpkg.com/ace-builds@1.4.6/src-noconflict")

  return (
    <CodeEditor
      ace={ace as unknown}
      language={language}
      value={value}
      onChange={(e) => setValue(e.detail.value)}
      preferences={preferences}
      onPreferencesChange={(e) => setPreferences(e.detail)}
      loading={loading}
      editorContentHeight={editorContentHeight}
      i18nStrings={{
        loadingState: t(i18nKeys.codeEditor.loadingState),
        errorState: t(i18nKeys.codeEditor.errorState),
        errorStateRecovery: t(i18nKeys.codeEditor.errorStateRecovery),
        editorGroupAriaLabel: t(i18nKeys.codeEditor.editorGroupAriraLabel),
        statusBarGroupAriaLabel: t(i18nKeys.codeEditor.statusBarGroupAriaLabel),
        cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
        errorsTab: t(i18nKeys.codeEditor.errorsTab),
        warningsTab: t(i18nKeys.codeEditor.warningsTab),
        preferencesButtonAriaLabel: t(i18nKeys.codeEditor.preferencesLabel),
        paneCloseButtonAriaLabel: t(i18nKeys.codeEditor.closeLabel),
        preferencesModalHeader: t(i18nKeys.codeEditor.preferencesLabel),
        preferencesModalCancel: t(i18nKeys.codeEditor.cancelLabel),
        preferencesModalConfirm: t(i18nKeys.codeEditor.confirmLabel),
        preferencesModalWrapLines: t(i18nKeys.codeEditor.warpLinesLabel),
        preferencesModalTheme: t(i18nKeys.codeEditor.themeLabel),
        preferencesModalLightThemes: t(i18nKeys.codeEditor.lightThemesLabel),
        preferencesModalDarkThemes: t(i18nKeys.codeEditor.darkThemesLabel),
      }}
    />
  );
};

export default JamCodeEditor;
